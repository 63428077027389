import React from 'react'

import { Container, Text } from '@tribegroup'

interface IProps {
  firstText: string | React.ReactNode
  secondText?: string | React.ReactNode
  color: string
}

const SubmissionThumbnailStatusLabel: React.FC<IProps> = ({ firstText, secondText, color }) => (
  <Container topOuterSpacing={0.5}>
    <Text block xsmall color={color} uppercase>
      {firstText}
    </Text>
    {secondText && (
      <Text block xsmall color={color} uppercase>
        {secondText}
      </Text>
    )}
  </Container>
)

export default SubmissionThumbnailStatusLabel
