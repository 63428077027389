import { differenceInHours, format } from 'date-fns'

import { TIME_WITH_MERIDIEM_INDICATOR } from '@constants'

export const isWithinHoursBefore = (
  hours: number,
  rawDate1?: Date | string,
  rawDate2?: Date | string,
) => {
  const date1 = typeof rawDate1 === 'string' ? new Date(rawDate1) : (rawDate1 as Date)
  const date2 = typeof rawDate2 === 'string' ? new Date(rawDate2) : (rawDate2 as Date)
  if (!date1 || !date2) {
    return false
  }

  const difference = differenceInHours(date2, date1)

  return difference >= 0 && difference <= hours
}

export const getLocalTimezoneName = (selectedDate: Date) => {
  const RE_PATTERN = new RegExp('[A-Z](?!.*[(])', 'g')
  const timeString = new Date(selectedDate).toTimeString()
  const matchingTimeString = timeString.match(RE_PATTERN)
  return matchingTimeString ? matchingTimeString.join('') : ''
}

export const getFormattedTimeWithMeridiem = (selectedDate: Date) =>
  format(selectedDate, TIME_WITH_MERIDIEM_INDICATOR)
