import { IAddNoteRequestBody, IAddNoteRequestMeta, INote, NotesActionTypes } from '@store/notes'

export const addNote = (
  submissionId: number,
  campaignId: number,
  requestBody: IAddNoteRequestBody,
  meta: IAddNoteRequestMeta,
) => ({
  payload: { submissionId, campaignId, requestBody },
  type: NotesActionTypes.ADD_REQUEST,
  meta,
})

export const addNoteError = (error: any) => ({
  payload: error,
  type: NotesActionTypes.ADD_ERROR,
})

export const addNoteSuccess = (submissionId: number, note: INote) => ({
  payload: { submissionId, note },
  type: NotesActionTypes.ADD_SUCCESS,
})
