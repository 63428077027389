import { FC, useEffect, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { METRIC_DISPUTE_FEEDBACK_CHARACTER_LIMIT } from '@constants'
import { useModal } from '@hooks/useModal'
import { IApplicationState } from '@store'
import { metricDisputeRequest } from '@store/metricDisputes/actions'
import { selectErrors, selectIsFulfilled, selectIsLoading } from '@store/metricDisputes/selectors'
import { MetricDisputeIssueType } from '@store/metricDisputes/types'
import { selectSubmission } from '@store/submissions'
import Theme from '@theme'
import { Button, Container, Header, Modal, ModalTitle, Text, TextArea, Toaster } from '@tribegroup'

interface IProps {
  submissionId: number
  hasAnalytics: boolean
}

export const AnalyticsPostPerformanceFlagSubmissionModal: FC<IProps> = ({
  submissionId,
  hasAnalytics,
}) => {
  const { isOpen, hideModal } = useModal()
  const dispatch = useDispatch()

  const submission = useSelector((state: IApplicationState) =>
    selectSubmission(state, submissionId),
  )

  const schema = useMemo(() => {
    if (hasAnalytics) {
      return yup.object({
        description: yup.string().trim().required().max(METRIC_DISPUTE_FEEDBACK_CHARACTER_LIMIT),
      })
    }
    return yup.object({})
  }, [hasAnalytics])

  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, isSubmitted, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const descriptionInnerRef = useMemo(() => (hasAnalytics ? register() : undefined), [hasAnalytics])

  const isLoading = useSelector(selectIsLoading)
  const isFulfilled = useSelector(selectIsFulfilled)
  const errors = useSelector(selectErrors)
  const hasError = Boolean(errors)

  useEffect(() => {
    if (isSubmitted && isSubmitSuccessful && isFulfilled) {
      hideModal()
      Toaster.createToast(FormattedMessage, {
        id: 'submission.performance.flag.toast',
      })
    }
  }, [isSubmitSuccessful, isSubmitted, isFulfilled])

  const onSubmit = (data) => {
    const issueType: MetricDisputeIssueType = hasAnalytics ? 'incorrect' : 'unavailable'
    return dispatch(
      metricDisputeRequest({
        submissionId,
        issueType,
        description: data.description ?? '',
      }),
    )
  }

  const submissionEnabled = hasAnalytics ? isDirty && isValid : true

  return (
    <Modal reactModalProps={{ isOpen: Boolean(isOpen) }} width={42.5} complex>
      <form
        name="metric-dispute-form"
        onSubmit={handleSubmit(onSubmit)}
        method="POST"
        autoComplete="off"
        data-testid="metric-dispute-form"
      >
        <ModalTitle bottomOuterSpacing={0.75} center>
          <FormattedHTMLMessage
            id={
              hasAnalytics
                ? 'submission.performance.flag.modal.incorrect.header'
                : 'submission.performance.flag.modal.unavailable.header'
            }
          />
        </ModalTitle>
        <Text color={Theme.grey900Color} customFontSize={1.125} light>
          {submission.is_proxy ? (
            <FormattedHTMLMessage id="submission.performance.flag.modal.incorrect.proxy" />
          ) : (
            <FormattedMessage
              id={
                hasAnalytics
                  ? 'submission.performance.flag.modal.incorrect.body'
                  : 'submission.performance.flag.modal.unavailable.body'
              }
            />
          )}
        </Text>
        {hasAnalytics && (
          <Container topOuterSpacing={1}>
            <TextArea
              scheme="primary"
              height={8}
              name="description"
              placeholder="Share your feedback"
              innerRef={descriptionInnerRef}
              characterLimit={METRIC_DISPUTE_FEEDBACK_CHARACTER_LIMIT}
              data-testid="submission-flag-textarea"
              trim
            />
          </Container>
        )}
        {hasError && isSubmitted && (
          <Container topOuterSpacing={1}>
            <Text small color={Theme.errorColor}>
              <FormattedMessage id="core.text.genericError" />
            </Text>
          </Container>
        )}
        <Container flexBetween topOuterSpacing={2.5}>
          <Button
            type="button"
            scheme="primary"
            onClick={hideModal}
            outlined
            rightOuterSpacing={1}
            width={15.25}
            disabled={isLoading}
          >
            <Header cta>
              <FormattedMessage id="core.text.cancel" />
            </Header>
          </Button>
          <Button
            scheme="primary"
            width={15.25}
            disabled={isLoading || !submissionEnabled}
            loading={isLoading}
          >
            <Header cta>
              <FormattedMessage id="core.text.submit" />
            </Header>
          </Button>
        </Container>
      </form>
    </Modal>
  )
}
