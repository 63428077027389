import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import BrandFansGroupRowTrackedRoute from '@components/BrandFans/Group/Row/TrackedRoute'
import SortMenu from '@components/UI/SortMenu'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { fetchBrandFanGroupMembers, selectBrandFanGroupMembersSortOptions } from '@store/brandFans'
import { Container } from '@tribegroup'

interface IProps {
  groupId: number
}

interface IInternalProps extends IProps {
  sortOptions: ReadonlyArray<string>
  fetchBrandFanGroupMembers: typeof fetchBrandFanGroupMembers
}

const BrandFansGroupDetailsSort: React.FC<IInternalProps> = ({
  groupId,
  sortOptions,
  fetchBrandFanGroupMembers: fetchBrandFanGroupMembersAction,
}) => {
  const onSortByChange = () => {
    const resetList = true
    fetchBrandFanGroupMembersAction(groupId, false, resetList)
  }

  return (
    <Container data-testid="brand-fans-group-details-sort">
      <SortMenu
        anchor={'right'}
        trackingWrapper={{
          component: BrandFansGroupRowTrackedRoute,
          createProps: (value: string) => ({
            eventName: EventTrackingNames.BrandFansGroupDetailView,
            groupId,
            sortBy: value,
            additionalProperties: {
              source: 'sort_filter',
            },
          }),
        }}
        options={sortOptions.map((option) => ({
          value: option,
          display: <FormattedMessage id={`brandFans.list.sort.option.${option}`} />,
        }))}
        onSortByChange={onSortByChange}
      />
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { groupId }: IProps) => ({
  sortOptions: selectBrandFanGroupMembersSortOptions(state, groupId),
})

const mapDispatchToProps = {
  fetchBrandFanGroupMembers,
}

export { BrandFansGroupDetailsSort }
export default compose<IInternalProps, IProps>(connect(mapStateToProps, mapDispatchToProps))(
  BrandFansGroupDetailsSort,
)
