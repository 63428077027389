import { StripeCardNumberElement } from '@stripe/stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { call, put, select, take, takeEvery } from 'redux-saga/effects'

import { BuilderSteps, EventTrackingNames, PaymentMethods } from '@enums'
import { IHateoasLink } from '@store'
import { trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  finalize,
  FinalizeCampaignActionTypes,
  finalizeError,
  finalizeSuccess,
  saveCampaign,
  selectFinalizeLink,
} from '@store/campaigns'
import {
  IPaymentDetails,
  selectHasBrandPartnershipHandle,
  selectRequestedSocialSubmissionTypes,
} from '@store/drafts'
import {
  createSetupIntent,
  CreateSetupIntentActionTypes,
  fetchMeRequest,
  selectSetupIntentClientSecret,
  setUpCardError,
  shouldShowCampaignCreationPrompt,
} from '@store/me'
import { setUIFlag, setUIFlagData, UIFlag } from '@store/ui'
import { networkRequest, stripeErrorToResponseError } from '@utils'

export function* handlePaymentSetupIntent(stripe: Stripe, element: StripeCardNumberElement) {
  yield put(createSetupIntent())
  yield take(CreateSetupIntentActionTypes.CREATE_SUCCESS)

  const clientSecret = yield select(selectSetupIntentClientSecret)
  const setupIntentResponse = yield call(stripe!.confirmCardSetup, clientSecret, {
    payment_method: {
      card: element,
    },
  })
  if (setupIntentResponse.error) {
    const respError = {
      messages: [stripeErrorToResponseError(setupIntentResponse.error)],
    }
    yield put(setUpCardError(respError))
    throw respError
  }
  const { payment_method } = setupIntentResponse.setupIntent
  return {
    payment: {
      payment_type: PaymentMethods.CREDIT_CARD,
      payment_method,
    },
  }
}

export function* handlePayment(paymentDetails: IPaymentDetails) {
  const { payment_type, stripe, use_existing_card, cardNumberElement } = paymentDetails
  if (payment_type === PaymentMethods.CREDIT_CARD && !use_existing_card) {
    return yield call(handlePaymentSetupIntent, stripe, cardNumberElement)
  }
  return paymentDetails
}

export function* handleFinalize(action: ReturnType<typeof finalize>) {
  try {
    const { campaignId, campaignType, params } = action.payload
    const { payment_details, ...finalizeDetails } = params
    const { history, redirect, toast } = action.meta

    yield put(saveCampaign(BuilderSteps.SUBMIT))

    const payment = yield call(handlePayment, payment_details)
    const authToken: string = yield select(selectAuthToken)
    const finalizeLink: IHateoasLink = yield select(selectFinalizeLink, campaignId)

    yield call(
      networkRequest,
      finalizeLink.method,
      finalizeLink.href,
      {
        ...finalizeDetails,
        payment_details: payment,
      },
      authToken,
    )

    const paidPartnershipTag = yield select(selectHasBrandPartnershipHandle, campaignId)
    const requestedSocialSubmissionTypes = yield select(
      selectRequestedSocialSubmissionTypes,
      campaignId,
    )
    yield put(
      trackEvent(EventTrackingNames.CampaignBuilderSubmitCampaign, {
        brief_id: campaignId,
        brief_type: campaignType,
        paid_partnership_tag: paidPartnershipTag,
        requested_social_submission_types: requestedSocialSubmissionTypes,
      }),
    )

    yield put(finalizeSuccess(campaignId, { toast }))
    yield put(fetchMeRequest())

    const showCampaignCreationPrompt = yield select(shouldShowCampaignCreationPrompt)
    if (showCampaignCreationPrompt) {
      yield put(setUIFlag(UIFlag.SHOW_CAMPAIGN_CREATION_CONFIRMATION_MODAL))
      yield put(
        setUIFlagData({
          flag: UIFlag.SHOW_CAMPAIGN_CREATION_CONFIRMATION_MODAL,
          data: {
            paymentMethod: payment_details.payment_type,
          },
        }),
      )
    }

    yield call(history.push, redirect)
  } catch (error) {
    yield put(finalizeError(error))
  }
}

export function* watchFinalizeRequest() {
  yield takeEvery(FinalizeCampaignActionTypes.FINALIZE_REQUEST, handleFinalize)
}
