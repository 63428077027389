import { useState } from 'react'

import Image from '@components/UI/Image'
import PlaceholderImage from '@images/placeholder-image.png'
import { IPorfolioObject } from '@store/creator-bio'
import { FadeIn } from '@tribegroup'

const CreatorPortfolioGalleryImage: React.FC<{ portfolioObject: IPorfolioObject }> = ({
  portfolioObject,
}) => {
  const [src, setSrc] = useState(portfolioObject.original_url)
  const [hasError, setHasError] = useState(false)

  const handleOnError = () => {
    setSrc(PlaceholderImage)
    setHasError(true)
  }
  return (
    <Image
      src={src}
      animation={FadeIn}
      contain
      fitToContainer
      onError={handleOnError}
      containerHeight={hasError ? 14 : undefined}
    />
  )
}

export default CreatorPortfolioGalleryImage
