import { Fragment } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectMediaObjectLicenseOptions } from '@store/licenses'
import { selectMediaObjectType } from '@store/mediaObjects'
import LicenseOption from './Option'

interface IProps {
  mediaObjectId: number
  onLicenseChange: (licenseIndex: number) => void
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

export const LicensingLicenseOptions = ({
  licenseOptions,
  mediaObjectId,
  onLicenseChange,
  mediaObjectType,
}: IInternalProps) => {
  return (
    <Fragment>
      {...Object.values(licenseOptions).map((licenseOption, licenseIndex) => {
        if (licenseOption.media_type !== mediaObjectType) {
          return null
        }
        return (
          <LicenseOption
            key={licenseIndex}
            mediaObjectId={mediaObjectId}
            licenseIndex={licenseIndex}
            onLicenseChange={onLicenseChange}
          />
        )
      })}
    </Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { mediaObjectId }: IProps) => ({
  licenseOptions: selectMediaObjectLicenseOptions(state, mediaObjectId),
  mediaObjectType: selectMediaObjectType(state, mediaObjectId),
})

const connector = connect(mapStateToProps)
export default compose<IInternalProps, IProps>(connector)(LicensingLicenseOptions)
