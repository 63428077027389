import styled from 'styled-components'

export const ConfirmationTitle = styled.div`
  margin-bottom: 0.5rem;
`

export const ConfirmationContent = styled.div`
  padding-bottom: 3.125rem;
  margin-bottom: 0.5rem;
`

export const FooterButtonWrapper = styled.div`
  margin-left: 0.5rem;
`
