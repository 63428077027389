import { SyncDraftActionTypes } from '@store/drafts'

export const syncDraft = (campaignId: number) => ({
  payload: campaignId,
  type: SyncDraftActionTypes.SYNC_DRAFT_REQUEST,
})

export const syncDraftSuccess = (draft: any) => ({
  payload: draft,
  type: SyncDraftActionTypes.SYNC_DRAFT_SUCCESS,
})

export const syncDraftError = (error: any) => ({
  payload: error,
  type: SyncDraftActionTypes.SYNC_DRAFT_ERROR,
})
