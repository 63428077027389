import { IResponseError } from '@lib/error'
import { ViewInfluencerSocialActionTypes } from '@store/submissions'

export const viewSocial = (campaignId: number, submissionId: number) => ({
  payload: { campaignId, submissionId },
  type: ViewInfluencerSocialActionTypes.VIEW_SOCIAL_REQUEST,
})

export const viewSocialError = (error: IResponseError) => ({
  payload: error,
  type: ViewInfluencerSocialActionTypes.VIEW_SOCIAL_ERROR,
})
