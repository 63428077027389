import * as yup from 'yup'

import { DEFAULT_TENANT } from '@constants'
import { IResponseError } from '@lib/error'
import { IHateoasLink } from '@store'
import getConfig from '@utils/getConfig'

export enum AuthActionTypes {
  AUTH_SUCCESS = 'auth/AUTH_SUCCESS',
  AUTH_ERROR = 'auth/AUTH_ERROR',
  EMAIL_VERIFICATION_REQUEST = 'auth/EMAIL_VERIFICATION_REQUEST',
  EMAIL_VERIFICATION_SUCCESS = 'auth/EMAIL_VERIFICATION_SUCCESS',
  REFRESH_REQUEST = 'token/REFRESH_REQUEST',
  REFRESH_CANCEL = 'token/REFRESH_CANCEL',
  REFRESH_SUCCESS = 'token/REFRESH_SUCCESS',
  SIGNIN_REQUEST = 'auth/SIGNIN_REQUEST',
  SIGNUP_REQUEST = 'auth/SIGNUP_REQUEST',
  SIGNOUT_REQUEST = 'auth/SIGNOUT_REQUEST',
  SIGNOUT_SUCCESS = 'auth/SIGNOUT_SUCCESS',
  CLEAR_ERRORS = 'auth/CLEAR_ERRORS',
  RESEND_VERIFICATION_REQUEST = 'auth/RESEND_VERIFICATION_REQUEST',
  RESEND_VERIFICATION_SUCCESS = 'auth/RESEND_VERIFICATION_SUCCESS',
  RESEND_VERIFICATION_ERROR = 'auth/RESEND_VERIFICATION_ERROR',
  SAVE_SIGNUP_FORM = 'auth/SAVE_SIGNUP_FORM',
  COMPLETE_SIGNUP_REQUEST = 'auth/COMPLETE_SIGNUP_REQUEST',
  COMPLETE_SIGNUP_SUCCESS = 'auth/COMPLETE_SIGNUP_SUCCESS',
}

export enum AutoSignInActionTypes {
  AUTO_SIGNIN_REQUEST = 'auth/AUTO_SIGNIN_REQUEST',
  AUTO_SIGNIN_SUCCESS = 'auth/AUTO_SIGNIN_SUCCESS',
  AUTO_SIGNIN_ERROR = 'auth/AUTO_SIGNIN_ERROR',
}

export enum IPActionTypes {
  CHECK_IP_REQUEST = 'auth/CHECK_IP_REQUEST',
  CHECK_IP_SUCCESS = 'auth/CHECK_IP_SUCCESS',
  CHECK_IP_ERROR = 'auth/CHECK_IP_ERROR',
}

export type SignUpData = { user_id: number } & SignUpFormData

export interface IAuthState {
  readonly isAuthenticating: boolean
  readonly isRequesting: boolean
  readonly access_token: string
  readonly duration: number
  readonly expiration: number
  readonly requesterCountryCode: string
  readonly links: ReadonlyArray<IHateoasLink>
  readonly errors?: IResponseError
  readonly signupData?: SignUpData
}

const companyNameYupSchema = () => {
  let baseSchema = yup.string()

  if (process.env.APP_NAME === DEFAULT_TENANT) {
    baseSchema = baseSchema.required('auth.signup.error.companyName.required')
  }

  return baseSchema.trim()
}

export const signUpSchema = yup.object({
  first_name: yup.string().required('auth.signup.error.firstName.required').trim(),
  last_name: yup.string().required('auth.signup.error.lastName.required').trim(),
  company_name: companyNameYupSchema(),
  country_code: yup.string().required().trim(),
  email: yup
    .string()
    .email('auth.signup.error.email.required')
    .required('auth.signup.error.email.required')
    .trim(),
  password: yup
    .string()
    .required('auth.signup.error.password.required')
    .min(8, 'auth.signup.error.password.min'),
  terms_and_conditions: yup.boolean().test({
    test: Boolean,
    message: 'auth.signup.error.terms.checked',
  }),
  hubspotutk: yup.string().optional(),
  marketing_newsletters: yup.boolean(),
  referrer_token: yup.string().optional(),
  phone_number: yup.string().optional(),
  google_client_id: yup.string().optional(),
  google_click_id: yup.string().optional(),
  bypass_recaptcha_token: yup.string().optional(),
  recaptcha_token: yup.string().when('bypass_recaptcha_token', {
    is: (val) => Boolean(val) || !getConfig('recaptchaEnabled'),
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required('auth.signup.error.recaptcha.required'),
  }),
})

export type SignUpFormData = yup.InferType<typeof signUpSchema>

export interface ISavedSignUpForm extends SignUpFormData {
  user_id: number
}

export interface IOnboarding {
  estimated_campaign_creation_count: string
  company_size: string
  campaign_creation_reason: string
  product_category: number
  company_type: string
}
