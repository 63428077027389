import React from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Flex, { FlexItem } from 'styled-flex-component'

import StripeError from '@components/UI/Error/StripeError'
import RouterLink from '@components/UI/RouterLink/RouterLink.styled'
import { PageRoutes } from '@enums'
import { IResponseErrorMessage } from '@lib/error'
import { IApplicationState } from '@store'
import { selectCreditCardLastFourDigits, selectHasCreditCardInfo } from '@store/me'
import { selectMediaObjectErrors } from '@store/mediaObjects'
import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import { CreditManagementPaymentMethodCreditCardEmpty } from './Empty'

const isResponseErrorMessage = (
  errorMessage: string | IResponseErrorMessage | undefined,
): errorMessage is IResponseErrorMessage => {
  return typeof errorMessage === 'object' && 'error_code' in errorMessage
}

export const CreditManagementPaymentMethodCreditCard = () => {
  const location = useLocation()
  const fromRoute = `?from=${encodeURIComponent(`${location.pathname}${location.search}`)}`

  const { hasCreditCard, creditCardLastDigits, errors } = useSelector(
    (state: IApplicationState) => {
      return {
        hasCreditCard: selectHasCreditCardInfo(state),
        creditCardLastDigits: selectCreditCardLastFourDigits(state),
        errors: selectMediaObjectErrors(state),
      }
    },
  )

  const hasErrors = Boolean(errors)

  if (!hasCreditCard) {
    return <CreditManagementPaymentMethodCreditCardEmpty />
  }

  return (
    <Flex full column>
      <FlexItem grow={1}>
        <Container fullWidth topOuterSpacing={2}>
          <Container
            backgroundColor={Theme.primary100Color}
            height={2}
            width={2}
            borderRadius="50%"
            inlineFlex
            justifyContent="center"
            alignItems="center"
            rightOuterSpacing={0.75}
            verticalAlign="middle"
          >
            <Icon
              verticalAlign="middle"
              color={Theme.primaryColor}
              glyph="approved"
              className="heavy-tick-icon"
            />
          </Container>
          <Container inlineBlock rightOuterSpacing={0.5}>
            <Text color={Theme.grey900Color}>
              <FormattedMessage
                id="licensing.creditCard.updateMessage"
                values={{
                  lastFourDigits: creditCardLastDigits,
                  updateLink: (
                    <Container inline leftInnerSpacing={0.5}>
                      <RouterLink to={`/${PageRoutes.Account}${fromRoute}`}>
                        <Text color={Theme.primaryColor}>
                          <FormattedMessage id="licensing.creditCard.updateLink" />
                        </Text>
                      </RouterLink>
                    </Container>
                  ),
                }}
              />
            </Text>
          </Container>
        </Container>
      </FlexItem>

      <Container bottomOuterSpacing={1.5}>
        {hasErrors && (
          <Container bottomOuterSpacing={1.5}>
            <Container
              height={2}
              width={2}
              backgroundColor={Theme.error100Color}
              flexCenter
              borderRadius="50%"
              bottomOuterSpacing={0.75}
            >
              <Text color={Theme.errorColor} customFontSize={1.25}>
                <FormattedMessage id="contentLibrary.licensing.creditManagement.hasError.icon" />
              </Text>
            </Container>
            <Container bottomOuterSpacing={0.5} width={21.25}>
              {errors?.messages && typeof errors?.messages === 'string' && (
                <Text small color={Theme.errorColor}>
                  {errors?.messages}
                </Text>
              )}
              {errors?.messages && isResponseErrorMessage(errors?.messages[0]) && (
                <StripeError errorCode={errors?.messages[0].error_code} />
              )}
              {errors?.messages && !isResponseErrorMessage(errors?.messages[0]) && (
                <Text color={Theme.errorColor} small>
                  <FormattedMessage id="core.text.shortGenericError" />
                </Text>
              )}
            </Container>
          </Container>
        )}
        <Container flexStart>
          <Text small color={Theme.grey700Color}>
            <FormattedMessage id="socialSubmission.card.modal.license.ccPreAuth" />
          </Text>
        </Container>
      </Container>
    </Flex>
  )
}
