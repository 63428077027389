import { call, select } from 'redux-saga/effects'

import { IResponseErrorMessage } from '@lib/error'
import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import { ILicense3DSResponse } from '@store/licenses'
import { IApproveRequestResponse } from '@store/submissions'
import { networkRequest, selectLink } from '@utils'

export function* handlePaymentAuthFailure(
  response: IApproveRequestResponse | ILicense3DSResponse,
  error: IResponseErrorMessage,
) {
  const authToken: string = yield select(selectAuthToken)
  const abandon3dsLink: IHateoasLink = selectLink(response.links, 'abandon3ds')!
  if (abandon3dsLink) {
    yield call(
      networkRequest,
      abandon3dsLink.method,
      abandon3dsLink.href,
      { payment_intent_id: response.payment_intent_id },
      authToken,
    )
  }
  return {
    messages: [error],
  }
}
