import ContentLoader from 'react-content-loader'

import Theme from '@theme'
import { LoadingListWrapper, LoadingProfileWrapper, LoadingWrapper } from './Loading.styled'

const ProfilePlaceholder = () => (
  <LoadingProfileWrapper data-testid="chat-loading">
    <ContentLoader
      width={332}
      height={176}
      speed={1.5}
      foregroundColor={Theme.grey400Color}
      backgroundColor={Theme.grey200Color}
    >
      <circle cx="169" cy="57" r="30" />
      <rect x="126" y="98" rx="3" ry="3" width="85" height="12" />
      <rect x="68" y="117" rx="3" ry="3" width="201" height="10" />
      <rect x="111" y="137" rx="4" ry="4" width="117" height="10" />
    </ContentLoader>
  </LoadingProfileWrapper>
)

const LoadingListPlaceholder = () => (
  <LoadingListWrapper>
    <ContentLoader
      width={332}
      height={116}
      speed={1.5}
      foregroundColor={Theme.grey400Color}
      backgroundColor={Theme.grey200Color}
    >
      <rect x="115" y="28" rx="4" ry="4" width="117" height="6.4" />
      <path d="M288,100 h-200 a20,20 0 0 1 -20,-20 v-40 a20,20 0 0 1 20,-20 h200 a20,20 0 0 1 20,20 v60 z" />
      <rect x="258" y="110" rx="3" ry="3" width="50" height="6.4" />
    </ContentLoader>
    <ContentLoader
      width={332}
      height={116}
      speed={1.5}
      foregroundColor={Theme.grey400Color}
      backgroundColor={Theme.grey200Color}
    >
      <rect x="115" y="28" rx="4" ry="4" width="117" height="6.4" />
      <path d="M44,50 h200 a20,20 0 0 1 20,20 v10 a20,20 0 0 1 -20,20 h-220 v-30 a20,20 0 0 1 20,-20 z" />
      <rect x="24" y="110" rx="3" ry="3" width="30" height="6.4" />
    </ContentLoader>
    <ContentLoader
      width={332}
      height={116}
      speed={1.5}
      foregroundColor={Theme.grey400Color}
      backgroundColor={Theme.grey200Color}
    >
      <rect x="115" y="28" rx="4" ry="4" width="117" height="6.4" />
      <path d="M288,100 h-150 a20,20 0 0 1 -20,-20 v-10 a20,20 0 0 1 20,-20 h150 a20,20 0 0 1 20,20 v30 z" />
      <rect x="268" y="110" rx="3" ry="3" width="40" height="6.4" />
    </ContentLoader>
    <ContentLoader
      width={332}
      height={176}
      speed={1.5}
      foregroundColor={Theme.grey400Color}
      backgroundColor={Theme.grey200Color}
    >
      <rect x="115" y="28" rx="4" ry="4" width="117" height="6.4" />
      <path d="M44,50 h200 a20,20 0 0 1 20,20 v50 a20,20 0 0 1 -20,20 h-220 v-70 a20,20 0 0 1 20,-20 z" />
      <rect x="24" y="150" rx="3" ry="3" width="38" height="6.4" />
    </ContentLoader>
  </LoadingListWrapper>
)

const SubmissionFeedbackLoading = () => (
  <LoadingWrapper>
    <ProfilePlaceholder />
    <LoadingListPlaceholder />
  </LoadingWrapper>
)

export default SubmissionFeedbackLoading
