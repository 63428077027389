import { Fragment } from 'react'

import { MultiframeNext, MultiframePrevious } from '@components/UI/Multiframe'

export const SubmissionCardMediaMultiframeNavigation = () => {
  return (
    <Fragment>
      <MultiframePrevious />
      <MultiframeNext />
    </Fragment>
  )
}

export default SubmissionCardMediaMultiframeNavigation
