import { Component } from 'react'

import { endOfDay } from 'date-fns'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IModalComponentProps } from '@context/Modal'
import { PublishOptions } from '@enums'
import { IMeInfoProps, withAnalyticsTracking, withMeInfo } from '@hocs'
import { IApplicationState } from '@store'
import { ISubmission, reschedule, selectErrors, selectIsRescheduling } from '@store/submissions'
import { clearErrors } from '@store/submissions/actions'
import { Modal } from '@tribegroup'
import SubmissionCardModalReschedule from './Reschedule'

const MIN_PUBLISHABLE_HOURS = 48

interface IOption {
  label: string
  value: string
}

interface ISubmissionCardModalRescheduleProps extends IModalComponentProps {
  campaignId: number
  submission: ISubmission
  currFilter?: string
  onRequestClose: VoidFunction
}

interface IInternalProps
  extends ISubmissionCardModalRescheduleProps,
    IMeInfoProps,
    InjectedIntlProps {
  reschedule: typeof reschedule
  isRescheduling: boolean
}

interface ISubmissionCardModalRescheduleState {
  selectedOptionValue?: string | number
  selectedDateValue?: Date
  showDatePicker: boolean
}

export class SubmissionCardModalRescheduleWrapper extends Component<
  IInternalProps,
  ISubmissionCardModalRescheduleState
> {
  state = {
    selectedOptionValue: undefined,
    selectedDateValue: undefined,
    showDatePicker: false,
  }

  componentDidUpdate(prevProps: IInternalProps) {
    if (prevProps.isRescheduling !== this.props.isRescheduling) {
      this.onCancel()
    }
  }

  onCancel = () => {
    this.setState(
      {
        selectedOptionValue: undefined,
        selectedDateValue: undefined,
        showDatePicker: false,
      },
      this.props.onRequestClose,
    )
  }

  onChangePublishingOption = (option: IOption) => {
    this.setState({
      selectedOptionValue: option.value,
      showDatePicker: option.value === PublishOptions.CHOOSE_A_DATE,
    })
  }

  onChangeDateValue = (date: Date) => {
    this.setState({
      selectedDateValue: endOfDay(date),
      showDatePicker: false,
    })
  }

  onRescheduleSubmission = () => {
    const { selectedDateValue, selectedOptionValue } = this.state
    const { submission, campaignId } = this.props

    const publishWithin = { publish_within_hours: MIN_PUBLISHABLE_HOURS }

    const publishWithDate = Boolean(selectedDateValue) && {
      scheduled_publish_date: endOfDay(selectedDateValue as any).toISOString(),
    }

    const requestBody =
      selectedOptionValue === PublishOptions.WITHIN_48_HOURS ? publishWithin : publishWithDate

    if (requestBody) {
      this.props.reschedule(submission.id, campaignId, requestBody)
    }
  }

  render() {
    const { isOpen = false, submission } = this.props
    return (
      <Modal reactModalProps={{ isOpen, onRequestClose: this.onCancel }} width={27.5} complex>
        <SubmissionCardModalReschedule
          submission={submission}
          onCloseModal={this.onCancel}
          onChangePublishingOption={this.onChangePublishingOption}
          onChangeDateValue={this.onChangeDateValue}
          onRescheduleSubmission={this.onRescheduleSubmission}
          {...this.state}
        />
      </Modal>
    )
  }
}

const mapDispatchToProps = {
  reschedule,
  clearErrors,
}

const mapStateToProps = (state: IApplicationState) => ({
  isRescheduling: selectIsRescheduling(state),
  error: selectErrors(state),
})

export default compose<IInternalProps, ISubmissionCardModalRescheduleProps>(
  withMeInfo,
  injectIntl,
  withAnalyticsTracking,
  connect(mapStateToProps, mapDispatchToProps),
)(SubmissionCardModalRescheduleWrapper)
