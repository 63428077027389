import styled from 'styled-components'

import { Spin } from '@lib/effects'

export const LoadingWrapper = styled.div`
  margin: 2rem 0;
`

export default styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  ${({ height }) => `height: ${height}rem`};
  ${({ fullHeight }) => fullHeight && `height: 100%`};
  ${({ leftAligned }) => leftAligned && `justify-content: flex-start`};
  ${({ yPadding = 1.875 }) =>
    yPadding &&
    `

  padding-top: ${yPadding}rem;
  padding-bottom: ${yPadding}rem;
  `}
  > span {
    margin-left: 0.5rem;
  }

  .spinner {
    animation: ${Spin} 2.8s linear infinite;
    transform-origin: 50% 50%;
    display: inline-block;
  }
`
