import { useState } from 'react'

interface IUseToggleResult {
  toggleIsVisible: boolean
  toggleHide: VoidFunction
  toggleShow: VoidFunction
  toggle: VoidFunction
}

const useToggle = (defaultValue = false) => {
  const [toggleIsVisible, setToggleIsVisible] = useState(defaultValue)
  const toggleHide = () => {
    setToggleIsVisible(false)
  }
  const toggleShow = () => {
    setToggleIsVisible(true)
  }
  const toggle = () => {
    setToggleIsVisible(!toggleIsVisible)
  }
  const result: IUseToggleResult = {
    toggleIsVisible,
    toggleHide,
    toggleShow,
    toggle,
  }
  return result
}

export { useToggle }
