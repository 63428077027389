import { call, put, select, takeEvery } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  cancelMoodboardMediaUpload,
  deleteMoodboardMedia,
  deleteMoodboardMediaError,
  deleteMoodboardMediaSuccess,
  MoodboardMediaDeleteActionTypes,
  selectDeleteLink,
  selectMediaId,
} from '@store/moodboard'
import networkRequest from '@utils/networkRequest'

export function* handleDeleteMoodboardMedia(action: ReturnType<typeof deleteMoodboardMedia>) {
  const { campaignId, position } = action.payload

  try {
    const authToken = yield select(selectAuthToken)

    const deleteMediaLink: IHateoasLink = yield select(selectDeleteLink, campaignId, position)

    const mediaId = yield select(selectMediaId, campaignId, position)

    yield put(cancelMoodboardMediaUpload(campaignId, mediaId))

    yield call(networkRequest, deleteMediaLink.method, deleteMediaLink.href, {}, authToken)

    yield put(deleteMoodboardMediaSuccess(campaignId, mediaId))
  } catch (error) {
    yield put(deleteMoodboardMediaError(campaignId, position))
  }
}

export function* watchMooodboardMediaDelete() {
  yield takeEvery(MoodboardMediaDeleteActionTypes.DELETE_REQUEST, handleDeleteMoodboardMedia)
}
