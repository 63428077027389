import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

import InformationContent from '@components/UI/Information/Content/Content'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { BRAND_MATCH_EDUCATION } from '@constants'
import { EventTrackingNames } from '@enums'
import Theme from '@theme'
import { Container, Link, Text } from '@tribegroup'

interface IProps {
  submissionId: number
  campaignId: number
}

const SubmissionCardHeaderBrandMatchContent = ({ submissionId, campaignId }: IProps) => {
  return (
    <InformationContent
      title={<FormattedMessage id="core.text.brandMatchPct" />}
      content={
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.BrandMatchLearnMore}
          submissionId={submissionId}
          campaignId={campaignId}
          eventTrigger="click"
        >
          <Container bottomOuterSpacing={0.5}>
            <FormattedHTMLMessage id="submission.card.action.header.brandMatchPct.tooltip.first" />
          </Container>
          <FormattedHTMLMessage id="submission.card.action.header.brandMatchPct.tooltip.second" />

          <Container topOuterSpacing={0.75}>
            <Link href={BRAND_MATCH_EDUCATION} target="_blank" color={Theme.secondaryColor}>
              <Text color={Theme.primaryColor}>
                <FormattedMessage id="core.text.learnMore.withArrow" />
              </Text>
            </Link>
          </Container>
        </ConnectedTrackedRoute>
      }
    />
  )
}

export default SubmissionCardHeaderBrandMatchContent
