import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import AnalyticsDetailCard from '@components/Analytics/DetailCard/DetailCard'
import { Hr } from '@components/UI/Hr/Hr.styled'
import { AnalyticsCustomPrice } from '@components/UI/Price/Price'
import { CPM_EXPLAINED, TRIBE_PRICING_PAGE_PRE_PURCHASE_METRICS_UTM } from '@constants'
import { IApplicationState } from '@store'
import { selectPrePurchaseAnalyticsPost } from '@store/prePurchaseAnalytics'
import { selectSubmissionId } from '@store/router'
import { selectSubmission, selectSubTotalCurrency } from '@store/submissions'
import Theme from '@theme'
import { Container, Link } from '@tribegroup'
import { abbreviateNumberOrDash } from '@utils'
import { isNumber } from '../PrePurchase'
import { AnalyticsPrePurchaseGrid } from '../PrePurchase.styled'

const externalLink = TRIBE_PRICING_PAGE_PRE_PURCHASE_METRICS_UTM

const AnalyticsPrePurchasePost = () => {
  const match = useRouteMatch()
  const { followers, prePurchase, currency } = useSelector((state: IApplicationState) => {
    const submissionId = selectSubmissionId(match)!
    const { identity_followers_count, identity_id } = selectSubmission(state, submissionId)

    return {
      followers: identity_followers_count,
      currency: selectSubTotalCurrency(state, submissionId),
      prePurchase: selectPrePurchaseAnalyticsPost(state, identity_id),
    }
  })

  const estimatedCpe = prePurchase?.est_cpe
  const estimatedCpm = prePurchase?.est_cpm

  return (
    <>
      <AnalyticsPrePurchaseGrid>
        <AnalyticsDetailCard
          title="submission.prePurchaseAnalytics.post.avgEngagement"
          content={abbreviateNumberOrDash(prePurchase?.avg_engagement)}
          elementName="pre-purchase-avg-engagement"
          productMetricGroup="pre_purchase"
          productMetricValue="avg_engagement"
          externalLink={externalLink}
          featured
        />
        {estimatedCpe && (
          <AnalyticsDetailCard
            title="submission.prePurchaseAnalytics.post.estCPE"
            content={<AnalyticsCustomPrice currency={currency} quantity={estimatedCpe} />}
            elementName="pre-purchase-est-cpe"
            productMetricGroup="pre_purchase"
            productMetricValue="est_cpe"
            externalLink={externalLink}
            featured
          />
        )}

        <AnalyticsDetailCard
          title="submission.prePurchaseAnalytics.post.avgImpressions"
          content={abbreviateNumberOrDash(prePurchase?.avg_impressions)}
          elementName="pre-purchase-avg-impressions"
          productMetricGroup="pre_purchase"
          productMetricValue="avg_impressions"
          externalLink={externalLink}
          featured
        />
      </AnalyticsPrePurchaseGrid>
      <Hr backgroundColor={Theme.grey400Color} />
      <Container topOuterSpacing={1.75}>
        <AnalyticsPrePurchaseGrid>
          <AnalyticsDetailCard
            title="submission.prePurchaseAnalytics.followers"
            content={abbreviateNumberOrDash(followers)}
            elementName="pre-purchase-followers"
            productMetricGroup="pre_purchase"
            productMetricValue="followers"
            externalLink={externalLink}
          />

          <AnalyticsDetailCard
            title="submission.prePurchaseAnalytics.post.avgEngagementPercentage"
            content={
              isNumber(prePurchase?.avg_engagement_pct) && (
                <FormattedMessage
                  id="core.text.percent"
                  values={{ value: prePurchase?.avg_engagement_pct }}
                />
              )
            }
            elementName="pre-purchase-avg-engagement-percentage"
            productMetricGroup="pre_purchase"
            productMetricValue="avg_engagement_pct"
            externalLink={externalLink}
          />

          <AnalyticsDetailCard
            title="submission.prePurchaseAnalytics.post.avgReach"
            content={abbreviateNumberOrDash(prePurchase?.avg_reach)}
            elementName="pre-purchase-avg-reach"
            productMetricGroup="pre_purchase"
            productMetricValue="avg_reach"
            externalLink={externalLink}
          />
          <AnalyticsDetailCard
            title="submission.prePurchaseAnalytics.post.avgReachPercentage"
            content={
              isNumber(prePurchase?.avg_reach_pct) && (
                <FormattedMessage
                  id="core.text.percent"
                  values={{ value: prePurchase?.avg_reach_pct }}
                />
              )
            }
            elementName="pre-purchase-avg-reach-percentage"
            productMetricGroup="pre_purchase"
            productMetricValue="avg_reach_pct"
            externalLink={externalLink}
          />

          {estimatedCpm && (
            <AnalyticsDetailCard
              title="submission.prePurchaseAnalytics.post.estCPM"
              content={<AnalyticsCustomPrice currency={currency} quantity={estimatedCpm} />}
              elementName="pre-purchase-est-cpm"
              productMetricGroup="pre_purchase"
              productMetricValue="est_cpm"
              externalLink={externalLink}
              values={{
                link: (
                  <Link href={CPM_EXPLAINED} target="_blank" color={Theme.primaryColor} lowercase>
                    <FormattedMessage id="core.text.clickHere" />
                  </Link>
                ),
              }}
            />
          )}
        </AnalyticsPrePurchaseGrid>
      </Container>
    </>
  )
}

export { AnalyticsPrePurchasePost }
export default AnalyticsPrePurchasePost
