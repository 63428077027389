import { FormattedMessage } from 'react-intl'
import { FlexItem } from 'styled-flex-component'

import { useModal } from '@hooks/useModal'
import Theme from '@theme'
import { Button, Container, Icon, Text } from '@tribegroup'
import { ConnectedAccountsModalChooseAccount } from './Modal/ChooseAccount/ChooseAccount'
import { Root } from './ConnectedAccounts.styled'

const ConnectedAccountsHeaderAction = () => {
  const { showModal } = useModal()

  const onClick = () => {
    showModal(ConnectedAccountsModalChooseAccount)
  }

  return (
    <Root bottomOuterSpacing={2} data-testid="connected-accounts-header-actions">
      <Container flexStart gap={1}>
        <Icon size={1.875} glyph="social-instagram-colour" />
        <Icon size={1.875} glyph="social-tiktok-colour" />
      </Container>
      <FlexItem noShrink>
        <Button
          onClick={onClick}
          topInnerSpacing={0.5}
          bottomInnerSpacing={0.5}
          leftInnerSpacing={1}
          rightInnerSpacing={1}
          backgroundColor={Theme.primaryColor}
          borderRadius={1}
          borderStyle="none"
        >
          <Text small customletterSpacing="1.5px" color={Theme.whiteColor}>
            <FormattedMessage id="connectedAccounts.connectBusinessAccount" />
          </Text>
        </Button>
      </FlexItem>
    </Root>
  )
}

export { ConnectedAccountsHeaderAction }
