import { createAction } from '@reduxjs/toolkit'

import { IBrandFanMember } from '@store/brandFans'
import { FetchSubmissionBrandFansMembershipActionTypes } from '../types'

export const fetchBrandFansMembership = createAction(
  FetchSubmissionBrandFansMembershipActionTypes.REQUEST,
  (submissionId: number) => ({ payload: { submissionId } }),
)

export const fetchBrandFansMembershipSuccess = createAction(
  FetchSubmissionBrandFansMembershipActionTypes.SUCCESS,
  (submissionId: number, brandFanMembers: IBrandFanMember[]) => ({
    payload: { submissionId, brandFanMembers },
  }),
)

export const fetchBrandFansMembershipError = createAction(
  FetchSubmissionBrandFansMembershipActionTypes.ERROR,
  (error: any) => ({ payload: error }),
)
