import React from 'react'

import Theme from '@theme'
import { Radio, Scheme } from '@tribegroup'

interface IProps {
  value: string
  scheme?: string
  label: React.ReactNode
  defaultChecked: boolean
  labelColor?: string
  showSelectedAsCheck?: boolean
  disabled?: boolean
  onOptionChange: (selected: string) => void
}

const BuilderBudgetCreatorGroupOption: React.FC<IProps> = ({
  value,
  scheme = 'primary',
  defaultChecked,
  onOptionChange,
  label,
  labelColor,
  showSelectedAsCheck,
  disabled,
}) => {
  const onChange = () => onOptionChange(value)
  return (
    <Radio
      showSelectedAsCheck={showSelectedAsCheck}
      name="creator_group"
      scheme={scheme as Scheme}
      defaultChecked={defaultChecked}
      label={label}
      value={value}
      onChange={onChange}
      labelColor={labelColor}
      labelFontSize={1.125}
      disabled={disabled}
      disablePointerOnHover={disabled}
      clearBorder={showSelectedAsCheck}
      backgroundColor={showSelectedAsCheck ? Theme.primary100Color : undefined}
    />
  )
}

export { BuilderBudgetCreatorGroupOption }
export default BuilderBudgetCreatorGroupOption
