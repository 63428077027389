import { IMeta } from '@store'
import { IMe, UpdateContactActionTypes } from '@store/me'

export const updateContact = (params: Partial<IMe>, meta: IMeta = {}) => ({
  payload: params,
  meta,
  type: UpdateContactActionTypes.UPDATE_CONTACT_REQUEST,
})

export const updateContactError = (error: any) => ({
  type: UpdateContactActionTypes.UPDATE_CONTACT_ERROR,
  payload: error,
})
