/* eslint-disable react/display-name */
import { useContext } from 'react'

import { PostCampaignReportSubmissionIdContext } from '@context/PostCampaignReportSubmissionId'

const withContextPostCampaignReportSubmissionId = (Component) => (props) => {
  const context = useContext(PostCampaignReportSubmissionIdContext)
  return <Component {...context} {...props} />
}

export { withContextPostCampaignReportSubmissionId }
