import { createAction } from '@reduxjs/toolkit'

import {
  ContentLibraryBillingActionTypes,
  ContentLibraryBillingUsingNextActionTypes,
} from '../types'

export const fetchContentLibraryBillings = createAction(
  ContentLibraryBillingActionTypes.FETCH_REQUEST,
  (isFetchNext?: boolean) => ({
    payload: isFetchNext ?? false,
  }),
)

export const fetchContentLibraryBillingsSuccess = createAction<any>(
  ContentLibraryBillingActionTypes.FETCH_SUCCESS,
)

export const fetchContentLibraryBillingsError = createAction<any>(
  ContentLibraryBillingActionTypes.FETCH_ERROR,
)

export const fetchContentLibraryBillingsNext = createAction(
  ContentLibraryBillingUsingNextActionTypes.FETCH_REQUEST,
)

export const fetchContentLibraryBillingsNextSuccess = createAction<any>(
  ContentLibraryBillingUsingNextActionTypes.FETCH_SUCCESS,
)

export const fetchContentLibraryBillingsNextError = createAction<any>(
  ContentLibraryBillingUsingNextActionTypes.FETCH_ERROR,
)
