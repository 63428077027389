import React, { PropsWithChildren } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import Loading from '@components/UI/Loading'
import { NoticeEmpty } from '@components/UI/Notice'
import { BillingTypes } from '@enums'
import { IApplicationState } from '@store'
import { selectBillingType } from '@store/router'

export interface IPurchaseWrapperProps {
  hasPurchases: boolean
  enableFetch: boolean
  fetchList: VoidFunction
  isLoading: boolean
}

interface IInternalProps extends IPurchaseWrapperProps {
  billingType: BillingTypes
}

const PurchaseWrapper: React.FC<PropsWithChildren<IInternalProps>> = ({
  children,
  fetchList,
  isLoading,
  enableFetch,
  hasPurchases,
  billingType,
}) => {
  React.useEffect(() => {
    if (enableFetch) {
      fetchList()
    }
  }, [])

  if (!hasPurchases && !isLoading) {
    return (
      <NoticeEmpty
        alt="purchases"
        title={<FormattedMessage id="billing.empty.title" />}
        subtitle={<FormattedMessage id={`billing.empty.${billingType}.subtitle`} />}
      />
    )
  }

  if (isLoading) {
    return <Loading />
  }

  return <React.Fragment>{children}</React.Fragment>
}

const mapStateToProps = (state: IApplicationState, { location }: RouteComponentProps) => ({
  billingType: selectBillingType(location),
})

export { PurchaseWrapper }
export default compose<IInternalProps, PropsWithChildren<IPurchaseWrapperProps>>(
  withRouter,
  connect(mapStateToProps),
)(PurchaseWrapper)
