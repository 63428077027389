import React from 'react'

import { FormattedMessage } from 'react-intl'

import { TermsLink } from '@components/Licensing'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

interface IProps {
  isLicensed?: boolean
  color?: string
}

export const LicensingAgreementsLink: React.FC<IProps> = ({ isLicensed, color }) => (
  <Container textAlign="center">
    <Container>
      <Text small color={Theme.grey800Color}>
        <FormattedMessage
          id={
            isLicensed
              ? 'license.text.tribeContentLicenseAgreement.text.licensed'
              : 'license.text.tribeContentLicenseAgreement.text.licenseRequest'
          }
        />
      </Text>
    </Container>
    <TermsLink color={color}>
      <FormattedMessage id="license.text.tribeContentLicenseAgreement" />
    </TermsLink>
  </Container>
)

export default LicensingAgreementsLink
