import { IFeatureLockedValue } from '@store/postCampaignReport'
import { getFeatureLockedValue } from './featureCheck'

export const getCPE = (amountInCents: number, engagement: number) => {
  return amountInCents / 100 / engagement
}

export const getCostPerMetric = (
  metric: number | IFeatureLockedValue<number> | undefined,
  amountInCents: number | undefined,
  fn: Function = (metric, amount) => amount / metric,
) => {
  let derivedMetric = metric

  if (typeof metric === 'object') {
    derivedMetric = getFeatureLockedValue(metric) as number
  }

  if (!derivedMetric || !amountInCents) {
    return undefined
  }

  const amount = amountInCents / 100

  return fn(derivedMetric, amount)
}
