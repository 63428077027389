import React from 'react'

import { Link, useParams } from 'react-router-dom'

import { HeaderBackButton } from '@components/UI/TitleBar'
import { PageRoutes } from '@enums'
import Theme from '@theme'

interface IParams {
  token: string
}

const BackButton: React.FC = () => {
  const { token } = useParams<IParams>()
  const backLink = `/${PageRoutes.ShareableContentLibrary}/${token}`
  return (
    <Link to={backLink}>
      <HeaderBackButton
        glyph="nav-back-default"
        size={1.5}
        color={Theme.grey900Color}
        data-cy-element="header-back-button"
      />
    </Link>
  )
}

export default BackButton
