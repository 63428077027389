import { call, put, takeLatest } from 'redux-saga/effects'

import { fetchError, fetchSuccess, RootResourceActionTypes } from '@store/rootResource'
import { networkRequest } from '@utils'

export function* handleFetch() {
  try {
    const result = yield call(networkRequest, 'GET', `${process.env.API_HOST}/brandapi/v2/root`)
    yield put(fetchSuccess(result))
  } catch (error) {
    yield put(fetchError(error))
  }
}

export function* watchFetchRequest() {
  yield takeLatest(RootResourceActionTypes.FETCH_REQUEST, handleFetch)
}
