import React from 'react'
import { ComponentType } from 'react'

import { getClosestParent } from '../utils'
import { IValidationResult, validateRule } from './ValidationRules'

export interface IWithFormValidationBaseProps extends IWithFormValidation {
  /**
   * Validate the entire form
   */
  validate: () => boolean

  /**
   * Clear a validation field by passing either an event or a name of the string
   */
  clearFieldValidation: (eventorName: React.FormEvent<HTMLInputElement> | string) => void
}

export interface IWithFormValidationProps extends IWithFormValidationBaseProps {
  /**
   * Declare a form reference to apply validations
   */
  setForm: (form: React.Ref<HTMLFormElement>) => void

  /**
   * Validate a field by passing the form event
   */
  validateField: (event: React.FormEvent<HTMLInputElement>) => void

  /**
   * Validate a field by passing the input name and vaue
   */
  validateFieldByNameAndValue: (name: string, value: string | number) => void
}

export interface IWithFormValidation extends React.FormHTMLAttributes<HTMLFormElement> {
  validationResults: Record<string, IValidationResult>
  validationRules?: any
}

const getInputValue = (formElement, key) => {
  const formInput = formElement.querySelector(`[name='${key}']`) as HTMLInputElement
  if (formInput.type === 'checkbox') {
    return formInput.checked ? 'on' : ''
  }
  if (formInput.type === 'radio') {
    const selected = [...formElement.querySelectorAll(`[name='${key}']`)].some(
      (el: HTMLInputElement) => el.checked,
    )
    return selected ? 'on' : ''
  }
  return formInput.value
}

export const withFormValidation = (FormComponent: ComponentType<IWithFormValidationProps>) => {
  return class Component extends React.PureComponent<
    React.FormHTMLAttributes<HTMLFormElement>,
    IWithFormValidation
  > {
    state = {
      validationResults: {},
    }

    formRef: HTMLFormElement

    setForm = (form: React.RefObject<HTMLFormElement>) => {
      if (form && form.current) {
        // tslint:disable-next-line:no-object-mutation
        this.formRef = form.current
      }
    }

    getValidationRules = () => {
      return Array.from(this.formRef.querySelectorAll('.formgroup-input [name]'))
        .map((field: any) => {
          const ruleString = getClosestParent(field, '.formgroup-input').getAttribute(
            'data-validation',
          )
          return {
            [field.name]: ruleString ? JSON.parse(ruleString) : {},
          }
        })
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})
    }

    clearFieldValidation = (eventOrName: React.FormEvent<HTMLInputElement> | string) => {
      const name = typeof eventOrName === 'string' ? eventOrName : eventOrName.currentTarget.name
      const validationResults = Object.keys(this.state.validationResults)
        .map((key) =>
          key !== name
            ? { [key]: this.state.validationResults[key] }
            : {
                [key]: {
                  ...this.state.validationResults[key],
                  hasError: false,
                },
              },
        )
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})
      this.setState({
        validationResults,
      })
    }

    validateFieldByNameAndValue = (name: string, value: string) => {
      const validationRules = this.getValidationRules()
      if (validationRules) {
        const rule = validationRules[name]
        const result = { value, ...validateRule(value, rule) }
        this.setState({
          validationResults: {
            ...this.state.validationResults,
            [name]: result,
          },
        })
      }
    }

    validateField = (event: React.FormEvent<HTMLInputElement>) => {
      this.validateFieldByNameAndValue(event.currentTarget.name, event.currentTarget.value)
    }

    validate = () => {
      const validationRules = this.getValidationRules()
      const formElement = this.formRef
      if (validationRules) {
        const validationResults = Object.keys(validationRules)
          .map((key) => {
            const rule = validationRules[key]
            const input = formElement.querySelector(`[name='${key}']`) as HTMLInputElement
            const value = getInputValue(formElement, key)
            const prefix = input.getAttribute('data-prefix') || ''
            const validationResult = !input.hasAttribute('disabled') && {
              [key]: { value, ...validateRule(value, rule, prefix) },
            }
            return validationResult
          })
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}) as Record<string, IValidationResult>

        const failedValidation: any = Object.keys(validationResults).find((key: string) => {
          // eslint-disable-next-line no-prototype-builtins
          return validationResults.hasOwnProperty(key) && validationResults[key].hasError
        })

        const failedInput = formElement.querySelector(
          `[name='${failedValidation}']`,
        ) as HTMLInputElement
        if (failedInput) {
          if (failedInput.type === 'hidden') {
            const dataFocus = failedInput.getAttribute('data-focus') as string
            const inputId = `#${failedInput.getAttribute('name')}`
            if (dataFocus || inputId) {
              const failedInputDataFocus = document.querySelector(
                dataFocus || inputId,
              ) as HTMLElement
              if (failedInputDataFocus && failedInputDataFocus.focus) {
                failedInputDataFocus.focus()
              }
            }
          } else {
            failedInput.focus()
          }
        }

        this.setState({
          validationResults,
        })
        return typeof failedValidation === 'undefined'
      } else {
        return false
      }
    }

    render() {
      return (
        <FormComponent
          {...this.props}
          validationResults={this.state.validationResults}
          setForm={this.setForm}
          validate={this.validate}
          validateField={this.validateField}
          validateFieldByNameAndValue={this.validateFieldByNameAndValue}
          clearFieldValidation={this.clearFieldValidation}
        />
      )
    }
  }
}
