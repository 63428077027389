import { PanelWrapper } from '@components/Builder/Layout/Layout.styled'
import { IDraftContextProps } from '@context/Draft'
import { withDraft } from '@hocs'
import { Panel } from '@tribegroup'
import { isSocialCampaign } from '@utils/campaign'
import BuilderBudgetTargetDemographicLocation from './Location'
import BuilderBudgetTargetDemographicSocial from './Social'

const BuilderBudgetTargetDemographic: React.FC<IDraftContextProps> = ({ draft }) => {
  const isSocial = isSocialCampaign(draft)
  return (
    <PanelWrapper data-testid="builder-budget-target-demographic">
      <Panel>
        {isSocial ? (
          <BuilderBudgetTargetDemographicSocial />
        ) : (
          <BuilderBudgetTargetDemographicLocation />
        )}
      </Panel>
    </PanelWrapper>
  )
}

export default withDraft(BuilderBudgetTargetDemographic)
