import { useRef, useState } from 'react'

import {
  ITooltip as ReactTooltipProps,
  Tooltip as ReactTooltip,
  TooltipRefProps,
} from 'react-tooltip'
import styled, { css } from 'styled-components'

import { themed } from '../Theme'

export interface ITooltipProps {
  hideAfterXMilliseconds?: number
}

export const StyledReactTooltip = themed(styled<TooltipRefProps>(ReactTooltip)`
  ${({ theme }) => css`
    &.__react_component_tooltip {
      background: ${theme.whiteColor};
      color: ${theme.grey900Color};
      border-radius: 0.25rem;
      box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 10%);
      font-size: 0.75rem;
      line-height: 1.17;
      letter-spacing: 0.3px;
      padding: 0.25rem 0.5rem;
      z-index: 999;

      &.show {
        opacity: 1;
        margin: 0;
      }

      &::after,
      &::before {
        display: none;
      }
    }
  `};
`)

export const Tooltip: React.FC<ITooltipProps & ReactTooltipProps> = ({ ...props }) => {
  const tooltipRef = useRef<TooltipRefProps>(null)
  const [timer, setTimer] = useState<ReturnType<typeof setInterval>>()

  const {
    afterShow: propAfterShow,
    afterHide: propAfterHide,
    hideAfterXMilliseconds,
    ...otherProps
  } = props

  const startTimer = () => {
    // tslint:disable-next-line:no-object-mutation
    setTimer(
      setTimeout(() => {
        tooltipRef.current?.close()
      }, hideAfterXMilliseconds ?? 10000),
    )
  }

  const stopTimer = () => {
    clearInterval(timer)
  }

  const afterShow = () => {
    if (typeof propAfterShow === 'function') {
      propAfterShow()
    }
    startTimer()
  }

  const afterHide = () => {
    if (typeof propAfterHide === 'function') {
      propAfterHide()
    }
    stopTimer()
  }

  return (
    <StyledReactTooltip
      id="base-tooltip"
      data-testid="base-tooltip"
      ref={tooltipRef}
      place="right"
      type="light"
      effect="solid"
      afterShow={afterShow}
      afterHide={afterHide}
      {...otherProps}
    />
  )
}
