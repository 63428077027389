export const RECAPTCHA_SITE_KEY = '6LeEProUAAAAABOdchn3GpQQog6h77RnGxZ7nJj0'

export const DEMO_VIDEO = {
  content_submission: {
    USA: '3y2zdmvwxd',
    GBR: 'wapp16hmns',
    AUS: '9kvr1i8qfn',
  },
  social_submission: {
    USA: '96h84zvn3k',
    GBR: 'kf3t9ons7b',
    AUS: 'oxao9r9xoy',
  },
}

export const LICENSING_TOAST_DELAY = 5000
