import React, { useEffect, useState } from 'react'

import OutsideClickHandler from 'react-outside-click-handler'

import TipStyled, {
  CoachMarkInnerWrapper,
  CoachMarkWrapper,
  TipIcon,
  TipIconWrapper,
  TipRoot,
  TipText,
} from './Tip.styled'

interface ICoachMark {
  content: React.ReactNode
  position: 'bottom' | 'top' | 'left' | 'right'
  width?: number
  height?: number
  yOffset?: number
  pointerXPosition?: 'center' | 'left'
  maxHeightOffset?: number
  overflow?: 'auto' | 'hidden'
}

interface IIcon {
  size?: number
  glyph?: string
  padded?: boolean
  color?: string
  backgroundColor?: string
}

export interface ITipProps {
  /**
   * Content displayed beside tip icon
   * @default undefined
   */
  text?: React.ReactNode

  /**
   * Max width for the text content in rem
   * @default 16.875
   */
  width?: number

  /**
   * Content displayed when tip is clicked
   */
  coachmark?: ICoachMark

  /**
   * Display tip element as centered
   */
  center?: boolean

  /**
   * top margin for tip in rem
   *
   * @default undefined
   */
  topOuterSpacing?: number

  /**
   * Sets the item to inline-flex
   */
  inline?: boolean

  /**
   * Sets the item to inline-flex
   */
  icon?: IIcon

  /**
   * hides the lightbulb icon
   */
  hideIcon?: boolean

  /**
   * ignore outside click from container with this ID
   */
  ignoredContainerId?: string

  /*
   * Have coachmark open on render
   */
  openCoachmarkOnRender?: boolean
}

export const Tip = ({
  width = 19.625,
  inline,
  text,
  coachmark,
  center,
  topOuterSpacing,
  icon,
  hideIcon = false,
  ignoredContainerId = '',
  openCoachmarkOnRender = false,
}: ITipProps) => {
  const [showCoachmark, setShowCoachmark] = useState(openCoachmarkOnRender)

  useEffect(() => {
    setShowCoachmark(() => openCoachmarkOnRender)
  }, [openCoachmarkOnRender])

  const onInsideClick = (event: Event) => {
    if (showCoachmark) {
      event.stopPropagation()
    }

    setShowCoachmark(true)
  }

  const onOutsideClick = (event) => {
    const baseTooltip = document.getElementById(ignoredContainerId)
    if (baseTooltip && baseTooltip.contains(event.target)) {
      return
    }
    setShowCoachmark(false)
  }

  const iconSize = icon && icon.size
  const iconGlyph = icon && icon.glyph
  const iconPadded = (icon && icon.padded) || !icon
  const iconBackgroundColor = icon && icon.backgroundColor
  const iconColor = icon && icon.color

  return (
    <TipRoot>
      <OutsideClickHandler onOutsideClick={onOutsideClick}>
        <TipStyled
          justifyStart={!center}
          justifyCenter={center}
          width={width}
          hasCoachmark={Boolean(coachmark)}
          topOuterSpacing={topOuterSpacing}
          inline={inline}
          onClick={onInsideClick}
        >
          {!hideIcon && (
            <TipIconWrapper
              backgroundColor={iconBackgroundColor}
              padded={iconPadded}
              justifyCenter
              alignCenter
            >
              <TipIcon glyph={iconGlyph || 'tip'} size={iconSize || 1.25} color={iconColor} />
            </TipIconWrapper>
          )}

          {text && <TipText alignCenter>{text}</TipText>}
          {coachmark && showCoachmark && (
            <CoachMarkWrapper
              height={coachmark.height}
              width={coachmark.width}
              position={coachmark.position}
              yOffset={coachmark.yOffset}
              pointerXPosition={coachmark.pointerXPosition || 'center'}
              padded={iconPadded}
            >
              <CoachMarkInnerWrapper
                maxHeightOffset={coachmark.maxHeightOffset}
                overflow={coachmark.overflow ?? 'auto'}
              >
                {coachmark.content}
              </CoachMarkInnerWrapper>
            </CoachMarkWrapper>
          )}
        </TipStyled>
      </OutsideClickHandler>
    </TipRoot>
  )
}

export default Tip
