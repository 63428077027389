import { defaultTheme, IThemeInterface } from '@tribegroup'
import flexboxGridSettings, { IFlexboxGrid } from './flexboxgrid'

type BaseTheme = IThemeInterface & IFlexboxGrid

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    BRAND_APP_THEME_OVERRIDE: Partial<BaseTheme>
  }
}

const Theme = {
  ...defaultTheme,
  flexboxgrid: flexboxGridSettings,
  ...(window.BRAND_APP_THEME_OVERRIDE ?? {}),
} as BaseTheme

export { mobileGutter, tabletGutter, titleBarHeight } from './gutters'
export default Theme
