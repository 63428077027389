import React from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

import { TRIBE_TOC } from '@constants'
import Theme from '@theme'
import { Container, Link, Text } from '@tribegroup'

const SubmissionCardModalTermsOfService: React.FC = () => (
  <Container topOuterSpacing={1.25} textAlign="center">
    <Text small color={Theme.grey900Color}>
      <FormattedHTMLMessage id="submission.chat.preapproved.modal.termsAgreement" />
    </Text>
    <Container topOuterSpacing={0.0625}>
      <Text small color={Theme.grey900Color}>
        <Link href={TRIBE_TOC} target="_blank" color={Theme.primaryColor}>
          <FormattedMessage id="core.text.tribeTermsofService" />
        </Link>
      </Text>
    </Container>
  </Container>
)

export default SubmissionCardModalTermsOfService
