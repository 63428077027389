import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { RouterProps, withRouter } from 'react-router'
import { compose } from 'redux'

import BuilderBrandForm from '@components/Builder/Brands/Form/Form'
import BuilderBrandsList from '@components/Builder/Brands/List/List'
import { withScrollToTop } from '@hocs'
import { Container, Text, Tip } from '@tribegroup'
import LogoRequirements from './Form/LogoRequirements/LogoRequirements'

type IInternalProps = RouterProps

export const Brands = ({ history }: IInternalProps) => {
  const { pathname } = history.location
  const showBrandsList = !(
    pathname.split('/').includes('edit') || pathname.split('/').includes('add')
  )
  return (
    <Fragment>
      {showBrandsList ? <BuilderBrandsList /> : <BuilderBrandForm />}
      <Container bottomInnerSpacing={2.5}>
        <Tip
          text={
            <Text uppercase small>
              <FormattedMessage id="builder.brands.brandLogo.tip" />
            </Text>
          }
          center
          coachmark={{
            content: <LogoRequirements />,
            position: 'top',
            width: 30.625,
            height: 31.5,
          }}
        />
      </Container>
    </Fragment>
  )
}

export default compose(withRouter, withScrollToTop)(Brands)
