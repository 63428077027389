import styled from 'styled-components'

interface IFlatButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  backgroundColor?: string
  borderColor?: string
  topInnerSpacing?: number
  bottomInnerSpacing?: number
  leftInnerSpacing?: number
  rightInnerSpacing?: number
}

const FlatButton: React.FC<IFlatButtonProps> = styled.button`
  width: 100%;
  border-radius: 2rem;
  outline: none;
  border: none;
  cursor: pointer;
  ${({
    backgroundColor,
    borderColor,
    topInnerSpacing = 0.375,
    bottomInnerSpacing = 0.25,
    leftInnerSpacing = 0.5,
    rightInnerSpacing = 0.5,
  }) => `
    background-color: ${backgroundColor || 'transparent'};
    border: 0.0625rem solid ${borderColor || backgroundColor};
    padding-top: ${topInnerSpacing}rem;
    padding-bottom: ${bottomInnerSpacing}rem;
    padding-left: ${leftInnerSpacing}rem;
    padding-right: ${rightInnerSpacing}rem;
  `};

  &:disabled {
    cursor: default;
  }
`

export { FlatButton }
