import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import UIBreakpoint from '@components/UI/Breakpoint'
import Theme from '@theme'
import { Button } from '@tribegroup'

export const PortfolioVideoTrigger = styled(Button)`
  border-radius: 50%;
  padding: 2.625rem;
  border: 0;
  background-color: rgb(0 0 0 / 30%);

  &:hover {
    background-color: rgb(0 0 0 / 70%);
  }

  ${breakpoint('phoneUpperBoundary')`
    background-color: transparent !important;
  `};
`

export const PortolioVideo = styled.video`
  width: 100%;
  height: 100%;
  outline: none;
  background-color: ${Theme.whiteColor};
`

export const Breakpoint = styled(UIBreakpoint)`
  height: 100%;
`
