import { FormattedMessage } from 'react-intl'

import LinkifiedString from '@components/UI/LinkifiedString'
import Theme from '@theme'
import { Container, Header, Text } from '@tribegroup'

interface ISubmissionCreatorNoteBlockProps {
  frameNumber?: number
  text: string
  active?: boolean
}

const SubmissionCreatorNoteBlock = ({
  text,
  frameNumber,
  active,
}: ISubmissionCreatorNoteBlockProps) => {
  return (
    <Container topInnerSpacing={0.5625} bottomOuterSpacing={0.5625} leftOuterSpacing={1.25}>
      {frameNumber && (
        <Header
          small
          uppercase
          color={active ? Theme.secondaryColor : Theme.grey900Color}
          bottomOuterSpacing={0.5}
        >
          <FormattedMessage id="submission.card.creator.notes.frame" values={{ frameNumber }} />
        </Header>
      )}
      {active && (
        <Text color={Theme.grey900Color} italic>
          <LinkifiedString value={text} color={Theme.primaryColor} />
        </Text>
      )}
    </Container>
  )
}

export default SubmissionCreatorNoteBlock
