import { FormattedHTMLMessage } from 'react-intl'

import Theme from '@theme'
import { Container, Icon } from '@tribegroup'

interface IProps {
  i18nKey: string
  values?: Record<string, any>
}

const ToastTemplateWarning: React.FC<IProps> = ({ i18nKey, values }) => (
  <Container flexStart alignItems="flex-start">
    <Container rightOuterSpacing={0.75} noShrink verticalAlign="middle">
      <Icon glyph="alert-solid" color={Theme.errorColor} size={1} verticalAlign="middle" />
    </Container>
    <Container noShrink topOuterSpacing={0.125}>
      <FormattedHTMLMessage id={i18nKey} values={values} />
    </Container>
  </Container>
)

export default ToastTemplateWarning
