import React from 'react'

import { useFlagsStatus } from '@unleash/proxy-client-react'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import AuthForm from '@components/UI/AuthForm'
import Box from '@components/UI/Box'
import { BOOK_A_DEMO, DEFAULT_TENANT } from '@constants'
import { ErrorCodes } from '@enums'
import { IApplicationState } from '@store'
import {
  clearErrors,
  ISignInParams,
  selectAuthErrorCode,
  selectIsAuthenticating,
  signInRequest,
} from '@store/auth'
import { selectForgotPasswordLink, selectSignUpLink } from '@store/rootResource'
import Theme from '@theme'
import {
  Button,
  Form,
  FormGroup,
  FormInput,
  FormValidationMessage,
  IWithFormValidationProps,
  Text,
  TextField,
  withFormValidation,
} from '@tribegroup'
import { queryStringtoObject } from '@utils'

export const AuthSignInFormSignUpLink = () => {
  const { flagsReady, flagsError } = useFlagsStatus()
  const showSignUpLink = process.env.APP_NAME !== DEFAULT_TENANT

  if (!showSignUpLink || !flagsReady || flagsError) {
    return null
  }

  return (
    <Text color={Theme.grey700Color}>
      <FormattedMessage id="auth.signin.link.signupAnAccount" />
      &nbsp;
      <Link to="/signUp" data-cy-element="sign-up-link">
        <FormattedMessage id="core.text.signUp" />
      </Link>
    </Text>
  )
}

interface IInternalProps extends IWithFormValidationProps, RouteComponentProps, InjectedIntlProps {
  clearErrors: typeof clearErrors
  signInRequest: typeof signInRequest
  isAuthenticating: boolean
  errorCode?: string
  hasForgotPasswordLink: boolean
  hasSignUpLink: boolean
}

export class AuthSignInForm extends React.Component<IInternalProps> {
  state = {
    email: '',
    password: '',
  }

  componentDidUpdate() {
    const { errorCode, history } = this.props
    if (errorCode && errorCode === ErrorCodes.SIGNIN_UNVERIFIED_ACCOUNT) {
      const encodedEmail = encodeURIComponent(this.state.email)
      history.push(`/resendVerification?email=${encodedEmail}`)
    }
  }

  onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    this.setState({
      [target.name]: target.value,
    })
  }

  onBeforeSubmit = () => {
    this.props.clearErrors()
    return this.props.validate()
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const parsedQueryString: any = queryStringtoObject(this.props.location.search)
    const search = parsedQueryString.search ? decodeURIComponent(parsedQueryString.search) : ''
    const from = parsedQueryString.from ? `${parsedQueryString.from}${search}` : undefined

    this.props.signInRequest(this.state as ISignInParams, this.props.history, from)
    this.setState({ password: '' })
  }

  render() {
    const {
      intl,
      isAuthenticating,
      validationResults = {},
      hasForgotPasswordLink,
      hasSignUpLink,
      errorCode,
    } = this.props

    const showSignUpLink = hasSignUpLink && process.env.APP_NAME !== DEFAULT_TENANT

    return (
      <Box fullOnSmallScreens>
        <AuthForm
          title={<FormattedMessage id="auth.signin.h1.signInHeader" />}
          subtitle={
            showSignUpLink ? (
              <AuthSignInFormSignUpLink />
            ) : (
              <Text color={Theme.grey700Color}>
                Don`t have an account? &nbsp;
                <a href={BOOK_A_DEMO} target="_blank" rel="noreferrer">
                  Get in touch
                </a>
              </Text>
            )
          }
        >
          <Form
            name="sign-in"
            onSubmit={this.onSubmit}
            onBeforeSubmit={this.onBeforeSubmit}
            setForm={this.props.setForm}
            method="POST"
            data-cy-element="sign-in-form"
          >
            <FormGroup stretchChildWidth>
              <FormInput
                validationRule={{
                  type: {
                    value: 'email',
                    message: intl.formatMessage({
                      id: 'auth.signin.error.email.required',
                    }),
                  },
                }}
              >
                <TextField
                  data-cy-element="sign-in-form-email-field"
                  type="email"
                  name="email"
                  value={this.state.email}
                  invalid={validationResults.email && validationResults.email.hasError}
                  placeholder={intl.formatMessage({
                    id: 'core.text.email',
                  })}
                  onFocus={this.props.clearFieldValidation}
                  onChange={this.onChange}
                />
              </FormInput>
              {validationResults.email && validationResults.email.hasError && (
                <FormValidationMessage>
                  <Text small>{validationResults.email.errorMessage}</Text>
                </FormValidationMessage>
              )}
            </FormGroup>
            <FormGroup stretchChildWidth>
              <FormInput
                validationRule={{
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      id: 'auth.signin.error.password.required',
                    }),
                  },
                }}
              >
                <TextField
                  data-cy-element="sign-in-form-password-field"
                  type="password"
                  name="password"
                  value={this.state.password}
                  invalid={validationResults.password && validationResults.password.hasError}
                  placeholder={intl.formatMessage({
                    id: 'core.text.password',
                  })}
                  onFocus={this.props.clearFieldValidation}
                  onChange={this.onChange}
                />
              </FormInput>
              {validationResults.password && validationResults.password.hasError && (
                <FormValidationMessage>
                  <Text small>{validationResults.password.errorMessage}</Text>
                </FormValidationMessage>
              )}
              {errorCode && (
                <FormValidationMessage>
                  <Text small>
                    <FormattedMessage id={errorCode} />
                  </Text>
                </FormValidationMessage>
              )}
            </FormGroup>
            <Flex center>
              <Button
                heavy
                loading={isAuthenticating}
                scheme="primary"
                data-cy-element="log-in-button"
                disabled={isAuthenticating}
              >
                <FormattedMessage id="core.text.signIn" />
              </Button>
            </Flex>
          </Form>
          {hasForgotPasswordLink && (
            <Flex center column className="footer">
              <Link to="/forgotPassword" data-cy-element="forgot-password-link">
                <FormattedMessage id="auth.signin.text.forgotPassword" />
              </Link>
            </Flex>
          )}
        </AuthForm>
      </Box>
    )
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  errorCode: selectAuthErrorCode(state),
  isAuthenticating: selectIsAuthenticating(state),
  hasForgotPasswordLink: selectForgotPasswordLink(state),
  hasSignUpLink: selectSignUpLink(state),
})

const mapDispatchToProps = {
  signInRequest,
  clearErrors,
}

export default compose<IInternalProps, {}>(
  withRouter,
  withFormValidation,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(AuthSignInForm)
