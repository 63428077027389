import React from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import Confirmation from '@components/UI/Confirmation'
import { FooterButtonWrapper } from '@components/UI/Confirmation/Confirmation.styled'
import { IMember, removeMember } from '@store/members'
import { Button } from '@tribegroup'

interface IProps {
  member: IMember
  campaignId: number
  isOpen: boolean
  onClose: VoidFunction
}

const InboxMemberRemoveConfirmation: React.FC<IProps> = ({
  campaignId,
  member,
  onClose,
  isOpen,
}) => {
  const dispatch = useDispatch()

  const onRemoveClick = () => {
    dispatch(removeMember(campaignId, member.id))
    onClose()
  }

  return (
    <Confirmation
      title={<FormattedMessage id="inbox.sidebar.members.removeMember.confirmation.title" />}
      content={
        <FormattedMessage
          id="inbox.sidebar.members.removeMember.confirmation.body"
          values={{ member: member.name || member.email }}
        />
      }
      footerButtons={
        <React.Fragment>
          <FooterButtonWrapper>
            <Button
              scheme="primary"
              quiet
              onClick={onClose}
              data-cy-element="remove-member-modal-cancel-button"
            >
              <FormattedMessage id="core.text.cancel" />
            </Button>
          </FooterButtonWrapper>
          <FooterButtonWrapper>
            <Button
              scheme="primary"
              onClick={onRemoveClick}
              data-cy-element="remove-member-modal-remove-button"
            >
              <FormattedMessage id="core.text.remove" />
            </Button>
          </FooterButtonWrapper>
        </React.Fragment>
      }
      isOpen={isOpen}
      onCloseIconClick={onClose}
    />
  )
}

export default InboxMemberRemoveConfirmation
