import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import RouterLink from '@components/UI/RouterLink/RouterLink.styled'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { IPCRChannel, selectPCRChannelQueryString } from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Text } from '@tribegroup'
import { getCampaignMetricsTab } from '@utils'
import { ChannelWrapper } from './Channel.styled'

export interface IInternalProps extends IPCRChannel {
  queryString: string
  campaignId: number
}

const PostCampaignReportChannel: React.FC<IInternalProps> = ({
  active,
  channel,
  count,
  queryString,
  campaignId,
}) => {
  return (
    <ConnectedTrackedRoute
      eventName={EventTrackingNames.ViewCampaignMetricsTab}
      campaignId={campaignId}
      additionalProperties={{
        current_filter: undefined,
        metrics_tab: getCampaignMetricsTab(channel),
      }}
    >
      <RouterLink to={{ search: queryString }}>
        <ChannelWrapper active={active}>
          <Text color={Theme.grey800Color}>
            <FormattedMessage id={`postCampaignReport.channel.label.text.${channel}`} />
          </Text>
          {count > 0 && (
            <Text key="pcr-channel-count-text" color={Theme.grey800Color}>
              &nbsp; {count}
            </Text>
          )}
        </ChannelWrapper>
      </RouterLink>
    </ConnectedTrackedRoute>
  )
}

interface IProps extends IPCRChannel, RouteComponentProps {}

const mapStateToProps = (state: IApplicationState, { channel, match }: IProps) => ({
  queryString: selectPCRChannelQueryString(state, channel),
  campaignId: selectCampaignId(match)!,
})

export { PostCampaignReportChannel }
export default compose<IInternalProps, IPCRChannel>(
  withRouter,
  connect(mapStateToProps),
)(PostCampaignReportChannel)
