import { createSelector } from 'reselect'

import { BrandManagerIdentityProviders } from '@enums'
import { IApplicationState, IHateoasLink } from '@store'

const selectBrandManagerIdentitiesState = (state: IApplicationState) => state.brandManagerIdentities

const selectBrandManagerIdentitiesLinks = createSelector(
  selectBrandManagerIdentitiesState,
  (identities) => identities.links,
)

export const selectIsFetching = createSelector(
  selectBrandManagerIdentitiesState,
  (identities) => identities.isFetching,
)

export const selectBrandManagerIdentitiesIsFetched = createSelector(
  selectBrandManagerIdentitiesState,
  (identities) => identities.isFetched,
)

export const selectErrors = createSelector(
  selectBrandManagerIdentitiesState,
  (identities) => identities.errors,
)

export const selectIsConnecting = createSelector(
  selectBrandManagerIdentitiesState,
  (identities) => identities.isConnecting,
)

export const selectConnectIdentitiesLink = createSelector(
  selectBrandManagerIdentitiesLinks,
  (links: ReadonlyArray<IHateoasLink>) => links.find((link) => link.rel === 'connect_identities'),
)

export const selectBrandManagerIdentities = createSelector(
  selectBrandManagerIdentitiesState,
  (identities) => identities.brandManagerIdentities,
)

export const selectCheckIfHasNewBrandManagerIdentityUID = createSelector(
  selectBrandManagerIdentities,
  (state, newUids: string[]) => newUids,
  (brandManagerIdentities, newUids) => {
    const storeUids = brandManagerIdentities.map((identity) => identity.oauth_uid)
    return newUids.some((uid) => !storeUids.includes(uid))
  },
)

export const selectBrandManagerIdentity = createSelector(
  selectBrandManagerIdentities,
  (state, brandManagerIdentityId) => brandManagerIdentityId,
  (identities, brandManagerIdentityId) =>
    identities.find((identity) => identity.id === brandManagerIdentityId),
)

export const selectBrandManagerIdentityLink = createSelector(
  selectBrandManagerIdentity,
  (state, brandManagerIdentityId, rel) => rel,
  (identity, rel) => identity?.links.find((link) => link.rel === rel),
)

export const selectpendingBrandManagerIdentityIds = createSelector(
  selectBrandManagerIdentitiesState,
  (identities) => identities.pendingBrandManagerIdentityIds,
)

export const selectBrandManagerTikTokAccounts = createSelector(
  selectBrandManagerIdentities,
  (identities) =>
    identities.filter((identity) => identity.channel === BrandManagerIdentityProviders.TikTok),
)
