import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const UnsupportedScreenWrapper = styled.div`
  ${breakpoint('tabletLandscape')`
    display: none;
  `};
`

export const SupportedScreenWrapper = styled.div`
  display: none;
  ${breakpoint('tabletLandscape')`
    display: block;
  `}
`
