import React from 'react'

import { FormattedMessage } from 'react-intl'

import FlatButton from '@components/UI/FlatButton'
import Theme from '@theme'
import { Header } from '@tribegroup'
import { IButtonProps } from './Button'
import BrandedContentAdBarButtonWrapper from './Wrapper'

const PromoteAsAd: React.FC<IButtonProps> = ({ onClick, submissionId }) => (
  <BrandedContentAdBarButtonWrapper submissionId={submissionId}>
    <FlatButton borderColor={Theme.primaryColor} onClick={onClick}>
      <Header color={Theme.primaryColor} uppercase cta>
        <FormattedMessage id="core.text.boost" />
      </Header>
    </FlatButton>
  </BrandedContentAdBarButtonWrapper>
)

export { PromoteAsAd }
export default PromoteAsAd
