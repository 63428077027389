import { FC } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import {
  selectCurrentDeclinedFilter,
  selectIsBCAFilter,
  selectIsDeclinedFilter,
} from '@store/router'
import SubmissionCardListEmptyBrandedContentAd from './BrandedContentAd'
import SubmissionCardListEmptyDeclined from './Declined'
import SubmissionCardListEmptyDefault from './Default'

interface IProps {
  submissionFilter: string
}

type IInternalProps = IProps & ConnectedProps<typeof connector>

const SubmissionCardListEmpty: FC<IInternalProps> = ({
  isBCAFilter,
  declinedFilter,
  isDeclinedListFilter,
  submissionFilter,
}) => {
  if (isBCAFilter) {
    return <SubmissionCardListEmptyBrandedContentAd />
  }

  if (isDeclinedListFilter) {
    return <SubmissionCardListEmptyDeclined declinedFilter={declinedFilter} />
  }

  return <SubmissionCardListEmptyDefault submissionFilter={submissionFilter} />
}

const mapStateToProps = (state: IApplicationState) => ({
  isBCAFilter: selectIsBCAFilter(state),
  isDeclinedListFilter: selectIsDeclinedFilter(state),
  declinedFilter: selectCurrentDeclinedFilter(state),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(connector)(SubmissionCardListEmpty)
