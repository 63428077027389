/* eslint-disable react/display-name */
import { SubmissionListFilterContext } from '@context/SubmissionListFilter'

export const withSubmissionListFilter = (Component) => (props) => {
  return (
    <SubmissionListFilterContext.Consumer>
      {(contextProps) => <Component {...props} {...contextProps} />}
    </SubmissionListFilterContext.Consumer>
  )
}
