import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  ContentLibraryBillingActionTypes,
  fetchContentLibraryBillings,
  fetchContentLibraryBillingsError,
  fetchContentLibraryBillingsSuccess,
  selectContentLibraryBillingHistoryNextLink,
} from '@store/billings'
import { selectContentLibraryBillingHistoryLink } from '@store/me'
import { networkRequest } from '@utils'

export function* handleFetchContentLibraryPurchases(
  action: ReturnType<typeof fetchContentLibraryBillings>,
) {
  try {
    const isFetchNext = action.payload
    const authToken: string = yield select(selectAuthToken)
    const billingHistoryLink: IHateoasLink = yield isFetchNext
      ? select(selectContentLibraryBillingHistoryNextLink)
      : select(selectContentLibraryBillingHistoryLink)
    const result = yield call(
      networkRequest,
      billingHistoryLink.method,
      billingHistoryLink.href,
      undefined,
      authToken,
    )

    yield put(fetchContentLibraryBillingsSuccess(result))
  } catch (error) {
    yield put(fetchContentLibraryBillingsError(error))
  }
}

export function* watchFetchContentLibraryBillingRequest() {
  yield takeLatest(
    ContentLibraryBillingActionTypes.FETCH_REQUEST,
    handleFetchContentLibraryPurchases,
  )
}
