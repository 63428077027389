import { WatcherFunction } from '..'
import { watchConnectAdAccountRequest } from './connectAdAccounts'
import { watchConnectIdentitiesRequest } from './connectIdentities'
import { watchFetchIdentitiesRequest } from './fetchIdentities'
import { watchReconnectIdentityRequest } from './reconnectIdentity'
import { watchRemoveIdentityRequest } from './removeIdentity'

export const brandManagerIdentitiesSagas: ReadonlyArray<WatcherFunction> = [
  watchConnectIdentitiesRequest,
  watchFetchIdentitiesRequest,
  watchRemoveIdentityRequest,
  watchReconnectIdentityRequest,
  watchConnectAdAccountRequest,
]
