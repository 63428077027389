import { FormattedMessage } from 'react-intl'

import { NoticeEmpty } from '@components/UI/Notice'
import TribeOnly from '@components/UI/TribeOnly'
import { TRIBE_INBOX_DEMO_LINK } from '@constants'
import { CampaignStatuses } from '@enums'
import { useCurrentCampaignStatusFilter } from '@hooks/campaigns/useCurrentCampaignStatusFilter'
import Theme from '@theme'
import { Link, Text } from '@tribegroup'

const CampaignListEmpty: React.FC = () => {
  const currentCampaignStatusFilter = useCurrentCampaignStatusFilter()
  const inReviewFilter = currentCampaignStatusFilter === CampaignStatuses.InReview
  return (
    <NoticeEmpty
      alt="campaign"
      title={
        <FormattedMessage
          id={`campaign.campaignsPage.empty.title.${currentCampaignStatusFilter}`}
        />
      }
      subtitle={
        <FormattedMessage
          id={`campaign.campaignsPage.empty.subtitle.${currentCampaignStatusFilter}`}
        />
      }
      education={
        inReviewFilter && (
          <TribeOnly>
            <Link target="_blank" href={TRIBE_INBOX_DEMO_LINK}>
              <Text color={Theme.primaryColor}>
                <FormattedMessage id="core.text.playDemo.withArrow" />
              </Text>
            </Link>
          </TribeOnly>
        )
      }
    />
  )
}

export default CampaignListEmpty
