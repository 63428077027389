import { PropsWithChildren, PureComponent } from 'react'

import ReactModal, { Props as ReactModalProps } from 'react-modal'
import styled, { css } from 'styled-components'

import { Tooltip } from '@tribegroup'
import { isTruthyOrZero } from '@utils/isTruthyOrZero'
import { SlideInUp, SlideOutDown, Spin } from '../Effects'
import { Icon } from '../Icon'
import { themed, useDefaultBreakpoint } from '../Theme'
import bodyScrollHandler from './bodyScrollHandler'

const animationTime = {
  content: 300,
  overlay: 150,
}

const fullAnimationTime = Math.max(animationTime.content + animationTime.overlay)

const styles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100,
  },

  content: {
    padding: '0',
    width: '100%',
    height: '100%',
    border: '0',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'transparent',
    borderRadius: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

const overlaySupportStyled: ReactModal.Styles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100,
    display: 'flex',
  },

  content: {
    position: 'relative',
    padding: 0,
    margin: 'auto',
    inset: 0,
    borderRadius: '0.5rem',
  },
}

export interface IModalContentProps {
  /**
   * Modal content width in rem
   */
  width?: number
  /**
   * Modal content height in rem
   */
  height?: number

  /**
   * Shows modal as complex construct
   */
  complex?: boolean

  padding?: number
  mobilePadding?: number
}

export const ModalContent = styled<IModalContentProps, any>('div')`
  ${({ height }) =>
    height &&
    css`
      min-height: ${height}rem;
    `};
  ${({ hide }) =>
    hide &&
    css`
      display: none;
      opacity: 0;
    `};
  width: 100%;
  height: 100%;
  ${({ mobilePadding = 1 }) => css`
    padding: ${mobilePadding}rem;
  `};
  ${useDefaultBreakpoint('phoneUpperBoundary')`
    animation-duration: ${animationTime.content}ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    border-radius: 0.5rem;
    height: auto;
    ${({ width = 32.5 }) => css`
      max-height: 90vh;
      max-width: ${width}rem;
    `};
    ${({ complex }) =>
      complex
        ? css`
            padding: 3rem;
          `
        : css`
            padding: 1.5rem;
          `};
    ${({ padding }) =>
      isTruthyOrZero(padding) &&
      css`
        padding: ${padding}rem;
      `};
  `};
`

export const GlobalStyle = themed(styled.div`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity ${fullAnimationTime}ms;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    ${ModalContent} {
      animation-name: ${SlideInUp};
    }
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
    ${ModalContent} {
      animation-name: ${SlideOutDown};
    }
  }

  ${ModalContent} {
    background: #fff;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    overflow: auto;
  }
`)

export const Loading = themed(styled(Icon)`
  ${({ theme }) => css`
    fill: ${theme.whiteColor};
    animation: ${Spin} 2.8s linear infinite;
  `};
`)

export const ModalBaseWrapper = () => <GlobalStyle id="modal-base" />

export interface IModalProps extends IModalContentProps {
  reactModalProps: ReactModalProps
  complex?: boolean
  isLoadingContent?: boolean
  mobilePadding?: number
  padding?: number
  enableOverlayClick?: boolean
}

export default class Modal extends PureComponent<PropsWithChildren<IModalProps>> {
  private internalId = `modal-${Date.now()}`

  getParent = () => {
    return document.querySelector('#modal-base') as HTMLElement
  }

  componentDidMount() {
    const isOpen = this.props.reactModalProps.isOpen
    bodyScrollHandler.set(this.internalId, isOpen)
  }

  componentDidUpdate() {
    const isOpen = this.props.reactModalProps.isOpen
    bodyScrollHandler.set(this.internalId, isOpen)
  }

  componentWillUnmount() {
    bodyScrollHandler.remove(this.internalId)
  }

  public render() {
    const {
      width,
      height,
      children,
      reactModalProps,
      complex,
      mobilePadding,
      padding,
      isLoadingContent = false,
      enableOverlayClick,
    } = this.props

    return (
      <ReactModal
        {...reactModalProps}
        ariaHideApp={false}
        closeTimeoutMS={fullAnimationTime}
        parentSelector={this.getParent() ? this.getParent : undefined}
        style={
          enableOverlayClick
            ? overlaySupportStyled
            : {
                overlay: styles.overlay,
                content: styles.content,
              }
        }
      >
        {isLoadingContent && <Loading glyph="spinner" className="spinner" />}
        <ModalContent
          width={width}
          height={height}
          complex={complex}
          data-cy-element="modal"
          hide={isLoadingContent}
          mobilePadding={mobilePadding}
          padding={padding}
        >
          {children}
          <Tooltip />
        </ModalContent>
      </ReactModal>
    )
  }
}
