import { call, put, select, takeLatest } from 'redux-saga/effects'

import { selectAuthToken } from '@store/auth'
import {
  fetchCreatorBioByLink,
  FetchCreatorBioByLinkActionTypes,
  fetchCreatorBioByLinkError,
  fetchCreatorBioByLinkSuccess,
} from '@store/creator-bio'
import { networkRequest } from '@utils'

export function* handleFetchCreatorBio(action: ReturnType<typeof fetchCreatorBioByLink>) {
  const link = action.payload
  const { href, method } = link
  try {
    const authToken: string = yield select(selectAuthToken)
    const creatorBio = yield call(networkRequest, method, href, undefined, authToken)
    yield put(fetchCreatorBioByLinkSuccess({ link, creatorBio }))
  } catch (error) {
    yield put(fetchCreatorBioByLinkError({ error }))
  }
}

export function* watchCreatorBio() {
  yield takeLatest(FetchCreatorBioByLinkActionTypes.FETCH_REQUEST, handleFetchCreatorBio)
}
