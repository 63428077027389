import React, { useMemo } from 'react'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectStripeApiKey } from '@store/me'

interface IPaymentProps {
  children: React.ReactNode
}

interface IInternalProps extends IPaymentProps {
  stripeApiKey: string
}

export const Payment = ({ stripeApiKey, children }: IInternalProps) => {
  const stripePromise = useMemo(() => loadStripe(stripeApiKey), [stripeApiKey])
  return <Elements stripe={stripePromise}>{children}</Elements>
}

const mapStateToProps = (state: IApplicationState) => ({
  stripeApiKey: selectStripeApiKey(state),
})

export default compose<IInternalProps, IPaymentProps>(connect(mapStateToProps))(Payment)
