import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Container, H4, Text } from '@tribegroup'

const BuilderBriefChannelsPinterestHeader = () => {
  return (
    <Container topOuterSpacing={2}>
      <Container bottomOuterSpacing={1}>
        <Text small uppercase color={Theme.grey800Color} textDecoration="underline">
          <FormattedMessage id="builder.brief.socialMedia.pinterest.forPins" />
        </Text>
      </Container>
      <H4 color={Theme.defaultColor}>
        <FormattedMessage id="builder.brief.socialMedia.pinterest.tags.header" />
      </H4>
    </Container>
  )
}

export default BuilderBriefChannelsPinterestHeader
