import { Component, Fragment } from 'react'

import { InjectedIntlProps } from 'react-intl'
import { Link } from 'react-router-dom'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import Flex, { FlexItem } from 'styled-flex-component'

import CampaignShareTrigger from '@components/Campaign/Share/Trigger'
import InboxReferralTrigger from '@components/Inbox/Referral/Trigger'
import { ActionIconWrapper } from '@components/Inbox/TitleBar/TitleBar.styled'
import SubmissionRateCardPanelTriggerDesktop from '@components/Submission/RateCardPanel/Trigger/Desktop'
import ActionIcon from '@components/UI/ActionIcon'
import HeadTag from '@components/UI/HeadTag'
import { LogoLink } from '@components/UI/TitleBar'
import { HeaderTitle } from '@components/UI/TitleBar/TitleBar.styled'
import TrackedRoute from '@components/UI/TrackedRoute/TrackedRoute'
import { IAnalyticsTrackingContextProps } from '@context/AnalyticsTracking'
import { ICampaignContextProps } from '@context/Campaign'
import { ISubmissionContextProps } from '@context/Submission'
import { EventTrackingNames } from '@enums'
import Theme from '@theme'
import { H5 } from '@tribegroup'
import { buildSubmissionUrl } from '@utils/urls'
import { Absolute } from '../TitleBar.styled'
import { getRateCardCurrency } from '../titleBarService'
import TitleBarDesktopBackButton from './BackButton/BackButton'

interface IProps
  extends ICampaignContextProps,
    IAnalyticsTrackingContextProps,
    ISubmissionContextProps,
    InjectedIntlProps {
  search: string
  hasReferralProgramLink: boolean
  hasFirebaseUrl: boolean
}

export class SubmissionTitleBarDesktop extends Component<IProps> {
  render() {
    const {
      submission,
      campaign,
      intl,
      isCampaignFetched,
      isSubmissionFetched,
      search,
      hasReferralProgramLink,
      hasFirebaseUrl,
    } = this.props

    const hasFetchedCampaignAndSubmission = isCampaignFetched && isSubmissionFetched
    const rateCardCurrency = getRateCardCurrency(campaign)

    const notesTipId =
      isSubmissionFetched && submission.notes_any
        ? 'submission.card.menu.viewNotes'
        : 'submission.card.menu.addNotes'

    return (
      <Fragment>
        <HeadTag text={submission && submission.influencer_full_name} />
        <Flex full alignCenter>
          <Absolute>
            <LogoLink />
          </Absolute>
          {hasFetchedCampaignAndSubmission && (
            <FlexItem grow={1}>
              <Grid>
                <Row center="xs" middle="xs">
                  <Col sm={8} md={10}>
                    <Flex full alignCenter justifyBetween>
                      <FlexItem>
                        <TitleBarDesktopBackButton />
                      </FlexItem>
                      <FlexItem grow={1}>
                        <HeaderTitle>
                          <H5 color={Theme.grey900Color}>{submission.influencer_full_name}</H5>
                        </HeaderTitle>
                      </FlexItem>
                      <FlexItem>
                        {hasFirebaseUrl && <CampaignShareTrigger campaignId={campaign.id} />}
                        {hasReferralProgramLink && (
                          <InboxReferralTrigger campaignId={campaign.id} />
                        )}
                        {rateCardCurrency && (
                          <SubmissionRateCardPanelTriggerDesktop
                            submission={submission}
                            campaign={campaign}
                            rateCardCurrency={rateCardCurrency}
                          />
                        )}
                        <ActionIconWrapper>
                          <TrackedRoute
                            eventName={EventTrackingNames.ToolbarViewSubmissionNotes}
                            properties={this.props.getSubmissionTrackingProps()}
                          >
                            <Link
                              to={{
                                pathname: `${buildSubmissionUrl(
                                  campaign,
                                  submission,
                                )}/detail/notes`,
                                search,
                              }}
                            >
                              <ActionIcon
                                glyph="comments"
                                color={Theme.grey900Color}
                                tooltip={intl.formatMessage({ id: notesTipId })}
                                size={1.5}
                                highlighted={submission.notes_any}
                                showNotification={submission.notes_unread}
                                elementName="notes"
                              />
                            </Link>
                          </TrackedRoute>
                        </ActionIconWrapper>
                        <ActionIconWrapper>
                          <TrackedRoute
                            eventName={EventTrackingNames.ToolbarPreviewCampaign}
                            properties={{
                              ...this.props.getCampaignTrackingProps(),
                              ...this.props.getSubmissionLocationProps(),
                              current_view: 'submission_view',
                            }}
                          >
                            <Link
                              to={{
                                pathname: `${buildSubmissionUrl(campaign, submission)}/preview`,
                                search,
                              }}
                            >
                              <ActionIcon
                                glyph="preview"
                                color={Theme.grey900Color}
                                tooltip={intl.formatMessage({
                                  id: 'inbox.titlebar.action.preview',
                                })}
                                size={1.5}
                                elementName="preview"
                              />
                            </Link>
                          </TrackedRoute>
                        </ActionIconWrapper>
                      </FlexItem>
                    </Flex>
                  </Col>
                </Row>
              </Grid>
            </FlexItem>
          )}
        </Flex>
      </Fragment>
    )
  }
}
