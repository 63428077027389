import React from 'react'

import { isEmpty } from '@utils'
import SubmissionSort, { ISubmissionSortProps } from './Sort'

const SubmissionSortWrapper: React.FC<ISubmissionSortProps> = ({ sortOptions, campaignId }) => {
  const hasSortOptions = !isEmpty(sortOptions)
  if (!hasSortOptions) {
    return null
  } else {
    return <SubmissionSort sortOptions={sortOptions} campaignId={campaignId} />
  }
}

export { SubmissionSortWrapper }
export default SubmissionSortWrapper
