import React, { useContext } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { ActionMeta, components, MultiValueRemoveProps } from 'react-select'
import { compose } from 'recompose'

import { PanelWrapper } from '@components/Builder/Layout/Layout.styled'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { DraftContext, IDraftContextProps } from '@context/Draft'
import { EventTrackingNames } from '@enums'
import { withBuilderValidation, withDraft } from '@hocs'
import { IApplicationState } from '@store'
import { trackEvent } from '@store/analytics'
import {
  fetchBrandFanGroups,
  IBrandFanGroupOption,
  selectBrandFanGroupsByLabelAndValue,
} from '@store/brandFans'
import { selectCampaignBrandFanGroupIds } from '@store/campaigns'
import {
  selectDraftBrandFanGroupIds,
  selectIsAllowedToAssociateBrandFanGroup,
  selectIsDisabledAttribute,
} from '@store/drafts'
import Theme from '@theme'
import { Container, FormInput, H4, Header, Icon, Panel, Select, Text, TextField } from '@tribegroup'
import { getMessage, isEmpty } from '@utils'
import BudgetErrorMessage, { hasValidationError } from '../Error/Message'
import { BRAND_FAN_GROUP_IDS } from './CreatorGroup'
import BuilderBudgetCreatorGroupCampaignExpirationMessage from './ExpirationMessage'
import StarIcon from './StarIcon'

export const BRAND_FAN_GROUP_COUNT_VALID = 'brand_fan_group_count_valid'

interface IInternalProps
  extends ConnectedProps<typeof connector>,
    InjectedIntlProps,
    IBuilderValidationContextProps,
    IDraftContextProps {}

export const trackBrandFanSelection = (
  draftId: number,
  brandId: number,
  actionMeta: ActionMeta<any>,
  trackEventAction,
) => {
  if (actionMeta.action === 'select-option') {
    const option = (actionMeta as any).option
    const trackingProperties = {
      brief_id: draftId,
      brand_id: brandId,
      group_id: option.value,
      group_name: option.label,
    }
    trackEventAction(EventTrackingNames.CampaignBuilderBrandFansAdd, trackingProperties)
  }
  if (actionMeta.action === 'remove-value') {
    const removed = (actionMeta as any).removedValue
    const trackingProperties = {
      brief_id: draftId,
      brand_id: brandId,
      group_id: removed.value,
      group_name: removed.label,
    }
    trackEventAction(EventTrackingNames.CampaignBuilderBrandFansRemove, trackingProperties)
  }
}

const MultiValueRemove: React.FC<MultiValueRemoveProps<any>> = ({ ...props }) => {
  const { draft } = useContext(DraftContext)
  const { associatedBrandFanGroupIds, isBrandFanGroupDisabled } = useSelector(
    (state: IApplicationState) => {
      return {
        isBrandFanGroupDisabled: selectIsDisabledAttribute(state, BRAND_FAN_GROUP_IDS),
        associatedBrandFanGroupIds: selectCampaignBrandFanGroupIds(state, draft.id!),
      }
    },
  )

  if (isBrandFanGroupDisabled && associatedBrandFanGroupIds.includes(props.data.value)) {
    return null
  }
  return (
    <components.MultiValueRemove {...props}>
      <Icon glyph="cross-heavy" size={0.75} />
    </components.MultiValueRemove>
  )
}

const BuilderBudgetCreatorBrandFanGroups: React.FC<IInternalProps> = ({
  intl,
  updateForm,
  brandFanGroups,
  brandFanGroupIds,
  validationResults,
  clearFieldValidation,
  isAllowedToAssociateBrandFanGroup,
  fetchBrandFanGroups: fetchBrandFanGroupsAction,
  trackEvent: trackEventAction,
  draft,
}) => {
  React.useEffect(() => {
    fetchBrandFanGroupsAction()
  }, [])

  const onBrandFanGroupChange = (
    options: ReadonlyArray<IBrandFanGroupOption>,
    actionMeta: ActionMeta<any>,
  ) => {
    trackBrandFanSelection(
      draft.id as number,
      draft.brand_id as number,
      actionMeta,
      trackEventAction,
    )
    updateForm({
      brand_fan_group_ids: (options || []).map((option) => +option.value),
    })
  }

  const onClearError = () => clearFieldValidation(BRAND_FAN_GROUP_IDS)

  const defaultValue = brandFanGroupIds.map((bfgId) =>
    brandFanGroups.find((group) => group.value === bfgId),
  )

  const disabledOptions = (option: IBrandFanGroupOption) => !option.count

  return (
    <PanelWrapper data-testid="builder-budget-form-brand-fan-groups-section">
      <Panel
        title={
          <H4 color={Theme.defaultColor}>
            <FormattedMessage id="builder.budget.creatorGroup.brandFans.group.title" />
          </H4>
        }
        actionIcons={[<StarIcon key="star-rating" />]}
      >
        <FormInput
          validationRule={{
            required: {
              value: true,
              message: getMessage(intl, 'builder.budget.creatorGroup.brandFans.group.invalid'),
            },
          }}
        >
          <TextField
            name={BRAND_FAN_GROUP_COUNT_VALID}
            type="hidden"
            value={brandFanGroupIds.length > 0 ? 'valid' : ''}
            readOnly
          />
        </FormInput>
        <FormInput
          validationRule={{
            required: {
              value: true,
              message: getMessage(intl, 'builder.budget.creatorGroup.brandFans.group.required'),
            },
          }}
        >
          <Container topOuterSpacing={0.75}>
            <Text color={Theme.defaultColor}>
              <FormattedMessage id="builder.budget.creatorGroup.brandFans.group.subtitle" />
            </Text>
          </Container>
          <Container topOuterSpacing={2.5}>
            <Header small color={Theme.grey700Color} uppercase>
              <FormattedMessage id="core.text.brandFans" />
            </Header>
          </Container>
          {!isEmpty(brandFanGroups) ? (
            <Container topOuterSpacing={0.75}>
              <Select
                scheme="default"
                showAsTags
                dropdownHeight={15}
                height={4}
                fontSize={0.875}
                invalid={hasValidationError(validationResults, BRAND_FAN_GROUP_IDS)}
                pillBackgroundColor={Theme.primary100Color}
                pillXHoverColor={Theme.primary200Color}
                rsConfig={{
                  name: BRAND_FAN_GROUP_IDS,
                  inputId: BRAND_FAN_GROUP_IDS,
                  components: { MultiValueRemove },
                  onChange: onBrandFanGroupChange,
                  backspaceRemovesValue: false,
                  isDisabled: !isAllowedToAssociateBrandFanGroup,
                  defaultValue,
                  options: brandFanGroups as any,
                  onFocus: onClearError,
                  isOptionDisabled: disabledOptions,
                  placeholder: intl.formatMessage({
                    id: 'builder.budget.creatorGroup.brandFans.group.placeholder',
                  }),
                }}
              />
            </Container>
          ) : (
            <Container topOuterSpacing={1.25}>
              <Text color={Theme.grey800Color} italic>
                <FormattedMessage id="builder.budget.creatorGroup.brandFans.group.empty" />
              </Text>
            </Container>
          )}
        </FormInput>
        <BudgetErrorMessage
          results={validationResults}
          errorKey={brandFanGroups.length > 0 ? BRAND_FAN_GROUP_IDS : BRAND_FAN_GROUP_COUNT_VALID}
        />
        {!isAllowedToAssociateBrandFanGroup && (
          <BuilderBudgetCreatorGroupCampaignExpirationMessage />
        )}
      </Panel>
    </PanelWrapper>
  )
}

const mapDispatchToProps = {
  fetchBrandFanGroups,
  trackEvent,
}

const mapStateToProps = (state: IApplicationState) => ({
  brandFanGroupIds: selectDraftBrandFanGroupIds(state),
  brandFanGroups: selectBrandFanGroupsByLabelAndValue(state),
  isAllowedToAssociateBrandFanGroup: selectIsAllowedToAssociateBrandFanGroup(state),
})

const connector = connect(mapStateToProps, mapDispatchToProps)
export { BuilderBudgetCreatorBrandFanGroups }
export default compose<IInternalProps, {}>(
  injectIntl,
  withDraft,
  withBuilderValidation,
  connector,
)(BuilderBudgetCreatorBrandFanGroups)
