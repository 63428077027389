import Account from '@components/Account/Account'
import AccountTitleBar from '@components/Account/TitleBar/TitleBar'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import { PaddedContainerCentered } from '@components/UI/PaddedContainer/PaddedContainer.styled'
import { ToastContainer } from '@tribegroup'

export const AccountPage = () => (
  <MainLayout>
    <ToastContainer sidebarModifier={13.875} />
    <HeadTag id="core.text.myAccount" />
    <AccountTitleBar />
    <PaddedContainerCentered hasPaddingOnMobile>
      <Account />
    </PaddedContainerCentered>
  </MainLayout>
)

export default AccountPage
