import {
  FeedbackActionTypes,
  IAddFeedbackRequestBody,
  IFeedback,
  IFeedBackMeta,
} from '@store/feedback'

export const addFeedback = (
  submissionId: number,
  campaignId: number,
  requestBody: IAddFeedbackRequestBody,
  meta: IFeedBackMeta = {},
) => ({
  payload: { submissionId, campaignId, requestBody },
  type: FeedbackActionTypes.ADD_REQUEST,
  meta,
})

export const addFeedbackError = (error: any) => ({
  payload: error,
  type: FeedbackActionTypes.ADD_ERROR,
})

export const addFeedbackSuccess = (submissionId: number, feedback: IFeedback) => ({
  payload: { submissionId, feedback },
  type: FeedbackActionTypes.ADD_SUCCESS,
})
