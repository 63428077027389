import { IBrandManagerIdentity, ReconnectBrandManagerIdentity } from '@store/brandManagerIdentities'

export const reconnectIdentity = (brandManagerIdentityId: number) => ({
  type: ReconnectBrandManagerIdentity.RECONNECT_REQUEST,
  payload: brandManagerIdentityId,
})

export const reconnectIdentitySuccess = (brandManagerIdentity: IBrandManagerIdentity) => ({
  payload: brandManagerIdentity,
  type: ReconnectBrandManagerIdentity.RECONNECT_SUCCESS,
})

export const reconnectIdentityError = (error: any) => ({
  payload: error,
  type: ReconnectBrandManagerIdentity.RECONNECT_ERROR,
})
