import React from 'react'

import { Radio, Scheme } from '@tribegroup'

interface IProps {
  value: string
  scheme?: string
  checked?: boolean
  label: React.ReactNode
  labelColor?: string
  showSelectedAsCheck?: boolean
  disabled?: boolean
  onOptionChange: (selected: string) => void
}

const BuilderBudgetTargetAudienceOption: React.FC<IProps> = ({
  value,
  scheme = 'primary',
  onOptionChange,
  label,
  checked,
  labelColor,
  disabled,
  showSelectedAsCheck,
}) => {
  const onChange = () => onOptionChange(value)
  return (
    <Radio
      name="target_audience"
      scheme={scheme as Scheme}
      checked={checked}
      label={label}
      value={value}
      onChange={onChange}
      labelColor={labelColor}
      labelFontSize={1.125}
      disabled={disabled}
      disablePointerOnHover={disabled}
      showSelectedAsCheck={showSelectedAsCheck}
    />
  )
}

export default BuilderBudgetTargetAudienceOption
