import React from 'react'

import { FormattedMessage } from 'react-intl'

import { CampaignMenuActionsContext } from '@components/Campaign/Menu'
import ActionIcon from '@components/UI/ActionIcon'
import Breakpoint from '@components/UI/Breakpoint'
import Theme from '@theme'
import { DropdownMenu, MenuItem } from '@tribegroup'

export const InboxTitleBarMoreMenu: React.FC = () => {
  const {
    onDownloadInstagramHandles,
    onOpenConfirmModal,
    onOpenPauseModal,
    hasAnyAction,
    hasActivateLink,
    hasPauseLink,
    hasDownloadInstagramHandles,
  } = React.useContext(CampaignMenuActionsContext)

  if (!hasAnyAction) {
    return null
  }

  return (
    <React.Fragment>
      <DropdownMenu
        indicatorSpace={0}
        top={0}
        bottom="auto"
        right={-1.25}
        width={11.75}
        target={
          <ActionIcon
            glyph="more"
            size={1}
            color={Theme.grey900Color}
            key="titlebar-more-menu"
            elementName="more-menu"
          />
        }
      >
        <Breakpoint tabletPortrait tabletLandscape mobile>
          {hasDownloadInstagramHandles && (
            <MenuItem onClick={onDownloadInstagramHandles} elementName="download-creator-handles">
              <FormattedMessage id="inbox.titlebar.action.downloadInstagramHandles" />
            </MenuItem>
          )}
        </Breakpoint>
        {hasActivateLink && (
          <MenuItem onClick={onOpenConfirmModal} elementName="activate-campaign">
            <FormattedMessage id="campaign.campaignCard.menuAction.text.activateCampaign" />
          </MenuItem>
        )}
        {hasPauseLink && (
          <MenuItem onClick={onOpenPauseModal} elementName="pause-campaign">
            <FormattedMessage id="campaign.campaignCard.menuAction.text.pauseCampaign" />
          </MenuItem>
        )}
      </DropdownMenu>
    </React.Fragment>
  )
}

export default InboxTitleBarMoreMenu
