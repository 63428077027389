import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { IApplicationState } from '@store/index'
import { selectInfluencerIsProxy } from '@store/submissions'
import { Text } from '@tribegroup'
import { TagContainer } from './Tag.styled'

type Props = {
  submissionId: number
}

export const SubmissionCardHeaderTalentAgencyCreatorTag: React.FC<Props> = ({ submissionId }) => {
  const isProxy = useSelector((state: IApplicationState) =>
    selectInfluencerIsProxy(state, submissionId),
  )

  if (!isProxy) {
    return null
  }

  return (
    <TagContainer innerSpacing={0.1875} flexCenter>
      <Text uppercase light xxsmall>
        <FormattedMessage id="core.text.talentAgencyCreator" />
      </Text>
    </TagContainer>
  )
}
