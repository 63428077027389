import React from 'react'

import timeLinex1 from '@images/referral-timeline.png'
import timeLinex2 from '@images/referral-timeline@2x.png'
import timeLinex3 from '@images/referral-timeline@3x.png'

const InboxReferralTimelineGraphics: React.FC = () => (
  <img
    width={474}
    src={timeLinex1}
    srcSet={`${timeLinex1} 300w, ${timeLinex2} 768w, ${timeLinex3} 1280w`}
    alt="referral-program-modal-timeline-graphic"
  />
)

export default InboxReferralTimelineGraphics
