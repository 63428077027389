import styled from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'

export const RateCardStyled = styled.div`
  margin: 1.5rem 1.625rem;
  min-height: calc(100vh - 10.75rem);
`

export const RateCardRatingDivider = styled.div`
  border-top: solid 1px ${Theme.grey400Color};
  border-bottom: 0;
  padding-bottom: 0.75rem;
`

export const RateCardList = styled(Flex)`
  border-top: 1px solid ${Theme.grey400Color};
  background: ${Theme.whiteColor};
  margin: auto;
`

export const RateCardListItem = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0.75rem;

  &:first-of-type {
    border-right: 1px solid ${Theme.grey400Color};
    padding-left: 0;

    > div {
      margin-left: 0;
    }
  }

  &:last-of-type {
    padding-left: 1rem;
  }
`

export const RateCardFooter = styled.div`
  bottom: 0;
  position: sticky;
  width: inherit;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: ${Theme.whiteColor};
`
