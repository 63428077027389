import React, { useEffect } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { compose } from 'recompose'

import InfluencerIdentityRow, {
  IInfluencerIdentityRowProps,
  InfluencerIdentityRowRoot,
} from '@components/InfluencerIdentity/Row'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import {
  fetchSubmissions,
  selectIdentityAge,
  selectIdentityAvatar,
  selectIdentityFormattedLocationAndGender,
  selectIdentityFullName,
  selectIdentityHasGroup,
  selectIdentityProvider,
  selectIdentitySocialPageUrl,
  selectIdentitySubmissionsLink,
  selectIdentityUsername,
  selectIsBrandFanIdentityOpened,
  toggleIdentity,
} from '@store/brandFans'
import InfluencerIdentityRowSubmissionWrapper from './Submissions/Wrapper'
import BrandFanInfluencerIdentityRowActions from './Actions'
import BrandFanInfluencerIdentityRowLabels from './Labels'
import BrandFanInfluencerIdentityRowPerformance from './Performance'
import BrandFanInfluencerIdentityRowTrackedRoute from './TrackedRoute'

interface IProps {
  identityId: number
  showCTAs?: boolean
  horizontalPadding?: number
  showLocationGenderAgeSection?: boolean
  showSubmissions?: boolean
}

interface IInternalProps extends IProps, IInfluencerIdentityRowProps {
  hasGroup: boolean
  hasIdentitySubmissionsLink: boolean
  fetchSubmissions: typeof fetchSubmissions
}

const BrandFanInfluencerIdentityRow: React.FC<IInternalProps> = ({
  fullName,
  locationAndGender,
  age,
  socialUrl,
  avatarSrc,
  username,
  provider,
  hasGroup,
  identityId,
  horizontalPadding,
  hasIdentitySubmissionsLink,
  fetchSubmissions: fetchSubmissionsAction,
  showCTAs = true,
  showLocationGenderAgeSection = true,
  showSubmissions = true,
}) => {
  const dispatch = useDispatch()
  const isOpened = useSelector((state) => selectIsBrandFanIdentityOpened(state, identityId))

  const toggle = () => {
    dispatch(toggleIdentity(identityId))
  }

  const onFetch = () => {
    if (isOpened) {
      fetchSubmissionsAction(identityId)
    }
  }
  const viewSubmissionsAllowed = showSubmissions && hasIdentitySubmissionsLink

  useEffect(onFetch, [isOpened])

  return (
    <InfluencerIdentityRowRoot>
      <BrandFanInfluencerIdentityRowTrackedRoute
        identityId={identityId}
        eventName={EventTrackingNames.BrandFansRowClicked}
        additionalProperties={{
          action: isOpened ? 'close' : 'open',
        }}
      >
        <InfluencerIdentityRow
          onRowClick={viewSubmissionsAllowed ? toggle : undefined}
          horizontalPadding={horizontalPadding}
          avatarRightSpacing={1}
          fullName={fullName}
          socialUrl={socialUrl}
          avatarSrc={avatarSrc}
          username={username}
          provider={provider}
          showStar={hasGroup}
          age={age}
          locationAndGender={locationAndGender}
          showLocationGenderAge={showLocationGenderAgeSection}
          stats={
            <BrandFanInfluencerIdentityRowPerformance identityId={identityId} provider={provider} />
          }
          labels={showCTAs && <BrandFanInfluencerIdentityRowLabels identityId={identityId} />}
          actions={
            showCTAs && (
              <BrandFanInfluencerIdentityRowActions identityId={identityId} active={hasGroup} />
            )
          }
        />
      </BrandFanInfluencerIdentityRowTrackedRoute>
      {viewSubmissionsAllowed && isOpened && (
        <InfluencerIdentityRowSubmissionWrapper identityId={identityId} />
      )}
    </InfluencerIdentityRowRoot>
  )
}

const mapDispatchToProps = {
  fetchSubmissions,
}

const mapStateToProps = (state: IApplicationState, { identityId }: IProps) => ({
  fullName: selectIdentityFullName(state, identityId),
  age: selectIdentityAge(state, identityId),
  locationAndGender: selectIdentityFormattedLocationAndGender(state, identityId),
  socialUrl: selectIdentitySocialPageUrl(state, identityId),
  avatarSrc: selectIdentityAvatar(state, identityId),
  username: selectIdentityUsername(state, identityId),
  provider: selectIdentityProvider(state, identityId),
  hasGroup: selectIdentityHasGroup(state, identityId),
  hasIdentitySubmissionsLink: Boolean(selectIdentitySubmissionsLink(state, identityId)),
})

export { BrandFanInfluencerIdentityRow }
export default compose<IInternalProps, IProps>(
  React.memo,
  connect(mapStateToProps, mapDispatchToProps),
)(BrandFanInfluencerIdentityRow)
