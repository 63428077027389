import React, { PropsWithChildren } from 'react'

import Flex from 'styled-flex-component'

import Image from '@components/UI/Image'
import { AspectRatios } from '@enums'
import logo from '@images/brand-icon.png'
import Theme from '@theme'
import TitleBarStyled, {
  HeaderAboveSidebar,
  HeaderAction,
  HeaderContent,
  LogoLinkStyled,
  SelectionModeOverlay,
} from './TitleBar.styled'

export interface ITitleBarProps {
  id?: string
  actions?: React.ReactNode
  isSelectionModeOn?: boolean
  onSelectionOverlayClick?: VoidFunction
  desktopOnly?: boolean
}

export const TitleBarLogo = () => (
  <Image src={logo} aspectRatio={AspectRatios.ONE_BY_ONE} loadingColor={Theme.whiteColor} contain />
)

export const LogoLink = () => (
  <LogoLinkStyled to="/campaigns" data-cy-element="campaigns-button">
    <TitleBarLogo />
  </LogoLinkStyled>
)

export class TitleBar extends React.PureComponent<PropsWithChildren<ITitleBarProps>, any> {
  static defaultProps = {
    id: 'title-bar',
  }

  public render() {
    const {
      actions,
      id,
      children,
      isSelectionModeOn,
      onSelectionOverlayClick,
      desktopOnly,
    } = this.props
    return (
      <TitleBarStyled data-cy-element={id} desktop={desktopOnly}>
        <SelectionModeOverlay
          isSelectionModeOn={isSelectionModeOn}
          onClick={onSelectionOverlayClick}
        />
        <Flex justifyBetween full alignCenter>
          <HeaderAboveSidebar justifyBetween alignCenter>
            <LogoLink />
          </HeaderAboveSidebar>
          <HeaderContent justifyBetween alignCenter full>
            <Flex full alignCenter>
              {children}
            </Flex>
            {actions && <HeaderAction>{actions}</HeaderAction>}
          </HeaderContent>
        </Flex>
      </TitleBarStyled>
    )
  }
}

export default TitleBar
