export const isDefinedEnvVar = (varName: string): boolean => {
  const envVar = process.env[varName]
  if (!envVar) {
    return false
  }
  /**
   * For prod builds, environment variable is being substituted
   * with identifiable token pattern i.e. __ENV_VAR__
   * If it matches that pattern, treat this a falsy
   */
  const replacedToken = `__${varName.toUpperCase()}__`
  if (envVar === replacedToken) {
    return false
  }
  return true
}
