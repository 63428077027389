import { IMeta } from '@store'
import { BulkDeclineActionTypes } from '@store/submissions'

interface IBulkDeclineParams {
  submission_ids: ReadonlyArray<number>
  decline_reason: string
}

export const bulkDecline = (campaignId: number, params: IBulkDeclineParams, meta: IMeta) => ({
  payload: { campaignId, params },
  meta,
  type: BulkDeclineActionTypes.BULK_DECLINE_REQUEST,
})

export const bulkDeclineSuccess = (
  campaignId: number,
  submissionIds: ReadonlyArray<number>,
  meta?: object,
) => ({
  payload: { campaignId, submissionIds },
  meta,
  type: BulkDeclineActionTypes.BULK_DECLINE_SUCCESS,
})

export const bulkDeclineError = (error: any) => ({
  payload: error,
  type: BulkDeclineActionTypes.BULK_DECLINE_ERROR,
})
