import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import SortMenu from '@components/UI/SortMenu'
import { IApplicationState } from '@store'
import { fetchInfluencerIdentities } from '@store/brandFans'
import { selectInfluencerIdentitySortOptions } from '@store/me'
import BrandFansInfluencerIdentityTopPanelTrackedRoute from './TrackedRoute'

interface IInternalProps {
  sortOptions: ReadonlyArray<string>
  fetchInfluencerIdentities: typeof fetchInfluencerIdentities
}

const InfluencerIdentityTopPanelSort: React.FC<IInternalProps> = ({
  sortOptions,
  fetchInfluencerIdentities: fetchInfluencerIdentitiesAction,
}) => {
  const onSortByChange = () => {
    const resetList = true
    fetchInfluencerIdentitiesAction(false, resetList)
  }

  return (
    <SortMenu
      anchor={'right'}
      trackingWrapper={{
        component: BrandFansInfluencerIdentityTopPanelTrackedRoute,
        createProps: (value: string) => ({
          source: 'sort_filter',
          sortBy: value,
        }),
      }}
      options={sortOptions.map((option) => ({
        value: option,
        display: <FormattedMessage id={`brandFans.list.sort.option.${option}`} />,
      }))}
      onSortByChange={onSortByChange}
    />
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  sortOptions: selectInfluencerIdentitySortOptions(state),
})

const mapDispatchToProps = {
  fetchInfluencerIdentities,
}

export { InfluencerIdentityTopPanelSort }
export default compose(connect(mapStateToProps, mapDispatchToProps))(InfluencerIdentityTopPanelSort)
