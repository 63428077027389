import { Fragment, useContext } from 'react'

import { FormattedMessage } from 'react-intl'

import Confirmation from '@components/UI/Confirmation'
import { FooterButtonWrapper } from '@components/UI/Confirmation/Confirmation.styled'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { Button } from '@tribegroup'
import { BuilderTitleBarActionsConfirmCloseContext } from './Provider'

interface IProps {
  eventName: EventTrackingNames
  campaignId: number
}

export const BuilderTitleBarActionsConfirmCloseConfirmation = ({
  eventName,
  campaignId,
}: IProps) => {
  const { confirmationShown, onConfirm, onDiscard, onClose } = useContext(
    BuilderTitleBarActionsConfirmCloseContext,
  )

  return (
    <Confirmation
      title={<FormattedMessage id="builder.confirm.unsavedChanges.title" />}
      isOpen={confirmationShown}
      onCloseIconClick={onClose}
      content={null}
      showCloseIcon
      footerButtons={
        <Fragment>
          <FooterButtonWrapper>
            <ConnectedTrackedRoute
              disableTracking={!eventName}
              eventName={eventName}
              campaignId={campaignId}
              additionalProperties={{
                action: 'discard',
                current_filter: undefined,
              }}
            >
              <Button
                scheme="primary"
                quiet
                onClick={onDiscard}
                small
                data-cy-element="save-changes-modal-discard-button"
                data-testid="save-changes-modal-discard-button"
              >
                <FormattedMessage id="core.text.discard" />
              </Button>
            </ConnectedTrackedRoute>
          </FooterButtonWrapper>
          <FooterButtonWrapper>
            <ConnectedTrackedRoute
              disableTracking={!eventName}
              eventName={eventName}
              campaignId={campaignId}
              additionalProperties={{
                action: 'save',
                current_filter: undefined,
              }}
            >
              <Button
                scheme="primary"
                onClick={onConfirm}
                small
                data-cy-element="save-changes-modal-save-button"
                data-testid="save-changes-modal-save-button"
              >
                <FormattedMessage id="core.text.save" />
              </Button>
            </ConnectedTrackedRoute>
          </FooterButtonWrapper>
        </Fragment>
      }
    />
  )
}

export default BuilderTitleBarActionsConfirmCloseConfirmation
