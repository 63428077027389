import React from 'react'

import { ScrollPositionContext } from '@context/ScrollPosition'

export const withResetScrollPosition = (WrappedComponent: React.ComponentType) => {
  const InnerComponent: React.FC = (props) => {
    const { setScrollPosition } = React.useContext(ScrollPositionContext)
    React.useEffect(() => {
      setScrollPosition(0)
    }, [])
    return <WrappedComponent {...props} />
  }
  return InnerComponent
}
