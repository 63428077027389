import React from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import Breakpoint from '@components/UI/Breakpoint'
import { BrandedContentAdStatusTypes } from '@enums'
import { useCampaignIdFromRoute } from '@hooks/useCampaignIdFromRoute'
import { IApplicationState } from '@store'
import {
  promoteAsAd,
  selectIdentityProvider,
  selectIsSubmissionFromTiktok,
  selectTransactedBrandedContentAdStatus,
} from '@store/submissions'
import Theme from '@theme'
import { CheckboxWithState, Container, Text } from '@tribegroup'
import AuthCode from './AuthCode'
import BrandedContentAdFormButton from './Button'
import Duration from './Duration'
import DurationAndAmount from './DurationAndAmount'
import { DurationAndAmountContainer } from './Form.styled'
import BrandedContentAdPreview from './Preview'
import BrandedContentAdFormRightsAgreement from './RightsAgreement'
import TotalAmount from './TotalAmount'
interface IProps {
  submissionId: number
}

const BrandedContentAdForm: React.FC<IProps> = ({ submissionId }) => {
  const campaignId = useCampaignIdFromRoute()
  const methods = useForm({ mode: 'onChange' })

  const dispatch = useDispatch()
  const { brandedContentAdStatus, identityProvider, isTikTok } = useSelector(
    (state: IApplicationState) => {
      return {
        brandedContentAdStatus: selectTransactedBrandedContentAdStatus(state, submissionId),
        identityProvider: selectIdentityProvider(state, submissionId),
        isTikTok: selectIsSubmissionFromTiktok(state, submissionId),
      }
    },
  )

  const showHelperText = [
    BrandedContentAdStatusTypes.Requested,
    BrandedContentAdStatusTypes.PendingCreatorApproval,
    undefined,
  ].includes(brandedContentAdStatus)

  const isBCAGranted =
    brandedContentAdStatus === BrandedContentAdStatusTypes.Completed ||
    brandedContentAdStatus === BrandedContentAdStatusTypes.Approved ||
    brandedContentAdStatus === BrandedContentAdStatusTypes.Expired

  const showAuthCodeAndDuration = isTikTok && isBCAGranted

  const onSubmit = (data) => {
    const payload = {
      duration_in_days: parseInt(data.duration_in_days, 10),
      amount: parseInt(data.planned_spend_amount, 10),
      interested_in_media_package: data.interested_in_media_package,
    }

    dispatch(promoteAsAd(campaignId, submissionId, payload))
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} data-testid="branded-content-ad-form">
        <Container topOuterSpacing={2} leftInnerSpacing={1.25} rightInnerSpacing={1.25}>
          <Flex justifyEvenly>
            <DurationAndAmountContainer>
              {showAuthCodeAndDuration && (
                <Container bottomOuterSpacing={1.75} width={11.875}>
                  {brandedContentAdStatus !== BrandedContentAdStatusTypes.Expired && (
                    <AuthCode submissionId={submissionId} />
                  )}
                  <Duration submissionId={submissionId} />
                </Container>
              )}
              {!showAuthCodeAndDuration && (
                <DurationAndAmount submissionId={submissionId} campaignId={campaignId} />
              )}
              {showHelperText && (
                <Container topOuterSpacing={0.75} width={12}>
                  <Text color={Theme.grey800Color} small>
                    <FormattedMessage id={`brandedContentAds.${identityProvider}.modal.remember`} />
                  </Text>
                </Container>
              )}
            </DurationAndAmountContainer>
            <Breakpoint desktop tabletLandscape tabletPortrait>
              <Container textAlign="center" leftOuterSpacing={2}>
                <BrandedContentAdPreview submissionId={submissionId} />
              </Container>
            </Breakpoint>
          </Flex>
          {showAuthCodeAndDuration && <TotalAmount submissionId={submissionId} />}
        </Container>
        {brandedContentAdStatus === undefined && (
          <Container textAlign="center" topOuterSpacing={2.25}>
            <CheckboxWithState
              name="interested_in_media_package"
              scheme="primary"
              label={
                <Text color={Theme.grey800Color} small>
                  <FormattedMessage id="brandedContentAds.modal.mediaPackage.checkbox.label" />
                </Text>
              }
              data-testid="media-package-checkbox"
              innerRef={methods.register()}
            />
          </Container>
        )}

        <Container textAlign="center" topOuterSpacing={1.25}>
          <BrandedContentAdFormButton submissionId={submissionId} />
        </Container>
        <BrandedContentAdFormRightsAgreement campaignId={campaignId} submissionId={submissionId} />
        <Breakpoint mobile>
          <Flex justifyCenter>
            <Container topOuterSpacing={2} bottomOuterSpacing={2}>
              <BrandedContentAdPreview submissionId={submissionId} />
            </Container>
          </Flex>
        </Breakpoint>
      </form>
    </FormProvider>
  )
}

export default BrandedContentAdForm
