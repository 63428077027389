import React from 'react'

import { FormattedMessage } from 'react-intl'

import { BrandFanGroupMemberWarningContext } from '@context/BrandFanGroupMemberWarning'
import Theme from '@theme'
import { Button, Container, H5 } from '@tribegroup'

interface IProps {
  fullName: string
}

const BrandFansInfluencerIdentityRemoveFromLive: React.FC<IProps> = ({ fullName }) => {
  const { hideWarning } = React.useContext(BrandFanGroupMemberWarningContext)
  return (
    <React.Fragment>
      <H5 color={Theme.primaryColor}>
        <FormattedMessage
          id="brandFans.identityGroups.warning.removeFromLive"
          values={{ influencerName: fullName }}
        />
      </H5>
      <Container topOuterSpacing={2}>
        <Button fullWidth scheme="primary" onClick={hideWarning} data-testid="warning-button-okay">
          <FormattedMessage id="core.text.okay" />
        </Button>
      </Container>
    </React.Fragment>
  )
}

export { BrandFansInfluencerIdentityRemoveFromLive }
export default BrandFansInfluencerIdentityRemoveFromLive
