import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, useLocation, withRouter } from 'react-router'
import { compose } from 'recompose'

import { SideBarBlockItem } from '@components/UI/SideBar'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { INBOX_FILTER } from '@constants'
import { IMobileMenuControlsContextProps } from '@context/MobileMenuControls'
import { EventTrackingNames } from '@enums'
import { PageRoutes } from '@enums'
import { withMobileMenuControls } from '@hocs'
import { useInboxFilter } from '@hooks/useInboxFilter'
import { useLocationSearch } from '@hooks/useLocationSearch'
import { IApplicationState } from '@store'
import { selectSubmissionsFilters } from '@store/campaigns'
import { selectCampaignId, selectCurrentView } from '@store/router'
import { selectDefaultSortByFilter, selectSubmissionsStats } from '@store/submissions'
import { isEmpty, objectToQueryString } from '@utils'
import { isActiveMenu } from '@utils/urls'

interface IProps {
  filter: string
}

interface IInternalProps extends IProps, IMobileMenuControlsContextProps {
  campaignId: number
  submissionsStats: object
  filters: ReadonlyArray<string>
  defaultSortByFilters: Record<string, string>
  currentView: string
}

const InboxFiltersItem = ({
  filter,
  submissionsStats,
  defaultSortByFilters,
  campaignId,
  hideMobileMenu,
  currentView,
}) => {
  const currentSearch = useLocationSearch()
  const { currentFilter } = useInboxFilter()
  const { pathname } = useLocation()
  const newSearch = objectToQueryString({
    filter,
    sort_by: defaultSortByFilters[filter],
    status: currentSearch && currentSearch.status,
  })
  const targetPathname = `/${PageRoutes.Campaigns}/${campaignId}`
  const count = submissionsStats[filter]

  const active =
    currentView === INBOX_FILTER &&
    isActiveMenu(pathname, PageRoutes.Campaigns, currentFilter, filter)

  const onClick = () => {
    hideMobileMenu()
  }

  if (isEmpty(filter)) {
    return null
  }

  return (
    <ConnectedTrackedRoute
      campaignId={campaignId}
      eventName={EventTrackingNames.SwitchInboxFilter}
      additionalProperties={{ count, target_filter: filter }}
    >
      <SideBarBlockItem
        key={filter}
        text={<FormattedMessage id={`inbox.sidebar.filters.${filter}`} />}
        count={count}
        to={{ pathname: targetPathname, search: newSearch }}
        active={active}
        showNotification={false}
        onClick={onClick}
        elementName={`side-bar-${filter}`}
      />
    </ConnectedTrackedRoute>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  return {
    campaignId,
    submissionsStats: selectSubmissionsStats(state, campaignId),
    filters: selectSubmissionsFilters(state, campaignId),
    defaultSortByFilters: selectDefaultSortByFilter(state),
    currentView: selectCurrentView(state),
  }
}

export { InboxFiltersItem }
export default compose<IInternalProps, IProps>(
  withRouter,
  withMobileMenuControls,
  connect(mapStateToProps),
  withMobileMenuControls,
)(InboxFiltersItem)
