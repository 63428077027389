import { FormattedMessage } from 'react-intl'

import { CostBreakdownRow } from '@components/UI/CostBreakdown'
import { usePreapprovalAmountPercentage } from '@hooks/usePreapprovalAmountPercentage'
import { ISubmission } from '@store/submissions'

interface IProps {
  submission: ISubmission
  medium?: boolean
}

const SubmissionCostBreakdownSocialBalancePaid: React.FC<IProps> = ({ submission, medium }) => {
  const percentage = usePreapprovalAmountPercentage()
  const { amount_paid_before_tax_cents, amount_paid_before_tax_currency } = submission
  const balanceShown = 0 - amount_paid_before_tax_cents
  return (
    <CostBreakdownRow
      medium={medium}
      label={
        <FormattedMessage
          id="core.text.xPercentPaymentAndCurrency"
          values={{ percentValue: percentage, currency: amount_paid_before_tax_currency }}
        />
      }
      currency={amount_paid_before_tax_currency}
      quantity={balanceShown}
    />
  )
}

export default SubmissionCostBreakdownSocialBalancePaid
