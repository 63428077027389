import { createStatic } from 'styled-components-breakpoint'

import { breakpoints } from '@tribegroup/Theme/breakpoints'

export const getColor = (theme: IThemeInterface, scheme = '', modifier = '') => {
  return theme[`${scheme}${modifier}Color`]
}

export { default as themed } from '@tribegroup/Theme/themed'
export interface IThemeInterface {
  name: string

  defaultColor: string
  whiteColor: string

  primaryColor: string
  primary100Color: string
  primary200Color: string

  secondaryColor: string
  secondary700Color: string
  secondary500Color: string
  secondary300Color: string
  secondary100Color: string

  successColor: string
  success100Color: string

  contentColor: string

  alertColor: string
  alert100Color: string

  errorColor: string
  error100Color: string

  grey900Color: string
  grey800Color: string
  grey700Color: string
  grey600Color: string
  grey400Color: string
  grey300Color: string
  grey200Color: string
  grey100Color: string

  facebookBlue: string

  breakpoints: typeof breakpoints
}

export const defaultTheme: IThemeInterface = {
  name: 'Default Theme',

  defaultColor: '#4d4d4d',
  whiteColor: '#ffffff',

  grey900Color: '#4d4d4d',
  grey800Color: '#808080',
  grey700Color: '#b3b3b3',
  grey600Color: '#cbcbcb',
  grey400Color: '#e3e3e3',
  grey300Color: '#ebebeb',
  grey200Color: '#f4f4f4',
  grey100Color: '#fafafa',

  primaryColor: '#f848c4',
  primary200Color: '#fee9f8',
  primary100Color: '#fef2fb',

  secondaryColor: '#00b9d5',
  secondary700Color: '#4dcee1',
  secondary500Color: '#7fdcea',
  secondary300Color: '#b2eaf3',
  secondary100Color: '#e5f8fa',

  successColor: '#01d36d',
  success100Color: '#e7f6e8',

  contentColor: '#005fa3',

  alertColor: '#ffc112',
  alert100Color: '#fffae0',

  errorColor: '#e12e2e',
  error100Color: '#fff1f1',

  facebookBlue: '#1877f2',

  breakpoints,
}

export const useBreakpoint = (theme: IThemeInterface, boundary: string) =>
  createStatic(theme.breakpoints)[boundary]

export const useDefaultBreakpoint = (boundary: string) => createStatic(breakpoints)[boundary]

export const setAlpha = (hexColor: string, alpha: number) =>
  `${hexColor}${Math.round(alpha * 255).toString(16)}`

export { breakpoints }
