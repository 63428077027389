import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectInfluencerIdentitiesSortByOptions } from '@store/campaigns'
import { selectCampaignId } from '@store/router'
import { ISubmissionSortProps } from './Sort'
import SubmissionSortWrapper from './SortWrapper'

const SortInfluencerIdentityList = (props: ISubmissionSortProps) => (
  <SubmissionSortWrapper {...props} />
)

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  return {
    campaignId,
    sortOptions: selectInfluencerIdentitiesSortByOptions(state, campaignId),
  }
}

export default compose<ISubmissionSortProps, {}>(
  withRouter,
  connect(mapStateToProps),
)(SortInfluencerIdentityList)
