import Flex from 'styled-flex-component'

import Image from '@components/UI/Image'
import { AspectRatios } from '@enums'
import { IBrand } from '@store/brands'
import Theme from '@theme'
import { H5 } from '@tribegroup'
import { PanelWrapper } from '../../Layout/Layout.styled'
import HowToVideosTip from '../HowToVideos/Tip'
import { ImageWrapper, SelectedBrandWrapper } from './SelectedBrand.styled'

export interface ISelectedBrand {
  brand: IBrand
  showVideoTip?: boolean
}

const BuilderCampaignSelectedBrand = ({ brand, showVideoTip = true }: ISelectedBrand) => {
  return (
    <PanelWrapper data-cy-element="selected-brand-panel-wrapper">
      <SelectedBrandWrapper>
        {brand && (
          <Flex alignCenter>
            <ImageWrapper>
              <Image
                contain
                src={brand.logo_url}
                loadingColor={Theme.whiteColor}
                backgroundColor={Theme.whiteColor}
                aspectRatio={AspectRatios.ONE_BY_ONE}
              />
            </ImageWrapper>
            <H5 color={Theme.grey900Color}>{brand.name}</H5>
          </Flex>
        )}
        {showVideoTip && <HowToVideosTip />}
      </SelectedBrandWrapper>
    </PanelWrapper>
  )
}

export default BuilderCampaignSelectedBrand
