import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components'

import Theme from '@theme'

export const DrawerFormStyled = styled('div')`
  background: ${Theme.whiteColor};
  display: flex;
  padding: 0.5rem 1.5rem;
  min-height: 2.75rem;
`

export const DrawerFormTextareaAutosizeStyled = styled(TextareaAutosize)`
  border: none;
  color: ${Theme.grey900Color};
  display: block;
  font-size: 0.875rem;
  letter-spacing: 0.3px;
  line-height: 1.43;
  resize: none;
  width: 100%;
  height: 100%;
  flex: 1;
  margin-top: auto;
  margin-bottom: auto;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${Theme.grey700Color};
  }

  textarea {
    height: 100%;
  }

  &:disabled {
    color: ${Theme.grey700Color};
    background: transparent;
  }
`

export const DrawerFormActionButtonWrapper = styled.button`
  border: none;
  background: transparent;
  z-index: 2;
  height: 2.5rem;
`

export const DrawerFormUploadButton = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  transition: 0.2s;

  &:hover:enabled {
    background-color: ${Theme.grey200Color};
  }

  :disabled {
    cursor: unset;
  }
`

export const DrawerFormErrorContainer = styled.span`
  position: absolute;
  font-size: 0.875rem;
  bottom: 100%;
  display: block;
  background: ${Theme.errorColor};
  padding: 0.5rem 1rem;
  padding-right: 3.5rem;
  width: calc(100% - 3rem);
  box-sizing: border-box;
  border-radius: 0.5rem;
  color: ${Theme.whiteColor};
  line-height: 1.43;

  div[data-icon-glyph='close-x'] {
    top: 50%;
    bottom: 50%;
    right: 1rem;
  }
`
