import { call, put, select, takeLatest } from 'redux-saga/effects'

import { selectAuthToken } from '@store/auth'
import {
  fetchReelMetrics,
  fetchReelMetricsError,
  fetchReelMetricsSuccess,
  IReelMetricsResponse,
  ReelMetricsActionTypes,
  selectReelMetricsLink,
} from '@store/postCampaignReport'
import { networkRequest } from '@utils'
import { WatcherFunction } from '..'

export function* handleFetchReelMetrics(action: ReturnType<typeof fetchReelMetrics>) {
  const campaignId = action.payload

  try {
    const link = yield select(selectReelMetricsLink, campaignId)
    const authToken: string = yield select(selectAuthToken)

    const metrics: IReelMetricsResponse = yield call(
      networkRequest,
      link.method,
      link.href,
      undefined,
      authToken,
    )

    yield put(fetchReelMetricsSuccess(metrics, campaignId))
  } catch (error) {
    yield put(fetchReelMetricsError(error))
  }
}

function* watchFetchReelMetrics() {
  yield takeLatest(ReelMetricsActionTypes.FETCH_REQUEST, handleFetchReelMetrics)
}

export const reelMetricsSagas: ReadonlyArray<WatcherFunction> = [watchFetchReelMetrics]
