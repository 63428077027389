import { createAction } from '@reduxjs/toolkit'

import { IMeta } from '@store'
import { ITransactedLicense, PurchaseLicenseActionTypes } from '../types'

export const purchaseLicense = createAction(
  PurchaseLicenseActionTypes.PURCHASE_LICENSE,
  (
    mediaObjectId: number,
    licenseIndex: number,
    campaignId?: number,
    submissionId?: number,
    meta?: IMeta,
  ) => ({ payload: { mediaObjectId, licenseIndex, campaignId, submissionId, meta } }),
)

export const purchaseLicenseSuccess = createAction(
  PurchaseLicenseActionTypes.PURCHASE_LICENSE_SUCCESS,
  (mediaObjectId: number, transactedLicense: ITransactedLicense) => ({
    payload: { mediaObjectId, transactedLicense },
  }),
)

export const purchaseLicenseError = createAction<any>(
  PurchaseLicenseActionTypes.PURCHASE_LICENSE_ERROR,
)
