import React, { useContext } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { getPostCampaignReportPerformanceCount } from '@components/Reports/PostCampaignReport/Performance/Performance'
import { CampaignContext } from '@context/Campaign'
import { IPostCampaignReportSubmissionIdContextProps } from '@context/PostCampaignReportSubmissionId'
import { withContextPostCampaignReportSubmissionId } from '@hocs'
import { IApplicationState } from '@store'
import {
  IPCRPerformance,
  selectDisplaySubmissionPerformance,
  selectShowSubmissionPerformance,
} from '@store/postCampaignReport'
import Theme from '@theme'
import { Container, H5, Text } from '@tribegroup'
import PostCampaignReportSubmissionListPerformanceEmpty from './Empty/Empty'
import PostCampaignReportSubmissionListPerformanceLabelWithTooltip from './LabelWithTooltip'

interface IProps {
  campaignId: number
}

export interface IInternalProps extends IProps {
  showSubmissionMetrics: boolean
  postPerformance: ReadonlyArray<IPCRPerformance>
}

export const getWidth = (index, length) => {
  const firstAndLastItemWidth = 6.25
  const middleItemWidth = 5

  return index === 0 || index === length - 1 ? firstAndLastItemWidth : middleItemWidth
}

const PostCampaignReportSubmissionListPerformance: React.FC<IInternalProps> = ({
  postPerformance,
  showSubmissionMetrics,
  campaignId,
}) => {
  const {
    campaign: { is_price_hidden: isPriceHidden },
  } = useContext(CampaignContext)

  if (!showSubmissionMetrics) {
    return <PostCampaignReportSubmissionListPerformanceEmpty />
  }

  let data = postPerformance
  if (isPriceHidden) {
    data = data.filter(
      (item) => !item.key.includes('costPerEngagement') && !item.key.includes('purchaseAmount'),
    )
  }

  const abbreviateValue = true

  return (
    <Flex>
      {data.map((postMetrics, index) => (
        <Container
          key={`performance-post-${index}`}
          textAlign="center"
          width={getWidth(index, postPerformance.length)}
        >
          <H5 color={Theme.defaultColor}>
            {getPostCampaignReportPerformanceCount(
              postMetrics.value,
              postMetrics.currency,
              postMetrics.showPercentSign,
              abbreviateValue,
            )}
          </H5>
          {!postMetrics.showTooltip ? (
            <Text xsmall color={Theme.grey700Color} uppercase>
              <FormattedMessage
                id={`postCampaignReport.submission.list.performance.${postMetrics.key}`}
              />
            </Text>
          ) : (
            <PostCampaignReportSubmissionListPerformanceLabelWithTooltip
              campaignId={campaignId}
              title={postMetrics.key}
              currency={postMetrics.currency!}
            />
          )}
        </Container>
      ))}
    </Flex>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { contextPostCampaignReportSubmissionId }: IPostCampaignReportSubmissionIdContextProps,
) => ({
  postPerformance: selectDisplaySubmissionPerformance(state, contextPostCampaignReportSubmissionId),
  showSubmissionMetrics: selectShowSubmissionPerformance(
    state,
    contextPostCampaignReportSubmissionId,
  ),
})

export { PostCampaignReportSubmissionListPerformance }
export default compose<IInternalProps, IProps>(
  withRouter,
  withContextPostCampaignReportSubmissionId,
  connect(mapStateToProps),
)(PostCampaignReportSubmissionListPerformance)
