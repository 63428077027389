import { PureComponent } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { autoSignIn } from '@store/auth'
import { queryStringtoObject } from '@utils'

interface IInternalProps extends RouteComponentProps {
  autoSignIn: typeof autoSignIn
}

export class AuthAutoSignInRedirect extends PureComponent<IInternalProps> {
  componentDidMount() {
    const { history } = this.props
    const query: any = queryStringtoObject(history.location.search)
    if (query.token) {
      this.props.autoSignIn(
        { token: query.token },
        { history, redirect: query.campaign_id && `/campaigns/${query.campaign_id}` },
      )
    }
  }

  render() {
    return null
  }
}

const mapDispatchToProps = {
  autoSignIn,
}

export default compose<IInternalProps, {}>(
  withRouter,
  connect(undefined, mapDispatchToProps),
)(AuthAutoSignInRedirect)
