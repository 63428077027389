import { FormattedMessage } from 'react-intl'

import { AnalyticsCustomPrice } from '@components/UI/Price/Price'
import Theme from '@theme'
import { Text } from '@tribegroup'
import abbreviateNumber from '@utils/abbreviateNumber'
import { MetricEntry } from '../types'

type Props = { entry: MetricEntry; currency: string }

const Value: React.FC<Props> = ({ currency, entry }) => {
  if (!entry.value) {
    return <FormattedMessage id="core.text.notapplicable" />
  }

  if (entry.type === 'currency') {
    return (
      <AnalyticsCustomPrice
        quantity={typeof entry.value === 'number' ? entry.value : Number(entry.value)}
        currency={currency}
      />
    )
  }

  if (entry.type === 'percentage') {
    return <FormattedMessage id="core.text.percent" values={{ value: entry.value }} />
  }

  return `${abbreviateNumber(Number(entry.value))}`
}

export const AnalyticsBreakdownMetricTableRow: React.FC<Props> = ({ entry, currency }) => {
  const isValueNotAvailable = entry.value === null

  return (
    <tr data-testid={`${entry.metric}-metric`}>
      <td width="40%">
        <Text>
          <FormattedMessage id={`analytics.breakdown.metric.${entry.metric}`} />
        </Text>
      </td>
      <td width="35%">
        <Text color={!entry.source ? Theme.grey700Color : undefined}>
          <FormattedMessage
            id={
              !entry.source
                ? 'core.text.notapplicable'
                : `analytics.breakdown.source.${entry.source}`
            }
          />
        </Text>
      </td>
      <td width="25%">
        <Text color={isValueNotAvailable ? Theme.grey700Color : undefined}>
          <Value currency={currency} entry={entry} />
        </Text>
      </td>
    </tr>
  )
}
