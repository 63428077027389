import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { TrackedRoute } from '@components/UI/TrackedRoute'
import { IModalComponentProps } from '@context/Modal'
import { ContentLibraryFilterTypes, EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import {
  selectMediaObjectsStatsTotal,
  selectShareableContentLibraryLink,
} from '@store/mediaObjects'
import Theme from '@theme'
import { Button, Container, Header, Link, Toaster } from '@tribegroup'
import { clipboard } from '@utils'
import ShareThisLibraryToast from './Toast'

interface IProps {
  onRequestClose: VoidFunction
}

interface IInternalProps extends IModalComponentProps, IProps {
  shareableContentLibraryURL: string
  librarySize: number
}

const ShareThisLibraryModalActions: React.FC<IInternalProps> = ({
  shareableContentLibraryURL,
  onRequestClose,
  librarySize,
}) => {
  const onCopyUrl = async () => {
    await clipboard.copy(shareableContentLibraryURL)
    Toaster.createToast(ShareThisLibraryToast)
    onRequestClose()
  }

  return (
    <Flex center data-testid="shareable-cl-modal-actions-container">
      <Container maxWidth={10.8125} width="50%">
        <TrackedRoute
          eventName={EventTrackingNames.ShareCLPreview}
          properties={{ library_size: librarySize }}
        >
          <Link
            href={shareableContentLibraryURL}
            target="_blank"
            color={Theme.primaryColor}
            data-testid="shareable-cl-modal-preview-link"
          >
            <Button scheme="primary" height={3} outlined width={10.8125} fullWidth noFocus>
              <Header cta>
                <FormattedMessage id="core.text.preview" />
              </Header>
            </Button>
          </Link>
        </TrackedRoute>
      </Container>
      <Container maxWidth={10.8125} width="50%" leftOuterSpacing={0.6875}>
        <TrackedRoute
          eventName={EventTrackingNames.ShareCLCopyURL}
          properties={{ library_size: librarySize }}
        >
          <Button scheme="primary" height={3} fullWidth onClick={onCopyUrl} noFocus>
            <Header cta>
              <FormattedMessage id="core.text.copyURL" />
            </Header>
          </Button>
        </TrackedRoute>
      </Container>
    </Flex>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  shareableContentLibraryURL: selectShareableContentLibraryLink(state),
  librarySize: selectMediaObjectsStatsTotal(state, ContentLibraryFilterTypes.DISCOVER),
})

export { ShareThisLibraryModalActions }
export default compose<IInternalProps, IProps>(connect(mapStateToProps))(
  ShareThisLibraryModalActions,
)
