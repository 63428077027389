import React from 'react'

import { FormattedMessage } from 'react-intl'

import TitleBar, { TitleBarMobile } from '@components/UI/TitleBar'
import { useBrandFansFilter } from '@hooks/useBrandFansFilter'

const BrandFansTitleBar: React.FC = () => {
  const { currentFilter } = useBrandFansFilter()

  return (
    <React.Fragment>
      <TitleBar desktopOnly />
      <TitleBarMobile
        title={<FormattedMessage id={`brandFans.sidebar.filter.${currentFilter}`} />}
      />
    </React.Fragment>
  )
}

export { BrandFansTitleBar }
export default BrandFansTitleBar
