import { Fragment } from 'react'

import { Icon } from './../../Icon'
import { defaultTheme } from './../../Theme'
import { Text } from './../../Typography'
import { RemoveIconWrapper, Tag } from './TagSet.styled'

interface ITagSetProps {
  attr: string
  list: ReadonlyArray<string>
  onRemoveTag: (attr, item) => void
  pillBackgroundColor?: string
}

export const TagSet = ({ attr, list = [], pillBackgroundColor, onRemoveTag }: ITagSetProps) => {
  const onClick = (event) => {
    const target = event.target as HTMLButtonElement
    const element = target.closest('[data-item]')
    const item = element!.getAttribute('data-item')
    if (onRemoveTag) {
      onRemoveTag(attr, item)
    }
  }

  return (
    <Fragment>
      {list.map((item) => {
        return (
          <Tag key={`${attr}-${item}`} pillBackgroundColor={pillBackgroundColor}>
            <Text color={defaultTheme.defaultColor}>{item}</Text>
            <RemoveIconWrapper data-item={item}>
              <Icon
                onClick={onClick}
                size={1}
                color={defaultTheme.defaultColor}
                glyph="cross-heavy"
              />
            </RemoveIconWrapper>
          </Tag>
        )
      })}
    </Fragment>
  )
}
