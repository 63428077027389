import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'

export const BreakdownStyled = styled.div`
  margin-bottom: 3.75rem;
`

export const BreakdownTableWrapper = styled.div`
  overflow: auto;
`

export const BreakdownTable = styled.table`
  width: 100%;
`

/* stylelint-disable block-no-empty */
export const BreakdownDownloadLink = styled.div``
/* stylelint-enable block-no-empty */

/* stylelint-disable block-no-empty */
export const BreakdownArrow = styled.div``
/* stylelint-enable block-no-empty */

export const BreakdownTableRow = styled.tr`
  border-bottom: 1px solid ${Theme.grey200Color};

  ${BreakdownArrow} {
    &:first-child {
      display: initial;
    }

    &:last-child {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: ${Theme.grey100Color};
    ${BreakdownArrow} {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: initial;
      }
    }
  }

  ${breakpoint('desktop')`
    td:last-child > ${BreakdownDownloadLink} {
      visibility: hidden;
    }
    &:hover {
      td:last-child > ${BreakdownDownloadLink} {
        visibility: visible;
      }
    }
  `};

  &:last-of-type {
    border: none;
  }
`

export const BreakdownTableHeader = styled.th`
  text-align: left;
  padding: 0 0.625rem 0.8125rem;
`

export const BreakdownTableData = styled.td`
  height: 1.75rem;
  padding: 0.75rem 0.625rem;
  vertical-align: middle;
`

export const BreakdownTableDataActionContainer = styled.div`
  min-width: 1.375rem;
`
