import styled from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'

export const CommentListStyled = styled(Flex)`
  margin: 0 1.625rem;
  min-height: calc(100vh - 7.75rem);
  padding-bottom: 1rem;
`

export const CommentBlockStyled = styled('div')`
  width: 100%;
  position: relative;
  text-align: center;
  padding-top: 1.25rem;
`

export const CommentBlockMessage = styled('span')`
  display: block;
  border-radius: 0.5rem;
  text-align: left;
  background-color: ${Theme.alert100Color};
  margin-top: 0.4375rem;
  margin-bottom: 0.25rem;
  padding: 0.75rem 1rem 0.8125rem;
  word-wrap: break-word;
`

export const CommentFormStyled = styled('div')`
  bottom: 0;
  position: sticky;
  width: inherit;
`

export const CommentFormDivider = styled('div')`
  margin: 0 1.625rem;
  border-top: solid 1px ${Theme.grey400Color};
  border-bottom: 0;
`

export const CommentFormTextAreaWrapper = styled('div')`
  width: 100%;
`
