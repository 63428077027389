import { Fragment } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import StripeError from '@components/UI/Error/StripeError'
import { IModalContextProps } from '@context/Modal'
import { PublishOptions } from '@enums'
import { withModal } from '@hocs'
import { IResponseError } from '@lib/error'
import { IApplicationState } from '@store'
import { selectBrandManagerIdentitiesLink } from '@store/me'
import { IOption } from '@store/rootResource'
import { selectCampaignId } from '@store/router'
import {
  ISubmission,
  selectErrorCode,
  selectErrors,
  selectIsApproving,
  selectIsInfluencerOutsideTimezone,
  selectIsPriceHidden,
  selectPriceBeforePreApproval,
  selectShowSamplingHint,
} from '@store/submissions'
import Theme from '@theme'
import { Button, CloseIcon, Container, Header, ModalTitle, Text } from '@tribegroup'
import InfluencerOutsideZoneHint from '../components/InfluencerIdentityOutsideZoneHint/InfluencerIdentityOutsideZoneHint'
import PublishingDatePicker from '../components/PublishingDatePicker/PublishingDatePicker'
import PublishingManagerZoneHint from '../components/PublishingManagerZoneHint/PublishingManagerZoneHint'
import PublishingOptions from '../components/PublishingOptions/PublishingOptions'
import SamplingCampaignHint from '../components/SamplingCampaignHint/SamplingCampaignHint'
import SubmissionCardModalTermsOfService from '../components/TermsOfService'
import { SubmissionCardModalApproveCostBreakdownSpacer } from './Approve.styled'
import SubmissionCardModalApproveCostBreakdown from './CostBreakdown'

interface ISubmissionCardModalApproveProps {
  submission: ISubmission
  onCloseModal: VoidFunction
  selectedOptionValue?: string | number
  selectedDateValue?: Date
  showDatePicker: boolean
  onApproveSubmission: VoidFunction
  hasInsufficientFunds: boolean
  hasPriceChangeSincePreApproval?: boolean
  onChangePublishingOption: (option: IOption) => void
  onChangeDateValue: (date: Date) => void
}

interface IInternalProps
  extends ISubmissionCardModalApproveProps,
    InjectedIntlProps,
    IModalContextProps {
  isApproving?: boolean
  error?: IResponseError
  errorMessage?: string
  errorCode?: string
  showSamplingHint: boolean
  isInfluencerOutsideTimezone: boolean
  hasbrandManagerIdentitiesLink?: boolean
  showCostBreakdown: boolean
  hasError: boolean
}

export const SubmissionCardModalApprove = ({
  isInfluencerOutsideTimezone,
  showSamplingHint,
  submission,
  selectedOptionValue,
  selectedDateValue,
  showDatePicker,
  errorCode,
  isApproving,
  hasPriceChangeSincePreApproval,
  intl,
  hasInsufficientFunds,
  showCostBreakdown,
  hasError,
  ...props
}: IInternalProps) => {
  const hasChosenDateOption = selectedOptionValue === PublishOptions.CHOOSE_A_DATE
  const hasSelectedDateValue = hasChosenDateOption && Boolean(selectedDateValue)
  const hasNoChosenOption = !selectedOptionValue
  const showPriceAdjustmentHint = hasPriceChangeSincePreApproval

  const publishingOptions = [PublishOptions.WITHIN_48_HOURS, PublishOptions.CHOOSE_A_DATE].map(
    (option: string) => ({
      value: option,
      label: intl.formatMessage({
        id: `socialSubmission.card.modal.approve.${option}`,
      }),
    }),
  )

  const showInfluencerZoneHint =
    !showSamplingHint && isInfluencerOutsideTimezone && hasNoChosenOption

  const showSamplingCampaignHint = showSamplingHint && hasNoChosenOption

  const shouldEnableButton =
    selectedOptionValue === PublishOptions.WITHIN_48_HOURS || hasSelectedDateValue

  const showManagerZoneHint = hasSelectedDateValue

  const showAnyHint = [showSamplingCampaignHint, showManagerZoneHint, showInfluencerZoneHint].some(
    Boolean,
  )

  const showGenericError = hasError && !hasInsufficientFunds && !errorCode

  return (
    <Fragment>
      <ModalTitle>
        <FormattedMessage id="core.text.finalApproval" />
      </ModalTitle>
      <CloseIcon onClick={props.onCloseModal} />
      <Container topOuterSpacing={2.875} bottomOuterSpacing={0.75}>
        <Header small color={Theme.grey900Color} uppercase>
          <label htmlFor="publishing-options">
            <FormattedMessage id="socialSubmission.card.modal.approve.scheduleToGoLive" />
          </label>
        </Header>
      </Container>
      <PublishingOptions
        hasChosenDateOption={hasChosenDateOption}
        selectedDate={selectedDateValue}
        onChangeOption={props.onChangePublishingOption}
        placeholder={intl.formatMessage({
          id: 'socialSubmission.card.modal.approve.chooseADate',
        })}
        options={publishingOptions}
      />
      {showAnyHint && (
        <Container topOuterSpacing={1} bottomOuterSpacing={2.5}>
          {showSamplingCampaignHint && <SamplingCampaignHint />}
          {showManagerZoneHint && <PublishingManagerZoneHint selectedDate={selectedDateValue!} />}
          {showInfluencerZoneHint && (
            <InfluencerOutsideZoneHint
              influencerName={submission.influencer_full_name}
              influencerCountry={submission.influencer_country!}
            />
          )}
        </Container>
      )}
      {showDatePicker && (
        <Container topOuterSpacing={2.5}>
          <PublishingDatePicker
            selectedDate={selectedDateValue}
            onSelectDate={props.onChangeDateValue}
          />
        </Container>
      )}
      {!showDatePicker && (
        <Fragment>
          <Container topOuterSpacing={2.5}>
            {showCostBreakdown ? (
              <SubmissionCardModalApproveCostBreakdown
                submission={submission}
                hasPriceChangeSincePreApproval={hasPriceChangeSincePreApproval}
              />
            ) : (
              <SubmissionCardModalApproveCostBreakdownSpacer />
            )}
          </Container>
          {showPriceAdjustmentHint && (
            <Container topOuterSpacing={1.75}>
              <Text small color={Theme.grey800Color}>
                <FormattedMessage id="socialSubmission.card.modal.preapprove.priceAdjustmentHint" />
              </Text>
            </Container>
          )}
        </Fragment>
      )}
      <Container topOuterSpacing={2.5}>
        {!hasInsufficientFunds && errorCode && <StripeError errorCode={errorCode} />}

        {showGenericError && (
          <Text color={Theme.errorColor} small>
            <FormattedMessage id="core.text.shortGenericError" />
          </Text>
        )}

        <Button
          fullWidth
          scheme="primary"
          height={3}
          onClick={props.onApproveSubmission}
          disabled={!shouldEnableButton || isApproving}
          loading={isApproving}
          topOuterSpacing={1}
          data-cy-element="approve-submission-modal-approve-button"
          data-testid="approve-submission-modal-approve-button"
        >
          <Header cta>
            <FormattedMessage id="core.text.approve" />
          </Header>
        </Button>
      </Container>
      <SubmissionCardModalTermsOfService />
    </Fragment>
  )
}

export const mapStateToProps = (
  state: IApplicationState,
  { match, submission }: ISubmissionCardModalApproveProps & RouteComponentProps,
) => {
  const campaignId = selectCampaignId(match) as number
  const showCostBreakdown = !selectIsPriceHidden(state, submission.id)
  return {
    errorCode: selectErrorCode(state),
    isApproving: selectIsApproving(state),
    hasPriceChangeSincePreApproval: Boolean(
      selectPriceBeforePreApproval(state, submission.id, campaignId),
    ),
    showSamplingHint: selectShowSamplingHint(state, campaignId, submission.id),
    isInfluencerOutsideTimezone: selectIsInfluencerOutsideTimezone(state, submission.id),
    hasbrandManagerIdentitiesLink: selectBrandManagerIdentitiesLink(state),
    showCostBreakdown,
    hasError: Boolean(selectErrors(state)),
  }
}

export default compose<IInternalProps, ISubmissionCardModalApproveProps>(
  withRouter,
  withModal,
  injectIntl,
  connect(mapStateToProps, undefined),
)(SubmissionCardModalApprove)
