import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import {
  CompleteSignUpActionType,
  completeSignUpFailed,
  completeSignUpRequest,
  completeSignUpSuccess,
} from '@store/completeSignup'
import { selectCompleteVerificationLink } from '@store/rootResource'
import { networkRequest } from '@utils'
import { WatcherFunction } from '..'

export function* handleCompleteSignUpRequest(action: ReturnType<typeof completeSignUpRequest>) {
  try {
    const data = action.payload

    const completeSignupLink: IHateoasLink = yield select(selectCompleteVerificationLink)
    yield call(networkRequest, completeSignupLink.method, completeSignupLink.href, data, undefined)
    yield put(completeSignUpSuccess())
  } catch (error) {
    yield put(completeSignUpFailed(error))
  }
}

export function* watchCompleteSignupRequest() {
  yield takeLatest(CompleteSignUpActionType.PENDING, handleCompleteSignUpRequest)
}

export const completeSignUpSagas: ReadonlyArray<WatcherFunction> = [watchCompleteSignupRequest]
