import { InjectedIntlProps, injectIntl } from 'react-intl'

import Theme from '@theme'
import { Icon } from '@tribegroup'

interface IInfluencerIdentityLabelsInsights {
  small?: boolean
}

interface IInternalProps extends InjectedIntlProps, IInfluencerIdentityLabelsInsights {}

export const InfluencerIdentityLabelsInsights = ({ small, intl }: IInternalProps) => {
  const iconProps = small
    ? { size: 0.75, color: Theme.primaryColor }
    : { size: 0.9375, color: Theme.defaultColor }
  return (
    <Icon
      glyph="insights"
      data-tooltip-id="base-tooltip"
      data-tooltip-content={intl.formatMessage({ id: 'audienceInsights.tooltip' })}
      {...iconProps}
    />
  )
}

export default injectIntl(InfluencerIdentityLabelsInsights)
