import AuthSignUpConfirmation from '@components/Auth/SignUp/Confirmation'
import FullImageBackground from '@components/UI/FullImageBackground'

const SignUpConfirmationPage = () => (
  <FullImageBackground>
    <AuthSignUpConfirmation />
  </FullImageBackground>
)

export default SignUpConfirmationPage
