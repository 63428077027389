import React from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import InfluencerIdentityRowLabelTooltip from '@components/InfluencerIdentity/Row/LabelTooltip'
import InfluencerIdentityRowValueAndLabel from '@components/InfluencerIdentity/Row/ValueAndLabel'
import { useInfluencerIdentityId } from '@context/InfluencerIdentityId/Provider'
import { SubmissionChannels } from '@enums'
import { IApplicationState } from '@store'
import {
  selectInfluencerIdentityAverageEngagementPercent,
  selectInfluencerIdentityPlatformProvider,
} from '@store/influencerIdentities'
import { selectCampaignId } from '@store/router'

const InfluencerIdentityRowPerformanceAverageEngagement = () => {
  const match = useRouteMatch()

  const { contextInfluencerIdentityId } = useInfluencerIdentityId()
  const { identityProvider, engagement } = useSelector((state: IApplicationState) => {
    const campaignId = selectCampaignId(match)!
    return {
      engagement: selectInfluencerIdentityAverageEngagementPercent(
        state,
        campaignId,
        contextInfluencerIdentityId,
      ),
      identityProvider: selectInfluencerIdentityPlatformProvider(
        state,
        campaignId,
        contextInfluencerIdentityId,
      ),
    }
  })
  const isTikTok = identityProvider === SubmissionChannels.TikTok

  return (
    <InfluencerIdentityRowValueAndLabel
      value={
        engagement ? (
          <FormattedMessage id="core.text.percent" values={{ value: engagement }} />
        ) : (
          <FormattedMessage id="core.metric.empty" />
        )
      }
      label={
        <InfluencerIdentityRowLabelTooltip
          width={15.25}
          title={<FormattedMessage id="submission.performance.averageEngagement.title" />}
          body={
            <FormattedMessage
              id={
                isTikTok
                  ? 'submission.performance.averageEngagement.tiktok.body'
                  : 'submission.performance.averageEngagement.body'
              }
            />
          }
        >
          <FormattedMessage id="submission.performance.averageEngagement" />
        </InfluencerIdentityRowLabelTooltip>
      }
    />
  )
}

export default InfluencerIdentityRowPerformanceAverageEngagement
