import { IResponseError } from '@lib/error'
import { IFeatureHateoasLink, IHateoasLink } from '@store'
import { ISubmission } from '@store/submissions'

export interface IInfluencerIdentityAnalytics {
  readonly avg_reach_pct?: number
  readonly avg_engagement_pct?: number
  readonly avg_impressions?: number
}

export interface IInfluencerIdentity {
  readonly id: number
  readonly identity_avatar: string
  readonly influencer_full_name: string
  readonly influencer_first_name: string
  readonly influencer_last_name: string
  readonly identity_username: string
  readonly identity_provider: string
  readonly influencer_state: string
  readonly influencer_country: string
  readonly influencer_gender: string
  readonly influencer_age: number
  readonly identity_analytics?: IInfluencerIdentityAnalytics
  readonly membership_group_ids: ReadonlyArray<number>
  readonly links: ReadonlyArray<IHateoasLink | IFeatureHateoasLink>
  readonly identity_social_page_url: string
  readonly identity_followers_count: number
  readonly identity_collabs: number
}

export interface IBrandFanEditableFields {
  readonly name: string
  readonly description?: string
}

export interface IMemberAvatar {
  avatar_url: string
}

export interface IBrandFanGroupOption {
  label: string
  value: number
  count: number
}

export interface IBrandFanGroup extends IBrandFanEditableFields {
  readonly id: number
  readonly live_briefs_any: boolean
  readonly member_count: number
  readonly first_ten_member_avatars: ReadonlyArray<IMemberAvatar>
  readonly links: ReadonlyArray<IHateoasLink>
}

export interface IBrandFanStats {
  all?: number
  brand_fans?: number
  groups?: number
}

export interface IBrandFanMember {
  id: number
  brand_fan_group_id: number
  identity_id: number
  influencer_id: number
}

export interface IBrandFansState {
  readonly isFetchingIdentities?: boolean
  readonly isFetchingBrandFanGroups?: boolean
  readonly isUpdatingBrandFanGroup?: boolean
  readonly isCreatingBrandFanGroup?: boolean
  readonly isAddingOrRemovingBrandFanGroupMember?: boolean
  readonly identitiesById: Record<number, IInfluencerIdentity>
  readonly identityIdsByFilter: Record<string, ReadonlyArray<number>>
  readonly groupsById: Record<number, IBrandFanGroup>
  readonly groupIds: ReadonlyArray<number>
  readonly identityIdsByGroupId: Record<number, ReadonlyArray<number>>
  readonly errors?: IResponseError
  readonly stats?: IBrandFanStats
  readonly linksByFilter: Record<string, ReadonlyArray<IHateoasLink>>
  readonly membersLinksByGroupId: Record<string, ReadonlyArray<IHateoasLink>>
  readonly submissionsByIdentityId: Record<number, ReadonlyArray<ISubmission>>
  readonly pendingRequestsByInfluencerIdentityId: ReadonlyArray<number>
  readonly pendingRequestsByBrandFanGroupId: ReadonlyArray<number>
  readonly openedIdentities: Record<number, boolean>
  readonly membersByGroupId: Record<number, Record<number, IBrandFanMember>>
  readonly membersByInfluencerId: Record<number, ReadonlyArray<IBrandFanMember>>
  readonly membersByIdentityId: Record<number, ReadonlyArray<IBrandFanMember>>
  readonly membershipById: Record<number, IBrandFanMember>
}

export enum FetchInfluencerIdentitiesActionTypes {
  FETCH_REQUEST = 'brandFans/influencerIdentities/FETCH_REQUEST',
  FETCH_SUCCESS = 'brandFans/influencerIdentities/FETCH_SUCCESS',
  FETCH_ERROR = 'brandFans/influencerIdentities/FETCH_ERROR',
  RESET_LIST = 'brandFans/influencerIdentities/RESET_LIST',
}

export enum RefreshInfluencerIdentitiesSelfActionTypes {
  REFRESH_REQUEST = 'brandFans/influencerIdentities/REFRESH_REQUEST',
}

export enum FetchInfluencerIdentityActionTypes {
  FETCH_REQUEST = 'brandFans/influencerIdentity/FETCH_REQUEST',
  FETCH_SUCCESS = 'brandFans/influencerIdentity/FETCH_SUCCESS',
  FETCH_ERROR = 'brandFans/influencerIdentity/FETCH_ERROR',
}

export enum FetchBrandFanGroupsActionTypes {
  FETCH_REQUEST = 'brandFanGroups/FETCH_REQUEST',
  FETCH_SUCCESS = 'brandFanGroups/FETCH_SUCCESS',
  FETCH_ERROR = 'brandFanGroups/FETCH_ERROR',
}

export enum FetchInfluencerIdentitySubmissionsActionTypes {
  FETCH_REQUEST = 'influencerIdentities/submissions/FETCH_REQUEST',
  FETCH_SUCCESS = 'influencerIdentities/submissions/FETCH_SUCCESS',
  FETCH_ERROR = 'influencerIdentities/submissions/FETCH_ERROR',
}

export enum UpdateBrandFanGroupActionTypes {
  UPDATE_REQUEST = 'brandFanGroup/UPDATE_REQUEST',
  UPDATE_SUCCESS = 'brandFanGroup/UPDATE_SUCCESS',
  UPDATE_ERROR = 'brandFanGroup/UPDATE_ERROR',
}

export enum FetchBrandFanGroupMembersActionTypes {
  FETCH_REQUEST = 'brandFanGroup/members/FETCH_REQUEST',
  FETCH_SUCCESS = 'brandFanGroup/members/FETCH_SUCCESS',
  FETCH_ERROR = 'brandFanGroup/members/FETCH_ERROR',
  RESET_LIST = 'brandFanGroup/members/RESET_LIST',
  REFRESH_REQUEST = 'brandFanGroup/members/REFRESH_REQUEST',
}

export enum CreateBrandFanGroupActionTypes {
  CREATE_REQUEST = 'brandFanGroup/CREATE_REQUEST',
  CREATE_SUCCESS = 'brandFanGroup/CREATE_SUCCESS',
  CREATE_ERROR = 'brandFanGroup/CREATE_ERROR',
}

export enum AddBrandFanGroupMemberActionTypes {
  ADD_MEMBER_REQUEST = 'brandFanGroup/ADD_MEMBER_REQUEST',
  ADD_MEMBER_SUCCESS = 'brandFanGroup/ADD_MEMBER_SUCCESS',
  ADD_MEMBER_ERROR = 'brandFanGroup/ADD_MEMBER_ERROR',
}

export enum RemoveBrandFanGroupMemberActionTypes {
  REMOVE_MEMBER_REQUEST = 'brandFanGroup/REMOVE_MEMBER_REQUEST',
  REMOVE_MEMBER_SUCCESS = 'brandFanGroup/REMOVE_MEMBER_SUCCESS',
  REMOVE_MEMBER_ERROR = 'brandFanGroup/REMOVE_MEMBER_ERROR',
}

export enum BrandFansActionTypes {
  CLEAR_ERRORS = 'brandFans/CLEAR_ERRORS',
}

export enum OpenBrandFanInfluencerIdentity {
  TOGGLE = 'brandFans/influencerIdentities/TOGGLE',
}

export enum RemoveBrandFanMemberActionType {
  REMOVE_BRAND_FAN_MEMBER = 'brandFanGroup/REMOVE_BRAND_FAN_MEMBER',
}
