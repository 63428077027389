import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'

interface IProps {
  channel: string
  disabled?: boolean
  onClick?: VoidFunction
}

const ConnectedAccountsModalChooseAccountSocialCard = ({ channel, disabled, onClick }: IProps) => {
  return (
    <Container
      borderColor={Theme.grey200Color}
      borderWidth={0.0625}
      borderStyle="solid"
      borderRadius={0.5}
      width={8.75}
      height={12.25}
      flexCenter
      flexColumn
      cursor={disabled ? 'not-allowed' : 'pointer'}
      backgroundColor={disabled ? Theme.grey100Color : undefined}
      hoverBorderColor={disabled ? undefined : Theme.primaryColor}
      onClick={onClick}
      data-testid={`connected-account-${channel}-social-card`}
    >
      <Container>
        <Icon size={1.875} glyph={`social-${channel}-colour`} />
      </Container>
      <Container topOuterSpacing={0.75}>
        <Text light color={Theme.grey900Color}>
          <FormattedMessage id={`connectedAccounts.tiktok.modal.chooseAccount.${channel}.header`} />
        </Text>
      </Container>
      <Container topOuterSpacing={1.25} leftOuterSpacing={1.25} rightOuterSpacing={1.25}>
        <Text small light color={Theme.grey800Color}>
          <FormattedMessage
            id={`connectedAccounts.tiktok.modal.chooseAccount.${channel}.subHeader`}
          />
        </Text>
      </Container>
    </Container>
  )
}

export { ConnectedAccountsModalChooseAccountSocialCard }
