import { useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import { fetchBrands, selectBrands, selectBrandsIsFetching } from '@store/brands'
import { IApplicationState } from '@store/index'
import { IOption } from '@store/rootResource'
import { MediaObjectSearchBaseFilter } from './BaseFilter'

const StyledMediaObjectSearchBaseFilter = styled.div`
  [data-testid='brand_filter'] {
    left: 0;
    right: auto;
  }
  ${breakpoint('phoneUpperBoundary')`
  [data-testid='brand_filter'] {
    left: auto;
    right: 0;
  }   
  `}
`

export const BrandsFilter = () => {
  const dispatch = useDispatch()
  const { isFetching, brands } = useSelector((state: IApplicationState) => {
    return {
      isFetching: selectBrandsIsFetching(state),
      brands: selectBrands(state),
    }
  })

  const options: IOption[] = brands.map((brand) => ({
    label: brand.name,
    value: brand.id.toString(),
  }))

  useEffect(() => {
    dispatch(fetchBrands())
  }, [])

  return (
    <StyledMediaObjectSearchBaseFilter>
      <MediaObjectSearchBaseFilter
        allLabel={<FormattedMessage id="contentLibrary.search.filter.brand" />}
        options={options}
        searchKey="brand_filter"
        isLoading={isFetching}
      />
    </StyledMediaObjectSearchBaseFilter>
  )
}
