import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { MoreMenuWrapperAlwaysVisible } from '../MoreMenuWrapper'

export const CardOuterWrapper = styled(MoreMenuWrapperAlwaysVisible)`
  position: relative;
`

export const CardInnerWrapper = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  text-decoration: none;
  display: block;
  ${({ selected }) => css`
    background-color: ${selected ? Theme.primary200Color : Theme.whiteColor};
  `};
`

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 1rem;
  left: 1rem;
`

export const LabelStyled = styled.span`
  background-color: rgb(255 255 255 / 75%);
  border-radius: 0.625rem;
  padding: 0.125rem 0.625rem;
  width: fit-content;
  margin-bottom: 0.875rem;
  margin-right: 0.25rem;
`

export const MediaIconStyled = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

export const ActionBarRight = styled(Flex)`
  width: auto;
  text-align: right;

  & > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`

export const ActionBarLeft = styled(Flex)`
  width: auto;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`

export const ActionBarWrapper = styled(Flex)`
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  min-height: 3.5rem;
  width: 100%;
`
