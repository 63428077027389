import React from 'react'

import styled, { css } from 'styled-components'

export interface ILinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  /**
   * Sets href of the link
   *
   * @default ""
   */
  href: string

  /**
   * Sets the color of the link
   *
   * @default rgba(0, 0, 0, 0.7)
   */
  color?: string

  /**
   * Displays the text with smaller font size
   *
   * @default false
   */
  disabled?: boolean

  /**
   * Sets the font size in rem
   *
   * @default 1
   */
  size?: string | number

  /**
   * Sets text-decoration to underlined
   *
   * @default false
   */
  underlined?: boolean

  /**
   * Sets text-transform to lowercase
   *
   * @default false
   */
  lowercase?: boolean
}

const StyledLink = styled<ILinkProps, any>('a')`
  font-size: inherit;
  cursor: pointer;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-decoration: none;
  color: #00b9d5;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      cursor: default;
      text-decoration: none;

      &:hover {
        text-decoration: none !important;
      }
    `};
  ${({ size }) =>
    size &&
    css`
      font-size: ${`${size}rem`};
    `};
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
  ${({ underlined }) =>
    underlined &&
    css`
      text-decoration: underline;
    `};
  ${({ lowercase }) =>
    lowercase &&
    css`
      text-transform: lowercase;
    `};
`

export class Link extends React.PureComponent<ILinkProps> {
  public render() {
    return <StyledLink {...this.props} rel="noopener noreferrer" />
  }
}
