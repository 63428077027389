import styled, { css } from 'styled-components'

import { IImageBackgroundProps } from './ImageBackground'

export default styled<IImageBackgroundProps, any>('div')`
  ${({
    image,
    position = 50,
    attachment = 'fixed',
    height = 'inherit',
    size = 'cover',
    additionalStyles = {},
    centerContent = false,
    opacity = 0,
  }) => css`
    height: ${height};
    background: linear-gradient(rgba(0, 0, 0, ${opacity}), rgba(0, 0, 0, ${opacity})), url(${image});
    background-size: ${size};
    background-repeat: no-repeat;
    background-position: ${position}%;
    background-attachment: ${attachment};
    ${centerContent &&
      css`
        display: flex;
        align-items: center;
        justify-content: center;
      `};
    ${additionalStyles};
  `};
`
