import { FormattedMessage } from 'react-intl'

import { SideBarContentWrapper } from '@components/UI/Coachmark/Coachmark.styled'
import { Text, Tip } from '@tribegroup'
import BuilderBudgetCreatorGroupSelectionCoachMarkContent from './CoachMarkContent'

export const BuilderBudgetCreatorGroupSelectionTip = () => {
  return (
    <SideBarContentWrapper marginTop={2} marginLeft={3} width={12}>
      <Tip
        text={
          <Text xsmall uppercase>
            <FormattedMessage id="builder.budget.creatorGroup.tip" />
          </Text>
        }
        width={12}
        coachmark={{
          content: <BuilderBudgetCreatorGroupSelectionCoachMarkContent />,
          position: 'left',
          width: 21.275,
          yOffset: 2,
        }}
      />
    </SideBarContentWrapper>
  )
}

export default BuilderBudgetCreatorGroupSelectionTip
