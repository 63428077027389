import { Stripe } from '@stripe/stripe-js'
import { FormattedMessage } from 'react-intl'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import SubmissionCardModalLicenseToast from '@components/Submission/Card/Modal/License/Toast'
import { INBOX_FILTER, LICENSING_TOAST_DELAY, SHORTLISTED_FILTER } from '@constants'
import { ErrorCodes, EventTrackingNames } from '@enums'
import { handlePaymentAuthFailure } from '@sagas/payments'
import { IHateoasLink } from '@store'
import {
  selectMediaObjectTrackingProps,
  selectSelectedLicenseOptionTrackingProps,
  trackEvent,
} from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import { fetchCampaign, selectIsContentCampaign } from '@store/campaigns'
import { ILicense3DSResponse, selectLicenseOptionLicenseLink } from '@store/licenses'
import { fetchMeRequest } from '@store/me'
import {
  fetchMediaObject,
  fetchMediaObjects,
  IRequestLicenseActionRequestPayload,
  ITransactedLicense,
  requestLicense,
  RequestLicenseActionTypes,
  requestLicenseError,
  requestLicenseSuccess,
} from '@store/mediaObjects'
import { selectStripe } from '@store/payments'
import { fetchPurchaseOrders } from '@store/purchaseOrders'
import { selectContentLibraryFilter, selectCurrentSubmissionFilter } from '@store/router'
import { fetchSubmission, fetchSubmissions, updateFilterSubmissionIds } from '@store/submissions'
import { setUIFlag, UIFlag } from '@store/ui'
import { Toaster } from '@tribegroup'
import { networkRequest, selectLink, stripeErrorToResponseError } from '@utils'

export function* handleSecureRequestLicense(
  licenseLink: IHateoasLink,
  requestPayload: IRequestLicenseActionRequestPayload | undefined = undefined,
) {
  const authToken: string = yield select(selectAuthToken)
  const requestLicense3DSResponse: ILicense3DSResponse = yield call(
    networkRequest,
    licenseLink.method,
    licenseLink.href,
    requestPayload ? { ...requestPayload } : undefined,
    authToken,
  )

  if (requestLicense3DSResponse.license) {
    return requestLicense3DSResponse.license
  }

  const stripeSDK: Stripe = yield select(selectStripe)

  const paymentIntentResponse = yield call(
    stripeSDK.confirmCardPayment,
    requestLicense3DSResponse.client_secret,
  )

  if (paymentIntentResponse.error) {
    const paymentError = stripeErrorToResponseError(
      paymentIntentResponse.error,
      ErrorCodes.CONTENT_LICENSING_STRIPE_ERROR,
    )
    const errorResponse = yield call(
      handlePaymentAuthFailure,
      requestLicense3DSResponse,
      paymentError,
    )
    throw errorResponse
  }

  const { paymentIntent } = paymentIntentResponse

  const requestLicense3DSLink: IHateoasLink = selectLink(
    requestLicense3DSResponse.links,
    'request_license_purchase3ds',
  ) as IHateoasLink

  const transactedLicense: ITransactedLicense = yield call(
    networkRequest,
    requestLicense3DSLink.method,
    requestLicense3DSLink.href,
    {
      payment_intent_id: paymentIntent.id,
    },
    authToken,
  )

  return transactedLicense
}

export function* handleRequestLicense(action: ReturnType<typeof requestLicense>) {
  try {
    const { mediaObjectId, licenseIndex, campaignId, submissionId, requestPayload } = action.payload

    const requestLicenseLink: IHateoasLink = yield select(
      selectLicenseOptionLicenseLink,
      mediaObjectId,
      licenseIndex,
    )

    const transactedLicense: ITransactedLicense = yield call(
      handleSecureRequestLicense,
      requestLicenseLink,
      requestPayload,
    )

    const isSourceFromCampaignInbox = campaignId && submissionId

    const isLicensePurchase = isSourceFromCampaignInbox
      ? yield select(selectIsContentCampaign, campaignId)
      : false

    if (isLicensePurchase && campaignId && submissionId) {
      yield call(Toaster.createToast, SubmissionCardModalLicenseToast, {
        downloadLink: transactedLicense.original_media_url,
      })
      yield put(
        updateFilterSubmissionIds(campaignId, submissionId, [INBOX_FILTER, SHORTLISTED_FILTER]),
      )
      yield put(fetchCampaign(campaignId))
    } else {
      if (!requestPayload) {
        yield call(
          Toaster.createToast,
          FormattedMessage,
          { id: 'socialSubmission.card.modal.license.toast' },
          false,
          {
            timeout: LICENSING_TOAST_DELAY,
          },
        )
      }
    }

    if (action.meta?.refreshPurchaseOrders) {
      yield put(fetchPurchaseOrders())
    }

    yield put(requestLicenseSuccess(mediaObjectId, transactedLicense))

    const eventProperties = isSourceFromCampaignInbox
      ? yield select(
          selectSelectedLicenseOptionTrackingProps,
          campaignId,
          submissionId,
          licenseIndex,
        )
      : yield select(selectMediaObjectTrackingProps, mediaObjectId)

    yield put(trackEvent(EventTrackingNames.RequestContentRights, eventProperties))

    yield put(fetchMediaObject(mediaObjectId))
    yield put(fetchMeRequest())

    if (submissionId && campaignId) {
      yield put(fetchSubmission(submissionId, campaignId))
    }

    const filter = yield select(selectContentLibraryFilter)
    yield put(fetchMediaObjects(filter))

    if (action.meta?.refreshSubmissions) {
      const filter = yield select(selectCurrentSubmissionFilter, campaignId)
      yield put(fetchSubmissions(campaignId!, { ...action.meta, filter }))
      yield put(fetchCampaign(campaignId!))
    }

    yield put(setUIFlag(UIFlag.HIDE_MODAL))
  } catch (error) {
    yield put(requestLicenseError(error))
  }
}

export function* watchRequestLicenseRequest() {
  yield takeLatest(RequestLicenseActionTypes.REQUEST_LICENSE, handleRequestLicense)
}
