import { call, put, select, takeLatest } from 'redux-saga/effects'

import { SHORTLISTED_FILTER } from '@constants'
import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import { selectShortlistSummaryLink } from '@store/campaigns'
import {
  fetchShortlistSummary,
  fetchShortlistSummaryError,
  fetchShortlistSummarySuccess,
  IShortlistSummary,
  ShortlistSummaryActionTypes,
} from '@store/shortlistSummary'
import { selectShortlistSummaryPayload } from '@store/shortlistSummary'
import { networkRequest } from '@utils'

export function* handleFetchShortlistSummary(action: ReturnType<typeof fetchShortlistSummary>) {
  const { campaignId } = action.payload

  try {
    const authToken: string = yield select(selectAuthToken)
    const shortlistSummaryLink: IHateoasLink = yield select(selectShortlistSummaryLink, campaignId)
    const payload = yield select(selectShortlistSummaryPayload, campaignId, SHORTLISTED_FILTER)

    const shortlistSummary: IShortlistSummary = yield call(
      networkRequest,
      shortlistSummaryLink.method,
      shortlistSummaryLink.href,
      payload,
      authToken,
    )
    yield put(fetchShortlistSummarySuccess(campaignId, shortlistSummary))
  } catch (error) {
    yield put(fetchShortlistSummaryError(error))
  }
}

export function* watchFetchShortlistSummary() {
  yield takeLatest(ShortlistSummaryActionTypes.FETCH_REQUEST, handleFetchShortlistSummary)
}
