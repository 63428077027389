import { CampaignsActionTypes } from '@store/campaigns'

export const fetchCampaigns = (status = 'all', isFetchingNext = false) => ({
  payload: { status, isFetchingNext },
  type: CampaignsActionTypes.FETCH_REQUEST,
})

export const fetchCampaignsSuccess = (data: any, status = 'all') => ({
  payload: { ...data, status },
  type: CampaignsActionTypes.FETCH_SUCCESS,
})

export const fetchCampaignsError = (error: any) => ({
  payload: error,
  type: CampaignsActionTypes.FETCH_ERROR,
})

export const updateHasSeenPreviewTooltip = () => ({
  type: CampaignsActionTypes.UPDATE_HAS_SEEN_PREVIEW_TOOLTIP,
})
