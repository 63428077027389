import { createContext, useContext } from 'react'

import hooks from '@hooks'

export const InfluencerIdentityIdContext = createContext({} as IInfluencerIdentityIdContextProps)

export const useInfluencerIdentityId = () => useContext(InfluencerIdentityIdContext)

interface IInfluencerIdentityIdProviderProps {
  influencerIdentityId?: number
  children: React.ReactNode
}

export interface IInfluencerIdentityIdContextProps {
  contextInfluencerIdentityId: number
}

export const InfluencerIdentityIdProvider = ({
  influencerIdentityId: influencerIdfromArgs,
  children,
}: IInfluencerIdentityIdProviderProps) => {
  const { influencerId: influencerIdentityIdFromURL } = hooks.useLocationSearch()
  return (
    <InfluencerIdentityIdContext.Provider
      value={
        {
          contextInfluencerIdentityId:
            influencerIdfromArgs ||
            (influencerIdentityIdFromURL && parseInt(influencerIdentityIdFromURL, 10)),
        } as IInfluencerIdentityIdContextProps
      }
    >
      {children}
    </InfluencerIdentityIdContext.Provider>
  )
}
