import { PureComponent } from 'react'

import CurrencyFormatter from 'react-currency-formatter'
import InputRange, { InputRangeProps } from 'react-input-range'
import styled, { css } from 'styled-components'

import { Icon } from '../Icon'
import { defaultTheme, themed } from '../Theme'
import { Text } from '../Typography'
import { LabelWrapper, PointWrapper, SinglePlotDisabledIcon } from './Slider.styled'

import 'react-input-range/lib/css/index.css'

interface ISliderProps extends InputRangeProps {
  labelCount?: number
  currency?: string
  nearest?: number
  labelValues?: ReadonlyArray<{ value; display }>
  minLabel?: string
  maxLabel?: string
  maxValuePlus?: boolean
  elementName?: string
}

const SliderWrapper = themed(styled<ISliderProps, any>('div')`
  position: relative;

  .input-range {
    height: 2.125rem;
  }

  .input-range__slider {
    box-shadow: 2px 3px 0.5rem 0 rgb(0 0 0 / 12%);
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    top: -0.7rem;
    ${({ theme }) =>
      theme &&
      css`
        border: solid 1px ${theme.grey300Color};
        background-color: ${theme.whiteColor};
      `};
  }

  .input-range__track {
    height: 0.5rem;
    ${({ theme }) =>
      theme &&
      css`
        background-color: ${theme.grey100Color};
      `};
  }

  .input-range__track--active {
    ${({ theme }) =>
      theme &&
      css`
        background-color: ${theme.primaryColor};
      `};
  }

  .input-range--disabled .input-range__track--active {
    ${({ theme }) =>
      theme &&
      css`
        opacity: 0.5;
      `};
  }

  .input-range__label {
    display: none;
  }
`)

const getlabels = (count, min, max, nearest = 1) => {
  const interval = (max - min) / count
  const boundaries = Array.from({ length: count - 1 }, (_, idx) => {
    const result = min + interval * (idx + 1)
    return Math.ceil(result / nearest) * nearest
  })
  return boundaries
}

const PlotPoint = ({ plotPosition, display }) => {
  return (
    <PointWrapper left={plotPosition}>
      <Text color={defaultTheme.grey700Color}>{display}</Text>
    </PointWrapper>
  )
}
export class Slider extends PureComponent<ISliderProps> {
  getValue(value, withPlus?) {
    const currency = this.props.currency
    return (
      <CurrencyFormatter
        quantity={value}
        currency={currency || undefined}
        pattern={`${currency ? '!' : ''}###,###${withPlus ? '+' : ' '}`}
      />
    )
  }

  getInputValue(value, maxValue) {
    if (maxValue && !isNaN(value)) {
      return Math.min(maxValue, value)
    } else if (maxValue && value.min && value.max) {
      return {
        max: Math.min(maxValue, value.max),
        min: value.min,
      }
    }
    return value
  }

  render() {
    const {
      labelCount,
      labelValues,
      maxValue,
      maxLabel,
      maxValuePlus,
      minValue,
      minLabel,
      nearest,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      currency,
      value,
      disabled,
      ...sliderProps
    } = this.props

    return (
      <SliderWrapper>
        <InputRange
          {...sliderProps}
          disabled={disabled}
          minValue={minValue}
          maxValue={maxValue}
          value={this.getInputValue(value, maxValue)}
        />
        <LabelWrapper>
          <PlotPoint plotPosition={0} display={minLabel ? minLabel : this.getValue(minValue)} />
          {labelCount
            ? getlabels(labelCount, minValue, maxValue, nearest).map((label) => {
                return (
                  <PlotPoint
                    key={label}
                    plotPosition={((label - minValue!) / (maxValue! - minValue!)) * 100}
                    display={this.getValue(label)}
                  />
                )
              })
            : labelValues &&
              labelValues.map((label) => {
                return (
                  <PlotPoint
                    key={label.value}
                    plotPosition={((label.value - minValue!) / (maxValue! - minValue!)) * 100}
                    display={label.display}
                  />
                )
              })}
          <PlotPoint
            plotPosition={100}
            display={maxLabel ? maxLabel : this.getValue(maxValue, maxValuePlus)}
          />
        </LabelWrapper>
        {disabled && typeof value === 'number' && (
          <SinglePlotDisabledIcon value={value} minValue={minValue} maxValue={maxValue}>
            <Icon glyph="locked" size={0.75} />
          </SinglePlotDisabledIcon>
        )}
      </SliderWrapper>
    )
  }
}

export type { ISliderProps }
export default Slider
