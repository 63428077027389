import { createAction } from '@reduxjs/toolkit'

import { IResponseError } from '@lib/error'
import { ISubmission } from '@store/submissions'
import { PreApproveActionTypes } from '../types'

export const preApprove = createAction(
  PreApproveActionTypes.PREAPPROVE_REQUEST,
  (submissionId: number, campaignId: number, params?: any) => ({
    payload: { submissionId, campaignId, params },
  }),
)

export const preApproveSuccess = createAction(
  PreApproveActionTypes.PREAPPROVE_SUCCESS,
  (submission: ISubmission, campaignId: number) => ({ payload: { submission, campaignId } }),
)

export const preApproveError = createAction<IResponseError>(PreApproveActionTypes.PREAPPROVE_ERROR)
