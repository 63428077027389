import { call, put, select, take, takeEvery } from 'redux-saga/effects'

import { ToastTemplateTicked } from '@components/UI/ToastTemplate'
import ToastTemplateWarning from '@components/UI/ToastTemplate/Warning'
import { FacebookService } from '@services/facebook'
import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  FetchBrandManagerIdentities,
  fetchIdentities,
  IBrandManagerIdentity,
  IFacebookPermissions,
  IInstagramAccountsViaFacebookPages,
  ReconnectBrandManagerIdentity,
  reconnectIdentity,
  reconnectIdentityError,
  reconnectIdentitySuccess,
  selectBrandManagerIdentity,
  selectBrandManagerIdentityLink,
} from '@store/brandManagerIdentities'
import { Toaster } from '@tribegroup'
import { networkRequest } from '@utils'
import { toRequestPayload, verifyPermission } from './connectIdentities'

export function* handleReconnectIdentity(action: ReturnType<typeof reconnectIdentity>) {
  try {
    const instagramAccounts: IInstagramAccountsViaFacebookPages[] = yield call(
      FacebookService.getInstagramAccounts,
    )

    const permissions: IFacebookPermissions[] = yield call(FacebookService.getPermissions)

    yield call(verifyPermission, permissions)

    const brandManagerIdentityId = action.payload
    const authToken: string = yield select(selectAuthToken)
    const reconnectIdentityLink: IHateoasLink = yield select(
      selectBrandManagerIdentityLink,
      brandManagerIdentityId,
      'reconnect_identity',
    )
    yield call(
      networkRequest,
      reconnectIdentityLink.method,
      reconnectIdentityLink.href,
      { identities: toRequestPayload(instagramAccounts) },
      authToken,
    )

    const brandManagerIdentity: IBrandManagerIdentity = yield select(
      selectBrandManagerIdentity,
      brandManagerIdentityId,
    )

    yield put(fetchIdentities())
    yield take(FetchBrandManagerIdentities.FETCH_SUCCESS)
    yield put(reconnectIdentitySuccess(brandManagerIdentity))

    const reconnectLink: IHateoasLink = yield select(
      selectBrandManagerIdentityLink,
      brandManagerIdentityId,
      'reconnect_identity',
    )

    if (reconnectLink) {
      yield call(
        Toaster.createToast,
        ToastTemplateTicked,
        { i18nKey: 'connectedAccounts.toast.reconnectAttemptSuccess' },
        false,
        { timeout: 5000 },
      )
    } else {
      yield call(Toaster.createToast, ToastTemplateTicked, {
        i18nKey: 'connectedAccounts.toast.reconnectSuccess',
      })
    }
  } catch (error) {
    const errorCode = error.messages?.[0]?.error_code || error.status
    if (errorCode) {
      Toaster.createToast(
        ToastTemplateWarning,
        { i18nKey: `connectedAccounts.error.${errorCode}` },
        false,
        { timeout: 5000 },
      )
    }
    yield put(reconnectIdentityError(error))
  }
}

export function* watchReconnectIdentityRequest() {
  yield takeEvery(ReconnectBrandManagerIdentity.RECONNECT_REQUEST, handleReconnectIdentity)
}
