import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  CampaignsActionTypes,
  fetchCampaigns,
  fetchCampaignsError,
  fetchCampaignsSuccess,
  selectFetchCampaignsLink,
} from '@store/campaigns'
import { networkRequest } from '@utils'

export const replaceUrlFilter = (link: string, filter = '') => {
  if (filter === 'all') {
    return link.replace('?brief_status={filter}', '')
  }
  return link.replace('{filter}', filter)
}

export function* handleFetchCampaigns(action: ReturnType<typeof fetchCampaigns>) {
  try {
    const authToken: string = yield select(selectAuthToken)

    const { isFetchingNext, status } = action.payload

    const campaignsLink: IHateoasLink = yield select(
      selectFetchCampaignsLink,
      status,
      isFetchingNext,
    )

    const result = yield call(
      networkRequest,
      campaignsLink.method,
      replaceUrlFilter(campaignsLink.href, status),
      undefined,
      authToken,
    )
    yield put(fetchCampaignsSuccess(result, status))
  } catch (error) {
    yield put(fetchCampaignsError(error))
  }
}

export function* watchCampaignsRequest() {
  yield takeLatest(CampaignsActionTypes.FETCH_REQUEST, handleFetchCampaigns)
}
