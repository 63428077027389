import { addToSet } from '@utils'

export const indexById = (items: ReadonlyArray<any>, current = {}, id = 'id', attribute?: string) =>
  items.reduce(
    (accum, curr) => ({
      ...accum,
      [curr[id]]: attribute ? curr[attribute] : curr,
    }),
    current,
  )

export const appendIdsToFilter = (
  items: ReadonlyArray<any>,
  filter: string | number,
  current = {},
  id = 'id',
) => {
  return {
    ...current,
    [filter]: addToSet([...(current[filter] || []), ...items.map(obj => obj[id])]),
  }
}
