import React, { PropsWithChildren } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { TrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectCollabsCount, selectIdentityGroupCount } from '@store/brandFans'
import {
  selectBrandFansCurrentView,
  selectCurrentBrandFansBrandFilter,
  selectCurrentBrandFansSort,
} from '@store/router'
import { selectIdentityBrandFanGroupIds } from '@store/submissions'

interface IProps {
  eventName: EventTrackingNames
  identityId: number
  submissionId?: number
  additionalProperties?: any
  hasTracking?: boolean
  disabled?: boolean
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

const BrandFanInfluencerIdentityRowTrackedRoute: React.FC<PropsWithChildren<IInternalProps>> = ({
  identityId,
  submissionId,
  eventName,
  sortFilter,
  currentView,
  additionalProperties,
  groupCount,
  brandFilter,
  collabsCount,
  disabled,
  children,
}) => {
  if (disabled) {
    return children
  }

  const brandFansOnlyProps = submissionId
    ? {}
    : {
        sort_filter: sortFilter,
        brand_filter: brandFilter,
        number_of_collabs: collabsCount,
      }

  return (
    <TrackedRoute
      eventName={eventName}
      properties={{
        identity_id: identityId,
        current_filter: currentView,
        number_of_groups_selected: groupCount,
        ...brandFansOnlyProps,
        ...additionalProperties,
      }}
    >
      {children}
    </TrackedRoute>
  )
}

const mapStateToProps = (state: IApplicationState, { identityId, submissionId }: IProps) => ({
  sortFilter: selectCurrentBrandFansSort(state),
  collabsCount: selectCollabsCount(state, identityId),
  brandFilter: selectCurrentBrandFansBrandFilter(state),
  currentView: selectBrandFansCurrentView(state),
  groupCount: submissionId
    ? selectIdentityBrandFanGroupIds(state, submissionId)
    : selectIdentityGroupCount(state, identityId),
})

const connector = connect(mapStateToProps)
export default compose<IInternalProps, PropsWithChildren<IProps>>(
  withRouter,
  connector,
)(BrandFanInfluencerIdentityRowTrackedRoute)
