import { InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import SubmissionCardModalDecline from '@components/Submission/Card/Modal/Decline/Decline'
import SubmissionCardModalDeclinePreapproved from '@components/Submission/Card/Modal/DeclinePreapproved/DeclinePreapproved'
import ActionIcon from '@components/UI/ActionIcon'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IModalContextProps } from '@context/Modal'
import { EventTrackingNames, SubmissionStatuses } from '@enums'
import { withModal } from '@hocs'
import { ISubmission } from '@store/submissions'
import Theme from '@theme'

interface IModalProps {
  campaignId: number
  submission: ISubmission
  currFilter: string
}

interface IActionBarIconDecline {
  modalProps: IModalProps
}

interface IInternalProps extends IActionBarIconDecline, InjectedIntlProps, IModalContextProps {}

export const ActionBarIconDecline = ({ showModal, modalProps, intl }: IInternalProps) => {
  const { submission, campaignId } = modalProps
  const isPreapproved = submission.status === SubmissionStatuses.PreApproved

  const ModalComponent = isPreapproved
    ? SubmissionCardModalDeclinePreapproved
    : SubmissionCardModalDecline

  const onClick = () => showModal(ModalComponent, modalProps)
  return (
    <ConnectedTrackedRoute
      campaignId={campaignId}
      submissionId={submission.id}
      disableTracking={!isPreapproved}
      eventName={EventTrackingNames.PreApproveDeclineStep1}
    >
      <ActionIcon
        glyph="decline-submission"
        color={Theme.grey900Color}
        size={1.5}
        onClick={onClick}
        tooltip={intl.formatMessage({ id: 'submission.card.action.decline' })}
        elementName="decline"
      />
    </ConnectedTrackedRoute>
  )
}

export default compose<IInternalProps, any>(withModal, injectIntl)(ActionBarIconDecline)
