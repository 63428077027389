import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import Confirmation from '@components/UI/Confirmation'
import { FooterButtonWrapper } from '@components/UI/Confirmation/Confirmation.styled'
import { IApplicationState } from '@store'
import { selectCampaignTitle } from '@store/campaigns'
import { Button } from '@tribegroup'

interface ICampaignCardModalActivateProps {
  campaignId: number
  onConfirm: VoidFunction
  onCancel: VoidFunction
  isOpen: boolean
}

interface IInternalProps extends ICampaignCardModalActivateProps {
  campaignTitle: string
}

export const CampaignCardModalActivate = ({
  campaignTitle,
  onConfirm,
  onCancel,
  isOpen,
}: IInternalProps) => {
  return (
    <Confirmation
      title={<FormattedMessage id="campaign.campaignCard.menuAction.activate.confirmation.title" />}
      content={
        <FormattedMessage
          id="campaign.campaignCard.menuAction.activate.confirmation.body"
          values={{ campaignTitle }}
        />
      }
      footerButtons={
        <Fragment>
          <FooterButtonWrapper>
            <Button
              scheme="primary"
              quiet
              onClick={onCancel}
              data-cy-element="activate-modal-cancel-button"
            >
              <FormattedMessage id="core.text.cancel" />
            </Button>
          </FooterButtonWrapper>
          <FooterButtonWrapper>
            <Button
              scheme="primary"
              onClick={onConfirm}
              data-cy-element="activate-modal-activate-button"
            >
              <FormattedMessage id="core.text.activate" />
            </Button>
          </FooterButtonWrapper>
        </Fragment>
      }
      isOpen={isOpen}
      onCloseIconClick={onCancel}
    />
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { campaignId }: ICampaignCardModalActivateProps,
) => ({
  campaignTitle: selectCampaignTitle(state, campaignId),
})

export default connect(mapStateToProps)(CampaignCardModalActivate)
