import React, { useContext } from 'react'

import { useSelector } from 'react-redux'
import { Grid } from 'react-styled-flexboxgrid'

import { TRIBE_PRICING_PAGE_METRIC_SUMMARY_UTM } from '@constants'
import { CampaignContext } from '@context/Campaign'
import { IApplicationState } from '@store'
import { selectCampaign } from '@store/campaigns'
import { selectTikTokMetricsSummary } from '@store/postCampaignReport'
import AnalyticsPostCampaignReportMetric from '../Metric/Metric'
import { StyledCol, StyledRow } from '../Summary/Summary.styled'

const externalLink = TRIBE_PRICING_PAGE_METRIC_SUMMARY_UTM

const AnalyticsPostCampaignReportTikTok: React.FC = () => {
  const {
    campaign: { is_price_hidden: isPriceHidden, id: campaignId },
  } = useContext(CampaignContext)

  const { tiktokMetricsSummary, currency } = useSelector((state: IApplicationState) => {
    const { budget_remaining_currency } = selectCampaign(state, campaignId)
    return {
      tiktokMetricsSummary: selectTikTokMetricsSummary(state, campaignId),
      currency: budget_remaining_currency,
    }
  })

  return (
    <Grid fluid>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-posts-cell">
          <AnalyticsPostCampaignReportMetric
            title="tiktok.totalPost"
            value={tiktokMetricsSummary.count}
            externalLink={externalLink}
          />
        </StyledCol>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="total-spend-cell">
            <AnalyticsPostCampaignReportMetric
              title="tiktok.totalSpend"
              value={tiktokMetricsSummary.total_purchase_amount}
              externalLink={externalLink}
              currency={currency}
            />
          </StyledCol>
        )}
        <StyledCol xs={6} lg={3} data-testid="total-followers-cell">
          <AnalyticsPostCampaignReportMetric
            title="tiktok.totalReach"
            value={tiktokMetricsSummary.total_followers}
            externalLink={externalLink}
            abbreviateValue
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-engagement-cell">
          <AnalyticsPostCampaignReportMetric
            title="tiktok.engagement"
            value={tiktokMetricsSummary.engagement}
            externalLink={externalLink}
            abbreviateValue
          />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-likes-cell">
          <AnalyticsPostCampaignReportMetric
            title="tiktok.likes"
            value={tiktokMetricsSummary.likes}
            externalLink={externalLink}
            abbreviateValue
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-comments-cell">
          <AnalyticsPostCampaignReportMetric
            title="tiktok.comments"
            value={tiktokMetricsSummary.comments}
            externalLink={externalLink}
            abbreviateValue
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-shares-cell">
          <AnalyticsPostCampaignReportMetric
            title="tiktok.shares"
            value={tiktokMetricsSummary.shares}
            externalLink={externalLink}
            abbreviateValue
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-engagement-rate-cell">
          <AnalyticsPostCampaignReportMetric
            title="tiktok.engagementRate"
            value={tiktokMetricsSummary.total_engagement_pct}
            externalLink={externalLink}
            showPercentSign
          />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="overall-cpe-cell">
            <AnalyticsPostCampaignReportMetric
              title="tiktok.overallCostPerEngagement"
              value={tiktokMetricsSummary.cpe}
              externalLink={externalLink}
              currency={currency}
            />
          </StyledCol>
        )}
        <StyledCol xs={6} lg={3} data-testid="total-views-cell">
          <AnalyticsPostCampaignReportMetric
            title="tiktok.totalViews"
            value={tiktokMetricsSummary.video_views}
            externalLink={externalLink}
            abbreviateValue
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-views-pct-cell">
          <AnalyticsPostCampaignReportMetric
            title="tiktok.totalViewsRate"
            value={tiktokMetricsSummary.video_views_pct}
            externalLink={externalLink}
            showPercentSign
          />
        </StyledCol>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="overall-cpv-cell">
            <AnalyticsPostCampaignReportMetric
              title="tiktok.overallCPV"
              value={tiktokMetricsSummary.cpv}
              externalLink={externalLink}
              currency={currency}
            />
          </StyledCol>
        )}
      </StyledRow>
      {!isPriceHidden && (
        <StyledRow>
          <StyledCol xs={6} lg={3} data-testid="overall-cpm-cell">
            <AnalyticsPostCampaignReportMetric
              title="tiktok.overallCPM"
              value={tiktokMetricsSummary.cpm}
              externalLink={externalLink}
              currency={currency}
            />
          </StyledCol>
        </StyledRow>
      )}
    </Grid>
  )
}

export default AnalyticsPostCampaignReportTikTok
