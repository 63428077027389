import { PropsWithChildren, PureComponent } from 'react'

import styled, { css } from 'styled-components'

export interface IMenuProps {
  /**
   * Set vertical inner spacing in rem
   *
   * @default 1.625
   */
  verticalPadding?: number

  /**
   * Set vertical inner spacing in rem
   *
   * @default 1.375
   */
  horizontalPadding?: number

  topOuterSpacing?: number

  /**
   * Set menu width in rem
   *
   * @default 12.1875
   */
  width?: number

  /**
   * Set menu max height in rem
   *
   * @default undefined
   */
  maxHeight?: number

  /**
   * Callback function once menu item is clicked
   *
   * @default undefined
   */
  onClick?: (event: Event) => void
}

const MenuWrapper = styled<IMenuProps, any>('div')`
  ${({ verticalPadding, horizontalPadding, width, maxHeight, topOuterSpacing }) => css`
    border-radius: 0.3125rem;
    box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    box-sizing: border-box;
    padding: ${verticalPadding}rem ${horizontalPadding}rem;
    display: inline-block;
    z-index: 2;
    ${topOuterSpacing &&
    css`
      margin-top: ${topOuterSpacing}rem;
    `}
    ${maxHeight &&
    css`
      max-height: ${maxHeight}rem;
      overflow: scroll;
    `}
    ${!width &&
    css`
      min-width: 100%;
    `};
    ${width &&
    css`
      min-width: ${width}rem;
    `};
  `};
`

export default class Menu extends PureComponent<PropsWithChildren<IMenuProps>> {
  static defaultProps = {
    horizontalPadding: 0.5,
    verticalPadding: 0.5,
  }
  render() {
    return <MenuWrapper {...this.props} />
  }
}
