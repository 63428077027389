import React from 'react'

import { FormattedMessage } from 'react-intl'

import { BuilderDefault } from '@enums'
import { ICampaign } from '@store/campaigns'
import CampaignPreviewBlock from '../Block'
import CampaignPreviewCommonBlockGender from '../CommonBlock/Gender'
import CampaignPreviewInfluencerRequirementsAge from './Age'
import CampaignPreviewInfluencerRequirementsChannels from './Channels'
import CampaignPreviewInfluencerRequirementsFollowers from './Followers'

export interface IPreloadedTagsProps {
  campaign: ICampaign
}

const CampaignPreviewInfluencerRequirements: React.FC<IPreloadedTagsProps> = ({ campaign }) => {
  const {
    min_follower_threshold,
    max_follower_threshold,
    channels,
    demographic_min_age,
    demographic_max_age,
    demographic_gender = [],
  } = campaign

  const showAsEmpty = !(demographic_gender.length > 0 || demographic_min_age || demographic_max_age)

  return (
    <CampaignPreviewBlock
      title={<FormattedMessage id="campaign.preview.influencerDetails.header" />}
      empty={showAsEmpty}
      emptyLines={[12.5, 15]}
    >
      {demographic_gender.length > 0 && (
        <CampaignPreviewCommonBlockGender selectedGenderCodes={demographic_gender} />
      )}
      {demographic_min_age && demographic_max_age && (
        <CampaignPreviewInfluencerRequirementsAge
          minAge={demographic_min_age}
          maxAge={demographic_max_age}
          showPlus={demographic_max_age === BuilderDefault.MAX_AGE_ALLOWED}
        />
      )}
      {channels.length > 0 && (
        <CampaignPreviewInfluencerRequirementsChannels selectedChannels={channels} />
      )}
      {min_follower_threshold && max_follower_threshold && (
        <CampaignPreviewInfluencerRequirementsFollowers
          minFollowers={min_follower_threshold}
          maxFollowers={max_follower_threshold}
        />
      )}
    </CampaignPreviewBlock>
  )
}

export default CampaignPreviewInfluencerRequirements
