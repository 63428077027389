import { ComponentType, PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'

import Theme from '@theme'
import { repeatStringXTimes } from '@utils'
import { AUTOPLAY_TIMER_TICK_INTERVAL } from '../../Autoplay/Autoplay'

interface IStyledBarsWrapperProps {
  totalCount: number
}

export const StyledBarsWrapper: ComponentType<PropsWithChildren<
  IStyledBarsWrapperProps
>> = styled.div`
  display: grid;
  grid-gap: 0.375rem;
  box-sizing: border-box;
  ${({ totalCount }) => css`
    grid-template-columns: ${repeatStringXTimes('1fr', totalCount, ' ')};
  `};
  position: absolute;
  width: 100%;
  bottom: -0.875rem;
  left: 0;
`

export const StyledBar = styled.div`
  height: 0.0625rem;
  width: 100%;
  background: ${Theme.grey400Color};
`

interface IStyledBarProgressProps {
  width: number
}
export const StyledBarProgress: ComponentType<IStyledBarProgressProps> = styled.div`
  transition: width ${AUTOPLAY_TIMER_TICK_INTERVAL}ms linear;
  background: ${Theme.primaryColor};
  width: ${({ width }) => width}%;
  height: 100%;
  display: block;
`
