import React from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'

import InternalFeedback from '@components/UI/Drawer/InternalMessage'
import { IFeedback } from '@store/feedback'
import FeedbackBlock from './Block/Block'
import SubmissionChatPreApprovalEducation from './PreApproval/Education'

interface IProps {
  meId: number
  chatList: ReadonlyArray<IFeedback>
  showPreapprovalEducation: boolean
  isSamplingCampaign: boolean
  isPitchingEnabled?: boolean
  isSocialCampaign?: boolean
}

interface IInternalProps extends IProps, InjectedIntlProps {}

const SubmissionChatList: React.FC<IInternalProps> = ({
  meId,
  chatList,
  showPreapprovalEducation,
  isSamplingCampaign,
  isPitchingEnabled,
  isSocialCampaign,
  intl,
}) => {
  const listIsEmpty = chatList.length === 0
  if (!showPreapprovalEducation && listIsEmpty) {
    return (
      <InternalFeedback
        message={{
          created_at: undefined,
          text: intl.formatMessage({ id: 'submission.feedback.defaultMessage' }),
        }}
      />
    )
  }

  return (
    <React.Fragment>
      {chatList.map((feedback) =>
        feedback.message_type !== 'submission_feedback' ? (
          <InternalFeedback key={feedback.id} message={feedback} />
        ) : (
          <FeedbackBlock key={feedback.id} feedback={feedback} meId={meId} />
        ),
      )}
      {showPreapprovalEducation && (
        <SubmissionChatPreApprovalEducation
          hasBottomSpacing={listIsEmpty}
          isSamplingCampaign={isSamplingCampaign}
          isPitchingEnabled={isPitchingEnabled}
          isSocialCampaign={isSocialCampaign}
        />
      )}
    </React.Fragment>
  )
}

export default injectIntl(SubmissionChatList)
