import React from 'react'

import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { compose } from 'recompose'
import Flex, { FlexItem } from 'styled-flex-component'

import { Hr } from '@components/UI/Hr/Hr.styled'
import Tag from '@components/UI/Tag'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { BRANDED_CONTENT_ADS_HOW_TO, WHAT_ARE_SPARK_ADS } from '@constants'
import { IModalComponentProps } from '@context/Modal'
import { BrandedContentAdStatusTypes, EventTrackingNames, SubmissionChannels } from '@enums'
import { withModal } from '@hocs'
import { useCampaignIdFromRoute } from '@hooks/useCampaignIdFromRoute'
import { useIsMounted } from '@hooks/useIsMounted'
import { IApplicationState } from '@store'
import {
  selectIdentityProvider,
  selectIsRequestingBrandedContentAd,
  selectIsSubmissionFromTiktok,
  selectTransactedBrandedContentAdDurationInDays,
  selectTransactedBrandedContentAdStatus,
} from '@store/submissions'
import { selectUIFlag, UIFlag } from '@store/ui'
import Theme from '@theme'
import { Checkbox, CloseIcon, Container, H5, Link, Modal, ModalTitle, Text } from '@tribegroup'
import { getMessage } from '@utils'
import BrandedContentAdModalForm from '../Form'
import { BetaTagContainer } from './Modal.styled'

interface IProps extends IModalComponentProps {
  submissionId: number
}

interface IInternalProps extends IProps, InjectedIntlProps {
  isRequestingBrandedContentAd: boolean
}

export const ModalFormContext = React.createContext({})

const BrandedContentAdModal: React.FC<IInternalProps> = ({
  intl,
  submissionId,
  isOpen = false,
  onRequestClose,
}) => {
  const isMounted = useIsMounted()
  const campaignId = useCampaignIdFromRoute()

  const {
    isRequestingBrandedContentAd,
    hasActiveUIFlag,
    brandedContentAdStatus,
    identityProvider,
    isTikTok,
    durationInDays,
  } = useSelector((state: IApplicationState) => {
    return {
      isRequestingBrandedContentAd: selectIsRequestingBrandedContentAd(state),
      hasActiveUIFlag:
        selectUIFlag(state, UIFlag.SHOW_RECONNECT_ACCOUNT_ALERT) ||
        selectUIFlag(state, UIFlag.SHOW_NO_BRAND_MANAGER_IDENTITY_ALERT) ||
        selectUIFlag(state, UIFlag.SHOW_CONNECT_ACCOUNT_ALERT),
      brandedContentAdStatus: selectTransactedBrandedContentAdStatus(state, submissionId),
      identityProvider: selectIdentityProvider(state, submissionId) ?? SubmissionChannels.Instagram,
      isTikTok: selectIsSubmissionFromTiktok(state, submissionId),
      durationInDays: selectTransactedBrandedContentAdDurationInDays(state, submissionId),
    }
  })

  React.useEffect(() => {
    if (isMounted && !isRequestingBrandedContentAd && !hasActiveUIFlag) {
      onRequestClose()
    }
  }, [isRequestingBrandedContentAd])

  const learnMoreLink =
    identityProvider === SubmissionChannels.TikTok ? WHAT_ARE_SPARK_ADS : BRANDED_CONTENT_ADS_HOW_TO

  const isBCAGranted =
    brandedContentAdStatus === BrandedContentAdStatusTypes.Completed ||
    brandedContentAdStatus === BrandedContentAdStatusTypes.Approved ||
    brandedContentAdStatus === BrandedContentAdStatusTypes.Expired
  const showBCASubheader = isTikTok && isBCAGranted

  return (
    <Modal reactModalProps={{ isOpen }} width={32.5} complex>
      <ModalTitle center bottomOuterSpacing={1.25}>
        <Flex alignCenter justifyCenter>
          <FormattedMessage id={`brandedContentAds.${identityProvider}.modal.title`} />
          <BetaTagContainer>
            <Tag text={getMessage(intl, 'core.text.beta')} />
          </BetaTagContainer>
        </Flex>
      </ModalTitle>
      <CloseIcon onClick={onRequestClose} />
      {showBCASubheader && (
        <Container>
          <Flex>
            <FlexItem>
              <Container relative rightPosition={1.125}>
                <Checkbox
                  backgroundColor={Theme.primary200Color}
                  scheme="primary"
                  checked
                  hideBorder
                  noHover
                  circular
                />
              </Container>
            </FlexItem>
            <FlexItem>
              <Container>
                <H5 color={Theme.grey900Color}>
                  <FormattedMessage
                    id={`brandedContentAds.tiktok.rights.active.modal.header`}
                    values={{ durationInDays }}
                  />
                </H5>
              </Container>
              <Container topOuterSpacing={0.25}>
                <Text small color={Theme.grey800Color}>
                  <FormattedHTMLMessage
                    id={`brandedContentAds.tiktok.rights.active.modal.subheader`}
                  />
                </Text>
              </Container>
            </FlexItem>
          </Flex>
        </Container>
      )}
      {!showBCASubheader && (
        <Container textAlign="center">
          <Container topOuterSpacing={1.25}>
            <H5 color={Theme.grey900Color}>
              <FormattedMessage id={`brandedContentAds.${identityProvider}.modal.header`} />
            </H5>
          </Container>
          <Container topOuterSpacing={0.25}>
            <Text small color={Theme.grey800Color}>
              <FormattedHTMLMessage id={`brandedContentAds.${identityProvider}.modal.subheader`} />{' '}
              <ConnectedTrackedRoute
                inline
                campaignId={campaignId}
                submissionId={submissionId}
                eventName={EventTrackingNames.BCAEducationLink}
                additionalProperties={{
                  link_url: learnMoreLink,
                  link_name: 'core.text.learnMore.withArrow',
                }}
              >
                <Link href={learnMoreLink} target="_blank" data-testid="branded-content-ads-link">
                  <Text color={Theme.primaryColor} small>
                    <FormattedMessage id="core.text.learnMore.withArrow" />
                  </Text>
                </Link>
              </ConnectedTrackedRoute>
            </Text>
          </Container>
          {!brandedContentAdStatus && (
            <Container topOuterSpacing={1}>
              <Text color={Theme.grey800Color} small>
                <FormattedMessage id="brandedContentAds.modal.fillout" />
              </Text>
            </Container>
          )}
          <Container topOuterSpacing={1}>
            <Hr />
          </Container>
        </Container>
      )}

      <Container textAlign="center">
        <BrandedContentAdModalForm submissionId={submissionId} />
      </Container>
    </Modal>
  )
}

export { BrandedContentAdModal }
export default compose<IInternalProps, IProps>(injectIntl, withModal)(BrandedContentAdModal)
