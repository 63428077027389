import { IFeedback } from '@store/feedback'
import { Container, Text } from '@tribegroup'
import SubmissionChatAttachmentDownloadFile from '../Attachment/DownloadFile'
import { AttachmentContainer, ImageContainer, MessageBlockContainer } from './Message.styled'
import { SubmissionChatMessageThumbnail } from './Thumbnail'

type Props = {
  feedback: IFeedback
  meId: number
}

const SubmissionChatMessage: React.FC<Props> = ({ feedback, meId }) => {
  const isPdf = feedback.attachment?.media_type === 'pdf'

  const isEmptyMessage = !feedback.text

  const isInfluencer = feedback.author_id !== meId

  const hasThumbnail = !isPdf && Boolean(feedback.attachment?.thumbnail_url)

  if (hasThumbnail && isEmptyMessage) {
    return (
      <ImageContainer isInfluencer={isInfluencer}>
        <SubmissionChatMessageThumbnail feedback={feedback} large />
      </ImageContainer>
    )
  }

  return (
    <MessageBlockContainer isInfluencer={isInfluencer}>
      {isPdf && feedback.attachment && (
        <AttachmentContainer emptyMessage={!feedback.text}>
          <SubmissionChatAttachmentDownloadFile
            attachment={feedback.attachment}
            key={feedback.attachment.file_id}
          />
        </AttachmentContainer>
      )}
      <Container flexStart>
        {hasThumbnail && (
          <Container
            rightOuterSpacing={0.5}
            minWidth={2.5}
            minHeight={2.5}
            maxWidth={2.5}
            maxHeight={2.5}
          >
            <SubmissionChatMessageThumbnail feedback={feedback} />
          </Container>
        )}
        <Text>{feedback.text}</Text>
      </Container>
    </MessageBlockContainer>
  )
}

export default SubmissionChatMessage
