import { PureComponent } from 'react'

import styled, { css } from 'styled-components'

export interface IFormLabelProps {
  /**
   * Sets outer margin top in rem
   *
   * @default 0
   */
  outerTopSpacing?: number

  /**
   * Sets outer margin bottom in rem
   *
   * @default 0.4375
   */
  outerBottomSpacing?: number
}

const FormLabelWrapper = styled<IFormLabelProps, any>('label')`
  ${({ outerTopSpacing = 0, outerBottomSpacing = 0.4375 }) => css`
    margin-top: ${outerTopSpacing}rem;
    margin-bottom: ${outerBottomSpacing}rem;
  `};
`

class FormLabel extends PureComponent<IFormLabelProps> {
  render() {
    return <FormLabelWrapper className="formgroup-label" {...this.props} />
  }
}

export default FormLabel
