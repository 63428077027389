import React from 'react'

import { Icon, IIconProps } from '@tribegroup'
import ActionIconStyled, {
  IActionIconStyledProps,
  NotificationDotStyled,
  PulseAnimation,
} from './ActionIcon.styled'

export interface IActionIcon extends IIconProps, IActionIconStyledProps {
  showNotification?: boolean
  disabled?: boolean
  noHover?: boolean
  tooltip?: React.ReactNode
  backgroundColor?: string
  elementName?: string
  hoverBackgroundColor?: string
}

export default class ActionIcon extends React.PureComponent<IActionIcon, any> {
  public render() {
    const {
      highlighted,
      backgroundColor,
      showNotification,
      tooltip,
      disabled,
      onClick,
      elementName,
      animatePulse,
      noHover,
      hoverBackgroundColor,
      ...iconProps
    } = this.props

    const actionIconContent = (
      <ActionIconStyled
        data-cy-element={elementName}
        data-testid={elementName}
        highlighted={highlighted}
        className="action-icon-wrapper"
        data-tooltip-id="base-tooltip"
        data-tooltip-content={tooltip}
        data-html
        disabled={disabled}
        onClick={!disabled ? onClick : undefined}
        backgroundColor={backgroundColor}
        noHover={noHover}
        hoverBackgroundColor={hoverBackgroundColor}
      >
        <Icon disabled={disabled} {...iconProps} />
        {showNotification && <NotificationDotStyled data-testid="notification-dot" />}
      </ActionIconStyled>
    )

    return animatePulse ? (
      <PulseAnimation data-testid="action-icon-pulse-animation">{actionIconContent}</PulseAnimation>
    ) : (
      actionIconContent
    )
  }
}
