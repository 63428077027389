import { FormattedMessage } from 'react-intl'

import { ApprovalType } from '@store/campaigns'
import { Button, Header } from '@tribegroup'

interface IProps {
  onClick: VoidFunction
  loading?: boolean
  disabled?: boolean
  approvalType?: ApprovalType
  preapprovalPercentage?: number
}

export const SubmissionCardModalLicenseButton = ({
  onClick,
  loading,
  disabled,
  approvalType,
  preapprovalPercentage,
}: IProps) => {
  let translationKey = ''

  if (approvalType === 'final-approval') {
    translationKey = 'submission.card.action.licensecontent.licenseContent'
  } else if (approvalType === 'pre-approval') {
    translationKey = 'submission.card.action.licensecontent.acceptPreapproval'
  }

  return (
    <Button
      fullWidth
      scheme="primary"
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      bottomOuterSpacing={1.25}
      height={3}
    >
      <Header cta>
        <FormattedMessage id={translationKey} values={{ preapprovalPercentage }} />
      </Header>
    </Button>
  )
}
