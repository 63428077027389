import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import SubmissionCostBreakdownSocial from '@components/Submission/CostBreakdown/Social'
import Price from '@components/UI/Price/Price'
import { IApplicationState } from '@store'
import {
  selectAmountCents,
  selectAmountCurrency,
  selectIdentityFirstName,
} from '@store/submissions'
import Theme from '@theme'
import { Container, Header, Text } from '@tribegroup'
import { ClickTheSubmissionContainer } from './CoachMark.styled'

interface IInternalProps extends IProps {
  influencerName: string
  currency: string
  influencerPrice: number
}

interface IProps {
  submissionId: number
  campaignId: number
}

export const SubmissionFeedbackNegotiationCoachMark = ({
  currency,
  influencerPrice,
  influencerName,
  submissionId,
  campaignId,
}: IInternalProps) => (
  <div>
    <Header small uppercase bottomOuterSpacing={1.25} color={Theme.primaryColor}>
      <FormattedMessage id="submission.feedback.form.popup.negotiatingprice" />
    </Header>
    <Container bottomOuterSpacing={1.25}>
      <Text color={Theme.primaryColor}>
        <FormattedMessage
          id="submission.feedback.form.popup.text"
          values={{
            influencerPrice: (
              <Price currency={currency} quantity={influencerPrice} rounded pattern="!###,###," />
            ),
            influencerName,
          }}
        />
      </Text>
    </Container>
    <Container bottomOuterSpacing={1.5}>
      <SubmissionCostBreakdownSocial campaignId={campaignId} submissionId={submissionId} />
    </Container>
    <ClickTheSubmissionContainer>
      <Text color={Theme.grey700Color}>
        <FormattedMessage id="submission.feedback.form.popup.priceSummaryInfo" />
      </Text>
    </ClickTheSubmissionContainer>
  </div>
)

const mapStateToProps = (state: IApplicationState, { submissionId }: IProps) => {
  return {
    currency: selectAmountCurrency(state, submissionId),
    influencerPrice: selectAmountCents(state, submissionId),
    influencerName: selectIdentityFirstName(state, submissionId),
  }
}

export default compose<IInternalProps, IProps>(connect(mapStateToProps))(
  SubmissionFeedbackNegotiationCoachMark,
)
