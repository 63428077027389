import { createSelector } from 'reselect'

import {
  CAMPAIGN_BUILDER,
  CONTENT_LIBRARY,
  CONTENT_LIBRARY_ITEM_VIEW,
  INBOX_VIEW,
} from '@constants'
import {
  BillingTypes,
  BrandedContentAdFilters,
  BrandFanFilters,
  BuilderSteps,
  ContentLibraryFilterTypes,
  PageRoutes,
  PCRChannels,
  SubmissionStatuses,
} from '@enums'
import { selectContentLibraryDefaultFilter, selectInfluencerIdentitySortOptions } from '@store/me'
import { getCurrentView, getSubmissionFilter, queryStringtoObject } from '@utils'
import { IApplicationState } from '..'
import { Location } from './types'

export const selectLocation = (state: IApplicationState) => state.router.location

export const selectCurrentView = createSelector(selectLocation, getCurrentView)

export const selectIsInfluencerView = createSelector(
  selectCurrentView,
  (currentView) => currentView === 'inbox_list_view',
)

export const selectIsInboxView = createSelector(
  selectCurrentView,
  (currentView) => currentView === INBOX_VIEW,
)

export const selectLocationsearch = createSelector(selectLocation, (location) => location.search)

export const selectLocationsearchAsObject = createSelector(
  selectLocationsearch,
  queryStringtoObject,
)

export const selectCurrentFilter = createSelector(
  selectLocationsearchAsObject,
  (searchObject) => searchObject.filter || '',
)

export const selectCurrentSortBy = createSelector(
  selectLocationsearchAsObject,
  (searchObject) => searchObject.sort_by || '',
)

export const selectCurrentBrandFilter = createSelector(
  selectLocationsearchAsObject,
  (searchObject) => searchObject.brand_filter || '',
)

export const selectCurrentDeclinedFilter = createSelector(
  selectLocationsearchAsObject,
  (searchObject) => searchObject.declined_filter || 'all',
)

export const selectCurrentSubmissionFilter = createSelector(selectLocation, (location) =>
  getSubmissionFilter(location),
)

export const selectContentLibraryFilter = createSelector(
  selectContentLibraryDefaultFilter,
  selectCurrentFilter,
  (defaultFilter: string, currentFilter: any) =>
    Object.values(ContentLibraryFilterTypes).includes(currentFilter)
      ? currentFilter
      : defaultFilter,
)

export const selectPathname = createSelector(
  selectLocation,
  (location: Location) => location.pathname,
)

export const selectContentLibraryFilterOrView = createSelector(
  selectCurrentView,
  selectContentLibraryFilter,
  (currentView: string, currentFilter: string) => {
    return currentView === CONTENT_LIBRARY_ITEM_VIEW
      ? CONTENT_LIBRARY_ITEM_VIEW
      : `${CONTENT_LIBRARY}_${currentFilter}`
  },
)

export const selectPathnameEndsWithContact = createSelector(selectPathname, (pathname: string) =>
  pathname.endsWith('/contact'),
)

export const selectPathnameEndsWithPayment = createSelector(selectPathname, (pathname: string) =>
  pathname.endsWith('/payment'),
)

export const selectPathnameEndsWithPassword = createSelector(selectPathname, (pathname: string) =>
  pathname.endsWith('/password'),
)

export const selectIsEditingAnyAccountForm = createSelector(
  selectPathnameEndsWithContact,
  selectPathnameEndsWithPayment,
  selectPathnameEndsWithPassword,
  (endsWithContact: boolean, endsWithPayment: boolean, endsWithPassword: boolean) =>
    [endsWithContact, endsWithPayment, endsWithPassword].some(Boolean),
)

export const selectCampaignId = (match) => {
  const matchParams = match && (match.params as { campaignId?: string })
  return matchParams && matchParams.campaignId ? parseInt(matchParams.campaignId, 10) : undefined
}

export const selectMediaObjectId = (match) => {
  const matchParams = match && (match.params as { mediaObjectId?: string })
  return matchParams && matchParams.mediaObjectId
    ? parseInt(matchParams.mediaObjectId, 10)
    : undefined
}

export const selectBrandFansGroupId = (match) => {
  const matchParams = match && (match.params as { brandFansGroupId?: string })
  return matchParams && matchParams.brandFansGroupId
    ? parseInt(matchParams.brandFansGroupId, 10)
    : undefined
}

export const selectShareableContentLibraryToken = (match) => {
  const matchParams = match && (match.params as { token?: string })
  return matchParams.token
}

export const selectSubmissionId = (match) => {
  const matchParams = match && (match.params as { submissionId?: string })
  return matchParams && matchParams.submissionId
    ? parseInt(matchParams.submissionId, 10)
    : undefined
}

export const selectSearchStatus = (location) => queryStringtoObject(location.search || '').status

export const selectActivePCRChannel = createSelector(
  selectLocation,
  (location) => queryStringtoObject(location.search).channel || PCRChannels.Summary,
)

export const selectBillingType = (location: Location) => {
  return location.pathname === `/${PageRoutes.BillingContentLibrary}`
    ? BillingTypes.ContentLibrary
    : BillingTypes.Campaigns
}

export const selectIsBCAFilter = createSelector(selectCurrentFilter, (currentFilter) => {
  return [BrandedContentAdFilters.Approved, BrandedContentAdFilters.Requested].includes(
    currentFilter,
  )
})

export const selectAllowResubmittedLabel = createSelector(selectCurrentFilter, (currentFilter) => {
  return !(
    currentFilter &&
    [
      BrandedContentAdFilters.Approved,
      BrandedContentAdFilters.Requested,
      SubmissionStatuses.Licensed,
      SubmissionStatuses.LicenseRequest,
      SubmissionStatuses.Published,
    ].some((filter) => filter === currentFilter)
  )
})

export const selectIsDeclinedFilter = createSelector(
  selectCurrentFilter,
  (currentFilter) => SubmissionStatuses.Declined === currentFilter,
)

export const selectIsOnCampaignBuilder = createSelector(selectPathname, (pathname) =>
  pathname.startsWith(`/${PageRoutes.CampaignBuilder}`),
)

export const selectCurrentBrandFansFilter = createSelector(
  selectPathname,
  selectCurrentFilter,
  (pathname, currentFilter) => {
    if (pathname.startsWith(`/${PageRoutes.BrandFanGroups}`)) {
      return BrandFanFilters.Groups
    }
    if (pathname.startsWith(`/${PageRoutes.Campaigns}`)) {
      return BrandFanFilters.BrandFans
    }
    return currentFilter || BrandFanFilters.All
  },
)

export const selectBrandFansCurrentView = createSelector(
  selectIsOnCampaignBuilder,
  selectCurrentBrandFansFilter,
  selectCurrentSubmissionFilter,
  selectPathname,
  (isOnCampaignBuilder, currentFilter, currentSubmissionFilter, pathname) => {
    if (isOnCampaignBuilder) {
      return CAMPAIGN_BUILDER
    }
    if (pathname.startsWith(`/${PageRoutes.Campaigns}`)) {
      return currentSubmissionFilter
    }
    if (currentFilter === BrandFanFilters.All) {
      return 'brand_fans_all_creators'
    }
    if (currentFilter === BrandFanFilters.BrandFans) {
      return 'brand_fans'
    }
    if (currentFilter === BrandFanFilters.Groups) {
      if (pathname.split('/').length <= 2) {
        return 'brand_fans_groups'
      } else {
        return 'brand_fans_group_details'
      }
    }
    return currentFilter
  },
)

export const selectCurrentBrandFansSort = createSelector(
  selectInfluencerIdentitySortOptions,
  selectCurrentSortBy,
  (defaultSortOptions, currentSort) => currentSort || defaultSortOptions[0],
)

export const selectCurrentBrandFansBrandFilter = createSelector(
  selectCurrentBrandFilter,
  (brandFilter) => (brandFilter ? decodeURIComponent(brandFilter) : 'all_brands'),
)

export const selectCurrentCampaignStatus = createSelector(
  selectLocationsearchAsObject,
  (searchObject) => searchObject.status || 'all',
)

export const selectCurrentCampaignBuilderStep = createSelector(selectPathname, (pathname) => {
  if (pathname.includes(BuilderSteps.BRAND)) {
    return BuilderSteps.BRAND
  }
  if (pathname.includes(BuilderSteps.CAMPAIGN)) {
    return BuilderSteps.CAMPAIGN
  }
  if (pathname.includes(BuilderSteps.BRIEF)) {
    return BuilderSteps.BRIEF
  }
  if (pathname.includes(BuilderSteps.BUDGET)) {
    return BuilderSteps.BUDGET
  }
  return BuilderSteps.SUBMIT
})

export const selectMediaTypeFilter = createSelector(
  selectLocationsearchAsObject,
  (searchObject) => searchObject.media_type_filter,
)

export const selectChannelFilter = createSelector(
  selectLocationsearchAsObject,
  (searchObject) => searchObject.channel_filter,
)

export const selectBrandFilter = createSelector(
  selectLocationsearchAsObject,
  (searchObject) => searchObject.brand_filter,
)

export const selectContentLibraryFilterKey = createSelector(
  selectContentLibraryFilter,
  selectMediaTypeFilter,
  selectChannelFilter,
  selectBrandFilter,
  (filter, mediaTypeFilter = 'all', channelFilter = 'all', brandFilter = 'all') => {
    return `${filter}:mediaType:${mediaTypeFilter}:channel:${channelFilter}:brand:${brandFilter}`
  },
)
