interface ILoginResponse {
  authResponse: any
  status: string
}

const FACEBOOK_CONNECTED_STATUS = 'connected'

const FACEBOOK_AD_ACCOUNT_SCOPES: ReadonlyArray<string> = ['name', 'business_name']
const FACEBOOK_AD_ACCOUNT_API_URL = `/me/adaccounts?fields=${FACEBOOK_AD_ACCOUNT_SCOPES.join(',')}`

const FACEBOOK_CHECK_PERMISSIONS = `/me/permissions`

const FACEBOOK_PAGES_SCOPES: ReadonlyArray<string> = ['instagram_business_account', 'access_token']
const FACEBOOK_USER_ACCOUNTS_URL = `/me/accounts?fields=${FACEBOOK_PAGES_SCOPES.join(',')}`

export const FacebookService = {
  login: () => {
    const facebook_login_scopes = [
      'ads_management',
      'instagram_basic',
      'pages_show_list',
      'business_management',
    ]

    const FACEBOOK_LOGIN_OPTIONS = {
      return_scopes: true,
      scope: facebook_login_scopes.join(','),
    }

    return new Promise((resolve, reject) => {
      FacebookService.getSdk().login((response: ILoginResponse) => {
        if (response.status === FACEBOOK_CONNECTED_STATUS) {
          resolve(response.authResponse)
        } else {
          reject(response)
        }
      }, FACEBOOK_LOGIN_OPTIONS)
    })
  },
  getAdAccounts: () =>
    FacebookService.ensureLoggedIn().then(() => {
      return new Promise((resolve) => {
        FacebookService.getSdk().api(FACEBOOK_AD_ACCOUNT_API_URL, (response: any) =>
          resolve(response),
        )
      }).then((response: any) => {
        return response.data
      })
    }),

  alwaysRequireLogin: () =>
    new Promise((resolve) => {
      resolve(FacebookService.login())
    }),

  getInstagramAccounts: () =>
    FacebookService.alwaysRequireLogin().then(() => {
      return new Promise((resolve) => {
        FacebookService.getSdk().api(FACEBOOK_USER_ACCOUNTS_URL, (response: any) =>
          resolve(response),
        )
      }).then((response: any) => {
        return response.data
      })
    }),

  getPermissions: () =>
    FacebookService.ensureLoggedIn().then(() => {
      return new Promise((resolve) => {
        FacebookService.getSdk().api(FACEBOOK_CHECK_PERMISSIONS, (response: any) =>
          resolve(response),
        )
      }).then((response: any) => {
        return response.data
      })
    }),

  getAccessToken: () =>
    new Promise((resolve) => resolve(FacebookService.getSdk().getAccessToken())),

  getSdk: () => (window as any).FB,

  ensureLoggedIn: () =>
    new Promise((resolve) => {
      if (FacebookService.getSdk().getAccessToken()) {
        resolve(FacebookService.getAccessToken())
      } else {
        resolve(FacebookService.login())
      }
    }),
}
