import { PureComponent } from 'react'

import { IMultiframeContextProps } from '@context/MultiframeControls'
import { withMultiframeControls } from '@hocs'
import { getPercentage } from '@utils'

interface IMultiframeAutoplayProps extends IMultiframeContextProps {
  autoplayInterval?: number
}

interface IMultiframeAutoplayState {
  autoplayPaused: boolean
  autoplaySeconds: number
}

export const AUTOPLAY_TIMER_TICK_INTERVAL = 100
const AUTOPLAY_INTERVAL = 7000

export class MultiframeAutoplay extends PureComponent<
  IMultiframeAutoplayProps,
  IMultiframeAutoplayState
> {
  state = {
    autoplayPaused: false,
    autoplaySeconds: 0,
  }
  private timer
  resetAutoplay = () => {
    this.setState({
      autoplaySeconds: 0,
    })
  }
  startAutoplay = () => {
    // tslint:disable-next-line
    this.timer = setInterval(this.onAutoplayTick, AUTOPLAY_TIMER_TICK_INTERVAL)
  }
  onAutoplayTick = () => {
    if (!this.props.autoplayPaused) {
      this.setState(
        (prevState) => ({
          autoplaySeconds: prevState.autoplaySeconds + AUTOPLAY_TIMER_TICK_INTERVAL,
        }),
        this.shouldSliderGoToNextFrame,
      )
      this.updateActiveFrameProgress()
    }
  }
  updateActiveFrameProgress = () => {
    const { activeFrameNumber, setActiveFrameProgress, autoplayInterval } = this.props
    const video = this.props.getSlideVideo(activeFrameNumber)
    const duration = video && video.duration
    if (duration) {
      setActiveFrameProgress(getPercentage(video!.currentTime, duration))
    }
    if (!video) {
      const usedAutoplayInterval = autoplayInterval || AUTOPLAY_INTERVAL
      setActiveFrameProgress(getPercentage(this.state.autoplaySeconds, usedAutoplayInterval))
    }
  }
  componentDidUpdate(prevProps: IMultiframeAutoplayProps) {
    const activeFrameNumber = this.props.activeFrameNumber
    const previousFrameNumber = prevProps.activeFrameNumber
    if (activeFrameNumber !== previousFrameNumber) {
      this.props.stopVideo(previousFrameNumber)
      this.resetAutoplay()
    }
  }
  shouldSliderGoToNextFrame = (videoDonePlayingFromArg?: boolean) => {
    const { autoplayInterval, activeFrameNumber } = this.props
    const usedAutoplayInterval = autoplayInterval || AUTOPLAY_INTERVAL
    const video = this.props.getSlideVideo(activeFrameNumber)
    const videoDonePlaying = video
      ? video.currentTime >= video.duration
      : this.state.autoplaySeconds >= usedAutoplayInterval
    const condition = videoDonePlayingFromArg || videoDonePlaying

    if (condition) {
      this.goToNextFrame()
    }
  }
  goToNextFrame = () => {
    const { frames, next, goToFrame, activeFrameNumber } = this.props
    const video = this.props.getSlideVideo(activeFrameNumber)
    this.resetAutoplay()
    if (video && frames.length === 1) {
      this.props.playVideo(this.props.activeFrameNumber)
    } else if (activeFrameNumber >= frames.length) {
      goToFrame(1)
    } else {
      next()
    }
  }
  componentWillUnmount() {
    this.props.stopVideo(this.props.activeFrameNumber)
    clearInterval(this.timer)
  }
  componentDidMount() {
    this.startAutoplay()
    this.props.playVideo(this.props.activeFrameNumber)
  }
  render() {
    return null
  }
}

export default withMultiframeControls(MultiframeAutoplay)
