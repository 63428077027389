import React from 'react'

import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import ActionIcon from '@components/UI/ActionIcon'
import StopPropagation from '@components/UI/StopPropagation/StopPropagation'
import { IModalContextProps } from '@context/Modal/Provider'
import { EventTrackingNames } from '@enums'
import { withModal } from '@hocs'
import Theme from '@theme'
import { Container, DropdownMenu, Icon, MenuItem } from '@tribegroup'
import { BrandFansGroupModalEditDescription, BrandFansGroupModalRename } from '../Modal'
import BrandFansGroupRowCreateCampaignLink from './CreateCampaign/Link'
import BrandFansGroupRowTrackedRoute from './TrackedRoute'

interface IInternalProps extends IProps, IModalContextProps {}

interface IProps {
  groupId: number
  canCreateCampaign: boolean
  hideCreateCampaign?: boolean
}

const BrandFansGroupRowActions: React.FC<IInternalProps> = ({
  groupId,
  showModal,
  canCreateCampaign,
  hideCreateCampaign = false,
}) => {
  const openRenameModal = () => {
    showModal(BrandFansGroupModalRename, { groupId })
  }
  const openEditDescriptionModal = () => {
    showModal(BrandFansGroupModalEditDescription, { groupId })
  }

  return (
    <Flex alignCenter>
      <Container rightOuterSpacing={0.5}>
        <StopPropagation>
          <DropdownMenu
            indicatorSpace={0}
            top={0}
            bottom="auto"
            right={-1.25}
            width={11.75}
            elementName="brand-fans-group-actions-menu"
            target={
              <ActionIcon
                hoverBackgroundColor={Theme.grey300Color}
                size={1}
                glyph="more"
                color={Theme.defaultColor}
                elementName="brand-fans-group-actions"
              />
            }
          >
            {!hideCreateCampaign && (
              <BrandFansGroupRowCreateCampaignLink
                eventName={EventTrackingNames.BrandFansGroupsListMenu}
                groupId={groupId}
                canCreateCampaign={canCreateCampaign}
                additionalProperties={{ group_action: 'start_campaign' }}
              >
                <MenuItem>
                  <FormattedMessage id="brandFans.groups.actions.createCampaign" />
                </MenuItem>
              </BrandFansGroupRowCreateCampaignLink>
            )}

            <BrandFansGroupRowTrackedRoute
              eventName={EventTrackingNames.BrandFansGroupsListMenu}
              groupId={groupId}
              hasTracking
              additionalProperties={{ group_action: 'rename' }}
            >
              <MenuItem onClick={openRenameModal}>
                <FormattedMessage id="brandFans.groups.actions.renameGroup" />
              </MenuItem>
            </BrandFansGroupRowTrackedRoute>
            <BrandFansGroupRowTrackedRoute
              eventName={EventTrackingNames.BrandFansGroupsListMenu}
              groupId={groupId}
              hasTracking
              additionalProperties={{ group_action: 'edit_description' }}
            >
              <MenuItem onClick={openEditDescriptionModal}>
                <FormattedMessage id="brandFans.groups.actions.editDescription" />
              </MenuItem>
            </BrandFansGroupRowTrackedRoute>
          </DropdownMenu>
        </StopPropagation>
      </Container>
      <Icon size={1.5} glyph="chevron-right" color={Theme.defaultColor} />
    </Flex>
  )
}

export { BrandFansGroupRowActions }
export default compose<IInternalProps, IProps>(withModal)(BrandFansGroupRowActions)
