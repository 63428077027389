import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectCampaignId } from '@store/router'
import { selectHasAnyNotes, selectIsPriceHidden, selectSubTotalCents } from '@store/submissions'
import Theme from '@theme'
import { Container, DropdownMenu, Icon, MenuItem, MenuItemLink } from '@tribegroup'
import { isEmpty } from '@utils'
import { buildSubmissionUrlById } from '@utils/urls'

export interface IMoreMenuActionProps {
  submissionId: number
  showCostBreakdown: (event: React.MouseEvent) => void
  topPosition?: number
  rightPosition?: number
}

interface IInternalProps extends IMoreMenuActionProps, RouteComponentProps {
  campaignId: number
  hasNotes: boolean
  hasPrice: boolean
}

const SubmissionCardMoreMenu: React.FC<IInternalProps> = ({
  submissionId,
  campaignId,
  showCostBreakdown,
  hasNotes,
  hasPrice,
  topPosition = 4,
  rightPosition = 1,
}) => {
  return (
    <Container
      data-cy-element="submission-card-more-menu"
      topPosition={topPosition}
      absolute
      rightPosition={rightPosition}
    >
      <DropdownMenu
        target={
          <Icon
            key={submissionId}
            glyph="more"
            size={1}
            color={Theme.grey700Color}
            className="card-menu-toggle"
          />
        }
        indicatorSpace={0}
        top={0}
        bottom="auto"
        right={-1.25}
        width={11.75}
      >
        {hasPrice && (
          <ConnectedTrackedRoute
            eventName={EventTrackingNames.ViewPriceSummary}
            submissionId={submissionId}
            campaignId={campaignId}
          >
            <MenuItem onClick={showCostBreakdown} elementName="view-cost-breakdown">
              <FormattedMessage id="submission.card.menu.costBreakdown" />
            </MenuItem>
          </ConnectedTrackedRoute>
        )}
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.ToolbarViewSubmissionNotes}
          submissionId={submissionId}
          campaignId={campaignId}
        >
          <MenuItemLink
            to={`${buildSubmissionUrlById(campaignId, submissionId)}/notes${location.search}`}
            elementName="notes"
          >
            <FormattedMessage
              id={hasNotes ? 'submission.card.menu.viewNotes' : 'submission.card.menu.addNotes'}
            />
          </MenuItemLink>
        </ConnectedTrackedRoute>
      </DropdownMenu>
    </Container>
  )
}

interface IStateProps extends RouteComponentProps, IMoreMenuActionProps {}

const mapStateToProps = (state: IApplicationState, { submissionId, match }: IStateProps) => {
  const campaignId = selectCampaignId(match)
  return {
    campaignId,
    hasNotes: selectHasAnyNotes(state, submissionId),
    hasPrice:
      !isEmpty(selectSubTotalCents(state, submissionId)) &&
      !selectIsPriceHidden(state, submissionId),
  }
}

export { SubmissionCardMoreMenu }
export default compose<IInternalProps, IMoreMenuActionProps>(
  withRouter,
  connect(mapStateToProps),
)(SubmissionCardMoreMenu)
