import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import { FetchBrandsActionTypes, fetchBrandsError, fetchBrandsSuccess } from '@store/brands'
import { selectFetchBrandsLink } from '@store/me'
import { networkRequest } from '@utils'

export function* handleFetchBrands() {
  try {
    const authToken: string = yield select(selectAuthToken)
    const brandsLink: IHateoasLink = yield select(selectFetchBrandsLink)
    const result = yield call(
      networkRequest,
      brandsLink.method,
      brandsLink.href,
      undefined,
      authToken,
    )
    yield put(fetchBrandsSuccess(result))
  } catch (error) {
    yield put(fetchBrandsError(error))
  }
}

export function* watchFetchBrandsRequest() {
  yield takeLatest(FetchBrandsActionTypes.FETCH_REQUEST, handleFetchBrands)
}
