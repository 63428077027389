import { call, put, takeLatest } from 'redux-saga/effects'

import { fetchIPError, fetchIPSuccess, IPActionTypes } from '@store/auth'
import { networkRequest } from '@utils'

export const ACCESS_KEY = 'bc1d8e54b8d1d2d8b35486829bef3fc8'
export const HOST = 'https://api.ipstack.com'

export function* handleCheckIP() {
  try {
    const headers = {
      Accept: 'application/json',
    }

    const result = yield call(
      networkRequest,
      'GET',
      `${HOST}/check?access_key=${ACCESS_KEY}&fields=country_code`,
      undefined,
      undefined,
      headers,
    )
    yield put(fetchIPSuccess(result))
  } catch (error) {
    yield put(fetchIPError(error))
  }
}

export function* watchCheckRequest() {
  yield takeLatest(IPActionTypes.CHECK_IP_REQUEST, handleCheckIP)
}
