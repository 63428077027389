import styled from 'styled-components'

import { Container } from '@tribegroup'

export const ProfileIconWrapper = styled(Container)`
  vertical-align: middle;
`

export const SubmissionImageWrapper = styled.div`
  border-radius: 0.25rem;
  overflow: hidden;
  width: 4rem;
  height: 4rem;
  margin-right: 1.25rem;
`
