export enum MetricDisputesActionTypes {
  PENDING = 'metricDisputes/pending',
  FULFILLED = 'metricDisputes/fulfilled',
  FAILED = 'metricDisputes/failed',
}

export type MetricDisputeIssueType = 'incorrect' | 'unavailable'

export type MetricDisputeFormDataType = {
  submissionId: number
  issueType: MetricDisputeIssueType
  description?: string
}

export type MetricDisputeState = {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  errors?: any
}
