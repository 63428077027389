import React, { PropsWithChildren } from 'react'

import { ConnectedTrackedRoute } from '../TrackedRoute'
import { ITrackingProps } from './SummaryNavigation'

export interface INavigationTrackedRouteProps extends ITrackingProps {
  source: string
}

const NavigationTrackedRoute: React.FC<PropsWithChildren<INavigationTrackedRouteProps>> = ({
  children,
  source,
  eventName,
  ...otherTrackingProps
}) => {
  return (
    <ConnectedTrackedRoute
      eventName={eventName}
      {...otherTrackingProps}
      additionalProperties={{
        source,
      }}
    >
      {children}
    </ConnectedTrackedRoute>
  )
}

export { NavigationTrackedRoute }
export default NavigationTrackedRoute
