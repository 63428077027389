import React from 'react'

import { FormattedMessage } from 'react-intl'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import Flex, { FlexItem } from 'styled-flex-component'

import { SignUpCallToAction } from '@components/ShareableContentLibrary/Buttons'
import ShareableContentLibraryTitleBarLogo from '@components/ShareableContentLibrary/List/TitleBar/Logo'
import TitleBarStyled, { HeaderContent, HeaderTitle } from '@components/UI/TitleBar/TitleBar.styled'
import TribeOnly from '@components/UI/TribeOnly'
import Theme from '@theme'
import { Container, H5 } from '@tribegroup'
import BackButton from './BackButton'

const ShareableContentLibraryFullTitleBar: React.FC = () => (
  <Container relative>
    <TitleBarStyled>
      <Flex alignCenter full>
        <Container absolute>
          <ShareableContentLibraryTitleBarLogo />
        </Container>
        <Col xs sm>
          <Flex full alignCenter>
            <FlexItem grow={1}>
              <Grid>
                <Row center="xs" middle="xs">
                  <Col sm={8} md={10}>
                    <Flex full alignCenter justifyBetween>
                      <FlexItem>
                        <BackButton />
                      </FlexItem>
                      <FlexItem grow={1}>
                        <HeaderTitle>
                          <H5 color={Theme.grey900Color}>
                            <FormattedMessage id="contentLibrary.shareable.backToLibrary" />
                          </H5>
                        </HeaderTitle>
                      </FlexItem>
                    </Flex>
                  </Col>
                </Row>
              </Grid>
            </FlexItem>
          </Flex>
        </Col>
        <TribeOnly>
          <Container absolute rightPosition={0}>
            <HeaderContent alignCenter>
              <SignUpCallToAction />
            </HeaderContent>
          </Container>
        </TribeOnly>
      </Flex>
    </TitleBarStyled>
  </Container>
)

export { ShareableContentLibraryFullTitleBar }
export default ShareableContentLibraryFullTitleBar
