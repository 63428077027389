import React from 'react'

import Avatar from '@components/UI/Avatar'
import { StopPropagation } from '@components/UI/StopPropagation/StopPropagation'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'
import { StarWrapper } from './Avatar.styled'

interface IInfluencerIdentityRowAvatarProps {
  socialUrl: string
  avatarSrc: string
  username: string
  avatarRightSpacing?: number
  showStar?: boolean
}

const InfluencerIdentityRowAvatar: React.FC<IInfluencerIdentityRowAvatarProps> = ({
  socialUrl,
  avatarSrc,
  username,
  showStar,
  avatarRightSpacing = 1.25,
}) => (
  <Container
    rightOuterSpacing={avatarRightSpacing}
    data-testid="influencer-identity-row-avatar"
    relative
  >
    <StopPropagation>
      <Avatar size={3.75} socialUrl={socialUrl} avatarSrc={avatarSrc} username={username} />
      {showStar && (
        <StarWrapper>
          <Icon glyph="star-rating" color={Theme.primaryColor} size={0.75} />
        </StarWrapper>
      )}
    </StopPropagation>
  </Container>
)

export { InfluencerIdentityRowAvatar, IInfluencerIdentityRowAvatarProps }
export default InfluencerIdentityRowAvatar
