import { FC, FormEvent, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { CreditManagementWrapper } from '@components/MediaObject/Licensing/CreditManagement/Wrapper'
import { LicenseBooklet } from '@components/UI/LicenseBooklet/LicenseBooklet'
import Loading from '@components/UI/Loading'
import { RELICENSE_STATUSES } from '@constants'
import { LicenseStatuses } from '@enums'
import { useModal } from '@hooks/useModal'
import { IApplicationState } from '@store/index'
import {
  fetchMediaObjectLicenseOptions,
  ILicense,
  selectIsFetching,
  selectMediaObjectLicenseOptions,
} from '@store/licenses'
import {
  selectFirstErrorCode,
  selectHasInsufficientFundError,
  selectMediaObject,
  selectMediaObjectIsRequestingLicense,
} from '@store/mediaObjects'
import { CloseIcon, Modal } from '@tribegroup'
import { isEmpty } from '@utils/isEmpty'
import { MediaObjectRequestLicenseFooter } from './Footer'
import { MediaObjectRequestLicenseHeader } from './Header'
import { MediaObjectRequestLicensePriceSummary } from './PriceSummary'

interface IProps {
  mediaObjectId: number
}

export const MediaObjectRequestLicense: FC<IProps> = ({ mediaObjectId }) => {
  const { isOpen, hideModal, showModal } = useModal()
  const dispatch = useDispatch()

  const [selectedLicenseIndex, setSelectedLicenseIndex] = useState(0)

  const { mediaObject, licenseOptions, isFetching } = useSelector((state: IApplicationState) => {
    const mediaObject = selectMediaObject(state, mediaObjectId)
    const licenseOptions = selectMediaObjectLicenseOptions(state, mediaObject?.id)

    return {
      mediaObject,
      licenseOptions,
      isFetching: selectIsFetching(state),
      hasInsufficientFundError: selectHasInsufficientFundError(state),
      errorCode: selectFirstErrorCode(state),
      isRequesting: selectMediaObjectIsRequestingLicense(state),
    }
  })

  useEffect(() => {
    dispatch(fetchMediaObjectLicenseOptions(mediaObject.id))
  }, [])

  if (isFetching && isEmpty(licenseOptions)) {
    return (
      <Modal reactModalProps={{ isOpen: Boolean(isOpen) }} width={55} padding={0}>
        <Loading />
      </Modal>
    )
  }

  const selectLicenseOption = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    const elementValue = target.value

    const index = licenseOptions.findIndex((value) => `${value.license_type}` === `${elementValue}`)

    setSelectedLicenseIndex(index)
  }

  const onClick = () => {
    const transactedLicense = mediaObject.transacted_license

    if (transactedLicense && transactedLicense.status === 'requested') {
      return hideModal()
    }

    return showModal(CreditManagementWrapper, { mediaObjectId, licenseIndex: selectedLicenseIndex })
  }

  return (
    <Modal reactModalProps={{ isOpen: Boolean(isOpen) }} width={55} padding={0} mobilePadding={0}>
      <CloseIcon onClick={hideModal} />
      <LicenseBooklet
        mediaObject={mediaObject}
        licenseOptions={licenseOptions}
        header={<MediaObjectRequestLicenseHeader mediaObject={mediaObject} />}
        onSelectLicenseOption={selectLicenseOption}
      >
        <MediaObjectRequestLicensePriceSummary
          license={
            (mediaObject.transacted_license &&
            !RELICENSE_STATUSES.includes(mediaObject.transacted_license?.status as LicenseStatuses)
              ? mediaObject.transacted_license
              : licenseOptions[selectedLicenseIndex]) as ILicense
          }
        />

        <MediaObjectRequestLicenseFooter onClick={onClick} mediaObject={mediaObject} />
      </LicenseBooklet>
    </Modal>
  )
}
