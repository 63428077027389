import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Checkbox, Container, H4 } from '@tribegroup'

interface IProps {
  durationInDaysOptions: ReadonlyArray<number>
  durationInDays: number
}

const DurationOptionsReadOnly: React.FC<IProps> = ({ durationInDaysOptions, durationInDays }) => {
  return (
    <React.Fragment>
      {durationInDaysOptions.map((durationOption) => {
        const isSelected = durationOption === durationInDays
        return (
          <Container bottomOuterSpacing={0.5} key={durationOption} height={2}>
            <Checkbox
              checked={isSelected}
              backgroundColor={isSelected ? Theme.primary200Color : undefined}
              hideBorder={isSelected}
              scheme="primary"
              noHover
              label={
                <H4 color={isSelected ? Theme.defaultColor : Theme.grey600Color}>
                  <FormattedMessage id="core.text.xDays" values={{ days: durationOption }} />
                </H4>
              }
              circular
              value={durationOption}
            />
          </Container>
        )
      })}
    </React.Fragment>
  )
}

export { DurationOptionsReadOnly }
export default DurationOptionsReadOnly
