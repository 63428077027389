import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Button, Header } from '@tribegroup'

const ButtonRightsDeclined: React.FC = () => (
  <Button scheme="primary" fullWidth disabled>
    <Header color={Theme.grey800Color} uppercase cta>
      <FormattedMessage id="submission.bca.rights.declined" />
    </Header>
  </Button>
)

export { ButtonRightsDeclined }
export default ButtonRightsDeclined
