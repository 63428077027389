import { ILicenseOption } from '@store/campaigns'
import { MediaObjectLicenseOptionsActionTypes } from '@store/licenses'

export const fetchMediaObjectLicenseOptions = (mediaObjectId: number) => ({
  payload: mediaObjectId,
  type: MediaObjectLicenseOptionsActionTypes.FETCH_OPTIONS,
})

export const fetchMediaObjectLicenseOptionsSuccess = (
  mediaObjectId: number,
  licenseOptions: ReadonlyArray<ILicenseOption>,
) => ({
  payload: { mediaObjectId, licenseOptions },
  type: MediaObjectLicenseOptionsActionTypes.FETCH_OPTIONS_SUCCESS,
})

export const fetchMediaObjectLicenseOptionsError = (error: any) => ({
  payload: error,
  type: MediaObjectLicenseOptionsActionTypes.FETCH_OPTIONS_ERROR,
})
