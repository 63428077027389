import { IMeta } from '@store'
import { ISubmission, ShortlistActionTypes } from '@store/submissions'

export const shortlistSubmissionRequest = (
  campaignId: number,
  submissionId: number,
  { filter, sortBy, refreshSubmissions, event }: IMeta = {},
) => ({
  payload: { campaignId, submissionId },
  type: ShortlistActionTypes.SHORTLIST_REQUEST,
  meta: { filter, sortBy, refreshSubmissions, event } as IMeta,
})

export const shortlistSubmissionSuccess = (submission: ISubmission, { event }: IMeta = {}) => ({
  payload: submission,
  type: ShortlistActionTypes.SHORTLIST_SUCCESS,
  meta: { event },
})

export const shortlistSubmissionError = (error: any) => ({
  payload: error,
  type: ShortlistActionTypes.SHORTLIST_ERROR,
})
