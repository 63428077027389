import { useEffect, useMemo, useRef, useState } from 'react'

import { addDays, differenceInCalendarDays, format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { FULL_DATE_WITH_DAY_OF_WEEK } from '@constants'
import { BuilderDefault } from '@enums'
import Theme from '@theme'
import { H2, Icon, Slider, Text } from '@tribegroup'
import { FocusableDropdown } from '../../Form.styled'
import { DurationSelector, DurationWrapper, TextWrapper } from './Duration.styled'

export interface IBuilderSubmitCampaignScheduleDurationProps {
  defaultValue?: number
  disabled: boolean
  startDate?: Date
  onChange?: (startDate: Date, endDate: Date) => void
  invalid?: boolean
}

const BuilderSubmitCampaignScheduleDuration: React.FC<IBuilderSubmitCampaignScheduleDurationProps> = ({
  defaultValue,
  startDate,
  disabled,
  invalid,
  onChange: propOnChange,
}) => {
  const [value, setValue] = useState(defaultValue || BuilderDefault.DEFAULT_DURATION_DAYS)
  const [open, setOpen] = useState<boolean>(false)

  const dropdownElementRef = useRef<HTMLDivElement>()

  const onOutsideClick = (event: MouseEvent) => {
    const dropdownElement = dropdownElementRef.current
    if (dropdownElement && !dropdownElement.contains(event.target as Node)) {
      setOpen(false)
    }
  }

  const onClick = () => {
    setOpen(true)
  }

  const onChange = () => {
    if (propOnChange) {
      const newStartDate = startDate || new Date()
      const endDate = addDays(newStartDate, value)
      propOnChange(newStartDate, endDate)
    }
  }

  const MIN_DAYS = useMemo(
    () =>
      Math.max(
        BuilderDefault.MIN_DURATION_DAYS,
        differenceInCalendarDays(new Date(), startDate || new Date()),
      ),
    [startDate],
  )

  const onSliderChange = (value: number) => {
    setValue(Math.max(MIN_DAYS, value))
  }

  const startDateOrNow = startDate || new Date()
  const actualValue = value && (value < MIN_DAYS ? MIN_DAYS : value)

  useEffect(() => {
    document.addEventListener('mousedown', onOutsideClick)
    return () => {
      document.removeEventListener('mousedown', onOutsideClick)
    }
  }, [])

  return (
    <DurationWrapper>
      <FocusableDropdown onClick={!disabled ? onClick : undefined} tabIndex={0} invalid={invalid}>
        <Flex>
          {startDate && defaultValue ? (
            <H2 color={Theme.grey900Color}>
              <FormattedMessage id="core.text.xDays" values={{ days: actualValue }} />
            </H2>
          ) : (
            <H2 color={Theme.grey700Color}>
              <FormattedMessage id="builder.submit.schedule.duration" />
            </H2>
          )}
          <Icon
            glyph="chevron-down"
            color={Theme.defaultColor}
            size={1.5}
            data-cy-element="duration-chevron"
            data-testid="duration-chevron"
          />
        </Flex>
      </FocusableDropdown>
      {open && (
        <DurationSelector
          ref={dropdownElementRef}
          data-cy-element="duration-selector"
          data-testid="duration-selector"
        >
          <H2 color={Theme.defaultColor} bottomOuterSpacing={0.25}>
            <FormattedMessage id="core.text.xDays" values={{ days: actualValue }} />
          </H2>
          <TextWrapper>
            <Text color={Theme.defaultColor}>
              <FormattedMessage
                id="builder.submit.schedule.duration.until"
                values={{
                  date: format(addDays(startDateOrNow, actualValue), FULL_DATE_WITH_DAY_OF_WEEK),
                }}
              />
            </Text>
          </TextWrapper>
          <Slider
            labelCount={4}
            minValue={5}
            maxValue={45}
            value={value!}
            onChange={onSliderChange}
            onChangeComplete={onChange}
            elementName="campaign-duration-slider"
          />
        </DurationSelector>
      )}
    </DurationWrapper>
  )
}

export default BuilderSubmitCampaignScheduleDuration
