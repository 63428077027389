import LineClamp from '@components/UI/LineClamp'
import { ICampaign } from '@store/campaigns'
import Theme from '@theme'
import { H5 } from '@tribegroup'
import { TitleWrapper } from './Title.styled'

interface ICampaignCardTitle {
  campaign: ICampaign
}

const CampaignCardTitle = ({ campaign }: ICampaignCardTitle) => (
  <TitleWrapper data-cy-element="campaign-title">
    <H5 color={Theme.grey900Color}>
      {campaign && campaign.product_name && (
        <LineClamp fallbackHeight={3} lines={2}>
          {campaign.product_name.split(' ').join(' ')}
        </LineClamp>
      )}
    </H5>
  </TitleWrapper>
)

export default CampaignCardTitle
