import { format, isToday } from 'date-fns'

import { DAY_MONTH_TIME_WITH_DAY_OF_WEEK } from '@constants'

export const drawerDateFormat = (date: Date) => {
  if (isToday(date)) {
    return {
      id: 'inbox.drawer.dateformat.today',
      values: {
        time: format(date, 'H:mm'),
      },
    }
  }

  return {
    id: 'inbox.drawer.dateformat.date',
    values: {
      date: format(date, DAY_MONTH_TIME_WITH_DAY_OF_WEEK),
    },
  }
}

export default drawerDateFormat
