import { ResponseError } from '@lib/error'
import Payment from '@lib/payment'
import { ICreditCardDetails } from '@store/me'

export const createPaymentToken = (cardDetails: ICreditCardDetails, apiKey: string) => {
  Payment.setPublishableKey(apiKey)
  return new Promise((resolve, reject) => {
    return Payment.card.createToken(cardDetails, (status: number, response: any) => {
      if (status === 200) {
        resolve(response)
      } else {
        const { error } = response
        const respError = new ResponseError(
          {
            errors: [{ error_code: error.code, message: error.message }],
          },
          status,
        )
        reject(respError)
      }
    })
  })
}
