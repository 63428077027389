import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { SideBarBlock, SideBarBlockItem } from '@components/UI/SideBar'
import TrackedRoute from '@components/UI/TrackedRoute/TrackedRoute'
import { IMobileMenuControlsContextProps } from '@context/MobileMenuControls'
import { ContentLibraryFilterTypes, EventTrackingNames, PageRoutes } from '@enums'
import { withMobileMenuControls } from '@hocs'
import { IApplicationState } from '@store'
import { selectContentLibraryFilters } from '@store/me'
import { fetchMediaObjects, IMediaObjectStats, selectMediaObjectsStats } from '@store/mediaObjects'
import { selectContentLibraryFilter } from '@store/router'
import { objectToQueryString } from '@utils'
import { isActiveMenu } from '@utils/urls'

interface IInternalProps extends IMobileMenuControlsContextProps {
  fetchMediaObjects: typeof fetchMediaObjects
  contentLibraryFilters: ReadonlyArray<string>
  contentLibraryStats: IMediaObjectStats
  contentLibraryCurrentFilter: ContentLibraryFilterTypes
}

const ContentLibraryFilters: React.FC<IInternalProps> = ({
  contentLibraryFilters,
  contentLibraryStats,
  hideMobileMenu,
  contentLibraryCurrentFilter,
  fetchMediaObjects: fetchMediaObjectsAction,
}) => {
  React.useEffect(() => {
    fetchMediaObjectsAction(contentLibraryCurrentFilter)
  }, [])

  const { pathname } = useLocation()

  return (
    <SideBarBlock
      header={
        <Flex justifyBetween alignCenter>
          <FormattedMessage id="contentLibrary.sidebar.header" />
        </Flex>
      }
      items={[
        ...contentLibraryFilters.map((filter: string) => (
          <TrackedRoute
            key={filter}
            eventName={EventTrackingNames.ViewContentLibrary}
            properties={{
              current_filter: `content_library_${filter}`,
              library_size: contentLibraryStats[filter],
            }}
          >
            <SideBarBlockItem
              key={filter}
              to={{
                pathname: PageRoutes.ContentLibrary,
                search: objectToQueryString({ filter }),
              }}
              text={<FormattedMessage id={`contentLibrary.sidebar.filter.${filter}`} />}
              active={isActiveMenu(
                pathname,
                PageRoutes.ContentLibrary,
                contentLibraryCurrentFilter,
                filter,
              )}
              onClick={hideMobileMenu}
              count={contentLibraryStats[filter]}
              elementName={`content-library-sidebar-${filter}`}
            />
          </TrackedRoute>
        )),
      ]}
    />
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  contentLibraryFilters: selectContentLibraryFilters(state),
  contentLibraryCurrentFilter: selectContentLibraryFilter(state),
  contentLibraryStats: selectMediaObjectsStats(state),
})

const mapDispatchToProps = {
  fetchMediaObjects,
}

export { ContentLibraryFilters }
export default compose<IInternalProps, {}>(
  withMobileMenuControls,
  connect(mapStateToProps, mapDispatchToProps),
)(ContentLibraryFilters)
