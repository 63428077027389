import { IMultiframeContextProps } from '@context/MultiframeControls'
import { withMultiframeControls } from '@hocs'
import { ISubmissionFrame } from '@store/submissions'
import { StyledBar, StyledBarProgress, StyledBarsWrapper } from './Bars.styled'

export const MultiframePaginationBars = ({
  frames,
  activeFrameNumber,
  activeFrameProgress,
}: IMultiframeContextProps) => {
  return (
    <StyledBarsWrapper totalCount={frames.length} data-testid="pagination-bars">
      {frames.map((frame: ISubmissionFrame, index: number) => {
        const completed = frame.frame_number! < activeFrameNumber
        const active = frame.frame_number === activeFrameNumber
        return (
          <StyledBar key={`${frame.id}${index}`}>
            {completed && <StyledBarProgress width={100} />}
            {active && (
              <StyledBarProgress width={activeFrameProgress} data-testid="story-active-progress" />
            )}
          </StyledBar>
        )
      })}
    </StyledBarsWrapper>
  )
}

export default withMultiframeControls(MultiframePaginationBars)
