/* eslint-disable react/display-name */
import { SelectedSubmissionsContext } from '@context/SelectedSubmissions'

export const withSelectedSubmissions = (Component) => (props) => {
  return (
    <SelectedSubmissionsContext.Consumer>
      {(contextProps) => <Component {...props} {...contextProps} />}
    </SelectedSubmissionsContext.Consumer>
  )
}
