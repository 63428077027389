import { createSelector } from 'reselect'

import { IApplicationState } from '@store'
import { IShortlistedSubmissionStats, selectSubmissionMetrics } from '@store/submissions'
import { IShortlistSummary } from './types'

export const selectIsFetchingShortlistSummary = (state: IApplicationState) =>
  state.shortlistSummary.isFetching

export const selectShortlistSummary = (state: IApplicationState, campaignId: number) =>
  state.shortlistSummary.shortlistSummaryByCampaignId[campaignId] || ({} as IShortlistSummary)

export const selectShortlistSummaryProjectedSpend = createSelector(
  selectSubmissionMetrics,
  (submissionMetrics: IShortlistedSubmissionStats) => ({
    amount: submissionMetrics ? submissionMetrics.projected_spend_cents / 100 : undefined,
    currency: submissionMetrics?.projected_spend_currency,
  }),
)

export const selectShortlistSummaryFollowers = createSelector(
  selectSubmissionMetrics,
  (submissionMetrics: IShortlistedSubmissionStats) => submissionMetrics?.collective_reach,
)

export const selectShortlistSummaryPayload = createSelector(
  selectSubmissionMetrics,
  (submissionMetrics: IShortlistedSubmissionStats) => ({
    projected_spend_cents: submissionMetrics?.projected_spend_cents,
    projected_spend_currency: submissionMetrics?.projected_spend_currency,
    identities: submissionMetrics?.identities,
  }),
)
