import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import { IApplicationState } from '@store'
import { selectAuthToken } from '@store/auth'

export const onRender = (isAuthenticated, Component, props): JSX.Element => {
  return isAuthenticated ? (
    <Redirect
      to={{
        pathname: '/campaigns',
      }}
    />
  ) : (
    <Component {...props} />
  )
}

export const PublicRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return onRender(isAuthenticated, Component, props)
      }}
    />
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  isAuthenticated: Boolean(selectAuthToken(state)),
})

export default connect(mapStateToProps)(PublicRoute)
