export enum AnalyticsIdentifyActionTypes {
  IDENTIFY_REQUEST = 'analytics/IDENTIFY_REQUEST',
  IDENTIFY_SUCCESS = 'analytics/IDENTIFY_SUCCESS',
  IDENTIFY_ERROR = 'analytics/IDENTIFY_ERROR',
}

export enum AnalyticsTrackingActionTypes {
  SEND_TRACKING_REQUEST = 'analytics/SEND_TRACKING_REQUEST',
  SEND_TRACKING_SUCCESS = 'analytics/SEND_TRACKING_SUCCESS',
  LEGACY_SEND_TRACKING = 'analytics/LEGACY_SEND_TRACKING',
}
