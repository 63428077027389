import { Fragment, useEffect } from 'react'

import { isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import AnalyticsAudienceMatchLabel from '@components/Analytics/AudienceMatch/Label/Label'
import AnalyticsIdentityAudienceInsights from '@components/Analytics/Identity/AudienceInsights'
import { Hr } from '@components/UI/Hr/Hr.styled'
import Loading from '@components/UI/Loading'
import { AudienceInsightsSource, HashLinks } from '@enums'
import { useDrawer } from '@hooks/useDrawer'
import { IApplicationState } from '@store'
import {
  fetchAudienceInsights,
  selectAudienceInsights,
  selectAudienceInsightsIsFetching,
} from '@store/audienceInsights'
import {
  selectAudienceInsightsLink,
  selectIdentityId,
  selectIsAudienceMatch,
  selectIsSubmissionFromTiktok,
} from '@store/submissions'
import Theme from '@theme'
import { Container, H3, Icon, Text } from '@tribegroup'
import AnalyticsAudienceMetricsDrawer from './Drawer'

interface IProps {
  campaignId: number
  submissionId: number
}

type IInternalProps = IProps & ConnectedProps<typeof connector>

const AnalyticsAudienceMetricsWrapper: React.FC<IInternalProps> = ({
  campaignId,
  identityId,
  submissionId,
  isFetching,
  hasAudienceInsightsLink,
  isAudienceMatch,
  isTikTok,
  fetchAudienceInsights: fetchAudienceInsightsAction,
}) => {
  useEffect(() => {
    if (identityId) {
      fetchAudienceInsightsAction(identityId, AudienceInsightsSource.Submission)
    }
  }, [identityId])

  if (isFetching) {
    return <Loading />
  }

  if (!hasAudienceInsightsLink) {
    return <Fragment />
  }

  const { showDrawer } = useDrawer()

  const learnMoreOnClick = () => {
    showDrawer(
      <AnalyticsAudienceMetricsDrawer campaignId={campaignId} />,
      { showHeaderDivider: true },
      <FormattedMessage id="core.text.audienceMetrics" />,
    )
  }

  return (
    <Fragment>
      <Hr backgroundColor={Theme.grey400Color} />
      <Container topOuterSpacing={2.5} bottomOuterSpacing={4} id={HashLinks.AudienceInsights}>
        <Container bottomOuterSpacing={1.625}>
          <Flex full justifyBetween alignCenter>
            <Container>
              <H3 inline color={Theme.grey900Color}>
                <FormattedMessage id="core.text.audienceMetrics" />
              </H3>
              <Container inlineBlock pointer verticalAlign="middle" leftOuterSpacing={1.5}>
                <Text
                  customFontSize={0.8125}
                  lineHeight={1}
                  color={Theme.grey900Color}
                  uppercase
                  customletterSpacing="1.5px"
                  onClick={learnMoreOnClick}
                  data-testid="learn-more-button"
                >
                  <FormattedMessage id="core.text.learnMore" />
                </Text>
                <Icon
                  size={1.5}
                  glyph="chevron-right"
                  color={Theme.grey900Color}
                  verticalAlign="middle"
                />
              </Container>
            </Container>

            {isAudienceMatch && (
              <AnalyticsAudienceMatchLabel submissionId={submissionId} campaignId={campaignId} />
            )}
          </Flex>
        </Container>
        {hasAudienceInsightsLink && (
          <AnalyticsIdentityAudienceInsights identityId={identityId} isTikTok={isTikTok} />
        )}
      </Container>
    </Fragment>
  )
}
const mapStateToProps = (state: IApplicationState, { campaignId, submissionId }: IProps) => {
  const identityId = selectIdentityId(state, submissionId)
  const audienceInsightsLink = selectAudienceInsightsLink(state, submissionId)

  return {
    campaignId,
    identityId,
    submissionId,
    hasAudienceInsightsLink: Boolean(audienceInsightsLink),
    hasAudienceInsights: !isEmpty(selectAudienceInsights(state, identityId)),
    isFetching: selectAudienceInsightsIsFetching(state),
    isAudienceMatch: selectIsAudienceMatch(state, submissionId),
    isTikTok: selectIsSubmissionFromTiktok(state, submissionId),
  }
}

const mapDispatchToProps = {
  fetchAudienceInsights,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<IInternalProps, IProps>(connector)(AnalyticsAudienceMetricsWrapper)
