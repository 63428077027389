import React from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import BuilderCampaignTextAreaPanel from '@components/Builder/Campaign/TextAreaPanel/TextAreaPanel'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { withBuilderValidation } from '@hocs'
import { getPrefilledText, isEmpty } from '@utils'

interface IProps {
  disabled?: boolean
  onFieldChange: (callToAction: string) => void
  onFieldBlur: (callToAction: string) => void
  isSocial?: boolean
  value?: string
}

interface IInternalProps
  extends IProps,
    IDraftContextProps,
    InjectedIntlProps,
    IBuilderValidationContextProps {}

const BuilderCampaignCallToAction: React.FC<IInternalProps> = ({
  value: defaultValue,
  onFieldBlur,
  validationResults,
  isSocial,
  intl,
  clearFieldValidation,
  onFieldChange,
  disabled,
}) => {
  const [value, setValue] = React.useState<string>((defaultValue as string) || '')
  const [actualValue, setActualValue] = React.useState('')

  const prefix = intl.formatMessage({
    id: isSocial ? 'builder.campaign.cta.prefix.social' : 'builder.campaign.cta.prefix.content',
  })
  const prefixWithSpace = `${prefix} `

  const onFocus = (event: React.FormEvent<HTMLInputElement>) => {
    const textArea = event.target as HTMLTextAreaElement

    if (textArea.value.startsWith(prefixWithSpace)) {
      setValue(textArea.value)
    } else {
      setValue(prefixWithSpace)
    }
    if (validationResults && validationResults.call_to_action) {
      clearFieldValidation('call_to_action')
    }
  }

  const onBlur = (event: React.SyntheticEvent) => {
    const textArea = event.target as HTMLTextAreaElement
    clearFieldValidation('call_to_action')
    if (textArea.value.trim() === prefix.trim() || isEmpty(textArea.value.trim())) {
      setValue('')
      onFieldBlur('')
    } else {
      onFieldBlur(value)
    }
  }

  const fixMismatchedPrefix = (thisValue) => {
    const socialPrefix = intl.formatMessage({ id: 'builder.campaign.cta.prefix.social' })
    const contentPrefix = intl.formatMessage({
      id: 'builder.campaign.cta.prefix.content',
    })
    if (isSocial && thisValue.startsWith(contentPrefix)) {
      return thisValue.replace(contentPrefix, socialPrefix)
    } else if (!isSocial && thisValue.startsWith(socialPrefix)) {
      return thisValue.replace(socialPrefix, contentPrefix)
    }
    return thisValue
  }

  const onChange = (event: React.SyntheticEvent) => {
    const textAreaValue = (event.target as HTMLTextAreaElement).value
    const currentValue = fixMismatchedPrefix(textAreaValue)
    const newValue = getPrefilledText(prefixWithSpace, currentValue, value)
    setValue(newValue)
    onFieldChange(newValue)
    setActualValue(textAreaValue)
  }

  return (
    <BuilderCampaignTextAreaPanel
      disabled={disabled}
      data-value={actualValue}
      name="call_to_action"
      placeholderKey={isSocial ? 'call_to_action.social' : 'call_to_action.content'}
      height={3.125}
      maxLength={55}
      required
      moduleName="campaign"
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      validationResults={validationResults}
      additionalTexts={['text']}
      elementName="call-to-action-panel-wrapper"
    />
  )
}

export { BuilderCampaignCallToAction }
export default compose<IInternalProps, IProps>(
  injectIntl,
  withBuilderValidation,
)(BuilderCampaignCallToAction)
