import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import { FadeIn } from '@lib/effects'

const Root = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${FadeIn} 200ms ease-in forwards;

  &::before {
    position: absolute;
    height: 100%;
    width: 100%;
    content: ' ';
    background: #000;
    opacity: 0.85;
    z-index: -1;
  }
`

const Video = styled.video`
  outline: none;
  width: auto;
  max-width: 100vw;
  max-height: 100vh;
  ${breakpoint('tabletPortrait')`
    height: 100%;
    max-height: 72vh;
    max-width: calc(100vw - 2rem);
  `};
`

const Image = styled.img`
  max-width: 100%;
  max-height: calc(100vh - 2rem);
  object-fit: contain;
  ${breakpoint('tabletPortrait')`
    height: 100%;
    max-height: 72vh;
    max-width: calc(100vw - 2rem);
  `};
`

export { Root, Video, Image }
