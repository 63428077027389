import { useContext, useEffect } from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { useDispatch, useSelector } from 'react-redux'
import Flex, { FlexItem } from 'styled-flex-component'

import BrandFanInfluencerIdentityRowTrackedRoute from '@components/BrandFans/InfluencerIdentity/Row/TrackedRoute'
import ActionIcon from '@components/UI/ActionIcon'
import Avatar from '@components/UI/Avatar'
import { EventTrackingNames, FeatureToggles } from '@enums'
import { IApplicationState } from '@store'
import {
  selectHasBrandFanGroupMemberships,
  selectIdentityAvatarSrc,
  selectIdentityId,
  selectIdentitySocialUrl,
  selectIdentityUsername,
  selectIsInfluencerEligibleForBrandFans,
  selectIsStorySocialSubmission,
} from '@store/submissions'
import { fetchBrandFansMembership } from '@store/submissions/actions/fetchSubmissionBrandFanMembership'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'
import { SubmissionCardHeaderContext } from '../Header'

interface IProps {
  onClick?: (event: React.SyntheticEvent<HTMLAnchorElement>) => void
  submissionId: number
  avatar?: React.ReactNode
}

const SubmissionCardHeaderAvatarSocialProfileAvatar = ({
  onClick,
  submissionId,
  avatar,
}: IProps) => {
  const { setIsShowAddToBrandFan, isShowAddToBrandFan } = useContext(SubmissionCardHeaderContext)
  const isBrandFansEnabledForContentCampaigns = useFlag(
    FeatureToggles.BRAND_FANS_FROM_CONTENT_CAMPAIGNS_ENABLED,
  )
  const dispatch = useDispatch()

  const {
    username,
    socialUrl,
    avatarSrc,
    identityId,
    hasBrandFanGroupMemberships,
    isStory,
    isEligibleForBrandFans,
  } = useSelector((state: IApplicationState) => {
    return {
      username: selectIdentityUsername(state, submissionId),
      socialUrl: selectIdentitySocialUrl(state, submissionId),
      avatarSrc: selectIdentityAvatarSrc(state, submissionId),
      identityId: selectIdentityId(state, submissionId),
      isStory: selectIsStorySocialSubmission(state, submissionId),
      hasBrandFanGroupMemberships: selectHasBrandFanGroupMemberships(state, submissionId),
      isEligibleForBrandFans: selectIsInfluencerEligibleForBrandFans(state, submissionId),
    }
  })

  const showBrandFansOnAvatar = () => {
    setIsShowAddToBrandFan(true)
  }
  const hideBrandFansOnAvatar = () => {
    setIsShowAddToBrandFan(false)
  }

  const actionIconColorProps = hasBrandFanGroupMemberships
    ? {
        color: Theme.primaryColor,
        backgroundColor: Theme.primary100Color,
        hoverBackgroundColor: Theme.primary100Color,
      }
    : {
        color: Theme.grey900Color,
        backgroundColor: Theme.grey100Color,
        hoverBackgroundColor: Theme.grey100Color,
      }

  useEffect(() => {
    if (isBrandFansEnabledForContentCampaigns && submissionId) {
      dispatch(fetchBrandFansMembership(submissionId))
    }
  }, [])

  return (
    <FlexItem noShrink>
      <Container
        relative
        rightOuterSpacing={0.75}
        height={2.5}
        onMouseEnter={showBrandFansOnAvatar}
        onMouseLeave={hideBrandFansOnAvatar}
        data-testid="social-profile-avatar-container"
      >
        {avatar ?? (
          <Avatar
            size={2.5}
            username={username}
            socialUrl={socialUrl}
            avatarSrc={avatarSrc}
            onClick={isEligibleForBrandFans ? onClick : undefined}
            isStory={isStory}
          />
        )}
        {isShowAddToBrandFan ? (
          <Container
            absolute
            topPosition={0}
            leftPosition={0}
            backgroundColor={Theme.whiteColor}
            borderRadius={100}
          >
            <BrandFanInfluencerIdentityRowTrackedRoute
              eventName={EventTrackingNames.BrandFansGroupsModal}
              identityId={identityId}
              submissionId={submissionId}
              disabled={!isEligibleForBrandFans}
            >
              <ActionIcon
                data-testid="brand-fans-add-icon"
                glyph="plus"
                size={1.125}
                onClick={isEligibleForBrandFans ? onClick : undefined}
                disabled={!isEligibleForBrandFans}
                {...actionIconColorProps}
              />
            </BrandFanInfluencerIdentityRowTrackedRoute>
          </Container>
        ) : (
          hasBrandFanGroupMemberships && (
            <Container
              width={1.25}
              height={1.25}
              borderRadius={0.625}
              backgroundColor={Theme.whiteColor}
              absolute
              topPosition={0}
              rightPosition={-0.625}
            >
              <Flex justifyCenter alignCenter full>
                <Icon glyph="star-rating" color={Theme.primaryColor} size={0.75} />
              </Flex>
            </Container>
          )
        )}
      </Container>
    </FlexItem>
  )
}

export default SubmissionCardHeaderAvatarSocialProfileAvatar
