import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { CostBreakdownRow } from '@components/UI/CostBreakdown'
import { IApplicationState } from '@store'
import { selectPurchaseOrderMetadata } from '@store/purchaseOrders'
import Theme from '@theme'
import { Container, H3, Text } from '@tribegroup'
import { AvailableCreditPurchaseOrderList } from './List'

export const AvailableCreditTooltipContent = () => {
  const { metadata } = useSelector((state: IApplicationState) => {
    return {
      metadata: selectPurchaseOrderMetadata(state),
    }
  })

  const total_amount_remaining_cents = metadata?.total_amount_remaining_cents ?? 0
  const total_amount_remaining_currency = metadata?.total_amount_remaining_currency ?? 'AUD'

  return (
    <Container
      topOuterSpacing={0.75}
      bottomOuterSpacing={0.75}
      leftOuterSpacing={0.75}
      rightOuterSpacing={0.75}
    >
      <H3 capitalize color={Theme.grey900Color} bottomOuterSpacing={1.5}>
        <FormattedMessage id="contentLibrary.licensing.creditManagement.availableCredit.label" />
      </H3>
      <Container flexBetween alignItems="flex-start" bottomOuterSpacing={0.75}>
        <Container rightOuterSpacing={1} width={12}>
          <Text color={Theme.grey900Color} medium>
            <FormattedMessage id="core.text.expiry" />
          </Text>
        </Container>
        <Container fullWidth textAlign="left">
          <Text block color={Theme.grey900Color} medium>
            <FormattedMessage id="core.text.purchase_orders" />
          </Text>
        </Container>
        <Container minWidth={6} textAlign="right">
          <Text color={Theme.grey900Color} medium>
            <FormattedMessage id="core.text.credit" />
          </Text>
        </Container>
      </Container>

      <AvailableCreditPurchaseOrderList />

      <Container
        topOuterSpacing={1.25}
        borderWidthBottom={0.0625}
        borderColor={Theme.grey300Color}
        topInnerSpacing={0.75}
        bottomInnerSpacing={1.25}
      >
        <CostBreakdownRow
          color={Theme.primaryColor}
          label={<FormattedMessage id="core.text.total" />}
          currency={total_amount_remaining_currency}
          quantity={total_amount_remaining_cents}
        />
      </Container>

      <Container topOuterSpacing={1.25} rightOuterSpacing={0.5}>
        <Text color={Theme.defaultColor} block customFontSize={0.8125} lineHeight={1.23}>
          <FormattedMessage id="contentLibrary.licensing.creditManagement.availableCredit.info.first" />
        </Text>
      </Container>
      <Container topOuterSpacing={0.75} rightOuterSpacing={0.5}>
        <Text color={Theme.defaultColor} block customFontSize={0.8125} lineHeight={1.23}>
          <FormattedMessage id="contentLibrary.licensing.creditManagement.availableCredit.info.second" />
        </Text>
      </Container>
      <Container topOuterSpacing={0.75} rightOuterSpacing={0.5}>
        <Text color={Theme.defaultColor} block customFontSize={0.8125} lineHeight={1.23}>
          <FormattedMessage id="contentLibrary.licensing.creditManagement.availableCredit.info.third" />
        </Text>
      </Container>
    </Container>
  )
}
