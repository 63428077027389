import styled from 'styled-components'

import Theme from '@theme'

export default styled.section`
  margin-bottom: 1rem;

  header {
    font-size: 0.8125rem;
    line-height: 1.23;
    letter-spacing: 1.5px;
    color: ${Theme.grey900Color};
    padding: 0.5rem 1rem;
    text-transform: uppercase;
  }
`
