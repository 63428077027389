import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Flex from 'styled-flex-component'

import Theme from '@theme'

export const PricePanelWrapper = styled(Flex)`
  margin: 0 1.25rem;
`

export const PricePanelAmountWrapper = styled(Flex)`
  padding-right: 0.5rem;
  ${({ light }) =>
    light &&
    css`
      opacity: 0.4;
    `};
`

export const PricePanelAmount = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.375rem;
`

export const PricePanelLabel = styled.div`
  border-radius: 1rem;
  background: ${Theme.secondary100Color};
  height: 1.25rem;
  padding: 0.0625rem 0.5rem;
  box-sizing: border-box;
`

export const SubmissionCardPriceSumaryWrapper = styled.div`
  position: relative;
  top: 0;
  z-index: 2;

  > div {
    transform: translate(0, -100%);
  }

  ${breakpoint('tabletPortrait')`
    position: absolute;
    ${({ top = 4 }) => `top: ${top}rem;`};
    right: 0;
    width: calc(100% - 26.725rem);
    > div {
      transform: none;
    }
  `};
  ${breakpoint('tabletLandscape')`
    width: calc(100% - 31.125rem);
  `};
`
