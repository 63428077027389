import { createContext, PropsWithChildren, PureComponent } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { SubmissionStatuses } from '@enums'
import { IApplicationState } from '@store'
import { selectSubmissionsFilters } from '@store/campaigns'
import { selectCampaignId, selectLocationsearchAsObject } from '@store/router'

export interface ISubmissionListFilterContextProps {
  filter: string
  sortBy?: string
  declinedFilter?: string
  isFilterLicenseRelated?: boolean
}

export const SubmissionListFilterContext = createContext({})

export class SubmissionListFilterProvider extends PureComponent<
  PropsWithChildren<ISubmissionListFilterContextProps>
> {
  render() {
    const { filter, sortBy, declinedFilter, isFilterLicenseRelated } = this.props

    const contextProps: ISubmissionListFilterContextProps = {
      filter,
      sortBy,
      declinedFilter,
      isFilterLicenseRelated,
    }

    return (
      <SubmissionListFilterContext.Provider value={contextProps}>
        {this.props.children}
      </SubmissionListFilterContext.Provider>
    )
  }
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const searchParams = selectLocationsearchAsObject(state)
  const campaignId = selectCampaignId(match) as number
  const parsedFilter = searchParams.filter && decodeURIComponent(searchParams.filter)
  const sortBy = searchParams.sort_by && decodeURIComponent(searchParams.sort_by)
  const declinedFilter =
    searchParams.declined_filter && decodeURIComponent(searchParams.declined_filter)

  const filters = selectSubmissionsFilters(state, campaignId)
  const filter = filters.includes(parsedFilter) ? parsedFilter : filters[0]
  const isFilterLicenseRelated = [
    SubmissionStatuses.Licensed,
    SubmissionStatuses.LicenseRequest,
  ].includes(filter as SubmissionStatuses)

  return {
    filter,
    sortBy,
    declinedFilter,
    isFilterLicenseRelated,
  }
}

export default compose<ISubmissionListFilterContextProps, {}>(
  withRouter,
  connect(mapStateToProps),
)(SubmissionListFilterProvider)
