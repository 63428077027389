import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { LicensingAgreementsLink } from '@components/Licensing'
import MediaObjectLicensingLicensedUploadToFacebookAdsManager from '@components/MediaObject/Licensing/Licensed/UploadToFacebookAdsManager'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IAnalyticsTrackingContextProps } from '@context/AnalyticsTracking'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import { EventTrackingNames } from '@enums'
import { withSubmissionListFilter } from '@hocs'
import { IApplicationState } from '@store'
import { selectIsSocialCampaign } from '@store/campaigns'
import {
  selectHasRequestOrPurchaseLicenseLinkUsingMediaObjectId,
  selectIsPurchasingOrRequestingLicense,
  selectMediaObjectLicenseOption,
} from '@store/licenses'
import {
  purchaseLicense,
  requestLicense,
  selectIsLicensed,
  selectIsLicenseRequested,
  selectIsLicenseRequestExpired,
  selectMediaObjectIsRequestingLicense,
  selectOriginalMediaUrl,
  selectUploadToFacebookLink,
} from '@store/mediaObjects'
import { selectCampaignId, selectIsInboxView } from '@store/router'
import { selectIsCarousel, selectIsFetching } from '@store/submissions'
import Theme from '@theme'
import { Container, Link } from '@tribegroup'
import SubmissionCardModalLicenseActionsButton from './Button/Button'
import ActionsStyled from './Actions.styled'

interface ISubmissionCardModalLicenseActionsProps {
  submissionId: number
  mediaObjectId: number
  licenseIndex: number
  frameNumber?: number
}

interface IInternalProps
  extends ISubmissionCardModalLicenseActionsProps,
    RouteComponentProps,
    IAnalyticsTrackingContextProps,
    ISubmissionListFilterContextProps {
  campaignId: number
  hasFacebookAdUploadLink: boolean
  isPurchasingOrRequestingLicense: boolean
  hasRequestOrPurchaseLicenseLink?: boolean
  purchaseLicense: typeof purchaseLicense
  requestLicense: typeof requestLicense
  isSocial: boolean
  isLicenseRequested: boolean
  isLicensed: boolean
  licensedOriginalMedia: string
  isFetchingSubmission: boolean
  isLicenseRequestExpired: boolean
  isCarousel: boolean
  isInboxView: boolean
}

export class SubmissionCardModalLicenseActions extends React.Component<IInternalProps> {
  onRequestLicenseClick = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault()
    const {
      submissionId,
      licenseIndex,
      isSocial,
      campaignId,
      mediaObjectId,
      isInboxView,
    } = this.props

    if (isSocial) {
      this.props.requestLicense(mediaObjectId, licenseIndex, campaignId, submissionId, {
        refreshSubmissions: isInboxView,
      })
      return
    }

    this.props.purchaseLicense(mediaObjectId, licenseIndex, campaignId, submissionId, {
      refreshSubmissions: isInboxView,
    })
  }

  getFormattedMessageIdAndValues = (id) => {
    return this.props.isCarousel
      ? {
          id: `${id}.frame`,
          values: { frame: this.props.frameNumber },
        }
      : { id }
  }

  render() {
    const {
      campaignId,
      hasRequestOrPurchaseLicenseLink,
      isPurchasingOrRequestingLicense,
      isSocial,
      isLicenseRequested,
      isLicensed,
      submissionId,
      licensedOriginalMedia,
      hasFacebookAdUploadLink,
      isFetchingSubmission,
      isLicenseRequestExpired,
      mediaObjectId,
    } = this.props

    if (isLicenseRequested) {
      return (
        <ActionsStyled>
          <SubmissionCardModalLicenseActionsButton
            disabled
            text={
              <FormattedMessage
                {...this.getFormattedMessageIdAndValues(
                  'socialSubmission.card.modal.license.button.license_requested',
                )}
              />
            }
          />
          <LicensingAgreementsLink color={Theme.primaryColor} />
        </ActionsStyled>
      )
    }

    if (isLicensed) {
      return (
        <ActionsStyled>
          <ConnectedTrackedRoute
            eventName={EventTrackingNames.DownloadLicensedContent}
            submissionId={submissionId}
            campaignId={campaignId}
          >
            <Link
              target="_blank"
              href={licensedOriginalMedia}
              data-cy-element="download-button"
              role="button"
            >
              <SubmissionCardModalLicenseActionsButton
                text={
                  <FormattedMessage
                    {...this.getFormattedMessageIdAndValues(
                      'socialSubmission.card.modal.license.button.download',
                    )}
                  />
                }
              />
            </Link>
          </ConnectedTrackedRoute>
          <Flex center>
            <LicensingAgreementsLink color={Theme.primaryColor} isLicensed={isLicensed} />
          </Flex>
          {hasFacebookAdUploadLink && (
            <Container topOuterSpacing={1.25}>
              <Flex justifyCenter alignCenter>
                <MediaObjectLicensingLicensedUploadToFacebookAdsManager
                  mediaObjectId={mediaObjectId}
                />
              </Flex>
            </Container>
          )}
        </ActionsStyled>
      )
    }

    if (!hasRequestOrPurchaseLicenseLink && !isLicenseRequestExpired) {
      return null
    }

    return (
      <ActionsStyled>
        <SubmissionCardModalLicenseActionsButton
          onClick={this.onRequestLicenseClick}
          disabled={isPurchasingOrRequestingLicense || isLicenseRequested || isFetchingSubmission}
          loading={isPurchasingOrRequestingLicense}
          text={
            isSocial ? (
              <FormattedMessage
                {...this.getFormattedMessageIdAndValues(
                  'socialSubmission.card.modal.license.button.request',
                )}
              />
            ) : (
              <FormattedMessage id="socialSubmission.card.modal.license.button.purchase" />
            )
          }
          elementName={
            isSocial
              ? 'license-options-modal-request-license-button'
              : 'license-options-modal-license-content-button'
          }
        />
        <LicensingAgreementsLink color={Theme.primaryColor} />
      </ActionsStyled>
    )
  }
}

const mapDispatchToProps = {
  requestLicense,
  purchaseLicense,
}

const mapStateToProps = (
  state: IApplicationState,
  { submissionId, match, licenseIndex, mediaObjectId }: IInternalProps,
) => {
  const campaignId = selectCampaignId(match) as number

  const isRequestingMediaObject = selectMediaObjectIsRequestingLicense(state)
  const isPurchasingSubmission = selectIsPurchasingOrRequestingLicense(state)

  return {
    campaignId,
    isPurchasingOrRequestingLicense: isRequestingMediaObject || isPurchasingSubmission,
    hasRequestOrPurchaseLicenseLink: Boolean(
      selectHasRequestOrPurchaseLicenseLinkUsingMediaObjectId(state, mediaObjectId, licenseIndex),
    ),
    isInboxView: selectIsInboxView(state),
    hasFacebookAdUploadLink: Boolean(selectUploadToFacebookLink(state, mediaObjectId)),
    isCarousel: selectIsCarousel(state, submissionId),
    isSocial: selectIsSocialCampaign(state, campaignId),
    isLicenseRequested: selectIsLicenseRequested(state, mediaObjectId),
    isLicenseRequestExpired: selectIsLicenseRequestExpired(state, mediaObjectId),
    isLicensed: selectIsLicensed(state, mediaObjectId),
    selectedLicenseOption: selectMediaObjectLicenseOption(state, mediaObjectId, licenseIndex),
    licensedOriginalMedia: selectOriginalMediaUrl(state, mediaObjectId),
    isFetchingSubmission: selectIsFetching(state),
  }
}

export default compose<IInternalProps, ISubmissionCardModalLicenseActionsProps>(
  withSubmissionListFilter,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SubmissionCardModalLicenseActions)
