import { useContext } from 'react'

import { FormattedMessage } from 'react-intl'

import { DEFAULT_PAID_PARTNERSHIP_HANDLE } from '@constants'
import { CampaignContext } from '@context/Campaign'
import { SubmissionChannels } from '@enums'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import CampaignPreviewBlock from '../Block'
import { Tag, TagsWrapper } from './Tags.styled'

const channelOrder = [
  SubmissionChannels.Instagram,
  SubmissionChannels.TikTok,
  SubmissionChannels.Pinterest,
  SubmissionChannels.X,
  SubmissionChannels.YouTube,
  SubmissionChannels.Facebook,
  SubmissionChannels.Twitter,
]

export const CampaignPreviewTags = () => {
  const { campaign } = useContext(CampaignContext)

  if (campaign?.channels?.length === 0) {
    return <></>
  }

  const orderedChannels = channelOrder
    .map((channel) =>
      campaign?.channels?.find((campaignChannel) => campaignChannel.name === channel),
    )
    .filter(Boolean)

  return (
    <>
      {orderedChannels?.map((channel) => {
        const hasPaidPartnership =
          channel?.paid_partnership_handle &&
          channel?.paid_partnership_handle !== DEFAULT_PAID_PARTNERSHIP_HANDLE

        const channelTags = channel?.tags ?? []
        const channelUrls = channel?.urls ?? []

        const hasTags = channelTags.length !== 0 || channelUrls.length !== 0

        if (channel?.name === SubmissionChannels.Pinterest && !hasPaidPartnership && !hasTags) {
          return <></>
        }

        return (
          <CampaignPreviewBlock
            title={`${channel?.name} TAGS`}
            key={`brief-preview-channel-${channel?.name}`}
          >
            {hasPaidPartnership && (
              <Container topOuterSpacing={0.75}>
                <Text small color={Theme.grey800Color}>
                  <FormattedMessage id="core.text.paidPartnership" />
                </Text>
                <TagsWrapper>
                  <Tag>{channel?.paid_partnership_handle}</Tag>
                </TagsWrapper>
              </Container>
            )}

            {hasTags && hasPaidPartnership && (
              <Container topOuterSpacing={0.75}>
                <Text small color={Theme.grey800Color}>
                  <FormattedMessage id="campaign.preview.tags.header" />
                </Text>
              </Container>
            )}

            {hasTags && (
              <TagsWrapper>
                {channelTags.map((tag) => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
                {channelUrls.map((url) => (
                  <Tag key={url}>{url}</Tag>
                ))}
              </TagsWrapper>
            )}
          </CampaignPreviewBlock>
        )
      })}
    </>
  )
}

export default CampaignPreviewTags
