import React from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import MediaObjectFullDetails from '@components/MediaObject/Full/Details/Details'
import MediaObjectFullMedia from '@components/MediaObject/Full/Media/Media'
import Breakpoint from '@components/UI/Breakpoint'
import { FullGridArea, FullHr, FullStyled } from '@components/UI/Full/Full.styled'
import FullLoading from '@components/UI/Full/Loading'
import { SHAREABLE_CONTENT_LIBRARY } from '@constants'
import { IApplicationState } from '@store'
import {
  fetchShareableMediaObject,
  selectIsLoadingFullPage,
  selectShareableContentLibraryMediaObjectIds,
} from '@store/mediaObjects'
import { selectMediaObjectId } from '@store/router'
import { isEmpty } from '@utils'
import ShareableContentLibraryFullPricePanel from './PricePanel'

interface IInternalProps {
  isLoading: boolean
  hasMediaObjects: boolean
  mediaObjectId: number
  fetchShareableMediaObject: typeof fetchShareableMediaObject
}

interface IParams {
  token: string
}

const ShareableContentLibraryFull: React.FC<IInternalProps> = ({
  isLoading,
  mediaObjectId,
  hasMediaObjects,
  fetchShareableMediaObject: fetchShareableMediaObjectAction,
}) => {
  const { token } = useParams<IParams>()

  React.useEffect(() => {
    if (!hasMediaObjects) {
      fetchShareableMediaObjectAction(String(token), mediaObjectId)
    }
  }, [])

  if (isLoading) {
    return <FullLoading alternatePricePosition />
  }

  return (
    <FullStyled layoutStyle="branded_cl">
      <FullGridArea name="media">
        <MediaObjectFullMedia />
      </FullGridArea>
      <FullGridArea name="price">
        <Breakpoint desktop tabletLandscape>
          <FullHr />
        </Breakpoint>
        <ShareableContentLibraryFullPricePanel key={mediaObjectId} mediaObjectId={mediaObjectId} />
        <Breakpoint tabletPortrait mobile>
          <FullHr />
        </Breakpoint>
      </FullGridArea>
      <FullGridArea name="details" cssOverflow>
        <MediaObjectFullDetails />
      </FullGridArea>
    </FullStyled>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const mediaObjectId = selectMediaObjectId(match)
  return {
    isLoading: selectIsLoadingFullPage(state, SHAREABLE_CONTENT_LIBRARY, mediaObjectId),
    hasMediaObjects: !isEmpty(selectShareableContentLibraryMediaObjectIds(state)),
    mediaObjectId,
  }
}

const mapDispatchToProps = {
  fetchShareableMediaObject,
}

export { ShareableContentLibraryFull }
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ShareableContentLibraryFull)
