import { SocialSubmissionTypes, SubmissionChannels } from '@enums'
import {
  AnalyticsData,
  AnalyticsPerspective,
  AnalyticsSourceMap,
  MetricEntry,
  MetricKey,
  MetricSource,
} from './types'

export const HIDDEN_REACH_METRICS: Partial<Record<SocialSubmissionTypes, MetricKey[]>> = {
  [SocialSubmissionTypes.TikTok]: ['avg_reach', 'avg_reach_pct', 'reach', 'reach_pct'],
  [SocialSubmissionTypes.Reel]: ['avg_reach', 'avg_reach_pct', 'reach', 'reach_pct'],
}

const overviewMetricKeys: Record<AnalyticsPerspective, MetricKey[]> = {
  'pre-purchase': [
    'followers',
    'avg_engagement_pct',
    'avg_impressions_pct',
    'avg_reach_pct',
    'avg_emv',
  ],
  'post-purchase': ['followers', 'engagement_pct', 'impressions_pct', 'reach_pct', 'emv'],
}

const performanceMetricKeys: Record<AnalyticsPerspective, MetricKey[]> = {
  'pre-purchase': ['est_cpe', 'est_cpv', 'est_cpm', 'est_emv_multiplier'],
  'post-purchase': ['cpe', 'cpv', 'cpm', 'emv_multiplier'],
}

const engagementMetricKeys: Record<AnalyticsPerspective, MetricKey[]> = {
  'pre-purchase': [
    'avg_likes',
    'avg_comments',
    'avg_shares',
    'avg_engagement',
    'avg_impressions',
    'avg_reach',
  ],
  'post-purchase': ['likes', 'comments', 'shares', 'engagement', 'impressions', 'reach'],
}

const percentageBasedMetricKeys: MetricKey[] = [
  'avg_engagement_pct',
  'avg_impressions_pct',
  'avg_reach_pct',
  'engagement_pct',
  'impressions_pct',
  'reach_pct',
]

const currencyBasedMetricKeys: MetricKey[] = [
  'est_cpe',
  'est_cpm',
  'est_cpv',
  'cpe',
  'cpm',
  'cpv',
  'avg_emv',
  'emv',
]

const tiktokSourceMap: AnalyticsSourceMap = {
  'pre-purchase': {
    followers: 'tiktok',
    avg_engagement: 'tiktok_tribe',
    avg_engagement_pct: 'tiktok_tribe',
    avg_impressions: 'tiktok_tribe',
    avg_impressions_pct: 'tiktok_tribe',
    avg_reach: 'tiktok_tribe',
    avg_reach_pct: 'tiktok_tribe',
    est_cpe: 'tiktok_tribe',
    est_cpm: 'tiktok_tribe',
    est_cpv: 'tiktok_tribe',
    avg_emv: 'tiktok_tribe',
    est_emv_multiplier: 'tiktok_tribe',
    avg_likes: 'tiktok_tribe',
    avg_comments: 'tiktok_tribe',
    avg_shares: 'tiktok_tribe',
  },
  'post-purchase': {
    followers: 'tiktok',
    engagement: 'tiktok',
    engagement_pct: 'tiktok',
    impressions: 'tiktok',
    impressions_pct: 'tiktok',
    reach: 'tiktok',
    reach_pct: 'tiktok',
    cpe: 'tiktok_tribe',
    cpm: 'tiktok_tribe',
    cpv: 'tiktok_tribe',
    emv: 'tiktok_tribe',
    emv_multiplier: 'tiktok_tribe',
    likes: 'tiktok',
    comments: 'tiktok',
    shares: 'tiktok',
  },
}

const xSourceMap: AnalyticsSourceMap = {
  'pre-purchase': {
    followers: 'x',
    avg_engagement: 'x_tribe',
    avg_engagement_pct: 'x_tribe',
    avg_impressions: 'x_tribe',
    avg_impressions_pct: 'x_tribe',
    avg_reach: 'x_tribe',
    avg_reach_pct: 'x_tribe',
    est_cpe: 'x_tribe',
    est_cpm: 'x_tribe',
    est_cpv: 'x_tribe',
    avg_emv: 'x_tribe',
    est_emv_multiplier: 'x_tribe',
    avg_likes: 'x_tribe',
    avg_comments: 'x_tribe',
    avg_shares: 'x_tribe',
  },
  'post-purchase': {
    followers: 'x',
    engagement: 'x',
    engagement_pct: 'x',
    impressions: 'x',
    impressions_pct: 'x',
    reach: 'x',
    reach_pct: 'x',
    cpe: 'x_tribe',
    cpm: 'x_tribe',
    cpv: 'x_tribe',
    emv: 'x_tribe',
    emv_multiplier: 'x_tribe',
    likes: 'x',
    comments: 'x',
    shares: 'x',
  },
}

const metaSourcemap: AnalyticsSourceMap = {
  'pre-purchase': {
    followers: 'meta',
    avg_engagement: 'meta_tribe',
    avg_engagement_pct: 'meta_tribe',
    avg_impressions: 'meta_tribe',
    avg_impressions_pct: 'meta_tribe',
    avg_reach: 'meta_tribe',
    avg_reach_pct: 'meta_tribe',
    est_cpe: 'meta_tribe',
    est_cpm: 'meta_tribe',
    est_cpv: 'meta_tribe',
    avg_emv: 'meta_tribe',
    est_emv_multiplier: 'meta_tribe',
    avg_likes: 'meta_tribe',
    avg_comments: 'meta_tribe',
    avg_shares: 'meta_tribe',
  },
  'post-purchase': {
    followers: 'meta',
    engagement: 'meta',
    engagement_pct: 'meta',
    impressions: 'meta',
    impressions_pct: 'meta',
    reach: 'meta',
    reach_pct: 'meta',
    cpe: 'meta_tribe',
    cpm: 'meta_tribe',
    cpv: 'meta_tribe',
    emv: 'meta_tribe',
    emv_multiplier: 'meta_tribe',
    likes: 'meta',
    comments: 'meta',
    shares: 'meta',
  },
}

const getSource = (
  metricKey: MetricKey,
  perspective: AnalyticsPerspective,
  socialSubmissionType: SocialSubmissionTypes,
  isProxy = false,
  channel: SubmissionChannels | undefined,
): MetricSource | null => {
  if (isProxy) {
    return 'manual'
  }

  if (socialSubmissionType === SocialSubmissionTypes.TikTok) {
    return tiktokSourceMap[perspective][metricKey] ?? null
  }

  if (channel === SubmissionChannels.X) {
    return xSourceMap[perspective][metricKey] ?? null
  }

  if (
    [
      SocialSubmissionTypes.Standard,
      SocialSubmissionTypes.Reel,
      SocialSubmissionTypes.Carousel,
      SocialSubmissionTypes.Story,
    ].includes(socialSubmissionType)
  ) {
    return metaSourcemap[perspective][metricKey] ?? null
  }

  return null
}

const getMetricEntries = (
  metricKeys: MetricKey[],
  analyticsData: AnalyticsData,
  perspective: AnalyticsPerspective,
  socialSubmissionType: SocialSubmissionTypes,
  isProxy = false,
  channel: SubmissionChannels | undefined,
) => {
  const entries: MetricEntry[] = []
  metricKeys.forEach((metric) => {
    if (analyticsData[metric] === 'hidden') {
      return
    }
    if (!analyticsData[metric]) {
      entries.push({
        metric,
        source: null,
        value: null,
      })
      return
    }
    const entry: MetricEntry = {
      type: 'number',
      metric,
      source: getSource(metric, perspective, socialSubmissionType, isProxy, channel),
      value: analyticsData[metric]!,
    }

    if (percentageBasedMetricKeys.includes(metric)) {
      entry.type = 'percentage'
    } else if (currencyBasedMetricKeys.includes(metric)) {
      entry.type = 'currency'
    }

    entries.push(entry)
  })

  return entries
}

export const getOverviewMetricEntries = (
  analyticsData: AnalyticsData,
  socialSubmissionType: SocialSubmissionTypes,
  perspective: AnalyticsPerspective,
  isProxy = false,
  channel: SubmissionChannels | undefined = undefined,
): MetricEntry[] => {
  return getMetricEntries(
    overviewMetricKeys[perspective],
    analyticsData,
    perspective,
    socialSubmissionType,
    isProxy,
    channel,
  )
}

export const getPerformanceMetricEntries = (
  analyticsData: AnalyticsData,
  socialSubmissionType: SocialSubmissionTypes,
  perspective: AnalyticsPerspective,
  isProxy = false,
  channel: SubmissionChannels | undefined = undefined,
): MetricEntry[] => {
  return getMetricEntries(
    performanceMetricKeys[perspective],
    analyticsData,
    perspective,
    socialSubmissionType,
    isProxy,
    channel,
  )
}

export const getEngagementMetricEntries = (
  analyticsData: AnalyticsData,
  socialSubmissionType: SocialSubmissionTypes,
  perspective: AnalyticsPerspective,
  isProxy = false,
  channel: SubmissionChannels | undefined = undefined,
): MetricEntry[] => {
  return getMetricEntries(
    engagementMetricKeys[perspective],
    analyticsData,
    perspective,
    socialSubmissionType,
    isProxy,
    channel,
  )
}

export const FINANCIAL_ANALYTICS_KEYS: MetricKey[] = [
  // Pre Purchase
  'est_cpe',
  'est_cpv',
  'est_cpm',
  'est_emv_multiplier',
  'avg_emv',
  // Post Purchase
  'cpe',
  'cpv',
  'cpm',
  'emv_multiplier',
  'emv',
]
