import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  markAsRead,
  MarkAsReadActionTypes,
  markAsReadError,
  selectMarkAsReadLink,
} from '@store/feedback'
import { networkRequest } from '@utils'

export function* handleMarkAsRead(action: ReturnType<typeof markAsRead>) {
  try {
    const submissionId = action.payload
    const authToken: string = yield select(selectAuthToken)
    const markAsReadLink: IHateoasLink = yield select(selectMarkAsReadLink, submissionId)
    yield call(networkRequest, markAsReadLink.method, markAsReadLink.href, undefined, authToken)
  } catch (error) {
    yield put(markAsReadError(error))
  }
}

export function* watchMarkAsReadRequest() {
  yield takeLatest(MarkAsReadActionTypes.MARK_AS_READ_REQUEST, handleMarkAsRead)
}
