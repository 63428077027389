import React, { useContext } from 'react'

import { useSelector } from 'react-redux'

import AnalyticsPostCampaignReportListViewProfile from '@components/Analytics/PostCampaignReport/ListView/Profile/Profile'
import { getBackgroundColor } from '@components/Analytics/PostCampaignReport/Post/List/List'
import { CampaignContext } from '@context/Campaign'
import { IApplicationState } from '@store'
import { selectTikTokMetricIds } from '@store/postCampaignReport'
import { Container } from '@tribegroup'
import AnalyticsPostCampaignReportListView from '../../ListView/ListView'
import AnalyticsPostCampaignReportListViewTikTokHeaders from './Headers/Headers'
import AnalyticsPostCampaignReportTikTokListItem from './Item/Item'

const AnalyticsPostCampaignReportTikTokList: React.FC = () => {
  const {
    campaign: { is_price_hidden: isPriceHidden, id: campaignId },
  } = useContext(CampaignContext)

  const { submissionIds } = useSelector((state: IApplicationState) => {
    return {
      submissionIds: selectTikTokMetricIds(state, campaignId),
    }
  })
  const profiles = submissionIds.map((submissionId, index) => (
    <Container data-testid="tiktok-profile-wrapper" key={`profile-${submissionId}`}>
      <AnalyticsPostCampaignReportListViewProfile
        backgroundColor={getBackgroundColor(index)}
        submissionId={submissionId}
      />
    </Container>
  ))

  return (
    <AnalyticsPostCampaignReportListView
      profiles={profiles}
      headers={<AnalyticsPostCampaignReportListViewTikTokHeaders isPriceHidden={isPriceHidden} />}
    >
      {submissionIds.map((submissionId, index) => (
        <Container key={`tiktok-list-${submissionId}`} data-testid="tiktok-list-item">
          <AnalyticsPostCampaignReportTikTokListItem
            submissionId={submissionId}
            backgroundColor={getBackgroundColor(index)}
            isPriceHidden={isPriceHidden}
          />
        </Container>
      ))}
    </AnalyticsPostCampaignReportListView>
  )
}

export default AnalyticsPostCampaignReportTikTokList
