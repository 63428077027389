import styled from 'styled-components'

import { Icon } from '@tribegroup'

export const PanelSectionWrapper = styled.div`
  margin-bottom: 1.5rem;
`

export const CodeConductWrapper = styled.div`
  margin-top: 4.125rem;
`

export const WrappedIcon = styled(Icon)`
  position: relative;
  top: 0.125rem;
  padding-left: 0.25rem;
`
