import React from 'react'

import OutsideClickHandler from 'react-outside-click-handler'
import Flex from 'styled-flex-component'

import { IMultiframeContextProps } from '@context/MultiframeControls'
import { withMultiframeControls } from '@hocs'
import { useBodyOverlayedClassHandler } from '@hooks/useBodyOverlayedClassHandler'
import {
  MediaMultiframeFullModeClose,
  MediaMultiframeFullModeContent,
  MediaMultiframeFullModeOpen,
  MediaMultiframeFullModeOpenOverlay,
} from './FullMode.styled'

interface IMultiframeFullModeProps {
  children: React.ReactNode
}

interface IInternalProps extends IMultiframeFullModeProps, IMultiframeContextProps {}

export const MultiframeFullMode = ({ children, pause }: IInternalProps) => {
  const [open, setOpen] = React.useState(false)
  useBodyOverlayedClassHandler(open)

  const toggleOpen = () => {
    pause(!open)
    setOpen(!open)
  }

  if (!open) {
    return <MediaMultiframeFullModeClose data-testid="full-multiframe-close" onClick={toggleOpen} />
  }
  return (
    <MediaMultiframeFullModeOpen data-testid="full-multiframe-open">
      <MediaMultiframeFullModeOpenOverlay />
      <Flex full center>
        <MediaMultiframeFullModeContent center>
          <OutsideClickHandler onOutsideClick={toggleOpen}>{children}</OutsideClickHandler>
        </MediaMultiframeFullModeContent>
      </Flex>
    </MediaMultiframeFullModeOpen>
  )
}

export default withMultiframeControls(MultiframeFullMode)
