import { format } from 'date-fns'
import { createSelector } from 'reselect'

import { FULL_DATE } from '@constants'
import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { IVideoMetrics, IVideoMetricsSummary } from '@store/postCampaignReport/videoMetrics'

const VIDEO_METRIC_PROPS: ReadonlyArray<string> = [
  'engagement',
  'engagement_rate',
  'cost_per_engagement',
  'views',
  'view_rate',
  'cost_per_view',
]

export const selectVideoMetricIds = (
  state: IApplicationState,
  campaignId: number,
  channel: PCRChannels,
) => {
  const videoMetricIds = state.reportings.videoMetrics.videoMetricIdsByChannel[campaignId]
  return videoMetricIds && videoMetricIds[channel] ? videoMetricIds[channel] : []
}

export const selectVideoMetrics = (state: IApplicationState, id: number) =>
  state.reportings.videoMetrics.videoMetricsById[id] || {}

export const selectShowVideoPerformance = createSelector(
  selectVideoMetrics,
  (videoMetrics: IVideoMetrics) => {
    return VIDEO_METRIC_PROPS.some(
      (metricProp: string) => videoMetrics[metricProp] || videoMetrics[metricProp] === 0,
    )
  },
)

export const selectVideoMetricsSummary = (
  state: IApplicationState,
  campaignId: number,
  channel: string,
) => {
  const summary = state.reportings.videoMetrics.videoMetricsSummary[campaignId]

  if (!summary) {
    return {}
  }

  return summary[channel] || {}
}

export const selectVideoPublishedDate = createSelector(
  selectVideoMetrics,
  (videoMetrics: IVideoMetrics) =>
    videoMetrics.published_at && format(new Date(videoMetrics.published_at), FULL_DATE),
)
export const selectVideoSpend = createSelector(
  selectVideoMetrics,
  (videoMetrics: IVideoMetrics) => ({
    amount: videoMetrics.purchase_amount,
    currency: videoMetrics.purchase_amount_currency,
  }),
)

export const selectVideoFollowers = createSelector(
  selectVideoMetrics,
  (videoMetrics: IVideoMetrics) => videoMetrics.followers,
)

export const selectVideoEngagement = createSelector(
  selectVideoMetrics,
  (videoMetrics: IVideoMetrics) => videoMetrics.engagement,
)

export const selectVideoEngagementPct = createSelector(
  selectVideoMetrics,
  (videoMetrics: IVideoMetrics) => videoMetrics.engagement_rate,
)

export const selectVideoCPE = createSelector(selectVideoMetrics, (videoMetrics: IVideoMetrics) => ({
  amount: videoMetrics.cost_per_engagement,
  currency: videoMetrics.cost_per_engagement_currency,
}))

export const selectVideoViews = createSelector(
  selectVideoMetrics,
  (videoMetrics: IVideoMetrics) => videoMetrics.views,
)

export const selectVideoViewPct = createSelector(
  selectVideoMetrics,
  (videoMetrics: IVideoMetrics) => videoMetrics.view_rate,
)

export const selectVideoCPV = createSelector(selectVideoMetrics, (videoMetrics: IVideoMetrics) => ({
  amount: videoMetrics.cost_per_view,
  currency: videoMetrics.cost_per_view_currency,
}))

export const selectVideoReach = createSelector(
  selectVideoMetrics,
  (videoMetrics: IVideoMetrics) => videoMetrics.reach,
)

export const selectVideoReachPct = createSelector(
  selectVideoMetrics,
  (videoMetrics: IVideoMetrics) => videoMetrics.reach_pct,
)

export const selectVideoImpressions = createSelector(
  selectVideoMetrics,
  (videoMetrics: IVideoMetrics) => videoMetrics.impressions,
)

export const selectVideoCPM = createSelector(selectVideoMetrics, (videoMetrics: IVideoMetrics) => ({
  amount: videoMetrics.cpm,
  currency: videoMetrics.cpm_currency,
}))

export const selectVideosTotalPosts = createSelector(
  selectVideoMetricsSummary,
  (summary: IVideoMetricsSummary) => summary.count,
)

export const selectVideosTotalSpend = createSelector(
  selectVideoMetricsSummary,
  (summary: IVideoMetricsSummary) => ({
    amount: summary.total_purchase_amount,
    currency: summary.total_purchase_amount_currency,
  }),
)

export const selectVideosTotalFollowers = createSelector(
  selectVideoMetricsSummary,
  (summary: IVideoMetricsSummary) => summary.total_followers,
)

export const selectVideosTotalVideoViews = createSelector(
  selectVideoMetricsSummary,
  (summary: IVideoMetricsSummary) => summary.total_video_views,
)

export const selectVideosTotalEngagement = createSelector(
  selectVideoMetricsSummary,
  (summary: IVideoMetricsSummary) => summary.total_engagement,
)

export const selectVideosTotalEngagementPct = createSelector(
  selectVideoMetricsSummary,
  (summary: IVideoMetricsSummary) => summary.total_engagement_pct,
)

export const selectVideosAvgEngagementPct = createSelector(
  selectVideoMetricsSummary,
  (summary: IVideoMetricsSummary) => summary.avg_engagement_rate,
)

export const selectVideosAvgVideoViewsPct = createSelector(
  selectVideoMetricsSummary,
  (summary: IVideoMetricsSummary) => summary.avg_video_view_rate,
)

export const selectVideosOverallCPV = createSelector(
  selectVideoMetricsSummary,
  (summary: IVideoMetricsSummary) => ({
    amount: summary.total_cost_per_view,
    currency: summary.total_cost_per_view_currency,
  }),
)

export const selectVideosTotalReach = createSelector(
  selectVideoMetricsSummary,
  (summary: IVideoMetricsSummary) => summary.total_reach,
)

export const selectVideosAvgReachPct = createSelector(
  selectVideoMetricsSummary,
  (summary: IVideoMetricsSummary) => summary.avg_reach_pct,
)

export const selectVideosTotalImpressions = createSelector(
  selectVideoMetricsSummary,
  (summary: IVideoMetricsSummary) => summary.total_impressions,
)

export const selectVideosOverallCPM = createSelector(
  selectVideoMetricsSummary,
  (summary: IVideoMetricsSummary) => ({
    amount: summary.overall_cpm,
    currency: summary.overall_cpm_currency,
  }),
)
