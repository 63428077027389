import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Image from '@components/UI/Image'
import LineClamp from '@components/UI/LineClamp'
import TrackedRoute from '@components/UI/TrackedRoute/TrackedRoute'
import { IDraftContextProps } from '@context/Draft'
import { AspectRatios, EventTrackingNames } from '@enums'
import { withDraft } from '@hocs'
import { IApplicationState } from '@store'
import { IBrand, selectUpdateBrandLink } from '@store/brands'
import Theme from '@theme'
import { DropdownMenu, H2, Icon, MenuItemLink } from '@tribegroup'
import { BrandCardWrapper, BrandName, DropdownWrapper, StyledCardWrapper } from './Card.styled'

interface IBuilderBrandCardProps {
  brand: IBrand
  selected: boolean
  campaignType?: string
  onCardClick: VoidFunction
  to: any
}

interface IInternalProps extends IBuilderBrandCardProps, IDraftContextProps {
  hasUpdateBrandLink: boolean
}

export const BuilderBrandCard = ({
  selected,
  brand,
  hasUpdateBrandLink,
  campaignType,
  onCardClick,
  to,
  draft,
}: IInternalProps) => {
  const draftId = draft.id ? `${draft.id}/` : ''
  const pathname = `/builder/brands/${draftId}edit/${brand.id}`
  const brandStepIncomplete = Boolean(
    draft.status === undefined && draft.step_completed === undefined,
  )

  const ConditionalTrackingWrapper = brandStepIncomplete ? TrackedRoute : Fragment
  const conditionalTrackingProps: any = brandStepIncomplete
    ? {
        eventName: EventTrackingNames.CampaignBuilderBrandStepCompleted,
        condition: brandStepIncomplete,
        properties: {
          brief_id: draft.id,
          brief_type: campaignType,
        },
      }
    : undefined
  return (
    <BrandCardWrapper>
      {hasUpdateBrandLink && (
        <DropdownWrapper>
          <DropdownMenu
            target={
              <Icon
                key={brand.id}
                glyph="more"
                size={1}
                color={Theme.grey700Color}
                className="card-menu-toggle"
              />
            }
            indicatorSpace={0}
          >
            <TrackedRoute
              eventName={EventTrackingNames.CampaignBuilderEditBrandStart}
              properties={{
                brief_id: draft.id,
                brief_type: campaignType,
                brand_id: brand.id,
              }}
            >
              <MenuItemLink
                to={{
                  pathname,
                  search: to.search,
                }}
              >
                <FormattedMessage id="builder.brands.list.editBrand" />
              </MenuItemLink>
            </TrackedRoute>
          </DropdownMenu>
        </DropdownWrapper>
      )}
      <ConditionalTrackingWrapper {...conditionalTrackingProps}>
        <TrackedRoute
          eventName={EventTrackingNames.CampaignBuilderSelectBrand}
          properties={{
            brief_id: draft.id,
            brief_type: campaignType,
            brand_id: brand.id,
          }}
        >
          <StyledCardWrapper onClick={onCardClick} selected={selected} to={to}>
            <Image
              contain
              backgroundColor={Theme.whiteColor}
              src={brand.logo_url}
              loadingColor={Theme.whiteColor}
              aspectRatio={AspectRatios.ONE_BY_ONE}
            />
            <BrandName>
              <H2 color={Theme.grey900Color} title={brand.name}>
                <LineClamp lines={2} fallbackHeight={3.875}>
                  {brand.name}
                </LineClamp>
              </H2>
            </BrandName>
          </StyledCardWrapper>
        </TrackedRoute>
      </ConditionalTrackingWrapper>
    </BrandCardWrapper>
  )
}

const mapStateToProps = (state: IApplicationState, { brand }: IBuilderBrandCardProps) => ({
  hasUpdateBrandLink: Boolean(selectUpdateBrandLink(state, brand.id)),
})

export default compose<IInternalProps, IBuilderBrandCardProps>(
  withDraft,
  connect(mapStateToProps),
)(BuilderBrandCard)
