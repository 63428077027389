import { IResponseError } from '@lib/error'

export enum FetchCategoriesActionTypes {
  FETCH_REQUEST = 'categories/FETCH_REQUEST',
  FETCH_SUCCESS = 'categories/FETCH_SUCCESS',
  FETCH_ERROR = 'categories/FETCH_ERROR',
}

export interface ICategory {
  readonly id: number
  readonly name: string
}

export interface ICategoriesState {
  readonly isFetching: boolean
  readonly categories: ReadonlyArray<ICategory>
  readonly errors?: IResponseError
}
