import { delay } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchNotes,
  fetchNotesError,
  fetchNotesSuccess,
  markAsRead,
  NotesActionTypes,
} from '@store/notes'
import { fetchSubmission, selectNotesLink } from '@store/submissions'
import { networkRequest } from '@utils'

export function* handleFetchNotes(action: ReturnType<typeof fetchNotes>) {
  try {
    const { submissionId, campaignId } = action.payload
    const authToken: string = yield select(selectAuthToken)
    const notesLink: IHateoasLink = yield select(selectNotesLink, submissionId)
    const result = yield call(
      networkRequest,
      notesLink.method,
      notesLink.href,
      undefined,
      authToken,
    )
    yield put(fetchNotesSuccess(submissionId, result))
    yield put(markAsRead(submissionId))
    yield call(delay, 100)
    yield put(fetchSubmission(submissionId, campaignId))
  } catch (error) {
    yield put(fetchNotesError(error))
  }
}

export function* watchListNotesRequest() {
  yield takeLatest(NotesActionTypes.FETCH_REQUEST, handleFetchNotes)
}
