import styled, { css } from 'styled-components'

import Theme from '@theme'

export const PopoverStyled = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  ${({ bottom }) =>
    bottom
      ? css`
          bottom: 0;
        `
      : css`
          top: 0;
        `};
  width: fill-available;
  padding: 1.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 10%);
  background-color: ${Theme.whiteColor};
`
