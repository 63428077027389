import { IDraftContextProps } from '@context/Draft'
import { SupportedSubmissionTypes } from '@enums'
import { withDraft } from '@hocs'
import BuilderBudgetTargetAudience, { BuilderBudgetTargetAudienceHeadline } from './Audience'
import BuilderBudgetTargetDemographic, {
  BuilderBudgetTargetDemographicHeadline,
} from './Demographic'

export const CREATORS_HEADER_I18N_PREFIX = 'builder.budget.demographics.targetCreators.header'
export const AUDIENCES_HEADER_I18N_PREFIX = 'builder.budget.demographics.targetAudience.header'

const BuilderBudgetTarget = ({ draft }: IDraftContextProps) => {
  const isSocialCampaign = draft?.supported_submission_types?.includes(
    SupportedSubmissionTypes.SocialSubmission,
  )

  if (isSocialCampaign) {
    return (
      <>
        <BuilderBudgetTargetDemographicHeadline />
        <BuilderBudgetTargetDemographic />
        <BuilderBudgetTargetAudienceHeadline />
        <BuilderBudgetTargetAudience />
      </>
    )
  }

  return <BuilderBudgetTargetDemographic />
}

export default withDraft(BuilderBudgetTarget)
