import Loading from '@components/UI/Loading'
import { SocialSubmissionTypes, SubmissionChannels } from '@enums'
import { Container } from '@tribegroup'
import { AnalyticsBreakdownGlossary } from './Glossary/Glossary'
import { AnalyticsBreakdownMetricTableHeader } from './MetricTable/Header'
import { AnalyticsBreakdownMetricTableSection } from './MetricTable/Section'
import { AnalyticsData, AnalyticsPerspective } from './types'
import { useAnalyticsBreakdown } from './useAnalyticsBreakdown'

export type AnalyticsBreakdownProps = {
  analytics: Partial<AnalyticsData>
  perspective: AnalyticsPerspective
  socialSubmissionType: SocialSubmissionTypes
  currency: string
  isLoaded: boolean
  isProxy?: boolean
  channel?: SubmissionChannels
}

export const AnalyticsBreakdown: React.FC<AnalyticsBreakdownProps> = ({
  analytics = {},
  perspective,
  isLoaded,
  currency,
  socialSubmissionType,
  isProxy,
  channel,
}) => {
  const { engagement, overview, performance } = useAnalyticsBreakdown(
    analytics,
    socialSubmissionType,
    perspective,
    Boolean(isProxy),
    channel,
  )

  if (!isLoaded) {
    return <Loading />
  }

  return (
    <Container relative>
      <Container absolute rightPosition={0} topPosition={-2}>
        <AnalyticsBreakdownGlossary
          submissionType={socialSubmissionType}
          entries={[...overview, ...performance, ...engagement]}
          currency={currency}
        />
      </Container>
      <AnalyticsBreakdownMetricTableHeader />
      <AnalyticsBreakdownMetricTableSection
        name="overview"
        entries={overview}
        currency={currency}
      />
      {performance.length > 0 && (
        <AnalyticsBreakdownMetricTableSection
          name="performanceBreakdown"
          entries={performance}
          currency={currency}
        />
      )}
      <AnalyticsBreakdownMetricTableSection
        name="engagementBreakdown"
        entries={engagement}
        currency={currency}
      />
    </Container>
  )
}
