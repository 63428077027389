import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import ShareableContentLibraryListTitleBar from '@components/ShareableContentLibrary/List/TitleBar'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import image404 from '@images/404-page.png'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { Image, JumboHeader, MainContainer, TextWrapper } from './404.styled'

export const ShareableContentLibrary404 = () => (
  <MainLayout backgroundColor={Theme.whiteColor} data-testid="scl-404-error-page">
    <HeadTag id="contentLibrary.shareable.header" />
    <ShareableContentLibraryListTitleBar />
    <MainContainer>
      <Flex center>
        <Image src={image404} alt="page 404" />
      </Flex>
      <TextWrapper>
        <div>
          <JumboHeader color={Theme.primaryColor}>
            <FormattedMessage id="contentLibrary.shareable.404.title1" />
          </JumboHeader>
          <JumboHeader color={Theme.defaultColor}>
            <FormattedHTMLMessage id="contentLibrary.shareable.404.title2" />
          </JumboHeader>
          <Container topOuterSpacing={0.3125}>
            <Text color={Theme.defaultColor} small>
              <FormattedMessage id="contentLibrary.shareable.404.subtitle" />
            </Text>
          </Container>
        </div>
      </TextWrapper>
    </MainContainer>
  </MainLayout>
)

export default ShareableContentLibrary404
