import { call, put, select, takeEvery } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import { selectCampaignLink } from '@store/campaigns'
import {
  fetchMembers,
  fetchMembersError,
  fetchMembersSuccess,
  MembersActionTypes,
} from '@store/members'
import { networkRequest } from '@utils'

export function* handleFetch(action: ReturnType<typeof fetchMembers>) {
  try {
    const authToken: string = yield select(selectAuthToken)
    const membersLink: IHateoasLink = yield select(selectCampaignLink, action.payload, 'members')
    const result = yield call(
      networkRequest,
      membersLink.method,
      membersLink.href,
      undefined,
      authToken,
    )
    yield put(fetchMembersSuccess(result, action.payload))
  } catch (error) {
    yield put(fetchMembersError(error))
  }
}

export function* watchMembersRequest() {
  yield takeEvery(MembersActionTypes.FETCH_REQUEST, handleFetch)
}
