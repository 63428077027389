import { connect } from 'react-redux'
import { compose } from 'recompose'

import { CardContainer } from '@components/UI/Card/Card'
import { ICampaignContextProps } from '@context/Campaign'
import { ISelectedSubmissionsContextProps } from '@context/SelectedSubmissions'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import {
  withAnalyticsTracking,
  withCampaign,
  withSelectedSubmissions,
  withSubmissionListFilter,
} from '@hocs'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { IMediaObject } from '@store/mediaObjects'
import {
  ISubmission,
  selectActiveSubmissionMediaObject,
  selectShowBrandedContentAdBar,
  selectSubmission,
} from '@store/submissions'
import { Container } from '@tribegroup'
import { isSocialCampaign } from '@utils/campaign'
import SubmissionCardHeader from './Header/Header'
import SubmissionLinkToDetails from './LinkToDetails/LinkToDetails'
import SubmissionCardThumbnail from './Thumbnail/Thumbnail'
import SubmissionCardActionBar from './ActionBar'
import { SubmissionCardBrandedContentAdBar } from './BrandedContentAd'
import SubmissionCardMoreMenu from './MoreMenu'
import SubmissionCardPriceSummary from './PriceSummary'

export interface IProps {
  submissionId: number
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

interface IInternalProps extends IProps, ISelectedSubmissionsContextProps {
  showLicenseRequestAsApprove?: boolean
  submission: ISubmission
  campaignId: number
  showBrandedContentAdBar: boolean
  isSocial: boolean
  selected?: boolean
  hasCostBreakdownMessage?: boolean
}

const SubmissionCard: React.FC<IInternalProps> = ({
  submissionId,
  submission,
  campaignId,
  showLicenseRequestAsApprove,
  showBrandedContentAdBar,
  isSocial,
  selected,
  isSelectionModeOn,
}) => {
  const { toggleIsVisible, toggleHide, toggleShow, toggle } = hooks.useToggle()
  const { id: mediaObjectId } = hooks.useSelector<IMediaObject>((state: IApplicationState) =>
    selectActiveSubmissionMediaObject(state, submissionId),
  )

  return (
    <CardContainer
      selected={selected}
      elementName="submission-card"
      outerContent={
        isSocial ? (
          <SubmissionCardMoreMenu submissionId={submissionId} showCostBreakdown={toggleShow} />
        ) : undefined
      }
    >
      <SubmissionCardHeader
        campaignId={campaignId}
        submissionId={submissionId}
        toggleCostBreakdown={toggle}
        enableCostBreakdownTracking={toggleIsVisible}
      />
      <SubmissionLinkToDetails submissionId={submission.id} selected={selected}>
        <SubmissionCardThumbnail submissionId={submission.id} />
      </SubmissionLinkToDetails>
      {showBrandedContentAdBar && <SubmissionCardBrandedContentAdBar submissionId={submissionId} />}
      <SubmissionCardActionBar
        submissionId={submission.id}
        showLicenseRequestAsApprove={showLicenseRequestAsApprove}
        isSelectionModeOn={isSelectionModeOn}
      />
      {toggleIsVisible && (
        <Container absolute leftPosition={0} topPosition={4} fullWidth>
          <SubmissionCardPriceSummary
            mediaObjectId={mediaObjectId}
            campaignId={campaignId}
            submissionId={submissionId}
            onHide={toggleHide}
          />
        </Container>
      )}
    </CardContainer>
  )
}

interface IStateProps
  extends IInternalProps,
    ICampaignContextProps,
    ISubmissionListFilterContextProps,
    ISelectedSubmissionsContextProps {}

const mapStateToProps = (
  state: IApplicationState,
  { submissionId, selectedSubmissionIds, campaign, isFilterLicenseRelated }: IStateProps,
) => {
  const isSocial = isSocialCampaign(campaign)
  const campaignId = campaign.id
  return {
    isSocial,
    campaignId,
    submission: selectSubmission(state, submissionId),
    selected: selectedSubmissionIds.includes(submissionId),
    showLicenseRequestAsApprove: !isSocial && !isFilterLicenseRelated,
    showBrandedContentAdBar: selectShowBrandedContentAdBar(state, submissionId, isSocial),
  }
}

export { SubmissionCard }
export default compose<IInternalProps, IProps>(
  withAnalyticsTracking,
  withSubmissionListFilter,
  withSelectedSubmissions,
  withCampaign,
  connect(mapStateToProps),
)(SubmissionCard)
