import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'

import { IMultiframeContextProps } from '@context/MultiframeControls'
import { SocialSubmissionTypes } from '@enums'
import { withMultiframeControls } from '@hocs'

interface IDescriptionPanelProps {
  socialSubmissionType: SocialSubmissionTypes
}

interface IInternalProps extends IDescriptionPanelProps, IMultiframeContextProps {}

export const MultiframeDescriptionPanel = ({
  socialSubmissionType,
  frames,
  activeFrameNumber,
}: IInternalProps) => {
  return (
    <Fragment>
      <FormattedMessage id={`multiframe.socialSubmissionType.${socialSubmissionType}`} />
      <br />
      <FormattedMessage
        id="multiframe.frame.count"
        values={{ current: activeFrameNumber, total: frames.length }}
      />
    </Fragment>
  )
}

export default compose<IInternalProps, IDescriptionPanelProps>(withMultiframeControls)(
  MultiframeDescriptionPanel,
)
