import { FormattedMessage } from 'react-intl'

import {
  CheckItem,
  CoachmarkBlock,
  CoachmarkContent,
  CoachmarkSection,
  SideBarContentWrapper,
} from '@components/UI/Coachmark/Coachmark.styled'
import Theme from '@theme'
import { H4, Header, Icon, Text, Tip } from '@tribegroup'

export const CoachMarkContent = () => {
  return (
    <CoachmarkContent data-cy-element="call-out-your-important-points-content-wrapper">
      <CoachmarkSection>
        <H4 color={Theme.defaultColor} bottomOuterSpacing={1}>
          <FormattedMessage id="builder.brief.dosanddonts.coach.header" />
        </H4>
        <CoachmarkBlock>
          <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.5}>
            <FormattedMessage id="builder.brief.dosanddonts.coach.dos" />
          </Header>
          <CheckItem>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1.25} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.dosanddonts.coach.dos.body1" />
            </Text>
          </CheckItem>
          <CheckItem>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1.25} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.dosanddonts.coach.dos.body2" />
            </Text>
          </CheckItem>
          <CheckItem>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1.25} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.dosanddonts.coach.dos.body3" />
            </Text>
          </CheckItem>
        </CoachmarkBlock>
        <CoachmarkBlock>
          <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.5}>
            <FormattedMessage id="builder.brief.dosanddonts.coach.donts" />
          </Header>
          <CheckItem>
            <Icon glyph="cross-heavy" color={Theme.alertColor} size={1.25} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.dosanddonts.coach.donts.body1" />
            </Text>
          </CheckItem>
          <CheckItem>
            <Icon glyph="cross-heavy" color={Theme.alertColor} size={1.25} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.dosanddonts.coach.donts.body2" />
            </Text>
          </CheckItem>
          <CheckItem>
            <Icon glyph="cross-heavy" color={Theme.alertColor} size={1.25} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.dosanddonts.coach.donts.body3" />
            </Text>
          </CheckItem>
        </CoachmarkBlock>
      </CoachmarkSection>
    </CoachmarkContent>
  )
}

export const BuilderCampaignDosAndDontsTip = () => {
  return (
    <SideBarContentWrapper>
      <Tip
        text={
          <Text xsmall uppercase data-cy-element="call-out-your-important-points-tip">
            <FormattedMessage id="builder.brief.dosanddonts.tip" />
          </Text>
        }
        width={12}
        coachmark={{
          content: <CoachMarkContent />,
          position: 'left',
          width: 39.875,
          yOffset: 9,
        }}
      />
    </SideBarContentWrapper>
  )
}

export default BuilderCampaignDosAndDontsTip
