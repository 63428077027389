import React from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import InfluencerIdentityRowLabelTooltip from '@components/InfluencerIdentity/Row/LabelTooltip'
import InfluencerIdentityRowValueAndLabel from '@components/InfluencerIdentity/Row/ValueAndLabel'
import Price from '@components/UI/Price/Price'
import { useInfluencerIdentityId } from '@context/InfluencerIdentityId/Provider'
import { SubmissionChannels } from '@enums'
import { IApplicationState } from '@store'
import {
  selectInfluencerIdentityEstimatedCPE,
  selectInfluencerIdentityEstimatedCPECurrency,
  selectInfluencerIdentityEstimatedCPEIsAvarage,
  selectInfluencerIdentityPlatformProvider,
} from '@store/influencerIdentities'
import { selectCampaignId } from '@store/router'
import { Container } from '@tribegroup'
import { EstimatedCPETooltipID } from './Tooltip/AverageTooltip'

interface IInternalProps {
  currency: string
  cpe: number
  isAveraged: boolean
}

const CPEValue = ({ isAveraged, currency, cpe }: IInternalProps) => (
  <React.Fragment>
    <Price currency={currency} quantity={cpe} pattern="!###,###," />
    {isAveraged && (
      <Container
        inline
        data-tooltip-id={EstimatedCPETooltipID}
        data-for={EstimatedCPETooltipID}
        pointer
      >
        <FormattedMessage id="core.text.symbol.attention" />
      </Container>
    )}
  </React.Fragment>
)

const InfluencerIdentityRowPerformanceEstimatedCPE = () => {
  const match = useRouteMatch()

  const { contextInfluencerIdentityId } = useInfluencerIdentityId()
  const { cpe, currency, isAveraged, identityProvider } = useSelector(
    (state: IApplicationState) => {
      const campaignId = selectCampaignId(match)!
      return {
        cpe: selectInfluencerIdentityEstimatedCPE(state, campaignId, contextInfluencerIdentityId),
        currency: selectInfluencerIdentityEstimatedCPECurrency(
          state,
          campaignId,
          contextInfluencerIdentityId,
        ),
        isAveraged: selectInfluencerIdentityEstimatedCPEIsAvarage(
          state,
          campaignId,
          contextInfluencerIdentityId,
        ),
        identityProvider: selectInfluencerIdentityPlatformProvider(
          state,
          campaignId,
          contextInfluencerIdentityId,
        ),
      }
    },
  )

  const tooltipBodyTextId = () => {
    switch (identityProvider) {
      case SubmissionChannels.Instagram:
        return 'submission.performance.estimatedCpe.instagram.body'
      case SubmissionChannels.TikTok:
      case SubmissionChannels.Facebook:
        return 'submission.performance.estimatedCpe.tiktok.facebook.body'
      case SubmissionChannels.Twitter:
        return 'submission.performance.estimatedCpe.twitter.body'
      default:
        return 'submission.performance.estimatedCpe.instagram.body'
    }
  }
  return (
    <InfluencerIdentityRowValueAndLabel
      value={
        <React.Fragment>
          {cpe ? (
            <CPEValue currency={currency} cpe={cpe} isAveraged={isAveraged} />
          ) : (
            <FormattedMessage id="core.metric.empty" />
          )}
        </React.Fragment>
      }
      label={
        <InfluencerIdentityRowLabelTooltip
          waypointOffset={350}
          title={<FormattedMessage id="submission.performance.estimatedCpe" />}
          body={<FormattedHTMLMessage id={tooltipBodyTextId()} />}
        >
          <FormattedMessage id="submission.performance.estCpe" />
        </InfluencerIdentityRowLabelTooltip>
      }
    />
  )
}

export default InfluencerIdentityRowPerformanceEstimatedCPE
