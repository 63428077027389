import React from 'react'

import BuilderDialogUploadInProgress from '@components/Builder/Dialog/UploadInProgress'
import { IModalContextProps } from '@context/Modal'
import { withModal } from '@hocs'
import Theme from '@theme'
import { Icon, Text } from '@tribegroup'
import ProgressTrackerBlockStyled, { IconWrapper, StyledLink, StyledSpan } from './Block.styled'

interface IProgressTrackerProps {
  onBuilderNavigateClick?: (event: React.SyntheticEvent, to: string) => void
  reached?: boolean
  error?: boolean
  children?: React.ReactNode
  active?: boolean
  to: string
  elementName?: string
  isAnyUploading?: boolean
}

interface IInternalProps extends IProgressTrackerProps, IModalContextProps {}

export const ProgressTrackerBlock = ({
  onBuilderNavigateClick,
  reached = false,
  children,
  error = false,
  active = false,
  to,
  elementName,
  showModal,
  isAnyUploading,
}: IInternalProps) => {
  const showCheck = reached && !error && !active
  const clickable = reached && !active && !isAnyUploading
  const Wrapper = clickable ? StyledLink : StyledSpan

  const onClick = (event: React.SyntheticEvent) => {
    if (isAnyUploading) {
      return showModal(BuilderDialogUploadInProgress)
    }

    if (onBuilderNavigateClick && reached) {
      onBuilderNavigateClick(event, to)
    }
  }

  return (
    <ProgressTrackerBlockStyled data-cy-element={elementName}>
      <Wrapper
        to={reached && !isAnyUploading ? to : '#'}
        onClick={onClick}
        data-disabled={!reached}
        data-active={active}
        data-testid="progress-tracker-block"
      >
        <Text color={active ? Theme.grey900Color : Theme.grey700Color}>{children}</Text>
        {showCheck && (
          <IconWrapper
            alignCenter
            justifyCenter
            background={Theme.success100Color}
            data-cy-element="tick-heavy"
          >
            <Icon size={0.75} glyph="tick-heavy" color={Theme.successColor} />
          </IconWrapper>
        )}
        {error && (
          <IconWrapper
            alignCenter
            justifyCenter
            background={Theme.error100Color}
            data-cy-element="cross-heavy"
          >
            <Icon size={0.75} glyph="cross-heavy" color={Theme.errorColor} />
          </IconWrapper>
        )}
      </Wrapper>
    </ProgressTrackerBlockStyled>
  )
}

export default withModal(ProgressTrackerBlock)
