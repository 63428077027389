import React from 'react'

import { useSelector } from 'react-redux'

import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { selectSubmissionChannel } from '@store/postCampaignReport'
import { Icon } from '@tribegroup'

interface IProps {
  submissionId: number
}

const AnalyticsPostCampaignReportListViewProfileSocialIcon = ({ submissionId }: IProps) => {
  const { channel } = useSelector((state: IApplicationState) => {
    return {
      channel: selectSubmissionChannel(state, submissionId),
    }
  })

  switch (channel) {
    case PCRChannels.TikTok: {
      return <Icon size={0.75} glyph={`social-tiktok-colour`} />
    }
    default: {
      return <Icon size={0.75} glyph={`social-instagram-colour`} />
    }
  }
}

export default AnalyticsPostCampaignReportListViewProfileSocialIcon
