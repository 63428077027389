import React from 'react'

import { ScrollPositionContext } from '@context/ScrollPosition'
import hooks from '@hooks'
import { getVerticalScrollPosition, setVerticalScrollPosition } from '@utils'

export const withRememberScrollPosition = (WrappedComponent: React.ComponentType) => {
  const InnerComponent: React.FC = (props) => {
    const { scrollPosition, setScrollPosition } = React.useContext(ScrollPositionContext)
    const { filter } = hooks.useLocationSearch()
    const [isMounted, setIsMounted] = React.useState(false)

    React.useEffect(() => {
      if (isMounted) {
        setVerticalScrollPosition(0)
      }
    }, [filter])

    React.useLayoutEffect(() => {
      setIsMounted(true)
      setVerticalScrollPosition(scrollPosition)
      return () => {
        setScrollPosition(getVerticalScrollPosition())
      }
    }, [])

    return <WrappedComponent {...props} />
  }
  return InnerComponent
}
