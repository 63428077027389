import { SHAREABLE_CONTENT_LIBRARY } from '@constants'
import { ContentLibraryFilterTypes } from '@enums'
import { IFetchMetaData, IHateoasLink } from '@store'
import {
  FetchMediaObjectsActionTypes,
  FetchShareableContentLibraryActionTypes,
  IMediaObject,
} from '@store/mediaObjects'

export const fetchMediaObjects = (
  filter = ContentLibraryFilterTypes.DISCOVER,
  isFetchNext = false,
) => ({
  payload: { filter, isFetchNext },
  type: FetchMediaObjectsActionTypes.FETCH_REQUEST,
})

export const fetchShareableContentLibrary = (token: string, isFetchNext = false) => ({
  payload: { token, isFetchNext },
  type: FetchShareableContentLibraryActionTypes.FETCH_REQUEST,
})

export const fetchMediaObjectsSuccess = (
  items: ReadonlyArray<IMediaObject>,
  links: ReadonlyArray<IHateoasLink>,
  metadata: IFetchMetaData,
  filter: ContentLibraryFilterTypes | typeof SHAREABLE_CONTENT_LIBRARY,
  shareableCLToken?: string,
) => ({
  payload: { items, links, filter, metadata, shareableCLToken },
  type: FetchMediaObjectsActionTypes.FETCH_SUCCESS,
})

export const fetchMediaObjectsError = (error: any) => ({
  payload: error,
  type: FetchMediaObjectsActionTypes.FETCH_ERROR,
})
