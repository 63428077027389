import React from 'react'

import OutsideClickHandler from 'react-outside-click-handler'

import hooks from '@hooks'
import { isMobileDevice } from '@utils'
import { Root, Video } from './MediaModal.styled'

const MediaModalVideoPlayer: React.FC = () => {
  const { closeMediaModal, videoSrc } = hooks.useMediaModal()
  const videoRef = React.createRef()

  const onLoadStart = () => {
    const video = videoRef.current as HTMLVideoElement

    const handleScreenChange = () => {
      const { fullscreenElement } = document as any
      if (!fullscreenElement) {
        closeMediaModal()
        video.removeEventListener('fullscreenchange', handleScreenChange)
      }
    }

    video.play()
    if (isMobileDevice() && video.requestFullscreen) {
      video.requestFullscreen()
      video.addEventListener('fullscreenchange', handleScreenChange, false)
    }
  }

  if (!videoSrc) {
    return null
  }

  return (
    <Root data-testid="media-modal-video-player">
      <OutsideClickHandler onOutsideClick={closeMediaModal}>
        <Video
          ref={videoRef}
          src={videoSrc}
          controlsList="nodownload"
          preload="auto"
          controls
          onLoadStart={onLoadStart}
        />
      </OutsideClickHandler>
    </Root>
  )
}

export default MediaModalVideoPlayer
