import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink, IListResponse } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  FetchBrandManagerIdentities,
  fetchIdentities,
  fetchIdentitiesError,
  fetchIdentitiesSuccess,
} from '@store/brandManagerIdentities'
import { selectBrandManagerIdentitiesLink } from '@store/me'
import { networkRequest } from '@utils'

export function* handleFetchIdentities(action: ReturnType<typeof fetchIdentities>) {
  try {
    const { payload } = action
    const authToken: string = yield select(selectAuthToken)
    const identitiesLink: IHateoasLink = yield select(selectBrandManagerIdentitiesLink)
    const url = new URL(identitiesLink.href)
    if (payload?.campaignId) {
      url.searchParams.append('brief_id', payload.campaignId.toString())
    }

    const { items: identities, links }: IListResponse = yield call(
      networkRequest,
      identitiesLink.method,
      url.href,
      undefined,
      authToken,
    )
    yield put(fetchIdentitiesSuccess(identities, links))
  } catch (error) {
    yield put(fetchIdentitiesError(error))
  }
}

export function* watchFetchIdentitiesRequest() {
  yield takeLatest(FetchBrandManagerIdentities.FETCH_REQUEST, handleFetchIdentities)
}
