import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import { IApplicationState } from '@store'
import { selectAuthToken } from '@store/auth'

export const onRender = (isAuthenticated, Component, props): JSX.Element => {
  const search =
    props.location && props.location.search
      ? `&search=${encodeURIComponent(props.location.search)}`
      : ''
  return isAuthenticated ? (
    <Component {...props} />
  ) : (
    <Redirect
      to={{
        pathname: '/signIn',
        search: props.location && `?from=${props.location.pathname}${search}`,
      }}
    />
  )
}

export const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props: any) => {
      return onRender(isAuthenticated, Component, props)
    }}
  />
)

const mapStateToProps = (state: IApplicationState) => ({
  isAuthenticated: Boolean(selectAuthToken(state)),
})

export default connect(mapStateToProps)(PrivateRoute)
