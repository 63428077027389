import { LinkProps } from 'react-router-dom'

import { Button } from '@tribegroup'
import { IButtonProps } from '@tribegroup/Button/Button'
import ButtonLinkStyled from './ButtonLink.styled'

const ButtonLink = (props: LinkProps & IButtonProps) => {
  const { to, target, ...buttonProps } = props
  const targetProps = target
    ? {
        target,
        rel: 'noopener noreferrer',
      }
    : undefined
  return (
    <ButtonLinkStyled to={to} {...targetProps}>
      <Button leftInnerSpacing={1.3125} rightInnerSpacing={1.3125} {...buttonProps} />
    </ButtonLinkStyled>
  )
}

export default ButtonLink
