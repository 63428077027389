import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { ICampaignContextProps } from '@context/Campaign'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import { EventTrackingNames } from '@enums'
import { IMeInfoProps, withCampaign, withSubmissionListFilter } from '@hocs'
import { IApplicationState } from '@store'
import { selectIsAddingFeedback } from '@store/feedback'
import { bulkAddFeedback } from '@store/feedback/actions/bulkAdd'
import { ITextWithTip } from '@store/rootResource'
import {
  Button,
  CloseIcon,
  Form,
  IWithFormValidationProps,
  Modal,
  ModalTitle,
  TextArea,
  Tip,
} from '@tribegroup'
import { isEmpty, toEventsTracking } from '@utils'
import { FeedbackActionButtonsWrapper } from './BulkFeedback.styled'

export interface IInboxTitleBarSelectionModeBulkFeedbackProps {
  submissionIds: ReadonlyArray<number>
  campaignId: number
  isOpen?: boolean
  onCancel: VoidFunction
}

interface IInternalProps
  extends IInboxTitleBarSelectionModeBulkFeedbackProps,
    ICampaignContextProps,
    IMeInfoProps,
    IWithFormValidationProps,
    ISubmissionListFilterContextProps,
    InjectedIntlProps {
  bulkAddFeedback: typeof bulkAddFeedback
  isAddingFeedback: boolean
  feedbackMessages: ReadonlyArray<ITextWithTip>
}

export interface IInboxTitleBarSelectionModeBulkFeedbackState {
  open: boolean
  message: string
  tip: string
}

const messageCharacterLimit = 800
export class InboxTitleBarSelectionModeBulkFeedback extends React.Component<
  IInternalProps,
  IInboxTitleBarSelectionModeBulkFeedbackState
> {
  state = {
    open: false,
    message: '',
    tip: '',
  }

  componentDidUpdate(prevProps: IInternalProps) {
    if (prevProps.isAddingFeedback && !this.props.isAddingFeedback) {
      this.props.onCancel()
    }
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { filter, sortBy, submissionIds, campaign } = this.props
    const message = this.state.message.trim()

    const analytics = toEventsTracking(EventTrackingNames.SubmitBulkFeedback, {
      brief_id: campaign.id,
      role: campaign.role,
      count: submissionIds.length,
      current_filter: filter,
      message,
      brief_state: campaign.status,
    })

    if (message.length > 0 && message.length <= messageCharacterLimit) {
      this.props.bulkAddFeedback(
        campaign.id,
        {
          message_body: message,
          submission_ids: submissionIds,
        },
        {
          refreshSubmissions: true,
          filter,
          sortBy,
          event: analytics,
        },
      )
    }
  }

  onCancelClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    this.props.onCancel()
  }

  resetForm = () => {
    this.setState({
      message: '',
      tip: '',
    })
  }

  onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      message: event.currentTarget.value,
    })
  }

  render() {
    const { intl, isOpen = false, onCancel, isAddingFeedback, validate, submissionIds } = this.props

    return (
      <Modal reactModalProps={{ isOpen, onRequestClose: onCancel }} width={50} complex>
        <ModalTitle center>
          <FormattedMessage
            id="submission.feedback.form.bulk.title"
            values={{ value: submissionIds && submissionIds.length }}
          />
        </ModalTitle>
        <CloseIcon onClick={onCancel} />
        <Form
          onSubmit={this.onSubmit}
          onBeforeSubmit={validate}
          setForm={this.props.setForm}
          method="POST"
        >
          <TextArea
            height={8}
            placeholder={intl.formatMessage({
              id: 'submission.feedback.form.bulk.message.placeholder',
            })}
            value={this.state.message}
            onChange={this.onMessageChange}
            characterLimit={messageCharacterLimit}
          />
          <FeedbackActionButtonsWrapper>
            <Flex justifyBetween>
              <div>{this.state.tip && <Tip text={this.state.tip} />}</div>
              <Flex justifyEnd>
                <Button
                  scheme="primary"
                  outlined
                  onClick={this.onCancelClick}
                  fontSize={0.875}
                  height={3}
                  width={10.125}
                >
                  <FormattedMessage id="core.text.cancel" />
                </Button>
                <Button
                  scheme="primary"
                  fontSize={0.875}
                  height={3}
                  width={10.125}
                  leftOuterSpacing={1}
                  disabled={isAddingFeedback || isEmpty(this.state.message.trim())}
                  loading={isAddingFeedback}
                >
                  <FormattedMessage id="core.text.submit" />
                </Button>
              </Flex>
            </Flex>
          </FeedbackActionButtonsWrapper>
        </Form>
      </Modal>
    )
  }
}

const mapDispatchToProps = {
  bulkAddFeedback,
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    isAddingFeedback: selectIsAddingFeedback(state),
  }
}

export default compose<IInternalProps, IInboxTitleBarSelectionModeBulkFeedbackProps>(
  withCampaign,
  injectIntl,
  withSubmissionListFilter,
  connect(mapStateToProps, mapDispatchToProps),
)(InboxTitleBarSelectionModeBulkFeedback)
