import { keyframes } from 'styled-components'

export const ZoomFadeOut = keyframes`
  from {
   opacity: 1;
  }
  50% {
   opacity: 0;
   transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
   opacity: 0;
  }
`
