import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const SummaryWrapper = styled.div`
  ${breakpoint('tabletPortrait')`
    margin-bottom: 5rem;
  `}
`

export { SummaryWrapper }
