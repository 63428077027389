import Flex from 'styled-flex-component'

import ContentLibraryTitleBar from '@components/ContentLibrary/TitleBar'
import ContentLibraryTopPanel from '@components/ContentLibrary/TopPanel'
import HomeSideBar from '@components/Home/SideBar/SideBar'
import CreditManagementWatcher from '@components/MediaObject/Licensing/CreditManagement/Watcher'
import MediaObjectsFacebookAdAccounts from '@components/MediaObject/Licensing/Licensed/FacebookAdAccounts'
import MediaObjectList from '@components/MediaObject/List'
import MediaObjectSearch from '@components/MediaObject/Search/Search'
import ConnectedPayment from '@components/Payment/ConnectedPayment'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import PaddedContainerStyled from '@components/UI/PaddedContainer/PaddedContainer.styled'
import AnalyticsTrackingProvider from '@context/AnalyticsTracking'
import { CampaignListFilterProvider } from '@context/CampaignListFilter'
import { CurrentMediaObjectIdProvider } from '@context/CurrentMediaObjectId'
import { MediaModalProvider } from '@context/MediaModal'
import { ModalProvider } from '@context/Modal'
import Theme from '@theme'
import { ToastContainer } from '@tribegroup'
import { ContentLibraryUnsupportedScreenWrapper } from './UnsupportedScreenWrapper'

export const ContentLibraryPage = () => {
  return (
    <MainLayout backgroundColor={Theme.grey100Color}>
      <MediaModalProvider>
        <CurrentMediaObjectIdProvider>
          <ToastContainer sidebarModifier={13.875} />
          <HeadTag id="core.text.contentLibrary" />
          <CampaignListFilterProvider>
            <ConnectedPayment>
              <AnalyticsTrackingProvider>
                <ModalProvider>
                  <ContentLibraryTitleBar />
                  <CreditManagementWatcher />
                  <Flex>
                    <HomeSideBar />
                    <Flex column full>
                      <PaddedContainerStyled>
                        <ContentLibraryUnsupportedScreenWrapper>
                          <ContentLibraryTopPanel />
                          <MediaObjectsFacebookAdAccounts />
                          <MediaObjectSearch />
                          <MediaObjectList />
                        </ContentLibraryUnsupportedScreenWrapper>
                      </PaddedContainerStyled>
                    </Flex>
                  </Flex>
                </ModalProvider>
              </AnalyticsTrackingProvider>
            </ConnectedPayment>
          </CampaignListFilterProvider>
        </CurrentMediaObjectIdProvider>
      </MediaModalProvider>
    </MainLayout>
  )
}

export default ContentLibraryPage
