import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import AnalyticsAudienceReporting from '@components/Analytics/AudienceReporting/AudienceReporting'
import { NoticeUnsupportedScreen } from '@components/UI/Notice'
import { SupportedScreenWrapper } from '@components/UI/ScreenSize'
import { IModalContextProps } from '@context/Modal/Provider'
import { IApplicationState } from '@store'
import { selectIsCampaignFetched } from '@store/campaigns'
import { selectCampaignId } from '@store/router'

type IInternalProps = ConnectedProps<typeof connector> & IModalContextProps

const AnalyticsAudienceReportingWrapper: React.FC<IInternalProps> = () => {
  return (
    <React.Fragment>
      <NoticeUnsupportedScreen
        alt="audience-reporting-unsupported-screen"
        title={<FormattedMessage id="analytics.audienceReporting.unsupportedScreen.title" />}
        subtitle={<FormattedMessage id="analytics.audienceReporting.unsupportedScreen.subtitle" />}
      />
      <SupportedScreenWrapper>
        <AnalyticsAudienceReporting />
      </SupportedScreenWrapper>
    </React.Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match)!

  return {
    isCampaignFetched: selectIsCampaignFetched(state, campaignId),
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(withRouter, connector)(AnalyticsAudienceReportingWrapper)
