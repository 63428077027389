import React from 'react'

import linkifyStr from 'linkify-string'

import Theme from '@theme'

interface IProps {
  value: string
  color?: string
}

const LinkifiedString: React.FC<IProps> = ({ value, color = Theme.grey900Color }) => {
  if (!value) {
    return null
  }
  return (
    <p
      dangerouslySetInnerHTML={{
        __html: linkifyStr(value, {
          tagName: 'a',
          target: {
            url: '_blank',
          },
          attributes: {
            style: `color: ${color}`,
          },
        }),
      }}
    />
  )
}

export default LinkifiedString
