import React, { RefObject } from 'react'

import { AspectRatios, MediaTypes } from '@enums'
import MediaImage from './Image/Image'
import MediaVideo from './Video/Video'

export interface IMediaProps extends IVideoMediaProps, IImageMediaProps {}

interface IMediaBaseProps {
  src: string
  fullSizeSrc: string
  mediaType: MediaTypes
  alt?: string
  withRightSeparatorBorder?: boolean
  backgroundColor?: string
  containImage?: boolean
  aspectRatio?: AspectRatios
  mediaId?: number
}

export interface IImageMediaProps extends IMediaBaseProps {
  expandable?: boolean
  fitToContainer?: boolean
}

export interface IVideoMediaProps extends IMediaBaseProps {
  autoPlay?: boolean
  controls?: boolean
  showPlayIcon?: boolean
  innerRef?: RefObject<unknown>
}

const Media: React.FC<IMediaProps> = (props) => {
  if (props.mediaType === MediaTypes.VIDEO) {
    return <MediaVideo {...props} />
  }
  return <MediaImage {...props} />
}

export { Media }
export default Media
