import { createAction } from '@reduxjs/toolkit'

import { BrandManagerIdentityProviders } from '@enums'
import { IResponseError } from '@lib/error'
import { ConnectCampaignSocialAccountActionType } from '@store/campaigns'

export const connectCampaignSocialAccount = createAction(
  ConnectCampaignSocialAccountActionType?.REQUEST,
  (
    socialAccountId: string,
    campaignId: number,
    handle: string,
    channel?: BrandManagerIdentityProviders,
  ) => ({
    payload: { socialAccountId, campaignId, handle, channel },
  }),
)

export const connectCampaignSocialAccountSuccess = createAction(
  ConnectCampaignSocialAccountActionType?.SUCCESS,
)

export const connectCampaignSocialAccountError = createAction<IResponseError>(
  ConnectCampaignSocialAccountActionType?.ERROR,
)
