import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Container, Header } from '@tribegroup'

interface ISubmissionCostBreakdownReceiptProps {
  receiptNumber: string
}

const MediaObjectCostBreakdownReceipt: React.FC<ISubmissionCostBreakdownReceiptProps> = ({
  receiptNumber,
}) => (
  <Container
    topOuterSpacing={0.75}
    topInnerSpacing={0.75}
    borderWidthTop={0.0625}
    borderColor={Theme.grey400Color}
  >
    <Header small uppercase color={Theme.primaryColor} bottomOuterSpacing={0.125}>
      <FormattedMessage id="socialSubmission.card.costBreakdown.receiptNo" />
    </Header>
    <Header small uppercase color={Theme.grey900Color}>
      {receiptNumber}
    </Header>
  </Container>
)

export default MediaObjectCostBreakdownReceipt
