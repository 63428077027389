import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Flex, { FlexItem } from 'styled-flex-component'

import { EllipsisWrapper } from '@components/InfluencerIdentity/Row/Details/Details.styled'
import Image from '@components/UI/Image'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { AspectRatios, EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import {
  selectInfluencerHandle,
  selectInfluencerImageURL,
  selectInfluencerName,
  selectIsCarousel,
  selectPublishedURL,
  selectSocialPageURL,
  selectSubmissionChannel,
} from '@store/postCampaignReport'
import { selectActivePCRChannel, selectCampaignId } from '@store/router'
import { viewSocial } from '@store/submissions'
import Theme from '@theme'
import { Container, FadeIn, H5, Icon, Link as AnchorLink, Text } from '@tribegroup'
import AnalyticsPostCampaignReportListViewProfileSocialIcon from './SocialIcon'

interface IProps {
  submissionId: number
  backgroundColor: string
}
type IInternalProps = IProps & ConnectedProps<typeof connector>

const AnalyticsPostCampaignReportListViewProfile: React.FC<IInternalProps> = ({
  isCarousel,
  backgroundColor,
  campaignId,
  submissionId,
  activePCRChannel,
  submissionImageURL,
  socialPageURL,
  influencerName,
  influencerHandle,
  publishedURL,
  viewSocial: viewSocialAction,
}) => {
  const onViewSocial = () => viewSocialAction(campaignId, submissionId)

  return (
    <Container borderRadius={0.5} backgroundColor={backgroundColor} height={4.75}>
      <Flex alignStart alignCenter>
        <FlexItem noShrink>
          <Container
            borderRadius={0.25}
            overflow="hidden"
            width={3.5}
            height={3.5}
            rightOuterSpacing={1.25}
            topOuterSpacing={0.625}
            verticalAlign="middle"
            inlineBlock
          >
            <ConnectedTrackedRoute
              eventName={EventTrackingNames.ViewSubmission}
              campaignId={campaignId}
              submissionId={submissionId}
              additionalProperties={{
                source: 'campaign_metrics',
              }}
            >
              <Link
                to={`/campaigns/${campaignId}/submissions/${submissionId}?from=${activePCRChannel}`}
              >
                <Container relative>
                  <Image
                    src={submissionImageURL}
                    aspectRatio={AspectRatios.ONE_BY_ONE}
                    animation={FadeIn}
                    loadingColor={Theme.grey900Color}
                  />
                  {isCarousel && (
                    <Container absolute bottomPosition={0.5} leftPosition={0.5}>
                      <Icon size={1.25} glyph="carousel" color={Theme.whiteColor} />
                    </Container>
                  )}
                </Container>
              </Link>
            </ConnectedTrackedRoute>
          </Container>
        </FlexItem>
        <Container topInnerSpacing={1.125} rightInnerSpacing={1}>
          <FlexItem>
            <EllipsisWrapper color={Theme.defaultColor}>
              <AnchorLink
                href={publishedURL || socialPageURL}
                target="_blank"
                onClick={onViewSocial}
              >
                <H5 inline color={Theme.defaultColor} rightOuterSpacing={0.75}>
                  {influencerName}
                </H5>
              </AnchorLink>
            </EllipsisWrapper>
            <EllipsisWrapper color={Theme.grey700Color}>
              <AnchorLink
                href={publishedURL || socialPageURL}
                target="_blank"
                onClick={onViewSocial}
              >
                <Container
                  verticalAlign="middle"
                  rightOuterSpacing={0.25}
                  topOuterSpacing={0.125}
                  inlineBlock
                >
                  <AnalyticsPostCampaignReportListViewProfileSocialIcon
                    submissionId={submissionId}
                  />
                </Container>
                <Container rightOuterSpacing={0.5} inline>
                  <Text xsmall color={Theme.grey700Color}>
                    {influencerHandle}
                  </Text>
                </Container>
                <Container inline verticalAlign="bottom">
                  <Icon size={0.75} glyph={'external-link'} color={Theme.grey700Color} />
                </Container>
              </AnchorLink>
            </EllipsisWrapper>
          </FlexItem>
        </Container>
      </Flex>
    </Container>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { submissionId, match }: IProps & RouteComponentProps,
) => ({
  influencerName: selectInfluencerName(state, submissionId),
  influencerHandle: selectInfluencerHandle(state, submissionId),
  submissionImageURL: selectInfluencerImageURL(state, submissionId),
  socialPageURL: selectSocialPageURL(state, submissionId),
  channel: selectSubmissionChannel(state, submissionId),
  isCarousel: selectIsCarousel(state, submissionId),
  campaignId: selectCampaignId(match)!,
  activePCRChannel: selectActivePCRChannel(state),
  publishedURL: selectPublishedURL(state, submissionId),
})

const mapDispatchToProps = {
  viewSocial,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<IInternalProps, IProps>(
  withRouter,
  connector,
)(AnalyticsPostCampaignReportListViewProfile)
