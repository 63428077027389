export interface IResponseError {
  name?: string
  status: number
  messages?: ReadonlyArray<IResponseErrorMessage> | string
  message?: string
}

export interface IResponseErrorMessage {
  error_code: string
  message: string
}

const parseError = (error: any) => {
  if (error) {
    // eslint-disable-next-line no-prototype-builtins
    if (error.hasOwnProperty('errors')) {
      return error.errors
    }
    // eslint-disable-next-line no-prototype-builtins
    if (error.hasOwnProperty('error')) {
      return [error.error]
    }
  }
  return 'Unknown error occured.'
}

export class ResponseError extends Error implements IResponseError {
  status: number
  messages: ReadonlyArray<IResponseErrorMessage> | string
  constructor(error?: any, status = 0, name = 'ResponseError') {
    super(error)
    this.name = name
    this.status = status
    this.messages = parseError(error)
  }
}
