import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import {
  fetchContentLibraryBillings,
  selectContentLibraryBillingList,
  selectIsFetchingBilling,
} from '@store/billings'
import { isEmpty } from '@utils'
import PurchaseWrapper, { IPurchaseWrapperProps } from './Wrapper'

const mapStateToProps = (state: IApplicationState) => {
  const hasPurchases = !isEmpty(selectContentLibraryBillingList(state))
  const isLoading = !hasPurchases && selectIsFetchingBilling(state)
  return { isLoading, hasPurchases, enableFetch: true }
}

const mapDispatchToProps = { fetchList: fetchContentLibraryBillings }

export default compose<IPurchaseWrapperProps, {}>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PurchaseWrapper)
