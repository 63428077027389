import { call, put, select, takeEvery } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import { selectBulkDeclineLink } from '@store/campaigns'
import {
  bulkDecline,
  BulkDeclineActionTypes,
  bulkDeclineError,
  bulkDeclineSuccess,
  fetchSubmissions,
} from '@store/submissions'
import { networkRequest } from '@utils'

export function* handleBulkDecline(action: ReturnType<typeof bulkDecline>) {
  try {
    const { campaignId, params } = action.payload
    const { refreshSubmissions, filter, sortBy, event: analyticsEvent } = action.meta
    const authToken: string = yield select(selectAuthToken)
    const bulkDeclineLink: IHateoasLink = yield select(selectBulkDeclineLink, campaignId)
    yield call(networkRequest, bulkDeclineLink.method, bulkDeclineLink.href, params, authToken)
    yield put(
      bulkDeclineSuccess(campaignId, params.submission_ids, {
        refreshSubmissions,
        filter,
        sortBy,
      }),
    )
    yield put(trackEvent(analyticsEvent.eventPayload.event, analyticsEvent.eventPayload.properties))
    yield put(fetchSubmissions(campaignId, { refreshSubmissions, filter, sortBy }))
  } catch (error) {
    yield put(bulkDeclineError(error))
  }
}

export function* watchBulkDeclineRequest() {
  yield takeEvery(BulkDeclineActionTypes.BULK_DECLINE_REQUEST, handleBulkDecline)
}
