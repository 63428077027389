import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'

export const SelectAll = styled.div`
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
  outline: none;
  margin-right: 0.5rem;
`

export const InboxTitleBarSelectionModeStyled = styled(Flex)`
  width: 100%;
`
export const CancelButtonWrapper = styled.div`
  flex-grow: 0;
  width: 15rem;
  text-align: right;
`

export const CancelButton = styled.span`
  cursor: pointer;
`

export const SelectionModeActions = styled(Flex)`
  flex-grow: 0;
  width: 15rem;
  flex-shrink: 0;
`

export const SelectedCount = styled.div`
  text-align: center;
  flex-grow: 1;
  flex-shrink: 0;
  ${({ hilightCount }) =>
    hilightCount &&
    css`
      .count {
        color: ${Theme.primaryColor};
      }
    `};
`
