import React from 'react'

import { useSelector } from 'react-redux'
import { compose } from 'recompose'

import BuilderDialogUploadInProgress from '@components/Builder/Dialog/UploadInProgress'
import ActionIcon from '@components/UI/ActionIcon'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IModalContextProps } from '@context/Modal'
import { EventTrackingNames, PageRoutes } from '@enums'
import { withBuilderValidation, withModal } from '@hocs'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { selectCampaignIdFromDraft } from '@store/drafts'
import { selectIsAnyUploading } from '@store/moodboard'
import Theme from '@theme'
import { BuilderTitleBarActionsConfirmCloseContext } from '../ConfirmClose'

interface IInternalProps extends IBuilderValidationContextProps, IModalContextProps {}

const BuilderTitleBarActionsButtonClose: React.FC<IInternalProps> = ({
  formChanged,
  showModal,
}) => {
  const { from = `/${PageRoutes.Campaigns}` } = hooks.useLocationSearch()
  const { showConfirmModal } = React.useContext(BuilderTitleBarActionsConfirmCloseContext)

  const { isAnyUploading, campaignId } = useSelector((state: IApplicationState) => {
    const campaignId = selectCampaignIdFromDraft(state)

    return {
      campaignId,
      isAnyUploading: campaignId && selectIsAnyUploading(state, campaignId),
    }
  })

  const onClick = () => {
    if (isAnyUploading) {
      return showModal(BuilderDialogUploadInProgress)
    }

    showConfirmModal(from, EventTrackingNames.CampaignBuilderCloseBuilder)
  }

  return (
    <ConnectedTrackedRoute
      disableTracking={Boolean(formChanged)}
      eventName={EventTrackingNames.CampaignBuilderCloseBuilder}
      campaignId={campaignId}
      additionalProperties={{
        action: 'close',
        current_filter: undefined,
      }}
    >
      <ActionIcon
        glyph="close-x"
        color={Theme.defaultColor}
        size={1.5}
        onClick={onClick}
        elementName="campaign-builder-close-button"
      />
    </ConnectedTrackedRoute>
  )
}

export default compose<IInternalProps, {}>(
  withBuilderValidation,
  withModal,
)(BuilderTitleBarActionsButtonClose)
