import { useFlag } from '@unleash/proxy-client-react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import MediaObjectLicensing from '@components/MediaObject/Licensing/Licensing'
import { MediaObjectRequestLicense } from '@components/MediaObject/Licensing/RequestLicense/RequestLicense'
import ActionIcon from '@components/UI/ActionIcon'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IConnectedTrackedRoute } from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { IModalContextProps } from '@context/Modal'
import { EventTrackingNames, FeatureToggles, LicenseStatuses } from '@enums'
import { withModal } from '@hocs'
import { IApplicationState } from '@store'
import {
  selectHasRequestedLicense,
  selectIsLicensed,
  selectIsLicenseDeclined,
  selectTransactedLicenseStatus,
} from '@store/mediaObjects'
import Theme from '@theme'
import { getMessage } from '@utils/message'

interface IActionBarLicensingProps {
  mediaObjectId: number
}

interface IInternalProps extends IActionBarLicensingProps, InjectedIntlProps, IModalContextProps {
  licenseStatus?: LicenseStatuses
  isDeclined: boolean
  isLicensed: boolean
  hasLicenseRequested: boolean
}

const Wrapper = ({ children, hasTracking, ...trackingProps }) =>
  hasTracking ? (
    <ConnectedTrackedRoute {...(trackingProps as IConnectedTrackedRoute)}>
      {children}
    </ConnectedTrackedRoute>
  ) : (
    <span>{children}</span>
  )

export const ActionBarLicensing = ({
  showModal,
  mediaObjectId,
  licenseStatus,
  isDeclined,
  isLicensed,
  hasLicenseRequested,
  intl,
}: IInternalProps) => {
  const isNewLicenseTypesEnabled = useFlag(FeatureToggles.NEW_LICENSE_TYPES_ENABLED)

  const onButtonClick = () => {
    const LicenseComponent = isNewLicenseTypesEnabled
      ? MediaObjectRequestLicense
      : MediaObjectLicensing

    showModal(LicenseComponent, { mediaObjectId })
  }

  return (
    <Wrapper
      hasTracking={!isDeclined}
      eventName={EventTrackingNames.ViewLicensingOptions}
      mediaObjectId={mediaObjectId}
    >
      <ActionIcon
        glyph="license-content"
        color={Theme.grey900Color}
        size={1.5}
        onClick={onButtonClick}
        tooltip={getMessage(
          intl,
          `submission.card.action.licensecontent.${licenseStatus || 'request'}`,
        )}
        highlighted={hasLicenseRequested || isLicensed}
        disabled={isDeclined}
        elementName="license"
      />
    </Wrapper>
  )
}

const mapStateToProps = (state: IApplicationState, { mediaObjectId }: IInternalProps) => ({
  licenseStatus: selectTransactedLicenseStatus(state, mediaObjectId),
  isDeclined: selectIsLicenseDeclined(state, mediaObjectId),
  isLicensed: selectIsLicensed(state, mediaObjectId),
  hasLicenseRequested: selectHasRequestedLicense(state, mediaObjectId),
})

export default compose<IInternalProps, IActionBarLicensingProps>(
  injectIntl,
  withModal,
  connect(mapStateToProps),
)(ActionBarLicensing)
