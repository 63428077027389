import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { returnSecondIfDeepEqual } from '@utils'
import { IPrePurchaseAnalyticsState, PrePurchaseAnalyticsActionTypes } from './types'

const initialState: IPrePurchaseAnalyticsState = {
  prePurchaseAnalyticsByIdentityId: {},
  isFetching: false,
  errors: undefined,
}

const reducer: Reducer<IPrePurchaseAnalyticsState> = (state = initialState, action) => {
  switch (action.type) {
    case PrePurchaseAnalyticsActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }

    case PrePurchaseAnalyticsActionTypes.FETCH_SUCCESS: {
      const { identityId, prePurchaseAnalyticsType, prePurchaseAnalytics } = action.payload
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        prePurchaseAnalyticsByIdentityId: returnSecondIfDeepEqual(
          {
            ...state.prePurchaseAnalyticsByIdentityId,
            [identityId]: {
              ...state.prePurchaseAnalyticsByIdentityId[identityId],
              [prePurchaseAnalyticsType]: prePurchaseAnalytics,
            },
          },
          state.prePurchaseAnalyticsByIdentityId,
        ),
      }
    }

    case PrePurchaseAnalyticsActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as prePurchaseAnalyticsReducer }
