import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { IVideoMetricsState } from '@store/postCampaignReport'
import { returnSecondIfDeepEqual } from '@utils'
import { indexById } from '@utils/groupBy'
import { IVideoMetrics, VideoMetricsActionTypes } from './types'

const initialState: IVideoMetricsState = {
  isFetching: false,
  errors: undefined,
  videoMetricsById: {},
  videoMetricIdsByChannel: {},
  videoMetricsSummary: {},
}

const reducer: Reducer<IVideoMetricsState> = (state = initialState, action) => {
  switch (action.type) {
    case VideoMetricsActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }

    case VideoMetricsActionTypes.FETCH_SUCCESS: {
      const { campaignId, videoMetrics, channel } = action.payload

      return {
        ...state,
        videoMetricsById: returnSecondIfDeepEqual(
          indexById(videoMetrics.items, state.videoMetricsById, 'submission_id'),
          state.videoMetricsById,
        ),
        videoMetricsSummary: returnSecondIfDeepEqual(
          {
            ...state.videoMetricsSummary,
            [campaignId]: {
              ...state.videoMetricsSummary[campaignId],
              [channel]: videoMetrics.metadata,
            },
          },
          state.videoMetricsSummary,
        ),
        videoMetricIdsByChannel: returnSecondIfDeepEqual(
          {
            ...state.videoMetricIdsByChannel,
            [campaignId]: {
              ...state.videoMetricIdsByChannel[campaignId],
              [channel]: videoMetrics.items.map((item: IVideoMetrics) => item.submission_id),
            },
          },
          state.videoMetricIdsByChannel,
        ),
        errors: undefined,
        isFetching: false,
      }
    }

    case VideoMetricsActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as videoMetricsReducer }
export default reducer
