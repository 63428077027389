import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { PanelWrapper } from '@components/Builder/Layout/Layout.styled'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { withBuilderValidation, withDraft } from '@hocs'
import { useFormattedNumberFieldValidation } from '@hooks/useFormattedNumberFieldValidation'
import Theme from '@theme'
import { Container, FormGroup, FormInput, H4, Panel, Slider, TextField } from '@tribegroup'
import { fromCents } from '@tribegroup/utils'
import BudgetErrorMessage from '../Error/Message'
import CurrencyAndTax from './CurrencyAndTax'

const FIELD_NAME = 'total_budget'

interface IInternalProps
  extends IDraftContextProps,
    InjectedIntlProps,
    IBuilderValidationContextProps {}

const BuilderBudgetEstimatedBudget: React.FC<IInternalProps> = ({
  draft,
  intl,
  validationResults,
  clearFieldValidation,
  updateForm,
}) => {
  const {
    indicative_budget_currency,
    indicative_budget_cents = 0,
    disabled_attributes = [],
  } = draft

  const [amount_cents, amount_currency] = [indicative_budget_cents, indicative_budget_currency]

  const currencySymbol = intl.formatMessage({
    id: `core.currency.symbol.${amount_currency}`,
  })

  const disabled = disabled_attributes.includes('budget')
  const {
    setValueByElementEvent,
    setValueByNumber,
    formattedValue,
    value,
    valueInCents,
  } = useFormattedNumberFieldValidation(fromCents(amount_cents), currencySymbol, intl.formatNumber)

  const onChangeComplete = () => {
    clearFieldValidation(FIELD_NAME)
    updateForm({ indicative_budget_cents: valueInCents })
  }
  const hasBudgetError =
    validationResults && validationResults[FIELD_NAME] && validationResults[FIELD_NAME].hasError

  return (
    <PanelWrapper>
      <Panel
        title={
          <Container flexBetween alignItems="flex-end">
            <H4 inline color={Theme.defaultColor}>
              <FormattedMessage id="builder.budget.estimatedBudget.solution.header" />
            </H4>
          </Container>
        }
        info={<CurrencyAndTax />}
      >
        <Container topOuterSpacing={1.25}>
          <FormGroup>
            <FormInput
              validationRule={{
                min: {
                  value: 1,
                  message: intl.formatMessage({
                    id: 'builder.budget.error.budgetTotal.required',
                  }),
                },
              }}
            >
              {!disabled && <TextField type="hidden" value={value} name={FIELD_NAME} />}
              <TextField
                onChange={setValueByElementEvent}
                onBlur={onChangeComplete}
                value={formattedValue}
                textCenter
                medium
                fullWidth
                rounded
                disabled={disabled}
                data-testid="estimated-budget-input"
                invalid={hasBudgetError}
              />
            </FormInput>
          </FormGroup>
          <Container topOuterSpacing={1.25}>
            <Slider
              currency={amount_currency}
              minValue={0}
              labelCount={4}
              maxValue={100000}
              maxValuePlus
              nearest={1000}
              step={1000}
              onChangeComplete={onChangeComplete}
              onChange={setValueByNumber}
              value={value}
              disabled={disabled}
            />
          </Container>
        </Container>
        <BudgetErrorMessage results={validationResults} errorKey={FIELD_NAME} />
      </Panel>
    </PanelWrapper>
  )
}

export default compose<IInternalProps, {}>(
  withDraft,
  injectIntl,
  withBuilderValidation,
)(BuilderBudgetEstimatedBudget)
