import { createSelector } from 'reselect'

import { IApplicationState } from '@store'

export const selectMember = (state: IApplicationState, memberId: number) =>
  state.members.membersById[memberId] || undefined

export const selectMembers = createSelector(
  [
    (state: IApplicationState) => state.members.memberIdsByCampaignId,
    (state: IApplicationState) => state.members.membersById,
    (state, campaignId) => campaignId,
  ],
  (memberIdsByCampaignId, membersById, campaignId) => {
    const memberIds = memberIdsByCampaignId[campaignId] || []
    return memberIds.map(id => membersById[id])
  },
)

export const selectMemberLinks = (state: IApplicationState, memberId: number) =>
  selectMember(state, memberId).links || []

export const selectMemberLink = (state: IApplicationState, memberId: number, linkRel: string) =>
  selectMemberLinks(state, memberId).find(link => link.rel === linkRel)

export const selectMembersLinks = (state: IApplicationState, campaignId: number) =>
  state.members.memberLinksByCampaignId[campaignId]

export const selectAddMemberLink = (state: IApplicationState, campaignId: number) => {
  const membersLinks = selectMembersLinks(state, campaignId)
  return membersLinks && membersLinks.find(link => link.rel === 'add_member')
}

export const selectRemoveMemberLink = (state: IApplicationState, memberId: number) =>
  selectMemberLink(state, memberId, 'delete')

export const selectisAdding = (state: IApplicationState) => state.members.isAdding

export const selectMemberErrors = (state: IApplicationState) => state.members.errors
