import styled from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'

const DEFAULT_SIZE = 2.5

export const PlaceholderAvatarStyled = styled(Flex)`
  min-width: ${({ size }) => size ?? DEFAULT_SIZE}rem;
  min-height: ${({ size }) => size ?? DEFAULT_SIZE}rem;
  height: ${({ size }) => size ?? DEFAULT_SIZE}rem;
  width: auto;
  margin-right: 0.75rem;
  border-radius: 3rem;
  overflow: hidden;
  text-transform: uppercase;
  border: 1px transparent;
  position: relative;

  &::after {
    content: ' ';
    width: 100%;
    height: 100%;
    border-radius: 3rem;
    border: 1px dashed ${Theme.primaryColor};
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.6;
    box-sizing: border-box;
  }
`
