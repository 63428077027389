import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import {
  selectIsFetchingSubmissionsByIdentity,
  selectSubmissionsByIdentity,
} from '@store/brandFans'
import { isEmpty } from '@utils'
import InfluencerIdentityRowSubmissionEmpty from './Empty'
import InfluencerIdentityRowSubmissionsList from './List'
import InfluencerIdentityRowSubmissionsLoading from './Loading'

interface IProps {
  identityId: number
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

export const InfluencerIdentityRowSubmissionWrapper: React.FC<IInternalProps> = ({
  isFetching,
  isEmpty,
  identityId,
}) => {
  if (isFetching) {
    return <InfluencerIdentityRowSubmissionsLoading />
  }
  if (isEmpty) {
    return <InfluencerIdentityRowSubmissionEmpty />
  }
  return <InfluencerIdentityRowSubmissionsList identityId={identityId} />
}

const mapStateToProps = (state: IApplicationState, { identityId }: IProps) => ({
  isFetching: selectIsFetchingSubmissionsByIdentity(state, identityId),
  isEmpty: isEmpty(selectSubmissionsByIdentity(state, identityId)),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(connector)(InfluencerIdentityRowSubmissionWrapper)
