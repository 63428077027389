import React from 'react'

import ImageBackgroundStyled from './ImageBackground.styled'

export interface IImageBackgroundProps {
  image: ImageData | string
  position?: number
  attachment?: 'scroll' | 'fixed' | 'local' | 'initial' | 'inherit'
  size?: 'auto' | 'length' | 'cover' | 'contain' | 'initial' | 'inherit'
  height?: string | 'inherit'
  additionalStyles?: object
  centerContent?: boolean
  opacity?: number
  children?: React.ReactNode
}

const ImageBackground = (props: IImageBackgroundProps) => <ImageBackgroundStyled {...props} />

export default ImageBackground
