import { createReducer } from '@reduxjs/toolkit'
import { Reducer } from 'redux'

import { INBOX_FILTER } from '@constants'
import { SubmissionStatuses } from '@enums'
import { AuthActionTypes } from '@store/auth'
import { FetchInfluencerIdentitySubmissionsActionTypes } from '@store/brandFans'
import {
  PurchaseLicenseActionTypes,
  RequestLicenseActionTypes as MediaObjectLicenseRequestActionType,
} from '@store/mediaObjects'
import { LOCATION_CHANGE } from '@store/router/types'
import { addToSet, removeFromSet, returnSecondIfDeepEqual } from '@utils'
import { appendIdsToFilter, indexById } from '@utils/groupBy'
import {
  ApproveActionTypes,
  BulkDeclineActionTypes,
  DeclineActionTypes,
  FetchSubmissionBrandFansMembershipActionTypes,
  InfluencerSubmissionsActionTypes,
  ISubmission,
  PreApproveActionTypes,
  PromoteAsAdActionTypes,
  RequestLicenseActionTypes,
  RescheduleActionTypes,
  SubmissionsByInfluencerIdentityActionTypes,
  UpdateFilterSubmissionIdsTypes,
} from './types'
import {
  ISubmissionsState,
  ShortlistActionTypes,
  SubmissionActionTypes,
  SubmissionsActionTypes,
} from './'

const initialState: ISubmissionsState = {
  isFetching: false,
  isPreApproving: false,
  isApproving: false,
  isDeclining: false,
  isRescheduling: false,
  isRequestingLicense: false,
  isRequestingBrandedContentAd: false,
  isShortlisting: {},
  isTransferringMedia: false,
  errors: undefined,
  submissionsById: {},
  submissionIdsByFilter: {},
  submissionLinksByFilter: {},
  submissionsMetadataByCampaignId: {},
  selectedSortByFilter: {},
  isFetchingInfluencerSubmissions: false,
  submissionsByInfluencerId: {},
  submissionsByInfluencerIdentityId: {},
  brandFanGroupIdsByIdentityId: {},
  pendingRequestsByInfluencerIdentityId: [],
}

const reducer: Reducer<ISubmissionsState> = (state = initialState, action) => {
  switch (action.type) {
    case ApproveActionTypes.APPROVE_REQUEST: {
      return {
        ...state,
        errors: undefined,
        isApproving: true,
      }
    }

    case PreApproveActionTypes.PREAPPROVE_REQUEST: {
      return {
        ...state,
        errors: undefined,
        isPreApproving: true,
      }
    }

    case RescheduleActionTypes.RESCHEDULE_REQUEST: {
      return {
        ...state,
        errors: undefined,
        isRescheduling: true,
      }
    }

    case BulkDeclineActionTypes.BULK_DECLINE_REQUEST:
    case DeclineActionTypes.DECLINE_REQUEST: {
      return {
        ...state,
        isDeclining: true,
      }
    }

    case ShortlistActionTypes.SHORTLIST_REQUEST:
    case ShortlistActionTypes.UNSHORTLIST_REQUEST: {
      const { submissionId } = action.payload
      return {
        ...state,
        isShortlisting: {
          ...state.isShortlisting,
          [submissionId]: true,
        },
      }
    }

    case PromoteAsAdActionTypes.PROMOTE_AS_AD_REQUEST: {
      return {
        ...state,
        isRequestingBrandedContentAd: true,
      }
    }

    case SubmissionsByInfluencerIdentityActionTypes.FETCH_REQUEST: {
      const { influencerIdentityId, backgroundRefresh } = action.payload
      if (backgroundRefresh) {
        return state
      }
      return {
        ...state,
        pendingRequestsByInfluencerIdentityId: addToSet(
          state.pendingRequestsByInfluencerIdentityId,
          [influencerIdentityId],
        ),
      }
    }

    case SubmissionActionTypes.FETCH_REQUEST:
    case SubmissionsActionTypes.FETCH_REQUEST: {
      const resetList = action.meta && action.meta.resetList
      const sortBy = action.meta && action.meta.sortBy
      const filter = action.meta && action.meta.filter

      const baseState = {
        ...state,
        isFetching: true,
        selectedSortByFilter: {
          ...state.selectedSortByFilter,
          [filter]: sortBy,
        },
      }

      if (resetList) {
        const { campaignId } = action.payload
        return {
          ...baseState,
          submissionIdsByFilter: {
            ...state.submissionIdsByFilter,
            [campaignId]: {},
          },
        }
      }

      return baseState
    }

    case RequestLicenseActionTypes.LICENSE_REQUEST: {
      return {
        ...state,
        isRequestingLicense: true,
      }
    }

    case SubmissionsByInfluencerIdentityActionTypes.FETCH_SUCCESS: {
      const { campaignId, influencerIdentityId, submissions } = action.payload
      const submissionsByInfluencerIdentity = state.submissionsByInfluencerIdentityId[campaignId]
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        submissionsById: indexById(submissions, state.submissionsById),
        pendingRequestsByInfluencerIdentityId: removeFromSet(
          state.pendingRequestsByInfluencerIdentityId,
          influencerIdentityId,
        ),
        submissionsByInfluencerIdentityId: {
          ...state.submissionsByInfluencerIdentityId,
          [campaignId]: returnSecondIfDeepEqual(
            {
              ...submissionsByInfluencerIdentity,
              [influencerIdentityId]: submissions.map((submission) => submission.id),
            },
            submissionsByInfluencerIdentity,
          ),
        },
      }
    }

    case ShortlistActionTypes.SHORTLIST_SUCCESS: {
      const submission: ISubmission = action.payload

      return {
        ...state,
        isFetching: false,
        errors: undefined,
        isShortlisting: {
          [submission.id]: false,
        },
        submissionsById: returnSecondIfDeepEqual(
          {
            ...state.submissionsById,
            [submission.id]: submission,
          },
          state.submissionsById,
        ),
      }
    }

    case RescheduleActionTypes.RESCHEDULE_SUCCESS:
    case RequestLicenseActionTypes.LICENSE_SUCCESS:
    case PromoteAsAdActionTypes.PROMOTE_AS_AD_SUCCESS:
    case SubmissionActionTypes.FETCH_SUCCESS: {
      const submission: ISubmission = action.payload
      return {
        ...state,
        isFetching: false,
        isDeclining: false,
        isRescheduling: false,
        isRequestingLicense: false,
        isRequestingBrandedContentAd: false,
        errors: undefined,
        submissionsById: returnSecondIfDeepEqual(
          {
            ...state.submissionsById,
            [submission.id]: submission,
          },
          state.submissionsById,
        ),
        brandFanGroupIdsByIdentityId: returnSecondIfDeepEqual(
          {
            ...state.brandFanGroupIdsByIdentityId,
            [submission.identity_id]: submission.identity_brand_fan_group_ids,
          },
          state.brandFanGroupIdsByIdentityId,
        ),
      }
    }

    case ShortlistActionTypes.UNSHORTLIST_SUCCESS: {
      const { submission, campaignId } = action.payload
      const submissionIdsForShortlisted = removeFromSet(
        (state.submissionIdsByFilter[campaignId] &&
          state.submissionIdsByFilter[campaignId].shortlisted) ||
          [],
        submission.id,
      )

      return {
        ...state,
        errors: undefined,
        isShortlisting: {
          [submission.id]: false,
        },
        submissionsById: {
          ...state.submissionsById,
          [submission.id]: submission,
        },
        submissionIdsByFilter: {
          ...state.submissionIdsByFilter,
          [campaignId]: {
            ...state.submissionIdsByFilter[campaignId],
            shortlisted: submissionIdsForShortlisted,
          },
        },
      }
    }

    case BulkDeclineActionTypes.BULK_DECLINE_SUCCESS: {
      const { campaignId, submissionIds } = action.payload
      const { filter } = action.meta

      const updatedSubmissionIdsByFilter = (
        (state.submissionIdsByFilter[campaignId] &&
          state.submissionIdsByFilter[campaignId][filter]) ||
        []
      ).filter((submissionId) => !submissionIds.includes(submissionId))

      const declinedSubmissions = submissionIds
        .map((id) => state.submissionsById[id])
        .reduce(
          (accum, curr) => ({
            ...accum,
            [curr.id]: { ...curr, status: SubmissionStatuses.Declined },
          }),
          state.submissionsById,
        )

      return {
        ...state,
        isApproving: false,
        isDeclining: false,
        errors: undefined,
        submissionsById: {
          ...state.submissionsById,
          ...declinedSubmissions,
        },
        submissionIdsByFilter: {
          ...state.submissionIdsByFilter,
          [campaignId]: {
            ...state.submissionIdsByFilter[campaignId],
            [filter]: updatedSubmissionIdsByFilter,
          },
        },
      }
    }

    case UpdateFilterSubmissionIdsTypes.REMOVE: {
      const { campaignId, submissionId, filters } = action.payload

      const submissionIdsForAffectedFilters = filters.reduce((accum, curr) => {
        const submissionIdsForCurrentFilter =
          state.submissionIdsByFilter[campaignId] && state.submissionIdsByFilter[campaignId][curr]
        const newSet = removeFromSet(submissionIdsForCurrentFilter || [], submissionId)
        return {
          ...accum,
          [curr]: newSet,
        }
      }, {})

      return {
        ...state,
        submissionIdsByFilter: {
          ...state.submissionIdsByFilter,
          [campaignId]: {
            ...state.submissionIdsByFilter[campaignId],
            ...submissionIdsForAffectedFilters,
          },
        },
      }
    }

    case DeclineActionTypes.DECLINE_SUCCESS:
    case PreApproveActionTypes.PREAPPROVE_SUCCESS:
    case ApproveActionTypes.APPROVE_SUCCESS: {
      const { submission } = action.payload
      return {
        ...state,
        isApproving: false,
        isPreApproving: false,
        isDeclining: false,
        errors: undefined,
        submissionsById: {
          ...state.submissionsById,
          [submission.id]: submission,
        },
      }
    }

    case SubmissionsActionTypes.FETCH_SUCCESS: {
      const { campaignId, items = [], links, metadata } = action.payload
      const { filter } = action.meta
      const submissionIdsForCurrentFilter =
        state.submissionIdsByFilter[campaignId] && state.submissionIdsByFilter[campaignId][filter]

      const updatedBrandFanGroupIdsByIdentityId = indexById(
        items,
        state.brandFanGroupIdsByIdentityId,
        'identity_id',
        'identity_brand_fan_group_ids',
      )

      const fetchedSubmissions = indexById(
        items.map((submission) =>
          returnSecondIfDeepEqual(submission, state.submissionsById[submission.id]),
        ),
      )

      const submissionsById = {
        ...state.submissionsById,
        ...fetchedSubmissions,
      }

      return {
        ...state,
        isFetching: false,
        errors: undefined,
        submissionsById: returnSecondIfDeepEqual(submissionsById, state.submissionsById),
        submissionIdsByFilter: {
          ...state.submissionIdsByFilter,
          [campaignId]: {
            ...(state.submissionIdsByFilter[campaignId] || {}),
            [filter]: addToSet([
              ...(submissionIdsForCurrentFilter || []),
              ...items.map((submission) => submission.id),
            ]),
          },
        },
        submissionLinksByFilter: {
          ...state.submissionLinksByFilter,
          [campaignId]: {
            ...(state.submissionLinksByFilter[campaignId] || {}),
            [filter]: links,
          },
        },
        submissionsMetadataByCampaignId: {
          ...state.submissionsMetadataByCampaignId,
          [campaignId]: metadata,
        },
        brandFanGroupIdsByIdentityId: {
          ...state.brandFanGroupIdsByIdentityId,
          ...updatedBrandFanGroupIdsByIdentityId,
        },
      }
    }

    case InfluencerSubmissionsActionTypes.FETCH_ERROR:
    case ShortlistActionTypes.UNSHORTLIST_ERROR:
    case ShortlistActionTypes.SHORTLIST_ERROR:
    case PreApproveActionTypes.PREAPPROVE_ERROR:
    case ApproveActionTypes.APPROVE_ERROR:
    case DeclineActionTypes.DECLINE_ERROR:
    case BulkDeclineActionTypes.BULK_DECLINE_ERROR:
    case RequestLicenseActionTypes.LICENSE_ERROR:
    case RescheduleActionTypes.RESCHEDULE_ERROR:
    case SubmissionActionTypes.FETCH_ERROR:
    case PromoteAsAdActionTypes.PROMOTE_AS_AD_ERROR:
    case SubmissionsActionTypes.FETCH_ERROR:
    case FetchSubmissionBrandFansMembershipActionTypes.ERROR: {
      return {
        ...state,
        isFetching: false,
        isApproving: false,
        isPreApproving: false,
        isDeclining: false,
        isRescheduling: false,
        isRequestingLicense: false,
        isFetchingInfluencerSubmissions: false,
        isRequestingBrandedContentAd: false,
        errors: action.payload,
      }
    }

    case SubmissionsByInfluencerIdentityActionTypes.FETCH_ERROR: {
      const { influencerIdentityId, error } = action.payload
      return {
        ...state,
        errors: error,
        pendingRequestsByInfluencerIdentityId: removeFromSet(
          state.pendingRequestsByInfluencerIdentityId,
          influencerIdentityId,
        ),
      }
    }

    case PurchaseLicenseActionTypes.PURCHASE_LICENSE_SUCCESS: {
      const { campaignId, submissionId } = action.payload
      const affectedFilters: ReadonlyArray<any> = [INBOX_FILTER, 'shortlisted', 'preapproved']

      const submissionIdsForAffectedFilters = affectedFilters.reduce((accum, curr) => {
        const submissionIdsForCurrentFilter =
          state.submissionIdsByFilter[campaignId] && state.submissionIdsByFilter[campaignId][curr]
        const newSet = removeFromSet(submissionIdsForCurrentFilter || [], submissionId)
        return {
          ...accum,
          [curr]: newSet,
        }
      }, {})

      return {
        ...state,
        errors: undefined,
        submissionIdsByFilter: {
          ...state.submissionIdsByFilter,
          [campaignId]: {
            ...state.submissionIdsByFilter[campaignId],
            ...submissionIdsForAffectedFilters,
          },
        },
      }
    }

    case MediaObjectLicenseRequestActionType.REQUEST_LICENSE_SUCCESS: {
      return {
        ...state,
        errors: undefined,
      }
    }

    case SubmissionsActionTypes.CLEAR_ERRORS: {
      return {
        ...state,
        errors: undefined,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    case LOCATION_CHANGE: {
      const filters = action.payload.pathname.match(/campaigns\/[0-9]*/g)
        ? state.selectedSortByFilter
        : {}

      return {
        ...state,
        selectedSortByFilter: filters,
      }
    }

    case InfluencerSubmissionsActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetchingInfluencerSubmissions: true,
      }
    }

    case InfluencerSubmissionsActionTypes.FETCH_SUCCESS: {
      const { influencerId, campaignId, influencerSubmissions } = action.payload

      const influencerSubmissionsById = indexById(influencerSubmissions)
      const { influencerSubmissionsIds } = appendIdsToFilter(
        influencerSubmissions,
        'influencerSubmissionsIds',
      ) as { influencerSubmissionsIds: ReadonlyArray<any> }

      return {
        ...state,
        submissionsById: returnSecondIfDeepEqual(
          { ...state.submissionsById, ...influencerSubmissionsById },
          state.submissionsById,
        ),
        submissionsByInfluencerId: returnSecondIfDeepEqual(
          {
            ...state.submissionsByInfluencerId,
            [campaignId]: {
              ...state.submissionsByInfluencerId[campaignId],
              [influencerId]: influencerSubmissionsIds,
            },
          },
          state.submissionsByInfluencerId,
        ),
      }
    }

    case FetchInfluencerIdentitySubmissionsActionTypes.FETCH_SUCCESS: {
      const { submissions } = action.payload
      return {
        ...state,
        submissionsById: returnSecondIfDeepEqual(
          {
            ...state.submissionsById,
            ...indexById(submissions),
          },
          state.submissionsById,
        ),
      }
    }

    default:
      return state
  }
}

const submissionsReducer = createReducer(initialState, (builder) => {
  builder.addDefaultCase(reducer)
})

export { submissionsReducer }
