import { FormattedHTMLMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { SubmissionStatuses } from '@enums'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'

interface IProps {
  newStatus: SubmissionStatuses
}

interface IInternalProps extends InjectedIntlProps, IProps {}

const ToastTemplateSubmissionMoved: React.FC<IInternalProps> = ({ intl, newStatus }) => (
  <Flex alignCenter>
    <FormattedHTMLMessage
      id="submission.toast.movedToXView"
      values={{ view: intl.formatMessage({ id: `inbox.sidebar.filters.${newStatus}` }) }}
    />
    <Container relative height={1} width={1}>
      <Container height={1} absolute rightPosition={-0.5} topPosition={0}>
        <Icon color={Theme.defaultColor} glyph="tick" size={1} />
      </Container>
    </Container>
  </Flex>
)

export default compose<IInternalProps, IProps>(injectIntl)(ToastTemplateSubmissionMoved)
