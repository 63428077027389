import React from 'react'

import { BuilderDefault } from '@enums'
import { IMedia, IMoodboardItem } from '@store/moodboard'
import BuilderCampaignMoodboardTip from '../Moodboard/Tip'
import MoodboardItem from './Item'

interface IProps {
  mediaList: ReadonlyArray<IMoodboardItem | undefined>
  updateMediaList: (images: ReadonlyArray<IMedia>) => void
  removeMedia: (position: number) => void
  disableDelete?: boolean
}

const MoodboardGrid: React.FC<IProps> = ({
  mediaList,
  updateMediaList,
  removeMedia,
  disableDelete,
}) => {
  return (
    <>
      {[...Array(BuilderDefault.MAX_ARRAY_LENGTH_MOODBOARD)].map((_, index) => {
        return (
          <MoodboardItem
            key={`moodboard-${index}`}
            position={index}
            media={mediaList[index]}
            onImageDropped={updateMediaList}
            onCloseClick={removeMedia}
            disableDelete={disableDelete}
          />
        )
      })}
      <BuilderCampaignMoodboardTip />
    </>
  )
}

export { MoodboardGrid }
export default MoodboardGrid
