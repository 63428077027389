import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { SocialSubmissionTypes, SubmissionChannels } from '@enums'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { isEmpty } from '@utils/isEmpty'
import Container from './../../Container'
import { H4 } from './../../Typography'
import { InputWrapper } from './InputSet.styled'

const CustomInputWrapper = ({ children, urls, refProp, form }) => {
  const { isStoryRequestedSubmissionType } = useSelector((state: IApplicationState) => {
    const isStoryRequestedFromChannel = state.drafts.campaign?.channels
      ?.find((channel) => channel.name === SubmissionChannels.Instagram)
      ?.requested_social_submission_types?.includes('story')

    return {
      isStoryRequestedSubmissionType:
        state.drafts.campaign?.requested_social_submission_types?.includes(
          SocialSubmissionTypes.Story,
        ) || Boolean(isStoryRequestedFromChannel),
    }
  })

  if (!isStoryRequestedSubmissionType) {
    return <></>
  }

  return (
    <Container topOuterSpacing={1}>
      <H4 color={Theme.grey900Color}>
        <FormattedMessage id="builder.brief.socialMedia.instagram.storyURL.header" />
      </H4>
      <InputWrapper
        data-attr="urls"
        data-pattern="^(http|https)://"
        data-error="Please enter a valid URL"
        ref={refProp}
      >
        {isEmpty(urls) && form}
      </InputWrapper>
      {children}
    </Container>
  )
}

export default CustomInputWrapper
