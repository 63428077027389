import { Fragment } from 'react'

import { useSelector } from 'react-redux'

import { BrandManagerIdentityProviders } from '@enums'
import { useBooleanToggle } from '@hooks/useBooleanToggle'
import { useModal } from '@hooks/useModal'
import { IApplicationState } from '@store'
import {
  IBrandManagerIdentity,
  selectBrandManagerIdentityLink,
} from '@store/brandManagerIdentities'
import Theme from '@theme'
import { DropdownMenu, Icon } from '@tribegroup'
import ConnectedAccountsModalTikTokRemoveAccount from '../Modal/TikTokRemoveAccount'
import ConnectedAccountsListIdentityRowMenuReconnect from './IdentityRowMenuReconnect'
import ConnectedAccountsListIdentityRowMenuRemove from './IdentityRowMenuRemove'
import IdentityRowMenuRemoveConfirmation from './IdentityRowMenuRemoveConfirmation'

interface IProps {
  brandManagerIdentity: IBrandManagerIdentity
  hasReconnectLink: boolean
}
const ConnectedAccountsListIdentityRowMenu = ({
  brandManagerIdentity,
  hasReconnectLink,
}: IProps) => {
  const { showModal } = useModal()

  const { hasRemoveLink } = useSelector((state: IApplicationState) => ({
    hasRemoveLink: Boolean(
      selectBrandManagerIdentityLink(state, brandManagerIdentity.id, 'remove'),
    ),
  }))

  const showTikTokDialog = () => {
    showModal(ConnectedAccountsModalTikTokRemoveAccount)
  }

  const [isRemoveConfirmOpen, toggleIsRemoveConfirmOpen] = useBooleanToggle(false)

  return (
    <Fragment>
      <IdentityRowMenuRemoveConfirmation
        brandManagerIdentity={brandManagerIdentity}
        isOpen={isRemoveConfirmOpen}
        onClose={toggleIsRemoveConfirmOpen}
      />
      <DropdownMenu
        width={4.5}
        target={<Icon glyph="more" size={1} color={Theme.grey900Color} />}
        indicatorSpace={0}
        elementName="drop-down-menu"
        data-testid="identity-row-menu"
      >
        {hasReconnectLink && (
          <ConnectedAccountsListIdentityRowMenuReconnect
            brandManagerIdentityId={brandManagerIdentity.id}
          />
        )}
        {hasRemoveLink && (
          <ConnectedAccountsListIdentityRowMenuRemove onClick={toggleIsRemoveConfirmOpen} />
        )}

        {!hasRemoveLink &&
          brandManagerIdentity.channel === BrandManagerIdentityProviders.TikTok && (
            <ConnectedAccountsListIdentityRowMenuRemove onClick={showTikTokDialog} />
          )}
      </DropdownMenu>
    </Fragment>
  )
}

export default ConnectedAccountsListIdentityRowMenu
