import Flex from 'styled-flex-component'

import BrandFansContent from '@components/BrandFans/Content'
import BrandFansTitleBar from '@components/BrandFans/TitleBar/TitleBar'
import HomeSideBar from '@components/Home/SideBar/SideBar'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import PaddedContainerStyled from '@components/UI/PaddedContainer/PaddedContainer.styled'
import { ModalProvider } from '@context/Modal'
import { ToastContainer } from '@tribegroup'

const BrandFansPage = () => (
  <MainLayout>
    <ModalProvider>
      <ToastContainer />
      <HeadTag id="core.text.brandFans" />
      <BrandFansTitleBar />
      <Flex>
        <HomeSideBar />
        <Flex column full>
          <PaddedContainerStyled data-testid="brand-fans-content-container">
            <BrandFansContent />
          </PaddedContainerStyled>
        </Flex>
      </Flex>
    </ModalProvider>
  </MainLayout>
)

export { BrandFansPage }
export default BrandFansPage
