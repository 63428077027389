import { ComponentType } from 'react'

import styled, { css } from 'styled-components'

export type verticalAlignPropType =
  | 'baseline'
  | 'sub'
  | 'super'
  | 'top'
  | 'text-top'
  | 'middle'
  | 'bottom'
  | 'text-bottom'

export interface IContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  textAlign?: string
  width?: number | string
  maxWidth?: number | string
  minWidth?: number | string
  height?: number | string
  minHeight?: number | string
  maxHeight?: number | string
  inline?: boolean
  outerSpacingAuto?: boolean
  outerSpacing?: number
  leftOuterSpacing?: number
  rightOuterSpacing?: number
  topOuterSpacing?: number
  bottomOuterSpacing?: number
  innerSpacing?: number
  innerSpacingY?: number
  innerSpacingX?: number
  leftInnerSpacing?: number
  rightInnerSpacing?: number
  topInnerSpacing?: number
  bottomInnerSpacing?: number
  relative?: boolean
  absolute?: boolean
  fixed?: boolean
  sticky?: boolean
  inlineFlex?: boolean
  fullWidth?: boolean
  fullHeight?: boolean
  leftPosition?: number
  rightPosition?: number
  topPosition?: number | string
  bottomPosition?: number
  pointer?: boolean
  cursorDefault?: boolean
  inherit?: boolean
  inlineBlock?: boolean
  noWrap?: boolean
  overflow?: 'visible' | 'hidden' | 'scroll' | 'auto'
  borderRadius?: number | string
  wordBreak?: boolean
  zIndex?: number
  verticalAlign?: verticalAlignPropType | number
  backgroundColor?: string
  borderWidth?: number
  borderWidthTop?: number
  borderWidthBottom?: number
  borderWidthLeft?: number
  borderWidthRight?: number
  borderColor?: string
  borderStyle?:
    | 'dashed'
    | 'dotted'
    | 'double'
    | 'groove'
    | 'hidden'
    | 'inset'
    | 'outset'
    | 'solid'
    | 'revert'
    | 'outset'
  lineHeight?: number | string
  color?: string
  removeAnchorTextDecoration?: boolean
  textOverflowAsEllipsis?: boolean
  boxShadow?: string
  flexCenter?: boolean
  flexStart?: boolean
  flexEnd?: boolean
  flexBetween?: boolean
  flexColumn?: boolean
  flexWrap?: 'wrap' | 'no-wrap' | 'wrap-reverse' | 'revert'
  flexBasis?: number | string
  gap?: number
  grow?: number
  alignItems?: 'center' | 'flex-start' | 'flex-end'
  noShrink?: boolean
  cursor?: 'default' | 'pointer' | 'not-allowed' | 'grab'
  hoverBackgroundColor?: string
  hoverBorderColor?: string
  opacity?: number
  hoverOpacity?: number
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  borderBox?: boolean
}

const Container: ComponentType<IContainerProps> = styled.div`
  ${({
    textAlign,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    inline,
    fullHeight,
    fullWidth,
    relative,
    absolute,
    sticky,
    fixed,
    outerSpacingAuto,
    outerSpacing,
    innerSpacing,
    leftOuterSpacing,
    rightOuterSpacing,
    topOuterSpacing,
    bottomOuterSpacing,
    leftInnerSpacing,
    rightInnerSpacing,
    topInnerSpacing,
    bottomInnerSpacing,
    leftPosition,
    rightPosition,
    topPosition,
    bottomPosition,
    pointer,
    cursorDefault,
    inherit,
    inlineBlock,
    inlineFlex,
    noWrap,
    overflow,
    borderRadius,
    wordBreak,
    zIndex,
    verticalAlign,
    backgroundColor,
    borderWidth,
    borderWidthTop,
    borderWidthBottom,
    borderWidthLeft,
    borderWidthRight,
    borderColor,
    borderStyle = 'solid',
    color,
    lineHeight,
    boxShadow,
    removeAnchorTextDecoration,
    textOverflowAsEllipsis,
    flexCenter,
    flexBetween,
    flexStart,
    flexEnd,
    flexColumn,
    flexWrap,
    flexBasis,
    alignItems,
    noShrink,
    cursor,
    hoverBackgroundColor,
    hoverBorderColor,
    hoverOpacity,
    opacity,
    justifyContent,
    gap,
    grow,
    innerSpacingY,
    innerSpacingX,
    borderBox,
  }: IContainerProps) =>
    css`
      ${textAlign && `text-align: ${textAlign}`};
      ${inline && `display: inline`};
      ${fullWidth && `width: 100%`};
      ${fullHeight && `height: 100%`};
      ${relative && `position: relative;`};
      ${absolute && `position: absolute;`};
      ${sticky && `position: sticky;`};
      ${fixed && `position: fixed;`};
      ${outerSpacing && `margin: ${outerSpacing}rem;`};
      ${innerSpacing && `padding: ${innerSpacing}rem;`};
      ${innerSpacingY &&
      css`
        padding-top: ${innerSpacingY}rem;
        padding-bottom: ${innerSpacingY}rem;
      `};
      ${innerSpacingX &&
      css`
        padding-left: ${innerSpacingX}rem;
        padding-right: ${innerSpacingX}rem;
      `};
      ${outerSpacingAuto && 'margin: auto;'};
      ${leftOuterSpacing && `margin-left: ${leftOuterSpacing}rem;`};
      ${rightOuterSpacing && `margin-right: ${rightOuterSpacing}rem;`};
      ${topOuterSpacing && `margin-top: ${topOuterSpacing}rem;`};
      ${bottomOuterSpacing && `margin-bottom: ${bottomOuterSpacing}rem;`};
      ${leftInnerSpacing && `padding-left: ${leftInnerSpacing}rem;`};
      ${rightInnerSpacing && `padding-right: ${rightInnerSpacing}rem;`};
      ${topInnerSpacing && `padding-top: ${topInnerSpacing}rem;`};
      ${bottomInnerSpacing && `padding-bottom: ${bottomInnerSpacing}rem;`};
      ${(leftPosition || leftPosition === 0) && `left: ${leftPosition}rem;`};
      ${(rightPosition || rightPosition === 0) && `right: ${rightPosition}rem;`};
      ${(topPosition || topPosition === 0) && `top: ${topPosition}rem;`};
      ${(bottomPosition || bottomPosition === 0) && `bottom: ${bottomPosition}rem;`};
      ${pointer && `cursor: pointer`};
      ${cursorDefault && `cursor: default`};
      ${inlineBlock && `display: inline-block`};
      ${inherit && `display: inherit`};
      ${inlineFlex && `display: inline-flex`};
      ${noWrap && `white-space: nowrap`};
      ${wordBreak && `word-break: break-word;`};
      ${zIndex && `z-index: ${zIndex};`};
      ${backgroundColor && `background-color: ${backgroundColor};`};
      ${borderColor && `border-color: ${borderColor};`};
      ${color && `color: ${color};`};
      ${borderBox && `box-sizing: border-box;`}
      ${textOverflowAsEllipsis &&
      css`
        text-overflow: ellipsis;
        overflow: hidden;
      `};
      ${borderStyle &&
      `
        border-style: ${borderStyle};
        `};
      ${borderWidth &&
      `
        border-width: ${borderWidth}rem;
      `};
      ${borderWidthTop &&
      `
          border-top-width: ${borderWidthTop}rem;
        `};
      ${borderWidthBottom &&
      `
          border-bottom-width: ${borderWidthBottom}rem;
        `};
      ${borderWidthLeft &&
      `
          border-left-width: ${borderWidthLeft}rem;
        `};
      ${borderWidthRight &&
      `
          border-right-width: ${borderWidthRight}rem;
        `};
      ${overflow &&
      `
        overflow: ${overflow};
        scrollbar-width: thin;
      `};
      ${width &&
      `
          width: ${typeof width === 'number' ? `${width}rem` : width};
        `};
      ${maxWidth &&
      `
          max-width: ${typeof maxWidth === 'number' ? `${maxWidth}rem` : maxWidth};
        `};
      ${minWidth &&
      `
            min-width: ${typeof minWidth === 'number' ? `${minWidth}rem` : minWidth};
          `};
      ${height &&
      `
          height: ${typeof height === 'number' ? `${height}rem` : height};
        `};
      ${minHeight &&
      `
          min-height: ${typeof minHeight === 'number' ? `${minHeight}rem` : minHeight};
        `};
      ${maxHeight &&
      `
          max-height: ${typeof maxHeight === 'number' ? `${maxHeight}rem` : maxHeight};
        `};
      ${borderRadius &&
      `
          border-radius: ${typeof borderRadius === 'number' ? `${borderRadius}rem` : borderRadius};
          `};
      ${verticalAlign && `vertical-align: ${verticalAlign};`}
      ${lineHeight &&
      `
          line-height: ${typeof lineHeight === 'number' ? `${lineHeight}rem` : lineHeight};
        `};
      ${boxShadow &&
      `
          box-shadow: ${boxShadow};
        `};
      ${removeAnchorTextDecoration &&
      css`
        a {
          text-decoration: none;
        }
      `}
      ${flexCenter &&
      `
        display: flex;
        justify-content: center;
        align-items: center;
      `};
      ${flexStart &&
      `
          display: flex;
          justify-content: flex-start;
          align-items: center;
        `};
      ${flexEnd &&
      `
          display: flex;
          justify-content: flex-end;
          align-items: center;
        `};
      ${flexBetween &&
      `
        display: flex;
        justify-content: space-between;
        align-items: center;
      `};
      ${flexColumn &&
      `
        flex-direction: column;
      `}
      ${flexWrap &&
      `
          flex-wrap: ${flexWrap};
        `}
      ${flexBasis &&
      `flex-basis:  ${typeof flexBasis === 'number' ? `${flexBasis}rem` : flexBasis};`};
      ${alignItems && `align-items: ${alignItems};`};
      ${noShrink && `flex-shrink: 0;`};
      ${cursor && `cursor: ${cursor};`};
      ${opacity && `opacity: ${opacity};`};
      ${justifyContent && `justify-content: ${justifyContent};`};
      ${gap && `gap: ${gap}rem;`};
      ${grow && `flex-grow: ${grow};`};

      &:hover {
        ${hoverBackgroundColor && `background-color: ${hoverBackgroundColor};`};
        ${hoverBorderColor && `border-color: ${hoverBorderColor};`};
        ${hoverOpacity && `opacity: ${hoverOpacity / 100};`};
      }
    `}
`

export default Container
