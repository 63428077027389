import { call, put, select, takeEvery } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { handleFetchInfluencerIdentity } from '@sagas/influencerIdentities/influencerIdentity'
import { IHateoasLink } from '@store'
import { selectSubmissionTrackingProps, trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  fetchSubmissions,
  ISubmission,
  selectShortlistLink,
  ShortlistActionTypes,
  shortlistSubmissionError,
  shortlistSubmissionRequest,
  shortlistSubmissionSuccess,
} from '@store/submissions'
import { networkRequest } from '@utils'

export function* handleShortlist(action: ReturnType<typeof shortlistSubmissionRequest>) {
  try {
    const { campaignId, submissionId } = action.payload
    const { filter, sortBy, refreshSubmissions, event } = action.meta
    const authToken: string = yield select(selectAuthToken)
    const shortlistSubmissionLink: IHateoasLink = yield select(selectShortlistLink, submissionId)
    const submission: ISubmission = yield call(
      networkRequest,
      shortlistSubmissionLink.method,
      shortlistSubmissionLink.href,
      undefined,
      authToken,
    )

    const properties = yield select(selectSubmissionTrackingProps, campaignId, submissionId)
    yield put(trackEvent(EventTrackingNames.ShortlistSubmission, properties))

    yield put(shortlistSubmissionSuccess(submission, { event }))
    if (refreshSubmissions) {
      yield put(fetchSubmissions(campaignId, { filter, sortBy }))
    }
    yield call(handleFetchInfluencerIdentity, campaignId, submission.id)
  } catch (error) {
    yield put(shortlistSubmissionError(error))
  }
}

export function* watchShortlistRequest() {
  yield takeEvery(ShortlistActionTypes.SHORTLIST_REQUEST, handleShortlist)
}
