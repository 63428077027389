import styled from 'styled-components'

import Theme from '@theme'

const IndicatorWrapper = styled.div`
  svg {
    fill: ${Theme.primaryColor};
  }
`

const VisibleOnHover = styled.div`
  display: none;
`

const Root = styled.div`
  position: absolute;
  cursor: pointer;
  background-color: ${Theme.whiteColor};
  right: 0.75rem;
  top: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;

  &:hover {
    background-color: ${Theme.primaryColor};
    ${IndicatorWrapper} {
      svg {
        fill: ${Theme.whiteColor};
      }
    }
    ${VisibleOnHover} {
      display: block;
    }
  }
`

export { Root, IndicatorWrapper, VisibleOnHover }
