import React from 'react'

import { getHeaderStyled, IHProps } from './H.styled'

const HStyled = getHeaderStyled('h1', {
  fontSize: 1.875,
  fontWeight: 'normal',
  lineHeight: 1.33,
  letterSpacing: 0.3,
})

export class H1 extends React.PureComponent<
  IHProps & React.HtmlHTMLAttributes<HTMLHeadingElement>
> {
  public render() {
    return <HStyled {...this.props} />
  }
}
