import { createReducer } from '@reduxjs/toolkit'

import { completeSignUpFailed, completeSignUpRequest, completeSignUpSuccess } from './actions'
import { ICompleteSignupState } from './types'

const initialState = {
  loading: 'idle',
} as ICompleteSignupState

export const completeSignUpReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(completeSignUpRequest, (state) => {
      state.loading = 'pending'
    })

    .addCase(completeSignUpSuccess, (state) => {
      state.loading = 'succeeded'
    })

    .addCase(completeSignUpFailed, (state, action) => {
      state.loading = 'failed'
      state.errors = action.payload
    })
})
