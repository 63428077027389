import { call, put, select, takeEvery } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import { IMedia, MediaUploadActionTypes, upload, uploadError, uploadSuccess } from '@store/media'
import { selectUploadMediaLink } from '@store/rootResource'
import { mediaUpload } from '@utils'

export function* handleMediaUpload(action: ReturnType<typeof upload>) {
  const { media, uploadId } = action.payload
  try {
    const authToken: string = yield select(selectAuthToken)
    const uploadLink: IHateoasLink = yield select(selectUploadMediaLink)
    const uploadedMedia: IMedia = yield call(
      mediaUpload,
      uploadLink.method,
      uploadLink.href,
      media,
      authToken,
    )
    yield put(uploadSuccess(uploadedMedia, uploadId!))
  } catch (error) {
    yield put(uploadError(error, uploadId!))
  }
}

export function* watchMediaUpload() {
  yield takeEvery(MediaUploadActionTypes.UPLOAD_REQUEST, handleMediaUpload)
}
