import { useDispatch } from 'react-redux'

import { reconnectIdentity } from '@store/brandManagerIdentities'

const useIdentityRowReconnect = (brandManagerIdentityId: number) => {
  const dispatch = useDispatch()
  const reconnect = () => {
    dispatch(reconnectIdentity(brandManagerIdentityId))
  }
  return { reconnect }
}

export { useIdentityRowReconnect }
