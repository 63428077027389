import { createSelector } from 'reselect'

import { EMPTY_LIST } from '@constants'
import {
  BuilderSteps,
  CampaignStatuses,
  PaymentMethods,
  SubmissionChannels,
  SupportedSubmissionTypes,
} from '@enums'
import { IApplicationState } from '@store'
import {
  ICampaign,
  IChannel,
  ILicenseGroup,
  selectAllCampaignCount,
  selectCampaign,
  selectHasSeenPreviewTooltip,
} from '@store/campaigns'
import { ICampaignObjectives, IDraft } from '@store/drafts'
import { selectUserSubscriptionPricings } from '@store/me'
import { selectProvidedObjectives } from '@store/rootResource'
import { selectLocationsearchAsObject } from '@store/router'
import { difference, isEmpty } from '@utils'
import { isSocialCampaign } from '@utils/campaign'

export const selectDraftIsSaving = (state: IApplicationState) => state.drafts.isSaving

export const selectDraft = (state: IApplicationState) => state.drafts.campaign || {}

export const selectCampaignIdFromDraft = createSelector(selectDraft, (draft: IDraft) => draft.id)

export const selectDraftChanges = (state: IApplicationState, campaignId: number) => {
  const campaign = selectCampaign(state, campaignId) || {}
  const draft = selectDraft(state)
  return difference(draft, campaign)
}

export const selectDraftHasChanges = (state: IApplicationState, campaignId?: number) =>
  Boolean(campaignId) && !isEmpty(selectDraftChanges(state, campaignId as number))

export const selectIsInDraftStatus = createSelector(
  selectDraft,
  (draft) => draft.status === CampaignStatuses.Draft || draft.status === undefined,
)

export const selectDraftIsSocial = createSelector(
  selectDraft,
  (draft) =>
    draft.submission_type === SupportedSubmissionTypes.SocialSubmission ||
    isSocialCampaign(draft as ICampaign),
)

export const selectDraftBrandId = createSelector(selectDraft, (draft) => draft.brand_id)

export const selectCampaignObjectives = createSelector(
  selectDraft,
  selectIsInDraftStatus,
  selectProvidedObjectives,
  (draft, isDraft, providedObjectives) => {
    const draftCampaignObjectives = draft.campaign_objectives as ICampaignObjectives
    const draftProvidedObjectives = isDraft &&
      providedObjectives && { provided: providedObjectives }
    return { ...draftProvidedObjectives, ...draftCampaignObjectives }
  },
)

export const selectCampaignObjectivesProvided = createSelector(
  selectCampaignObjectives,
  (objectives) => (objectives && objectives.provided) || EMPTY_LIST,
)

export const selectCampaignObjectivesOther = createSelector(
  selectCampaignObjectives,
  (objectives) => (objectives && objectives.other) || '',
)

export const selectShowCampaignObjectives = createSelector(
  selectCampaignObjectives,
  selectDraftIsSocial,
  (objectives, isSocial) => !isEmpty(objectives) && isSocial,
)

export const selectHasBrandPartnershipHandle = createSelector(
  selectDraft,
  selectDraftIsSocial,
  (draft: IDraft, isSocial: boolean) => {
    if (!isSocial) {
      return undefined
    }
    if (draft.has_brand_partnership_handle === undefined) {
      return true
    }
    return draft.has_brand_partnership_handle
  },
)

export const selectHasChannelBrandPartnershipHandle = createSelector(
  (state, channelName: SubmissionChannels) => channelName,
  selectDraft,
  selectDraftIsSocial,
  (channelName: SubmissionChannels, draft: IDraft, isSocial: boolean) => {
    if (!isSocial) {
      return undefined
    }

    const hasRequiredSocialAccount = draft.channels?.find((channel) => channel.name === channelName)
      ?.has_required_social_channel_account

    if (hasRequiredSocialAccount === undefined) {
      return true
    }
    return hasRequiredSocialAccount
  },
)

export const selectCheckedTikTokAdManagerAccount = createSelector(
  selectDraft,
  selectDraftIsSocial,
  (draft: IDraft, isSocial: boolean) => {
    if (!isSocial) {
      return false
    }

    return draft.has_tiktok_ad_manager_account
  },
)

export const selectPaidPartnershipHandle = createSelector(
  selectDraft,
  (draft: IDraft) => draft.paid_partnership_handle,
)

export const selectChannelPaidPartnershipHandle = createSelector(
  (state, channelName: SubmissionChannels) => channelName,
  selectDraft,
  (channelName: SubmissionChannels, draft: IDraft) =>
    draft.channels?.find((channel) => channel.name === channelName)?.paid_partnership_handle,
)

export const selectShowPaidPartnershipHandle = createSelector(
  selectPaidPartnershipHandle,
  selectHasBrandPartnershipHandle,
  (paidPartnershipHandle, hasBrandPartnershipHandle) => {
    return Boolean(paidPartnershipHandle) && hasBrandPartnershipHandle
  },
)

export const selectShowCampaignPreviewTooltip = createSelector(
  selectDraft,
  selectAllCampaignCount,
  selectHasSeenPreviewTooltip,
  (state, currentStep: BuilderSteps) => currentStep,
  (draft: IDraft, campaignCount: number, hasSeenPreview: boolean, currentStep: BuilderSteps) => {
    const hasCreatedAnyCampaign = Boolean(campaignCount)
    const hasHeroImage = draft.hero_16x9_original_url !== undefined
    const showPreviewTooltip =
      !hasCreatedAnyCampaign &&
      !hasSeenPreview &&
      currentStep === BuilderSteps.CAMPAIGN &&
      hasHeroImage

    return showPreviewTooltip
  },
)

export const selectDraftIsUsingInvoice = createSelector(selectDraft, (draft: IDraft) => {
  return Boolean(draft.payment_method && draft.payment_method === PaymentMethods.INVOICE)
})

export const selectDraftTypeFromLocation = createSelector(
  selectLocationsearchAsObject,
  (searchParams) => searchParams.type,
)

export const selectDraftType = createSelector(
  selectDraft,
  selectDraftTypeFromLocation,
  (draft, draftTypeFromLocation) =>
    draftTypeFromLocation || draft.submission_type || draft.supported_submission_types?.[0],
)

export const selectDraftLicenseCard = createSelector(
  selectDraft,
  (draft: IDraft) => draft.license_card || EMPTY_LIST,
)

export const selectDraftLicenseCardGroupedByLabel = createSelector(
  selectDraftLicenseCard,
  (daftLicenseCard) =>
    Object.values(
      daftLicenseCard.reduce(
        (accum, curr) => ({
          ...accum,
          [curr.label]: {
            label: curr.label,
            description: curr.description,
            options: [...(accum[curr.label]?.options || []), curr],
          } as ILicenseGroup,
        }),
        {},
      ),
    ) as ReadonlyArray<ILicenseGroup>,
)

export const selectDraftBrandFanGroupIds = createSelector(
  selectDraft,
  (draft) => draft.brand_fan_group_ids || (EMPTY_LIST as ReadonlyArray<number>),
)

export const selectDraftHasBrandFanGroupAssociation = createSelector(
  selectDraftBrandFanGroupIds,
  (groupIds) => groupIds.length > 0,
)

export const selectIsAllowedToAssociateBrandFanGroup = createSelector(selectDraft, (draft) =>
  Boolean(draft.allowed_to_associate_brand_fan_group),
)

export const selectDisabledAttributes = createSelector(
  selectDraft,
  (draft: IDraft) => draft.disabled_attributes || EMPTY_LIST,
)

export const selectIsDisabledAttribute = createSelector(
  selectDisabledAttributes,
  (state: IApplicationState, attr: string) => attr,
  (disabledAttributes: ReadonlyArray<string>, attr: string) => disabledAttributes.includes(attr),
)

export const selectIsAllAttributesDisabled = createSelector(
  selectDisabledAttributes,
  (state: IApplicationState, attrs: ReadonlyArray<string>) => attrs,
  (disabledAttributes: ReadonlyArray<string>, attrs: ReadonlyArray<string>) => {
    return attrs.every((attr) => disabledAttributes.includes(attr))
  },
)

export const selectHasTargetAudienceSet = createSelector(selectDraft, (draft: IDraft) => {
  return [draft.audience_age_range, draft.audience_gender, draft.audience_location_codes].some(
    (audience) => !isEmpty(audience),
  )
})

const selectCurrentStepCompleted = createSelector(
  selectDraft,
  (draft: IDraft) => draft.step_completed,
)

export const selectIsCurrentStepCompleted = createSelector(
  selectCurrentStepCompleted,
  (state: IApplicationState, targetStep: BuilderSteps) => targetStep,
  (currentStepCompleted: string, targetStep: BuilderSteps) => currentStepCompleted === targetStep,
)

export const selectHasInstagramChannel = createSelector(selectDraft, (draft: IDraft) => {
  if (!draft.channels) {
    return false
  }

  const hasSelectedHandlesTagsOrUrls = (channel: IChannel) =>
    [channel.handles, channel.tags, channel.urls].every((attr: string[]) => !isEmpty(attr))

  const instagramChannel = draft.channels.find(
    (channel) =>
      channel.name === SubmissionChannels.Instagram && Boolean(hasSelectedHandlesTagsOrUrls),
  )
  return Boolean(instagramChannel)
})

export const selectRequestedSocialSubmissionTypes = createSelector(
  selectDraft,
  selectDraftIsSocial,
  selectHasInstagramChannel,
  (draft: IDraft, isSocial: boolean, hasInstagramChannel: boolean) => {
    if (!isSocial || !hasInstagramChannel) {
      return undefined
    }
    return draft.requested_social_submission_types
  },
)

export const selectDraftUserSubscriptionPricings = createSelector(
  selectUserSubscriptionPricings,
  selectDraftType,
  (pricings, draftType) =>
    pricings?.filter(
      ({ source, submission_type }) =>
        source !== 'content_library' &&
        (submission_type === undefined || submission_type === draftType),
    ) || EMPTY_LIST,
)

export const selectDraftIsActivationFeeWaived = createSelector(
  selectDraft,
  (draft) => draft.activation_fee_waived,
)

export const selectPinterestChannel = createSelector(selectDraft, (draft) =>
  draft.channels?.find((channel) => channel.name === SubmissionChannels.Pinterest),
)
