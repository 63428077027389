import React from 'react'

import { FormattedMessage, FormattedNumber } from 'react-intl'

import InfluencerIdentityRowLabelTooltip from '@components/InfluencerIdentity/Row/LabelTooltip'
import InfluencerIdentityRowValueAndLabel from '@components/InfluencerIdentity/Row/ValueAndLabel'
import { SubmissionChannels } from '@enums'

interface IPerformanceAverageImpressionsProps {
  averageImpressions: number
  provider: SubmissionChannels
}

const BrandFanInfluencerIdentityRowPerformanceAverageImpressions: React.FC<IPerformanceAverageImpressionsProps> = ({
  averageImpressions,
  provider,
}) => {
  return (
    <InfluencerIdentityRowValueAndLabel
      value={
        averageImpressions ? (
          <FormattedNumber value={averageImpressions} />
        ) : (
          <FormattedMessage
            id={
              [
                SubmissionChannels.Instagram,
                SubmissionChannels.Pinterest,
                SubmissionChannels.TikTok,
              ].includes(provider)
                ? 'core.metric.empty'
                : 'core.metric.na'
            }
          />
        )
      }
      label={
        <InfluencerIdentityRowLabelTooltip
          title={<FormattedMessage id="submission.prePurchaseAnalytics.post.avgImpressions" />}
          body={
            <FormattedMessage id="submission.prePurchaseAnalytics.post.avgImpressions.tooltip" />
          }
        >
          <FormattedMessage id="core.text.avgImp" />
        </InfluencerIdentityRowLabelTooltip>
      }
      width={5.5}
    />
  )
}

export {
  BrandFanInfluencerIdentityRowPerformanceAverageImpressions,
  IPerformanceAverageImpressionsProps,
}
export default BrandFanInfluencerIdentityRowPerformanceAverageImpressions
