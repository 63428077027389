import { Fragment } from 'react'

import SubmissionCostBreakdownSocialBalanceDue from '@components/Submission/CostBreakdown/Social/BalanceDue'
import SubmissionCostBreakdownSocialBalancePaid from '@components/Submission/CostBreakdown/Social/BalancePaid'
import SubmissionCostBreakdownSocialBasePrices from '@components/Submission/CostBreakdown/Social/BasePrices'
import SubmissionCostBreakdownSocialTotalPrice from '@components/Submission/CostBreakdown/Social/TotalPrice'
import { ISubmission } from '@store/submissions'
import Theme from '@theme'
import { Container } from '@tribegroup'
import SubmissionCardPriceSummaryHeader from '../../PriceSummary/Header'

interface IProps {
  submission: ISubmission
  hasPriceChangeSincePreApproval?: boolean
}

const SubmissionCardModalApproveCostBreakdown: React.FC<IProps> = ({
  submission,
  hasPriceChangeSincePreApproval,
}) => {
  return (
    <Fragment>
      <SubmissionCardPriceSummaryHeader />
      <SubmissionCostBreakdownSocialBasePrices submission={submission} />
      <Container
        topInnerSpacing={0.75}
        topOuterSpacing={0.75}
        bottomOuterSpacing={0.75}
        borderWidthTop={0.0625}
        borderColor={Theme.grey400Color}
      >
        <SubmissionCostBreakdownSocialTotalPrice submission={submission} showCurrency />
      </Container>
      {Boolean(submission.balance_amount_before_tax_cents) && (
        <Fragment>
          <Container
            topInnerSpacing={0.75}
            topOuterSpacing={0.625}
            bottomOuterSpacing={0.125}
            borderWidthTop={0.0625}
            borderColor={Theme.grey400Color}
          >
            <SubmissionCostBreakdownSocialBalancePaid submission={submission} />
          </Container>
          <SubmissionCostBreakdownSocialBalanceDue
            submission={submission}
            showBalanceAsterisk={hasPriceChangeSincePreApproval}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default SubmissionCardModalApproveCostBreakdown
