import { IResponseError } from '@lib/error'
import { ISubmission, SubmissionsByInfluencerIdentityActionTypes } from '@store/submissions'

export const fetchSubmissionsByInfluencerIdentity = (
  campaignId: number,
  influencerIdentityId: number,
) => ({
  type: SubmissionsByInfluencerIdentityActionTypes.FETCH_REQUEST,
  payload: {
    campaignId,
    influencerIdentityId,
  },
})

export const fetchSubmissionsByInfluencerIdentitySuccess = (
  campaignId: number,
  influencerIdentityId: number,
  submissions: ReadonlyArray<ISubmission>,
  backgroundRefresh: boolean,
) => ({
  type: SubmissionsByInfluencerIdentityActionTypes.FETCH_SUCCESS,
  payload: {
    campaignId,
    influencerIdentityId,
    submissions,
    backgroundRefresh,
  },
})

export const fetchSubmissionsByInfluencerIdentityError = (
  influencerIdentityId: number,
  error: IResponseError,
) => ({
  payload: { influencerIdentityId, error },
  type: SubmissionsByInfluencerIdentityActionTypes.FETCH_ERROR,
})
