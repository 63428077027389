import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Container, Text } from '@tribegroup'

type Props = {
  type: 'prePurchase' | 'postPurchase'
}

export const OffPlatformSubmissionMetricsUnavailableNotice: React.FC<Props> = ({ type }) => {
  return (
    <Container borderColor={Theme.grey400Color} borderWidth={0.0625} borderRadius={0.5}>
      <Container
        innerSpacingX={1}
        innerSpacingY={0.5}
        borderWidthBottom={0.0625}
        borderColor={Theme.grey400Color}
      >
        <Text color={Theme.grey800Color} uppercase block customFontSize={0.8125}>
          <FormattedMessage id="submission.fullView.offPlatform.metrics.header" />
        </Text>
      </Container>
      <Container innerSpacingX={1} innerSpacingY={0.75}>
        <Text color={Theme.grey800Color}>
          <FormattedMessage id={`submission.fullView.offPlatform.metrics.${type}`} />
        </Text>
      </Container>
    </Container>
  )
}
