import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'
import Flex, { FlexItem } from 'styled-flex-component'

import { IApplicationState } from '@store'
import { selectBrandFanGroupMemberCount, selectBrandFanGroupName } from '@store/brandFans'
import Theme from '@theme'
import { H5 } from '@tribegroup'
import { ListItem } from './Popover.styled'

interface IProps {
  groupId: number
}

type IInternalProps = ConnectedProps<typeof connector>

const CampaignBrandFansPopoverInvitedGroup: React.FC<IInternalProps> = ({ name, count }) => {
  return (
    <ListItem>
      <Flex justifyBetween alignStart>
        <H5 color={Theme.defaultColor} rightOuterSpacing={1}>
          {name}
        </H5>
        <FlexItem noShrink>
          <H5 color={Theme.primaryColor}>{count}</H5>
        </FlexItem>
      </Flex>
    </ListItem>
  )
}

const mapStateToProps = (state: IApplicationState, { groupId }: IProps) => ({
  name: selectBrandFanGroupName(state, groupId),
  count: selectBrandFanGroupMemberCount(state, groupId),
})
const connector = connect(mapStateToProps)
export default compose<IInternalProps, IProps>(connector)(CampaignBrandFansPopoverInvitedGroup)
