import { FormattedMessage } from 'react-intl'

import { IMediaObject } from '@store/mediaObjects'
import Theme from '@theme'
import { Container, H1, Text } from '@tribegroup'

export const RequestLicenseHeader = ({ mediaObject }: { mediaObject: IMediaObject }) => {
  const transactedLicense = mediaObject.transacted_license

  if (transactedLicense && ['requested', 'licensed'].includes(transactedLicense.status)) {
    return (
      <>
        <H1 color={Theme.grey900Color}>
          <FormattedMessage id={`licenseBooklet.license.${transactedLicense.status}.header`} />
        </H1>
        <Container topOuterSpacing={0.75}>
          <Text color={Theme.grey900Color} light customFontSize={1.125}>
            <FormattedMessage id={`licenseBooklet.license.${transactedLicense.status}.body`} />
          </Text>
        </Container>
      </>
    )
  }

  return (
    <>
      <H1 color={Theme.grey900Color}>
        <FormattedMessage id="licenseBooklet.requestLicense.header" />
      </H1>
      <Container topOuterSpacing={0.75}>
        <Text
          color={Theme.grey900Color}
          light
          customFontSize={1.125}
          style={{ whiteSpace: 'pre-line' }}
        >
          <FormattedMessage id="licenseBooklet.requestLicense.body" />
        </Text>
      </Container>
    </>
  )
}
