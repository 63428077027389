import React from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { FormattedMessage } from 'react-intl'

import BuilderBriefChannelsSubmissionTypes from '@components/Builder/Brief/Channels/SubmissionTypes'
import { Information } from '@components/UI/Information'
import { INSTAGRAM_POST_TYPES_LEARN_MORE } from '@constants'
import { FeatureToggles, SubmissionChannels } from '@enums'
import Theme from '@theme'
import { Container, H4, Link, Text } from '@tribegroup'
import BuilderBriefChannelsInstagramHeader from '../Instagram/Header'
import BuilderBriefChannelsInstagramSubmissionTypes from '../Instagram/SubmissionTypes'
import BuilderBriefChannelsPinterestHeader from '../Pinterest/Header'
import BuilderBriefChannelsTiktokHeader from '../Tiktok/Header'

const LearnMoreTooltip: React.FC<{ tooltipMessageId: string; url: string }> = ({
  tooltipMessageId,
  url,
}) => {
  return (
    <Container leftOuterSpacing={0.25} inlineBlock verticalAlign="middle">
      <Information width={19.0625} xPosition="right">
        <Text color={Theme.defaultColor} block customFontSize={0.8125}>
          <FormattedMessage id={tooltipMessageId} />
        </Text>
        <Container topOuterSpacing={0.5}>
          <Link target="_blank" href={url}>
            <Text color={Theme.primaryColor}>
              <FormattedMessage id="core.text.learnMore.withArrow" />
            </Text>
          </Link>
        </Container>
      </Information>
    </Container>
  )
}

const SocialPanelBeforeContent: React.FC<{ channel: SubmissionChannels }> = ({ channel }) => {
  const isXPlatformEnabled = useFlag(FeatureToggles.X_SOCIAL_PLATFORM_ENABLED)

  const isInstagram = channel === SubmissionChannels.Instagram
  const isTiktok = channel === SubmissionChannels.TikTok
  const isPinterest = channel === SubmissionChannels.Pinterest
  const isX = channel === SubmissionChannels.X
  const isYoutube = channel === SubmissionChannels.YouTube

  if (isInstagram) {
    return (
      <>
        {isXPlatformEnabled ? (
          <BuilderBriefChannelsSubmissionTypes
            builderChannel={SubmissionChannels.Instagram}
            header={
              <H4 color={Theme.grey900Color}>
                <FormattedMessage id="builder.brief.socialMedia.instagram.header" />
                <LearnMoreTooltip
                  tooltipMessageId="builder.brief.socialMedia.instagram.tooltip"
                  url={INSTAGRAM_POST_TYPES_LEARN_MORE}
                />
              </H4>
            }
          />
        ) : (
          <BuilderBriefChannelsInstagramSubmissionTypes />
        )}
        <BuilderBriefChannelsInstagramHeader />
      </>
    )
  }
  if (isTiktok) {
    return <BuilderBriefChannelsTiktokHeader />
  }

  if (isPinterest) {
    return <BuilderBriefChannelsPinterestHeader />
  }

  if (isX) {
    return (
      <>
        <BuilderBriefChannelsSubmissionTypes
          builderChannel={SubmissionChannels.X}
          header={
            <H4 color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.socialMedia.submissionType.header" />
              <LearnMoreTooltip
                tooltipMessageId="builder.brief.socialMedia.x.tooltip"
                url={INSTAGRAM_POST_TYPES_LEARN_MORE}
              />
            </H4>
          }
        />
        <H4 color={Theme.grey900Color}>
          <FormattedMessage id="builder.brief.socialMedia.tags.header" />
        </H4>
      </>
    )
  }

  if (isYoutube) {
    return (
      <>
        <BuilderBriefChannelsSubmissionTypes builderChannel={SubmissionChannels.YouTube} />
        <H4 color={Theme.grey900Color}>
          <FormattedMessage id="builder.brief.socialMedia.tags.header" />
        </H4>
      </>
    )
  }

  return null
}

export default SocialPanelBeforeContent
