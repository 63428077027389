import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import {
  fetchStates,
  FetchStatesActionTypes,
  fetchStatesError,
  fetchStatesSuccess,
  IState,
  selectCountryStatesLink,
} from '@store/rootResource'
import { networkRequest } from '@utils'

export function* handleFetchStates(action: ReturnType<typeof fetchStates>) {
  try {
    const statesLink: IHateoasLink = yield select(selectCountryStatesLink)
    const states: ReadonlyArray<IState> = yield call(
      networkRequest,
      statesLink.method,
      `${statesLink.href}/${action.payload}`,
    )
    yield put(fetchStatesSuccess(states))
  } catch (error) {
    yield put(fetchStatesError(error))
  }
}

export function* watchFetchStatesRequest() {
  yield takeLatest(FetchStatesActionTypes.FETCH_STATES_REQUEST, handleFetchStates)
}
