import { createSelector } from 'reselect'

import { EMPTY_LIST } from '@constants'
import { SubmissionChannels } from '@enums'
import { IApplicationState, IHateoasLink } from '@store'
import { selectIsFetching } from '@store/licenses'
import { IGender, selectAllGenders } from '@store/rootResource'
import { IIdentityAnalytics } from '@store/submissions'
import { isEmpty } from '@utils'
import { IInfluencerIdentity } from './types'

export const selectinfluencerIdentitiesByCampaignId = (
  state: IApplicationState,
  campaignId: number,
) => state.influencerIdentities.influencerIdentitiesByCampaignId[campaignId] || EMPTY_LIST

export const selectInfluencerIdentityIdsByCampaignId = createSelector(
  selectinfluencerIdentitiesByCampaignId,
  (influencerIdentities) => influencerIdentities.map(({ id }) => id) || EMPTY_LIST,
)

export const selectHasInfluencerIdentities = createSelector(
  selectIsFetching,
  selectinfluencerIdentitiesByCampaignId,
  (isFetching: boolean, influencerIdentities: ReadonlyArray<IInfluencerIdentity>) =>
    !isFetching && !isEmpty(influencerIdentities),
)

export const selectInfluencerIdentitiesIsFetching = (state: IApplicationState) => {
  return state.influencerIdentities.isFetching
}

export const selectInfluencerIdentitiesIsFetched = createSelector(
  selectinfluencerIdentitiesByCampaignId,
  (influencerIdentities) => {
    return !isEmpty(influencerIdentities)
  },
)

const selectinfluencerIdentitiesByCampaignIdIsFetched = createSelector(
  selectinfluencerIdentitiesByCampaignId,
  (influencerIdentities) => !isEmpty(influencerIdentities),
)

const selectInfluencerIdentitiesLinks = (
  state: IApplicationState,
  campaignId: number,
  linkRel: string,
) => {
  const influencerIdentityLinksByCampaignId =
    state.influencerIdentities.influencerIdentityLinksByCampaignId[campaignId] || EMPTY_LIST
  return influencerIdentityLinksByCampaignId.find((link) => link.rel === linkRel)
}

export const selectInfluencerIdentitiesIsInitialFetch = createSelector(
  selectInfluencerIdentitiesIsFetching,
  selectinfluencerIdentitiesByCampaignIdIsFetched,
  (isFetching, isFetched) => {
    return isFetching && !isFetched
  },
)

export const selectInfluencerIdentitiesNextLink = (state: IApplicationState, campaignId: number) =>
  selectInfluencerIdentitiesLinks(state, campaignId, 'next')

export const selectInfluencerIdentitiesHasNextLink = createSelector(
  selectInfluencerIdentitiesNextLink,
  (influencerLink) => Boolean(influencerLink),
)

export const selectInfluencerIdentity = createSelector(
  selectinfluencerIdentitiesByCampaignId,
  (state, campaignId, influencerIdentityId) => influencerIdentityId,
  (
    influencerIdentitiesByCampaignId: ReadonlyArray<IInfluencerIdentity>,
    influencerIdentityId: number,
  ) =>
    influencerIdentitiesByCampaignId.find(
      (influencer: IInfluencerIdentity) => influencer.id === influencerIdentityId,
    ) || ({} as IInfluencerIdentity),
)

const selectInfluencerIdentityLinks = createSelector(
  selectInfluencerIdentity,
  (influencer: IInfluencerIdentity) => influencer.links || EMPTY_LIST,
)

export const selectSubmissionsByInfluencerIdentityLink = createSelector(
  selectInfluencerIdentityLinks,
  (links: ReadonlyArray<IHateoasLink>) => links.find((link) => link.rel === 'submissions'),
)

export const selectCreatorBioByInfluencerIdentityLink = createSelector(
  selectInfluencerIdentityLinks,
  (links: ReadonlyArray<IHateoasLink>) => links.find((link) => link.rel === 'creator_bio'),
)

export const selectInfluencerIdentityLink = createSelector(
  selectInfluencerIdentityLinks,
  (links: ReadonlyArray<IHateoasLink>) => links.find((link) => link.rel === 'self'),
)

export const selectInfluencerIdentityAvatar = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_avatar,
)

export const selectInfluencerIdentitySocialPageUrl = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_social_page_url,
)

export const selectInfluencerIdentityFullName = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.influencer_full_name,
)

export const selectIsInfluencerIdentityPreviouslyPurchased = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.previously_purchased,
)

export const selectInfluencerIdentityAge = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.influencer_age,
)

export const selectInfluencerIdentityPlatformProvider = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_provider as SubmissionChannels,
)

export const selectInfluencerIdentityPlatformUsername = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_username,
)

export const selectIsInfluencerIdentityNew = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.influencer_new,
)

export const selectInfluencerIsProxy = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.influencer_is_proxy,
)

export const selectInfluencerIdentityAnalytics = createSelector(
  selectInfluencerIdentity,
  (influencer) => (influencer.identity_analytics || {}) as IIdentityAnalytics,
)

export const selectHasInfluencerIdentityAnalytics = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_audience_insights_available,
)

export const selectInfluencerIdentityFormattedLocationAndGender = createSelector(
  selectInfluencerIdentity,
  selectAllGenders,
  (influencer, allGenders) => {
    const location = [influencer.influencer_state, influencer.influencer_country]
      .filter(Boolean)
      .join(', ')

    const selectedGender = allGenders.find(
      (gender?: IGender) => gender?.code === influencer.influencer_gender,
    )

    const genderDisplay = selectedGender?.display_name

    if (!genderDisplay) {
      return location
    }

    return `${location}, ${genderDisplay}`
  },
)

export const selectInfluencerIdentityAverageEngagementPercent = createSelector(
  selectInfluencerIdentityAnalytics,
  (analytics) => analytics.avg_engagement_pct,
)

export const selectInfluencerIdentityTotalLikes = createSelector(
  selectInfluencerIdentityAnalytics,
  (analytics) => analytics.likes,
)

export const selectInfluencerIdentityFollowerCount = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_followers_count,
)

export const selectInfluencerIdentityPostCount = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_post_count,
)

export const selectInfluencerIdentityHasPosts = createSelector(
  selectInfluencerIdentityPostCount,
  (count) => Boolean(count),
)

export const selectInfluencerIdentityHasShortlisted = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_any_shortlisted_submissions,
)

export const selectInfluencerIdentityHasFeedback = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_has_feedback,
)

export const selectInfluencerIdentityHasUnreadFeedback = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_has_unread_feedback,
)

export const selectInfluencerIdentityEstimatedCPE = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_estimated_cpe,
)

export const selectInfluencerIdentityEstimatedCPECurrency = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_estimated_cpe_currency,
)

export const selectInfluencerIdentityEstimatedCPEIsAvarage = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_estimated_cpe_average,
)

export const selectInfluencerIdentityBrandFanGroupIds = createSelector(
  selectInfluencerIdentity,
  (influencer) => influencer.identity_brand_fan_group_ids || EMPTY_LIST,
)

export const selectInfluencerIdentityHasGroup = createSelector(
  selectInfluencerIdentityBrandFanGroupIds,
  (identityBrandFanGroupIds) => identityBrandFanGroupIds.length > 0,
)
