import { createReducer } from '@reduxjs/toolkit'

import { AuthActionTypes } from '@store/auth'
import { addFeedbackDraft, removeFeedbackDraft } from './actions'
import { IFeedbackDraftState } from './types'

const initialState: IFeedbackDraftState = {
  feedbackDraftBySubmissionId: {},
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addFeedbackDraft, (state, { payload }) => {
      const { submissionId, message } = payload
      state.feedbackDraftBySubmissionId = {
        ...state.feedbackDraftBySubmissionId,
        [submissionId]: message,
      }
    })
    .addCase(removeFeedbackDraft, (state, { payload }) => {
      const { submissionId } = payload
      delete state.feedbackDraftBySubmissionId[submissionId]
    })
    .addMatcher(
      (action) => action.type === AuthActionTypes.SIGNOUT_SUCCESS,
      () => initialState,
    )
})

export { reducer as feedbackDraftReducer }
