import React from 'react'

const StopPropagation: React.FC<React.PropsWithChildren> = ({ children }) => {
  const onClick = (event: React.SyntheticEvent) => event.stopPropagation()
  return (
    <span onClick={onClick} role="button">
      {children}
    </span>
  )
}

export { StopPropagation }
export default StopPropagation
