import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectDraft, selectIsInDraftStatus } from '@store/drafts'
import { isEmpty } from '@utils'
import BuilderTitleBarActionsButtonPreview from './Buttons/Preview'
import BuilderActionsStyled from './Actions.styled'
import BuilderTitleBarCampaignActions from './CampaignActions'
import BuilderTitleBarDraftActions from './DraftActions'

type IInternalProps = ConnectedProps<typeof connector>

const BuilderTitleBarActions: React.FC<IInternalProps> = ({ showDraftActions, canPreview }) => (
  <BuilderActionsStyled alignCenter justifyEnd>
    {canPreview && <BuilderTitleBarActionsButtonPreview />}
    {showDraftActions ? <BuilderTitleBarDraftActions /> : <BuilderTitleBarCampaignActions />}
  </BuilderActionsStyled>
)

const mapStateToProps = (state: IApplicationState) => {
  const draft = selectDraft(state)
  return {
    showDraftActions: selectIsInDraftStatus(state),
    canPreview: !isEmpty(draft),
  }
}

const connector = connect(mapStateToProps)

export default compose(connector)(BuilderTitleBarActions)
