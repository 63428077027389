import React from 'react'

import Theme from '@theme'
import { Icon } from '@tribegroup'
import { StarContainer } from './CreatorGroup.styled'

const StarIcon: React.FC = () => (
  <StarContainer>
    <Icon glyph="star-rating" color={Theme.primaryColor} size={1.25} />
  </StarContainer>
)

export { StarIcon }
export default StarIcon
