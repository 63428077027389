import React, { PropsWithChildren } from 'react'

import Flex from 'styled-flex-component'

import { Hr } from '@components/UI/Hr/Hr.styled'
import Theme from '@theme'
import { Container } from '@tribegroup'
import BuilderBudgetTargetBeta from './Beta'

interface IProps {
  textWidth: number
  beta?: boolean
}

const BuilderBudgetTargetHeadline: React.FC<PropsWithChildren<IProps>> = ({
  textWidth,
  beta = false,
  children,
}) => (
  <Container relative topOuterSpacing={3.75} bottomOuterSpacing={3.75}>
    <Container absolute fullWidth topPosition={1.125}>
      {beta && <BuilderBudgetTargetBeta />}
      <Hr backgroundColor={Theme.grey600Color} />
    </Container>
    <Flex center full>
      <Container
        width={textWidth}
        textAlign="center"
        leftInnerSpacing={3}
        rightInnerSpacing={3}
        zIndex={1}
        backgroundColor={Theme.grey100Color}
      >
        {children}
      </Container>
    </Flex>
  </Container>
)

export default BuilderBudgetTargetHeadline
