import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'recompose'

import AnalyticsPostCampaignReportGlossary from '@components/Analytics/PostCampaignReport/Glossary'
import { Hr } from '@components/UI/Hr/Hr.styled'
import Loading from '@components/UI/Loading'
import { NoticeUnsupportedScreen } from '@components/UI/Notice'
import { SupportedScreenWrapper } from '@components/UI/ScreenSize'
import { IApplicationState } from '@store'
import {
  selectIsSummaryActive,
  selectShowCampaignMetricsLoadingScreen,
  selectShowGlossaryButton,
  selectShowSubmissionList,
} from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Container } from '@tribegroup'
import PostCampaignReportChannelList from './Channel/List/List'
import PostCampaignReporPerformanceListWrapper from './Performance/List/Wrapper/Wrapper'
import PostCampaignReportPerformanceTitle from './Performance/Title/Title'
import { PostCampaignReportProxySubmissionEducationText } from './ProxySubmissionEducation/Text'
import PostCampaignReportSubmissionListWrapper from './Submission/List/Wrapper/Wrapper'
import PostCampaignReportTitle from './Title/Title'

export interface IInternalProps {
  showPerformanceTitle?: boolean
  showLoadingScreen?: boolean
  showSubmissionList?: boolean
  showGlossaryButton?: boolean
}

const ScreenLayoutWrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <>
    <NoticeUnsupportedScreen
      alt="influencer-view-unsupported-screen"
      title={<FormattedMessage id="postCampaignReport.unsupportedScreen.title" />}
      subtitle={<FormattedMessage id="postCampaignReport.unsupportedScreen.subtitle" />}
    />
    <SupportedScreenWrapper>{children}</SupportedScreenWrapper>
  </>
)

const PostCampaignReport: React.FC<IInternalProps> = ({
  showPerformanceTitle,
  showLoadingScreen,
  showSubmissionList,
  showGlossaryButton,
}) => {
  if (showLoadingScreen) {
    return <Loading />
  }

  return (
    <ScreenLayoutWrapper>
      <PostCampaignReportTitle />
      <Container topInnerSpacing={2.5}>
        <PostCampaignReportChannelList />
      </Container>
      <Container topInnerSpacing={1.25} bottomInnerSpacing={0.5}>
        <Hr backgroundColor={Theme.primaryColor} />
      </Container>
      {showPerformanceTitle && <PostCampaignReportPerformanceTitle />}
      <Container topOuterSpacing={2}>
        <PostCampaignReporPerformanceListWrapper />
      </Container>
      <PostCampaignReportProxySubmissionEducationText topOuterSpacing={0.375} />
      {showSubmissionList && <PostCampaignReportSubmissionListWrapper />}
      {showGlossaryButton && <AnalyticsPostCampaignReportGlossary />}
    </ScreenLayoutWrapper>
  )
}

const mapStateToProps = (state: IApplicationState, { match }) => {
  const campaignId = selectCampaignId(match)!

  return {
    showPerformanceTitle: selectIsSummaryActive(state),
    showLoadingScreen: selectShowCampaignMetricsLoadingScreen(state, campaignId),
    showSubmissionList: selectShowSubmissionList(state),
    showGlossaryButton: selectShowGlossaryButton(state),
  }
}

export { PostCampaignReport }
export default compose<IInternalProps, {}>(withRouter, connect(mapStateToProps))(PostCampaignReport)
