import { createAction } from '@reduxjs/toolkit'

import { CompleteSignUpActionType, CompleteSignupFormDataType } from '@store/completeSignup/types'

export const completeSignUpRequest = createAction<CompleteSignupFormDataType>(
  CompleteSignUpActionType.PENDING,
)

export const completeSignUpSuccess = createAction(CompleteSignUpActionType.FULFILLED)

export const completeSignUpFailed = createAction<any>(CompleteSignUpActionType.FAILED)
