import { AudienceReportingActionTypes, IAudienceReporting } from './types'

export const fetchAudienceReporting = (campaignId: number) => ({
  type: AudienceReportingActionTypes.FETCH_REQUEST,
  payload: { campaignId },
})

export const fetchAudienceReportingSuccess = (
  campaignId: number,
  audienceReporting: IAudienceReporting,
) => ({
  type: AudienceReportingActionTypes.FETCH_SUCCESS,
  payload: { campaignId, audienceReporting },
})

export const fetchAudienceReportingError = (error: any) => ({
  type: AudienceReportingActionTypes.FETCH_ERROR,
  payload: error,
})
