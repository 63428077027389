import React, { Fragment, useContext } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { compose } from 'recompose'

import SubmissionCardModalApproveWrapper from '@components/Submission/Card/Modal/Approve/Wrapper'
import SubmissionCardModalPreApproval from '@components/Submission/Card/Modal/PreApproval'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { IModalContextProps } from '@context/Modal'
import { PopupMenuContext, PopupMenuItem } from '@context/PopupMenu'
import { EventTrackingNames } from '@enums'
import { withModal } from '@hocs'
import { IConnectedSocialAccount } from '@store/campaigns'
import { clearErrors } from '@store/submissions'
import { Text } from '@tribegroup'
import { IApproveModalProps } from './Approve'
import SubmissionCardModalTikTokPreApprovalConfirmation from './TikTokPreApprovalConfirmation'

interface IProps {
  modalProps: IApproveModalProps
  shouldShowTikTokPreApprovePrompt: boolean
  tiktokSocialAccount: IConnectedSocialAccount | undefined
}

interface IInternalProps extends IProps, IModalContextProps {}

const ActionBarIconApprovePopupMenu: React.FC<IInternalProps> = ({
  modalProps,
  showModal,
  hideModal,
  shouldShowTikTokPreApprovePrompt,
  tiktokSocialAccount,
}) => {
  const { hidePopup } = useContext(PopupMenuContext)
  const { submission, campaignId } = modalProps
  const dispatch = useDispatch()

  const showConfirmationModal = (isPreApproval = false) => {
    hidePopup()
    showModal(SubmissionCardModalTikTokPreApprovalConfirmation, {
      isOpen: true,
      tiktokSocialAccount,
      onCancel: hideModal,
      onProceed: () => {
        hidePopup()
        if (isPreApproval) {
          showModal(SubmissionCardModalPreApproval, {
            submissionId: submission.id,
            campaignId,
          })
          return
        }
        showModal(SubmissionCardModalApproveWrapper, modalProps)
      },
    })
    return
  }

  const finalApprove = () => {
    if (shouldShowTikTokPreApprovePrompt) {
      return showConfirmationModal()
    }
    hidePopup()
    showModal(SubmissionCardModalApproveWrapper, modalProps)
  }

  const preApprove = () => {
    if (shouldShowTikTokPreApprovePrompt) {
      return showConfirmationModal(true)
    }
    hidePopup()
    dispatch(clearErrors())

    showModal(SubmissionCardModalPreApproval, {
      submissionId: submission.id,
      campaignId,
    })
  }

  return (
    <Fragment>
      <PopupMenuItem onClick={finalApprove}>
        <Text xsmall color="inherit" uppercase>
          <FormattedMessage id="submission.card.action.menu.finalApproval" />
        </Text>
      </PopupMenuItem>
      <PopupMenuItem onClick={preApprove}>
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.ViewPreApproveModal}
          submissionId={submission.id}
          campaignId={campaignId}
        >
          <Text xsmall color="inherit" uppercase>
            <FormattedMessage id="submission.card.action.menu.preApprove" />
          </Text>
        </ConnectedTrackedRoute>
      </PopupMenuItem>
    </Fragment>
  )
}

export default compose<IInternalProps, IProps>(withModal)(ActionBarIconApprovePopupMenu)
