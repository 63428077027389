import { IFeatureHateoasLink, IHateoasLink } from '@store'
import { IFeatureLockedMetricsSummary } from '../types'

export enum CampaignMetricsActionTypes {
  FETCH_REQUEST = 'pcr/campaignMetrics/FETCH_REQUEST',
  FETCH_SUCCESS = 'pcr/campaignMetrics/FETCH_SUCCESS',
  FETCH_ERROR = 'pcr/campaignMetrics/FETCH_ERROR',
}

export interface ISubmissionsPendingData {
  posts: number
  videos: number
  stories: number
  tiktok: number
  reels?: number
}

export interface ICampaignMetrics extends IFeatureLockedMetricsSummary {
  brief_id: number
  data_pending_submissions_count: ISubmissionsPendingData
  instagram_posts_count: number
  instagram_videos_count: number
  instagram_stories_count: number
  instagram_reels_count: number
  facebook_posts_count: number
  twitter_posts_count: number
  tiktok_count: number
  first_submission_published_date: string
  last_submission_published_date: string
  submissions_count: number
  unique_influencers: number
  total_amount: number
  total_amount_currency: string
  total_social_reach: number
  total_engagement: number
  total_engagement_pct: number
  avg_engagement_rate: number
  total_cost_per_engagement: number
  total_cost_per_engagement_currency: string
  is_ready_for_download?: boolean
  links: ReadonlyArray<IHateoasLink | IFeatureHateoasLink>
}

export interface ICampaignMetricsState {
  isFetching: boolean
  errors?: object | string
  campaignMetricsById: Record<number, ICampaignMetrics>
}
