import { FormattedMessage } from 'react-intl'

import StopPropagation from '@components/UI/StopPropagation/StopPropagation'
import { PageRoutes } from '@enums'
import Theme from '@theme'
import { Link } from '@tribegroup'
import { Container, Text } from '@tribegroup'

interface IProps {
  href: string
  method: string
}

export const InfluencerIdentityRowViewBio = ({ href, method }: IProps) => {
  const params = new URLSearchParams()
  params.set('href', encodeURI(href))
  params.set('method', method)

  return (
    <StopPropagation>
      <Container
        color={Theme.primaryColor}
        borderRadius={0.25}
        borderColor={Theme.primary100Color}
        borderWidth={0.0625}
        borderStyle="solid"
        hoverOpacity={75}
        innerSpacingX={0.25}
        bottomInnerSpacing={0.125}
        inline
      >
        <Link
          href={`/${PageRoutes.CreatorBioPage}?${params.toString()}`}
          color={Theme.primaryColor}
          target="_blank"
        >
          <Text uppercase light xxsmall verticalAlign="middle">
            <FormattedMessage id="core.text.viewBio" />
          </Text>
        </Link>
      </Container>
    </StopPropagation>
  )
}
