import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { BetaLabel, Container, H4 } from '@tribegroup'

const ConnectedAccountsHeadline = () => {
  return (
    <>
      <Container topOuterSpacing={1}>
        <Flex wrap="wrap" alignCenter>
          <H4 light color={Theme.defaultColor} rightOuterSpacing={0.5}>
            <FormattedMessage id="connectedAccounts.connectedBusinessAccounts" />
          </H4>
          <BetaLabel />
        </Flex>
      </Container>
      <Container topOuterSpacing={1.875} maxWidth={45.375} fullWidth>
        <H4 light color={Theme.defaultColor} className="education">
          <FormattedHTMLMessage id="connectedAccounts.creatorAds.education.connectAccount" />
        </H4>
      </Container>
    </>
  )
}

export default ConnectedAccountsHeadline
