import { FormattedHTMLMessage } from 'react-intl'

import { Container, Icon } from '@tribegroup'

interface IProps {
  i18nKey: string
  values?: Record<string, any>
}

const ToastTemplateTicked: React.FC<IProps> = ({ i18nKey, values }) => (
  <Container flexStart alignItems="flex-start">
    <Container noShrink topOuterSpacing={0.125}>
      <FormattedHTMLMessage id={i18nKey} values={values} />
    </Container>
    <Container leftOuterSpacing={1}>
      <Icon glyph="tick" />
    </Container>
  </Container>
)

export default ToastTemplateTicked
