import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { TrackedRoute } from '@components/UI/TrackedRoute'
import { IModalContextProps } from '@context/Modal'
import { ContentLibraryFilterTypes, EventTrackingNames } from '@enums'
import { withModal } from '@hocs'
import { IApplicationState } from '@store'
import { selectMediaObjectsStatsTotal } from '@store/mediaObjects'
import { Button, Header } from '@tribegroup'
import ShareThisLibraryModal from './Modal'

interface IProps {
  fullWidth?: boolean
}

interface IInternalProps extends IProps, IModalContextProps {
  librarySize: number
}

const ShareThisLibraryButton: React.FC<IInternalProps> = ({
  fullWidth,
  showModal,
  librarySize,
}) => {
  const onClick = () => {
    showModal(ShareThisLibraryModal)
  }

  return (
    <TrackedRoute
      eventName={EventTrackingNames.ShareCLButton}
      properties={{ library_size: librarySize }}
    >
      <Button
        onClick={onClick}
        scheme="primary"
        outlined
        small
        noFocus
        leftInnerSpacing={1.0875}
        rightInnerSpacing={1.0875}
        fullWidth={fullWidth}
      >
        <Header cta small>
          <FormattedMessage id="contentLibrary.shareable.shareThis" />
        </Header>
      </Button>
    </TrackedRoute>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  librarySize: selectMediaObjectsStatsTotal(state, ContentLibraryFilterTypes.DISCOVER),
})

export { ShareThisLibraryButton }
export default compose<IInternalProps, IProps>(
  withModal,
  connect(mapStateToProps),
)(ShareThisLibraryButton)
