import { useEffect } from 'react'

import OutsideClickHandler from 'react-outside-click-handler'
import { useDispatch, useSelector } from 'react-redux'

import Breakpoint from '@components/UI/Breakpoint'
import { EventTrackingNames } from '@enums'
import { useBooleanToggle } from '@hooks/useBooleanToggle'
import { useModal } from '@hooks/useModal'
import { IApplicationState } from '@store'
import { trackEvent } from '@store/analytics'
import {
  fetchPurchaseOrders,
  selectHasPurchaseOrders,
  selectIsFetching,
  selectPurchaseOrderMetadata,
  selectPurchaseOrders,
} from '@store/purchaseOrders'
import { CloseIcon, Container, Modal } from '@tribegroup'
import { Tip } from '@tribegroup/Tip/Tip'
import { AvailableCreditTooltipContent } from './Tooltip/Content'
import { Wrapper } from './AvailableCredit.styled'
import { AvailableCreditPanel } from './Panel'

const AvailableCreditMobileModal = () => {
  const { isOpen, hideModal } = useModal()

  return (
    <Modal reactModalProps={{ isOpen: Boolean(isOpen) }} width={33.75} complex>
      <CloseIcon onClick={hideModal} />
      <Container>
        <AvailableCreditTooltipContent />
      </Container>
    </Modal>
  )
}

const AvailableCredit = () => {
  const dispatch = useDispatch()
  const { showModal } = useModal()

  const { hasPurchaseOrders, metadata } = useSelector((state: IApplicationState) => {
    return {
      purchaseOrders: selectPurchaseOrders(state),
      hasPurchaseOrders: selectHasPurchaseOrders(state),
      metadata: selectPurchaseOrderMetadata(state),
      isFetching: selectIsFetching(state),
    }
  })
  const [isTooltipVisible, toggleIsTooltipVisible] = useBooleanToggle(false)

  useEffect(() => {
    if (!hasPurchaseOrders) {
      dispatch(fetchPurchaseOrders())
    }
  }, [])

  const total_amount_remaining_cents = metadata?.total_amount_remaining_cents ?? 0
  const total_amount_remaining_currency = metadata?.total_amount_remaining_currency ?? 'AUD'

  const onClick = () => {
    toggleIsTooltipVisible()
    dispatch(trackEvent(EventTrackingNames.ViewContentLibraryBudgetPanelTooltip, {}))
  }

  const onOutsideClick = () => {
    toggleIsTooltipVisible(false)
  }

  const onMobileClick = () => {
    showModal(AvailableCreditMobileModal)
  }

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <Breakpoint tabletLandscape desktop>
        <Wrapper onClick={onClick}>
          <Tip
            text={
              <AvailableCreditPanel
                amount={total_amount_remaining_cents}
                currency={total_amount_remaining_currency}
              />
            }
            openCoachmarkOnRender={isTooltipVisible}
            coachmark={{
              content: <AvailableCreditTooltipContent />,
              position: 'right',
              width: 26.375,
              yOffset: 2,
            }}
            hideIcon
          />
        </Wrapper>
      </Breakpoint>
      <Breakpoint tabletPortrait mobile>
        <Wrapper onClick={onMobileClick}>
          <AvailableCreditPanel
            amount={total_amount_remaining_cents}
            currency={total_amount_remaining_currency}
          />
        </Wrapper>
      </Breakpoint>
    </OutsideClickHandler>
  )
}

export default AvailableCredit
