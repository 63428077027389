import { Fragment } from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectLicenseDescription, selectLicenseLabel } from '@store/mediaObjects'
import { Radio, SelectionSubtext } from '@tribegroup'

interface ILicenseOptionSelectedProps {
  mediaObjectId: number
  backgroundColor?: string
  clearBorder?: boolean
}

interface IInternalProps extends ILicenseOptionSelectedProps {
  licenseLabel: string
  licenseDescription: string
}

export const LicenseOptionSelected = ({
  licenseLabel,
  licenseDescription,
  backgroundColor,
  clearBorder = false,
}: IInternalProps) => (
  <Fragment>
    <Radio
      disablePointerOnHover
      showSelectedAsCheck
      scheme="primary"
      label={licenseLabel}
      labelCapitalize
      backgroundColor={backgroundColor}
      clearBorder={clearBorder}
      defaultChecked
    />
    <SelectionSubtext>{licenseDescription}</SelectionSubtext>
  </Fragment>
)

const mapStateToProps = (state: IApplicationState, { mediaObjectId }: IInternalProps) => ({
  licenseLabel: selectLicenseLabel(state, mediaObjectId),
  licenseDescription: selectLicenseDescription(state, mediaObjectId),
})

export default compose<IInternalProps, ILicenseOptionSelectedProps>(connect(mapStateToProps))(
  LicenseOptionSelected,
)
