import { SubmitErrorMessageWrapper } from '@components/Builder/Layout/Layout.styled'
import Theme from '@theme'
import { Text } from '@tribegroup'

export const SubmitErrorMessage = ({ results, errorKey }) => {
  if (results[errorKey] && results[errorKey].hasError) {
    return (
      <SubmitErrorMessageWrapper>
        <Text small color={Theme.errorColor}>
          {results[errorKey].errorMessage}
        </Text>
      </SubmitErrorMessageWrapper>
    )
  }
  return null
}

export const hasValidationError = (results, errorKey) =>
  results[errorKey] && results[errorKey].hasError
