import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { AnalyticsPostCampaignReportGlossaryEntry } from '@components/Analytics/PostCampaignReport/Glossary'
import { CPM_EXPLAINED } from '@constants'
import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { selectCampaignOperatingRegionTaxName } from '@store/campaigns'
import { selectStoriesTotalSpend } from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Link } from '@tribegroup'

type IInternalProps = ConnectedProps<typeof connector>

const AnalyticsPostCampaignReportGlossaryStory: React.FC<IInternalProps> = ({ taxName }) => (
  <React.Fragment>
    <AnalyticsPostCampaignReportGlossaryEntry translationKey="story.published" />
    <AnalyticsPostCampaignReportGlossaryEntry
      translationKey="story.spend"
      values={{
        taxDisplay: taxName,
      }}
    />
    <AnalyticsPostCampaignReportGlossaryEntry translationKey="story.followers" />

    <AnalyticsPostCampaignReportGlossaryEntry translationKey="story.reach" />
    <AnalyticsPostCampaignReportGlossaryEntry translationKey="story.reachPercentage" />
    <AnalyticsPostCampaignReportGlossaryEntry translationKey="story.impressions" />
    <AnalyticsPostCampaignReportGlossaryEntry
      translationKey="story.cpm"
      values={{
        link: (
          <Link href={CPM_EXPLAINED} target="_blank" color={Theme.primaryColor} lowercase>
            <FormattedMessage id="core.text.clickHere" />
          </Link>
        ),
      }}
    />
  </React.Fragment>
)

const mapStateToProps = (
  state: IApplicationState,
  { intl, match }: RouteComponentProps & InjectedIntlProps,
) => {
  const campaignId = selectCampaignId(match)!

  const currency = selectStoriesTotalSpend(state, campaignId, PCRChannels.IGStories).currency

  return {
    taxName: selectCampaignOperatingRegionTaxName(state, campaignId, intl, currency),
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(
  withRouter,
  injectIntl,
  connector,
)(AnalyticsPostCampaignReportGlossaryStory)
