import styled from 'styled-components'

const NoticeImage = styled.img`
  ${({ width, height }) => `
    width: ${width}rem;
    height: ${height}rem;
  `};
`

export { NoticeImage }
