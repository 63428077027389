import { FacebookMediaTransferActionTypes, IMediaTransferRequestParams } from '@store/facebookAds'

export const mediaTransferRequest = (adAccountId: string, params: IMediaTransferRequestParams) => ({
  payload: { adAccountId, params },
  type: FacebookMediaTransferActionTypes.MEDIA_TRANSFER_REQUEST,
})

export const mediaTransferSuccess = () => ({
  type: FacebookMediaTransferActionTypes.MEDIA_TRANSFER_SUCCESS,
})

export const mediaTransferError = (error: any) => ({
  payload: error,
  type: FacebookMediaTransferActionTypes.MEDIA_TRANSFER_ERROR,
})
