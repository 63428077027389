import React from 'react'

import Flex from 'styled-flex-component'

import { SignUpCallToAction } from '@components/ShareableContentLibrary/Buttons'
import Breakpoint from '@components/UI/Breakpoint'
import TitleBarStyled, { HeaderContent } from '@components/UI/TitleBar/TitleBar.styled'
import TribeOnly from '@components/UI/TribeOnly'
import { Container } from '@tribegroup'
import ShareableContentLibraryTitleBarLogo from './Logo'

const ShareableContentLibraryListTitleBar: React.FC = () => (
  <TitleBarStyled>
    <Flex justifyBetween full alignCenter>
      <ShareableContentLibraryTitleBarLogo />
      <TribeOnly>
        <Breakpoint mobile>
          <Container relative leftOuterSpacing={-3.125} width="100vw">
            <Flex justifyCenter full>
              <SignUpCallToAction outlined />
            </Flex>
          </Container>
        </Breakpoint>
        <HeaderContent>
          <Breakpoint tabletPortrait tabletLandscape desktop>
            <SignUpCallToAction />
          </Breakpoint>
        </HeaderContent>
      </TribeOnly>
    </Flex>
  </TitleBarStyled>
)

export { ShareableContentLibraryListTitleBar }
export default ShareableContentLibraryListTitleBar
