import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'

export const TabContent = styled.div`
  height: 12.25rem;
  overflow: scroll;
`

export const TabHeader = styled(Flex)`
  margin-bottom: 1.5rem;
  margin-left: -1.125rem;
`

export const TabItem = styled.a`
  display: block;
  padding: 0.375rem;
  margin-left: 1.125rem;
  text-align: center;
  width: 100%;
  text-decoration: none;
  position: relative;
  z-index: 1;
  ${({ active }) => css`
    &::before {
      content: ' ';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      border-radius: 0.5rem;
      ${active
        ? css`
            background-color: ${Theme.primaryColor};
            opacity: 0.07;
          `
        : css`
            background-color: ${Theme.grey100Color};
          `};
    }
  `};
`

export const TabLineItem = styled.li`
  display: flex;
  text-decoration: none;
  cursor: pointer;
  padding: 0.3125rem;
  border-radius: 0.5rem;

  &:hover {
    background: ${Theme.grey200Color};
  }
`

export const TabText = styled.span`
  margin-left: 0.75rem;
  display: inline-block;
  width: calc(100% - 2rem);
`

export default styled.div`
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
`
