import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { useDrawer } from '@hooks/useDrawer'
import SubmissionChat from '..'

export const PAGE_LOAD_OPEN_CHAT_QUERY_PARAM = 'openChat'

interface IProps {
  submissionId: number
  campaignId: number
}

const SubmissionChatOpenOnLoadWatcher: React.FC<IProps> = ({ submissionId, campaignId }) => {
  const location = useLocation()
  const history = useHistory()
  const { showDrawer } = useDrawer()

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.get(PAGE_LOAD_OPEN_CHAT_QUERY_PARAM)) {
      queryParams.delete(PAGE_LOAD_OPEN_CHAT_QUERY_PARAM)
      history.replace({
        search: queryParams.toString(),
      })
      showDrawer(<SubmissionChat submissionId={submissionId} campaignId={campaignId} />, {
        showHeaderDivider: true,
      })
    }
  }, [])

  return null
}

export default SubmissionChatOpenOnLoadWatcher
