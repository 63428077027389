import styled from 'styled-components'

import Theme from '@theme'

export const SectionHeader = styled.div`
  padding: 1rem 0;
  border-top: solid 1px #000;
  color: ${Theme.defaultColor};
`

export const Section = styled.div`
  display: grid;
  grid-template-columns: 10.5rem auto;
  grid-gap: 2rem;
  margin-bottom: 0.75rem;
`

export const MetricHeaderTable = styled.table`
  margin-bottom: 0.75rem;
  width: 100%;

  td {
    padding-left: 1rem;
    color: ${Theme.grey800Color};
  }
`

export const MetricTable = styled.table`
  td {
    border: 1px solid ${Theme.grey400Color};
    padding: 1rem 1.5rem 1rem 1rem;
    color: ${Theme.defaultColor};
  }

  tr:nth-child(even) {
    background-color: ${Theme.grey100Color};
  }

  tr:nth-child(odd) {
    background-color: ${Theme.whiteColor};
  }
`
