import { BuilderSteps } from '@enums'
import { IMeta } from '@store'
import { ICampaign, SaveCampaignActionTypes } from '@store/campaigns'

export const saveCampaign = (stepCompleted?: BuilderSteps, meta?: IMeta) => ({
  payload: stepCompleted,
  type: SaveCampaignActionTypes.SAVE_REQUEST,
  meta,
})

export const saveCampaignSuccess = (campaign: ICampaign, meta?: IMeta) => ({
  payload: campaign,
  meta,
  type: SaveCampaignActionTypes.SAVE_SUCCESS,
})

export const saveCampaignError = (error: any) => ({
  payload: error,
  type: SaveCampaignActionTypes.SAVE_ERROR,
})
