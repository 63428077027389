import { useHistory } from 'react-router-dom'

import { IMeta } from '@store'
import {
  AuthActionTypes,
  AutoSignInActionTypes,
  IOnboarding,
  IPActionTypes,
  ISavedSignUpForm,
  SignUpFormData,
} from '@store/auth'

type History = ReturnType<typeof useHistory>

export interface ISignInParams {
  email: string
  password: string
}

export interface ISignUpParams extends SignUpFormData {
  company_size?: string
  company_type?: string
}

export interface IEmailVerificationParams {
  verification_token: string
  email: string
  referrer_token?: string
}

export const signInRequest = (
  signInParams: ISignInParams,
  history: History,
  path = '/campaigns',
) => ({
  payload: {
    history,
    signInParams,
    path,
  },
  type: AuthActionTypes.SIGNIN_REQUEST,
})

export const signUpRequest = (signUpParams: ISignUpParams, history?: History) => ({
  payload: {
    history,
    signUpParams,
  },
  type: AuthActionTypes.SIGNUP_REQUEST,
})

export const signOutRequest = (meta: IMeta = {}) => ({
  meta,
  type: AuthActionTypes.SIGNOUT_REQUEST,
})

export const signOutSuccess = () => ({
  type: AuthActionTypes.SIGNOUT_SUCCESS,
})

export const autoSignIn = (params: { token: string }, meta: IMeta) => ({
  payload: params,
  meta,
  type: AutoSignInActionTypes.AUTO_SIGNIN_REQUEST,
})

export const emailVerificationRequest = (emailVerificationParams: IEmailVerificationParams) => ({
  payload: {
    params: emailVerificationParams,
  },
  type: AuthActionTypes.EMAIL_VERIFICATION_REQUEST,
})

export const emailVerificationSuccess = (meta = {}) => ({
  meta,
  type: AuthActionTypes.EMAIL_VERIFICATION_SUCCESS,
})

export const refreshToken = (now = Date.now()) => ({
  payload: now,
  type: AuthActionTypes.REFRESH_REQUEST,
})

export const cancelRefreshToken = () => ({
  type: AuthActionTypes.REFRESH_CANCEL,
})

export const authSuccess = (data = {}) => ({
  payload: data,
  type: AuthActionTypes.AUTH_SUCCESS,
})

export const authError = (error?: any) => ({
  payload: error,
  type: AuthActionTypes.AUTH_ERROR,
})

export const clearErrors = () => ({
  type: AuthActionTypes.CLEAR_ERRORS,
})

export const fetchIPRequest = () => ({
  type: IPActionTypes.CHECK_IP_REQUEST,
})

export const fetchIPSuccess = (result) => ({
  type: IPActionTypes.CHECK_IP_SUCCESS,
  payload: result,
})

export const fetchIPError = (error: any) => ({
  type: IPActionTypes.CHECK_IP_ERROR,
  payload: error,
})

export const resendVerificationRequest = (
  email: string,
  history?: History,
  toastMessage?: React.ReactNode,
  source = 'traditional',
) => ({
  type: AuthActionTypes.RESEND_VERIFICATION_REQUEST,
  payload: {
    email,
    history,
    toastMessage,
    source,
  },
})

export const resendVerificationSuccess = () => ({
  type: AuthActionTypes.RESEND_VERIFICATION_SUCCESS,
})

export const resendVerificationError = (error) => ({
  type: AuthActionTypes.RESEND_VERIFICATION_ERROR,
  payload: error,
})

export const saveSignUpForm = (formData: ISavedSignUpForm) => ({
  type: AuthActionTypes.SAVE_SIGNUP_FORM,
  payload: formData,
})

export const completeSignUpRequest = ({
  onboardingData,
  history,
}: {
  onboardingData: IOnboarding
  history: History
}) => ({
  payload: {
    onboardingData,
    history,
  },
  type: AuthActionTypes.COMPLETE_SIGNUP_REQUEST,
})

export const completeSignUpSuccess = () => ({ type: AuthActionTypes.COMPLETE_SIGNUP_SUCCESS })
