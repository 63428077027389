import styled from 'styled-components'

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;

  button {
    transition: opacity 0.75s;
    opacity: 0;
  }

  input:focus + span > button {
    opacity: 1;
  }
`

export const ButtonWrapper = styled.span`
  margin-left: 1rem;
`

export const ErrorWrapper = styled.div`
  margin-top: 0.25rem;
`

export const Tags = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
`
