import { FetchCategoriesActionTypes } from '@store/categories'

export const fetchCategories = () => ({
  type: FetchCategoriesActionTypes.FETCH_REQUEST,
})

export const fetchCategoriesSuccess = (data: any) => ({
  payload: data,
  type: FetchCategoriesActionTypes.FETCH_SUCCESS,
})

export const fetchCategoriesError = (error: any) => ({
  payload: error,
  type: FetchCategoriesActionTypes.FETCH_ERROR,
})
