import { PureComponent } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IMediaUploaderProps, withBuilderValidation, withMediaUploader } from '@hocs'
import Theme from '@theme'
import { FormGroup, FormInput, H4, ImageCropper, Panel, Text, TextField } from '@tribegroup'
import { ErrorMessage } from '@tribegroup/ImageCropper/ImageCropper.styled'
import { isEmpty } from '@utils'
import { PanelWrapper } from '../../Layout/Layout.styled'
import BuilderCampaignCoverImageTip from './Tip/Tip'

interface IBuilderCampaignCoverImageProps {
  name?: string
  coverImage?: string
  onCoverImageChanged: (mediaId?: string, mediaUrl?: string) => void
}

interface IInternalProps
  extends IMediaUploaderProps,
    InjectedIntlProps,
    IBuilderCampaignCoverImageProps,
    IBuilderValidationContextProps {}

export class BuilderCampaignCoverImage extends PureComponent<IInternalProps> {
  state = {
    errorMessage: '',
    file: undefined,
  }
  componentDidUpdate(prevProps: IInternalProps) {
    const hasMediaUploaded = Boolean(this.props.media) && prevProps.media !== this.props.media

    if (hasMediaUploaded) {
      const objectUrl = this.state.file ? URL.createObjectURL(this.state.file) : undefined

      this.props.onCoverImageChanged(this.props.media!.id, objectUrl)
    }
    if (prevProps.isUploading && !this.props.isUploading) {
      this.clearErrors()
    }
  }

  onRejected = () => {
    this.clearErrors()
    this.setState({
      errorMessage: this.props.intl.formatMessage({
        id: 'builder.campaign.error.coverimage.fileformat',
      }),
    })
  }

  onReset = () => {
    this.props.onCoverImageChanged()
    this.props.setEnableActions(true)
  }

  onClearValidation = () => {
    this.clearErrors()
  }

  onCropComplete = (file: Blob) => {
    this.props.upload(file)
    this.setState({ file })
    this.props.setEnableActions(true)
  }

  onDropAccepted = () => {
    this.clearErrors()
    this.props.setEnableActions(false)
  }

  clearErrors = () => {
    this.setState({ errorMessage: '' })
    this.props.clearFieldValidation(this.props.name!)
  }

  render() {
    const { intl, validationResults, coverImage, isUploading, name } = this.props
    const validationResult = validationResults[name!]
    const hasErrors =
      (validationResult && validationResult.hasError) || !isEmpty(this.state.errorMessage)
    const errorMessage =
      (validationResult && validationResult.errorMessage) || this.state.errorMessage
    return (
      <PanelWrapper data-cy-element="cover-image-panel-wrapper">
        <Panel
          title={
            <H4 color={Theme.defaultColor}>
              <FormattedMessage id="builder.campaign.coverimage.label" />
            </H4>
          }
        >
          <FormGroup>
            <FormInput
              validationRule={{
                required: {
                  value: true,
                  message: intl.formatMessage({ id: 'builder.campaign.error.coverimage.required' }),
                },
              }}
            >
              <TextField
                name={name}
                type="hidden"
                value={coverImage}
                readOnly
                data-focus="[data-element='image-cropper']"
              />
              <ImageCropper
                name={name}
                defaultImageSrc={coverImage}
                accepts="image/jpeg,image/png"
                onFocus={this.onClearValidation}
                onCropComplete={this.onCropComplete}
                onRejected={this.onRejected}
                onReset={this.onReset}
                cropLabel={intl.formatMessage({ id: 'core.text.crop' })}
                cropCancelLabel={intl.formatMessage({ id: 'core.text.cancel' })}
                isLoading={isUploading}
                onDropAccepted={this.onDropAccepted}
                errorMessage={
                  hasErrors && (
                    <ErrorMessage>
                      <Text small color={Theme.errorColor}>
                        {errorMessage}
                      </Text>
                    </ErrorMessage>
                  )
                }
              />
            </FormInput>
          </FormGroup>
        </Panel>
        <BuilderCampaignCoverImageTip />
      </PanelWrapper>
    )
  }
}

export default compose<IInternalProps, IBuilderCampaignCoverImageProps>(
  injectIntl,
  withBuilderValidation,
  withMediaUploader,
)(BuilderCampaignCoverImage)
