import { ActivateWithFeeActionTypes, ICampaign } from '@store/campaigns'

export const activateWithFee = (campaignId: number) => ({
  payload: {
    campaignId,
  },
  type: ActivateWithFeeActionTypes.ACTIVATE_WITH_FEE_REQUEST,
})

export const activateWithFeeSuccess = (campaign: ICampaign) => ({
  payload: campaign,
  type: ActivateWithFeeActionTypes.ACTIVATE_WITH_FEE_SUCCESS,
})

export const activateWithFeeError = (error: any) => ({
  payload: error,
  type: ActivateWithFeeActionTypes.ACTIVATE_WITH_FEE_ERROR,
})
