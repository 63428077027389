import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const PanelWrapper = styled.fieldset`
  border-radius: 0.5rem;
  width: 100%;
  border: none;
  padding: 1.5rem;
  ${breakpoint('phoneUpperBoundary')`
    padding: 2rem 2.5rem 1.5rem 2.5rem;
    `};
  box-sizing: border-box;
  background: #fff;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;

      input:disabled {
        background: transparent;
        opacity: 1;
      }

      label,
      a,
      .action-icon-wrapper {
        pointer-events: none;
        cursor: default;
        text-decoration: none;
      }
    `};
`

const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const PanelRight = styled.div`
  flex-shrink: 0;
  display: flex;
`

const PanelTitle = styled.div`
  flex-shrink: 0;
  margin-bottom: 1rem;
`

const IconWrapper = styled.div`
  margin-top: -0.75rem;
  margin-left: 1rem;
`

const PanelFluidWrapper = styled.div`
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  ${breakpoint('phoneUpperBoundary')`
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  `};
`

export { PanelTitle, PanelHeader, PanelRight, IconWrapper, PanelFluidWrapper }
export default PanelWrapper
