import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchMediaObjectLicenseOptions,
  fetchMediaObjectLicenseOptionsError,
  fetchMediaObjectLicenseOptionsSuccess,
  MediaObjectLicenseOptionsActionTypes,
} from '@store/licenses'
import { selectLicenseOptionsLink } from '@store/mediaObjects'
import { networkRequest } from '@utils'

export function* handleFetchMediaObjectLicenseOptions(
  action: ReturnType<typeof fetchMediaObjectLicenseOptions>,
) {
  try {
    const mediaObjectId = action.payload
    const authToken: string = yield select(selectAuthToken)
    const licensesLink: IHateoasLink = yield select(selectLicenseOptionsLink, mediaObjectId)

    const licenseOptions = yield call(
      networkRequest,
      licensesLink.method,
      licensesLink.href,
      undefined,
      authToken,
    )

    yield put(fetchMediaObjectLicenseOptionsSuccess(mediaObjectId, licenseOptions.items))
  } catch (error) {
    yield put(fetchMediaObjectLicenseOptionsError(error))
  }
}

export function* watchMediaObjectLicenseOptionsRequest() {
  yield takeLatest(
    MediaObjectLicenseOptionsActionTypes.FETCH_OPTIONS,
    handleFetchMediaObjectLicenseOptions,
  )
}
