import { Component } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import ConfirmationMessageBox from '@components/UI/ConfirmationMessageBox'
import TribeOnly from '@components/UI/TribeOnly'
import { Link, Text } from '@tribegroup'
import { queryStringtoObject } from '@utils'

interface IInternalProps extends RouteComponentProps, InjectedIntlProps {}

interface IAuthSignUpConfirmationState {
  email?: string
}

export class AuthSignUpConfirmation extends Component<
  IInternalProps,
  IAuthSignUpConfirmationState
> {
  static getDerivedStateFromProps(
    nextProps: IInternalProps,
    prevState: IAuthSignUpConfirmationState,
  ) {
    const parsedQueryString: any = queryStringtoObject(nextProps.location.search)
    const signedUpEmail = parsedQueryString.email && decodeURIComponent(parsedQueryString.email)

    if (!signedUpEmail) {
      nextProps.history.push('/signUp')
    }

    if (signedUpEmail !== prevState.email) {
      return { email: signedUpEmail }
    }

    return null
  }

  state = {
    email: undefined,
  }

  render() {
    const { intl } = this.props
    const messageTitle = intl.formatMessage({ id: 'auth.signupConfirmation.title' })
    return (
      <ConfirmationMessageBox
        title={messageTitle}
        subtitle={intl.formatMessage(
          {
            id: 'auth.signupConfirmation.subtitle',
          },
          { email: this.state.email },
        )}
        messageCTA={
          <Text>
            <img alt={messageTitle} width={65} src={require('@images/cta-email.png')} />
          </Text>
        }
        footerCTA={
          <TribeOnly>
            <Link target="_blank" href="https://www.tribegroup.co/faq">
              <FormattedMessage id="auth.signupConfirmation.link" />
            </Link>
          </TribeOnly>
        }
      />
    )
  }
}

export default compose<IInternalProps, {}>(withRouter, injectIntl)(AuthSignUpConfirmation)
