import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { BlockText } from '@components/Campaign/Preview/Block/Block.styled'
import { FULL_DATE } from '@constants'
import { IApplicationState } from '@store/index'
import {
  selectTransactedBrandedContentAdAuthCode,
  selectTransactedBrandedContentAdDurationInDays,
  selectTransactedBrandedContentAdExpiresAt,
  selectTransactedBrandedContentAdReceiptNumber,
  selectTransactedBrandedContentAdStatus,
} from '@store/submissions'
import Theme from '@theme'
import { Container, Header } from '@tribegroup'

interface IProps {
  submissionId: number
}

const Duration = ({ submissionId }: IProps) => {
  const { expiresAt, durationInDays, receiptNumber } = useSelector((state: IApplicationState) => {
    return {
      expiresAt: selectTransactedBrandedContentAdExpiresAt(state, submissionId),
      durationInDays: selectTransactedBrandedContentAdDurationInDays(state, submissionId),
      authCode: selectTransactedBrandedContentAdAuthCode(state, submissionId),
      brandedContentAdStatus: selectTransactedBrandedContentAdStatus(state, submissionId),
      receiptNumber: selectTransactedBrandedContentAdReceiptNumber(state, submissionId),
    }
  })

  return (
    <>
      <Container bottomOuterSpacing={1}>
        <Header cta color={Theme.grey700Color} uppercase>
          <FormattedMessage id="core.text.duration" />
        </Header>
        <Container wordBreak>
          <BlockText customFontSize={1} color={Theme.grey800Color}>
            <FormattedMessage id="core.text.xDays" values={{ days: durationInDays }} />
          </BlockText>
          {expiresAt && (
            <BlockText customFontSize={1} color={Theme.grey800Color}>
              <FormattedMessage id="contentSubmission.card.details.expires" />{' '}
              {format(new Date(expiresAt), FULL_DATE)}
            </BlockText>
          )}
        </Container>
      </Container>
      <Header cta color={Theme.grey700Color} uppercase>
        <FormattedMessage id="socialSubmission.card.modal.license.receiptNumber" />
      </Header>
      <Container wordBreak>
        <BlockText customFontSize={1} color={Theme.grey800Color}>
          {receiptNumber}
        </BlockText>
      </Container>
    </>
  )
}

export default Duration
