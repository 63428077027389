import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import {
  DownloadButton,
  LicenseOptionSelected,
  LicensingAgreementsLink,
  LicensingHeader,
  MediaDetails,
  MediaPreview,
  TransactedLicensePriceDetails,
} from '@components/Licensing'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectIsLicensed, selectUploadToFacebookLink } from '@store/mediaObjects'
import Theme from '@theme'
import { CloseIcon, Container } from '@tribegroup'
import MediaObjectLicensingLicensedUploadToFacebookAdsManager from './UploadToFacebookAdsManager'

interface IProps {
  onRequestClose: VoidFunction
  mediaObjectId: number
}

const Licensed = ({ onRequestClose, mediaObjectId }: IProps) => {
  const { isLicensed, hasUploadToFacebookLink } = useSelector((state: IApplicationState) => {
    return {
      hasUploadToFacebookLink: selectUploadToFacebookLink(state, mediaObjectId),
      isLicensed: selectIsLicensed(state, mediaObjectId),
    }
  })

  return (
    <>
      <LicensingHeader>
        <FormattedMessage id="licensing.licensed.header" />
      </LicensingHeader>
      <CloseIcon onClick={onRequestClose} />
      <LicenseOptionSelected
        mediaObjectId={mediaObjectId}
        backgroundColor={Theme.primary100Color}
        clearBorder
      />
      <Container topOuterSpacing={1.5} leftOuterSpacing={3.125}>
        <Flex justifyBetween alignStart>
          <MediaDetails mediaObjectId={mediaObjectId} />
          <MediaPreview mediaObjectId={mediaObjectId} />
        </Flex>
      </Container>
      <Container topOuterSpacing={2.75} leftOuterSpacing={3.125}>
        <TransactedLicensePriceDetails mediaObjectId={mediaObjectId} />
      </Container>
      <Container topOuterSpacing={1.25}>
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.DownloadLicensedContent}
          mediaObjectId={mediaObjectId}
        >
          <DownloadButton mediaObjectId={mediaObjectId}>
            <FormattedMessage id="licensing.licensed.cta" />
          </DownloadButton>
        </ConnectedTrackedRoute>
      </Container>
      <Container>
        <Flex justifyCenter column alignCenter>
          <LicensingAgreementsLink isLicensed={isLicensed} color={Theme.primaryColor} />
        </Flex>
      </Container>
      {hasUploadToFacebookLink && (
        <Container topOuterSpacing={1.25}>
          <Flex justifyCenter alignCenter>
            <MediaObjectLicensingLicensedUploadToFacebookAdsManager mediaObjectId={mediaObjectId} />
          </Flex>
        </Container>
      )}
    </>
  )
}

export { Licensed }
export default Licensed
