import React from 'react'

import { FormattedMessage } from 'react-intl'

import { Information } from '@components/UI/Information'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { EventTrackingNames } from '@enums'
import { useToggle } from '@hooks/useToggle'
import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import AnalyticsAudienceMatchLabelContent from './Content'
interface IProps {
  submissionId: number
  campaignId: number
}

const AnalyticsAudienceMatchLabel: React.FC<IProps> = ({ submissionId, campaignId }) => {
  const { toggleIsVisible, toggleHide, toggleShow } = useToggle()

  return (
    <Information
      delay={0.125}
      width={18.125}
      panelBottomOffset={1}
      panelTopOffset={1}
      onEnterProp={toggleShow}
      onLeaveProp={toggleHide}
      panelRightOffset={-0.0625}
      target={
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.AudienceTopMatchTooltip}
          submissionId={submissionId}
          campaignId={campaignId}
          eventTrigger={toggleIsVisible ? undefined : 'mouseEnter'}
        >
          <Container
            leftInnerSpacing={0.5}
            rightInnerSpacing={0.5}
            bottomInnerSpacing={0.25}
            topInnerSpacing={0.25}
            borderRadius={0.25}
            backgroundColor={Theme.primary100Color}
            pointer
            color={Theme.primaryColor}
            relative
            inlineBlock
            verticalAlign="middle"
          >
            <Container
              rightOuterSpacing={0.375}
              bottomOuterSpacing={0.25}
              inlineBlock
              verticalAlign="middle"
            >
              <Icon
                glyph="audience-match"
                size={0.875}
                verticalAlign="middle"
                data-testid="audience-match-icon"
                color={Theme.primaryColor}
              />
            </Container>
            <Text uppercase light>
              <FormattedMessage id="core.text.audienceMatch" />
            </Text>
          </Container>
        </ConnectedTrackedRoute>
      }
    >
      <AnalyticsAudienceMatchLabelContent submissionId={submissionId} campaignId={campaignId} />
    </Information>
  )
}

export default AnalyticsAudienceMatchLabel
