import { Fragment, PureComponent } from 'react'

import ReactSelect, { components, DropdownIndicatorProps, Props } from 'react-select'
import styled, { css } from 'styled-components'

import { Icon } from '../Icon'
import { IScheme } from '../Interfaces/schemes'
import { defaultTheme, getColor, themed } from '../Theme'

export interface ISelectProps extends IScheme {
  /**
   * Displays button in an quiet state
   *
   * @default false
   */
  quiet?: boolean

  /**
   * Width of the select box in rem
   *
   * @default undefined
   */
  width?: number

  /**
   * Height of the select box in rem
   *
   * @default undefined
   */
  height?: number

  /**
   * Height of the dropdown
   *
   * @default undefined
   */
  dropdownHeight?: number

  /**
   * Displays the selectbox as a tag selection box. This forces isMulti prop to be true.
   *
   * @default false
   */
  showAsTags?: boolean

  /**
   * Indicates that this field has invalid value
   *
   * @default false
   */
  invalid?: boolean

  /**
   * Renders the dropdown in much bigger fonts
   *
   * @default false
   */
  large?: boolean

  /**
   * Renders the dropdown with thicker padding
   *
   * @default undefined
   */
  thick?: boolean

  /**
   * A custom text that shows up on the input when something has been selected
   *
   * @default undefined
   */
  label?: string

  /**
   * Renders the dropdown with underline
   *
   * @default false
   */
  underlined?: boolean

  /**
   * Renders the chevron size based on the passed value
   *
   * @default undefined
   */
  iconSize?: number

  /**
   * Renders the text using the passed value as font size in rem
   *
   * @default undefined
   */
  fontSize?: number

  /**
   * Disables the select dropdown
   *
   * @default undefined
   */
  disabled?: boolean

  /**
   * React select config
   */
  rsConfig: Props<any>

  pillBackgroundColor?: string
  pillXHoverColor?: string

  /**
   * Adjusts width dynamically based on selected value
   */
  dynamicWidth?: boolean
}

interface IWrappedReactSelect extends ISelectProps {
  /**
   * Prefix added to react-select custom elements
   */
  classNamePrefix: string
}

const WrappedReactSelect = themed(styled<IWrappedReactSelect, any>(ReactSelect)`
  ${({
    theme,
    scheme,
    classNamePrefix,
    width,
    height,
    dropdownHeight,
    quiet,
    invalid,
    large,
    thick,
    underlined,
    pillBackgroundColor,
    pillXHoverColor,
    fontSize = 0.75,
    dynamicWidth,
  }) => css`
    display: inline-block;
    text-align: left;
    ${!dynamicWidth &&
    css`
      width: 100%;
    `}
    ${width &&
    css`
      max-width: ${width}rem;
    `};

    .${classNamePrefix}__menu {
      z-index: 9;

      > div {
        padding: 0.5rem;
        border-radius: 0.25rem;
        ${dropdownHeight &&
        css`
          max-height: ${dropdownHeight}rem;
          overflow: scroll;
        `};
      }
    }
    .${classNamePrefix}__control {
      border-radius: 0.375rem;
      background-color: ${theme.whiteColor};
      border: solid 1px ${theme.grey400Color};

      ${height &&
      css`
        min-height: ${height}rem;
      `};

      &.${classNamePrefix}__control--is-focused {
        box-shadow: none;
        ${!quiet &&
        !invalid &&
        css`
          border: solid 1px ${getColor(theme, scheme)};

          &:hover {
            border-color: ${getColor(theme, scheme)};
          }
        `};
      }

      &.${classNamePrefix}__control--is-disabled {
        opacity: 0.3;
        border: solid 1px ${theme.grey400Color};

        .${classNamePrefix}__multi-value__remove {
          display: none;
        }
      }

      &:hover {
        border-color: ${theme.grey400Color};
      }

      .${classNamePrefix}__value-container {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-left: 0.25rem;
        color: #575757;

        ${large &&
        css`
          font-size: 1.5rem;
        `};

        ${fontSize &&
        css`
          font-size: ${fontSize}rem;
        `};

        ${quiet &&
        css`
          padding-left: 0;
        `};

        ${thick &&
        css`
          padding: 0.625rem 0 0.625rem 1rem;
          font-size: 0.875rem;
        `};

        .${classNamePrefix}__single-value {
          color: ${theme.grey900Color};
          ${quiet &&
          css`
            margin-left: 0;
            padding: 0.5rem 0;
          `};
          ${invalid &&
          css`
            color: ${getColor(theme, 'error')};
          `};
          ${dynamicWidth &&
          css`
            position: relative;
            transform: translateY(0);
          `}
        }

        .${classNamePrefix}__multi-value {
          padding: 0.375rem;
          border-radius: 0.25rem;
          background-color: ${defaultTheme.primary100Color};
          margin: 0 0.25rem 0.125rem;
          ${pillBackgroundColor &&
          css`
            background-color: ${pillBackgroundColor};
          `};
        }

        .${classNamePrefix}__multi-value__label {
          color: ${defaultTheme.defaultColor};
          font-size: ${fontSize}rem;
        }

        .${classNamePrefix}__multi-value__remove {
          color: ${defaultTheme.defaultColor};
          cursor: pointer;

          &:hover {
            background-color: ${defaultTheme.primary200Color};
            ${pillXHoverColor &&
            css`
              background-color: ${pillXHoverColor};
            `};
          }

          svg {
            transform: scale(1.375);
          }
        }
      }

      .${classNamePrefix}__placeholder {
        color: ${theme.grey700Color};

        ${quiet &&
        css`
          margin-left: 0;
        `};
        ${dynamicWidth &&
        css`
          position: relative;
          transform: translateY(0);
        `}
      }

      .${classNamePrefix}__indicator-separator {
        display: none;
      }

      ${quiet &&
      css`
        border-color: transparent;

        &:hover,
        &:focus,
        &:active {
          border-color: transparent;
        }
      `};

      ${underlined &&
      css`
        border-bottom: solid 1px ${theme.grey900Color};

        &:hover,
        &:focus,
        &:active {
          border-bottom: solid 1px ${getColor(theme, scheme)};
        }
      `};

      ${invalid &&
      css`
        color: ${getColor(theme, 'error')};

        &:hover {
          color: ${getColor(theme, 'error')};
        }

        ${!quiet &&
        css`
          border-color: ${getColor(theme, 'error')};

          &:hover {
            border-color: ${getColor(theme, 'error')};
          }
        `};
      `};
    }

    .${classNamePrefix}__menu {
      border-radius: 5px;
      background-color: #fff;

      .${classNamePrefix}__menu-list {
        border-radius: 5px;
        background-color: #fff;
      }

      .${classNamePrefix}__option {
        font-size: ${fontSize}rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: 0.3;
        color: ${theme.grey900Color};
        cursor: pointer;
        border-radius: 0.25rem;

        &:hover,
        &:focus,
        &:active {
          background-color: ${theme.grey200Color};
        }
        &.${classNamePrefix}__option--is-disabled {
          color: ${theme.grey700Color};
          background-color: transparent;
          cursor: default;
        }
        &.${classNamePrefix}__option--is-selected {
          position: relative;
          background-color: transparent;

          &:hover {
            background-color: #fafafa;
          }

          &::after {
            border: solid ${theme.primaryColor};
            border-width: 0 1px 1px 0;
            content: ' ';
            height: 0.625rem;
            left: auto;
            position: absolute;
            right: 0.875rem;
            bottom: calc(50% - 0.3125rem);
            transform: rotate(45deg);
            width: 0.25rem;
          }
        }
        &.${classNamePrefix}__option--is-focused {
          background-color: ${theme.grey200Color};
        }
      }
    }
    .${classNamePrefix}__input {
      input {
        caret-color: ${getColor(theme, scheme)};
      }
    }
  `};
`)

const Label = styled.h1`
  position: absolute;
  z-index: 1;
  background-color: ${defaultTheme.whiteColor};
  color: ${defaultTheme.grey900Color};
  width: 50%;
  margin-top: 0.525rem;
  font-size: 1.5rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
`

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const iconSize = props.selectProps.iconSize || 1.5
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon
          glyph={props.selectProps.menuIsOpen ? 'chevron-up' : 'chevron-down'}
          size={iconSize}
          data-cy-element="drop-down-indicator"
        />
      </components.DropdownIndicator>
    )
  )
}
export default class Select extends PureComponent<ISelectProps> {
  public render() {
    const { rsConfig, label, ...props } = this.props
    const classNamePrefix = (rsConfig && rsConfig.classNamePrefix) || 'custom'
    const noOptions = () => null
    const selectProps: Props = this.props.showAsTags
      ? {
          ...props,
          ...rsConfig,
          closeMenuOnSelect: false,
          components: { ...rsConfig.components, DropdownIndicator },
          isClearable: false,
          isMulti: true,
        }
      : {
          ...props,
          ...rsConfig,
          components: { DropdownIndicator },
        }

    return (
      <Fragment>
        {label && <Label>{this.props.label}</Label>}
        <WrappedReactSelect
          {...selectProps}
          classNamePrefix={classNamePrefix}
          noOptionsMessage={noOptions}
        />
      </Fragment>
    )
  }
}
