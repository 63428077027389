import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { compose } from 'recompose'

import CheckedListIem from '@components/UI/CheckedListItem/CheckedListItem'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { BRANDS_TIKTOK_CONNECT_EDUCATION } from '@constants'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { EventTrackingNames } from '@enums'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import {
  selectCheckedTikTokAdManagerAccount,
  selectDraft,
  selectIsInDraftStatus,
} from '@store/drafts'
import Theme from '@theme'
import { Container, Header, Link, Text } from '@tribegroup'
import BuilderBriefChannelsTikTokNoTikTokCheckBox from './NoTikTokCheckBox'

const BuilderBriefChannelsTikTokConnectAccountEducation = ({
  updateForm,
}: IBuilderValidationContextProps) => {
  const onCheckboxChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement
    updateForm({
      has_tiktok_ad_manager_account: !target.checked,
    })
  }
  const { isChecked, isDraft, draft } = useSelector((state: IApplicationState) => {
    const hasTikTokAdManagerAccount = selectCheckedTikTokAdManagerAccount(state)
    return {
      draft: selectDraft(state),
      isChecked: hasTikTokAdManagerAccount === false,
      isDraft: selectIsInDraftStatus(state),
    }
  })

  return (
    <Container topOuterSpacing={1.5} relative rightOuterSpacing={-2.5} rightInnerSpacing={2.5}>
      <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.75}>
        <FormattedMessage id="builder.brief.socialMedia.tiktok.requirements.header" />
      </Header>
      <Container>
        <CheckedListIem>
          <FormattedMessage id="builder.brief.socialMedia.tiktok.points.item" />{' '}
          <ConnectedTrackedRoute
            inline
            draftId={draft.id}
            eventName={EventTrackingNames.CampaignBuilderEducationLink}
            additionalProperties={{
              link_url: BRANDS_TIKTOK_CONNECT_EDUCATION,
              link_name: 'core.text.learnHow.withArrow',
            }}
          >
            <Link
              href={BRANDS_TIKTOK_CONNECT_EDUCATION}
              target="_blank"
              color={Theme.secondaryColor}
            >
              <Text color={Theme.primaryColor}>
                <FormattedMessage id="core.text.learnHow.withArrow" />
              </Text>
            </Link>
          </ConnectedTrackedRoute>
        </CheckedListIem>
        <BuilderBriefChannelsTikTokNoTikTokCheckBox
          isDisabled={!isDraft}
          isChecked={isChecked}
          onCheckboxChange={onCheckboxChange}
        />
      </Container>
    </Container>
  )
}

export { BuilderBriefChannelsTikTokConnectAccountEducation }

export default compose(withBuilderValidation)(BuilderBriefChannelsTikTokConnectAccountEducation)
