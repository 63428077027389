import React, { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import SubmissionCostBreakdownSocialBasePrices from '@components/Submission/CostBreakdown/Social/BasePrices'
import SubmissionCostBreakdownPreApprovePrice from '@components/Submission/CostBreakdown/Social/PreApprovePrice'
import SubmissionCostBreakdownSocialTotalPrice from '@components/Submission/CostBreakdown/Social/TotalPrice'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectIsTraditional } from '@store/campaigns'
import { selectSubmission } from '@store/submissions'
import Theme from '@theme'
import { Container, Header, Icon } from '@tribegroup'

interface IProps {
  submissionId: number
  campaignId: number
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
}

const SubmissionCardModalPreApprovalCostBreakdown: React.FC<IProps> = ({
  isExpanded,
  campaignId,
  setIsExpanded,
  submissionId,
}) => {
  const onChevronClick = () => setIsExpanded(!isExpanded)
  const chevronDirection = isExpanded ? 'down' : 'up'
  const triggerAction = isExpanded ? 'collapse' : 'expand'
  const chevronColor = isExpanded ? Theme.primaryColor : Theme.defaultColor
  const { submission, isTraditional } = useSelector((state: IApplicationState) => ({
    submission: selectSubmission(state, submissionId),
    isTraditional: selectIsTraditional(state, campaignId),
  }))

  const simplifiedPricingSummaryTopOuterSpacing = isExpanded ? 3.125 : 2.125

  return (
    <React.Fragment>
      <Container
        data-testid="preapproval-cost-breakdown"
        topOuterSpacing={simplifiedPricingSummaryTopOuterSpacing}
        bottomOuterSpacing={1}
      >
        <Flex justifyBetween alignCenter>
          <Header small uppercase color={Theme.primaryColor}>
            <FormattedMessage id="submission.chat.preapproved.modal.preApprovalSummary" />
          </Header>
          {isTraditional && (
            <ConnectedTrackedRoute
              campaignId={campaignId}
              submissionId={submissionId}
              eventName={EventTrackingNames.ViewPreApproveModalPriceSummary}
              additionalProperties={{
                action: triggerAction,
              }}
            >
              <Icon
                data-testid="preapproval-cost-breakdown-chevron"
                size={1.5}
                color={chevronColor}
                glyph={`chevron-${chevronDirection}`}
                onClick={onChevronClick}
              />
            </ConnectedTrackedRoute>
          )}
        </Flex>
      </Container>
      <Fragment>
        {isExpanded && <SubmissionCostBreakdownSocialBasePrices submission={submission} />}
        <Container
          topInnerSpacing={0.75}
          topOuterSpacing={0.625}
          bottomOuterSpacing={0.125}
          borderWidthTop={0.0625}
          borderColor={Theme.grey400Color}
        >
          <SubmissionCostBreakdownSocialTotalPrice submission={submission} showCurrency />
        </Container>
        <Container
          topInnerSpacing={0.75}
          topOuterSpacing={0.625}
          bottomOuterSpacing={0.125}
          borderWidthTop={0.0625}
          borderColor={Theme.grey400Color}
        >
          <SubmissionCostBreakdownPreApprovePrice submission={submission} />
        </Container>
      </Fragment>
    </React.Fragment>
  )
}

export default SubmissionCardModalPreApprovalCostBreakdown
