import React from 'react'

import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Price from '@components/UI/Price/Price'
import { IApplicationState } from '@store'
import {
  selectAmountCurrency,
  selectTransactedBrandedContentAd,
  selectTransactedBrandedContentAdAmountCents,
  selectTransactedBrandedContentAdAmountCurrency,
} from '@store/submissions'
import Theme from '@theme'
import { Container, H4, TextField } from '@tribegroup'
import { isEmpty } from '@utils'

interface IProps {
  submissionId: number
}

interface IInternalProps extends IProps {
  amount: number
  savedCurrency: string
  defaultCurrency: string
}

const PlannedSpend: React.FC<IInternalProps> = ({ amount, savedCurrency, defaultCurrency }) => {
  const { register } = useFormContext()
  const isEditable = !amount
  if (!isEditable) {
    return (
      <Container bottomInnerSpacing={0.5} topInnerSpacing={0.5}>
        <H4 color={Theme.grey900Color}>
          <Price currency={savedCurrency} quantity={amount} rounded />
        </H4>
      </Container>
    )
  }

  return (
    <React.Fragment>
      <Container absolute topOuterSpacing={0.6}>
        <H4 color={Theme.grey900Color}>
          <FormattedMessage id={`core.currency.symbol.${defaultCurrency}`} />
        </H4>
      </Container>
      <TextField
        data-testid="planned-spend-input"
        quiet
        fullWidth
        autoFocus
        type="number"
        scheme="default"
        leftPadding={1}
        name="planned_spend_amount"
        fontSize={1.125}
        defaultValue={amount}
        innerRef={register({
          required: true,
          min: 1,
        })}
      />
    </React.Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { submissionId }) => {
  return {
    amount: selectTransactedBrandedContentAdAmountCents(state, submissionId),
    savedCurrency: selectTransactedBrandedContentAdAmountCurrency(state, submissionId),
    defaultCurrency: selectAmountCurrency(state, submissionId),
    isEditable: isEmpty(selectTransactedBrandedContentAd(state, submissionId)),
  }
}

export { PlannedSpend }
export default compose<IInternalProps, IProps>(connect(mapStateToProps))(PlannedSpend)
