enum EventTrackingNames {
  SignUpValidateEmail = 'SignUp.ValidateEmail',
  ApproveSubmission = 'ApproveSubmission',
  DeclineSubmission = 'DeclineSubmission',
  ViewCreatorProfile = 'ViewCreatorProfile',
  BulkSelectSelectAll = 'BulkSelect.SelectAll',
  ShortlistSubmission = 'ShortlistSubmission',
  UnshortlistSubmission = 'UnshortlistSubmission',
  BulkSelectStart = 'Toolbar.BulkSelectStart',
  ToolbarViewRateCard = 'Toolbar.ViewRateCard',
  ToolbarPreviewCampaign = 'Toolbar.PreviewCampaign',
  ToolbarBrandReferral = 'Toolbar.BrandReferral',
  ToolbarShareCampaignDeeplink = 'Toolbar.ShareCampaignDeeplink',
  ToolbarViewSubmissionNotes = 'Toolbar.ViewSubmissionNotes',
  SubmitBulkFeedback = 'SubmitBulkFeedback',
  LicenseContentSubmission = 'LicenseContentSubmission',
  ViewFrameNotes = 'ViewFrameNotes',
  DisplayMemberInviteModal = 'DisplayMemberInviteModal',
  ViewPriceSummary = 'ViewPriceSummary',
  ViewSubmissionFeedback = 'ViewSubmissionFeedback',
  CancelMemberInvite = 'CancelMemberInvite',
  RemoveMember = 'RemoveMember',
  SubmitSubmissionFeedback = 'SubmitSubmissionFeedback',
  ToolbarRequestCampaignReport = 'Toolbar.RequestCampaignReport',
  ViewAccountsPage = 'ViewAccountsPage',
  ViewBillingPage = 'ViewBillingPage',
  ViewContentLibrary = 'ViewContentLibrary',
  ViewContentLibraryItem = 'ViewContentLibraryItem',
  FilterContentLibrary = 'FilterContentLibrary',
  ViewLicensingOptions = 'ViewLicensingOptions',
  DownloadLicensedContent = 'DownloadLicensedContent',
  DownloadBillingHistory = 'DownloadBillingHistory',
  RequestContentRights = 'RequestContentRights',
  NegotiationTooltipDisplayed = 'NegotiationTooltipDisplayed',
  NegotiationTooltipHidden = 'NegotiationTooltipHidden',
  NegotiationTooltipClicked = 'NegotiationTooltipClicked',
  RequestContentLibraryCredit = 'RequestContentLibraryCredit',
  SwitchBrandApp = 'SwitchBrandApp',
  CampaignBuilderSubmitCampaign = 'CampaignBuilder.SubmitCampaign',
  CampaignBuilderEditCampaign = 'CampaignBuilder.EditCampaign',
  CampaignBuilderCloseBuilder = 'CampaignBuilder.CloseBuilder',
  CampaignBuilderEditDone = 'CampaignBuilder.EditDone',
  CampaignBuilderBrandStepCompleted = 'CampaignBuilder.BrandStepCompleted',
  CampaignBuilderCreateCampaign = 'CampaignBuilder.CreateCampaign',
  CampaignBuilderSelectCampaignType = 'CampaignBuilder.SelectCampaignType',
  CampaignBuilderSelectBrand = 'CampaignBuilder.SelectBrand',
  CampaignBuilderCreateBrandStart = 'CampaignBuilder.CreateBrandStart',
  CampaignBuilderCreateBrandCancelled = 'CampaignBuilder.CreateBrandCancelled',
  CampaignBuilderCreateBrandCompleted = 'CampaignBuilder.CreateBrandCompleted',
  CampaignBuilderEditBrandStart = 'CampaignBuilder.EditBrandStart',
  CampaignBuilderEditBrandCancelled = 'CampaignBuilder.EditBrandCancelled',
  CampaignBuilderEditBrandCompleted = 'CampaignBuilder.EditBrandCompleted',
  CampaignBuilderSubmitCampaignPage = 'CampaignBuilder.SubmitCampaignPage',
  CampaignBuilderSubmitBriefPage = 'CampaignBuilder.SubmitBriefPage',
  CampaignBuilderSubmitBudgetPage = 'CampaignBuilder.SubmitBudgetPage',
  CampaignBuilderScheduleCampaign = 'CampaignBuilder.ScheduleCampaign',
  CampaignBuilderSaveCampaign = 'CampaignBuilder.SaveCampaign',
  CampaignBuilderSaveDraft = 'CampaignBuilder.SaveDraft',
  CampaignBuilderPreviewCampaign = 'CampaignBuilder.PreviewCampaign',
  CampaignBuilderTooltip = 'CampaignBuilder.Tooltip',
  CampaignBuilderEducationLink = 'CampaignBuilder.EducationLink',
  CampaignBuilderTargetCreators = 'CampaignBuilder.TargetCreators',
  CampaignBuilderBrandFansAdd = 'CampaignBuilder.BrandFansAdd',
  CampaignBuilderBrandFansRemove = 'CampaignBuilder.BrandFansRemove',
  CampaignBuilderPlayDemo = 'CampaignBuilder.PlayDemo',
  CreateCampaign = 'CreateCampaign',
  SortSubmissions = 'SortSubmissions',
  PauseCampaign = 'PauseCampaign',
  ActivateCampaign = 'ActivateCampaign',
  RescheduleSubmission = 'RescheduleSubmission',
  ViewSubmission = 'ViewSubmission',
  BulkDeclineSubmissions = 'BulkDeclineSubmissions',
  ViewInsights = 'ViewInsights',
  InviteMember = 'InviteMember',
  AddSubmissionNote = 'AddSubmissionNote',
  SwitchToTileView = 'SwitchToTileView',
  SwitchToListView = 'SwitchToListView',
  ListViewRowClicked = 'ListViewRowClicked',
  UploadToFacebookAdsManager = 'UploadToFacebookAdsManager',
  ViewCampaignMetrics = 'ViewCampaignMetrics',
  ViewCampaignMetricsTab = 'ViewCampaignMetricsTab',
  ViewCampaignMetricsTooltip = 'ViewCampaignMetricsTooltip',
  CampaignMetricsDownloadCSV = 'CampaignMetricsDownloadCSV',
  BCARightsRequest = 'BCARightsRequest',
  BCARightsAgreement = 'BCARightsAgreement',
  ViewBCAModal = 'ViewBCAModal',
  BCAAdSpendTooltip = 'BCAAdSpendTooltip',
  BCAEducationLink = 'BCAEducationLink',
  ShareCLCopyURL = 'ShareCLCopyURL',
  ShareCLPreview = 'ShareCLPreview',
  ShareCLButton = 'ShareCLButton',
  ShareCLEducationLink = 'ShareCLEducationLink',
  ShareableCLShareCLButton = 'ShareableCLShareCLButton',
  ShareableCLViewContentLibraryItem = 'ShareableCLViewContentLibraryItem',
  ShareableCLViewPriceSummary = 'ShareableCLViewPriceSummary',
  SwitchInboxFilter = 'SwitchInboxFilter',
  SubscriptionLearnMore = 'SubscriptionLearnMore',
  DownloadInstagramHandlesCSV = 'DownloadInstagramHandlesCSV',
  SubmitRequireApprovalsModal = 'SubmitRequireApprovalsModal',
  BrandFansViewAllCreators = 'BrandFansViewAllCreators',
  BrandFansViewBrandFans = 'BrandFansViewBrandFans',
  BrandFansViewGroupsList = 'BrandFansViewGroupsList',
  BrandFansGroupsListCTA = 'BrandFansGroupsListCTA',
  BrandFansGroupsModal = 'BrandFansGroupsModal',
  BrandFansGroupsListMenu = 'BrandFansGroupsListMenu',
  BrandFansGroupDetailView = 'BrandFansGroupDetailView',
  BrandFansGroupsRemove = 'BrandFansGroupsRemove',
  BrandFansGroupsAdd = 'BrandFansGroupsAdd',
  BrandFansGroupsAddWarning = 'BrandFansGroupsAddWarning',
  BrandFansRowClicked = 'BrandFansRowClicked',
  BrandFansViewInsights = 'BrandFansViewInsights',

  ViewRequestContentLibraryCredit = 'ViewRequestContentLibraryCredit',
  ViewContentLibraryBudgetPanelTooltip = 'ViewContentLibraryBudgetPanelTooltip',
  BrandReferralCopyURL = 'BrandReferralCopyURL',
  BrandReferralLearnMore = 'BrandReferralLearnMore',
  BrandReferralDismiss = 'BrandReferralDismiss',
  ShareCampaignDeeplinkLearnMore = 'ShareCampaignDeeplinkLearnMore',
  ShareCampaignDeeplinkDismiss = 'ShareCampaignDeeplinkDismiss',
  ShareCampaignDeeplinkCopyURL = 'ShareCampaignDeeplinkCopyURL',
  AudienceTopMatchTooltip = 'AudienceTopMatchTooltip',
  AudienceTopMatchLearnMore = 'AudienceTopMatchLearnMore',
  AudienceTopMatchHover = 'AudienceTopMatchHover',
  AudienceTopMatchClick = 'AudienceTopMatchClick',
  ViewPreApproveModal = 'ViewPreApproveModal',
  ViewPreApproveModalPriceSummary = 'ViewPreApproveModalPriceSummary',
  PreApproveSubmission = 'PreApproveSubmission',
  PreApproveConfirmationCTA = 'PreApproveConfirmationCTA',
  PreApproveConfirmationDismiss = 'PreApproveConfirmationDismiss',
  PreApproveDeclineStep1 = 'PreApproveDeclineStep1',
  PreApproveDeclineStep2 = 'PreApproveDeclineStep2',
  PreApproveDeclineLearnMore = 'PreApproveDeclineLearnMore',
  BudgetPanelHover = 'BudgetPanelHover',
  BudgetPanelTooltip = 'BudgetPanelTooltip',
  ViewAudienceReporting = 'ViewAudienceReporting',
  ViewAudienceReportingEducation = 'ViewAudienceReportingEducation',
  ViewAudienceReportingLearnMore = 'ViewAudienceReportingLearnMore',
  AudienceReportingToggle = 'AudienceReportingToggle',
  DismissAudienceReportingEducation = 'DismissAudienceReportingEducation',
  BrandSignUpStep1Completed = 'BrandSignUpStep1Completed',
  BrandSignUpStep2Completed = 'BrandSignUpStep2Completed',
  ViewBio = 'ViewBio',
  ViewBioPreview = 'ViewBioPreview',
  ViewBioPreviewNext = 'ViewBioPreviewNext',
  ViewBioPreviewPrevious = 'ViewBioPreviewPrevious',
  ViewBioPreviewDismiss = 'ViewBioPreviewDismiss',
  BrandMatchTooltip = 'BrandMatchTooltip',
  BrandMatchLearnMore = 'BrandMatchLearnMore',
  FeedbackUploadSuccess = 'FeedbackUploadSuccess',
  FeedbackUploadFailure = 'FeedbackUploadFailure',
  FeedbackUploadRejection = 'FeedbackUploadRejection',
}

export { EventTrackingNames }
