import React from 'react'

import styled from 'styled-components'

import { IMultiframeControlProps, MultiframeControls } from '../Next/Next.styled'

export const PreviousWrapper: React.ComponentType<IMultiframeControlProps> = styled(
  MultiframeControls,
)`
  left: 1.375rem;
`
