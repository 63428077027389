export enum UIActionTypes {
  SET_FLAG = 'UIActionTypes/SET_FLAG',
  CLEAR_FLAG = 'UIActionTypes/CLEAR_FLAG',
  SET_FLAG_DATA = 'UIActionTypes/SET_FLAG_DATA',
  CREATE_SNACKBAR = 'UIActionTypes/CREATE_SNACKBAR',
  REMOVE_SNACKBAR = 'UIActionTypes/REMOVE_SNACKBAR',
}

export enum UIFlag {
  SHOW_TURNOFF_REQUIRE_APPROVALS_MODAL = 'SHOW_TURNOFF_REQUIRE_APPROVALS_MODAL',
  SHOW_PAID_PARTNERSHIP_ADD_HANDLE_REMINDER = 'SHOW_PAID_PARTNERSHIP_ADD_HANDLE_REMINDER',
  SHOW_CAMPAIGN_CREATION_CONFIRMATION_MODAL = 'SHOW_CAMPAIGN_CREATION_CONFIRMATION_MODAL',
  SHOW_IG_CONNECT_ACTIONS_MODAL = 'SHOW_IG_CONNECT_ACTIONS_MODAL',
  SHOW_IG_CONNECT_ACTIONS_REMINDER_MODAL = 'SHOW_IG_CONNECT_ACTIONS_REMINDER_MODAL',
  SHOW_ONBOARDING_AUTH_STEP = 'SHOW_ONBOARDING_AUTH_STEP',

  // Credit Management
  SHOW_REQUEST_ACCESS_SUCCESS = 'SHOW_REQUEST_ACCESS_SUCCESS',

  // BCA
  SHOW_NO_BRAND_MANAGER_IDENTITY_ALERT = 'SHOW_NO_BRAND_MANAGER_IDENTITY_ALERT',
  SHOW_CONNECT_ACCOUNT_ALERT = 'SHOW_CONNECT_ACCOUNT_ALERT',
  SHOW_RECONNECT_ACCOUNT_ALERT = 'SHOW_RECONNECT_ACCOUNT_ALERT',

  // Feedbacks
  CHAT_SENDING_INPROGRESS = 'CHAT_SENDING_INPROGRESS',

  HIDE_MODAL = 'HIDE_MODAL',
}

export interface IUIState {
  enabled: Partial<Record<UIFlag, boolean>>
  data: Partial<Record<UIFlag, unknown>>
}
