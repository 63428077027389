import React from 'react'

import Flex from 'styled-flex-component'

import { IconWrapper } from '@components/BrandFans/Header/Header.styled'
import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import BrandFansGroupRowCreateCampaignButton from '../Row/CreateCampaign/Button'

interface IProps {
  groupId: number
  count: number
  description: string
  name: string
  showCreateCampaignButton?: boolean
}

const BrandFansGroupDetailsHeader: React.FC<IProps> = ({
  groupId,
  name,
  description,
  count,
  showCreateCampaignButton,
}) => {
  const canCreateCampaign = count > 0
  return (
    <Flex center>
      <Container textAlign="center" topOuterSpacing={3.3125} bottomOuterSpacing={2.5}>
        <IconWrapper center>
          <Icon glyph="star-rating" size={1.5} color={Theme.primaryColor} />
        </IconWrapper>
        <Container topOuterSpacing={1} width={31.75} wordBreak>
          <Text color={Theme.defaultColor} customFontSize={1.75} light>
            {name} ({count})
          </Text>
        </Container>
        <Container topOuterSpacing={1} bottomOuterSpacing={1.25} width={31.75}>
          <Text color={Theme.defaultColor}>{description}</Text>
        </Container>
        {showCreateCampaignButton && (
          <Flex center>
            <BrandFansGroupRowCreateCampaignButton
              groupId={groupId}
              canCreateCampaign={canCreateCampaign}
            />
          </Flex>
        )}
      </Container>
    </Flex>
  )
}

export { BrandFansGroupDetailsHeader }
export default BrandFansGroupDetailsHeader
