import { useState } from 'react'
import React from 'react'

import { FileRejection, useDropzone } from 'react-dropzone'

import { AspectRatios } from '@enums'
import { Icon } from '../Icon'
import { defaultTheme } from '../Theme'
import {
  CameraIconWrapper,
  Caption,
  CloseIconWrapper,
  DroppedFileCover,
  DroppedFileWrapper,
  EmptyFile,
  ImageLoadingCover,
  ReplaceImageOverlay,
  SquareWrapper,
} from './FileDrop.styled'

export interface IFileDropProps {
  /**
   * Index-assigned to the filedrop
   */
  index?: number

  /**
   * File accepted by this file drop
   */
  // tslint:disable-next-line:readonly-array
  accepts: string[] | string

  /**
   * total number of files possible in Multi File drop
   */
  maxFiles?: number

  /**
   * function called when a new file is dropped
   *
   */
  onFileDrop?: (file: File, index?: number) => void

  /**
   * function called when a file was removed
   *
   */
  onDropReset?: (index) => void

  /**
   * function called when a file is rejected
   *
   */
  onRejected?: (files: ReadonlyArray<File>, index?: number) => void

  /**
   * The thickness of the border on empty
   *
   * @default 1rem
   */
  borderWidth?: number

  /**
   * Text caption displayed on empty state
   *
   * @default undefined
   */
  caption?: React.ReactNode

  /**
   * Disable click behaviour
   *
   * @default undefined
   */
  noClick?: boolean

  /**
   * Disable keydown behaviour
   *
   * @default undefined
   */
  noKeyboard?: boolean

  /**
   * Default image url displayed in file drop
   *
   * @default undefined
   */
  defaultSrc?: string

  /**
   * Default image alt displayed in file drop
   *
   * @default undefined
   */
  defaultAlt?: string

  /**
   * Max file size accepted
   *
   * @default undefined
   */
  maxFileSize?: number

  /**
   * object-fit setting of the image
   *
   * @default contain
   */
  objectFit?: string

  /**
   * shows image as loading/uploading
   *
   * @default false
   */
  isLoading?: boolean

  /**
   * Shows a camera icon on hover and click image to set a new image (x button is not displayed)
   *
   * @default false
   */
  allowReplace?: boolean

  aspectRatio?: AspectRatios
}

export class FileDrop extends React.PureComponent<IFileDropProps> {
  render() {
    const {
      accepts,
      index,
      caption,
      borderWidth,
      noClick,
      noKeyboard,
      onFileDrop,
      onDropReset,
      onRejected,
      defaultSrc,
      objectFit,
      defaultAlt,
      maxFileSize,
      isLoading,
      allowReplace,
      aspectRatio,
    } = this.props

    const dropZoneProps = {
      accepts,
      index,
      borderWidth,
      caption,
      noClick,
      noKeyboard,
      defaultSrc,
      defaultAlt,
      objectFit,
      maxFileSize,
      isLoading,
      allowReplace,
      onDropReset: () => {
        if (onDropReset) {
          onDropReset(index)
        }
      },
      onDropAccepted: (file: File) => {
        if (onFileDrop) {
          onFileDrop(file, index)
        }
      },
      onDropRejected: (rejectionFiles: ReadonlyArray<FileRejection>) => {
        if (onRejected) {
          const files = rejectionFiles.map((rejectionFile: FileRejection) => rejectionFile.file)
          onRejected(files, index)
        }
      },
    }

    return (
      <SquareWrapper aspectRatio={aspectRatio}>
        <InnerDropzone {...dropZoneProps} />
      </SquareWrapper>
    )
  }
}

interface IInnerDropzoneProps {
  borderWidth?: number
  // tslint:disable-next-line:readonly-array
  accepts: string[] | string
  caption?: React.ReactNode
  noKeyboard?: boolean
  noClick?: boolean
  maxFileSize?: number
  objectFit?: string
  onDropAccepted?: (file: File) => void
  onDropRejected?: (files: ReadonlyArray<FileRejection>) => void
  onDropReset?: VoidFunction
  defaultSrc?: string
  defaultAlt?: string
  isLoading?: boolean
  allowReplace?: boolean
}

export const InnerDropzone = ({
  accepts,
  maxFileSize,
  borderWidth,
  caption,
  onDropAccepted,
  onDropReset,
  onDropRejected,
  objectFit = 'contain',
  noKeyboard = false,
  noClick = false,
  defaultSrc,
  defaultAlt,
  isLoading,
  allowReplace,
}: IInnerDropzoneProps) => {
  const [droppedFile, setDroppedFile] = useState(undefined)
  const [imageSrc, setImageSrc] = useState('')
  const [isReplaced, setIsReplaced] = useState(false)

  React.useEffect(() => {
    if (!isReplaced && defaultSrc) {
      setImageSrc(defaultSrc)
    }
  })

  const onInnerDropAccepted = (files) => {
    const file = files[0]
    setDroppedFile(file)
    setIsReplaced(true)
    if (onDropAccepted) {
      onDropAccepted(file)
    }
  }
  const onInnerReset = () => {
    setIsReplaced(true)
    setDroppedFile(undefined)
    if (onDropReset) {
      onDropReset()
    }
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: accepts,
    maxSize: maxFileSize,
    onDropAccepted: onInnerDropAccepted,
    onDropRejected,
    multiple: false,
    noClick,
    noKeyboard,
  })

  const useDefaultSrc = !isReplaced && imageSrc

  return droppedFile || useDefaultSrc ? (
    <DroppedFileWrapper
      objectFit={objectFit}
      data-cy-element="dropped-file-wrapper"
      data-testid="dropped-file-wrapper"
    >
      {useDefaultSrc ? (
        <img src={imageSrc} alt={defaultAlt} />
      ) : (
        droppedFile && (
          <img src={URL.createObjectURL(droppedFile)} alt={(droppedFile! as File).name} />
        )
      )}
      <DroppedFileCover />
      {isLoading && (
        <ImageLoadingCover>
          <Icon glyph="spinner" className="spinner" />
        </ImageLoadingCover>
      )}
      {allowReplace ? (
        <ReplaceImageOverlay {...getRootProps({ refKey: 'innerRef' })}>
          <CameraIconWrapper>
            <Icon glyph="camera" size={1.5} />
          </CameraIconWrapper>
          <input {...getInputProps()} />
        </ReplaceImageOverlay>
      ) : (
        <CloseIconWrapper>
          <Icon
            disabled={noClick}
            glyph="cross-heavy"
            size={1.5}
            onClick={onInnerReset}
            data-cy-element="remove-moodboard-image"
            data-testid="remove-moodboard-image"
          />
        </CloseIconWrapper>
      )}
    </DroppedFileWrapper>
  ) : (
    <EmptyFile
      data-testid="empty-moodboard-image"
      data-cy-element="empty-moodboard-image"
      borderWidth={borderWidth}
      {...getRootProps({ refKey: 'innerRef' })}
      isDragActive={isDragActive}
      noClick={noClick}
    >
      <Icon color={defaultTheme.defaultColor} disabled={noClick} glyph="plus" size={1.5} />
      {caption && <Caption>{caption}</Caption>}
      <input {...getInputProps()} />
    </EmptyFile>
  )
}

export default FileDrop
