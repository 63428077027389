import { DEFAULT_TENANT } from '@constants'

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    BRAND_APP_LOCALE_OVERRIDE: Record<string, string>
  }
}

/* eslint-disable @typescript-eslint/no-var-requires */
let messages = require('./apps/tribe/locales/en.json')

if (process.env.APP_NAME && process.env.APP_NAME !== DEFAULT_TENANT) {
  const overrides = require(`./apps/${
    process.env.APP_NAME === '__APP_NAME__' ? DEFAULT_TENANT : process.env.APP_NAME
  }/locales/en.json`)
  messages = {
    ...messages,
    ...overrides,
  }
}

if (window.BRAND_APP_LOCALE_OVERRIDE) {
  messages = { ...messages, ...window.BRAND_APP_LOCALE_OVERRIDE }
}

export default messages
