import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Link, Text } from '@tribegroup'

interface IProps {
  downloadLink: string
}

const SubmissionCardModalLicenseToast: React.FC<IProps> = ({ downloadLink }) => (
  <React.Fragment>
    <Text small medium color={Theme.grey900Color}>
      <FormattedMessage id="contentSubmission.card.modal.license.text.niceWork" />
      &nbsp;
    </Text>
    <Text small color={Theme.grey900Color}>
      <FormattedMessage id="contentSubmission.card.modal.license.text.submissionInLicensed" />
      &nbsp;
    </Text>
    <Link
      color={Theme.primaryColor}
      href={downloadLink}
      target="_blank"
      data-cy-element="toast-download-button"
    >
      <FormattedMessage id="contentSubmission.card.modal.license.link.downloadNow" />
    </Link>
  </React.Fragment>
)

export { SubmissionCardModalLicenseToast }
export default SubmissionCardModalLicenseToast
