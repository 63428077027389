import { keyframes } from 'styled-components'

export const SlideOut = keyframes`
  0% {
    transform: translate3d(-40rem, 0, 0);
  }
  100% {
    transform: translate3d(0);
  }
`
