import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Container, Text } from '@tribegroup'

const MidDot = () => (
  <Container rightOuterSpacing={0.5} inline>
    <Text color={Theme.grey700Color}>&middot;</Text>
  </Container>
)

const LocationAndGender = ({ locationAndGender }) => (
  <Text small color={Theme.grey700Color} capitalize>
    {locationAndGender}
  </Text>
)

const AgeDisplay = ({ age }) => (
  <Text small color={Theme.grey700Color}>
    {', '}
    <FormattedMessage id="submission.profile.age" values={{ age }} />
  </Text>
)

interface IInfluencerIdentityRowDetailsLocationAgeGenderProps {
  locationAndGender: string
  age: number
  showMidDot?: boolean
}

const InfluencerIdentityRowDetailsLocationAgeGender: React.FC<IInfluencerIdentityRowDetailsLocationAgeGenderProps> = ({
  locationAndGender,
  age,
  showMidDot = true,
}: IInfluencerIdentityRowDetailsLocationAgeGenderProps) => (
  <React.Fragment>
    {locationAndGender && (
      <React.Fragment>
        {showMidDot && <MidDot />}
        <LocationAndGender locationAndGender={locationAndGender} />
      </React.Fragment>
    )}
    {age && <AgeDisplay age={age} />}
  </React.Fragment>
)

export {
  InfluencerIdentityRowDetailsLocationAgeGender,
  IInfluencerIdentityRowDetailsLocationAgeGenderProps,
  MidDot,
  LocationAndGender,
  AgeDisplay,
}
export default InfluencerIdentityRowDetailsLocationAgeGender
