import { useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import ConfirmationMessageBox from '@components/UI/ConfirmationMessageBox'
import { resendVerificationRequest } from '@store/auth'
import { Button } from '@tribegroup'

interface IProps {
  email: string
  source?: 'traditional' | 'solution'
}

export const AuthCompleteSignupMessageBoxLinkExpired = ({ email, source }: IProps) => {
  const dispatch = useDispatch()
  const [buttonEnabled, setButtonEnabled] = useState(true)

  const onClick = () => {
    dispatch(
      resendVerificationRequest(
        email,
        undefined,
        <FormattedMessage id="resendVerification.confirmation.toast" />,
        source,
      ),
    )

    setButtonEnabled(false)
  }

  return (
    <ConfirmationMessageBox
      title={<FormattedMessage id="auth.completeSignup.linkExpired.title" />}
      footerCTA={
        <Button scheme="primary" onClick={onClick} disabled={!buttonEnabled}>
          {buttonEnabled ? (
            <FormattedMessage id={`resendVerification.confirmation.button.enabled`} />
          ) : (
            <FormattedMessage id={`resendVerification.confirmation.button.disabled`} />
          )}
        </Button>
      }
    >
      <FormattedMessage id="auth.completeSignup.linkExpired.body" />
    </ConfirmationMessageBox>
  )
}
