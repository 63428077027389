import { connect, ConnectedProps, useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Col } from 'react-styled-flexboxgrid'
import { compose } from 'recompose'
import { FlexItem } from 'styled-flex-component'

import { FullHr } from '@components/UI/Full/Full.styled'
import { PricePanelStyled, PricePanelWrapper } from '@components/UI/PricePanel/PricePanel.styled'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IMultiframeContextProps } from '@context/MultiframeControls'
import { EventTrackingNames, LicenseStatuses, SupportedSubmissionTypes } from '@enums'
import { withMultiframeControls, withSubmissionListFilter } from '@hocs'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { selectIsSocialCampaign } from '@store/campaigns'
import {
  selectShouldShowLicenseOptionsPrice,
  selectShouldShowLicensePrice,
  selectShouldShowLicenseRelatedPrice,
  selectTransactedLicense,
} from '@store/mediaObjects'
import { selectCampaignId } from '@store/router'
import {
  selectActiveSubmissionMediaObject,
  selectIsPriceHidden,
  selectSubmission,
} from '@store/submissions'
import { Container } from '@tribegroup'
import { getDisplayedSubmissionStatus, isEmpty } from '@utils'
import SubmissionCardPriceSummary from '../PriceSummary'
import SubmissionCardPricePanelAmount from './Amount'
import SubmissionCardPricePanelLabel from './Label'
import { SubmissionCardPriceSumaryWrapper } from './PricePanel.styled'

interface IProps {
  submissionType: SupportedSubmissionTypes
  submissionId: number
}

interface IStateProps extends IProps, IMultiframeContextProps, RouteComponentProps {}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

const SubmissionCardPricePanel: React.FC<IInternalProps> = ({
  submissionType,
  campaignId,
  showLicensePrice,
  showLicenseOptionsPrice,
  submissionId,
  mediaObjectId,
  displayedStatus,
  hasSubmission,
  submission,
}) => {
  const { toggleIsVisible, toggleHide, toggle } = hooks.useToggle()
  const { isPriceHidden } = useSelector((state: IApplicationState) => {
    return {
      isPriceHidden: selectIsPriceHidden(state, submissionId),
    }
  })

  const disableTracking = toggleIsVisible || isPriceHidden
  const hasPrice =
    !isEmpty(submission.total_amount_before_tax_cents) || !isEmpty(submission.minimum_amount_cents)

  const showAmount = hasSubmission && hasPrice

  const panelOnClick = () => {
    return isPriceHidden || !showAmount ? undefined : toggle()
  }

  const showHr = showAmount || displayedStatus

  return (
    <>
      {showHr && <FullHr />}
      <Container data-cy-element="pop-over-license-options">
        {toggleIsVisible && (
          <SubmissionCardPriceSumaryWrapper>
            <SubmissionCardPriceSummary
              mediaObjectId={mediaObjectId}
              campaignId={campaignId}
              submissionId={submissionId}
              onHide={toggleHide}
            />
          </SubmissionCardPriceSumaryWrapper>
        )}
        <ConnectedTrackedRoute
          disableTracking={disableTracking}
          eventName={EventTrackingNames.ViewPriceSummary}
          submissionId={submissionId}
          campaignId={campaignId}
        >
          <div onClick={panelOnClick} role="button">
            <PricePanelStyled clickable={showAmount}>
              <PricePanelWrapper justifyBetween alignCenter>
                <FlexItem>
                  {showAmount && (
                    <Container data-testid="submission-price-panel-amount" minHeight={1.25}>
                      <SubmissionCardPricePanelAmount
                        campaignId={campaignId}
                        showLicensePrice={showLicensePrice}
                        showLicenseOptionsPrice={showLicenseOptionsPrice}
                        mediaObjectId={mediaObjectId}
                        submissionId={submissionId}
                        submissionType={submissionType}
                      />
                    </Container>
                  )}
                </FlexItem>
                {displayedStatus && (
                  <FlexItem noShrink>
                    <SubmissionCardPricePanelLabel displayedStatus={displayedStatus} />
                  </FlexItem>
                )}
              </PricePanelWrapper>
            </PricePanelStyled>
          </div>
        </ConnectedTrackedRoute>
      </Container>
      {showHr && (
        <Col xs={12} md={false}>
          <FullHr />
        </Col>
      )}
    </>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { match, activeFrameNumber, submissionId }: IStateProps,
) => {
  const campaignId = selectCampaignId(match) as number
  const submission = selectSubmission(state, submissionId)
  const isSocial = selectIsSocialCampaign(state, campaignId)

  const mediaObject = selectActiveSubmissionMediaObject(state, submissionId, activeFrameNumber)

  const mediaObjectId = mediaObject?.id

  const transactedLicense = selectTransactedLicense(state, mediaObjectId)
  const showLicenseOptionsPrice = selectShouldShowLicenseOptionsPrice(
    state,
    mediaObjectId,
    isSocial,
  )
  const showLicensePrice = selectShouldShowLicensePrice(state, mediaObjectId, isSocial)
  const showLicenseRelatedPrice = selectShouldShowLicenseRelatedPrice(
    state,
    mediaObjectId,
    isSocial,
  )

  const displayedStatus = getDisplayedSubmissionStatus(
    submission,
    showLicenseRelatedPrice,
    transactedLicense.status as LicenseStatuses,
  )

  return {
    campaignId,
    showLicensePrice,
    showLicenseOptionsPrice,
    mediaObjectId,
    displayedStatus,
    submission,
    hasSubmission: !isEmpty(submission),
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(
  withRouter,
  withMultiframeControls,
  withSubmissionListFilter,
  connector,
)(SubmissionCardPricePanel)
