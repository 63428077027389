import React from 'react'

import { ToggleButton } from '@components/UI/Switch/Switch.styled'
import Theme from '@theme'
import { Container } from '@tribegroup'

interface IProps {
  defaulValue?: boolean
  disabled?: boolean
  onClick?: VoidFunction
}

const ConnectedAccountsDrawerContentFilledIdentitySwitch: React.FC<IProps> = ({
  defaulValue = false,
  disabled,
  onClick,
}) => {
  return (
    <Container
      width={2.5}
      height={1.5}
      pointer
      fullWidth
      backgroundColor={defaulValue ? Theme.primaryColor : Theme.grey300Color}
      borderRadius={1}
      onClick={onClick}
      relative
      cursor={!disabled ? 'pointer' : 'not-allowed'}
      data-testid="identity-toggle-action"
    >
      <ToggleButton
        width={1.25}
        height={1.25}
        borderRadius={100}
        backgroundColor={Theme.whiteColor}
        absolute
        leftPosition={!defaulValue ? 0.125 : 1.125}
        topPosition={0.125}
        data-testid="switch-toggle"
      />
    </Container>
  )
}

export default ConnectedAccountsDrawerContentFilledIdentitySwitch
