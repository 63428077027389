import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import Loading from '@components/UI/Loading'
import { BillingTypes } from '@enums'
import { IApplicationState } from '@store'
import {
  fetchCampaignBillings,
  IPurchase,
  selectIsFetchingBilling,
  selectPurchaseListByMonth,
} from '@store/billings'
import { selectBillingType, selectCampaignId } from '@store/router'
import Theme from '@theme'
import { H3 } from '@tribegroup'
import PurchaseBreakdown from './Breakdown/Breakdown'
import PurchaseCampaignWrapper from './Wrapper/CampaignWrapper'
import PurchaseContentLibraryWrapper from './Wrapper/ContentLibraryWrapper'

interface IInternalProps {
  purchasesByMonth: Record<string, ReadonlyArray<IPurchase>>
  billingType: BillingTypes
  isLoading?: boolean
}

const Purchase: React.FC<IInternalProps> = ({ purchasesByMonth, billingType, isLoading }) => {
  const PurchaseWrapper =
    billingType === BillingTypes.Campaigns ? PurchaseCampaignWrapper : PurchaseContentLibraryWrapper

  return (
    <PurchaseWrapper>
      <H3
        color={Theme.grey900Color}
        topOuterSpacing={0.5}
        bottomOuterSpacing={2.5}
        leftOuterSpacing={0.625}
      >
        <FormattedMessage id={`core.text.purchases.${billingType}`} />
      </H3>
      {isLoading ? (
        <Loading />
      ) : (
        Object.keys(purchasesByMonth).map((month) => (
          <PurchaseBreakdown
            key={`breakdown-${month}`}
            month={month}
            purchases={purchasesByMonth[month]}
          />
        ))
      )}
    </PurchaseWrapper>
  )
}

const mapStateToProps = (state: IApplicationState, { match, location }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  const billingType = selectBillingType(location)
  return {
    billingType,
    purchasesByMonth: selectPurchaseListByMonth(state, billingType, campaignId),
    isLoading: selectIsFetchingBilling(state),
  }
}

const mapDispatchToProps = {
  fetchCampaignBillings,
}

export { Purchase }
export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Purchase)
