import React from 'react'

import { Link, LinkProps } from 'react-router-dom'

import RouterLink from '../RouterLink/RouterLink.styled'

interface IConditionalLink extends LinkProps {
  showLink?: boolean
  children: React.ReactNode
}

const ConditionalLink = ({ showLink, children, ...linkProps }: IConditionalLink) =>
  showLink ? <Link {...linkProps}>{children}</Link> : <React.Fragment>{children}</React.Fragment>

const ConditionalRouterLink = ({ showLink, children, ...linkProps }: IConditionalLink) =>
  showLink ? (
    <RouterLink {...linkProps}>{children}</RouterLink>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  )

export { ConditionalRouterLink }
export default ConditionalLink
