import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import SubmissionThumbnailStatusLabel from '.'

const SubmissionThumbnailStatusLabelPostOverdue: React.FC = () => (
  <SubmissionThumbnailStatusLabel
    firstText={<FormattedMessage id="submission.card.publishSchedule.post" />}
    secondText={<FormattedMessage id="submission.card.publishSchedule.overdue" />}
    color={Theme.errorColor}
  />
)

export default SubmissionThumbnailStatusLabelPostOverdue
