import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { Container, Icon } from '@tribegroup'

const InboxReferralToast: React.FC = () => (
  <Flex center>
    <Container rightOuterSpacing={1}>
      <FormattedMessage id="inbox.referral.modal.toast.copied" />
    </Container>
    <Icon glyph="tick" />
  </Flex>
)

export default InboxReferralToast
