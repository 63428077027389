import { useContext, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { CampaignContext } from '@context/Campaign'
import { ModalProvider } from '@context/Modal'
import { MemberRoleTypes } from '@enums'
import { fetchIdentities, selectBrandManagerIdentities } from '@store/brandManagerIdentities'
import { Container } from '@tribegroup'
import { ConnectedAccountsDrawerCollaborator } from './Collaborator/Collaborator'
import { ConnectedAccountsDrawerIdentityList } from './Identity/List'
import { ConnectedAccountsDrawerFooter } from './Footer'
import { ConnectedAccountsDrawerTitle } from './Title'

const ConnectedAccountsDrawer = () => {
  const {
    campaign: { role: brandManagerRole, id: campaignId },
  } = useContext(CampaignContext)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchIdentities({ campaignId }))
  }, [])

  const brandManagerIdentities = useSelector(selectBrandManagerIdentities)

  if (
    [MemberRoleTypes.Collaborator, MemberRoleTypes.SolutionCollaborator].includes(brandManagerRole)
  ) {
    return (
      <ConnectedAccountsDrawerCollaborator>
        {brandManagerRole === MemberRoleTypes.SolutionCollaborator && (
          <ConnectedAccountsDrawerFooter />
        )}
      </ConnectedAccountsDrawerCollaborator>
    )
  }

  return (
    <ModalProvider>
      <Container>
        <ConnectedAccountsDrawerTitle />
        <ConnectedAccountsDrawerIdentityList brandManagerIdentities={brandManagerIdentities} />
        <ConnectedAccountsDrawerFooter />
      </Container>
    </ModalProvider>
  )
}

export { ConnectedAccountsDrawer }
