import { useContext, useEffect } from 'react'

import Currency from 'react-currency-formatter'
import { FormattedMessage, FormattedNumber, InjectedIntlProps, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import MetricsGrid from '@components/Submission/Metrics/Metrics.styled'
import DetailCard from '@components/UI/DetailCard'
import Loading from '@components/UI/Loading'
import { CampaignContext } from '@context/Campaign'
import { IApplicationState } from '@store'
import { selectIsPriceHidden } from '@store/campaigns'
import { fetchPublishedSummary } from '@store/publishedSummary'
import {
  selectIsFetching,
  selectTotalEngagement,
  selectTotalFollowers,
} from '@store/publishedSummary/selectors'
import { isTruthyOrZero } from '@utils'
import { getCPE } from '@utils/metrics'

const InboxTopPanelMetricsPublished: React.FC<InjectedIntlProps> = ({ intl }) => {
  const dispatch = useDispatch()

  const { campaign, isCampaignFetched } = useContext(CampaignContext)

  useEffect(() => {
    if (isCampaignFetched) {
      dispatch(fetchPublishedSummary(campaign.id))
    }
  }, [isCampaignFetched, campaign.id])

  const { totalFollowers, totalEngagement, isFetching, isPriceHidden } = useSelector(
    (state: IApplicationState) => {
      return {
        totalEngagement: selectTotalEngagement(state, campaign.id),
        totalFollowers: selectTotalFollowers(state, campaign.id),
        isFetching: useSelector(selectIsFetching),
        isPriceHidden: selectIsPriceHidden(state, campaign.id),
      }
    },
  )

  if (isFetching) {
    return <Loading />
  }

  const {
    budget_spent_approvals_cents: totalSpendAmountCents,
    budget_spent_approvals_currency: currency,
  } = campaign

  const totalCostPerEngagementAmount =
    totalSpendAmountCents && totalEngagement && getCPE(totalSpendAmountCents, totalEngagement)

  const showCPE = !isPriceHidden

  return (
    <MetricsGrid>
      {!isPriceHidden && (
        <DetailCard
          title={
            <FormattedMessage
              id="inbox.submission.metrics.spend.published"
              values={{
                taxName: intl.formatMessage({
                  id: `core.currency.taxDisplay.${currency ?? 'generic'}`,
                }),
              }}
            />
          }
          content={
            isTruthyOrZero(totalSpendAmountCents) ? (
              <Currency
                currency={currency}
                quantity={totalSpendAmountCents! / 100}
                pattern="!###,### "
              />
            ) : (
              <FormattedMessage id="core.metric.empty" />
            )
          }
          elementName="inbox-published-filter-spend"
        />
      )}

      <DetailCard
        title={<FormattedMessage id="inbox.submission.metrics.collectiveReach" />}
        content={
          isTruthyOrZero(totalFollowers) ? (
            <FormattedNumber value={totalFollowers!} />
          ) : (
            <FormattedMessage id="core.metric.empty" />
          )
        }
        elementName="inbox-published-filter-collective-reach"
      />
      <DetailCard
        title={<FormattedMessage id="inbox.submission.metrics.engagement.published" />}
        content={
          isTruthyOrZero(totalEngagement) ? (
            <FormattedNumber value={totalEngagement!} />
          ) : (
            <FormattedMessage id="core.metric.empty" />
          )
        }
        elementName="inbox-published-filter-engagement"
      />
      {showCPE && (
        <DetailCard
          title={<FormattedMessage id="inbox.submission.metrics.costPerEngagement.published" />}
          content={
            isTruthyOrZero(totalCostPerEngagementAmount) ? (
              <Currency currency={currency} quantity={totalCostPerEngagementAmount} />
            ) : (
              <FormattedMessage id="core.metric.empty" />
            )
          }
          elementName="inbox-published-filter-cost-per-engagement"
        />
      )}
    </MetricsGrid>
  )
}

export { InboxTopPanelMetricsPublished }
export default injectIntl(InboxTopPanelMetricsPublished)
