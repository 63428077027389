import { useContext } from 'react'

import { useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import { SubmissionContext } from '@context/Submission/Provider'
import { IApplicationState } from '@store'
import { selectHasCreatorNotes } from '@store/submissions'
import SubmissionCreatorNotePanelHeader from '../Header/Header'
import SubmissionCreatorNotePopup from '../Popup/Popup'
import { SubmissionCreatorNotePanelStyled } from './Panel.styled'

export const SubmissionCreatorNotePanel: React.FC = () => {
  const { submission } = useContext(SubmissionContext)
  const { hasCreatorNotes } = useSelector((state: IApplicationState) => ({
    hasCreatorNotes: selectHasCreatorNotes(state, submission.id),
  }))
  return (
    <SubmissionCreatorNotePanelStyled xs={7}>
      <Flex justifyBetween>
        <SubmissionCreatorNotePanelHeader />
        {hasCreatorNotes && <SubmissionCreatorNotePopup />}
      </Flex>
    </SubmissionCreatorNotePanelStyled>
  )
}

export default SubmissionCreatorNotePanel
