import React from 'react'

import { ILicenseOption } from '@store/campaigns'
import { FormGroup, Radio, SelectionSubtext } from '@tribegroup'

export interface ISubmissionCardModalLicenseOptions {
  onLicenseChange: (event: React.FormEvent<HTMLInputElement>) => void
  licenses: ReadonlyArray<ILicenseOption>
}

const SubmissionCardModalLicenseSelection = ({
  onLicenseChange,
  licenses,
}: ISubmissionCardModalLicenseOptions) => {
  return (
    <FormGroup>
      {Object.values(licenses).map((license, index) => (
        <React.Fragment key={license.label || `label${index}`}>
          <Radio
            onChange={onLicenseChange}
            scheme="primary"
            label={license.label}
            defaultChecked={index === 0}
            name="licensingOption"
            labelCapitalize
            data-license-index={index}
            elementName={license.license_type}
          />
          <SelectionSubtext>{license.description}</SelectionSubtext>
        </React.Fragment>
      ))}
    </FormGroup>
  )
}

export default SubmissionCardModalLicenseSelection
