import styled, { css } from 'styled-components'

import { IHeaderProps } from './Header'

export default styled<IHeaderProps, any>('header')`
  ${({
    color,
    small,
    cta,
    uppercase,
    inline,
    leftOuterSpacing,
    rightOuterSpacing,
    bottomOuterSpacing,
    topOuterSpacing,
  }) => css`
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 1.5px;
    ${color &&
      css`
        color: ${color};
      `};

    ${small &&
      css`
        font-size: 0.8125rem;
        line-height: 1.23;
      `};

    ${cta &&
      css`
        font-size: 0.875rem;
        line-height: 1.43;
        letter-spacing: 1.5px;

        ${small &&
          css`
            font-size: 0.75rem;
            line-height: 1.33;
          `};
      `};

    ${uppercase &&
      css`
        text-transform: uppercase;
      `};

    ${inline &&
      css`
        display: inline;
        line-height: normal;
      `};
    ${leftOuterSpacing &&
      css`
        margin-left: ${leftOuterSpacing}rem;
      `};
    ${rightOuterSpacing &&
      css`
        margin-right: ${rightOuterSpacing}rem;
      `};
    ${topOuterSpacing &&
      css`
        margin-top: ${topOuterSpacing}rem;
      `};
    ${bottomOuterSpacing &&
      css`
        margin-bottom: ${bottomOuterSpacing}rem;
      `};
    ${({ textAlign }) =>
      textAlign &&
      css`
        display: block;
        text-align: ${textAlign};
      `};
    ${({ fullWidth }) =>
      fullWidth &&
      css`
        width: 100%;
      `}
  `};
`
