import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore, Middleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import { PersistConfig, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { createTracker } from 'redux-segment'

import rootReducer from '@store'
import { getPageMapper } from '@utils'
import getConfig from '@utils/getConfig'
import { rootSaga } from './sagas'

export const history = createBrowserHistory()

export default function configureStore() {
  // tslint:disable-next-line:no-let
  let composeEnhancers = compose
  const loggerMiddleware = createLogger({ collapsed: true })
  const sagaMiddleware = createSagaMiddleware()

  const persistConfig: PersistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'rootResource', 'feedbackDraft'],
  }

  // tslint:disable-next-line:no-let
  let middlewares: ReadonlyArray<Middleware> = [sagaMiddleware, createTracker(getPageMapper())]

  if (getConfig('devtoolsEnabled')) {
    middlewares = middlewares.concat(loggerMiddleware)
    composeEnhancers = composeWithDevTools({})
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer())

  const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middlewares)))

  const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  return { store, persistor }
}
