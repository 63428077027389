export const selectPrevId = (ids: ReadonlyArray<number>, id: number) => {
  return ids[ids.indexOf(id) - 1]
}

export const selectNextId = (ids: ReadonlyArray<number>, id: number) => {
  return ids[ids.indexOf(id) + 1]
}

export const selectOneAfterNextId = (ids: ReadonlyArray<number>, id: number) => {
  return ids[ids.indexOf(id) + 2]
}
