import { SubmissionChannels } from '@enums'
import { IChannel } from '@store/campaigns'

export const removeChannel = (channelItems: Record<string, any>, channelName: string) => {
  return Object.keys(channelItems).reduce(
    (accum, curr) => (curr === channelName ? accum : { ...accum, [curr]: channelItems[curr] }),
    {},
  )
}

export const toChannelsArray = (channelItems: Record<string, any>) => {
  return Object.keys(channelItems).map((channelName) => ({
    name: channelName,
    ...(channelItems[channelName] || {}),
  }))
}

export const toChannelsObject = (channels?: ReadonlyArray<IChannel>) => {
  return (channels || []).reduce((accum, curr) => {
    const { name, ...rest } = curr
    return {
      ...accum,
      [name]: rest,
    }
  }, {})
}

export const getChannelInitialState = (channelName: string) => {
  switch (channelName) {
    case SubmissionChannels.TikTok:
    case SubmissionChannels.Instagram:
      return {
        tags: [],
      }
    case SubmissionChannels.Facebook: {
      return {
        tags: [],
        handles: [],
        urls: [],
      }
    }
    case SubmissionChannels.Twitter: {
      return {
        tags: [],
        urls: [],
      }
    }
    case SubmissionChannels.Pinterest: {
      return { tags: [], handles: [] }
    }
    case SubmissionChannels.X: {
      return { tags: [], handles: [], requested_social_submission_types: [] }
    }

    case SubmissionChannels.YouTube: {
      return { tags: [], handles: [], requested_social_submission_types: [] }
    }
    default:
      return undefined
  }
}

export const moveHandlesToTags = (channels) => {
  return Object.keys(channels).reduce((accum, channelName) => {
    const channel = channels[channelName]
    return {
      ...accum,
      [channelName]: {
        ...channel,
        tags: [...channel?.tags, ...(channel?.handles || [])],
        handles: undefined,
      },
    }
  }, {})
}

export const getAllAvailableTags = (channel) => {
  return [...(channel?.tags || []), ...(channel?.handles || []), ...(channel?.urls || [])]
}
