import { FormattedMessage } from 'react-intl'

import { NoticeUnsupportedScreen } from '@components/UI/Notice'

export const InfluencerIdentityLayoutUnsupportedScreen = () => {
  return (
    <NoticeUnsupportedScreen
      subtitle={<FormattedMessage id="core.text.tryUsingDesktop" />}
      alt="influencer-view-unsupported-screen"
      title={<FormattedMessage id="submission.influencer.unsupportedScreen.subtitle" />}
    />
  )
}

export default InfluencerIdentityLayoutUnsupportedScreen
