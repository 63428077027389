import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  IBrand,
  selectUpdateBrandLink,
  updateBrand,
  UpdateBrandActionTypes,
  updateBrandError,
  updateBrandSuccess,
} from '@store/brands'
import { networkRequest } from '@utils'

export function* handleUpdateBrand(action: ReturnType<typeof updateBrand>) {
  try {
    const { brandId, params } = action.payload
    const { history, redirect, toast, event } = action.meta
    const authToken: string = yield select(selectAuthToken)
    const updateBrandLink: IHateoasLink = yield select(selectUpdateBrandLink, brandId)
    const brand: IBrand = yield call(
      networkRequest,
      updateBrandLink.method,
      updateBrandLink.href,
      params,
      authToken,
    )
    yield put(updateBrandSuccess(brand, { toast }))
    yield put(trackEvent(event.eventPayload.event, event.eventPayload.properties))
    if (history && redirect) {
      yield call(history.push, `${redirect}${history.location.search}`)
    }
  } catch (error) {
    yield put(updateBrandError(error))
  }
}

export function* watchUpdateBrandRequest() {
  yield takeLatest(UpdateBrandActionTypes.UPDATE_REQUEST, handleUpdateBrand)
}
