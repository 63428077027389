import { PureComponent } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { FullGridArea, FullHr, FullStyled } from '@components/UI/Full/Full.styled'
import FullLoading from '@components/UI/Full/Loading'
import {
  fetchMediaObject,
  selectIsLoadingFullPage,
  selectIsLoadingLicenseOptions,
} from '@store/mediaObjects'
import { selectContentLibraryFilter, selectMediaObjectId } from '@store/router'
import { Container } from '@tribegroup'
import MediaObjectActionBar from '../Card/ActionBar/ActionBar'
import MediaObjectFullDetails from './Details/Details'
import MediaObjectFullHeader from './Header/Header'
import MediaObjectFullMedia from './Media/Media'
import MediaObjectFullPricePanel from './PricePanel/PricePanel'

interface IInternalProps {
  fetchMediaObject: typeof fetchMediaObject
  mediaObjectId: number
  isLoadingLicenseOptions: boolean
  isLoading: boolean
}

export class MediaObjectFull extends PureComponent<IInternalProps> {
  componentDidMount() {
    this.props.fetchMediaObject(this.props.mediaObjectId)
  }
  render() {
    if (this.props.isLoading) {
      return <FullLoading alternatePricePosition />
    }

    return (
      <FullStyled>
        <FullGridArea name="header">
          <Container leftOuterSpacing={0.25} rightOuterSpacing={0.25}>
            <MediaObjectFullHeader />
          </Container>
        </FullGridArea>
        <FullGridArea name="media">
          <MediaObjectFullMedia />
        </FullGridArea>
        <FullGridArea name="actions">
          <Container leftOuterSpacing={0.5} rightOuterSpacing={0.5}>
            <MediaObjectActionBar mediaObjectId={this.props.mediaObjectId} />
          </Container>
        </FullGridArea>
        <FullGridArea name="price">
          {!this.props.isLoadingLicenseOptions && <FullHr />}
          <MediaObjectFullPricePanel
            key={this.props.mediaObjectId}
            mediaObjectId={this.props.mediaObjectId}
          />
          <FullHr />
        </FullGridArea>
        <FullGridArea name="details" cssOverflow>
          <MediaObjectFullDetails />
        </FullGridArea>
      </FullStyled>
    )
  }
}

const mapStateToProps = (state, { match }: RouteComponentProps) => {
  const filter = selectContentLibraryFilter(state)
  const mediaObjectId = selectMediaObjectId(match) as number
  return {
    mediaObjectId,
    isLoading: selectIsLoadingFullPage(state, filter, mediaObjectId),
    isLoadingLicenseOptions: selectIsLoadingLicenseOptions(state, mediaObjectId!),
  }
}

const mapDispatchToProps = {
  fetchMediaObject,
}

export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MediaObjectFull)
