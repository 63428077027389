import { IResponseError } from '@lib/error'
import { IHateoasLink } from '@store'

export enum FetchBrandsActionTypes {
  FETCH_REQUEST = 'brands/FETCH_REQUEST',
  FETCH_SUCCESS = 'brands/FETCH_SUCCESS',
  FETCH_ERROR = 'brands/FETCH_ERROR',
}

export enum AddBrandActionTypes {
  ADD_REQUEST = 'brand/ADD_REQUEST',
  ADD_SUCCESS = 'brand/ADD_SUCCESS',
  ADD_ERROR = 'brand/ADD_ERROR',
}

export enum UpdateBrandActionTypes {
  UPDATE_REQUEST = 'brand/UPDATE_REQUEST',
  UPDATE_SUCCESS = 'brand/UPDATE_SUCCESS',
  UPDATE_ERROR = 'brand/UPDATE_ERROR',
}

export interface IBrand {
  readonly id: number
  readonly name: string
  readonly logo_url: string
  readonly previous_campaigns_count: number
  readonly links: ReadonlyArray<IHateoasLink>
}

export interface IBrandsState {
  readonly links: ReadonlyArray<IHateoasLink>
  readonly isFetching: boolean
  readonly hasFetched: boolean
  readonly isAdding: boolean
  readonly isUpdating: boolean
  readonly brandsById: Record<number, IBrand>
  readonly errors?: IResponseError
}
