import { useEffect } from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { BrandFanGroupMemberWarningProvider } from '@context/BrandFanGroupMemberWarning'
import { IModalComponentProps } from '@context/Modal'
import { BrandFanFilters, FeatureToggles } from '@enums'
import { IApplicationState } from '@store'
import {
  fetchBrandFanGroups,
  selectIdentityFirstName,
  selectIdentityGroupIds,
  selectIsListEmpty,
} from '@store/brandFans'
import {
  selectBrandFanGroupsFromSubmission,
  selectIdentityBrandFanGroupIds,
  selectIdentityFirstName as selectIdentityFirstNameFromSubmission,
} from '@store/submissions'
import { fetchBrandFansMembership } from '@store/submissions/actions/fetchSubmissionBrandFanMembership'
import { CloseIcon, Modal, ModalTitle } from '@tribegroup'
import BrandFansInfluencerIdentityGroupsEmpty from './Empty'
import BrandFansInfluencerIdentityFormAndWarningWrapper from './FormAndWarningWrapper'
import { MainContainer } from './Groups.styled'
import BrandFansInfluencerIdentityGroupsList from './List'

interface IProps extends IModalComponentProps {
  identityId: number
  influencerId?: number
  submissionId?: number
  campaignId?: number
}

const BrandFansInfluencerIdentityGroups: React.FC<IProps> = ({
  identityId,
  onRequestClose,
  isOpen = false,
  submissionId,
  campaignId,
  influencerId,
}) => {
  const dispatch = useDispatch()
  const isContentSubmissionSupported = useFlag(
    FeatureToggles.BRAND_FANS_FROM_CONTENT_CAMPAIGNS_ENABLED,
  )
  const { isListEmpty, firstName, groupIds } = useSelector((state: IApplicationState) => {
    let groupIds = selectIdentityGroupIds(state, identityId)

    if (submissionId) {
      groupIds = isContentSubmissionSupported
        ? selectBrandFanGroupsFromSubmission(state, submissionId)
        : selectIdentityBrandFanGroupIds(state, submissionId)
    }
    return {
      isListEmpty: selectIsListEmpty(state, BrandFanFilters.Groups),
      firstName: submissionId
        ? selectIdentityFirstNameFromSubmission(state, submissionId)
        : selectIdentityFirstName(state, identityId),
      groupIds,
    }
  })

  useEffect(() => {
    dispatch(fetchBrandFanGroups())
    if (submissionId && isContentSubmissionSupported) {
      dispatch(fetchBrandFansMembership(submissionId))
    }
  }, [])

  return (
    <Modal reactModalProps={{ isOpen }} width={27.5} complex>
      <BrandFanGroupMemberWarningProvider>
        <MainContainer data-testid="brand-fan-groups-modal">
          <CloseIcon onClick={onRequestClose} />
          <ModalTitle bottomOuterSpacing={1.25}>
            <FormattedMessage id="brandFans.identityGroups.title" />
          </ModalTitle>
          {isListEmpty ? (
            <BrandFansInfluencerIdentityGroupsEmpty />
          ) : (
            <BrandFansInfluencerIdentityGroupsList
              identityId={identityId}
              submissionId={submissionId}
              campaignId={campaignId}
              identityGroupIds={groupIds}
              influencerId={influencerId}
            />
          )}
          <BrandFansInfluencerIdentityFormAndWarningWrapper
            identityId={identityId}
            submissionId={submissionId}
            campaignId={campaignId}
            firstName={firstName}
            groupMembershipsCount={groupIds.length}
            influencerId={influencerId}
          />
        </MainContainer>
      </BrandFanGroupMemberWarningProvider>
    </Modal>
  )
}

export { BrandFansInfluencerIdentityGroups }
export default BrandFansInfluencerIdentityGroups
