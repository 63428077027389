import React from 'react'

import ShareableContentLibraryFull from '@components/ShareableContentLibrary/Full/Full'
import ShareableContentLibraryFullTitleBar from '@components/ShareableContentLibrary/Full/TitleBar'
import ShareableContentLibraryNavigation from '@components/ShareableContentLibrary/Navigation'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import SinglePageContainer from '@components/UI/SinglePageContainer/SinglePageContainer'
import { withConnected404Page } from '@hocs'
import ShareableContentLibrary404 from '@pages/ShareableContentLibrary/404'
import { selectMediaObjectErrors } from '@store/mediaObjects'

export const ShareableMediaObjectPage: React.FC = () => (
  <MainLayout data-testid="shareable-media-object-page">
    <HeadTag id="contentLibrary.shareable.header" />
    <ShareableContentLibraryFullTitleBar />
    <SinglePageContainer>
      <ShareableContentLibraryNavigation>
        <ShareableContentLibraryFull />
      </ShareableContentLibraryNavigation>
    </SinglePageContainer>
  </MainLayout>
)

const connected404Props = {
  component: ShareableContentLibrary404,
  selector: selectMediaObjectErrors,
}

export default withConnected404Page(connected404Props)(ShareableMediaObjectPage)
