import React from 'react'

import { getThumbnailIcon } from '@components/Submission/Card/Thumbnail/Thumbnail'
import { MediaTypes, SocialSubmissionTypes } from '@enums'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'

interface IProps {
  mediaType: MediaTypes
  socialSubmissionType: SocialSubmissionTypes
}

const OverlayIcon: React.FC<IProps> = ({ mediaType, socialSubmissionType }) => {
  const glyphIcon = getThumbnailIcon(mediaType, socialSubmissionType)
  if (glyphIcon) {
    return (
      <Container absolute bottomPosition={0.5} leftPosition={1}>
        <Icon
          shadowed
          size={1.5}
          glyph={glyphIcon}
          opacity={0.9}
          color={Theme.whiteColor}
          data-testid="submission-glyph-icon"
        />
      </Container>
    )
  }
  return null
}

export { OverlayIcon }
export default OverlayIcon
