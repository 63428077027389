import React, { PropsWithChildren } from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import {
  activate,
  downloadInstagramHandles,
  pause,
  selectCampaignLink,
  selectCampaignTitle,
  selectInternalEditLink,
  selectShowDownloadInstagramHandlesButton,
} from '@store/campaigns'
import CampaignCardModalActivate from '../Card/Modal/Activate/Activate'
import CampaignCardModalPause from '../Card/Modal/Pause/Pause'

interface IProps {
  campaignId: number
}

interface ICampaignMenuActionsContextProps extends IProps {
  onOpenConfirmModal: VoidFunction
  onOpenPauseModal: VoidFunction
  onDownloadInstagramHandles: VoidFunction
  hasActivateLink: boolean
  hasPauseLink: boolean
  hasDownloadInstagramHandles: boolean
  hasAnyAction: boolean
  editLink: string
}

interface IInternalProps extends ICampaignMenuActionsContextProps {
  pause: typeof pause
  activate: typeof activate
  downloadInstagramHandles: typeof downloadInstagramHandles
  campaignTitle: string
}

const CampaignMenuActionsContext = React.createContext({} as ICampaignMenuActionsContextProps)

const CampaignMenuActionsProvider = ({
  campaignId,
  pause: pauseAction,
  activate: activateAction,
  downloadInstagramHandles: downloadInstagramHandlesAction,
  hasActivateLink,
  hasPauseLink,
  hasDownloadInstagramHandles,
  hasAnyAction,
  children,
  editLink,
  campaignTitle,
}: PropsWithChildren<IInternalProps>) => {
  const [isOpenModalActivate, setIsOpenModalActivate] = React.useState(false)
  const [isOpenModalPause, setIsOpenModalPause] = React.useState(false)

  const onActivate = () => {
    activateAction(campaignId)
    onCancelModal()
  }

  const onCancelModal = () => {
    setIsOpenModalActivate(false)
    setIsOpenModalPause(false)
  }

  const onDownloadInstagramHandles = () => {
    downloadInstagramHandlesAction(campaignId, `${campaignTitle} ${Date.now()}`)
  }

  const onOpenConfirmModal = () => {
    setIsOpenModalActivate(true)
  }

  const onOpenPauseModal = () => {
    setIsOpenModalPause(true)
  }

  const onPause = () => {
    pauseAction(campaignId)
    onCancelModal()
  }

  return (
    <CampaignMenuActionsContext.Provider
      value={{
        campaignId,
        onOpenConfirmModal,
        onOpenPauseModal,
        onDownloadInstagramHandles,
        hasActivateLink,
        hasPauseLink,
        hasDownloadInstagramHandles,
        hasAnyAction,
        editLink,
      }}
    >
      <CampaignCardModalActivate
        campaignId={campaignId}
        onConfirm={onActivate}
        onCancel={onCancelModal}
        isOpen={isOpenModalActivate}
      />
      <CampaignCardModalPause
        onConfirm={onPause}
        onCancel={onCancelModal}
        isOpen={isOpenModalPause}
      />
      {children}
    </CampaignMenuActionsContext.Provider>
  )
}

const mapStateToProps = (state: IApplicationState, { campaignId }) => {
  const hasActivateLink = Boolean(selectCampaignLink(state, campaignId, 'activate'))
  const hasPauseLink = Boolean(selectCampaignLink(state, campaignId, 'pause'))
  const hasDownloadInstagramHandles = selectShowDownloadInstagramHandlesButton(state, campaignId)
  return {
    hasActivateLink,
    hasPauseLink,
    hasDownloadInstagramHandles,
    hasAnyAction: [hasActivateLink, hasPauseLink, hasDownloadInstagramHandles].some(Boolean),
    editLink: selectInternalEditLink(state, campaignId),
    campaignTitle: selectCampaignTitle(state, campaignId),
  }
}

const mapDispatchToProps = {
  pause,
  activate,
  downloadInstagramHandles,
}

export { CampaignMenuActionsContext, CampaignMenuActionsProvider }
export default compose<IInternalProps, PropsWithChildren<IProps>>(
  connect(mapStateToProps, mapDispatchToProps),
)(CampaignMenuActionsProvider)
