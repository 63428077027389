import React from 'react'

import { ActionBarRight, ActionBarWrapper } from '@components/UI/Card/Card.styled'
import { ISubmission } from '@store/submissions'
import ActionBarIconChat from '../Icon/Chat'
import ActionBarIconLicense from '../Icon/License'

interface IProps {
  campaignId: number
  submission: ISubmission
  hasFeedbackLink: boolean
}

const SubmissionCardActionBarSocialLicenseRequests: React.FC<IProps> = ({
  hasFeedbackLink,
  submission,
  campaignId,
}) => {
  const submissionId = submission.id
  return (
    <ActionBarWrapper justifyEnd>
      <ActionBarRight>
        {hasFeedbackLink && <ActionBarIconChat submission={submission} campaignId={campaignId} />}
        <ActionBarIconLicense submissionId={submissionId} />
      </ActionBarRight>
    </ActionBarWrapper>
  )
}

export default SubmissionCardActionBarSocialLicenseRequests
