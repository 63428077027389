import styled, { css } from 'styled-components'

import { themed } from '../Theme'

export const PointWrapper = styled.div`
  position: absolute;
  width: 5rem;
  left: calc(${({ left }) => left}% - 2.5rem);
  text-align: center;
`

export const LabelWrapper = styled.div`
  padding: 0 0.125rem;
  margin-top: 0.75rem;
  height: 1.25rem;
  position: relative;
  width: 100%;
  ${PointWrapper} {
    &:first-of-type {
      text-align: left;
      left: 0;
    }

    &:last-of-type {
      text-align: right;
      left: auto;
      right: 0;
    }
  }
`

export const SinglePlotDisabledIcon = themed(styled.div`
  ${({ theme, value, minValue, maxValue }) =>
    css`
      position: absolute;
      top: 0;
      height: 2.2125rem;
      display: flex;
      justify-content: center;
      align-items: center;
      left: calc(${((value - minValue) / (maxValue - minValue)) * 100}% + 0.1875rem);

      svg {
        fill: ${theme.primaryColor};
      }
    `}
`)
