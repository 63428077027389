import React, { PropsWithChildren } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IInfluencerIdentityIdContextProps } from '@context/InfluencerIdentityId'
import { ISelectedSubmissionsContextProps } from '@context/SelectedSubmissions'
import { EventTrackingNames } from '@enums'
import { withContextInfluencerIdentityId, withSelectedSubmissions } from '@hocs'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { selectCampaignId } from '@store/router'
import { objectToQueryString } from '@utils'
import { buildSubmissionUrlById } from '@utils/urls'

interface ISubmissionLinkToDetailsProps {
  submissionId: number
  selected?: boolean
}

interface IInternalProps
  extends ISubmissionLinkToDetailsProps,
    ISelectedSubmissionsContextProps,
    IInfluencerIdentityIdContextProps {
  submissionLink: string
  campaignId: number
}

const SubmissionLinkToDetails: React.FC<PropsWithChildren<IInternalProps>> = ({
  campaignId,
  submissionId,
  isSelectionModeOn,
  selected,
  selectSubmission,
  deselectSubmission,
  children,
  contextInfluencerIdentityId,
}) => {
  const searchParams = hooks.useLocationSearch()
  const onClick = (event: React.SyntheticEvent) => {
    if (isSelectionModeOn) {
      event.preventDefault()
      selected ? deselectSubmission(submissionId) : selectSubmission(submissionId)
    }
  }
  const submissionLink = buildSubmissionUrlById(campaignId, submissionId) as string
  const submissionSource = hooks.useIsInfluencerView() ? 'inbox_list_view' : 'inbox'

  return (
    <ConnectedTrackedRoute
      disableTracking={selected}
      campaignId={campaignId}
      submissionId={submissionId}
      eventName={EventTrackingNames.ViewSubmission}
      additionalProperties={{ source: submissionSource }}
    >
      <Link
        to={{
          pathname: submissionLink,
          search: objectToQueryString({
            ...searchParams,
            influencerId: contextInfluencerIdentityId,
          }),
        }}
        onClick={onClick}
      >
        {children}
      </Link>
    </ConnectedTrackedRoute>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => ({
  campaignId: selectCampaignId(match),
})

export { SubmissionLinkToDetails }
export default compose<IInternalProps, PropsWithChildren<ISubmissionLinkToDetailsProps>>(
  withContextInfluencerIdentityId,
  withRouter,
  withSelectedSubmissions,
  connect(mapStateToProps),
)(SubmissionLinkToDetails)
