import { useContext } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { PAID_PARTNERSHIP_LEARN_HOW } from '@constants'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { EventTrackingNames, SubmissionChannels } from '@enums'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import { IChannel } from '@store/campaigns'
import {
  selectChannelPaidPartnershipHandle,
  selectDraft,
  selectHasChannelBrandPartnershipHandle,
} from '@store/drafts'
import Theme from '@theme'
import { Container, FormInput, H4, Icon, Link, Text, TextField } from '@tribegroup'
import { toChannelsObject } from '@utils/channels'
import { ChannelsContext } from '../../Channels'
import { NoWrapLinkWrapper, Tag } from '../../Channels.styled'
import BuilderBriefChannelsInstagramHandleInput from '../HandleInput'
import BuilderBriefChannelsInstagramPaidPartershipInformation from './Information'
import BuilderBriefChannelsInstagramPaidPartershipNoInstagramCheckBox from './NoInstagramCheckBox'
import BuilderBriefChannelsInstagramPaidPartershipTip from './Tip'

const ADJUSTED_WIDTH = 'calc(100% - 5rem)'

export interface IInstagramPaidPartershipResults {
  has_brand_partnership_handle?: boolean | ''
  paid_partnership_handle?: string
  channels?: IChannel[]
}

const BuilderBriefChannelsInstagramPaidPartership: React.FC<IBuilderValidationContextProps> = ({
  clearFieldValidation,
  updateForm,
}) => {
  const { setChannelItems } = useContext(ChannelsContext)

  const { draft, paidPartnershipHandle, hasBrandPartnershipHandle } = useSelector(
    (state: IApplicationState) => {
      const draft = selectDraft(state)
      const paidPartnershipHandle = selectChannelPaidPartnershipHandle(
        state,
        SubmissionChannels.Instagram,
      )
      const hasBrandPartnershipHandle = selectHasChannelBrandPartnershipHandle(
        state,
        SubmissionChannels.Instagram,
      )

      return {
        draft,
        paidPartnershipHandle,
        hasBrandPartnershipHandle,
      }
    },
  )

  const isChecked = hasBrandPartnershipHandle === false
  const isCheckedOrHasPaidPartnershipHandle = isChecked || Boolean(paidPartnershipHandle)
  const hasPaidPartnershipHandleOrUsingDefaultFallback =
    Boolean(paidPartnershipHandle) && !isChecked

  const updatePaidPartnership = (result: IInstagramPaidPartershipResults) => {
    if (result && result.channels) {
      setChannelItems(toChannelsObject(result.channels))
    }

    updateForm(result)
    clearFieldValidation('paid_partnership_handle_valid')
  }

  const onCheckboxChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement

    updatePaidPartnership({
      channels: draft?.channels?.map((channel) => {
        if (channel.name !== SubmissionChannels.Instagram) {
          return channel
        }

        return {
          ...channel,
          paid_partnership_handle: '',
          has_required_social_channel_account: !target.checked,
        }
      }),
    })
  }

  const clearSavedPaidPartnershipHandle = () => {
    updatePaidPartnership({
      channels: draft?.channels?.map((channel) => {
        if (channel.name !== SubmissionChannels.Instagram) {
          return channel
        }

        return {
          ...channel,
          paid_partnership_handle: '',
        }
      }),
    })
  }

  return (
    <Container topOuterSpacing={1.5} relative rightOuterSpacing={-2.5} rightInnerSpacing={2.5}>
      <FormInput
        validationRule={{
          required: true,
        }}
      >
        <TextField
          name="paid_partnership_handle_valid"
          type="hidden"
          value={isCheckedOrHasPaidPartnershipHandle ? 'valid' : ''}
          readOnly
        />
      </FormInput>
      <Container bottomOuterSpacing={0.75}>
        <Flex alignCenter>
          <H4 color={Theme.grey900Color}>
            <FormattedMessage id="builder.brief.socialMedia.paidpartnership.header" />
          </H4>
          <BuilderBriefChannelsInstagramPaidPartershipInformation />
        </Flex>
      </Container>
      <Container bottomOuterSpacing={1} width={ADJUSTED_WIDTH}>
        <Text color={Theme.grey900Color}>
          <FormattedMessage id="builder.brief.socialMedia.paidpartnership.subtitle" />
          &nbsp;
          <NoWrapLinkWrapper>
            <ConnectedTrackedRoute
              inline
              draftId={draft.id}
              eventName={EventTrackingNames.CampaignBuilderEducationLink}
              additionalProperties={{
                link_url: PAID_PARTNERSHIP_LEARN_HOW,
                link_name: 'core.text.learnHow.withArrow',
              }}
            >
              <Link target="_blank" href={PAID_PARTNERSHIP_LEARN_HOW} color={Theme.primaryColor}>
                <FormattedMessage id="core.text.learnHow.withArrow" />
              </Link>
            </ConnectedTrackedRoute>
          </NoWrapLinkWrapper>
        </Text>
      </Container>

      <Container>
        {hasPaidPartnershipHandleOrUsingDefaultFallback ? (
          <Flex>
            <Tag>
              <Text color={Theme.defaultColor}>{paidPartnershipHandle}</Text>
              <Container leftOuterSpacing={0.5}>
                <Flex>
                  <Icon
                    onClick={clearSavedPaidPartnershipHandle}
                    size={1}
                    color={Theme.defaultColor}
                    glyph="cross-heavy"
                  />
                </Flex>
              </Container>
            </Tag>
          </Flex>
        ) : (
          <BuilderBriefChannelsInstagramHandleInput
            disabled={isChecked}
            onUpdate={updatePaidPartnership}
          />
        )}

        <BuilderBriefChannelsInstagramPaidPartershipNoInstagramCheckBox
          onCheckboxChange={onCheckboxChange}
          isChecked={isChecked}
          draftId={draft.id!}
        />
      </Container>
      <BuilderBriefChannelsInstagramPaidPartershipTip />
    </Container>
  )
}

export { BuilderBriefChannelsInstagramPaidPartership }
export default withBuilderValidation(BuilderBriefChannelsInstagramPaidPartership)
