import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { returnSecondIfDeepEqual } from '@utils'
import { AudienceInsightsActionTypes, IIAudienceInsightsState } from './types'

const initialState: IIAudienceInsightsState = {
  audienceInsightsByIdentityId: {},
  errors: undefined,
  isFetching: false,
}

const reducer: Reducer<IIAudienceInsightsState> = (state = initialState, action) => {
  switch (action.type) {
    case AudienceInsightsActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case AudienceInsightsActionTypes.FETCH_SUCCESS: {
      const { identityId, audienceInsights } = action.payload
      return {
        ...state,
        audienceInsightsByIdentityId: returnSecondIfDeepEqual(
          {
            ...state.audienceInsightsByIdentityId,
            [identityId]: audienceInsights,
          },
          state.audienceInsightsByIdentityId,
        ),
        isFetching: false,
        errors: undefined,
      }
    }
    case AudienceInsightsActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }
    }
    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState
    default:
      return state
  }
}

export { reducer as audienceInsightsReducer }
