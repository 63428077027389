import { IResponseError } from '@lib/error'
import { InfluencerIdentitiesUsingNextActionTypes } from '@store/influencerIdentities'

export const fetchInfluencerIdentitiesUsingNext = (campaignId: number) => ({
  payload: campaignId,
  type: InfluencerIdentitiesUsingNextActionTypes.FETCH_REQUEST,
})

export const fetchInfluencerIdentitiesUsingNextSuccess = (data: any, campaignId: number) => ({
  payload: { ...data, campaignId },
  type: InfluencerIdentitiesUsingNextActionTypes.FETCH_SUCCESS,
})

export const fetchInfluencerIdentitiesUsingNextError = (error: IResponseError) => ({
  payload: error,
  type: InfluencerIdentitiesUsingNextActionTypes.FETCH_ERROR,
})
