import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IDraftContextProps } from '@context/Draft'
import { withDraft } from '@hocs'
import { IApplicationState } from '@store'
import { selectCampaignOperatingRegionTaxName } from '@store/campaigns'
import Theme from '@theme'
import { Text } from '@tribegroup'

interface IInternalProps extends ConnectedProps<typeof connector>, InjectedIntlProps {}

const CurrencyAndTax: React.FC<IInternalProps> = ({ taxName, intl, currency }) => {
  const currencySymbol = intl.formatMessage({ id: `core.currency.symbol.${currency}` })
  return (
    <Text color={Theme.defaultColor}>
      <span>{`${currency}${currencySymbol}`}</span> (
      <FormattedMessage
        id="submission.fullView.pricePanel.tax.text"
        values={{
          taxName,
        }}
      />
      )
    </Text>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { draft, intl }: InjectedIntlProps & IDraftContextProps,
) => {
  const currency = draft.budget_total_currency
  return {
    currency,
    taxName: selectCampaignOperatingRegionTaxName(state, draft.id!, intl, currency),
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(injectIntl, withDraft, connector)(CurrencyAndTax)
