import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken, signOutRequest } from '@store/auth'
import { AutoSwitchActionTypes, autoSwitchError, selectAutoSwitchLink } from '@store/me'
import { networkRequest } from '@utils'

export function* handleAutoSwitch() {
  try {
    const authToken: string = yield select(selectAuthToken)
    const autoSwitchLink: IHateoasLink = yield select(selectAutoSwitchLink)
    const result: IHateoasLink = yield call(
      networkRequest,
      autoSwitchLink.method,
      autoSwitchLink.href,
      undefined,
      authToken,
    )
    yield put(signOutRequest())
    yield call([window.location, window.location.replace], result.href)
  } catch (error) {
    yield put(autoSwitchError(error))
  }
}

export function* watchAutoSwitchRequest() {
  yield takeLatest(AutoSwitchActionTypes.AUTOSWITCH_REQUEST, handleAutoSwitch)
}
