import { createSelector } from 'reselect'

import { CONTENT_LIBRARY, DEMO_VIDEO, EMPTY_LIST } from '@constants'
import { SupportedSubmissionTypes } from '@enums'
import { IResponseErrorMessage } from '@lib/error'
import { IApplicationState, IHateoasLink } from '@store'
import {
  IBrandFansLink,
  ICompany,
  ICreditCardData,
  IMe,
  IPreferences,
  ISubscriptionTier,
} from '@store/me'
import { IOperatingRegion, selectOperatingRegions } from '@store/rootResource'
import { isAdmin, isEmpty } from '@utils'

export const selectMeIsFetching = (state: IApplicationState): boolean => state.me.isFetching
export const selectMeIsFetched = (state: IApplicationState): boolean => state.me.isFetched
export const selectIsAutoSwitching = (state: IApplicationState): boolean => state.me.isSwitching
export const selectIsUpdating = (state: IApplicationState): boolean => state.me.isUpdating
export const selectIsCreatingIntent = (state: IApplicationState): boolean =>
  state.me.isCreatingIntent
export const selectIsSettingUpCard = (state: IApplicationState): boolean => state.me.isSettingUpCard
export const selectMeErrors = (state: IApplicationState) => state.me.errors
export const selectMeCapabilities = (state: IApplicationState) => state.me.info.capabilities ?? []

export const selectMeErrorMessages = (state: IApplicationState) => {
  const meErrors = selectMeErrors(state)
  return meErrors && (meErrors.messages as ReadonlyArray<IResponseErrorMessage>)
}
export const selectPaymentErrorMessage = (state: IApplicationState) => {
  const errors = selectMeErrorMessages(state)
  const errorMessage = errors && errors[0] && (errors[0].message || errors[0])
  return errorMessage
}

export const selectLink = (state: IApplicationState, rel: string): IHateoasLink | undefined =>
  state.me.info.links.find((link: IHateoasLink) => link.rel === rel)
export const selectCampaignLink = (state: IApplicationState) => selectLink(state, 'brief')
export const selectSignOutLink = (state: IApplicationState) => selectLink(state, 'signout')
export const selectCampaignsLink = (state: IApplicationState) => selectLink(state, 'briefs')
export const selectSubmissionsLink = (state: IApplicationState) => selectLink(state, 'submissions')
export const selectMediaObjectsLink = (state: IApplicationState) =>
  selectLink(state, 'media_object')

export const selectCampaignsBillingHistoryLink = (state: IApplicationState) =>
  selectLink(state, 'briefs_purchase_history')

export const selectContentLibraryBillingHistoryLink = (state: IApplicationState) =>
  selectLink(state, 'content_library_purchase_history')

export const selectAutoSwitchLink = (state: IApplicationState) =>
  selectLink(state, 'auto_signin_old_app')

export const selectUpdateContactLink = (state: IApplicationState) =>
  selectLink(state, 'profile_update')

export const selectChangePasswordLink = (state: IApplicationState) =>
  selectLink(state, 'change_password')

export const selectUpdateCreditCardLink = (state: IApplicationState) =>
  selectLink(state, 'update_credit_card')

export const selectAddCreditCardLink = (state: IApplicationState) =>
  selectLink(state, 'add_credit_card')

export const selectFetchBrandsLink = (state: IApplicationState) => selectLink(state, 'brands')
export const selectContentLibraryLink = (state: IApplicationState) =>
  selectLink(state, CONTENT_LIBRARY)

export const selectSetupIntentLink = (state: IApplicationState) =>
  selectLink(state, 'stripe_setup_intents')

export const selectAddCustomerLink = (state: IApplicationState) =>
  selectLink(state, 'stripe_add_customer')

export const selectUpdatePreferencesLink = (state: IApplicationState) =>
  selectLink(state, 'update_preferences')

export const selectInfluencerIdentitiesLink = (state: IApplicationState) =>
  selectLink(state, 'influencer_identities') as IBrandFansLink

export const selectBrandFanGroupsLink = (state: IApplicationState) =>
  selectLink(state, 'brand_fan_groups') as IBrandFansLink

export const selectBrandManagerIdentitiesLink = (state: IApplicationState): IBrandFansLink | null =>
  selectLink(state, 'identities') as IBrandFansLink

export const selectPurchaseOrdersLink = (state: IApplicationState) =>
  selectLink(state, 'purchase_orders')

export const selectConnectTikTokAdAccountLink = (state: IApplicationState) =>
  selectLink(state, 'authorize_tiktok_ad_manager_account')

export const selectInfluencerIdentityFilters = createSelector(
  selectInfluencerIdentitiesLink,
  (link: IBrandFansLink) => (link && link.filter) || EMPTY_LIST,
)

export const selectInfluencerIdentitySortOptions = createSelector(
  selectInfluencerIdentitiesLink,
  (link: IBrandFansLink) => (link && link.sort_by) || EMPTY_LIST,
)

export const selectContentLibraryEnabled = createSelector(selectContentLibraryLink, (link) =>
  Boolean(link),
)

export const selectContentLibraryFilters = createSelector(
  selectContentLibraryLink,
  (link?: IHateoasLink) => {
    const filters = (link && link.filter) || EMPTY_LIST
    return filters
  },
)

export const selectContentLibraryDefaultFilter = createSelector(
  selectContentLibraryFilters,
  (filters: ReadonlyArray<string>) => (filters.length > 0 ? filters[0] : ''),
)

export const selectPaymentApiKey = (state: IApplicationState) => {
  return selectMeInfo(state).stripe_api_key
}

export const selectMeInfo = createSelector(
  (state: IApplicationState) => state.me,
  (me) => me.info,
)

export const selectMeId = (state: IApplicationState): number => selectMeInfo(state).id!

export const selectCompanyInfo = createSelector(
  selectMeInfo,
  (meInfo) =>
    ({
      company_name: meInfo.company_name,
      company_accounts_payable_email: meInfo.company_accounts_payable_email,
      company_billing_contact: meInfo.company_billing_contact,
      company_address_street: meInfo.company_address_street,
      company_address_city: meInfo.company_address_city,
      company_address_country: meInfo.company_address_country,
      company_address_postcode: meInfo.company_address_postcode,
      company_address_state: meInfo.company_address_state,
      company_address_state_code: meInfo.company_address_state_code,
      company_operating_region: meInfo.company_operating_region,
    } as ICompany),
)

export const hasCompanyInfo = createSelector(selectMeInfo, (meInfo) => meInfo.company_any)

export const selectReferralProgramToken = createSelector(
  selectMeInfo,
  (meInfo) => meInfo.referral_program_token,
)

export const selectReferralProgramLink = createSelector(
  selectReferralProgramToken,
  (token?: string) => {
    if (!token) {
      return undefined
    }
    return `${window.location.origin}/referral/${token}`
  },
)

export const selectCreditCardLink = (state: IApplicationState) => selectSetupIntentLink(state)

export const selectCreditCardInfo = createSelector(selectMeInfo, (meInfo) => {
  const { credit_card_any, credit_card_data } = meInfo
  return {
    credit_card_any,
    credit_card_data,
  }
})

export const selectFirstName = createSelector(
  selectMeInfo,
  (meInfo: Partial<IMe>) => meInfo.first_name,
)

export const selectHasCreditCardInfo = createSelector(
  selectMeInfo,
  (meInfo) => meInfo.credit_card_any,
)

export const selectCreditCardData = createSelector(
  selectMeInfo,
  (meInfo) => meInfo.credit_card_data || ({} as ICreditCardData),
)

export const selectCreditCardLastFourDigits = createSelector(
  selectCreditCardData,
  (creditCardData: ICreditCardData) => creditCardData.last_four_digits,
)

export const selectCreditCardExpiryYear = createSelector(
  selectCreditCardData,
  (creditCardData: ICreditCardData) => creditCardData.expiry_year,
)

export const selectCreditCardExpiryMonth = createSelector(
  selectCreditCardData,
  (creditCardData: ICreditCardData) => creditCardData.expiry_month,
)

export const selectCampaignCountUsingCreditCard = createSelector(
  selectCreditCardData,
  (creditCardData: ICreditCardData) => creditCardData.brief_count || 0,
)

export const selectStripeApiKey = createSelector(selectMeInfo, (meInfo) => meInfo.stripe_api_key)

export const shouldUserTrackEvents = createSelector(
  selectMeInfo,
  (meInfo) => !isAdmin(meInfo as IMe),
)

export const selectSetupIntentClientSecret = createSelector(
  (state: IApplicationState) => state.me,
  (me) => me.clientSecret,
)

export const selectPaymentFormIsLoading = createSelector(
  selectIsCreatingIntent,
  selectIsSettingUpCard,
  selectMeIsFetching,
  (isCreatingIntent: boolean, isSettingUpCard: boolean, isFetchingMe: boolean) =>
    [isCreatingIntent, isSettingUpCard, isFetchingMe].some(Boolean),
)

export const selectMeError = createSelector(selectMeErrorMessages, (meErrors) =>
  meErrors && meErrors.length > 0 ? meErrors[0] : {},
)

export const selectMeErrorMessage = createSelector(
  selectMeError,
  (error: IResponseErrorMessage) => error.message,
)

export const selectMeErrorCode = createSelector(
  selectMeError,
  (error: IResponseErrorMessage) => error.error_code,
)

const selectCompanyRegion = createSelector(
  (state: IApplicationState) => selectOperatingRegions(state),
  selectCompanyInfo,
  (operatingRegions: ReadonlyArray<IOperatingRegion>, companyInfo: ICompany) => {
    const findRegion = (operatingRegion: IOperatingRegion) =>
      operatingRegion.region_code === companyInfo.company_operating_region
    const targetRegion = operatingRegions.find(findRegion)
    return targetRegion || ({} as IOperatingRegion)
  },
)

export const selectDemoVideoId = createSelector(
  (state: IApplicationState, submissionType: SupportedSubmissionTypes) => submissionType,
  selectCompanyRegion,
  (submissionType: SupportedSubmissionTypes, operatingRegion: IOperatingRegion) => {
    return (
      !isEmpty(operatingRegion) &&
      DEMO_VIDEO[submissionType] &&
      DEMO_VIDEO[submissionType][operatingRegion.region_code]
    )
  },
)

export const selectCompanyRegionCurrency = createSelector(
  selectCompanyRegion,
  (operatingRegion: IOperatingRegion) => operatingRegion.currency_code,
)

export const selectCompanyRegionTaxDisplayName = createSelector(
  (state, intl) => intl,
  (state, intl, fallbackCurrency) => fallbackCurrency,
  (intl: ReactIntl.InjectedIntl, fallbackCurrency: string) => {
    return intl.formatMessage({ id: `core.currency.taxDisplay.${fallbackCurrency || 'generic'}` })
  },
)

export const selectActivationFeeForUserRegion = createSelector(
  selectCompanyRegion,
  (operatingRegion: IOperatingRegion) => operatingRegion.brief_activation_fee_cents,
)

export const selectMinimumInvoiceBudgetForUserRegion = createSelector(
  selectCompanyRegion,
  (operatingRegion: IOperatingRegion) => {
    return !isNaN(operatingRegion.minimum_invoice_value_cents)
      ? operatingRegion.minimum_invoice_value_cents
      : 1
  },
)

export const selectReferralRewardPriceForUserRegion = createSelector(
  selectCompanyRegion,
  (operatingRegion: IOperatingRegion) => {
    if (operatingRegion.referral_reward_price_cents) {
      return operatingRegion.referral_reward_price_cents / 100
    }
    return undefined
  },
)

export const selectHasPendingCreditRequest = createSelector(selectMeInfo, (meInfo) =>
  Boolean(meInfo.pending_credit_request_any),
)

export const selectCreditAmountCents = createSelector(
  selectMeInfo,
  (meInfo) => meInfo.credit_amount_cents,
)

export const selectCreditAmount = createSelector(
  selectCreditAmountCents,
  (amountInCents) => amountInCents && amountInCents / 100,
)

export const selectShowInvoiceCreditRemaining = createSelector(
  selectCreditAmountCents,
  (amountInCents) => !isEmpty(amountInCents),
)

export const selectCreditCurrency = createSelector(
  selectMeInfo,
  selectCompanyRegionCurrency,
  (meInfo, regionDefaultCurrency) => meInfo.credit_amount_currency || regionDefaultCurrency,
)

export const selectPreferences = createSelector(
  selectMeInfo,
  (meInfo: IMe): IPreferences => meInfo.preferences || {},
)

export const shouldShowCampaignCreationPrompt = createSelector(
  selectPreferences,
  (preferences: IPreferences) => !preferences.disable_brief_creation_prompt,
)

export const selectSubscriptionTier = createSelector(
  selectMeInfo,
  (meInfo) => meInfo.subscription_tier || ({} as ISubscriptionTier),
)

export const selectUserHasSubscription = createSelector(
  selectSubscriptionTier,
  (subscriptionTier) => {
    return !subscriptionTier.free_tier
  },
)

export const selectSubscriptionTierFeatures = createSelector(
  selectSubscriptionTier,
  (currentTier: ISubscriptionTier) => currentTier.features || (EMPTY_LIST as ReadonlyArray<string>),
)

export const selectHasFeatureForSubscribedTier = createSelector(
  selectSubscriptionTierFeatures,
  (state: IApplicationState, feature: string) => feature,
  (features: ReadonlyArray<string>, feature: string) => features.includes(feature),
)

export const selectActivationFee = createSelector(
  selectSubscriptionTier,
  (subscriptionTier: ISubscriptionTier) => {
    return subscriptionTier.activation_fee_cents
  },
)

export const selectActivationFeeCurrency = createSelector(
  selectSubscriptionTier,
  (subscriptionTier: ISubscriptionTier) => {
    return subscriptionTier.activation_fee_currency
  },
)

export const selectUserSubscriptionPricings = createSelector(
  selectSubscriptionTier,
  (subscriptionTier: ISubscriptionTier) => {
    return subscriptionTier.content_licensing_pricings
  },
)
