import { Component } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { signOutRequest } from '@store/auth'

interface IInternalProps extends RouteComponentProps {
  signOutRequest: typeof signOutRequest
}

export class AuthSignOutRedirect extends Component<IInternalProps> {
  componentDidMount() {
    this.props.signOutRequest({
      history: this.props.history,
      redirect: '/',
    })
  }

  render() {
    return null
  }
}

const mapDispatchToProps = {
  signOutRequest,
}

export default compose<IInternalProps, {}>(
  withRouter,
  connect(undefined, mapDispatchToProps),
)(AuthSignOutRedirect)
