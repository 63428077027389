import styled from 'styled-components'

import Theme from '@theme'
import { BlockText } from '../Block/Block.styled'

const HouseRulesStyled = styled.ul`
  padding-left: 1.5rem;

  li {
    display: flex;

    &::before {
      padding-top: 0.25rem;
      content: '\\2022';
      color: ${Theme.grey900Color};
      display: inline-block;
      width: 1rem;
      margin-left: -1.5rem;
      flex-shrink: 0;
    }
  }

  ${BlockText} {
    display: inline-block;
  }
`

export default HouseRulesStyled
