import { call, put, select, takeEvery } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  addMember,
  addMemberError,
  addMemberSuccess,
  IMember,
  MemberActionTypes,
  selectAddMemberLink,
} from '@store/members'
import { networkRequest } from '@utils'

export function* handleAdd(action: ReturnType<typeof addMember>) {
  const { campaignId, addMemberParams } = action.payload
  const analyticsEvent = action.meta?.event

  try {
    const authToken: string = yield select(selectAuthToken)
    const addMemberLink: IHateoasLink = yield select(selectAddMemberLink, action.payload.campaignId)
    const member: IMember = yield call(
      networkRequest,
      addMemberLink.method,
      addMemberLink.href,
      addMemberParams,
      authToken,
    )
    yield put(addMemberSuccess(campaignId, member, action.meta))
    yield put(trackEvent(analyticsEvent.eventPayload.event, analyticsEvent.eventPayload.properties))
  } catch (error) {
    yield put(addMemberError(error))
  }
}

export function* watchAddMemberRequest() {
  yield takeEvery(MemberActionTypes.ADD_REQUEST, handleAdd)
}
