import Flex from 'styled-flex-component'

import { SubmissionChannels } from '@enums'
import InfluencerIdentityRowPerformanceAverageEngagement from './AverageEngagement/AverageEngagement'
import InfluencerIdentityRowPerformanceEstimatedCPE from './EstimatedCPE/EstimatedCPE'
import InfluencerIdentityRowPerformanceFollowerCount from './FollowerCount/FollowerCount'
import InfluencerIdentityRowSubmissionEmpty from './Empty'

const InfluencerIdentityRowPerformanceMetrics: React.FC<{
  identityProvider: SubmissionChannels
}> = ({ identityProvider }) => {
  const isPinterest = identityProvider === SubmissionChannels.Pinterest

  if (isPinterest) {
    return (
      <Flex data-testid="empty-performance-row">
        <InfluencerIdentityRowSubmissionEmpty />
        <InfluencerIdentityRowSubmissionEmpty />
        <InfluencerIdentityRowSubmissionEmpty />
      </Flex>
    )
  }

  return (
    <Flex>
      <InfluencerIdentityRowPerformanceFollowerCount />
      <InfluencerIdentityRowPerformanceAverageEngagement />
      <InfluencerIdentityRowPerformanceEstimatedCPE />
    </Flex>
  )
}

export default InfluencerIdentityRowPerformanceMetrics
