import React from 'react'

import Flex from 'styled-flex-component'

import { Icon } from '../Icon'
import { Header } from '../Typography'
import { KEYCODE, trimStringMap } from '../utils'
import { IconWrapper, ListItemTextarea, ListItemWrapper, StyledList } from './List.styled'

interface IIconProps {
  /**
   * Sets icon glyph
   */
  glyph: string
  /**
   * Sets icon color
   */
  color: string
  /**
   * Sets icon background color
   */
  backgroundColor: string
}

export interface IListProps {
  /**
   * Sets icon displayed for the bulleted list
   */
  icon: IIconProps

  /**
   * Sets icon displayed for the bulleted list
   *
   * @default undefined
   */
  header: string

  /**
   * Placeholder displayed for a new list entry
   */
  placeholder: string

  /**
   * Placeholder displayed for a new list
   */
  emptyPlaceholder: string

  /**
   * Default value for list
   *
   * @default undefined
   */
  defaultValue?: ReadonlyArray<string>

  /**
   * Disable line break
   *
   * @default undefined
   */
  disableLineBreak?: boolean

  /**
   * Callback invoked whenever the value is changed. passes an array of all the string values
   *
   * @default undefined
   */
  onChange?: (values: ReadonlyArray<string>) => void

  /**
   * Callback invoked whenever the field is focused
   *
   * @default undefined
   */
  onFocus?: (event) => void
}

export interface IListState {
  items: Record<number, string>
}

export class List extends React.PureComponent<IListProps, IListState> {
  state = {
    items: {},
  }

  private randomId = `${Math.random()}`

  componentDidMount() {
    const items = this.props.defaultValue
      ? this.props.defaultValue.reduce((accum, val, index) => {
          return { ...accum, [index]: val }
        }, {})
      : {}
    this.setState({
      items,
    })
  }

  onChange = (event: React.SyntheticEvent) => {
    const element = event.target as HTMLInputElement
    const dataIndex = parseInt(element.getAttribute('data-index') || '0', 10)
    const lineValue = this.props.disableLineBreak
      ? element.value.replace(/\r?\n|\r/g, '')
      : element.value

    this.setState(
      (prevState) => ({ items: { ...prevState.items, [dataIndex]: lineValue } }),
      () => {
        if (this.props.onChange) {
          this.props.onChange(Object.values(trimStringMap(this.state.items)))
        }
      },
    )
  }

  onBlur = () => {
    this.setState((prevState) => {
      return {
        items: trimStringMap(prevState.items),
      }
    })
  }

  onRemoveClick = (event: React.SyntheticEvent) => {
    const element = event.currentTarget as HTMLDivElement
    const dataIndex = parseInt(element.getAttribute('data-index') || '0', 10)
    this.setState(
      (prevState) => {
        const updatedList = Object.values(prevState.items).filter(
          (curr, index) => index !== dataIndex,
        )
        const filteredItemList = updatedList.reduce((accum, item, index) => {
          return { ...accum, [index]: item }
        }, []) as Record<number, string>
        return {
          items: filteredItemList,
        }
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(Object.values(this.state.items))
        }
      },
    )
  }

  onKeyDown = (event: React.KeyboardEvent) => {
    if (this.props.disableLineBreak && event.keyCode === KEYCODE.ENTER) {
      event.preventDefault()
    }
  }

  public render() {
    const items: ReadonlyArray<string> = [
      ...(Object.values(this.state.items) as ReadonlyArray<string>),
      '',
    ]

    const { header, icon, placeholder, emptyPlaceholder } = this.props
    const hasEmptyItems = Object.values(this.state.items).length === 0
    return (
      <StyledList>
        <Header uppercase bottomOuterSpacing={0.875}>
          {header}
        </Header>
        {items.map((item, index) => (
          <ListItemWrapper key={`${this.randomId}-${index}`} removable={Boolean(item)}>
            {hasEmptyItems ? (
              <IconWrapper backgroundColor="transparent" empty>
                <Icon glyph="plus-heavy" size={0.8} />
              </IconWrapper>
            ) : (
              <IconWrapper backgroundColor={icon.backgroundColor}>
                <Icon glyph={icon.glyph} color={icon.color} size={0.8} />
              </IconWrapper>
            )}

            <ListItemTextarea
              data-index={index}
              placeholder={hasEmptyItems ? emptyPlaceholder : placeholder}
              value={item}
              onChange={this.onChange}
              onBlur={this.onBlur}
              onKeyDown={this.onKeyDown}
            />
            <Flex role="button" onClick={this.onRemoveClick} data-index={index} alignStart>
              <Icon glyph="cross-heavy" size={0.8} className="close-icon" />
            </Flex>
          </ListItemWrapper>
        ))}
      </StyledList>
    )
  }
}
