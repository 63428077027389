import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import InfluencerIdentityRowPerformanceEstimatedCPEAverageTooltip from '@components/Inbox/InfluencerIdentity/Row/Performance/EstimatedCPE/Tooltip/AverageTooltip'
import Loading from '@components/UI/Loading'
import { NoticeEmpty } from '@components/UI/Notice'
import { SupportedScreenWrapper } from '@components/UI/ScreenSize'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { selectIsCampaignFetched } from '@store/campaigns'
import {
  fetchInfluencerIdentities,
  selectHasInfluencerIdentities,
  selectInfluencerIdentitiesIsInitialFetch,
} from '@store/influencerIdentities'
import { selectCampaignId } from '@store/router'
import InfluencerIdentityLayoutUnsupportedScreen from '../Layout/UnsupportedScreen'
import InfluencerIdentityList from './List'

const UnsupportedScreenHandler = ({ children }) => (
  <Fragment>
    <InfluencerIdentityLayoutUnsupportedScreen />
    <SupportedScreenWrapper>{children}</SupportedScreenWrapper>
  </Fragment>
)

interface IInternalProps {
  campaignId: number
  isInitialFetch: boolean
  hasInfluencerIdentities: boolean
  hasMore: boolean
  isCampaignFetched: boolean
  fetchInfluencerIdentities: typeof fetchInfluencerIdentities
}

export const InfluencerIdentityListWrapper = ({
  campaignId,
  isInitialFetch,
  hasInfluencerIdentities,
  isCampaignFetched,
  fetchInfluencerIdentities: fetchInfluencerIdentitiesAction,
}: IInternalProps) => {
  const search = hooks.useLocationSearch()

  hooks.useActionDispatcherEffect(
    true,
    fetchInfluencerIdentitiesAction,
    [campaignId],
    [isCampaignFetched],
  )

  const shouldFetchAndResetList = !isInitialFetch && Boolean(search.sort_by)
  hooks.useActionDispatcherEffect(
    shouldFetchAndResetList,
    fetchInfluencerIdentitiesAction,
    [campaignId],
    [search.sort_by],
  )

  if (isInitialFetch || !isCampaignFetched) {
    return <Loading />
  }

  if (!hasInfluencerIdentities) {
    return (
      <UnsupportedScreenHandler>
        <NoticeEmpty
          alt="influencer-identities-submissions"
          title={<FormattedMessage id={`inbox.cardlist.empty.title.inbox`} />}
          subtitle={<FormattedMessage id={`inbox.cardlist.empty.subtitle.inbox`} />}
        />
      </UnsupportedScreenHandler>
    )
  }

  return (
    <UnsupportedScreenHandler>
      <InfluencerIdentityList />
      <InfluencerIdentityRowPerformanceEstimatedCPEAverageTooltip />
    </UnsupportedScreenHandler>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  return {
    campaignId,
    isCampaignFetched: selectIsCampaignFetched(state, campaignId),
    isInitialFetch: selectInfluencerIdentitiesIsInitialFetch(state, campaignId),
    hasInfluencerIdentities: selectHasInfluencerIdentities(state, campaignId),
  }
}

const mapDispatchToProps = {
  fetchInfluencerIdentities,
}

export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InfluencerIdentityListWrapper)
