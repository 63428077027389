import React from 'react'

import { FormattedMessage } from 'react-intl'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import Theme from '@theme'
import { Container, Text, Tip } from '@tribegroup'

interface IProps {
  campaignId: number
  submissionId: number
}

const PlannedSpendTip: React.FC<IProps> = ({ campaignId, submissionId }) => (
  <Container data-testid="planned-spend-tip-container">
    <ConnectedTrackedRoute
      campaignId={campaignId}
      submissionId={submissionId}
      eventName={EventTrackingNames.BCAAdSpendTooltip}
    >
      <Tip
        inline
        center
        icon={{
          size: 1,
          glyph: 'information',
          padded: false,
        }}
        coachmark={{
          content: (
            <Text small color={Theme.grey800Color}>
              <FormattedMessage id="brandedContentAds.modal.plannedAsSpendTip" />
            </Text>
          ),
          position: 'bottom',
          pointerXPosition: 'center',
          width: 12.7,
        }}
      />
    </ConnectedTrackedRoute>
  </Container>
)

export { PlannedSpendTip }
export default PlannedSpendTip
