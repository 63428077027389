import React from 'react'

import unsupportedx1 from '@images/campaignbuilder/fineapple-and-greybitmap.png'
import unsupportedx2 from '@images/campaignbuilder/fineapple-and-greybitmap@2x.png'
import unsupportedx3 from '@images/campaignbuilder/fineapple-and-greybitmap@3x.png'
import { NoticeImage } from '../Notice.styled'

interface IProps {
  alt: string
}

const NoticeUnsupportedScreenImage: React.FC<IProps> = ({ alt }) => {
  return (
    <NoticeImage
      data-testid="notice-image"
      width={12.5}
      height={12.5}
      src={unsupportedx1}
      srcSet={`${unsupportedx1} 300w, ${unsupportedx2} 768w, ${unsupportedx3} 1280w`}
      alt={`empty-${alt}`}
    />
  )
}

export { NoticeUnsupportedScreenImage }
export default NoticeUnsupportedScreenImage
