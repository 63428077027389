import React from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import {
  selectSubmissionCardSubheaderAsCPE,
  selectSubmissionCardSubheaderAsEngagementPercent,
  selectSubmissionCardSubheaderAsEstimatedCPE,
  viewSocial,
} from '@store/submissions'
import SubHeaderLabelCPE from './Label/CPE'
import SubHeaderLabelEngagementPercent from './Label/EngagementPercent'
import SubHeaderLabelEstimatedCPE from './Label/EstimatedCPE'
import SubHeaderLabelFollowers from './Label/Followers'

interface ISubmissionCardHeaderProps {
  campaignId: number
  submissionId: number
}

interface IInternalProps extends ISubmissionCardHeaderProps, RouteComponentProps {
  viewSocial: typeof viewSocial
  showAsCPE: boolean
  showAsEstimatedCPE: boolean
  showAsEngagementPercent: boolean
}

const SubmissionCardSubHeader: React.FC<IInternalProps> = ({
  campaignId,
  submissionId,
  showAsCPE,
  showAsEstimatedCPE,
  showAsEngagementPercent,
}) => {
  if (showAsEngagementPercent) {
    return <SubHeaderLabelEngagementPercent submissionId={submissionId} />
  }

  if (showAsEstimatedCPE) {
    return <SubHeaderLabelEstimatedCPE submissionId={submissionId} />
  }

  if (showAsCPE) {
    return <SubHeaderLabelCPE submissionId={submissionId} />
  }

  return <SubHeaderLabelFollowers campaignId={campaignId} submissionId={submissionId} />
}

const mapDispatchToProps = {
  viewSocial,
}

const mapStateToProps = (state: IApplicationState) => ({
  showAsCPE: selectSubmissionCardSubheaderAsCPE(state),
  showAsEstimatedCPE: selectSubmissionCardSubheaderAsEstimatedCPE(state),
  showAsEngagementPercent: selectSubmissionCardSubheaderAsEngagementPercent(state),
})

export { SubmissionCardSubHeader }
export default compose<IInternalProps, ISubmissionCardHeaderProps>(
  connect(mapStateToProps, mapDispatchToProps),
)(SubmissionCardSubHeader)
