import React from 'react'

import { SideBarBlockItem } from '@components/UI/SideBar'
import { InformationTooltipContext } from '@context/InformationTooltip'
import { IMember } from '@store/members'
import { Container } from '@tribegroup'
import { pxToRem } from '@utils'
import InboxMemberPopupMenu from './PopupMenu'
import InboxMemberRemoveConfirmation from './RemoveConfirmation'

interface IProps {
  member: IMember
  campaignId: number
}

const getXAdjustment = () => {
  if (window.outerWidth <= 340) {
    return 16
  }
  if (window.outerWidth <= 360) {
    return 18
  }
  if (window.outerWidth <= 375) {
    return 22
  }
  if (window.outerWidth <= 400) {
    return 22.5
  }
  if (window.outerWidth <= 430) {
    return 23
  }
  return 25.25
}

const InboxMember: React.FC<IProps> = ({ member, campaignId }) => {
  const { showTooltip, hideTooltip } = React.useContext(InformationTooltipContext)

  const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] = React.useState(false)
  const [isActive, setIsActive] = React.useState(false)

  const showRemoveConfirmation = () => {
    setIsRemoveConfirmationOpen(true)
  }

  const hideRemoveConfirmation = () => {
    setIsRemoveConfirmationOpen(false)
  }

  const toggleActive = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsActive(!isActive)
    if (!isActive) {
      showPopup(event)
    }
  }

  const onHideTooltip = () => {
    setIsActive(false)
  }

  const showPopup = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.currentTarget as HTMLElement
    const { left, top } = target.getBoundingClientRect()
    const xAdjustment = getXAdjustment()
    showTooltip(
      <InboxMemberPopupMenu
        onClick={hideTooltip}
        member={member}
        showRemoveConfirmation={showRemoveConfirmation}
      />,
      {
        x: pxToRem(left) + xAdjustment,
        y: pxToRem(top + window.scrollY),
      },
      'left',
      11.75,
      {
        useDefaultStyle: false,
        onHideTooltip,
      },
    )
  }

  return (
    <React.Fragment>
      <InboxMemberRemoveConfirmation
        isOpen={isRemoveConfirmationOpen}
        member={member}
        campaignId={campaignId}
        onClose={hideRemoveConfirmation}
      />
      <Container pointer>
        <SideBarBlockItem
          key={member.email}
          text={member.name || member.email}
          noHighlight
          showNotification={false}
          onClick={toggleActive}
          active={isActive}
        />
      </Container>
    </React.Fragment>
  )
}

export default InboxMember
