import React, { PropsWithChildren } from 'react'

import PanelWrapper, { IconWrapper, PanelHeader, PanelRight, PanelTitle } from './Panel.styled'

export interface IPanelProps {
  /**
   * Disables the entire panel, blocking any input fields as turning the panel transparent
   *
   * @default undefined
   */
  disabled?: boolean

  /**
   * List of action icons displayed on the right side of the title
   *
   * @default undefined
   */
  actionIcons?: ReadonlyArray<any>

  /**
   * Title displayed on the header of the panel
   *
   * @default undefined
   */
  title?: React.ReactNode

  /**
   * More information displayed in the panel
   *
   * @default undefined
   */
  info?: React.ReactNode
}

export default class Panel extends React.PureComponent<PropsWithChildren<IPanelProps>> {
  public render() {
    const { title, actionIcons, info, children, ...otherProps } = this.props
    const showInfo = !actionIcons && info
    return (
      <PanelWrapper {...otherProps}>
        <PanelHeader>
          {title && <PanelTitle>{title}</PanelTitle>}
          {actionIcons && (
            <PanelRight>
              {actionIcons.map((actionIcon: any) => {
                return (
                  <IconWrapper key={actionIcon.key || actionIcon.glyph}>{actionIcon}</IconWrapper>
                )
              })}
            </PanelRight>
          )}
          {showInfo && <PanelRight>{info}</PanelRight>}
        </PanelHeader>
        <div>{children}</div>
      </PanelWrapper>
    )
  }
}
