import { Fragment } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { PublishOptions } from '@enums'
import { IMeInfoProps } from '@hocs'
import { IResponseError } from '@lib/error'
import { IApplicationState } from '@store'
import {
  ISubmission,
  selectErrorCode,
  selectErrorMessage,
  selectErrors,
  selectIsRescheduling,
} from '@store/submissions'
import Theme from '@theme'
import { Button, CloseIcon, Container, Header, ModalTitle } from '@tribegroup'
import { isWithinHoursBefore } from '@utils'
import InfluencerOutsideZoneHint from '../components/InfluencerIdentityOutsideZoneHint/InfluencerIdentityOutsideZoneHint'
import PublishingDatePicker from '../components/PublishingDatePicker/PublishingDatePicker'
import PublishingManagerZoneHint from '../components/PublishingManagerZoneHint/PublishingManagerZoneHint'
import PublishingOptions from '../components/PublishingOptions/PublishingOptions'

const MIN_PUBLISHABLE_HOURS = 48

interface ISubmissionCardModalRescheduleProps {
  submission: ISubmission
  onCloseModal: VoidFunction
  selectedOptionValue?: string
  selectedDateValue?: Date
  showDatePicker: boolean
  onRescheduleSubmission: VoidFunction
  onChangePublishingOption: (option: IOption) => void
  onChangeDateValue: (date: Date) => void
}

interface IOption {
  label: string
  value: string
}

interface IInternalProps
  extends ISubmissionCardModalRescheduleProps,
    IMeInfoProps,
    InjectedIntlProps {
  isRescheduling: boolean
  error?: IResponseError
  errorMessage?: string
  errorCode?: string
}

export const SubmissionCardModalReschedule = ({
  submission,
  selectedDateValue,
  selectedOptionValue,
  showDatePicker,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errorCode,
  isRescheduling,
  intl,
  ...props
}: IInternalProps) => {
  const within48HoursOption = {
    value: PublishOptions.WITHIN_48_HOURS,
    label: intl.formatMessage({
      id: 'socialSubmission.card.modal.approve.within48Hours',
    }),
  }

  const chooseADateOption = {
    value: PublishOptions.CHOOSE_A_DATE,
    label: intl.formatMessage({
      id: 'socialSubmission.card.modal.approve.chooseADate',
    }),
  }

  const showWithin48HoursOption = !isWithinHoursBefore(
    MIN_PUBLISHABLE_HOURS,
    new Date(),
    submission.scheduled_publish_date,
  )
  const publishingOptions = showWithin48HoursOption
    ? [within48HoursOption, chooseADateOption]
    : [chooseADateOption]

  const hasChosenDateOption = selectedOptionValue === PublishOptions.CHOOSE_A_DATE
  const hasChosenWithin48HoursOption = selectedOptionValue === PublishOptions.WITHIN_48_HOURS

  const buttonEnabled = hasChosenWithin48HoursOption || (hasChosenDateOption && selectedDateValue)

  const showManagerZoneHint = hasChosenDateOption && selectedDateValue

  const showInfluencerHint = !selectedOptionValue && Boolean(submission.influencer_outside_timezone)

  const scheduledPublishDate =
    submission.scheduled_publish_date && new Date(submission.scheduled_publish_date)

  const savedPublishingDate = (selectedDateValue as Date) || scheduledPublishDate

  return (
    <Fragment>
      <ModalTitle center>
        <FormattedMessage id="socialSubmission.card.modal.reschedule.header" />
      </ModalTitle>
      <CloseIcon onClick={props.onCloseModal} data-cy-element="close-modal" />
      <Container topOuterSpacing={2.875} bottomOuterSpacing={0.75}>
        <Header small color={Theme.grey900Color} uppercase>
          <FormattedMessage id="socialSubmission.card.modal.reschedule.scheduleToGoLive" />
        </Header>
      </Container>
      <PublishingOptions
        hasChosenDateOption={hasChosenDateOption}
        selectedDate={selectedDateValue}
        onChangeOption={props.onChangePublishingOption}
        placeholder={intl.formatMessage({
          id: 'socialSubmission.card.modal.approve.chooseADate',
        })}
        options={publishingOptions}
      />
      {showDatePicker && (
        <Container topOuterSpacing={2.5}>
          <PublishingDatePicker
            selectedDate={selectedDateValue}
            onSelectDate={props.onChangeDateValue}
          />
        </Container>
      )}
      {showInfluencerHint && (
        <Container topOuterSpacing={1} bottomOuterSpacing={3.25}>
          <InfluencerOutsideZoneHint
            influencerName={submission.influencer_full_name}
            influencerCountry={submission.influencer_country!}
          />
        </Container>
      )}
      {showManagerZoneHint && (
        <Container topOuterSpacing={1} bottomOuterSpacing={3.25}>
          <PublishingManagerZoneHint selectedDate={savedPublishingDate} />
        </Container>
      )}
      <Container topOuterSpacing={2.5}>
        <Button
          fullWidth
          scheme="primary"
          height={3}
          onClick={props.onRescheduleSubmission}
          disabled={!buttonEnabled || isRescheduling}
          loading={isRescheduling}
          data-cy-element="reschedule-modal-reschedule-button"
        >
          <FormattedMessage id="core.text.reschedule" />
        </Button>
      </Container>
    </Fragment>
  )
}

export const mapStateToProps = (state: IApplicationState) => ({
  isRescheduling: selectIsRescheduling(state),
  error: selectErrors(state),
  errorCode: selectErrorCode(state),
  errorMessage: selectErrorMessage(state),
})

export default compose<IInternalProps, ISubmissionCardModalRescheduleProps>(
  injectIntl,
  connect(mapStateToProps, undefined),
)(SubmissionCardModalReschedule)
