import { FormattedMessage } from 'react-intl'

import { SubmissionChannels } from '@enums'
import { ICampaign } from '@store/campaigns'
import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import CampaignPreviewBlock from '../Block'

interface IProps {
  campaign: ICampaign
}

const CampaignPreviewSubmissionOptions = ({ campaign: { requested_submission_types } }: IProps) => {
  const channels = [
    SubmissionChannels.Instagram,
    SubmissionChannels.TikTok,
    SubmissionChannels.Pinterest,
    SubmissionChannels.X,
    SubmissionChannels.YouTube,
    SubmissionChannels.Facebook,
    SubmissionChannels.Twitter,
  ]

  return (
    <CampaignPreviewBlock
      title={<FormattedMessage id="campaign.preview.submissionType.header" />}
      emptyLines={[15]}
    >
      <Container topOuterSpacing={1}>
        {channels.map(
          (channel) =>
            requested_submission_types?.[channel] && (
              <Container
                key={`campaign-preview-submission-options-${channel}`}
                flexStart
                bottomOuterSpacing={1}
                gap={0.75}
              >
                <Icon glyph={`social-${channel}-colour`} size={1.25} />
                <Container flexStart flexWrap="wrap" gap={0.5}>
                  {requested_submission_types?.[channel]?.map((submissionType) => (
                    <Container
                      topInnerSpacing={0.375}
                      bottomInnerSpacing={0.375}
                      leftInnerSpacing={0.625}
                      rightInnerSpacing={0.625}
                      backgroundColor={Theme.primary100Color}
                      borderRadius={0.25}
                      key={`campaign-preview-submission-options-${channel}-${submissionType}`}
                    >
                      <Text color={Theme.grey900Color}>
                        <FormattedMessage
                          id={`campaign.preview.submissionType.${submissionType}`}
                        />
                      </Text>
                    </Container>
                  ))}
                </Container>
              </Container>
            ),
        )}
      </Container>
    </CampaignPreviewBlock>
  )
}

export { CampaignPreviewSubmissionOptions }
