import { MouseEvent, useContext } from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { SubmissionCardModalLicense } from '@components/Submission/Card/Modal/License.New/License'
import { PurchaseLicense } from '@components/Submission/Card/Modal/PurchaseLicense/PurchaseLicense'
import ActionIcon from '@components/UI/ActionIcon'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { CampaignContext } from '@context/Campaign'
import { IModalContextProps } from '@context/Modal'
import { IMultiframeContextProps } from '@context/MultiframeControls'
import { PopupMenuContext } from '@context/PopupMenu'
import { EventTrackingNames, FeatureToggles, SubmissionStatuses } from '@enums'
import { withModal, withMultiframeControls } from '@hocs'
import { IApplicationState } from '@store'
import { selectCampaignId } from '@store/router'
import {
  selectActiveSubmissionMediaObject,
  selectSubmission,
  selectSubmissionMediaObjectFrameNumber,
} from '@store/submissions'
import Theme from '@theme'
import { pxToRem } from '@utils/pxToRem'
import ActionBarIconApproveContentPopupMenu from '../Approve/ContentPopupMenu'

interface IProps {
  submissionId: number
}

interface IInternalProps
  extends IProps,
    InjectedIntlProps,
    IModalContextProps,
    IMultiframeContextProps,
    ConnectedProps<typeof connector> {}

export const ActionBarIconPurchaseLicense = ({
  campaignId,
  submissionId,
  submission,
  intl,
  showModal,
  mediaObjectId,
  frameNumber,
}: IInternalProps) => {
  const isNewLicenseTypesEnabled = useFlag(FeatureToggles.NEW_LICENSE_TYPES_ENABLED)

  const { campaign } = useContext(CampaignContext)
  const { showPopup } = useContext(PopupMenuContext)

  const isHighlighted = submission.status === SubmissionStatuses?.PreApproved

  const onClick = ({ target }: MouseEvent<HTMLDivElement>) => {
    if (campaign.preapproval_allowed && submission.status === SubmissionStatuses.Pending) {
      const targetElement = target as HTMLElement
      const { left, top, width } = targetElement.getBoundingClientRect()

      showPopup(
        <ActionBarIconApproveContentPopupMenu
          submissionId={submissionId}
          frameNumber={frameNumber}
        />,
        {
          x: pxToRem(left + width),
          y: pxToRem(top),
        },
      )
      return
    }

    const ModalComponent = isNewLicenseTypesEnabled ? PurchaseLicense : SubmissionCardModalLicense

    showModal(ModalComponent, {
      submissionId,
      frameNumber,
      approvalType: 'final-approval',
    })
  }

  return (
    <ConnectedTrackedRoute
      eventName={EventTrackingNames.ViewLicensingOptions}
      campaignId={campaignId}
      submissionId={submissionId}
      mediaObjectId={mediaObjectId}
    >
      <ActionIcon
        glyph="approve-submission"
        color={Theme.grey900Color}
        size={1.5}
        onClick={onClick}
        tooltip={intl.formatMessage({ id: 'submission.card.action.licensecontent.licenseContent' })}
        elementName="purchase-license"
        highlighted={isHighlighted}
      />
    </ConnectedTrackedRoute>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  {
    submissionId,
    match,
    activeFrameNumber,
  }: IProps & RouteComponentProps & IMultiframeContextProps,
) => {
  const { id: mediaObjectId } = selectActiveSubmissionMediaObject(
    state,
    submissionId,
    activeFrameNumber,
  )

  return {
    frameNumber: selectSubmissionMediaObjectFrameNumber(state, submissionId, mediaObjectId),
    mediaObjectId,
    campaignId: selectCampaignId(match) as number,
    submission: selectSubmission(state, submissionId),
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(
  withMultiframeControls,
  withRouter,
  withModal,
  injectIntl,
  connector,
)(ActionBarIconPurchaseLicense)
