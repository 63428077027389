import { PureComponent } from 'react'

import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'

import Main from '@components/Main'
import SubmissionChatSendInProgress from '@components/Submission/Chat/SendInProgress'
import SnackbarBase from '@components/UI/Snackbar/Base'
import SnackBarProvider from '@components/UI/Snackbar/Provider'
import Tooltip from '@components/UI/Tooltip'
import { APP_LOCALE } from '@constants'
import { FeatureToggleProvider } from '@context/FeatureToggle'
import InformationTooltipProvider from '@context/InformationTooltip'
import { MobileMenuControlsProvider } from '@context/MobileMenuControls'
import PopupMenuProvider from '@context/PopupMenu'
import ScrollPositionProvider from '@context/ScrollPosition/Provider'
import loadLocaleData from '@lib/i18n/configureI18n'
import { Logger } from '@lib/logger'
import Theme from '@theme'
import { ModalBaseWrapper } from '@tribegroup'
import configureStore from './configureStore'
import messages from './messages'
import Pages from './pages'

// Load react-intl library locale-data for number and relative-time formatting
loadLocaleData()

const { store, persistor } = configureStore()
class App extends PureComponent {
  componentDidMount() {
    Logger.init()
  }

  public render() {
    return (
      <IntlProvider locale={APP_LOCALE} messages={messages}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={Theme}>
              <ScrollPositionProvider>
                <MobileMenuControlsProvider>
                  <InformationTooltipProvider>
                    <PopupMenuProvider>
                      <FeatureToggleProvider>
                        <SnackBarProvider>
                          <ModalBaseWrapper />
                          <Main>
                            <Tooltip />
                            <Pages />
                            <SnackbarBase />
                            <SubmissionChatSendInProgress />
                          </Main>
                        </SnackBarProvider>
                      </FeatureToggleProvider>
                    </PopupMenuProvider>
                  </InformationTooltipProvider>
                </MobileMenuControlsProvider>
              </ScrollPositionProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </IntlProvider>
    )
  }
}

export default App
