import styled, { css } from 'styled-components'

export const AvatarImage = styled.div`
  border-radius: 100%;
  overflow: hidden;
  ${({ size }) => css`
    height: ${size}rem;
    width: ${size}rem;
  `};
`

export const StoryHalo = styled.img`
  ${({ size }) => css`
    height: ${size + 0.5}rem;
    width: ${size + 0.5}rem;
    position: absolute;
    left: -0.25rem;
    top: -0.25rem;
  `};
`
