import { FormattedMessage } from 'react-intl'

import InfluencerIdentityRowValueAndLabel from '@components/InfluencerIdentity/Row/ValueAndLabel'

const InfluencerIdentityRowEmpty = () => (
  <InfluencerIdentityRowValueAndLabel
    value={<FormattedMessage id="core.metric.empty" />}
    label={''}
  />
)

export default InfluencerIdentityRowEmpty
