import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'
import { CloseIcon as UICloseIcon, Container, setAlpha } from '@tribegroup'

export const PortfolioContainer = styled(Container)`
  display: flex;
  scroll-snap-type: x mandatory;
  overflow: auto hidden;
  width: 100%;
  ${breakpoint('phoneUpperBoundary')`
    padding: 2.5rem 0;
  `}
`

export const PortfolioItemContainer = styled(Container)`
  min-width: 100%;
  scroll-snap-align: center;
`

export const FrameCounter = styled.span`
  position: absolute;
  right: 0.75rem;
  bottom: 0.75rem;
  padding: 0.5rem;
  background-color: ${setAlpha(Theme.grey200Color, 0.7)};
  border-radius: 0.25rem;
  color: ${Theme.grey900Color};
  ${breakpoint('phoneUpperBoundary')`
    background: ${Theme.whiteColor};
    top: 2.75rem;
    margin: 0 1rem;
    bottom: unset;
  `};
`

export const PortfolioModalContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  height: 100%;
  ${breakpoint('phoneUpperBoundary')`
    grid-template-columns: 6rem auto 6rem;
    max-height: 90vh;
  `};
`

export const NavigationButton = styled.button`
  background-color: ${Theme.whiteColor};
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 0;
`

export const CloseIcon = styled(UICloseIcon)`
  display: none;
`
