import React from 'react'

import { format } from 'date-fns'

import Image from '@components/UI/Image'
import MediaVideo from '@components/UI/Media/Video/Video'
import { DAY_MONTH_TIME_WITH_DAY_OF_WEEK } from '@constants'
import { IModalComponentProps } from '@context/Modal'
import { MediaTypes } from '@enums'
import Theme from '@theme'
import { CloseIcon, Container, Modal, Text } from '@tribegroup'
import { Initials } from './PreviewModal.styled'

type Props = {
  url: string
  mediaType: MediaTypes
  authorName?: string
  initials: string
  createdAt: string
}

export type ChatMessageAttachmentPreviewModalProps = Props

export const ChatMessageAttachmentPreviewModal: React.FC<Props & IModalComponentProps> = ({
  onRequestClose,
  isOpen = false,
  url,
  mediaType,
  authorName,
  initials,
  createdAt,
}) => {
  return (
    <Modal reactModalProps={{ isOpen }} width={40} padding={0}>
      <Container
        data-testid="chat-message-attachment-preview-modal"
        flexStart
        flexColumn
        alignItems="flex-start"
      >
        <Container height={2} innerSpacingX={1} innerSpacingY={0.5} flexStart>
          <Initials data-testid="initials">{initials}</Initials>
          <Text>{authorName}</Text>
          <Container leftOuterSpacing={0.5}>
            <Text color={Theme.grey700Color}>
              {format(new Date(createdAt), DAY_MONTH_TIME_WITH_DAY_OF_WEEK)}
            </Text>
          </Container>
          <CloseIcon onClick={onRequestClose} />
        </Container>
        {mediaType === MediaTypes.IMAGE && (
          <Image
            src={url}
            contain
            backgroundColor={Theme.whiteColor}
            fitToContainer
            role="figure"
          />
        )}
        {mediaType === MediaTypes.VIDEO && (
          <MediaVideo
            src={url}
            fullSizeSrc={url}
            mediaType={MediaTypes.VIDEO}
            controls
            showPlayIcon
          />
        )}
      </Container>
    </Modal>
  )
}
