import { useContext } from 'react'

import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import AnalyticsPostCampaignReportEmpty from '@components/Analytics/PostCampaignReport/ListView/Empty/Empty'
import AnalyticsPostCampaignReportListViewAmount from '@components/Analytics/PostCampaignReport/ListView/Item/Amount'
import {
  Entry,
  RowWrapper,
} from '@components/Analytics/PostCampaignReport/ListView/ListView.styled'
import LockableEntry from '@components/Analytics/PostCampaignReport/ListView/LockableEntry/LockableEntry'
import { isNumber } from '@components/Analytics/PrePurchase/PrePurchase'
import { AnalyticsCustomPrice } from '@components/UI/Price/Price'
import { FULL_DATE } from '@constants'
import { CampaignContext } from '@context/Campaign'
import { IApplicationState } from '@store'
import { selectCampaignTotalCurrency } from '@store/campaigns'
import { selectReelMetrics } from '@store/postCampaignReport'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { abbreviateNumber } from '@utils'

interface IProps {
  submissionId: number
  backgroundColor: string
}

export const AnalyticsPostCampaignReportReelListItem: React.FC<IProps> = ({
  submissionId,
  backgroundColor,
}) => {
  const {
    campaign: { is_price_hidden: isPriceHidden, id: campaignId },
  } = useContext(CampaignContext)

  const { currency, metrics } = useSelector((state: IApplicationState) => {
    return {
      metrics: selectReelMetrics(state, submissionId),
      currency: selectCampaignTotalCurrency(state, campaignId),
    }
  })

  if (!metrics || !metrics?.published_at || !metrics?.likes) {
    return (
      <Container>
        <AnalyticsPostCampaignReportEmpty
          minWidth={isPriceHidden ? 58.5 : 84.375}
          backgroundColor={backgroundColor}
        />
      </Container>
    )
  }

  return (
    <Container inline relative height={4.75}>
      <Flex>
        <RowWrapper data-testid="reel-published-date" backgroundColor={backgroundColor}>
          <Entry>
            <Text color={Theme.grey900Color}>
              {format(new Date(metrics.published_at), FULL_DATE)}
            </Text>
          </Entry>
        </RowWrapper>
        {!isPriceHidden && (
          <AnalyticsPostCampaignReportListViewAmount
            backgroundColor={backgroundColor}
            testId="reel-purchase-amount"
            pattern="!###,### "
            value={{ amount: metrics?.purchase_amount, currency }}
          />
        )}
        <RowWrapper data-testid="reel-followers" backgroundColor={backgroundColor}>
          <Entry>
            <Text color={Theme.grey900Color}>{abbreviateNumber(metrics.followers)}</Text>
          </Entry>
        </RowWrapper>
        <RowWrapper data-testid="reel-likes" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!metrics?.likes}
            content={
              isNumber(metrics?.likes) && (
                <Text color={Theme.grey900Color}>{abbreviateNumber(metrics?.likes ?? 0)}</Text>
              )
            }
          />
        </RowWrapper>
        <RowWrapper data-testid="reel-comments" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!metrics?.comments}
            content={
              isNumber(metrics?.comments) && (
                <Text color={Theme.grey900Color}>{abbreviateNumber(metrics?.comments ?? 0)}</Text>
              )
            }
          />
        </RowWrapper>
        <RowWrapper data-testid="reel-shares" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!metrics?.shares}
            content={
              isNumber(metrics?.shares) && (
                <Text color={Theme.grey900Color}>{abbreviateNumber(metrics?.shares ?? 0)}</Text>
              )
            }
          />
        </RowWrapper>
        <RowWrapper data-testid="reel-engagement" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!metrics?.engagement}
            content={
              isNumber(metrics?.engagement) && (
                <Text color={Theme.grey900Color}>{abbreviateNumber(metrics?.engagement ?? 0)}</Text>
              )
            }
          />
        </RowWrapper>
        <RowWrapper data-testid="reel-engagement-pct" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!metrics?.engagement_pct}
            content={
              isNumber(metrics?.engagement_pct) && (
                <Text color={Theme.grey900Color}>
                  <FormattedMessage
                    id="core.text.percent"
                    values={{ value: metrics?.engagement_pct }}
                  />
                </Text>
              )
            }
          />
        </RowWrapper>
        {!isPriceHidden && (
          <RowWrapper data-testid="reel-cpe" backgroundColor={backgroundColor}>
            <LockableEntry
              empty={!metrics?.cpe}
              content={
                isNumber(metrics?.cpe) && (
                  <Text color={Theme.grey900Color}>
                    <AnalyticsCustomPrice currency={currency} quantity={metrics.cpe!} />
                  </Text>
                )
              }
            />
          </RowWrapper>
        )}

        <RowWrapper data-testid="reel-views" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!metrics?.video_views}
            content={
              isNumber(metrics?.video_views) && (
                <Text color={Theme.grey900Color}>
                  {abbreviateNumber(metrics?.video_views ?? 0)}
                </Text>
              )
            }
          />
        </RowWrapper>
        <RowWrapper data-testid="reel-view-pct" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!metrics?.video_views_pct}
            content={
              isNumber(metrics?.video_views_pct) && (
                <Text color={Theme.grey900Color}>
                  <FormattedMessage
                    id="core.text.percent"
                    values={{ value: metrics?.video_views_pct }}
                  />
                </Text>
              )
            }
          />
        </RowWrapper>
        {!isPriceHidden && (
          <>
            <RowWrapper data-testid="reel-cpm" backgroundColor={backgroundColor}>
              <LockableEntry
                empty={!metrics?.cpm}
                content={
                  isNumber(metrics?.cpm) && (
                    <Text color={Theme.grey900Color}>
                      <AnalyticsCustomPrice currency={currency} quantity={metrics.cpm!} />
                    </Text>
                  )
                }
              />
            </RowWrapper>
            <RowWrapper data-testid="reel-cpv" backgroundColor={backgroundColor}>
              <LockableEntry
                empty={!metrics?.cpv}
                content={
                  isNumber(metrics?.cpv) && (
                    <Text color={Theme.grey900Color}>
                      <AnalyticsCustomPrice currency={currency} quantity={metrics.cpv!} />
                    </Text>
                  )
                }
              />
            </RowWrapper>
          </>
        )}
      </Flex>
    </Container>
  )
}
