import { useEffect } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import CampaignCard from '@components/Campaign/Card'
import { CardGridWithInfinteScroll } from '@components/UI/CardGrid/CardGrid.styled'
import Loading from '@components/UI/Loading'
import { ALL } from '@constants'
import { ICampaignListFilterContextProps } from '@context/CampaignListFilter'
import { withScrollToTop } from '@hocs'
import { withResetScrollPosition } from '@hocs'
import { useCampaigns } from '@hooks/campaigns/useCampaigns'
import { fetchCampaigns, ICampaign } from '@store/campaigns'
import { isEmpty } from '@utils'
import CampaignListEmpty from './Empty'

export interface IInternalProps extends ICampaignListFilterContextProps, InjectedIntlProps {
  campaigns: ReadonlyArray<ICampaign>
  fetchCampaigns: typeof fetchCampaigns
  fetchCampaignsUsingNext: typeof fetchCampaigns
  hasEmptyList?: boolean
  hasMore: boolean
  isInitialFetch?: boolean
}

const CampaignList: React.FC<IInternalProps> = () => {
  const {
    campaigns,
    hasMore,
    isFetching,
    fetchMore,
    currentCampaignStatusFilter,
    fetch,
  } = useCampaigns()

  const hasEmptyList = isEmpty(campaigns)

  useEffect(() => {
    if (!isFetching) {
      fetch()
    }
  }, [currentCampaignStatusFilter])

  const showPill = currentCampaignStatusFilter === ALL

  if (isFetching && hasEmptyList) {
    return <Loading height={30} />
  }

  if (hasEmptyList) {
    return <CampaignListEmpty />
  }

  return (
    <CardGridWithInfinteScroll data-testid="campaigns-grid">
      <InfiniteScroll
        dataLength={campaigns.length}
        next={fetchMore}
        loader={<Loading height={30} />}
        hasMore={hasMore}
        scrollThreshold={0.8}
        style={{ overflow: 'hidden' }}
      >
        <div>
          {campaigns.map((campaign: ICampaign) => (
            <CampaignCard key={campaign.id} campaign={campaign} showPill={showPill} />
          ))}
        </div>
      </InfiniteScroll>
    </CardGridWithInfinteScroll>
  )
}

export { CampaignList }
export default compose<IInternalProps, {}>(
  withScrollToTop,
  injectIntl,
  withResetScrollPosition,
)(CampaignList)
