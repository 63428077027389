import { FC, useEffect, useState } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'recompose'

import StripeError from '@components/UI/Error/StripeError'
import { IModalComponentProps, IModalContextProps } from '@context/Modal'
import { withModal } from '@hocs'
import { useIsMounted } from '@hooks/useIsMounted'
import { IApplicationState } from '@store'
import { selectIsSamplingEnabled } from '@store/campaigns'
import {
  clearErrors,
  selectErrorCode,
  selectErrors,
  selectHasInsufficientFundsForSubmission,
  selectIsPitchingEnabled,
  selectIsPreApproving,
  selectIsPriceHidden,
} from '@store/submissions'
import Theme from '@theme'
import { CloseIcon, Container, Modal, ModalTitle, Text } from '@tribegroup'
import SubmissionCardModalTermsOfService from '../components/TermsOfService'
import SubmissionCardModalPreApprovalCostBreakdown from './CostBreakdown'
import SubmissionCardModalPreApprovalDisclaimer from './Disclaimer'
import { SubmissionCardModalPreApprovalCostBreakdownSpacer } from './PreApproval.styled'
import SubmissionCardModalPreApprovalPreapprovalCTA from './PreapprovalCTA'
import SubmissionCardModalPreApprovalSubTitle from './SubTitle'

interface IProps extends IModalComponentProps {
  submissionId: number
  campaignId: number
}

interface IInternalProps extends IProps, IModalContextProps, InjectedIntlProps {}

const SubmissionCardModalPreApproval: FC<IInternalProps> = ({
  submissionId,
  campaignId,
  onRequestClose,
  isOpen = false,
  hideModal,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const isMounted = useIsMounted()
  const dispatch = useDispatch()

  const {
    errorCode,
    isPreApproving,
    hasError,
    hasInsufficientFunds,
    isSamplingEnabled,
    isPitchingEnabled,
    showCostBreakdown,
  } = useSelector((state: IApplicationState) => {
    return {
      errorCode: selectErrorCode(state),
      isPreApproving: selectIsPreApproving(state),
      hasError: Boolean(selectErrors(state)),
      hasInsufficientFunds: selectHasInsufficientFundsForSubmission(state),
      isSamplingEnabled: selectIsSamplingEnabled(state, campaignId),
      isPitchingEnabled: Boolean(selectIsPitchingEnabled(state, submissionId)),
      showCostBreakdown: !selectIsPriceHidden(state, submissionId),
    }
  })

  useEffect(() => {
    if (isMounted && !isPreApproving && !hasError) {
      hideModal()
    }
  }, [isPreApproving])

  useEffect(() => {
    if (hasInsufficientFunds) {
      hideModal()
    }
  }, [hasInsufficientFunds])

  useEffect(() => {
    return () => {
      if (hasInsufficientFunds || hasError) {
        dispatch(clearErrors())
      }
    }
  }, [hasInsufficientFunds, hasError])

  const showGenericError = hasError && !hasInsufficientFunds && !errorCode

  return (
    <Modal reactModalProps={{ isOpen }} width={27.5} complex>
      <Container data-testid="preapproval-modal">
        <ModalTitle>
          <FormattedMessage id="core.text.preApproval" />
        </ModalTitle>

        <CloseIcon onClick={onRequestClose} />
        <SubmissionCardModalPreApprovalSubTitle
          isExpanded={isExpanded}
          isSamplingEnabled={isSamplingEnabled}
          isPitchingEnabled={isPitchingEnabled}
        />
        <SubmissionCardModalPreApprovalDisclaimer
          isExpanded={isExpanded}
          isPitchingEnabled={isPitchingEnabled}
        />

        {showCostBreakdown ? (
          <SubmissionCardModalPreApprovalCostBreakdown
            submissionId={submissionId}
            campaignId={campaignId}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        ) : (
          <SubmissionCardModalPreApprovalCostBreakdownSpacer />
        )}

        <Container topOuterSpacing={2.5}>
          {!hasInsufficientFunds && errorCode && (
            <Container bottomOuterSpacing={1}>
              <StripeError errorCode={errorCode} />
            </Container>
          )}

          {showGenericError && (
            <Container bottomOuterSpacing={1}>
              <Text color={Theme.errorColor} small>
                <FormattedMessage id="core.text.shortGenericError" />
              </Text>
            </Container>
          )}

          <SubmissionCardModalPreApprovalPreapprovalCTA
            submissionId={submissionId}
            campaignId={campaignId}
          />
        </Container>
        <SubmissionCardModalTermsOfService />
      </Container>
    </Modal>
  )
}

export default compose<IInternalProps, IProps>(
  withModal,
  injectIntl,
)(SubmissionCardModalPreApproval)
