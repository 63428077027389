import { useContext } from 'react'

import { FormattedMessage } from 'react-intl'

import { AnalyticsPostCampaignReportGlossaryEntry } from '@components/Analytics/PostCampaignReport/Glossary'
import { CPM_EXPLAINED } from '@constants'
import { CampaignContext } from '@context/Campaign'
import Theme from '@theme'
import { Link } from '@tribegroup'

export const AnalyticsPostCampaignReportGlossaryReel = () => {
  const {
    campaign: { indicative_budget_currency: currency },
  } = useContext(CampaignContext)

  return (
    <>
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="reel.published" />
      <AnalyticsPostCampaignReportGlossaryEntry
        translationKey="reel.spend"
        values={{
          taxDisplay: <FormattedMessage id={`core.currency.taxDisplay.${currency ?? 'generic'}`} />,
        }}
      />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="reel.engagement" />
      <AnalyticsPostCampaignReportGlossaryEntry
        translationKey="reel.cpe"
        values={{
          taxDisplay: <FormattedMessage id={`core.currency.taxDisplay.${currency ?? 'generic'}`} />,
        }}
      />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="reel.views" />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="reel.followers" />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="reel.likes" />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="reel.comments" />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="reel.shares" />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="reel.engagementPct" />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="reel.viewPct" />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="reel.cpv" />
      <AnalyticsPostCampaignReportGlossaryEntry
        translationKey="reel.cpm"
        values={{
          link: (
            <Link href={CPM_EXPLAINED} target="_blank" color={Theme.primaryColor} lowercase>
              <FormattedMessage id="core.text.clickHere" />
            </Link>
          ),
        }}
      />
    </>
  )
}
