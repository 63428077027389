import { createAction } from '@reduxjs/toolkit'

import { BrandManagerIdentityProviders } from '@enums'
import { IResponseError } from '@lib/error'
import { DisconnectCampaignSocialAccountActionType } from '@store/campaigns'

export const disconnectCampaignSocialAccount = createAction(
  DisconnectCampaignSocialAccountActionType?.REQUEST,
  (
    socialAccountId: string,
    campaignId: number,
    handle: string,
    channel?: BrandManagerIdentityProviders,
  ) => ({
    payload: { socialAccountId, campaignId, handle, channel },
  }),
)

export const disconnectCampaignSocialAccountSuccess = createAction(
  DisconnectCampaignSocialAccountActionType?.SUCCESS,
)

export const disconnectCampaignSocialAccountError = createAction<IResponseError>(
  DisconnectCampaignSocialAccountActionType?.ERROR,
)
