import { ContentLibraryFilterTypes } from '@enums'
import hooks from '@hooks'

interface IUseContentLibraryFilterResults {
  isLicenseRequests: boolean
  isLicensed: boolean
  isDiscover: boolean
}

const useContentLibraryFilter = () => {
  const isLicenseRequests = hooks.useQueryStringMatcher(
    'filter',
    ContentLibraryFilterTypes.LICENSE_REQUESTS,
  )
  const isLicensed = hooks.useQueryStringMatcher('filter', ContentLibraryFilterTypes.LICENSED)

  return {
    isLicenseRequests,
    isLicensed,
    isDiscover: !isLicenseRequests && !isLicensed,
  } as IUseContentLibraryFilterResults
}

export { useContentLibraryFilter }
