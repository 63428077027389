import React, { useContext } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import AnalyticsPostCampaignReportListViewProfile from '@components/Analytics/PostCampaignReport/ListView/Profile/Profile'
import { getBackgroundColor } from '@components/Analytics/PostCampaignReport/Post/List/List'
import { CampaignContext } from '@context/Campaign'
import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { selectStoryMetricIds } from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import { Container } from '@tribegroup'
import AnalyticsPostCampaignReportListViewHeaders from '../../ListView/Headers/Headers'
import AnalyticsPostCampaignReportListView from '../../ListView/ListView'
import AnalyticsPostCampaignReportStoryListItem from './Item/Item'

type IInternalProps = ConnectedProps<typeof connector>

const AnalyticsPostCampaignReportStoryList: React.FC<IInternalProps> = ({ submissionIds }) => {
  const {
    campaign: { is_price_hidden: isPriceHidden },
  } = useContext(CampaignContext)

  const translationKeys = ['published', !isPriceHidden && 'spend', 'followers'].filter(
    Boolean,
  ) as ReadonlyArray<string>

  const profiles = submissionIds.map((submissionId, index) => (
    <Container data-testid="story-profile-wrapper" key={`profile-${submissionId}`}>
      <AnalyticsPostCampaignReportListViewProfile
        backgroundColor={getBackgroundColor(index)}
        submissionId={submissionId}
      />
    </Container>
  ))

  return (
    <AnalyticsPostCampaignReportListView
      profiles={profiles}
      headers={
        <AnalyticsPostCampaignReportListViewHeaders
          translationKeys={translationKeys}
          isPriceHidden={isPriceHidden}
        />
      }
    >
      {submissionIds.map((submissionId, index) => (
        <Container key={`story-list-${submissionId}`} data-testid="story-list-item">
          <AnalyticsPostCampaignReportStoryListItem
            submissionId={submissionId}
            backgroundColor={getBackgroundColor(index)}
          />
        </Container>
      ))}
    </AnalyticsPostCampaignReportListView>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  const submissionIds = selectStoryMetricIds(state, campaignId, PCRChannels.IGStories)

  return {
    submissionIds,
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(
  withRouter,
  connector,
)(AnalyticsPostCampaignReportStoryList)
