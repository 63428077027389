import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import {
  ColWrapper,
  Header,
} from '@components/Analytics/PostCampaignReport/ListView/ListView.styled'
import Theme from '@theme'
import { Text } from '@tribegroup'

interface IProps {
  isPriceHidden?: boolean
}

const AnalyticsPostCampaignReportListViewTikTokHeaders: React.FC<IProps> = ({
  isPriceHidden = false,
}) => {
  return (
    <Flex full>
      <ColWrapper key={`tiktok-header-published`}>
        <Header>
          <Text uppercase color={Theme.grey800Color} xsmall>
            <FormattedMessage
              id={`analytics.postCampaignReport.submission.list.header.published`}
            />
          </Text>
        </Header>
      </ColWrapper>
      {!isPriceHidden && (
        <ColWrapper key={`tiktok-header-spend`}>
          <Header>
            <Text uppercase color={Theme.grey800Color} xsmall>
              <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.spend`} />
            </Text>
          </Header>
        </ColWrapper>
      )}
      <ColWrapper key={`tiktok-header-followers`}>
        <Header>
          <Text uppercase color={Theme.grey800Color} xsmall>
            <FormattedMessage
              id={`analytics.postCampaignReport.submission.list.header.followers`}
            />
          </Text>
        </Header>
      </ColWrapper>
      <ColWrapper key={`tiktok-header-likes`}>
        <Header>
          <Text uppercase color={Theme.grey800Color} xsmall>
            <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.likes`} />
          </Text>
        </Header>
      </ColWrapper>
      <ColWrapper key={`tiktok-header-comments`}>
        <Header>
          <Text uppercase color={Theme.grey800Color} xsmall>
            <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.comments`} />
          </Text>
        </Header>
      </ColWrapper>
      <ColWrapper key={`tiktok-header-shares`}>
        <Header>
          <Text uppercase color={Theme.grey800Color} xsmall>
            <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.shares`} />
          </Text>
        </Header>
      </ColWrapper>
      <ColWrapper key={`tiktok-header-eng`}>
        <Header>
          <Text uppercase color={Theme.grey800Color} xsmall>
            <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.eng`} />
          </Text>
        </Header>
      </ColWrapper>
      <ColWrapper key={`tiktok-header-engagementPct`}>
        <Header>
          <Text uppercase color={Theme.grey800Color} xsmall>
            <FormattedMessage
              id={`analytics.postCampaignReport.submission.list.header.engagementPct`}
            />
          </Text>
        </Header>
      </ColWrapper>
      {!isPriceHidden && (
        <ColWrapper key={`tiktok-header-cpe`}>
          <Header>
            <Text uppercase color={Theme.grey800Color} xsmall>
              <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.cpe`} />
            </Text>
          </Header>
        </ColWrapper>
      )}

      <ColWrapper key={`tiktok-header-views`}>
        <Header>
          <Text uppercase color={Theme.grey800Color} xsmall>
            <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.views`} />
          </Text>
        </Header>
      </ColWrapper>
      <ColWrapper key={`tiktok-header-viewPct`}>
        <Header>
          <Text uppercase color={Theme.grey800Color} xsmall>
            <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.viewPct`} />
          </Text>
        </Header>
      </ColWrapper>
      {!isPriceHidden && (
        <>
          <ColWrapper key={`tiktok-header-cpv`}>
            <Header>
              <Text uppercase color={Theme.grey800Color} xsmall>
                <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.cpv`} />
              </Text>
            </Header>
          </ColWrapper>
          <ColWrapper key={`tiktok-header-cpm`}>
            <Header>
              <Text uppercase color={Theme.grey800Color} xsmall>
                <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.cpm`} />
              </Text>
            </Header>
          </ColWrapper>
        </>
      )}
    </Flex>
  )
}

export default AnalyticsPostCampaignReportListViewTikTokHeaders
