import styled from 'styled-components'

export const PillWrapperStyled = styled.div`
  position: absolute;
  left: 1rem;
  top: 1rem;

  > span {
    padding: 0.25rem 0.625rem;
  }
`

export default PillWrapperStyled
