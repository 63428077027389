import React from 'react'

import { compose } from 'recompose'

import { ICampaignContextProps } from '@context/Campaign'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import { withCampaign, withSubmissionListFilter } from '@hocs'
import { isSocialCampaign } from '@utils/campaign'
import SubmissionCardActionBarContent from './Content'
import SubmissionCardActionBarSelectionMode from './SelectionMode'
import SubmissionCardActionBarSocial from './Social'

interface IProps {
  submissionId: number
  showLicenseRequestAsApprove?: boolean
  isSelectionModeOn?: boolean
}

interface IInternalProps extends IProps, ISubmissionListFilterContextProps, ICampaignContextProps {}

const SubmissionCardActionBar: React.FC<IInternalProps> = ({
  submissionId,
  campaign,
  isSelectionModeOn,
}) => {
  if (isSelectionModeOn) {
    return <SubmissionCardActionBarSelectionMode submissionId={submissionId} />
  }

  const isSocial = isSocialCampaign(campaign)

  if (!isSocial) {
    return <SubmissionCardActionBarContent campaignId={campaign.id} submissionId={submissionId} />
  }

  return <SubmissionCardActionBarSocial campaignId={campaign.id} submissionId={submissionId} />
}

export { SubmissionCardActionBar }
export default compose<IInternalProps, IProps>(
  withCampaign,
  withSubmissionListFilter,
)(SubmissionCardActionBar)
