import { format } from 'date-fns'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, RouterProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import {
  BreakdownDownloadLink,
  BreakdownTableData,
  BreakdownTableDataActionContainer,
  BreakdownTableRow,
} from '@components/Billing/Breakdown/Breakdown.styled'
import HoverIcon from '@components/UI/HoverIcon/HoverIcon'
import Price from '@components/UI/Price/Price'
import { StopPropagation } from '@components/UI/StopPropagation/StopPropagation'
import { TrackedRoute } from '@components/UI/TrackedRoute'
import { FULL_DATE } from '@constants'
import { BillingTypes, EventTrackingNames } from '@enums'
import { IApplicationState, IHateoasLink } from '@store'
import { IPurchase, selectReceiptLink } from '@store/billings'
import { ICampaign } from '@store/campaigns'
import { selectBillingType } from '@store/router'
import Theme from '@theme'
import { Text } from '@tribegroup'
import { isEmpty, objectToQueryString } from '@utils'

interface IPurchaseBreakdownRowProps {
  purchase: IPurchase
  campaign: ICampaign
  searchParams?: {}
}

interface IInternalProps extends IPurchaseBreakdownRowProps, RouterProps, InjectedIntlProps {
  receiptLink?: IHateoasLink
  billingType: BillingTypes
}

export const PurchaseBreakdownRow = ({
  campaign,
  purchase,
  history,
  searchParams,
  receiptLink,
  intl,
  billingType,
}: IInternalProps) => {
  const submissionId = purchase.submission_id
  const campaignId = campaign.id

  const redirectToSubmission = () => {
    const pathname =
      billingType === BillingTypes.Campaigns
        ? `/campaigns/${campaignId}/submissions/${submissionId}`
        : `/media-objects/${purchase.media_object_id}`

    history.push({
      pathname,
      search: searchParams && objectToQueryString(searchParams),
    })
  }

  const showDownloadReceipt = !isEmpty(receiptLink)

  return (
    <BreakdownTableRow onClick={submissionId ? redirectToSubmission : undefined}>
      <BreakdownTableData>
        <Text color={Theme.grey900Color}>{purchase.purchase_number}</Text>
      </BreakdownTableData>
      <BreakdownTableData>
        <Text color={Theme.grey900Color}>{purchase.purchase_order_numbers}</Text>
      </BreakdownTableData>
      <BreakdownTableData>
        <Text color={Theme.grey900Color}>
          <FormattedMessage id={`core.text.${purchase.payment_method}`} />
        </Text>
      </BreakdownTableData>
      <BreakdownTableData>
        <Text color={Theme.grey900Color}>{format(new Date(purchase.purchased_at), FULL_DATE)}</Text>
      </BreakdownTableData>
      <BreakdownTableData>
        <Text color={Theme.grey900Color}>
          <Price
            quantity={purchase.brand_amount_before_tax_cents}
            currency={purchase.brand_amount_before_tax_currency}
          />
        </Text>
      </BreakdownTableData>
      <BreakdownTableData>
        <Text color={Theme.grey900Color}>
          <Price quantity={purchase.brand_tax_cents} currency={purchase.brand_tax_currency} />
        </Text>
      </BreakdownTableData>
      <BreakdownTableData>
        <Text color={Theme.grey900Color}>
          <Price quantity={purchase.brand_amount_cents} currency={purchase.brand_amount_currency} />
        </Text>
      </BreakdownTableData>
      <BreakdownTableData>
        <BreakdownTableDataActionContainer>
          {showDownloadReceipt && (
            <BreakdownDownloadLink>
              <StopPropagation>
                <TrackedRoute
                  eventName={EventTrackingNames.DownloadBillingHistory}
                  properties={{
                    purchase_id: purchase.id,
                    link_url: receiptLink!.href,
                  }}
                >
                  <a
                    href={receiptLink!.href}
                    download
                    role="button"
                    data-tooltip-id="base-tooltip"
                    data-tooltip-content={intl.formatMessage({
                      id: 'billing.purchaseBreakdown.downloadReceipt',
                    })}
                    data-offset="{'bottom': 12}"
                    data-testid={`purchase-${purchase.id}-line-item-download-button`}
                  >
                    <HoverIcon
                      glyph="license-content"
                      size={1.375}
                      hoverColor={Theme.grey900Color}
                      color={Theme.grey700Color}
                    />
                  </a>
                </TrackedRoute>
              </StopPropagation>
            </BreakdownDownloadLink>
          )}
        </BreakdownTableDataActionContainer>
      </BreakdownTableData>
    </BreakdownTableRow>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { purchase, location }: IInternalProps & RouteComponentProps,
) => ({
  receiptLink: selectReceiptLink(state, purchase.id),
  billingType: selectBillingType(location),
})

export default compose<IInternalProps, IPurchaseBreakdownRowProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps),
)(PurchaseBreakdownRow)
