import { useMemo } from 'react'

import hooks from '@hooks'

const useQueryStringMatcher = (name: string, value: string) => {
  const search = hooks.useLocation().search
  const isMatch = useMemo(() => {
    const query = new URLSearchParams(search)
    return query.get(name) === value
  }, [name, value, search])
  return isMatch
}

export { useQueryStringMatcher }
