import React, { PropsWithChildren } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { ConditionalTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectMediaObjectType } from '@store/mediaObjects'
import { selectShareableContentLibraryToken } from '@store/router'

interface IProps {
  eventName: EventTrackingNames
  mediaObjectId?: number
  source?: string
  hasTracking?: boolean
  additionalProperties?: object
}

interface IInternalProps extends IProps {
  libraryOwner: string
  contentType?: string
}

const ShareableContentLibraryTrackedRoute: React.FC<PropsWithChildren<IInternalProps>> = ({
  eventName,
  libraryOwner,
  contentType,
  mediaObjectId,
  source,
  children,
  additionalProperties,
  hasTracking = true,
}) => (
  <ConditionalTrackedRoute
    hasTracking={hasTracking}
    eventName={eventName}
    properties={{
      library_owner: libraryOwner,
      content_type: contentType,
      media_object_id: mediaObjectId,
      source,
      ...additionalProperties,
    }}
  >
    {children}
  </ConditionalTrackedRoute>
)

const mapStateToProps = (
  state: IApplicationState,
  { match, mediaObjectId }: RouteComponentProps & IProps,
) => ({
  libraryOwner: selectShareableContentLibraryToken(match),
  contentType: selectMediaObjectType(state, mediaObjectId as number),
})

export { ShareableContentLibraryTrackedRoute }
export default compose<IInternalProps, PropsWithChildren<IProps>>(
  withRouter,
  connect(mapStateToProps),
)(ShareableContentLibraryTrackedRoute)
