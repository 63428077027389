import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const HoverToggleChild = styled.div`
  ${breakpoint('phoneUpperBoundary')`
    opacity: 0;
    transition: opacity 200ms;
    ${({ visible }) => visible && `opacity: 1`};
  `};
`

const HoverToggleParent = styled.div`
  &:hover {
    ${HoverToggleChild} {
      opacity: 1;
    }
  }
`

export { HoverToggleChild, HoverToggleParent }
