import React from 'react'

import HeaderStyled from './Header.styled'

export interface IHeaderProps {
  /**
   * Applies small subheader typography
   */
  small?: boolean

  /**
   * Applies cta typography
   */
  cta?: boolean

  /**
   * Displays text as uppercase
   *
   */
  uppercase?: boolean

  /**
   * displays element as inline
   */
  inline?: boolean

  /**
   * left margin in rem
   */
  leftOuterSpacing?: number

  /**
   * right margin in rem
   */
  rightOuterSpacing?: number

  /**
   * top margin in rem
   */
  topOuterSpacing?: number

  /**
   * bottom margin in rem
   */
  bottomOuterSpacing?: number

  /**
   * Displays the text alignment
   *
   * @default 'left'
   */
  textAlign?: 'left' | 'center' | 'right'

  /**
   * display as 100% width
   */
  fullWidth?: boolean
}

export class Header extends React.PureComponent<
  IHeaderProps & React.HtmlHTMLAttributes<HTMLHeadingElement>
> {
  public render() {
    return <HeaderStyled {...this.props} />
  }
}
