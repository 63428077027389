import { useContext } from 'react'

import { InboxPromptContext } from '@context/InboxPrompt'
import { IInboxPromptContextProps } from '@context/InboxPrompt/Provider'

const useInboxPrompt = () => {
  const contextProps = useContext<IInboxPromptContextProps>(InboxPromptContext)
  return contextProps
}

export { useInboxPrompt }
