import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'

const onHover = `
  &:hover {
    .card-menu-toggle {
      opacity: 1;
      svg g {
        fill: ${Theme.grey700Color};
      }
      &:hover,
      &:active,
      &[open] {
        svg g {
          fill: ${Theme.grey900Color};
        }
      }
    }
  }
`

export const MoreMenuWrapperVisibleOnHover = styled.div`
  .card-menu-toggle {
    opacity: 1;
    ${breakpoint('smallDesktop')`
      opacity: 0;
      transition: opacity 0.2s;
      &[open] {
        opacity: 1;
        svg g {
          fill: ${Theme.grey900Color};
        }
      }
    `};
  }

  ${onHover};
`

export const MoreMenuWrapperAlwaysVisible = styled.div`
  ${onHover};
`
