import { AddBrandFanGroupMemberActionTypes } from '@store/brandFans'

export const addBrandFanGroupMember = (
  identityId: number,
  groupId: number,
  campaignId?: number,
  submissionId?: number,
  influencerId?: number,
) => ({
  payload: { identityId, influencerId, groupId, submissionId, campaignId },
  type: AddBrandFanGroupMemberActionTypes.ADD_MEMBER_REQUEST,
})

export const addBrandFanGroupMemberSuccess = (identityId: number, groupId: number) => ({
  payload: { identityId, groupId },
  type: AddBrandFanGroupMemberActionTypes.ADD_MEMBER_SUCCESS,
})

export const addBrandFanGroupMemberError = (groupId: number, error: any) => ({
  payload: { groupId, error },
  type: AddBrandFanGroupMemberActionTypes.ADD_MEMBER_ERROR,
})
