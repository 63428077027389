export const getMinimumBudgetMessage = (
  intl: ReactIntl.InjectedIntl,
  minimum: number,
  currency: string,
) => {
  const currencySymbol = intl.formatMessage({ id: `core.currency.symbol.${currency}` })
  const minBudgetThreshold = intl.formatNumber(minimum)
  const minBudgetMessage = intl.formatMessage(
    { id: 'builder.submit.budget.invoice.min' },
    {
      min: minBudgetThreshold,
      currency: currencySymbol,
    },
  )

  return minBudgetMessage
}
