import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import { selectCampaignIsFinalizing, selectCampaignIsSaving } from '@store/campaigns'
import { Button } from '@tribegroup'

interface IInternalProps extends IBuilderValidationContextProps, ConnectedProps<typeof connector> {}

const BuilderTitleBarActionsButtonFinalize: React.FC<IInternalProps> = ({
  isSaving,
  isEnableSubmit,
  isEnableActions,
  hasStepValidationErrors,
  validate,
  isFinalizing,
}) => {
  const onClick = (event) => {
    if (hasStepValidationErrors) {
      event.preventDefault()
      validate()
    }
  }

  const disabled = isSaving || !isEnableSubmit || !isEnableActions

  return (
    <Button
      scheme="primary"
      small
      width={9.1875}
      disabled={disabled}
      onClick={onClick}
      loading={isFinalizing}
      data-cy-element="submit-button"
      data-testid="campaign-builder-submit-button"
    >
      <FormattedMessage id="core.text.submit" />
    </Button>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  isSaving: selectCampaignIsSaving(state),
  isFinalizing: selectCampaignIsFinalizing(state),
})
const connector = connect(mapStateToProps)
export { BuilderTitleBarActionsButtonFinalize }
export default compose(withBuilderValidation, connector)(BuilderTitleBarActionsButtonFinalize)
