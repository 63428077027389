import { createReducer } from '@reduxjs/toolkit'

import { LocationChangeAction } from './actions'
import { Location } from './types'

export interface IRouterState {
  location: Location
}

const initialState = {
  location: {
    pathname: '',
    hash: '',
    search: '',
  },
} as IRouterState

export const routerReducer = createReducer<IRouterState>(initialState, (builder) => {
  builder.addCase(LocationChangeAction, (state, action) => {
    state.location = action.payload
  })
})
