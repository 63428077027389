import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { SubmissionChannels } from '@enums'
import { IApplicationState } from '@store'
import {
  selectAverageEngagementPercent,
  selectAverageImpressions,
  selectAverageReach,
  selectCollabsCount,
  selectFollowersCount,
} from '@store/brandFans'
import BrandFanInfluencerIdentityRowPerformanceAverageEngagementPercent, {
  IPerformanceAverageEngagementPercentProps,
} from './AverageEngagementPercent'
import BrandFanInfluencerIdentityRowPerformanceAverageImpressions, {
  IPerformanceAverageImpressionsProps,
} from './AverageImpressions'
import BrandFanInfluencerIdentityRowPerformanceAverageReachPercent, {
  IPerformanceAverageReachPercentProps,
} from './AverageReachPercent'
import BrandFanInfluencerIdentityRowPerformanceCollabs, {
  IPerformanceCollabsProps,
} from './Collabs'
import BrandFanInfluencerIdentityRowPerformanceFollowers, {
  IPerformanceFollowersProps,
} from './Followers'

interface IProps {
  identityId: number
  provider: SubmissionChannels
}

interface IInternalProps
  extends IPerformanceAverageEngagementPercentProps,
    IPerformanceFollowersProps,
    IPerformanceCollabsProps,
    IPerformanceAverageReachPercentProps,
    IPerformanceAverageImpressionsProps {}

const BrandFanInfluencerIdentityRowPerformance: React.FC<IInternalProps> = ({
  followerCount,
  averageEngagementPercent,
  averageImpressions,
  collabs,
  averageReach,
  provider,
}) => {
  return (
    <React.Fragment>
      <BrandFanInfluencerIdentityRowPerformanceFollowers
        followerCount={followerCount}
        provider={provider}
      />
      <BrandFanInfluencerIdentityRowPerformanceAverageEngagementPercent
        averageEngagementPercent={averageEngagementPercent}
        provider={provider}
      />
      <BrandFanInfluencerIdentityRowPerformanceAverageReachPercent
        averageReach={averageReach}
        provider={provider}
      />
      <BrandFanInfluencerIdentityRowPerformanceAverageImpressions
        averageImpressions={averageImpressions}
        provider={provider}
      />
      <BrandFanInfluencerIdentityRowPerformanceCollabs collabs={collabs} />
    </React.Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { identityId }: IProps) => ({
  followerCount: selectFollowersCount(state, identityId),
  averageEngagementPercent: selectAverageEngagementPercent(state, identityId),
  averageImpressions: selectAverageImpressions(state, identityId),
  collabs: selectCollabsCount(state, identityId),
  averageReach: selectAverageReach(state, identityId),
})

export { BrandFanInfluencerIdentityRowPerformance }
export default compose<IInternalProps, IProps>(connect(mapStateToProps))(
  BrandFanInfluencerIdentityRowPerformance,
)
