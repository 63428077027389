import React from 'react'

import Theme from '@theme'
import { H3, H5, Header, Icon } from '@tribegroup'
import Information from '../Information/Information'
import DetailCardStyled, {
  DetailCardBody,
  DetailCardTitle,
  DetailCardTitleHeader,
} from './DetailCard.styled'

export interface IBoxProps {
  title: React.ReactNode
  content: React.ReactNode
  tooltip?: React.ReactNode
  tooltipWidth?: number
  elementName?: string
  smallContentText?: boolean
  color?: string
  glyph?: string
  glyphColor?: string
}

const DetailCard = ({
  title,
  content,
  tooltip,
  elementName,
  smallContentText,
  color = Theme.grey900Color,
  glyph = 'information',
  glyphColor = Theme.grey900Color,
  tooltipWidth = 17,
}: IBoxProps) => (
  <DetailCardStyled data-cy-element={elementName} data-testid={elementName}>
    <DetailCardTitle justifyBetween>
      <DetailCardTitleHeader>
        <Header cta color={color} uppercase>
          {title}
        </Header>
      </DetailCardTitleHeader>
      {tooltip && (
        <Information width={tooltipWidth} target={<Icon glyph={glyph} color={glyphColor} />}>
          {tooltip}
        </Information>
      )}
    </DetailCardTitle>
    <DetailCardBody>
      {smallContentText ? <H5 color={color}>{content}</H5> : <H3 color={color}>{content}</H3>}
    </DetailCardBody>
  </DetailCardStyled>
)

export default DetailCard
