import styled, { css } from 'styled-components'

import { Pulse } from '@lib/effects'
import Theme from '@theme'

export interface IActionIconStyledProps {
  highlighted?: boolean
  animatePulse?: boolean
}

export default styled<IActionIconStyledProps, any>('div')`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  transition: 0.2s;
  cursor: pointer;

  ${({ backgroundColor }) => css`
    ${backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
  `}
  ${({ disabled, noHover }) => css`
    ${(disabled || noHover) &&
    css`
      cursor: default;
    `}
  `}
  ${({ highlighted }) => css`
    ${highlighted &&
    css`
      background-color: ${Theme.primary100Color};
    `};
  `}
  &:hover {
    ${({ disabled, highlighted, noHover, hoverBackgroundColor = Theme.grey200Color }) => css`
      background-color: ${disabled || noHover
        ? 'none'
        : highlighted
        ? Theme.primary200Color
        : hoverBackgroundColor};
    `}
  }
`

export const PulseAnimation = styled.span`
  position: relative;
  display: inline-block;

  &::before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${Theme.primaryColor};
    top: 0;
    left: 0;
    border-radius: 1.25rem;
    animation: ${Pulse} 1.5s ease-out 2;
    opacity: 0;
  }
`

export const NotificationDotStyled = styled.div`
  position: absolute;
  right: 0.375rem;
  top: 0.375rem;
  background: red;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 6.25rem;
`
