import { useContext } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { CampaignContext } from '@context/Campaign'
import { BrandManagerIdentityProviders } from '@enums'
import { IBrandManagerIdentity } from '@store/brandManagerIdentities'
import {
  connectCampaignSocialAccount,
  disconnectCampaignSocialAccount,
  IConnectedSocialAccount,
  selectIsConnectingCampaignIdentity,
} from '@store/campaigns'
import { ConnectedAccountsDrawerIdentityInstagram } from './Instagram/Instagram'
import { ConnectedAccountsDrawerIdentityTikTok } from './TikTok/TikTok'

interface IProps {
  provider: BrandManagerIdentityProviders
  brandManagerIdentity?: IBrandManagerIdentity | IConnectedSocialAccount
  isActive?: boolean
}

const ConnectedAccountsDrawerIdentity = ({ isActive, brandManagerIdentity, provider }: IProps) => {
  const dispatch = useDispatch()

  const isConnectingCampaignIdentity = useSelector(selectIsConnectingCampaignIdentity)

  const {
    campaign: { id: campaignId },
  } = useContext(CampaignContext)

  const onClick = () => {
    if (isConnectingCampaignIdentity || !brandManagerIdentity) {
      return
    }

    if (isActive) {
      dispatch(
        disconnectCampaignSocialAccount(
          `${brandManagerIdentity.id}`,
          campaignId,
          `${brandManagerIdentity.display_name}`,
          brandManagerIdentity.channel,
        ),
      )
    } else {
      dispatch(
        connectCampaignSocialAccount(
          `${brandManagerIdentity.id}`,
          campaignId,
          `${brandManagerIdentity.display_name}`,
          brandManagerIdentity.channel,
        ),
      )
    }
  }

  if (provider === BrandManagerIdentityProviders.Instagram) {
    return (
      <ConnectedAccountsDrawerIdentityInstagram
        brandManagerIdentity={brandManagerIdentity}
        isActive={isActive}
        onClick={onClick}
      />
    )
  }

  return (
    <ConnectedAccountsDrawerIdentityTikTok
      brandManagerIdentity={brandManagerIdentity}
      isActive={isActive}
      onClick={onClick}
    />
  )
}

export { ConnectedAccountsDrawerIdentity }
