import { ComponentType, PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'

export const SubmissionFullStoryWrapper = styled.div`
  position: relative;
`

interface INavigationArrowsWrapper {
  showArrows?: boolean
}

export const NavigationArrowsWrapper: ComponentType<PropsWithChildren<
  INavigationArrowsWrapper
>> = styled.div`
  position: absolute;
  width: calc(100% + 8.75rem);
  margin-left: -4.375rem;
  top: 0;
  height: 100%;
  transition: 0.3s opacity;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  ${({ showArrows }) =>
    showArrows &&
    css`
      opacity: 1;
    `};
`
