import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

import { FadeIn, FadeOut } from '@lib/effects'
import Theme from '@theme'

export const PreviewInformationPanel = styled.div`
  cursor: default;
  z-index: 1;
  background: ${Theme.whiteColor};
  width: 17.5rem;
  height: 5.875rem;
  box-sizing: border-box;
  position: fixed;
  padding: 1.25rem;
  box-shadow: 0 0.125rem 0.625rem 0 rgb(0 0 0 / 8%);
  border-radius: 0.25rem;
  ${({ showPreviewTooltip }) => css`
    animation: ${showPreviewTooltip ? FadeIn : FadeOut} 0.4s ease-in forwards;
  `}
  top: 3.5rem;

  &::after {
    animation: ${FadeIn} 0.4s ease-in forwards;
    position: absolute;
    content: ' ';
    left: calc(50% - 0.625rem);
    border-left: 0.625rem solid transparent;
    border-right: 0.625rem solid transparent;
    border-bottom: 0.625rem solid #fff;
    top: -0.5625rem;
    filter: drop-shadow(0 2px 10px #ddd);
  }
`

export const PreviewIconWrapper = styled(Flex)`
  position: relative;
  margin: auto;
  margin-right: 1.25rem;
`
