import { createAction } from '@reduxjs/toolkit'

import { IMeta } from '@store'
import { ApproveActionTypes, ISubmission } from '../types'

export const approve = createAction(
  ApproveActionTypes.APPROVE_REQUEST,
  (submissionId: number, campaignId: number, params: object, meta: IMeta) => ({
    payload: { submissionId, campaignId, params, meta },
  }),
)

export const approveSuccess = createAction(
  ApproveActionTypes.APPROVE_SUCCESS,
  (submission: ISubmission, campaignId: number, meta?: IMeta) => ({
    payload: { submission, campaignId, meta },
  }),
)

export const approveError = createAction<any>(ApproveActionTypes.APPROVE_ERROR)
