import { createSelector } from 'reselect'

import { IApplicationState } from '@store'
import { selectLink } from '@utils/selectLink'

const moodboardUploadState = (state: IApplicationState) => state.moodboard

export const selectMoodboardMediaListByCampaignId = createSelector(
  [moodboardUploadState, (_, campaignId: number) => campaignId],
  (state, campaignId) => state.mediaList[campaignId] ?? [],
)

export const selectIsAnyUploading = createSelector(
  [moodboardUploadState, (_, campaignId: number) => campaignId],
  (state, campaignId) =>
    Boolean(
      state.mediaList[campaignId]?.find((media) => {
        return media?.isUploading
      }),
    ),
)

export const selectDeleteLink = createSelector(
  selectMoodboardMediaListByCampaignId,
  (_, campaignId: number, position: number) => position,
  (mediaList, position) => {
    const preSignedUrlItem = mediaList[position]?.preSignedUrlItem
    return preSignedUrlItem?.links && selectLink(preSignedUrlItem?.links, 'delete')
  },
)

export const selectMediaId = createSelector(
  selectMoodboardMediaListByCampaignId,
  (_, campaignId: number, position: number) => position,
  (mediaList, position) => {
    return mediaList[position].preSignedUrlItem?.id
  },
)
