import React from 'react'

import Flex from 'styled-flex-component'

import InboxFilters from '@components/Inbox/Filters'
import InboxMemberList from '@components/Inbox/Member/List'
import ReportsList from '@components/Reports/List/List'
import PostCampaignReport from '@components/Reports/PostCampaignReport/PostCampaignReport'
import ReportsTitleBar from '@components/Reports/TitleBar/TitleBar'
import MainLayout from '@components/UI/MainLayout'
import PaddedContainerStyled from '@components/UI/PaddedContainer/PaddedContainer.styled'
import SideBar from '@components/UI/SideBar'
import AnalyticsTrackingProvider from '@context/AnalyticsTracking'
import { CampaignProvider } from '@context/Campaign'
import { DrawerProvider } from '@context/Drawer'
import { ModalProvider } from '@context/Modal'
import SubmissionListFilterProvider from '@context/SubmissionListFilter/Provider'
import Theme from '@theme'

const PostCampaignReportPage: React.FC = () => (
  <MainLayout backgroundColor={Theme.whiteColor}>
    <CampaignProvider>
      <SubmissionListFilterProvider>
        <AnalyticsTrackingProvider>
          <DrawerProvider>
            <ModalProvider>
              <ReportsTitleBar />
              <Flex>
                <SideBar>
                  <InboxFilters />
                  <ReportsList />
                  <InboxMemberList />
                </SideBar>
                <Flex column full>
                  <PaddedContainerStyled>
                    <PostCampaignReport />
                  </PaddedContainerStyled>
                </Flex>
              </Flex>
            </ModalProvider>
          </DrawerProvider>
        </AnalyticsTrackingProvider>
      </SubmissionListFilterProvider>
    </CampaignProvider>
  </MainLayout>
)

export default PostCampaignReportPage
