import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import SideBar, { SideBarBlock, SideBarBlockItem } from '@components/UI/SideBar'
import { BillingTypes, PageRoutes } from '@enums'
import { IApplicationState } from '@store'
import {
  selectCampaignsBillingHistoryLink,
  selectContentLibraryBillingHistoryLink,
} from '@store/me'
import { selectBillingType } from '@store/router'

interface IInternalProps {
  hasCampaignsLink: boolean
  hasContentLibraryLink: boolean
  billingType: BillingTypes
}

const BillingHistorySideBar: React.FC<IInternalProps> = ({
  hasCampaignsLink,
  hasContentLibraryLink,
  billingType,
}) => {
  const items: ReadonlyArray<React.ReactNode> = [
    hasCampaignsLink && (
      <SideBarBlockItem
        active={billingType === BillingTypes.Campaigns}
        text={<FormattedMessage id="core.text.campaigns" />}
        to={`/${PageRoutes.BillingCampaigns}`}
      />
    ),
    hasContentLibraryLink && (
      <SideBarBlockItem
        active={billingType === BillingTypes.ContentLibrary}
        text={<FormattedMessage id="core.text.contentLibrary" />}
        to={`/${PageRoutes.BillingContentLibrary}`}
      />
    ),
  ].filter(Boolean)
  return (
    <SideBar key={billingType}>
      <SideBarBlock header={<FormattedMessage id="core.text.billing" />} items={items} />
    </SideBar>
  )
}

const mapStateToProps = (state: IApplicationState, { location }: RouteComponentProps) => ({
  billingType: selectBillingType(location),
  hasCampaignsLink: selectCampaignsBillingHistoryLink(state),
  hasContentLibraryLink: selectContentLibraryBillingHistoryLink(state),
})

export { BillingHistorySideBar }
export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps),
)(BillingHistorySideBar)
