/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import BrandFansInfluencerIdentityGroups from '@components/BrandFans/InfluencerIdentity/Groups'
import InfluencerLabels from '@components/Inbox/InfluencerIdentity/Labels'
import { IModalContextProps } from '@context/Modal'
import { withModal } from '@hocs'
import { IApplicationState } from '@store'
import { addBrandFanGroupMember } from '@store/brandFans'
import { selectCampaign, selectCampaignBrandName, selectIsSocialCampaign } from '@store/campaigns'
import { selectLocationsearch } from '@store/router'
import {
  selectIdentityBrandFanGroupsCount,
  selectIsInfluencerEligibleForBrandFans,
  selectIsNewInfluencer,
  selectIsPreviouslyPurchased,
  selectSubmission,
  shouldShowSubmissionPrice,
  viewSocial,
} from '@store/submissions'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { buildSubmissionUrl } from '@utils/urls'
import SubmissionCardHeaderAvatar from './Avatar/Avatar'
import SubmissionCardHeaderHandle from './Handle/Handle'
import SubmissionCardHeaderPrice from './Price/Price'
import SubHeaderIconWrapper from './SubHeader/SocialIconWrapper'
import SubmissionCardSubHeader from './SubHeader/SubHeader'
import SubmissionCardHeaderActions from './Actions'
import { SubmissionCardHeaderRoot, SubmissionCardMainHeader } from './Header.styled'

interface ISubmissionCardHeaderProps {
  submissionId: number
  campaignId: number
  enableCostBreakdownTracking?: boolean
  toggleCostBreakdown?: (event: React.MouseEvent) => void
}

interface IInternalProps
  extends ISubmissionCardHeaderProps,
    RouteComponentProps,
    IModalContextProps,
    ConnectedProps<typeof connector> {}

const SubmissionCardHeaderContext = React.createContext({
  isShowAddToBrandFan: false,
  setIsShowAddToBrandFan: (value: boolean) => {},
})

const SubmissionCardHeaderBrandFansPrompt = ({ submissionId }: { submissionId: number }) => {
  const { brandFanGroupsCount, isSocial, isEligibleForBrandFans } = useSelector(
    (state: IApplicationState) => {
      const submission = selectSubmission(state, submissionId)
      return {
        brandFanGroupsCount: selectIdentityBrandFanGroupsCount(state, submissionId),
        isSocial: selectIsSocialCampaign(state, submission.brief_id),
        isEligibleForBrandFans: selectIsInfluencerEligibleForBrandFans(state, submissionId),
      }
    },
  )

  if (!isEligibleForBrandFans) {
    return (
      <Text color={Theme.grey900Color} customletterSpacing="0.26px">
        <FormattedMessage id="brandFans.identity.ineligible" />
      </Text>
    )
  }

  return brandFanGroupsCount > 0 && isSocial ? (
    <Text color={Theme.primaryColor} customletterSpacing="0.26px">
      <FormattedMessage id="core.text.addToXGroups" values={{ count: brandFanGroupsCount }} />
    </Text>
  ) : (
    <Text color={Theme.grey900Color} customletterSpacing="0.26px">
      <FormattedMessage id="core.text.addToBrandFans" />
    </Text>
  )
}

export const SubmissionCardHeader: React.FC<IInternalProps> = ({
  campaignId,
  submissionId,
  brandName,
  isNewInfluencer,
  isPreviouslyPurchased,
  locationSearch,
  campaign,
  submission,
  showPrice,
  toggleCostBreakdown,
  enableCostBreakdownTracking,
  viewSocial: viewSocialAction,
  showModal,
}) => {
  const [isShowAddToBrandFan, setIsShowAddToBrandFan] = React.useState(false)
  const onViewSocial = () => viewSocialAction(campaignId, submissionId)

  const onShowBrandFans = (event: React.SyntheticEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    showModal(BrandFansInfluencerIdentityGroups, {
      identityId: submission.identity_id,
      influencerId: submission.influencer_id,
      submissionId,
      campaignId,
    })
  }

  return (
    <SubmissionCardHeaderContext.Provider value={{ isShowAddToBrandFan, setIsShowAddToBrandFan }}>
      <SubmissionCardHeaderRoot>
        <SubmissionCardMainHeader
          full
          justifyBetween
          userHandleOpacity={isShowAddToBrandFan ? 0.45 : 1}
        >
          <Flex justifyStart className="header-inner-wrapper">
            <SubmissionCardHeaderAvatar
              campaignId={campaignId}
              submissionId={submissionId}
              onClick={onShowBrandFans}
            />
            <div className="creator-details">
              <Flex justifyStart alignCenter className="user-handle-wrapper">
                <Container className="user-handle">
                  <SubmissionCardHeaderHandle
                    campaignId={campaignId}
                    submissionId={submissionId}
                    onViewSocial={onViewSocial}
                  />
                </Container>
                {!isShowAddToBrandFan && (
                  <InfluencerLabels
                    spacing={0.375}
                    brandName={brandName as string}
                    showAnalytics={false}
                    showNew={Boolean(isNewInfluencer)}
                    showPreviouslyPurchased={isPreviouslyPurchased}
                    newLink={{
                      pathname: buildSubmissionUrl(campaign, submission),
                      search: locationSearch,
                    }}
                  />
                )}
              </Flex>
              <Flex className="social-details" alignCenter>
                <Container relative fullWidth>
                  <SubHeaderIconWrapper
                    campaignId={campaignId}
                    submissionId={submissionId}
                    onViewSocial={onViewSocial}
                  >
                    <SubmissionCardSubHeader campaignId={campaignId} submissionId={submissionId} />
                  </SubHeaderIconWrapper>
                  {isShowAddToBrandFan && (
                    <Container
                      absolute
                      topPosition={0}
                      leftPosition={0}
                      backgroundColor={Theme.whiteColor}
                      minWidth={12}
                    >
                      <SubmissionCardHeaderBrandFansPrompt submissionId={submissionId} />
                    </Container>
                  )}
                </Container>
              </Flex>
            </div>
          </Flex>
          {!isShowAddToBrandFan && showPrice && (
            <SubmissionCardHeaderPrice
              enableCostBreakdownTracking={enableCostBreakdownTracking}
              toggleCostBreakdown={toggleCostBreakdown}
              submissionId={submissionId}
              campaignId={campaignId}
            />
          )}
        </SubmissionCardMainHeader>
        <Container height={1.75}>
          <SubmissionCardHeaderActions submissionId={submissionId} campaignId={campaignId} />
        </Container>
      </SubmissionCardHeaderRoot>
    </SubmissionCardHeaderContext.Provider>
  )
}

const mapDispatchToProps = {
  viewSocial,
  addBrandFanGroupMember,
}

const mapStateToProps = (state: IApplicationState, { submissionId, campaignId }) => {
  return {
    campaign: selectCampaign(state, campaignId),
    locationSearch: selectLocationsearch(state),
    submission: selectSubmission(state, submissionId),
    brandName: selectCampaignBrandName(state, campaignId),
    showPrice: shouldShowSubmissionPrice(state, submissionId),
    isNewInfluencer: selectIsNewInfluencer(state, submissionId),
    isPreviouslyPurchased: selectIsPreviouslyPurchased(state, submissionId),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export { SubmissionCardHeaderContext }
export default compose<IInternalProps, ISubmissionCardHeaderProps>(
  connector,
  withModal,
)(SubmissionCardHeader)
