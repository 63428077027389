import { Fragment } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import PricePanelAmount from '@components/UI/PricePanel/Amount'
import { IApplicationState } from '@store'
import {
  selectLicenseOptionAmountBeforeTaxCurrency,
  selectLicenseOptionTaxDisplayName,
  selectMinimumLicenseAmount,
} from '@store/licenses'
import {
  selectLicenseAmountBeforeTaxCents,
  selectLicenseAmountBeforeTaxCurrency,
  selectLicenseTaxDisplayName,
  selectShouldShowLicenseOptions,
} from '@store/mediaObjects'
import { selectMediaObjectId } from '@store/router'

interface IInternalProps {
  fromPrice?: number
  licensePrice?: number
  licensePriceCurrency?: string
  fromPriceCurrency?: string
  licenseTaxDisplayName?: string
  fromPriceTaxDisplayName?: string
  useFromPrice: boolean
}

export const MediaObjectFullPricePanelAmount = ({
  fromPrice,
  licensePrice,
  licensePriceCurrency,
  fromPriceCurrency,
  licenseTaxDisplayName,
  fromPriceTaxDisplayName,
  useFromPrice,
}: IInternalProps) => (
  <Fragment>
    {useFromPrice
      ? fromPrice && (
          <PricePanelAmount
            showFrom
            price={fromPrice}
            currency={fromPriceCurrency!}
            taxDisplayName={fromPriceTaxDisplayName!}
          />
        )
      : licensePrice && (
          <PricePanelAmount
            price={licensePrice}
            currency={licensePriceCurrency!}
            taxDisplayName={licenseTaxDisplayName!}
          />
        )}
  </Fragment>
)

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const mediaObjectId = selectMediaObjectId(match)
  return {
    fromPrice: selectMinimumLicenseAmount(state, mediaObjectId!),
    licensePrice: selectLicenseAmountBeforeTaxCents(state, mediaObjectId!),
    licensePriceCurrency: selectLicenseAmountBeforeTaxCurrency(state, mediaObjectId!),
    fromPriceCurrency: selectLicenseOptionAmountBeforeTaxCurrency(state, mediaObjectId!, 0),
    fromPriceTaxDisplayName: selectLicenseOptionTaxDisplayName(state, mediaObjectId!, 0),
    licenseTaxDisplayName: selectLicenseTaxDisplayName(state, mediaObjectId!),
    useFromPrice: selectShouldShowLicenseOptions(state, mediaObjectId!),
  }
}

export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps),
)(MediaObjectFullPricePanelAmount)
