import { call, put, select, take } from 'redux-saga/effects'

import { IMeta } from '@store'
import { authError } from '@store/auth'
import { CampaignsActionTypes, fetchCampaigns, selectAllCampaignCount } from '@store/campaigns'

export function* handleAfterSignin(action: { meta: IMeta }) {
  try {
    const { history, redirect } = action.meta
    yield put(fetchCampaigns())
    yield take(CampaignsActionTypes.FETCH_SUCCESS)
    const campaignCount = yield select(selectAllCampaignCount)
    yield call(history!.push, getRedirect({ campaignCount, redirect }))
  } catch (error) {
    yield put(authError(error))
  }
}

export const getRedirect = ({ campaignCount, redirect }) => {
  if (campaignCount === 0) {
    return '/campaigns'
  } else if (redirect) {
    return redirect
  }
  return '/campaigns'
}
