import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col } from 'react-styled-flexboxgrid'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import TitleBarStyled from '@components/UI/TitleBar/TitleBar.styled'
import { IAnalyticsTrackingContextProps } from '@context/AnalyticsTracking'
import { ICampaignContextProps } from '@context/Campaign'
import { ISubmissionContextProps } from '@context/Submission'
import {
  withAnalyticsTracking,
  withCampaignNoRenderBlock,
  withSubmissionNoRenderBlock,
} from '@hocs'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { selectFirebaseURL } from '@store/campaigns'
import { selectReferralProgramLink } from '@store/me'
import { SubmissionTitleBarDesktop } from './Desktop/Desktop'
import { SubmissionTitleBarMobile } from './Mobile'

interface IInternalProps
  extends ICampaignContextProps,
    IAnalyticsTrackingContextProps,
    ISubmissionContextProps,
    InjectedIntlProps {
  hasReferralProgramLink: boolean
  hasFirebaseUrl: boolean
}

export const SubmissionTitleBar = (props: IInternalProps) => {
  const { search } = hooks.useLocation()
  return (
    <TitleBarStyled>
      <Flex alignCenter full>
        <Col xs={false} sm>
          <SubmissionTitleBarDesktop {...props} search={search} />
        </Col>
        <Col xs sm={false}>
          <SubmissionTitleBarMobile {...props} search={search} />
        </Col>
      </Flex>
    </TitleBarStyled>
  )
}

const mapStateToProps = (state: IApplicationState, { campaign }: ICampaignContextProps) => ({
  hasReferralProgramLink: Boolean(selectReferralProgramLink(state)),
  hasFirebaseUrl: selectFirebaseURL(state, campaign.id),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(
  withSubmissionNoRenderBlock,
  withCampaignNoRenderBlock,
  withAnalyticsTracking,
  injectIntl,
  connector,
)(SubmissionTitleBar)
