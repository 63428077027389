import React from 'react'

import { Link, useLocation } from 'react-router-dom'

import Image from '@components/UI/Image'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { AspectRatios, EventTrackingNames, MediaTypes, SocialSubmissionTypes } from '@enums'
import { ISubmissionFrame } from '@store/submissions'
import Theme from '@theme'
import { Container, FadeIn } from '@tribegroup'
import { buildSubmissionUrlById } from '@utils/urls'
import OverlayIcon from './OverlayIcon'

interface IProps {
  submissionId: number
  campaignId: number
  currentView: string
  mediaObject: ISubmissionFrame
  socialSubmissionType?: SocialSubmissionTypes
  disableLinkClick: boolean
}

const InfluencerIdentityRowSubmissionThumbnail: React.FC<IProps> = ({
  submissionId,
  campaignId,
  mediaObject,
  currentView,
  socialSubmissionType,
  disableLinkClick,
}) => {
  const { pathname, search } = useLocation()
  const mediaType = mediaObject.media_type as MediaTypes
  const onLinkClick = (event: React.SyntheticEvent) => {
    if (disableLinkClick) {
      event.preventDefault()
    }
  }
  return (
    <Container key={submissionId} relative data-testid="influencer-identity-submission">
      <ConnectedTrackedRoute
        eventName={EventTrackingNames.ViewSubmission}
        submissionId={submissionId}
        campaignId={campaignId}
        additionalProperties={{
          brief_id: campaignId,
          source: currentView,
          current_view: currentView,
          current_filter: currentView,
        }}
      >
        <Link
          onClick={onLinkClick}
          to={{
            pathname: `${buildSubmissionUrlById(campaignId, submissionId)}`,
            search: `from=${encodeURIComponent(`${pathname}${search}`)}`,
          }}
        >
          <Image
            src={mediaObject.thumbnail_media_url}
            aspectRatio={AspectRatios.ONE_BY_ONE}
            animation={FadeIn}
            loadingColor={Theme.whiteColor}
          />
          <OverlayIcon
            mediaType={mediaType}
            socialSubmissionType={socialSubmissionType as SocialSubmissionTypes}
          />
        </Link>
      </ConnectedTrackedRoute>
    </Container>
  )
}

export { InfluencerIdentityRowSubmissionThumbnail }
export default InfluencerIdentityRowSubmissionThumbnail
