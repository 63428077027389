import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

import { themed, useBreakpoint } from '../Theme'

export const ToastWrapper = themed(styled(Flex)`
  ${({ theme }) => css`
    background: ${theme.whiteColor};
    border-radius: 1.5rem;
    box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 10%);
    padding: 1rem 0.75rem 1rem 0;
    color: ${theme.grey900Color};
  `}
`)

export const TextWrapper = styled.div`
  padding: 0 0.75rem 0 1.5rem;
`

export const ToastContainerStyled = themed(styled.div`
  position: fixed;
  z-index: 99999;
  bottom: 2rem;
  left: 50%;
  ${({ theme, sidebarModifier }) =>
    sidebarModifier &&
    css`
      ${useBreakpoint(theme, 'tabletPortrait')`
        left: calc(50% + ${sidebarModifier / 2}rem);
      `};
    `};
  width: 100%;
`)
