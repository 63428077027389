import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import { Icon } from '@tribegroup'

const Portfolio = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.25rem;
  margin-top: 1.5rem;
`

const PortfolioObjectIcon = styled(Icon)`
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  ${breakpoint('desktop')`
    left: 1rem;
    bottom: 1rem;
  `}
`

export { Portfolio, PortfolioObjectIcon }
