import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import TitleBar, { TitleBarMobile } from '@components/UI/TitleBar'
import { ContentLibraryFilterTypes } from '@enums'
import { IApplicationState } from '@store'
import { selectMediaObjectsStats, selectShowContentLibraryButtons } from '@store/mediaObjects'
import { selectContentLibraryFilter } from '@store/router'
import ContentLibraryTitleBarButtons from './Buttons'

interface IInternalProps {
  contentLibraryStats: {}
  contentLibraryCurrentFilter: ContentLibraryFilterTypes
  showContentLibraryButtons: boolean
}

export const ContentLibraryTitleBar = ({
  contentLibraryStats,
  contentLibraryCurrentFilter,
  showContentLibraryButtons,
}: IInternalProps) => {
  const count = contentLibraryStats[contentLibraryCurrentFilter]
  return (
    <Fragment>
      <TitleBar
        desktopOnly
        actions={showContentLibraryButtons ? <ContentLibraryTitleBarButtons /> : null}
      />
      <TitleBarMobile
        title={
          <FormattedMessage id={`contentLibrary.sidebar.filter.${contentLibraryCurrentFilter}`} />
        }
        count={count}
      />
    </Fragment>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  contentLibraryCurrentFilter: selectContentLibraryFilter(state),
  contentLibraryStats: selectMediaObjectsStats(state),
  showContentLibraryButtons: selectShowContentLibraryButtons(state),
})

export default compose<IInternalProps, {}>(connect(mapStateToProps))(ContentLibraryTitleBar)
