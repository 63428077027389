import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { usePreapprovalAmountPercentage } from '@hooks/usePreapprovalAmountPercentage'
import { IApplicationState } from '@store'
import { selectCampaignId } from '@store/router'
import { selectCostBreakdownMessage } from '@store/submissions'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

interface IProps {
  submissionId: number
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

const SubmissionCostBreakdownMessage: React.FC<IInternalProps> = ({ costBreakdownMessage }) => {
  const preapprovalAmountPercentage = usePreapprovalAmountPercentage()
  return (
    <Container topOuterSpacing={1} data-testid="cost-breakdown-message-container">
      <Text italic color={Theme.grey800Color} block light>
        <FormattedMessage
          id={`costBreakdown.message.${costBreakdownMessage}`}
          values={{ preapprovalAmountPercentage }}
        />
      </Text>
    </Container>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { submissionId, match }: IProps & RouteComponentProps,
) => {
  const campaignId = selectCampaignId(match) as number
  return {
    costBreakdownMessage: selectCostBreakdownMessage(state, submissionId, campaignId),
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(
  withRouter,
  connector,
)(SubmissionCostBreakdownMessage)
