import { WatcherFunction } from '..'
import { watchAddBrandFanGroupMemberRequest } from './addBrandFanGroupMember'
import { watchCreateBrandFanGroupRequest } from './createBrandFanGroup'
import { watchBrandFanGroupMemberRequest } from './fetchBrandFanGroupMembers'
import { watchBrandFanGroupsRequest } from './fetchBrandFanGroups'
import { watchInfluencerIdentitiesRequest } from './fetchInfluencerIdentities'
import { watchFetchInfluencerIdentityRequest } from './fetchInfluencerIdentity'
import { watchFetchSubmissionsRequest } from './fetchSubmissions'
import { watchRefreshBrandFanGroupMemberRequest } from './refreshBrandFanGroupMembers'
import { watchRefreshInfluencerIdentitiesSelfRequest } from './refreshInfluencerIdentitiesSelf'
import { watchRemoveBrandFanGroupMemberRequest } from './removeBrandFanGroupMember'
import { watchUpdateBrandFanGroupRequest } from './updateBrandFanGroup'

export const brandFansSagas: ReadonlyArray<WatcherFunction> = [
  watchBrandFanGroupsRequest,
  watchInfluencerIdentitiesRequest,
  watchFetchSubmissionsRequest,
  watchUpdateBrandFanGroupRequest,
  watchBrandFanGroupMemberRequest,
  watchCreateBrandFanGroupRequest,
  watchAddBrandFanGroupMemberRequest,
  watchRemoveBrandFanGroupMemberRequest,
  watchFetchInfluencerIdentityRequest,
  watchRefreshBrandFanGroupMemberRequest,
  watchRefreshInfluencerIdentitiesSelfRequest,
]
