import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import ActionIcon from '@components/UI/ActionIcon'
import ConditionalLink from '@components/UI/ConditionalLink/ConditionalLink'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames, InboxViewTypes } from '@enums'
import { withSubmissionListFilter } from '@hocs'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { selectDisplayInfluencerView } from '@store/campaigns'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Container } from '@tribegroup'
import { getMessage } from '@utils/message'

interface IInternalProps extends InjectedIntlProps {
  displayInfluencerView?: boolean
  campaignId: number
  filter: string
}

export const InboxViewOptions = ({
  intl,
  campaignId,
  displayInfluencerView,
  filter,
}: IInternalProps) => {
  const isInfluencerView = hooks.useIsInfluencerView()
  return (
    <Flex>
      <Container>
        <ConnectedTrackedRoute
          disableTracking={!isInfluencerView}
          eventName={EventTrackingNames.SwitchToTileView}
          campaignId={campaignId}
          additionalProperties={{ current_filter: filter }}
        >
          <ConditionalLink showLink={displayInfluencerView} to={{ search: '' }}>
            <ActionIcon
              glyph="view-by-tile"
              size={1.5}
              highlighted={!isInfluencerView}
              noHover={!displayInfluencerView}
              tooltip={getMessage(intl, 'inbox.viewOptions.tile')}
              color={Theme.defaultColor}
            />
          </ConditionalLink>
        </ConnectedTrackedRoute>
      </Container>
      {displayInfluencerView && (
        <Container leftOuterSpacing={0.5}>
          <ConnectedTrackedRoute
            disableTracking={isInfluencerView}
            eventName={EventTrackingNames.SwitchToListView}
            campaignId={campaignId}
            additionalProperties={{ current_filter: filter }}
          >
            <Link
              to={{
                search: `viewType=${InboxViewTypes.Influencer}`,
              }}
            >
              <ActionIcon
                glyph="view-by-creator"
                size={1.5}
                highlighted={isInfluencerView}
                tooltip={getMessage(intl, 'inbox.viewOptions.influencer')}
                color={Theme.defaultColor}
              />
            </Link>
          </ConnectedTrackedRoute>
        </Container>
      )}
    </Flex>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  return {
    campaignId,
    displayInfluencerView: selectDisplayInfluencerView(state, campaignId),
  }
}

export default compose<IInternalProps, {}>(
  withSubmissionListFilter,
  injectIntl,
  withRouter,
  connect(mapStateToProps),
)(InboxViewOptions)
