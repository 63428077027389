import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import {
  selectLicenseAmountBeforeTaxCents,
  selectLicenseAmountBeforeTaxCurrency,
  selectLicenseTaxDisplayName,
} from '@store/mediaObjects'
import LicensingPriceDetails, { IPriceDetailsProps } from './PriceDetails'

interface ITransactedLicensePriceDetailsProps {
  mediaObjectId: number
}

interface IInternalProps extends ITransactedLicensePriceDetailsProps, IPriceDetailsProps {}

export const TransactedLicensePriceDetails: React.FC<IInternalProps> = (props) => (
  <LicensingPriceDetails {...props} />
)

const mapStateToProps = (state: IApplicationState, { mediaObjectId }) => ({
  amountBeforeTaxCents: selectLicenseAmountBeforeTaxCents(state, mediaObjectId),
  amountBeforeTaxCurrency: selectLicenseAmountBeforeTaxCurrency(state, mediaObjectId),
  taxDisplayName: selectLicenseTaxDisplayName(state, mediaObjectId),
})

export default compose<IInternalProps, ITransactedLicensePriceDetailsProps>(
  connect(mapStateToProps),
)(TransactedLicensePriceDetails)
