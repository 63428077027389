import { createContext, useContext } from 'react'

import { ISnackbar } from './types'

interface ISnackbarContext {
  snackBars: ISnackbar[]

  createSnackbar: (messageId: string, severity: ISnackbar['severity']) => number
  removeSnackbar: (id: number) => void
}

export const SnackBarContext = createContext<ISnackbarContext>({
  snackBars: [],
  createSnackbar: () => -1,
  removeSnackbar: () => null,
})

export const useSnackBar = () => useContext(SnackBarContext)
