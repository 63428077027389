import { useState } from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Flex, { FlexItem } from 'styled-flex-component'

import MediaObjectLicensing from '@components/MediaObject/Licensing/Licensing'
import { MediaObjectRequestLicense } from '@components/MediaObject/Licensing/RequestLicense/RequestLicense'
import Loading from '@components/UI/Loading'
import Price from '@components/UI/Price/Price'
import { RELICENSE_STATUSES } from '@constants'
import { FeatureToggles, LicenseStatuses, PaymentMethods } from '@enums'
import { useModal } from '@hooks/useModal'
import { IApplicationState } from '@store'
import { selectIsRequestingLicense, selectMediaObjectLicenseOption } from '@store/licenses'
import { selectHasCreditCardInfo } from '@store/me'
import {
  clearMediaObjectErrors,
  requestLicense,
  selectHasTransactedLicense,
  selectMediaObjectErrors,
  selectTransactedLicenseStatus,
} from '@store/mediaObjects'
import { IPurchaseOrder } from '@store/purchaseOrders'
import Theme from '@theme'
import { Button, CloseIcon, Container, H1, Modal, Text, Tip } from '@tribegroup'
import { LicensingCreditManagementPaymentMethodItem } from './PaymentMethod/Item'
import { CreditManagementPaymentMethodWrapper } from './PaymentMethod/Wrapper'
import { CreditManagementCoachMark } from './CoachMark'

interface IProps {
  mediaObjectId: number
  licenseIndex: number
}

export const CreditManagementWrapper = ({ mediaObjectId, licenseIndex }: IProps) => {
  const isNewLicenseTypesEnabled = useFlag(FeatureToggles.NEW_LICENSE_TYPES_ENABLED)
  const isCreditCardRemovalEnabled = useFlag(FeatureToggles.CREDIT_CARD_REMOVAL_ENABLED)
  const LicenseComponent = isNewLicenseTypesEnabled
    ? MediaObjectRequestLicense
    : MediaObjectLicensing

  const { isOpen, hideModal, showModal } = useModal()
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(
    isCreditCardRemovalEnabled ? PaymentMethods.PURCHASE_ORDER : PaymentMethods.CREDIT_CARD,
  )
  const [purchaseOrders, setPurchaseOrders] = useState<IPurchaseOrder[]>([])

  const {
    isRequestingLicense,
    hasTransactedLicense,
    transactedLicenseStatus,
    licenseOption,
    hasCreditCard,
    hasErrors,
  } = useSelector((state: IApplicationState) => {
    return {
      isRequestingLicense: selectIsRequestingLicense(state),
      hasTransactedLicense: selectHasTransactedLicense(state, mediaObjectId),
      transactedLicenseStatus: selectTransactedLicenseStatus(
        state,
        mediaObjectId,
      ) as LicenseStatuses,
      licenseOption: selectMediaObjectLicenseOption(state, mediaObjectId, licenseIndex),
      hasCreditCard: selectHasCreditCardInfo(state),
      hasErrors: selectMediaObjectErrors(state),
    }
  })

  const dispatch = useDispatch()

  const onBack = () => {
    showModal(LicenseComponent, { mediaObjectId })
  }

  const onPaymentMethodClick = (paymentMethod: PaymentMethods) => {
    setPaymentMethod(paymentMethod)
    dispatch(clearMediaObjectErrors())
  }

  const onSubmit = () => {
    const purchaseOrderIds = purchaseOrders.map((purchaseOrder) => purchaseOrder.id)
    const isPaymentMethodPurchaseOrder = paymentMethod === PaymentMethods.PURCHASE_ORDER
    const metadata = isPaymentMethodPurchaseOrder ? { refreshPurchaseOrders: true } : undefined

    dispatch(
      requestLicense(mediaObjectId, licenseIndex, undefined, undefined, metadata, {
        purchase_method: paymentMethod,
        purchase_order_ids:
          paymentMethod === PaymentMethods.PURCHASE_ORDER ? purchaseOrderIds : undefined,
      }),
    )
  }

  const totalAmount = purchaseOrders.reduce((acc, curr) => acc + curr.amount_remaining_cents, 0)
  const licenseAmountCovered = totalAmount >= licenseOption.total_amount_before_tax_cents

  const disableSubmit =
    isRequestingLicense ||
    (paymentMethod === PaymentMethods.CREDIT_CARD && !hasCreditCard) ||
    (paymentMethod === PaymentMethods.PURCHASE_ORDER && !licenseAmountCovered)

  const showReload = paymentMethod === PaymentMethods.PURCHASE_ORDER && hasErrors

  const onReload = () => {
    dispatch(clearMediaObjectErrors())
  }

  const onSelectPurchaseOrders = (purchaseOrders: IPurchaseOrder[]) =>
    setPurchaseOrders(purchaseOrders)

  if (hasTransactedLicense && !RELICENSE_STATUSES.includes(transactedLicenseStatus)) {
    showModal(LicenseComponent, { mediaObjectId })
  }

  return (
    <Modal
      reactModalProps={{ isOpen: Boolean(isOpen) }}
      width={32.5}
      height={43.3125}
      padding={3.125}
    >
      <CloseIcon onClick={!isRequestingLicense ? hideModal : undefined} />
      <Container height={37.0625} fullWidth>
        <Flex column full>
          <Container maxWidth={isCreditCardRemovalEnabled ? 25 : 24}>
            <H1 color={Theme.grey900Color}>
              <FormattedMessage
                id={
                  isCreditCardRemovalEnabled
                    ? 'contentLibrary.licensing.creditManagement.purchaseOrder.header'
                    : 'contentLibrary.licensing.creditManagement.header'
                }
              />
              <Container inlineBlock leftInnerSpacing={0.5}>
                <Tip
                  inline
                  center
                  icon={{
                    size: 1,
                    glyph: 'information',
                    padded: false,
                  }}
                  coachmark={{
                    content: <CreditManagementCoachMark />,
                    position: 'bottom',
                    width: 17.5,
                  }}
                />
              </Container>
            </H1>
          </Container>

          {!isCreditCardRemovalEnabled && (
            <Container flexStart fullWidth>
              {[PaymentMethods.CREDIT_CARD, PaymentMethods.PURCHASE_ORDER].map((item) => (
                <LicensingCreditManagementPaymentMethodItem
                  key={`${item}-credit-management-payment-method`}
                  paymentMethod={item}
                  onItemClick={onPaymentMethodClick}
                  isRequestingLicense={isRequestingLicense}
                  active={paymentMethod === item}
                />
              ))}
            </Container>
          )}

          <FlexItem grow={1}>
            {isRequestingLicense ? (
              <Loading fullHeight yPadding={0} />
            ) : (
              <CreditManagementPaymentMethodWrapper
                paymentMethod={paymentMethod}
                licenseOption={licenseOption}
                onSelectPurchaseOrders={onSelectPurchaseOrders}
              />
            )}
          </FlexItem>

          <Container
            flexBetween
            fullWidth
            borderColor={Theme.grey400Color}
            topOuterSpacing={0.5}
            borderWidthTop={0.0625}
            topInnerSpacing={0.5}
          >
            <Text color={Theme.grey700Color} textAlign="left" block>
              {licenseOption.label}
            </Text>
            <Container flexEnd>
              <Container rightOuterSpacing={0.25}>
                <Text color={Theme.grey900Color}>
                  <Price
                    quantity={licenseOption.total_amount_before_tax_cents}
                    currency={licenseOption.total_amount_before_tax_currency}
                  />
                </Text>
              </Container>
              <Text color={Theme.grey900Color}>
                <FormattedMessage
                  id="contentLibrary.licensing.creditManagement.creditCard.licenseAmountTax"
                  values={{ taxName: licenseOption.tax_display_name }}
                />
              </Text>
            </Container>
          </Container>
          {showReload && (
            <Button
              fullWidth
              topOuterSpacing={2.5}
              height={2.5}
              outlined
              scheme="primary"
              onClick={onReload}
            >
              <FormattedMessage id="core.text.reload" />
            </Button>
          )}

          {!showReload && (
            <Container flexBetween fullWidth topOuterSpacing={2.5}>
              <FlexItem grow={1}>
                <Button
                  fullWidth
                  rightOuterSpacing={0.5}
                  height={2.5}
                  outlined
                  scheme="primary"
                  onClick={onBack}
                  disabled={isRequestingLicense}
                >
                  <FormattedMessage id="core.text.back" />
                </Button>
              </FlexItem>
              <FlexItem grow={1}>
                <Button
                  fullWidth
                  leftOuterSpacing={0.5}
                  height={2.5}
                  scheme="primary"
                  onClick={onSubmit}
                  disabled={disableSubmit}
                >
                  <FormattedMessage id="core.text.done" />
                </Button>
              </FlexItem>
            </Container>
          )}
        </Flex>
      </Container>
    </Modal>
  )
}
