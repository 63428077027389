import React, { useContext } from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { CampaignContext } from '@context/Campaign'
import { useSubmission } from '@context/Submission'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectLicenseOriginalMediaUrl } from '@store/mediaObjects'
import { Link } from '@tribegroup'
import { LicensingButton } from './Button'

interface IDownloadButton {
  mediaObjectId: number
  children: React.ReactNode
}

interface IInternalProps extends IDownloadButton {
  licenseOriginalMediaUrl: string
}

export const DownloadButton = ({
  licenseOriginalMediaUrl,
  children,
  mediaObjectId,
}: IInternalProps) => {
  const { campaign } = useContext(CampaignContext)
  const { submission } = useSubmission()
  return (
    <ConnectedTrackedRoute
      eventName={EventTrackingNames.DownloadLicensedContent}
      submissionId={submission?.id}
      campaignId={campaign?.id}
      mediaObjectId={mediaObjectId}
    >
      <Link href={licenseOriginalMediaUrl} target="_blank">
        <LicensingButton>{children}</LicensingButton>
      </Link>
    </ConnectedTrackedRoute>
  )
}

const mapStateToProps = (state: IApplicationState, { mediaObjectId }) => ({
  licenseOriginalMediaUrl: selectLicenseOriginalMediaUrl(state, mediaObjectId),
})

export default compose<IInternalProps, IDownloadButton>(connect(mapStateToProps))(DownloadButton)
