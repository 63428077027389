import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { BrandManagerIdentityProviders } from '@enums'
import { useBooleanToggle } from '@hooks/useBooleanToggle'
import { useModal } from '@hooks/useModal'
import { usePrevious } from '@hooks/usePrevious'
import {
  connectIdentities,
  fetchIdentities,
  selectIsConnecting,
} from '@store/brandManagerIdentities'
import { IApplicationState } from '@store/index'
import { selectConnectTikTokAdAccountLink } from '@store/me'
import Theme from '@theme'
import { CloseIcon, Container, H1, Modal } from '@tribegroup'
import { ConnectedAccountsModalChooseAccountSocialCard } from './SocialCard'

const ConnectedAccountsModalChooseAccount = () => {
  const { isOpen, hideModal } = useModal()
  const dispatch = useDispatch()
  const { isConnecting, connectTikTokAdAccountLink } = useSelector((state: IApplicationState) => {
    return {
      isConnecting: selectIsConnecting(state),
      connectTikTokAdAccountLink: selectConnectTikTokAdAccountLink(state),
    }
  })

  const wasConnecting = usePrevious(isConnecting)
  const [isPopupOpen, toggleIsPopupOpen] = useBooleanToggle(false)

  useEffect(() => {
    if (wasConnecting && !isConnecting) {
      hideModal()
    }
  }, [isConnecting, wasConnecting])

  const onConnectInstagramIdentities = () => {
    dispatch(connectIdentities())
  }

  const onConnectTiktokAdAccount = () => {
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=660,left=750,top=100`

    const link = connectTikTokAdAccountLink?.href

    const newWindow = window.open(link, '_blank', params)

    toggleIsPopupOpen()

    if (newWindow) {
      const timer = setInterval(function () {
        if (newWindow.closed) {
          clearInterval(timer)
          toggleIsPopupOpen()
          hideModal()
          dispatch(fetchIdentities())
        }
      }, 1000)
    }
  }

  return (
    <Modal
      reactModalProps={{ isOpen: Boolean(isOpen), shouldFocusAfterRender: false }}
      width={32.5}
    >
      <CloseIcon onClick={hideModal} />
      <Container topOuterSpacing={3.125} bottomOuterSpacing={2} textAlign="center">
        <H1 color={Theme.grey900Color}>Choose an Account</H1>
        <Container flexCenter fullWidth gap={0.5} topOuterSpacing={2}>
          <ConnectedAccountsModalChooseAccountSocialCard
            channel={BrandManagerIdentityProviders.Instagram}
            disabled={isConnecting}
            onClick={onConnectInstagramIdentities}
          />
          <ConnectedAccountsModalChooseAccountSocialCard
            channel={BrandManagerIdentityProviders.TikTok}
            disabled={isPopupOpen || !connectTikTokAdAccountLink}
            onClick={connectTikTokAdAccountLink ? onConnectTiktokAdAccount : undefined}
          />
        </Container>
      </Container>
    </Modal>
  )
}

export { ConnectedAccountsModalChooseAccount }
