import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import { InformationIconContainer } from './CreatorGroup.styled'

const BuilderBudgetCreatorGroupCampaignExpirationMessage: React.FC = () => (
  <Container topOuterSpacing={0.5}>
    <Flex>
      <InformationIconContainer>
        <Icon size={1.125} glyph="information" color={Theme.primaryColor} />
      </InformationIconContainer>
      <Container leftOuterSpacing={0.5}>
        <Text small color={Theme.defaultColor}>
          <FormattedMessage id="builder.budget.creatorGroup.brandFans.expiration.message" />
        </Text>
      </Container>
    </Flex>
  </Container>
)

export { BuilderBudgetCreatorGroupCampaignExpirationMessage }
export default BuilderBudgetCreatorGroupCampaignExpirationMessage
