import { PureComponent } from 'react'

import { endOfDay } from 'date-fns'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { ICurrentSubmissionIdContextProps } from '@context/CurrentSubmissionId'
import { IModalComponentProps } from '@context/Modal'
import { PublishOptions } from '@enums'
import { withCurrentSubmissionId } from '@hocs'
import { IResponseError } from '@lib/error'
import { IApplicationState } from '@store'
import { IOption } from '@store/rootResource'
import {
  approve,
  ISubmission,
  selectErrors,
  selectHasInsufficientFundsForSubmission,
  selectIsApproving,
} from '@store/submissions'
import { clearErrors } from '@store/submissions'
import { Modal } from '@tribegroup'
import SubmissionCardModalApprove from './Approve'

const MIN_PUBLISHABLE_HOURS = 48

interface ISubmissionCardModalApproveWrapperProps
  extends IModalComponentProps,
    ICurrentSubmissionIdContextProps {
  submission: ISubmission
  campaignId: number
  currFilter?: string
  onRequestClose: VoidFunction
}

interface IInternalProps extends ISubmissionCardModalApproveWrapperProps, InjectedIntlProps {
  approve: typeof approve
  clearErrors: typeof clearErrors
  isApproving?: boolean
  error?: IResponseError
  hasInsufficientFunds: boolean
}

interface ISubmissionCardModalApproveWrapperState {
  selectedOptionValue?: string | number
  selectedDateValue?: Date
  showDatePicker: boolean
}

export class SubmissionCardModalApproveWrapper extends PureComponent<
  IInternalProps,
  ISubmissionCardModalApproveWrapperState
> {
  state = {
    selectedOptionValue: undefined,
    selectedDateValue: undefined,
    showDatePicker: false,
  }

  componentDidMount() {
    this.props.clearErrors()
  }

  componentDidUpdate(prevProps: IInternalProps) {
    const { isApproving, error, hasInsufficientFunds } = this.props
    const hasErrors = Boolean(error)

    if (prevProps.isApproving && !isApproving && (!hasErrors || hasInsufficientFunds)) {
      this.onCancel()
    }
  }

  onCancel = () => {
    this.setState(
      {
        selectedOptionValue: undefined,
        selectedDateValue: undefined,
        showDatePicker: false,
      },
      this.props.onRequestClose,
    )
  }

  onChangePublishingOption = (option: IOption) => {
    this.setState({
      selectedOptionValue: option.value,
      showDatePicker: option.value === PublishOptions.CHOOSE_A_DATE,
    })
  }

  onChangeDateValue = (date: Date) => {
    this.setState({
      selectedDateValue: endOfDay(date),
      showDatePicker: false,
    })
  }

  onApproveSubmission = () => {
    const { selectedDateValue, selectedOptionValue } = this.state
    const { submission, campaignId, currFilter } = this.props

    const publishWithin = { publish_within_hours: MIN_PUBLISHABLE_HOURS }

    const publishWithDate = Boolean(selectedDateValue) && {
      scheduled_publish_date: endOfDay(selectedDateValue as any).toISOString(),
    }

    const requestBody =
      selectedOptionValue === PublishOptions.WITHIN_48_HOURS ? publishWithin : publishWithDate

    if (requestBody) {
      this.props.approve(submission.id, campaignId, requestBody, {
        filter: currFilter,
      })
      this.props.setCurrentSubmissionId(submission.id)
    }
  }

  render() {
    const { isOpen = false, submission, hasInsufficientFunds } = this.props
    return (
      <Modal reactModalProps={{ isOpen, onRequestClose: this.onCancel }} width={27.5} complex>
        <SubmissionCardModalApprove
          submission={submission}
          onCloseModal={this.onCancel}
          onChangePublishingOption={this.onChangePublishingOption}
          onChangeDateValue={this.onChangeDateValue}
          onApproveSubmission={this.onApproveSubmission}
          hasInsufficientFunds={hasInsufficientFunds}
          {...this.state}
        />
      </Modal>
    )
  }
}

export const mapDispatchToProps = {
  approve,
  clearErrors,
}

export const mapStateToProps = (state: IApplicationState) => ({
  isApproving: selectIsApproving(state),
  error: selectErrors(state),
  hasInsufficientFunds: selectHasInsufficientFundsForSubmission(state),
})

export default compose<IInternalProps, ISubmissionCardModalApproveWrapperProps>(
  injectIntl,
  withCurrentSubmissionId,
  connect(mapStateToProps, mapDispatchToProps),
)(SubmissionCardModalApproveWrapper)
