import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const DurationAndAmountContainer = styled.div`
  margin: auto;
  text-align: left;
  ${breakpoint('tabletPortrait')`
    padding-right: 1.875rem;
    margin: 0;
  `};
`

export { DurationAndAmountContainer }
