import { WatcherFunction } from '..'
import { watchAutoSignInRequest } from './autoSignIn'
import { watchCompleteSignUpRequest } from './completeSignUp'
import { watchEmailVerificationRequest } from './emailVerification'
import { watchRefreshTokenRequest } from './refreshToken'
import { watchResendVerificationRequest } from './resendVerification'
import { watchSignInRequest } from './signIn'
import { watchSignOutRequest } from './signOut'
import { watchSignUpRequest } from './signUp'
import { watchUnAuthorizedResponses } from './unAuthorized'

export const authSagas: ReadonlyArray<WatcherFunction> = [
  watchAutoSignInRequest,
  watchEmailVerificationRequest,
  watchRefreshTokenRequest,
  watchSignInRequest,
  watchSignOutRequest,
  watchSignUpRequest,
  watchUnAuthorizedResponses,
  watchResendVerificationRequest,
  watchCompleteSignUpRequest,
]
