import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import InboxInfluencerIdentityRow from '@components/Inbox/InfluencerIdentity/Row'
import Loading from '@components/UI/Loading'
import { IApplicationState } from '@store'
import {
  fetchInfluencerIdentitiesUsingNext,
  selectInfluencerIdentitiesHasNextLink,
  selectInfluencerIdentityIdsByCampaignId,
} from '@store/influencerIdentities'
import { selectCampaignId } from '@store/router'
import { Container } from '@tribegroup'

interface IInternalProps {
  campaignId: number
  influencerIdentityIds: ReadonlyArray<number>
  hasMore: boolean
  fetchInfluencerIdentitiesUsingNext: typeof fetchInfluencerIdentitiesUsingNext
}

export const InfluencerIdentityList = ({
  campaignId,
  influencerIdentityIds,
  hasMore,
  fetchInfluencerIdentitiesUsingNext: fetchInfluencerIdentitiesUsingNextAction,
}: IInternalProps) => {
  const fetchNext = () => {
    fetchInfluencerIdentitiesUsingNextAction(campaignId)
  }

  return (
    <Container bottomInnerSpacing={4.5}>
      <InfiniteScroll
        dataLength={influencerIdentityIds.length}
        next={fetchNext}
        loader={<Loading height={30} />}
        hasMore={hasMore}
        scrollThreshold={0.8}
        style={{ overflow: 'hidden' }}
      >
        <div>
          {influencerIdentityIds.map((influencerIdentityId: number) => (
            <InboxInfluencerIdentityRow
              key={influencerIdentityId}
              influencerIdentityId={influencerIdentityId}
              campaignId={campaignId}
            />
          ))}
        </div>
      </InfiniteScroll>
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  return {
    campaignId,
    influencerIdentityIds: selectInfluencerIdentityIdsByCampaignId(state, campaignId),
    hasMore: selectInfluencerIdentitiesHasNextLink(state, campaignId),
  }
}

const mapDispatchToProps = {
  fetchInfluencerIdentitiesUsingNext,
}

export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InfluencerIdentityList)
