import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { INoteState, NotesActionTypes } from './types'

const initialState: INoteState = {
  notesBySubmissionId: {},
  notesLinksBySubmissionId: {},
  isFetching: false,
  isAdding: false,
  errors: undefined,
}

const reducer: Reducer<INoteState> = (state = initialState, action) => {
  switch (action.type) {
    case NotesActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }

    case NotesActionTypes.ADD_REQUEST: {
      return {
        ...state,
        isAdding: true,
      }
    }

    case NotesActionTypes.FETCH_SUCCESS: {
      const {
        submissionId,
        result: { items, links },
      } = action.payload

      return {
        ...state,
        isFetching: false,
        notesBySubmissionId: {
          ...state.notesBySubmissionId,
          [submissionId]: items,
        },
        notesLinksBySubmissionId: {
          ...state.notesLinksBySubmissionId,
          [submissionId]: links,
        },
      }
    }

    case NotesActionTypes.ADD_SUCCESS: {
      const { submissionId, note } = action.payload
      const currentNotes = state.notesBySubmissionId[submissionId] || []
      return {
        ...state,
        isAdding: false,
        notesBySubmissionId: {
          ...state.notesBySubmissionId,
          [submissionId]: [note, ...currentNotes],
        },
      }
    }

    case NotesActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: true,
        errors: action.payload,
      }
    }

    case NotesActionTypes.ADD_ERROR: {
      return {
        ...state,
        isAdding: false,
        errors: action.payload,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as notesReducer }
