import { useContext } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { CampaignContext } from '@context/Campaign'
import { BrandManagerIdentityProviders, SubmissionChannels } from '@enums'
import {
  selectBriefChannelPreference,
  selectCampaignConnectedSocialAccount,
} from '@store/campaigns'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { ConnectedAccountsDrawerIdentity } from '../Identity/Identity'
import { ConnectedAccountsDrawerIdentityPlaceholder } from '../Identity/Placeholder'

const ConnectedAccountsDrawerCollaboratorList = () => {
  const { campaign } = useContext(CampaignContext)

  const {
    briefConnectedInstagramIdentity,
    briefConnectedTikTokIdentity,
    channelPreferences,
  } = useSelector((state: IApplicationState) => {
    return {
      briefConnectedInstagramIdentity: selectCampaignConnectedSocialAccount(
        state,
        campaign.id,
        BrandManagerIdentityProviders.Instagram,
      ),
      briefConnectedTikTokIdentity: selectCampaignConnectedSocialAccount(
        state,
        campaign.id,
        BrandManagerIdentityProviders.TikTok,
      ),
      channelPreferences: selectBriefChannelPreference(state, campaign.id),
    }
  })

  return (
    <Container
      leftOuterSpacing={0.75}
      rightOuterSpacing={0.75}
      bottomOuterSpacing={0.5}
      overflow="auto"
    >
      {channelPreferences?.includes(SubmissionChannels.Instagram) && (
        <ConnectedAccountsDrawerIdentity
          provider={BrandManagerIdentityProviders.Instagram}
          brandManagerIdentity={briefConnectedInstagramIdentity}
          isActive={Boolean(briefConnectedInstagramIdentity)}
        />
      )}
      {channelPreferences?.includes(SubmissionChannels.TikTok) && (
        <>
          {!briefConnectedTikTokIdentity ? (
            <ConnectedAccountsDrawerIdentityPlaceholder
              glyph="social-tiktok"
              displayName="Ads Manager account"
              hideChevron
            />
          ) : (
            <ConnectedAccountsDrawerIdentity
              provider={BrandManagerIdentityProviders.TikTok}
              brandManagerIdentity={briefConnectedTikTokIdentity}
              isActive
            />
          )}
        </>
      )}
      {!briefConnectedInstagramIdentity && !briefConnectedTikTokIdentity && (
        <Container
          borderRadius={0.5}
          backgroundColor={Theme.primary100Color}
          rightInnerSpacing={1}
          leftInnerSpacing={1}
          topInnerSpacing={0.75}
          bottomInnerSpacing={0.75}
          topOuterSpacing={1.25}
        >
          <Text italic light color={Theme.grey900Color}>
            <FormattedMessage id="connectedAccounts.drawer.owner.tiktok.collaborator.accountEducation" />
          </Text>
        </Container>
      )}

      {briefConnectedInstagramIdentity?.reconnect_required && (
        <Container
          borderRadius={0.5}
          backgroundColor={Theme.primary100Color}
          rightInnerSpacing={1}
          leftInnerSpacing={1}
          topInnerSpacing={0.75}
          bottomInnerSpacing={0.75}
          topOuterSpacing={1.25}
        >
          <Text italic light color={Theme.grey900Color}>
            <FormattedMessage id="connectedAccounts.drawer.collaborator.label.reconnect" />
          </Text>
        </Container>
      )}
    </Container>
  )
}

export { ConnectedAccountsDrawerCollaboratorList }
