import AuthSetPasswordConfirmation from '@components/Auth/SetPassword/Confirmation'
import FullImageBackground from '@components/UI/FullImageBackground'

const SetPasswordConfirmationPage = () => (
  <FullImageBackground>
    <AuthSetPasswordConfirmation />
  </FullImageBackground>
)

export default SetPasswordConfirmationPage
