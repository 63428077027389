/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithChildren } from 'react'

import ActionIcon from '@components/UI/ActionIcon'
import Breakpoint from '@components/UI/Breakpoint'
import { BreakpointsPropType, IBreakpointProps } from '@components/UI/Breakpoint/Breakpoint.styled'
import { useBodyOverlayedClassHandler } from '@hooks/useBodyOverlayedClassHandler'
import Theme from '@theme'
import { Drawer, IDrawerProps } from '@tribegroup'

const DEFAULT_BREAKPOINTS = {
  desktop: true,
  mobile: true,
  tabletLandscape: true,
  tabletPortrait: true,
}

export type SupportedBreakpoints = (keyof IBreakpointProps)[]
export interface IDrawerContextProps {
  isOpen: boolean
  showDrawer: (
    componentToRender: React.ReactNode,
    props?: Partial<IDrawerProps>,
    title?: React.ReactNode,
    breakpoints?: SupportedBreakpoints,
  ) => void
  updateTitle: (newTitle: React.ReactNode) => void
  closeDrawer: VoidFunction
}

export const DrawerContext = React.createContext<IDrawerContextProps>({
  isOpen: false,
  showDrawer: (componentToRender: React.ReactNode, props: Partial<IDrawerProps>) => null,
  updateTitle: (newTitle: React.ReactNode) => null,
  closeDrawer: () => null,
})

export const DrawerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [title, setTitle] = React.useState<React.ReactNode>(null)
  const [breakpoints, setBreakpoints] = React.useState<BreakpointsPropType>(DEFAULT_BREAKPOINTS)
  const [drawerContent, setDrawerContent] = React.useState<React.ReactNode | undefined>(undefined)
  const [drawerProps, setDrawerProps] = React.useState<Partial<IDrawerProps> | undefined>(undefined)

  const showDrawer = (
    content: React.ReactNode,
    props: Partial<IDrawerProps>,
    title?: React.ReactNode,
    updatedBreakpoints?: SupportedBreakpoints,
  ) => {
    setDrawerContent(content)
    setDrawerProps(props)
    setIsOpen(true)
    setTitle(title || '')
    if (updatedBreakpoints) {
      const supportedBreakpoints = updatedBreakpoints.reduce(
        (accum, curr) => ({ ...accum, [curr]: true }),
        {},
      )
      setBreakpoints(supportedBreakpoints)
    } else {
      setBreakpoints(DEFAULT_BREAKPOINTS)
    }
  }

  const closeDrawer = () => {
    setIsOpen(false)
  }

  const updateTitle = (newTitle: React.ReactNode) => setTitle(newTitle)

  const contextProps: IDrawerContextProps = {
    isOpen,
    showDrawer,
    updateTitle,
    closeDrawer,
  }

  useBodyOverlayedClassHandler(isOpen)

  return (
    <DrawerContext.Provider value={contextProps}>
      <Drawer
        {...drawerProps}
        open={isOpen}
        title={title}
        wrapperCallback={closeDrawer}
        breakpoints={breakpoints}
        headerRightIcon={
          <ActionIcon
            size={1.5}
            glyph="close-x"
            color={Theme.grey900Color}
            onClick={closeDrawer}
            elementName="close-feedback-view"
          />
        }
      >
        {drawerContent}
      </Drawer>
      {children}
    </DrawerContext.Provider>
  )
}

export default DrawerProvider
