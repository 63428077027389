import { PureComponent } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { PanelWrapper } from '@components/Builder/Layout/Layout.styled'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import { fetchCategories, selectCategoriesAsLabelValue } from '@store/categories'
import Theme from '@theme'
import {
  FormGroup,
  FormGroupRow,
  FormInput,
  FormValidationMessage,
  H4,
  Panel,
  Select,
  Text,
} from '@tribegroup'
import { isEmpty } from '@utils'

interface IBuilderCampaignCategoriesProps {
  primary?: number
  secondary?: number
  disabled?: boolean
  onCategoryChanged: (selectedValues: {}) => void
}

interface IInternalProps
  extends IBuilderCampaignCategoriesProps,
    InjectedIntlProps,
    IBuilderValidationContextProps {
  categories: ReadonlyArray<any>
  fetchCategories: typeof fetchCategories
}

export class BuilderCampaignCategories extends PureComponent<IInternalProps> {
  componentDidMount() {
    this.props.fetchCategories()
  }

  clearValidation = (name: string) => {
    this.props.clearFieldValidation(name)
  }

  onPrimaryChanged = (option) => {
    this.props.clearFieldValidation('product_category_primary_id')
    if (option.value === this.props.secondary) {
      this.props.onCategoryChanged({
        product_category_primary_id: option.value,
        product_category_secondary_id: '',
      })
    } else {
      this.props.onCategoryChanged({
        product_category_primary_id: option.value,
      })
    }
  }

  onSecondaryChanged = (option) => {
    this.props.onCategoryChanged({
      product_category_primary_id: this.props.primary,
      product_category_secondary_id: option.value,
    })
  }

  onFocus = (event) => {
    const id = event.currentTarget.getAttribute('id')
    if (id) {
      this.clearValidation(id)
    }
  }

  render() {
    const { intl, categories, validationResults, primary, secondary, disabled } = this.props
    if (isEmpty(categories)) {
      return null
    }
    const secondaryCategories = categories.filter((option) => option.value !== primary)
    return (
      <PanelWrapper data-cy-element="categories-panel-wrapper">
        <Panel
          disabled={disabled}
          title={
            <H4 color={Theme.defaultColor}>
              <FormattedMessage id="builder.campaign.category.label" />
            </H4>
          }
        >
          <Text color={Theme.defaultColor}>
            <FormattedMessage id="builder.campaign.category.text" />
          </Text>
          <FormGroupRow>
            <FormGroup outerTopSpacing={1.5}>
              <FormInput
                validationRule={{
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      id: 'builder.campaign.error.category.required',
                    }),
                  },
                }}
              >
                <Select
                  scheme="default"
                  thick
                  dropdownHeight={13.7}
                  invalid={
                    validationResults.product_category_primary_id &&
                    validationResults.product_category_primary_id.hasError
                  }
                  rsConfig={{
                    onFocus: this.onFocus,
                    name: 'product_category_primary_id',
                    inputId: 'product_category_primary_id',
                    value: categories.find((option) => option.value === primary),
                    placeholder: intl.formatMessage({
                      id: 'builder.campaign.category.primary.placeholder',
                    }),
                    onChange: this.onPrimaryChanged,
                    options: categories as any,
                  }}
                />
              </FormInput>
              {validationResults.product_category_primary_id &&
                validationResults.product_category_primary_id.hasError && (
                  <FormValidationMessage>
                    <Text small color={Theme.errorColor}>
                      {validationResults.product_category_primary_id.errorMessage}
                    </Text>
                  </FormValidationMessage>
                )}
            </FormGroup>
            <FormGroup outerTopSpacing={1.5}>
              <Select
                scheme="default"
                thick
                dropdownHeight={13.7}
                rsConfig={{
                  onFocus: this.onFocus,
                  isDisabled: !primary,
                  value: secondary ? categories.find((option) => option.value === secondary) : '',
                  onChange: this.onSecondaryChanged,
                  placeholder: intl.formatMessage({
                    id: 'builder.campaign.category.secondary.placeholder',
                  }),
                  name: 'product_category_secondary_id',
                  inputId: 'product_category_secondary_id',
                  options: secondaryCategories,
                }}
              />
            </FormGroup>
          </FormGroupRow>
        </Panel>
      </PanelWrapper>
    )
  }
}

const mapDispatchToProps = {
  fetchCategories,
}

const mapStateToProps = (state: IApplicationState) => {
  const categories = selectCategoriesAsLabelValue(state)
  return {
    categories,
  }
}

export default compose<IInternalProps, IBuilderCampaignCategoriesProps>(
  injectIntl,
  withBuilderValidation,
  connect(mapStateToProps, mapDispatchToProps),
)(BuilderCampaignCategories)
