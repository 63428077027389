import { FormattedMessage } from 'react-intl'

import ConfirmationMessageBox from '@components/UI/ConfirmationMessageBox'
import RouterLink from '@components/UI/RouterLink/RouterLink.styled'
import { PageRoutes } from '@enums'
import { Button } from '@tribegroup'

export const AuthCompleteSignupMessageBoxSetupComplete = () => {
  return (
    <ConfirmationMessageBox
      title={<FormattedMessage id="auth.completeSignup.setupComplete.title" />}
      footerCTA={
        <RouterLink to={`/${PageRoutes.SignIn}`}>
          <Button scheme="primary">
            <FormattedMessage id="core.text.loginToTribe" />
          </Button>
        </RouterLink>
      }
    >
      <FormattedMessage id="auth.completeSignup.setupComplete.body" />
    </ConfirmationMessageBox>
  )
}
