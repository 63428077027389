import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'
import { Container } from '@tribegroup'

const ShowContentOnHover = styled.div`
  display: none;
`

const HideContentOnHover = styled.div`
  display: block;
`

const AvatarContainer = styled(Container)`
  margin-right: -0.825rem;
  ${breakpoint('desktop')`
    margin-right: -0.375rem;
  `};
`

const OtherMembersCount = styled.div`
  border-radius: 2rem;
  height: 100%;
  width: 100%;
  border: solid 0.25rem ${Theme.whiteColor};
  background-color: ${Theme.grey300Color};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.625rem;
`

const Root = styled.div`
  cursor: pointer;
  padding: 0.75rem 1.25rem 1.25rem;
  margin-bottom: 0.75rem;
  border-radius: 0.5rem;
  background: ${Theme.whiteColor};
  text-decoration: none;

  &:hover {
    background: ${Theme.grey200Color};
    ${ShowContentOnHover} {
      display: block;
    }
    ${HideContentOnHover} {
      display: none;
    }
  }
`

const HeaderRoot = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export {
  Root,
  HeaderRoot,
  HideContentOnHover,
  ShowContentOnHover,
  OtherMembersCount,
  AvatarContainer,
}
