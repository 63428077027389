import { PageRoutes } from '@enums'

export const getBackLink = (pathname: string) => {
  const submissionDetailBaseLink = pathname.match(/\/campaigns\/[0-9]*\/submissions\/[0-9]*/g)
  const inboxBaseLink = pathname.match(/\/campaigns\/[0-9]*/g)
  const campaignsBilling = pathname.match(/\/billing\/campaigns\/[0-9]*/g)
  const contentLibraryBilling = pathname.match(/\/billing\/content-library\/[0-9]*/g)
  const campaignBaseLink = '/campaigns'

  if (campaignsBilling) {
    return `/${PageRoutes.BillingCampaigns}`
  }

  if (contentLibraryBilling) {
    return `/${PageRoutes.BillingContentLibrary}`
  }

  if (!inboxBaseLink) {
    return campaignBaseLink
  }

  const isInsideSubmissionDetail = /\/detail\/(notes|feedback)/g.test(pathname)
  if (isInsideSubmissionDetail && submissionDetailBaseLink) {
    return submissionDetailBaseLink[0]
  }

  return inboxBaseLink[0]
}
