import { useContext } from 'react'

import { MediaModalContext } from '@context/MediaModal'

const useMediaModal = () => {
  const { videoSrc, setVideoSrc, imageSrc, setImageSrc } = useContext(MediaModalContext)

  const openVideoPlayer = (src: string) => {
    setVideoSrc(src)
  }

  const openImageViewer = (src: string) => {
    setImageSrc(src)
  }

  const closeMediaModal = () => {
    setVideoSrc(null)
    setImageSrc(null)
  }

  return {
    closeMediaModal,
    videoSrc,
    openVideoPlayer,
    imageSrc,
    openImageViewer,
  }
}

export { useMediaModal }
