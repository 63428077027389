import { AuthActionTypes } from '@store/auth'
import { CampaignActionTypes } from '@store/campaigns'
import { CampaignMetricsActionTypes, ICampaignMetricsState } from './types'

const initialState: ICampaignMetricsState = {
  isFetching: false,
  errors: undefined,
  campaignMetricsById: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CampaignMetricsActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
        errors: undefined,
      }
    }

    case CampaignMetricsActionTypes.FETCH_SUCCESS: {
      const campaignId = action.payload.brief_id
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        campaignMetricsById: {
          ...state.campaignMetricsById,
          [campaignId]: action.payload,
        },
      }
    }

    case CampaignMetricsActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    case CampaignActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        errors: undefined,
      }

    default:
      return state
  }
}

export { reducer as campaignMetricsReducer }
