import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { Hr } from '@components/UI/Hr/Hr.styled'
import { useGender } from '@hooks/useGender'
import { IApplicationState } from '@store'
import { selectCampaignTarget } from '@store/campaigns'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Container, H3, Text } from '@tribegroup'
import { isEmpty } from '@utils'

type IInternalProps = ConnectedProps<typeof connector>

const AudienceReportingDrawerCampaignTarget: React.FC<IInternalProps> = ({
  age,
  genders,
  locations,
}) => {
  if (isEmpty(age) && isEmpty(genders) && isEmpty(locations)) {
    return null
  }

  const { allGenders } = useGender()

  const hasSelectedAll = allGenders.length === genders.length

  return (
    <Container bottomOuterSpacing={2}>
      <H3 color={Theme.primaryColor}>
        <FormattedMessage id="analytics.audienceMetrics.drawer.audienceTarget.header" />
      </H3>
      {age.length > 0 && (
        <Container topOuterSpacing={1} data-testid="target-preferred-age">
          <Text block color={Theme.grey900Color}>
            <FormattedMessage id="core.text.preferredAge" />
          </Text>

          {age.map((record) => (
            <Container
              key={record}
              topOuterSpacing={0.5}
              topInnerSpacing={0.5}
              bottomInnerSpacing={0.5}
              leftInnerSpacing={0.75}
              rightInnerSpacing={0.75}
              backgroundColor={Theme.primary100Color}
              inlineBlock
              borderRadius={0.5}
              rightOuterSpacing={0.25}
            >
              <Text color={Theme.grey900Color}>{record}</Text>
            </Container>
          ))}
        </Container>
      )}

      {genders.length > 0 && (
        <Container topOuterSpacing={1}>
          <Text block color={Theme.grey900Color}>
            <FormattedMessage id="core.text.gender" />
          </Text>
          {hasSelectedAll ? (
            <Container
              topOuterSpacing={0.5}
              topInnerSpacing={0.5}
              bottomInnerSpacing={0.5}
              leftInnerSpacing={0.75}
              rightInnerSpacing={0.75}
              backgroundColor={Theme.primary100Color}
              inlineBlock
              borderRadius={0.5}
            >
              <Text color={Theme.grey900Color}>
                <FormattedMessage id="core.text.allGenders" />
              </Text>
            </Container>
          ) : (
            genders.map((gender) => (
              <Container
                key={gender}
                topOuterSpacing={0.5}
                topInnerSpacing={0.5}
                bottomInnerSpacing={0.5}
                leftInnerSpacing={0.75}
                rightInnerSpacing={0.75}
                backgroundColor={Theme.primary100Color}
                inlineBlock
                borderRadius={0.5}
                rightOuterSpacing={0.25}
              >
                <Text color={Theme.grey900Color} capitalize>
                  {gender}
                </Text>
              </Container>
            ))
          )}
        </Container>
      )}

      {locations.length > 0 && (
        <Container topOuterSpacing={1} bottomOuterSpacing={2.5}>
          <Text block color={Theme.grey900Color}>
            <FormattedMessage id="core.text.location" />
          </Text>
          {locations.map((location) => (
            <Container
              key={location}
              topOuterSpacing={0.5}
              topInnerSpacing={0.5}
              bottomInnerSpacing={0.5}
              leftInnerSpacing={0.75}
              rightInnerSpacing={0.75}
              backgroundColor={Theme.primary100Color}
              inlineBlock
              borderRadius={0.5}
              rightOuterSpacing={0.25}
            >
              <Text color={Theme.grey900Color}>{location}</Text>
            </Container>
          ))}
        </Container>
      )}
      <Hr backgroundColor={Theme.grey400Color} />
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match)!
  const { age, genders, locations } = selectCampaignTarget(state, campaignId)
  return {
    age,
    genders: genders || [],
    locations,
  }
}

const connector = connect(mapStateToProps)

export default compose(withRouter, connector)(AudienceReportingDrawerCampaignTarget)
