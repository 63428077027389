import { IMultiframeContextProps } from '@context/MultiframeControls'
import { withMultiframeControls } from '@hocs'
import Theme from '@theme'
import { Icon } from '@tribegroup'
import { NextWrapper } from './Next.styled'

export const MultiframeNext = ({ next, activeFrameNumber, frames }: IMultiframeContextProps) => {
  const enabled = activeFrameNumber < frames.length
  return (
    <NextWrapper
      onClick={next as VoidFunction}
      disabled={!enabled}
      className="slider-navigation-button next"
      title="Next Frame"
    >
      <Icon glyph="chevron-right" size={1.25} color={Theme.defaultColor} />
    </NextWrapper>
  )
}

export default withMultiframeControls(MultiframeNext)
