import { useContext } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import * as yup from 'yup'

import AuthSignupFormErrorContainer from '@components/Auth/SignUp/Form/ErrorContainer'
import { TrackedRoute } from '@components/UI/TrackedRoute'
import {
  AnalyticsTrackingContext,
  IAnalyticsTrackingContextProps,
} from '@context/AnalyticsTracking'
import { CampaignContext } from '@context/Campaign'
import { EventTrackingNames, MemberRoleTypes } from '@enums'
import { useModal } from '@hooks/useModal'
import { addMember, selectisAdding } from '@store/members'
import {
  Button,
  CloseIcon,
  Container,
  FormGroup,
  Header,
  Modal,
  ModalTitle,
  Radio,
  SelectionSubtext,
  TextField,
} from '@tribegroup'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('auth.signup.error.email.required')
    .required('auth.signup.error.email.required')
    .trim(),
  role_type: yup.string().required(),
})

type FormDataType = yup.InferType<typeof validationSchema>

export const InboxMemberAddForm = () => {
  const { register, getValues, handleSubmit, errors } = useForm<FormDataType>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      role_type: MemberRoleTypes.Guest,
    },
  })

  const { getCampaignTrackingProps, getCampaignTrackingEvent } = useContext(
    AnalyticsTrackingContext,
  ) as IAnalyticsTrackingContextProps
  const { campaign } = useContext(CampaignContext)
  const { isOpen, hideModal } = useModal()

  const dispatch = useDispatch()

  const isAdding = useSelector(selectisAdding)

  const cancelInviteTrackedProps = {
    eventName: EventTrackingNames.CancelMemberInvite,
    properties: {
      ...getCampaignTrackingProps(campaign),
      cancelled_invite_role: getValues('role_type'),
    },
  }

  const onSubmit = (formData: FormDataType) => {
    const analyticsEvent = getCampaignTrackingEvent(EventTrackingNames.InviteMember, campaign, {
      invited_user_role: formData.role_type,
    })

    return dispatch(
      addMember(
        campaign?.id,
        {
          email: formData?.email,
          role_type: formData?.role_type as MemberRoleTypes,
        },
        {
          event: analyticsEvent,
          toast: (
            <FormattedMessage
              id="inbox.sidebar.members.toast.success"
              values={{ email: formData.email }}
            />
          ),
        },
      ),
    )
  }

  const invitableRoles = campaign?.invitable_roles ?? [
    MemberRoleTypes.Collaborator,
    MemberRoleTypes.GuestWithFinancials,
    MemberRoleTypes.Guest,
  ]

  return (
    <Modal reactModalProps={{ isOpen: Boolean(isOpen) }} complex>
      <ModalTitle bottomOuterSpacing={1}>
        <FormattedMessage id="inbox.sidebar.members.form.title" />
      </ModalTitle>
      <TrackedRoute {...cancelInviteTrackedProps}>
        <CloseIcon onClick={hideModal} />
      </TrackedRoute>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup stretchChildWidth>
          <TextField
            innerRef={register}
            fullWidth
            rounded
            type="text"
            name="email"
            placeholder="Email address"
            invalid={Boolean(errors.email)}
          />
          <AuthSignupFormErrorContainer intlId={errors.email?.message} />
        </FormGroup>

        <FormGroup>
          {invitableRoles?.map((role: MemberRoleTypes) => {
            return (
              <Container key={`invite-member-${role}`} bottomOuterSpacing={0.5}>
                <Radio
                  scheme="primary"
                  label={<FormattedMessage id={`inbox.sidebar.members.form.type.${role}.title`} />}
                  name="role_type"
                  value={role}
                  innerRef={register}
                  data-testid={`invite-member-${role}-input`}
                />
                <SelectionSubtext>
                  <FormattedMessage id={`inbox.sidebar.members.form.type.${role}.subtext`} />
                </SelectionSubtext>
              </Container>
            )
          })}
        </FormGroup>

        <Container topOuterSpacing={2}>
          <Grid fluid>
            <Row>
              <Col xs={6}>
                <Container rightOuterSpacing={0.625}>
                  <TrackedRoute {...cancelInviteTrackedProps}>
                    <Button
                      fullWidth
                      scheme="primary"
                      outlined
                      onClick={hideModal}
                      type="reset"
                      data-cy-element="invite-member-modal-cancel-button"
                    >
                      <Header cta>
                        <FormattedMessage id="core.text.cancel" />
                      </Header>
                    </Button>
                  </TrackedRoute>
                </Container>
              </Col>
              <Col xs={6}>
                <Container leftOuterSpacing={0.625}>
                  <Button
                    fullWidth
                    scheme="primary"
                    disabled={isAdding}
                    loading={isAdding}
                    data-cy-element="invite-member-modal-add-button"
                    data-testid="invite-member-modal-add-button"
                  >
                    <Header cta>
                      <FormattedMessage id="core.text.add" />
                    </Header>
                  </Button>
                </Container>
              </Col>
            </Row>
          </Grid>
        </Container>
      </form>
    </Modal>
  )
}
