import { call, put, takeLatest } from 'redux-saga/effects'

import { FacebookService } from '@services/facebook'
import {
  fetchAdAccountsError,
  fetchAdAccountsSuccess,
  FetchFacebookAdAccountsActionTypes,
} from '@store/facebookAds'

export function* handleFetchAdAccounts() {
  try {
    const adAccounts = yield call(FacebookService.getAdAccounts)
    yield put(fetchAdAccountsSuccess(adAccounts))
  } catch (error) {
    yield put(fetchAdAccountsError(error))
  }
}

export function* watchFetchAdAccountsRequest() {
  yield takeLatest(FetchFacebookAdAccountsActionTypes.FETCH_REQUEST, handleFetchAdAccounts)
}
