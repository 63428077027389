export const FULL_DATE = 'dd MMM yyyy'
export const FULL_DATE_WITH_COMMA = 'dd MMM, yyyy'
export const FULL_DATE_WITH_DAY_OF_WEEK = 'eee, dd MMM yyyy'
export const FULL_DATE_WITH_FULL_MONTH = 'dd MMMM yyyy'

export const DAY_MONTH = 'dd MMM'
export const DAY_MONTH_TIME_WITH_DAY_OF_WEEK = 'eee, dd MMM, H:mm'

export const DAY_OF_WEEK = 'eee'

export const YEAR_MONTH = 'yyyy-MM'
export const MONTH_MONTH = 'MMMM yyyy'

export const TIME_WITH_MERIDIEM_INDICATOR = 'hh:mm a'
