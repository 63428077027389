import { AudienceInsightsSource } from '@enums'
import { AudienceInsightsActionTypes, IAudienceInsights } from './'

export const fetchAudienceInsights = (identityId: number, source: AudienceInsightsSource) => ({
  type: AudienceInsightsActionTypes.FETCH_REQUEST,
  payload: { identityId, source },
})

export const fetchAudienceInsightsSuccess = (
  identityId: number,
  audienceInsights: IAudienceInsights,
) => ({
  type: AudienceInsightsActionTypes.FETCH_SUCCESS,
  payload: { identityId, audienceInsights },
})

export const fetchAudienceInsightsError = (error: any) => ({
  type: AudienceInsightsActionTypes.FETCH_ERROR,
  payload: error,
})
