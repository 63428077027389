import { MouseEvent, useContext } from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { ConnectedAccountsSideBarItemEducationConnectTikTokAccountOnApprove } from '@components/ConnectedAccounts/SideBarItem/Education/ConnectTikTokAccountOnApprove'
import SubmissionCardModalApproveWrapper from '@components/Submission/Card/Modal/Approve/Wrapper'
import ActionIcon from '@components/UI/ActionIcon'
import { InboxPromptContext } from '@context/InboxPrompt'
import { PopupMenuContext } from '@context/PopupMenu'
import { AppName } from '@enums'
import { useModal } from '@hooks/useModal'
import {
  selectCampaign,
  selectConnectedTikTokSocialAccount,
  selectHasApprovedTikTokSubmission,
} from '@store/campaigns'
import { IApplicationState } from '@store/index'
import { ISubmission, selectIsSubmissionFromTiktok } from '@store/submissions'
import Theme from '@theme'
import { pxToRem } from '@utils/pxToRem'
import ActionBarIconApprovePopupMenu from './PopupMenu'
import SubmissionCardModalTikTokPreApprovalConfirmation from './TikTokPreApprovalConfirmation'

interface IApproveModalProps {
  currFilter?: string
  submission: ISubmission
  campaignId: number
}

interface IProps extends InjectedIntlProps {
  modalProps: IApproveModalProps
  highlighted?: boolean
  hasPreApproveLink?: boolean
}

export const ActionBarIconApprove = ({
  modalProps,
  highlighted,
  hasPreApproveLink,
  intl,
}: IProps) => {
  const { showModal, hideModal } = useModal()
  const { showPopup } = useContext(PopupMenuContext)
  const {
    campaignId,
    submission: { id: submissionId },
  } = modalProps
  const { showInboxPrompt } = useContext(InboxPromptContext)

  const {
    shouldShowTikTokPreApproveModal,
    shouldShowTikTokPreApprovePrompt,
    tiktokSocialAccount,
  } = useSelector((state: IApplicationState) => {
    const campaign = selectCampaign(state, campaignId)
    const isTikTokSubmission = selectIsSubmissionFromTiktok(state, submissionId)
    const hasApprovedTikTokSubmissions = selectHasApprovedTikTokSubmission(state, campaignId)
    const tiktokSocialAccount = selectConnectedTikTokSocialAccount(state, campaignId)

    const isTribeTenant = process.env.APP_NAME === AppName.TRIBE

    return {
      shouldShowTikTokPreApproveModal:
        isTikTokSubmission &&
        Boolean(tiktokSocialAccount) &&
        !campaign.has_tiktok_campaign_id &&
        !hasApprovedTikTokSubmissions &&
        isTribeTenant,
      shouldShowTikTokPreApprovePrompt:
        isTikTokSubmission &&
        !tiktokSocialAccount &&
        !hasApprovedTikTokSubmissions &&
        isTribeTenant,
      tiktokSocialAccount,
    }
  })

  const handleFinalApproval = () => {
    hideModal()
    showModal(SubmissionCardModalApproveWrapper, modalProps)
  }

  const onClick = ({ target }: MouseEvent<HTMLDivElement>) => {
    if (shouldShowTikTokPreApprovePrompt) {
      return showInboxPrompt(<ConnectedAccountsSideBarItemEducationConnectTikTokAccountOnApprove />)
    }

    if (hasPreApproveLink) {
      const targetElement = target as HTMLElement
      const { left, top, width } = targetElement.getBoundingClientRect()

      showPopup(
        <ActionBarIconApprovePopupMenu
          shouldShowTikTokPreApprovePrompt={shouldShowTikTokPreApproveModal}
          tiktokSocialAccount={tiktokSocialAccount}
          modalProps={modalProps}
        />,
        {
          x: pxToRem(left + width),
          y: pxToRem(top),
        },
      )
      return
    }

    if (shouldShowTikTokPreApproveModal) {
      showModal(SubmissionCardModalTikTokPreApprovalConfirmation, {
        tiktokSocialAccount,
        onCancel: hideModal,
        onProceed: handleFinalApproval,
      })
    } else {
      handleFinalApproval()
    }
  }

  return (
    <ActionIcon
      highlighted={highlighted}
      glyph="approve-submission"
      color={Theme.grey900Color}
      size={1.5}
      onClick={onClick}
      tooltip={intl.formatMessage({ id: 'submission.card.action.approve' })}
      elementName="approve"
    />
  )
}

export type { IApproveModalProps }
export default injectIntl(ActionBarIconApprove)
