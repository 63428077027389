import { CampaignStatuses } from '@enums'
import { ICampaign } from '@store/campaigns'
import { IDraft } from '@store/drafts'

export const generateEditLink = (campaign: ICampaign | IDraft) => {
  if (campaign.status === CampaignStatuses.Draft && Boolean(campaign.step_completed)) {
    return `/builder/${campaign.step_completed}/${campaign.id}`
  }
  return `/builder/campaign/${campaign.id}`
}
