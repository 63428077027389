import { useContext, useState } from 'react'

import OutsideClickHandler from 'react-outside-click-handler'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import Breakpoint from '@components/UI/Breakpoint'
import BudgetInformationPanel from '@components/UI/BudgetInformationPanel/BudgetInformationPanel'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { INBOX_FILTER } from '@constants'
import { InformationTooltipContext } from '@context/InformationTooltip'
import { EventTrackingNames, MemberRoleTypes } from '@enums'
import { IApplicationState } from '@store'
import {
  selectCampaignBudgetRemaining,
  selectCampaignBudgetSpent,
  selectCampaignTotalCurrency,
  selectIsPriceHidden,
  selectIsSocialCampaign,
  selectIsTraditional,
  selectPaymentMethod,
  selectRole,
} from '@store/campaigns'
import { selectCampaignId, selectCurrentSubmissionFilter } from '@store/router'
import { selectSubmissionsTotalValue } from '@store/submissions'
import { Container } from '@tribegroup'
import { pxToRem } from '@utils'
import CampaignBudgetHoverableIcon from './HoverableIcon'
import CampaignBudgetInboxValue from './InboxValue'
import CampaignBudgetTipContent from './TipContent'

type IInternalProps = ConnectedProps<typeof connector>

export const CampaignBudget = ({
  campaignId,
  remaining,
  spent,
  currency,
  paymentMethod,
  isSocialCampaign,
  inboxValue,
  isPriceHidden,
  isTraditional,
  isOwner,
}: IInternalProps) => {
  const { showTooltip, hideTooltip, tooltipVisible } = useContext(InformationTooltipContext)
  const [tipShown, setTipShown] = useState(false)

  const submissionFilter = useSelector(selectCurrentSubmissionFilter)

  const showInboxValue = inboxValue && submissionFilter === INBOX_FILTER

  const showBudgetBreakdown = isTraditional || isOwner

  const showBudget = isTraditional || !isPriceHidden

  if (!showBudget) {
    return null
  }

  const onOutsideClick = () => {
    if (!tooltipVisible) {
      resetTooltip()
    }
  }

  const onInsideClick = () => {
    if (tipShown) {
      resetTooltip()
      return
    }

    const target = document.querySelector('[data-icon-glyph="information"]') as HTMLElement
    const { left, top } = target.getBoundingClientRect()
    showTooltip(
      <CampaignBudgetTipContent campaignId={campaignId} paymentMethod={paymentMethod} />,
      {
        x: pxToRem(left),
        y: pxToRem(top + window.scrollY + 16),
      },
      'right',
      24,
    )
    setTipShown(true)
  }

  const resetTooltip = () => {
    hideTooltip()
    setTipShown(false)
  }

  return (
    <ConnectedTrackedRoute
      eventName={EventTrackingNames.BudgetPanelTooltip}
      disableTracking={!isSocialCampaign}
      campaignId={campaignId}
      additionalProperties={{
        budget_type: paymentMethod,
        action: tipShown ? 'hide' : 'show',
      }}
    >
      <OutsideClickHandler onOutsideClick={onOutsideClick}>
        <BudgetInformationPanel
          remaining={remaining}
          spent={spent}
          currency={currency}
          onClick={showBudgetBreakdown ? onInsideClick : undefined}
          secondRowContent={
            inboxValue && (
              <Breakpoint mobile tabletPortrait>
                <Container topOuterSpacing={0.5}>
                  <CampaignBudgetInboxValue
                    amount={inboxValue?.inbox_cents}
                    currency={inboxValue?.inbox_currency}
                  />
                </Container>
              </Breakpoint>
            )
          }
        >
          <Flex justifyBetween alignCenter>
            <Container topOuterSpacing={0.25} leftOuterSpacing={0.5} width={1} inlineBlock>
              {showBudgetBreakdown && <CampaignBudgetHoverableIcon campaignId={campaignId} />}
            </Container>
            {showInboxValue && (
              <Breakpoint tabletLandscape desktop>
                <CampaignBudgetInboxValue
                  reversed
                  amount={inboxValue?.inbox_cents}
                  currency={inboxValue?.inbox_currency}
                />
              </Breakpoint>
            )}
          </Flex>
        </BudgetInformationPanel>
      </OutsideClickHandler>
    </ConnectedTrackedRoute>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  return {
    campaignId,
    isSocialCampaign: selectIsSocialCampaign(state, campaignId),
    remaining: selectCampaignBudgetRemaining(state, campaignId),
    spent: selectCampaignBudgetSpent(state, campaignId),
    currency: selectCampaignTotalCurrency(state, campaignId),
    paymentMethod: selectPaymentMethod(state, campaignId),
    inboxValue: selectSubmissionsTotalValue(state, campaignId),
    isPriceHidden: selectIsPriceHidden(state, campaignId),
    isTraditional: selectIsTraditional(state, campaignId),
    isOwner: selectRole(state, campaignId) === MemberRoleTypes.Owner,
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(withRouter, connector)(CampaignBudget)
