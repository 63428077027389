import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { BrandedContentAdStatusTypes } from '@enums'
import { IApplicationState } from '@store'
import {
  selectTransactedBrandedContentAd,
  selectTransactedBrandedContentAdStatus,
} from '@store/submissions'
import { isEmpty } from '@utils'
import ButtonPendingCreatorApproval from './PendingCreatorApproval'
import ButtonRequestGranted from './RequestGranted'
import ButtonRequestPricing from './RequestPricing'
import ButtonRightsDeclined from './RightsDeclined'
import ButtonRightsExpired from './RightsExpired'
import ButtonRightsRequested from './RightsRequested'

interface IProps {
  submissionId: number
}

interface IInternalProps {
  bcaStatus: BrandedContentAdStatusTypes
  hasBcaTransaction: boolean
}

const BrandedContentAdFormButton: React.FC<IInternalProps> = ({ bcaStatus, hasBcaTransaction }) => {
  if (!hasBcaTransaction) {
    return <ButtonRequestPricing />
  }

  switch (bcaStatus) {
    case BrandedContentAdStatusTypes.PendingCreatorApproval:
      return <ButtonPendingCreatorApproval />
    case BrandedContentAdStatusTypes.Requested: {
      return <ButtonRightsRequested />
    }
    case BrandedContentAdStatusTypes.Declined: {
      return <ButtonRightsDeclined />
    }
    case BrandedContentAdStatusTypes.Completed:
    case BrandedContentAdStatusTypes.Approved: {
      return <ButtonRequestGranted />
    }
    case BrandedContentAdStatusTypes.Expired: {
      return <ButtonRightsExpired />
    }
  }
}

const mapStateToProps = (state: IApplicationState, { submissionId }: IProps) => ({
  bcaStatus: selectTransactedBrandedContentAdStatus(state, submissionId),
  hasBcaTransaction: !isEmpty(selectTransactedBrandedContentAd(state, submissionId)),
})

export { BrandedContentAdFormButton }
export default compose<IInternalProps, IProps>(connect(mapStateToProps))(BrandedContentAdFormButton)
