import { call, select } from 'redux-saga/effects'

import { IMeta } from '@store'
import { selectCampaignHasPermissionError } from '@store/campaigns'

export function* handleCampaignPermissionError(action: { meta?: IMeta }) {
  const hasPermissionError = yield select(selectCampaignHasPermissionError)
  if (hasPermissionError && action.meta) {
    const { history } = action.meta
    yield call(history!.push, '/campaigns')
  }
}
