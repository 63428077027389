import { IMeta } from '@store'
import { IMember, IMemberInviteFormData, MemberActionTypes } from './types'
import { MembersActionTypes } from './'

export const fetchMembers = (campaignId: number) => ({
  payload: campaignId,
  type: MembersActionTypes.FETCH_REQUEST,
})

export const fetchMembersSuccess = (members: any, campaignId: number) => ({
  payload: { campaignId, ...members },
  type: MembersActionTypes.FETCH_SUCCESS,
})

export const fetchMembersError = (error: any) => ({
  payload: error,
  type: MembersActionTypes.FETCH_ERROR,
})

export const addMember = (campaignId: number, formData: IMemberInviteFormData, meta?: IMeta) => ({
  payload: {
    campaignId,
    addMemberParams: formData,
  },
  type: MemberActionTypes.ADD_REQUEST,
  meta,
})

export const addMemberSuccess = (campaignId: number, member: IMember, meta?: any) => ({
  type: MemberActionTypes.ADD_SUCCESS,
  payload: { campaignId, member },
  meta,
})

export const addMemberError = (error: any) => ({
  payload: error,
  type: MemberActionTypes.ADD_ERROR,
})

export const removeMember = (campaignId: number, memberId: number) => ({
  payload: { campaignId, memberId },
  type: MemberActionTypes.REMOVE_REQUEST,
})

export const removeMemberSuccess = (campaignId: number, memberId: number) => ({
  payload: { campaignId, memberId },
  type: MemberActionTypes.REMOVE_SUCCESS,
})

export const removeMemberError = (error: any) => ({
  payload: error,
  type: MemberActionTypes.REMOVE_ERROR,
})

export const clearErrors = () => ({
  type: MemberActionTypes.CLEAR_ERRORS,
})
