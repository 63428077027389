import { FormattedHTMLMessage } from 'react-intl'

import {
  CoachmarkContent,
  CoachmarkSection,
  SideBarContentWrapper,
} from '@components/UI/Coachmark/Coachmark.styled'
import Theme from '@theme'
import { Container, Text, Tip } from '@tribegroup'

const BuilderBriefChannelsTiktokTiptopCoachMarkContent = () => {
  return (
    <CoachmarkContent data-testid="tiktok-tip-coachmark">
      <CoachmarkSection>
        <Text color={Theme.defaultColor} small>
          <FormattedHTMLMessage id="builder.brief.socialMedia.tiktok.tip.coachmark" />
        </Text>
      </CoachmarkSection>
    </CoachmarkContent>
  )
}

interface IProps {
  isTikTokAdded: boolean
}

const BuilderBriefChannelsTiktokTip: React.FC<IProps> = ({ isTikTokAdded }) => {
  return (
    <SideBarContentWrapper center={!isTikTokAdded} marginTop={isTikTokAdded ? 5 : 0}>
      <Tip
        data-testid="tiktok-tip"
        text={
          <Container absolute width={12.5} data-testid="tiktok-tip">
            <Text xsmall>
              <FormattedHTMLMessage id="builder.brief.socialMedia.tiktok.tip" />
            </Text>
          </Container>
        }
        width={12}
        coachmark={{
          content: <BuilderBriefChannelsTiktokTiptopCoachMarkContent />,
          position: 'left',
          width: 22,
          yOffset: 2.5,
        }}
      />
    </SideBarContentWrapper>
  )
}

export default BuilderBriefChannelsTiktokTip
