import { call, put, select, takeEvery } from 'redux-saga/effects'

import { ToastTemplateSubmissionMoved } from '@components/UI/ToastTemplate'
import { INBOX_FILTER, SHORTLISTED_FILTER } from '@constants'
import { EventTrackingNames, SubmissionStatuses } from '@enums'
import { handleFetchInfluencerIdentity } from '@sagas/influencerIdentities/influencerIdentity'
import { IHateoasLink } from '@store'
import { selectSubmissionTrackingProps, trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import { fetchCampaignBillings } from '@store/billings'
import { fetchCampaign } from '@store/campaigns'
import { selectCurrentSubmissionFilter } from '@store/router'
import {
  fetchSubmissions,
  ISubmission,
  preApprove,
  PreApproveActionTypes,
  preApproveError,
  preApproveSuccess,
  selectPreApproveLink,
  updateFilterSubmissionIds,
} from '@store/submissions'
import { Toaster } from '@tribegroup'
import { handleSecureApproveRequest } from './approve'

export function* handlePreApprove(action: ReturnType<typeof preApprove>) {
  try {
    const { campaignId, submissionId } = action.payload
    const authToken: string = yield select(selectAuthToken)

    const approveLink: IHateoasLink = yield select(selectPreApproveLink, submissionId)

    const submission: ISubmission = yield call(
      handleSecureApproveRequest,
      action,
      authToken,
      approveLink,
    )

    const properties = yield select(selectSubmissionTrackingProps, campaignId, submissionId)
    yield put(trackEvent(EventTrackingNames.PreApproveSubmission, properties))

    yield put(
      updateFilterSubmissionIds(campaignId, submissionId, [INBOX_FILTER, SHORTLISTED_FILTER]),
    )
    yield put(preApproveSuccess(submission, campaignId))

    yield call(Toaster.createToast, ToastTemplateSubmissionMoved, {
      newStatus: SubmissionStatuses.PreApproved,
    })

    const filter = yield select(selectCurrentSubmissionFilter, campaignId)

    yield put(fetchSubmissions(campaignId, { filter }))
    yield put(fetchCampaign(campaignId))

    yield call(handleFetchInfluencerIdentity, campaignId, submission.id)
    yield put(fetchCampaignBillings())
  } catch (error) {
    yield put(preApproveError(error))
  }
}

export function* watchPreApproveRequest() {
  yield takeEvery(PreApproveActionTypes.PREAPPROVE_REQUEST, handlePreApprove)
}
