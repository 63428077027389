import { FormattedMessage } from 'react-intl'

import { CostBreakdownRow } from '@components/UI/CostBreakdown'
import { ISubmission } from '@store/submissions'

interface IProps {
  submission: ISubmission
  showCurrency?: boolean
}

const SubmissionCostBreakdownSocialTotalPrice: React.FC<IProps> = ({
  submission,
  showCurrency,
}) => {
  const {
    total_amount_before_tax_cents,
    total_amount_before_tax_currency,
    tax_display_name,
  } = submission

  return (
    <CostBreakdownRow
      label={
        <FormattedMessage
          id="core.text.totalCurrencyExTaxName"
          values={{
            taxName: tax_display_name,
            currency: showCurrency ? total_amount_before_tax_currency : '',
          }}
        />
      }
      medium
      labelWidth={9}
      currency={total_amount_before_tax_currency!}
      quantity={total_amount_before_tax_cents!}
    />
  )
}

export default SubmissionCostBreakdownSocialTotalPrice
