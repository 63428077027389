import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js'

import { IMe, SetupCardActionTypes } from '@store/me'

export const setUpCard = (
  stripe: Stripe,
  element: StripeCardNumberElement,
  onSuccess?: () => void,
) => ({
  payload: { stripe, element, onSuccess },
  type: SetupCardActionTypes.SETUP_REQUEST,
})

export const setUpCardSuccess = (me: IMe) => ({
  payload: me,
  type: SetupCardActionTypes.SETUP_SUCCESS,
})

export const setUpCardError = (error: any) => ({
  payload: error,
  type: SetupCardActionTypes.SETUP_ERROR,
})
