import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import Image from '@components/UI/Image'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { TRIBE_HOW_TO_INVITE_CREATORS } from '@constants'
import { IModalComponentProps } from '@context/Modal'
import { EventTrackingNames } from '@enums'
import shareGraphics from '@images/share-graphics.png'
import { IApplicationState } from '@store'
import { selectFirebaseURL } from '@store/campaigns'
import Theme from '@theme'
import { Button, CloseIcon, Container, H3, Header, Link, Modal, Text, Toaster } from '@tribegroup'
import { clipboard } from '@utils'
import CampaignShareModalToast from './Toast'

interface IProps extends IModalComponentProps {
  campaignId: number
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

const CampaignShareModal: React.FC<IInternalProps> = ({
  onRequestClose,
  isOpen = false,
  fierbaseURL,
  campaignId,
}) => {
  const onCopyUrl = async () => {
    if (fierbaseURL) {
      await clipboard.copy(fierbaseURL)
      Toaster.createToast(CampaignShareModalToast)
      onRequestClose()
    }
  }
  return (
    <Modal reactModalProps={{ isOpen }} width={34.625}>
      <Container data-testid="campaign-share-modal" topInnerSpacing={0.5} bottomInnerSpacing={0.5}>
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.ShareCampaignDeeplinkDismiss}
          campaignId={campaignId}
        >
          <CloseIcon onClick={onRequestClose} />
        </ConnectedTrackedRoute>
        <Container outerSpacingAuto width={12.5} height={8.375}>
          <Image src={shareGraphics} />
        </Container>
        <Container
          topOuterSpacing={1.5}
          outerSpacingAuto
          width="100%"
          maxWidth={20}
          textAlign="center"
        >
          <H3 color={Theme.grey900Color}>
            <FormattedMessage id="campaign.share.header" />
          </H3>
        </Container>
        <Container
          topOuterSpacing={0.75}
          outerSpacingAuto
          width="100%"
          maxWidth={21.125}
          bottomOuterSpacing={1.75}
          textAlign="center"
        >
          <Text color={Theme.grey800Color}>
            <FormattedMessage id="campaign.share.body" />
          </Text>
        </Container>
        <Container bottomInnerSpacing={1.625}>
          <Flex center column>
            <ConnectedTrackedRoute
              campaignId={campaignId}
              eventName={EventTrackingNames.ShareCampaignDeeplinkCopyURL}
            >
              <Button
                data-testid="campaign-share-copy-link"
                scheme="primary"
                height={3}
                width={14}
                noFocus
                onClick={onCopyUrl}
              >
                <Header cta>
                  <FormattedMessage id="campaign.share.copyLink" />
                </Header>
              </Button>
            </ConnectedTrackedRoute>
            <Container topOuterSpacing={1}>
              <ConnectedTrackedRoute
                campaignId={campaignId}
                eventName={EventTrackingNames.ShareCampaignDeeplinkLearnMore}
              >
                <Link
                  href={TRIBE_HOW_TO_INVITE_CREATORS}
                  target="_blank"
                  color={Theme.secondaryColor}
                >
                  <Text color={Theme.primaryColor}>
                    <FormattedMessage id="core.text.learnMore.withArrow" />
                  </Text>
                </Link>
              </ConnectedTrackedRoute>
            </Container>
          </Flex>
        </Container>
      </Container>
    </Modal>
  )
}

const mapStateToProps = (state: IApplicationState, { campaignId }: IProps) => ({
  fierbaseURL: selectFirebaseURL(state, campaignId),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(connector)(CampaignShareModal)
