import React from 'react'

import { FormattedMessage } from 'react-intl'

import { NoticeEmpty } from '@components/UI/Notice'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import SubmissionCardListEmptyBrandedContentAdEducationLink from './EducationLink'
import SubmissionCardListEmptyBrandedContentAdImage from './Image'

const SubmissionCardListEmptyBrandedContentAd: React.FC = () => {
  return (
    <NoticeEmpty
      alt="branded-content-ad-submissions"
      title={
        <Container maxWidth={22.75}>
          <FormattedMessage id="inbox.cardlist.empty.bca.title" />
        </Container>
      }
      subtitle={
        <Container maxWidth={28.75}>
          <FormattedMessage id="inbox.cardlist.empty.bca.subtitle1" />
          <Container topOuterSpacing={0.75} textAlign="center">
            <Text light color={Theme.grey800Color}>
              <FormattedMessage id="inbox.cardlist.empty.bca.subtitle2" />
            </Text>
          </Container>
        </Container>
      }
      education={<SubmissionCardListEmptyBrandedContentAdEducationLink />}
      image={<SubmissionCardListEmptyBrandedContentAdImage alt="branded-content-ad-submissions" />}
    />
  )
}

export default SubmissionCardListEmptyBrandedContentAd
