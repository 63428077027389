import { WatcherFunction } from '..'
import { watchAutoSwitchRequest } from './autoSwitch'
import { watchChangePasswordRequest } from './changePassword'
import { watchCreateSetupIntentRequest } from './createSetupIntent'
import { watchFetchMeRequest } from './me'
import { watchSetupCardRequest } from './setupCard'
import { watchUpateContactRequest } from './updateContact'
import { watchUpateCreditCardRequest } from './updateCreditCard'
import { watchUpatePreferencesRequest } from './updatePreferences'

export const meSagas: ReadonlyArray<WatcherFunction> = [
  watchAutoSwitchRequest,
  watchChangePasswordRequest,
  watchFetchMeRequest,
  watchUpateContactRequest,
  watchUpateCreditCardRequest,
  watchCreateSetupIntentRequest,
  watchSetupCardRequest,
  watchUpatePreferencesRequest,
]
