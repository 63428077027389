import { createRef, useEffect, useState } from 'react'

import PlaceholderImage from '@images/placeholder-image.png'
import { IPorfolioObject } from '@store/creator-bio'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'
import { isMobileDevice } from '@utils'
import { PortfolioVideoTrigger, PortolioVideo } from './Video.styled'

interface ICreatorPortfolioGalleryVideoProps {
  portfolioObject: IPorfolioObject
  isActive?: boolean
}

const CreatorPortfolioGalleryVideo: React.FC<ICreatorPortfolioGalleryVideoProps> = ({
  portfolioObject,
  isActive,
}) => {
  const [showControls, setShowControls] = useState(false)
  const [posterSrc, setPosterSrc] = useState(portfolioObject.thumbnail_url)
  const [hasError, setHasError] = useState(false)
  const videoRef = createRef<HTMLVideoElement>()
  const isMobile = isMobileDevice()

  const hideOverlay = () => {
    const video = videoRef.current
    if (!video) {
      return
    }
    setShowControls(true)
    video.play()

    if (isMobile && video.requestFullscreen) {
      const handleScreenChange = () => {
        const { fullscreenElement } = document
        if (!fullscreenElement) {
          setShowControls(false)
          video.removeEventListener('fullscreenchange', handleScreenChange)
          video.pause()
        }
      }
      video.requestFullscreen().then(() => {
        video.addEventListener('fullscreenchange', handleScreenChange, false)
      })
    }
  }

  const handleOnError = () => {
    setPosterSrc(PlaceholderImage)
    setHasError(true)
  }

  useEffect(() => {
    if (!videoRef.current) {
      return
    }
    if (!isActive && !videoRef.current.paused) {
      videoRef.current.pause()
    }
  }, [isActive])

  return (
    <Container relative height={hasError ? 14 : '100%'}>
      <PortolioVideo
        ref={videoRef}
        src={portfolioObject.original_url}
        poster={posterSrc}
        controls={showControls}
        onError={handleOnError}
      />
      {!showControls && !hasError && (
        <Container flexCenter absolute leftPosition={0} topPosition={0} width="100%" height="100%">
          <PortfolioVideoTrigger onClick={hideOverlay} data-testid="video-trigger">
            <Icon glyph="play" color={Theme.whiteColor} size={isMobile ? 2.25 : 4} />
          </PortfolioVideoTrigger>
        </Container>
      )}
    </Container>
  )
}

export default CreatorPortfolioGalleryVideo
