import styled from 'styled-components'

export const TitleWrapper = styled.div`
  height: 3rem;

  .clamp-lines > div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
