import { createAction } from '@reduxjs/toolkit'

import { PCRChannels } from '@enums'
import { ITikTokMetricsResponse, TikTokMetricsActionTypes } from '.'

export const fetchTikTokMetrics = createAction(
  TikTokMetricsActionTypes.FETCH_REQUEST,
  (campaignId: number, channel: PCRChannels) => ({
    payload: {
      campaignId,
      channel,
    },
  }),
)

export const fetchTikTokMetricsSuccess = createAction(
  TikTokMetricsActionTypes.FETCH_SUCCESS,
  (tiktokMetrics: ITikTokMetricsResponse, campaignId: number, channel: PCRChannels) => ({
    payload: {
      tiktokMetrics,
      campaignId,
      channel,
    },
  }),
)

export const fetchTikTokMetricsError = createAction(
  TikTokMetricsActionTypes.FETCH_ERROR,
  (error) => ({
    payload: error,
  }),
)
