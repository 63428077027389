import { FormattedMessage } from 'react-intl'

import { ClickableInformation } from '@components/UI/Information'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { EventTrackingNames } from '@enums'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import SubmissionCardHeaderBrandMatchContent from './Content'

interface IProps {
  brandMatchPct: number
  submissionId: number
  campaignId: number
}

const SubmissionCardHeaderBrandMatch = ({ brandMatchPct, submissionId, campaignId }: IProps) => {
  return (
    <ClickableInformation
      width={18.125}
      leftOffset={5.5}
      target={
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.BrandMatchTooltip}
          submissionId={submissionId}
          campaignId={campaignId}
          eventTrigger="click"
        >
          <Container
            innerSpacing={0.1875}
            flexCenter
            borderColor={Theme.primary100Color}
            borderWidth={0.0625}
            borderRadius={0.25}
            borderStyle="solid"
          >
            <Text uppercase light xxsmall color={Theme.primaryColor}>
              <FormattedMessage
                id="submission.card.action.header.brandMatchPct"
                values={{ brandMatchPct }}
              />
            </Text>
          </Container>
        </ConnectedTrackedRoute>
      }
    >
      <SubmissionCardHeaderBrandMatchContent submissionId={submissionId} campaignId={campaignId} />
    </ClickableInformation>
  )
}

export default SubmissionCardHeaderBrandMatch
