import { IResponseError } from '@lib/error'
import { IHateoasLink } from '..'

export interface IPurchase {
  id: number
  submission_id: number
  purchase_number: string
  purchased_at: string
  payment_method: string
  brand_amount_before_tax_cents: number
  brand_amount_before_tax_currency: string
  brand_tax_cents: number
  brand_tax_currency: string
  brand_amount_cents: number
  brand_amount_currency: string
  links?: ReadonlyArray<IHateoasLink>
  media_object_id?: number
  purchase_order_numbers?: string
}

export interface IBilling {
  id: number
  name: string
  brand_amount_cents: number
  brand_amount_currency: string
  status: string
  purchases: ReadonlyArray<IPurchase>
  links?: ReadonlyArray<IHateoasLink>
  payment_method: string
}

export interface IBillingState {
  readonly billingsByCampaignId: Record<string, IBilling>
  readonly purchaseIdsByMonthInContentLibrary: Record<string, ReadonlyArray<number>>
  readonly purchasesById: Record<number, IPurchase>
  readonly purchaseIdsByMonthPerCampaignId: Record<number, Record<string, ReadonlyArray<number>>>
  readonly isFetching?: boolean
  readonly linksByBillingType: Record<string, ReadonlyArray<IHateoasLink>>
  readonly errors?: IResponseError
}

export enum CampaignBillingActionTypes {
  FETCH_REQUEST = 'campaignBillings/FETCH_REQUEST',
  FETCH_SUCCESS = 'campaignBillings/FETCH_SUCCESS',
  FETCH_ERROR = 'campaignBillings/FETCH_ERROR',
}

export enum ContentLibraryBillingActionTypes {
  FETCH_REQUEST = 'contentLibraryBillings/FETCH_REQUEST',
  FETCH_SUCCESS = 'contentLibraryBillings/FETCH_SUCCESS',
  FETCH_ERROR = 'contentLibraryBillings/FETCH_ERROR',
}

export enum ContentLibraryBillingUsingNextActionTypes {
  FETCH_REQUEST = 'contentLibraryBillingsUsingNext/FETCH_REQUEST',
  FETCH_SUCCESS = 'contentLibraryBillingsUsingNext/FETCH_SUCCESS',
  FETCH_ERROR = 'contentLibraryBillingsUsingNext/FETCH_ERROR',
}

export enum BillingFullReportActionTypes {
  DOWNLOAD_FULL_REPORT_REQUEST = 'billings/DOWNLOAD_FULL_REPORT_REQUEST',
  DOWNLOAD_FULL_REPORT_SUCCESS = 'billings/DOWNLOAD_FULL_REPORT_SUCCESS',
  DOWNLOAD_FULL_REPORT_ERROR = 'billings/DOWNLOAD_FULL_REPORT_ERROR',
}
