import React from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import InformationContent from '@components/UI/Information/Content/Content'
import UpsellInformation from '@components/Upsell/Information/Information'
import { EventTrackingNames, FeatureToggles } from '@enums'
import { IApplicationState } from '@store'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

interface IAnalyticsDetailCardTooltip {
  title: React.ReactNode
  content: React.ReactNode
  productMetricGroup?: string
  productMetricValue?: string
  locked?: boolean
  externalLink?: string
  showAsterisk?: boolean
}

interface IInternalProps extends IAnalyticsDetailCardTooltip {
  campaignId: number
}

const AnalyticsDetailCardTooltip: React.FC<IInternalProps> = ({
  title,
  content,
  locked,
  externalLink,
  productMetricValue,
  productMetricGroup,
  campaignId,
  showAsterisk,
}) => {
  const isXEnabled = useFlag(FeatureToggles.X_SOCIAL_PLATFORM_ENABLED)
  return (
    <React.Fragment>
      <InformationContent
        titleColor={locked ? Theme.grey900Color : Theme.primaryColor}
        title={title}
        content={content}
      />

      {showAsterisk && (
        <Container topOuterSpacing={0.5}>
          <Container absolute leftPosition={0.75}>
            <Text medium color={Theme.grey900Color} customFontSize={0.8125}>
              <FormattedMessage id="core.text.symbol.attention" />
            </Text>
          </Container>
          <Text color={Theme.grey900Color} customFontSize={0.8125}>
            <FormattedMessage
              id={
                isXEnabled
                  ? 'analytics.shortlistSummary.tooltipDisclaimer'
                  : 'analytics.shortlistSummary.instagramOnlyMetric'
              }
            />
          </Text>
        </Container>
      )}

      {locked && externalLink && (
        <UpsellInformation
          textInformationMaxWidth={9.75}
          textInformation={<FormattedHTMLMessage id="upsell.information.unlock" />}
          externalLink={externalLink}
          trackingProps={{
            eventName: EventTrackingNames.SubscriptionLearnMore,
            additionalProperties: {
              product_metric_group: productMetricGroup,
              product_metric: productMetricValue,
            },
            campaignId,
          }}
        />
      )}
    </React.Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => ({
  campaignId: selectCampaignId(match)!,
})

export default compose<IInternalProps, IAnalyticsDetailCardTooltip>(
  withRouter,
  connect(mapStateToProps),
)(AnalyticsDetailCardTooltip)
