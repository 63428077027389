import {
  CreateBrandFanGroupActionTypes,
  IBrandFanEditableFields,
  IBrandFanGroup,
} from '@store/brandFans'

export const createBrandFanGroup = (
  identityId: number,
  campaignId?: number,
  submissionId?: number,
  params?: IBrandFanEditableFields,
  influencerId?: number,
) => ({
  payload: { identityId, campaignId, submissionId, params, influencerId },
  type: CreateBrandFanGroupActionTypes.CREATE_REQUEST,
})

export const createBrandFanGroupSuccess = (brandFanGroup: IBrandFanGroup) => ({
  payload: brandFanGroup,
  type: CreateBrandFanGroupActionTypes.CREATE_SUCCESS,
})

export const createBrandFanGroupError = (error: any) => ({
  payload: error,
  type: CreateBrandFanGroupActionTypes.CREATE_ERROR,
})
