import { createContext, PureComponent } from 'react'

import { connect } from 'react-redux'
import { RouterProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import {
  fetchCampaign,
  ICampaign,
  saveCampaign,
  selectCampaign,
  selectCampaignIsSaving,
  selectIsSocialCampaign,
} from '@store/campaigns'

export interface ICampaignContextProps {
  saveCampaign?: typeof saveCampaign
  campaign: ICampaign
  isCampaignFetched: boolean
  isSaving: boolean
  isOpen?: boolean
  isSocialCampaign?: boolean
}

interface IInternalProps extends RouterProps, ICampaignContextProps {
  match: any
  campaignId: number
  fetchCampaign: typeof fetchCampaign
}

export const CampaignContext = createContext({ campaign: {} } as ICampaignContextProps)

export class CampaignProvider extends PureComponent<IInternalProps> {
  componentDidMount() {
    const { campaignId, history } = this.props
    if (campaignId) {
      this.props.fetchCampaign(this.props.campaignId, {
        history,
      })
    }
  }

  render() {
    const {
      campaign,
      saveCampaign: saveCampaignAction,
      isCampaignFetched,
      children,
      isSaving,
      isSocialCampaign,
    } = this.props

    const contextProps: ICampaignContextProps = {
      campaign,
      isCampaignFetched,
      saveCampaign: saveCampaignAction,
      isSaving,
      isSocialCampaign,
    }

    return <CampaignContext.Provider value={contextProps}>{children}</CampaignContext.Provider>
  }
}

const mapStateToProps = (state: IApplicationState, { match }) => {
  const campaignId = parseInt(match.params.campaignId, 10)
  const campaign = selectCampaign(state, campaignId)
  return {
    campaign,
    campaignId,
    isCampaignFetched: Boolean(campaign),
    isSaving: selectCampaignIsSaving(state),
    isSocialCampaign: selectIsSocialCampaign(state, campaignId),
  }
}

const mapDispatchToProps = {
  fetchCampaign,
  saveCampaign,
}

export default compose<IInternalProps, any>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CampaignProvider)
