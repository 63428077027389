import React, { FC } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { DownloadButton, LicensingAgreementsLink } from '@components/Licensing'
import MediaObjectLicensingLicensedUploadToFacebookAdsManager from '@components/MediaObject/Licensing/Licensed/UploadToFacebookAdsManager'
import StripeError from '@components/UI/Error/StripeError'
import { ApprovalType } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import {
  IMediaObject,
  selectFirstErrorCode,
  selectHasInsufficientFundError,
  selectMediaObjectErrors,
  selectMediaObjectIsRequestingLicense,
  selectUploadToFacebookLink,
} from '@store/mediaObjects'
import { selectPreapprovalAmountPercentage } from '@store/rootResource'
import Theme from '@theme'
import { Button, Container, Header, Text } from '@tribegroup'

interface IProps {
  onClick: VoidFunction
  mediaObject: IMediaObject
  approvalType?: ApprovalType
  disableSubmit?: boolean
}

export const PurchaseLicenseFooter: FC<IProps> = ({
  onClick,
  mediaObject,
  approvalType,
  disableSubmit,
}) => {
  const {
    hasInsufficientFundError,
    errorCode,
    isRequesting,
    hasFacebookAdUploadLink,
    preapprovalPercentage,
    hasError,
  } = useSelector((state: IApplicationState) => {
    return {
      hasInsufficientFundError: selectHasInsufficientFundError(state),
      errorCode: selectFirstErrorCode(state),
      isRequesting: selectMediaObjectIsRequestingLicense(state),
      hasFacebookAdUploadLink: Boolean(selectUploadToFacebookLink(state, mediaObject.id)),
      preapprovalPercentage: selectPreapprovalAmountPercentage(state),
      hasError: Boolean(selectMediaObjectErrors(state)),
    }
  })

  const showGenericError = hasError && !hasInsufficientFundError && !errorCode

  let translationKey = ''

  if (approvalType === 'final-approval') {
    translationKey = 'submission.card.action.licensecontent.licenseContent'
  } else if (approvalType === 'pre-approval') {
    translationKey = 'submission.card.action.licensecontent.acceptPreapproval'
  }

  return (
    <Container topOuterSpacing={2.5}>
      <Container bottomOuterSpacing={1.5}>
        {!hasInsufficientFundError && errorCode && <StripeError errorCode={errorCode} />}
        {showGenericError && (
          <Text color={Theme.errorColor} small>
            <FormattedMessage id="core.text.shortGenericError" />
          </Text>
        )}
      </Container>

      {disableSubmit && (
        <Container bottomOuterSpacing={1.5}>
          <Text color={Theme.errorColor} small>
            <FormattedMessage id="licenseBooklet.mediaTypeDisclaimer" />
          </Text>
        </Container>
      )}

      {mediaObject?.transacted_license?.status === 'licensed' ? (
        <DownloadButton mediaObjectId={mediaObject.id}>
          <FormattedMessage id="licensing.licensed.cta" />
        </DownloadButton>
      ) : (
        <Button
          fullWidth
          scheme="primary"
          disabled={isRequesting || disableSubmit}
          loading={isRequesting}
          onClick={onClick}
          bottomOuterSpacing={1.25}
          height={3}
        >
          {mediaObject.transacted_license?.status === 'requested' ? (
            <Header cta>
              <FormattedMessage id="core.text.okay" />
            </Header>
          ) : (
            <Header cta>
              <FormattedMessage id={translationKey} values={{ preapprovalPercentage }} />
            </Header>
          )}
        </Button>
      )}

      <LicensingAgreementsLink color={Theme.primaryColor} />

      {hasFacebookAdUploadLink && (
        <Container topOuterSpacing={1.25} flexCenter>
          <MediaObjectLicensingLicensedUploadToFacebookAdsManager mediaObjectId={mediaObject.id} />
        </Container>
      )}
    </Container>
  )
}
