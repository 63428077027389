import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'

import Confirmation from '@components/UI/Confirmation'
import { FooterButtonWrapper } from '@components/UI/Confirmation/Confirmation.styled'
import { Button } from '@tribegroup'

interface ICampaignCardModalPauseProps {
  onConfirm: VoidFunction
  onCancel: VoidFunction
  isOpen: boolean
}

export const CampaignCardModalPause = ({
  onConfirm,
  onCancel,
  isOpen,
}: ICampaignCardModalPauseProps) => {
  return (
    <Confirmation
      title={<FormattedMessage id="campaign.campaignCard.menuAction.pause.confirmation.title" />}
      content={<FormattedMessage id="campaign.campaignCard.menuAction.pause.confirmation.body" />}
      footerButtons={
        <Fragment>
          <FooterButtonWrapper>
            <Button
              scheme="primary"
              quiet
              onClick={onCancel}
              data-cy-element="pause-modal-cancel-button"
            >
              <FormattedMessage id="core.text.cancel" />
            </Button>
          </FooterButtonWrapper>
          <FooterButtonWrapper>
            <Button scheme="primary" onClick={onConfirm} data-cy-element="pause-modal-pause-button">
              <FormattedMessage id="core.text.pause" />
            </Button>
          </FooterButtonWrapper>
        </Fragment>
      }
      isOpen={isOpen}
      onCloseIconClick={onCancel}
    />
  )
}

export default CampaignCardModalPause
