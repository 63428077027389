import { FormattedMessage } from 'react-intl'

import Breakpoint from '@components/UI/Breakpoint'
import Theme from '@theme'
import { CircleSkeleton, Container, H2, Icon, LineSkeleton, Text } from '@tribegroup'
import { BioContentContainer, InfluencerMemberSince, Separator } from './Details.styled'

const CreatorDetailsLoadingState = () => (
  <Container data-testid="creator-details-loading" flexCenter flexColumn bottomOuterSpacing={5}>
    <CircleSkeleton outerSpacing={1.25} />
    <H2 color={Theme.grey600Color} bottomOuterSpacing={0.5}>
      <FormattedMessage id="creatorPortfolio.bio.creatorsName" />
    </H2>
    <Container flexCenter>
      <Icon glyph="location-pin" size={1.5} color={Theme.defaultColor} />
      <Container leftOuterSpacing={0.5}>
        <Text color={Theme.grey600Color}>
          <FormattedMessage id="creatorPortfolio.bio.location" />
        </Text>
      </Container>
    </Container>
    <Container outerSpacing={2}>
      <Breakpoint mobile>
        <InfluencerMemberSince width={13} innerSpacing={0.5}>
          <LineSkeleton />
        </InfluencerMemberSince>
      </Breakpoint>
      <Container flexCenter>
        <LineSkeleton width={2} />
        <Separator />
        <LineSkeleton width={6} />
      </Container>
    </Container>
    <BioContentContainer flexCenter>
      <LineSkeleton bottomOuterSpacing={0.5} />
      <LineSkeleton bottomOuterSpacing={0.5} />
      <LineSkeleton bottomOuterSpacing={0.5} />
      <LineSkeleton width={'50%'} />
    </BioContentContainer>
  </Container>
)

export default CreatorDetailsLoadingState
