import React, { PropsWithChildren } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import SummaryNavigation, { ISummaryNavigationProps } from '@components/UI/SummaryNavigation'
import { IInfluencerIdentityIdContextProps } from '@context/InfluencerIdentityId'
import { EventTrackingNames } from '@enums'
import { withContextInfluencerIdentityId, withSubmissionListFilter } from '@hocs'
import { IApplicationState } from '@store'
import { selectCampaignId, selectSubmissionId } from '@store/router'
import {
  selectInfluencerIdentityNextSubmissionId,
  selectInfluencerIdentityPrevSubmissionId,
} from '@store/submissions'
import { buildSubmissionUrlById } from '@utils/urls'

export interface IInternalProps extends ISummaryNavigationProps {
  prevSubmissionId?: number
  nextSubmissionId?: number
  campaignId: number
}

export const SubmissionSummaryNavigationByInfluencerIdentityId: React.FC<PropsWithChildren<
  IInternalProps
>> = ({
  prevLink,
  nextLink,
  currentId,
  campaignId,
  children,
  nextSubmissionId,
  prevSubmissionId,
}) => {
  return (
    <SummaryNavigation
      prevLink={prevLink}
      nextLink={nextLink}
      currentId={currentId}
      nextTrackingProps={{
        campaignId,
        eventName: EventTrackingNames.ViewSubmission,
        submissionId: nextSubmissionId,
      }}
      prevTrackingProps={{
        campaignId,
        eventName: EventTrackingNames.ViewSubmission,
        submissionId: prevSubmissionId,
      }}
      loadingMore={false}
    >
      {children}
    </SummaryNavigation>
  )
}

interface IStateProps
  extends RouteComponentProps,
    IInfluencerIdentityIdContextProps,
    RouteComponentProps {}

const mapStateToProps = (
  state: IApplicationState,
  { contextInfluencerIdentityId, match }: IStateProps,
) => {
  const campaignId = selectCampaignId(match) as number
  const submissionId = selectSubmissionId(match) as number
  const prevSubmissionId = selectInfluencerIdentityPrevSubmissionId(
    state,
    campaignId,
    contextInfluencerIdentityId,
    submissionId,
  )
  const nextSubmissionId = selectInfluencerIdentityNextSubmissionId(
    state,
    campaignId,
    contextInfluencerIdentityId,
    submissionId,
  )
  return {
    currentId: submissionId,
    campaignId,
    prevSubmissionId,
    nextSubmissionId,
    prevLink: buildSubmissionUrlById(campaignId, prevSubmissionId),
    nextLink: buildSubmissionUrlById(campaignId, nextSubmissionId),
  }
}

export default compose<IInternalProps, {}>(
  withRouter,
  withSubmissionListFilter,
  withContextInfluencerIdentityId,
  connect(mapStateToProps),
)(SubmissionSummaryNavigationByInfluencerIdentityId)
