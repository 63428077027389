import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import InfluencerIdentityLabels from '@components/Inbox/InfluencerIdentity/Labels'
import { IInfluencerIdentityIdContextProps } from '@context/InfluencerIdentityId'
import { withContextInfluencerIdentityId } from '@hocs/withContextInfluencerIdentityId'
import { IApplicationState } from '@store'
import { selectCampaignBrandName } from '@store/campaigns'
import {
  selectIsInfluencerIdentityNew,
  selectIsInfluencerIdentityPreviouslyPurchased,
} from '@store/influencerIdentities'
import { selectCampaignId } from '@store/router'

interface IInternalProps {
  isPreviouslyPurchased: boolean
  isNew: boolean
  hasAnalytics: boolean
  brandName: string
}

export const InboxInfluencerIdentityRowLabels = ({
  brandName,
  isNew,
  isPreviouslyPurchased,
}: IInternalProps) => {
  return (
    <InfluencerIdentityLabels
      spacing={0.9375}
      brandName={brandName}
      showNew={isNew}
      showPreviouslyPurchased={isPreviouslyPurchased}
      showTalentAgencyCreatorTag
    />
  )
}

interface IStateProps extends IInfluencerIdentityIdContextProps, RouteComponentProps {}

const mapStatetoProps = (
  state: IApplicationState,
  { match, contextInfluencerIdentityId }: IStateProps,
) => {
  const campaignId = selectCampaignId(match)!
  return {
    brandName: selectCampaignBrandName(state, campaignId),
    isNew: selectIsInfluencerIdentityNew(state, campaignId, contextInfluencerIdentityId),
    isPreviouslyPurchased: selectIsInfluencerIdentityPreviouslyPurchased(
      state,
      campaignId,
      contextInfluencerIdentityId,
    ),
  }
}

export default compose<IInternalProps, {}>(
  withContextInfluencerIdentityId,
  withRouter,
  connect(mapStatetoProps),
)(InboxInfluencerIdentityRowLabels)
