import { ITransactedLicense } from '@store/mediaObjects'
import Theme from '@theme'
import { FormGroup, Radio, SelectionSubtext } from '@tribegroup'

interface IProps {
  transactedLicense: ITransactedLicense
}

export const SubmissionCardModalLicenseSelected = ({ transactedLicense }: IProps) => {
  return (
    <FormGroup>
      <Radio
        disablePointerOnHover
        showSelectedAsCheck
        scheme="primary"
        label={transactedLicense.label}
        labelCapitalize
        backgroundColor={Theme.primary200Color}
        clearBorder
        defaultChecked
      />
      <SelectionSubtext>{transactedLicense.description}</SelectionSubtext>
    </FormGroup>
  )
}
