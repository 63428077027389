import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Header, Icon } from '@tribegroup'
import CampaignPreviewBlock from '../Block'
import { BlockText } from '../Block/Block.styled'
import { CheckIconWrapper, DontsListWrapper, ListItem, XIconWrapper } from './DosAndDonts.styled'

export interface ICampaignPreviewDosAndDontsProps {
  dos: ReadonlyArray<string>
  donts: ReadonlyArray<string>
}

const CampaignPreviewDosAndDonts = ({ dos = [], donts = [] }: ICampaignPreviewDosAndDontsProps) => {
  const hasDos = dos.length > 0
  const hasDonts = donts.length > 0
  return hasDos || hasDonts ? (
    <CampaignPreviewBlock>
      {hasDos && (
        <Fragment>
          <Header small color={Theme.grey900Color} uppercase cta bottomOuterSpacing={0.125}>
            <FormattedMessage id="campaign.preview.do.header" />
          </Header>
          <ul>
            {dos.map((rule, idx) => (
              <ListItem key={`rule-${rule}-${idx}`}>
                <CheckIconWrapper alignCenter justifyCenter inline>
                  <Icon glyph="tick-heavy" color={Theme.successColor} size={0.7} />
                </CheckIconWrapper>
                <BlockText small color={Theme.grey800Color}>
                  {rule}
                </BlockText>
              </ListItem>
            ))}
          </ul>
        </Fragment>
      )}
      {hasDonts && (
        <DontsListWrapper>
          <Header small color={Theme.grey900Color} uppercase cta bottomOuterSpacing={0.125}>
            <FormattedMessage id="campaign.preview.dont.header" />
          </Header>
          <ul>
            {donts.map((rule, idx) => (
              <ListItem key={`rule-${rule}-${idx}`}>
                <XIconWrapper alignCenter justifyCenter inline>
                  <Icon glyph="cross-heavy" color={Theme.alertColor} size={0.7} />
                </XIconWrapper>
                <BlockText small color={Theme.grey800Color}>
                  {rule}
                </BlockText>
              </ListItem>
            ))}
          </ul>
        </DontsListWrapper>
      )}
    </CampaignPreviewBlock>
  ) : null
}

export default CampaignPreviewDosAndDonts
