import React, { PropsWithChildren } from 'react'

import { compose } from 'recompose'

import { IModalContextProps } from '@context/Modal'
import { withModal } from '@hocs'
import { Container } from '@tribegroup'
import BuilderBudgetTargetAudienceConfirmation from './Confirmation'

interface IProps {
  onConfirm?: VoidFunction
  disabled: boolean
}

interface IInternalProps extends IProps, IModalContextProps {}

const BuilderBudgetTargetAudienceUnsetOptionContainer: React.FC<PropsWithChildren<
  IInternalProps
>> = ({ children, disabled, onConfirm, showModal }) => {
  const onShowDialog = () => showModal(BuilderBudgetTargetAudienceConfirmation, { onConfirm })
  return (
    <Container
      absolute
      width="100%"
      height="100%"
      zIndex={1}
      pointer={!disabled}
      onClick={!disabled ? onShowDialog : undefined}
      data-testid="target-audience-unset-option-container"
    >
      {children}
    </Container>
  )
}

export default compose<IInternalProps, PropsWithChildren<IProps>>(withModal)(
  BuilderBudgetTargetAudienceUnsetOptionContainer,
)
