import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'

import { PanelWrapper, TextareaErrorMessageWrapper } from '@components/Builder/Layout/Layout.styled'
import Theme from '@theme'
import { FormGroup, FormInput, H4, Panel, Text, TextArea } from '@tribegroup'

interface IBuilderFieldTextArea {
  disabled?: boolean
  maxLength?: number
  value?: string
  height?: number
  required?: boolean
  name: string
  placeholderKey?: string
  moduleName: string
  validationResults: any
  additionalTexts?: ReadonlyArray<string>
  onChange?: (event: React.FormEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: React.FormEvent<HTMLTextAreaElement>) => void
  onFocus?: (eventorName: React.FormEvent<HTMLInputElement> | string) => void
  elementName?: string
}

interface IInternalProps extends IBuilderFieldTextArea, InjectedIntlProps {}

export const BuilderCampaignTextAreaPanel = ({
  disabled,
  maxLength,
  value,
  height,
  name,
  placeholderKey,
  moduleName,
  required,
  onChange,
  onBlur,
  onFocus,
  validationResults,
  additionalTexts,
  intl,
  elementName,
}: IInternalProps) => {
  const validationResult = validationResults[name]
  return (
    <PanelWrapper data-cy-element={elementName}>
      <Panel
        disabled={disabled}
        title={
          <H4 color={Theme.defaultColor}>
            <FormattedMessage id={`builder.${moduleName}.${name}.label`} />
          </H4>
        }
      >
        {additionalTexts &&
          additionalTexts.map((text) => {
            return (
              <div key={`builder.${moduleName}.${name}.${text}`}>
                <Text color={Theme.defaultColor}>
                  <FormattedMessage id={`builder.${moduleName}.${name}.${text}`} />
                </Text>
              </div>
            )
          })}

        <FormGroup outerTopSpacing={additionalTexts ? 1.5 : 0}>
          <FormInput
            validationRule={{
              required: required
                ? {
                    value: true,
                    message: intl.formatMessage({
                      id: `builder.${moduleName}.error.${name}.required`,
                    }),
                  }
                : false,
              maxLength: maxLength
                ? {
                    value: maxLength,
                    message: intl.formatMessage({ id: `builder.${moduleName}.error.max` }),
                  }
                : undefined,
            }}
          >
            <TextArea
              scheme="default"
              placeholder={intl.formatMessage({
                id: `builder.${moduleName}.${placeholderKey || name}.placeholder`,
              })}
              name={name}
              height={height}
              characterLimit={maxLength}
              onChange={onChange}
              onFocus={onFocus as any}
              onBlur={onBlur}
              value={value}
              invalid={validationResult && validationResult.hasError}
            />
          </FormInput>
          {validationResult && validationResult.hasError && (
            <TextareaErrorMessageWrapper>
              <Text small color={Theme.errorColor}>
                {validationResult.errorMessage}
              </Text>
            </TextareaErrorMessageWrapper>
          )}
        </FormGroup>
      </Panel>
    </PanelWrapper>
  )
}

export default injectIntl(BuilderCampaignTextAreaPanel)
