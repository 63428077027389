import React, { PropsWithChildren } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import SummaryNavigation, { INavigationTrackedRouteProps } from '@components/UI/SummaryNavigation'
import { SHAREABLE_CONTENT_LIBRARY } from '@constants'
import { EventTrackingNames } from '@enums'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import {
  fetchShareableContentLibrary,
  selectIsLoadingMoreMediaObjects,
  selectLoadMoreMediaObjects,
  selectNextMediaObjectId,
  selectNextShareableMediaObjectLink,
  selectPrevMediaObjectId,
  selectPrevShareableMediaObjectLink,
} from '@store/mediaObjects'
import { selectMediaObjectId, selectShareableContentLibraryToken } from '@store/router'
import ShareableContentLibraryTrackedRoute from '../TrackedRoute'

interface IInternalProps {
  token: string
  prevLink?: string
  prevMediaObjectId: number
  nextLink?: string
  nextMediaObjectId: number
  isLoadingMore: boolean
  loadMoreMediaObjects: boolean
  fetchShareableContentLibrary: typeof fetchShareableContentLibrary
  mediaObjectId: number
}

const TrackingWrapper: React.FC<PropsWithChildren<INavigationTrackedRouteProps>> = ({
  mediaObjectId,
  children,
  source,
}) => (
  <ShareableContentLibraryTrackedRoute
    eventName={EventTrackingNames.ShareableCLViewContentLibraryItem}
    source={`shareable_cl_${source}`}
    mediaObjectId={mediaObjectId}
  >
    {children}
  </ShareableContentLibraryTrackedRoute>
)

export const ShareableContentLibraryNavigation: React.FC<PropsWithChildren<IInternalProps>> = ({
  children,
  nextLink,
  prevLink,
  mediaObjectId,
  loadMoreMediaObjects,
  prevMediaObjectId,
  nextMediaObjectId,
  token,
  fetchShareableContentLibrary: fetchShareableContentLibraryAction,
}) => {
  hooks.useActionDispatcherEffect(
    Boolean(loadMoreMediaObjects),
    fetchShareableContentLibraryAction,
    [token, true],
    [loadMoreMediaObjects],
  )
  return (
    <SummaryNavigation
      prevLink={prevLink}
      trackingWrapper={TrackingWrapper}
      prevTrackingProps={{
        eventName: EventTrackingNames.ShareableCLViewContentLibraryItem,
        mediaObjectId: prevMediaObjectId,
      }}
      nextTrackingProps={{
        eventName: EventTrackingNames.ShareableCLViewContentLibraryItem,
        mediaObjectId: nextMediaObjectId,
      }}
      nextLink={nextLink}
      currentId={mediaObjectId}
      loadingMore={loadMoreMediaObjects}
    >
      {children}
    </SummaryNavigation>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const mediaObjectId = selectMediaObjectId(match)
  const isLoadingMore = selectIsLoadingMoreMediaObjects(
    state,
    SHAREABLE_CONTENT_LIBRARY,
    mediaObjectId,
  )
  const token = selectShareableContentLibraryToken(match)
  return {
    token,
    mediaObjectId: !isLoadingMore && mediaObjectId,
    prevLink: selectPrevShareableMediaObjectLink(state, mediaObjectId, token),
    nextLink: selectNextShareableMediaObjectLink(state, mediaObjectId, token),
    prevMediaObjectId: selectPrevMediaObjectId(state, SHAREABLE_CONTENT_LIBRARY, mediaObjectId),
    nextMediaObjectId: selectNextMediaObjectId(state, SHAREABLE_CONTENT_LIBRARY, mediaObjectId),
    loadMoreMediaObjects: selectLoadMoreMediaObjects(
      state,
      SHAREABLE_CONTENT_LIBRARY,
      mediaObjectId,
    ),
  }
}

const mapDispatchToProps = {
  fetchShareableContentLibrary,
}

export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ShareableContentLibraryNavigation)
