import { Fragment, useEffect, useState } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, match, RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import ActionIcon from '@components/UI/ActionIcon'
import Drawer from '@components/UI/Drawer'
import LinkifiedString from '@components/UI/LinkifiedString'
import MediaSlider from '@components/UI/MediaSlider'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { WHAT_IS_A_CONTENT_CAMPAIGN } from '@constants'
import { ICampaignContextProps } from '@context/Campaign'
import { EventTrackingNames } from '@enums'
import { withCampaign } from '@hocs'
import { IApplicationState } from '@store'
import { IBrand, selectBrand } from '@store/brands'
import {
  selectCampaignHouseRules,
  selectEditLink,
  selectShowPaidPartnershipHandle as selectCampaignShowPaidPartnershipHandle,
} from '@store/campaigns'
import {
  IDraft,
  selectShowPaidPartnershipHandle as selectDraftShowPaidPartnershipHandle,
} from '@store/drafts'
import Theme from '@theme'
import { Container, Link as TribeLink, Text } from '@tribegroup'
import { generateEditLink } from '@utils'
import { isSocialCampaign } from '@utils/campaign'
import { BlockText } from './Block/Block.styled'
import CampaignPreviewTags from './Tags/Tags'
import CampaignPreviewTitle from './Title/Title'
import CampaignPreviewBlock from './Block'
import CampaignPreviewDosAndDonts from './DosAndDonts'
import CampaignPreviewHeroImage from './HeroImage'
import CampaignPreviewHouseRules from './HouseRules'
import CampaignPreviewInfluencerRequirements from './InfluencerRequirements'
import CampaignPreviewObjective from './Objective'
import { CampaignPreviewPitching } from './Pitching'
import CampaignPreviewPreferredAudience from './PreferredAudience'
import { EmptyImage16x9 } from './Preview.styled'
import { CampaignPreviewSubmissionOptions } from './SubmissionOptions'

interface IMatchParam {
  campaignId: string
  action?: string
}

interface IPreviewProps {
  draft?: IDraft
  open?: boolean
  onClose?: VoidFunction
}

interface IInternalProps
  extends IPreviewProps,
    RouteComponentProps,
    InjectedIntlProps,
    ICampaignContextProps {
  match: match<IMatchParam>
  hasEditPermission?: boolean
  hasBrandPartnershipHandle: boolean
  campaignHouseRules: ReadonlyArray<string>
  brand: IBrand
}

const CampaignPreview = ({
  intl,
  campaign,
  hasEditPermission,
  brand,
  campaignHouseRules,
  open,
  onClose,
}: IInternalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
  const previewBasePath = `/preview`

  useEffect(() => {
    setIsOpen(history.location.pathname.includes(previewBasePath))
  }, [history.location])

  useEffect(() => {
    setIsOpen(Boolean(open))
  }, [open])

  const handleClose = () => {
    if (onClose) {
      return onClose()
    }

    const { pathname, search } = history.location
    history.push(`${pathname.replace('/preview', '')}${search}`)
  }

  return (
    <Drawer
      width={20.75}
      title={intl.formatMessage({ id: 'inbox.preview.header.campaignPreview' })}
      open={isOpen}
      headerRightIcon={
        <ActionIcon
          size={1.5}
          glyph="close-x"
          color={Theme.grey900Color}
          onClick={handleClose}
          elementName="drawer-close-button"
        />
      }
      headerLeftIcon={
        hasEditPermission ? (
          <ConnectedTrackedRoute
            campaignId={campaign.id}
            eventName={EventTrackingNames.CampaignBuilderEditCampaign}
            additionalProperties={{ current_view: 'campaign_preview' }}
          >
            <Link to={generateEditLink(campaign)}>
              <ActionIcon
                size={1.5}
                glyph="edit"
                color={Theme.grey900Color}
                tooltip={intl.formatMessage({ id: 'inbox.titlebar.action.editCampaign' })}
                elementName="drawer-edit-button"
              />
            </Link>
          </ConnectedTrackedRoute>
        ) : null
      }
      wrapperCallback={handleClose}
    >
      <CampaignPreviewHeroImage
        logo={brand ? brand.logo_url : (campaign.brand_logo as string)}
        heroImage={campaign.hero_16x9_original_url}
      />
      <CampaignPreviewTitle
        brandName={brand ? brand.name : (campaign.brand_name as string)}
        productName={campaign.product_name}
        subTitle={campaign.call_to_action}
      />
      {isSocialCampaign(campaign) && !campaign.is_brand_fans && (
        <Fragment>
          <CampaignPreviewInfluencerRequirements campaign={campaign} />
          <CampaignPreviewPreferredAudience campaign={campaign} />
        </Fragment>
      )}
      <CampaignPreviewBlock
        title={<FormattedMessage id="campaign.preview.aboutUs.header" />}
        empty={!campaign.product_text}
        emptyLines={[16.875, 16.25, 15, 10]}
      >
        <BlockText small color={Theme.grey800Color}>
          <LinkifiedString value={campaign.product_text} color={Theme.primaryColor} />
        </BlockText>
      </CampaignPreviewBlock>
      <CampaignPreviewBlock title={<FormattedMessage id="campaign.preview.moodBoard.header" />} />
      {campaign.moodboard_images && campaign.moodboard_images.length > 0 ? (
        <MediaSlider mediaList={campaign.moodboard_images} />
      ) : (
        <EmptyImage16x9 withBottomSpace />
      )}
      <CampaignPreviewObjective text={campaign.objective} />
      <CampaignPreviewPitching
        pitchingEnabled={campaign.pitching_enabled}
        isSocial={isSocialCampaign(campaign)}
      />
      {!isSocialCampaign(campaign) && (
        <CampaignPreviewBlock
          title={<FormattedMessage id="campaign.preview.contentCampaign.header" />}
          emptyLines={[15]}
        >
          <BlockText small color={Theme.grey800Color}>
            <FormattedMessage id="campaign.preview.contentCampaign.text" />
          </BlockText>
          <Container topOuterSpacing={0.5}>
            <TribeLink href={WHAT_IS_A_CONTENT_CAMPAIGN} target="_blank">
              <Text color={Theme.primaryColor} customFontSize={0.8125}>
                <FormattedMessage id="campaign.preview.contentCampaign.learnMore" />
              </Text>
            </TribeLink>
          </Container>
        </CampaignPreviewBlock>
      )}

      {isSocialCampaign(campaign) && <CampaignPreviewSubmissionOptions campaign={campaign} />}

      <CampaignPreviewTags />

      <CampaignPreviewDosAndDonts dos={campaign.dos!} donts={campaign.donts!} />
      <CampaignPreviewBlock
        title={<FormattedMessage id="campaign.preview.howToGetProduct.header" />}
        empty={!campaign.product_where_to_find}
        emptyLines={[12.5, 15]}
      >
        <BlockText small color={Theme.grey800Color}>
          {campaign.product_where_to_find}
        </BlockText>
      </CampaignPreviewBlock>
      <CampaignPreviewBlock
        title={<FormattedMessage id="campaign.preview.campaignCurrency.header" />}
        empty={!campaign.budget_total_currency}
        emptyLines={[15]}
      >
        <BlockText small color={Theme.grey800Color}>
          <FormattedMessage
            id="campaign.preview.campaignCurrency.text"
            values={{ currencyCode: campaign.budget_total_currency }}
          />
        </BlockText>
      </CampaignPreviewBlock>
      <CampaignPreviewHouseRules rules={campaignHouseRules} />
    </Drawer>
  )
}

const mapStateToProps = (state: IApplicationState, { campaign }: IInternalProps) => ({
  hasEditPermission: Boolean(selectEditLink(state, campaign.id)),
  campaignHouseRules: selectCampaignHouseRules(state, campaign.id),
  hasBrandPartnershipHandle: selectCampaignShowPaidPartnershipHandle(state, campaign.id),
  brand: selectBrand(state, campaign.brand_id as number),
})

const mapStateToPropsDraft = (state: IApplicationState, ownProps: IInternalProps) => ({
  hasEditPermission: false,
  campaign: ownProps.draft,
  hasBrandPartnershipHandle: selectDraftShowPaidPartnershipHandle(state),
  brand: ownProps.draft && selectBrand(state, ownProps.draft.brand_id as number),
})

export const CampaignDraftPreview = compose<IInternalProps, IPreviewProps>(
  injectIntl,
  connect(mapStateToPropsDraft),
)(CampaignPreview)

export default compose<IInternalProps, IPreviewProps>(
  withCampaign,
  withRouter,
  injectIntl,
  connect(mapStateToProps),
)(CampaignPreview)
