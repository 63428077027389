import styled from 'styled-components'

import { MoreMenuWrapperVisibleOnHover } from '@components/UI/MoreMenuWrapper'
import Theme from '@theme'

export default styled(MoreMenuWrapperVisibleOnHover)`
  max-width: 18.75rem;
  position: relative;

  a {
    border-radius: 0.5rem;
    overflow: hidden;
    text-decoration: none;
    display: block;
  }
`

export const WrapperStyled = styled.div`
  overflow: hidden;
  border-radius: 0.5rem;

  &:hover {
    box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 10%);
  }
`

export const CampaignCardContent = styled.div`
  background: ${Theme.whiteColor};
  padding: 0.75rem 1rem 1.25rem;
`

export const CampaignCardContentHeader = styled.header`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  gap: 0.25rem;
`

export const MidContentWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.625rem;
`
