import { SideBarContentWrapper, TipImageWrapper } from '@components/UI/Coachmark/Coachmark.styled'
import image1x from '@images/campaignbuilder/cb-influencer-campaign-thumbnail.png'
import image2x from '@images/campaignbuilder/cb-influencer-campaign-thumbnail@2x.png'
import image3x from '@images/campaignbuilder/cb-influencer-campaign-thumbnail@3x.png'
import { TipImage } from './Tip.styled'

const HowToVideosTipSocialCampaign = () => {
  return (
    <SideBarContentWrapper data-testid="how-to-videos-tip-social-campaign">
      <TipImageWrapper>
        <TipImage srcSet={`${image1x} 300w, ${image2x} 768w, ${image3x} 1280w`} alt={image1x} />
      </TipImageWrapper>
    </SideBarContentWrapper>
  )
}

export default HowToVideosTipSocialCampaign
