import React, { PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'

import { themed, useBreakpoint } from '../Theme'

const InlineLabelWrapper = styled.div`
  width: ${({ inlineLabelWidth }) => inlineLabelWidth}rem;
  ${({ inlineLableVerticalSpacing }) => css`
    margin-top: ${inlineLableVerticalSpacing}rem;
    margin-bottom: ${inlineLableVerticalSpacing}rem;
  `};
  flex-shrink: 0;
  flex-grow: 0;
`

const ChildContentWrapper = styled.div`
  display: flex;
  width: 100%;

  ${({ contentWidth }) => css`
    max-width: ${contentWidth}rem;
  `};
  > div:not(:last-of-type) {
    margin-right: ${({ spaceBetween }) => spaceBetween}rem;
  }
`
export interface IFormGroupRowProps {
  /**
   * Spacing between each FormGroups in rem
   *
   * @default 1
   */
  spaceBetween?: number

  /**
   * Form group row width
   *
   * @default undefined
   */
  contentWidth?: number

  /**
   * Disable children stacking on mobile viewport
   *
   * @default false
   */
  disableMobileStacking?: boolean

  /**
   * Disable children stacking on mobile viewport
   *
   * @default false
   */
  inlineLabel?: React.ReactNode

  /**
   * Disable children stacking on mobile viewport
   *
   * @default false
   */
  inlineLabelWidth?: number

  /**
   * label top margin in rem
   */
  inlineLableVerticalSpacing?: number

  /**
   * enable wrapping with inlinelabel
   */
  wrap?: boolean
}

const FormGroupRowWrapper = themed(styled<IFormGroupRowProps, any>('div')`
  ${({ theme, disableMobileStacking, wrap }) => css`
    ${!disableMobileStacking
      ? css`
          display: block;
          ${useBreakpoint(theme, 'phoneUpperBoundary')`
            display: flex;
            align-items: flex-start;
            ${
              wrap &&
              css`
                flex-wrap: wrap;
              `
            };
          `};
        `
      : css`
          display: flex;
          align-items: flex-start;
          ${wrap &&
          css`
            flex-wrap: wrap;
          `};
        `};
  `};
  > div:not(.label-wrapper) {
    &:not(:last-of-type) {
      ${({ spaceBetween }) => css`
        margin-right: ${spaceBetween}rem;
      `};
    }
  }
`)

export default class FormGroupRow extends React.PureComponent<
  PropsWithChildren<IFormGroupRowProps>
> {
  static defaultProps = {
    spaceBetween: 1,
    disableMobileStacking: false,
    inlineLabel: undefined,
    inlineLabelWidth: 6,
    inlineLableVerticalSpacing: 0.25,
  }
  render() {
    const {
      children,
      inlineLableVerticalSpacing,
      inlineLabel,
      inlineLabelWidth,
      contentWidth,
      ...otherProps
    } = this.props
    return (
      <FormGroupRowWrapper {...otherProps}>
        {inlineLabel ? (
          <React.Fragment>
            <InlineLabelWrapper
              className="label-wrapper"
              inlineLabelWidth={inlineLabelWidth}
              inlineLableVerticalSpacing={inlineLableVerticalSpacing}
            >
              {inlineLabel}
            </InlineLabelWrapper>
            <ChildContentWrapper spaceBetween={otherProps.spaceBetween} contentWidth={contentWidth}>
              {children}
            </ChildContentWrapper>
          </React.Fragment>
        ) : (
          children
        )}
      </FormGroupRowWrapper>
    )
  }
}
