import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import AuthForm from '@components/UI/AuthForm'
import Box from '@components/UI/Box'
import { IApplicationState } from '@store'
import { forgotPasswordRequest, selectIsRequesting } from '@store/password'
import Theme from '@theme'
import {
  Button,
  Form,
  FormGroup,
  FormInput,
  FormValidationMessage,
  IWithFormValidationProps,
  Text,
  TextField,
  withFormValidation,
} from '@tribegroup'

interface IInternalProps extends IWithFormValidationProps, InjectedIntlProps, RouteComponentProps {
  forgotPasswordRequest: typeof forgotPasswordRequest
  isRequesting: boolean
}

export class AuthForgotPasswordForm extends React.Component<IInternalProps> {
  state = {
    email: '',
  }

  onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    this.props.forgotPasswordRequest(this.state, this.props.history)
  }

  onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement

    this.setState({
      [target.name]: target.value,
    })
  }

  render() {
    const { intl, validate, validationResults, setForm, isRequesting } = this.props
    return (
      <Box fullOnSmallScreens>
        <AuthForm
          title={<FormattedMessage id="resetPassword.forgotPassword.h1.header" />}
          subtitle={
            <Text color={Theme.grey800Color} textAlign="center">
              <FormattedMessage id="resetPassword.forgotPassword.text.subheader" />
            </Text>
          }
        >
          <Form
            name="reset-password"
            onSubmit={this.onFormSubmit}
            onBeforeSubmit={validate}
            setForm={setForm}
          >
            <FormGroup stretchChildWidth>
              <FormInput
                validationRule={{
                  required: true,
                  type: 'email',
                }}
              >
                <TextField
                  type="email"
                  name="email"
                  value={this.state.email}
                  invalid={validationResults.email && validationResults.email.hasError}
                  placeholder={intl.formatMessage({
                    id: 'core.text.email',
                  })}
                  onFocus={this.props.clearFieldValidation}
                  onChange={this.onChange}
                />
                {validationResults.email && validationResults.email.hasError && (
                  <FormValidationMessage>
                    <Text small>
                      <FormattedMessage id="resetPassword.forgotPassword.text.email.error" />
                    </Text>
                  </FormValidationMessage>
                )}
              </FormInput>
            </FormGroup>
            <Flex center>
              <Button
                heavy
                loading={isRequesting}
                scheme="primary"
                data-cy-element="reset-password-link"
              >
                <FormattedMessage id="resetPassword.forgotPassword.button" />
              </Button>
            </Flex>
          </Form>
          <Flex center column className="footer">
            <Link to="/signIn" data-cy-element="sign-in-link">
              <FormattedMessage id="resetPassword.forgotPassword.link.footer" />
            </Link>
          </Flex>
        </AuthForm>
      </Box>
    )
  }
}

const mapDispatchToProps = {
  forgotPasswordRequest,
}

const mapStateToProps = (state: IApplicationState) => ({
  isRequesting: selectIsRequesting(state),
})

export default compose<IInternalProps, {}>(
  withRouter,
  withFormValidation,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(AuthForgotPasswordForm)
