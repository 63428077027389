import { useContext } from 'react'

import { useSelector } from 'react-redux'

import { SideBarBlockItem } from '@components/UI/SideBar'
import { CampaignContext } from '@context/Campaign'
import { useDrawer } from '@hooks/useDrawer'
import { IApplicationState } from '@store'
import { selectShouldShowConnectAccountButton } from '@store/campaigns'
import { Container } from '@tribegroup'
import { ConnectedAccountsDrawer } from '../Drawer/Drawer'
import ConnectedAccountsSideBarItemContent from './Content'

const ConnectedAccountsSideBarItem: React.FC = () => {
  const { campaign } = useContext(CampaignContext)

  const { showDrawer, isOpen } = useDrawer()

  const shouldShowConnectAccountButton = useSelector((state: IApplicationState) =>
    selectShouldShowConnectAccountButton(state, campaign.id),
  )

  if (!shouldShowConnectAccountButton) {
    return null
  }

  const onShowConnectedAccountDrawer = () => {
    showDrawer(
      <ConnectedAccountsDrawer />,
      {
        desktopXOffset: 13.875,
        zIndex: 2,
        anchor: 'left',
      },
      undefined,
      ['desktop', 'tabletLandscape', 'tabletPortrait'],
    )
  }

  return (
    <Container bottomOuterSpacing={0.375} relative>
      <SideBarBlockItem
        text={
          <ConnectedAccountsSideBarItemContent
            campaign={campaign}
            onShowConnectedAccountDrawer={onShowConnectedAccountDrawer}
          />
        }
        active={isOpen}
        onClick={onShowConnectedAccountDrawer}
        elementName="side-bar-connected-account"
        paddingOverride="0.375rem 0.125rem 0.375rem 0.625rem"
      />
    </Container>
  )
}

export default ConnectedAccountsSideBarItem
