import { addClassToBody, removeClassFromBody } from '@utils'

// tslint:disable
export const bodyScrollHandler = {
  openComponentById: {},
  set(componentInternalId, status?: boolean) {
    this.openComponentById[componentInternalId] = status
    const openComponentStatus = Object.values(this.openComponentById)
    const hasOpenComponent = Boolean(openComponentStatus.find(Boolean))

    if (hasOpenComponent) {
      addClassToBody('overlayed-legacy')
    } else {
      removeClassFromBody('overlayed-legacy')
    }
  },
  remove(componentInternalId) {
    document.body.style.overflow = 'auto'
    removeClassFromBody('overlayed-legacy')

    delete this.openComponentById[componentInternalId]
  },
}

export default bodyScrollHandler
