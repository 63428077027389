import React, { useEffect } from 'react'

import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import {
  createSetupIntent,
  selectMeErrorCode,
  selectMeErrorMessage,
  selectMeIsFetching,
  selectPaymentFormIsLoading,
  selectSetupIntentClientSecret,
  setUpCard,
} from '@store/me'
import { Container, Form, Panel } from '@tribegroup'
import { CancelButton, FormButton, FormTitle } from './components'
import PaymentElements from './Elements'

interface IPaymentFormProps {
  autoFocus: boolean
}

interface IInternalProps extends IPaymentFormProps, RouteComponentProps {
  clientSecret?: string
  isFormLoading: boolean
  isFetchingMe: boolean
  createSetupIntent: typeof createSetupIntent
  setUpCard: typeof setUpCard
  errorMessage?: string
  errorCode?: string
}

export const PaymentForm: React.FC<IInternalProps> = (props) => {
  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    props.createSetupIntent()
  }, [])

  const onSuccess = () => {
    props.history.push('/account')
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const cardElement = elements?.getElement(CardNumberElement)
    if (stripe && cardElement) {
      props.setUpCard(stripe, cardElement, onSuccess)
    }
  }

  const hasError = props.errorCode || props.errorMessage
  return (
    <Form onSubmit={onSubmit}>
      <Panel
        disabled={props.isFormLoading}
        title={<FormTitle />}
        actionIcons={[
          <CancelButton key="edit-account" disabled={props.isFormLoading} />,
          <FormButton key="save" disabled={props.isFormLoading || !props.clientSecret} />,
        ]}
      >
        <Container topOuterSpacing={0.5}>
          <PaymentElements
            autoFocus={props.autoFocus}
            errorMessage={
              hasError && (
                <FormattedMessage id={props.errorCode || ''} defaultMessage={props.errorMessage} />
              )
            }
          />
        </Container>
      </Panel>
    </Form>
  )
}

const mapDispatchToProps = {
  createSetupIntent,
  setUpCard,
}

const mapStateToProps = (state: IApplicationState) => ({
  isFormLoading: selectPaymentFormIsLoading(state),
  isFetchingMe: selectMeIsFetching(state),
  clientSecret: selectSetupIntentClientSecret(state),
  errorMessage: selectMeErrorMessage(state),
  errorCode: selectMeErrorCode(state),
})

export default compose<IInternalProps, IPaymentFormProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PaymentForm)
