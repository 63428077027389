import React from 'react'

import { FormattedMessage } from 'react-intl'

import { usePreapprovalAmountPercentage } from '@hooks/usePreapprovalAmountPercentage'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { SubTitle } from './PreApproval.styled'

interface IProps {
  isExpanded?: boolean
  isSamplingEnabled?: boolean
  isPitchingEnabled?: boolean
}

const SubmissionCardModalPreApprovalSubTitle: React.FC<IProps> = ({
  isExpanded,
  isSamplingEnabled,
  isPitchingEnabled,
}) => {
  const preapprovalAmountPercentage = usePreapprovalAmountPercentage()
  let suffix = 'standard'

  if (isSamplingEnabled) {
    suffix = 'samplingEnabled'
  }

  if (isPitchingEnabled) {
    suffix = 'pitchingEnabled'
  }

  return (
    <Container
      topOuterSpacing={-1}
      overflow="hidden"
      maxHeight={isExpanded && isPitchingEnabled ? 2.5 : undefined}
    >
      <SubTitle>
        <Text color={Theme.grey900Color} customFontSize={1.125} customFontWeight={300}>
          <FormattedMessage
            id={`submission.chat.preapproved.modal.subtitle.${suffix}`}
            values={{
              preapprovalAmountPercentage,
            }}
          />
        </Text>
      </SubTitle>
    </Container>
  )
}

export default SubmissionCardModalPreApprovalSubTitle
