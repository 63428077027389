import Flex from 'styled-flex-component'

import Theme from '@theme'
import { H5 } from '@tribegroup'
import {
  CreatorHeaderDetailsTopStyled,
  CreatorHeaderStyled,
  CreatorHeaderUserHandleStyled,
} from './CreatorHeader.styled'

export const CreatorHeader = ({ children }) => (
  <CreatorHeaderStyled full justifyBetween>
    <Flex justifyStart>{children}</Flex>
  </CreatorHeaderStyled>
)

export const CreatorHeaderDetailsTop = ({ children }) => (
  <CreatorHeaderDetailsTopStyled justifyStart alignCenter>
    {children}
  </CreatorHeaderDetailsTopStyled>
)

export const CreatorHeaderDetailsBottom = ({ children }) => (
  <Flex justifyStart alignCenter>
    {children}
  </Flex>
)

export const CreatorHeaderUserHandle = ({ children }) => (
  <CreatorHeaderUserHandleStyled>
    <H5 inline color={Theme.grey900Color}>
      {children}
    </H5>
  </CreatorHeaderUserHandleStyled>
)

export default CreatorHeader
