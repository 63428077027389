import styled, { css } from 'styled-components'

import { defaultTheme } from '../../Theme'

export const Tag = styled.div`
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  background-color: ${defaultTheme.primary200Color};
  display: flex;
  align-items: center;
  ${({ pillBackgroundColor }) =>
    pillBackgroundColor &&
    css`
      background-color: ${pillBackgroundColor};
    `}
`

export const RemoveIconWrapper = styled.span`
  margin-left: 0.5rem;
  display: flex;
`
