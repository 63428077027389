import React from 'react'

import CampaignModalPaidPartnershipWatcher from '@components/Campaign/Modal/PaidPartnership/Watcher'
import CampaignNotEnoughCreditModal from '@components/Campaign/NotEnoughCreditModal/NotEnoughCreditModal'
import CampaignPreview from '@components/Campaign/Preview/Preview'
import MediaObjectsFacebookAdAccounts from '@components/MediaObject/Licensing/Licensed/FacebookAdAccounts'
import ConnectedPayment from '@components/Payment/ConnectedPayment'
import BrandedContentAdWatcher from '@components/Submission/Card/BrandedContentAd/Watcher'
import SubmissionComments from '@components/Submission/Comments'
import SubmissionSummary from '@components/Submission/Summary'
import SubmissionTitleBar from '@components/Submission/TitleBar/TitleBar'
import MainLayout from '@components/UI/MainLayout'
import AnalyticsTrackingProvider from '@context/AnalyticsTracking'
import { CampaignProvider } from '@context/Campaign'
import { CurrentMediaObjectIdProvider } from '@context/CurrentMediaObjectId'
import { CurrentSubmissionIdProvider } from '@context/CurrentSubmissionId'
import { DrawerProvider } from '@context/Drawer'
import { InboxPromptProvider } from '@context/InboxPrompt'
import { InfluencerIdentityIdProvider } from '@context/InfluencerIdentityId'
import { ModalProvider } from '@context/Modal'
import PopupMenuProvider from '@context/PopupMenu'
import { SubmissionProvider } from '@context/Submission'
import { SubmissionListFilterProvider } from '@context/SubmissionListFilter'
import { withScrollToTop } from '@hocs'
import { ToastContainer } from '@tribegroup'

const SubmissionPage: React.FC = () => {
  return (
    <MainLayout>
      <ToastContainer />
      <CampaignProvider>
        <SubmissionListFilterProvider>
          <SubmissionProvider>
            <CurrentSubmissionIdProvider>
              <CurrentMediaObjectIdProvider>
                <ConnectedPayment>
                  <AnalyticsTrackingProvider>
                    <ModalProvider>
                      <DrawerProvider>
                        <InboxPromptProvider>
                          <PopupMenuProvider>
                            <InfluencerIdentityIdProvider>
                              <CampaignNotEnoughCreditModal />
                              <CampaignModalPaidPartnershipWatcher />
                              <BrandedContentAdWatcher />
                              <MediaObjectsFacebookAdAccounts />
                              <SubmissionTitleBar />
                              <CampaignPreview />
                              <SubmissionComments />
                              <SubmissionSummary />
                            </InfluencerIdentityIdProvider>
                          </PopupMenuProvider>
                        </InboxPromptProvider>
                      </DrawerProvider>
                    </ModalProvider>
                  </AnalyticsTrackingProvider>
                </ConnectedPayment>
              </CurrentMediaObjectIdProvider>
            </CurrentSubmissionIdProvider>
          </SubmissionProvider>
        </SubmissionListFilterProvider>
      </CampaignProvider>
    </MainLayout>
  )
}

export { SubmissionPage }
export default withScrollToTop(SubmissionPage)
