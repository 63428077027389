import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { authError, authSuccess, autoSignIn, AutoSignInActionTypes } from '@store/auth'
import { fetchMeRequest } from '@store/me'
import { selectAutoSignInLink } from '@store/rootResource'
import { networkRequest } from '@utils'

export function* handleAutoSignIn(action: ReturnType<typeof autoSignIn>) {
  try {
    const autoSignInLink: IHateoasLink = yield select(selectAutoSignInLink)
    const result = yield call(
      networkRequest,
      autoSignInLink.method,
      autoSignInLink.href,
      action.payload,
    )
    yield put(authSuccess(result))
    yield put(fetchMeRequest({ ...action.meta, signIn: true }))
  } catch (error) {
    yield put(authError(error))
  }
}

export function* watchAutoSignInRequest() {
  yield takeLatest(AutoSignInActionTypes.AUTO_SIGNIN_REQUEST, handleAutoSignIn)
}
