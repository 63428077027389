import { createRef, Fragment, useEffect, useRef, useState } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import { compose } from 'recompose'

import DrawerForm from '@components/UI/Drawer/Form'
import { DrawerFormFileRejection } from '@components/UI/Drawer/Form/Form'
import { TrackedRoute } from '@components/UI/TrackedRoute'
import { IAnalyticsTrackingContextProps } from '@context/AnalyticsTracking'
import { EventTrackingNames, SubmissionStatuses } from '@enums'
import { useDrawer } from '@hooks/useDrawer'
import { IApplicationState } from '@store'
import { selectSubmissionTrackingProps, trackEvent } from '@store/analytics'
import { selectCampaign, selectIsSamplingEnabled, selectIsSocialCampaign } from '@store/campaigns'
import {
  addFeedback,
  fetchFeedback,
  IAddFeedbackRequestBody,
  selectFeedback,
  selectFeedbackErrors,
  selectInfluencer,
  selectIsAddingFeedback,
  selectIsFetchingFeedback,
} from '@store/feedback'
import { addFeedbackDraft, selectFeedbackDraft } from '@store/feedbackDraft'
import { selectMeId } from '@store/me'
import { selectFeedbackNegotiationKeywords } from '@store/rootResource'
import {
  selectInfluencerInitials,
  selectIsPitchingEnabled,
  selectPreApproveLink,
  selectSubmission,
} from '@store/submissions'
import { setUIFlag, UIFlag } from '@store/ui'
import Theme from '@theme'
import { Container } from '@tribegroup'
import { containsKeywords } from '@utils'
import SubmissionFeedbackLoading from './Loading/Loading'
import SubmissionFeedbackNegotiationHint from './Negotiation/Hint'
import FeedbackProfileBlock from './ProfileBlock/ProfileBlock'
import { ChatFormDivider, ChatListStyled } from './Chat.styled'
import SubmissionChatList from './List'

interface IInternalProps extends InjectedIntlProps, IAnalyticsTrackingContextProps, IProps {}

interface IProps {
  campaignId: number
  submissionId: number
}

export const CURRENCY_PATTERN = '[0-9]{2,4}\\.[0-9]{1,2}'
const FORM_ERROR_DURATION = 3_000 // 3s

const SubmissionChat: React.FC<IInternalProps> = ({ intl, campaignId, submissionId }) => {
  const { updateTitle } = useDrawer()

  const dispatch = useDispatch()

  const {
    influencer,
    chatList,
    isFetching,
    negotiationKeywords,
    submissionAnalyticsProperties,
    isSocialCampaign,
    hasPreapproveLink,
    isSamplingCampaign,
    meId,
    feedbackDraft,
    isPitchingEnabled,
    initials,
    submission,
    campaign,
    isAddingFeedback,
    feedbackErrors,
  } = useSelector((state: IApplicationState) => {
    return {
      influencer: selectInfluencer(state, submissionId),
      chatList: selectFeedback(state, submissionId),
      isFetching: selectIsFetchingFeedback(state),
      negotiationKeywords: selectFeedbackNegotiationKeywords(state),
      submissionAnalyticsProperties: selectSubmissionTrackingProps(state, campaignId, submissionId),
      isSocialCampaign: selectIsSocialCampaign(state, campaignId),
      hasPreapproveLink: Boolean(selectPreApproveLink(state, submissionId)),
      isSamplingCampaign: selectIsSamplingEnabled(state, campaignId),
      meId: selectMeId(state),
      feedbackDraft: selectFeedbackDraft(state, submissionId),
      isPitchingEnabled: Boolean(selectIsPitchingEnabled(state, submissionId)),
      initials: selectInfluencerInitials(state, submissionId).join(''),
      submission: selectSubmission(state, submissionId),
      campaign: selectCampaign(state, campaignId),
      isAddingFeedback: selectIsAddingFeedback(state),
      feedbackErrors: selectFeedbackErrors(state),
    }
  })
  const [shouldTrackNegotiationTooltip, setShouldTrackNegotiationTooltip] = useState(false)
  const [showNegotiationPopup, setShowNegotiationPopup] = useState(false)
  const [message, setMessage] = useState<string>('')
  const [attachedFile, setAttachedFile] = useState<File>()
  const [formError, setFormError] = useState<string>()

  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const setBaseTitle = () => {
    updateTitle(<FormattedMessage id="core.text.chat" />)
  }

  useEffect(() => {
    setBaseTitle()
    dispatch(fetchFeedback(submissionId, campaignId))

    return () => {
      dispatch(setUIFlag(UIFlag.CHAT_SENDING_INPROGRESS))
    }
  }, [])

  useEffect(() => {
    setMessage(feedbackDraft)
  }, [feedbackDraft])

  useEffect(() => {
    if (shouldTrackNegotiationTooltip) {
      if (showNegotiationPopup) {
        dispatch(
          trackEvent(EventTrackingNames.NegotiationTooltipDisplayed, submissionAnalyticsProperties),
        )
      } else {
        dispatch(
          trackEvent(EventTrackingNames.NegotiationTooltipHidden, submissionAnalyticsProperties),
        )
      }
    }
  }, [showNegotiationPopup])

  useEffect(() => {
    if (!formError) {
      return
    }
    setTimeout(() => {
      setFormError(undefined)
    }, FORM_ERROR_DURATION)
  }, [formError, setFormError])

  const bottomDiv = createRef<HTMLDivElement>()

  const scrollToBottom = () => {
    const node = bottomDiv.current
    if (node?.scrollIntoView) {
      setTimeout(() => {
        node.scrollIntoView()
      }, 300)
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [chatList])

  const placeholderIntlId =
    chatList.length > 0
      ? 'submission.feedback.block.addMoreFeedback'
      : 'submission.feedback.block.giveFeedback'

  const onMessageChange = (newMessage) => {
    const messageContainsKeywordOrCurrency =
      containsKeywords(negotiationKeywords, newMessage) ||
      Boolean(newMessage.match(CURRENCY_PATTERN))

    if (campaign?.traditional) {
      setShowNegotiationPopup(messageContainsKeywordOrCurrency)
      setShouldTrackNegotiationTooltip(true)
    }

    setMessage(newMessage)

    dispatch(addFeedbackDraft(submissionId, newMessage))
  }

  const onFormSubmit = () => {
    if (message || attachedFile) {
      const requestBody: IAddFeedbackRequestBody = {
        message_body: message,
        file: attachedFile,
      }
      const refreshSubmission = chatList.length === 0
      dispatch(
        addFeedback(submissionId, campaignId, requestBody, {
          refreshSubmission,
        }),
      )
      setShowNegotiationPopup(false)
    }
  }

  const onHiddenProfileBlock = () => {
    updateTitle(influencer.name)
  }

  const showPreapprovalEducation = isSocialCampaign
    ? hasPreapproveLink
    : Boolean(campaign.preapproval_allowed) && submission.status === SubmissionStatuses.Pending

  useEffect(() => {
    if (isAddingFeedback) {
      return
    }
    if (!feedbackErrors) {
      onMessageChange(feedbackDraft)
      setAttachedFile(undefined)
      setFormError(undefined)
    } else {
      setFormError('submission.chat.sending.error')
    }
  }, [isAddingFeedback, feedbackErrors, setAttachedFile, setFormError])

  if (isFetching) {
    return <SubmissionFeedbackLoading />
  }

  const onAttachmentError = (rejection: DrawerFormFileRejection) => {
    setFormError(rejection.errors[0].message)
    dispatch(
      trackEvent(EventTrackingNames.FeedbackUploadRejection, {
        file_size: rejection.file.size,
        file_content_type: rejection.file.type,
        submission_id: submissionId,
        brief_id: campaignId,
        sender_id: meId,
        sender_type: 'brand_manager',
      }),
    )
  }

  return (
    <Container
      data-testid="submission-chat-container"
      flexColumn
      height="calc(100vh - 3.813rem)"
      flexStart
    >
      <Fragment>
        {influencer && (
          <Waypoint
            key={`waypoint-feedback-${influencer.id}`}
            onEnter={setBaseTitle}
            onLeave={onHiddenProfileBlock}
          >
            <div>
              <FeedbackProfileBlock influencer={influencer} initials={initials} />
            </div>
          </Waypoint>
        )}
        <ChatListStyled alignBaseline columnReverse>
          <div ref={bottomDiv} />
          <SubmissionChatList
            meId={meId}
            chatList={chatList}
            showPreapprovalEducation={showPreapprovalEducation}
            isSamplingCampaign={isSamplingCampaign}
            isPitchingEnabled={isPitchingEnabled}
            isSocialCampaign={isSocialCampaign}
          />
        </ChatListStyled>
      </Fragment>

      <Container sticky bottomPosition={0} width="100%">
        <ChatFormDivider />
        <Container>
          {showNegotiationPopup && (
            <Container
              topInnerSpacing={0.75}
              leftInnerSpacing={1.625}
              rightInnerSpacing={3.75}
              backgroundColor={Theme.whiteColor}
              fullWidth
            >
              <TrackedRoute
                properties={submissionAnalyticsProperties}
                eventName={EventTrackingNames.NegotiationTooltipClicked}
              >
                <SubmissionFeedbackNegotiationHint
                  submissionId={submissionId}
                  campaignId={campaignId}
                  isSocialCampaign={isSocialCampaign}
                />
              </TrackedRoute>
            </Container>
          )}
          <DrawerForm
            autoFocus
            handleInput={onMessageChange}
            handleSubmit={onFormSubmit}
            message={message}
            textareaRef={textareaRef}
            placeholder={intl.formatMessage(
              { id: placeholderIntlId },
              { firstName: influencer && influencer.name },
            )}
            attachedFile={attachedFile}
            setAttachedFile={setAttachedFile}
            isSubmitting={isAddingFeedback}
            isSubmittingTextPlaceholder={intl.formatMessage({
              id: 'submission.chat.sending.inprogress',
            })}
            formErrorMessage={formError}
            onAttachmentError={onAttachmentError}
          />
        </Container>
      </Container>
    </Container>
  )
}

export default compose<IInternalProps, IProps>(injectIntl)(SubmissionChat)
