import React from 'react'

import styled, { css } from 'styled-components'

import Theme from '@theme'

export interface IMultiframeControlProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  disabled: boolean
}

export const MultiframeControls: React.ComponentType<IMultiframeControlProps> = styled.button`
  position: absolute;
  top: calc(50% - 1.25rem);
  border-radius: 16rem;
  background: ${Theme.whiteColor};
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      opacity: 0.2;
    `};
`

export const NextWrapper: React.ComponentType<IMultiframeControlProps> = styled(MultiframeControls)`
  right: 1.375rem;
`
