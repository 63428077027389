import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { DECINE_REASON_CHARACTER_LIMIT } from '@constants'
import { ICampaignContextProps } from '@context/Campaign'
import { IModalComponentProps } from '@context/Modal'
import { withCampaign } from '@hocs'
import { IApplicationState } from '@store'
import {
  ICategorisedDeclineReasons,
  selectPendingSubmissionDeclineReasons,
} from '@store/rootResource'
import { decline, ISubmission, selectIsDeclining } from '@store/submissions'
import {
  Button,
  CloseIcon,
  Form,
  IWithFormValidationProps,
  Modal,
  ModalTitle,
  TextArea,
  Tip,
} from '@tribegroup'
import { insertStringBetweenText, isEmpty } from '@utils'
import SubmissionCardModalDeclineReasons from './Reasons/Reasons'

export interface ISubmissionCardModalDeclineProps extends IModalComponentProps {
  currFilter: string
  submission: ISubmission
  campaignId: number
}

interface IInternalProps
  extends ISubmissionCardModalDeclineProps,
    ICampaignContextProps,
    IWithFormValidationProps,
    RouteComponentProps,
    InjectedIntlProps {
  decline: typeof decline
  isDeclining: boolean
  reasons: ReadonlyArray<ICategorisedDeclineReasons>
  submissionFilters?: ReadonlyArray<string>
}

export interface ISubmissionCardModalDeclineState {
  open: boolean
  decline_reason: string
  tip: string
}

export class SubmissionCardModalDecline extends React.Component<
  IInternalProps,
  ISubmissionCardModalDeclineState
> {
  state = {
    open: false,
    decline_reason: '',
    tip: '',
  }

  private textArea = React.createRef<HTMLTextAreaElement>()

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { currFilter, submission, campaignId } = this.props
    const declineReason = this.state.decline_reason.trim()
    event.preventDefault()

    if (declineReason.length > 0 && declineReason.length <= DECINE_REASON_CHARACTER_LIMIT) {
      this.props.decline(
        submission.id,
        campaignId,
        {
          decline_reason: declineReason,
        },
        {
          filter: currFilter,
        },
      )
    }
  }

  componentDidUpdate(prevProps: IInternalProps) {
    if (prevProps.isDeclining && !this.props.isDeclining) {
      this.props.onRequestClose()
    }
  }

  onCancelClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    this.resetForm()
    this.props.onRequestClose()
  }

  resetForm = () => {
    this.setState({
      decline_reason: '',
      tip: '',
    })
  }

  onReasonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      decline_reason: event.currentTarget.value,
    })
  }

  onReasonClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    const textArea = this.textArea.current as HTMLTextAreaElement
    const reasonSelected = event.currentTarget as HTMLElement
    const newDeclineReason = insertStringBetweenText(
      textArea.value,
      reasonSelected.getAttribute('data-text') || '',
      textArea.selectionStart,
    )

    this.setState({
      decline_reason: newDeclineReason,
      tip: reasonSelected.getAttribute('data-show-tip') || '',
    })
  }

  render() {
    const { intl, reasons, isDeclining, submission, validate, isOpen = false } = this.props
    const { decline_reason, tip } = this.state
    return (
      <Modal reactModalProps={{ isOpen }} width={53} complex>
        <ModalTitle center>
          <FormattedMessage
            id="submission.decline.form.title"
            values={{ name: submission.influencer_first_name }}
          />
        </ModalTitle>
        <CloseIcon onClick={this.onCancelClick} />
        <Form
          onSubmit={this.onSubmit}
          onBeforeSubmit={validate}
          setForm={this.props.setForm}
          method="POST"
        >
          <TextArea
            height={8}
            placeholder={intl.formatMessage({
              id: 'submission.decline.form.reason.placeholder',
            })}
            innerRef={this.textArea}
            value={decline_reason}
            onChange={this.onReasonChange}
            characterLimit={DECINE_REASON_CHARACTER_LIMIT}
          />
          <SubmissionCardModalDeclineReasons
            reasons={reasons}
            onReasonClick={this.onReasonClick}
            firstName={submission.influencer_first_name}
          />
          <Flex justifyBetween>
            <div>{tip && <Tip text={tip} />}</div>
            <Flex justifyEnd>
              <Button
                scheme="primary"
                outlined
                onClick={this.onCancelClick}
                fontSize={0.875}
                height={3}
                width={10.125}
              >
                <FormattedMessage id="core.text.cancel" />
              </Button>
              <Button
                scheme="primary"
                fontSize={0.875}
                height={3}
                width={10.125}
                leftOuterSpacing={1}
                disabled={isDeclining || isEmpty(decline_reason.trim())}
                loading={isDeclining}
              >
                <FormattedMessage id="core.text.submit" />
              </Button>
            </Flex>
          </Flex>
        </Form>
      </Modal>
    )
  }
}

const mapDispatchToProps = {
  decline,
}

const mapStateToProps = (state: IApplicationState, ownProps) => {
  return {
    isDeclining: selectIsDeclining(state),
    reasons: selectPendingSubmissionDeclineReasons(
      state,
      ownProps.campaign.supported_submission_types[0],
    ),
  }
}

export default compose<IInternalProps, ISubmissionCardModalDeclineProps>(
  withRouter,
  withCampaign,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(SubmissionCardModalDecline)
