import { createAction } from '@reduxjs/toolkit'

import { IMeta } from '@store'
import { AddBrandActionTypes, IBrand } from '@store/brands'

interface IAddBrandParams {
  name?: string
  media_id?: string
}

export const addBrand = createAction(
  AddBrandActionTypes.ADD_REQUEST,
  (payload: IAddBrandParams, meta: IMeta) => ({
    payload,
    meta,
  }),
)

export const addBrandSuccess = createAction(
  AddBrandActionTypes.ADD_SUCCESS,
  (payload: IBrand, meta?: IMeta) => ({
    payload,
    meta,
  }),
)

export const addBrandError = createAction<any>(AddBrandActionTypes.ADD_ERROR)
