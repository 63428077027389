import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'

import { NoticeUnsupportedScreen } from '@components/UI/Notice'
import { UnsupportedScreenWrapper } from '@components/UI/ScreenSize'
import TitleBar from '@components/UI/TitleBar/TitleBar'
import { Container } from '@tribegroup'

export const BuilderLayoutUnsupportedScreen = () => {
  return (
    <Fragment>
      <UnsupportedScreenWrapper>
        <TitleBar />
      </UnsupportedScreenWrapper>
      <Container leftInnerSpacing={1.5} rightInnerSpacing={1.5}>
        <NoticeUnsupportedScreen
          subtitle={<FormattedMessage id="builder.unsupportedscreen.text" />}
          alt="campaign-builder-unsupported-screen"
          title={<FormattedMessage id="builder.unsupportedscreen.header" />}
        />
      </Container>
    </Fragment>
  )
}

export default BuilderLayoutUnsupportedScreen
