import React from 'react'

import { FormattedHTMLMessage } from 'react-intl'

import Theme from '@theme'
import { H3 } from '@tribegroup'

const InboxReferraITitle: React.FC = () => (
  <H3 color={Theme.grey900Color}>
    <FormattedHTMLMessage id="inbox.referral.modal.title" />
  </H3>
)

export default InboxReferraITitle
