import styled, { css } from 'styled-components'

import { getColor, themed } from '../Theme'

const TextAreaAndHighLightStyles = `
  padding: 0.875rem 1rem;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.3px;
  box-sizing: border-box;
  display: block;
  border: 1px solid;
`

const TextAreaStyled = themed(styled('textarea')`
  ${TextAreaAndHighLightStyles};
  position: relative;
  background: transparent;
  resize: none;
  width: 100%;
  ${({ theme, invalid, overLimit, scheme }) => css`
    border-radius: 0.25rem;
    border: 1px solid ${theme.grey400Color};
    color: ${theme.grey900Color};
    width: 100%;
    transition: 0.25s border;
    outline: none;

    &::placeholder {
      color: ${theme.grey700Color};
    }
    ${invalid || overLimit
      ? css`
          border-color: ${theme.errorColor};
        `
      : css`
          &:focus {
            border-color: ${theme.grey900Color};
            ${scheme &&
            css`
              border-color: ${getColor(theme, scheme)};
            `};
          }
        `};
  `}
  ${({ width, height }) =>
    css`
      ${width &&
      css`
        max-width: ${width}rem;
      `};
      ${height &&
      css`
        height: ${height}rem;
      `};
    `};
`)

export const TextAreaContainer = themed(styled.div`
  ${({ theme }) => css`
    background-color: ${theme.whiteColor};
  `};
  position: relative;
  overflow: hidden;
`)

export const Backdrop = styled.div`
  position: absolute;
  inset: 0 -2rem 0 0;
  padding-right: 2rem;
  overflow: hidden;
  height: 100%;
  width: 100%;
  ${({ width, height }) =>
    css`
      ${width &&
      css`
        max-width: ${width}rem;
      `};
      ${height &&
      css`
        height: ${height}rem;
      `};
    `};
`

export const Highlights = styled.div`
  ${TextAreaAndHighLightStyles};
  width: auto;
  height: auto;
  border-color: transparent;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
  overflow: hidden;
  padding-bottom: 10rem;
`

export const Highlight = themed(styled.span`
  ${({ theme }) => css`
    background: ${theme.error100Color};
  `}
`)

export const CharacterCounter = themed(styled.div`
  ${({ theme }) => css`
    display: block;
    font-size: 0.6875rem;
    font-weight: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: 1px;
    text-align: right;
    margin-top: 0.375rem;
    color: ${theme.grey800Color};
  `};
`)

export const Count = themed(styled.span`
  ${({ theme, overLimit }) =>
    overLimit &&
    css`
      color: ${theme.errorColor};
    `};
  display: inline;
`)

export default TextAreaStyled
