import React from 'react'

import { Link } from 'react-router-dom'

import { HeaderBackButton } from '@components/UI/TitleBar'
import { PageRoutes } from '@enums'
import hooks from '@hooks'
import Theme from '@theme'
import { queryStringtoObject } from '@utils'

const getRedirectLink = (search) => {
  const searchObject = queryStringtoObject(search) || {}
  if (searchObject.from) {
    return searchObject.from
  }
  return {
    pathname: `/${PageRoutes.ContentLibrary}`,
    search,
  }
}

export const MediaObjectTitleBarBackButton: React.FC = () => {
  const { search } = hooks.useLocation()
  return (
    <Link to={getRedirectLink(search)}>
      <HeaderBackButton
        glyph="nav-back-default"
        size={1.5}
        color={Theme.grey900Color}
        data-cy-element="header-back-button"
      />
    </Link>
  )
}

export default MediaObjectTitleBarBackButton
