import React from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'

import Tabs from '@components/UI/Tabs'
import { ICategorisedDeclineReasons } from '@store/rootResource'
import SubmissionCardModalDeclineReasonsLineItem from './LineItem'
interface IProps {
  reasons: ReadonlyArray<ICategorisedDeclineReasons>
  firstName?: string
  onReasonClick: (event: React.SyntheticEvent) => void
}

interface IInternalProps extends IProps, InjectedIntlProps {}

export const SubmissionCardModalDeclineReasons: React.FC<IInternalProps> = ({
  reasons,
  onReasonClick,
  firstName,
  intl,
}) => (
  <Tabs
    tabs={reasons.map((category) => ({
      title: category.category,
      content: (
        <SubmissionCardModalDeclineReasonsLineItem
          intl={intl}
          onReasonClick={onReasonClick}
          firstName={firstName}
          reasons={category.reasons}
        />
      ),
    }))}
  />
)

export default injectIntl<IProps>(SubmissionCardModalDeclineReasons)
