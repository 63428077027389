import { call, put, select, takeEvery } from 'redux-saga/effects'

import { ToastTemplateSubmissionMoved } from '@components/UI/ToastTemplate'
import { INBOX_FILTER, PREAPPROVED_FILTER, SHORTLISTED_FILTER } from '@constants'
import { EventTrackingNames, SubmissionStatuses } from '@enums'
import { handleFetchInfluencerIdentity } from '@sagas/influencerIdentities/influencerIdentity'
import { IHateoasLink } from '@store'
import { selectSubmissionTrackingProps, trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import { fetchCampaign } from '@store/campaigns'
import {
  decline,
  DeclineActionTypes,
  declineError,
  declineSuccess,
  fetchSubmissions,
  ISubmission,
  selectDeclineLink,
  updateFilterSubmissionIds,
} from '@store/submissions'
import { Toaster } from '@tribegroup'
import { networkRequest } from '@utils'

export function* handleDecline(action: ReturnType<typeof decline>) {
  try {
    const { submissionId, campaignId, params } = action.payload
    const { history, filter, event } = action.meta
    const authToken: string = yield select(selectAuthToken)
    const declineLink: IHateoasLink = yield select(selectDeclineLink, submissionId)
    const submission: ISubmission = yield call(
      networkRequest,
      declineLink.method,
      declineLink.href,
      params,
      authToken,
    )

    const properties = yield select(selectSubmissionTrackingProps, campaignId, submissionId)
    yield put(trackEvent(EventTrackingNames.DeclineSubmission, properties))

    yield put(
      updateFilterSubmissionIds(campaignId, submissionId, [
        INBOX_FILTER,
        SHORTLISTED_FILTER,
        PREAPPROVED_FILTER,
      ]),
    )
    yield put(declineSuccess(submission, campaignId, { history, filter, event }))
    yield put(fetchSubmissions(campaignId, { filter }))
    yield put(fetchCampaign(campaignId))

    yield call(Toaster.createToast, ToastTemplateSubmissionMoved, {
      newStatus: SubmissionStatuses.Declined,
    })
    yield call(handleFetchInfluencerIdentity, campaignId, submission.id)
  } catch (error) {
    yield put(declineError(error))
  }
}

export function* watchDeclineRequest() {
  yield takeEvery(DeclineActionTypes.DECLINE_REQUEST, handleDecline)
}
