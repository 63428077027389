import { call, put, select, takeLatest } from 'redux-saga/effects'

import { unleashClient } from '@context/FeatureToggle'
import { FeatureToggles } from '@enums'
import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import { selectSubmissionsLink } from '@store/me'
import {
  fetchSubmission,
  fetchSubmissionError,
  fetchSubmissionSuccess,
  ISubmission,
  SubmissionActionTypes,
} from '@store/submissions'
import { fetchBrandFansMembership } from '@store/submissions/actions/fetchSubmissionBrandFanMembership'
import { networkRequest } from '@utils'

export function* handleFetchSubmission(action: ReturnType<typeof fetchSubmission>) {
  try {
    const authToken: string = yield select(selectAuthToken)
    const submissionLink: IHateoasLink = yield select(selectSubmissionsLink)

    const { campaignId, submissionId } = action.payload
    const submission: ISubmission = yield call(
      networkRequest,
      submissionLink.method,
      submissionLink.href
        .replace('{brief_id}', String(campaignId))
        .replace('{submission_id}', String(submissionId)),
      undefined,
      authToken,
    )
    yield put(fetchSubmissionSuccess(submission))

    const isBrandFanMembersEnabled = unleashClient.isEnabled(
      FeatureToggles.BRAND_FANS_FROM_CONTENT_CAMPAIGNS_ENABLED,
    )

    if (isBrandFanMembersEnabled) {
      yield put(fetchBrandFansMembership(submission.id))
    }
  } catch (error) {
    yield put(fetchSubmissionError(error))
  }
}

export function* watchSubmissionRequest() {
  yield takeLatest(SubmissionActionTypes.FETCH_REQUEST, handleFetchSubmission)
}
