import { FormEvent } from 'react'

import { ILicenseOption } from '@store/campaigns'
import { Container, FormGroup, Radio, SelectionSubtext } from '@tribegroup'

interface IProps {
  licenseOptions?: readonly ILicenseOption[]
  onChange?: (event: FormEvent<HTMLInputElement>) => void
}

export const SubmissionCardModalLicenseOptions = ({ licenseOptions, onChange }: IProps) => {
  return (
    <FormGroup>
      {Object.values(licenseOptions!).map((license: ILicenseOption, index) => {
        return (
          <Container key={`${license?.label}-${index}`} bottomOuterSpacing={1}>
            <Radio
              onChange={onChange}
              scheme="primary"
              label={license.label}
              defaultChecked={index === 0}
              name="licensingOption"
              labelCapitalize
              data-license-index={index}
              elementName={license.license_type}
            />
            <SelectionSubtext>{license.description}</SelectionSubtext>
          </Container>
        )
      })}
    </FormGroup>
  )
}
