import styled from 'styled-components'
import Flex from 'styled-flex-component'

export const StyledDotsWrapper = styled(Flex)`
  position: relative;
  position: absolute;
  bottom: -3.75rem;
  height: 2.5rem;
  left: 0;
  width: 100%;
`
