import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Header } from '@tribegroup'

const ShareableContentLibrarySubtitle: React.FC = () => (
  <Header small color={Theme.grey700Color} uppercase>
    <FormattedMessage id="contentLibrary.shareable.viewOnlyMode" />
  </Header>
)

export { ShareableContentLibrarySubtitle }
export default ShareableContentLibrarySubtitle
