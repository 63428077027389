import React from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { TrackedRoute } from '@components/UI/TrackedRoute'
import { SHAREABLE_CONTENT_LIBRARY_EDUCATION_LINK } from '@constants'
import { IModalComponentProps } from '@context/Modal'
import { ContentLibraryFilterTypes, EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectMediaObjectsStatsTotal } from '@store/mediaObjects'
import Theme from '@theme'
import { CloseIcon, Container, H5, Link, Modal, ModalTitle } from '@tribegroup'
import ShareThisLibraryModalActions from './ModalActions'

interface IInternalProps extends IModalComponentProps {
  librarySize: number
}

const ShareThisLibraryModal: React.FC<IInternalProps> = ({
  onRequestClose,
  isOpen = false,
  librarySize,
}) => (
  <Modal reactModalProps={{ isOpen }} width={28.75} complex>
    <CloseIcon onClick={onRequestClose} />
    <Flex justifyCenter column full>
      <Container textAlign="center" data-testid="shareable-cl-modal-title">
        <ModalTitle center bottomOuterSpacing={1.25}>
          <FormattedHTMLMessage id="contentLibrary.shareable.shareThis.modal.title" />
        </ModalTitle>
        <Container
          bottomOuterSpacing={0.5}
          textAlign="center"
          data-testid="shareable-cl-modal-body"
        >
          <H5 color={Theme.defaultColor}>
            <FormattedHTMLMessage id="contentLibrary.shareable.shareThis.modal.body" />
          </H5>
        </Container>
        <TrackedRoute
          eventName={EventTrackingNames.ShareCLEducationLink}
          properties={{ library_size: librarySize }}
        >
          <Link
            target="_blank"
            href={SHAREABLE_CONTENT_LIBRARY_EDUCATION_LINK}
            data-testid="shareable-cl-modal-education-link"
          >
            <H5 color={Theme.primaryColor}>
              <FormattedMessage id="contentLibrary.shareable.shareThis.modal.whyShareLink" />
            </H5>
          </Link>
        </TrackedRoute>
      </Container>
      <Container topOuterSpacing={2.5}>
        <ShareThisLibraryModalActions onRequestClose={onRequestClose} />
      </Container>
    </Flex>
  </Modal>
)

const mapStateToProps = (state: IApplicationState) => ({
  librarySize: selectMediaObjectsStatsTotal(state, ContentLibraryFilterTypes.DISCOVER),
})

export { ShareThisLibraryModal }
export default compose<IInternalProps, IModalComponentProps>(connect(mapStateToProps))(
  ShareThisLibraryModal,
)
