import { call, put, select, takeEvery } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { IHateoasLink } from '@store'
import { selectCampaignTrackingProps, trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  fetchCampaigns,
  ICampaign,
  pause,
  PauseActionTypes,
  pauseError,
  pauseSuccess,
  selectPauseLink,
} from '@store/campaigns'
import { networkRequest } from '@utils'

export function* handlePause(action: ReturnType<typeof pause>) {
  try {
    const campaignId = action.payload
    const authToken: string = yield select(selectAuthToken)
    const pauseLink: IHateoasLink = yield select(selectPauseLink, campaignId)
    const campaign: ICampaign = yield call(
      networkRequest,
      pauseLink.method,
      pauseLink.href,
      undefined,
      authToken,
    )

    yield put(pauseSuccess(campaign))

    const eventProperties = yield select(selectCampaignTrackingProps, campaignId)
    yield put(trackEvent(EventTrackingNames.PauseCampaign, eventProperties))

    yield put(fetchCampaigns())
  } catch (error) {
    yield put(pauseError(error))
  }
}

export function* watchPauseRequest() {
  yield takeEvery(PauseActionTypes.PAUSE_REQUEST, handlePause)
}
