import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import InfluencerIdentityRowLabelTooltip from '@components/InfluencerIdentity/Row/LabelTooltip'
import InfluencerIdentityRowValueAndLabel from '@components/InfluencerIdentity/Row/ValueAndLabel'
import { IInfluencerIdentityIdContextProps } from '@context/InfluencerIdentityId'
import { withContextInfluencerIdentityId } from '@hocs'
import { IApplicationState } from '@store'
import { selectInfluencerIdentityFollowerCount } from '@store/influencerIdentities'
import { selectCampaignId } from '@store/router'
import { abbreviateNumber } from '@utils'

interface IInternalProps {
  followerCount: number
}

const InfluencerIdentityRowPerformanceFollowerCount: React.FC<IInternalProps> = ({
  followerCount,
}) => {
  return (
    <InfluencerIdentityRowValueAndLabel
      value={abbreviateNumber(followerCount)}
      label={
        <InfluencerIdentityRowLabelTooltip
          title={<FormattedMessage id="submission.performance.followers" />}
          body={<FormattedMessage id="submission.performance.followers.body" />}
        >
          <FormattedMessage id="submission.performance.followers" />
        </InfluencerIdentityRowLabelTooltip>
      }
      width={5.25}
    />
  )
}

interface IStateProps extends IInfluencerIdentityIdContextProps, RouteComponentProps {}

const mapStateToProps = (
  state: IApplicationState,
  { contextInfluencerIdentityId, match }: IStateProps,
) => {
  const campaignId = selectCampaignId(match) as number
  return {
    followerCount: selectInfluencerIdentityFollowerCount(
      state,
      campaignId,
      contextInfluencerIdentityId,
    ),
  }
}

export { InfluencerIdentityRowPerformanceFollowerCount }

export default compose<IInternalProps, {}>(
  withRouter,
  withContextInfluencerIdentityId,
  connect(mapStateToProps),
)(InfluencerIdentityRowPerformanceFollowerCount)
