import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Container, Text } from '@tribegroup'

const PostCampaignReportSubmissionListPerformanceEmpty: React.FC = () => (
  <Container leftOuterSpacing={0.75}>
    <Text italic small color={Theme.grey700Color}>
      <FormattedMessage id="postCampaignReport.submission.list.performance.empty" />
    </Text>
  </Container>
)

export { PostCampaignReportSubmissionListPerformanceEmpty }
export default PostCampaignReportSubmissionListPerformanceEmpty
