import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { EventTrackingNames } from '@enums'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import { saveCampaign, selectCampaignIsSaving } from '@store/campaigns'
import { selectCampaignIdFromDraft } from '@store/drafts'
import { selectIsAnyUploading } from '@store/moodboard/selectors'
import { Button } from '@tribegroup'
import { useIsButtonLoading } from './useIsButtonLoading'

interface IInternalProps extends ConnectedProps<typeof connector>, IBuilderValidationContextProps {}

const BuilderTitleBarActionsButtonSaveDraft: React.FC<IInternalProps> = ({
  campaignId,
  isSaving,
  isUploading,
  saveCampaign: saveCampaignAction,
  formChanged,
  setFormChanged,
  isEnableActions,
}) => {
  const { loading, startLoading } = useIsButtonLoading(isSaving)

  const disabled =
    Boolean(campaignId) && (!formChanged || isSaving || !isEnableActions || isUploading)

  const onClick = () => {
    if (!disabled) {
      startLoading()
      saveCampaignAction()
      setFormChanged(false)
    }
  }

  return (
    <ConnectedTrackedRoute
      eventName={EventTrackingNames.CampaignBuilderSaveDraft}
      campaignId={campaignId}
      additionalProperties={{
        current_filter: undefined,
      }}
    >
      <Button
        width={10}
        scheme="primary"
        outlined
        small
        nowrap
        onClick={onClick}
        rightOuterSpacing={0.75}
        disabled={disabled}
        loading={loading}
        type="button"
        data-cy-element="save-button"
        data-testid="campaign-builder-save-draft-button"
      >
        <FormattedMessage id="core.text.saveDraft" />
      </Button>
    </ConnectedTrackedRoute>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  const campaignId = selectCampaignIdFromDraft(state)
  return {
    campaignId,
    isSaving: selectCampaignIsSaving(state),
    isUploading: campaignId ? selectIsAnyUploading(state, campaignId) : undefined,
  }
}

const mapDispatchToProps = {
  saveCampaign,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export default compose<IInternalProps, {}>(
  withBuilderValidation,
  connector,
)(BuilderTitleBarActionsButtonSaveDraft)
