import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { Grid } from 'react-styled-flexboxgrid'

import AnalyticsPostCampaignReportMetric from '@components/Analytics/PostCampaignReport/Metric'
import {
  StyledCol,
  StyledRow,
} from '@components/Analytics/PostCampaignReport/Summary/Summary.styled'
import { IAudienceReporting } from '@store/audienceReporting'
import Theme from '@theme'
import { Container, H3 } from '@tribegroup'

type IInternalProps = {
  audienceReporting: IAudienceReporting
}

export const AudienceReportingSummary: React.FC<IInternalProps> = ({
  audienceReporting: {
    summary: { overall_cpm, overall_cpm_currency, total_impressions, total_posts, unique_creators },
  },
}) => {
  return (
    <Fragment>
      <H3 color={Theme.grey900Color}>
        <FormattedMessage id="core.text.summary" />
      </H3>
      <Container topOuterSpacing={2.5}>
        <Grid fluid>
          <StyledRow>
            <StyledCol xs={6} lg={3}>
              <AnalyticsPostCampaignReportMetric
                translationPrefix="analytics.audienceReporting.summary"
                title="totalPosts"
                value={total_posts}
              />
            </StyledCol>
            <StyledCol xs={6} lg={3}>
              <AnalyticsPostCampaignReportMetric
                translationPrefix="analytics.audienceReporting.summary"
                title="uniqueCreators"
                value={unique_creators}
              />
            </StyledCol>
            <StyledCol xs={6} lg={3}>
              <AnalyticsPostCampaignReportMetric
                title="totalImpressions"
                translationPrefix="analytics.audienceReporting.summary"
                value={total_impressions}
              />
            </StyledCol>
            <StyledCol xs={6} lg={3}>
              <AnalyticsPostCampaignReportMetric
                title="overallCPM"
                translationPrefix="analytics.audienceReporting.summary"
                value={overall_cpm}
                currency={overall_cpm_currency}
              />
            </StyledCol>
          </StyledRow>
        </Grid>
      </Container>
    </Fragment>
  )
}

export default AudienceReportingSummary
