import styled, { css } from 'styled-components'

import Theme from '@theme'

const InfluencerIdentityRowRoot = styled.div`
  background-color: ${Theme.whiteColor};

  &:first-of-type {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 0.5rem 0.5rem;
  }

  &:not(:first-of-type) {
    border-top: 1px solid ${Theme.grey200Color};
  }
`

const InfluencerIdentityRowClickableArea = styled.div`
  cursor: default;
  padding: 1rem 1.25rem;
  ${({ opened }) =>
    opened &&
    css`
      border-radius: 0 0 0.5rem 0.5rem;
    `}
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
  ${({ horizontalPadding }) =>
    typeof horizontalPadding === 'number' &&
    css`
      padding-left: ${horizontalPadding}rem;
      padding-right: ${horizontalPadding}rem;
    `}
`

export { InfluencerIdentityRowRoot, InfluencerIdentityRowClickableArea }
