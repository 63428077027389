import { IResponseError } from '@lib/error'
import { IIdentityAnalytics } from '@store/submissions'
import { IHateoasLink } from '..'

export interface IInfluencerIdentityState {
  readonly isFetching: boolean
  readonly errors?: IResponseError
  readonly influencerIdentitiesByCampaignId: Record<number, ReadonlyArray<IInfluencerIdentity>>
  readonly influencerIdentityLinksByCampaignId: Record<number, ReadonlyArray<IHateoasLink>>
}

export interface IInfluencerIdentity {
  id: number
  influencer_gender: string
  influencer_state: string
  influencer_country: string
  influencer_rating: number
  influencer_age: number
  influencer_new: boolean
  influencer_first_name: string
  influencer_last_name: string
  influencer_full_name: string
  influencer_is_proxy?: boolean
  previously_purchased: boolean
  identity_avatar: string
  identity_followers_count: number
  identity_post_count: number
  identity_provider: string
  identity_analytics: IIdentityAnalytics
  identity_audience_insights_available: boolean
  identity_social_page_url: string
  identity_username: string
  identity_any_shortlisted_submissions: boolean
  identity_has_feedback: boolean
  identity_has_unread_feedback: boolean
  identity_estimated_cpe: number
  identity_estimated_cpe_currency: string
  identity_estimated_cpe_average: boolean
  identity_brand_fan_group_ids: ReadonlyArray<number>
  links: ReadonlyArray<IHateoasLink>
}

export enum InfluencerIdentitiesActionTypes {
  FETCH_REQUEST = 'influencerIdentities/FETCH_REQUEST',
  FETCH_SUCCESS = 'influencerIdentities/FETCH_SUCCESS',
  FETCH_ERROR = 'influencerIdentities/FETCH_ERROR',
}

export enum InfluencerIdentityActionTypes {
  UPDATE_INFLUENCER_IDENTITY = 'influencerIdentity/UPDATE_INFLUENCER_IDENTITY',
  REMOVE_INFLUENCER_IDENTITY = 'influencerIdentity/REMOVE_INFLUENCER_IDENTITY',
}

export enum InfluencerIdentitiesUsingNextActionTypes {
  FETCH_REQUEST = 'influencerIdentitiesUsingNext/FETCH_REQUEST',
  FETCH_SUCCESS = 'influencerIdentitiesUsingNext/FETCH_SUCCESS',
  FETCH_ERROR = 'influencerIdentitiesUsingNext/FETCH_ERROR',
}
