import React from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { useSelector } from 'react-redux'
import { FlexItem } from 'styled-flex-component'

import Avatar from '@components/UI/Avatar'
import { FeatureToggles } from '@enums'
import { IApplicationState } from '@store'
import { selectIsSocialCampaign } from '@store/campaigns'
import { selectInfluencerHeadshot, selectInfluencerInitials } from '@store/submissions'
import Theme from '@theme'
import { Container, H5 } from '@tribegroup'
import { PlaceholderAvatar } from '../Header.styled'
import SubmissionCardHeaderAvatarSocialProfileAvatar from './SocialProfileAvatar'

const DefaultAvatar: React.FC<{ submissionId: number }> = ({ submissionId }) => {
  const { initials, headShot } = useSelector((state: IApplicationState) => {
    return {
      initials: selectInfluencerInitials(state, submissionId),
      headShot: selectInfluencerHeadshot(state, submissionId),
    }
  })

  const isBrandFansEnabledForContentCampaigns = useFlag(
    FeatureToggles.BRAND_FANS_FROM_CONTENT_CAMPAIGNS_ENABLED,
  )

  if (headShot) {
    if (isBrandFansEnabledForContentCampaigns) {
      return <Avatar size={2.5} avatarSrc={headShot.thumbnail_url} />
    }

    return (
      <FlexItem noShrink>
        <Container relative rightOuterSpacing={0.75} height={2.5}>
          <Avatar size={2.5} avatarSrc={headShot.thumbnail_url} />
        </Container>
      </FlexItem>
    )
  }

  return (
    <PlaceholderAvatar alignCenter justifyCenter>
      <FlexItem>
        <H5 color={Theme.grey900Color}>{initials}</H5>
      </FlexItem>
    </PlaceholderAvatar>
  )
}

interface ISubmissionCardHeaderAvatarProps {
  campaignId: number
  submissionId: number
  onClick?: (event: React.SyntheticEvent<HTMLAnchorElement>) => void
}

export const SubmissionCardHeaderAvatar: React.FC<ISubmissionCardHeaderAvatarProps> = ({
  campaignId,
  onClick,
  submissionId,
}) => {
  const showSocialProfile = useSelector((state: IApplicationState) =>
    selectIsSocialCampaign(state, campaignId),
  )

  const isBrandFansEnabledForContentCampaigns = useFlag(
    FeatureToggles.BRAND_FANS_FROM_CONTENT_CAMPAIGNS_ENABLED,
  )

  if (showSocialProfile) {
    return (
      <SubmissionCardHeaderAvatarSocialProfileAvatar
        onClick={onClick}
        submissionId={submissionId}
      />
    )
  }

  if (isBrandFansEnabledForContentCampaigns) {
    return (
      <SubmissionCardHeaderAvatarSocialProfileAvatar
        onClick={onClick}
        submissionId={submissionId}
        avatar={<DefaultAvatar submissionId={submissionId} />}
      />
    )
  }

  return <DefaultAvatar submissionId={submissionId} />
}

export default SubmissionCardHeaderAvatar
