import { useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { useModal } from '@hooks/useModal'
import { IApplicationState } from '@store'
import {
  selectCampaignInsufficientCreditForActivationError,
  selectIsActivating,
} from '@store/campaigns'
import { selectHasInsufficientFundsForPurchase, selectIsMakingAPurchase } from '@store/submissions'
import Theme from '@theme'
import { Button, Container, H3, Modal } from '@tribegroup'
import { Message } from './NotEnoughCreditModal.styled'

const CampaignNotEnoughCreditModal = () => {
  const { hideModal } = useModal()

  const [isShownModal, setShownModal] = useState(false)

  const {
    isMakingPurchase,
    isActivating,
    hasInsufficientFunds,
    hasInsufficientCreditForActivatingBrief,
  } = useSelector((state: IApplicationState) => {
    return {
      isMakingPurchase: selectIsMakingAPurchase(state),
      isActivating: selectIsActivating(state),
      hasInsufficientFunds: selectHasInsufficientFundsForPurchase(state),
      hasInsufficientCreditForActivatingBrief: selectCampaignInsufficientCreditForActivationError(
        state,
      ),
    }
  })

  const [wasMakingPurchase, setWasMakingPurchase] = useState(false)

  useEffect(() => {
    if (
      wasMakingPurchase &&
      !isMakingPurchase &&
      (hasInsufficientFunds || hasInsufficientCreditForActivatingBrief)
    ) {
      hideModal()
      setShownModal(true)
    }
  }, [
    isMakingPurchase,
    isActivating,
    hasInsufficientFunds,
    hasInsufficientCreditForActivatingBrief,
  ])

  useEffect(() => {
    setWasMakingPurchase(isMakingPurchase || isActivating)
  }, [isMakingPurchase, isActivating, setWasMakingPurchase])

  return (
    <Modal reactModalProps={{ isOpen: isShownModal }} width={20}>
      <H3 color={Theme.defaultColor} bottomOuterSpacing={0.75}>
        <FormattedMessage id="campaign.notEnoughCredit.modal.header" />
      </H3>
      <Message>
        <FormattedMessage id="campaign.notEnoughCredit.modal.message" />
      </Message>
      <Container flexEnd>
        <Button scheme="primary" onClick={() => setShownModal(false)} small>
          <FormattedMessage id="core.text.okay" />
        </Button>
      </Container>
    </Modal>
  )
}

export default CampaignNotEnoughCreditModal
