import { createSelector } from 'reselect'

import { IApplicationState } from '@store'
import { IAudienceInsights } from '@store/audienceInsights'

export const selectAudienceInsights = createSelector(
  (state: IApplicationState) => state.audienceInsights,
  (state: IApplicationState, identityId: number) => identityId,
  (audienceInsights, identityId: number) =>
    audienceInsights.audienceInsightsByIdentityId[identityId] || ({} as IAudienceInsights),
)

export const selectAudienceInsightsIsFetching = (state: IApplicationState) =>
  state.audienceInsights.isFetching
