import React from 'react'

import { Button, Header } from '@tribegroup'

interface ILicensingButtonProps {
  disabled?: boolean
  loading?: boolean
  onClick?: (event: React.FormEvent<HTMLInputElement>) => void
  elementName?: string
  children: React.ReactNode
}

export const LicensingButton = ({
  disabled,
  loading,
  onClick,
  elementName,
  children,
}: ILicensingButtonProps) => (
  <Button
    fullWidth
    scheme="primary"
    disabled={disabled}
    loading={loading}
    onClick={onClick}
    bottomOuterSpacing={1.25}
    height={3}
    data-cy-element={elementName}
  >
    <Header cta>{children}</Header>
  </Button>
)

export default LicensingButton
