import { useCallback, useEffect, useState } from 'react'

import { useDropzone } from 'react-dropzone'

import { AspectRatios, MediaTypes } from '@enums'
import { IMedia, IMoodboardItem } from '@store/moodboard'
import { SquareWrapper } from '@tribegroup/FileDrop/FileDrop.styled'
import { moodboardFileValidator } from '@utils/moodboard'
import MoodboardItemEmpty from './Empty'
import MoodboardItemFilled from './Filled'
import MoodboardItemLoading from './Loading'

interface IProps {
  position: number
  media?: IMoodboardItem
  disableDelete?: boolean
  onImageDropped: (images: ReadonlyArray<IMedia>) => void
  onCloseClick: (position: number) => void
}

interface IGetMediaDetailsResponse {
  mediaType?: string | null
  mediaUrl?: string | null
}

const ACCEPTED_VIDEO_FILE_TYPES = ['video', 'video/mp4']
const ACCEPTED_IMAGE_FILE_TYPES = ['image', 'image/jpeg', 'image/png']
const ACCEPTED_FILE_TYPES = [...ACCEPTED_VIDEO_FILE_TYPES, ...ACCEPTED_IMAGE_FILE_TYPES]

const getMediaDetails = (media?: IMoodboardItem): IGetMediaDetailsResponse => {
  if (media?.file) {
    return {
      mediaType: media.file.type,
      mediaUrl: URL.createObjectURL(media.file),
    }
  }

  if (media?.moodboard) {
    return {
      mediaType: media.moodboard.media_type,
      mediaUrl: media.moodboard.media_url,
    }
  }

  return {
    mediaType: undefined,
    mediaUrl: undefined,
  }
}

export const MoodboardItem: React.FC<IProps> = ({
  media,
  position,
  disableDelete,
  onImageDropped,
  onCloseClick,
}) => {
  const [acceptedMediaList, setAcceptedMediaList] = useState<ReadonlyArray<IMedia>>([])

  useEffect(() => {
    if (acceptedMediaList.length > 0) {
      onImageDropped(acceptedMediaList)
      setAcceptedMediaList([])
    }
  }, [acceptedMediaList])

  const onDropAccepted = useCallback(async (acceptedFiles: ReadonlyArray<File>) => {
    const files = await Promise.all(
      acceptedFiles.map(async (acceptedFile) => {
        const error = await moodboardFileValidator(acceptedFile, ACCEPTED_VIDEO_FILE_TYPES)

        return {
          file: acceptedFile,
          errors: error ? [error] : undefined,
        }
      }),
    )

    setAcceptedMediaList(files)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ACCEPTED_FILE_TYPES.join(','),
    multiple: true,
    onDropAccepted,
  })

  const onClose = () => {
    onCloseClick(position)
  }

  const { mediaType, mediaUrl } = getMediaDetails(media)

  const errorCode = media?.uploadFailed ? 'upload-failed' : media?.errors?.find(Boolean)?.code
  const disableDeleteButton =
    (disableDelete || !media?.preSignedUrlItem || media.isDeleting) && !media?.isUploading

  return (
    <SquareWrapper aspectRatio={AspectRatios.FIVE_BY_FOUR}>
      {media && !media?.errors && (
        <MoodboardItemFilled
          mediaUrl={mediaUrl}
          isLoading={media?.isUploading}
          isDeleting={media?.isDeleting}
          mediaType={
            ACCEPTED_VIDEO_FILE_TYPES.includes(`${mediaType}`) ? MediaTypes.VIDEO : MediaTypes.IMAGE
          }
          disableDelete={disableDeleteButton}
          onCloseClick={onClose}
        />
      )}

      {!(media?.file instanceof File) && media?.preSignedUrlItem?.status === 'uploaded' && (
        <MoodboardItemLoading />
      )}
      {(!media || errorCode) && (
        <MoodboardItemEmpty
          errorCode={errorCode}
          {...getRootProps({ refKey: 'innerRef' })}
          isDragActive={isDragActive}
        >
          <input {...getInputProps()} />
        </MoodboardItemEmpty>
      )}
    </SquareWrapper>
  )
}

export default MoodboardItem
