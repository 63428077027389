import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchSubmissionPerformance,
  fetchSubmissionPerformanceError,
  fetchSubmissionPerformanceSuccess,
  ISubmissionPerformance,
  SubmissionPerformanceActionTypes,
} from '@store/performance'
import { selectSubmissionPerformanceLink } from '@store/submissions'
import { networkRequest } from '@utils'
import { WatcherFunction } from '..'

export function* handleFetchSubmissionPerformance(
  action: ReturnType<typeof fetchSubmissionPerformance>,
) {
  const submissionId = action.payload

  try {
    const authToken: string = yield select(selectAuthToken)
    const submissionPerformanceLink: IHateoasLink = yield select(
      selectSubmissionPerformanceLink,
      submissionId,
    )

    const submissionPerformance: ISubmissionPerformance = yield call(
      networkRequest,
      submissionPerformanceLink.method,
      submissionPerformanceLink.href,
      undefined,
      authToken,
    )

    yield put(fetchSubmissionPerformanceSuccess(submissionId, submissionPerformance))
  } catch (error) {
    yield put(fetchSubmissionPerformanceError(error))
  }
}

function* watchFetchSubmissionPerformance() {
  yield takeLatest(SubmissionPerformanceActionTypes.FETCH_REQUEST, handleFetchSubmissionPerformance)
}

export const submissionPerformanceSagas: ReadonlyArray<WatcherFunction> = [
  watchFetchSubmissionPerformance,
]
