import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import Flex, { FlexItem } from 'styled-flex-component'

import { LogoLink } from '@components/UI/TitleBar'
import TitleBarStyled from '@components/UI/TitleBar/TitleBar.styled'
import { Container } from '@tribegroup'
import MediaObjectTitleBarBackButton from './BackButton/BackButton'
import MediaObjectTitleBarHeader from './Header/Header'
import MediaObectTitleBarHeadTag from './HeadTag/HeadTag'

export const MediaObjectTitleBar = () => (
  <TitleBarStyled>
    <MediaObectTitleBarHeadTag />
    <Flex alignCenter full>
      <Container absolute>
        <LogoLink />
      </Container>
      <Col xs={false} sm>
        <Flex full alignCenter>
          <FlexItem grow={1}>
            <Grid>
              <Row center="xs" middle="xs">
                <Col sm={8} md={10}>
                  <Flex full alignCenter justifyBetween>
                    <FlexItem>
                      <MediaObjectTitleBarBackButton />
                    </FlexItem>
                    <FlexItem grow={1}>
                      <MediaObjectTitleBarHeader />
                    </FlexItem>
                  </Flex>
                </Col>
              </Row>
            </Grid>
          </FlexItem>
        </Flex>
      </Col>
      <Col xs sm={false}>
        <Flex full alignCenter justifyCenter>
          <FlexItem>
            <MediaObjectTitleBarHeader />
          </FlexItem>
        </Flex>
      </Col>
    </Flex>
  </TitleBarStyled>
)

export default MediaObjectTitleBar
