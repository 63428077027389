import { createSelector } from 'reselect'

import { EMPTY_LIST, SHAREABLE_CONTENT_LIBRARY } from '@constants'
import {
  ContentLibraryFilterTypes,
  ErrorCodes,
  LicenseStatuses,
  MediaTypes,
  PageRoutes,
  SubmissionStatuses,
} from '@enums'
import { IResponseError, IResponseErrorMessage } from '@lib/error'
import { IApplicationState, IHateoasLink } from '@store'
import { ILicenseOption, selectCreateCampaignLink } from '@store/campaigns'
import { selectMediaObjectLicenseOptions } from '@store/licenses'
import { IMediaObject, IShareableMediaObject, ITransactedLicense } from '@store/mediaObjects'
import { selectContentLibraryFilter, selectCurrentFilter } from '@store/router'
import { isEmpty, selectNextId, selectPrevId } from '@utils'

export const selectMediaObjectsStats = (state: IApplicationState) => state.mediaObjects.stats

const selectIds = (state: IApplicationState, mediaObjectIds: ReadonlyArray<number>) =>
  mediaObjectIds
const selectState = (state: IApplicationState) => state

export const selectMediaObjectIdsByFilter = (state: IApplicationState) =>
  state.mediaObjects.mediaObjectIdsByFilter

export const selectFilteredMediaObjectIds = createSelector(
  selectMediaObjectIdsByFilter,
  (state, filter) => filter,
  (idsByFilter, filter) => idsByFilter[filter] || EMPTY_LIST,
)

export const selectShareableContentLibraryMediaObjectIds = (state: IApplicationState) =>
  selectFilteredMediaObjectIds(state, SHAREABLE_CONTENT_LIBRARY)

export const selectFilteredMediaObjects = createSelector(
  selectFilteredMediaObjectIds,
  (state) => state.mediaObjects.mediaObjectsById,
  (mediaObjectIds, mediaObjectsById) => mediaObjectIds.map((id) => mediaObjectsById[id]),
)

export const selectMediaObject = (state: IApplicationState, mediaObjectId: number): IMediaObject =>
  state.mediaObjects.mediaObjectsById[mediaObjectId] || {}

export const selectIsMediaObjectLoaded = createSelector(
  selectMediaObject,
  (mediaObject) => !isEmpty(mediaObject),
)

export const selectMediaObjectIsFetching = (state: IApplicationState) =>
  state.mediaObjects.isFetching

export const selectMediaObjectIsRequestingLicense = (state: IApplicationState) =>
  state.mediaObjects.isRequestingLicense

export const selectMediaObjectErrors = (state: IApplicationState) => state.mediaObjects.errors

export const selectFirstErrorMessage = createSelector(
  selectMediaObjectErrors,
  (errors: IResponseError) => errors && errors.messages && errors.messages[0],
)

export const selectFirstErrorCode = createSelector(
  selectFirstErrorMessage,
  (error: IResponseErrorMessage) => error && error.error_code,
)

export const selectHasInsufficientFundError = createSelector(
  selectFirstErrorCode,
  (errorCode: string) => errorCode === ErrorCodes.ERROR_CODE_LICENSE_INSUFFICIENT_FUNDS,
)

export const selectMediaObjectsStatsTotal = createSelector(
  selectMediaObjectsStats,
  (state: IApplicationState, filter: string) => filter,
  // eslint-disable-next-line no-prototype-builtins
  (stats, filter) => (stats.hasOwnProperty(filter) ? stats[filter] : 0),
)

export const selectShareableContentLibraryTotal = (state: IApplicationState) =>
  selectMediaObjectsStatsTotal(state, 'total')

export const selectShareableContentLibraryHasMore = createSelector(
  selectShareableContentLibraryMediaObjectIds,
  selectShareableContentLibraryTotal,
  (mediaObjectIds: ReadonlyArray<number>, total) => {
    return mediaObjectIds.length < total
  },
)

export const selectMediaObjectsLink = (
  state: IApplicationState,
  filter: string,
  linkRel: string,
) => {
  const linksByFilter = state.mediaObjects.linksByFilter[filter] || EMPTY_LIST
  return linksByFilter.find((link) => link.rel === linkRel)
}

export const selectMediaObjectsNextLink = (state: IApplicationState, filter: string) =>
  selectMediaObjectsLink(state, filter, 'next')

export const selectMediaObjectsSelfLink = (state: IApplicationState, filter: string) =>
  selectMediaObjectsLink(state, filter, 'self')

export const selectLicenseOptionsLink = createSelector(
  selectMediaObject,
  (mediaObject: IMediaObject) =>
    mediaObject.links && mediaObject.links.find((link) => link.rel === 'license_options'),
)

export const selectMediaObjectType = createSelector(
  selectMediaObject,
  (mediaObject) => mediaObject.media_type,
)

export const selectIsVideoMediaType = createSelector(
  selectMediaObjectType,
  (mediaType: MediaTypes) => Boolean(mediaType === MediaTypes.VIDEO),
)

export const selectDimensions = createSelector(
  selectMediaObject,
  (mediaObject: IMediaObject) => mediaObject.dimensions,
)

export const selectPrintableDimensions = createSelector(
  selectMediaObject,
  (mediaObject: IMediaObject) => mediaObject.printable_dimensions,
)

export const selectFileType = createSelector(
  selectMediaObject,
  (mediaObject: IMediaObject) => mediaObject.file_type,
)

export const selectMediaType = createSelector(
  selectMediaObject,
  (mediaObject: IMediaObject) => mediaObject.media_type,
)

export const selectOriginalMediaUrl = createSelector(
  selectMediaObject,
  (mediaObject: IMediaObject) => mediaObject.original_media_url,
)

export const selectThumbnailMediaUrl = createSelector(
  selectMediaObject,
  (mediaObject: IMediaObject) => mediaObject.thumbnail_media_url,
)

// TODO: Merge with the one above
export const selectThumbnailOrPosterMediaUrl = createSelector(
  selectMediaObject,
  (mediaObject: IMediaObject) => mediaObject.thumbnail_media_url,
)

export const selectInfluencerFullName = createSelector(
  selectMediaObject,
  (mediaObject: IMediaObject) => mediaObject.influencer_full_name,
)

export const selectInfluencerFirstName = createSelector(
  selectMediaObject,
  (mediaObject: IMediaObject) => mediaObject.influencer_first_name,
)

export const selectInfluencerLastName = createSelector(
  selectMediaObject,
  (mediaObject: IMediaObject) => mediaObject.influencer_last_name,
)

export const selectInfluencerInitials = createSelector(
  selectInfluencerFirstName,
  selectInfluencerLastName,
  (firstName, lastName) => [firstName, lastName].map((name) => name && name.charAt(0)).join(''),
)

export const selectTransactedLicense = createSelector(
  selectMediaObject,
  (mediaObject: IMediaObject) => mediaObject.transacted_license || ({} as ITransactedLicense),
)

export const selectHasTransactedLicense = createSelector(
  selectTransactedLicense,
  (transactedLicense: ITransactedLicense) => !isEmpty(transactedLicense),
)

export const selectTransactedLicenseStatus = createSelector(
  selectTransactedLicense,
  (transactedLicense: ITransactedLicense) =>
    transactedLicense.status as LicenseStatuses | undefined,
)

export const selectIsLicensed = createSelector(
  selectTransactedLicenseStatus,
  (status?: string) => status === LicenseStatuses.Licensed,
)

export const selectIsLicenseDeclined = createSelector(
  selectTransactedLicenseStatus,
  (status?: string) => status === LicenseStatuses.Declined,
)

export const selectHasRequestedLicense = createSelector(
  selectTransactedLicenseStatus,
  (status?: string) => status === LicenseStatuses.Requested,
)

export const selectLicenseReceiptNumber = createSelector(
  selectTransactedLicense,
  (transactedLicense: ITransactedLicense) => transactedLicense.receipt_number,
)

export const selectLicensePurchasedAt = createSelector(
  selectTransactedLicense,
  (transactedLicense: ITransactedLicense) => transactedLicense.purchased_at,
)

export const selectLicenseLabel = createSelector(
  selectTransactedLicense,
  (transactedLicense: ITransactedLicense) => transactedLicense.label,
)

export const selectLicenseDescription = createSelector(
  selectTransactedLicense,
  (transactedLicense: ITransactedLicense) => transactedLicense.description,
)

export const selectLicenseAmountBeforeTaxCents = createSelector(
  selectTransactedLicense,
  (transactedLicense: ITransactedLicense) => transactedLicense.total_amount_before_tax_cents,
)

export const selectLicenseAmountBeforeTaxCurrency = createSelector(
  selectTransactedLicense,
  (transactedLicense: ITransactedLicense) => transactedLicense.total_amount_before_tax_currency,
)

export const selectPrevMediaObjectId = createSelector(
  selectFilteredMediaObjectIds,
  (state: IApplicationState, filter: string, mediaObjectId) => mediaObjectId,
  (mediaObjectIds: ReadonlyArray<number>, mediaObjectId: number) =>
    selectPrevId(mediaObjectIds, mediaObjectId),
)

export const selectPrevMediaObject = createSelector(
  (state) => state,
  selectPrevMediaObjectId,
  (state: IApplicationState, prevId: number) => selectMediaObject(state, prevId),
)

export const selectPrevMediaObjectLink = createSelector(
  selectPrevMediaObject,
  (mediaObject: IMediaObject) => mediaObject.id && `/${PageRoutes.MediaObjects}/${mediaObject.id}`,
)

export const selectNextMediaObjectId = createSelector(
  selectFilteredMediaObjectIds,
  (state: IApplicationState, filter: string, mediaObjectId) => mediaObjectId,
  (mediaObjectIds: ReadonlyArray<number>, mediaObjectId: number) =>
    selectNextId(mediaObjectIds, mediaObjectId),
)

export const selectNextMediaObject = createSelector(
  (state) => state,
  selectNextMediaObjectId,
  (state: IApplicationState, prevId: number) => selectMediaObject(state, prevId),
)

export const selectNextMediaObjectLink = createSelector(
  selectNextMediaObject,
  (mediaObject: IMediaObject) => mediaObject.id && `/${PageRoutes.MediaObjects}/${mediaObject.id}`,
)

const selectShareableContentLibraryTokenAdapter = (
  state: IApplicationState,
  mediaObjectId: number,
  token: string,
) => token

const selectPrevShareableMediaObjectAdapter = (state: IApplicationState, mediaObjectId) =>
  selectPrevMediaObject(state, SHAREABLE_CONTENT_LIBRARY, mediaObjectId)

const selectNextShareableMediaObjectAdapter = (state: IApplicationState, mediaObjectId) =>
  selectNextMediaObject(state, SHAREABLE_CONTENT_LIBRARY, mediaObjectId)

export const selectPrevShareableMediaObjectLink = createSelector(
  selectPrevShareableMediaObjectAdapter,
  selectShareableContentLibraryTokenAdapter,
  (mediaObject: IMediaObject, token) =>
    mediaObject.id && `/${PageRoutes.ShareableContentLibrary}/${token}/${mediaObject.id}`,
)

export const selectNextShareableMediaObjectLink = createSelector(
  selectNextShareableMediaObjectAdapter,
  selectShareableContentLibraryTokenAdapter,
  (mediaObject: IMediaObject, token) =>
    mediaObject.id && `/${PageRoutes.ShareableContentLibrary}/${token}/${mediaObject.id}`,
)

export const selectIsLoadingMoreMediaObjects = createSelector(
  selectMediaObjectsNextLink,
  selectMediaObjectIsFetching,
  selectPrevMediaObject,
  selectNextMediaObject,
  (
    nextMediaObjectLink: IHateoasLink,
    isFetching: boolean,
    prevMediaObject?: IMediaObject,
    nextMediaObject?: IMediaObject,
  ) => Boolean(nextMediaObjectLink && prevMediaObject && isEmpty(nextMediaObject) && isFetching),
)

export const selectLoadMoreMediaObjects = createSelector(
  selectMediaObjectIsFetching,
  selectMediaObjectsNextLink,
  selectNextMediaObjectLink,
  (isFetching, nextMediaObjectLink?, nextLink?) =>
    Boolean(nextMediaObjectLink && !nextLink && !isFetching),
)

export const selectLicenseOriginalMediaUrl = createSelector(
  selectTransactedLicense,
  (transactedLicense: ITransactedLicense) => transactedLicense.original_media_url,
)

export const selectLicenseTaxDisplayName = createSelector(
  selectTransactedLicense,
  (transactedLicense: ITransactedLicense) =>
    transactedLicense.tax_display_name !== 'N/A' ? transactedLicense.tax_display_name : undefined,
)

export const selectIsLicenseRequestExpired = createSelector(
  selectTransactedLicenseStatus,
  (status: LicenseStatuses) =>
    status === LicenseStatuses.RequestExpired || status === LicenseStatuses.ExpiredDeprecated,
)

export const selectIsLicenseExpired = createSelector(
  selectTransactedLicenseStatus,
  (status: LicenseStatuses) =>
    status === LicenseStatuses.LicenseExpired || status === LicenseStatuses.ExpiredDeprecated,
)

export const selectCardStatusLabel = createSelector(
  selectIsLicenseRequestExpired,
  selectIsLicenseExpired,
  selectIsLicenseDeclined,
  (isLicenseRequestExpired, isLicenseExpired, isDeclined) => {
    if (isLicenseRequestExpired) {
      return LicenseStatuses.RequestExpired
    }
    if (isLicenseExpired) {
      return LicenseStatuses.LicenseExpired
    }
    if (isDeclined) {
      return LicenseStatuses.Declined
    }
    return undefined
  },
)

export const selectShouldShowLicenseOptions = createSelector(
  selectIsLicenseRequestExpired,
  selectHasTransactedLicense,
  (isExpired, hasTransactedLicense) => isExpired || !hasTransactedLicense,
)

export const selectLicenseOptionsRequired = createSelector(
  (state, mediaObjectId) => selectMediaObjectLicenseOptions(state, mediaObjectId),
  selectHasTransactedLicense,
  selectIsLicenseRequestExpired,
  (licenseOptions, hasTransactedLicense, isLicenseRequestExpired) => {
    const hasLicenseOptions = !isEmpty(licenseOptions)
    return (!hasLicenseOptions && !hasTransactedLicense) || isLicenseRequestExpired
  },
)

export const selectIsLoadingLicenseOptions = createSelector(
  selectLicenseOptionsRequired,
  (state, mediaObjectId) => selectMediaObjectLicenseOptions(state, mediaObjectId),
  (licenseOptionsRequired, licenseOptions) => licenseOptionsRequired && isEmpty(licenseOptions),
)

const selectIsMediaObjectLoadedAdapter = (state, filter, mediaObjectId) =>
  selectIsMediaObjectLoaded(state, mediaObjectId)

export const selectIsLoadingFullPage = createSelector(
  selectIsMediaObjectLoadedAdapter,
  selectIsLoadingMoreMediaObjects,
  (isMediaObjectLoaded: boolean, isLoadingMore: boolean) => {
    return isLoadingMore || !isMediaObjectLoaded
  },
)

const selectMediaObjectLink = createSelector(
  selectMediaObject,
  (state, mediaObjectId, rel) => rel,
  (mediaObject, rel) => mediaObject.links && mediaObject.links.find((link) => link.rel === rel),
)

export const selectUploadToFacebookLink = (state: IApplicationState, mediaObjectId: number) =>
  selectMediaObjectLink(state, mediaObjectId, 'upload_to_facebook')

export const selectMediaObjectIsUploadedToFacebookAds = createSelector(
  selectMediaObject,
  (mediaObject) => Boolean(mediaObject.uploaded_to_facebook_ads),
)

export const selectShareableContentLibraryLink = (state: IApplicationState) => {
  const shareableCLLink = selectMediaObjectsLink(
    state,
    ContentLibraryFilterTypes.DISCOVER,
    SHAREABLE_CONTENT_LIBRARY,
  )
  const shareableCLToken = state.mediaObjects.shareableCLToken
  if (shareableCLLink) {
    return shareableCLLink.href
  }
  if (shareableCLToken) {
    return `${window.location.origin}/${PageRoutes.ShareableContentLibrary}/${shareableCLToken}`
  }
  return undefined
}

export const selectShowShareLibraryButton = createSelector(
  selectShareableContentLibraryLink,
  selectContentLibraryFilter,
  (link, filter) => {
    if (filter !== ContentLibraryFilterTypes.DISCOVER) {
      return false
    }
    return Boolean(link)
  },
)

export const selectShowContentLibraryButtons = createSelector(
  selectCreateCampaignLink,
  selectShareableContentLibraryLink,
  (createCampaignLink, shareThisLibraryLink) =>
    Boolean(createCampaignLink) || Boolean(shareThisLibraryLink),
)

const selectShareableMediaObject = createSelector(
  selectMediaObject,
  (mediaObject) => mediaObject as IShareableMediaObject,
)

export const selectShareableContentLibraryLicenseOptions = createSelector(
  selectShareableMediaObject,
  (mediaObject) => mediaObject.license_options || EMPTY_LIST,
)

export const selectShareableContentLibraryMinumunLicenseOption = createSelector(
  selectShareableContentLibraryLicenseOptions,
  (licenseOptions: ReadonlyArray<ILicenseOption>) =>
    licenseOptions.reduce(
      (accum, curr) =>
        accum.total_amount_before_tax_cents < curr.total_amount_before_tax_cents ? accum : curr,
      {} as ILicenseOption,
    ),
)

export const selectShareableContentLibraryAmount = createSelector(
  selectShareableContentLibraryMinumunLicenseOption,
  (licenseOption: ILicenseOption) => licenseOption.total_amount_before_tax_cents,
)

export const selectShareableContentLibraryTax = createSelector(
  selectShareableContentLibraryMinumunLicenseOption,
  (licenseOption: ILicenseOption) => licenseOption.tax_display_name,
)

export const selectShareableContentLibraryCurrency = createSelector(
  selectShareableContentLibraryMinumunLicenseOption,
  (licenseOption: ILicenseOption) => licenseOption.total_amount_before_tax_currency,
)

export const selectIsContentLibraryEmpty = createSelector(
  selectMediaObjectsStats,
  (stats) => ![stats.discover, stats.license_requests, stats.licensed].some(Boolean),
)

const selectLicenseStatusAdapter = (
  state: IApplicationState,
  mediaObjectIds: ReadonlyArray<number>,
  licenseStatus?: LicenseStatuses[],
) => licenseStatus

export const selectFirstMediaObjectWithTransactedLicense = createSelector(
  selectState,
  selectIds,
  selectLicenseStatusAdapter,
  (state, mediaObjectIds, licenseStatus) => {
    const mediaObjectId = mediaObjectIds.find((mediaObjectId) => {
      const transactedLicense = selectTransactedLicense(state, mediaObjectId)

      if (licenseStatus && !licenseStatus.includes(transactedLicense.status as LicenseStatuses)) {
        return false
      }

      return !isEmpty(transactedLicense)
    })

    if (!mediaObjectId) {
      return {} as IMediaObject
    }
    return selectMediaObject(state, mediaObjectId)
  },
)

export const selectMediaObjectsHaveTransactedLicense = createSelector(
  selectState,
  selectIds,
  (state: IApplicationState, mediaObjectIds: ReadonlyArray<number>) =>
    mediaObjectIds.some((mediaObjectId) => selectHasTransactedLicense(state, mediaObjectId)),
)

export const selectMediaObjectsHaveLicensedTransactedLicense = createSelector(
  selectState,
  selectIds,
  (state: IApplicationState, mediaObjectIds: ReadonlyArray<number>) =>
    mediaObjectIds.some((mediaObjectId) => selectIsLicensed(state, mediaObjectId)),
)

export const selectMediaObjectsHaveLicenseOptions = createSelector(
  selectState,
  selectIds,
  (state: IApplicationState, mediaObjectIds: ReadonlyArray<number>) =>
    mediaObjectIds.some((mediaObjectId) => Boolean(selectLicenseOptionsLink(state, mediaObjectId))),
)

export const selectIsLicenseRequested = createSelector(
  selectTransactedLicense,
  (license: ITransactedLicense) => license.status === LicenseStatuses.Requested,
)

const selectIsSocialAdapter = (state, mediaObjectId, isSocial) => isSocial

export const selectIsFilterLicenseRelated = createSelector(selectCurrentFilter, (currentFilter) =>
  [SubmissionStatuses.Licensed, SubmissionStatuses.LicenseRequest].includes(
    currentFilter as SubmissionStatuses,
  ),
)

export const selectShouldShowLicenseOptionsPrice = createSelector(
  selectHasTransactedLicense,
  selectIsLicenseRequestExpired,
  selectIsSocialAdapter,
  (hasTransactedLicense, isLicenseRequestExpired, isSocial) => {
    if (isSocial) {
      return false
    }
    return isLicenseRequestExpired || !hasTransactedLicense
  },
)

export const selectShouldShowLicensePrice = createSelector(
  selectIsSocialAdapter,
  selectIsFilterLicenseRelated,
  selectIsLicensed,
  selectIsLicenseRequested,
  selectIsLicenseDeclined,
  selectHasTransactedLicense,
  (
    isSocial,
    isFilterLicenseRelated,
    isLicensed,
    isLicenseRequested,
    isLicenseDeclined,
    hasTransactedLicense,
  ) => {
    if (isSocial && !isFilterLicenseRelated) {
      return false
    }

    return isLicensed || isLicenseRequested || isLicenseDeclined || hasTransactedLicense
  },
)

export const selectShouldShowLicenseRelatedPrice = createSelector(
  selectShouldShowLicenseOptionsPrice,
  selectShouldShowLicensePrice,
  (shouldShowLicenseOptionPrice, shouldShowLicensePrice) =>
    shouldShowLicenseOptionPrice || shouldShowLicensePrice,
)

export const selectFetchMediaObjectsLink = createSelector(
  selectMediaObjectsNextLink,
  selectMediaObjectsSelfLink,
  (state, filter, isFetchNext) => isFetchNext,
  (state, filter, isFetchNext, fallbackSelector) => fallbackSelector(state),
  (nextLink, selfLink, isFetchNext, fallbackLink) => {
    if (isFetchNext) {
      return nextLink
    }
    if (selfLink) {
      return selfLink
    }
    return fallbackLink
  },
)

export const selectInfluencerAvatar = createSelector(
  selectMediaObject,
  (mediaObject: IMediaObject) => mediaObject.influencer_avatar,
)
