import React, { useContext } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import AnalyticsPostCampaignReportListViewProfile from '@components/Analytics/PostCampaignReport/ListView/Profile/Profile'
import { CampaignContext } from '@context/Campaign'
import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { selectPostMetricIds } from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Container } from '@tribegroup'
import AnalyticsPostCampaignReportListViewHeaders from '../../ListView/Headers/Headers'
import AnalyticsPostCampaignReportListView from '../../ListView/ListView'
import AnalyticsPostCampaignReportPostListItem from './Item/Item'

type IInternalProps = ConnectedProps<typeof connector>

export const getBackgroundColor = (index) =>
  index % 2 === 0 ? Theme.whiteColor : Theme.grey100Color

const AnalyticsPostCampaignReportPostList: React.FC<IInternalProps> = ({ submissionIds }) => {
  const {
    campaign: { is_price_hidden: isPriceHidden },
  } = useContext(CampaignContext)

  const translationKeys = [
    'published',
    !isPriceHidden && 'spend',
    'followers',
    'likes',
    'comments',
    'eng',
    'engagementPct',
    !isPriceHidden && 'cpe',
  ].filter(Boolean) as ReadonlyArray<string>

  const profiles = submissionIds.map((submissionId, index) => (
    <Container data-testid="post-profile-wrapper" key={`profile-${submissionId}`}>
      <AnalyticsPostCampaignReportListViewProfile
        backgroundColor={getBackgroundColor(index)}
        submissionId={submissionId}
      />
    </Container>
  ))

  return (
    <AnalyticsPostCampaignReportListView
      profiles={profiles}
      headers={
        <AnalyticsPostCampaignReportListViewHeaders
          translationKeys={translationKeys}
          isPriceHidden={isPriceHidden}
        />
      }
    >
      {submissionIds.map((submissionId, index) => (
        <Container key={`post-list-${submissionId}`} data-testid="post-list-item">
          <AnalyticsPostCampaignReportPostListItem
            submissionId={submissionId}
            backgroundColor={getBackgroundColor(index)}
          />
        </Container>
      ))}
    </AnalyticsPostCampaignReportListView>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  const submissionIds = selectPostMetricIds(state, campaignId, PCRChannels.IGPosts)

  return {
    submissionIds,
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(
  withRouter,
  connector,
)(AnalyticsPostCampaignReportPostList)
