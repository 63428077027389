import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { abbreviateNumber } from '@utils'
import { BlockText } from '../Block/Block.styled'

interface IProps {
  minFollowers: number
  maxFollowers: number
}

const CampaignPreviewInfluencerRequirementsFollowers: React.FC<IProps> = ({
  minFollowers,
  maxFollowers,
}) => {
  return (
    <BlockText small color={Theme.grey800Color} capitalize>
      <FormattedMessage
        id="campaign.preview.influencerDetails.followerThreshold"
        values={{
          min: abbreviateNumber(minFollowers),
          max: abbreviateNumber(maxFollowers),
        }}
      />
    </BlockText>
  )
}

export default CampaignPreviewInfluencerRequirementsFollowers
