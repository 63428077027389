import React from 'react'

import Currency from 'react-currency-formatter'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import DetailCard from '@components/UI/DetailCard'
import { IApplicationState } from '@store'
import {
  selectAnalyticsActions,
  selectAnalyticsCostPerView,
  selectAnalyticsCostPerViewCurrency,
  selectAnalyticsFollowers,
  selectAnalyticsImpressions,
  selectAnalyticsStoryViewRate,
  selectAnalyticsUniqueViews,
} from '@store/performance'
import { selectSubmissionId } from '@store/router'
import { abbreviateNumberOrDash } from '@utils'
import { PerformanceDetailCardWithEmptyDefault } from '../Details'
import { PerformanceDetailCardGrid, PerformanceDetailCardWrapper } from '../Details.styled'

interface IInternalProps {
  followers: number
  impressions?: number
  uniqueViews?: number
  storyViewRate?: number
  actions?: number
  costPerView?: number
  costPerViewCurrency?: string
}

const SubmissionPerformanceDetailsStory: React.FC<IInternalProps> = ({
  followers,
  impressions,
  uniqueViews,
  storyViewRate,
  actions,
  costPerView,
  costPerViewCurrency,
}) => (
  <PerformanceDetailCardGrid>
    <PerformanceDetailCardWrapper>
      <DetailCard
        title={<FormattedMessage id={`submission.performance.followers`} />}
        content={abbreviateNumberOrDash(followers)}
        elementName="story-submission-followers"
      />
    </PerformanceDetailCardWrapper>
    <PerformanceDetailCardWithEmptyDefault
      elementName="story-submission-impressions"
      title={<FormattedMessage id={`submission.performance.impressions`} />}
      content={<FormattedNumber value={impressions!} />}
      value={impressions}
    />
    <PerformanceDetailCardWithEmptyDefault
      elementName="story-submission-unique-views"
      title={<FormattedMessage id={`submission.performance.uniqueViews`} />}
      content={<FormattedNumber value={uniqueViews!} />}
      value={uniqueViews}
    />
    <PerformanceDetailCardWithEmptyDefault
      elementName="story-submission-story-view-rate"
      title={<FormattedMessage id={`submission.performance.storyViewRate`} />}
      content={<FormattedMessage id="core.text.percent" values={{ value: storyViewRate }} />}
      value={storyViewRate}
    />
    <PerformanceDetailCardWithEmptyDefault
      elementName="story-submission-actions"
      title={<FormattedMessage id={`submission.performance.actions`} />}
      content={<FormattedNumber value={actions!} />}
      value={actions}
    />
    <PerformanceDetailCardWithEmptyDefault
      elementName="story-submission-cost-per-view"
      title={<FormattedMessage id={`submission.performance.costPerView`} />}
      content={<Currency currency={costPerViewCurrency} quantity={costPerView} />}
      value={costPerView}
    />
  </PerformanceDetailCardGrid>
)

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const submissionId = selectSubmissionId(match)!
  return {
    followers: selectAnalyticsFollowers(state, submissionId),
    impressions: selectAnalyticsImpressions(state, submissionId),
    uniqueViews: selectAnalyticsUniqueViews(state, submissionId),
    storyViewRate: selectAnalyticsStoryViewRate(state, submissionId),
    actions: selectAnalyticsActions(state, submissionId),
    costPerView: selectAnalyticsCostPerView(state, submissionId),
    costPerViewCurrency: selectAnalyticsCostPerViewCurrency(state, submissionId),
  }
}

export { SubmissionPerformanceDetailsStory }
export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps),
)(SubmissionPerformanceDetailsStory)
