import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import Media from '@components/UI/Media/Media'
import { AspectRatios, MediaTypes } from '@enums'
import { IApplicationState } from '@store'
import {
  selectMediaObjectType,
  selectOriginalMediaUrl,
  selectThumbnailMediaUrl,
} from '@store/mediaObjects'
import { selectMediaObjectId } from '@store/router'
import Theme from '@theme'

interface IInternalProps {
  thumbnailUrl: string
  mediaType: MediaTypes
  originalUrl: string
}

export const MediaObjectFullMedia = ({ thumbnailUrl, mediaType, originalUrl }: IInternalProps) => (
  <Media
    withRightSeparatorBorder
    src={thumbnailUrl}
    mediaType={mediaType}
    aspectRatio={AspectRatios.ONE_BY_ONE}
    backgroundColor={Theme.whiteColor}
    fullSizeSrc={originalUrl}
    controls
    expandable
    containImage
  />
)

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const mediaObjectId = selectMediaObjectId(match) as number
  return {
    mediaType: selectMediaObjectType(state, mediaObjectId!),
    thumbnailUrl: selectThumbnailMediaUrl(state, mediaObjectId!),
    originalUrl: selectOriginalMediaUrl(state, mediaObjectId!),
  }
}

export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps),
)(MediaObjectFullMedia)
