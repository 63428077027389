import { createContext, PropsWithChildren, PureComponent } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectCampaignId, selectSubmissionId } from '@store/router'
import { fetchSubmission, ISubmission, selectSubmission } from '@store/submissions'
import { isEmpty } from '@utils'

interface ISubmissionProviderState {
  submission: ISubmission
}
export interface ISubmissionContextProps extends ISubmissionProviderState {
  isSubmissionFetched: boolean
}
interface IInternalProps extends ISubmissionContextProps {
  fetchSubmission: typeof fetchSubmission
  campaignId: number
  submissionId: number
}

export const SubmissionContext = createContext({} as ISubmissionContextProps)

export class SubmissionProvider extends PureComponent<
  PropsWithChildren<IInternalProps>,
  ISubmissionProviderState
> {
  fetchSubmission() {
    const { submissionId, campaignId } = this.props
    if (submissionId && campaignId) {
      this.props.fetchSubmission(submissionId, campaignId)
    }
  }

  componentDidMount() {
    this.fetchSubmission()
  }

  componentDidUpdate(prevProps: IInternalProps) {
    if (
      !this.props.isSubmissionFetched &&
      (prevProps.submission && prevProps.submission.id) !== this.props.submission.id
    ) {
      this.fetchSubmission()
    }
  }

  render() {
    const contextProps: ISubmissionContextProps = {
      submission: this.props.submission,
      isSubmissionFetched: this.props.isSubmissionFetched,
    }

    return (
      <SubmissionContext.Provider value={contextProps}>
        {this.props.children}
      </SubmissionContext.Provider>
    )
  }
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  const submissionId = selectSubmissionId(match) as number
  const submission = selectSubmission(state, submissionId)
  return {
    campaignId,
    submissionId,
    submission,
    isSubmissionFetched: !isEmpty(submission),
  }
}

const mapDispatchToProps = {
  fetchSubmission,
}

export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SubmissionProvider)
