import React, { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'

import { CostBreakdownRow } from '@components/UI/CostBreakdown'
import { Hr } from '@components/UI/Hr/Hr.styled'
import { ILicenseOption } from '@store/campaigns'
import { Container } from '@tribegroup'

interface IProps {
  licenses: ReadonlyArray<ILicenseOption>
}

export const MediaObjectCostBreakdownLicenseOptions: React.FC<IProps> = ({ licenses }) => (
  <React.Fragment>
    {licenses.map((license: ILicenseOption, index) => {
      const isNotLastIndex = index < licenses.length - 1
      const {
        label,
        total_amount_before_tax_cents,
        total_amount_before_tax_currency,
        tax_display_name,
        media_type,
      } = license
      return (
        <Container key={license.label} data-testid="breakdown-license-option">
          <CostBreakdownRow
            medium
            label={`${label} - ${media_type}`}
            currency={total_amount_before_tax_currency!}
            quantity={total_amount_before_tax_cents!}
            priceLabel={
              <Fragment>
                (
                <FormattedMessage
                  id="submission.fullView.pricePanel.tax.text"
                  values={{ taxName: tax_display_name }}
                />
                )
              </Fragment>
            }
          />
          {isNotLastIndex && <Hr />}
        </Container>
      )
    })}
  </React.Fragment>
)

export default MediaObjectCostBreakdownLicenseOptions
