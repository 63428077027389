import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { ICampaignContextProps } from '@context/Campaign'
import { IMultiframeContextProps } from '@context/MultiframeControls'
import { ISubmissionContextProps } from '@context/Submission'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import {
  withCampaign,
  withMultiframeControls,
  withSubmission,
  withSubmissionListFilter,
} from '@hocs'
import { IApplicationState } from '@store'
import { selectHasTransactedLicense } from '@store/mediaObjects'
import { selectActiveSubmissionMediaObject } from '@store/submissions'
import { isSocialCampaign } from '@utils/campaign'
import SubmissionCardDetailsCaption from './Caption/Caption'
import SubmissionCardDetailsContent from './Content/Content'

interface IProps {
  mobile?: boolean
}

interface IStateProps extends IProps, IMultiframeContextProps, ISubmissionContextProps {}
interface IInternalProps
  extends IProps,
    ISubmissionContextProps,
    ICampaignContextProps,
    ISubmissionListFilterContextProps,
    ConnectedProps<typeof connector> {}

export const SubmissionCardDetails: React.FC<IInternalProps> = ({
  submission,
  campaign,
  isFilterLicenseRelated,
  hasTransactedLicense,
  mediaObjectId,
}) => {
  const isContentCampaign = !isSocialCampaign(campaign)

  if (isContentCampaign || (isFilterLicenseRelated && hasTransactedLicense)) {
    return <SubmissionCardDetailsContent mediaObjectId={mediaObjectId} />
  }

  return (
    <SubmissionCardDetailsCaption
      caption={submission.caption}
      isProxy={submission.is_proxy || submission.proxy_submission}
    />
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { activeFrameNumber, submission }: IStateProps,
) => {
  const mediaObject = selectActiveSubmissionMediaObject(state, submission.id, activeFrameNumber)

  const mediaObjectId = mediaObject?.id

  return {
    mediaObjectId,
    hasTransactedLicense: selectHasTransactedLicense(state, mediaObjectId),
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(
  withCampaign,
  withSubmissionListFilter,
  withSubmission,
  withMultiframeControls,
  connector,
)(SubmissionCardDetails)
