import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { BlockText } from '../Block/Block.styled'

interface IProps {
  maxAge: number
  minAge: number
  showPlus: boolean
}

const CampaignPreviewInfluencerRequirementsAge: React.FC<IProps> = ({
  maxAge,
  minAge,
  showPlus,
}) => {
  const max = showPlus ? `${maxAge}+` : maxAge
  return (
    <BlockText small color={Theme.grey800Color}>
      <FormattedMessage
        id="campaign.preview.influencerDetails.age"
        values={{
          ageRange: [minAge, max].filter(Boolean).join('-'),
        }}
      />
    </BlockText>
  )
}

export default CampaignPreviewInfluencerRequirementsAge
