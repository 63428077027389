import React, { PropsWithChildren } from 'react'

import H from 'history'
import { Link } from 'react-router-dom'
import { Col } from 'react-styled-flexboxgrid'

import Theme from '@theme'
import {
  CircledIcon,
  NavigationNextButton,
  NavigationPrevButton,
  NavigationStyled,
} from './ArrowNavigation.styled'

export const ArrowNavigationContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return <NavigationStyled>{children}</NavigationStyled>
}

interface INavigationButtonProps {
  link: H.LocationDescriptor
}

export const PreviousButton = ({ link }: INavigationButtonProps) => (
  <Col xs={false} sm>
    <NavigationPrevButton>
      <Link to={link} data-testid="arrow-navigation-previous-link">
        <CircledIcon glyph="nav-back-short" size={1.3} color={Theme.grey900Color} />
      </Link>
    </NavigationPrevButton>
  </Col>
)

export const NextButton = ({ link }: INavigationButtonProps) => (
  <Col xs={false} sm>
    <NavigationNextButton>
      <Link to={link} data-testid="arrow-navigation-next-link">
        <CircledIcon glyph="nav-fwd-short" size={1.3} color={Theme.grey900Color} />
      </Link>
    </NavigationNextButton>
  </Col>
)

export const DisabledNextButton = () => (
  <Col xs={false} sm>
    <NavigationNextButton>
      <CircledIcon glyph="nav-fwd-short" size={1.3} color={Theme.grey600Color} />
    </NavigationNextButton>
  </Col>
)
