import styled, { css } from 'styled-components'

import { themed } from '../Theme'

export const AutosizeInputWrapper = themed(styled.div`
  position: relative;
  text-align: center;

  ${({ theme, invalid, fontSize, borderColor }) => css`
    input {
      text-align: center;
      outline: none;
      border: none;
      padding-bottom: 0.25rem;
      border-bottom: 1px solid ${borderColor || theme.defaultColor};
      font-size: ${fontSize}rem;
      color: ${theme.defaultColor};
      background: transparent;
      letter-spacing: 0.3px;

      ${invalid &&
        css`
          border-bottom-color: ${theme.errorColor};
        `};
      &::placeholder {
        color: ${theme.grey700Color};
      }

      &:disabled {
        color: ${theme.grey700Color};
        border-bottom-color: ${theme.grey700Color};
      }
    }
  `}
  > div:nth-of-type(2) {
    position: relative;
  }
`)

export const Backdrop = styled.div`
  ${({ fontSize }) => css`
    font-size: ${fontSize}rem;
  `}
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 1px;
  left: -1px;
`

export const Highlights = styled.span`
  color: transparent;
`

export const Highlight = themed(styled.span`
  ${({ theme }) => css`
    background: ${theme.error100Color};
  `}
`)

export const CharacterLimitErrorMessage = styled.div`
  margin-top: 0.5rem;
  text-align: left;
`
