const MONTHS_NAMES: ReadonlyArray<string> = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const getMonthOptions = () => {
  return MONTHS_NAMES.map((month, idx) => ({
    label: `${idx + 1} - ${month}`,
    value: idx + 1,
  }))
}
