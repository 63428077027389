import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import TrackedRoute from '@components/UI/TrackedRoute/TrackedRoute'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { EventTrackingNames } from '@enums'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import { selectFinalizeLink } from '@store/campaigns'
import Theme from '@theme'
import { Button, DatePicker, FormInput, Text, TextField } from '@tribegroup'
import { ScheduleErrorWrapper, SmallAnimatedBlock } from '../Form.styled'
import BuilderSubmitCampaignScheduleDate from './Date/Date'
import BuilderSubmitCampaignScheduleDuration from './Duration/Duration'

interface IBuilderSubmitCampaignScheduleProps {
  onChangeSchedule: (date: Date, endDate?: Date) => void
  onShowForm?: (event: React.SyntheticEvent) => void
  disabledAttrs?: ReadonlyArray<string>
  startDate?: Date
  duration?: number
  campaignId?: number
  campaignType?: string
}

interface IInternalProps
  extends IBuilderSubmitCampaignScheduleProps,
    InjectedIntlProps,
    IBuilderValidationContextProps {
  hasFinalizeLink: boolean
}

export class BuilderSubmitCampaignSchedule extends React.PureComponent<IInternalProps> {
  onChangeDate = (startDate: Date) => {
    this.props.onChangeSchedule(startDate)
    this.props.clearFieldValidation('startDate')
  }

  onChangeDuration = (startDate: Date, endDate: Date) => {
    this.props.onChangeSchedule(startDate, endDate)
    this.props.clearFieldValidation('duration')
  }

  render() {
    const {
      intl,
      startDate,
      duration,
      hasFinalizeLink,
      campaignId,
      campaignType,
      disabledAttrs = [],
      validationResults = {},
    } = this.props
    const enabledButton = Boolean(startDate) && Boolean(duration)

    const disableStartDate = disabledAttrs.includes('start_date')
    const disabledEndDate = disabledAttrs.includes('end_date')

    const hasStartDateErrors = validationResults.startDate && validationResults.startDate.hasError
    const hasDurationErrors = validationResults.duration && validationResults.duration.hasError

    return (
      <React.Fragment>
        <FormInput
          validationRule={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'builder.submit.schedule.startDate.error.required',
              }),
            },
          }}
        >
          <TextField
            name="startDate"
            type="hidden"
            value={(startDate && startDate.toString()) || ''}
            readOnly
          />
        </FormInput>
        <FormInput
          validationRule={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'builder.submit.schedule.duration.error.required',
              }),
            },
          }}
        >
          <TextField name="duration" type="hidden" value={duration || ''} readOnly />
        </FormInput>
        <Flex justifyCenter>
          <div>
            <DatePicker
              width="auto"
              scheme="primary"
              centered
              invalid={hasStartDateErrors}
              datePickerConfig={{
                disabled: disableStartDate,
                customInput: <BuilderSubmitCampaignScheduleDate />,
                onChange: this.onChangeDate,
                selected: startDate,
                minDate: new Date(),
                placeholderText: intl.formatMessage({ id: 'builder.submit.schedule.startDate' }),
              }}
            />
            {hasStartDateErrors && (
              <ScheduleErrorWrapper>
                <Text color={Theme.errorColor}>{validationResults.startDate.errorMessage}</Text>
              </ScheduleErrorWrapper>
            )}
          </div>
          <div>
            <BuilderSubmitCampaignScheduleDuration
              defaultValue={duration}
              disabled={disabledEndDate}
              startDate={startDate}
              onChange={this.onChangeDuration}
              invalid={hasDurationErrors}
            />
            {hasDurationErrors && (
              <ScheduleErrorWrapper hasLeftMargin>
                <Text color={Theme.errorColor}>{validationResults.duration.errorMessage}</Text>
              </ScheduleErrorWrapper>
            )}
          </div>
        </Flex>
        {hasFinalizeLink && (
          <SmallAnimatedBlock>
            <TrackedRoute
              eventName={EventTrackingNames.CampaignBuilderScheduleCampaign}
              properties={{
                brief_id: campaignId,
                brief_type: campaignType,
              }}
            >
              <Button
                scheme="primary"
                disabled={!enabledButton}
                centered
                topOuterSpacing={5}
                onClick={this.props.onShowForm}
                data-cy-element="schedule-campaign-button"
              >
                <FormattedMessage id="builder.submit.schedule.button" />
              </Button>
            </TrackedRoute>
          </SmallAnimatedBlock>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: IApplicationState, { campaignId }) => ({
  hasFinalizeLink: selectFinalizeLink(state, campaignId),
})

export default compose<IBuilderSubmitCampaignScheduleProps, IBuilderSubmitCampaignScheduleProps>(
  injectIntl,
  withBuilderValidation,
  connect(mapStateToProps),
)(BuilderSubmitCampaignSchedule)
