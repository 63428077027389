import React from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import BrandFansGroupRow from '@components/BrandFans/Group/Row'
import Loading from '@components/UI/Loading'
import { BrandFanFilters } from '@enums'
import { IApplicationState } from '@store'
import {
  fetchBrandFanGroups,
  selectBrandFanGroupIds,
  selectIsFetchingInitialBrandFanGroupList,
  selectIsListEmpty,
} from '@store/brandFans'
import { Container } from '@tribegroup'
import InfluencerIdentityListEmpty from '../../InfluencerIdentity/List/Empty'

interface IInternalProps {
  isEmptyList: boolean
  count: number
  isLoadingInitialList: boolean
  fetchBrandFanGroups: typeof fetchBrandFanGroups
  groupIds: ReadonlyArray<number>
}

const InfluencerIdentityGroupList: React.FC<IInternalProps> = ({
  groupIds,
  isEmptyList,
  isLoadingInitialList,
  fetchBrandFanGroups: fetchBrandFanGroupsAction,
}) => {
  React.useEffect(() => {
    fetchBrandFanGroupsAction()
  }, [])

  if (isLoadingInitialList) {
    return <Loading height={30} />
  }

  if (isEmptyList) {
    return <InfluencerIdentityListEmpty type={BrandFanFilters.Groups} />
  }

  return (
    <Container topOuterSpacing={3.75} bottomInnerSpacing={4.5}>
      <div data-testid="brand-fans-groups-list">
        {groupIds.map((id) => (
          <BrandFansGroupRow key={id} groupId={id} />
        ))}
      </div>
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  isEmptyList: selectIsListEmpty(state, BrandFanFilters.Groups),
  isLoadingInitialList: selectIsFetchingInitialBrandFanGroupList(state),
  groupIds: selectBrandFanGroupIds(state),
})

const mapDispatchToProps = {
  fetchBrandFanGroups,
}

export { InfluencerIdentityGroupList }
export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InfluencerIdentityGroupList)
