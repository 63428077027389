import { createAction } from '@reduxjs/toolkit'

import { IReelMetricsResponse, ReelMetricsActionTypes } from './types'

export const fetchReelMetrics = createAction<number>(ReelMetricsActionTypes.FETCH_REQUEST)

export const fetchReelMetricsSuccess = createAction(
  ReelMetricsActionTypes.FETCH_SUCCESS,
  (metrics: IReelMetricsResponse, campaignId: number) => ({
    payload: {
      metrics,
      campaignId,
    },
  }),
)

export const fetchReelMetricsError = createAction<any>(ReelMetricsActionTypes.FETCH_ERROR)
