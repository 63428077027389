import styled, { css } from 'styled-components'

import Theme from '@theme'

const BudgetTrackerStyled = styled.progress`
  display: block;
  background: ${Theme.grey400Color};
  width: 100%;
  height: 0.1875rem;
  border: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  &::-webkit-progress-bar {
    background: ${Theme.grey400Color};
    border-radius: 0.125rem;
  }

  &::-webkit-progress-value {
    ${({ progressColor }) => css`
      background: ${progressColor};
      border-radius: 0.125rem;
    `};
  }

  &::-moz-progress-bar {
    ${({ progressColor }) => css`
      background: ${progressColor};
      border-radius: 0.125rem;
    `};
  }
`

export const AmountWrapperStyled = styled.div`
  min-height: 1rem;
  white-space: nowrap;
  color: ${Theme.grey700Color};

  strong {
    color: ${Theme.grey800Color};
    font-weight: inherit;
  }

  > span {
    display: flex;
    justify-content: flex-start;

    header {
      color: inherit;
      letter-spacing: 0.2px;
      text-transform: none;
    }
  }
`

export default BudgetTrackerStyled
