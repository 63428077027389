import { useEffect } from 'react'

import { useFlagsStatus } from '@unleash/proxy-client-react'
import { useHistory } from 'react-router-dom'

import AuthSignUp from '@components/Auth/SignUp'
import FullImageBackground from '@components/UI/FullImageBackground'
import HeadTag from '@components/UI/HeadTag'
import { DEFAULT_TENANT } from '@constants'

export const SignUpPage = () => {
  const { flagsReady } = useFlagsStatus()
  const history = useHistory()
  const isSignUpEnabled = process.env.APP_NAME !== DEFAULT_TENANT

  useEffect(() => {
    if (flagsReady && !isSignUpEnabled) {
      history.replace('/signIn')
    }
  }, [flagsReady, isSignUpEnabled, history])

  return (
    <FullImageBackground>
      <HeadTag id="auth.signup.h1.signUpHeader" />
      {flagsReady && isSignUpEnabled && <AuthSignUp />}
    </FullImageBackground>
  )
}

export default SignUpPage
