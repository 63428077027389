import { FormattedHTMLMessage } from 'react-intl'
import { call, put, select, take, takeEvery } from 'redux-saga/effects'

import { BrandFanFilters } from '@enums'
import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  addBrandFanGroupMember,
  AddBrandFanGroupMemberActionTypes,
  addBrandFanGroupMemberError,
  addBrandFanGroupMemberSuccess,
  fetchInfluencerIdentity,
  FetchInfluencerIdentityActionTypes,
  refreshBrandFanGroupMembers,
  refreshInfluencerIdentitiesSelf,
  selectAddBrandFanGroupMemberLink,
  selectBrandFanGroupName,
} from '@store/brandFans'
import { fetchSubmission, SubmissionActionTypes } from '@store/submissions'
import { Toaster } from '@tribegroup'
import { networkRequest } from '@utils'

export function* handleAddBrandFanGroupMember(action: ReturnType<typeof addBrandFanGroupMember>) {
  const { identityId, groupId, submissionId, campaignId, influencerId } = action.payload
  try {
    const authToken: string = yield select(selectAuthToken)
    const addBrandFanGroupMemberLink: IHateoasLink = yield select(
      selectAddBrandFanGroupMemberLink,
      groupId,
    )
    yield call(
      networkRequest,
      addBrandFanGroupMemberLink.method,
      addBrandFanGroupMemberLink.href,
      {
        identity_id: identityId,
        influencer_id: influencerId,
      },
      authToken,
    )

    if (submissionId && campaignId) {
      yield put(fetchSubmission(submissionId, campaignId))
      yield take(SubmissionActionTypes.FETCH_SUCCESS)
    } else {
      yield put(refreshBrandFanGroupMembers(groupId))
      yield put(refreshInfluencerIdentitiesSelf(BrandFanFilters.BrandFans))
      yield put(fetchInfluencerIdentity(identityId))

      yield take(FetchInfluencerIdentityActionTypes.FETCH_SUCCESS)
    }

    yield put(addBrandFanGroupMemberSuccess(identityId, groupId))

    const brandFanGroupName = yield select(selectBrandFanGroupName, groupId)
    yield call(Toaster.createToast, FormattedHTMLMessage, {
      id: 'brandFans.identityGroups.addToGroup.success',
      values: { brandFanGroupName },
    })
  } catch (error) {
    yield put(addBrandFanGroupMemberError(groupId, error))
  }
}

export function* watchAddBrandFanGroupMemberRequest() {
  yield takeEvery(
    AddBrandFanGroupMemberActionTypes.ADD_MEMBER_REQUEST,
    handleAddBrandFanGroupMember,
  )
}
