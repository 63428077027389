import { Fragment, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import AccountPaymentConfirm from '@components/Account/Payment/Confirm'
import { PanelWrapper } from '@components/Builder/Layout/Layout.styled'
import { PlaceholderPaymentElements } from '@components/Payment/Elements'
import ActionIcon from '@components/UI/ActionIcon'
import { IApplicationState } from '@store'
import {
  selectCampaignCountUsingCreditCard,
  selectCreditCardExpiryMonth,
  selectCreditCardExpiryYear,
  selectCreditCardLastFourDigits,
} from '@store/me'
import Theme from '@theme'
import { H4, Panel } from '@tribegroup'

export interface IBuilderSubmitCampaignPaymentCreditCardPlaceholder {
  switchToNewCardForm: VoidFunction
}

interface IInternalProps extends IBuilderSubmitCampaignPaymentCreditCardPlaceholder {
  lastFourDigits: string
  expiryMonth: number
  expiryYear: number
  campaignCount: number
}

export const BuilderSubmitCampaignPaymentCreditCardPlaceholder = ({
  lastFourDigits,
  expiryMonth,
  expiryYear,
  campaignCount,
  switchToNewCardForm,
}: IInternalProps) => {
  const [displayConfirm, setDisplayConfirm] = useState<boolean>(false)
  const confirm = () => {
    setDisplayConfirm(false)
    switchToNewCardForm()
  }

  const hideConfirm = () => setDisplayConfirm(false)
  const showConfirm = () => setDisplayConfirm(true)

  const onClick = campaignCount ? showConfirm : confirm
  return (
    <Fragment>
      <AccountPaymentConfirm
        campaignCount={campaignCount}
        onConfirm={confirm}
        onCancel={hideConfirm}
        isOpen={displayConfirm}
      />
      <PanelWrapper data-cy-element="credit-card-payment-details-panel-wrapper">
        <Panel
          title={
            <H4 color={Theme.defaultColor}>
              <FormattedMessage id="builder.submit.payment.header" />
            </H4>
          }
          actionIcons={[
            <ActionIcon
              key="pencil"
              glyph="edit"
              onClick={onClick}
              color={Theme.defaultColor}
              data-cy-element="edit-payment-details"
            />,
          ]}
        >
          <PlaceholderPaymentElements
            lastFourDigits={lastFourDigits}
            expiryMonth={expiryMonth}
            expiryYear={expiryYear}
          />
        </Panel>
      </PanelWrapper>
    </Fragment>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  lastFourDigits: selectCreditCardLastFourDigits(state),
  expiryMonth: selectCreditCardExpiryMonth(state),
  expiryYear: selectCreditCardExpiryYear(state),
  campaignCount: selectCampaignCountUsingCreditCard(state),
})

export default compose<IInternalProps, IBuilderSubmitCampaignPaymentCreditCardPlaceholder>(
  connect(mapStateToProps),
)(BuilderSubmitCampaignPaymentCreditCardPlaceholder)
