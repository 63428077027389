import { useEffect } from 'react'

import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import Avatar from '@components/UI/Avatar'
import Breakpoint from '@components/UI/Breakpoint'
import { FULL_DATE } from '@constants'
import { useLocationSearch } from '@hooks/useLocationSearch'
import { IApplicationState } from '@store'
import { fetchCreatorBioByLink, selectCreatorBioByLink, selectIsFetching } from '@store/creator-bio'
import Theme from '@theme'
import { Container, H2, Icon, Text } from '@tribegroup'
import { BioContentContainer, InfluencerMemberSince, Separator } from './Details.styled'
import CreatorDetailsLoadingState from './DetailsLoading'

const CreatorDetails: React.FC = () => {
  const { href, method } = useLocationSearch()
  const { isFetching, creatorBio } = useSelector((state: IApplicationState) => ({
    isFetching: selectIsFetching(state),
    creatorBio: selectCreatorBioByLink(state, { href, method }),
  }))
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCreatorBioByLink({ href, method }))
  }, [href, method])

  if (isFetching || !creatorBio) {
    return <CreatorDetailsLoadingState />
  }

  const showGender = Boolean(creatorBio.gender)

  return (
    <Container data-testid="creator-details" flexColumn flexCenter bottomOuterSpacing={5}>
      <Container outerSpacing={1.25}>
        <Avatar avatarSrc={creatorBio.headshot.original_url} size={8} />
      </Container>
      <H2 color={Theme.defaultColor} bottomOuterSpacing={0.5}>
        {creatorBio.full_name}
      </H2>
      <Container flexCenter>
        <Icon glyph="location-pin" size={1.5} color={Theme.defaultColor} />
        <Container leftOuterSpacing={0.5}>
          <Text data-testid="location" light color={Theme.defaultColor}>
            {creatorBio.location}
          </Text>
        </Container>
      </Container>
      <Container outerSpacing={2}>
        <Breakpoint mobile>
          <InfluencerMemberSince innerSpacing={0.5} leftInnerSpacing={2} rightInnerSpacing={2}>
            <Text data-testid="joining_date" light color={Theme.defaultColor}>
              <FormattedMessage id="creatorPortfolio.bio.memberSince" />
              {` ${format(new Date(creatorBio.joining_date), FULL_DATE)}`}
            </Text>
          </InfluencerMemberSince>
        </Breakpoint>
        <Container flexCenter>
          <Text data-testid="age" light color={Theme.defaultColor}>
            {creatorBio.age}
            <FormattedMessage id="creatorPortfolio.bio.yearsOld" />
          </Text>
          {showGender && (
            <>
              <Separator />
              <Text data-testid="gender" light color={Theme.defaultColor}>
                {creatorBio.gender}
              </Text>
            </>
          )}
          {creatorBio.rating && (
            <>
              <Separator />
              <Container flexCenter>
                <Icon glyph="star-rating-outline" size={1.5} color={Theme.alertColor} />
                <Container leftOuterSpacing={0.5}>
                  <Text data-testid="rating" light lineHeight={1.5}>
                    {creatorBio.rating.toLocaleString(undefined, { minimumFractionDigits: 1 })}
                  </Text>
                </Container>
              </Container>
            </>
          )}
          <Breakpoint desktop tabletLandscape tabletPortrait>
            <Container flexCenter>
              <Separator />
              <Text light color={Theme.defaultColor}>
                <FormattedMessage id="creatorPortfolio.bio.memberSince" />
                {` ${format(new Date(creatorBio.joining_date), FULL_DATE)}`}
              </Text>
            </Container>
          </Breakpoint>
        </Container>
      </Container>
      <BioContentContainer flexCenter>
        <Text
          textAlign="center"
          color={Theme.defaultColor}
          customFontSize={1.125}
          lineHeight={1.33}
          data-testid="bio"
        >
          {creatorBio.bio_text}
        </Text>
      </BioContentContainer>
    </Container>
  )
}

export default CreatorDetails
