import { call, put, select, takeLatest } from 'redux-saga/effects'

import { BrandFanFilters } from '@enums'
import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchInfluencerIdentitiesError,
  fetchInfluencerIdentitiesSuccess,
  refreshInfluencerIdentitiesSelf,
  RefreshInfluencerIdentitiesSelfActionTypes,
  selectBrandFansSelfLink,
} from '@store/brandFans'
import { networkRequest } from '@utils'

export function* handleRefreshInfluencerIdentitiesSelf(
  action: ReturnType<typeof refreshInfluencerIdentitiesSelf>,
) {
  try {
    const filter: BrandFanFilters = action.payload
    const authToken: string = yield select(selectAuthToken)
    const influencerIdentitiesSelfLink: IHateoasLink = yield select(selectBrandFansSelfLink, filter)

    if (influencerIdentitiesSelfLink) {
      const { items, metadata, links } = yield call(
        networkRequest,
        influencerIdentitiesSelfLink.method,
        influencerIdentitiesSelfLink.href,
        undefined,
        authToken,
      )
      yield put(fetchInfluencerIdentitiesSuccess(items, metadata, links, filter, 'append'))
    }
  } catch (error) {
    yield put(fetchInfluencerIdentitiesError(error))
  }
}

export function* watchRefreshInfluencerIdentitiesSelfRequest() {
  yield takeLatest(
    RefreshInfluencerIdentitiesSelfActionTypes.REFRESH_REQUEST,
    handleRefreshInfluencerIdentitiesSelf,
  )
}
