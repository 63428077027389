import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { CostBreakdownRow } from '@components/UI/CostBreakdown'
import { IApplicationState } from '@store'
import { selectCampaignBudgetSpent, selectCampaignTotalCurrency } from '@store/campaigns'
import Theme from '@theme'
import { Container } from '@tribegroup'

interface IProps {
  campaignId: number
}

const CampaignBudgetSpentLineItem: React.FC<IProps> = ({ campaignId }) => {
  const { budgetSpent = 0, currency } = useSelector((state: IApplicationState) => ({
    budgetSpent: selectCampaignBudgetSpent(state, campaignId),
    currency: selectCampaignTotalCurrency(state, campaignId),
  }))

  return (
    <Container
      topOuterSpacing={1.25}
      borderWidthTop={0.0625}
      borderWidthBottom={0.0625}
      borderColor={Theme.grey300Color}
      topInnerSpacing={0.75}
      bottomInnerSpacing={0.625}
    >
      <CostBreakdownRow
        label={<FormattedMessage id="campaign.budget.summary.budgetSpent" />}
        currency={currency}
        quantity={budgetSpent}
      />
    </Container>
  )
}

export default CampaignBudgetSpentLineItem
