import { createSelector } from 'reselect'

import { EMPTY_LIST } from '@constants'
import { BrandFanFilters } from '@enums'
import { IApplicationState, IFeatureHateoasLink, IHateoasLink } from '@store'
import {
  IBrandFanGroup,
  IBrandFanGroupOption,
  IBrandFanMember,
  IBrandFanStats,
  IInfluencerIdentity,
} from '@store/brandFans'
import { IGender, selectAllGenders } from '@store/rootResource'
import { selectCurrentBrandFansFilter } from '@store/router'
import { isEmpty } from '@utils'

const selectBrandFansLinks = (state: IApplicationState) => state.brandFans.linksByFilter || {}

const selectBrandFansGroupMembersLinks = (state: IApplicationState) =>
  state.brandFans.membersLinksByGroupId || {}

const selectFilterAdapter = (state: IApplicationState, filter: BrandFanFilters) => filter

const selectGroupIdAdapter = (state: IApplicationState, groupId: number) => groupId

const selectGroupIdsAdapter = (state: IApplicationState, groupIds: ReadonlyArray<number>) =>
  groupIds

export const selectBrandFansStats = (state: IApplicationState) =>
  state.brandFans.stats as IBrandFanStats

export const selectIdentities = (state: IApplicationState) => state.brandFans.identitiesById

export const selectIdentityIdsByFilter = (state: IApplicationState) =>
  state.brandFans.identityIdsByFilter

export const selectIdentityIdsByGroupId = (state: IApplicationState) =>
  state.brandFans.identityIdsByGroupId

const selectBrandFanGroupByIds = (state: IApplicationState) => state.brandFans.groupsById

export const selectBrandFanGroupsByIds = createSelector(
  selectBrandFanGroupByIds,
  selectGroupIdsAdapter,
  (groups, ids) => Object.values(groups).filter((group) => ids.includes(group.id)),
)

const selectBrandFanGroupByValues = createSelector(selectBrandFanGroupByIds, (byIds) =>
  Object.values(byIds),
)

export const selectIsUpdatingBrandFanGroup = (state: IApplicationState) =>
  state.brandFans.isUpdatingBrandFanGroup

const selectBrandFansErrors = (state: IApplicationState) => state.brandFans.errors

const selectBrandFansErrorMessages = (state: IApplicationState) => {
  const brandFansError = selectBrandFansErrors(state)
  return brandFansError && brandFansError.messages
}

export const selectBrandFansErrorCode = createSelector(selectBrandFansErrorMessages, (messages) => {
  if (isEmpty(messages) || typeof messages === 'string') {
    return undefined
  }
  return messages && messages[0].error_code
})

export const selectIsFetchingIdentities = (state: IApplicationState) =>
  state.brandFans.isFetchingIdentities

export const selectIsFetchingBrandFanGroups = (state: IApplicationState) =>
  state.brandFans.isFetchingBrandFanGroups

export const selectIsCreatingBrandFanGroup = (state: IApplicationState) =>
  state.brandFans.isCreatingBrandFanGroup

export const selectHasPendingBrandFanGroupRequest = (state: IApplicationState, groupId: number) =>
  state.brandFans.pendingRequestsByBrandFanGroupId.includes(groupId)

const selectIdentityAdapter = (state: IApplicationState, identityId: number) => identityId

const selectPendingRequestsByInfluencerIdentityId = (state: IApplicationState) =>
  state.brandFans.pendingRequestsByInfluencerIdentityId

const selectSubmissionsByIdentityId = (state: IApplicationState) =>
  state.brandFans.submissionsByIdentityId

export const selectIdentity = createSelector(
  selectIdentities,
  selectIdentityAdapter,
  (identitiesById: Record<number, IInfluencerIdentity>, identityId: number) =>
    identitiesById[identityId] || ({} as IInfluencerIdentity),
)

export const selectIdentityAudienceInsightsLink = createSelector(
  selectIdentity,
  (identity: IInfluencerIdentity) =>
    identity.links.find((link) => link.rel === 'identity_audience_insights') as IFeatureHateoasLink,
)

export const selectIdentityCreatorBioLink = createSelector(
  selectIdentity,
  (identity: IInfluencerIdentity) => identity.links.find((link) => link.rel === 'creator_bio'),
)

export const selectFetchIdentityLink = createSelector(
  selectIdentity,
  (identity: IInfluencerIdentity) => identity.links.find((link) => link.rel === 'self'),
)

export const selectIdentitySubmissionsLink = createSelector(
  selectIdentity,
  (identity: IInfluencerIdentity) => identity.links.find((link) => link.rel === 'submissions'),
)

export const selectStatByFilter = createSelector(
  selectBrandFansStats,
  selectFilterAdapter,
  (stats, filter) => stats && stats[filter],
)

export const selectIsListEmpty = createSelector(selectStatByFilter, (count: number) => count <= 0)

export const selectFilteredIdentityIds = createSelector(
  selectIdentityIdsByFilter,
  selectFilterAdapter,
  (identities, filter) => identities[filter] || EMPTY_LIST,
)

export const selectGroupMembersIdentityIds = createSelector(
  selectIdentityIdsByGroupId,
  selectGroupIdAdapter,
  (identities, groupId) => identities[groupId] || EMPTY_LIST,
)

export const selectIsFetchingInitialIdentityList = createSelector(
  selectIsFetchingIdentities,
  selectFilteredIdentityIds,
  (isFetchingIdentites, identityIds) => isEmpty(identityIds) && isFetchingIdentites,
)

export const selectIdentityFullName = createSelector(
  selectIdentity,
  (identity) => identity.influencer_full_name,
)

export const selectIdentityFirstName = createSelector(
  selectIdentity,
  (identity) => identity.influencer_first_name,
)

export const selectIdentityUsername = createSelector(
  selectIdentity,
  (identity) => identity.identity_username,
)

export const selectIdentityAvatar = createSelector(
  selectIdentity,
  (identity) => identity.identity_avatar,
)

export const selectIdentityProvider = createSelector(
  selectIdentity,
  (identity) => identity.identity_provider,
)

export const selectIdentityAge = createSelector(
  selectIdentity,
  (identity) => identity.influencer_age,
)

export const selectIdentitySocialPageUrl = createSelector(
  selectIdentity,
  (identity) => identity.identity_social_page_url,
)

export const selectIdentityFormattedLocationAndGender = createSelector(
  selectIdentity,
  selectAllGenders,
  (identity, allGenders) => {
    const { influencer_gender, influencer_state, influencer_country } = identity
    const location = [influencer_state, influencer_country].filter(Boolean).join(', ')
    const selectedGender = allGenders.find((gender?: IGender) => gender?.code === influencer_gender)

    const genderDisplay = selectedGender?.display_name

    if (!genderDisplay) {
      return location
    }

    return `${location} · ${genderDisplay}`
  },
)

export const selectIdentityGroupIds = createSelector(
  selectIdentity,
  (identity) => identity.membership_group_ids || EMPTY_LIST,
)

export const selectIdentityGroupCount = createSelector(
  selectIdentityGroupIds,
  (groupIds: ReadonlyArray<number>) => groupIds.length,
)

export const selectIdentityHasGroup = createSelector(selectIdentityGroupIds, (groupIds) =>
  Boolean(groupIds.length),
)

export const selectIsIdentityMemberOfGroup = createSelector(
  selectIdentityGroupIds,
  (state, identityId, groupId) => groupId,
  (groupMembershipIds, groupId) => groupMembershipIds.includes(groupId),
)

export const selectIdentityAnalytics = createSelector(
  selectIdentity,
  (identity) => identity.identity_analytics || {},
)

export const selectAverageEngagementPercent = createSelector(
  selectIdentityAnalytics,
  (analytics) => analytics.avg_engagement_pct,
)

export const selectFollowersCount = createSelector(
  selectIdentity,
  (identity) => identity.identity_followers_count,
)

export const selectAverageReach = createSelector(
  selectIdentityAnalytics,
  (analytics) => analytics.avg_reach_pct,
)

export const selectAverageImpressions = createSelector(
  selectIdentityAnalytics,
  (analytics) => analytics.avg_impressions,
)

export const selectCollabsCount = createSelector(
  selectIdentity,
  (identity) => identity.identity_collabs,
)

const selectLinksByFilter = createSelector(
  selectBrandFansLinks,
  selectFilterAdapter,
  (links, filter) => links[filter] || EMPTY_LIST,
)

const selectBrandFansGroupMembersLinksByGroupId = createSelector(
  selectBrandFansGroupMembersLinks,
  selectGroupIdAdapter,
  (links, groupId) => links[groupId] || EMPTY_LIST,
)

export const selectBrandFansSelfLink = createSelector(selectLinksByFilter, (links) =>
  links.find((link) => link.rel === 'self'),
)

export const selectBrandFansNextLink = createSelector(selectLinksByFilter, (links) =>
  links.find((link) => link.rel === 'next'),
)

const selectBrandFanGroupsLinks = createSelector(
  selectBrandFansLinks,
  (brandFanGroupsLinks) => brandFanGroupsLinks[BrandFanFilters.Groups] || EMPTY_LIST,
)

export const selectCreateBrandFanGroupLink = createSelector(selectBrandFanGroupsLinks, (links) =>
  links.find((link) => link.rel === 'create_brand_fan_group'),
)

export const selectBrandFanGroupIds = (state: IApplicationState) =>
  state.brandFans.groupIds || EMPTY_LIST

export const selectIsFetchingInitialBrandFanGroupList = createSelector(
  selectIsFetchingBrandFanGroups,
  selectBrandFanGroupIds,
  (isFetchingGroups, groupIds) => isEmpty(groupIds) && isFetchingGroups,
)

export const selectBrandFanGroupsByLabelAndValue = createSelector(
  selectBrandFanGroupByValues,
  (brandFanGroups: ReadonlyArray<IBrandFanGroup>): ReadonlyArray<IBrandFanGroupOption> =>
    brandFanGroups.map((brandFanGroup) => ({
      count: brandFanGroup.member_count,
      value: brandFanGroup.id,
      name: brandFanGroup.name,
      label: `${brandFanGroup.name} (${brandFanGroup.member_count})`,
    })),
)

export const selectBrandFanGroup = (state: IApplicationState, groupId: number) =>
  state.brandFans.groupsById[groupId] || ({} as IBrandFanGroup)

export const selectBrandFanGroupName = createSelector(
  selectBrandFanGroup,
  (brandFanGroup) => brandFanGroup.name,
)

export const selectBrandFanGroupNamesByIds = createSelector(selectBrandFanGroupsByIds, (groups) =>
  groups.map((group) => group.name),
)

export const selectBrandFanGroupDescription = createSelector(
  selectBrandFanGroup,
  (brandFanGroup) => brandFanGroup.description,
)

export const selectBrandFanGroupMemberCount = createSelector(
  selectBrandFanGroup,
  (brandFanGroup) => brandFanGroup.member_count,
)

export const selectBrandFanGroupLinks = createSelector(
  selectBrandFanGroup,
  (brandFanGroup) => brandFanGroup.links || EMPTY_LIST,
)

export const selectBrandFanGroupMemberAvatars = createSelector(
  selectBrandFanGroup,
  (brandFanGroup) => brandFanGroup.first_ten_member_avatars || EMPTY_LIST,
)

export const selectBrandFanGroupHasLiveCampaigns = createSelector(
  selectBrandFanGroup,
  (brandFanGroup) => brandFanGroup.live_briefs_any,
)

export const selectBrandFanGroupMembeCountWithoutAvatars = createSelector(
  selectBrandFanGroupMemberCount,
  selectBrandFanGroupMemberAvatars,
  (memberCount, membersWithAvartar) => memberCount - membersWithAvartar.length,
)

export const selectIsFetchingSubmissionsByIdentity = createSelector(
  selectPendingRequestsByInfluencerIdentityId,
  selectIdentityAdapter,
  (pendingRequests, identityId) => pendingRequests.includes(identityId),
)

export const selectIsSubmissionByIdentityFetched = createSelector(
  selectSubmissionsByIdentityId,
  selectIdentityAdapter,
  (submissionsByIdentityId, identityId) => Boolean(submissionsByIdentityId[identityId]),
)

export const selectSubmissionsByIdentity = createSelector(
  selectSubmissionsByIdentityId,
  selectIdentityAdapter,
  (submissionsByIdentityId, identityId) => submissionsByIdentityId[identityId] || EMPTY_LIST,
)

export const selectBrandFanGroupUpdateLink = createSelector(selectBrandFanGroupLinks, (links) =>
  links.find((link: IHateoasLink) => link.rel === 'update_brand_fan_group'),
)

export const selectBrandFanGroupMembersLink = createSelector(selectBrandFanGroup, (brandFanGroup) =>
  brandFanGroup.links.find((link) => link.rel === 'brand_fan_members'),
)

export const selectBrandFanGroupMembersSortOptions = createSelector(
  selectBrandFanGroupMembersLink,
  (link: IHateoasLink) => (link && link.sort_by) || EMPTY_LIST,
)

export const selectBrandFansGroupMembersNextLink = createSelector(
  selectBrandFansGroupMembersLinksByGroupId,
  (links) => links.find((link) => link.rel === 'next'),
)

export const selectBrandFansGroupMembersSelfLink = createSelector(
  selectBrandFansGroupMembersLinksByGroupId,
  (links) => links.find((link) => link.rel === 'self'),
)

export const selectAddBrandFanGroupMemberLink = createSelector(selectBrandFanGroupLinks, (links) =>
  links.find((link: IHateoasLink) => link.rel === 'add_brand_fan_member'),
)

export const selectRemoveBrandFanMemberLink = createSelector(selectBrandFanGroupLinks, (links) =>
  links.find((link: IHateoasLink) => link.rel === 'remove_brand_fan_member'),
)

export const selectBrandFanStatsForCurrentFilter = createSelector(
  selectBrandFansStats,
  selectCurrentBrandFansFilter,
  (stats, currentFilter) => stats && stats[currentFilter],
)

const selectBrandFanOpenedIdentities = (state: IApplicationState) =>
  state.brandFans.openedIdentities

export const selectIsBrandFanIdentityOpened = createSelector(
  selectBrandFanOpenedIdentities,
  (_, identityId: number) => identityId,
  (openedIdentities, identityId) => !!openedIdentities[identityId],
)

const selectBrandFanMembersByGroupid = (state: IApplicationState) =>
  state.brandFans.membersByGroupId

export const selectBrandFanMemberId = createSelector(
  selectBrandFanMembersByGroupid,
  (_, groupId: number, identityId?: number, influencerId?: number) => ({
    groupId,
    identityId,
    influencerId,
  }),
  (membersByGroupId, { groupId, identityId, influencerId }) => {
    const members = (membersByGroupId[groupId]
      ? Object.values(membersByGroupId[groupId])
      : EMPTY_LIST) as IBrandFanMember[]
    return members.find(
      (member) => member.identity_id === identityId || member.influencer_id === influencerId,
    )?.id
  },
)

const selectBrandFanMembersByInfluencerId = (state: IApplicationState) =>
  state.brandFans.membersByInfluencerId

const selectBrandFanMembersByIdentityId = (state: IApplicationState) =>
  state.brandFans.membersByIdentityId

export const selectBrandFanMembersByInfluencer = createSelector(
  selectBrandFanMembersByInfluencerId,
  (_, influencerId: number) => influencerId,
  (membersByInfluencerId, influencerId) => membersByInfluencerId[influencerId] || EMPTY_LIST,
)

export const selectBrandFanMembersByIdentity = createSelector(
  selectBrandFanMembersByIdentityId,
  (_, identityId: number) => identityId,
  (membersByIdentityId, identityId) => membersByIdentityId[identityId] || EMPTY_LIST,
)
