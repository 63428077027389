import Theme from '@theme'
import { Container, Header, Text } from '@tribegroup'

export const DetailsBlock = ({ children }) => (
  <Container topInnerSpacing={0.5} bottomInnerSpacing={0.25}>
    {children}
  </Container>
)

export const DetailsBlockHeader = ({ children }) => (
  <Header small color={Theme.grey700Color} bottomOuterSpacing={0.125} uppercase>
    {children}
  </Header>
)
export const DetailsBlockBody = ({ children }) => <Text color={Theme.grey900Color}>{children}</Text>

export default DetailsBlock
