import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectBrandFanGroupIds } from '@store/brandFans'
import { GroupListContainer } from './Groups.styled'
import BrandFansInfluencerIdentityGroupsItem from './Item'

interface IProps {
  identityId: number
  influencerId?: number
  submissionId?: number
  campaignId?: number
  identityGroupIds: ReadonlyArray<number>
}

interface IInternalProps extends IProps {
  groupIds: ReadonlyArray<number>
}

const BrandFansInfluencerIdentityGroupsList: React.FC<IInternalProps> = ({
  identityId,
  submissionId,
  identityGroupIds,
  campaignId,
  groupIds,
  influencerId,
}) => (
  <GroupListContainer data-testid="brand-fan-groups-list-container">
    {groupIds.map((groupId) => (
      <BrandFansInfluencerIdentityGroupsItem
        isMemberOfGroup={identityGroupIds.includes(groupId)}
        submissionId={submissionId}
        campaignId={campaignId}
        key={groupId}
        groupId={groupId}
        identityId={identityId}
        influencerId={influencerId}
      />
    ))}
  </GroupListContainer>
)

const mapStateToProps = (state: IApplicationState) => ({
  groupIds: selectBrandFanGroupIds(state),
})

export { BrandFansInfluencerIdentityGroupsList }
export default compose<IInternalProps, IProps>(connect(mapStateToProps))(
  BrandFansInfluencerIdentityGroupsList,
)
