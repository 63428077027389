import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { EllipsisWrapper } from '@components/InfluencerIdentity/Row/Details/Details.styled'
import { BudgetAmount } from '@components/UI/BudgetAmount/BudgetAmount'
import HoverableInformation from '@components/UI/Information/HoverableInformation/HoverableInformation'
import Theme from '@theme'
import { Container, Header, Text } from '@tribegroup'

const CampaignBudgetInboxValue = ({ amount, currency, reversed = false }) => {
  return (
    <Container data-testid="inbox-total-value">
      <Header small color={Theme.grey900Color}>
        <Flex alignCenter>
          <Container maxWidth="calc(100vw - 9.75rem)">
            <BudgetAmount
              spent={amount}
              currency={currency}
              largetext
              reversed={reversed}
              color={Theme.grey900Color}
              customMessage={
                <EllipsisWrapper>
                  <FormattedMessage id="campaign.budget.totalInboxValue" />
                </EllipsisWrapper>
              }
            />
          </Container>
          <Container topOuterSpacing={0.25} leftOuterSpacing={0.5} width={1} inlineBlock>
            <HoverableInformation
              width={18.75}
              xPosition="left"
              glyph="information"
              iconSize={1}
              waypointYOffset={270}
              topOffset={12}
            >
              <Container>
                <Text block color={Theme.grey900Color}>
                  <FormattedMessage id="campaign.budget.totalInboxValue.tooltip.paragraph.first" />
                </Text>
                <Container topOuterSpacing={0.5}>
                  <Text block color={Theme.grey900Color}>
                    <FormattedMessage id="campaign.budget.totalInboxValue.tooltip.paragraph.second" />
                  </Text>
                </Container>
              </Container>
            </HoverableInformation>
          </Container>
        </Flex>
      </Header>
    </Container>
  )
}

export default CampaignBudgetInboxValue
