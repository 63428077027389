import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import HeadTag from '@components/UI/HeadTag'
import { IApplicationState } from '@store'
import { selectInfluencerFullName } from '@store/mediaObjects'
import { selectMediaObjectId } from '@store/router'

interface IInternalProps {
  influencerName: string
}

export const MediaObjectTitleBarHeadTag = ({ influencerName }: IInternalProps) => (
  <HeadTag text={influencerName} />
)

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const mediaObjectId = selectMediaObjectId(match) as number
  return {
    influencerName: selectInfluencerFullName(state, mediaObjectId!),
  }
}

export default compose<IInternalProps, any>(
  withRouter,
  connect(mapStateToProps),
)(MediaObjectTitleBarHeadTag)
