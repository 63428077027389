import { createReducer } from '@reduxjs/toolkit'
import { castDraft } from 'immer'

import {
  fetchCreatorBioByLink,
  fetchCreatorBioByLinkError,
  fetchCreatorBioByLinkSuccess,
} from './actions/fetchCreatorBioByLink'
import { ICreatorBioState } from './types'
import { linkToIdentifier } from './util'

const initialState: ICreatorBioState = {
  creatorBioByLink: {},
  isFetching: true,
}

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchCreatorBioByLink, (state) => {
      state.isFetching = true
    })
    .addCase(fetchCreatorBioByLinkSuccess, (state, { payload }) => {
      const { link, creatorBio } = payload
      const identifer = linkToIdentifier(link)
      state.creatorBioByLink[identifer] = castDraft(creatorBio)
      state.isFetching = false
    })
    .addCase(fetchCreatorBioByLinkError, (state, { payload }) => {
      const { error } = payload
      state.isFetching = false
      state.errors = error
    }),
)

export { reducer as creatorBioReducer }
