import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { returnSecondIfDeepEqual } from '@utils'
import { ISubmissionPerformanceState, SubmissionPerformanceActionTypes } from './types'

const initialState: ISubmissionPerformanceState = {
  isFetching: false,
  errors: undefined,
  submissionPerformanceById: {},
}

const reducer: Reducer<ISubmissionPerformanceState> = (state = initialState, action) => {
  switch (action.type) {
    case SubmissionPerformanceActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
        errors: undefined,
      }
    }

    case SubmissionPerformanceActionTypes.FETCH_SUCCESS: {
      const { submissionId, submissionPerformance } = action.payload
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        submissionPerformanceById: returnSecondIfDeepEqual(
          {
            ...state.submissionPerformanceById,
            [submissionId]: submissionPerformance,
          },
          state.submissionPerformanceById,
        ),
      }
    }

    case SubmissionPerformanceActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS: {
      return initialState
    }

    default:
      return state
  }
}

export { reducer as submissionPerformanceReducer }
