import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation/Provider'
import { EventTrackingNames } from '@enums'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import { saveCampaign } from '@store/campaigns'
import { selectCampaignIdFromDraft, selectIsInDraftStatus } from '@store/drafts'
import BuilderTitleBarActionsConfirmCloseConfirmation from './Confirmation'

interface IContextProps {
  confirmationShown: boolean
  showConfirmModal: (redirect: string, eventOnConfirmClose?: EventTrackingNames) => void
  onConfirm: VoidFunction
  onDiscard: VoidFunction
  onClose?: VoidFunction
}

const BuilderTitleBarActionsConfirmCloseContext = React.createContext({} as IContextProps)

interface IInternalProps extends IBuilderValidationContextProps, ConnectedProps<typeof connector> {}

const BuilderTitleBarActionsConfirmCloseProvider: React.FC<IInternalProps> = ({
  formChanged,
  saveCampaign: saveCampaignAction,
  children,
  campaignId,
  validate,
  isDraftStatus,
}) => {
  const history = useHistory()
  const [redirect, setRedirect] = React.useState('')
  const [eventName, setEventName] = React.useState<EventTrackingNames | undefined>()

  const [confirmationShown, setConfirmationShown] = React.useState(false)

  const showConfirmModal = (newRedirect: string, eventOnConfirmClose?: EventTrackingNames) => {
    setRedirect(newRedirect)
    setEventName(eventOnConfirmClose)
    if (formChanged) {
      setConfirmationShown(true)
    } else {
      history.push(newRedirect)
    }
  }

  const onConfirm = () => {
    setConfirmationShown(false)
    if (isDraftStatus || validate()) {
      saveCampaignAction(undefined, { history, redirect })
    }
  }

  const onDiscard = () => {
    setConfirmationShown(false)
    history.push(redirect)
  }

  const onClose = () => {
    setConfirmationShown(false)
  }

  return (
    <BuilderTitleBarActionsConfirmCloseContext.Provider
      value={{
        showConfirmModal,
        confirmationShown,
        onConfirm,
        onDiscard,
        onClose,
      }}
    >
      <BuilderTitleBarActionsConfirmCloseConfirmation
        eventName={eventName!}
        campaignId={campaignId}
      />
      {children}
    </BuilderTitleBarActionsConfirmCloseContext.Provider>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  const campaignId = selectCampaignIdFromDraft(state) as number
  return {
    campaignId,
    isDraftStatus: selectIsInDraftStatus(state),
  }
}

const mapDispatchToProps = {
  saveCampaign,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export { BuilderTitleBarActionsConfirmCloseProvider, BuilderTitleBarActionsConfirmCloseContext }
export default compose<IInternalProps, {}>(
  withBuilderValidation,
  connector,
)(BuilderTitleBarActionsConfirmCloseProvider)
