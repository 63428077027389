import { IInfluencerIdentity, InfluencerIdentityActionTypes } from '../types'

export const updateInfluencerIdentity = (
  influencerIdentity: IInfluencerIdentity,
  campaignId: number,
) => ({
  payload: { influencerIdentity, campaignId },
  type: InfluencerIdentityActionTypes.UPDATE_INFLUENCER_IDENTITY,
})

export const removeInfluencerIdentity = (campaignId, influencerIdentityId) => ({
  payload: {
    campaignId,
    influencerIdentityId,
  },
  type: InfluencerIdentityActionTypes.REMOVE_INFLUENCER_IDENTITY,
})
