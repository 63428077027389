import { format } from 'date-fns'
import { createSelector } from 'reselect'

import { FULL_DATE } from '@constants'
import { PCRChannels, SocialSubmissionTypes } from '@enums'
import { IApplicationState } from '@store'
import { IPostMetrics, IPostMetricsSummary } from '@store/postCampaignReport'

const POST_METRIC_PROPS: ReadonlyArray<string> = [
  'likes',
  'comments',
  'shares',
  'engagement',
  'engagement_rate',
  'cost_per_engagement',
]

export const selectPostMetricIds = (
  state: IApplicationState,
  campaignId: number,
  channel: PCRChannels,
) => {
  const postMetricIds = state.reportings.postMetrics.postMetricIdsByChannel[campaignId]
  return postMetricIds && postMetricIds[channel] ? postMetricIds[channel] : []
}

export const selectPostMetrics = (state: IApplicationState, id: number) =>
  state.reportings.postMetrics.postMetricsById[id] || {}

export const selectShowPostPerformance = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => {
    return POST_METRIC_PROPS.some(
      (metricProp: string) => postMetrics[metricProp] || postMetrics[metricProp] === 0,
    )
  },
)

export const selectPostPublishedDate = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) =>
    postMetrics.published_at && format(new Date(postMetrics.published_at), FULL_DATE),
)

export const selectPostSpend = createSelector(selectPostMetrics, (postMetrics: IPostMetrics) => ({
  amount: postMetrics.purchase_amount,
  currency: postMetrics.purchase_amount_currency,
}))

export const selectPostFollowers = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => postMetrics.followers,
)

export const selectPostLikes = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => postMetrics.likes,
)

export const selectPostComments = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => postMetrics.comments,
)

export const selectPostEngagement = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => postMetrics.engagement,
)

export const selectPostEngagementPct = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => postMetrics.engagement_rate,
)

export const selectPostCPE = createSelector(selectPostMetrics, (postMetrics: IPostMetrics) => ({
  amount: postMetrics.cost_per_engagement,
  currency: postMetrics.cost_per_engagement_currency,
}))

export const selectPostReach = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => postMetrics.reach,
)

export const selectPostReachPct = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => postMetrics.reach_pct,
)

export const selectPostImpressions = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => postMetrics.impressions,
)

export const selectPostCPM = createSelector(selectPostMetrics, (postMetrics: IPostMetrics) => ({
  amount: postMetrics.cpm,
  currency: postMetrics.cpm_currency,
}))

export const selectPostPublishedURL = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => postMetrics.published_url,
)

export const selectPostSocialPageURL = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => postMetrics.social_page_url,
)

export const selectPostInfluencerName = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => postMetrics.influencer_name,
)

export const selectPostInfluencerHandle = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => postMetrics.handle,
)

export const selectSocialSubmissionType = createSelector(
  selectPostMetrics,
  (postMetrics: IPostMetrics) => postMetrics.social_submission_type,
)

export const selectIsCarousel = createSelector(
  selectSocialSubmissionType,
  (submissionType: string) => submissionType === SocialSubmissionTypes.Carousel,
)

export const selectPostMetricsSummary = (
  state: IApplicationState,
  campaignId: number,
  channel: string,
) => {
  const summary = state.reportings.postMetrics.postMetricsSummary[campaignId]

  if (!summary) {
    return {}
  }
  return summary[channel] || {}
}

export const selectPostsTotalPosts = createSelector(
  selectPostMetricsSummary,
  (summary: IPostMetricsSummary) => summary.count,
)

export const selectPostsTotalSpend = createSelector(
  selectPostMetricsSummary,
  (summary: IPostMetricsSummary) => ({
    amount: summary.total_purchase_amount,
    currency: summary.total_purchase_amount_currency,
  }),
)

export const selectPostsTotalFollowers = createSelector(
  selectPostMetricsSummary,
  (summary: IPostMetricsSummary) => summary.total_followers,
)

export const selectPostsTotalEngagement = createSelector(
  selectPostMetricsSummary,
  (summary: IPostMetricsSummary) => summary.total_engagement,
)

export const selectPostsTotalEngagementPct = createSelector(
  selectPostMetricsSummary,
  (summary: IPostMetricsSummary) => summary.total_engagement_pct,
)

export const selectPostsTotalLikes = createSelector(
  selectPostMetricsSummary,
  (summary: IPostMetricsSummary) => summary.total_likes,
)

export const selectPostsTotalComments = createSelector(
  selectPostMetricsSummary,
  (summary: IPostMetricsSummary) => summary.total_comments,
)

export const selectPostsAvgEngagementPct = createSelector(
  selectPostMetricsSummary,
  (summary: IPostMetricsSummary) => summary.avg_engagement_rate,
)

export const selectPostsOverallCPE = createSelector(
  selectPostMetricsSummary,
  (summary: IPostMetricsSummary) => ({
    amount: summary.total_cost_per_engagement,
    currency: summary.total_cost_per_engagement_currency,
  }),
)

export const selectPostsTotalReach = createSelector(
  selectPostMetricsSummary,
  (summary: IPostMetricsSummary) => summary.total_reach,
)

export const selectPostsAvgReachPct = createSelector(
  selectPostMetricsSummary,
  (summary: IPostMetricsSummary) => summary.avg_reach_pct,
)

export const selectPostsTotalImpressions = createSelector(
  selectPostMetricsSummary,
  (summary: IPostMetricsSummary) => summary.total_impressions,
)

export const selectPostsOverallCPM = createSelector(
  selectPostMetricsSummary,
  (summary: IPostMetricsSummary) => ({
    amount: summary.overall_cpm,
    currency: summary.overall_cpm_currency,
  }),
)
