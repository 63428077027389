import { createAction } from '@reduxjs/toolkit'

import { RequestPurchaseOrdersActionTypes } from '@store/purchaseOrders'

export const requestPurchaseOrdersAccess = createAction<undefined>(
  RequestPurchaseOrdersActionTypes.FETCH_REQUEST,
)

export const requestPurchaseOrdersAccessSuccess = createAction<undefined>(
  RequestPurchaseOrdersActionTypes.FETCH_SUCCESS,
)

export const requestPurchaseOrdersAccessError = createAction<any>(
  RequestPurchaseOrdersActionTypes.FETCH_ERROR,
)
