import { Component, Fragment, PropsWithChildren } from 'react'

import Theme from '@theme'
import { Icon } from '@tribegroup'
import { ExpandableChildren, ExpandableToggle } from './Expandable.styled'

export interface IExpandableProps {
  height: number
}

class Expandable extends Component<PropsWithChildren<IExpandableProps>> {
  state = {
    open: false,
  }

  toggle = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    const glyph = this.state.open ? 'chevron-up' : 'chevron-down'
    return (
      <Fragment>
        <ExpandableChildren height={this.props.height} open={this.state.open}>
          {this.props.children}
        </ExpandableChildren>
        <ExpandableToggle>
          <Icon size={1.5} color={Theme.grey700Color} glyph={glyph} onClick={this.toggle} />
        </ExpandableToggle>
      </Fragment>
    )
  }
}

export default Expandable
