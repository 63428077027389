import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import { Container } from '@tribegroup'

export const AnalyticsDetailCardGrid = styled.div`
  display: block;

  > div {
    margin-bottom: 0.5rem;
  }

  ${({ screen = 'tabletPortrait', gridCount = 3 }) =>
    breakpoint(screen)`
      display: grid;
      grid-template-columns: repeat(${gridCount}, 1fr);
      grid-gap: 0.5rem;
      > div {
        margin: 0;
      }
    `}
`

export const AnalyticsDetailCardFeaturedMetric = styled(Container)`
  justify-content: space-between;
  ${breakpoint('tabletLandscape')`
      justify-content: normal;
  `};
`
