import { FormattedMessage } from 'react-intl'

import { IFeedback } from '@store/feedback'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { drawerDateFormat } from '@utils'
import SubmissionChatMessage from '../Message/Message'
import { FeedbackBlockWrapper } from './Block.styled'

const Author: React.FC<{ meId: number; feedback: IFeedback }> = ({ feedback, meId }) => {
  const isSelf = meId === feedback.author_id
  return (
    <Text small color={Theme.grey700Color} textAlign={isSelf ? 'right' : 'left'}>
      {isSelf ? <FormattedMessage id="core.text.you" /> : feedback.author_name}
    </Text>
  )
}

interface IFeedbackBlockProps {
  feedback: IFeedback
  meId: number
}

const FeedbackBlock = ({ feedback, meId }: IFeedbackBlockProps) => {
  const dateTextProps = drawerDateFormat(new Date(feedback.created_at))
  return (
    <FeedbackBlockWrapper>
      <Text color={Theme.grey700Color} small>
        <FormattedMessage id={dateTextProps.id} values={dateTextProps.values} />
      </Text>
      <Container>
        <SubmissionChatMessage feedback={feedback} meId={meId} />
        <Container topInnerSpacing={0.5}>
          <Author feedback={feedback} meId={meId} />
        </Container>
      </Container>
    </FeedbackBlockWrapper>
  )
}

export default FeedbackBlock
