import React from 'react'

import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { FlexItem } from 'styled-flex-component'

import Avatar from '@components/UI/Avatar'
import {
  CreatorHeader,
  CreatorHeaderDetails,
  CreatorHeaderDetailsBottom,
  CreatorHeaderDetailsTop,
  CreatorHeaderUserHandle,
} from '@components/UI/CreatorHeader'
import PlaceholderAvatar from '@components/UI/PlaceholderAvatar/PlaceholderAvatar'
import { IApplicationState } from '@store'
import {
  selectInfluencerAvatar,
  selectInfluencerFullName,
  selectInfluencerInitials,
} from '@store/mediaObjects'
import { selectMediaObjectId } from '@store/router'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'

const MediaObjectFullHeader: React.FC = () => {
  const match = useRouteMatch()
  const { initials, fullName, avatar } = useSelector((state: IApplicationState) => {
    const mediaObjectId = selectMediaObjectId(match) as number
    return {
      fullName: selectInfluencerFullName(state, mediaObjectId),
      initials: selectInfluencerInitials(state, mediaObjectId),
      avatar: selectInfluencerAvatar(state, mediaObjectId),
    }
  })

  return (
    <CreatorHeader>
      {avatar ? (
        <FlexItem noShrink>
          <Container relative rightOuterSpacing={0.75} height={2.5}>
            <Avatar data-testid="influencer-avatar" avatarSrc={avatar} size={2.5} />
          </Container>
        </FlexItem>
      ) : (
        <PlaceholderAvatar data-testid="initial">{initials}</PlaceholderAvatar>
      )}
      <CreatorHeaderDetails>
        <CreatorHeaderDetailsTop>
          <CreatorHeaderUserHandle>{fullName}</CreatorHeaderUserHandle>
        </CreatorHeaderDetailsTop>
        <CreatorHeaderDetailsBottom>
          <Icon size={0.75} glyph="picture-heavy" color={Theme.grey700Color} />
        </CreatorHeaderDetailsBottom>
      </CreatorHeaderDetails>
    </CreatorHeader>
  )
}

export { MediaObjectFullHeader }
export default MediaObjectFullHeader
