import React from 'react'

import { compose } from 'recompose'

import ActionIcon from '@components/UI/ActionIcon'
import StopPropagation from '@components/UI/StopPropagation/StopPropagation'
import { IModalContextProps } from '@context/Modal'
import { EventTrackingNames } from '@enums'
import { withModal } from '@hocs'
import Theme from '@theme'
import BrandFansInfluencerIdentityGroups from '../Groups'
import BrandFanInfluencerIdentityRowTrackedRoute from './TrackedRoute'

interface IProps {
  identityId: number
  active?: boolean
}

interface IInternalProps extends IProps, IModalContextProps {}

const BrandFanInfluencerIdentityRowActions: React.FC<IInternalProps> = ({
  identityId,
  showModal,
  active,
}) => {
  const onOpenModal = () => showModal(BrandFansInfluencerIdentityGroups, { identityId })
  return (
    <StopPropagation>
      <BrandFanInfluencerIdentityRowTrackedRoute
        eventName={EventTrackingNames.BrandFansGroupsModal}
        identityId={identityId}
      >
        <ActionIcon
          data-testid="brand-fan-groups-modal-trigger"
          highlighted={active}
          glyph="plus"
          color={active ? Theme.primaryColor : Theme.defaultColor}
          size={1.125}
          onClick={onOpenModal}
        />
      </BrandFanInfluencerIdentityRowTrackedRoute>
    </StopPropagation>
  )
}

export { BrandFanInfluencerIdentityRowActions }
export default compose<IInternalProps, IProps>(withModal)(BrandFanInfluencerIdentityRowActions)
