import { call, put, select, takeEvery } from 'redux-saga/effects'

import { unleashClient } from '@context/FeatureToggle'
import { FeatureToggles } from '@enums'
import amplitude from '@lib/analytics/amplitude'
import { AnalyticsTrackingActionTypes, trackEvent, trackEventSuccess } from '@store/analytics'
import { shouldUserTrackEvents } from '@store/me'

export function* handleTrackingRequest(action: ReturnType<typeof trackEvent>) {
  const { payload } = action
  const { name, properties } = payload
  const shouldTrackEvents = yield select(shouldUserTrackEvents)
  const isAmplitudeEnabled = unleashClient.isEnabled(FeatureToggles.BYE_BYE_SEGMENT_ENABLED)
  if (shouldTrackEvents) {
    if (isAmplitudeEnabled) {
      yield call(amplitude.track, name, properties)
    } else {
      yield put(trackEventSuccess(name, properties))
    }
  }
}

export function* watchTrackingRequest() {
  yield takeEvery(AnalyticsTrackingActionTypes.SEND_TRACKING_REQUEST, handleTrackingRequest)
}
