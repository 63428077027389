import { Fragment, useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import {
  LicenseOptionPriceDetails,
  LicenseOptions,
  LicensingAgreementsLink,
  LicensingHeader,
  MediaDetails,
  MediaPreview,
  RequestLicenseButton,
} from '@components/Licensing'
import { CreditManagementWrapper } from '@components/MediaObject/Licensing/CreditManagement/Wrapper'
import StripeError from '@components/UI/Error/StripeError'
import { useModal } from '@hooks/useModal'
import { IApplicationState } from '@store'
import { fetchMediaObjectLicenseOptions } from '@store/licenses'
import {
  selectCreditAmount,
  selectHasCreditCardInfo,
  selectHasPendingCreditRequest,
} from '@store/me'
import {
  clearMediaObjectErrors,
  requestLicense,
  selectFirstErrorCode,
  selectHasInsufficientFundError,
} from '@store/mediaObjects'
import Theme from '@theme'
import { CloseIcon, Container, Text } from '@tribegroup'
import { isEmpty } from '@utils'

interface ILicensingOptionsProps {
  mediaObjectId: number
  onRequestClose: VoidFunction
  licenseIndex?: number
}

interface IInternalProps extends ILicensingOptionsProps, RouteComponentProps {
  errorCode?: string
  canRequestLicense: boolean
  hasPendingCreditRequest: boolean
  hasInsufficientFundError?: boolean
  requestLicense: typeof requestLicense
  clearMediaObjectErrors: typeof clearMediaObjectErrors
  fetchMediaObjectLicenseOptions: typeof fetchMediaObjectLicenseOptions
}

export const PendingCreditRequest = () => (
  <Text color={Theme.grey600Color} small>
    <FormattedMessage id="contentLibrary.invoiceCredit.creditRequestPending" />
  </Text>
)

export const LicensingOptions = ({
  errorCode,
  mediaObjectId,
  licenseIndex: licenseIndexProps,
  onRequestClose,
  hasInsufficientFundError,
  fetchMediaObjectLicenseOptions: fetchLicenseOptionsAction,
}: IInternalProps) => {
  const [licenseIndex, setLicenseIndex] = useState<number>(licenseIndexProps || 0)
  const { showModal } = useModal()

  const fetchLicenses = () => {
    fetchLicenseOptionsAction(mediaObjectId)
  }
  useEffect(fetchLicenses, [mediaObjectId])

  const onLicenseRequest = () => {
    return showModal(CreditManagementWrapper, { mediaObjectId, licenseIndex })
  }
  return (
    <Fragment>
      <LicensingHeader>
        <FormattedMessage id="licensing.licensingOptions.header" />
      </LicensingHeader>
      <CloseIcon onClick={onRequestClose} />
      <LicenseOptions mediaObjectId={mediaObjectId} onLicenseChange={setLicenseIndex} />
      <Container topOuterSpacing={1.5} leftOuterSpacing={3.125}>
        <Flex justifyBetween alignStart>
          <MediaDetails mediaObjectId={mediaObjectId} />
          <MediaPreview mediaObjectId={mediaObjectId} />
        </Flex>
      </Container>
      <Container topOuterSpacing={2.75} leftOuterSpacing={3.125}>
        <LicenseOptionPriceDetails mediaObjectId={mediaObjectId} licenseIndex={licenseIndex} />
      </Container>
      <Container topOuterSpacing={1.25} leftOuterSpacing={3.125}>
        {!hasInsufficientFundError && errorCode && <StripeError errorCode={errorCode} />}
      </Container>
      <Container topOuterSpacing={1.25}>
        <RequestLicenseButton onLicenseRequest={onLicenseRequest}>
          <FormattedMessage id="licensing.licensingOptions.cta" />
        </RequestLicenseButton>
      </Container>
      <Container>
        <Flex justifyCenter alignCenter>
          <Text textAlign="center" small color={Theme.grey800Color}>
            <FormattedMessage id="contentLibrary.licensing.creditManagement.paymentOption" />
          </Text>
        </Flex>
      </Container>
      <Container topOuterSpacing={1.25}>
        <LicensingAgreementsLink color={Theme.primaryColor} />
      </Container>
    </Fragment>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  const hasCreditCardInfo = selectHasCreditCardInfo(state)
  const hasInvoiceCredit = !isEmpty(selectCreditAmount(state))
  return {
    errorCode: selectFirstErrorCode(state),
    canRequestLicense: hasCreditCardInfo || hasInvoiceCredit,
    hasPendingCreditRequest: selectHasPendingCreditRequest(state),
    hasInsufficientFundError: selectHasInsufficientFundError(state),
  }
}

const mapDispatchToProps = {
  fetchMediaObjectLicenseOptions,
  requestLicense,
  clearMediaObjectErrors,
}

export default compose<IInternalProps, ILicensingOptionsProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(LicensingOptions)
