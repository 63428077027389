import { call, put, select, takeEvery } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { IHateoasLink } from '@store'
import { trackEvent } from '@store/analytics'
import { selectSubmissionTrackingProps } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import { addNote, addNoteError, addNoteSuccess, NotesActionTypes } from '@store/notes'
import { selectAddNoteLink } from '@store/notes'
import { fetchSubmission } from '@store/submissions'
import { networkRequest } from '@utils'

export function* handleAddNote(action: ReturnType<typeof addNote>) {
  try {
    const { submissionId, campaignId, requestBody } = action.payload
    const { refreshSubmission } = action.meta
    const authToken: string = yield select(selectAuthToken)
    const addNoteLink: IHateoasLink = yield select(selectAddNoteLink, submissionId)

    const note = yield call(
      networkRequest,
      addNoteLink.method,
      addNoteLink.href,
      requestBody,
      authToken,
    )

    yield put(addNoteSuccess(submissionId, note))
    const eventProperties = yield select(selectSubmissionTrackingProps, campaignId, submissionId)
    yield put(trackEvent(EventTrackingNames.AddSubmissionNote, eventProperties))

    if (refreshSubmission) {
      yield put(fetchSubmission(submissionId, campaignId))
    }
  } catch (error) {
    yield put(addNoteError(error))
  }
}

export function* watchAddNoteRequest() {
  yield takeEvery(NotesActionTypes.ADD_REQUEST, handleAddNote)
}
