import { useContext, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { CampaignContext } from '@context/Campaign'
import { EventTrackingNames } from '@enums'
import { trackEvent } from '@store/analytics'
import { selectCampaignStatus, selectRole } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import { selectActivePCRChannel } from '@store/router'
import { getCampaignMetricsTab } from '@utils/analyticsFunctions'

type Props = {
  metricMessageId: string
  delayInSeconds?: number
}
export const useMetricTooltipTracking = ({ metricMessageId, delayInSeconds = 0.25 }: Props) => {
  const dispatch = useDispatch()
  const {
    campaign: { id: campaignId },
  } = useContext(CampaignContext)
  const trackEventTimeout = useRef<ReturnType<typeof setTimeout>>()
  const { activeChannel, role, status } = useSelector((state: IApplicationState) => {
    return {
      activeChannel: selectActivePCRChannel(state),
      role: selectRole(state, campaignId),
      status: selectCampaignStatus(state, campaignId),
    }
  })
  const sendTrackEvent = () => {
    if (trackEventTimeout.current) {
      clearTimeout(trackEventTimeout.current)
    }

    trackEventTimeout.current = setTimeout(() => {
      dispatch(
        trackEvent(EventTrackingNames.ViewCampaignMetricsTooltip, {
          brief_id: campaignId,
          role,
          brief_state: status,
          metrics_tab: getCampaignMetricsTab(activeChannel),
          metrics_tooltip: metricMessageId,
        }),
      )
    }, delayInSeconds * 1000)
  }

  const clearTrackingTimeOut = () => {
    if (trackEventTimeout.current) {
      clearTimeout(trackEventTimeout.current)
    }
    trackEventTimeout.current = undefined
  }

  return {
    sendTrackEvent,
    clearTrackingTimeOut,
  }
}
