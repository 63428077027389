import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const MainModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 16.5rem;
  margin: auto;
  min-height: calc(100vh - 2rem);
  ${breakpoint('phoneUpperBoundary')`
    display: block;
    width: auto;
    margin: 0;
    min-height: 0;
  `};
`
export { MainModalContent }
