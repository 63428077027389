import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'
import { FlexItem } from 'styled-flex-component'

import ShareableContentLibraryTrackedRoute from '@components/ShareableContentLibrary/TrackedRoute'
import { SubmissionCardPriceSumaryWrapper } from '@components/Submission/Card/PricePanel/PricePanel.styled'
import { PricePanelStyled, PricePanelWrapper } from '@components/UI/PricePanel/PricePanel.styled'
import { EventTrackingNames } from '@enums'
import { useToggle } from '@hooks/useToggle'
import { IApplicationState } from '@store'
import { ILicenseOption } from '@store/campaigns'
import { fetchMediaObjectLicenseOptions } from '@store/licenses'
import { selectShareableContentLibraryLicenseOptions } from '@store/mediaObjects'
import ShareableContentLibraryFullPricePanelAmount from './Amount'
import ShareableContentLibraryFullPricePanelPopover from './Popover'

interface IProps {
  mediaObjectId: number
}

interface IInternalProps extends IProps {
  shouldRequestLicenseOptions: boolean
  fetchLicenseOptions: typeof fetchMediaObjectLicenseOptions
  licenses: ReadonlyArray<ILicenseOption>
}

export const ShareableContentLibraryFullPricePanel: React.FC<IInternalProps> = ({
  mediaObjectId,
  shouldRequestLicenseOptions,
  fetchLicenseOptions: fetchLicenseOptionsAction,
  licenses,
}) => {
  const { toggleIsVisible, toggleHide, toggle } = useToggle()
  React.useEffect(() => {
    if (shouldRequestLicenseOptions) {
      fetchLicenseOptionsAction(mediaObjectId)
    }
  }, [])
  return (
    <React.Fragment>
      {toggleIsVisible && (
        <SubmissionCardPriceSumaryWrapper top={0}>
          <ShareableContentLibraryFullPricePanelPopover onHide={toggleHide} licenses={licenses} />
        </SubmissionCardPriceSumaryWrapper>
      )}
      <ShareableContentLibraryTrackedRoute
        hasTracking={!toggleIsVisible}
        eventName={EventTrackingNames.ShareableCLViewPriceSummary}
        mediaObjectId={mediaObjectId}
      >
        <PricePanelStyled onClick={toggle} data-testid="price-panel-container">
          <PricePanelWrapper justifyBetween alignCenter>
            <FlexItem>
              <ShareableContentLibraryFullPricePanelAmount />
            </FlexItem>
          </PricePanelWrapper>
        </PricePanelStyled>
      </ShareableContentLibraryTrackedRoute>
    </React.Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { mediaObjectId }: IProps) => ({
  licenses: selectShareableContentLibraryLicenseOptions(state, mediaObjectId),
})

const mapDispatchToProps = {
  fetchLicenseOptions: fetchMediaObjectLicenseOptions,
}
export default compose<IInternalProps, IProps>(connect(mapStateToProps, mapDispatchToProps))(
  ShareableContentLibraryFullPricePanel,
)
