import { Component, Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Confirmation from '@components/UI/Confirmation'
import { FooterButtonWrapper } from '@components/UI/Confirmation/Confirmation.styled'
import TrackedRoute from '@components/UI/TrackedRoute/TrackedRoute'
import { IAnalyticsTrackingContextProps } from '@context/AnalyticsTracking'
import { ICampaignContextProps } from '@context/Campaign'
import { IModalContextProps } from '@context/Modal'
import { EventTrackingNames } from '@enums'
import { withAnalyticsTracking, withCampaign, withModal } from '@hocs'
import { IResponseError } from '@lib/error'
import { IApplicationState } from '@store'
import { clearErrors, selectisAdding, selectMemberErrors } from '@store/members'
import Theme from '@theme'
import { Button, Icon } from '@tribegroup'
import { InboxMemberAddForm } from '../Form/Form'

interface IInternalProps
  extends IAnalyticsTrackingContextProps,
    ICampaignContextProps,
    IModalContextProps {
  errors?: IResponseError
  clearErrors: typeof clearErrors
  isAdding: boolean
}

export class InboxMemberInvite extends Component<IInternalProps> {
  state = {
    showErrorMessage: false,
  }
  openModal = () => {
    this.props.showModal(InboxMemberAddForm)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAdding && !this.props.isAdding) {
      this.props.hideModal()
    }
    if (!prevProps.errors && this.props.errors) {
      this.showErrorMessage()
    }
  }

  showErrorMessage = () => this.setState({ showErrorMessage: true })

  hideErrorMessage = () => this.setState({ showErrorMessage: false }, this.delayedClearErrors)

  delayedClearErrors = () => setTimeout(this.props.clearErrors, 500)

  render() {
    const { getCampaignTrackingProps, errors } = this.props

    const errorMessage = errors && errors.messages && errors.messages[0]
    const hasErrorCodeOrMessage =
      errorMessage && ((errorMessage as any).message || (errorMessage as any).error_code)

    return (
      <Fragment>
        <TrackedRoute
          eventName={EventTrackingNames.DisplayMemberInviteModal}
          properties={getCampaignTrackingProps()}
        >
          <Icon
            glyph="circle-plus-heavy"
            color={Theme.grey900Color}
            data-cy-element="invite-member"
            data-testid="invite-member"
            onClick={this.openModal}
          />
        </TrackedRoute>
        <Confirmation
          title={
            hasErrorCodeOrMessage ? (
              <FormattedMessage
                id={`inbox.sidebar.members.form.error.${
                  errorMessage && (errorMessage as any).error_code
                }`}
                defaultMessage={errorMessage && (errorMessage as any).message}
              />
            ) : (
              <FormattedMessage id="inbox.sidebar.members.form.error.generic" />
            )
          }
          content={<FormattedMessage id="inbox.sidebar.members.form.error.body" />}
          footerButtons={
            <Fragment>
              <FooterButtonWrapper>
                <Button
                  scheme="primary"
                  onClick={this.hideErrorMessage}
                  data-cy-element="error-modal-okay-button"
                >
                  <FormattedMessage id="core.text.okay" />
                </Button>
              </FooterButtonWrapper>
            </Fragment>
          }
          isOpen={this.state.showErrorMessage}
          onCloseIconClick={this.hideErrorMessage}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    isAdding: selectisAdding(state),
    errors: selectMemberErrors(state) as any,
  }
}

const mapDispatchToProps = {
  clearErrors,
}

export default compose<IInternalProps, {}>(
  withModal,
  withCampaign,
  withAnalyticsTracking,
  connect(mapStateToProps, mapDispatchToProps),
)(InboxMemberInvite)
