import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { PanelWrapper } from '@components/Builder/Layout/Layout.styled'
import TrackedRoute from '@components/UI/TrackedRoute/TrackedRoute'
import { TRIBE_BRAND_FANS_EDUCATION_LINK } from '@constants'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { EventTrackingNames } from '@enums'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import {
  selectCampaignIdFromDraft,
  selectDraftBrandId,
  selectIsDisabledAttribute,
} from '@store/drafts'
import Theme from '@theme'
import { Container, FormInput, H4, Link, Panel, Text, TextField } from '@tribegroup'
import { getMessage } from '@utils'
import BudgetErrorMessage from '../Error/Message'
import BuilderBudgetCreatorGroupSelectionTip from './Tip/Tip'
import {
  BRAND_FAN_GROUP_IDS,
  CREATOR_GROUP_BRAND_FANS,
  CREATOR_GROUP_COMMUNITY,
} from './CreatorGroup'
import { SelectionWrapper } from './CreatorGroup.styled'
import BuilderBudgetCreatorGroupOption from './Option'

export const SELECTED_CREATOR_GROUP_VALID = 'selected_creator_group_valid'

interface IProps {
  selectedOption: string
  onOptionChange: (selected: string) => void
}

interface IInternalProps extends IProps, InjectedIntlProps, IBuilderValidationContextProps {
  campaignId: number
  brandId: number
  isSelectionDisabled: boolean
}

const BuilderBudgetCreatorGroupSelection: React.FC<IInternalProps> = ({
  intl,
  brandId,
  campaignId,
  selectedOption,
  onOptionChange,
  validationResults,
  isSelectionDisabled,
  clearFieldValidation,
}) => {
  const brandFanGroupSelected = selectedOption === CREATOR_GROUP_BRAND_FANS
  const creatorCommunitySelected = selectedOption === CREATOR_GROUP_COMMUNITY

  const selectionDisabledWithBrandFanGroupSelected = isSelectionDisabled && brandFanGroupSelected
  const selectionDisabledWithcreatorCommunitySelected =
    isSelectionDisabled && creatorCommunitySelected

  const creatorCommunityLabelColor = selectionDisabledWithBrandFanGroupSelected
    ? Theme.grey700Color
    : Theme.defaultColor
  const brandFanGroupLabelColor = selectionDisabledWithcreatorCommunitySelected
    ? Theme.grey700Color
    : Theme.defaultColor

  React.useEffect(() => {
    clearFieldValidation(SELECTED_CREATOR_GROUP_VALID)
  }, [selectedOption])

  return (
    <PanelWrapper>
      <BuilderBudgetCreatorGroupSelectionTip />
      <Panel
        title={
          <H4 color={Theme.defaultColor}>
            <FormattedMessage id="builder.budget.creatorGroup.title" />
          </H4>
        }
      >
        <SelectionWrapper data-testid="builder-budget-creator-group-selection">
          <FormInput
            validationRule={{
              required: {
                value: true,
                message: getMessage(intl, 'builder.budget.creatorGroup.creatorCommunity.selected'),
              },
            }}
          >
            <TextField name={SELECTED_CREATOR_GROUP_VALID} type="hidden" value={'valid'} readOnly />
          </FormInput>
          <Container>
            <Text color={Theme.defaultColor}>
              <FormattedMessage id="builder.budget.creatorGroup.subText" />
            </Text>
          </Container>
          <Container topOuterSpacing={1.5} bottomOuterSpacing={1.5}>
            <Container bottomOuterSpacing={0.75}>
              <TrackedRoute
                eventName={EventTrackingNames.CampaignBuilderTargetCreators}
                eventTrigger="change"
                properties={{
                  brief_id: campaignId,
                  brand_id: brandId,
                  target_creators: CREATOR_GROUP_COMMUNITY,
                }}
              >
                <BuilderBudgetCreatorGroupOption
                  onOptionChange={onOptionChange}
                  defaultChecked={creatorCommunitySelected}
                  showSelectedAsCheck={selectionDisabledWithcreatorCommunitySelected}
                  disabled={selectionDisabledWithBrandFanGroupSelected}
                  value={CREATOR_GROUP_COMMUNITY}
                  labelColor={creatorCommunityLabelColor}
                  label={
                    <FormattedMessage
                      id={`builder.budget.creatorGroup.${CREATOR_GROUP_COMMUNITY}`}
                    />
                  }
                />
              </TrackedRoute>
            </Container>
            <Container bottomOuterSpacing={0.75}>
              <TrackedRoute
                eventName={EventTrackingNames.CampaignBuilderTargetCreators}
                eventTrigger="change"
                properties={{
                  brief_id: campaignId,
                  brand_id: brandId,
                  target_creators: CREATOR_GROUP_BRAND_FANS,
                }}
              >
                <BuilderBudgetCreatorGroupOption
                  scheme={'primary'}
                  onOptionChange={onOptionChange}
                  defaultChecked={brandFanGroupSelected}
                  value={CREATOR_GROUP_BRAND_FANS}
                  showSelectedAsCheck={selectionDisabledWithBrandFanGroupSelected}
                  disabled={selectionDisabledWithcreatorCommunitySelected}
                  label={
                    <Flex>
                      <Container inline rightOuterSpacing={0.75}>
                        <H4 color={brandFanGroupLabelColor}>
                          <FormattedMessage
                            id={`builder.budget.creatorGroup.${CREATOR_GROUP_BRAND_FANS}`}
                          />
                        </H4>
                      </Container>
                      <Link
                        href={TRIBE_BRAND_FANS_EDUCATION_LINK}
                        target="_blank"
                        color={Theme.secondaryColor}
                      >
                        <Text color={Theme.primaryColor}>
                          <FormattedMessage id="builder.budget.creatorGroup.whatIsThis" />
                        </Text>
                      </Link>
                    </Flex>
                  }
                />
              </TrackedRoute>
            </Container>
          </Container>
          <BudgetErrorMessage results={validationResults} errorKey={SELECTED_CREATOR_GROUP_VALID} />
        </SelectionWrapper>
      </Panel>
    </PanelWrapper>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  brandId: selectDraftBrandId(state),
  campaignId: selectCampaignIdFromDraft(state),
  isSelectionDisabled: selectIsDisabledAttribute(state, BRAND_FAN_GROUP_IDS),
})

export { BuilderBudgetCreatorGroupSelection }
export default compose<IInternalProps, IProps>(
  injectIntl,
  withBuilderValidation,
  connect(mapStateToProps),
)(BuilderBudgetCreatorGroupSelection)
