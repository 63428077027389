import { FormattedMessage } from 'react-intl'

import { HOW_TO_PITCH, HOW_TO_SUBMIT_TO_CAMPAIGN } from '@constants'
import Theme from '@theme'
import { Container, Link as TribeLink, Text } from '@tribegroup'
import { BlockText } from './Block/Block.styled'
import CampaignPreviewBlock from './Block'

interface IProps {
  pitchingEnabled?: boolean
  isSocial?: boolean
}

export const CampaignPreviewPitching = ({ pitchingEnabled, isSocial }: IProps) => {
  const campaignType = isSocial ? 'social' : 'content'

  if (pitchingEnabled) {
    return (
      <CampaignPreviewBlock
        title={<FormattedMessage id="campaign.preview.pitching.header" />}
        emptyLines={[15]}
      >
        <BlockText small color={Theme.grey800Color}>
          <FormattedMessage id="campaign.preview.pitching.text" />
        </BlockText>
        <Container topOuterSpacing={0.5}>
          <TribeLink href={HOW_TO_PITCH} target="_blank">
            <Text color={Theme.primaryColor} customFontSize={0.8125}>
              <FormattedMessage id="campaign.preview.pitching.learnMore" />
            </Text>
          </TribeLink>
        </Container>
      </CampaignPreviewBlock>
    )
  }

  if (pitchingEnabled === false) {
    return (
      <CampaignPreviewBlock
        title={<FormattedMessage id="campaign.preview.contentUpfront.header" />}
        emptyLines={[15]}
      >
        <BlockText small color={Theme.grey800Color}>
          <FormattedMessage id={`campaign.preview.${campaignType}.contentUpfront.text`} />
        </BlockText>
        {isSocial && (
          <Container topOuterSpacing={0.5}>
            <TribeLink href={HOW_TO_SUBMIT_TO_CAMPAIGN} target="_blank">
              <Text color={Theme.primaryColor} customFontSize={0.8125}>
                <FormattedMessage id="campaign.preview.contentUpfront.learnMore" />
              </Text>
            </TribeLink>
          </Container>
        )}
      </CampaignPreviewBlock>
    )
  }

  return <></>
}
