import React from 'react'

import capitalize from 'lodash.capitalize'
import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import ActionIcon from '@components/UI/ActionIcon'
import { SubmissionChannels } from '@enums'
import Theme from '@theme'
import { BetaLabel, Container, Text } from '@tribegroup'
import { getChannelInitialState, moveHandlesToTags } from '@utils'
import { ChannelsContext } from '../Channels'
import { ActionIconWrapper } from '../Channels.styled'

interface IProps {
  channelName: SubmissionChannels
  isBeta?: boolean
  disabled?: boolean
}

const ChannelsPlaceholder: React.FC<IProps> = ({ channelName, isBeta, disabled }) => {
  const { channelItems, setChannelItems } = React.useContext(ChannelsContext)
  const onClick = () => {
    const newChannel = getChannelInitialState(channelName)
    setChannelItems(moveHandlesToTags({ ...channelItems, [channelName]: newChannel }))
  }

  const getChannelName = (channel: SubmissionChannels) => {
    if (channel === SubmissionChannels.TikTok) {
      return 'TikTok'
    }
    return capitalize(channelName)
  }

  return (
    <Flex justifyStart alignCenter>
      <ActionIconWrapper
        data-cy-element={`${channelName}-plus-button`}
        data-testid={`${channelName}-plus-button`}
      >
        <ActionIcon
          glyph="plus-heavy"
          backgroundColor={Theme.whiteColor}
          color={Theme.primaryColor}
          size={0.8}
          onClick={onClick}
          disabled={disabled}
        />
      </ActionIconWrapper>
      <Text small color={disabled ? Theme.grey700Color : Theme.defaultColor}>
        <FormattedMessage
          id="builder.brief.socialMedia.addChannel"
          values={{ channelName: getChannelName(channelName) }}
        />
      </Text>
      {isBeta && (
        <Container inline leftOuterSpacing={0.5}>
          <BetaLabel disabled={disabled} />
        </Container>
      )}
    </Flex>
  )
}

export { ChannelsPlaceholder }
export default ChannelsPlaceholder
