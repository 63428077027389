import { FixedContainer } from './Base.styled'
import { useSnackBar } from './context'
import { SnackbarError } from './Error'
import { SnackbarInProgress } from './InProgress'
import { SnackbarSuccess } from './Success'
import { ISnackbar } from './types'

type SnackbarComponent = React.FC<{ snackbar: ISnackbar }>

const snackbarSeverityComponentMap: Record<ISnackbar['severity'], SnackbarComponent> = {
  inProgress: SnackbarInProgress,
  success: SnackbarSuccess,
  error: SnackbarError,
}

const SnackbarBase = () => {
  const { snackBars } = useSnackBar()

  return (
    <FixedContainer>
      {snackBars.map((snackBar) => {
        const Component = snackbarSeverityComponentMap[snackBar.severity]
        return <Component key={snackBar.id} snackbar={snackBar} />
      })}
    </FixedContainer>
  )
}

export default SnackbarBase
