import { FormattedMessage } from 'react-intl'
import { call, put, select, take, takeLatest } from 'redux-saga/effects'

import { ResponseError } from '@lib/error'
import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchMeRequest,
  fetchMeSuccess,
  selectAddCustomerLink,
  selectSetupIntentClientSecret,
  setUpCard,
  SetupCardActionTypes,
  setUpCardError,
  setUpCardSuccess,
} from '@store/me'
import { Toaster } from '@tribegroup'
import { networkRequest, stripeErrorToResponseError } from '@utils'

export function* handleSetupCard(action: ReturnType<typeof setUpCard>) {
  try {
    const { stripe, element, onSuccess } = action.payload
    const clientSecret = yield select(selectSetupIntentClientSecret)
    const setupIntentResponse = yield call(stripe.confirmCardSetup, clientSecret, {
      payment_method: { card: element },
    })
    if (setupIntentResponse.error) {
      throw new ResponseError({
        error: stripeErrorToResponseError(setupIntentResponse.error),
      })
    }
    const { payment_method } = setupIntentResponse.setupIntent
    const authToken: string = yield select(selectAuthToken)
    const addCustomerLink: IHateoasLink = yield select(selectAddCustomerLink)
    const me = yield call(
      networkRequest,
      addCustomerLink.method,
      addCustomerLink.href,
      { payment_method },
      authToken,
    )

    yield put(setUpCardSuccess(me))

    yield put(fetchMeRequest())

    if (onSuccess) {
      yield take(fetchMeSuccess)
      yield call(onSuccess)
    }

    yield call(Toaster.createToast, FormattedMessage, { id: 'account.payment.updateSuccess' })
  } catch (error) {
    yield put(setUpCardError(error))
  }
}

export function* watchSetupCardRequest() {
  yield takeLatest(SetupCardActionTypes.SETUP_REQUEST, handleSetupCard)
}
