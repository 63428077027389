import { DEFAULT_TENANT } from '@constants'
import { ResponseError } from '@lib/error'

const REDIRECT_HTTP_STATUS_CODES: ReadonlyArray<number> = [301, 302, 303, 307, 308]

export const networkRequest = (
  method: string,
  url: string,
  data?: any,
  token?: string,
  customHeaders?: any,
) => {
  const basicHeaders = customHeaders || {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  const authTokenHeader = token && { Authorization: `Bearer ${token}` }

  const headers = url.startsWith(process.env.API_HOST as string)
    ? {
        ...basicHeaders,
        ...authTokenHeader,
        'Tenant-Identifier': process.env.APP_NAME || DEFAULT_TENANT,
      }
    : {
        ...basicHeaders,
        ...authTokenHeader,
      }

  return fetch(url, {
    body: JSON.stringify(data),
    method,
    headers,
  })
    .then(async (response: Response) => {
      const isRedirecting = REDIRECT_HTTP_STATUS_CODES.includes(response.status)
      if (!response.ok && !isRedirecting) {
        throw response
      }
      return response
        .json()
        .then((json) => json)
        .catch(() => response.statusText)
    })
    .catch(async (response: any) => {
      const json = await response.json()
      throw new ResponseError(json, response.status)
    })
}

export default networkRequest
