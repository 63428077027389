import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  addBrand,
  AddBrandActionTypes,
  addBrandError,
  addBrandSuccess,
  IBrand,
  selectAddBrandLink,
} from '@store/brands'
import { networkRequest, objectToQueryString, queryStringtoObject } from '@utils'

export function* handleAddBrand(action: ReturnType<typeof addBrand>) {
  try {
    const authToken: string = yield select(selectAuthToken)
    const { history, redirect, event } = action.meta
    const addBrandLink: IHateoasLink = yield select(selectAddBrandLink)
    const brand: IBrand = yield call(
      networkRequest,
      addBrandLink.method,
      addBrandLink.href,
      action.payload,
      authToken,
    )
    yield put(addBrandSuccess(brand))
    if (Array.isArray(event)) {
      yield event.map(({ name, properties }) => put(trackEvent(name, properties)))
    } else {
      yield put(trackEvent(event.name, event.properties))
    }
    if (history && redirect) {
      const qs = objectToQueryString({
        ...queryStringtoObject(history.location.search),
        brandId: brand.id,
      })
      yield call(history.push, `${redirect}${qs}`)
    }
  } catch (error) {
    yield put(addBrandError(error))
  }
}

export function* watchAddBrandRequest() {
  yield takeLatest(AddBrandActionTypes.ADD_REQUEST, handleAddBrand)
}
