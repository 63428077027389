import { DependencyList, useEffect } from 'react'

const useActionDispatcherEffect = (
  condition: boolean,
  dispatchAction: any,
  dispatchArgs: ReadonlyArray<any>,
  deps?: DependencyList,
) => {
  useEffect(() => {
    if (condition) {
      dispatchAction(...dispatchArgs)
    }
  }, deps)
}

export { useActionDispatcherEffect }
