import { format, isSameDay, subMinutes } from 'date-fns'
import { InjectedIntlProps, injectIntl } from 'react-intl'

import { BEFORE_24_HOURS_IN_MINS, DAY_MONTH } from '@constants'
import { Tip } from '@tribegroup'
import { getFormattedTimeWithMeridiem, getLocalTimezoneName } from '@utils'

interface IPublishingManagerZoneHintProps {
  selectedDate: Date
}

interface IInternalProps extends IPublishingManagerZoneHintProps, InjectedIntlProps {}

export const PublishingManagerZoneHint = ({ selectedDate, intl }: IInternalProps) => {
  const startTime = subMinutes(new Date(selectedDate), BEFORE_24_HOURS_IN_MINS) // 23 hours, 59 minutes
  const endTime = selectedDate
  const sameDay = isSameDay(startTime, endTime)
  const dates = sameDay
    ? {
        date: format(selectedDate, DAY_MONTH),
      }
    : {
        startDate: format(startTime, DAY_MONTH),
        endDate: format(endTime, DAY_MONTH),
      }

  return (
    <Tip
      text={intl.formatMessage(
        {
          id: `socialSubmission.card.modal.approve.managerZone.${sameDay ? 'sameDay' : 'nextDay'}`,
        },
        {
          startTime: getFormattedTimeWithMeridiem(startTime),
          endTime: getFormattedTimeWithMeridiem(endTime),
          timeZone: getLocalTimezoneName(selectedDate),
          ...dates,
        },
      )}
    />
  )
}

export default injectIntl(PublishingManagerZoneHint)
