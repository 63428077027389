import styled from 'styled-components'

import Theme from '@theme'
import { BlockText } from '../Block/Block.styled'

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.5rem;
  word-break: break-word;
  overflow-wrap: break-word;
`

export const Tag = styled(BlockText)`
  &:not(:last-of-type) {
    margin-right: 0.5rem;
  }

  font-size: 0.75rem;
  padding: 0.375rem 0.625rem;
  color: ${Theme.grey900Color};
  margin-bottom: 0.5rem;
  background-color: ${Theme.grey200Color};
  border-radius: 0.25rem;
`

export const ChannelName = styled(BlockText)`
  font-size: 0.75rem;
  text-transform: capitalize;
  color: ${Theme.grey800Color};
`
