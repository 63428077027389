import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { withBuilderValidation, withDraft } from '@hocs'
import { IApplicationState } from '@store'
import { selectTargetAudienceLocationCodes } from '@store/campaigns'
import { selectIsDisabledAttribute } from '@store/drafts'
import { IAudienceLocation, selectAudienceLocations } from '@store/rootResource'
import Theme from '@theme'
import { Container, MultiSelectButtonGroup } from '@tribegroup'
import BuilderBudgetTargetPanelTitle from '../PanelTitle'
import { AUDIENCES_HEADER_I18N_PREFIX } from '../Target'
import { TARGET_AUDIENCE_LOCATION_CODES } from './Audience'
import BuilderBudgetTargetAudienceRequiredField from './RequiredField'

interface IInternalProps
  extends IBuilderValidationContextProps,
    IDraftContextProps,
    ConnectedProps<typeof connector> {}

const BuilderBudgetTargetAudienceLocation: React.FC<IInternalProps> = ({
  draft,
  updateForm,
  isDisabledAttr,
  audienceLocations,
  clearFieldValidation,
  campaignAudienceLocationCodes,
}) => {
  const locationOptions = audienceLocations.map((location: IAudienceLocation) => ({
    value: location.code,
    display: location.display,
  }))

  const onLocationChange = (locationCodes: ReadonlyArray<string>) => {
    clearFieldValidation(TARGET_AUDIENCE_LOCATION_CODES)
    updateForm({
      audience_location_codes: locationCodes || [],
    })
  }

  return (
    <Container data-testid="builder-budget-target-audience-location">
      <BuilderBudgetTargetPanelTitle>
        <FormattedMessage id={`${AUDIENCES_HEADER_I18N_PREFIX}.location`} />
      </BuilderBudgetTargetPanelTitle>
      <BuilderBudgetTargetAudienceRequiredField
        fieldName={TARGET_AUDIENCE_LOCATION_CODES}
        fieldErrorKey="builder.budget.error.location.required"
      >
        <Container topOuterSpacing={1}>
          <MultiSelectButtonGroup
            name={TARGET_AUDIENCE_LOCATION_CODES}
            options={locationOptions}
            onSelectedValuesChange={onLocationChange}
            selectedValues={draft.audience_location_codes || []}
            disabledValues={isDisabledAttr ? campaignAudienceLocationCodes : []}
            activeBackgroundColor={Theme.primary200Color}
          />
        </Container>
      </BuilderBudgetTargetAudienceRequiredField>
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { draft }: IDraftContextProps) => ({
  audienceLocations: selectAudienceLocations(state),
  isDisabledAttr: selectIsDisabledAttribute(state, TARGET_AUDIENCE_LOCATION_CODES),
  campaignAudienceLocationCodes: selectTargetAudienceLocationCodes(state, draft.id as number),
})

const connector = connect(mapStateToProps)
export default compose<IInternalProps, {}>(
  withBuilderValidation,
  withDraft,
  connector,
)(BuilderBudgetTargetAudienceLocation)
