import styled, { css } from 'styled-components'

import Theme from '@theme'
import { Icon } from '@tribegroup'

export const NavigationStyled = styled.div`
  position: relative;
`

const NavIconsPosition = css`
  position: absolute;
  top: calc(50% - 1.125rem);
`

export const NavigationPrevButton = styled.div`
  ${NavIconsPosition}
  left: -3.75rem;
`

export const NavigationNextButton = styled.div`
  ${NavIconsPosition}
  right: -3.75rem;
`

export const CircledIcon = styled(Icon)`
  background: ${Theme.whiteColor};
  padding: 0.625rem;
  border-radius: 50%;
`
