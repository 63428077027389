import React, { useContext } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Grid } from 'react-styled-flexboxgrid'
import { compose } from 'recompose'

import { TRIBE_PRICING_PAGE_METRIC_SUMMARY_UTM } from '@constants'
import { CampaignContext } from '@context/Campaign'
import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import {
  selectStoriesAvgReachPct,
  selectStoriesOverallCPM,
  selectStoriesTotalFollowers,
  selectStoriesTotalImpressions,
  selectStoriesTotalPosts,
  selectStoriesTotalReach,
  selectStoriesTotalSpend,
} from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import AnalyticsPostCampaignReportMetric from '../Metric/Metric'
import { StyledCol, StyledRow } from '../Summary/Summary.styled'

const externalLink = TRIBE_PRICING_PAGE_METRIC_SUMMARY_UTM
const productMetricGroup = 'reporting_instagram_stories'

type IInternalProps = ConnectedProps<typeof connector>

const AnalyticsPostCampaignReportStory: React.FC<IInternalProps> = ({
  totalPosts,
  totalSpend,
  totalFollowers,
  totalReach,
  avgReachPct,
  totalImpressions,
  overallCPM,
}) => {
  const {
    campaign: { is_price_hidden: isPriceHidden },
  } = useContext(CampaignContext)

  return (
    <Grid fluid>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-posts-cell">
          <AnalyticsPostCampaignReportMetric title="ig.stories.totalStories" value={totalPosts} />
        </StyledCol>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="total-spend-cell">
            <AnalyticsPostCampaignReportMetric
              title="ig.stories.totalSpend"
              value={totalSpend.amount}
              currency={totalSpend.currency}
            />
          </StyledCol>
        )}
        <StyledCol xs={6} lg={3} data-testid="total-followers-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.stories.totalFollowers"
            value={totalFollowers}
          />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-reach-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.stories.totalReach"
            value={totalReach}
            externalLink={externalLink}
            productMetricGroup={productMetricGroup}
            productMetricValue="total_reach"
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="avg-reach-pct-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.stories.avgReachPct"
            value={avgReachPct}
            showPercentSign
            externalLink={externalLink}
            productMetricGroup={productMetricGroup}
            productMetricValue="avg_reach_pct"
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-impressions-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.stories.totalImpressions"
            value={totalImpressions}
            externalLink={externalLink}
            productMetricGroup={productMetricGroup}
            productMetricValue="total_impressions"
          />
        </StyledCol>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="overall-cpm-cell">
            <AnalyticsPostCampaignReportMetric
              title="ig.stories.overallCPM"
              value={overallCPM && overallCPM.amount}
              currency={overallCPM && overallCPM.currency}
              externalLink={externalLink}
              productMetricGroup={productMetricGroup}
              productMetricValue="overall_cpm"
            />
          </StyledCol>
        )}
      </StyledRow>
    </Grid>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match)!
  const channel = PCRChannels.IGStories

  return {
    totalPosts: selectStoriesTotalPosts(state, campaignId, channel),
    totalSpend: selectStoriesTotalSpend(state, campaignId, channel),
    totalFollowers: selectStoriesTotalFollowers(state, campaignId, channel),
    totalReach: selectStoriesTotalReach(state, campaignId, channel),
    avgReachPct: selectStoriesAvgReachPct(state, campaignId, channel),
    totalImpressions: selectStoriesTotalImpressions(state, campaignId, channel),
    overallCPM: selectStoriesOverallCPM(state, campaignId, channel),
  }
}

const connector = connect(mapStateToProps)

export { AnalyticsPostCampaignReportStory }
export default compose<IInternalProps, {}>(withRouter, connector)(AnalyticsPostCampaignReportStory)
