import React from 'react'

import styled, { css } from 'styled-components'

import { IScheme } from '../Interfaces/schemes'
import { getColor, themed } from '../Theme'

export interface ITextFieldProps extends IScheme, React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Sets the defaultValue for the text field
   *
   * @default text
   */
  type?: 'text' | 'number' | 'email' | 'search' | 'tel' | 'password' | 'hidden'

  /**
   * Sets the defaultValue for the text field
   *
   * @default ""
   */
  defaultValue?: any

  /**
   * Sets the placeholder for the text field
   *
   * @default ""
   */
  placeholder?: string

  /**
   * Sets the value for the text field
   *
   * @default ""
   */
  value?: string | number

  /**
   * Sets the width of the text field
   *
   * @default auto
   */
  width?: number

  /**
   * Sets the disabled attribute of the textfield
   *
   * @default auto
   */
  disabled?: boolean

  /**
   * Indicates that this field has invalid value
   *
   * @default false
   */
  invalid?: boolean

  /**
   * HTML ID for the input element
   *
   * @default undefined
   */
  id?: string

  /**
   * HTML name for the input element
   *
   * @default undefined
   */
  name?: string

  /**
   * Indicates that the textfield is a multiline textarea
   *
   * @default false
   */
  multiline?: boolean

  /**
   * Enables resize when textfield is a multiline textarea
   *
   * @default false
   */
  resizable?: boolean

  /**
   * Indicates textarea height when line is a multiline textarea
   *
   * @default 6
   */
  height?: number

  /**
   * Default focus the text field
   *
   * @default false
   */
  autoFocus?: boolean

  /**
   * Indicates if input is readonly
   *
   * @default false
   */
  readOnly?: boolean

  /**
   * Displays the textfield in full width
   *
   * @default false
   */
  fullWidth?: boolean

  /**
   * Displays the textfield in medium size
   *
   * @default false
   */
  medium?: boolean

  /**
   * Adds padding to the left
   *
   * @default 0
   */
  leftPadding?: number

  /**
   * Sets the fontsize
   *
   * @default undefined
   */
  fontSize?: number

  /**
   * Add rounded corners to textfield
   *
   * @default undefined
   */
  rounded?: boolean

  /**
   * Thicker textfield
   *
   * @default undefined
   */
  thick?: boolean

  /**
   * Hide borders
   *
   * @default undefined
   */
  quiet?: boolean

  /**
   * Align center the text
   *
   * @default undefined
   */
  textCenter?: boolean

  /**
   * ref to the actual input element
   *
   * @default undefined
   */
  innerRef?: React.Ref<HTMLFormElement> | React.MutableRefObject<HTMLFormElement>
}

/* stylelint-disable */
const typeNumber = css`
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    appearance: none;
  }
`
/* stylelint-enable */

export const StyledTextField = (elementType: string) =>
  themed(styled<ITextFieldProps, any>(elementType)`
    outline: none;
    transition: 0.25s border;
    padding: 0.5rem 0.75rem;
    ${({ theme }) => css`
      border: solid 1px ${theme.grey400Color};
    `};
    ${({ thick }) =>
      thick &&
      css`
        padding: 0.875rem 1rem;
        border-radius: 0.25rem;
      `}
    box-sizing: border-box;
    background-color: #fff;
    font-size: 0.875rem;
    ${({ fontSize }) =>
      fontSize &&
      css`
        font-size: ${fontSize}rem;
      `};
    ${({ textCenter }) =>
      textCenter &&
      css`
        text-align: center;
      `};
    ${({ width, theme, color }) => css`
      &::input-placeholder {
        color: ${theme.grey800Color};
      }

      ::placeholder {
        color: ${theme.grey800Color};
      }

      &:disabled {
        background-color: ${theme.grey100Color};
        border-color: ${theme.grey100Color};
      }

      color: ${color ?? theme.grey900Color};
      ${width &&
      css`
        width: 100%;
        max-width: ${width}rem;
      `};
    `};
    ${({ fullWidth }) =>
      fullWidth &&
      css`
        width: 100%;
      `};
    ${({ medium }) =>
      medium &&
      css`
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        font-size: 1.375rem;
      `};
    ${({ leftPadding }) =>
      leftPadding &&
      css`
        padding-left: ${leftPadding}rem;
      `};
    ${({ theme, scheme }) => css`
      caret-color: ${getColor(theme, scheme)};

      &:focus {
        border: solid 1px ${getColor(theme, scheme)};
      }
    `};
    ${({ invalid, theme }) =>
      invalid &&
      css`
        border: solid 1px ${theme.errorColor};

        &:focus {
          border: solid 1px ${theme.errorColor};
        }

        &::input-placeholder {
          color: ${theme.errorColor};
        }

        ::placeholder {
          color: ${theme.errorColor};
        }
      `};
    ${({ multiline, resizable, height }) => css`
      ${multiline &&
      css`
        height: ${height}rem;
        ${!resizable &&
        css`
          resize: none;
        `};
      `};
    `};
    ${({ rounded }) =>
      rounded &&
      css`
        border-radius: 0.25rem;
      `};
    ${({ quiet }) =>
      quiet &&
      css`
        background: transparent;
        border: none;

        &:focus {
          border: none;
        }
      `};
    ${typeNumber};
  `)

export default class TextField extends React.PureComponent<ITextFieldProps> {
  static defaultProps = {
    height: 6,
    multiline: false,
    resizable: false,
    textCenter: false,
  }
  StyledTextFieldCmp = this.props.multiline ? StyledTextField('textarea') : StyledTextField('input')
  public render() {
    return <this.StyledTextFieldCmp {...this.props} />
  }
}
