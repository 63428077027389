import React from 'react'

import isNumber from 'lodash.isnumber'
import Currency from 'react-currency-formatter'
import { FormattedMessage } from 'react-intl'

import { IAmount } from '@store/postCampaignReport'
import Theme from '@theme'
import { Text } from '@tribegroup'
import { Entry, RowWrapper } from '../ListView.styled'

interface IAnalyticsPostCampaignReportListViewAmountProps {
  backgroundColor: string
  testId?: string
  pattern?: string
  value: IAmount
}

const AnalyticsPostCampaignReportListViewAmount: React.FC<IAnalyticsPostCampaignReportListViewAmountProps> = ({
  backgroundColor,
  testId,
  pattern,
  value,
}) => (
  <RowWrapper data-testid={testId} backgroundColor={backgroundColor}>
    <Entry>
      {isNumber(value.amount) ? (
        <Text color={Theme.grey900Color}>
          <Currency pattern={pattern} currency={value.currency} quantity={value.amount} />
        </Text>
      ) : (
        <Text color={Theme.grey800Color}>
          <FormattedMessage id="core.metric.empty" />
        </Text>
      )}
    </Entry>
  </RowWrapper>
)

export default AnalyticsPostCampaignReportListViewAmount
