import styled from 'styled-components'

import Theme from '@theme'
import { Container, Text } from '@tribegroup'

export const PreviewContainer = styled(Container)`
  box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 10%);
  border-radius: 0.625rem;
`

export const EmptyLine = styled.div`
  border-radius: 12px;
  margin-top: ${({ topOuterSpacing = 0.5 }) => topOuterSpacing}rem;
  width: ${({ width }) => width}rem;
  height: ${({ height = 0.625 }) => height}rem;
  background: ${Theme.grey200Color};
`

export const SponsoredText = styled(Text)`
  font-size: 0.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.7px;
`
