import Flex from 'styled-flex-component'

import BillingHistorySideBar from '@components/Billing/SideBar/SideBar'
import PurchaseList from '@components/Purchase/Purchase'
import PurchaseListTitleBar from '@components/Purchase/TitleBar/TitleBar'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import PaddedContainerStyled from '@components/UI/PaddedContainer/PaddedContainer.styled'
import { CampaignProvider } from '@context/Campaign'
import Theme from '@theme'

const CampaignPurchasesPage = () => (
  <MainLayout backgroundColor={Theme.whiteColor}>
    <HeadTag id="core.text.purchases.campaignBillingHistory" />
    <CampaignProvider>
      <PurchaseListTitleBar />
      <Flex>
        <BillingHistorySideBar />
        <Flex column full>
          <PaddedContainerStyled>
            <PurchaseList />
          </PaddedContainerStyled>
        </Flex>
      </Flex>
    </CampaignProvider>
  </MainLayout>
)

export default CampaignPurchasesPage
