import { Location } from 'history'
import { EventTypes } from 'redux-segment'

import {
  APP_LOCALE,
  BILLING,
  CONTENT_LIBRARY,
  CONTENT_LIBRARY_ITEM_VIEW,
  DEFAULT_TENANT,
  INBOX_FILTER,
} from '@constants'
import {
  BrandedContentAdStatusTypes,
  BuilderSteps,
  PCRChannels,
  SupportedSubmissionTypes,
} from '@enums'
import { ICampaign, ILicenseOption } from '@store/campaigns'
import { IDraft } from '@store/drafts'
import { IMe, selectMeInfo } from '@store/me'
import { LOCATION_CHANGE } from '@store/router/types'
import { ISubmission } from '@store/submissions'
import { isEmpty } from '@utils'
import { queryStringtoObject } from '.'

export const isAdmin = (meInfo?: IMe): boolean => {
  if (meInfo && meInfo.id && !meInfo.track_events) {
    return true
  }
  return false
}

export const toEventsTracking = (event: string, properties?: {}) => {
  return {
    eventType: EventTypes.track,
    eventPayload: {
      event,
      properties,
    },
  }
}

export const getSubmissionValue = (submission: ISubmission) => {
  const subTotal = submission.total_amount_before_tax_cents

  if (!subTotal) {
    return undefined
  }

  const amount = subTotal / 100
  const formatOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    useGrouping: false,
    minimumFractionDigits: 2,
  }
  return new Intl.NumberFormat(APP_LOCALE, formatOptions).format(amount)
}

export const getHasFrameNotes = (submission: ISubmission) =>
  submission.media_objects.some((mediaObject) => Boolean(mediaObject.creator_note))

export const getSubmissionStatus = (submissionStatus: string) =>
  submissionStatus && submissionStatus.toLowerCase().startsWith('declined')
    ? 'declined'
    : submissionStatus

export const getLicenseOptionValue = (licenseOption: ILicenseOption) => {
  const subTotal = licenseOption.total_amount_before_tax_cents

  if (!subTotal) {
    return undefined
  }

  const amount = subTotal / 100
  const formatOptions: Intl.NumberFormatOptions = {
    style: 'decimal',
    useGrouping: false,
    minimumFractionDigits: 2,
  }
  return new Intl.NumberFormat(APP_LOCALE, formatOptions).format(amount)
}

export const getCurrentView = ({ pathname, search = '' }: Location) => {
  const isInfluencerView = search.includes('viewType=influencer')
  if (pathname.match(/\/billing/)) {
    return BILLING
  }
  if (pathname.match(/\/campaigns\/[0-9]+\/reports/)) {
    if (pathname.includes('reports/audience')) {
      return 'audience'
    }
    return 'campaign_metrics'
  }
  if (pathname.match(/\/submissions\/[0-9]+\/detail\/(?:notes|feedback|rate)/)) {
    return 'submission_view'
  }
  if (pathname.match(/\/submissions\/[0-9]+\/(?:notes|feedback|rate)/)) {
    return isInfluencerView ? 'inbox_list_view' : 'inbox'
  }
  if (pathname.match(/\/submissions\/[0-9]+/)) {
    return 'submission_view'
  }
  if (pathname.match(/\/campaigns\/[0-9]+/)) {
    return isInfluencerView ? 'inbox_list_view' : 'inbox'
  }
  if (pathname.match(/\/content-library/)) {
    return CONTENT_LIBRARY
  }
  if (pathname.match(/\/media-objects\/[0-9]+/)) {
    return CONTENT_LIBRARY_ITEM_VIEW
  }
  return 'my_campaigns'
}

const getQueryFilter = (location: Location, filterKey: string) => {
  const parsedQueryString: any = queryStringtoObject(location.search)
  return parsedQueryString && parsedQueryString[filterKey]
}

export const getSubmissionFilter = (location: Location) => {
  const currentFilter = getQueryFilter(location, 'filter')

  return currentFilter || INBOX_FILTER
}

export const getSubmissionSortBy = (location: Location) => {
  const sortBy = getQueryFilter(location, 'sort_by')
  return sortBy
}

export const getCampaignFilter = (location: Location) => {
  const currentFilter = getQueryFilter(location, 'status')
  return currentFilter || 'all'
}

const getBuilderStep = (location: Location) => {
  const currentStep = location.pathname.split('/')[2] as BuilderSteps
  switch (currentStep) {
    case BuilderSteps.BRAND:
      return 1
    case BuilderSteps.CAMPAIGN:
      return 2
    case BuilderSteps.BRIEF:
      return 3
    case BuilderSteps.BUDGET:
      return 4
    case BuilderSteps.SUBMIT:
      return 5
    default:
      return 0
  }
}

export const getCampaignType = (campaign: ICampaign | IDraft) => {
  if (isEmpty(campaign) || !campaign.supported_submission_types) {
    return undefined
  }
  const supportedSubmissionTypes =
    campaign.supported_submission_types && campaign.supported_submission_types
  const supportedSubmissionType =
    supportedSubmissionTypes!.length > 0 && supportedSubmissionTypes![0]
  const campaignType =
    supportedSubmissionType === SupportedSubmissionTypes.ContentSubmission ? 'content' : 'social'
  return campaignType
}

export const parseCampaignType = (submissionType?: string) => {
  if (submissionType === SupportedSubmissionTypes.SocialSubmission) {
    return 'social'
  } else if (submissionType === SupportedSubmissionTypes.ContentSubmission) {
    return 'content'
  }
  return undefined
}

export const getSubmissionProperties = (submission?: ISubmission) => {
  if (submission && !isEmpty(submission)) {
    return {
      submission_id: submission.id,
      value: getSubmissionValue(submission),
      currency: submission.total_amount_before_tax_currency,
      submission_state: getSubmissionStatus(submission.status),
      shortlisted: submission.shortlisted,
      influencer_new: Boolean(submission.influencer_new),
      social_submission_type: submission.social_submission_type,
      has_frame_notes: getHasFrameNotes(submission),
      pre_approved: submission.preapproved,
    }
  }
  return undefined
}

export const getCampaignProperties = (campaign?: ICampaign) =>
  campaign && {
    brief_id: campaign.id,
    brief_type: getCampaignType(campaign),
    brief_state: campaign.status,
    role: campaign.role,
    sampling: campaign.sampling_enabled,
  }

export const getDraftProperties = (draft?: IDraft) =>
  draft && {
    brief_id: draft.id,
    brief_type: getCampaignType(draft),
  }

export const getSubmissionLocationProps = (location) => ({
  current_view: getCurrentView(location),
  current_filter: getSubmissionFilter(location),
  content_library: false,
})

export const getDraftLocationProps = (location) => ({
  step: getBuilderStep(location),
})

export const getCampaignMetricsTab = (channel: string) => {
  switch (channel) {
    case PCRChannels.IGVideos:
      return 'instagram_videos'
    case PCRChannels.IGStories:
      return 'instagram_stories'
    case PCRChannels.IGPosts:
      return 'instagram_posts'
    case PCRChannels.Facebook:
      return 'facebook'
    case PCRChannels.Twitter:
      return 'twitter'
    case PCRChannels.Reel:
      return 'instagram_reels'
    case PCRChannels.TikTok:
      return 'tiktok'
    default:
      return 'summary'
  }
}

export const getBCAStatus = (
  hasPromoteAsAdLink: boolean,
  bcaStatus?: BrandedContentAdStatusTypes,
) => {
  if (hasPromoteAsAdLink) {
    return 'not_requested'
  }
  switch (bcaStatus) {
    case BrandedContentAdStatusTypes.Requested: {
      return 'requested'
    }
    case BrandedContentAdStatusTypes.PendingCreatorApproval: {
      return 'pending_creator_approval'
    }
    case BrandedContentAdStatusTypes.Declined: {
      return 'declined'
    }
    case BrandedContentAdStatusTypes.Approved: {
      return 'granted'
    }
    case BrandedContentAdStatusTypes.Completed: {
      return 'completed'
    }
    case BrandedContentAdStatusTypes.Expired: {
      return 'expired'
    }

    default:
      return undefined
  }
}

const isAdminSignInPage = (action) => {
  const pathname = action.location && action.location.pathname
  return pathname ? pathname.startsWith('/autoSignin') : false
}

export const trackPageEvents = (getState, action) => {
  const meInfo = selectMeInfo(getState())
  const asAdmin = isAdmin(meInfo)
  const isAutoSignInPage = isAdminSignInPage(action)
  const shouldTrackPageEvent =
    !asAdmin && !isAutoSignInPage && process.env.APP_NAME === DEFAULT_TENANT
  const pageEvent = { eventType: EventTypes.page }
  return shouldTrackPageEvent ? pageEvent : undefined
}

export const getPageMapper = () => ({
  mapper: {
    [LOCATION_CHANGE]: trackPageEvents,
  },
})
