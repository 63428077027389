import { ImageWrapper } from './ConfettiImage.styled'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const image1x = require('@images/campaignbuilder/illustration-confetti.png')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const image2x = require('@images/campaignbuilder/illustration-confetti@2x.png')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const image3x = require('@images/campaignbuilder/illustration-confetti@3x.png')

const ConfettiImage = () => {
  return (
    <ImageWrapper>
      <img
        srcSet={`${image1x} 69w, ${image2x} 115w, ${image3x} 230w`}
        alt="illustration-confetti"
      />
    </ImageWrapper>
  )
}

export default ConfettiImage
