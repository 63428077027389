import styled from 'styled-components'

import Theme from '@theme'

export const TextThumbnailContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 100%;
`

export const TextContent = styled.span`
  height: 100%;
  overflow: auto;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${Theme.defaultColor};
  display: flex;
  align-items: center;
  white-space: pre-line;
`

export const TextContentWrapper = styled.span`
  margin: auto 0;
`
