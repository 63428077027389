import React from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import SubmissionChat from '@components/Submission/Chat'
import ActionIcon from '@components/UI/ActionIcon'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { useDrawer } from '@hooks/useDrawer'
import { ISubmission } from '@store/submissions'
import Theme from '@theme'

interface IProps {
  campaignId: number
  submission: ISubmission
}

interface IInternalProps extends IProps, InjectedIntlProps {}

const ActionBarIconChat: React.FC<IInternalProps> = ({ campaignId, submission, intl }) => {
  const { showDrawer } = useDrawer()

  const {
    id: submissionId,
    feedback_any: highlighted,
    feedback_unread: showNotification,
  } = submission

  const onClick = () => {
    showDrawer(<SubmissionChat submissionId={submissionId} campaignId={campaignId} />, {
      showHeaderDivider: true,
    })
  }

  return (
    <ConnectedTrackedRoute
      eventName={EventTrackingNames.ViewSubmissionFeedback}
      campaignId={campaignId}
      submissionId={submission.id}
    >
      <ActionIcon
        glyph="feedback"
        highlighted={highlighted}
        showNotification={showNotification}
        color={Theme.grey900Color}
        size={1.5}
        onClick={onClick}
        tooltip={intl.formatMessage({ id: 'submission.card.action.feedback' })}
        elementName="feedback"
      />
    </ConnectedTrackedRoute>
  )
}

export default compose<IInternalProps, IProps>(injectIntl)(ActionBarIconChat)
