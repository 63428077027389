import React from 'react'

import { SubmissionChannels } from '@enums'
import Theme from '@theme'
import { Container, Icon, Link, Text } from '@tribegroup'
import { IconWrapper } from './SocialProvider.styled'

interface IInfluencerIdentityRowDetailsSocialProviderProps {
  provider: SubmissionChannels
  username: string
  socialUrl: string
}

const InfluencerIdentityRowDetailsSocialProvider: React.FC<IInfluencerIdentityRowDetailsSocialProviderProps> = ({
  provider,
  username,
  socialUrl,
}) => {
  return (
    <React.Fragment>
      <Link href={socialUrl} target="_blank">
        <Container rightOuterSpacing={0.25} inline>
          <IconWrapper>
            <Icon size={0.75} glyph={`social-${provider}-colour`} />
          </IconWrapper>
        </Container>
        <Container rightOuterSpacing={0.5} inline>
          <Text small color={Theme.grey700Color}>
            {username}
          </Text>
        </Container>
      </Link>
    </React.Fragment>
  )
}

export {
  InfluencerIdentityRowDetailsSocialProvider,
  IInfluencerIdentityRowDetailsSocialProviderProps,
}
export default InfluencerIdentityRowDetailsSocialProvider
