import styled from 'styled-components'

/* stylelint-disable block-no-empty */
export const HoverIconOnHover = styled.div``

export const HoverIconOffHover = styled.div``
/* stylelint-enable block-no-empty */

export const HoverIconStyled = styled.div`
  &:not(:hover) {
    ${HoverIconOnHover} {
      display: none;
    }
    ${HoverIconOffHover} {
      display: initial;
    }
  }

  &:hover {
    ${HoverIconOnHover}: {
      display: initial;
    };
    ${HoverIconOffHover} {
      display: none;
    }
  }
`
