import React from 'react'

import UIFlagWatcherModal from '@components/UI/UIFlagWatcherModal'
import { UIFlag } from '@store/ui'
import CampaignModalPaidPartnershipAddHandleReminder from '../AddHandleReminder'
import CampaignModalPaidPartnershipTurnOffRequireApprovals from '../TurnOffRequireApprovals'

const CampaignModalPaidPartnershipWatcher: React.FC = () => (
  <React.Fragment>
    <UIFlagWatcherModal
      uiFlag={UIFlag.SHOW_TURNOFF_REQUIRE_APPROVALS_MODAL}
      modalComponent={CampaignModalPaidPartnershipTurnOffRequireApprovals}
    />
    <UIFlagWatcherModal
      uiFlag={UIFlag.SHOW_PAID_PARTNERSHIP_ADD_HANDLE_REMINDER}
      modalComponent={CampaignModalPaidPartnershipAddHandleReminder}
    />
  </React.Fragment>
)

export { CampaignModalPaidPartnershipWatcher }
export default CampaignModalPaidPartnershipWatcher
