import { IMeta } from '@store'
import { SubmissionsActionTypes } from '@store/submissions'
import { ISubmissionResults } from '../types'

export const fetchSubmissions = (campaignId: number, meta: IMeta, isFetchNext = false) => ({
  payload: { campaignId, isFetchNext },
  meta,
  type: SubmissionsActionTypes.FETCH_REQUEST,
})

export const fetchSubmissionsSuccess = (
  submissions: ISubmissionResults,
  campaignId: number,
  meta: object,
) => ({
  payload: { campaignId, ...submissions },
  meta,
  type: SubmissionsActionTypes.FETCH_SUCCESS,
})

export const fetchSubmissionsError = (error: any) => ({
  payload: error,
  type: SubmissionsActionTypes.FETCH_ERROR,
})
