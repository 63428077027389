import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Flex from 'styled-flex-component'

import Theme, { titleBarHeight } from '@theme'
import { Container } from '@tribegroup'

interface ISideBarStyled {
  open: boolean
}

export default styled<ISideBarStyled>('aside')`
  transform: translate(0, -100%);
  transition: transform 0.2s, border 0.2s 0.2s;
  flex-shrink: 0;
  position: fixed;
  left: 0;
  top: ${titleBarHeight}rem;
  background: ${Theme.whiteColor};
  height: calc(100vh - ${titleBarHeight}rem);
  box-sizing: border-box;
  z-index: 2;
  width: 100%;
  ${({ expanded }) => `border-right: solid 1px ${expanded ? Theme.grey400Color : 'transparent'}`};
  ${({ open }) =>
    open &&
    css`
      transform: none;
    `};
  ${breakpoint('phoneUpperBoundary')`
    width: 13.875rem;
    position: sticky;
    transform: none;
  `};

  .scrollable-pane {
    padding: 1.6875rem 0.5rem;
    overflow: auto;
    height: calc(100% - ${titleBarHeight}rem);
    box-sizing: border-box;
  }
`
export const SideBarFooterStyled = styled.footer`
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0.75rem;
  padding-top: 0;
  background: ${Theme.whiteColor};
`

export const ProfileContainer = styled(Flex)`
  border-radius: 0.5rem;
  padding: 0.25rem 0.625rem;

  &:hover {
    cursor: pointer;
    background-color: ${Theme.grey200Color};
  }
`

export const ProfileNameContainer = styled(Container)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 30rem;
  display: flex;
  align-items: center;
  ${breakpoint('phoneUpperBoundary')`
    max-width: 9.5rem;
  `};
`
