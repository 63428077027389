import { IHateoasLink } from '@store'
import { FetchBrandFanGroupsActionTypes, IBrandFanGroup } from '@store/brandFans'

export const fetchBrandFanGroups = () => ({
  type: FetchBrandFanGroupsActionTypes.FETCH_REQUEST,
})

export const fetchBrandFanGroupsSuccess = (
  brandFanGroups: ReadonlyArray<IBrandFanGroup>,
  metadata: object,
  links: ReadonlyArray<IHateoasLink>,
) => ({
  payload: { brandFanGroups, metadata, links },
  type: FetchBrandFanGroupsActionTypes.FETCH_SUCCESS,
})

export const fetchBrandFanGroupsError = (error: any) => ({
  payload: error,
  type: FetchBrandFanGroupsActionTypes.FETCH_ERROR,
})
