import { keyframes } from 'styled-components'

export const FadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

export const DelayedFadeout = keyframes`
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100%: {
    opacity: 0;
  }
`
