import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useSnackBar } from '@components/UI/Snackbar/context'
import { selectFeedbackErrors, selectIsAddingFeedback } from '@store/feedback'
import { IApplicationState } from '@store/index'
import { clearUIFlag, selectUIFlag, UIFlag } from '@store/ui'

const onUnload = (e: BeforeUnloadEvent) => {
  // This is ignored by modern browsers, but is required for legacy browsers
  e.returnValue = 'There is an ongoing upload for your file. Are you sure you want to leave?'
}

const SubmissionChatSendInProgress = () => {
  const dispatch = useDispatch()
  const { createSnackbar, removeSnackbar } = useSnackBar()
  const { isAdding, errors } = useSelector((state: IApplicationState) => {
    return {
      isAdding: selectIsAddingFeedback(state),
      errors: selectFeedbackErrors(state),
    }
  })
  const [isInProgress, setIsInProgress] = useState(false)

  useEffect(() => {
    if (!isAdding) {
      dispatch(clearUIFlag(UIFlag.CHAT_SENDING_INPROGRESS))
      return
    }

    setIsInProgress(true)
    window.addEventListener('beforeunload', onUnload)

    const snackBarId = createSnackbar('submission.chat.sending.inprogress', 'inProgress')

    return () => {
      removeSnackbar(snackBarId)
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [])

  useEffect(() => {
    if (!isInProgress || isAdding) {
      return
    }

    if (errors) {
      createSnackbar('submission.chat.sending.error', 'error')
    } else {
      createSnackbar('submission.chat.sending.success', 'success')
    }

    dispatch(clearUIFlag(UIFlag.CHAT_SENDING_INPROGRESS))
  }, [isInProgress, isAdding, errors])

  return null
}

const UIFlagWrapper = () => {
  const { show } = useSelector((state: IApplicationState) => ({
    show: selectUIFlag(state, UIFlag.CHAT_SENDING_INPROGRESS),
  }))

  if (!show) {
    return null
  }

  return <SubmissionChatSendInProgress />
}

export default UIFlagWrapper
