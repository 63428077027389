import {
  FetchMediaObjectActionTypes,
  FetchShareableMediaObjectActionTypes,
  IMediaObject,
  SyncMediaObjectsActionTypes,
} from '@store/mediaObjects'

export const fetchMediaObject = (mediaObjectId: number) => ({
  payload: mediaObjectId,
  type: FetchMediaObjectActionTypes.FETCH_REQUEST,
})

export const fetchMediaObjectSuccess = (mediaObject: IMediaObject) => ({
  payload: mediaObject,
  type: FetchMediaObjectActionTypes.FETCH_SUCCESS,
})

export const fetchMediaObjectError = (error: any) => ({
  payload: error,
  type: FetchMediaObjectActionTypes.FETCH_ERROR,
})

export const syncMediaObjectToSubmission = mediaObjectId => {
  return {
    payload: { mediaObjectId },
    type: SyncMediaObjectsActionTypes.SYNC_SUCCESS,
  }
}

export const fetchShareableMediaObject = (token: string, mediaObjectId: number) => ({
  payload: { token, mediaObjectId },
  type: FetchShareableMediaObjectActionTypes.FETCH_REQUEST,
})
