import { useContext } from 'react'

import { DrawerContext } from '@context/Drawer'
import { IDrawerContextProps } from '@context/Drawer/Provider'

const useDrawer = () => {
  const contextProps = useContext<IDrawerContextProps>(DrawerContext)
  return contextProps
}

export { useDrawer }
