import { createRoot } from 'react-dom/client'

import App from './App'
import registerServiceWorker from './registerServiceWorker'

import 'chart.js/auto'

import './index.scss'
import './reset.scss'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(<App />)

registerServiceWorker()
