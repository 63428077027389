import { Fragment } from 'react'

import { Route } from 'react-router-dom'

import BuilderLayoutUnsupportedScreen from '@components/Builder/Layout/UnsupportedScreen/UnsupportedScreen'
import { SupportedScreenWrapper } from '@components/UI/ScreenSize'
import { ModalProvider } from '@context/Modal'
import { BuilderSteps } from '@enums'
import { ToastContainer } from '@tribegroup'
import ChooseBrandProfile from './../ChooseBrandProfile'
import ChooseCampaignType from './../ChooseCampaignType'
import CreateBriefPage from './../CreateBrief'
import CreateCampaignPage from './../CreateCampaign'
import SetBudgetPage from './../SetBudget'
import SubmitCampaignPage from './../SubmitCampaign'

const BuilderPage = () => {
  return (
    <Fragment>
      <BuilderLayoutUnsupportedScreen />
      <ModalProvider>
        <SupportedScreenWrapper>
          <ToastContainer />
          <Route exact path="/builder" component={ChooseCampaignType} />
          <Route exact path="/builder/brands" component={ChooseBrandProfile} />
          <Route exact path="/builder/brands/:campaignId(\d+)" component={ChooseBrandProfile} />
          <Route exact path="/builder/brands/add" component={ChooseBrandProfile} />
          <Route exact path="/builder/brands/:campaignId(\d+)/add" component={ChooseBrandProfile} />
          <Route
            exact
            path="/builder/brands/:campaignId/edit/:brandId(\d+)"
            component={ChooseBrandProfile}
          />
          <Route exact path="/builder/brands/edit/:brandId(\d+)" component={ChooseBrandProfile} />
          <Route
            exact
            path={`/builder/${BuilderSteps.CAMPAIGN}/:campaignId?`}
            component={CreateCampaignPage}
          />
          <Route
            exact
            path={`/builder/${BuilderSteps.BRIEF}/:campaignId`}
            component={CreateBriefPage}
          />
          <Route
            exact
            path={`/builder/${BuilderSteps.BUDGET}/:campaignId`}
            component={SetBudgetPage}
          />
          <Route
            exact
            path={`/builder/${BuilderSteps.SUBMIT}/:campaignId`}
            component={SubmitCampaignPage}
          />
        </SupportedScreenWrapper>
      </ModalProvider>
    </Fragment>
  )
}

export default BuilderPage
