import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouterProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import ActionIcon from '@components/UI/ActionIcon'
import { IApplicationState } from '@store'
import { changePassword, selectIsUpdating, selectMeErrorMessages } from '@store/me'
import Theme from '@theme'
import {
  Form,
  FormGroup,
  FormGroupRow,
  FormInput,
  FormValidationMessage,
  H5,
  Icon,
  IWithFormValidationProps,
  Panel,
  Text,
  TextField,
  withFormValidation,
} from '@tribegroup'
import { IWithFormValidation } from '@tribegroup/FormValidation'
import { escapeRegExp } from '@utils'
import { ErrorMessage } from '../Account'
import { List, Submit } from '../Account.styled'

interface IInternalProps
  extends RouterProps,
    InjectedIntlProps,
    IWithFormValidation,
    IWithFormValidationProps {
  changePassword: typeof changePassword
  errorMessages?: any
  isUpdating?: boolean
}

interface IAccountPasswordFormState {
  current_password: string
  new_password: string
  confirm_password: string
}

export class AccountPasswordForm extends React.PureComponent<
  IInternalProps,
  IAccountPasswordFormState
> {
  state = {
    current_password: '',
    new_password: '',
    confirm_password: '',
  }

  onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    this.setState({
      ...this.state,
      [target.name]: target.value,
    })
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { current_password, new_password } = this.state
    this.props.changePassword(
      {
        current_password,
        new_password,
      },
      {
        history: this.props.history,
        redirect: '/account',
      },
    )
  }

  render() {
    const { validationResults = {}, intl, errorMessages, isUpdating } = this.props

    const customPasswordRule = {
      custom: [
        {
          message: intl.formatMessage({
            id: 'account.error.password.required',
          }),
          value: '^.{8,}$',
        },
      ],
    }

    const errorCode = errorMessages && errorMessages[0].error_code

    return (
      <Form
        name="change_password"
        method="POST"
        onSubmit={this.onSubmit}
        onBeforeSubmit={this.props.validate}
        setForm={this.props.setForm}
      >
        <Panel
          disabled={isUpdating}
          actionIcons={[
            <Link key="edit-account" to="/account" data-cy-element="close-password">
              <ActionIcon glyph="close-x" />
            </Link>,
            <Submit key="save" type="submit" data-cy-element="save-password">
              <Icon glyph="tick" size={1.25} />
            </Submit>,
          ]}
          title={
            <H5 color={Theme.grey900Color}>
              <FormattedMessage id="core.text.password" />
            </H5>
          }
        >
          <List>
            <FormGroupRow
              spaceBetween={1}
              inlineLabelWidth={12.5}
              wrap
              inlineLableVerticalSpacing={0.625}
              contentWidth={32}
              inlineLabel={
                <Text xsmall uppercase color={Theme.grey800Color}>
                  <FormattedMessage id="account.password.form.currentPassword.label" />
                </Text>
              }
            >
              <FormGroup>
                <FormInput validationRule={customPasswordRule}>
                  <TextField
                    autoFocus
                    type="password"
                    name="current_password"
                    value={this.state.current_password}
                    fullWidth
                    onChange={this.onChange}
                    invalid={
                      validationResults.current_password &&
                      validationResults.current_password.hasError
                    }
                    placeholder={intl.formatMessage({
                      id: 'account.password.form.currentPassword.placeholder',
                    })}
                  />
                </FormInput>
                <ErrorMessage results={validationResults} field="current_password" />
              </FormGroup>
            </FormGroupRow>
            <FormGroupRow
              spaceBetween={1}
              inlineLabelWidth={12.5}
              wrap
              inlineLableVerticalSpacing={0.625}
              contentWidth={32}
              inlineLabel={
                <Text xsmall uppercase color={Theme.grey800Color}>
                  <FormattedMessage id="account.password.form.newPassword.label" />
                </Text>
              }
            >
              <FormGroup>
                <FormInput validationRule={customPasswordRule}>
                  <TextField
                    type="password"
                    name="new_password"
                    value={this.state.new_password}
                    fullWidth
                    onChange={this.onChange}
                    invalid={
                      validationResults.new_password && validationResults.new_password.hasError
                    }
                    placeholder={intl.formatMessage({
                      id: 'account.password.form.newPassword.placeholder',
                    })}
                  />
                </FormInput>
                <ErrorMessage results={validationResults} field="new_password" />
              </FormGroup>
            </FormGroupRow>
            <FormGroupRow
              spaceBetween={1}
              inlineLabelWidth={12.5}
              wrap
              inlineLableVerticalSpacing={0.625}
              contentWidth={32}
              inlineLabel={
                <Text xsmall uppercase color={Theme.grey800Color}>
                  <FormattedMessage id="core.text.confirmPassword" />
                </Text>
              }
            >
              <FormGroup>
                <FormInput
                  validationRule={{
                    custom: [
                      ...customPasswordRule.custom,
                      {
                        message: intl.formatMessage({
                          id: 'account.error.password.mismatch',
                        }),
                        value: `^${escapeRegExp(this.state.new_password)}$`,
                      },
                    ],
                  }}
                >
                  <TextField
                    type="password"
                    name="confirm_password"
                    value={this.state.confirm_password}
                    fullWidth
                    onChange={this.onChange}
                    invalid={
                      validationResults.confirm_password &&
                      validationResults.confirm_password.hasError
                    }
                    placeholder={intl.formatMessage({
                      id: 'core.text.confirmPassword',
                    })}
                  />
                </FormInput>
                <ErrorMessage results={validationResults} field="confirm_password" />
              </FormGroup>
            </FormGroupRow>

            {errorCode && (
              <FormGroupRow spaceBetween={1}>
                <FormGroup>
                  <FormValidationMessage>
                    <Text small>
                      <FormattedMessage id={errorCode} />
                    </Text>
                  </FormValidationMessage>
                </FormGroup>
              </FormGroupRow>
            )}
          </List>
        </Panel>
      </Form>
    )
  }
}

const mapDispatchToProps = {
  changePassword,
}

const mapStateToProps = (state: IApplicationState) => ({
  errorMessages: selectMeErrorMessages(state),
  isUpdating: selectIsUpdating(state),
})

export default compose<IInternalProps, any>(
  injectIntl,
  withRouter,
  withFormValidation,
  connect(mapStateToProps, mapDispatchToProps),
)(AccountPasswordForm)
