import { SubmissionChannels, SupportedSubmissionTypes } from '@enums'
import { IChannel } from '@store/campaigns'
import Theme from '@theme'
import { Icon } from '@tribegroup'
import ChannelsWrapper from './Channels.styled'

interface IIconProps {
  supportedTypes: ReadonlyArray<string>
  channels: ReadonlyArray<IChannel>
}

export const SocialIcons = ({ channels }) => {
  const SOCIAL_CHANNELS: ReadonlyArray<SubmissionChannels> = [
    SubmissionChannels.Instagram,
    SubmissionChannels.TikTok,
    SubmissionChannels.Pinterest,
    SubmissionChannels.X,
    SubmissionChannels.YouTube,
    SubmissionChannels.Facebook,
    SubmissionChannels.Twitter,
  ]
  const channelNames = channels.map((ch) => ch.name)
  return channelNames.length > 0 ? (
    <ChannelsWrapper data-cy-element="social-icons" data-testid="social-icons">
      {SOCIAL_CHANNELS.map(
        (channel) =>
          channelNames.includes(channel) && (
            <Icon key={`social-${channel}`} glyph={`social-${channel}-colour`} />
          ),
      )}
    </ChannelsWrapper>
  ) : null
}

export const ContentIcon = () => (
  <ChannelsWrapper data-cy-element="content-icons" data-testid="content-icons">
    <Icon glyph="content-campaign" color={Theme.primaryColor} />
  </ChannelsWrapper>
)

const CampaignCardChannels = ({ supportedTypes, channels }: IIconProps) => {
  const isContentOnly = !supportedTypes.includes(SupportedSubmissionTypes.SocialSubmission)
  return isContentOnly ? <ContentIcon /> : <SocialIcons channels={channels} />
}

export default CampaignCardChannels
