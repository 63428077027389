import { useContext } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { compose } from 'recompose'

import { ChannelsContext } from '@components/Builder/Brief/Channels/Channels'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { ICampaignContextProps } from '@context/Campaign'
import { IDraftContextProps } from '@context/Draft'
import { SubmissionChannels } from '@enums'
import { withBuilderValidation, withCampaign } from '@hocs'
import { IApplicationState } from '@store'
import { selectIsDisabledAttribute } from '@store/drafts'
import { selectRootResource } from '@store/rootResource'
import Theme from '@theme'
import { Container, FormInput, H4, MultiSelectButtonGroup } from '@tribegroup'

type Props = IBuilderValidationContextProps &
  ICampaignContextProps &
  IDraftContextProps & {
    builderChannel: SubmissionChannels
    header?: React.ReactNode
  }

const BuilderBriefChannelsSubmissionTypes = ({
  builderChannel,
  header = (
    <H4 color={Theme.grey900Color}>
      <FormattedMessage id="builder.brief.socialMedia.submissionType.header" />
    </H4>
  ),
  clearFieldValidation,
  campaign,
}: Props) => {
  const { channelItems, setChannelItems } = useContext(ChannelsContext)

  const formKey = `${builderChannel}_requested_social_submission_types`

  const { isSelectionDisabled, root } = useSelector((state: IApplicationState) => {
    return {
      isSelectionDisabled: selectIsDisabledAttribute(state, 'requested_social_submission_types'),
      root: selectRootResource(state),
    }
  })

  const onSubmissionTypesChange = (selectedTypes: string[]) => {
    setChannelItems({
      ...channelItems,
      [builderChannel]: {
        ...channelItems[builderChannel],
        requested_social_submission_types: selectedTypes,
      },
    })

    clearFieldValidation(formKey)
  }

  const savedValues = campaign?.channels.find((channel) => channel.name === builderChannel)
    ?.requested_social_submission_types
  const selectedValues = channelItems[builderChannel].requested_social_submission_types
  const socialSubmissionTypes = root.channels[builderChannel].submission_types

  const disabledValues = savedValues && isSelectionDisabled ? savedValues : []

  return (
    <Container topOuterSpacing={2} bottomOuterSpacing={2}>
      {header}
      <FormInput
        validationRule={{
          required: disabledValues.length === 0,
        }}
      >
        <Container
          topOuterSpacing={1}
          data-testid={`${builderChannel}-builder-select-submission-type`}
        >
          <MultiSelectButtonGroup
            name={formKey}
            options={socialSubmissionTypes.map((ssType: string) => ({
              value: ssType,
              display: <FormattedMessage id={`core.text.plural.${ssType}`} />,
            }))}
            selectedValues={selectedValues}
            disabledValues={disabledValues}
            onSelectedValuesChange={onSubmissionTypesChange}
            activeBackgroundColor={Theme.primary200Color}
          />
        </Container>
      </FormInput>
    </Container>
  )
}

export default compose<Props, any>(
  withBuilderValidation,
  withCampaign,
)(BuilderBriefChannelsSubmissionTypes)
