import { useContext } from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { FormattedMessage } from 'react-intl'

import { SubmissionCardModalLicense } from '@components/Submission/Card/Modal/License.New/License'
import { PurchaseLicense } from '@components/Submission/Card/Modal/PurchaseLicense/PurchaseLicense'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { CampaignContext } from '@context/Campaign'
import { PopupMenuContext, PopupMenuItem } from '@context/PopupMenu'
import { EventTrackingNames, FeatureToggles } from '@enums'
import { useModal } from '@hooks/useModal'
import Theme from '@theme'
import { Text } from '@tribegroup'

interface IProps {
  submissionId: number
  frameNumber: number
}

export const ActionBarIconApproveContentPopupMenu = ({ submissionId, frameNumber }: IProps) => {
  const isNewLicenseTypesEnabled = useFlag(FeatureToggles.NEW_LICENSE_TYPES_ENABLED)

  const { showModal } = useModal()
  const { hidePopup } = useContext(PopupMenuContext)
  const {
    campaign: { id: campaignId },
  } = useContext(CampaignContext)

  const showPreapproveModal = () => {
    hidePopup()

    if (isNewLicenseTypesEnabled) {
      showModal(PurchaseLicense, {
        submissionId,
        frameNumber,
        approvalType: 'pre-approval',
      })
      return
    }

    showModal(SubmissionCardModalLicense, {
      submissionId,
      frameNumber,
      approvalType: 'pre-approval',
    })
  }

  const showFinalApproveModal = () => {
    hidePopup()

    if (isNewLicenseTypesEnabled) {
      showModal(PurchaseLicense, {
        submissionId,
        frameNumber,
        approvalType: 'final-approval',
      })
      return
    }

    showModal(SubmissionCardModalLicense, {
      submissionId,
      frameNumber,
      approvalType: 'final-approval',
    })
  }

  return (
    <>
      <PopupMenuItem onClick={showFinalApproveModal}>
        <Text xsmall color={Theme.grey900Color} uppercase>
          <FormattedMessage id="submission.card.action.menu.finalApproval" />
        </Text>
      </PopupMenuItem>
      <PopupMenuItem onClick={showPreapproveModal}>
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.ViewPreApproveModal}
          submissionId={submissionId}
          campaignId={campaignId!}
        >
          <Text xsmall color={Theme.grey900Color} uppercase>
            <FormattedMessage id="submission.card.action.menu.preApprove" />
          </Text>
        </ConnectedTrackedRoute>
      </PopupMenuItem>
    </>
  )
}

export default ActionBarIconApproveContentPopupMenu
