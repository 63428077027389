import React from 'react'

import Currency from 'react-currency-formatter'
import { FormattedMessage } from 'react-intl'

import AnalyticsPostCampaignReportMetricTooltip from '@components/Analytics/PostCampaignReport/Metric/Tooltip/Tooltip'
import Information from '@components/UI/Information/Information'
import Theme from '@theme'
import { Container, Header, Icon, Text } from '@tribegroup'
import { abbreviateNumber } from '@utils'
import { useMetricTooltipTracking } from './useMetricTooltipTracking'

export interface IProps {
  title: string
  value?: number | null
  currency?: string
  showPercentSign?: boolean
  abbreviateValue?: boolean
  locked?: boolean
  externalLink?: string
  productMetricValue?: string
  productMetricGroup?: string
  translationPrefix?: string
  showAsterisk?: boolean
}

export const getCurrencyPattern = (value) => (value % 1 === 0 ? '!###,### ' : undefined)

export const getPostCampaignReportPerformanceCount = (
  value?: number | null,
  currency?: string,
  showPercentSign?: boolean,
  abbreviateValue?: boolean,
  locked?: boolean,
) => {
  if (locked || value === undefined || value === null) {
    return <FormattedMessage id="core.metric.empty" />
  }

  if (currency) {
    return <Currency quantity={value} currency={currency} pattern={getCurrencyPattern(value)} />
  }

  const localeValue = value.toLocaleString(undefined, { maximumFractionDigits: 2 })

  if (showPercentSign) {
    return <FormattedMessage id="core.text.percent" values={{ value: localeValue }} />
  }

  return abbreviateValue ? abbreviateNumber(value) : localeValue
}

const DELAY_IN_SECONDS = 0.25

const AnalyticsPostCampaignReportMetric: React.FC<IProps> = ({
  title,
  value,
  currency,
  showPercentSign,
  abbreviateValue,
  locked,
  externalLink,
  productMetricValue,
  showAsterisk,
  productMetricGroup = 'reporting',
  translationPrefix = 'postCampaignReport.performance.item.label.text',
}) => {
  const { clearTrackingTimeOut, sendTrackEvent } = useMetricTooltipTracking({
    metricMessageId: title,
    delayInSeconds: DELAY_IN_SECONDS,
  })

  return (
    <Container topInnerSpacing={1.125}>
      <Container width="fit-content">
        <Information
          delay={DELAY_IN_SECONDS}
          width={18.125}
          target={
            <Container bottomOuterSpacing={0.5}>
              <Header inline small uppercase color={Theme.grey900Color}>
                <FormattedMessage id={`${translationPrefix}.${title}`} />
                {showAsterisk && <FormattedMessage id="core.text.symbol.attention" />}
              </Header>
              <Container inline leftInnerSpacing={0.5}>
                {locked && (
                  <Icon verticalAlign="bottom" glyph={'locked'} color={Theme.grey600Color} />
                )}
              </Container>
            </Container>
          }
          onEnterProp={sendTrackEvent}
          onLeaveProp={clearTrackingTimeOut}
        >
          <AnalyticsPostCampaignReportMetricTooltip
            title={title}
            translationPrefix={translationPrefix}
            locked={locked}
            currency={currency}
            externalLink={externalLink}
            showAsterisk={showAsterisk}
            productMetricGroup={productMetricGroup}
            productMetricValue={productMetricValue}
          />
        </Information>
      </Container>
      <Text
        customFontWeight={400}
        customFontSize={1.875}
        lineHeight={0.93}
        color={locked ? Theme.grey700Color : Theme.primaryColor}
      >
        {getPostCampaignReportPerformanceCount(
          value,
          currency,
          showPercentSign,
          abbreviateValue,
          locked,
        )}
      </Text>
    </Container>
  )
}

export { AnalyticsPostCampaignReportMetric }
export default AnalyticsPostCampaignReportMetric
