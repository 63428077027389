import { useEffect } from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import Confirmation from '@components/UI/Confirmation'
import { FooterButtonWrapper } from '@components/UI/Confirmation/Confirmation.styled'
import Loading from '@components/UI/Loading'
import { DUPLICATE_TIKTOK_ACCOUNT_ERROR } from '@constants'
import { ModalProvider } from '@context/Modal'
import hooks from '@hooks'
import { usePrevious } from '@hooks/usePrevious'
import { IResponseErrorMessage } from '@lib/error'
import {
  connectAdAccount,
  fetchIdentities,
  selectConnectIdentitiesLink,
  selectErrors,
  selectIsConnecting,
} from '@store/brandManagerIdentities'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { Button, Container, H2, Text, ToastContainer } from '@tribegroup'
import { isEmpty } from '@utils/isEmpty'

const ConnectedAccountsPopUpCallback = () => {
  const { connectIdentitiesLink, isConnecting, errors } = useSelector(
    (state: IApplicationState) => {
      return {
        connectIdentitiesLink: selectConnectIdentitiesLink(state),
        isConnecting: selectIsConnecting(state),
        errors: selectErrors(state),
      }
    },
  )
  const dispatch = useDispatch()
  const wasConnecting = usePrevious(isConnecting)
  const { auth_code } = hooks.useLocationSearch()

  useEffect(() => {
    dispatch(fetchIdentities())
  }, [])

  useEffect(() => {
    if (!isEmpty(connectIdentitiesLink)) {
      dispatch(connectAdAccount(auth_code))
    }
  }, [auth_code, connectIdentitiesLink])

  useEffect(() => {
    if (wasConnecting && !isConnecting && isEmpty(errors)) {
      close()
    }
  }, [wasConnecting, isConnecting])

  const errorCode = (errors?.messages?.[0] as IResponseErrorMessage)?.error_code

  return (
    <ModalProvider>
      <Container height="100vh" fullWidth>
        <ToastContainer />
        <Confirmation
          title={
            <H2 light color={Theme.grey900Color}>
              <FormattedMessage id="connectedAccounts.error.modal.header" />
            </H2>
          }
          content={
            <Text color={Theme.grey800Color} light>
              <FormattedHTMLMessage id="connectedAccounts.error.modal.body" />
            </Text>
          }
          footerButtons={
            <FooterButtonWrapper>
              <Button scheme="primary" onClick={close} small>
                <FormattedMessage id="core.text.okay" />
              </Button>
            </FooterButtonWrapper>
          }
          isOpen={errorCode === DUPLICATE_TIKTOK_ACCOUNT_ERROR}
          onCloseIconClick={close}
        />

        {isConnecting && (
          <Loading fullHeight>
            <FormattedMessage id="connectedAccounts.connectAccount.tiktok.finishingUp" />
          </Loading>
        )}
      </Container>
    </ModalProvider>
  )
}

export { ConnectedAccountsPopUpCallback }
