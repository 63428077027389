import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'

export const PricePanelStyled = styled.div`
  padding: 1.25rem 0;
  min-height: 3.75rem;
  box-sizing: border-box;
  ${(props) => props.clickable && 'cursor: pointer;'}
`

export const PricePanelWrapper = styled(Flex)`
  margin: 0 1.25rem;
`

export const PricePanelAmountWrapper = styled(Flex)`
  padding-right: 0.5rem;
  ${({ greyedOut }) =>
    greyedOut &&
    css`
      opacity: 0.4;
    `};
`

export const PricePanelAmountStyled = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.375rem;
`

export const PricePanelLabel = styled.div`
  border-radius: 1rem;
  background: ${Theme.secondary100Color};
  height: 1.25rem;
  padding: 0.0625rem 0.5rem;
  box-sizing: border-box;
`

export const PricePanelPopoverWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0.3rem;
  width: fill-available;
`
