import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

export const StyledHeader = styled.div`
  margin-top: 5rem;
  text-align: center;
`

export const FileDropWrapper = styled.div`
  margin-top: 6rem;
  ${({ size }) =>
    size &&
    css`
      width: ${size}rem;
      height: ${size}rem;
    `};
`

export const ActionsWrapper = styled(Flex)`
  margin-top: 2.75rem;
  margin-bottom: 6.375rem;
  width: 9rem;
  justify-content: space-evenly;
`
