import { IHateoasLink } from '@store'
import { FetchBrandManagerIdentities, IBrandManagerIdentity } from '@store/brandManagerIdentities'

export const fetchIdentities = (payload?: { campaignId: number }) => ({
  type: FetchBrandManagerIdentities.FETCH_REQUEST,
  payload,
})

export const fetchIdentitiesSuccess = (
  identities: ReadonlyArray<IBrandManagerIdentity>,
  links: ReadonlyArray<IHateoasLink>,
) => ({
  payload: { identities, links },
  type: FetchBrandManagerIdentities.FETCH_SUCCESS,
})

export const fetchIdentitiesError = (error: any) => ({
  payload: error,
  type: FetchBrandManagerIdentities.FETCH_ERROR,
})
