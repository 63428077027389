import { RootResourceActionTypes } from '@store/rootResource'

export const fetchRequest = () => ({
  type: RootResourceActionTypes.FETCH_REQUEST,
})

export const fetchSuccess = (data: any) => ({
  payload: data,
  type: RootResourceActionTypes.FETCH_SUCCESS,
})

export const fetchError = (error: any) => ({
  payload: error,
  type: RootResourceActionTypes.FETCH_ERROR,
})
