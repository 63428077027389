import { Fragment, useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { Hr } from '@components/UI/Hr/Hr.styled'
import Loading from '@components/UI/Loading'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { EventTrackingNames } from '@enums'
import { useDrawer } from '@hooks/useDrawer'
import { IApplicationState } from '@store'
import { selectCampaignTrackingProps, trackEvent } from '@store/analytics'
import {
  fetchAudienceReporting,
  selectAudienceReporting,
  selectIsErrorAudienceReporting,
  selectIsFetchingAudienceReporting,
} from '@store/audienceReporting'
import { selectIsCampaignFetched } from '@store/campaigns'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Container, H3, H4, Text } from '@tribegroup'
import { isEmpty } from '@utils'
import AudienceReportingDemographics from './Demographics'
import AudienceReportingDrawer from './Drawer'
import AudienceReportingLocation from './Location'
import AudienceReportingSummary from './Summary'

type IInternalProps = ConnectedProps<typeof connector>

const AnalyticsAudienceReporting: React.FC<IInternalProps> = ({
  isCampaignFetched,
  campaignId,
  audienceReporting,
  isFetching,
  isError,
  fetchAudienceReporting: fetchAudienceReportingAction,
}) => {
  useEffect(() => {
    if (isCampaignFetched) {
      fetchAudienceReportingAction(campaignId)
    }
  }, [campaignId, isCampaignFetched])

  const { showDrawer } = useDrawer()

  if (isFetching) {
    return <Loading />
  }

  if (isError || isEmpty(audienceReporting)) {
    return (
      <Text color={Theme.grey900Color}>
        <FormattedMessage id="analytics.audienceReporting.notAvailable" />
      </Text>
    )
  }

  const learnMoreOnClick = () => {
    showDrawer(
      <AudienceReportingDrawer campaignId={campaignId} />,
      { showHeaderDivider: true },
      <FormattedMessage id="core.text.audienceReporting" />,
    )
  }

  return (
    <Fragment>
      <H3 color={Theme.grey900Color}>
        <FormattedMessage id="core.text.audience" />
      </H3>
      <Container topOuterSpacing={0.5}>
        <Text
          color={Theme.grey700Color}
          customFontSize={0.8125}
          customletterSpacing="1.5px"
          lineHeight={1}
          uppercase
        >
          <FormattedMessage
            id="analytics.audienceReporting.header.subtitle"
            values={{ count: audienceReporting.summary.total_posts.toLocaleString() }}
          />
        </Text>
      </Container>
      <Container topOuterSpacing={1.25} maxWidth={38.1875}>
        <H4 color={Theme.grey900Color}>
          <FormattedMessage id="analytics.audienceReporting.header.description" />
        </H4>
      </Container>
      <Container inlineBlock topOuterSpacing={1.5} bottomOuterSpacing={4.25} pointer>
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.ViewAudienceReportingEducation}
          campaignId={campaignId}
          inline
        >
          <Text
            customFontSize={0.8125}
            lineHeight={1}
            color={Theme.grey900Color}
            uppercase
            customletterSpacing="1.5px"
            onClick={learnMoreOnClick}
            data-testid="learn-more-button"
          >
            <FormattedMessage id="core.text.learnMore.withArrow" />
          </Text>
        </ConnectedTrackedRoute>
      </Container>
      <Hr backgroundColor={Theme.grey400Color} />
      <Container topOuterSpacing={1.25}>
        <AudienceReportingSummary audienceReporting={audienceReporting} />
      </Container>
      <Container topOuterSpacing={4} data-testid="demographic-charts">
        <AudienceReportingDemographics
          audienceReporting={audienceReporting}
          campaignId={campaignId}
        />
      </Container>
      <Container topOuterSpacing={4} data-testid="location-charts">
        <AudienceReportingLocation audienceReporting={audienceReporting} campaignId={campaignId} />
      </Container>
    </Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match)!
  return {
    campaignId,
    campaignAnalyticsProperties: selectCampaignTrackingProps(state, campaignId),
    isCampaignFetched: selectIsCampaignFetched(state, campaignId),
    isFetching: selectIsFetchingAudienceReporting(state),
    isError: selectIsErrorAudienceReporting(state),
    audienceReporting: selectAudienceReporting(state, campaignId),
  }
}

const mapDispatchToProps = {
  fetchAudienceReporting,
  trackEvent,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<IInternalProps, {}>(withRouter, connector)(AnalyticsAudienceReporting)
