export interface IFlexboxGrid {
  flexboxgrid: object
}

export default {
  gridSize: 12,
  gutterWidth: 0,
  outerMargin: 0,
  container: {
    xs: 0,
    sm: 35,
    md: 55,
    lg: 64,
  },
  breakpoints: {
    xs: 0,
    sm: 37.5,
    md: 55,
    lg: 64,
  },
}
