import { PropsWithChildren, useEffect } from 'react'

import { useStripe } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { setStripeToStore } from '@store/payments'
import Payment from './Payment'

interface IInternalProps {
  stripeApiKey: string
  setStripeToStore: typeof setStripeToStore
  stripeInStore?: Stripe
}

export const ConnectedPaymentInner: React.FC<PropsWithChildren<IInternalProps>> = (props) => {
  const stripe = useStripe()

  useEffect(() => {
    if (stripe) {
      props.setStripeToStore(stripe)
    }
  }, [stripe])

  if (!stripe) {
    return null
  }

  return <Payment>{props.children}</Payment>
}

const mapDispatchToProps = {
  setStripeToStore,
}

export const ConnectedPaymentComponent = compose<any, any>(connect(undefined, mapDispatchToProps))(
  ConnectedPaymentInner,
)

const ConnectedPayment = ({ children }) => (
  <Payment>
    <ConnectedPaymentComponent>{children}</ConnectedPaymentComponent>
  </Payment>
)

export default ConnectedPayment
