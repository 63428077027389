import React, { FC } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { DownloadButton, LicensingAgreementsLink } from '@components/Licensing'
import LicensingPreAuthMessage from '@components/Licensing/PreAuthMessage'
import MediaObjectLicensingLicensedUploadToFacebookAdsManager from '@components/MediaObject/Licensing/Licensed/UploadToFacebookAdsManager'
import StripeError from '@components/UI/Error/StripeError'
import { IApplicationState } from '@store/index'
import {
  IMediaObject,
  selectFirstErrorCode,
  selectHasInsufficientFundError,
  selectMediaObjectErrors,
  selectMediaObjectIsRequestingLicense,
  selectUploadToFacebookLink,
} from '@store/mediaObjects'
import Theme from '@theme'
import { Button, Container, Header, Text } from '@tribegroup'

interface IProps {
  onClick: VoidFunction
  mediaObject: IMediaObject
  showCreditCardPreAuth?: boolean
}

export const RequestLicenseFooter: FC<IProps> = ({
  onClick,
  mediaObject,
  showCreditCardPreAuth,
}) => {
  const {
    hasInsufficientFundError,
    errorCode,
    isRequesting,
    hasFacebookAdUploadLink,
    hasError,
  } = useSelector((state: IApplicationState) => {
    return {
      hasInsufficientFundError: selectHasInsufficientFundError(state),
      errorCode: selectFirstErrorCode(state),
      isRequesting: selectMediaObjectIsRequestingLicense(state),
      hasFacebookAdUploadLink: Boolean(selectUploadToFacebookLink(state, mediaObject.id)),
      hasError: Boolean(selectMediaObjectErrors(state)),
    }
  })

  const showGenericError = hasError && !hasInsufficientFundError && !errorCode

  return (
    <Container topOuterSpacing={2.5}>
      {showCreditCardPreAuth && (
        <Container bottomOuterSpacing={1}>
          <LicensingPreAuthMessage />
        </Container>
      )}
      <Container bottomOuterSpacing={1.5}>
        {!hasInsufficientFundError && errorCode && <StripeError errorCode={errorCode} />}
        {showGenericError && (
          <Text color={Theme.errorColor} small>
            <FormattedMessage id="core.text.shortGenericError" />
          </Text>
        )}
      </Container>
      {mediaObject?.transacted_license?.status === 'licensed' ? (
        <DownloadButton mediaObjectId={mediaObject.id}>
          <FormattedMessage id="licensing.licensed.cta" />
        </DownloadButton>
      ) : (
        <Button
          fullWidth
          scheme="primary"
          disabled={isRequesting}
          loading={isRequesting}
          onClick={onClick}
          bottomOuterSpacing={1.25}
          height={3}
        >
          {mediaObject.transacted_license?.status === 'requested' ? (
            <Header cta>
              <FormattedMessage id="core.text.okay" />
            </Header>
          ) : (
            <Header cta>
              <FormattedMessage id="licensing.licensingOptions.cta" />
            </Header>
          )}
        </Button>
      )}

      <LicensingAgreementsLink color={Theme.primaryColor} />

      {hasFacebookAdUploadLink && (
        <Container topOuterSpacing={1.25} flexCenter>
          <MediaObjectLicensingLicensedUploadToFacebookAdsManager mediaObjectId={mediaObject.id} />
        </Container>
      )}
    </Container>
  )
}
