import { FormattedHTMLMessage } from 'react-intl'

import { MediaTypes } from '@enums'
import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import {
  Caption,
  CloseIconWrapper,
  DroppedFileCover,
  DroppedFileWrapper,
  ImageLoadingCover,
} from '@tribegroup/FileDrop/FileDrop.styled'

interface IProps {
  mediaType: MediaTypes
  onCloseClick: VoidFunction
  mediaUrl?: string | null
  isLoading?: boolean
  isDeleting?: boolean
  disableDelete?: boolean
}

const MoodboardItemFilled: React.FC<IProps> = ({
  mediaUrl,
  mediaType,
  isLoading,
  isDeleting,
  disableDelete,
  onCloseClick,
}) => {
  return (
    <DroppedFileWrapper
      objectFit="cover"
      data-cy-element="dropped-file-wrapper"
      data-testid="dropped-file-wrapper"
    >
      {mediaType === MediaTypes.VIDEO && mediaUrl && (
        <>
          <video width="320" height="240" src={mediaUrl} preload="auto"></video>
          <Container absolute bottomPosition={1} leftPosition={1}>
            <Icon color={Theme.whiteColor} glyph="video" size={1.75} />
          </Container>
        </>
      )}

      {mediaType === MediaTypes.IMAGE && mediaUrl && (
        <img data-testid="moodboard-media-image" src={mediaUrl} />
      )}

      <DroppedFileCover />
      {isLoading && (
        <>
          <ImageLoadingCover>
            <Icon glyph="spinner" className="spinner" data-testid="moodboard-spinner" />
          </ImageLoadingCover>

          <Caption>
            <Text block small color={Theme.whiteColor}>
              <FormattedHTMLMessage id="core.text.processingFile" />
            </Text>
          </Caption>
        </>
      )}
      {isDeleting && (
        <>
          <ImageLoadingCover>
            <Icon glyph="spinner" className="spinner" data-testid="moodboard-spinner" />
          </ImageLoadingCover>

          <Caption>
            <Text block small color={Theme.whiteColor}>
              <FormattedHTMLMessage id="core.text.removingFile" />
            </Text>
          </Caption>
        </>
      )}
      {!disableDelete && (
        <CloseIconWrapper>
          <Icon
            glyph="cross-heavy"
            size={1.5}
            onClick={onCloseClick}
            data-cy-element="remove-moodboard-image"
            data-testid="remove-moodboard-image"
          />
        </CloseIconWrapper>
      )}
    </DroppedFileWrapper>
  )
}

export default MoodboardItemFilled
