import equal from 'fast-deep-equal'
import { Reducer } from 'redux'

import { ContentLibraryFilterTypes } from '@enums'
import { AuthActionTypes } from '@store/auth'
import { FetchInfluencerIdentitySubmissionsActionTypes } from '@store/brandFans'
import { MediaObjectLicenseOptionsActionTypes } from '@store/licenses'
import {
  FetchMediaObjectActionTypes,
  FetchMediaObjectsActionTypes,
  FetchShareableContentLibraryActionTypes,
  FetchShareableMediaObjectActionTypes,
  IMediaObjectsState,
  MediaObjectsActionTypes,
  PurchaseLicenseActionTypes,
  RequestLicenseActionTypes,
  SyncMediaObjectsActionTypes,
} from '@store/mediaObjects'
import {
  ApproveActionTypes,
  InfluencerSubmissionsActionTypes,
  ISubmission,
  SubmissionActionTypes,
  SubmissionsActionTypes,
} from '@store/submissions'
import { removeFromSet, returnSecondIfDeepEqual } from '@utils'
import { appendIdsToFilter, indexById } from '@utils/groupBy'

const initialState: IMediaObjectsState = {
  isFetching: false,
  isRequestingLicense: false,
  mediaObjectsById: {},
  mediaObjectIdsByFilter: {},
  mediaObjectIdsBySubmissionId: {},
  linksByFilter: {},
  stats: {},
  shareableCLToken: undefined,
  errors: undefined,
}

const getUpdatedMediaObjectsFromSubmission = (
  state: IMediaObjectsState,
  submissions: ReadonlyArray<ISubmission>,
) => {
  const mediaObjectsFromSubmissions = submissions.reduce(
    (accum, curr) => [
      ...accum,
      ...curr.media_objects.filter(
        (mediaObject) => !equal(mediaObject, state.mediaObjectsById[mediaObject.id]),
      ),
    ],
    [],
  )

  const mediaObjectIdsBySubmission = submissions.map(({ id, media_objects }) => ({
    id,
    mediaObjectIds: media_objects.map((mediaObject) => mediaObject.id),
  }))

  const mediaObjectIdsBySubmissionId = indexById(
    mediaObjectIdsBySubmission,
    undefined,
    'id',
    'mediaObjectIds',
  )

  return {
    mediaObjectIdsBySubmissionId: returnSecondIfDeepEqual(
      mediaObjectIdsBySubmissionId,
      state.mediaObjectIdsBySubmissionId,
    ),
    mediaObjectsById: returnSecondIfDeepEqual(
      indexById(mediaObjectsFromSubmissions, state.mediaObjectsById),
      state.mediaObjectsById,
    ),
  }
}

const reducer: Reducer<IMediaObjectsState> = (state = initialState, action) => {
  switch (action.type) {
    case FetchShareableMediaObjectActionTypes.FETCH_REQUEST:
    case FetchShareableContentLibraryActionTypes.FETCH_REQUEST:
    case FetchMediaObjectsActionTypes.FETCH_REQUEST:
    case FetchMediaObjectActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
        errors: undefined,
      }
    }

    case PurchaseLicenseActionTypes.PURCHASE_LICENSE:
    case RequestLicenseActionTypes.REQUEST_LICENSE: {
      return {
        ...state,
        isRequestingLicense: true,
        errors: undefined,
      }
    }

    case MediaObjectsActionTypes.CLEAR_ERRORS:
    case MediaObjectLicenseOptionsActionTypes.FETCH_OPTIONS: {
      return {
        ...state,
        errors: undefined,
      }
    }

    case FetchMediaObjectsActionTypes.FETCH_SUCCESS: {
      const { items, metadata, links, filter, shareableCLToken } = action.payload
      return {
        ...state,
        isFetching: false,
        mediaObjectsById: returnSecondIfDeepEqual(
          indexById(items, state.mediaObjectsById),
          state.mediaObjectsById,
        ),
        stats: metadata.stats,
        shareableCLToken,
        mediaObjectIdsByFilter: returnSecondIfDeepEqual(
          appendIdsToFilter(items, filter, state.mediaObjectIdsByFilter),
          state.mediaObjectIdsByFilter,
        ),
        linksByFilter: returnSecondIfDeepEqual(
          {
            ...state.linksByFilter,
            [filter]: links,
          },
          state.linksByFilter,
        ),
      }
    }

    case SyncMediaObjectsActionTypes.SYNC_SUCCESS:
    case PurchaseLicenseActionTypes.PURCHASE_LICENSE_SUCCESS:
    case RequestLicenseActionTypes.REQUEST_LICENSE_SUCCESS: {
      const { mediaObjectId } = action.payload

      const discoverIds = state.mediaObjectIdsByFilter[ContentLibraryFilterTypes.DISCOVER]
      const updatedDiscoverIds = removeFromSet(discoverIds || [], mediaObjectId)

      return {
        ...state,
        isRequestingLicense: false,
        errors: undefined,
        mediaObjectIdsByFilter: {
          ...state.mediaObjectIdsByFilter,
          [ContentLibraryFilterTypes.DISCOVER]: updatedDiscoverIds,
        },
      }
    }

    case FetchMediaObjectActionTypes.FETCH_SUCCESS: {
      const mediaObject = action.payload
      return {
        ...state,
        isRequestingLicense: false,
        isFetching: false,
        mediaObjectsById: returnSecondIfDeepEqual(
          {
            ...state.mediaObjectsById,
            [mediaObject.id]: mediaObject,
          },
          state.mediaObjectsById,
        ),
      }
    }

    case PurchaseLicenseActionTypes.PURCHASE_LICENSE_ERROR:
    case RequestLicenseActionTypes.REQUEST_LICENSE_ERROR:
    case FetchMediaObjectsActionTypes.FETCH_ERROR:
    case FetchMediaObjectActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        isRequestingLicense: false,
        errors: action.payload,
      }
    }

    case FetchInfluencerIdentitySubmissionsActionTypes.FETCH_SUCCESS: {
      const { submissions = [] } = action.payload
      const updatedMediaObjectsBySubmissions = getUpdatedMediaObjectsFromSubmission(
        state,
        submissions,
      )

      return {
        ...state,
        ...updatedMediaObjectsBySubmissions,
      }
    }

    case SubmissionsActionTypes.FETCH_SUCCESS: {
      const { items: submissions = [] }: { items: ReadonlyArray<ISubmission> } = action.payload
      const updatedMediaObjectsBySubmissions = getUpdatedMediaObjectsFromSubmission(
        state,
        submissions,
      )

      return {
        ...state,
        ...updatedMediaObjectsBySubmissions,
      }
    }

    case InfluencerSubmissionsActionTypes.FETCH_SUCCESS: {
      const {
        influencerSubmissions = [],
      }: { influencerSubmissions: ReadonlyArray<ISubmission> } = action.payload
      const updatedMediaObjectsBySubmissions = getUpdatedMediaObjectsFromSubmission(
        state,
        influencerSubmissions,
      )

      return {
        ...state,
        ...updatedMediaObjectsBySubmissions,
      }
    }

    case SubmissionActionTypes.FETCH_SUCCESS: {
      const submission: ISubmission = action.payload
      const mediaObjectIdsInSubmission = submission.media_objects.map(
        (mediaObject) => mediaObject.id,
      )
      return {
        ...state,
        mediaObjectIdsBySubmissionId: returnSecondIfDeepEqual(
          {
            ...state.mediaObjectIdsBySubmissionId,
            [submission.id]: mediaObjectIdsInSubmission,
          },
          state.mediaObjectIdsBySubmissionId,
        ),
        mediaObjectsById: returnSecondIfDeepEqual(
          indexById(submission.media_objects, state.mediaObjectsById),
          state.mediaObjectsById,
        ),
      }
    }

    case ApproveActionTypes.APPROVE_SUCCESS: {
      const submission: ISubmission = action.payload.submission
      const mediaObjectIdsInSubmission = submission.media_objects.map(
        (mediaObject) => mediaObject.id,
      )
      return {
        ...state,
        mediaObjectIdsBySubmissionId: returnSecondIfDeepEqual(
          {
            ...state.mediaObjectIdsBySubmissionId,
            [submission.id]: mediaObjectIdsInSubmission,
          },
          state.mediaObjectIdsBySubmissionId,
        ),
        mediaObjectsById: returnSecondIfDeepEqual(
          indexById(submission.media_objects, state.mediaObjectsById),
          state.mediaObjectsById,
        ),
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as mediaObjectsReducer }
