import { Link } from 'react-router-dom'

import { Submit } from '@components/Account/Account.styled'
import ActionIcon from '@components/UI/ActionIcon'
import { Icon } from '@tribegroup'

export const FormButton = ({ disabled }) => (
  <Submit type="submit" disabled={disabled} data-cy-element="save-payment-details">
    <Icon disabled={disabled} glyph="tick" size={1.25} />
  </Submit>
)

export const CancelButton = ({ disabled }) => (
  <Link to="/account" data-cy-element="close-payment-details">
    <ActionIcon disabled={disabled} glyph="close-x" />
  </Link>
)

export default FormButton
