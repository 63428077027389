import { FormattedMessage } from 'react-intl'

import Image from '@components/UI/Image'
import { AspectRatios } from '@enums'
import Theme from '@theme'
import { Text } from '@tribegroup'
import { EmptyImage16x9 } from '../Preview.styled'
import CampaignPreviewHeroImageStyled, { BrandLogoWrapper, EmptyLogo } from './HeroImage.styled'

export interface ICampaignPreviewHeroImage {
  logo: string
  heroImage?: string
}

const CampaignPreviewHeroImage = ({ logo, heroImage }: ICampaignPreviewHeroImage) => (
  <CampaignPreviewHeroImageStyled>
    {heroImage ? (
      <Image src={heroImage} aspectRatio={AspectRatios.SIXTEEN_BY_NINE} />
    ) : (
      <EmptyImage16x9 justifyCenter alignCenter>
        <Text color={Theme.grey700Color}>
          <FormattedMessage id="campaign.preview.empty.coverimage" />
        </Text>
      </EmptyImage16x9>
    )}
    <BrandLogoWrapper>
      {logo ? (
        <Image
          contain
          width={28}
          height={28}
          src={logo}
          loadingColor={Theme.whiteColor}
          backgroundColor={Theme.whiteColor}
          aspectRatio={AspectRatios.ONE_BY_ONE}
        />
      ) : (
        <EmptyLogo />
      )}
    </BrandLogoWrapper>
  </CampaignPreviewHeroImageStyled>
)

export default CampaignPreviewHeroImage
