import React from 'react'

import Flex from 'styled-flex-component'

import Billing from '@components/Billing'
import BillingHeaderTag from '@components/Billing/HeaderTag/HeaderTag'
import BillingHistorySideBar from '@components/Billing/SideBar/SideBar'
import BillingTitleBar from '@components/Billing/TitleBar/TitleBar'
import MainLayout from '@components/UI/MainLayout'
import PaddedContainerStyled from '@components/UI/PaddedContainer/PaddedContainer.styled'
import Theme from '@theme'

const CampaignBillingHistory: React.FC = () => (
  <MainLayout backgroundColor={Theme.whiteColor}>
    <BillingHeaderTag />
    <BillingTitleBar />
    <Flex>
      <BillingHistorySideBar />
      <Flex column full>
        <PaddedContainerStyled>
          <Billing />
        </PaddedContainerStyled>
      </Flex>
    </Flex>
  </MainLayout>
)

export default CampaignBillingHistory
