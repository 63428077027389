import { ActionBarRight, ActionBarWrapper } from '@components/UI/Card/Card.styled'
import ActionBarLicensing from './Licensing'

interface IMediaObjectActionBarProps {
  mediaObjectId: number
}

const MediaObjectActionBar = ({ mediaObjectId }: IMediaObjectActionBarProps) => {
  return (
    <ActionBarWrapper justifyEnd>
      <ActionBarRight>
        <ActionBarLicensing mediaObjectId={mediaObjectId} />
      </ActionBarRight>
    </ActionBarWrapper>
  )
}

export default MediaObjectActionBar
