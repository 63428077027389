import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { IApplicationState } from '@store'
import { IPCRChannel, selectPostCampaignReportChannelList } from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import PostCampaignReportChannel from '../Channel'

export interface IInternalProps {
  channels: ReadonlyArray<IPCRChannel>
}

const PostCampaignReportChannelList = ({ channels }: IInternalProps) => {
  return (
    <Flex wrap="wrap">
      {channels.map(({ channel, active, count }) => {
        return (
          <PostCampaignReportChannel
            key={`pcr-channel-${channel}`}
            channel={channel}
            active={active}
            count={count}
          />
        )
      })}
    </Flex>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match)!
  return {
    channels: selectPostCampaignReportChannelList(state, campaignId),
  }
}

export { PostCampaignReportChannelList }

export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps),
)(PostCampaignReportChannelList)
