import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import StripeError from '@components/UI/Error/StripeError'
import { ICampaignContextProps } from '@context/Campaign'
import { IModalComponentProps } from '@context/Modal'
import { LicenseStatuses, MediaTypes } from '@enums'
import { withCampaign } from '@hocs'
import { IApplicationState } from '@store'
import { ILicenseOption } from '@store/campaigns'
import {
  fetchMediaObjectLicenseOptions,
  ILicense,
  selectHasInsufficientFundsForLicense,
  selectMediaObjectLicenseOptions,
} from '@store/licenses'
import {
  ITransactedLicense,
  selectFirstErrorCode,
  selectLicenseOptionsLink,
  selectMediaObjectIsRequestingLicense,
  selectMediaType,
  selectTransactedLicense,
} from '@store/mediaObjects'
import { selectActiveSubmissionMediaObject } from '@store/submissions'
import { CloseIcon, Container, Modal, ModalTitle } from '@tribegroup'
import { isEmpty } from '@utils'
import { isSocialCampaign } from '@utils/campaign'
import SubmissionCardModalLicenseActions from './Actions/Actions'
import SubmissionCardModalLicenseDetails from './Details/Details'
import SubmissionCardModalLicenseSelected from './Selected/Selected'
import SubmissionCardModalLicenseOptions from './Selection/Selection'

export interface ISubmissionCardLicenseProps {
  submissionId: number
  frameNumber?: number
}

interface ISubmissionCardModalLicenseProps
  extends IModalComponentProps,
    ISubmissionCardLicenseProps {}

interface IInternalProps
  extends ISubmissionCardModalLicenseProps,
    ICampaignContextProps,
    InjectedIntlProps {
  mediaObjectId: number
  hasLicenseOptionsLink: boolean
  isRequesting: boolean
  hasInsufficientFunds: boolean
  showCreditCardPreAuth: boolean
  licenseOptions: ReadonlyArray<ILicenseOption>
  fetchMediaObjectLicenseOptions: typeof fetchMediaObjectLicenseOptions
  mediaType: MediaTypes
  errorCode?: string
  transactedLicenseFromMediaObject: ITransactedLicense
}

export class SubmissionCardModalLicense extends React.Component<IInternalProps> {
  state = {
    selectedLicenseIndex: 0,
  }

  componentDidMount() {
    if (this.props.hasLicenseOptionsLink) {
      this.props.fetchMediaObjectLicenseOptions(this.props.mediaObjectId)
    }
  }

  componentDidUpdate(prevProps: IInternalProps) {
    const { hasInsufficientFunds, isRequesting, errorCode } = this.props
    const hasErrors = Boolean(errorCode)

    if (prevProps.isRequesting && !isRequesting && !hasErrors) {
      this.onCancel()
    }

    if (hasInsufficientFunds && prevProps.isRequesting && !isRequesting) {
      this.onCancel()
    }
  }

  onLicenseChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    this.setState({
      selectedLicenseIndex: parseInt(target.getAttribute('data-license-index')!, 10),
    })
  }

  onCancel = () => {
    this.props.onRequestClose()
  }

  render() {
    const {
      licenseOptions,
      campaign,
      errorCode,
      frameNumber,
      submissionId,
      mediaObjectId,
      isOpen = false,
      hasInsufficientFunds,
      transactedLicenseFromMediaObject: transactedLicense,
    } = this.props

    const isSocial = isSocialCampaign(campaign)

    const licenseStatus = transactedLicense?.status
    const isRequestExpired = licenseStatus === LicenseStatuses.RequestExpired
    const showLicenseOptions = isEmpty(transactedLicense) || isRequestExpired

    const showCreditCardPreAuth = Boolean(
      isSocial &&
        campaign.payment_method &&
        !campaign.payment_method.includes('invoice') &&
        showLicenseOptions,
    )

    const showModal = showLicenseOptions ? !isEmpty(licenseOptions) : !isEmpty(transactedLicense)

    if (!showModal) {
      return null
    }

    const licenseIndex = this.state.selectedLicenseIndex
    const modalTitle =
      isEmpty(transactedLicense) || isRequestExpired
        ? 'socialSubmission.card.modal.license.header.licenseOptions'
        : `socialSubmission.card.modal.license.header.${transactedLicense!.status}`
    const selectedLicense = showLicenseOptions ? licenseOptions[licenseIndex] : transactedLicense

    return (
      <Modal reactModalProps={{ isOpen, onRequestClose: this.onCancel }} width={32.5} complex>
        <ModalTitle center>
          <FormattedMessage id={modalTitle} />
        </ModalTitle>
        <CloseIcon onClick={this.onCancel} />

        {showLicenseOptions ? (
          <SubmissionCardModalLicenseOptions
            licenses={licenseOptions}
            onLicenseChange={this.onLicenseChange}
          />
        ) : (
          <SubmissionCardModalLicenseSelected
            label={selectedLicense.label}
            description={selectedLicense.description}
          />
        )}

        {selectedLicense && (
          <SubmissionCardModalLicenseDetails
            showPreview
            license={selectedLicense as ILicense}
            showCreditCardPreAuth={showCreditCardPreAuth}
          />
        )}
        {!hasInsufficientFunds && errorCode && (
          <Container
            topOuterSpacing={1}
            bottomOuterSpacing={1}
            rightOuterSpacing={3}
            leftOuterSpacing={3}
          >
            <StripeError errorCode={errorCode} />
          </Container>
        )}
        <SubmissionCardModalLicenseActions
          frameNumber={frameNumber}
          mediaObjectId={mediaObjectId}
          submissionId={submissionId}
          licenseIndex={licenseIndex}
        />
      </Modal>
    )
  }
}

const mapStateToProps = (
  state: IApplicationState,
  { submissionId, campaign, frameNumber }: IInternalProps,
) => {
  const isSocial = isSocialCampaign(campaign)

  const { id: activeMediaObjectId } = selectActiveSubmissionMediaObject(
    state,
    submissionId,
    frameNumber,
  )

  const transactedLicenseFromMediaObject = selectTransactedLicense(state, activeMediaObjectId)

  return {
    mediaObjectId: activeMediaObjectId,
    isSocial,
    transactedLicenseFromMediaObject,
    isRequesting: selectMediaObjectIsRequestingLicense(state),
    errorCode: selectFirstErrorCode(state),
    hasLicenseOptionsLink: Boolean(selectLicenseOptionsLink(state, activeMediaObjectId)),
    licenseOptions: selectMediaObjectLicenseOptions(state, activeMediaObjectId),
    hasInsufficientFunds: selectHasInsufficientFundsForLicense(state),
    mediaType: selectMediaType(state, activeMediaObjectId),
  }
}

const mapDispatchToProps = {
  fetchMediaObjectLicenseOptions,
}

export default compose<IInternalProps, ISubmissionCardModalLicenseProps>(
  withCampaign,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(SubmissionCardModalLicense)
