import { call, put, select, takeLatest } from 'redux-saga/effects'

import { handleAfterSignin } from '@sagas/auth/afterSignIn'
import { IHateoasLink } from '@store'
import { identifyRequest } from '@store/analytics'
import { refreshToken, selectAuthToken } from '@store/auth'
import { fetchMeError, fetchMeRequest, fetchMeSuccess, MeActionTypes } from '@store/me'
import { selectMeLink } from '@store/rootResource'
import { networkRequest } from '@utils'

export function* handleFetchMe(action: ReturnType<typeof fetchMeRequest>) {
  try {
    const authToken: string = yield select(selectAuthToken)
    const meLink: IHateoasLink = yield select(selectMeLink)
    const result = yield call(networkRequest, meLink.method, meLink.href, undefined, authToken)
    yield put(fetchMeSuccess(result))
    yield put(refreshToken())
    yield put(identifyRequest(result))
    if (action.meta.signIn) {
      yield call(handleAfterSignin, action)
    }
  } catch (error) {
    yield put(fetchMeError(error))
  }
}

export function* watchFetchMeRequest() {
  yield takeLatest(MeActionTypes.FETCH_REQUEST, handleFetchMe)
}
