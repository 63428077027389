import React from 'react'

import Theme from '@theme'
import { Container, Icon } from '@tribegroup'

interface IProps {
  direction: 'left' | 'right'
  className?: string
  style?: object
  onClick?: VoidFunction
}

const Arrow: React.FC<IProps> = ({ direction, className = '', style, onClick }) => {
  const isDisabled = className.includes('disabled')
  return (
    <Container
      className={className}
      style={{ ...style, [direction]: '-2.75rem', marginTop: '-2rem' }}
      onClick={onClick}
      role="button"
    >
      <Icon
        glyph={`chevron-${direction}`}
        size={2.125}
        color={isDisabled ? Theme.grey600Color : Theme.defaultColor}
      />
    </Container>
  )
}

export { Arrow }
export default Arrow
