import { FormattedMessage } from 'react-intl'

import Confirmation from '@components/UI/Confirmation'
import { FooterButtonWrapper } from '@components/UI/Confirmation/Confirmation.styled'
import { useModal } from '@hooks/useModal'
import { Button, Header } from '@tribegroup'

export const CreditManagementPaymentMethodPurchaseOrderRequestAccess = () => {
  const { isOpen, hideModal } = useModal()

  return (
    <Confirmation
      isOpen={Boolean(isOpen)}
      title={
        <FormattedMessage id="contentLibrary.licensing.creditManagement.purchaseOrder.requestAccess.header" />
      }
      content={
        <FormattedMessage id="contentLibrary.licensing.creditManagement.purchaseOrder.requestAccess.content" />
      }
      footerButtons={
        <FooterButtonWrapper>
          <Button type="button" small scheme="primary" height={2} onClick={hideModal}>
            <Header cta small>
              <FormattedMessage id="core.text.okay.thanks" />
            </Header>
          </Button>
        </FooterButtonWrapper>
      }
    />
  )
}
