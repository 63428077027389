import AuthForgotPasswordForm from '@components/Auth/ForgotPassword/Form'
import FullImageBackground from '@components/UI/FullImageBackground'
import HeadTag from '@components/UI/HeadTag'

export const ForgotPasswordPage = () => (
  <FullImageBackground>
    <HeadTag id="resetPassword.forgotPassword.h1.header" />
    <AuthForgotPasswordForm />
  </FullImageBackground>
)

export default ForgotPasswordPage
