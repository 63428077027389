import React from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import ActionIcon from '@components/UI/ActionIcon'
import { ICampaignContextProps } from '@context/Campaign'
import { withCampaign } from '@hocs'
import { IApplicationState } from '@store'
import { selectIsShortlisting, shortlistSubmissionRequest } from '@store/submissions'
import Theme from '@theme'
import { getSubmissionFilter, getSubmissionSortBy } from '@utils'

interface IActionBarIconShortlistProps {
  submissionId: number
  refreshSubmissionsOnAction?: boolean
}

interface IInternalProps
  extends IActionBarIconShortlistProps,
    InjectedIntlProps,
    RouteComponentProps,
    ICampaignContextProps {
  shortlistSubmissionRequest: typeof shortlistSubmissionRequest
  filter?: string
  sortBy?: string
}

export const ActionBarIconShortlist: React.FC<IInternalProps> = ({
  intl,
  filter,
  sortBy,
  submissionId,
  campaign,
  shortlistSubmissionRequest: shortlistSubmission,
  refreshSubmissionsOnAction = true,
}: IInternalProps) => {
  const isShortlisting = useSelector((state: IApplicationState) =>
    selectIsShortlisting(state, submissionId),
  )

  const onShortlistSubmission = () => {
    shortlistSubmission(campaign.id, submissionId, {
      filter,
      sortBy,
      refreshSubmissions: refreshSubmissionsOnAction,
    })
  }

  return (
    <ActionIcon
      glyph="heart"
      color={Theme.grey900Color}
      size={1.5}
      onClick={onShortlistSubmission}
      tooltip={intl.formatMessage({ id: 'submission.card.action.addtoshortlist' })}
      elementName="shortlist"
      disabled={isShortlisting}
    />
  )
}

const mapDispatchToProps = {
  shortlistSubmissionRequest,
}

const mapStateToProps = (state: IApplicationState, { history }) => ({
  filter: getSubmissionFilter(history.location),
  sortBy: getSubmissionSortBy(history.location),
})

export default compose<IInternalProps, IActionBarIconShortlistProps>(
  injectIntl,
  withRouter,
  withCampaign,
  connect(mapStateToProps, mapDispatchToProps),
)(ActionBarIconShortlist)
