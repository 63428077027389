import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import {
  fetchCampaignBillings,
  selectCampaignBillingList,
  selectIsFetchingBilling,
} from '@store/billings'
import { isEmpty } from '@utils'
import PurchaseWrapper, { IPurchaseWrapperProps } from './Wrapper'

const mapStateToProps = (state: IApplicationState) => {
  const hasPurchases = !isEmpty(selectCampaignBillingList(state))
  const isLoading = !hasPurchases && selectIsFetchingBilling(state)
  return { isLoading, hasPurchases, enableFetch: !hasPurchases }
}

const mapDispatchToProps = {
  fetchList: fetchCampaignBillings,
}

export default compose<IPurchaseWrapperProps, {}>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PurchaseWrapper)
