import { useEffect } from 'react'

import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'recompose'

import { Hr } from '@components/UI/Hr/Hr.styled'
import Price from '@components/UI/Price/Price'
import { TRIBE_TOC } from '@constants'
import { IModalComponentProps } from '@context/Modal'
import { withModal } from '@hocs'
import { usePrevious } from '@hooks/usePrevious'
import rocket from '@images/rocketship-illustration.png'
import { IApplicationState } from '@store'
import {
  activateWithFee,
  selectBriefActivationFeeCents,
  selectBriefActivationFeeCurrency,
  selectBriefActivationFeeStatus,
  selectIsActivatingWithFee,
} from '@store/campaigns'
import {
  selectActivationFee,
  selectActivationFeeCurrency,
  selectCompanyRegionTaxDisplayName,
} from '@store/me'
import Theme from '@theme'
import { Button, CloseIcon, Container, H2, Link, Modal, Text } from '@tribegroup'
import { RocketImage } from './Activate.styled'

interface IProps extends IModalComponentProps {
  campaignId: number
}

type IInternalProps = IProps & InjectedIntlProps

const CampaignCardModalCreditCardActivate: React.FC<IInternalProps> = ({
  onRequestClose,
  isOpen = false,
  campaignId,
  intl,
}) => {
  const {
    activationFee,
    activationFeeCurrency,
    taxDisplayName,
    isActivating,
    briefActivationFee,
    briefActivationFeeCurrency,
    briefActivationFeeStatus,
  } = useSelector((state: IApplicationState) => {
    const activationFeeCurrency = selectActivationFeeCurrency(state)

    return {
      activationFeeCurrency,
      activationFee: selectActivationFee(state),
      taxDisplayName: selectCompanyRegionTaxDisplayName(state, intl, activationFeeCurrency),
      isActivating: selectIsActivatingWithFee(state),
      briefActivationFee: selectBriefActivationFeeCents(state, campaignId),
      briefActivationFeeCurrency: selectBriefActivationFeeCurrency(state, campaignId),
      briefActivationFeeStatus: selectBriefActivationFeeStatus(state, campaignId),
    }
  })

  const wasActivating = usePrevious(isActivating)

  useEffect(() => {
    if (wasActivating && !isActivating) {
      onRequestClose()
    }
  }, [wasActivating, isActivating])

  const dispatch = useDispatch()

  const onClickActivateCampaign = () => {
    dispatch(activateWithFee(campaignId))
  }

  return (
    <Modal reactModalProps={{ isOpen }} width={27.5} padding={3.125}>
      <CloseIcon onClick={!isActivating ? onRequestClose : undefined} />
      <Container textAlign="center">
        <Container flexCenter>
          <RocketImage
            width={134}
            height={134}
            src={rocket}
            alt="campaign-activation-prompt-illustration"
          />
        </Container>
        <H2 topOuterSpacing={1.75} color={Theme.grey900Color}>
          <FormattedMessage id="campaign.card.modal.creditCard.header" />
        </H2>
        <Container topOuterSpacing={1} leftOuterSpacing={1.5} rightOuterSpacing={1.5}>
          <Text color={Theme.grey800Color}>
            <FormattedMessage id="campaign.card.modal.creditCard.details" />
          </Text>
        </Container>
        <Container topOuterSpacing={2.5}>
          <Hr backgroundColor={Theme.grey400Color} />
          <Container flexBetween>
            <Text block color={Theme.grey900Color}>
              <FormattedMessage
                id="core.text.totalCurrencyExTaxName"
                values={{ currency: activationFeeCurrency, taxName: taxDisplayName }}
              />
            </Text>
            <Text block color={Theme.grey900Color}>
              {briefActivationFeeStatus === 'overridden' ? (
                <Price
                  quantity={briefActivationFee!}
                  currency={briefActivationFeeCurrency}
                  rounded
                />
              ) : (
                <Price quantity={activationFee} currency={activationFeeCurrency} rounded />
              )}
            </Text>
          </Container>
        </Container>
        <Container topOuterSpacing={3.375}>
          <Button
            disabled={isActivating}
            loading={isActivating}
            height={3}
            scheme="primary"
            fullWidth
            onClick={onClickActivateCampaign}
            data-testid="cc-campaign-modal-activate-button"
          >
            <FormattedMessage id="core.text.activateCampaign" />
          </Button>
        </Container>
        <Container topOuterSpacing={1.25} textAlign="center">
          <Text small color={Theme.grey900Color}>
            <FormattedHTMLMessage id="core.text.preTermsAgreement" />
          </Text>
          <Container topOuterSpacing={0.0625}>
            <Text small color={Theme.grey900Color}>
              <Link href={TRIBE_TOC} target="_blank" color={Theme.primaryColor}>
                <FormattedMessage id="core.text.tribeTermsofService" />
              </Link>
            </Text>
          </Container>
        </Container>
      </Container>
    </Modal>
  )
}

export default compose<IInternalProps, IProps>(
  withModal,
  injectIntl,
)(CampaignCardModalCreditCardActivate)
