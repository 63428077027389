import React from 'react'

import { NoticeImage } from '@components/UI/Notice'
import empty from '@images/bca-empty-image.png'
import emptyx2 from '@images/bca-empty-image@2x.png'
import emptyx3 from '@images/bca-empty-image@3x.png'

interface IProps {
  alt: string
}

const SubmissionCardListEmptyBrandedContentAdImage: React.FC<IProps> = ({ alt }) => {
  return (
    <NoticeImage
      data-testid="notice-image"
      width={'auto'}
      height={19.375}
      src={empty}
      srcSet={`${empty} 300w, ${emptyx2} 768w, ${emptyx3} 1280w`}
      alt={`empty-${alt}`}
    />
  )
}

export default SubmissionCardListEmptyBrandedContentAdImage
