import React from 'react'

import { Header } from '@tribegroup'
import SideBarBlockStyled from './Block.styled'

export interface ISideBarBlockProps {
  header: string | React.ReactNode
  items?: ReadonlyArray<React.ReactNode>
  elementName?: string
}

const SideBarBlock = ({
  header,
  items = [],
  id = 'side-bar-block',
  elementName = 'side-bar-item',
}: ISideBarBlockProps & React.HtmlHTMLAttributes<HTMLDivElement>) => (
  <SideBarBlockStyled>
    <Header cta>{header}</Header>
    {items && (
      <ul data-cy-element={id}>
        {items.map((item, index) => (
          <li key={index} data-cy-element={`${elementName}-${index}`}>
            {item}
          </li>
        ))}
      </ul>
    )}
  </SideBarBlockStyled>
)

export default SideBarBlock
