import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { CostBreakdownRow } from '@components/UI/CostBreakdown'
import { usePreapprovalAmountPercentage } from '@hooks/usePreapprovalAmountPercentage'
import { IApplicationState } from '@store'
import {
  selectBudgetTotalCurrency,
  selectCampaign,
  selectCampaignBudgetRemaining,
  selectCampaignBudgetSpent,
  selectIsSocialCampaign,
  selectShouldHideCampaignBudget,
} from '@store/campaigns'
import Theme from '@theme'
import { Container } from '@tribegroup'

interface IProps {
  campaignId: number
  isPayingByCreditCard: boolean
}

const CampaignBudgetLineItems: React.FC<IProps> = ({ isPayingByCreditCard, campaignId }) => {
  const budget = useSelector((state: IApplicationState) => {
    const {
      budget_total_cents,
      budget_spent_currency,
      budget_spent_approvals_cents,
      budget_spent_approvals_currency,
      budget_spent_preapprovals_cents,
      budget_spent_preapprovals_currency,
      budget_spent_outstandings_cents,
      budget_spent_outstandings_currency,
      budget_spent_content_licenses_cents,
      budget_spent_content_licenses_currency,
      budget_spent_branded_content_ad_cents,
      budget_spent_branded_content_ad_currency,
      budget_remaining_currency,
      budget_spent_activation_fee_cents,
      budget_spent_activation_fee_currency,
      budget_spent_ad_hoc_purchases_cents,
      budget_spent_ad_hoc_purchases_currency,
    } = selectCampaign(state, campaignId)

    return {
      budget_total_cents,
      budget_spent_currency,
      budget_spent_approvals_cents,
      budget_spent_approvals_currency,
      budget_spent_preapprovals_cents,
      budget_spent_preapprovals_currency,
      budget_spent_outstandings_cents,
      budget_spent_outstandings_currency,
      budget_spent_content_licenses_cents,
      budget_spent_content_licenses_currency,
      budget_spent_branded_content_ad_cents,
      budget_spent_branded_content_ad_currency,
      budget_remaining_currency,
      budget_spent_activation_fee_cents,
      budget_spent_activation_fee_currency,
      budget_spent_ad_hoc_purchases_cents,
      budget_spent_ad_hoc_purchases_currency,
      budget_total_currency: selectBudgetTotalCurrency(state, campaignId),
      budget_spent_cents: selectCampaignBudgetSpent(state, campaignId),
      budget_remaining_cents: selectCampaignBudgetRemaining(state, campaignId),
    }
  })
  const percentage = usePreapprovalAmountPercentage()
  const amountForDisplay = (price: number) => (isPayingByCreditCard ? price : -Math.abs(price))
  const { shouldHideRemainingBudget, isSocialCampaign, isPreapprovalAllowed } = useSelector(
    (state: IApplicationState) => {
      const campaign = selectCampaign(state, campaignId)

      return {
        shouldHideRemainingBudget: selectShouldHideCampaignBudget(state, campaignId),
        isSocialCampaign: selectIsSocialCampaign(state, campaignId),
        isPreapprovalAllowed: campaign?.preapproval_allowed,
      }
    },
  )

  return (
    <Container topOuterSpacing={1.5}>
      {!isPayingByCreditCard && !shouldHideRemainingBudget && (
        <Container bottomOuterSpacing={0.75}>
          <CostBreakdownRow
            medium
            label={<FormattedMessage id="core.text.budget" />}
            currency={budget.budget_total_currency!}
            quantity={budget.budget_total_cents!}
          />
        </Container>
      )}

      {isSocialCampaign && (
        <CostBreakdownRow
          label={<FormattedMessage id="campaign.budget.summary.approvals" />}
          currency={budget.budget_spent_approvals_currency!}
          quantity={amountForDisplay(budget.budget_spent_approvals_cents!)}
        />
      )}

      {isPreapprovalAllowed && (
        <>
          <CostBreakdownRow
            label={
              <FormattedMessage id="campaign.budget.summary.preApprovals" values={{ percentage }} />
            }
            currency={budget.budget_spent_preapprovals_currency!}
            quantity={amountForDisplay(budget.budget_spent_preapprovals_cents!)}
          />
          <CostBreakdownRow
            labelWidth={13}
            label={<FormattedMessage id="campaign.budget.summary.preApprovalsBalanceDue" />}
            currency={budget.budget_spent_outstandings_currency!}
            quantity={amountForDisplay(budget.budget_spent_outstandings_cents!)}
          />
        </>
      )}

      {isSocialCampaign ? (
        <CostBreakdownRow
          label={<FormattedMessage id="campaign.budget.summary.contentLicenses" />}
          currency={budget.budget_spent_content_licenses_currency!}
          quantity={amountForDisplay(budget.budget_spent_content_licenses_cents!)}
        />
      ) : (
        <CostBreakdownRow
          label={<FormattedMessage id="campaign.budget.summary.contentLicenses" />}
          currency={budget.budget_spent_approvals_currency!}
          quantity={amountForDisplay(budget.budget_spent_approvals_cents!)}
        />
      )}

      {isSocialCampaign && budget.budget_spent_branded_content_ad_currency && (
        <CostBreakdownRow
          label={<FormattedMessage id="campaign.budget.summary.brandContentAds" />}
          currency={budget.budget_spent_branded_content_ad_currency!}
          quantity={amountForDisplay(budget.budget_spent_branded_content_ad_cents!)}
        />
      )}

      {budget.budget_spent_activation_fee_currency && (
        <CostBreakdownRow
          label={<FormattedMessage id="campaign.budget.summary.activationFee" />}
          currency={budget.budget_spent_activation_fee_currency!}
          quantity={amountForDisplay(budget.budget_spent_activation_fee_cents!)}
        />
      )}
      <CostBreakdownRow
        label={<FormattedMessage id="campaign.budget.summary.adhocPurchase" />}
        currency={budget.budget_spent_ad_hoc_purchases_currency!}
        quantity={amountForDisplay(budget.budget_spent_ad_hoc_purchases_cents!)}
      />
      <Container topOuterSpacing={0.75}>
        {!shouldHideRemainingBudget && (
          <>
            {isPayingByCreditCard ? (
              <CostBreakdownRow
                color={Theme.primaryColor}
                medium
                label={<FormattedMessage id="campaign.budget.summary.budgetSpent" />}
                currency={budget.budget_spent_currency!}
                quantity={budget.budget_spent_cents!}
              />
            ) : (
              <CostBreakdownRow
                color={Theme.primaryColor}
                medium
                label={<FormattedMessage id="campaign.budget.summary.budgetRemaining" />}
                currency={budget.budget_remaining_currency!}
                quantity={budget.budget_remaining_cents!}
              />
            )}
          </>
        )}
      </Container>
    </Container>
  )
}

export default CampaignBudgetLineItems
