import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { H5, Icon } from '@tribegroup'
import Price from '../Price/Price'
import { PricePanelAmountStyled, PricePanelAmountWrapper } from './PricePanel.styled'

interface IProps {
  price: number
  currency: string
  taxDisplayName: string
  greyedOut?: boolean
  hasPriceChanged?: boolean
  showFrom?: boolean
}

export const PricePanelAmount = ({
  price,
  currency,
  taxDisplayName,
  showFrom,
  greyedOut,
  hasPriceChanged,
}: IProps) => (
  <PricePanelAmountWrapper justifyStart greyedOut={greyedOut}>
    <PricePanelAmountStyled>
      <H5 inline color={Theme.grey900Color}>
        {showFrom && (
          <Fragment>
            <FormattedMessage id="core.text.from" />
            &nbsp;
          </Fragment>
        )}
        <Price
          quantity={price}
          currency={currency}
          rounded
          pattern={hasPriceChanged ? '!###,###*' : undefined}
        />
      </H5>

      {taxDisplayName && taxDisplayName !== 'N/A' && (
        <H5 inline light color={Theme.grey900Color}>
          &nbsp;
          <FormattedMessage
            id="submission.fullView.pricePanel.tax.text"
            values={{ taxName: taxDisplayName }}
          />
        </H5>
      )}
    </PricePanelAmountStyled>
    &nbsp;
    <Icon glyph="information" color={Theme.grey900Color} />
  </PricePanelAmountWrapper>
)

export default PricePanelAmount
