import { Fragment } from 'react'

import SubmissionCreatorNotePanel from '@components/Submission/CreatorNote/Panel/Panel'
import Media from '@components/UI/Media/Media'
import { AspectRatios, MediaTypes, SocialSubmissionTypes } from '@enums'
import { ISubmission } from '@store/submissions'
import Theme from '@theme'
import { Container } from '@tribegroup'
import SubmissionCardMediaMultiframe from './Multiframe'

export interface ISubmissionCardMediaProps {
  submission: ISubmission
  expandable?: boolean
  containImage?: boolean
}

export const SubmissionCardMedia = ({ submission, ...props }: ISubmissionCardMediaProps) => {
  const socialSubmissionType = submission.social_submission_type as SocialSubmissionTypes
  const isMultiframe = [SocialSubmissionTypes.Story, SocialSubmissionTypes.Carousel].includes(
    socialSubmissionType,
  )

  if (isMultiframe) {
    return <SubmissionCardMediaMultiframe socialSubmissionType={socialSubmissionType} />
  }

  const firstMediaObject = submission.media_objects[0]
  const isVideo = firstMediaObject.media_type === MediaTypes.VIDEO

  return (
    <Fragment>
      <Media
        withRightSeparatorBorder
        src={firstMediaObject.thumbnail_media_url}
        fullSizeSrc={firstMediaObject.original_media_url}
        mediaId={firstMediaObject.frame_number}
        mediaType={firstMediaObject.media_type as MediaTypes}
        aspectRatio={AspectRatios.ONE_BY_ONE}
        backgroundColor={Theme.whiteColor}
        showPlayIcon={isVideo}
        controls
        {...props}
      />
      <Container fullWidth relative>
        <SubmissionCreatorNotePanel />
      </Container>
    </Fragment>
  )
}

export default SubmissionCardMedia
