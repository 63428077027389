import React, { PropsWithChildren } from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import LoadingStyled from './Loading.styled'

interface ILoadingProps {
  height?: number
  fullHeight?: boolean
  leftAligned?: boolean
  yPadding?: number
  iconOnly?: boolean
  color?: string
}

const Loading: React.FC<PropsWithChildren<ILoadingProps>> = ({
  children,
  height,
  fullHeight,
  leftAligned,
  yPadding,
  iconOnly,
  color,
}) => {
  const text = children ? children : <FormattedMessage id="campaign.campaignsPage.loading.text" />
  return (
    <LoadingStyled
      height={height}
      leftAligned={leftAligned}
      yPadding={yPadding}
      fullHeight={fullHeight}
    >
      <Icon color={color ?? Theme.primaryColor} glyph="spinner" className="spinner" />
      {!iconOnly && (
        <Container leftOuterSpacing={0.625}>
          <Text small color={Theme.grey600Color}>
            {text}
          </Text>
        </Container>
      )}
    </LoadingStyled>
  )
}

export default Loading
