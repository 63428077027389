import { useContext } from 'react'

import { ModalContext } from '@context/Modal'
import { IModalContextProps } from '@context/Modal/Provider'

const useModal = () => {
  const contextProps = useContext<IModalContextProps>(ModalContext)
  return contextProps
}

export { useModal }
