import styled from 'styled-components'

export const MoodboardHeader = styled.div`
  margin-top: 3.75rem;
`

export const MoodboardText = styled.div`
  margin-top: 0.75rem;
`

export const MoodboardUploadGridWrapper = styled.div`
  margin-top: 2rem;
  display: grid;
  box-sizing: border-box;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
`

export const ErrorMessage = styled.div`
  margin-top: 0.5rem;
`
