import { createSelector } from 'reselect'

import {
  EMPTY_LIST,
  PENDING_SUBMISSION_DECLINE_REASONS_CATEGORY,
  PREAPPROVED_SUBMISSION_DECLINE_REASONS_CATEGORY,
  SHAREABLE_CONTENT_LIBRARY,
} from '@constants'
import { IApplicationState, IHateoasLink } from '@store'
import {
  IAudienceLocation,
  ICategorisedDeclineReasons,
  IConsentedItems,
  IGender,
  IOperatingRegion,
  IProvidedCampaignObjective,
  IRootResourceState,
} from '@store/rootResource'
import { toLabelValuePair } from '@utils'
import getConfig from '@utils/getConfig'

export const DEFAULT_GENDERS = [
  { code: 'male', display_name: 'Male' },
  { code: 'female', display_name: 'Female' },
]

export const selectRootResource = (state: IApplicationState) => state.rootResource

export const selectRootResourceIsFetching = (state: IApplicationState) =>
  selectRootResource(state).isFetching

export const selectRootResourceIsFetched = (state: IApplicationState) =>
  selectRootResource(state).isFetched

export const selectLink = (state: IApplicationState, rel: string): IHateoasLink | undefined =>
  selectRootResource(state).links.find((rootLink: IHateoasLink) => rootLink.rel === rel)

export const selectMeLink = (state: IApplicationState) => selectLink(state, 'me')

export const selectSignInLink = (state: IApplicationState) => selectLink(state, 'signin')

export const selectSignUpLink = (state: IApplicationState) => selectLink(state, 'signup')

export const selectCompleteSignUpLink = (state: IApplicationState) =>
  selectLink(state, 'signup_completion')

export const selectForgotPasswordLink = (state: IApplicationState) =>
  selectLink(state, 'forgot_password')

export const selectSetPasswordLink = (state: IApplicationState) =>
  selectLink(state, 'reset_password')

export const selectVerificationEmailLink = (state: IApplicationState) =>
  selectLink(state, 'verify_email')

export const selectAutoSignInLink = (state: IApplicationState) =>
  selectLink(state, 'auto_signin_new_app')

export const selectCategoriesLink = (state: IApplicationState) => selectLink(state, 'categories')

export const selectUploadMediaLink = (state: IApplicationState) => selectLink(state, 'temp_media')

export const selectShareableContentLibraryLink = (state: IApplicationState) =>
  selectLink(state, SHAREABLE_CONTENT_LIBRARY)

export const selectShareableMediaObjectLink = (state: IApplicationState) =>
  selectLink(state, 'shareable_media_object')

export const selectCompleteVerificationLink = (state: IApplicationState) =>
  selectLink(state, 'complete_verification')

const selectConsentedItems = (state: IApplicationState): IConsentedItems =>
  selectRootResource(state).consented_items

export const selectConsentedItemsTermsDefault = (state: IApplicationState) =>
  selectConsentedItems(state).toc_checked_by_default

export const selectConsentedItemsEmailDefault = (state: IApplicationState) =>
  selectConsentedItems(state).brand_marketing_consent_default

export const selectCompanySizes = (state: IApplicationState) =>
  selectRootResource(state).company_sizes

export const selectCompanyTypes = (state: IApplicationState) =>
  selectRootResource(state).company_types

export const selectDeclineReasons = (state: IApplicationState, submissionType: string) => {
  const reasons = selectRootResource(state).decline_reasons
  return (reasons && reasons[submissionType]) || EMPTY_LIST
}

export const selectPendingSubmissionDeclineReasons = createSelector(
  selectDeclineReasons,
  (reasons: ReadonlyArray<ICategorisedDeclineReasons>) =>
    reasons.filter((reason) =>
      PENDING_SUBMISSION_DECLINE_REASONS_CATEGORY.includes(reason.category),
    ),
)

export const selectPreapprovedSubmissionDeclineReasons = createSelector(
  selectDeclineReasons,
  (reasons: ReadonlyArray<ICategorisedDeclineReasons>) =>
    reasons.filter((reason) =>
      PREAPPROVED_SUBMISSION_DECLINE_REASONS_CATEGORY.includes(reason.category),
    ),
)

export const selectTargetRegions = (state: IApplicationState) =>
  selectRootResource(state).target_regions || EMPTY_LIST

export const selectTargetRegionsAsLabelValue = (state: IApplicationState) =>
  toLabelValuePair(selectTargetRegions(state), 'id', 'display_name')

export const selectCountries = (state: IApplicationState) =>
  selectRootResource(state).countries || EMPTY_LIST

export const selectCountryStates = (state: IApplicationState) =>
  selectRootResource(state).states || EMPTY_LIST

export const selectResendVerificationLink = (state: IApplicationState) =>
  selectLink(state, 'resend_verification_email')

export const selectResendCompleteSignupVerificationLink = (state: IApplicationState) =>
  selectLink(state, 'resend_complete_signup_verification_email')

export const selectCountryStatesLink = (state: IApplicationState) => selectLink(state, 'states')

export const selectCountriesAsLabelValue = (state: IApplicationState) =>
  toLabelValuePair(selectCountries(state), 'country_code', 'display_name')

export const selectCountryStatesAsLabelValue = (state: IApplicationState) =>
  toLabelValuePair(selectCountryStates(state), 'code', 'display_name')

export const selectProvidedObjectives = (state: IApplicationState) =>
  selectRootResource(state).provided_objectives ||
  (EMPTY_LIST as ReadonlyArray<IProvidedCampaignObjective>)

export const selectFeedbackNegotiationKeywords = createSelector(
  selectRootResource,
  (rootResource) => {
    return rootResource.feedback_negotiation_keywords || EMPTY_LIST
  },
)

export const selectOperatingRegions = (state: IApplicationState): ReadonlyArray<IOperatingRegion> =>
  selectRootResource(state).operating_regions || EMPTY_LIST

export const selectAudienceAgeRange = (state: IApplicationState): ReadonlyArray<string> =>
  selectRootResource(state).audience_age_range || EMPTY_LIST

export const selectAudienceLocations = (
  state: IApplicationState,
): ReadonlyArray<IAudienceLocation> => selectRootResource(state).audience_locations || EMPTY_LIST

export const selectBrandedContentAdDaysOptions = (
  state: IApplicationState,
): ReadonlyArray<number> => selectRootResource(state).bca_duration_in_days_options || EMPTY_LIST

export const selectTikTokBrandedContentAdDaysOptions = (
  state: IApplicationState,
): ReadonlyArray<number> => selectRootResource(state).tiktok_bca_duration_in_days_options || [60]

export const selectSubscriptionTiers = (state: IApplicationState) =>
  selectRootResource(state).subscription_tiers || EMPTY_LIST

export const selectSocialSubmissionTypes = (state: IApplicationState) =>
  selectRootResource(state).social_submission_types || EMPTY_LIST

export const selectPreapprovalAmountPercentage = createSelector(
  selectRootResource,
  (root: IRootResourceState) => root.preapproval_amount_percentage,
)

export const selectAllGenders = createSelector(
  selectRootResource,
  (root: IRootResourceState): ReadonlyArray<IGender> => {
    if (getConfig('additionalGendersEnabled')) {
      return root.genders || []
    }
    return DEFAULT_GENDERS
  },
)

export const selectInstagramGenders = createSelector(
  selectAllGenders,
  (allGenders: ReadonlyArray<IGender>): ReadonlyArray<IGender> => {
    if (getConfig('additionalGendersEnabled')) {
      return allGenders.length ? allGenders.slice(0, 2) : []
    }
    return DEFAULT_GENDERS
  },
)

export const selectTenantFeaturesByKey = createSelector(
  selectRootResource,
  ({ tenant_features: tenantFeatures = [] }: IRootResourceState) => {
    return tenantFeatures.reduce(
      (accum, tenantFeature) => ({
        ...accum,
        [tenantFeature.key]: tenantFeature.value,
      }),
      {},
    )
  },
)

export const selectCampaignCreationReasons = createSelector(
  selectRootResource,
  (state: IRootResourceState) => state.campaign_creation_reasons,
)

export const selectEstimatedCampaignCreationCounts = createSelector(
  selectRootResource,
  (state: IRootResourceState) => state.estimated_campaign_creation_count,
)

export const selectProductCategories = createSelector(
  selectRootResource,
  (state: IRootResourceState) => state.product_categories,
)
