import React from 'react'

import { FormattedMessage } from 'react-intl'

import { ICampaign } from '@store/campaigns'
import Theme from '@theme'
import { Header } from '@tribegroup'
import CampaignPreviewBlock from '../Block'
import CampaignPreviewCommonBlockGender from '../CommonBlock/Gender'
import CampaignPreviewPreferredAudienceAge from './Age'
import CampaignPreviewPreferredAudienceLocations from './Locations'

interface IProps {
  campaign: ICampaign
}

const CampaignPreviewPreferredAudience: React.FC<IProps> = ({ campaign }) => {
  const { audience_age_range = [], audience_gender = [], audience_location_codes = [] } = campaign

  if (
    audience_age_range.length === 0 &&
    audience_gender.length === 0 &&
    audience_location_codes.length === 0
  ) {
    return null
  }

  return (
    <CampaignPreviewBlock>
      <Header small color={Theme.grey900Color} uppercase cta bottomOuterSpacing={0.25}>
        <FormattedMessage id="campaign.preview.preferredAudience.header" />
      </Header>
      {audience_gender.length > 0 && (
        <CampaignPreviewCommonBlockGender instagramGenders selectedGenderCodes={audience_gender} />
      )}
      {audience_age_range.length > 0 && (
        <CampaignPreviewPreferredAudienceAge selectedAgeRange={audience_age_range} />
      )}
      {audience_location_codes.length > 0 && (
        <CampaignPreviewPreferredAudienceLocations locationCodes={audience_location_codes} />
      )}
    </CampaignPreviewBlock>
  )
}

export default CampaignPreviewPreferredAudience
