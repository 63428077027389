import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import {
  AuthActionTypes,
  resendVerificationError,
  resendVerificationRequest,
  resendVerificationSuccess,
} from '@store/auth'
import {
  selectResendCompleteSignupVerificationLink,
  selectResendVerificationLink,
} from '@store/rootResource'
import { Toaster } from '@tribegroup'
import { networkRequest } from '@utils'

export function* handleResendVerificationRequest(
  action: ReturnType<typeof resendVerificationRequest>,
) {
  try {
    const { email, history, toastMessage, source } = action.payload

    let link: IHateoasLink

    if (source === 'solution') {
      link = yield select(selectResendCompleteSignupVerificationLink)
    } else {
      link = yield select(selectResendVerificationLink)
    }

    yield call(networkRequest, link.method, link.href, {
      email,
    })
    yield put(resendVerificationSuccess())
    if (history) {
      yield call(history.push, `/signUpConfirmation?email=${encodeURIComponent(email)}`)
    }
    if (toastMessage) {
      yield call(Toaster.toast, toastMessage)
    }
  } catch (error) {
    yield put(resendVerificationError(error))
  }
}

export function* watchResendVerificationRequest() {
  yield takeLatest(AuthActionTypes.RESEND_VERIFICATION_REQUEST, handleResendVerificationRequest)
}
