import styled from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { FadeIn } from '@tribegroup'

export const ChatProfileBlockStyled = styled(Flex)`
  padding: 0 1.625rem;
`

export const ChatListStyled = styled(Flex)`
  padding: 1.25rem 1.625rem 0;
  flex: 1;
  text-align: center;
  animation: ${FadeIn} 0.2s linear;
  width: 100%;
  box-sizing: border-box;
`

export const ChatFormDivider = styled('div')`
  width: calc(100% - 3.25rem);
  margin-left: 1.625rem;
  margin-right: 1.625rem;
  margin-top: 1.0625rem;
  border-top: solid 1px ${Theme.grey400Color};
  border-bottom: 0;
`
