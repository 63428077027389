import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouterProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import ActionIcon from '@components/UI/ActionIcon'
import { IApplicationState } from '@store'
import { IMe, selectIsUpdating, selectMeInfo, updateContact } from '@store/me'
import Theme from '@theme'
import {
  Form,
  FormGroup,
  FormGroupRow,
  FormInput,
  H5,
  Icon,
  Panel,
  Text,
  TextField,
  withFormValidation,
} from '@tribegroup'
import { IWithFormValidation, IWithFormValidationProps } from '@tribegroup/FormValidation'
import { ErrorMessage } from '../Account'
import { List, Submit } from './../Account.styled'

interface IInternalProps
  extends RouterProps,
    InjectedIntlProps,
    IWithFormValidationProps,
    IWithFormValidation {
  me: IMe
  updateContact: typeof updateContact
  isUpdating?: boolean
}

interface IAccountContactFormState {
  first_name: string
  last_name: string
  email: string
  phone_number: string
}

export class AccountContactForm extends React.PureComponent<
  IInternalProps,
  IAccountContactFormState
> {
  constructor(props: IInternalProps) {
    super(props)
    const { me } = props
    this.state = {
      first_name: me.first_name,
      last_name: me.last_name,
      email: me.email,
      phone_number: me.phone_number || '',
    }
  }

  onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    this.setState({
      ...this.state,
      [target.name]: target.value,
    })
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    this.props.updateContact(this.state, {
      history: this.props.history,
      redirect: '/account',
    })
  }

  render() {
    const { validationResults = {}, intl, isUpdating } = this.props

    const requiredValidationRule = {
      required: {
        value: true,
        message: intl.formatMessage({
          id: 'account.error.required',
        }),
      },
    }

    return (
      <Form
        name="edit_contact"
        method="POST"
        onSubmit={this.onSubmit}
        onBeforeSubmit={this.props.validate}
        setForm={this.props.setForm}
      >
        <Panel
          disabled={isUpdating}
          actionIcons={[
            <Link key="edit-account" to="/account" data-cy-element="close-edit-contact-details">
              <ActionIcon glyph="close-x" />
            </Link>,
            <Submit key="save" type="submit" data-cy-element="save-contact-details">
              <Icon glyph="tick" size={1.25} />
            </Submit>,
          ]}
          title={
            <H5 color={Theme.grey900Color}>
              <FormattedMessage id="account.contact.title" />
            </H5>
          }
        >
          <List>
            <FormGroupRow
              spaceBetween={1}
              inlineLabelWidth={12.5}
              wrap
              inlineLableVerticalSpacing={0.625}
              contentWidth={32}
              inlineLabel={
                <Text xsmall uppercase color={Theme.grey800Color}>
                  <FormattedMessage id="account.contact.firstName" />
                </Text>
              }
            >
              <FormGroup>
                <FormInput validationRule={requiredValidationRule}>
                  <TextField
                    autoFocus
                    type="text"
                    name="first_name"
                    value={this.state.first_name}
                    fullWidth
                    onChange={this.onChange}
                    invalid={validationResults.first_name && validationResults.first_name.hasError}
                  />
                </FormInput>
                <ErrorMessage results={validationResults} field="first_name" />
              </FormGroup>
            </FormGroupRow>
            <FormGroupRow
              spaceBetween={1}
              inlineLabelWidth={12.5}
              wrap
              inlineLableVerticalSpacing={0.625}
              contentWidth={32}
              inlineLabel={
                <Text xsmall uppercase color={Theme.grey800Color}>
                  <FormattedMessage id="account.contact.lastName" />
                </Text>
              }
            >
              <FormGroup>
                <FormInput validationRule={requiredValidationRule}>
                  <TextField
                    type="text"
                    name="last_name"
                    value={this.state.last_name}
                    fullWidth
                    onChange={this.onChange}
                    invalid={validationResults.last_name && validationResults.last_name.hasError}
                  />
                </FormInput>
                <ErrorMessage results={validationResults} field="last_name" />
              </FormGroup>
            </FormGroupRow>
            <FormGroupRow
              spaceBetween={1}
              inlineLabelWidth={12.5}
              wrap
              inlineLableVerticalSpacing={0.625}
              contentWidth={32}
              inlineLabel={
                <Text xsmall uppercase color={Theme.grey800Color}>
                  <FormattedMessage id="core.text.email" />
                </Text>
              }
            >
              <FormGroup>
                <FormInput>
                  <TextField
                    type="email"
                    name="email"
                    value={this.state.email}
                    fullWidth
                    disabled
                  />
                </FormInput>
              </FormGroup>
            </FormGroupRow>
            <FormGroupRow
              spaceBetween={1}
              inlineLabelWidth={12.5}
              wrap
              inlineLableVerticalSpacing={0.625}
              contentWidth={32}
              inlineLabel={
                <Text xsmall uppercase color={Theme.grey800Color}>
                  <FormattedMessage id="account.contact.phoneNumber" />
                </Text>
              }
            >
              <FormGroup>
                <FormInput>
                  <TextField
                    type="tel"
                    name="phone_number"
                    value={this.state.phone_number}
                    fullWidth
                    onChange={this.onChange}
                  />
                </FormInput>
                <ErrorMessage results={validationResults} field="phone_number" />
              </FormGroup>
            </FormGroupRow>
          </List>
        </Panel>
      </Form>
    )
  }
}

const mapDispatchToProps = {
  updateContact,
}

const mapStateToProps = (state: IApplicationState) => ({
  me: selectMeInfo(state),
  isUpdating: selectIsUpdating(state),
})

export default compose<IInternalProps, any>(
  injectIntl,
  withRouter,
  withFormValidation,
  connect(mapStateToProps, mapDispatchToProps),
)(AccountContactForm)
