import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { withBuilderValidation } from '@hocs'
import Theme from '@theme'
import { Container } from '@tribegroup'
import { PREFERRED_TARGET_AUDIENCE_SET, PREFERRED_TARGET_AUDIENCE_UNSET } from './Audience'
import BuilderBudgetTargetAudienceOption from './Option'
import BuilderBudgetTargetAudienceUnsetOptionContainer from './UnsetOptionContainer'

interface IProps {
  selectedOption: string
  onOptionChange: (selected: string) => void
  disabledSelection: boolean
}

interface IInternalProps extends IProps, InjectedIntlProps, IBuilderValidationContextProps {}

const BuilderBudgetTargetAudienceSelection: React.FC<IInternalProps> = ({
  onOptionChange,
  selectedOption,
  disabledSelection,
}) => {
  const setSelected = selectedOption === PREFERRED_TARGET_AUDIENCE_SET
  const unsetSelected = selectedOption === PREFERRED_TARGET_AUDIENCE_UNSET

  const onUnsetOptionClick = () => onOptionChange(PREFERRED_TARGET_AUDIENCE_UNSET)

  const setOptionLabelColor =
    disabledSelection && unsetSelected ? Theme.grey700Color : Theme.defaultColor
  const unsetOptionLabelColor =
    disabledSelection && setSelected ? Theme.grey700Color : Theme.defaultColor
  return (
    <Container bottomOuterSpacing={1.5}>
      <Container bottomOuterSpacing={0.75}>
        <BuilderBudgetTargetAudienceOption
          onOptionChange={onOptionChange}
          disabled={disabledSelection}
          checked={setSelected}
          value={PREFERRED_TARGET_AUDIENCE_SET}
          showSelectedAsCheck={disabledSelection && setSelected}
          labelColor={setOptionLabelColor}
          label={<FormattedMessage id="builder.budget.demographics.targetAudience.selection.set" />}
        />
      </Container>
      <Container bottomOuterSpacing={0.75} relative>
        <BuilderBudgetTargetAudienceUnsetOptionContainer
          onConfirm={onUnsetOptionClick}
          disabled={disabledSelection}
        />
        <BuilderBudgetTargetAudienceOption
          onOptionChange={onOptionChange}
          checked={unsetSelected}
          value={PREFERRED_TARGET_AUDIENCE_UNSET}
          disabled={disabledSelection}
          showSelectedAsCheck={disabledSelection && unsetSelected}
          labelColor={unsetOptionLabelColor}
          label={
            <FormattedMessage id="builder.budget.demographics.targetAudience.selection.unset" />
          }
        />
      </Container>
    </Container>
  )
}

export default compose<IInternalProps, IProps>(
  injectIntl,
  withBuilderValidation,
)(BuilderBudgetTargetAudienceSelection)
