import { EMPTY_LIST } from '@constants'
import { IApplicationState } from '@store'

export const selectFeedbackLink = (state: IApplicationState, submissionId: number, rel: string) => {
  const feedbackLinks = state.feedback.feedbackLinksBySubmissionId[submissionId]
  return feedbackLinks && feedbackLinks.find((link) => link.rel === rel)
}

export const selectFeedback = (state: IApplicationState, submissionId: number) =>
  state.feedback.feedbackBySubmissionId[submissionId] || EMPTY_LIST

export const selectInfluencer = (state: IApplicationState, submissionId: number) =>
  state.feedback.influencerBySubmissionId[submissionId]

export const selectAddFeedbackLink = (state: IApplicationState, submissionId: number) =>
  selectFeedbackLink(state, submissionId, 'add_feedback')

export const selectMarkAsReadLink = (state: IApplicationState, submissionId: number) =>
  selectFeedbackLink(state, submissionId, 'mark_as_read')

export const selectGeneratePresignedUrlLink = (state: IApplicationState, submissionId: number) =>
  selectFeedbackLink(state, submissionId, 'generate_presigned_url')

export const selectIsAddingFeedback = (state: IApplicationState) => state.feedback.isAdding

export const selectIsFetchingFeedback = (state: IApplicationState) => state.feedback.isFetching

export const selectFeedbackErrors = (state: IApplicationState) => state.feedback.errors
