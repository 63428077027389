import Flex from 'styled-flex-component'

import BrandFansGroupDetails from '@components/BrandFans/Group/Details'
import BrandFansGroupDetailsTitleBar from '@components/BrandFans/Group/Details/TitleBar'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import { ModalProvider } from '@context/Modal'
import { ToastContainer } from '@tribegroup'

const BrandFanGroupPage = () => (
  <MainLayout>
    <ModalProvider>
      <ToastContainer />
      <HeadTag id="core.text.brandFans" />
      <BrandFansGroupDetailsTitleBar />
      <Flex>
        <Flex column full>
          <BrandFansGroupDetails />
        </Flex>
      </Flex>
    </ModalProvider>
  </MainLayout>
)

export { BrandFanGroupPage }
export default BrandFanGroupPage
