import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { BlockText } from '@components/Campaign/Preview/Block/Block.styled'
import { ToastTemplateTicked } from '@components/UI/ToastTemplate'
import { HOW_TO_USE_AUTH_CODE } from '@constants'
import useCopyToClipboard from '@hooks/useCopyToClipboard'
import { IApplicationState } from '@store/index'
import { selectTransactedBrandedContentAdAuthCode } from '@store/submissions'
import Theme from '@theme'
import { Container, Header, Link, Text, Toaster } from '@tribegroup'

interface IProps {
  submissionId: number
}

const AuthCode = ({ submissionId }: IProps) => {
  const [, copy] = useCopyToClipboard()
  const { authCode } = useSelector((state: IApplicationState) => {
    return {
      authCode: selectTransactedBrandedContentAdAuthCode(state, submissionId),
    }
  })

  const onCopy = (code) => {
    copy(code)
    Toaster.createToast(
      ToastTemplateTicked,
      {
        i18nKey: 'submission.bca.rights.accepted.authCode.toast.copied',
      },
      false,
      { timeout: 5000 },
    )
  }

  return (
    <Container bottomOuterSpacing={1}>
      <Header cta color={Theme.grey700Color} uppercase>
        <FormattedMessage id="brandedContentAds.modal.authorizationCode" />
      </Header>
      <Container wordBreak cursor="pointer">
        <BlockText
          customFontSize={1}
          color={Theme.grey900Color}
          data-tooltip-id="base-tooltip"
          data-tooltip-content="Copy"
          truncated
          onClick={() => onCopy(authCode)}
        >
          {authCode}
        </BlockText>
        <Link href={HOW_TO_USE_AUTH_CODE} target="_blank">
          <Text color={Theme.primaryColor} customFontSize={0.75}>
            <FormattedMessage id="brandedContentAds.modal.authorizationCode.howToUse" />
          </Text>
        </Link>
      </Container>
    </Container>
  )
}

export default AuthCode
