import React from 'react'

import { FormattedHTMLMessage } from 'react-intl'

import groupEmptyx1 from '@images/group-empty.png'
import groupEmptyx2 from '@images/group-empty@2x.png'
import groupEmptyx3 from '@images/group-empty@3x.png'
import Theme from '@theme'
import { Container, H4 } from '@tribegroup'
import { EmptyContainer, GroupImageWrapper } from './Groups.styled'

const BrandFansInfluencerIdentityGroupsEmpty: React.FC = () => (
  <EmptyContainer column data-testid="brand-fan-groups-list-empty">
    <H4 color={Theme.grey800Color}>
      <FormattedHTMLMessage id="brandFans.identityGroups.empty.subtitle" />
    </H4>
    <Container topOuterSpacing={6}>
      <GroupImageWrapper justifyCenter alignCenter>
        <img
          width={140}
          height={100}
          src={groupEmptyx1}
          srcSet={`${groupEmptyx1} 300w, ${groupEmptyx2} 768w, ${groupEmptyx3} 1280w`}
          alt="empty-groups-illustration"
        />
      </GroupImageWrapper>
    </Container>
  </EmptyContainer>
)

export { BrandFansInfluencerIdentityGroupsEmpty }
export default BrandFansInfluencerIdentityGroupsEmpty
