import { InfluencerSubmissionsActionTypes, ISubmission } from '../types'

export const fetchInfluencerSubmissionsRequest = (
  submissionId: number,
  influencerId: number,
  campaignId: number,
) => ({
  type: InfluencerSubmissionsActionTypes.FETCH_REQUEST,
  payload: {
    submissionId,
    influencerId,
    campaignId,
  },
})

export const fetchInfluencerSubmissionsSuccess = (
  influencerId: number,
  campaignId: number,
  influencerSubmissions: ReadonlyArray<ISubmission>,
) => ({
  type: InfluencerSubmissionsActionTypes.FETCH_SUCCESS,
  payload: {
    influencerId,
    campaignId,
    influencerSubmissions,
  },
})

export const fetchInfluencerSubmissionsError = (error: any) => ({
  payload: error,
  type: InfluencerSubmissionsActionTypes.FETCH_ERROR,
})
