import { Fragment } from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import Flex from 'styled-flex-component'

import AnalyticsPrePurchaseBreakdown from '@components/Analytics/Breakdown/PrePurchaseBreakdown'
import AnalyticsPrePurchase from '@components/Analytics/PrePurchase'
import AnalyticsPrePurchaseLegacy from '@components/Analytics/PrePurchase/Legacy'
import { InfluencerIdentityLabelsPreviouslyPurchased } from '@components/Inbox/InfluencerIdentity/Labels'
import Avatar from '@components/UI/Avatar'
import { FeatureToggles } from '@enums'
import { useGender } from '@hooks/useGender'
import { IApplicationState } from '@store'
import { selectCampaignBrandName } from '@store/campaigns'
import { selectCampaignId, selectSubmissionId } from '@store/router'
import {
  selectIsPreviouslyPurchased,
  selectIsSubmissionFromInstagram,
  selectIsSubmissionFromPinterest,
  selectIsSubmissionFromTiktok,
  selectIsSubmissionFromX,
  selectPrePurchaseLink,
  selectSubmission,
} from '@store/submissions'
import Theme from '@theme'
import { Container, H3, H5, Icon, Link, Text } from '@tribegroup'
import { OffPlatformSubmissionMetricsUnavailableNotice } from './OffPlatformMetricUnavailableNotice'
import { IconWrapper, ProfileDetailsWrapper, ProfileWrapper } from './Profile.styled'

const InfluencerAnalytics: React.FC<{ submissionId: number }> = ({ submissionId }) => {
  const isMetricConsolidationEnabled = useFlag(FeatureToggles.METRIC_CONSOLIDATION_ENABLED)
  const {
    isSubmissionFromInstagram,
    isSubmissionFromPinterest,
    isSubmissionFromTikTok,
    isSubmissionFromX,
    hasPrePurchaseLink,
    submission,
  } = useSelector((state: IApplicationState) => ({
    submission: selectSubmission(state, submissionId),
    isSubmissionFromInstagram: selectIsSubmissionFromInstagram(state, submissionId),
    isSubmissionFromTikTok: selectIsSubmissionFromTiktok(state, submissionId),
    isSubmissionFromPinterest: selectIsSubmissionFromPinterest(state, submissionId),
    isSubmissionFromX: selectIsSubmissionFromX(state, submissionId),
    hasPrePurchaseLink: Boolean(selectPrePurchaseLink(state, submissionId)),
  }))

  if (submission.proxy_submission || submission.is_proxy) {
    return <OffPlatformSubmissionMetricsUnavailableNotice type="prePurchase" />
  }

  if (
    isMetricConsolidationEnabled &&
    (isSubmissionFromInstagram || isSubmissionFromTikTok || isSubmissionFromX)
  ) {
    return <AnalyticsPrePurchaseBreakdown submissionId={submissionId} />
  }

  const isAnalyticsPrePurchaseNew =
    (isSubmissionFromInstagram && hasPrePurchaseLink) ||
    isSubmissionFromTikTok ||
    isSubmissionFromPinterest

  if (isAnalyticsPrePurchaseNew) {
    return <AnalyticsPrePurchase />
  }

  return <AnalyticsPrePurchaseLegacy />
}

const SubmissionInfluencerProfile = () => {
  const match = useRouteMatch()
  const submissionId = selectSubmissionId(match) as number
  const campaignId = selectCampaignId(match) as number
  const { brandName, isPreviouslyPurchased, submission } = useSelector(
    (state: IApplicationState) => ({
      submission: selectSubmission(state, submissionId),
      brandName: selectCampaignBrandName(state, campaignId),
      isPreviouslyPurchased: selectIsPreviouslyPurchased(state, submissionId),
    }),
  )
  const { findGender } = useGender()
  const hasCountryAndState = submission.influencer_country && submission.influencer_state

  const selectedGender = findGender(submission?.influencer_gender)
  const genderDisplay = selectedGender?.display_name

  const hasAgeAndGender = submission.influencer_age && genderDisplay
  const showMidDot =
    (submission.influencer_country || submission.influencer_state) &&
    (submission.influencer_age || genderDisplay)

  return (
    <ProfileWrapper>
      <Flex alignCenter justifyBetween>
        <H3 color={Theme.grey900Color}>
          <FormattedMessage id="submission.influencer.header" />
        </H3>
        <Link
          href={submission.identity_social_page_url}
          target="_blank"
          color={Theme.secondaryColor}
        >
          <Icon glyph={`social-${submission.identity_provider}-colour`} size={1.25} />
        </Link>
      </Flex>
      <ProfileDetailsWrapper>
        <Container rightOuterSpacing={1}>
          <Avatar
            size={4.5}
            avatarSrc={submission.identity_avatar}
            username={submission.identity_username}
          />
        </Container>
        <div>
          <Flex alignCenter>
            <H5 rightOuterSpacing={0.375} color={Theme.grey900Color}>
              {submission.influencer_full_name}
            </H5>
            {isPreviouslyPurchased && (
              <InfluencerIdentityLabelsPreviouslyPurchased brandName={brandName!} />
            )}
          </Flex>
          <div>
            <Text color={Theme.grey700Color} capitalize light data-influencer-location>
              {hasCountryAndState
                ? `${submission.influencer_state}, `
                : submission.influencer_state}
              {submission.influencer_country}
            </Text>
            <Text color={Theme.grey700Color}>{showMidDot && <Fragment> &middot; </Fragment>}</Text>
            <Text color={Theme.grey700Color} capitalize light data-influencer-gender>
              {hasAgeAndGender ? `${genderDisplay}, ` : genderDisplay}
            </Text>
            <Text color={Theme.grey700Color} light>
              {submission.influencer_age && (
                <FormattedMessage
                  id="submission.profile.age"
                  values={{ age: submission.influencer_age }}
                />
              )}
            </Text>
          </div>
          {Boolean(submission.influencer_rating) && (
            <Flex>
              <Text data-influencer-rating color={Theme.grey700Color}>
                {submission.influencer_rating}
              </Text>
              <IconWrapper alignCenter justifyCenter>
                <Icon glyph="star-rating" color={Theme.alertColor} size={0.8} />
              </IconWrapper>
            </Flex>
          )}
        </div>
      </ProfileDetailsWrapper>
      <InfluencerAnalytics submissionId={submissionId} />
    </ProfileWrapper>
  )
}

export { SubmissionInfluencerProfile }
export default SubmissionInfluencerProfile
