import styled from 'styled-components'

export const ThumbnailImageWrapper = styled.div`
  position: relative;
`

export const ThumbnailImageOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: rgb(96 96 96 / 30%);
`

export const ThumbnailImageIconWrapper = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
`
