import React, { PropsWithChildren } from 'react'

import Theme from '@theme'
import { bodyScrollHandler, H5, Text } from '@tribegroup'
import {
  DRAWER_ANIMATION_DURATION_MS,
  DrawerContent,
  DrawerGrayWrapper,
  DrawerHeader,
  DrawerHeaderContent,
  DrawerHeaderDivider,
  DrawerHeaderWrapper,
  DrawerStyled,
  DrawerWrapperStyled,
} from './Drawer.styled'

interface IDrawerProps {
  id?: string
  open?: boolean
  title: React.ReactNode
  headerLeftIcon?: React.ReactNode
  headerRightIcon?: React.ReactNode
  showHeaderDivider?: boolean
  width?: number
  wrapperCallback?: (event: Event) => void
}

interface IDrawerState {
  shouldRender: boolean
}

export class Drawer extends React.Component<PropsWithChildren<IDrawerProps>, IDrawerState> {
  static defaultProps = {
    id: 'drawer',
    open: false,
    showHeaderDivider: false,
    width: 20.75,
  }

  state = {
    shouldRender: false,
  }

  private internalId = `modal-${Date.now()}`

  setShouldRender = () => {
    this.setState({
      shouldRender: Boolean(this.props.open),
    })
  }

  componentDidMount() {
    this.setShouldRender()
    bodyScrollHandler.set(this.internalId, this.props.open)
  }

  componentWillUnmount() {
    bodyScrollHandler.remove(this.internalId)
  }

  componentDidUpdate(prevProps) {
    const open = this.props.open
    if (open !== prevProps.open) {
      if (!open) {
        setTimeout(this.setShouldRender, DRAWER_ANIMATION_DURATION_MS)
      } else {
        this.setShouldRender()
      }
    }
    bodyScrollHandler.set(this.internalId, open)
  }

  render() {
    const {
      id,
      title,
      headerLeftIcon,
      headerRightIcon,
      children,
      open,
      showHeaderDivider,
      wrapperCallback,
      width,
    } = this.props

    const { shouldRender } = this.state

    return (
      shouldRender && (
        <DrawerWrapperStyled open={open}>
          <DrawerGrayWrapper
            data-cy-element={`${id}-wrapper`}
            open={open}
            onClick={wrapperCallback}
          />
          <DrawerStyled data-cy-element={id} open={open} width={width}>
            <DrawerHeader>
              <DrawerHeaderWrapper justifyBetween alignCenter>
                <DrawerHeaderContent left basis="2.5rem">
                  <Text>{headerLeftIcon}</Text>
                </DrawerHeaderContent>
                <DrawerHeaderContent>
                  <H5 color={Theme.grey900Color}>{title}</H5>
                </DrawerHeaderContent>
                <DrawerHeaderContent right basis="2.5rem">
                  {headerRightIcon}
                </DrawerHeaderContent>
              </DrawerHeaderWrapper>
              {showHeaderDivider && <DrawerHeaderDivider />}
            </DrawerHeader>
            <DrawerContent>{children}</DrawerContent>
          </DrawerStyled>
        </DrawerWrapperStyled>
      )
    )
  }
}

export default Drawer
