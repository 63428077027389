import { IApplicationState } from '@store'
import { IAnyMediaTransferRequestParams } from '@store/facebookAds'
import { selectUploadToFacebookLink as selectMediaObjectUploadToFacebookLink } from '@store/mediaObjects'
import { selectUploadToFacebookLink as selectSubmissionUploadToFacebookLink } from '@store/submissions'

export const selectUploadToFacebookLink = (
  state: IApplicationState,
  params: Partial<IAnyMediaTransferRequestParams>,
) => {
  const { submissionId, mediaObjectId } = params
  if (mediaObjectId) {
    return selectMediaObjectUploadToFacebookLink(state, mediaObjectId)
  }
  return selectSubmissionUploadToFacebookLink(state, submissionId as number)
}
