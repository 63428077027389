import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { SubmissionStatuses } from '@enums'
import { useInboxFilter } from '@hooks/useInboxFilter'
import { IApplicationState } from '@store'
import {
  selectMediaObjectsHaveLicenseOptions,
  selectMediaObjectsHaveTransactedLicense,
} from '@store/mediaObjects'
import {
  selectApproveLink,
  selectDeclineLink,
  selectFeedbackLink,
  selectPreApproveLink,
  selectRescheduleLink,
  selectShortlistLink,
  selectSubmission,
  selectSubmissionMediaObjectIds,
  selectUnshortlistLink,
} from '@store/submissions'
import SubmissionCardActionBarSocialApproved from './Approved'
import SubmissionCardActionBarSocialDeclined from './Declined'
import SubmissionCardActionBarSocialInbox from './Inbox'
import SubmissionCardActionBarSocialLicenseRequests from './LicenseRequests'
import SubmissionCardActionBarSocialPreApproved from './PreApproved'

interface IProps {
  submissionId: number
  campaignId: number
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

const SubmissionCardActionBarSocial: React.FC<IInternalProps> = ({
  campaignId,
  hasShortlistLink,
  hasUnshortlistLink,
  hasDeclineLink,
  hasFeedbackLink,
  hasApproveLink,
  hasPreApproveLink,
  submission,
  hasRescheduleLink,
  showLicenseIcon,
}) => {
  const { currentFilter } = useInboxFilter()

  if (submission.status === SubmissionStatuses.Pending) {
    return (
      <SubmissionCardActionBarSocialInbox
        hasRescheduleLink={hasRescheduleLink}
        submission={submission}
        campaignId={campaignId}
        hasShortlistLink={hasShortlistLink}
        hasUnshortlistLink={hasUnshortlistLink}
        hasDeclineLink={hasDeclineLink}
        hasFeedbackLink={hasFeedbackLink}
        hasApproveLink={hasApproveLink}
        hasPreApproveLink={hasPreApproveLink}
      />
    )
  }

  if (submission.status === SubmissionStatuses.PreApproved) {
    return (
      <SubmissionCardActionBarSocialPreApproved
        submission={submission}
        campaignId={campaignId}
        hasDeclineLink={hasDeclineLink}
        hasFeedbackLink={hasFeedbackLink}
        hasApproveLink={hasApproveLink}
      />
    )
  }

  if (
    [SubmissionStatuses.LicenseRequest, SubmissionStatuses.Licensed].includes(
      currentFilter as SubmissionStatuses,
    )
  ) {
    return (
      <SubmissionCardActionBarSocialLicenseRequests
        campaignId={campaignId}
        submission={submission}
        hasFeedbackLink={hasFeedbackLink}
      />
    )
  }

  if (currentFilter === SubmissionStatuses.Declined) {
    return (
      <SubmissionCardActionBarSocialDeclined
        campaignId={campaignId}
        showLicenseIcon={showLicenseIcon}
        submission={submission}
        hasFeedbackLink={hasFeedbackLink}
      />
    )
  }

  return (
    <SubmissionCardActionBarSocialApproved
      campaignId={campaignId}
      submission={submission}
      hasRescheduleLink={hasRescheduleLink}
      hasFeedbackLink={hasFeedbackLink}
      showLicenseIcon={showLicenseIcon}
    />
  )
}

const mapStateToProps = (state: IApplicationState, { submissionId }: IProps) => {
  const submission = selectSubmission(state, submissionId)

  const mediaObjectIds = selectSubmissionMediaObjectIds(state, submissionId)
  const hasLicenseOptionsLink = selectMediaObjectsHaveLicenseOptions(state, mediaObjectIds)

  const hasTransactedLicense = selectMediaObjectsHaveTransactedLicense(state, mediaObjectIds)

  const showLicenseIcon = hasTransactedLicense || hasLicenseOptionsLink
  return {
    submission,
    showLicenseIcon,
    hasApproveLink: Boolean(selectApproveLink(state, submissionId)),
    hasDeclineLink: Boolean(selectDeclineLink(state, submissionId)),
    hasFeedbackLink: Boolean(selectFeedbackLink(state, submissionId)),
    hasRescheduleLink: Boolean(selectRescheduleLink(state, submissionId)),
    hasShortlistLink: Boolean(selectShortlistLink(state, submissionId)),
    hasUnshortlistLink: Boolean(selectUnshortlistLink(state, submissionId)),
    hasPreApproveLink: Boolean(selectPreApproveLink(state, submissionId)),
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(connector)(SubmissionCardActionBarSocial)
