import styled from 'styled-components'

export const InfluencerIdentityRowDetailsRoot = styled.div`
  white-space: nowrap;
`

export const EllipsisWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ color }) => color};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}rem;`}
`
