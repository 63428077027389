import { PCRChannels } from '@enums'
import { IPostMetricsResponse, PostMetricsActionTypes } from '@store/postCampaignReport'

export const fetchPostMetrics = (campaignId: number, channel: PCRChannels) => ({
  payload: {
    campaignId,
    channel,
  },
  type: PostMetricsActionTypes.FETCH_REQUEST,
})

export const fetchPostMetricsSuccess = (
  postMetrics: IPostMetricsResponse,
  campaignId: number,
  channel: PCRChannels,
) => ({
  payload: {
    postMetrics,
    campaignId,
    channel,
  },
  type: PostMetricsActionTypes.FETCH_SUCCESS,
})

export const fetchPostMetricsError = error => ({
  payload: error,
  type: PostMetricsActionTypes.FETCH_ERROR,
})
