import { FormEvent, useContext, useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { LicensingAgreementsLink } from '@components/Licensing'
import StripeError from '@components/UI/Error/StripeError'
import Loading from '@components/UI/Loading'
import { CampaignContext } from '@context/Campaign'
import { useModal } from '@hooks/useModal'
import { ApprovalType } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import {
  fetchMediaObjectLicenseOptions,
  ILicense,
  selectIsPurchasingOrRequestingLicense,
  selectMediaObjectLicenseOptions,
} from '@store/licenses'
import {
  purchaseLicense,
  selectFirstErrorCode,
  selectHasInsufficientFundError,
  selectMediaObjectIsFetching,
} from '@store/mediaObjects'
import { selectPreapprovalAmountPercentage } from '@store/rootResource'
import { selectIsInboxView } from '@store/router'
import {
  selectActiveSubmissionMediaObject,
  selectIsPriceHidden,
  selectSubmission,
} from '@store/submissions'
import { clearUIFlag, selectUIFlag, UIFlag } from '@store/ui'
import Theme from '@theme'
import { CloseIcon, Container, Modal, Text } from '@tribegroup'
import { isEmpty } from '@utils/isEmpty'
import { SubmissionCardModalLicenseMediaDetails } from './Aside/MediaDetails'
import { SubmissionCardModalLicenseOptions } from './Aside/Options'
import { SubmissionCardModalLicenseSelected } from './Aside/Selected'
import { SubmissionCardModalLicenseButton } from './Button'
import { SubmissionCardModalLicenseHeader } from './Header'
import {
  ModalLicenseHeaderWrapper,
  ModalLicenseLicenseOptionsWrapper,
  ModalLicensePriceSummaryWrapper,
  ModalLicenseWrapper,
} from './License.styled'
import { SubmissionCardModalLicensePriceSummary } from './PriceSummary'

interface IProps {
  submissionId: number
  frameNumber: number
  approvalType?: ApprovalType
}

export const SubmissionCardModalLicense = ({ submissionId, frameNumber, approvalType }: IProps) => {
  const { isOpen, hideModal } = useModal()
  const dispatch = useDispatch()

  const {
    campaign: { id: campaignId },
  } = useContext(CampaignContext)

  const [selectedLicenseIndex, setSelectedLicenseIndex] = useState(0)

  const {
    activeMediaObject,
    licenseOptions,
    isFetching,
    isPurchasingLicense,
    isInboxView,
    shouldHideModal,
    preapprovalPercentage,
    transactedLicense,
    submission,
    hasInsufficientFundError,
    errorCode,
    showPriceSummary,
  } = useSelector((state: IApplicationState) => {
    const activeMediaObject = selectActiveSubmissionMediaObject(state, submissionId, frameNumber)
    const activeMediaObjectId = activeMediaObject.id

    let licenseOptions = selectMediaObjectLicenseOptions(state, activeMediaObjectId)

    if (approvalType === 'pre-approval') {
      licenseOptions = licenseOptions.filter((option) => option?.preapproval)
    } else {
      licenseOptions = licenseOptions.filter((option) => !option?.preapproval)
    }

    return {
      activeMediaObject,
      licenseOptions,
      isFetching: selectMediaObjectIsFetching(state),
      isPurchasingLicense: selectIsPurchasingOrRequestingLicense(state),
      isInboxView: selectIsInboxView(state),
      shouldHideModal: selectUIFlag(state, UIFlag.HIDE_MODAL),
      preapprovalPercentage: selectPreapprovalAmountPercentage(state),
      transactedLicense: activeMediaObject?.transacted_license,
      submission: selectSubmission(state, submissionId),
      hasInsufficientFundError: selectHasInsufficientFundError(state),
      errorCode: selectFirstErrorCode(state),
      showPriceSummary: !selectIsPriceHidden(state, submissionId),
    }
  })

  useEffect(() => {
    dispatch(fetchMediaObjectLicenseOptions(activeMediaObject?.id))
  }, [])

  useEffect(() => {
    if (shouldHideModal) {
      hideModal()
      dispatch(clearUIFlag(UIFlag.HIDE_MODAL))
    }
  }, [shouldHideModal])

  if (isFetching && isEmpty(licenseOptions)) {
    return (
      <Modal reactModalProps={{ isOpen: Boolean(isOpen) }} width={55} padding={0}>
        <Loading />
      </Modal>
    )
  }

  if (isEmpty(licenseOptions)) {
    return <></>
  }

  const selectLicenseOption = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    const index = parseInt(target.getAttribute('data-license-index')!, 10)
    setSelectedLicenseIndex(index)
  }

  const onClick = () => {
    dispatch(
      purchaseLicense(activeMediaObject?.id, selectedLicenseIndex, campaignId, submissionId, {
        refreshSubmissions: isInboxView,
        purchaseType: approvalType,
      }),
    )
  }

  return (
    <Modal reactModalProps={{ isOpen: Boolean(isOpen) }} width={55} padding={0} mobilePadding={0}>
      <CloseIcon onClick={hideModal} />
      <ModalLicenseWrapper>
        <ModalLicenseHeaderWrapper topOuterSpacing={3} rightOuterSpacing={3} leftOuterSpacing={1.5}>
          <SubmissionCardModalLicenseHeader
            approvalType={approvalType}
            preapprovalPercentage={preapprovalPercentage}
            submission={submission}
          />
        </ModalLicenseHeaderWrapper>

        <ModalLicenseLicenseOptionsWrapper>
          <Container bottomOuterSpacing={1.25} leftOuterSpacing={3}>
            <Text
              uppercase
              customFontSize={0.8125}
              lineHeight={1.23}
              color={Theme.grey900Color}
              customletterSpacing={'1.5px'}
            >
              {transactedLicense ? (
                <FormattedMessage id="socialSubmission.card.modal.license.header.selectedLicense" />
              ) : (
                <FormattedMessage id="socialSubmission.card.modal.license.header.licenseOptions" />
              )}
            </Text>
          </Container>

          {transactedLicense ? (
            <SubmissionCardModalLicenseSelected transactedLicense={transactedLicense} />
          ) : (
            <SubmissionCardModalLicenseOptions
              licenseOptions={licenseOptions}
              onChange={selectLicenseOption}
            />
          )}

          <Container leftOuterSpacing={3} topOuterSpacing={2}>
            <SubmissionCardModalLicenseMediaDetails mediaObject={activeMediaObject} />
          </Container>
        </ModalLicenseLicenseOptionsWrapper>
        <ModalLicensePriceSummaryWrapper
          rightOuterSpacing={3}
          leftOuterSpacing={1.5}
          bottomOuterSpacing={3}
        >
          {showPriceSummary && (
            <SubmissionCardModalLicensePriceSummary
              license={licenseOptions[selectedLicenseIndex] as ILicense}
              approvalType={approvalType}
              submission={submission}
              preapprovalPercentage={preapprovalPercentage}
            />
          )}

          <Container topOuterSpacing={2.5}>
            <Container bottomOuterSpacing={1.5}>
              {!hasInsufficientFundError && errorCode && <StripeError errorCode={errorCode} />}
            </Container>
            <SubmissionCardModalLicenseButton
              onClick={onClick}
              disabled={isPurchasingLicense}
              loading={isPurchasingLicense}
              approvalType={approvalType}
              preapprovalPercentage={preapprovalPercentage}
            />
            <LicensingAgreementsLink color={Theme.primaryColor} />
          </Container>
        </ModalLicensePriceSummaryWrapper>
      </ModalLicenseWrapper>
    </Modal>
  )
}
