export const abbreviateNumber = (value: number) => {
  if (value < 1000) {
    return String(value)
  }

  const suffixes: ReadonlyArray<string> = ['', 'k', 'm', 'b', 't']
  const tens = Math.pow(10, 1)

  return (
    suffixes
      .map((suffix, index) => {
        const suffixBaseValue = Math.pow(10, index * 3)
        const baseValue = (value * tens) / suffixBaseValue
        const roundedBaseValue = Math.floor(baseValue)
        const roundedValue = roundedBaseValue / tens
        return roundedValue >= 1 ? roundedValue + suffix : null
      })
      .reduce((accum, curr) => curr || accum) || 0
  )
}

export const abbreviateNumberOrDash = (value?: number | null) => {
  if (value === undefined || value === null) {
    return '-'
  }
  return abbreviateNumber(value)
}

export default abbreviateNumber
