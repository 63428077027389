import { createSelector } from 'reselect'

import { EMPTY_LIST } from '@constants'
import { ErrorCodes, MediaTypes } from '@enums'
import { IApplicationState, IHateoasLink } from '@store'
import { ILicenseOption } from '@store/campaigns'
import { isEmpty } from '@utils'

export const selectIsPurchasing = (state: IApplicationState) => state.licenses.isPurchasing

export const selectIsRequestingLicense = (state: IApplicationState) =>
  state.licenses.isRequestingLicense

export const selectIsPurchasingOrRequestingLicense = createSelector(
  selectIsPurchasing,
  selectIsRequestingLicense,
  (isPurchasing: boolean, isRequestingLicense: boolean) => isPurchasing || isRequestingLicense,
)

export const selectErrors = (state: IApplicationState) => state.licenses.errors

export const selectErrorMessages = (state: IApplicationState) => {
  const error = selectErrors(state)
  return error && error.messages
}

export const selectIsFetching = (state: IApplicationState) => state.licenses.isFetching

export const selectErrorMessage = (state: IApplicationState) => {
  const errorMessages = selectErrorMessages(state)

  if (isEmpty(errorMessages)) {
    return undefined
  }

  if (typeof errorMessages === 'string') {
    return errorMessages
  }

  return errorMessages && errorMessages[0].message
}

export const selectErrorCode = (state: IApplicationState) => {
  const errorMessages = selectErrorMessages(state)

  if (isEmpty(errorMessages) || typeof errorMessages === 'string') {
    return undefined
  }

  return errorMessages && errorMessages[0].error_code
}

export const selectHasInsufficientFundsForLicense = createSelector(
  selectErrorCode,
  (licenseErrorCode: string) =>
    [
      ErrorCodes.ERROR_CODE_LICENSE_INSUFFICIENT_FUNDS,
      ErrorCodes.ERROR_CODE_LICENSE_NOT_ENOUGH_BUDGET,
    ].includes(licenseErrorCode as ErrorCodes),
)

export const selectMediaObjectLicenseOptions = (state: IApplicationState, mediaObjectId: number) =>
  state.licenses.licenseOptionsByMediaObjectId[mediaObjectId] || EMPTY_LIST

export const selectMediaObjectLicenseOptionsByMediaType = createSelector(
  selectMediaObjectLicenseOptions,
  (state: IApplicationState, mediaObjectId: number, mediaType: MediaTypes) => mediaType,
  (licenseOptions, mediaType) => licenseOptions.filter((option) => option.media_type === mediaType),
)

export const selectMediaObjectLicenseOption = createSelector(
  selectMediaObjectLicenseOptions,
  (state, mediaObjectId, licenseIndex) => licenseIndex,
  (licenseOptions: ReadonlyArray<ILicenseOption>, licenseIndex: number) =>
    licenseOptions[licenseIndex] || ({} as ILicenseOption),
)

export const selectLicenseOptionAmountBeforeTaxCents = createSelector(
  selectMediaObjectLicenseOption,
  (licenseOption: ILicenseOption) => licenseOption.total_amount_before_tax_cents,
)

export const selectLicenseOptionAmountBeforeTaxCurrency = createSelector(
  selectMediaObjectLicenseOption,
  (licenseOption: ILicenseOption) => licenseOption.total_amount_before_tax_currency,
)

export const selectLicenseOptionTaxDisplayName = createSelector(
  selectMediaObjectLicenseOption,
  (licenseOption: ILicenseOption) => licenseOption.tax_display_name,
)

export const selectLicenseOptionLicenseLink = createSelector(
  selectMediaObjectLicenseOption,
  (licenseOption: ILicenseOption) =>
    licenseOption?.links?.find((link) => link.rel === 'request_license_request_purchase3ds'),
)

export const selectPurchaseLicenseOptionLink = createSelector(
  selectMediaObjectLicenseOption,
  (licenseOption: ILicenseOption) =>
    licenseOption?.links?.find((link) => link.rel === 'request_purchase3ds'),
)

export const selectPreApprovePurchaseLicenseOptionLink = createSelector(
  selectMediaObjectLicenseOptions,
  (state, mediaObjectId, licenseIndex) => licenseIndex,

  (options: ILicenseOption[], licenseIndex) => {
    const licenseOptions = options.filter((option) => option?.preapproval)
    const selectedLicenseOption = licenseOptions[licenseIndex]
    return selectedLicenseOption?.links?.find((link) => link?.rel === 'request_purchase3ds')
  },
)

export const selectHasRequestOrPurchaseLicenseLinkUsingMediaObjectId = createSelector(
  selectPurchaseLicenseOptionLink,
  selectLicenseOptionLicenseLink,
  (purchaseLicenseLink: IHateoasLink, requestLicenseLink: IHateoasLink) =>
    Boolean(purchaseLicenseLink || requestLicenseLink),
)

export const selectLicenseOptionLabel = createSelector(
  selectMediaObjectLicenseOption,
  (licenseOption: ILicenseOption) => licenseOption.label,
)

export const selectLicenseOptionDescription = createSelector(
  selectMediaObjectLicenseOption,
  (licenseOption: ILicenseOption) => licenseOption.description,
)

export const selectMinimumLicenseAmount = createSelector(
  selectMediaObjectLicenseOptions,
  (licenseOptions: ReadonlyArray<ILicenseOption>) =>
    licenseOptions.length
      ? Math.min(
          ...licenseOptions.map((licenseOption) => licenseOption.total_amount_before_tax_cents),
        )
      : undefined,
)
