import styled from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'

const IconWrapper = styled(Flex)`
  width: 2.5rem;
  height: 2.5rem;
  margin: auto;
  background: ${Theme.whiteColor};
  border-radius: 100%;
`

export { IconWrapper }
