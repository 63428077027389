import { useSelector } from 'react-redux'

import { selectTenantFeaturesByKey } from '@store/rootResource'

const useTenantEnabledFeatures = () => {
  const tenantFeaturesByKey = useSelector(selectTenantFeaturesByKey)
  const isEnabledTenantFeature = (featureKey: string) => tenantFeaturesByKey[featureKey] ?? true
  return {
    isEnabledTenantFeature,
  }
}

export { useTenantEnabledFeatures }
