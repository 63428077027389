import React from 'react'

import { FormattedMessage } from 'react-intl'

import { CampaignMenuActionsContext } from '@components/Campaign/Menu'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import Theme from '@theme'
import { DropdownMenu, Icon, MenuItem, MenuItemLink } from '@tribegroup'
import CampaignCardMoreMenuStyled from './MoreMenu.styled'

const CampaignCardMoreMenu: React.FC = () => {
  const {
    campaignId,
    hasActivateLink,
    editLink,
    hasPauseLink,
    onOpenConfirmModal,
    onOpenPauseModal,
  } = React.useContext(CampaignMenuActionsContext)
  return (
    <CampaignCardMoreMenuStyled>
      <DropdownMenu
        target={
          <Icon
            key={campaignId}
            glyph="more"
            size={1}
            color={Theme.grey700Color}
            className="card-menu-toggle"
          />
        }
        indicatorSpace={0}
        right={-1.25}
        bottom={1.6875}
        elementName="drop-down-menu"
      >
        {hasActivateLink && (
          <MenuItem onClick={onOpenConfirmModal} elementName="activate-campaign">
            <FormattedMessage id="campaign.campaignCard.menuAction.text.activateCampaign" />
          </MenuItem>
        )}
        {hasPauseLink && (
          <MenuItem onClick={onOpenPauseModal} elementName="pause-campaign">
            <FormattedMessage id="campaign.campaignCard.menuAction.text.pauseCampaign" />
          </MenuItem>
        )}
        {Boolean(editLink) && (
          <ConnectedTrackedRoute
            eventName={EventTrackingNames.CampaignBuilderEditCampaign}
            campaignId={campaignId}
          >
            <MenuItemLink to={editLink} elementName="edit-campaign">
              <FormattedMessage id="campaign.campaignCard.menuAction.text.editCampaign" />
            </MenuItemLink>
          </ConnectedTrackedRoute>
        )}
      </DropdownMenu>
    </CampaignCardMoreMenuStyled>
  )
}

export { CampaignCardMoreMenu }
export default CampaignCardMoreMenu
