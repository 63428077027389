/* eslint-disable react/display-name */
import { CampaignListFilterContext } from '@context/CampaignListFilter'

export const withCampaignListFilter = (Component) => (props) => {
  return (
    <CampaignListFilterContext.Consumer>
      {(contextProps) => <Component {...props} {...contextProps} />}
    </CampaignListFilterContext.Consumer>
  )
}
