import React from 'react'

import styled, { css } from 'styled-components'

import { Icon } from '../../Icon'
import { themed } from '../../Theme'

const CloseIconStyled = themed(styled(Icon)`
  ${({ theme, top, right, color }) => css`
    cursor: pointer;
    position: absolute;
    top: ${top}rem;
    right: ${right}rem;

    svg g {
      fill: ${color ?? theme.defaultColor};
    }
  `};
`)

export interface ICloseIconProps {
  onClick?: (event: React.SyntheticEvent) => void
  size?: number
  top?: number
  right?: number
  color?: string
}

const CloseIcon = ({ onClick, size = 1.25, top = 0.75, right = 0.75, color }: ICloseIconProps) => (
  <CloseIconStyled
    glyph="close-x"
    size={size}
    onClick={onClick}
    data-cy-element="close-icon"
    data-testid="close-icon"
    top={top}
    right={right}
    color={color}
  />
)

export default CloseIcon
