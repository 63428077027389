import React from 'react'

import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container, H4 } from '@tribegroup'
import { ToggleButton } from './Switch.styled'

interface IValues {
  off: string
  on: string
}

interface IProps {
  defaulValue?: boolean
  values: IValues
  onChange: (value: boolean) => void
}

const Switch: React.FC<IProps> = ({ defaulValue = false, values, onChange }) => {
  const [isOn, setIsOn] = React.useState(defaulValue)

  const onClick = () => {
    const newValue = !isOn
    setIsOn(newValue)
    onChange(newValue)
  }

  return (
    <Container
      width={4.25}
      height={2}
      pointer
      fullWidth
      backgroundColor={Theme.grey300Color}
      borderRadius={1}
      onClick={onClick}
      relative
    >
      <Flex full justifyBetween>
        <Container width={2} height={2}>
          <Flex full center>
            <H4 color={Theme.whiteColor}>{values.off}</H4>
          </Flex>
        </Container>
        <Container width={2} height={2}>
          <Flex full center>
            <H4 color={Theme.whiteColor}>{values.on}</H4>
          </Flex>
        </Container>
      </Flex>
      <ToggleButton
        width={2}
        height={2}
        borderRadius={100}
        backgroundColor={Theme.primaryColor}
        absolute
        leftPosition={!isOn ? 0 : 2.25}
        topPosition={0}
        data-testid="switch-toggle"
      >
        <Flex full justifyCenter alignCenter>
          <H4 color={Theme.whiteColor}>{isOn ? values.on : values.off}</H4>
        </Flex>
      </ToggleButton>
    </Container>
  )
}

export default Switch
