import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { H2, H5 } from '@tribegroup'
import BuilderBudgetTargetDemographicHeaderHeadline from './../Headline'

const BuilderBudgetTargetDemographicHeadline: React.FC = () => (
  <BuilderBudgetTargetDemographicHeaderHeadline textWidth={20}>
    <H5 color={Theme.grey900Color}>
      <FormattedMessage id="builder.budget.demographics.targetCreators.header" />
    </H5>
    <H2 color={Theme.grey900Color}>
      <FormattedMessage id="builder.budget.demographics.targetCreators.creatorCommunity" />
    </H2>
  </BuilderBudgetTargetDemographicHeaderHeadline>
)

export default BuilderBudgetTargetDemographicHeadline
