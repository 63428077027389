/* eslint-disable react/display-name */
import { SubmissionContext } from '@context/Submission'

export const withSubmission = (Component) => (props) => {
  return (
    <SubmissionContext.Consumer>
      {(contextProps) =>
        contextProps.isSubmissionFetched && <Component {...props} {...contextProps} />
      }
    </SubmissionContext.Consumer>
  )
}

export const withSubmissionNoRenderBlock = (Component) => (props) => {
  return (
    <SubmissionContext.Consumer>
      {(contextProps) => <Component {...props} {...contextProps} />}
    </SubmissionContext.Consumer>
  )
}
