import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import PricePanelAmount from '@components/UI/PricePanel/Amount'
import { SupportedSubmissionTypes } from '@enums'
import { IApplicationState } from '@store'
import { ILicenseOption, selectCampaignMinimumLicenseOption } from '@store/campaigns'
import { selectMediaObjectType, selectTransactedLicense } from '@store/mediaObjects'
import { selectPriceBeforePreApproval, selectSubmission } from '@store/submissions'

interface IProps {
  showLicensePrice: boolean
  showLicenseOptionsPrice: boolean
  submissionId: number
  campaignId: number
  submissionType: SupportedSubmissionTypes
  mediaObjectId: number
}

interface IInternalProps extends ConnectedProps<typeof connector>, IProps {}

const getTaxDisplayName = (
  isContentSubmission,
  showLicensePrice,
  minimumLicenseOption,
  transactedLicense,
  submission,
) => {
  if (isContentSubmission) {
    return minimumLicenseOption.tax_display_name
  }
  if (showLicensePrice) {
    return transactedLicense.tax_display_name
  }
  return submission.tax_display_name
}

const SubmissionCardPricePanelAmount: React.FC<IInternalProps> = ({
  submissionType,
  minimumLicenseOption,
  showLicensePrice,
  showLicenseOptionsPrice,
  transactedLicense,
  submission,
  hasPriceChangeSincePreApproval,
}) => {
  const {
    total_amount_before_tax_currency,
    amount_currency,
    total_amount_before_tax_cents,
  } = submission

  const isContentSubmission = submissionType === SupportedSubmissionTypes.ContentSubmission

  const taxDisplayName = getTaxDisplayName(
    isContentSubmission,
    showLicensePrice,
    minimumLicenseOption,
    transactedLicense,
    submission,
  )

  if (showLicenseOptionsPrice) {
    return (
      <PricePanelAmount
        showFrom
        price={minimumLicenseOption.total_amount_before_tax_cents}
        currency={minimumLicenseOption.total_amount_before_tax_currency || amount_currency}
        taxDisplayName={taxDisplayName}
      />
    )
  }

  if (showLicensePrice) {
    return (
      <PricePanelAmount
        price={transactedLicense.total_amount_before_tax_cents || 0}
        currency={transactedLicense.total_amount_before_tax_currency || amount_currency}
        taxDisplayName={taxDisplayName}
      />
    )
  }

  return (
    <PricePanelAmount
      price={total_amount_before_tax_cents || 0}
      currency={total_amount_before_tax_currency || amount_currency}
      taxDisplayName={taxDisplayName}
      hasPriceChanged={hasPriceChangeSincePreApproval}
    />
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { submissionId, mediaObjectId, campaignId }: IProps,
) => {
  const submission = selectSubmission(state, submissionId)
  const mediaObjectMediaType = selectMediaObjectType(state, mediaObjectId)

  const mediaType = submission?.final_media_type ?? mediaObjectMediaType

  return {
    submission: selectSubmission(state, submissionId),
    hasPriceChangeSincePreApproval: Boolean(
      selectPriceBeforePreApproval(state, submissionId, campaignId),
    ),
    minimumLicenseOption: selectCampaignMinimumLicenseOption(
      state,
      campaignId,
      mediaType,
    ) as ILicenseOption,
    transactedLicense: selectTransactedLicense(state, mediaObjectId),
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(connector)(SubmissionCardPricePanelAmount)
