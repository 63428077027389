import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Text } from '@tribegroup'

interface IBrandFansGroupRowDescriptionProps {
  description: string
}
const BrandFansGroupRowDescription: React.FC<IBrandFansGroupRowDescriptionProps> = ({
  description,
}) => (
  <React.Fragment>
    {description ? (
      <Text light color={Theme.grey800Color}>
        {description}
      </Text>
    ) : (
      <Text light color={Theme.grey700Color}>
        <FormattedMessage id="brandFans.groups.description.empty" />
      </Text>
    )}
  </React.Fragment>
)

export { IBrandFansGroupRowDescriptionProps, BrandFansGroupRowDescription }
export default BrandFansGroupRowDescription
