import styled from 'styled-components'

export const ImageWrapper = styled.div`
  width: 4.3125rem;
  height: auto;
  margin: 7.5rem auto 0;

  img {
    width: 100%;
    display: block;
  }
`
