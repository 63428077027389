import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Image from '@components/UI/Image'
import placeholderImage from '@images/placeholder-image.png'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

const InfluencerIdentityRowSubmissionEmpty: React.FC = () => (
  <Container leftInnerSpacing={6} bottomInnerSpacing={1.5}>
    <Flex justifyStart alignCenter>
      <Container width={7.5} height={7.5} rightOuterSpacing={1}>
        <Image src={placeholderImage} />
      </Container>
      <Text color={Theme.grey700Color} small>
        <FormattedMessage id="brandFans.identityGroups.noSubmissions" />
      </Text>
    </Flex>
  </Container>
)

export default InfluencerIdentityRowSubmissionEmpty
