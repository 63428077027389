import { createSelector } from 'reselect'

import { IApplicationState, IHateoasLink } from '@store'
import { isEmpty } from '@utils'

export const selectAuthToken = (state: IApplicationState): string => state.auth.access_token

export const selectAuthExpiration = (state: IApplicationState) => state.auth.expiration

export const selectAuthErrors = (state: IApplicationState) => state.auth.errors

export const selectAuthErrorMessages = (state: IApplicationState) => {
  const authError = selectAuthErrors(state)
  return authError && authError.messages
}

export const selectAuthErrorCode = createSelector(selectAuthErrorMessages, (messages) => {
  if (isEmpty(messages) || typeof messages === 'string') {
    return undefined
  }
  return messages && messages[0].error_code
})

export const selectIsAuthenticating = (state: IApplicationState) => state.auth.isAuthenticating

export const selectLink = (state: IApplicationState, rel: string): IHateoasLink | undefined =>
  state.auth.links.find((rootLink: IHateoasLink) => rootLink.rel === rel)

export const selectRefreshTokenLink = (state: IApplicationState) =>
  selectLink(state, 'refresh_access_token')

export const selectRequesterCountryCode = (state: IApplicationState) =>
  state.auth.requesterCountryCode

export const selectIsRequesting = (state: IApplicationState) => state.auth.isRequesting

export const selectSignUpData = (state: IApplicationState) => state.auth.signupData
