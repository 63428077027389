import styled, { css } from 'styled-components'

import Theme from '@theme'

interface IHrProps {
  backgroundColor: string
  margin: number
}

export const Hr = styled.hr<IHrProps>`
  border: none;
  height: 1px;
  ${({ margin }) =>
    typeof margin === 'number' &&
    css`
      margin: ${margin}rem;
    `}
  ${({ backgroundColor = Theme.grey300Color }) =>
    backgroundColor &&
    css`
      background: ${backgroundColor};
    `};
`
