import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const SubmissionCreatorNotePanelStyled = styled.div`
  padding: 1.5rem 1.25rem;
  position: absolute;
  height: 5rem;
  box-sizing: border-box;
  width: 100%;
  ${breakpoint('tabletPortrait')`
    width: 100%;
    padding: 1.5rem 0;	
  `};
`
