import { Fragment } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import CampaignBudget from '@components/Campaign/Budget/Budget'
import InboxTopPanelDeclinedFilters from '@components/Inbox/TopPanel/Declined/Filters'
import InboxViewOptions from '@components/Inbox/ViewOptions/ViewOptions'
import { SubmissionSortInfluencerList, SubmissionSortTiles } from '@components/Submission/Sort'
import { MemberRoleTypes, SubmissionStatuses } from '@enums'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { selectDisplayCampaignBudget, selectIsSocialCampaign } from '@store/campaigns'
import { selectCampaignId } from '@store/router'
import { selectFilteredDeclinedSubmissionsLink } from '@store/submissions'
import { Container } from '@tribegroup'
import InboxTopPanelMetrics from './Metrics/Metrics'

interface IInternalProps {
  displayCampaignBudget: boolean
  isSocialCampaign: boolean
  hasFilteredDeclinedLink?: boolean
  role: MemberRoleTypes
}

const metricsSupportedFilters: ReadonlyArray<any> = ['shortlisted', 'published']

export const InboxTopPanel = ({
  hasFilteredDeclinedLink,
  isSocialCampaign,
  displayCampaignBudget,
}: IInternalProps) => {
  const { currentFilter } = hooks.useInboxFilter()
  const isInfluencerView = hooks.useIsInfluencerView()

  const shouldDisplayMetrics = metricsSupportedFilters.includes(currentFilter)
  const isDeclinedListFilter = currentFilter === SubmissionStatuses.Declined
  const showDeclinedFilters = hasFilteredDeclinedLink && isDeclinedListFilter
  return (
    <Fragment>
      <Container bottomOuterSpacing={0.75}>
        <Flex alignCenter justifyBetween alignEnd>
          {isSocialCampaign && <InboxViewOptions />}
          {isInfluencerView ? <SubmissionSortInfluencerList /> : <SubmissionSortTiles />}
        </Flex>
      </Container>
      {displayCampaignBudget && <CampaignBudget />}
      {shouldDisplayMetrics && <InboxTopPanelMetrics />}
      {showDeclinedFilters && <InboxTopPanelDeclinedFilters />}
    </Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  return {
    hasFilteredDeclinedLink: Boolean(selectFilteredDeclinedSubmissionsLink(state, campaignId)),
    displayCampaignBudget: selectDisplayCampaignBudget(state, campaignId),
    isSocialCampaign: selectIsSocialCampaign(state, campaignId),
  }
}
export default compose<IInternalProps, {}>(withRouter, connect(mapStateToProps))(InboxTopPanel)
