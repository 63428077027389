import { Fragment, useEffect } from 'react'

import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import ButtonLink from '@components/UI/ButtonLink'
import TrackedRoute from '@components/UI/TrackedRoute/TrackedRoute'
import { BuilderSteps, EventTrackingNames, PageRoutes, SupportedSubmissionTypes } from '@enums'
import hooks from '@hooks'
import contentcampaign from '@images/campaignbuilder/cb-content-campaign-banner.png'
import contentcampaignx2 from '@images/campaignbuilder/cb-content-campaign-banner@2x.png'
import contentcampaignx3 from '@images/campaignbuilder/cb-content-campaign-banner@3x.png'
import influencercampaign from '@images/campaignbuilder/cb-influencer-campaign-banner.png'
import influencercampaignx2 from '@images/campaignbuilder/cb-influencer-campaign-banner@2x.png'
import influencercampaignx3 from '@images/campaignbuilder/cb-influencer-campaign-banner@3x.png'
import { IApplicationState } from '@store'
import { CampaignTypes } from '@store/campaigns'
import { selectDemoVideoId } from '@store/me'
import Theme from '@theme'
import { Button, Container, H1, Header, Text } from '@tribegroup'
import { objectToQueryString, parseCampaignType } from '@utils'
import {
  BuilderCampaignTypeCardWrapper,
  ContentWrapper,
  DisabledCopyWrapper,
  ImageWrapper,
  MainContainer,
} from './Card.styled'

interface IBuilderCampaignTypeCardProps {
  type: SupportedSubmissionTypes
  disabled?: boolean
  showSolutionsCampaignLink?: boolean
}

interface IInternalProps extends IBuilderCampaignTypeCardProps {
  videoId: string
}

const BuilderCampaignTypeCard: React.FC<IInternalProps> = ({
  type,
  videoId,
  disabled,
  showSolutionsCampaignLink,
}) => {
  useEffect(() => {
    return () => {
      const popOverEl = document.querySelector(
        `wistia-${videoId}-1_popover_container`,
      ) as HTMLDivElement
      if (popOverEl) {
        popOverEl.remove()
      }
    }
  })

  const { brand_fan_group_id, from = `/${PageRoutes.Campaigns}` } = hooks.useLocationSearch()

  const [imagex1, imagex2, imagex3] =
    type === SupportedSubmissionTypes.SocialSubmission
      ? [influencercampaign, influencercampaignx2, influencercampaignx3]
      : [contentcampaign, contentcampaignx2, contentcampaignx3]

  return (
    <Fragment>
      <Helmet>
        <script src={`https://fast.wistia.com/embed/medias/${videoId}.jsonp`} async />
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
      </Helmet>
      <BuilderCampaignTypeCardWrapper alignCenter justifyCenter disabled={disabled}>
        <MainContainer>
          <Container relative>
            <ImageWrapper>
              <img
                data-testid={`campaign-builder-banner-${type}`}
                srcSet={`${imagex1} 440w, ${imagex2} 880w, ${imagex3} 1320w`}
                alt="campaign-type"
              />
            </ImageWrapper>
          </Container>
          <Container>
            <H1 color={Theme.grey900Color}>
              <FormattedMessage id={`builder.submissiontypes.${type}.title`} />
            </H1>
          </Container>
          <ContentWrapper>
            <Text color={Theme.grey900Color}>
              <FormattedMessage id={`builder.submissiontypes.${type}.body`} />
            </Text>
          </ContentWrapper>
          <Flex justifyCenter>
            {disabled ? (
              <Button scheme="primary" data-cy-element={`${type}-campaign-button`}>
                <Header cta uppercase color={Theme.whiteColor}>
                  <FormattedMessage id={`builder.submissiontypes.${type}.cta`} />
                </Header>
              </Button>
            ) : (
              <TrackedRoute
                eventName={EventTrackingNames.CampaignBuilderSelectCampaignType}
                properties={{ brief_type: parseCampaignType(type) }}
              >
                <ButtonLink
                  scheme="primary"
                  to={{
                    pathname: `/builder/${BuilderSteps.BRAND}`,
                    search: objectToQueryString({
                      campaignType: CampaignTypes.TRADITIONAL,
                      type,
                      brand_fan_group_id,
                      from,
                    }),
                  }}
                  data-cy-element={`${type}-campaign-button`}
                >
                  <Header cta uppercase color={Theme.whiteColor}>
                    <FormattedMessage id={`builder.submissiontypes.${type}.cta`} />
                  </Header>
                </ButtonLink>
              </TrackedRoute>
            )}
          </Flex>
          {showSolutionsCampaignLink && (
            <Flex justifyCenter>
              <Container topOuterSpacing={2}>
                <TrackedRoute
                  eventName={EventTrackingNames.CampaignBuilderSelectCampaignType}
                  properties={{ brief_type: parseCampaignType(type), traditional: false }}
                >
                  <Link
                    to={{
                      pathname: `/builder/${BuilderSteps.BRAND}`,
                      search: objectToQueryString({
                        campaignType: CampaignTypes.SOLUTION,
                        type,
                        brand_fan_group_id,
                        from,
                      }),
                    }}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Text color={Theme.primaryColor}>
                      <FormattedMessage id={`builder.submissiontypes.tribe_plus_${type}.cta`} />
                    </Text>
                  </Link>
                </TrackedRoute>
              </Container>
            </Flex>
          )}
        </MainContainer>
        {disabled && (
          <DisabledCopyWrapper>
            <Container width={19.625}>
              <H1 color={Theme.primaryColor} bottomOuterSpacing={1}>
                <FormattedMessage id={`builder.submissiontypes.${type}.title`} />
              </H1>
              <Text color={Theme.grey900Color}>
                <FormattedMessage id={`builder.submissiontypes.${type}.notAvaialble`} />
              </Text>
            </Container>
          </DisabledCopyWrapper>
        )}
      </BuilderCampaignTypeCardWrapper>
    </Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { type }: IBuilderCampaignTypeCardProps) => ({
  videoId: selectDemoVideoId(state, type),
})

export { BuilderCampaignTypeCard }
export default compose<IInternalProps, IBuilderCampaignTypeCardProps>(connect(mapStateToProps))(
  BuilderCampaignTypeCard,
)
