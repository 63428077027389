import React from 'react'

import { FormattedMessage } from 'react-intl'

import InfluencerIdentityRowLabelTooltip from '@components/InfluencerIdentity/Row/LabelTooltip'
import InfluencerIdentityRowValueAndLabel from '@components/InfluencerIdentity/Row/ValueAndLabel'
import { SubmissionChannels } from '@enums'

interface IPerformanceAverageEngagementPercentProps {
  averageEngagementPercent: number
  provider: SubmissionChannels
}

const BrandFanInfluencerIdentityRowPerformanceAverageEngagementPercent: React.FC<IPerformanceAverageEngagementPercentProps> = ({
  averageEngagementPercent,
  provider,
}) => {
  return (
    <InfluencerIdentityRowValueAndLabel
      value={
        averageEngagementPercent ? (
          <FormattedMessage id="core.text.percent" values={{ value: averageEngagementPercent }} />
        ) : (
          <FormattedMessage
            id={
              [
                SubmissionChannels.Instagram,
                SubmissionChannels.Pinterest,
                SubmissionChannels.TikTok,
              ].includes(provider)
                ? 'core.metric.empty'
                : 'core.metric.na'
            }
          />
        )
      }
      label={
        <InfluencerIdentityRowLabelTooltip
          title={
            <FormattedMessage id="submission.prePurchaseAnalytics.post.avgEngagementPercentage" />
          }
          body={
            <FormattedMessage id="submission.prePurchaseAnalytics.post.avgEngagementPercentage.tooltip" />
          }
        >
          <FormattedMessage id="core.text.avgEngPercent" />
        </InfluencerIdentityRowLabelTooltip>
      }
      width={5.75}
    />
  )
}

export {
  BrandFanInfluencerIdentityRowPerformanceAverageEngagementPercent,
  IPerformanceAverageEngagementPercentProps,
}
export default BrandFanInfluencerIdentityRowPerformanceAverageEngagementPercent
