import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { Container, Icon } from '@tribegroup'

const ShareThisLibraryToast: React.FC = () => {
  return (
    <Flex center>
      <Container rightOuterSpacing={1}>
        <FormattedMessage id="contentLibrary.shareable.shareThis.toast.copied" />
      </Container>
      <Icon glyph="tick" />
    </Flex>
  )
}

export default ShareThisLibraryToast
