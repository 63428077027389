import React from 'react'

import isNumber from 'lodash.isnumber'
import Currency from 'react-currency-formatter'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import { IAmount } from '@store/postCampaignReport'
import Theme from '@theme'
import { Text } from '@tribegroup'
import { RowWrapper } from '../ListView.styled'
import LockableEntry from '../LockableEntry/LockableEntry'

interface IAnalyticsPostCampaignReportListViewInsightsProps {
  testIdPrefix: string
  backgroundColor: string
  reach?: number
  reachPct?: number
  impressions?: number
  cpm?: IAmount
  isPriceHidden?: boolean
}

const AnalyticsPostCampaignReportListViewInsights: React.FC<IAnalyticsPostCampaignReportListViewInsightsProps> = ({
  testIdPrefix,
  backgroundColor,
  reach,
  reachPct,
  impressions,
  cpm,
  isPriceHidden = false,
}) => (
  <React.Fragment>
    <RowWrapper data-testid={`${testIdPrefix}-reach`} backgroundColor={backgroundColor}>
      <LockableEntry
        empty={!isNumber(reach)}
        content={
          isNumber(reach) && (
            <Text color={Theme.grey900Color}>
              <FormattedNumber value={reach!} />
            </Text>
          )
        }
      />
    </RowWrapper>
    <RowWrapper data-testid={`${testIdPrefix}-reach-pct`} backgroundColor={backgroundColor}>
      <LockableEntry
        empty={!isNumber(reachPct)}
        content={
          isNumber(reachPct) && (
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="core.text.percent" values={{ value: reachPct }} />
            </Text>
          )
        }
      />
    </RowWrapper>
    <RowWrapper data-testid={`${testIdPrefix}-impressions`} backgroundColor={backgroundColor}>
      <LockableEntry
        empty={!isNumber(impressions)}
        content={
          isNumber(impressions) && (
            <Text color={Theme.grey900Color}>
              <FormattedNumber value={impressions!} />
            </Text>
          )
        }
      />
    </RowWrapper>
    {!isPriceHidden && (
      <RowWrapper data-testid={`${testIdPrefix}-cpm`} backgroundColor={backgroundColor}>
        <LockableEntry
          empty={!cpm || !isNumber(cpm.amount)}
          content={
            cpm &&
            isNumber(cpm.amount) && (
              <Text color={Theme.grey900Color}>
                <Currency currency={cpm.currency} quantity={cpm.amount} />
              </Text>
            )
          }
        />
      </RowWrapper>
    )}
  </React.Fragment>
)

export default AnalyticsPostCampaignReportListViewInsights
