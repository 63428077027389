import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { SideBarBlock } from '@components/UI/SideBar'
import { IApplicationState } from '@store'
import {
  fetchBrandFanGroups,
  fetchInfluencerIdentities,
  selectBrandFansStats,
} from '@store/brandFans'
import {
  selectBrandFanGroupsLink,
  selectInfluencerIdentitiesLink,
  selectInfluencerIdentityFilters,
} from '@store/me'
import BrandFansFiltersGroups from './Groups'
import BrandFansFiltersIdentity from './Identity'

type IInternalProps = ConnectedProps<typeof connector>

const BrandFansFilters: React.FC<IInternalProps> = ({
  brandFansStats,

  hasBrandFanGroupsLink,
  influencerIdentityFilters,
  hasInfluencerIdentitiesLink,
  fetchBrandFanGroups: fetchBrandFanGroupsAction,
  fetchInfluencerIdentities: fetchInfluencerIdentitiesAction,
}) => {
  React.useEffect(() => {
    fetchBrandFanGroupsAction()
    fetchInfluencerIdentitiesAction()
  }, [])

  const identityFilters = hasInfluencerIdentitiesLink ? influencerIdentityFilters : []
  return (
    <SideBarBlock
      header={
        <Flex justifyBetween alignCenter data-testid="filter-header-brand-fans">
          <FormattedMessage id="core.text.brandFans" />
        </Flex>
      }
      items={[
        ...identityFilters.map((filter: string) => (
          <BrandFansFiltersIdentity key={filter} filter={filter} stats={brandFansStats} />
        )),
        hasBrandFanGroupsLink && (
          <BrandFansFiltersGroups key="brand-fan-groups" stats={brandFansStats} />
        ),
      ]}
    />
  )
}

const mapDispatchToProps = {
  fetchInfluencerIdentities,
  fetchBrandFanGroups,
}

const mapStateToProps = (state: IApplicationState) => {
  const brandFanGroupsLink = selectBrandFanGroupsLink(state)
  const influencerIdentitiesLink = selectInfluencerIdentitiesLink(state)
  return {
    influencerIdentityFilters: selectInfluencerIdentityFilters(state),
    hasInfluencerIdentitiesLink: Boolean(influencerIdentitiesLink),
    hasBrandFanGroupsLink: Boolean(brandFanGroupsLink),
    brandFansStats: selectBrandFansStats(state),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export { BrandFansFilters }
export default compose<IInternalProps, {}>(connector)(BrandFansFilters)
