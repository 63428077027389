import Theme from '@theme'
import { Text } from '@tribegroup'
import { ColWrapper, Header } from '../ListView.styled'

interface IProps {
  children: React.ReactNode
}

export const PostCampaignReportListViewHeadersItem = ({ children }: IProps) => {
  return (
    <ColWrapper>
      <Header>
        <Text uppercase color={Theme.grey800Color} xsmall>
          {children}
        </Text>
      </Header>
    </ColWrapper>
  )
}
