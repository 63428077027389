import styled from 'styled-components'

import Image from '@components/UI/Image'
import Theme from '@theme'

export const CampaignCardImageWrapperStyled = styled.div`
  position: relative;
  overflow: hidden;
`

export const GradientCover = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  background: linear-gradient(rgb(0 0 0 / 10%), rgb(0 0 0 / 0%));
`

export const PlaceholderImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 2.8125rem;
  background-color: ${Theme.grey400Color};
`

export default CampaignCardImageWrapperStyled
