import { format } from 'date-fns'
import { createSelector } from 'reselect'

import { EMPTY_LIST, FULL_DATE } from '@constants'
import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { IStoryMetrics, IStoryMetricsSummary } from '@store/postCampaignReport/storyMetrics'
import { isTruthyOrZero } from '@utils'

const STORY_METRIC_PROPS: ReadonlyArray<string> = ['impressions', 'reach', 'reach_pct', 'cpm']

export const selectStoryMetricIds = (
  state: IApplicationState,
  campaignId: number,
  channel: PCRChannels,
) => {
  const storyMetricIds = state.reportings.storyMetrics.storyMetricIdsByChannel[campaignId]
  return storyMetricIds && storyMetricIds[channel] ? storyMetricIds[channel] : EMPTY_LIST
}

export const selectStoryMetrics = (state: IApplicationState, id: number) =>
  state.reportings.storyMetrics.storyMetricsById[id] || {}

export const selectStoryPublishedDate = createSelector(
  selectStoryMetrics,
  (storyMetrics: IStoryMetrics) =>
    storyMetrics.published_at && format(new Date(storyMetrics.published_at), FULL_DATE),
)

export const selectStorySpend = createSelector(
  selectStoryMetrics,
  (storyMetrics: IStoryMetrics) => ({
    amount: storyMetrics.purchase_amount,
    currency: storyMetrics.purchase_amount_currency,
  }),
)

export const selectStoryFollowers = createSelector(
  selectStoryMetrics,
  (storyMetrics: IStoryMetrics) => storyMetrics.followers,
)

export const selectStoryReach = createSelector(
  selectStoryMetrics,
  (storyMetrics: IStoryMetrics) => storyMetrics.reach,
)

export const selectStoryReachPct = createSelector(
  selectStoryMetrics,
  (storyMetrics: IStoryMetrics) => storyMetrics.reach_pct,
)

export const selectStoryImpressions = createSelector(
  selectStoryMetrics,
  (storyMetrics: IStoryMetrics) => storyMetrics.impressions,
)

export const selectStoryCPM = createSelector(selectStoryMetrics, (storyMetrics: IStoryMetrics) => ({
  amount: storyMetrics.cpm,
  currency: storyMetrics.cpm_currency,
}))

export const selectShowStoryPerformance = createSelector(
  selectStoryMetrics,
  (storyMetrics: IStoryMetrics) => {
    return STORY_METRIC_PROPS.some((metricProp: string) => isTruthyOrZero(storyMetrics[metricProp]))
  },
)

export const selectStoryMetricsSummary = (
  state: IApplicationState,
  campaignId: number,
  channel: string,
) => {
  const summary = state.reportings.storyMetrics.storyMetricsSummary[campaignId]

  if (!summary) {
    return {}
  }

  return summary[channel] || {}
}

export const selectStoriesTotalPosts = createSelector(
  selectStoryMetricsSummary,
  (summary: IStoryMetricsSummary) => summary.count,
)

export const selectStoriesTotalSpend = createSelector(
  selectStoryMetricsSummary,
  (summary: IStoryMetricsSummary) => ({
    amount: summary.total_purchase_amount,
    currency: summary.total_purchase_amount_currency,
  }),
)

export const selectStoriesTotalFollowers = createSelector(
  selectStoryMetricsSummary,
  (summary: IStoryMetricsSummary) => summary.total_followers,
)

export const selectStoriesTotalReach = createSelector(
  selectStoryMetricsSummary,
  (summary: IStoryMetricsSummary) => summary.total_reach,
)

export const selectStoriesAvgReachPct = createSelector(
  selectStoryMetricsSummary,
  (summary: IStoryMetricsSummary) => summary.avg_reach_pct,
)

export const selectStoriesTotalImpressions = createSelector(
  selectStoryMetricsSummary,
  (summary: IStoryMetricsSummary) => summary.total_impressions,
)

export const selectStoriesOverallCPM = createSelector(
  selectStoryMetricsSummary,
  (summary: IStoryMetricsSummary) => ({
    amount: summary.overall_cpm,
    currency: summary.overall_cpm_currency,
  }),
)
