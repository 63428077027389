import { format } from 'date-fns'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import Flex, { FlexItem } from 'styled-flex-component'

import { FULL_DATE } from '@constants'
import { SubmissionStatuses } from '@enums'
import Theme from '@theme'
import { Text } from '@tribegroup'

interface IStatusDateProps {
  date: string
  status: SubmissionStatuses
}

interface IInternalProps extends InjectedIntlProps, IStatusDateProps {}

export const StatusDate = ({ date, status, intl }: IInternalProps) => {
  return (
    <Flex
      full
      column
      justifyCenter
      data-tooltip-id="base-tooltip"
      data-tooltip-content={intl.formatMessage({
        id: `core.text.${status}`,
      })}
    >
      <FlexItem>
        <Text xsmall color={Theme.grey900Color} uppercase>
          <FormattedMessage id={`core.text.${status}`} />
        </Text>
      </FlexItem>
      <FlexItem>
        <Text xsmall color={Theme.grey900Color} uppercase>
          {format(new Date(date), FULL_DATE)}
        </Text>
      </FlexItem>
    </Flex>
  )
}

export default injectIntl(StatusDate)
