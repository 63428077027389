import { differenceInHours } from 'date-fns'

import { LicenseStatuses, MediaTypes, SocialSubmissionTypes, SubmissionStatuses } from '@enums'
import { IMediaObject } from '@store/mediaObjects'
import { ISubmission } from '@store/submissions'

interface IProps {
  isShortlisted?: boolean
  isApproved?: boolean
  isPublished?: boolean
  isLicenseExpired?: boolean
  isLicensed?: boolean
  isPending?: boolean
  isDeclined?: boolean
  isPublishScheduleMissed?: boolean
  shouldSubmissionBePostedWithinHours?: boolean
  scheduledPublishDate?: boolean | Date
  isMediaTypeVideo?: boolean
  isCarousel?: boolean
  isStory?: boolean
  isReel?: boolean
}

const getLicenseStatus = (mediaObject: IMediaObject) => mediaObject?.transacted_license?.status

const getSubmissionFlags = (submission: ISubmission): IProps => {
  const mediaObject = submission.media_objects[0]
  const licenseStatus = getLicenseStatus(mediaObject)

  const isPublished = submission.status === SubmissionStatuses.Published
  const isLicenseExpired =
    licenseStatus === LicenseStatuses.LicenseExpired ||
    licenseStatus === LicenseStatuses.ExpiredDeprecated
  const isLicensed = licenseStatus === LicenseStatuses.Licensed

  const isApproved = submission.status === SubmissionStatuses.Approved
  const scheduledPublishDate = isApproved && new Date(submission.scheduled_publish_date!)
  const isPublishScheduleMissed =
    scheduledPublishDate && differenceInHours(scheduledPublishDate, new Date()) <= 0
  const shouldSubmissionBePostedWithinHours = Boolean(submission.publish_within_hours)
  const isShortlisted = submission.shortlisted
  const isPending = submission.status === SubmissionStatuses.Pending

  const isMediaTypeVideo = mediaObject && mediaObject.media_type === MediaTypes.VIDEO
  const isDeclined = Boolean(submission.status && submission.status.includes('declined'))

  const isStory = submission.social_submission_type === SocialSubmissionTypes.Story
  const isCarousel = submission.social_submission_type === SocialSubmissionTypes.Carousel
  const isReel = submission.social_submission_type === SocialSubmissionTypes.Reel

  return {
    isShortlisted,
    isApproved,
    isPublished,
    isLicenseExpired,
    isLicensed,
    isPending,
    isDeclined,
    isPublishScheduleMissed,
    shouldSubmissionBePostedWithinHours,
    scheduledPublishDate,
    isMediaTypeVideo,
    isStory,
    isCarousel,
    isReel,
  }
}

export { getSubmissionFlags }
