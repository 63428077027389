import React from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { AppName, BuilderDefault, SubmissionChannels } from '@enums'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import { selectIsDisabledAttribute } from '@store/drafts'
import Theme from '@theme'
import { Container, FormInput, SocialPanel, TextField } from '@tribegroup'
import { getAllAvailableTags, removeChannel } from '@utils'
import { ChannelsContext } from '../Channels'
import BuilderBriefChannelsInstagramPaidPartership from '../Instagram/PaidPartnership'
import BuilderBriefChannelsPinterestPaidPartership from '../Pinterest/PaidParnertship/PaidPartnership'
import BuilderBriefChannelsTikTokConnectAccountEducation from '../Tiktok/ConnectAccountEducation'
import SocialPanelBeforeContent from './BeforeContent'
import SocialPanelRequiredErrorMessage from './RequiredErrorMessage'

interface IBuilderBriefChannelsSocialPanelProps {
  channelName: SubmissionChannels
}

interface IInternalProps
  extends IBuilderBriefChannelsSocialPanelProps,
    InjectedIntlProps,
    IBuilderValidationContextProps {}

const BuilderBriefChannelsSocialPanel: React.FC<IInternalProps> = ({
  intl,
  clearFieldValidation,
  channelName,
  checkValidationResults,
  updateForm,
}) => {
  const isPinterestTenant = process.env.APP_NAME === AppName.PINTEREST

  const { channelRemovalEnabled } = useSelector((state: IApplicationState) => ({
    channelRemovalEnabled:
      !selectIsDisabledAttribute(state, `${channelName}_channel`) &&
      process.env.APP_NAME !== AppName.PINTEREST,
  }))
  const { channelItems, setChannelItems } = React.useContext(ChannelsContext)
  const fieldName = `channel_${channelName}_size`
  const isInstagram = channelName === SubmissionChannels.Instagram
  const isTikTok = channelName === SubmissionChannels.TikTok
  const isPinterest = channelName === SubmissionChannels.Pinterest || isPinterestTenant

  const submissionTypeRequiredChannels = [
    SubmissionChannels.Instagram,
    SubmissionChannels.X,
    SubmissionChannels.YouTube,
  ]

  const channelAttributes = channelItems[channelName]

  const onRemoveChannel = () => {
    if (isInstagram) {
      clearFieldValidation('paid_partnership_handle_valid')
      updateForm({
        has_brand_partnership_handle: '',
        paid_partnership_handle: '',
        requested_social_submission_types: [],
      })
    }
    clearFieldValidation(fieldName)
    setChannelItems(removeChannel(channelItems, channelName))
  }

  const onTagItemsChanged = (changedChannelName: string, result: any) => {
    clearFieldValidation(fieldName)
    setChannelItems({
      ...channelItems,
      [changedChannelName]: {
        ...channelItems[changedChannelName],
        ...result,
      },
    })
  }

  const isPaidPartnershipAllowed = isInstagram || isPinterest

  const hasPaidPartnershipsError =
    isPaidPartnershipAllowed && checkValidationResults('paid_partnership_handle_valid')

  const hasSubmissionTypesError =
    (isInstagram && checkValidationResults('requested_social_submission_types')) ||
    (submissionTypeRequiredChannels.includes(channelName) &&
      checkValidationResults(`${channelName}_requested_social_submission_types`))

  const hasInstagramRelatedError = hasPaidPartnershipsError || hasSubmissionTypesError

  const hasChannelError = checkValidationResults(fieldName)

  const hasAnyError = hasChannelError || hasInstagramRelatedError

  const hasPinterestSocialPannelError = checkValidationResults(
    'pinterest_paid_partnership_handle_valid',
  )

  return (
    <React.Fragment>
      <SocialPanel
        highlightIcon={hasAnyError}
        channel={channelName}
        submitText={intl.formatMessage({
          id: 'builder.brief.socialMedia.submitText',
        })}
        onRemoveChannel={channelRemovalEnabled ? onRemoveChannel : undefined}
        onTagItemsChanged={onTagItemsChanged}
        pillBackgroundColor={Theme.primary200Color}
        contentBeforeInput={<SocialPanelBeforeContent channel={channelName} />}
        {...channelAttributes}
      >
        {isInstagram && <BuilderBriefChannelsInstagramPaidPartership />}
        {isPinterest && <BuilderBriefChannelsPinterestPaidPartership />}
        {isTikTok && <BuilderBriefChannelsTikTokConnectAccountEducation />}
      </SocialPanel>
      <FormInput
        validationRule={{
          min: !isPinterest ? BuilderDefault.MIN_ARRAY_SOCIAL_CHANNEL_TAG : undefined,
        }}
      >
        <TextField
          name={`channel_${channelName}_size`}
          type="hidden"
          value={getAllAvailableTags(channelAttributes).length}
          readOnly
        />
      </FormInput>

      {!isPinterest && hasAnyError && (
        <Container topOuterSpacing={0.75}>
          <SocialPanelRequiredErrorMessage channel={channelName} />
        </Container>
      )}

      {isPinterest && hasPinterestSocialPannelError && (
        <Container topOuterSpacing={0.75}>
          <SocialPanelRequiredErrorMessage channel={SubmissionChannels.Pinterest} />
        </Container>
      )}
    </React.Fragment>
  )
}

export { BuilderBriefChannelsSocialPanel }
export default compose<IInternalProps, IBuilderBriefChannelsSocialPanelProps>(
  injectIntl,
  withBuilderValidation,
)(BuilderBriefChannelsSocialPanel)
