import { InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import SubmissionCardModalRescheduleWrapper from '@components/Submission/Card/Modal/Reschedule/Wrapper'
import ActionIcon from '@components/UI/ActionIcon'
import { IModalContextProps } from '@context/Modal'
import { withModal } from '@hocs'
import Theme from '@theme'

interface IActionBarIconReschedule {
  showNotification: boolean
  highlighted: boolean
  modalProps: any
}

interface IInternalProps extends IActionBarIconReschedule, InjectedIntlProps, IModalContextProps {}

export const ActionBarIconReschedule = ({
  highlighted,
  showNotification,
  intl,
  showModal,
  modalProps,
}: IInternalProps) => {
  const onClick = () => showModal(SubmissionCardModalRescheduleWrapper, modalProps)
  return (
    <ActionIcon
      glyph="schedule"
      color={Theme.grey900Color}
      highlighted={highlighted}
      showNotification={showNotification}
      size={1.5}
      onClick={onClick}
      tooltip={intl.formatMessage({ id: 'submission.card.action.schedule' })}
      elementName="reschedule"
    />
  )
}

export default compose<IInternalProps, any>(withModal, injectIntl)(ActionBarIconReschedule)
