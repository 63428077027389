import { call, put, select, takeEvery } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { FacebookService } from '@services/facebook'
import { IHateoasLink } from '@store'
import {
  selectMediaObjectTrackingProps,
  selectSubmissionTrackingProps,
  trackEvent,
} from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  FacebookMediaTransferActionTypes,
  IAnyMediaTransferRequestParams,
  mediaTransferError,
  mediaTransferRequest,
  mediaTransferSuccess,
  selectUploadToFacebookLink,
} from '@store/facebookAds'
import { fetchMediaObject } from '@store/mediaObjects'
import { fetchSubmission } from '@store/submissions'
import { networkRequest } from '@utils'

export function* handleMediaTransfer(action: ReturnType<typeof mediaTransferRequest>) {
  try {
    const { adAccountId, params } = action.payload
    const authToken: string = yield select(selectAuthToken)
    const fbAdUploadLink: IHateoasLink = yield select(selectUploadToFacebookLink, params)
    const fbAccessToken = yield call(FacebookService.getAccessToken)
    yield call(
      networkRequest,
      fbAdUploadLink.method,
      fbAdUploadLink.href,
      {
        account_id: adAccountId,
        auth_token: fbAccessToken,
      },
      authToken,
    )

    yield put(mediaTransferSuccess())
    yield call(handlePostTransferSuccess, params)
  } catch (error) {
    yield put(mediaTransferError(error))
  }
}

export function* handlePostTransferSuccess({
  submissionId,
  campaignId,
  mediaObjectId,
}: Partial<IAnyMediaTransferRequestParams>) {
  if (submissionId && campaignId) {
    yield put(fetchSubmission(submissionId, campaignId))
    const properties = yield select(selectSubmissionTrackingProps, campaignId, submissionId)
    yield put(trackEvent(EventTrackingNames.UploadToFacebookAdsManager, properties))
  } else if (mediaObjectId) {
    yield put(fetchMediaObject(mediaObjectId))
    const properties = yield select(selectMediaObjectTrackingProps, mediaObjectId)
    yield put(trackEvent(EventTrackingNames.UploadToFacebookAdsManager, properties))
  }
}

export function* watchMediaTransferRequest() {
  yield takeEvery(FacebookMediaTransferActionTypes.MEDIA_TRANSFER_REQUEST, handleMediaTransfer)
}
