import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import Breakpoint from '@components/UI/Breakpoint'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { selectIsContentLibraryEmpty, selectShowShareLibraryButton } from '@store/mediaObjects'
import { Container } from '@tribegroup'
import { ShareThisLibraryButton } from '../TitleBar/Buttons'
import ContentLibraryAvailableCredit from './AvailableCredit/AvailableCredit'

type IInternalProps = ConnectedProps<typeof connector>

const ContentLibraryTopPanel = ({
  showShareLibraryButton,
  isContentLibraryEmpty,
}: IInternalProps) => {
  const { isDiscover } = hooks.useContentLibraryFilter()

  return (
    <div>
      {showShareLibraryButton && (
        <Breakpoint mobile>
          <Container bottomOuterSpacing={1}>
            <ShareThisLibraryButton fullWidth />
          </Container>
        </Breakpoint>
      )}
      {isDiscover && !isContentLibraryEmpty && <ContentLibraryAvailableCredit />}
    </div>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  isContentLibraryEmpty: selectIsContentLibraryEmpty(state),
  showShareLibraryButton: selectShowShareLibraryButton(state),
})

const connector = connect(mapStateToProps)
export { ContentLibraryTopPanel }
export default compose<IInternalProps, {}>(connector)(ContentLibraryTopPanel)
