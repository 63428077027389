import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import {
  AutoSwitchActionTypes,
  ChangePasswordActionTypes,
  CreateSetupIntentActionTypes,
  IMeState,
  ISubscriptionTier,
  MeActionTypes,
  SetupCardActionTypes,
  UpdateContactActionTypes,
  UpdateCreditCardActionTypes,
} from '@store/me'

const initialState: IMeState = {
  info: {
    id: 0,
    email: '',
    first_name: '',
    last_name: '',
    company_any: false,
    company_name: '',
    company_accounts_payable_email: '',
    company_address_country: '',
    company_operating_region: '',
    intercom_user_hash: '',
    links: [],
    subscription_tier: {} as ISubscriptionTier,
  },
  errors: undefined,
  isUpdating: false,
  isFetching: false,
  isSwitching: false,
  isFetched: false,
  isCreatingIntent: false,
  isSettingUpCard: false,
}

const reducer: Reducer<IMeState> = (state = initialState, action) => {
  switch (action.type) {
    case AutoSwitchActionTypes.AUTOSWITCH_REQUEST: {
      return {
        ...state,
        isSwitching: true,
      }
    }

    case MeActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        errors: undefined,
        isFetching: true,
      }
    }

    case ChangePasswordActionTypes.CHANGE_PASSWORD_REQUEST:
    case UpdateCreditCardActionTypes.UPDATE_CREDITCARD_REQUEST:
    case UpdateContactActionTypes.UPDATE_CONTACT_REQUEST: {
      return {
        ...state,
        isUpdating: true,
        errors: undefined,
      }
    }

    case CreateSetupIntentActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        errors: undefined,
        isCreatingIntent: true,
        clientSecret: undefined,
      }
    }

    case SetupCardActionTypes.SETUP_REQUEST: {
      return {
        ...state,
        errors: undefined,
        isSettingUpCard: true,
      }
    }

    case CreateSetupIntentActionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        errors: undefined,
        isCreatingIntent: false,
        clientSecret: action.payload,
      }
    }

    case SetupCardActionTypes.SETUP_SUCCESS: {
      return {
        ...state,
        errors: undefined,
        isSettingUpCard: false,
      }
    }

    case AutoSwitchActionTypes.AUTOSWITCH_SUCCESS:
    case MeActionTypes.FETCH_SUCCESS: {
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload,
        },
        isFetching: false,
        isUpdating: false,
        isSettingUpCard: false,
        isFetched: true,
        errors: undefined,
      }
    }

    case ChangePasswordActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isUpdating: false,
        errors: undefined,
      }
    }

    case CreateSetupIntentActionTypes.CREATE_ERROR:
    case SetupCardActionTypes.SETUP_ERROR:
    case AutoSwitchActionTypes.AUTOSWITCH_ERROR:
    case ChangePasswordActionTypes.CHANGE_PASSWORD_ERROR:
    case MeActionTypes.FETCH_ERROR:
    case UpdateCreditCardActionTypes.UPDATE_CREDITCARD_ERROR:
    case UpdateContactActionTypes.UPDATE_CONTACT_ERROR: {
      return {
        ...state,
        isCreatingIntent: false,
        isSettingUpCard: false,
        isFetching: false,
        isSwitching: false,
        isUpdating: false,
        isRequestingCredit: false,
        errors: action.payload,
      }
    }
    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState
    default:
      return state
  }
}

export { reducer as meReducer }
