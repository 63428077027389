import { ISubmissionPerformance, SubmissionPerformanceActionTypes } from './types'

export const fetchSubmissionPerformance = (submissionId: number) => ({
  type: SubmissionPerformanceActionTypes.FETCH_REQUEST,
  payload: submissionId,
})

export const fetchSubmissionPerformanceSuccess = (
  submissionId: number,
  submissionPerformance: ISubmissionPerformance,
) => ({
  type: SubmissionPerformanceActionTypes.FETCH_SUCCESS,
  payload: {
    submissionId,
    submissionPerformance,
  },
})

export const fetchSubmissionPerformanceError = (error: any) => ({
  type: SubmissionPerformanceActionTypes.FETCH_ERROR,
  payload: error,
})
