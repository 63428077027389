import React, { PropsWithChildren } from 'react'

export interface IScrollPositionContextProps {
  setScrollPosition: React.Dispatch<React.SetStateAction<number>>
  scrollPosition: number
}

export const ScrollPositionContext = React.createContext({} as IScrollPositionContextProps)

export const ScrollPositionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [scrollPosition, setScrollPosition] = React.useState<number>(0)

  const contextProps: IScrollPositionContextProps = {
    scrollPosition,
    setScrollPosition,
  }

  return (
    <ScrollPositionContext.Provider value={contextProps}>{children}</ScrollPositionContext.Provider>
  )
}

export default ScrollPositionProvider
