import { keyframes } from 'styled-components'

export const SlideOutToRight = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(40rem, 0,0 );
  }
`
