import { useEffect } from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'
import Flex, { FlexItem } from 'styled-flex-component'

import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import TribeOnly from '@components/UI/TribeOnly'
import { AUDIENCE_INSIGHTS_INFO } from '@constants'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectCampaignTrackingProps, trackEvent } from '@store/analytics'
import Theme from '@theme'
import { Container, H5, Icon, Link, Text } from '@tribegroup'
import AudienceReportingDrawerCampaignTarget from './CampaignTarget'

interface IProps {
  campaignId: number
}

type IInternalProps = ConnectedProps<typeof connector> & IProps

const AnalyticsAudienceReportingDrawer: React.FC<IInternalProps> = ({
  campaignId,
  campaignAnalyticsProperties,
  trackEvent: trackEventAction,
}) => {
  useEffect(() => {
    return () => {
      trackEventAction(EventTrackingNames.DismissAudienceReportingEducation, {
        ...campaignAnalyticsProperties,
      })
    }
  })

  return (
    <Container
      topOuterSpacing={1.75}
      leftOuterSpacing={1.625}
      rightOuterSpacing={1.625}
      bottomOuterSpacing={6.625}
      overflow="scroll"
    >
      <AudienceReportingDrawerCampaignTarget />
      <Container>
        <H5 color={Theme.primaryColor}>
          <FormattedMessage id="analytics.audienceReporting.drawer.howReportsAreGenerated.title" />
        </H5>
        <Container topOuterSpacing={0.5}>
          <Text block customFontSize={0.8125} color={Theme.grey900Color}>
            <FormattedMessage id="analytics.audienceReporting.drawer.howReportsAreGenerated.paragraph.first" />
          </Text>
        </Container>
      </Container>

      <Container topOuterSpacing={1.25}>
        <H5 color={Theme.primaryColor}>
          <FormattedMessage id="analytics.audienceReporting.drawer.estPercentageVsEstImpressions.title" />
        </H5>
        <Container topOuterSpacing={0.5}>
          <Text block customFontSize={0.8125} color={Theme.grey900Color}>
            <FormattedMessage id="analytics.audienceReporting.drawer.estPercentageVsEstImpressions.paragraph.first" />
          </Text>
        </Container>
      </Container>

      <Container topOuterSpacing={1.25}>
        <H5 color={Theme.primaryColor}>
          <FormattedMessage id="analytics.audienceReporting.drawer.dataExpectation.title" />
        </H5>
        <Container topOuterSpacing={0.5}>
          <Text block customFontSize={0.8125} color={Theme.grey900Color}>
            <FormattedMessage id="analytics.audienceReporting.drawer.dataExpectation.paragraph.first" />
          </Text>
        </Container>
        <Container topOuterSpacing={0.5}>
          <Text block customFontSize={0.8125} color={Theme.grey900Color}>
            <FormattedHTMLMessage id="analytics.audienceReporting.drawer.dataExpectation.paragraph.second" />
          </Text>
        </Container>

        <Container topOuterSpacing={1.5}>
          <Flex full>
            <FlexItem basis="50%">
              <Text small color={Theme.grey900Color}>
                <FormattedMessage id="core.text.ageRange" />
              </Text>
            </FlexItem>
            <FlexItem basis="50%">
              <Text small color={Theme.grey900Color}>
                <FormattedMessage id="analytics.audienceReporting.drawer.AvgAudiencePercentage" />
              </Text>
            </FlexItem>
          </Flex>
          <Container
            backgroundColor={Theme.grey200Color}
            leftInnerSpacing={1}
            rightInnerSpacing={1}
            topInnerSpacing={0.75}
            bottomInnerSpacing={0.75}
            topOuterSpacing={0.75}
            borderRadius={0.25}
          >
            <Flex full justifyBetween>
              <Container fullWidth>
                <Text small color={Theme.grey900Color}>
                  13-17
                </Text>
              </Container>
              <Container fullWidth>
                <Text small color={Theme.grey900Color}>
                  2.1%
                </Text>
              </Container>
            </Flex>
          </Container>
          <Container
            leftInnerSpacing={1}
            rightInnerSpacing={1}
            topInnerSpacing={0.75}
            bottomInnerSpacing={0.75}
            borderRadius={0.25}
          >
            <Flex full justifyBetween>
              <Container fullWidth>
                <Text small color={Theme.grey900Color}>
                  18-24
                </Text>
              </Container>
              <Container fullWidth>
                <Text small color={Theme.grey900Color}>
                  18.9%
                </Text>
              </Container>
            </Flex>
          </Container>
          <Container
            backgroundColor={Theme.grey200Color}
            leftInnerSpacing={1}
            rightInnerSpacing={1}
            topInnerSpacing={0.75}
            bottomInnerSpacing={0.75}
            borderRadius={0.25}
          >
            <Flex full justifyBetween>
              <Container fullWidth>
                <Text small color={Theme.grey900Color}>
                  25-34
                </Text>
              </Container>
              <Container fullWidth>
                <Text small color={Theme.grey900Color}>
                  44.3%
                </Text>
              </Container>
            </Flex>
          </Container>
          <Container
            leftInnerSpacing={1}
            rightInnerSpacing={1}
            topInnerSpacing={0.75}
            bottomInnerSpacing={0.75}
            borderRadius={0.25}
          >
            <Flex full justifyBetween>
              <Container fullWidth>
                <Text small color={Theme.grey900Color}>
                  35-44
                </Text>
              </Container>
              <Container fullWidth>
                <Text small color={Theme.grey900Color}>
                  22.1%
                </Text>
              </Container>
            </Flex>
          </Container>
          <Container
            backgroundColor={Theme.grey200Color}
            leftInnerSpacing={1}
            rightInnerSpacing={1}
            topInnerSpacing={0.75}
            bottomInnerSpacing={0.75}
            borderRadius={0.25}
          >
            <Flex full justifyBetween>
              <Container fullWidth>
                <Text small color={Theme.grey900Color}>
                  45+
                </Text>
              </Container>
              <Container fullWidth>
                <Text small color={Theme.grey900Color}>
                  11.4%
                </Text>
              </Container>
            </Flex>
          </Container>
        </Container>
      </Container>

      <Container topOuterSpacing={2}>
        <H5 color={Theme.primaryColor}>
          <FormattedMessage id="analytics.audienceReporting.drawer.preciseTargeting.title" />
        </H5>
        <TribeOnly>
          <Container topOuterSpacing={0.5}>
            <Text block customFontSize={0.8125} color={Theme.grey900Color}>
              <FormattedMessage id="analytics.audienceReporting.drawer.preciseTargeting.paragraph.first" />
            </Text>
          </Container>
          <Container topOuterSpacing={1.5}>
            <ConnectedTrackedRoute
              eventName={EventTrackingNames.ViewAudienceReportingLearnMore}
              campaignId={campaignId}
            >
              <Link target="_blank" href={AUDIENCE_INSIGHTS_INFO}>
                <Text color={Theme.primaryColor} small>
                  <FormattedMessage id="core.text.learnMore" />
                </Text>
                <Icon glyph="chevron-right" color={Theme.primaryColor} verticalAlign="middle" />
              </Link>
            </ConnectedTrackedRoute>
          </Container>
        </TribeOnly>
      </Container>

      <Container topOuterSpacing={2}>
        <H5 color={Theme.grey900Color}>
          <FormattedMessage id="analytics.audienceReporting.drawer.footer" />
        </H5>
      </Container>
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { campaignId }: IProps) => {
  return {
    campaignAnalyticsProperties: selectCampaignTrackingProps(state, campaignId),
  }
}

const mapDispatchToProps = {
  trackEvent,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<IInternalProps, IProps>(connector)(AnalyticsAudienceReportingDrawer)
