import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  disableRequireApprovalPrompt,
  DisableRequireApprovalPromptActionTypes,
  disableRequireApprovalPromptError,
  disableRequireApprovalPromptSuccess,
  fetchCampaign,
  ICampaign,
  selectDisableRequireApprovalPromptLink,
} from '@store/campaigns'
import { networkRequest } from '@utils'

export function* handleDisableRequireApprovalPromptRequest(
  action: ReturnType<typeof disableRequireApprovalPrompt>,
) {
  try {
    const authToken: string = yield select(selectAuthToken)
    const campaignId = action.payload
    const disableRequireAutoApprovalLink: IHateoasLink = yield select(
      selectDisableRequireApprovalPromptLink,
      campaignId,
    )
    const campaign: ICampaign = yield call(
      networkRequest,
      disableRequireAutoApprovalLink.method,
      disableRequireAutoApprovalLink.href,
      undefined,
      authToken,
    )
    yield put(disableRequireApprovalPromptSuccess(campaign))
    yield put(fetchCampaign(campaignId))
  } catch (error) {
    yield put(disableRequireApprovalPromptError(error))
  }
}

export function* watchDisableRequireApprovalPromptRequest() {
  yield takeLatest(
    DisableRequireApprovalPromptActionTypes.DISABLE_REQUEST,
    handleDisableRequireApprovalPromptRequest,
  )
}
