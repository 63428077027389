import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { IInfluencerIdentityIdContextProps } from '@context/InfluencerIdentityId'
import { PageRoutes } from '@enums'
import { withContextInfluencerIdentityId } from '@hocs/withContextInfluencerIdentityId'
import hooks from '@hooks'
import { useCampaignIdFromRoute } from '@hooks/useCampaignIdFromRoute'
import { IApplicationState } from '@store'
import { selectCreatorBioByInfluencerIdentityLink } from '@store/influencerIdentities'
import Theme from '@theme'
import { Container, Link, Text } from '@tribegroup'

const InfluencerIdentityLabelsViewBio = ({
  contextInfluencerIdentityId: identityId,
}: IInfluencerIdentityIdContextProps) => {
  const campaignId = useCampaignIdFromRoute()

  const { creatorBioLink } = useSelector((state: IApplicationState) => {
    return {
      creatorBioLink: selectCreatorBioByInfluencerIdentityLink(state, campaignId, identityId),
    }
  })

  const isInfluencerView = hooks.useIsInfluencerView()

  if (!creatorBioLink || !isInfluencerView) {
    return null
  }

  const { href, method } = creatorBioLink
  const params = new URLSearchParams()
  params.set('href', encodeURI(href))
  params.set('method', method)
  params.set('campaignId', campaignId.toString(10))

  return (
    <Container
      color={Theme.primaryColor}
      borderRadius={0.25}
      borderColor={Theme.primary100Color}
      borderWidth={0.0625}
      borderStyle="solid"
      data-testid="influencer-identity-view-bio"
    >
      <Link
        href={`/${PageRoutes.CreatorBioPage}?${params.toString()}`}
        color={Theme.primaryColor}
        target="_blank"
      >
        <Text uppercase light xxsmall verticalAlign="middle">
          <FormattedMessage id="core.text.viewBio" />
        </Text>
      </Link>
    </Container>
  )
}

export default withContextInfluencerIdentityId(InfluencerIdentityLabelsViewBio)
