import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { FlexItem } from 'styled-flex-component'

export const FlexItemWrapper = styled(FlexItem)`
  flex-basis: 70%;
  ${breakpoint('desktop')`
    flex-basis: 50%;
  `};
`
