import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { HeaderTitle } from '@components/UI/TitleBar/TitleBar.styled'
import { IApplicationState } from '@store'
import { selectInfluencerFullName } from '@store/mediaObjects'
import { selectMediaObjectId } from '@store/router'
import Theme from '@theme'
import { H5 } from '@tribegroup'

interface IInternalProps {
  influencerName: string
}

export const MediaObjectTitleBarHeader = ({ influencerName }: IInternalProps) => (
  <HeaderTitle>
    <H5 color={Theme.grey900Color}>{influencerName}</H5>
  </HeaderTitle>
)

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const mediaObjectId = selectMediaObjectId(match) as number
  return { influencerName: selectInfluencerFullName(state, mediaObjectId!) }
}

export default compose<IInternalProps, any>(
  withRouter,
  connect(mapStateToProps),
)(MediaObjectTitleBarHeader)
