const HEADER_HEIGHT_PIXELS = 60

export const setVerticalScrollPosition = (position: number) => {
  if (window && window.scroll) {
    window.scroll(0, position)
  }
}

export const getVerticalScrollPosition = () => {
  return (
    (window && window.pageYOffset) ||
    (document && document.documentElement && document.documentElement.scrollTop) ||
    0
  )
}

export const scrollToElementWithHeaderOffset = element => {
  element.scrollIntoView(true)
  window.scrollBy(0, -1 * HEADER_HEIGHT_PIXELS)
}
