import { useSelector } from 'react-redux'

import { SubmissionStatuses } from '@enums'
import { IApplicationState } from '@store'
import {
  selectMediaObjectsHaveLicensedTransactedLicense,
  selectMediaObjectsHaveLicenseOptions,
} from '@store/mediaObjects'
import {
  selectDeclineLink,
  selectFeedbackLink,
  selectIsSubmissionDeclined,
  selectShortlistLink,
  selectSubmission,
  selectSubmissionMediaObjectIds,
  selectUnshortlistLink,
} from '@store/submissions'
import SubmissionCardActionBarContentDeclined from './Declined'
import SubmissionCardActionBarContentInbox from './Inbox'
import SubmissionCardActionBarContentLicensed from './Licensed'

interface IProps {
  submissionId: number
  campaignId: number
}

const SubmissionCardActionBarContent = ({ submissionId, campaignId }: IProps) => {
  const {
    submission,
    hasShortlistLink,
    hasFeedbackLink,
    hasUnshortlistLink,
    hasDeclineLink,
    showLicenseIcon,
    isDeclined,
  } = useSelector((state: IApplicationState) => {
    const mediaObjectIds = selectSubmissionMediaObjectIds(state, submissionId)
    const submission = selectSubmission(state, submissionId)

    const hasLicenseOptionsLink = selectMediaObjectsHaveLicenseOptions(state, mediaObjectIds)
    const isLicensed = selectMediaObjectsHaveLicensedTransactedLicense(state, mediaObjectIds)

    const showLicenseIcon = isLicensed || hasLicenseOptionsLink

    return {
      submission,
      showLicenseIcon,
      isDeclined: Boolean(selectIsSubmissionDeclined(state, submissionId)),
      hasDeclineLink: Boolean(selectDeclineLink(state, submissionId)),
      hasFeedbackLink: Boolean(selectFeedbackLink(state, submissionId)),
      hasShortlistLink: Boolean(selectShortlistLink(state, submissionId)),
      hasUnshortlistLink: Boolean(selectUnshortlistLink(state, submissionId)),
    }
  })

  if (
    [SubmissionStatuses.Pending, SubmissionStatuses.PreApproved].includes(
      submission.status as SubmissionStatuses,
    )
  ) {
    return (
      <SubmissionCardActionBarContentInbox
        submission={submission}
        campaignId={campaignId}
        hasShortlistLink={hasShortlistLink}
        hasUnshortlistLink={hasUnshortlistLink}
        hasDeclineLink={hasDeclineLink}
        hasFeedbackLink={hasFeedbackLink}
        showLicenseIcon={showLicenseIcon}
      />
    )
  }

  if (isDeclined) {
    return (
      <SubmissionCardActionBarContentDeclined
        campaignId={campaignId}
        submission={submission}
        hasFeedbackLink={hasFeedbackLink}
      />
    )
  }

  return (
    <SubmissionCardActionBarContentLicensed
      campaignId={campaignId}
      submission={submission}
      hasFeedbackLink={hasFeedbackLink}
      showLicenseIcon={showLicenseIcon}
    />
  )
}

export default SubmissionCardActionBarContent
