import styled, { css } from 'styled-components'

import { Container } from '@tribegroup'

export const TikTokIdentityWrapper = styled(Container)`
  #tiktok-handle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ color }) => color};
  }

  #tiktok-handle-subtitle {
    opacity: 1;
    height: auto;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    #tiktok-handle {
      white-space: normal;
    }

    #tiktok-handle-subtitle {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
  }
`

export const EaseInWrapper = styled(Container)`
  opacity: 1;
  height: auto;
  transition: opacity 0.3s ease-in;
  ${({ isHidden }) =>
    isHidden &&
    css`
      opacity: 0;
      height: 0;
      overflow: hidden;
    `}
`
