import React from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import SubmissionMetrics from '@components/Submission/Metrics'
import { SubmissionChannels } from '@enums'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { selectBriefChannelPreference, selectShortlistSummaryLink } from '@store/campaigns'
import { selectCampaignId } from '@store/router'
import InboxTopPanelMetricsPublished from './Published/Published'
import InboxTopPanelMetricsShortlisted from './Shortlisted/Shortlisted'

interface IInternalProps {
  hasShortlistSummaryLink: boolean
  channels: SubmissionChannels[]
}

const InboxTopPanelMetrics: React.FC<IInternalProps> = ({ hasShortlistSummaryLink, channels }) => {
  const { currentFilter } = hooks.useInboxFilter()
  const isPinterestCampaign = channels.includes(SubmissionChannels.Pinterest)
  if (isPinterestCampaign) {
    return null
  }

  if (currentFilter === 'shortlisted') {
    if (hasShortlistSummaryLink) {
      return <InboxTopPanelMetricsShortlisted />
    }

    return <SubmissionMetrics />
  }

  return (
    <React.Fragment>
      {currentFilter === 'published' && <InboxTopPanelMetricsPublished />}
    </React.Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match)!
  return {
    hasShortlistSummaryLink: Boolean(selectShortlistSummaryLink(state, campaignId)),
    channels: selectBriefChannelPreference(state, campaignId),
  }
}

const connector = connect(mapStateToProps)

export { InboxTopPanelMetrics }
export default compose<IInternalProps, {}>(withRouter, connector)(InboxTopPanelMetrics)
