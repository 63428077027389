import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router'

import { useActionDispatcherEffect } from './useActionDispatcherEffect'
import { useBooleanToggle } from './useBooleanToggle'
import { useCampaignIdFromRoute } from './useCampaignIdFromRoute'
import { useContentLibraryFilter } from './useContentLibraryFilter'
import { useGender } from './useGender'
import { useInboxFilter } from './useInboxFilter'
import { useIsInfluencerView } from './useIsInfluencerView'
import { useIsMounted } from './useIsMounted'
import { useLocationSearch } from './useLocationSearch'
import { useMediaModal } from './useMediaModal'
import { usePreapprovalAmountPercentage } from './usePreapprovalAmountPercentage'
import { usePrevious } from './usePrevious'
import { useQueryStringMatcher } from './useQueryStringMatcher'
import { useToggle } from './useToggle'

const useShallowEqualSelector = <T>(selector) => useSelector(selector, shallowEqual) as T

// do not add new hooks here, this is only here for legacy code testing purposes
const hooks = {
  useSelector: useShallowEqualSelector,
  useDispatch,
  useRouteMatch,
  useParams,
  useLocation,
  useHistory,
  useActionDispatcherEffect,
  useQueryStringMatcher,
  useIsInfluencerView,
  useContentLibraryFilter,
  useInboxFilter,
  useLocationSearch,
  useToggle,
  useMediaModal,
  useIsMounted,
  useCampaignIdFromRoute,
  usePreapprovalAmountPercentage,
  useGender,
  useBooleanToggle,
  usePrevious,
}

export default hooks
