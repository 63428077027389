import { FlexItem } from 'styled-flex-component'

import { Container } from '@tribegroup'
import { BudgetAmount } from '../BudgetAmount/BudgetAmount'

const BudgetAmountInformation = ({ remaining, spent, currency, customMessage }) => {
  return (
    <FlexItem noShrink>
      <Container maxWidth="calc(100vw - 9.75rem)">
        <BudgetAmount
          remaining={remaining}
          spent={spent}
          currency={currency}
          largetext
          customMessage={customMessage}
        />
      </Container>
    </FlexItem>
  )
}

export default BudgetAmountInformation
