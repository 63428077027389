import { call, cancel, fork, put, select, takeEvery } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import { selectRequestPreSignedUrlLink } from '@store/campaigns'
import {
  cancelMoodboardMediaUpload,
  IPreSignedUrlItem,
  IPreSignedUrlResponse,
  MoodboardMediaUploadActionTypes,
  uploadMoodboardMedia,
  uploadMoodboardMediaError,
  uploadMoodboardMediaInProgress,
  uploadMoodboardMediaSuccess,
} from '@store/moodboard'
import { networkRequest, presignedURLMediaUpload, selectLink } from '@utils'

const tasks = {}

export function* cancelTask(action: ReturnType<typeof cancelMoodboardMediaUpload>) {
  const { mediaId } = action.payload

  const task = tasks[mediaId]

  if (task) {
    yield cancel(task)
  }
}

export function* uploadMedia(campaignId, media, preSignedUrlItem: IPreSignedUrlItem) {
  yield put(uploadMoodboardMediaInProgress(campaignId, preSignedUrlItem, media))

  const uploadLink = preSignedUrlItem.upload_url
  const uploadSuccess = yield call(presignedURLMediaUpload, 'PUT', uploadLink, media)

  const authToken: string = yield select(selectAuthToken)

  if (uploadSuccess) {
    try {
      const link = selectLink(preSignedUrlItem.links, 'uploaded')!
      yield call(
        networkRequest,
        link.method,
        link.href,
        {
          file_content_type: media.type,
          file_size: media.size,
        },
        authToken,
      )
      yield put(uploadMoodboardMediaSuccess(campaignId, preSignedUrlItem.id))
    } catch {
      yield put(uploadMoodboardMediaSuccess(campaignId, preSignedUrlItem.id))
    }
  } else {
    yield put(uploadMoodboardMediaError(campaignId, preSignedUrlItem.id))
    const link = selectLink(preSignedUrlItem.links, 'delete')!
    yield call(networkRequest, link.method, link.href, {}, authToken)
  }
}

export function* handleMoodboardMediaUpload(action: ReturnType<typeof uploadMoodboardMedia>) {
  const { files, campaignId } = action.payload
  const authToken = yield select(selectAuthToken)

  const preSignedUrlLink: IHateoasLink = yield select(selectRequestPreSignedUrlLink, campaignId)

  const response: IPreSignedUrlResponse = yield call(
    networkRequest,
    preSignedUrlLink.method,
    preSignedUrlLink.href,
    {
      moodboard_media_count: files.length,
    },
    authToken,
  )

  for (const [index, item] of response.items.entries()) {
    tasks[item.id] = yield fork(uploadMedia, campaignId, files[index], item)
  }
}

export function* watchMoodboardMediaUpload() {
  yield takeEvery(MoodboardMediaUploadActionTypes.UPLOAD_REQUEST, handleMoodboardMediaUpload)
  yield takeEvery(MoodboardMediaUploadActionTypes.CANCEL_REQUEST, cancelTask)
}
