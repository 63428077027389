import React, { PropsWithChildren } from 'react'

import { MediaModalImageViewer, MediaModalVideoPlayer } from '@components/UI/MediaModal'
import { bodyScrollHandler } from '@tribegroup'

export interface IScrollPositionContextProps {
  videoSrc: string | null
  setVideoSrc: React.Dispatch<React.SetStateAction<string | null>>
  imageSrc: string | null
  setImageSrc: React.Dispatch<React.SetStateAction<string | null>>
}

export const MediaModalContext = React.createContext({
  videoSrc: null,
} as IScrollPositionContextProps)

export const MediaModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [videoSrc, setVideoSrc] = React.useState<string | null>(null)
  const [imageSrc, setImageSrc] = React.useState<string | null>(null)

  const contextProps: IScrollPositionContextProps = {
    videoSrc,
    setVideoSrc,
    imageSrc,
    setImageSrc,
  }

  React.useEffect(() => {
    if (videoSrc || imageSrc) {
      bodyScrollHandler.set('media_modal', true)
    } else {
      bodyScrollHandler.set('media_modal', false)
    }
    return bodyScrollHandler.remove('media_modal')
  }, [videoSrc, imageSrc])

  return (
    <MediaModalContext.Provider value={contextProps}>
      <MediaModalVideoPlayer />
      <MediaModalImageViewer />
      {children}
    </MediaModalContext.Provider>
  )
}

export default MediaModalProvider
