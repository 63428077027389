import { createAction } from '@reduxjs/toolkit'

import { IMeta } from '@store'
import { IBrand, UpdateBrandActionTypes } from '@store/brands'

export const updateBrand = createAction(
  UpdateBrandActionTypes.UPDATE_REQUEST,
  (brandId: number, params, meta: IMeta) => ({
    payload: { brandId, params },
    meta,
  }),
)

export const updateBrandSuccess = createAction(
  UpdateBrandActionTypes.UPDATE_SUCCESS,
  (brand: IBrand, { toast, event }: IMeta) => ({
    payload: brand,
    meta: {
      toast,
      event,
    },
  }),
)

export const updateBrandError = createAction<any>(UpdateBrandActionTypes.UPDATE_ERROR)
