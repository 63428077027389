import Loading from '@components/UI/Loading'
import Theme from '@theme'
import { Icon } from '@tribegroup'
import { DEFAULT_SIZE_IN_REM, LoadingOverlay, PreviewContainer } from './UploadPreview.styled'

type Props = {
  file: File
  onRemove: () => void
  size?: number
  isUploading?: boolean
}

const DrawerFormUploadPreview: React.FC<Props> = ({ file, onRemove, size, isUploading }) => {
  const previewSize = size ?? DEFAULT_SIZE_IN_REM
  const isImage = file.type.includes('image')
  const isPdf = file.type.includes('pdf')

  return (
    <PreviewContainer onClose={isUploading ? undefined : onRemove}>
      {isImage && <Icon glyph="picture" size={previewSize} />}
      {isPdf && <Icon glyph="document-pdf" size={previewSize} />}
      {isUploading && (
        <LoadingOverlay>
          <Loading iconOnly yPadding={0} fullHeight color={Theme.whiteColor} />
        </LoadingOverlay>
      )}
    </PreviewContainer>
  )
}

export default DrawerFormUploadPreview
