import { Fragment, useContext } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { CampaignMenuActionsContext } from '@components/Campaign/Menu'
import CampaignShareTrigger from '@components/Campaign/Share/Trigger'
import InboxReferralTrigger from '@components/Inbox/Referral/Trigger'
import { ActionIconWrapper } from '@components/Inbox/TitleBar/TitleBar.styled'
import ActionIcon from '@components/UI/ActionIcon'
import Breakpoint from '@components/UI/Breakpoint'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { ICampaign, selectCampaign, selectFirebaseURL } from '@store/campaigns'
import { selectReferralProgramLink } from '@store/me'
import { selectAllowSubmissionsMultiSelect } from '@store/submissions'
import Theme from '@theme'
import { Button, Container, Text } from '@tribegroup'
import InboxTitleBarMoreMenu from './../MoreMenu'

interface ITitleBarActionsProps {
  campaignId: number
  redirectToPreview: VoidFunction
  toggleSelectionMode: VoidFunction
}

interface IInternalProps extends ITitleBarActionsProps, InjectedIntlProps {
  allowSelection: boolean
  campaign: ICampaign
  hasReferralProgramLink: boolean
  hasFirebaseUrl: boolean
}

export const InboxTitleBarActions = ({
  campaign,
  intl,
  redirectToPreview,
  toggleSelectionMode,
  allowSelection,
  hasReferralProgramLink,
  hasFirebaseUrl,
}: IInternalProps) => {
  const { onDownloadInstagramHandles, hasDownloadInstagramHandles, editLink } = useContext(
    CampaignMenuActionsContext,
  )

  return (
    <Fragment>
      <ActionIconWrapper>
        {hasDownloadInstagramHandles && (
          <Breakpoint desktop>
            <Container height={2.5} rightOuterSpacing={0.5}>
              <Flex center full>
                <Button
                  outlined
                  height={1.75}
                  small
                  noUppercase
                  noFocus
                  topInnerSpacing={0}
                  bottomInnerSpacing={0}
                  leftInnerSpacing={1.25}
                  rightInnerSpacing={1.25}
                  onClick={onDownloadInstagramHandles}
                >
                  <Text color={Theme.grey900Color} customFontSize={0.8125}>
                    <FormattedMessage id="inbox.titlebar.action.downloadInstagramHandles" />
                  </Text>
                </Button>
              </Flex>
            </Container>
          </Breakpoint>
        )}
      </ActionIconWrapper>
      {hasFirebaseUrl && <CampaignShareTrigger campaignId={campaign.id} />}
      {hasReferralProgramLink && <InboxReferralTrigger campaignId={campaign.id} />}
      <ActionIconWrapper>
        {Boolean(editLink) && (
          <ConnectedTrackedRoute
            eventName={EventTrackingNames.CampaignBuilderEditCampaign}
            campaignId={campaign.id}
          >
            <Link to={editLink}>
              <ActionIcon
                glyph="edit"
                highlighted={false}
                showNotification={false}
                color={Theme.grey900Color}
                tooltip={intl.formatMessage({ id: 'inbox.titlebar.action.editCampaign' })}
                size={1.5}
                elementName="edit-campaign"
              />
            </Link>
          </ConnectedTrackedRoute>
        )}
      </ActionIconWrapper>
      <ConnectedTrackedRoute
        eventName={EventTrackingNames.ToolbarPreviewCampaign}
        campaignId={campaign.id}
      >
        <ActionIconWrapper>
          <ActionIcon
            glyph="preview"
            highlighted={false}
            showNotification={false}
            color={Theme.grey900Color}
            tooltip={intl.formatMessage({ id: 'inbox.titlebar.action.preview' })}
            size={1.5}
            onClick={redirectToPreview}
            elementName="preview-campaign"
          />
        </ActionIconWrapper>
      </ConnectedTrackedRoute>
      {allowSelection && (
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.BulkSelectStart}
          campaignId={campaign.id}
        >
          <ActionIconWrapper>
            <ActionIcon
              glyph="select"
              highlighted={false}
              showNotification={false}
              color={Theme.grey900Color}
              tooltip={intl.formatMessage({ id: 'inbox.titlebar.action.select' })}
              size={1.5}
              onClick={toggleSelectionMode}
              elementName="select"
            />
          </ActionIconWrapper>
        </ConnectedTrackedRoute>
      )}
      <InboxTitleBarMoreMenu />
    </Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { campaignId }: ITitleBarActionsProps) => ({
  campaign: selectCampaign(state, campaignId),
  allowSelection: selectAllowSubmissionsMultiSelect(state, campaignId),
  hasReferralProgramLink: Boolean(selectReferralProgramLink(state)),
  hasFirebaseUrl: Boolean(selectFirebaseURL(state, campaignId)),
})

export default compose<IInternalProps, ITitleBarActionsProps>(
  injectIntl,
  connect(mapStateToProps),
)(InboxTitleBarActions)
