import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const ChartsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoint('tabletPortrait')`
  display: grid;
  grid-column-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
  `};
  ${breakpoint('desktop')`
    grid-template-columns: repeat(2, 1fr);
  `};
`

export const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 25rem;
  position: relative;
  ${breakpoint('tabletPortrait')`
    width: 80%;
  `};
`
