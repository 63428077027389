import { WatcherFunction } from '..'
import { watchActivateRequest } from './activate'
import { watchActivateWithFeeRequest } from './activateWithFee'
import { watchFetchCampaignRequest } from './campaign'
import { watchCampaignsRequest } from './campaigns'
import { watchConnectSocialAccountRequest } from './connectSocialAccount'
import { watchDisableRequireApprovalPromptRequest } from './disableRequireApprovalPrompt'
import { watchDisconnectSocialAccountRequest } from './disconnectSocialAccount'
import { watchDownloadInstagramHandles } from './downloadInstagramHandles'
import { watchFinalizeRequest } from './finalize'
import { watchPauseRequest } from './pause'
import { watchSaveRequest } from './save'
import { watchUpdatePreferences } from './updatePreferences'

export const campaignsSagas: ReadonlyArray<WatcherFunction> = [
  watchActivateRequest,
  watchFetchCampaignRequest,
  watchCampaignsRequest,
  watchFinalizeRequest,
  watchSaveRequest,
  watchPauseRequest,
  watchDisableRequireApprovalPromptRequest,
  watchUpdatePreferences,
  watchDownloadInstagramHandles,
  watchActivateWithFeeRequest,
  watchConnectSocialAccountRequest,
  watchDisconnectSocialAccountRequest,
]
