import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Flex, { FlexItem } from 'styled-flex-component'

import { FadeInWithVisibility, FadeOutWithVisibility, SlideIn, SlideOut } from '@lib/effects'
import Theme from '@theme'

interface IDrawerStyledProps {
  open: boolean
  width: number
}

interface IDrawerHeaderContentProps {
  left: boolean
  right: boolean
}

export const DRAWER_ANIMATION_DURATION_MS = 500

export const DrawerHeader = styled('div')`
  position: sticky;
  top: 0;
  height: 3.75rem;
  background: ${Theme.whiteColor};
  width: inherit;
  z-index: 2;
`

export const DrawerHeaderWrapper = styled(Flex)`
  height: inherit;
`

export const DrawerHeaderContent = styled<IDrawerHeaderContentProps>(FlexItem)`
  margin: 0 1.375rem;
  ${({ left }) =>
    left &&
    css`
      margin-right: 0;
    `};
  ${({ right }) =>
    right &&
    css`
      margin-left: 0;
    `};
`

export const DrawerHeaderDivider = styled('div')`
  margin-left: 1.625rem;
  margin-right: 1.625rem;
  border-top: solid 1px ${Theme.grey400Color};
  border-bottom: 0;
`

export const DrawerContent = styled('section')`
  position: inline-block;
  width: inherit;
  background: ${Theme.whiteColor};
`

export const DrawerStyled = styled<IDrawerStyledProps>('aside')`
  ${({ open, width }) =>
    css`
      position: absolute;
      top: 0;
      right: -40rem;
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      background: ${Theme.whiteColor};
      animation: ${open ? SlideIn : SlideOut} ${DRAWER_ANIMATION_DURATION_MS}ms ease-in-out forwards;
      ${breakpoint('phoneUpperBoundary')`
        width: ${width}rem;
      `};
    `};
`

export const DrawerGrayWrapper = styled<IDrawerStyledProps>('div')`
  position: fixed;
  left: 0;
  top: 0;
  width: inherit;
  height: inherit;
  background-color: rgb(0 0 0 / 50%);
  ${({ open }) =>
    css`
      animation: ${open ? FadeInWithVisibility : FadeOutWithVisibility}
        ${DRAWER_ANIMATION_DURATION_MS}ms ease-in forwards;
    `};
`

export const DrawerWrapperStyled = styled('div')`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  ${({ open }) =>
    css`
      animation: ${open ? FadeInWithVisibility : FadeOutWithVisibility}
        ${DRAWER_ANIMATION_DURATION_MS}ms ease-in forwards;
    `};
`
