import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import AnalyticsDetailCard from '@components/Analytics/DetailCard/DetailCard'
import { Hr } from '@components/UI/Hr/Hr.styled'
import { TRIBE_PRICING_PAGE_PRE_PURCHASE_METRICS_UTM } from '@constants'
import { IApplicationState } from '@store'
import { selectPrePurchaseAnalyticsPinterest } from '@store/prePurchaseAnalytics'
import { selectSubmissionId } from '@store/router'
import { selectSubmission } from '@store/submissions'
import Theme from '@theme'
import { Container } from '@tribegroup'
import { abbreviateNumberOrDash } from '@utils'
import { isNumber } from '../PrePurchase'
import { AnalyticsPrePurchaseGrid } from '../PrePurchase.styled'

const externalLink = TRIBE_PRICING_PAGE_PRE_PURCHASE_METRICS_UTM

export const AnalyticsPrePurchasePinterest = () => {
  const match = useRouteMatch()
  const { prePurchase } = useSelector((state: IApplicationState) => {
    const submissionId = selectSubmissionId(match)!
    const { identity_id } = selectSubmission(state, submissionId)

    return {
      prePurchase: selectPrePurchaseAnalyticsPinterest(state, identity_id),
    }
  })

  return (
    <>
      <AnalyticsPrePurchaseGrid>
        <AnalyticsDetailCard
          title="submission.prePurchaseAnalytics.pinterest.avgDailyEngagement"
          content={abbreviateNumberOrDash(prePurchase?.avg_daily_engagement)}
          elementName="pre-purchase-avg-daily-engagement"
          productMetricGroup="pre_purchase"
          productMetricValue="avg_daily_engagement"
          featured
        />
        <AnalyticsDetailCard
          title="submission.prePurchaseAnalytics.pinterest.avgEngagementPct"
          content={
            isNumber(prePurchase?.avg_engagement_pct) && (
              <FormattedMessage
                id="core.text.percent"
                values={{ value: prePurchase?.avg_engagement_pct }}
              />
            )
          }
          elementName="pre-purchase-avg-engagement-pct"
          productMetricGroup="pre_purchase"
          productMetricValue="avg_engagement_pct"
          featured
        />
        <AnalyticsDetailCard
          title="submission.prePurchaseAnalytics.pinterest.avgDailyImpressions"
          content={abbreviateNumberOrDash(prePurchase?.avg_daily_impressions)}
          elementName="pre-purchase-avg-daily-impressions"
          productMetricGroup="pre_purchase"
          productMetricValue="avg_daily_impressions"
          externalLink={externalLink}
          featured
        />
      </AnalyticsPrePurchaseGrid>
      <Hr backgroundColor={Theme.grey400Color} />
      <Container topOuterSpacing={1.75}>
        <AnalyticsPrePurchaseGrid>
          <AnalyticsDetailCard
            title="submission.prePurchaseAnalytics.pinterest.avgDailySaves"
            content={abbreviateNumberOrDash(prePurchase?.avg_daily_saves)}
            elementName="pre-purchase-avg-daily-saves"
            productMetricGroup="pre_purchase"
            productMetricValue="avg_daily_saves"
            externalLink={externalLink}
          />

          <AnalyticsDetailCard
            title="submission.prePurchaseAnalytics.pinterest.savePct"
            content={
              isNumber(prePurchase?.avg_save_pct) && (
                <FormattedMessage
                  id="core.text.percent"
                  values={{ value: prePurchase?.avg_save_pct }}
                />
              )
            }
            elementName="pre-purchase-avg-save-pct"
            productMetricGroup="pre_purchase"
            productMetricValue="avg_save_pct"
          />

          <AnalyticsDetailCard
            title="submission.prePurchaseAnalytics.pinterest.avgPinClickPct"
            content={
              isNumber(prePurchase?.avg_pin_click_pct) && (
                <FormattedMessage
                  id="core.text.percent"
                  values={{ value: prePurchase?.avg_pin_click_pct }}
                />
              )
            }
            elementName="pre-purchase-avg-pin-click-pct"
            productMetricGroup="pre_purchase"
            productMetricValue="avg_pin_click_pct"
          />

          <AnalyticsDetailCard
            title="submission.prePurchaseAnalytics.pinterest.avgOutboundClickPct"
            content={
              isNumber(prePurchase?.avg_outbound_click_pct) && (
                <FormattedMessage
                  id="core.text.percent"
                  values={{ value: prePurchase?.avg_outbound_click_pct }}
                />
              )
            }
            elementName="pre-purchase-avg-outbound-click-pct"
            productMetricGroup="pre_purchase"
            productMetricValue="avg_outbound_click_pct"
            externalLink={externalLink}
          />

          <AnalyticsDetailCard
            title="submission.prePurchaseAnalytics.pinterest.avgDailyOutboundClicks"
            content={abbreviateNumberOrDash(prePurchase?.avg_daily_outbound_clicks)}
            elementName="pre-purchase-avg-daily-outbound-clicks"
            productMetricGroup="pre_purchase"
            productMetricValue="avg_daily_outbound_clicks"
            externalLink={externalLink}
          />

          <AnalyticsDetailCard
            title="submission.prePurchaseAnalytics.pinterest.avgDailyPinClicks"
            content={abbreviateNumberOrDash(prePurchase?.avg_daily_pin_clicks)}
            elementName="pre-purchase-avg-daily-pin-clicks"
            productMetricGroup="pre_purchase"
            productMetricValue="avg_daily_pin_clicks"
            externalLink={externalLink}
          />
        </AnalyticsPrePurchaseGrid>
      </Container>
    </>
  )
}
