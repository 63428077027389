import { useContext } from 'react'

import { isEmpty } from 'lodash'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Loading from '@components/UI/Loading'
import RouterLink from '@components/UI/RouterLink/RouterLink.styled'
import { CampaignContext } from '@context/Campaign'
import { BrandManagerIdentityProviders, PageRoutes, SubmissionChannels } from '@enums'
import { IBrandManagerIdentity, selectIsFetching } from '@store/brandManagerIdentities'
import {
  selectBriefChannelPreference,
  selectCampaignConnectedSocialAccount,
} from '@store/campaigns'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { Button, Container, Text } from '@tribegroup'
import { EaseInWrapper } from './TikTok/TikTok.styled'
import { ConnectedAccountsDrawerIdentity } from './Identity'
import { ConnectedAccountsDrawerIdentityPlaceholder } from './Placeholder'

interface IProps {
  brandManagerIdentities?: IBrandManagerIdentity[]
}

const ConnectedAccountsDrawerIdentityList = ({ brandManagerIdentities }: IProps) => {
  const isFetching = useSelector(selectIsFetching)

  const { campaign } = useContext(CampaignContext)
  const location = useLocation()
  const fromRoute = `?from=${encodeURIComponent(`${location.pathname}${location.search}`)}`

  const requiredInstagramIdentity = brandManagerIdentities?.find(
    (identity) =>
      identity.display_name === campaign?.paid_partnership_handle?.replace('@', '') &&
      identity.channel === BrandManagerIdentityProviders.Instagram,
  )

  const {
    briefConnectedInstagramIdentity,
    briefConnectedTikTokIdentity,
    channelPreferences,
  } = useSelector((state: IApplicationState) => {
    return {
      briefConnectedInstagramIdentity: selectCampaignConnectedSocialAccount(
        state,
        campaign.id,
        BrandManagerIdentityProviders.Instagram,
      ),
      briefConnectedTikTokIdentity: selectCampaignConnectedSocialAccount(
        state,
        campaign.id,
        BrandManagerIdentityProviders.TikTok,
      ),
      channelPreferences: selectBriefChannelPreference(state, campaign.id),
    }
  })

  const tiktokBrandManagerIdentities = brandManagerIdentities?.filter(
    (identity) => identity.channel === BrandManagerIdentityProviders.TikTok,
  )

  const shouldShowTikTokIdentityList = !briefConnectedTikTokIdentity && tiktokBrandManagerIdentities

  if (isFetching && isEmpty(brandManagerIdentities)) {
    return <Loading />
  }

  return (
    <Container
      leftOuterSpacing={0.75}
      rightOuterSpacing={0.75}
      bottomOuterSpacing={0.5}
      overflow="auto"
      maxHeight="calc(100vh - 27.25rem)"
    >
      {channelPreferences?.includes(SubmissionChannels.Instagram) && (
        <ConnectedAccountsDrawerIdentity
          provider={BrandManagerIdentityProviders.Instagram}
          brandManagerIdentity={briefConnectedInstagramIdentity || requiredInstagramIdentity}
          isActive={Boolean(briefConnectedInstagramIdentity)}
        />
      )}

      {channelPreferences?.includes(SubmissionChannels.TikTok) && (
        <>
          <EaseInWrapper isHidden={!briefConnectedTikTokIdentity}>
            <ConnectedAccountsDrawerIdentity
              provider={BrandManagerIdentityProviders.TikTok}
              brandManagerIdentity={briefConnectedTikTokIdentity}
              isActive
            />
          </EaseInWrapper>

          {briefConnectedTikTokIdentity &&
            !campaign?.has_approved_tiktok_submission &&
            !campaign?.has_tiktok_campaign_id && (
              <Container
                borderRadius={0.5}
                backgroundColor={Theme.primary100Color}
                rightInnerSpacing={1}
                leftInnerSpacing={1}
                topInnerSpacing={0.75}
                bottomInnerSpacing={0.75}
                topOuterSpacing={1.25}
              >
                <Text italic light color={Theme.grey900Color}>
                  <FormattedHTMLMessage
                    id="connectedAccounts.drawer.owner.tiktok.accountEducation"
                    values={{ displayName: briefConnectedTikTokIdentity?.display_name }}
                  />
                </Text>
              </Container>
            )}

          {!briefConnectedTikTokIdentity && isEmpty(tiktokBrandManagerIdentities) && (
            <RouterLink to={`/${PageRoutes.ConnectedAccounts}${fromRoute}`}>
              <ConnectedAccountsDrawerIdentityPlaceholder
                glyph="social-tiktok"
                displayName="Ads Manager account"
              />
            </RouterLink>
          )}

          <EaseInWrapper isHidden={!shouldShowTikTokIdentityList}>
            {tiktokBrandManagerIdentities?.map((identity) => (
              <ConnectedAccountsDrawerIdentity
                key={identity?.id}
                provider={BrandManagerIdentityProviders.TikTok}
                brandManagerIdentity={identity}
              />
            ))}
          </EaseInWrapper>
        </>
      )}

      {!requiredInstagramIdentity && isEmpty(tiktokBrandManagerIdentities) && (
        <Container topOuterSpacing={1.25}>
          <RouterLink to={`/${PageRoutes.ConnectedAccounts}${fromRoute}`}>
            <Button scheme="primary" fullWidth outlined>
              <FormattedMessage id="connectedAccounts.drawer.owner.button.connectAccount" />
            </Button>
          </RouterLink>
        </Container>
      )}
    </Container>
  )
}

export { ConnectedAccountsDrawerIdentityList }
