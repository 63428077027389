import { Icon } from '@tribegroup'
import { IIconProps } from '@tribegroup/Icon/Icon'
import { HoverIconOffHover, HoverIconOnHover, HoverIconStyled } from './HoverIcon.styled'

interface IHoverIcon extends IIconProps {
  hoverColor: string
}

const HoverIcon = ({ hoverColor, color, ...iconProps }: IHoverIcon) => (
  <HoverIconStyled>
    <HoverIconOnHover>
      <Icon color={hoverColor} {...iconProps} />
    </HoverIconOnHover>
    <HoverIconOffHover>
      <Icon color={color} {...iconProps} />
    </HoverIconOffHover>
  </HoverIconStyled>
)

export default HoverIcon
