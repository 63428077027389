import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { IApplicationState } from '@store'
import { selectIsVideoMediaType } from '@store/mediaObjects'
import {
  selectAnalyticsComments,
  selectAnalyticsCostPerEngagement,
  selectAnalyticsEngagement,
  selectAnalyticsEngagementPercentage,
  selectAnalyticsLikes,
  selectAnalyticsRetweets,
  selectAnalyticsShares,
  selectAnalyticsVideoViews,
  selectHasAnalytics,
} from '@store/performance'
import { selectSubmissionId } from '@store/router'
import {
  selectFirstMediaObjectId,
  selectIdentityFollowersCount,
  selectIsProxySubmission,
  selectIsStorySocialSubmission,
  selectIsSubmissionFromFacebook,
  selectIsSubmissionFromTwitter,
  selectSocialSubmissionType,
  selectSubTotalCurrency,
} from '@store/submissions'

export const useSubmissionPerformanceDetails = () => {
  const match = useRouteMatch()
  const submissionId = selectSubmissionId(match)!
  return useSelector((state: IApplicationState) => {
    const mediaObjectId = selectFirstMediaObjectId(state, submissionId)

    return {
      submissionId,
      isStory: selectIsStorySocialSubmission(state, submissionId),
      isVideo: selectIsVideoMediaType(state, mediaObjectId),
      isSubmissionFromFacebook: selectIsSubmissionFromFacebook(state, submissionId),
      isSubmissionFromTwitter: selectIsSubmissionFromTwitter(state, submissionId),
      hasAnalytics: selectHasAnalytics(state, submissionId),
      identityFollowersCount: selectIdentityFollowersCount(state, submissionId),
      engagementPercentage: selectAnalyticsEngagementPercentage(state, submissionId),
      costPerEngagement: selectAnalyticsCostPerEngagement(state, submissionId),
      likes: selectAnalyticsLikes(state, submissionId),
      comments: selectAnalyticsComments(state, submissionId),
      videoViews: selectAnalyticsVideoViews(state, submissionId),
      shares: selectAnalyticsShares(state, submissionId),
      retweets: selectAnalyticsRetweets(state, submissionId),
      totalEngagement: selectAnalyticsEngagement(state, submissionId),
      currency: selectSubTotalCurrency(state, submissionId),
      socialSubmissionType: selectSocialSubmissionType(state, submissionId),
      isProxySubmission: selectIsProxySubmission(state, submissionId),
    }
  })
}

export default useSubmissionPerformanceDetails
