import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Avatar from '@components/UI/Avatar'
import { useGender } from '@hooks/useGender'
import { IInfluencerIdentity } from '@store/feedback'
import Theme from '@theme'
import { H5, Text } from '@tribegroup'
import { ChatProfileBlockStyled } from '../Chat.styled'
import {
  FeedbackProfileAvatarWrapperStyled,
  FeedbackProfileIndicatorPreviouslyPurchasedIcon,
  FeedbackProfileIndicatorStyled,
  FeedbackProfileInitials,
} from './ProfileBlock.styled'

interface IProps {
  influencer: IInfluencerIdentity
  initials?: string
}

export const FeedbackProfileBlock: React.FC<IProps> = ({ influencer, initials }) => {
  const { findGender } = useGender()
  const hasCountryAndState = influencer.country && influencer.state

  const selectedGender = findGender(influencer.gender)
  const genderDisplay = selectedGender?.display_name

  const hasAgeAndGender = influencer.age && genderDisplay

  let avatarUrl = influencer.avatar

  if (influencer.headshot) {
    avatarUrl = influencer.headshot.original_url
  }

  return (
    <ChatProfileBlockStyled center column>
      <FeedbackProfileAvatarWrapperStyled>
        {avatarUrl ? (
          <Avatar size={4.5} avatarSrc={avatarUrl} borderWidth={0} />
        ) : (
          <FeedbackProfileInitials flexCenter data-testid="feedback-profile-initials">
            <H5 color={Theme.grey900Color}>{initials}</H5>
          </FeedbackProfileInitials>
        )}
      </FeedbackProfileAvatarWrapperStyled>
      <Flex justifyCenter alignBaseline full>
        <H5 topOuterSpacing={1} color={Theme.grey900Color}>
          {influencer.name}
        </H5>
        {influencer.previously_purchased && (
          <FeedbackProfileIndicatorStyled alignCenter justifyCenter>
            <FeedbackProfileIndicatorPreviouslyPurchasedIcon
              data-testid="influencer-previously-purchased-icon"
              size={0.75}
              glyph="tick-heavy"
              color={Theme.secondaryColor}
            />
          </FeedbackProfileIndicatorStyled>
        )}
      </Flex>
      <Text color={Theme.grey700Color} textAlign="center" capitalize light>
        {hasCountryAndState ? `${influencer.state}, ` : influencer.state}
        {influencer.country}
      </Text>

      <Text color={Theme.grey700Color} textAlign="center" capitalize light>
        {hasAgeAndGender ? `${genderDisplay}, ` : genderDisplay}
        {influencer.age && (
          <Text color={Theme.grey700Color} light>
            <FormattedMessage id="submission.profile.age" values={{ age: influencer.age }} />
          </Text>
        )}
      </Text>
    </ChatProfileBlockStyled>
  )
}

export default FeedbackProfileBlock
