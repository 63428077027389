import { FetchInfluencerIdentityActionTypes, IInfluencerIdentity } from '@store/brandFans'

export const fetchInfluencerIdentity = (identityId: number) => ({
  payload: identityId,
  type: FetchInfluencerIdentityActionTypes.FETCH_REQUEST,
})

export const fetchInfluencerIdentitySuccess = (influencerIdentity: IInfluencerIdentity) => ({
  payload: influencerIdentity,
  type: FetchInfluencerIdentityActionTypes.FETCH_SUCCESS,
})

export const fetchInfluencerIdentityError = (error: any) => ({
  payload: error,
  type: FetchInfluencerIdentityActionTypes.FETCH_ERROR,
})
