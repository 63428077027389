import { PureComponent } from 'react'

import ChartJSDatalabels from 'chartjs-plugin-datalabels'
import { Bar as CharJSHorizontalBar } from 'react-chartjs-2'

import { defaultTheme, IThemeInterface } from '../../Theme'

interface ICharData {
  label: string
  value: number
  percentage: number
}

interface IHorizontalBarProps {
  data: ReadonlyArray<ICharData>
  showAsPercentage?: boolean
  roundPercentage?: boolean
  theme: IThemeInterface
}

export class HorizontalBar extends PureComponent<IHorizontalBarProps> {
  static defaultProps = {
    showAsPercentage: false,
    theme: defaultTheme,
  }

  render() {
    const { data, showAsPercentage, roundPercentage, theme } = this.props
    const dataPoints = data.map((dat) => {
      if (showAsPercentage) {
        return roundPercentage ? Math.round(dat.percentage) : dat.percentage
      }

      return dat.value
    })
    const labels = data.map((dat) => dat.label)

    const highestValue = Math.max(...dataPoints)
    const highestValueIdx = dataPoints.indexOf(highestValue)
    const backgroundColor = dataPoints.map((value) =>
      highestValue === value ? theme.primaryColor : theme.secondary500Color,
    )

    const config = {
      labels,
      datasets: [
        {
          data: dataPoints,
          backgroundColor,
          datalabels: {
            anchor: 'start',
            offset: 2,
            align: 'end',
            display: true,
            color: (curr) => {
              return curr.dataIndex === highestValueIdx
                ? theme.primaryColor
                : theme.secondary500Color
            },
            font: {
              size: 14,
              family: 'Rubik',
            },
            formatter: (value) => {
              return `${parseFloat(value).toLocaleString()}${showAsPercentage ? '%' : ''}`
            },
          },
        },
      ],
    }
    return (
      <CharJSHorizontalBar
        data={config}
        plugins={[ChartJSDatalabels]}
        options={{
          indexAxis: 'y',
          layout: {
            padding: {
              top: 0,
              right: 100,
              bottom: 0,
              left: 0,
            },
          },
          scales: {
            x: {
              border: {
                display: false,
              },
              grid: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
            y: {
              border: {
                display: false,
              },
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  family: 'Rubik',
                  size: 14,
                },
                color: theme.grey700Color,
              },
            },
          },
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    )
  }
}

export type { IHorizontalBarProps }
