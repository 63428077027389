import React from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { compose } from 'recompose'

import BuilderDialogUploadInProgress from '@components/Builder/Dialog/UploadInProgress'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IModalContextProps } from '@context/Modal'
import { EventTrackingNames, PageRoutes } from '@enums'
import { withBuilderValidation, withModal } from '@hocs'
import { useLocationSearch } from '@hooks/useLocationSearch'
import { IApplicationState } from '@store'
import { selectCampaignIdFromDraft } from '@store/drafts'
import { selectIsAnyUploading } from '@store/moodboard'
import { Button } from '@tribegroup'
import { BuilderTitleBarActionsConfirmCloseContext } from '../ConfirmClose'

interface IInternalProps extends IModalContextProps, IBuilderValidationContextProps {}

const BuilderTitleBarActionsButtonExit: React.FC<IInternalProps> = ({ formChanged, showModal }) => {
  const { isAnyUploading, campaignId } = useSelector((state: IApplicationState) => {
    const campaignId = selectCampaignIdFromDraft(state)

    return {
      campaignId,
      isAnyUploading: campaignId && selectIsAnyUploading(state, campaignId),
    }
  })

  const { from = `/${PageRoutes.Campaigns}/${campaignId}` } = useLocationSearch()
  const { showConfirmModal } = React.useContext(BuilderTitleBarActionsConfirmCloseContext)

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault()

    if (isAnyUploading) {
      return showModal(BuilderDialogUploadInProgress)
    }

    showConfirmModal(from, EventTrackingNames.CampaignBuilderEditDone)
  }

  return (
    <ConnectedTrackedRoute
      disableTracking={formChanged}
      eventName={EventTrackingNames.CampaignBuilderEditDone}
      campaignId={campaignId}
      additionalProperties={{
        action: 'close',
        current_filter: undefined,
      }}
    >
      <Button
        scheme="primary"
        small
        data-cy-element="campaign-builder-exit-button"
        data-testid="campaign-builder-exit-button"
        width={6.75}
        onClick={onClick}
      >
        <FormattedMessage id="core.text.exit" />
      </Button>
    </ConnectedTrackedRoute>
  )
}

export default compose<IInternalProps, {}>(
  withBuilderValidation,
  withModal,
)(BuilderTitleBarActionsButtonExit)
