import { createContext, PureComponent } from 'react'

import { connect } from 'react-redux'
import { RouterProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { ICampaign, selectCampaign } from '@store/campaigns'
import { IDraft, saveDraft, selectDraft, syncDraft } from '@store/drafts'
import { isEmpty } from '@utils'

export interface IDraftContextProps {
  draft: IDraft
  saveDraft: typeof saveDraft
}

interface IInternalProps extends RouterProps, IDraftContextProps {
  campaignId?: number
  campaign?: ICampaign
  syncDraft: typeof syncDraft
}

export const DraftContext = createContext({ draft: {} } as IDraftContextProps)

export class DraftProvider extends PureComponent<IInternalProps> {
  componentDidMount() {
    const { campaignId } = this.props
    if (campaignId) {
      this.props.syncDraft(campaignId)
    }
  }

  componentDidUpdate(prevProps: IInternalProps) {
    const { campaignId } = this.props
    if (campaignId && isEmpty(prevProps.campaign) && !isEmpty(this.props.campaign)) {
      this.props.syncDraft(campaignId)
    }
  }

  render() {
    const { draft, campaignId, children, saveDraft: saveDraftAction } = this.props
    const isReady = !campaignId || (Boolean(campaignId) && !isEmpty(draft))

    const contextProps: IDraftContextProps = {
      draft,
      saveDraft: saveDraftAction,
    }

    return isReady && <DraftContext.Provider value={contextProps}>{children}</DraftContext.Provider>
  }
}

const mapStateToProps = (state: IApplicationState, { match }) => {
  const campaignId = match.params && match.params.campaignId
  return {
    campaignId: campaignId && parseInt(campaignId, 10),
    campaign: selectCampaign(state, campaignId),
    draft: selectDraft(state),
  }
}

const mapDispatchToProps = {
  saveDraft,
  syncDraft,
}

export default compose<IInternalProps, any>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(DraftProvider)
