import { BuilderDefault, BuilderSteps, PaymentMethods } from '@enums'
import { IDraft } from '@store/drafts'
import { isSocialCampaign as getIsSocialCampaign } from '@utils/campaign'

interface IValidationRules {
  name: string
  min?: number
  required?: boolean
  maxLength?: number
  arrayLength?: number
  maxArrayLength?: number
  objectiveSelectionRequired?: boolean
}

const hasAssociatedBrandFanGroups = (draft: IDraft) => {
  if (draft.brand_fan_group_ids) {
    return Boolean(draft.brand_fan_group_ids.length)
  }
  return false
}

export const validateCampaignObjectives = (value) =>
  Boolean(
    value
      ? (value.provided && value.provided.filter((element) => element.value === true).length) ||
          (value && value.other)
      : true,
  )

export const validateStep = (draft: IDraft, rules: ReadonlyArray<IValidationRules>) => {
  return Boolean(
    rules.every((rule) => {
      const rawValue = draft[rule.name]
      const value = typeof rawValue === 'string' ? rawValue.trim() : rawValue
      const minTest = rule.min ? value >= rule.min : true
      const requireTest = rule.required ? Boolean(value) || value === 0 : true
      const maxLengthTest = rule.maxLength && value ? Boolean(value.length <= rule.maxLength) : true
      const arrayLength = rule.arrayLength ? value && value.length >= rule.arrayLength : true
      const objectiveSelectionRequiredTest = rule.objectiveSelectionRequired
        ? validateCampaignObjectives(value)
        : true

      const maxArrayLength = rule.maxArrayLength
        ? value && value.length <= rule.maxArrayLength
        : true
      return Boolean(
        [
          requireTest,
          minTest,
          maxLengthTest,
          arrayLength,
          maxArrayLength,
          objectiveSelectionRequiredTest,
        ].every((test) => test === true),
      )
    }),
  )
}

interface IValidateStepsOptions {
  minimumInvoiceBudget?: number
}

export const getInvoiceMinimumBudget = (draft: IDraft, minimumInvoiceBudget = 1) => {
  const disabledAttributes = draft.disabled_attributes || []
  if (disabledAttributes.includes('budget')) {
    return 0
  }
  return minimumInvoiceBudget
}

export const validateSteps = (draft: IDraft, options?: IValidateStepsOptions) => {
  const isSocialCampaign = getIsSocialCampaign(draft)
  const isAssociatedToBrandFanGroups = hasAssociatedBrandFanGroups(draft)
  const isInvoice = draft && draft.payment_method === PaymentMethods.INVOICE

  const NONE_REQUIRED = 0

  return {
    [BuilderSteps.BRAND]: validateStep(draft, [
      {
        name: 'brand_id',
        required: true,
      },
    ]),
    [BuilderSteps.CAMPAIGN]: validateStep(draft, [
      {
        name: 'product_name',
        required: true,
        maxLength: BuilderDefault.DEFAULT_TEXTAREA_MAX_LENGTH,
      },
      { name: 'hero_16x9_original_url', required: true },
      { name: 'call_to_action', required: true, maxLength: BuilderDefault.MAX_LENGTH_CALLTOACTION },
      { name: 'product_where_to_find', maxLength: BuilderDefault.MAX_LENGTH_WHERETOGETPRODUCT },
      { name: 'product_text', required: true, maxLength: 500 },
      { name: 'product_category_primary_id', required: true },
      { name: 'campaign_objectives', objectiveSelectionRequired: isSocialCampaign },
    ]),
    [BuilderSteps.BRIEF]: validateStep(draft, [
      {
        name: 'moodboard_images',
        required: true,
        arrayLength: BuilderDefault.MIN_ARRAY_LENGTH_MOODBOARD,
      },
      {
        name: 'objective',
        required: true,
        maxLength: BuilderDefault.MAX_LENGTH_OBJECTIVE,
      },
      {
        name: 'dos',
        required: true,
        arrayLength: BuilderDefault.MIN_ARRAY_LENGTH_DOSANDDONTS,
        maxArrayLength: BuilderDefault.MAX_ARRAY_LENGTH_DOSANDDONTS,
      },
      {
        name: 'donts',
        required: true,
        arrayLength: BuilderDefault.MIN_ARRAY_LENGTH_DOSANDDONTS,
        maxArrayLength: BuilderDefault.MAX_ARRAY_LENGTH_DOSANDDONTS,
      },
      {
        name: 'channels',
        arrayLength: isSocialCampaign ? BuilderDefault.MIN_ARRAY_SOCIAL_CHANNEL : NONE_REQUIRED,
      },
    ]),
    [BuilderSteps.BUDGET]: validateStep(draft, [
      {
        name: 'indicative_budget_cents',
        required: true,
        min: isInvoice
          ? getInvoiceMinimumBudget(draft, options && options.minimumInvoiceBudget)
          : 1,
      },
      {
        name: 'demographic_target_region_ids',
        arrayLength: !isAssociatedToBrandFanGroups
          ? BuilderDefault.MIN_ARRAY_DEMOGRAPHIC_REGION
          : NONE_REQUIRED,
      },
      {
        name: 'demographic_gender',
        arrayLength:
          isSocialCampaign && !isAssociatedToBrandFanGroups
            ? BuilderDefault.MIN_ARRAY_DEMOGRAPHIC_GENDER
            : NONE_REQUIRED,
      },
      {
        name: 'brand_fan_group_ids',
        arrayLength: isAssociatedToBrandFanGroups
          ? BuilderDefault.MIN_ARRAY_BRAND_FAN_GROUP
          : NONE_REQUIRED,
      },
    ]),
    [BuilderSteps.SUBMIT]: validateStep(draft, [
      { name: 'start_date', required: true },
      { name: 'end_date', required: true },
    ]),
  }
}
