import { ComponentType, PropsWithChildren } from 'react'

import styled from 'styled-components'
import Flex from 'styled-flex-component'

export const MultiframeSliderWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
`

interface ITrackProps {
  active: number
  count: number
  animationEnabled: boolean
}

export const MultiframeSliderTrack: ComponentType<PropsWithChildren<ITrackProps>> = styled.div`
  position: relative;
  overflow: visible;
  ${({ animationEnabled }) =>
    animationEnabled &&
    `
      transition-duration: 50ms, 300ms;
      transition-property: opacity, transform;
      transition-timing-function: linear, ease;
    `};
  ${({ count, active }) => `
    display: grid;
    width: ${count * 100}%;
    grid-template-columns: repeat(${count}, 1fr);
    transform: translate3d(-${active * (100 / count)}%, 0, 0);
  `};
`

const sliderBaseStyles = (active: boolean) => `
  width: 100%;
  box-sizing: border-box;
  ${
    !active &&
    `
    transition: opacity;
    transition-delay: 300ms;
    opacity: 0;
  `
  };
`

export const SlideWrapper = styled.div`
  height: 100%;
  opacity: 1;
  ${({ active }) => sliderBaseStyles(active)}
`

export const SlidePortraitWrapper = styled(Flex)`
  height: 80vh;
  background: #000;
  ${({ active }) => sliderBaseStyles(active)}
`
