import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { BrandFanGroupMemberWarningContext } from '@context/BrandFanGroupMemberWarning'
import { IApplicationState } from '@store'
import { selectHasPendingBrandFanGroupRequest } from '@store/brandFans'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'

interface IProps {
  groupId: number
  isMemberOfGroup: boolean
}

interface IInternalProps extends IProps {
  hasPendingRequest: boolean
}

const IconIndicator = ({ glyph, color = Theme.defaultColor, size = 1.5 }) => (
  <Icon
    data-testid={`brand-fan-groups-list-item-action-${glyph}`}
    glyph={glyph}
    color={color}
    size={size}
  />
)

const BrandFansInfluencerIdentityGroupsItemAction: React.FC<IInternalProps> = ({
  groupId,
  isMemberOfGroup,
  hasPendingRequest,
}) => {
  const { selectedGroupId } = React.useContext(BrandFanGroupMemberWarningContext)
  const hasGroupWarning = selectedGroupId === groupId

  if (hasGroupWarning) {
    return <IconIndicator glyph="warning" color={Theme.primaryColor} />
  }

  if (hasPendingRequest) {
    return (
      <Container textAlign="center">
        <IconIndicator glyph="spinner" size={1.325} />
      </Container>
    )
  }

  if (isMemberOfGroup) {
    return (
      <Flex justifyCenter>
        <IconIndicator glyph="minus" />
        <IconIndicator glyph="tick" size={1.375} />
      </Flex>
    )
  }
  return <IconIndicator glyph="plus" size={1.25} />
}

const mapStateToProps = (state: IApplicationState, { groupId }: IProps) => ({
  hasPendingRequest: selectHasPendingBrandFanGroupRequest(state, groupId),
})

export { BrandFansInfluencerIdentityGroupsItemAction }
export default compose<IInternalProps, IProps>(connect(mapStateToProps))(
  BrandFansInfluencerIdentityGroupsItemAction,
)
