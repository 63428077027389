import { createContext, PropsWithChildren, PureComponent } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { ICampaign, selectCampaign } from '@store/campaigns'
import { selectCampaignId, selectSubmissionId } from '@store/router'
import { ISubmission, selectSubmission } from '@store/submissions'
import {
  getCampaignProperties,
  getSubmissionLocationProps,
  getSubmissionProperties,
  toEventsTracking,
} from '@utils'

export interface IAnalyticsTrackingContextProps {
  getSubmissionTrackingProps: (submission?: ISubmission, campaign?: ICampaign) => object
  getSubmissionTrackingEvent: (eventName: string, submission: ISubmission) => object | undefined
  getCampaignTrackingProps: (campaign?: ICampaign) => object
  getCampaignTrackingEvent: (
    eventName: string,
    campaign: ICampaign,
    otherProps: object,
  ) => object | undefined
  getSubmissionLocationProps: () => object
}

interface IInternalProps extends RouteComponentProps {
  campaign: ICampaign
  submission: ISubmission
}

export const AnalyticsTrackingContext = createContext({})

export class AnalyticsTrackingProvider extends PureComponent<PropsWithChildren<IInternalProps>> {
  getSubmissionTrackingProps = (
    submission: ISubmission = this.props.submission,
    campaign: ICampaign = this.props.campaign,
  ) => ({
    ...getCampaignProperties(campaign),
    ...getSubmissionProperties(submission),
    ...getSubmissionLocationProps(this.props.history.location),
  })

  getCampaignTrackingProps = (campaign: ICampaign = this.props.campaign) => ({
    ...getCampaignProperties(campaign),
    ...getSubmissionLocationProps(this.props.history.location),
  })

  getSubmissionTrackingEvent = (
    eventName: string,
    submission: ISubmission = this.props.submission,
  ) => {
    return toEventsTracking(eventName, this.getSubmissionTrackingProps(submission))
  }

  getCampaignTrackingEvent = (
    eventName: string,
    campaign: ICampaign = this.props.campaign,
    otherProps: object = {},
  ) => {
    return toEventsTracking(eventName, {
      ...this.getCampaignTrackingProps(campaign),
      ...otherProps,
    })
  }

  getSubmissionLocationProps = () => getSubmissionLocationProps(this.props.history.location)

  render() {
    const contextProps: IAnalyticsTrackingContextProps = {
      getSubmissionTrackingProps: this.getSubmissionTrackingProps,
      getSubmissionTrackingEvent: this.getSubmissionTrackingEvent,
      getCampaignTrackingProps: this.getCampaignTrackingProps,
      getCampaignTrackingEvent: this.getCampaignTrackingEvent,
      getSubmissionLocationProps: this.getSubmissionLocationProps,
    }

    return (
      <AnalyticsTrackingContext.Provider value={contextProps}>
        {this.props.children}
      </AnalyticsTrackingContext.Provider>
    )
  }
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  const submissionId = selectSubmissionId(match) as number
  return {
    campaign: selectCampaign(state, campaignId!),
    submission: selectSubmission(state, submissionId!),
  }
}

export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps),
)(AnalyticsTrackingProvider)
