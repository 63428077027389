/* eslint-disable react/display-name */
import { MobileMenuControlsContext } from '@context/MobileMenuControls'

export const withMobileMenuControls = (Component) => (props) => {
  return (
    <MobileMenuControlsContext.Consumer>
      {(contextProps) => <Component {...props} {...contextProps} />}
    </MobileMenuControlsContext.Consumer>
  )
}
