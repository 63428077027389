import { put, takeLatest } from 'redux-saga/effects'

import { saveDraft, SaveDraftActionTypes, saveDraftError, saveDraftSuccess } from '@store/drafts'

export function* handleSaveDraft(action: ReturnType<typeof saveDraft>) {
  try {
    yield put(saveDraftSuccess(action.payload))
  } catch (error) {
    yield put(saveDraftError(error))
  }
}

export function* watchSaveRequest() {
  yield takeLatest(SaveDraftActionTypes.SAVE_DRAFT_REQUEST, handleSaveDraft)
}
