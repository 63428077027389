enum BrandedContentAdStatusTypes {
  Requested = 'requested',
  Approved = 'approved',
  Declined = 'declined',
  Completed = 'completed',
  PendingCreatorApproval = 'pending_creator_approval',
  Expired = 'expired',
}

export { BrandedContentAdStatusTypes }
