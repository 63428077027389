import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import ShareThisLibraryToast from '@components/ContentLibrary/TitleBar/Buttons/ShareThisLibraryButton/Toast'
import ShareableContentLibraryTrackedRoute from '@components/ShareableContentLibrary/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectShareableContentLibraryTotal } from '@store/mediaObjects'
import { Button, Header, Toaster } from '@tribegroup'
import { clipboard } from '@utils'
interface IInternalProps {
  librarySize: number
}

const ShareThisLibrary: React.FC<IInternalProps> = ({ librarySize }) => {
  const onClick = async () => {
    await clipboard.copy(window.location.href)
    Toaster.createToast(ShareThisLibraryToast)
  }
  return (
    <ShareableContentLibraryTrackedRoute
      eventName={EventTrackingNames.ShareableCLShareCLButton}
      additionalProperties={{ library_size: librarySize }}
    >
      <Button scheme="primary" small width={11.5625} onClick={onClick} noFocus>
        <Header cta small>
          <FormattedMessage id="contentLibrary.shareable.shareThis" />
        </Header>
      </Button>
    </ShareableContentLibraryTrackedRoute>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  librarySize: selectShareableContentLibraryTotal(state),
})

export { ShareThisLibrary }
export default compose(connect(mapStateToProps))(ShareThisLibrary)
