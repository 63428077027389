import { createAction } from '@reduxjs/toolkit'

import { FetchPurchaseOrdersActionTypes, IPurchaseOrderResponse } from '@store/purchaseOrders'

export const fetchPurchaseOrders = createAction<undefined>(
  FetchPurchaseOrdersActionTypes.FETCH_REQUEST,
)

export const fetchPurchaseOrdersSuccess = createAction<IPurchaseOrderResponse>(
  FetchPurchaseOrdersActionTypes.FETCH_SUCCESS,
)

export const fetchPurchaseOrdersError = createAction<any>(
  FetchPurchaseOrdersActionTypes.FETCH_ERROR,
)
