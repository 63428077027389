import styled from 'styled-components'

import Theme from '@theme'
import { Container } from '@tribegroup'

export const TagContainer = styled(Container)`
  color: ${Theme.primaryColor};
  border-radius: 0.25rem;
  border: 1px solid ${Theme.primary100Color};
`
