import styled from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'

export default styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${Theme.grey400Color};
  width: 100%;
  display: block;
`

export const DetailCardTitle = styled(Flex)`
  padding: 0.5625rem 0.5rem 0.375rem 0.75rem;
  border-bottom: 1px solid ${Theme.grey400Color};
`

export const DetailCardTitleHeader = styled('div')`
  white-space: nowrap;
`

export const DetailCardBody = styled.div`
  padding: 0.375rem 0.5rem 0.75rem 0.75rem;
  min-height: 1.5625rem;
`
