import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { SideBarBlock } from '@components/UI/SideBar'
import { ICampaignContextProps } from '@context/Campaign'
import { IApplicationState } from '@store'
import { selectSubmissionsFilters } from '@store/campaigns'
import { selectCampaignId, selectCurrentView } from '@store/router'
import InboxFiltersItem from './Item'

export interface IInternalProps extends ICampaignContextProps {
  currentView: string
  filters: ReadonlyArray<string>
}

const InboxFilters: React.FC<IInternalProps> = ({ filters }) => (
  <SideBarBlock
    header={<FormattedMessage id="inbox.sidebar.submissions" />}
    items={filters.map((filter: string) => (
      <InboxFiltersItem filter={filter} key={filter} />
    ))}
  />
)

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  return {
    currentView: selectCurrentView(state),
    filters: selectSubmissionsFilters(state, campaignId),
  }
}

export { InboxFilters }
export default compose<IInternalProps, {}>(withRouter, connect(mapStateToProps))(InboxFilters)
