import React, { PropsWithChildren } from 'react'

import { ALL } from '@constants'
import { CampaignStatuses } from '@enums'
import hooks from '@hooks'

export interface ICampaignListFilterContextProps {
  status: string
}

export const CampaignListFilterContext = React.createContext({} as ICampaignListFilterContextProps)

const CampaignListFilterProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const searchParams = hooks.useLocationSearch()
  const parsedStatus =
    searchParams.status && (decodeURIComponent(searchParams.status) as CampaignStatuses)
  const status = Object.values(CampaignStatuses).includes(parsedStatus) ? parsedStatus : ALL

  const contextProps: ICampaignListFilterContextProps = {
    status,
  }

  return (
    <CampaignListFilterContext.Provider value={contextProps}>
      {children}
    </CampaignListFilterContext.Provider>
  )
}

export { CampaignListFilterProvider }
export default CampaignListFilterProvider
