import React from 'react'

import BuilderBudgetTargetDemographicAge from './Age'
import BuilderBudgetTargetDemographicFollowers from './Followers'
import BuilderBudgetTargetDemographicGender from './Gender'
import BuilderBudgetTargetDemographicLocation from './Location'

const BuilderBudgetTargetDemographicSocial: React.FC = () => (
  <React.Fragment>
    <BuilderBudgetTargetDemographicFollowers />
    <BuilderBudgetTargetDemographicAge />
    <BuilderBudgetTargetDemographicGender />
    <BuilderBudgetTargetDemographicLocation />
  </React.Fragment>
)

export default BuilderBudgetTargetDemographicSocial
