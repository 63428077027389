import React from 'react'

import { FormattedMessage } from 'react-intl'

import LinkToDetails from '@components/Submission/Card/LinkToDetails'
import { IModalComponentProps } from '@context/Modal'
import Theme from '@theme'
import { Button, CloseIcon, Container, H3, Header, Modal, Text, Tip } from '@tribegroup'

interface IProps extends IModalComponentProps {
  submissionId: number
}

const SubmissionCardModalLicenseInboxCarouselEducation: React.FC<IProps> = ({
  isOpen = false,
  onRequestClose,
  submissionId,
}) => {
  return (
    <Modal reactModalProps={{ isOpen }} width={20}>
      <Container
        data-testid="carousel-license-inbox-modal"
        leftInnerSpacing={0.25}
        rightInnerSpacing={0.25}
        topInnerSpacing={0.25}
        bottomInnerSpacing={1}
        removeAnchorTextDecoration
      >
        <CloseIcon onClick={onRequestClose} />
        <Container bottomOuterSpacing={1}>
          <Tip />
        </Container>
        <Container maxWidth={15.25}>
          <H3 color={Theme.defaultColor} bottomOuterSpacing={1}>
            <FormattedMessage id="licensing.modal.carouselInInbox.tip.header" />
          </H3>
        </Container>
        <Container bottomOuterSpacing={1.75}>
          <Text color={Theme.grey800Color}>
            <Container bottomOuterSpacing={0.5}>
              <FormattedMessage id="core.text.hotTip" />:
            </Container>
            <FormattedMessage id="licensing.modal.carouselInInbox.tip1" />
            <br />
            <FormattedMessage id="licensing.modal.carouselInInbox.tip2" />
            <br />
            <FormattedMessage id="licensing.modal.carouselInInbox.tip3" />
            <br />
            <FormattedMessage id="licensing.modal.carouselInInbox.tip4" />
            <br />
          </Text>
        </Container>
        <LinkToDetails submissionId={submissionId}>
          <Button scheme="primary" fullWidth small>
            <Header cta small>
              <FormattedMessage id="licensing.modal.carouselInInbox.button" />
            </Header>
          </Button>
        </LinkToDetails>
      </Container>
    </Modal>
  )
}

export default SubmissionCardModalLicenseInboxCarouselEducation
