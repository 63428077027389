import { call, put, select, takeEvery } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchInfluencerIdentitiesUsingNext,
  fetchInfluencerIdentitiesUsingNextError,
  fetchInfluencerIdentitiesUsingNextSuccess,
  InfluencerIdentitiesUsingNextActionTypes,
  selectInfluencerIdentitiesNextLink,
} from '@store/influencerIdentities'
import { selectCurrentSortBy } from '@store/router'
import { networkRequest } from '@utils'

export function* handleFetchInfluencerIdentitiesUsingNext(
  action: ReturnType<typeof fetchInfluencerIdentitiesUsingNext>,
) {
  try {
    const campaignId = action.payload
    const authToken: string = yield select(selectAuthToken)

    const sortBy = yield select(selectCurrentSortBy)
    const influencerIdentitiesLink: IHateoasLink = yield select(
      selectInfluencerIdentitiesNextLink,
      campaignId,
    )

    const result = yield call(
      networkRequest,
      influencerIdentitiesLink.method,
      influencerIdentitiesLink.href.replace('{sort_by}', sortBy),
      undefined,
      authToken,
    )
    yield put(fetchInfluencerIdentitiesUsingNextSuccess(result, campaignId))
  } catch (error) {
    yield put(fetchInfluencerIdentitiesUsingNextError(error))
  }
}

export function* watchFetchInfluencerIdentitiesUsingNextRequest() {
  yield takeEvery(
    InfluencerIdentitiesUsingNextActionTypes.FETCH_REQUEST,
    handleFetchInfluencerIdentitiesUsingNext,
  )
}
