import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import SubmissionThumbnailStatusLabel from '.'

const SubmissionThumbnailStatusLabelLicensed: React.FC = () => (
  <SubmissionThumbnailStatusLabel
    firstText={<FormattedMessage id="core.text.licensed" />}
    color={Theme.grey900Color}
  />
)

export default SubmissionThumbnailStatusLabelLicensed
