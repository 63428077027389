import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'redux'

import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import { withSubmissionListFilter } from '@hocs'
import { IApplicationState } from '@store'
import { selectCampaignId } from '@store/router'
import { selectFilteredSubmissionsSortOptions } from '@store/submissions'
import { ISubmissionSortProps } from './Sort'
import SubmissionSortWrapper from './SortWrapper'

const SubmissionSortTiles = (props: ISubmissionSortProps) => <SubmissionSortWrapper {...props} />

const mapStateToProps = (
  state: IApplicationState,
  { filter, match }: ISubmissionListFilterContextProps & RouteComponentProps,
) => {
  const campaignId = selectCampaignId(match) as number
  const sortOptionsLink = selectFilteredSubmissionsSortOptions(state, campaignId, filter)
  return {
    campaignId,
    sortOptions: sortOptionsLink && sortOptionsLink.sort_by,
  }
}

export default compose(
  withRouter,
  withSubmissionListFilter,
  connect(mapStateToProps),
)(SubmissionSortTiles)
