import React from 'react'

import Cropper from 'react-cropper'
import Dropzone from 'react-dropzone'

import { Button } from '../Button'
import { Icon } from '../Icon'
import { dataURLtoBlob } from '../utils'
import {
  AspectRatioWrapper,
  CloseIconWrapper,
  CropActions,
  CropperWrapper,
  DroppedFileCover,
  DropzoneWrapper,
  ImageCropperWrapper,
  ImageLoadingCover,
  UploadField,
} from './ImageCropper.styled'

import 'cropperjs/dist/cropper.css'

export interface IImageCropperProps extends React.FormHTMLAttributes<HTMLInputElement> {
  /**
   * Error message displayed
   *
   */
  errorMessage?: React.ReactNode

  /**
   * Text displayed in the crop button
   *
   */
  cropLabel: string

  /**
   * Text displayed in the cancel crop button
   *
   */
  cropCancelLabel: string

  /**
   * function called once an image has been dragged and cropped. Uses the new image blob as an arg
   *
   */
  onCropComplete?: (blob: Blob) => void

  /**
   * function called when a file is rejected
   *
   */
  onRejected?: (blob: Blob) => void

  /**
   * function called when the image cropper is reset
   *
   */
  onReset?: VoidFunction

  /**
   * function called when the image cropper is focused
   *
   */
  onFocus?: VoidFunction

  /**
   * default image src
   *
   */
  defaultImageSrc?: string

  /**
   * shows image cropper as loading (only when an image has already been cropped)
   *
   */
  isLoading?: boolean

  /**
   * File type accepted
   *
   */
  accepts?: string

  /**
   * function called when a drop has been accepted
   *
   */
  onDropAccepted?: (file: File) => void
}

export interface IImageCropperState {
  droppedImage?: string
  croppedImage?: string
  filename?: string
  isCropping: boolean
  clean: boolean
}

export class ImageCropper extends React.PureComponent<IImageCropperProps, IImageCropperState> {
  state = {
    isCropping: false,
    droppedImage: undefined,
    croppedImage: undefined,
    filename: undefined,
    clean: true,
  }

  private dropzoneRef = React.createRef() as any
  private cropperRef = React.createRef() as any

  onDropAccepted = (files) => {
    const droppedFile = files[0]
    this.setState(
      {
        droppedImage: URL.createObjectURL(droppedFile),
        filename: droppedFile.name,
        clean: false,
      },
      () => {
        if (this.props.onDropAccepted) {
          this.props.onDropAccepted(droppedFile)
        }
      },
    )
  }

  reset = (event) => {
    event.preventDefault()
    this.setState(
      {
        droppedImage: undefined,
        filename: undefined,
        croppedImage: undefined,
        isCropping: false,
        clean: false,
      },
      () => {
        if (this.props.onReset) {
          this.props.onReset()
        }
      },
    )
  }

  onDropRejected = (file) => {
    if (this.props.onRejected) {
      this.props.onRejected(file)
    }
  }

  onCropClick = (event) => {
    event.preventDefault()
    this.setState({
      isCropping: true,
    })
    const canvas: HTMLCanvasElement = this.cropperRef.current.cropper.getCroppedCanvas()
    if (typeof canvas.toBlob === 'function') {
      canvas.toBlob((blob: Blob) => {
        if (this.props.onCropComplete) {
          this.props.onCropComplete(blob)
        }
        this.setState({
          croppedImage: URL.createObjectURL(blob),
          isCropping: false,
        })
      })
    } else {
      const blob = dataURLtoBlob(canvas.toDataURL('image/jpeg', 0.5))
      this.setState({
        croppedImage: URL.createObjectURL(blob),
        isCropping: false,
      })
    }
  }

  public render() {
    const {
      errorMessage,
      cropLabel,
      cropCancelLabel,
      defaultImageSrc,
      isLoading,
      accepts,
    } = this.props
    const { droppedImage, croppedImage, filename, isCropping, clean } = this.state

    const savedImage = (clean && defaultImageSrc) || croppedImage
    if (savedImage) {
      return (
        <ImageCropperWrapper>
          <AspectRatioWrapper>
            <img src={savedImage} alt={filename} />
            <DroppedFileCover />
            {isLoading && (
              <ImageLoadingCover>
                <Icon glyph="spinner" color="#fff" className="spinner" />
              </ImageLoadingCover>
            )}
            <CloseIconWrapper>
              <Icon glyph="cross-heavy" size={1.5} onClick={this.reset} />
            </CloseIconWrapper>
          </AspectRatioWrapper>
          {errorMessage}
        </ImageCropperWrapper>
      )
    }

    if (droppedImage) {
      return (
        <ImageCropperWrapper>
          <CropperWrapper>
            <Cropper
              ref={this.cropperRef}
              src={droppedImage}
              style={{ width: '100%' }}
              aspectRatio={16 / 9}
              guides
              zoomable={false}
            />

            {isCropping && (
              <ImageLoadingCover>
                <Icon glyph="spinner" color="#fff" className="spinner" />
              </ImageLoadingCover>
            )}
          </CropperWrapper>
          <CropActions>
            <Button leftOuterSpacing={1} onClick={this.reset} small quiet>
              {cropCancelLabel}
            </Button>
            <Button leftOuterSpacing={1} onClick={this.onCropClick} small scheme="primary">
              {cropLabel}
            </Button>
          </CropActions>
        </ImageCropperWrapper>
      )
    }

    return (
      <ImageCropperWrapper>
        <AspectRatioWrapper>
          <DropzoneWrapper>
            <Dropzone
              multiple={false}
              ref={this.dropzoneRef}
              accept={accepts}
              onDropAccepted={this.onDropAccepted}
              onDropRejected={this.onDropRejected}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div {...getRootProps()} onFocus={this.props.onFocus} data-element="image-cropper">
                  <input {...getInputProps()} />

                  <UploadField active={isDragActive}>
                    <Icon glyph="plus" size={1.5} />
                  </UploadField>
                </div>
              )}
            </Dropzone>
          </DropzoneWrapper>
        </AspectRatioWrapper>
        {errorMessage}
      </ImageCropperWrapper>
    )
  }
}

export default ImageCropper
