import BuilderCampaignForm from '@components/Builder/Campaign/Form'
import BuilderTitleBar from '@components/Builder/TitleBar'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import { BuilderValidationProvider } from '@context/BuilderValidation'
import { CampaignProvider } from '@context/Campaign'
import { DraftProvider } from '@context/Draft'
import { BuilderSteps } from '@enums'

const CreateCampaignPage = () => (
  <MainLayout>
    <CampaignProvider>
      <DraftProvider>
        <BuilderValidationProvider currentStep={BuilderSteps.CAMPAIGN}>
          <HeadTag id={`builder.steps.${BuilderSteps.CAMPAIGN}.title`} />
          <BuilderTitleBar />
          <BuilderCampaignForm />
        </BuilderValidationProvider>
      </DraftProvider>
    </CampaignProvider>
  </MainLayout>
)

export default CreateCampaignPage
