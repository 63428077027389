import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { ICompany, selectCompanyInfo } from '@store/me'
import Theme from '@theme'
import { H5, Panel, Text } from '@tribegroup'
import { Label, List, ListItem } from './../Account.styled'

interface IAccountCompanyProps {
  disabled?: boolean
}

interface IInternalProps extends IAccountCompanyProps {
  company: ICompany
}

export const AccountCompany = ({ company, disabled }: IInternalProps) => {
  const {
    company_name,
    company_accounts_payable_email,
    company_billing_contact,
    company_address_street,
    company_address_city,
    company_address_state,
    company_address_country,
    company_address_postcode,
  } = company

  const countryWithPostcode = [company_address_country, company_address_postcode]
    .filter(Boolean)
    .join(' ')

  return (
    <Panel
      disabled={disabled}
      title={
        <H5 color={Theme.grey900Color}>
          <FormattedMessage id="account.company.title" />
        </H5>
      }
    >
      <List>
        {company_name && (
          <ListItem>
            <Label>
              <Text xsmall uppercase color={Theme.grey800Color}>
                <FormattedMessage id="account.company.name" />
              </Text>
            </Label>
            <Text color={Theme.grey900Color}>{company_name}</Text>
          </ListItem>
        )}
        {company_accounts_payable_email && (
          <ListItem>
            <Label>
              <Text xsmall uppercase color={Theme.grey800Color}>
                <FormattedMessage id="account.company.accountEmail" />
              </Text>
            </Label>
            <Text color={Theme.grey900Color}>{company_accounts_payable_email}</Text>
          </ListItem>
        )}
        {company_billing_contact && (
          <ListItem>
            <Label>
              <Text xsmall uppercase color={Theme.grey800Color}>
                <FormattedMessage id="account.company.billingContact" />
              </Text>
            </Label>
            <Text color={Theme.grey900Color}>{company_billing_contact}</Text>
          </ListItem>
        )}
        <ListItem>
          <Label>
            <Text xsmall uppercase color={Theme.grey800Color}>
              <FormattedMessage id="account.company.billingAddress" />
            </Text>
          </Label>
          <Text color={Theme.grey900Color}>
            {[
              company_address_street,
              company_address_city,
              company_address_state,
              countryWithPostcode,
            ]
              .filter(Boolean)
              .map((info) => (
                <Fragment key={info}>
                  {info}
                  <br />
                </Fragment>
              ))}
          </Text>
        </ListItem>
      </List>
    </Panel>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  company: selectCompanyInfo(state),
})

export default compose<IInternalProps, IAccountCompanyProps>(connect(mapStateToProps))(
  AccountCompany,
)
