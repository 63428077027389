import { useEffect } from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Icon, Text } from '@tribegroup'
import { SnackbarContainer } from './Base.styled'
import { AUTO_HIDE_DURATION_IN_MS } from './constants'
import { useSnackBar } from './context'
import { ISnackbar } from './types'

export const SnackbarError: React.FC<{ snackbar: ISnackbar }> = ({ snackbar }) => {
  const { removeSnackbar } = useSnackBar()

  useEffect(() => {
    setTimeout(() => {
      removeSnackbar(snackbar.id)
    }, AUTO_HIDE_DURATION_IN_MS)
  }, [])

  return (
    <SnackbarContainer backgroundColor={Theme.error100Color}>
      <Icon glyph="alert-solid" color={Theme.errorColor} />
      <Text small>
        <FormattedMessage id={snackbar.message} />
      </Text>
    </SnackbarContainer>
  )
}
