import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Container, H4, Text } from '@tribegroup'

const BuilderBriefChannelsTiktokHeader: React.FC = () => (
  <Container topOuterSpacing={1}>
    <H4 color={Theme.grey900Color} bottomOuterSpacing={1}>
      <FormattedMessage id="builder.brief.socialMedia.tiktok.tags.header" />
    </H4>
    <Text color={Theme.grey900Color}>
      <FormattedHTMLMessage id="builder.brief.socialMedia.tiktok.tags.subheader" />
    </Text>
  </Container>
)

export default BuilderBriefChannelsTiktokHeader
