enum CampaignStatuses {
  Active = 'active',
  Expired = 'expired',
  Paused = 'paused',
  Scheduled = 'pending',
  InReview = 'pending_review',
  Draft = 'draft',
  Completed = 'completed',
}

enum CampaignDeclineStatuses {
  DeclinedByBrand = 'declined_by_brand',
  DeclinedByInfluencer = 'declined_by_influencer',
  DeclinedByTribe = 'declined_by_tribe',
}

export { CampaignStatuses, CampaignDeclineStatuses }

type StatusFilters = CampaignStatuses | 'all'
export type CampaignStatusFilters = StatusFilters
