import React, { PropsWithChildren } from 'react'

import { Information } from '@components/UI/Information'
import InformationContent from '@components/UI/Information/Content/Content'

interface IInfluencerIdentityRowLabelTooltipProps {
  title: React.ReactNode
  body: React.ReactNode
  width?: number
  waypointOffset?: number
}

const InfluencerIdentityRowLabelTooltip: React.FC<PropsWithChildren<
  IInfluencerIdentityRowLabelTooltipProps
>> = ({ children, title, body, width = 15, waypointOffset }) => {
  return (
    <Information target={children} width={width} delay={1} waypointOffset={waypointOffset}>
      <InformationContent title={title} content={body} />
    </Information>
  )
}

export default InfluencerIdentityRowLabelTooltip
