enum MemberRoleTypes {
  Owner = 'owner',
  Collaborator = 'collaborator',
  Guest = 'guest',
  GuestWithFinancials = 'guest_with_financials',
  Client = 'client',
  SolutionCollaborator = 'solution_collaborator',
}

export { MemberRoleTypes }
