import { all, fork } from 'redux-saga/effects'

import { analyticsSagas } from './analytics'
import { audienceInsightsSagas } from './audienceInsights'
import { audienceReportingSagas } from './audienceReporting'
import { authSagas } from './auth'
import { billingsSagas } from './billings'
import { brandFansSagas } from './brandFans'
import { brandManagerIdentitiesSagas } from './brandManagerIdentities'
import { brandsSagas } from './brands'
import { campaignsSagas } from './campaigns'
import { categoriesSagas } from './categories'
import { completeSignUpSagas } from './completeSignup'
import { creatorBioSagas } from './creator-bio'
import { draftsSagas } from './drafts'
import { errorsSagas } from './errors'
import { facebookAdsSagas } from './facebookAds'
import { feedbackSagas } from './feedback'
import { influencerIdentitiesSaga } from './influencerIdentities'
import { ipstackSagas } from './ipstack'
import { licensesSagas } from './licenses'
import { meSagas } from './me'
import { mediaSagas } from './media'
import { mediaObjectsSagas } from './mediaObjects'
import { membersSagas } from './members'
import { metricDisputeSagas } from './metricDisputes'
import { moodboardSagas } from './moodboard'
import { notesSagas } from './notes'
import { passwordSagas } from './password'
import { submissionPerformanceSagas } from './performance'
import { postCampaignReportSagas } from './postCampaignReport'
import { prePurchaseSagas } from './prePurchaseAnalytics'
import { publishedSummarySagas } from './publishedSummary'
import { purchaseOrderSagas } from './purchaseOrders'
import { rootResourceSagas } from './rootResource'
import { shortlistSummarySagas } from './shortlistSummary'
import { submissionsSagas } from './submissions'
import { toastsSagas } from './toasts'

export type WatcherFunction = () => Generator

export function* rootSaga() {
  yield all(
    [
      ...analyticsSagas,
      ...authSagas,
      ...audienceInsightsSagas,
      ...campaignsSagas,
      ...categoriesSagas,
      ...brandsSagas,
      ...brandFansSagas,
      ...brandManagerIdentitiesSagas,
      ...meSagas,
      ...draftsSagas,
      ...passwordSagas,
      ...rootResourceSagas,
      ...submissionsSagas,
      ...mediaSagas,
      ...mediaObjectsSagas,
      ...membersSagas,
      ...facebookAdsSagas,
      ...feedbackSagas,
      ...toastsSagas,
      ...notesSagas,
      ...licensesSagas,
      ...billingsSagas,
      ...ipstackSagas,
      ...influencerIdentitiesSaga,
      ...errorsSagas,
      ...postCampaignReportSagas,
      ...submissionPerformanceSagas,
      ...prePurchaseSagas,
      ...shortlistSummarySagas,
      ...audienceReportingSagas,
      ...creatorBioSagas,
      ...publishedSummarySagas,
      ...moodboardSagas,
      ...purchaseOrderSagas,
      ...completeSignUpSagas,
      ...metricDisputeSagas,
    ].map((saga) => fork(saga)),
  )
}
