import styled from 'styled-components'

import Theme from '@theme'

export const Message = styled.p`
  color: ${Theme.grey800Color};
  margin-bottom: 3.125rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
`
