import React from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import AnalyticsPostCampaignReportPostList from '@components/Analytics/PostCampaignReport/Post/List/List'
import { AnalyticsPostCampaignReportReelList } from '@components/Analytics/PostCampaignReport/Reel/List/List'
import AnalyticsPostCampaignReportStoryList from '@components/Analytics/PostCampaignReport/Story/List/List'
import AnalyticsPostCampaignReportTikTokList from '@components/Analytics/PostCampaignReport/TikTok/List/List'
import AnalyticsPostCampaignReportVideoList from '@components/Analytics/PostCampaignReport/Video/List/List'
import { PostCampaignReportSubmissionIdProvider } from '@context/PostCampaignReportSubmissionId'
import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { selectSubmissionMetricIds } from '@store/postCampaignReport'
import { selectActivePCRChannel, selectCampaignId } from '@store/router'
import { Container } from '@tribegroup'
import PostCampaignReportSubmissionListRow from './Row/Row'

export interface IInternalProps {
  submissionMetricIds: ReadonlyArray<number>
  activeChannel: PCRChannels
  campaignId: number
}

const PostCampaignReportSubmissionList: React.FC<IInternalProps> = ({
  submissionMetricIds,
  activeChannel,
  campaignId,
}) => {
  if (activeChannel === PCRChannels.IGPosts) {
    return (
      <Container bottomInnerSpacing={4.5}>
        <AnalyticsPostCampaignReportPostList />
      </Container>
    )
  }

  if (activeChannel === PCRChannels.IGVideos) {
    return (
      <Container bottomInnerSpacing={4.5}>
        <AnalyticsPostCampaignReportVideoList />
      </Container>
    )
  }

  if (activeChannel === PCRChannels.IGStories) {
    return (
      <Container bottomInnerSpacing={4.5}>
        <AnalyticsPostCampaignReportStoryList />
      </Container>
    )
  }

  if (activeChannel === PCRChannels.TikTok) {
    return (
      <Container bottomInnerSpacing={4.5}>
        <AnalyticsPostCampaignReportTikTokList />
      </Container>
    )
  }

  if (activeChannel === PCRChannels.Reel) {
    return (
      <Container bottomInnerSpacing={4.5}>
        <AnalyticsPostCampaignReportReelList />
      </Container>
    )
  }

  return (
    <Container topOuterSpacing={3.125} bottomInnerSpacing={4.5}>
      {submissionMetricIds.map((submissionMetricId) => (
        <PostCampaignReportSubmissionIdProvider
          key={`post-metrics-${submissionMetricId}`}
          submissionId={submissionMetricId}
        >
          <PostCampaignReportSubmissionListRow campaignId={campaignId} />
        </PostCampaignReportSubmissionIdProvider>
      ))}
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match)!
  const activeChannel = selectActivePCRChannel(state)
  return {
    campaignId,
    activeChannel,
    submissionMetricIds: selectSubmissionMetricIds(state, campaignId, activeChannel),
  }
}

export { PostCampaignReportSubmissionList }
export default compose(withRouter, connect(mapStateToProps))(PostCampaignReportSubmissionList)
