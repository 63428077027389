import React from 'react'

import { verticalAlignPropType } from '@tribegroup/Container/Container.styled'
import TextStyled from './Text.styled'

export interface ITextProps extends React.HTMLAttributes<HTMLSpanElement> {
  /**
   * Sets the color of the text
   *
   * @default rgba(0, 0, 0, 0.7)
   */

  color?: string

  /**
   * Displays the text with smaller font size
   *
   * @default false
   */
  small?: boolean

  /**
   * Displays the text in uppercase
   *
   * @default false
   */
  uppercase?: boolean

  /**
   * Displays the text with capitalized first letter for each word
   *
   * @default false
   */
  capitalize?: boolean

  /**
   * Displays the text with extra small font size
   *
   * @default false
   */
  xsmall?: boolean

  /**
   * Displays the text with double extra small font size
   *
   * @default false
   */
  xxsmall?: boolean

  /**
   * Displays the text in medium weight
   *
   * @default false
   */
  medium?: boolean

  /**
   * Displays the text in medium light
   *
   * @default false
   */
  light?: boolean

  /**
   * Displays the text alignment
   *
   * @default 'left'
   */
  textAlign?: 'left' | 'center' | 'right'

  /**
   * Adds whitespace nowrap to span
   *
   * @default false
   */
  nowrap?: boolean

  /**
   * display as block instead of inline
   *
   * @default false
   */
  block?: boolean

  /**
   * display text in italic
   *
   * @default false
   */
  italic?: boolean

  /**
   * custom font size (in rem) for text sizes that do not fit the preset fontsizes
   *
   * @default undefined
   */
  customFontSize?: number

  /**
   * specify the height of a line.
   */
  lineHeight?: number

  /**
   * custom font weight for text weights that do not fit the preset fontweights
   *
   * @default undefined
   */
  customFontWeight?: number

  /**
   * custom font weight for text weights that do not fit the preset letterSpacing
   *
   * @default undefined
   */
  customletterSpacing?: string

  /**
   * custom text decoration
   *
   * @default undefined
   */
  textDecoration?: string

  verticalAlign?: verticalAlignPropType | number

  /**
   * Truncate text with ellipsis
   */
  truncated?: boolean
}

export class Text extends React.PureComponent<ITextProps> {
  public render() {
    return <TextStyled {...this.props} />
  }
}
