import { Component } from 'react'

import { connect } from 'react-redux'
import { RouterProps, withRouter } from 'react-router'
import { compose } from 'redux'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { ICampaignContextProps } from '@context/Campaign'
import { EventTrackingNames } from '@enums'
import { withCampaign } from '@hocs'
import { IApplicationState } from '@store'
import { ISubmission, selectSubmission } from '@store/submissions'
import { objectToQueryString, queryStringtoObject } from '@utils'
import { isSocialCampaign } from '@utils/campaign'
import { buildSubmissionUrl } from '@utils/urls'
import SubmissionThumbnailImage from './Image/Image'
import SubmissionThumbnailStatus from './Status/Status'
import { SelectedSubmissionIndicator, Wrapper } from './Thumbnail.styled'

interface ISubmissionThumbnailProps {
  submissionId: number
  fromSubmission: ISubmission
}

interface IInternalProps extends ICampaignContextProps, ISubmissionThumbnailProps, RouterProps {
  submission: ISubmission
}

export class SubmissionThumbnail extends Component<IInternalProps> {
  render() {
    const { submissionId, submission, fromSubmission, campaign, history } = this.props

    const isSocial = isSocialCampaign(campaign)

    const isFromAndPropSubmissionIdsEqual = submissionId === fromSubmission.id
    const useSubmission = isFromAndPropSubmissionIdsEqual ? fromSubmission : submission

    const searchParams = queryStringtoObject(history.location.search)
    const redirectObject = {
      pathname: buildSubmissionUrl(campaign, useSubmission),
      search: objectToQueryString({
        ...searchParams,
        from: searchParams.from || fromSubmission.id,
      }),
    }

    return (
      <ConnectedTrackedRoute
        eventName={EventTrackingNames.ViewSubmission}
        campaignId={campaign.id}
        submissionId={submissionId}
        additionalProperties={{ source: 'other_submissions' }}
      >
        <Wrapper to={redirectObject}>
          <SelectedSubmissionIndicator visible={isFromAndPropSubmissionIdsEqual} />
          <SubmissionThumbnailImage submissionId={useSubmission.id} isSocial={isSocial} />
          {useSubmission.scheduled_publish_date && (
            <SubmissionThumbnailStatus submission={useSubmission} />
          )}
        </Wrapper>
      </ConnectedTrackedRoute>
    )
  }
}

const mapStateToProps = (
  state: IApplicationState,
  { submissionId }: ISubmissionThumbnailProps,
) => ({
  submission: selectSubmission(state, submissionId),
})

export default compose(withCampaign, withRouter, connect(mapStateToProps))(SubmissionThumbnail)
