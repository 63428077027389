import React from 'react'

import { FormattedMessage } from 'react-intl'

import { EventTrackingNames } from '@enums'
import { Button, Header } from '@tribegroup'
import BrandFansGroupRowCreateCampaignLink from './Link'

interface IProps {
  groupId: number
  canCreateCampaign: boolean
}

const BrandFansGroupRowCreateCampaignButton: React.FC<IProps> = ({
  groupId,
  canCreateCampaign,
}) => {
  return (
    <BrandFansGroupRowCreateCampaignLink
      eventName={EventTrackingNames.BrandFansGroupsListCTA}
      groupId={groupId}
      canCreateCampaign={canCreateCampaign}
    >
      <Button type="button" small scheme="primary" height={2}>
        <Header cta small>
          <FormattedMessage id="brandFans.groups.actions.createCampaign" />
        </Header>
      </Button>
    </BrandFansGroupRowCreateCampaignLink>
  )
}

export { BrandFansGroupRowCreateCampaignButton }
export default BrandFansGroupRowCreateCampaignButton
