import { ReactNode } from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { isEmpty } from '@utils'
import Price from '../Price/Price'

interface IBreakdownRowProps {
  currency: string
  quantity: number
  medium?: boolean
  label: ReactNode
  color?: string
  labelWidth?: number
  isCrossedOut?: boolean
  showUpdated?: boolean
  priceLabel?: ReactNode
}

const CostBreakdownRow = ({
  label,
  currency,
  quantity,
  labelWidth = 10,
  medium = false,
  color = Theme.defaultColor,
  isCrossedOut = false,
  showUpdated = false,
  priceLabel,
}: IBreakdownRowProps) => {
  const hasPrice = !isEmpty(quantity) && !isEmpty(currency)
  const isZeroValue = quantity === 0
  const textDecoration = isCrossedOut ? 'line-through' : undefined
  return (
    <Container bottomOuterSpacing={0.125}>
      <Flex justifyBetween>
        <Container width={labelWidth} rightOuterSpacing={0.25}>
          <Text color={color} medium={medium} textDecoration={textDecoration} capitalize>
            {label}
          </Text>
          {showUpdated && (
            <Text light italic>
              -
              <FormattedMessage id="core.text.updated" />
            </Text>
          )}
        </Container>
        <Container>
          <Text color={color} medium={medium} textDecoration={textDecoration}>
            {hasPrice ? (
              <Price currency={currency} quantity={quantity} rounded={isZeroValue} />
            ) : (
              <FormattedMessage id="core.text.notapplicable" />
            )}
          </Text>
          {priceLabel && (
            <Text
              block
              color={color}
              medium={medium}
              textDecoration={textDecoration}
              textAlign="right"
            >
              {priceLabel}
            </Text>
          )}
        </Container>
      </Flex>
    </Container>
  )
}

export { CostBreakdownRow }
