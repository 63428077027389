import React from 'react'

import flamingox1 from '@images/flamingo.png'
import flamingox2 from '@images/flamingo@2x.png'
import flamingox3 from '@images/flamingo@3x.png'

const InboxReferralGraphics: React.FC = () => (
  <img
    width={200}
    height={134}
    src={flamingox1}
    srcSet={`${flamingox1} 300w, ${flamingox2} 768w, ${flamingox3} 1280w`}
    alt="referral-program-modal-illustration"
  />
)

export default InboxReferralGraphics
