import { FormattedMessage } from 'react-intl'

import { CoachmarkContent, CoachmarkSection } from '@components/UI/Coachmark/Coachmark.styled'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { PAID_PARTNERSHIP_LEARN_HOW } from '@constants'
import { IDraftContextProps } from '@context/Draft'
import { EventTrackingNames } from '@enums'
import { withDraft } from '@hocs'
import Theme from '@theme'
import { Container, Link, Text } from '@tribegroup'

const BuilderBriefChannelsInstagramPaidPartershipTipCoachMark: React.FC<IDraftContextProps> = ({
  draft,
}) => {
  return (
    <CoachmarkContent>
      <CoachmarkSection>
        <Text color={Theme.defaultColor} small>
          <FormattedMessage id="builder.brief.socialMedia.paidpartnership.requireApprovals.tooltip" />
        </Text>
        <Container topOuterSpacing={0.5}>
          <ConnectedTrackedRoute
            draftId={draft.id}
            eventName={EventTrackingNames.CampaignBuilderEducationLink}
            additionalProperties={{
              link_url: PAID_PARTNERSHIP_LEARN_HOW,
              tooltip_name: 'builder.brief.socialMedia.paidpartnership.requireApprovals.tip',
              link_name: 'core.text.learnHow.withArrow',
            }}
          >
            <Link href={PAID_PARTNERSHIP_LEARN_HOW} target="_blank" color={Theme.secondaryColor}>
              <Text color={Theme.primaryColor} small>
                <FormattedMessage id="core.text.learnHow.withArrow" />
              </Text>
            </Link>
          </ConnectedTrackedRoute>
        </Container>
      </CoachmarkSection>
    </CoachmarkContent>
  )
}

export default withDraft(BuilderBriefChannelsInstagramPaidPartershipTipCoachMark)
