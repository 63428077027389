import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import { FadeIn } from '@lib/effects'
import { mobileGutter } from '@theme'

export interface ICardGridProps {
  gap?: number
  noSideBar?: boolean
}

const SmallerGridBreakpoints = css`
  ${breakpoint('phoneUpperBoundary')`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `};
  ${breakpoint('tabletPortrait')`
    grid-template-columns: 1fr 1fr 1fr;
  `};
  ${breakpoint('desktop')`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`

const BaseGridBreakpoints = css`
  ${breakpoint('tabletPortrait')`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `};
  ${breakpoint('desktop')`
    grid-template-columns: 1fr 1fr 1fr;
  `};
  ${breakpoint('largeDesktop')`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`

const CardGridBaseStyle = css`
  display: grid;
  box-sizing: border-box;

  ${({ gap, noSideBar }) => css`
    grid-gap: ${gap || mobileGutter}rem;
    ${noSideBar ? SmallerGridBreakpoints : BaseGridBreakpoints};
  `};
  > div {
    display: block;
    max-width: none;
    width: auto;
    transition: box-shadow 0.2s, background 0.2s;
    animation: ${FadeIn} 0.2s linear;
    margin-bottom: 0.75rem;
    ${breakpoint('tabletPortrait')`
        margin-bottom: 0;
      `};
  }
`

export const CardGridWithInfinteScroll = styled<ICardGridProps, any>('div')`
  .infinite-scroll-component {
    > div:first-of-type {
      ${CardGridBaseStyle};
      padding-bottom: 0.5rem;
    }
  }
`

export default styled<ICardGridProps, any>('div')`
  ${CardGridBaseStyle};
`
