import { IMeta } from '@store'
import { ICreditCardDetails, IMe, MeActionTypes, UpdateCreditCardActionTypes } from '@store/me'

export const updateCreditCard = (cardDetails: ICreditCardDetails, meta: IMeta = {}) => ({
  payload: cardDetails,
  meta,
  type: UpdateCreditCardActionTypes.UPDATE_CREDITCARD_REQUEST,
})

export const updateCreditCardSuccess = (me: IMe) => ({
  payload: me,
  meta: {
    toast: 'Credit card updated successfully',
  },
  type: MeActionTypes.FETCH_SUCCESS,
})

export const updateCreditCardError = (error: any) => ({
  type: UpdateCreditCardActionTypes.UPDATE_CREDITCARD_ERROR,
  payload: error,
})
