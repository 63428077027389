import { isEmpty } from '@utils'

export const queryStringtoObject = (queryString: string) => {
  const qs = queryString.startsWith('?') ? queryString.slice(1) : queryString
  return qs.split('&').reduce((accum, curr) => {
    const [key, value] = curr.split('=')
    return key && value ? { ...accum, [key]: value } : accum
  }, {}) as any
}

export const objectToQueryString = (params: object): string => {
  if (isEmpty(params)) {
    return ''
  }
  const filtered = Object.keys(params).filter(key => Boolean(params[key]))
  if (isEmpty(filtered)) {
    return ''
  }
  return `?${filtered.map(key => `${key}=${params[key]}`).join('&')}`
}

export default queryStringtoObject
