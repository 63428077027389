import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { IStoryMetricsState } from '@store/postCampaignReport'
import { returnSecondIfDeepEqual } from '@utils'
import { indexById } from '@utils/groupBy'
import { IStoryMetrics, StoryMetricsActionTypes } from './types'

const initialState: IStoryMetricsState = {
  isFetching: false,
  errors: undefined,
  storyMetricsById: {},
  storyMetricsSummary: {},
  storyMetricIdsByChannel: {},
}

const reducer: Reducer<IStoryMetricsState> = (state = initialState, action) => {
  switch (action.type) {
    case StoryMetricsActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }

    case StoryMetricsActionTypes.FETCH_SUCCESS: {
      const { campaignId, storyMetrics, channel } = action.payload

      return {
        ...state,
        storyMetricsById: returnSecondIfDeepEqual(
          indexById(storyMetrics.items, state.storyMetricsById, 'submission_id'),
          state.storyMetricsById,
        ),
        storyMetricsSummary: returnSecondIfDeepEqual(
          {
            ...state.storyMetricsSummary,
            [campaignId]: {
              ...state.storyMetricsSummary[campaignId],
              [channel]: storyMetrics.metadata,
            },
          },
          state.storyMetricsSummary,
        ),
        storyMetricIdsByChannel: returnSecondIfDeepEqual(
          {
            ...state.storyMetricIdsByChannel,
            [campaignId]: {
              ...state.storyMetricIdsByChannel[campaignId],
              [channel]: storyMetrics.items.map((item: IStoryMetrics) => item.submission_id),
            },
          },
          state.storyMetricIdsByChannel,
        ),
        errors: undefined,
        isFetching: false,
      }
    }

    case StoryMetricsActionTypes.FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as storyMetricsReducer }

export default reducer
