import { IMeta } from '@store'
import { ISubmission, RescheduleActionTypes } from '@store/submissions'

interface IRescheduleParams {
  scheduled_publish_date?: string
  publish_within_hours?: number
}

export const reschedule = (
  submissionId: number,
  campaignId: number,
  params: IRescheduleParams,
  meta?: IMeta,
) => ({
  payload: { submissionId, campaignId, params },
  type: RescheduleActionTypes.RESCHEDULE_REQUEST,
  meta,
})

export const rescheduleSuccess = (submission: ISubmission) => ({
  payload: submission,
  type: RescheduleActionTypes.RESCHEDULE_SUCCESS,
})

export const rescheduleError = (error: any) => ({
  payload: error,
  type: RescheduleActionTypes.RESCHEDULE_ERROR,
})
