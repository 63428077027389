import { createAction } from '@reduxjs/toolkit'

import { FeedbackDraftsActionTypes } from '../types'

export const addFeedbackDraft = createAction(
  FeedbackDraftsActionTypes.ADD_FEEDBACK_DRAFT,
  (submissionId: number, message: string) => ({
    payload: { submissionId, message },
  }),
)
