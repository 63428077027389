import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { authSuccess, selectAuthToken } from '@store/auth'
import {
  changePassword,
  ChangePasswordActionTypes,
  changePasswordError,
  changePasswordSuccess,
  selectChangePasswordLink,
} from '@store/me'
import { networkRequest } from '@utils'

export function* handleChangePassword(action: ReturnType<typeof changePassword>) {
  try {
    const { history, redirect } = action.meta
    const authToken: string = yield select(selectAuthToken)
    const changePasswordLink: IHateoasLink = yield select(selectChangePasswordLink)
    const auth = yield call(
      networkRequest,
      changePasswordLink.method,
      changePasswordLink.href,
      action.payload,
      authToken,
    )
    yield put(authSuccess(auth))
    yield put(changePasswordSuccess())

    if (history && redirect) {
      yield call(history.push, redirect)
    }
  } catch (error) {
    yield put(changePasswordError(error))
  }
}

export function* watchChangePasswordRequest() {
  yield takeLatest(ChangePasswordActionTypes.CHANGE_PASSWORD_REQUEST, handleChangePassword)
}
