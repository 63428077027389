interface ILabelValueObject {
  label: string
  value: string
}

export const toLabelValuePair = (
  options: ReadonlyArray<any>,
  key?: string,
  value?: string,
): ReadonlyArray<ILabelValueObject> =>
  options.reduce((accum, curr) => {
    if (typeof curr === 'string') {
      return [...accum, { label: curr, value: curr }]
    }
    if (key && value) {
      return [...accum, { label: curr[value], value: curr[key] }]
    }
    return accum
  }, [])
