import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const gap = 0.5625

export default styled.div`
  margin-bottom: 1.25rem;

  > div:not(:last-child) {
    margin-bottom: ${gap}rem;
  }

  ${breakpoint('phoneUpperBoundary')`
    display: grid;
    grid-column-gap: ${gap}rem;
    grid-row-gap: ${gap}rem;
    grid-template-columns: repeat(auto-fit, calc(50% - ${gap / 2}rem));
    > div:not(:last-child) {
      margin-bottom: 0;
    }
  `};
  ${breakpoint('desktop')`
    display: flex;
    > div:not(:last-child) {
      margin-right: ${gap}rem;
    }
  `};
`
