import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Avatar from '@components/UI/Avatar'
import { IMemberAvatar } from '@store/brandFans'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { AvatarContainer, OtherMembersCount } from './Row.styled'

interface IBrandFansGroupRowAvatarListProps {
  avatars: ReadonlyArray<IMemberAvatar>
  identitiesWithoutAvatarCount: number
}

const BrandFansGroupRowAvatarList: React.FC<IBrandFansGroupRowAvatarListProps> = ({
  avatars,
  identitiesWithoutAvatarCount,
}) => {
  return (
    <Container
      rightInnerSpacing={0.375}
      data-testid="brand-fans-group-member-avatar-list-container"
    >
      <Flex justifyEnd alignEnd>
        {avatars.map((avatar) => (
          <AvatarContainer key={avatar.avatar_url} bottomOuterSpacing={-0.25}>
            <Avatar
              size={2.5}
              avatarSrc={avatar.avatar_url}
              borderWidth={0.25}
              borderColor={Theme.whiteColor}
            />
          </AvatarContainer>
        ))}
        {identitiesWithoutAvatarCount > 0 && (
          <Container
            rightOuterSpacing={-0.375}
            bottomOuterSpacing={-0.25}
            relative
            minWidth={3.375}
            height={2.5}
            data-testid="count-for-other-members"
          >
            <OtherMembersCount>
              <Text color={Theme.defaultColor}>
                <FormattedMessage
                  id="core.text.plus.value"
                  values={{ value: identitiesWithoutAvatarCount }}
                />
              </Text>
            </OtherMembersCount>
          </Container>
        )}
      </Flex>
    </Container>
  )
}

export { IBrandFansGroupRowAvatarListProps, BrandFansGroupRowAvatarList }
export default BrandFansGroupRowAvatarList
