import { FetchStatesActionTypes, IState } from '@store/rootResource'

export const fetchStates = (countryCode: string) => ({
  payload: countryCode,
  type: FetchStatesActionTypes.FETCH_STATES_REQUEST,
})

export const fetchStatesSuccess = (states: ReadonlyArray<IState>) => ({
  payload: states,
  type: FetchStatesActionTypes.FETCH_STATES_SUCCESS,
})

export const fetchStatesError = (error: any) => ({
  payload: error,
  type: FetchStatesActionTypes.FETCH_STATES_ERROR,
})
