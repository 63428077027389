import React from 'react'

import styled from 'styled-components'

import CheckboxButtonCombo from './CheckboxButtonCombo'
import CheckboxButtonComboBase from './CheckboxButtonComboBase'

export interface IOption {
  value: React.ReactText
  display: React.ReactNode
}

export interface IMultiSelectButtonGroupProps extends React.InputHTMLAttributes<HTMLInputElement> {
  allOption?: React.ReactNode
  options: ReadonlyArray<IOption>
  showOutlinedAsDisabled?: boolean
  activeBackgroundColor?: string
  onSelectedValuesChange?: (values: ReadonlyArray<React.ReactText>) => void
  selectedValues?: ReadonlyArray<React.ReactText>
  disabledValues?: ReadonlyArray<React.ReactText>
  outlinedValues?: ReadonlyArray<React.ReactText>
}

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const MultiSelectButtonGroup: React.FC<IMultiSelectButtonGroupProps> = React.forwardRef(
  (
    {
      options,
      allOption,
      onSelectedValuesChange,
      showOutlinedAsDisabled = false,
      selectedValues: preSelectedValues = [],
      disabledValues = [],
      outlinedValues = [],
      activeBackgroundColor,
      ...inputProps
    }: IMultiSelectButtonGroupProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [inputValue, setInputValue] = React.useState(preSelectedValues?.join('-') || '')

    const [selectedValues, setSelectedValues] = React.useState<ReadonlyArray<React.ReactText>>(
      preSelectedValues,
    )
    const [allSelected, setAllSelected] = React.useState(false)
    const [isMounted, setIsMounted] = React.useState(false)

    const onValuesChange = (newValues: ReadonlyArray<React.ReactText>) => {
      if (onSelectedValuesChange) {
        onSelectedValuesChange(newValues)
      }
    }

    const onSelect = (value: React.ReactText) => {
      setAllSelected(false)
      const updatedValues: ReadonlyArray<React.ReactText> = [...selectedValues, value]
      setSelectedValues(updatedValues)
      onValuesChange(updatedValues)
    }
    const onDeselect = (value: React.ReactText) => {
      const updatedValues = selectedValues.filter((item) => item !== value)
      setSelectedValues(updatedValues)
      onValuesChange(updatedValues)
    }

    const onAllClick = () => {
      setSelectedValues([])
      setAllSelected(!allSelected)
      onValuesChange(options.map((option) => option.value))
    }

    React.useEffect(() => {
      if (isMounted) {
        const value = allSelected ? options.map((option) => option.value) : selectedValues
        setInputValue(value.join('-'))
      }
    }, [selectedValues, allSelected, isMounted])

    React.useEffect(() => {
      setIsMounted(true)
    }, [])

    return (
      <Root>
        <input
          data-testid="multi-select-button-group-input"
          type="hidden"
          value={inputValue}
          ref={ref}
          {...inputProps}
        />
        {allOption && (
          <CheckboxButtonComboBase
            active={allSelected}
            activeBackgroundColor={activeBackgroundColor}
            onClick={onAllClick}
          >
            {allOption}
          </CheckboxButtonComboBase>
        )}

        {options.map((option) => (
          <CheckboxButtonCombo
            key={option.value}
            selected={selectedValues.includes(option.value)}
            disabled={disabledValues.includes(option.value)}
            outlined={outlinedValues.includes(option.value)}
            showOutlinedAsDisabled={showOutlinedAsDisabled}
            activeBackgroundColor={activeBackgroundColor}
            value={option.value}
            display={option.display}
            onSelect={onSelect}
            onDeselect={onDeselect}
          />
        ))}
      </Root>
    )
  },
)

// tslint:disable-next-line: no-object-mutation
MultiSelectButtonGroup.displayName = 'MultiSelectButtonGroup'

export default MultiSelectButtonGroup
