import React from 'react'

import UIFlagWatcherModal from '@components/UI/UIFlagWatcherModal'
import { UIFlag } from '@store/ui'
import { CreditManagementPaymentMethodPurchaseOrderRequestAccess } from './PaymentMethod/PurchaseOrder/RequestAccess'

const CreditManagementWatcher: React.FC = () => (
  <UIFlagWatcherModal
    uiFlag={UIFlag.SHOW_REQUEST_ACCESS_SUCCESS}
    modalComponent={CreditManagementPaymentMethodPurchaseOrderRequestAccess}
  />
)

export default CreditManagementWatcher
