import React from 'react'

import { FormattedMessage, InjectedIntlProps } from 'react-intl'
import { Link } from 'react-router-dom'
import Flex, { FlexItem } from 'styled-flex-component'

import SubmissionRateCardPanelTriggerMobile from '@components/Submission/RateCardPanel/Trigger/Mobile'
import ActionIcon from '@components/UI/ActionIcon'
import { LogoLink } from '@components/UI/TitleBar'
import { HeaderTitle } from '@components/UI/TitleBar/TitleBar.styled'
import TrackedRoute from '@components/UI/TrackedRoute/TrackedRoute'
import { IAnalyticsTrackingContextProps } from '@context/AnalyticsTracking'
import { ICampaignContextProps } from '@context/Campaign'
import { ISubmissionContextProps } from '@context/Submission'
import { EventTrackingNames } from '@enums'
import Theme from '@theme'
import { DropdownMenu, H5, MenuItem } from '@tribegroup'
import { buildSubmissionUrl } from '@utils/urls'
import { MoreMenu } from './TitleBar.styled'

interface IProps
  extends ICampaignContextProps,
    IAnalyticsTrackingContextProps,
    ISubmissionContextProps,
    InjectedIntlProps {
  search: string
}

export const SubmissionTitleBarMobile: React.FC<IProps> = ({
  submission,
  campaign,
  isCampaignFetched,
  isSubmissionFetched,
  search,
  getSubmissionTrackingProps,
  getCampaignTrackingProps,
  getSubmissionLocationProps,
}) => {
  const hasFetchedCampaignAndSubmission = isCampaignFetched && isSubmissionFetched
  const showRateIcon =
    hasFetchedCampaignAndSubmission && Boolean(campaign.rate_card || campaign.license_card)

  return (
    <Flex full alignCenter justifyBetween>
      <FlexItem>
        <LogoLink />
      </FlexItem>
      {hasFetchedCampaignAndSubmission && (
        <React.Fragment>
          <FlexItem>
            <HeaderTitle>
              <H5 color={Theme.grey900Color}>{submission.influencer_full_name}</H5>
            </HeaderTitle>
          </FlexItem>
          <FlexItem>
            <DropdownMenu
              target={
                <MoreMenu>
                  <ActionIcon glyph="more" color={Theme.grey900Color} size={1.5} />
                </MoreMenu>
              }
            >
              {showRateIcon && (
                <SubmissionRateCardPanelTriggerMobile campaign={campaign} submission={submission} />
              )}
              <TrackedRoute
                eventName={EventTrackingNames.ToolbarViewSubmissionNotes}
                properties={getSubmissionTrackingProps(submission)}
              >
                <MenuItem>
                  <Link
                    to={{
                      pathname: `${buildSubmissionUrl(campaign, submission)}/detail/notes`,
                      search,
                    }}
                  >
                    {submission.notes_any ? (
                      <FormattedMessage id="submission.card.menu.viewNotes" />
                    ) : (
                      <FormattedMessage id="submission.card.menu.addNotes" />
                    )}
                  </Link>
                </MenuItem>
              </TrackedRoute>
              <TrackedRoute
                eventName={EventTrackingNames.ToolbarPreviewCampaign}
                properties={{
                  ...getCampaignTrackingProps(),
                  ...getSubmissionLocationProps(),
                  current_view: 'submission_view',
                }}
              >
                <MenuItem>
                  <Link
                    to={{
                      pathname: `${buildSubmissionUrl(campaign, submission)}/preview`,
                      search,
                    }}
                  >
                    <FormattedMessage id="inbox.titlebar.action.preview" />
                  </Link>
                </MenuItem>
              </TrackedRoute>
            </DropdownMenu>
          </FlexItem>
        </React.Fragment>
      )}
    </Flex>
  )
}
