import styled, { css } from 'styled-components'

import { Spin } from '../Effects'
import { themed } from '../Theme'

export const ImageCropperWrapper = themed(styled.div`
  display: block;
  position: relative;

  img {
    width: 100%;
    display: block;
  }

  ${({ theme }) => css`
    .cropper-view-box {
      outline: 0.125rem solid ${theme.secondaryColor};
    }

    .cropper-line {
      background-color: transparent;
    }

    .cropper-point {
      background-color: ${theme.secondaryColor};
    }
  `};
`)

export const DroppedFileCover = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgb(0 0 0 / 5%);
`

export const ImageLoadingCover = themed(styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    animation: ${Spin} 2.8s linear infinite;
    transform-origin: 50% 50%;
    display: inline-block;
    ${({ theme }) => css`
      svg {
        fill: ${theme.whiteColor};
      }
    `};
  }
`)

export const ErrorMessage = styled.div`
  margin-top: 0.5rem;
`

export const CropActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  position: relative;
`

export const UploadField = themed(styled.div`
  ${({ theme, active }) => css`
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: ${theme.grey100Color};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: ${theme.defaultColor};
    }

    ${active &&
      css`
        background: ${theme.grey400Color};
      `};
  `};
`)

export const CropperWrapper = styled.div`
  position: relative;
  min-height: 10rem;
  border-radius: 0.5rem;
  overflow: hidden;
`

export const CloseIconWrapper = themed(styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  ${({ theme }) => css`
    svg {
      fill: ${theme.whiteColor};
    }
  `};
`)

export const AspectRatioWrapper = styled.div`
  padding-top: 56.25%;
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    top: 0;
  }
`

export const DropzoneWrapper = themed(styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  > div {
    height: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
    outline: none;
    box-sizing: border-box;
    ${({ theme }) => css`
      &:focus {
        border: 1px solid ${theme.defaultColor};
      }
    `};
  }
`)
