import { createAction } from '@reduxjs/toolkit'

import { MetricDisputeFormDataType, MetricDisputesActionTypes } from '@store/metricDisputes/types'

export const metricDisputeRequest = createAction<MetricDisputeFormDataType>(
  MetricDisputesActionTypes.PENDING,
)

export const metricDisputeSuccess = createAction(MetricDisputesActionTypes.FULFILLED)

export const metricDisputeFailed = createAction<any>(MetricDisputesActionTypes.FAILED)
