import { Component, Fragment, PropsWithChildren } from 'react'

import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectAuthToken } from '@store/auth'
import { fetchMeRequest, selectMeIsFetched } from '@store/me'
import { fetchRequest as fetchRootResource, selectRootResourceIsFetched } from '@store/rootResource'
import { showCookieToast } from '@utils'

interface IInternalProps extends InjectedIntlProps {
  fetchMeRequest: typeof fetchMeRequest
  fetchRootResource: typeof fetchRootResource
  isLoggedIn: boolean
  isMeFetched: boolean
  isRootResourceFetched: boolean
}

export class Main extends Component<PropsWithChildren<IInternalProps>> {
  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillMount() {
    this.props.fetchRootResource()
    if (this.props.isLoggedIn) {
      this.props.fetchMeRequest()
    }
  }

  componentDidMount() {
    if (!Cookies.get('tribecookieread') && this.props.isLoggedIn) {
      showCookieToast()
    }
  }

  componentDidUpdate() {
    if (!Cookies.get('tribecookieread') && this.props.isLoggedIn) {
      showCookieToast()
    }
  }

  render() {
    const { isLoggedIn, isMeFetched, isRootResourceFetched, intl } = this.props
    const isRootAndMeIsFetched = isRootResourceFetched && isMeFetched
    const isReady = isLoggedIn ? isRootAndMeIsFetched : isRootResourceFetched
    return (
      isReady && (
        <Fragment>
          <Helmet
            titleTemplate={`${intl.formatMessage({
              id: 'core.text.tribe',
            })} - %s`}
          />

          <main className="main">{this.props.children}</main>
        </Fragment>
      )
    )
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  isLoggedIn: Boolean(selectAuthToken(state)),
  isMeFetched: selectMeIsFetched(state),
  isRootResourceFetched: selectRootResourceIsFetched(state),
})

const mapDispatchToProps = {
  fetchMeRequest,
  fetchRootResource,
}

export default compose<IInternalProps, PropsWithChildren>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(Main)
