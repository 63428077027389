import { format } from 'date-fns'
import { createSelector } from 'reselect'

import { DEFAULT_SUBMISSIONS_PENDING_DATA, EMPTY_LIST, FULL_DATE } from '@constants'
import { PCRChannels } from '@enums'
import { IApplicationState, IFeatureHateoasLink, IHateoasLink } from '@store'
import { selectCampaign, selectIsCampaignFetched } from '@store/campaigns'
import { ICampaignMetrics, selectCampaignMetrics } from '@store/postCampaignReport'
import { selectActivePCRChannel } from '@store/router'
import { isEmpty } from '@utils'

export const selectFirstPublishedDate = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => campaignMetrics.first_submission_published_date,
)

export const selectLastPublishedDate = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => campaignMetrics.last_submission_published_date,
)

export const selectFirstPublishedDateText = createSelector(
  selectFirstPublishedDate,
  (firstSubmissionPublishedDate: string) =>
    firstSubmissionPublishedDate
      ? format(new Date(firstSubmissionPublishedDate), FULL_DATE)
      : undefined,
)

export const selectLastPublishedDateText = createSelector(
  selectLastPublishedDate,
  (lastSubmissionPublishedDate: string) =>
    lastSubmissionPublishedDate
      ? format(new Date(lastSubmissionPublishedDate), FULL_DATE)
      : undefined,
)

export const selectTotalSpend = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => ({
    amount: campaignMetrics.total_amount,
    currency: campaignMetrics.total_amount_currency,
  }),
)

export const selectTotalFollowers = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => campaignMetrics.total_social_reach,
)

export const selectTotalEngagement = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => campaignMetrics.total_engagement,
)

export const selectTotalEngagementPct = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => campaignMetrics.total_engagement_pct,
)

export const selectIsCampaignMetricsInErrorState = (state: IApplicationState) =>
  Boolean(state.reportings.campaignMetrics.errors)

export const selectIsFetchingCampaignMetric = (state: IApplicationState) =>
  state.reportings.campaignMetrics.isFetching

export const selectShowCampaignMetricsLoadingScreen = createSelector(
  selectCampaignMetrics,
  selectIsFetchingCampaignMetric,
  selectIsCampaignFetched,
  (campaignMetric: ICampaignMetrics | undefined, isFetchingCampaignMetric, isCampaignFetched) =>
    (isEmpty(campaignMetric) && isFetchingCampaignMetric) || !isCampaignFetched,
)

export const selectPostCampaignReportChannelCount = createSelector(
  selectCampaignMetrics,
  (state: IApplicationState, campaignId: number, channel: string) => channel,
  (campaignMetrics: ICampaignMetrics, channel: string) => {
    switch (channel) {
      case PCRChannels.IGPosts:
        return campaignMetrics.instagram_posts_count
      case PCRChannels.IGStories:
        return campaignMetrics.instagram_stories_count
      case PCRChannels.IGVideos:
        return campaignMetrics.instagram_videos_count
      case PCRChannels.Facebook:
        return campaignMetrics.facebook_posts_count
      case PCRChannels.Twitter:
        return campaignMetrics.twitter_posts_count
      case PCRChannels.TikTok:
        return campaignMetrics.tiktok_count ?? 0
      case PCRChannels.Reel:
        return campaignMetrics.instagram_reels_count ?? 0
      default:
        return 0
    }
  },
)

export const selectPostCampaignReportChannel = createSelector(
  selectPostCampaignReportChannelCount,
  selectActivePCRChannel,
  (state: IApplicationState, campaignId: number, channel: string) => channel,
  (count, activeChannel, channel) => {
    return {
      active: channel === activeChannel,
      count,
      channel,
    }
  },
)

export const selectPostCampaignReportChannelList = (
  state: IApplicationState,
  campaignId: number,
) => {
  return Object.values(PCRChannels).reduce(
    (acc, channel: string) => [...acc, selectPostCampaignReportChannel(state, campaignId, channel)],
    [],
  )
}

export const selectDataPendingSubmissions = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => {
    return !isEmpty(campaignMetrics) && campaignMetrics.data_pending_submissions_count
      ? campaignMetrics.data_pending_submissions_count
      : DEFAULT_SUBMISSIONS_PENDING_DATA
  },
)

export const selectShowPendingSection = createSelector(
  selectDataPendingSubmissions,
  (pendingData) =>
    [
      pendingData.posts,
      pendingData.stories,
      pendingData.videos,
      pendingData.tiktok,
      pendingData?.reels,
    ].some((count) => count && count > 0),
)

export const selectCampaignSummaryLinks = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => campaignMetrics.links || EMPTY_LIST,
)

export const selectCampaignMetricsLink = createSelector(
  selectCampaignSummaryLinks,
  (state: IApplicationState, campaignId: number, rel: string) => rel,
  (links: ReadonlyArray<IHateoasLink | IFeatureHateoasLink>, rel: string) =>
    links.find((link) => link.rel === rel),
)

export const selectDownloadPostCampaignReportLink = (
  state: IApplicationState,
  campaignId: number,
  timezone = '',
) => {
  const hateoasLink = selectCampaignMetricsLink(
    state,
    campaignId,
    'pcr_download_csv',
  ) as IFeatureHateoasLink

  if (!hateoasLink) {
    return
  }

  let href = hateoasLink.href.replace('{timezone}', timezone)

  const campaign = selectCampaign(state, campaignId)

  if (!campaign.is_price_hidden) {
    href = href.replace('{indicative_budget_cents}', `${campaign.indicative_budget_cents ?? ''}`)
  } else {
    href = href.replace('&indicative_budget_cents={indicative_budget_cents}', '')
  }

  return {
    ...hateoasLink,
    href,
  }
}

export const selectPostsMetricsLink = (
  state: IApplicationState,
  campaignId: number,
  channel: PCRChannels,
) => {
  if (channel === PCRChannels.IGPosts) {
    return selectCampaignMetricsLink(state, campaignId, 'pcr_instagram_posts')
  }

  if (channel === PCRChannels.Facebook) {
    return selectCampaignMetricsLink(state, campaignId, 'pcr_facebook_posts')
  }

  if (channel === PCRChannels.Twitter) {
    return selectCampaignMetricsLink(state, campaignId, 'pcr_twitter_posts')
  }

  return undefined
}

export const selectVideoMetricsLink = (
  state: IApplicationState,
  campaignId: number,
  channel: PCRChannels,
) => {
  if (channel === PCRChannels.IGVideos) {
    return selectCampaignMetricsLink(state, campaignId, 'pcr_instagram_videos')
  }

  return undefined
}

export const selectStoryMetricsLink = (
  state: IApplicationState,
  campaignId: number,
  channel: PCRChannels,
) => {
  if (channel === PCRChannels.IGStories) {
    return selectCampaignMetricsLink(state, campaignId, 'pcr_instagram_stories')
  }

  return undefined
}

export const selectTikTokMetricsLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignMetricsLink(state, campaignId, 'pcr_tiktok')

export const selectReelMetricsLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignMetricsLink(state, campaignId, 'pcr_instagram_reels')

export const selectShowCampaignReportTitleDates = createSelector(
  selectFirstPublishedDate,
  selectLastPublishedDate,
  (firstPublishedDate?: string, lastPublishedDate?: string) =>
    !isEmpty(firstPublishedDate) && !isEmpty(lastPublishedDate),
)

export const selectTotalPosts = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => campaignMetrics.submissions_count,
)

export const selectUniqueCreators = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => campaignMetrics.unique_influencers,
)

export const selectAvgEngagementPct = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => campaignMetrics.avg_engagement_rate,
)

export const selectOverallCPE = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => ({
    amount: campaignMetrics.total_cost_per_engagement,
    currency: campaignMetrics.total_cost_per_engagement_currency,
  }),
)

export const selectTotalReach = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => campaignMetrics.total_reach,
)

export const selectAvgReachPct = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => campaignMetrics.avg_reach_pct,
)

export const selectTotalImpressions = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => campaignMetrics.total_impressions,
)

export const selectOverallCPM = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => ({
    amount: campaignMetrics.overall_cpm,
    currency: campaignMetrics.overall_cpm_currency,
  }),
)

export const selectIsReadyForDownload = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => campaignMetrics.is_ready_for_download,
)
