export type Scheme = 'primary' | 'secondary' | 'success' | 'error' | 'alert' | 'default'

export interface IScheme {
  /**
   * Displays the component with the selected color scheme
   *
   * @default default
   */
  scheme?: Scheme
}

export const schemeTypes = {
  Default: 'default',
  Error: 'error',
  Primary: 'primary',
  Secondary: 'secondary',
  Success: 'success',
  Alert: 'alert',
}
