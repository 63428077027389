import { call, put, select, takeLatest } from 'redux-saga/effects'

import { DEFAULT_TENANT } from '@constants'
import { EventTrackingNames } from '@enums'
import { IHateoasLink } from '@store'
import { trackEvent } from '@store/analytics'
import { identifyRequest } from '@store/analytics'
import { AuthActionTypes, authError, authSuccess, saveSignUpForm, signUpRequest } from '@store/auth'
import { selectSignUpLink } from '@store/rootResource'
import { setUIFlag, UIFlag } from '@store/ui'
import { networkRequest } from '@utils'

export function* handleSignUp(action: ReturnType<typeof signUpRequest>) {
  try {
    const signUpLink: IHateoasLink = yield select(selectSignUpLink)
    const { signUpParams, history } = action.payload

    const skipOnboarding = process.env.APP_NAME !== DEFAULT_TENANT

    const companyName = !skipOnboarding ? signUpParams.company_name : undefined

    const result = yield call(networkRequest, signUpLink.method, signUpLink.href, {
      ...signUpParams,
      company_name: companyName,
    })
    yield put(authSuccess(result))
    yield put(saveSignUpForm({ ...result, ...signUpParams }))

    if (!skipOnboarding) {
      yield put(setUIFlag(UIFlag.SHOW_ONBOARDING_AUTH_STEP))
    }

    const user_id = result.user_id

    yield put(
      identifyRequest({
        id: user_id,
        email: signUpParams.email,
        first_name: signUpParams.first_name,
        last_name: signUpParams.last_name,
        company_name: companyName,
        track_events: true,
      }),
    )

    yield put(
      trackEvent(EventTrackingNames.BrandSignUpStep1Completed, {
        user_id,
        email: signUpParams.email,
        first_name: signUpParams.first_name,
        last_name: signUpParams.last_name,
        company_name: companyName,
      }),
    )

    if (skipOnboarding) {
      history?.push(`/signUpConfirmation?email=${encodeURIComponent(signUpParams.email)}`)
    }
  } catch (error) {
    yield put(authError(error))
  }
}

export function* watchSignUpRequest() {
  yield takeLatest(AuthActionTypes.SIGNUP_REQUEST, handleSignUp)
}
