import { useFlag } from '@unleash/proxy-client-react'
import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import InformationContent from '@components/UI/Information/Content/Content'
import UpsellInformation from '@components/Upsell/Information/Information'
import { CPM_EXPLAINED } from '@constants'
import { EventTrackingNames, FeatureToggles } from '@enums'
import { IApplicationState } from '@store'
import { selectCampaignOperatingRegionTaxName } from '@store/campaigns'
import { selectCampaignId, selectSubmissionId } from '@store/router'
import Theme from '@theme'
import { Container, Link, Text } from '@tribegroup'

interface IProps {
  title: string
  locked?: boolean
  currency?: string
  externalLink?: string
  productMetricValue?: string
  productMetricGroup?: string
  translationPrefix?: string
  showAsterisk?: boolean
}

interface IInternalProps extends IProps {
  campaignId: number
  submissionId: number
  taxName: string
}

interface IStateProps extends InjectedIntlProps, RouteComponentProps, IProps {}

const AnalyticsPostCampaignReportMetricTooltip: React.FC<IInternalProps> = ({
  locked,
  title,
  externalLink,
  campaignId,
  submissionId,
  productMetricValue,
  productMetricGroup,
  taxName,
  showAsterisk,
  translationPrefix = 'postCampaignReport.performance.item.label.text',
}) => {
  const metricMessageId = `${translationPrefix}.${title}`
  const isXEnabled = useFlag(FeatureToggles.X_SOCIAL_PLATFORM_ENABLED)

  return (
    <>
      <InformationContent
        titleColor={locked ? Theme.grey900Color : Theme.primaryColor}
        title={<FormattedMessage id={metricMessageId} />}
        content={
          <FormattedMessage
            id={`${metricMessageId}.tooltip`}
            values={{
              taxDisplay: taxName,
              link: (
                <Link href={CPM_EXPLAINED} target="_blank" color={Theme.primaryColor} lowercase>
                  <FormattedMessage id="core.text.clickHere" />
                </Link>
              ),
            }}
          />
        }
      />

      {showAsterisk && (
        <Container topOuterSpacing={0.5}>
          <Container absolute leftPosition={0.75}>
            <Text medium color={Theme.grey900Color} customFontSize={0.8125}>
              <FormattedMessage id="core.text.symbol.attention" />
            </Text>
          </Container>
          <Text color={Theme.grey900Color} customFontSize={0.8125}>
            <FormattedMessage
              id={
                isXEnabled
                  ? 'postCampaignReport.performance.tooltipDisclaimer'
                  : 'postCampaignReport.performance.instagramOnlyDisclaimer'
              }
            />
          </Text>
        </Container>
      )}

      {locked && externalLink && (
        <UpsellInformation
          textInformationMaxWidth={9.75}
          textInformation={<FormattedHTMLMessage id="upsell.information.unlock" />}
          externalLink={externalLink}
          trackingProps={{
            eventName: EventTrackingNames.SubscriptionLearnMore,
            additionalProperties: {
              current_filter: 'metrics',
              product_metric_group: productMetricGroup,
              product_metric: productMetricValue,
            },
            submissionId,
            campaignId,
          }}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: IApplicationState, { currency, intl, match }: IStateProps) => {
  const campaignId = selectCampaignId(match) as number

  return {
    campaignId,
    taxName: selectCampaignOperatingRegionTaxName(state, campaignId, intl, currency),
    submissionId: selectSubmissionId(match) as number,
  }
}

export { AnalyticsPostCampaignReportMetricTooltip }
export default compose<IInternalProps, IProps>(
  withRouter,
  injectIntl,
  connect(mapStateToProps),
)(AnalyticsPostCampaignReportMetricTooltip)
