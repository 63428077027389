import styled from 'styled-components'

import Theme from '@theme'

export const ImageWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  overflow: hidden;
  border-radius: 0.25rem;
`

export const SelectedBrandWrapper = styled.div`
  padding: 1rem 2.5rem;
  background: ${Theme.whiteColor};
  border-radius: 0.5rem;
  min-height: 3rem;
`
