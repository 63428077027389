import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import { selectPurchaseOrdersLink } from '@store/me'
import {
  FetchPurchaseOrdersActionTypes,
  fetchPurchaseOrdersError,
  fetchPurchaseOrdersSuccess,
  IPurchaseOrderResponse,
} from '@store/purchaseOrders'
import networkRequest from '@utils/networkRequest'

export function* handleFetchRequest() {
  try {
    const authToken = yield select(selectAuthToken)
    const purchaseOrdersLink: IHateoasLink = yield select(selectPurchaseOrdersLink)

    const purchaseOrders: IPurchaseOrderResponse = yield call(
      networkRequest,
      purchaseOrdersLink.method,
      purchaseOrdersLink.href,
      undefined,
      authToken,
    )

    yield put(fetchPurchaseOrdersSuccess(purchaseOrders))
  } catch (error) {
    yield put(fetchPurchaseOrdersError(error))
  }
}

export function* watchFetchPurchaseOrders() {
  yield takeLatest(FetchPurchaseOrdersActionTypes.FETCH_REQUEST, handleFetchRequest)
}
