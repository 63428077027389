import styled from 'styled-components'

import Theme from '@theme'

export const DurationWrapper = styled.div`
  position: relative;
  white-space: nowrap;
  margin-left: 3rem;
`

export const TextWrapper = styled.div`
  margin-bottom: 0.75rem;
`

export const DurationSelector = styled.div`
  z-index: 9;
  margin-top: 0.625rem;
  width: 23.125rem;
  height: 13.1325rem;
  background: ${Theme.whiteColor};
  left: calc(50% - 11.5625rem);
  position: absolute;
  padding: 2rem 2.5rem 4rem;
  box-sizing: border-box;
  box-shadow: 0.125rem 0.25rem 0.5rem 0 rgb(0 0 0 / 8%);

  &::before {
    content: ' ';
    left: calc(50% - 0.3225rem);
    border-left: 0.625rem solid transparent;
    border-right: 0.625rem solid transparent;
    border-bottom: 0.625rem solid ${Theme.whiteColor};
    top: -0.5625rem;
    position: absolute;
  }
`
