import React from 'react'

import { useForm } from 'react-hook-form'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex, { FlexItem } from 'styled-flex-component'

import { IApplicationState } from '@store'
import {
  clearBrandFansErrors,
  createBrandFanGroup,
  IBrandFanEditableFields,
  selectBrandFansErrorCode,
  selectIsCreatingBrandFanGroup,
} from '@store/brandFans'
import { Button, Container, TextField } from '@tribegroup'
import { getMessage } from '@utils'
import ErrorMessage from './ErrorMessage'

interface IProps {
  identityId: number
  influencerId?: number
  submissionId?: number
  campaignId?: number
}

interface IInternalProps extends IProps, InjectedIntlProps {
  errorCode: string
  isListEmpty: boolean
  isCreatingBrandFanGroup: boolean
  createBrandFanGroup: typeof createBrandFanGroup
  clearBrandFansErrors: typeof clearBrandFansErrors
}

const BrandFansInfluencerIdentityGroupsForm: React.FC<IInternalProps> = ({
  intl,
  errorCode,
  identityId,
  campaignId,
  submissionId,
  isCreatingBrandFanGroup,
  clearBrandFansErrors: clearBrandFansErrorsAction,
  createBrandFanGroup: createBrandFanGroupAction,
  influencerId,
}) => {
  const hasError = Boolean(errorCode)
  const {
    register,
    getValues,
    errors,
    trigger,
    reset,
    formState: { isDirty, isValid },
  } = useForm({ mode: 'onChange' })

  React.useEffect(() => {
    const isCreateSuccess = !isCreatingBrandFanGroup && !hasError
    if (isCreateSuccess) {
      reset()
    }
  }, [isCreatingBrandFanGroup])

  const onCreateBrandFanGroup = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    if (await trigger()) {
      createBrandFanGroupAction(
        identityId,
        campaignId,
        submissionId,
        getValues() as IBrandFanEditableFields,
        influencerId,
      )
    }
  }

  const onClearErrors = () => {
    if (hasError) {
      clearBrandFansErrorsAction()
    }
  }
  React.useEffect(() => onClearErrors)

  return (
    <form data-testid="brand-fans-group-create-form">
      <Flex alignCenter>
        <FlexItem grow={1}>
          <Container rightOuterSpacing={0.75}>
            <TextField
              fullWidth
              thick
              autoFocus
              scheme="default"
              name="name"
              data-testid="brand-fans-group-create-form-input"
              fontSize={0.875}
              defaultValue=""
              maxLength={60}
              onChange={onClearErrors}
              placeholder={getMessage(intl, 'brandFans.identityGroups.input.placeholder')}
              innerRef={register({
                required: true,
              })}
            />
          </Container>
        </FlexItem>
        <FlexItem shrink>
          <Button
            scheme="primary"
            data-testid="brand-fans-group-create-form-button"
            disabled={isCreatingBrandFanGroup || !isDirty || !isValid}
            loading={isCreatingBrandFanGroup}
            onClick={onCreateBrandFanGroup}
          >
            <FormattedMessage id="core.text.add" />
          </Button>
        </FlexItem>
      </Flex>
      {errors.name && (
        <ErrorMessage>
          <FormattedMessage id="brandFans.identityGroups.input.error.required" />
        </ErrorMessage>
      )}
      {hasError && (
        <ErrorMessage>
          <FormattedMessage id={`brandFans.error.${errorCode}`} />
        </ErrorMessage>
      )}
    </form>
  )
}

const mapDispatchToProps = {
  clearBrandFansErrors,
  createBrandFanGroup,
}

const mapStateToProps = (state: IApplicationState) => ({
  isCreatingBrandFanGroup: selectIsCreatingBrandFanGroup(state),
  errorCode: selectBrandFansErrorCode(state),
})

export { BrandFansInfluencerIdentityGroupsForm }
export default compose<IInternalProps, IProps>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(BrandFansInfluencerIdentityGroupsForm)
