import { DeclineActionTypes, ISubmission } from '@store/submissions'

export const decline = (submissionId: number, campaignId: number, params: object, meta?: any) => ({
  payload: { submissionId, campaignId, params },
  type: DeclineActionTypes.DECLINE_REQUEST,
  meta,
})

export const declineSuccess = (submission: ISubmission, campaignId: number, meta?: object) => ({
  payload: { submission, campaignId },
  meta,
  type: DeclineActionTypes.DECLINE_SUCCESS,
})

export const declineError = (error: any) => ({
  payload: error,
  type: DeclineActionTypes.DECLINE_ERROR,
})
