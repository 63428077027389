import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import {
  AnalyticsPostCampaignReportGlossaryPost,
  AnalyticsPostCampaignReportGlossaryStory,
  AnalyticsPostCampaignReportGlossaryVideo,
} from '@components/Analytics/PostCampaignReport/Glossary'
import { AnalyticsPostCampaignReportGlossaryReel } from '@components/Analytics/PostCampaignReport/Glossary/Reel/Reel'
import { AnalyticsPostCampaignReportGlossaryTikTok } from '@components/Analytics/PostCampaignReport/Glossary/TikTok/TikTok'
import ActionIcon from '@components/UI/ActionIcon'
import Drawer from '@components/UI/Drawer'
import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { selectActivePCRChannel } from '@store/router'
import Theme from '@theme'
import { Container, Header, Icon, Text } from '@tribegroup'

type IInternalProps = ConnectedProps<typeof connector>

const AnalyticsPostCampaignReportGlossary: React.FC<IInternalProps> = ({ activeChannel }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const closeDrawer = () => setIsOpen(false)
  const openDrawer = () => setIsOpen(true)

  return (
    <React.Fragment>
      <Drawer
        title={<FormattedMessage id="core.text.glossary" />}
        wrapperCallback={closeDrawer}
        headerRightIcon={
          <ActionIcon size={1.5} glyph="close-x" color={Theme.grey900Color} onClick={closeDrawer} />
        }
        showHeaderDivider
        open={isOpen}
      >
        <Container
          leftInnerSpacing={1.625}
          rightInnerSpacing={2.5}
          bottomInnerSpacing={2}
          data-testid="glossary-container"
        >
          <Container topInnerSpacing={1.75}>
            <Text color={Theme.primaryColor} customFontSize={1} lineHeight={1.5}>
              <FormattedMessage id="analytics.postCampaignReport.glossary.header" />
            </Text>
          </Container>
          {activeChannel === PCRChannels.IGPosts && <AnalyticsPostCampaignReportGlossaryPost />}
          {activeChannel === PCRChannels.IGVideos && <AnalyticsPostCampaignReportGlossaryVideo />}
          {activeChannel === PCRChannels.IGStories && <AnalyticsPostCampaignReportGlossaryStory />}
          {activeChannel === PCRChannels.TikTok && <AnalyticsPostCampaignReportGlossaryTikTok />}
          {activeChannel === PCRChannels.Reel && <AnalyticsPostCampaignReportGlossaryReel />}
        </Container>
      </Drawer>
      <Container
        fixed
        bottomPosition={0}
        rightPosition={0}
        leftPosition={13}
        rightInnerSpacing={2.8125}
        lineHeight={3.75}
        width="calc(100% - 15.8125rem)"
        textAlign="right"
        backgroundColor={Theme.whiteColor}
        zIndex={3}
      >
        <Container
          data-testid="glossary-button"
          inline
          pointer
          verticalAlign="middle"
          onClick={openDrawer}
          rightOuterSpacing={2.5}
        >
          <Header inline small uppercase color={Theme.defaultColor}>
            <FormattedMessage id="core.text.glossary" />
          </Header>
          <Container inlineBlock relative topPosition={0.375}>
            <Icon glyph="chevron-left" size={1.5} color={Theme.defaultColor} />
          </Container>
        </Container>
      </Container>
    </React.Fragment>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  activeChannel: selectActivePCRChannel(state),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(connector)(AnalyticsPostCampaignReportGlossary)
