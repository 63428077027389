import { createReducer } from '@reduxjs/toolkit'
import { Reducer } from 'redux'

import { IUIState } from '@store/ui'
import { clearUIFlag, setUIFlag, setUIFlagData } from './actions'

const initialState: IUIState = {
  enabled: {},
  data: {},
}

const reducer: Reducer<IUIState> = createReducer(initialState, (builder) =>
  builder
    .addCase(setUIFlag, (state, { payload: flag }) => {
      state.enabled[flag] = true
    })
    .addCase(clearUIFlag, (state, { payload: flag }) => {
      state.enabled[flag] = false
    })
    .addCase(setUIFlagData, (state, { payload }) => {
      const { data, flag } = payload
      state.data[flag] = data
    }),
)

export { reducer as uiReducer }
