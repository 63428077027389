import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'

const SubmissionCardModalPreapproveLicenseToast = () => (
  <Flex center>
    <Container rightOuterSpacing={0.5}>
      <Text small color={Theme.grey900Color}>
        {`Submission has moved to the `}
      </Text>
      <Text small medium color={Theme.grey900Color}>
        {`Pre-Approved `}
      </Text>
      <Text small color={Theme.grey900Color}>
        {`View `}
      </Text>
    </Container>
    <Icon glyph="tick" color={Theme.primaryColor} />
  </Flex>
)

export { SubmissionCardModalPreapproveLicenseToast }
export default SubmissionCardModalPreapproveLicenseToast
