import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { BuilderDefault } from '@enums'
import { withBuilderValidation, withDraft } from '@hocs'
import Theme from '@theme'
import { Container, H2, Slider } from '@tribegroup'
import { abbreviateNumber } from '@utils'
import BuilderBudgetTargetPanelTitle from '../PanelTitle'
import { CREATORS_HEADER_I18N_PREFIX } from '../Target'
import { BudgetInputWrapper, BudgetSliderWrapper } from './../../Form.styled'

interface IInternalProps
  extends IBuilderValidationContextProps,
    InjectedIntlProps,
    IDraftContextProps {}

const BuilderBudgetTargetDemographicFollowers: React.FC<IInternalProps> = ({
  intl,
  draft,
  updateForm,
}) => {
  const maxFollowersAllowed = BuilderDefault.SOLUTIONS_MAX_FOLLOWERS_ALLOWED

  const [min, setMin] = React.useState(
    draft.min_follower_threshold || BuilderDefault.MIN_FOLLOWERS_DEFAULT,
  )
  const [max, setMax] = React.useState(
    draft.max_follower_threshold || BuilderDefault.MAX_FOLLOWERS_DEFAULT,
  )

  const onSliderChanged = (updatedRange) => {
    setMin(Math.min(BuilderDefault.MAX_FOLLOWERS_START_RANGE_ALLOWED, updatedRange.min))
    setMax(Math.min(maxFollowersAllowed, updatedRange.max))
  }

  const onFollowerThresholdChanged = () => {
    updateForm({
      min_follower_threshold: min,
      max_follower_threshold: max,
    })
  }

  const formattedBudgetMax = intl.formatNumber(max)
  const budgetMax = max === maxFollowersAllowed ? `${formattedBudgetMax}+` : formattedBudgetMax

  const labelValues = [400_000, 800_000, 1_200_000, 1_600_000].map((value) => ({
    value,
    display: abbreviateNumber(value),
  }))

  return (
    <Container data-testid="builder-budget-target-demographic-followers">
      <BuilderBudgetTargetPanelTitle>
        <FormattedMessage id={`${CREATORS_HEADER_I18N_PREFIX}.followers`} />
      </BuilderBudgetTargetPanelTitle>
      <BudgetInputWrapper centered>
        <H2 color={Theme.defaultColor}>
          <FormattedMessage
            id="builder.budget.range"
            values={{
              min: intl.formatNumber(min),
              max: budgetMax,
            }}
          />
        </H2>
      </BudgetInputWrapper>
      <BudgetSliderWrapper>
        <Slider
          minValue={BuilderDefault.MIN_FOLLOWERS_ALLOWED}
          minLabel={abbreviateNumber(BuilderDefault.MIN_FOLLOWERS_ALLOWED) as string}
          labelValues={labelValues}
          maxValue={maxFollowersAllowed}
          maxValuePlus
          maxLabel={`${abbreviateNumber(maxFollowersAllowed)}+`}
          nearest={1000}
          step={1000}
          value={{ min, max }}
          onChange={onSliderChanged}
          onChangeComplete={onFollowerThresholdChanged}
        />
      </BudgetSliderWrapper>
    </Container>
  )
}

export default compose<IInternalProps, {}>(
  withBuilderValidation,
  withDraft,
  injectIntl,
)(BuilderBudgetTargetDemographicFollowers)
