import styled, { css } from 'styled-components'

import { Spin } from '../Effects'
import { themed } from '../Theme'

export const EmptyFile = themed(styled.div`
  ${({ theme, isDragActive, borderWidth = 1, noClick }) => css`
    outline: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: ${borderWidth}rem solid ${theme.whiteColor};
    box-sizing: border-box;
    justify-content: center;
    background: ${theme.grey100Color};
    cursor: pointer;
    ${noClick &&
    css`
      cursor: default;
    `};
    ${isDragActive &&
    css`
      background: ${theme.grey400Color};
    `};
  `};
`)

export const Caption = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  top: calc(50% + 1.875rem);
  text-align: center;
`

export const DroppedFileWrapper = styled.div`
  img,
  video {
    width: 100%;
    object-position: center;
    height: 100%;
    ${({ objectFit }) => css`
      object-fit: ${objectFit};
    `};
  }
`

export const DroppedFileCover = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: black;
  opacity: 0.05;
`

export const CloseIconWrapper = themed(styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  ${({ theme }) => css`
    svg {
      fill: ${theme.whiteColor};
    }
  `}
`)

export const CameraIconWrapper = styled.div`
  opacity: 0;
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.2s opacity;

  &::before {
    content: ' ';
    position: absolute;
    border-radius: 100rem;
    opacity: 0.2;
    background: #000;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`

export const ReplaceImageOverlay = themed(styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    ${CameraIconWrapper} {
      opacity: 1;
    }
  }

  ${({ theme }) => css`
    svg {
      fill: ${theme.whiteColor};
    }
  `}
`)

export const SquareWrapper = themed(styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  padding-top: 100%;
  ${({ theme, aspectRatio }) => css`
    ${aspectRatio &&
    css`
      padding-top: ${aspectRatio}%;
    `}
    background: ${theme.whiteColor};
    position: relative;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `};
`)

export const ImageLoadingCover = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    animation: ${Spin} 2.8s linear infinite;
    transform-origin: 50% 50%;
    display: inline-block;
    ${({ theme }) => css`
      svg {
        fill: ${theme.whiteColor};
      }
    `};
  }
`
