import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Flex, { FlexItem } from 'styled-flex-component'

import Avatar from '@components/UI/Avatar'
import Breakpoint from '@components/UI/Breakpoint'
import Loading from '@components/UI/Loading'
import hashtagPlaceholderImage from '@images/hashtag-placeholder@3x.png'
import { IApplicationState } from '@store'
import {
  IBrandManagerIdentity,
  selectBrandManagerIdentityLink,
  selectpendingBrandManagerIdentityIds,
} from '@store/brandManagerIdentities'
import Theme from '@theme'
import { Container, H4, Icon, Text } from '@tribegroup'
import { Root } from './IdentityRow.styled'
import ConnectedAccountsListIdentityRowMenu from './IdentityRowMenu'
import ConnectedAccountsListIdentityRowReconnectButton from './IdentityRowReconnectButton'

interface IProps {
  brandManagerIdentity: IBrandManagerIdentity
}
const ConnectedAccountsListIdentityRow = ({ brandManagerIdentity }: IProps) => {
  const { hasReconnectLink, pendingBrandManagerIdentityIds } = useSelector(
    (state: IApplicationState) => ({
      hasReconnectLink: Boolean(
        selectBrandManagerIdentityLink(state, brandManagerIdentity.id, 'reconnect_identity'),
      ),
      pendingBrandManagerIdentityIds: selectpendingBrandManagerIdentityIds(state),
    }),
  )

  const isBrandIdentityTikTok = brandManagerIdentity?.channel === 'tiktok'

  return (
    <Root data-testid="identity-list-row">
      <Container topInnerSpacing={1} bottomInnerSpacing={1}>
        <Flex justifyBetween alignCenter>
          <Flex justifyStart alignCenter>
            <FlexItem noShrink>
              <Container rightOuterSpacing={1}>
                <Avatar
                  size={4.5}
                  borderWidth={0.0625}
                  avatarSrc={brandManagerIdentity.avatar_url ?? brandManagerIdentity.display_url}
                  borderColor={Theme.grey400Color}
                  placeholderImage={hashtagPlaceholderImage}
                />
              </Container>
            </FlexItem>
            <FlexItem>
              <Flex justifyStart alignCenter>
                <FlexItem noShrink>
                  <Icon
                    size={1}
                    glyph={
                      isBrandIdentityTikTok ? 'social-tiktok-colour' : 'social-instagram-colour'
                    }
                    verticalAlign="middle"
                  />
                </FlexItem>
                <Container
                  inlineFlex
                  flexColumn
                  rightOuterSpacing={0.5}
                  leftOuterSpacing={0.5}
                  gap={0.25}
                >
                  <H4 color={Theme.grey900Color} textOverflowAsEllipsis light>
                    {brandManagerIdentity.handle ?? brandManagerIdentity.display_name}
                  </H4>
                  {isBrandIdentityTikTok && (
                    <Text xsmall color={Theme.grey700Color} light customletterSpacing="0.3px">
                      <FormattedMessage id="connectedAccounts.tiktok.identity.subtitle" />
                    </Text>
                  )}
                </Container>
                {hasReconnectLink && (
                  <Container rightOuterSpacing={0.5} noShrink>
                    <Icon glyph="alert-solid" size={1} color={Theme.errorColor} />
                  </Container>
                )}
              </Flex>
            </FlexItem>
          </Flex>
          <FlexItem noShrink>
            <Flex justifyEnd alignCenter>
              {pendingBrandManagerIdentityIds.includes(brandManagerIdentity.id) ? (
                <Loading iconOnly yPadding={0} />
              ) : (
                <>
                  <Breakpoint tabletLandscape tabletPortrait desktop>
                    {hasReconnectLink && (
                      <ConnectedAccountsListIdentityRowReconnectButton
                        brandManagerIdentityId={brandManagerIdentity.id}
                      />
                    )}
                  </Breakpoint>
                  <ConnectedAccountsListIdentityRowMenu
                    brandManagerIdentity={brandManagerIdentity}
                    hasReconnectLink={hasReconnectLink}
                  />
                </>
              )}
            </Flex>
          </FlexItem>
        </Flex>
      </Container>
    </Root>
  )
}

export default ConnectedAccountsListIdentityRow
