import React, { PropsWithChildren } from 'react'

import Theme from '@theme'
import { Container, Text } from '@tribegroup'

interface IProps {
  bottomPosition?: number
}

const BrandFansGroupModalErrorMessage: React.FC<PropsWithChildren<IProps>> = ({
  children,
  bottomPosition = -1.375,
}) => {
  return (
    <Container absolute bottomPosition={bottomPosition}>
      <Text small color={Theme.errorColor}>
        {children}
      </Text>
    </Container>
  )
}

export { BrandFansGroupModalErrorMessage }
export default BrandFansGroupModalErrorMessage
