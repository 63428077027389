import React, { useLayoutEffect, useRef } from 'react'

import OutsideClickHandler from 'react-outside-click-handler'

import {
  ClickableInformationOuterPanel,
  InformationPanel,
  InformationPanelContent,
  InformationPanelTriangle,
  ReactiveContainer,
} from '@components/UI/Information/Information.styled'
import { InformationTooltipContext } from '@context/InformationTooltip'

export const INFORMATION_TOOLTIP_ID = 'information-tooltip-base-content'

const InformationTooltipBase: React.FC = () => {
  const {
    coordinates,
    width,
    tooltipVisible,
    xPosition,
    content,
    hideTooltip,
    options = {},
  } = React.useContext(InformationTooltipContext)
  const mobileXOffset = width - coordinates.x
  const showRightOfTarget = xPosition === 'right'
  const shouldShowBelowTarget = options.showBelowTarget
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!ref.current || !shouldShowBelowTarget) {
      return
    }
    const rect = ref.current.getBoundingClientRect()

    const maxHeight = window.innerHeight - rect.top

    if (rect.height > maxHeight) {
      const padding = 20
      ref.current.style.maxHeight = `${maxHeight - padding}px`
      ref.current.style.overflow = 'auto'
    } else {
      ref.current.style.maxHeight = 'unset'
    }
  }, [tooltipVisible, ref.current, window.innerHeight, shouldShowBelowTarget])

  return (
    <ReactiveContainer
      shouldShowBelowTarget={shouldShowBelowTarget}
      topPosition={coordinates.y}
      leftPosition={coordinates.x}
      mobileXOffset={mobileXOffset}
    >
      <ClickableInformationOuterPanel
        showBelowTarget={shouldShowBelowTarget}
        isVisible={tooltipVisible}
        showRightOfTarget={showRightOfTarget}
        onMouseLeave={options.hoverable ? hideTooltip : undefined}
        rightOffset={options.rightOffset}
        cursor={options.cursor}
      >
        {options.useDefaultStyle ? (
          <InformationPanel>
            <InformationPanelTriangle
              showBelowTarget={shouldShowBelowTarget}
              showRightOfTarget={showRightOfTarget}
              mobileXOffset={mobileXOffset}
              rightOffset={options.rightOffset}
            />
            <InformationPanelContent ref={ref} width={width} id={INFORMATION_TOOLTIP_ID}>
              {content}
            </InformationPanelContent>
          </InformationPanel>
        ) : (
          content
        )}
      </ClickableInformationOuterPanel>
    </ReactiveContainer>
  )
}

const InformationTooltipBaseWrapper: React.FC = () => {
  const { hideTooltip } = React.useContext(InformationTooltipContext)
  const onOutsideClick = () => {
    hideTooltip()
  }

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <InformationTooltipBase />
    </OutsideClickHandler>
  )
}

export { InformationTooltipBase }
export default InformationTooltipBaseWrapper
