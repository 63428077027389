import React, { PropsWithChildren } from 'react'

import OutsideClickHandler from 'react-outside-click-handler'
import styled, { css } from 'styled-components'

import { Icon } from '../Icon'
import { defaultTheme, getColor, themed } from '../Theme'
import Menu, { IMenuProps } from './Menu'

export interface IDropdownMenuProps extends IMenuProps, IMenuWrapperProps {
  /**
   * String or JSX element that serves as the target for the menu
   *
   * @default undefined
   */
  target: JSX.Element

  /**
   * Show the open/close indicator beside the target element
   *
   * @default false
   */
  showIndicator?: boolean

  /**
   * Icon that will be placed beside the target when menu is open
   *
   * @default <Icon glyph="chevron-up" color={defaultTheme.grey900Color} size={0.5} />
   */
  indicatorOpen?: React.ReactNode

  /**
   * Icon that will be placed beside the target when menu is closed
   *
   * @default <Icon glyph="chevron-down" color={defaultTheme.grey900Color} size={0.5} />
   */
  indicatorClose?: React.ReactNode

  /**
   * Space between indicator and target in rem
   *
   * @default 0.5
   */
  indicatorSpace?: number

  /**
   * Anchors the indicator either on the left or right side of the target
   */
  indicatorAnchor?: 'left' | 'right'

  /**
   * Anchors the dropdown menu either on the left or right side of the target
   *
   * @default left
   */
  menuAnchor?: 'left' | 'right'
  elementName?: string
}

export interface IMenuWrapperProps {
  /**
   * CSS top position relative to container (in rem)
   *
   * @default 0
   */
  top?: number | 'auto'

  /**
   * CSS right position relative to container (in rem)
   *
   * @default 0
   */
  right?: number | 'auto'

  /**
   * CSS left position relative to container (in rem)
   *
   * @default auto
   */
  left?: number | 'auto'

  /**
   * CSS bottom position relative to container (in rem)
   *
   * @default auto
   */
  bottom?: number | 'auto'

  /**
   * Position the menu absolutely to target
   *
   * @default false
   */
  positionAbsolute?: boolean
}

export interface IDropdownMenuState {
  open: boolean
}

const getPositionCssValue = (value: number | 'auto') => {
  if (value === 'auto') {
    return value
  } else {
    return `${value}rem`
  }
}

const DropdownMenuWrapper = styled<IDropdownMenuProps, any>('div')`
  display: inline;
`

export const TargetContainer = styled.div`
  ${({ anchor }) =>
    anchor === 'right'
      ? 'padding: 0.75rem 1rem 0.75rem 2rem;'
      : 'padding: 0.75rem 2rem 0.75rem 1rem;'}
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TargetWrapper = themed(styled.div`
  display: block;
  cursor: pointer;
  position: relative;
  ${({ open, theme }) =>
    open
      ? css`
          ${TargetContainer} {
            background-color: ${getColor(theme, 'primary100')};
          }

          &:hover {
            ${TargetContainer} {
              background-color: ${getColor(theme, 'primary200')};
            }
          }
        `
      : `
      &:hover {
        ${TargetContainer} {
          background-color: ${getColor(theme, 'grey200')}
        }
      }
    `}
`)

const IndicatorWrapper = styled.div`
  position: absolute;
  ${({ positioning }) => `${positioning ?? 'left'}: 0.75rem`};
  top: calc(50% - 0.5rem);
  height: 100%;
`

const MenuWrapper = styled<IMenuWrapperProps, any>('div')`
  display: block;
  position: relative;
  ${({ positionAbsolute }) =>
    positionAbsolute &&
    css`
      position: absolute;
      top: auto;
      left: auto;
    `};

  > div {
    position: absolute;
    ${({ top = 0, right = 0, bottom, left }) => css`
      top: ${top ? getPositionCssValue(top) : 0};
      right: ${right ? getPositionCssValue(right) : 0};
      ${left !== undefined &&
      left !== null &&
      css`
        right: auto;
        left: ${getPositionCssValue(left)};
      `};
      ${bottom !== undefined &&
      bottom !== null &&
      css`
        top: auto;
        bottom: ${getPositionCssValue(bottom)};
      `};
    `};
  }
`

class DropdownMenu extends React.PureComponent<
  PropsWithChildren<IDropdownMenuProps>,
  IDropdownMenuState
> {
  static defaultProps = {
    indicatorClose: <Icon glyph="chevron-down" color={defaultTheme.grey900Color} size={1} />,
    indicatorOpen: <Icon glyph="chevron-up" color={defaultTheme.grey900Color} size={1} />,
    showIndicator: false,
    indicatorSpace: 0.5,
    menuAnchor: 'left',
    positionAbsolute: false,
  }
  state = {
    open: false,
  }

  onOpen = () => {
    this.setState({
      open: true,
    })
  }

  onClose = () => {
    this.setState({
      open: false,
    })
  }

  public render() {
    const {
      target,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      indicatorSpace,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onClick,
      indicatorClose,
      indicatorOpen,
      showIndicator,
      indicatorAnchor,
      bottom,
      top,
      right,
      left,
      positionAbsolute,
      elementName,
      ...props
    } = this.props
    const { open } = this.state
    const TargetElement = React.cloneElement(target, { active: open ? 1 : 0, open })
    return (
      <OutsideClickHandler onOutsideClick={this.onClose}>
        <DropdownMenuWrapper data-cy-element={elementName}>
          <TargetWrapper open={open} onClick={this.onOpen}>
            {TargetElement}
            {showIndicator && (
              <IndicatorWrapper positioning={indicatorAnchor}>
                {open ? indicatorOpen : indicatorClose}
              </IndicatorWrapper>
            )}
          </TargetWrapper>
          <MenuWrapper
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            positionAbsolute={positionAbsolute}
            open={open}
          >
            {open && <Menu {...props} onClick={this.onClose} />}
          </MenuWrapper>
        </DropdownMenuWrapper>
      </OutsideClickHandler>
    )
  }
}

export default DropdownMenu
