import { PureComponent } from 'react'

import { default as ReactDatePicker, ReactDatePickerProps } from 'react-datepicker'
import styled, { css } from 'styled-components'

import { FULL_DATE } from '@constants'
import { IScheme } from '../Interfaces/schemes'
import { getColor, themed } from '../Theme'

import 'react-datepicker/dist/react-datepicker.css'

export interface IDatePickerProps extends IScheme {
  /**
   * Sets the width of the text field
   *
   * @default auto
   */
  width?: number | string

  /**
   * Sets the disabled attribute of the textfield
   *
   * @default auto
   */
  disabled?: boolean

  /**
   * Indicates that this field has invalid value
   *
   * @default false
   */
  invalid?: boolean

  /**
   * HTML ID for the input element
   *
   * @default undefined
   */
  id?: string

  /**
   * override ReactDatePicker style and make datepicker centered
   */
  centered?: boolean

  /**
   * Props for the wrapped ReactDatePicker
   */
  datePickerConfig: Partial<ReactDatePickerProps>
}

const ReactDatePickerWrapper = themed(styled<IDatePickerProps, any>('span')`
  display: inline-block;
  position: relative;
  ${({ width, theme, invalid, scheme, centered }) => css`
    ${width
      ? css`
          width: ${typeof width === 'number' ? `${width}rem` : width};
        `
      : css`
          width: 100%;
        `};

    .react-datepicker {
      border: none;
      width: 100%;
    }

    .react-datepicker__input-container {
      width: 100%;

      input {
        padding: 0.5rem;
        padding-right: 1.7rem;
        border-radius: 0.1875rem;
        border: solid 1px ${theme.grey400Color};
        background-color: transparent;
        font-size: 0.8125rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${theme.grey900Color};
        transition: 0.25s border;
        box-sizing: border-box;
        width: 100%;

        &::placeholder,
        &::input-placeholder {
          color: ${theme.grey800Color};
        }

        &:disabled {
          opacity: 0.3;
        }

        outline: none;

        &::placeholder,
        &::input-placeholder {
          color: ${theme.grey800Color};
        }

        &:disabled {
          opacity: 0.3;
        }

        ${invalid &&
        css`
          color: ${theme.errorColor};
          border: solid 1px ${theme.errorColor};
        `};

        caret-color: ${theme.grey900Color};

        &:focus {
          border: solid 1px ${getColor(theme, scheme)};
        }
      }
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__current-month {
      color: ${theme.grey900Color};
      font-weight: normal;
      height: 2rem;
    }

    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
      border-bottom-color: ${theme.whiteColor};
      fill: ${theme.whiteColor};
      color: ${theme.whiteColor};
      stroke: ${theme.whiteColor};

      &::before {
        top: 0;
        border: none;
      }
    }

    .react-datepicker__header {
      border-bottom: none;
    }

    .react-datepicker__header,
    .react-datepicker__today-button,
    .react-datepicker__header react-datepicker__header--time {
      background-color: ${theme.whiteColor};
      font-family: Rubik, verdana, sans-serif;
    }

    .react-datepicker__day-name,
    .react-datepicker__time-name {
      font-family: Rubik, verdana, sans-serif;
      color: ${theme.grey900Color};
    }

    .react-datepicker__day {
      color: ${theme.grey900Color};
    }

    .react-datepicker__day--disabled {
      color: ${theme.grey400Color};
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__day:hover {
      border-radius: 50%;
      background-color: ${getColor(theme, scheme)};
      color: ${theme.whiteColor};
    }
    ${centered &&
    css`
      .react-datepicker__current-month {
        border-bottom: 1px solid ${theme.grey100Color};
        height: 1.85rem;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
      }

      .react-datepicker-popper {
        transform: none !important;
        top: 2.75rem !important;
        left: calc(50% - 12.0625rem) !important;
        width: 24.125rem;
        padding: 0.5rem 1.5rem;
        box-sizing: border-box;
        background: ${theme.whiteColor};
      }

      .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
        left: calc(50% - 0.5rem);
        margin-top: -0.9375rem;
      }
    `};
  `};
`)

export default class DatePicker extends PureComponent<IDatePickerProps, any> {
  constructor(props: IDatePickerProps) {
    super(props)
    this.state = {
      startDate: null,
    }
  }

  onChange = (date: Date) => {
    this.setState({
      startDate: date,
    })
  }

  public render() {
    const { datePickerConfig, ...props } = this.props
    return (
      <ReactDatePickerWrapper {...props} data-cy-element="date-picker">
        <ReactDatePicker
          onChange={this.onChange}
          selected={this.state.startDate}
          dateFormat={FULL_DATE}
          placeholderText="DD/MM/YYYY"
          {...datePickerConfig}
        />
      </ReactDatePickerWrapper>
    )
  }
}
