import React from 'react'

import { isPast } from 'date-fns'

import { ActionBarLeft, ActionBarRight, ActionBarWrapper } from '@components/UI/Card/Card.styled'
import { useInboxFilter } from '@hooks/useInboxFilter'
import { ISubmission } from '@store/submissions'
import ActionBarIconChat from '../Icon/Chat'
import ActionBarIconLicense from '../Icon/License'
import ActionBarIconReschedule from '../Icon/Reschedule/Reschedule'
import SubmissionCardActionBarSocialPublishDate from './PublishDate'

interface IProps {
  campaignId: number
  submission: ISubmission
  hasFeedbackLink: boolean
  showLicenseIcon: boolean
  hasRescheduleLink: boolean
}

const SubmissionCardActionBarSocialApproved: React.FC<IProps> = ({
  hasRescheduleLink,
  hasFeedbackLink,
  submission,
  campaignId,
  showLicenseIcon,
}) => {
  const submissionId = submission.id

  const { currentFilter } = useInboxFilter()
  const modalProps = {
    currFilter: currentFilter,
    submission,
    campaignId,
  }

  return (
    <ActionBarWrapper justifyBetween>
      <ActionBarLeft>
        <SubmissionCardActionBarSocialPublishDate submission={submission} />
      </ActionBarLeft>
      <ActionBarRight>
        {hasRescheduleLink && (
          <ActionBarIconReschedule
            showNotification={isPast(new Date(submission.scheduled_publish_date as string))}
            highlighted={Boolean(submission.scheduled_publish_date)}
            modalProps={modalProps}
          />
        )}
        {hasFeedbackLink && <ActionBarIconChat submission={submission} campaignId={campaignId} />}
        {showLicenseIcon && <ActionBarIconLicense submissionId={submissionId} />}
      </ActionBarRight>
    </ActionBarWrapper>
  )
}

export default SubmissionCardActionBarSocialApproved
