import { useSelector } from 'react-redux'

import { MemberRoleTypes } from '@enums'
import { selectBudgetTotalCurrency, selectRole } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import {
  selectIsFetchingSubmissionPerformance,
  selectSubmissionPerformance,
} from '@store/performance'
import {
  selectCPECurrency,
  selectSocialSubmissionType,
  selectSubmission,
  selectSubTotalCurrency,
} from '@store/submissions'
import { AnalyticsBreakdown } from './Breakdown'
import { FINANCIAL_ANALYTICS_KEYS, HIDDEN_REACH_METRICS } from './mapping-functions'
import { AnalyticsData } from './types'

type Props = {
  submissionId: number
}

export const AnalyticsPostPurchaseBreakdown: React.FC<Props> = ({ submissionId }) => {
  const { isFetching, analytics, currency, socialSubmissionType, isProxy } = useSelector(
    (state: IApplicationState) => {
      const { identity_followers_count, brief_id, is_price_hidden, is_proxy } = selectSubmission(
        state,
        submissionId,
      )
      const role = selectRole(state, brief_id)
      const campaignBudgetCurrency = selectBudgetTotalCurrency(state, brief_id)
      const isFetching = selectIsFetchingSubmissionPerformance(state)
      const submissionPerformance = selectSubmissionPerformance(state, submissionId)
      const cpeCurrency = selectCPECurrency(state, submissionId)
      const currency =
        selectSubTotalCurrency(state, submissionId) ?? cpeCurrency ?? campaignBudgetCurrency
      const socialSubmissionType = selectSocialSubmissionType(state, submissionId)
      const analytics: AnalyticsData = {
        followers: identity_followers_count,
        ...submissionPerformance,
      }
      const isHiddenFinancialAnalytics =
        role === MemberRoleTypes.SolutionCollaborator && is_price_hidden

      if (isHiddenFinancialAnalytics) {
        FINANCIAL_ANALYTICS_KEYS.forEach((key) => {
          analytics[key] = 'hidden'
        })
      }

      const hiddenMetrics = HIDDEN_REACH_METRICS[socialSubmissionType]

      if (hiddenMetrics) {
        hiddenMetrics.forEach((key) => {
          analytics[key] = 'hidden'
        })
      }

      return { isFetching, analytics, currency, socialSubmissionType, isProxy: Boolean(is_proxy) }
    },
  )

  return (
    <AnalyticsBreakdown
      isLoaded={!isFetching}
      perspective="post-purchase"
      analytics={analytics}
      currency={currency!}
      socialSubmissionType={socialSubmissionType}
      isProxy={isProxy}
    />
  )
}
