import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { FlexItem } from 'styled-flex-component'

import { FadeIn } from '@lib/effects'

export const SubmissionCreatorNotePopupClose = styled.div`
  display: block;
`

export const SubmissionCreatorNotePopupOpen = styled.div`
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 10%);
  padding-right: 0.75rem;
  padding-bottom: 0.75rem;
  padding-top: 1.25rem;
  position: absolute;
  z-index: 2;
  bottom: 0.25rem;
  background: white;
  width: 20rem;
  right: 0.5rem;
  ${breakpoint('phoneUpperBoundary')`
    width: 23.4375rem;
    right: -0.75rem;
  `};
  max-width: 80vw;
  animation: ${FadeIn} 0.4s ease-in forwards;
`

export const OpenActionIconWrapper = styled(FlexItem)`
  min-width: 3.75rem;
  align-self: flex-end;
  text-align: right;
`
