import { useFlag } from '@unleash/proxy-client-react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { AnalyticsPostPurchaseBreakdown } from '@components/Analytics/Breakdown/PostPurchaseBreakdown'
import { FeatureToggles } from '@enums'
import { IApplicationState } from '@store'
import { selectSubmissionId } from '@store/router'
import {
  selectIsReelSocialSubmission,
  selectIsStorySocialSubmission,
  selectIsSubmissionFromInstagram,
  selectIsSubmissionFromTiktok,
  selectIsVideoSocialSubmission,
} from '@store/submissions'
import AnalyticsPostPerformancePost from '../Post/Post'
import AnalyticsPostPerformanceReel from '../Reel/Reel'
import AnalyticsPostPerformanceStory from '../Story/Story'
import AnalyticsPostPerformanceTikTok from '../TikTok/TikTok'
import AnalyticsPostPerformanceVideo from '../Video/Video'

const AnalyticsPostPerformanceWrapper = () => {
  const isMetricConsolidationEnabled = useFlag(FeatureToggles.METRIC_CONSOLIDATION_ENABLED)
  const match = useRouteMatch()
  const submissionId = selectSubmissionId(match) as number

  const { isStory, isVideo, isTikTok, isReel, isSubmissionFromInstagram } = useSelector(
    (state: IApplicationState) => {
      return {
        isSubmissionFromInstagram: selectIsSubmissionFromInstagram(state, submissionId),
        isStory: selectIsStorySocialSubmission(state, submissionId),
        isVideo: selectIsVideoSocialSubmission(state, submissionId),
        isTikTok: selectIsSubmissionFromTiktok(state, submissionId),
        isReel: selectIsReelSocialSubmission(state, submissionId),
      }
    },
  )

  if (isMetricConsolidationEnabled && (isSubmissionFromInstagram || isTikTok)) {
    return <AnalyticsPostPurchaseBreakdown submissionId={submissionId} />
  }

  if (isTikTok) {
    return <AnalyticsPostPerformanceTikTok />
  }

  if (isReel) {
    return <AnalyticsPostPerformanceReel />
  }

  if (isVideo) {
    return <AnalyticsPostPerformanceVideo />
  }

  if (isStory) {
    return <AnalyticsPostPerformanceStory />
  }

  return <AnalyticsPostPerformancePost />
}

export { AnalyticsPostPerformanceWrapper }
export default AnalyticsPostPerformanceWrapper
