import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'
import { Container } from '@tribegroup'

const shown = `
  opacity: 1;
  height: auto;
  overflow: visible;
`

const hidden = `
  height: 0;
  overflow: hidden;
  opacity: 0;
`

export const InformationTarget = styled(Container)``

export const InformationPanelTriangle = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  bottom: 1rem;
  border-right: #fff solid 1rem;
  border-top: transparent solid 1rem;
  border-left: transparent solid 1rem;
  border-bottom: transparent solid 1rem;
  z-index: 1;
  ${({ showRightOfTarget }) =>
    showRightOfTarget &&
    css`
      right: auto;
      left: 0;
      border-right: transparent solid 1rem;
      border-left: #fff solid 1rem;
    `};
  ${({ showBelowTarget }) =>
    showBelowTarget &&
    css`
      top: 1rem;
      bottom: auto;
    `};
  ${({ mobileXOffset = 0 }) => css`
    right: ${mobileXOffset}rem;
    ${breakpoint('tabletPortrait')`
      right: 0;
    `};
  `};
`

export const InformationPanelContent = styled.div`
  padding: 1.25rem;
  background-color: white;
  width: ${({ width }) => width}rem;
  max-width: 75vw;
  z-index: 4;
  border-radius: 0.5rem;
  box-sizing: border-box;
`

const invisiblePanel = `
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  z-index: 100;
`

export const InformationPanel = styled.div`
  box-shadow: 0 0.125rem 1.875rem 0 rgb(0 0 0 / 12%);
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  transition: opacity 200ms;
  border-radius: 0.5rem;
`

export const InformationOuterPanel = styled.div`
  ${invisiblePanel};
  ${hidden};

  ${({ showRightOfTarget }) =>
    showRightOfTarget &&
    css`
      ${breakpoint('tabletPortrait')`
      right: auto;
      left: 0.5rem;
    `};
    `};
  ${({ showBelowTarget, topOffset }) =>
    showBelowTarget &&
    css`
      top: ${topOffset ? `${topOffset}rem` : `0`};
      bottom: auto;
    `};
  ${({ rightOffset }) =>
    rightOffset &&
    css`
      right: ${rightOffset}rem;
    `};
  ${({ bottomOffset }) =>
    bottomOffset &&
    css`
      bottom: ${bottomOffset}rem;
    `};
  ${({ leftOffset }) =>
    leftOffset &&
    css`
      left: ${leftOffset}rem !important;
    `};
`

export const InformationWrapper = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    ${InformationOuterPanel} {
      ${({ delay }) => delay && `transition-delay: ${delay}s;`}
      ${shown};
    }

    ${InformationPanelContent} {
      cursor: auto;
    }

    ${({ hoverStyle }) => hoverStyle}

    [data-icon-glyph='locked'] {
      svg {
        fill: ${Theme.errorColor};
      }
    }
  }
`

export const ClickableInformationOuterPanel = styled.div`
  ${invisiblePanel};
  ${({ showRightOfTarget }) =>
    showRightOfTarget &&
    css`
      ${breakpoint('tabletPortrait')`
        right: auto;
        left: 0.5rem;
      `};
    `};
  ${({ showBelowTarget }) =>
    showBelowTarget
      ? css`
          right: -0.5rem;
          top: 1rem;
          bottom: auto;
        `
      : css`
          right: -0.5rem;
          margin-bottom: 0.5rem;
        `};
  ${({ isVisible }) => (isVisible ? shown : hidden)};
  ${({ cursor }) =>
    cursor &&
    css`
      cursor: ${cursor};
    `};
`

export const ClickableInformation = styled.div`
  position: relative;
  ${({ isVisible }) =>
    css`
      ${InformationPanel} {
        ${isVisible ? shown : hidden};
      }
    `}
`

export const ReactiveContainer = styled.div`
  position: absolute;
  top: ${({ topPosition, shouldShowBelowTarget }) =>
    `calc(${topPosition}rem ${shouldShowBelowTarget ? '- 1rem' : '+ 2rem'})`};
  right: auto;
  ${({ leftPosition, mobileXOffset }) => css`
    left: ${leftPosition + mobileXOffset}rem;
    ${breakpoint('tabletPortrait')`
      left: ${leftPosition}rem;
    `};
  `}
`
