import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Col } from 'react-styled-flexboxgrid'
import { compose } from 'recompose'

import SubmissionCreatorNotePanel from '@components/Submission/CreatorNote/Panel/Panel'
import { FullDetailsInnerWrapper, FullHr, FullStyled } from '@components/UI/Full/Full.styled'
import { SupportedSubmissionTypes } from '@enums'
import { IApplicationState } from '@store'
import { ICampaign } from '@store/campaigns'
import {
  ISubmission,
  selectIsTextSubmission,
  selectShowBrandedContentAdActions,
  selectShowBrandedContentAdBar,
} from '@store/submissions'
import { Container } from '@tribegroup'
import { isSocialCampaign } from '@utils/campaign'
import SubmissionCardActionBar from '../ActionBar'
import { SubmissionCardBrandedContentAdButton } from '../BrandedContentAd'
import SubmissionCardDetails from '../Details/Details'
import SubmissionCardHeader from '../Header/Header'
import { SubmissionCardMedia } from '../Media'
import SubmissionCardPricePanel from '../PricePanel'
import { SubmissionCardTextPreview } from '../TextPreview/TextPreview'
import {
  CardGridArea,
  CardMediaGridArea,
  SubmissionCardDetailsWrapper,
  SubmissionCardMediaWrapper,
} from './Full.styled'

interface ISubmissionCardFullProps {
  submission: ISubmission
  submissionType: SupportedSubmissionTypes
  campaign: ICampaign
  showLicenseRequestAsApprove?: boolean
}

interface IInternalProps extends ISubmissionCardFullProps, RouteComponentProps {
  showBrandedContentAdActions: boolean
  showBrandedContentAdBar: boolean
  isTextSubmission: boolean
}

const SubmissionCardFull = ({
  submission,
  submissionType,
  campaign,
  showLicenseRequestAsApprove,
  showBrandedContentAdActions,
  showBrandedContentAdBar,
  isTextSubmission,
}: IInternalProps) => {
  const showBrandedContentSection = showBrandedContentAdBar && showBrandedContentAdActions

  return (
    <FullStyled>
      <CardGridArea name="header">
        <Container leftOuterSpacing={0.25} rightOuterSpacing={0.25}>
          <SubmissionCardHeader campaignId={campaign.id} submissionId={submission.id} />
        </Container>
      </CardGridArea>
      <CardMediaGridArea>
        <SubmissionCardMediaWrapper>
          {isTextSubmission ? (
            <>
              <SubmissionCardTextPreview submissionId={submission.id} />
              <Container fullWidth relative>
                <SubmissionCreatorNotePanel />
              </Container>
            </>
          ) : (
            <SubmissionCardMedia
              submission={submission}
              key={submission.id}
              expandable
              containImage
            />
          )}
        </SubmissionCardMediaWrapper>
      </CardMediaGridArea>
      <CardGridArea name="actions">
        <Col xs={false} md={12}>
          <FullHr />
        </Col>
        <Container leftOuterSpacing={0.5} rightOuterSpacing={0.5}>
          <SubmissionCardActionBar
            submissionId={submission.id}
            showLicenseRequestAsApprove={showLicenseRequestAsApprove}
          />
        </Container>
      </CardGridArea>

      {showBrandedContentSection && (
        <CardGridArea name="brandedContentAd">
          <Container
            leftInnerSpacing={1.125}
            rightInnerSpacing={1.125}
            topInnerSpacing={0.75}
            bottomInnerSpacing={1}
          >
            <SubmissionCardBrandedContentAdButton submissionId={submission.id} />
          </Container>
        </CardGridArea>
      )}

      <CardGridArea name="price" data-cy-element="card-grid-area-price">
        <SubmissionCardPricePanel
          submissionType={submissionType}
          submissionId={submission.id}
          key={submission.id}
        />
      </CardGridArea>

      <SubmissionCardDetailsWrapper>
        <FullDetailsInnerWrapper>
          <SubmissionCardDetails />
        </FullDetailsInnerWrapper>
      </SubmissionCardDetailsWrapper>
    </FullStyled>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { submission, campaign }: ISubmissionCardFullProps,
) => ({
  showBrandedContentAdActions: selectShowBrandedContentAdActions(state, submission.id),
  showBrandedContentAdBar: selectShowBrandedContentAdBar(
    state,
    submission.id,
    isSocialCampaign(campaign),
  ),
  isTextSubmission: selectIsTextSubmission(state, submission.id),
})

export { SubmissionCardFull }
export default compose<IInternalProps, ISubmissionCardFullProps>(
  withRouter,
  connect(mapStateToProps),
)(SubmissionCardFull)
