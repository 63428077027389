import { PropsWithChildren, PureComponent } from 'react'

import styled, { css } from 'styled-components'

import { IScheme } from '../Interfaces/schemes'
import { getColor, themed } from '../Theme'

export interface IPillProps extends IScheme {
  /**
   * Displays pill in an outlined state
   *
   * @default false
   */
  outlined?: boolean
}

const PillWrapper = themed(styled<IPillProps, any>('span')`
  font-size: 0.5rem;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  border-radius: 12.5px;
  padding: 0.125rem 0.325rem;
  display: inline-block;
  ${({ scheme, theme, outlined }) => css`
    color: ${theme.whiteColor};
    background-color: ${theme.grey900Color};

    ${scheme &&
    css`
      background-color: ${getColor(theme, scheme)};
    `};

    ${outlined &&
    css`
      border: solid 1px ${theme.whiteColor};
      background-color: transparent;
    `};
  `};
`)

export default class Pill extends PureComponent<PropsWithChildren<IPillProps>> {
  public render() {
    return <PillWrapper {...this.props} />
  }
}
