import { useSelector } from 'react-redux'

import { IGender, selectAllGenders, selectInstagramGenders } from '@store/rootResource'

type FindGenderResult = IGender | undefined
type GenderOption = { value: string; display: string }

interface IGenderResults {
  allGenders: ReadonlyArray<IGender>
  genderOptions: ReadonlyArray<GenderOption>
  findGender: (code?: string) => FindGenderResult
}

const useGender = (instagramGenders = false): IGenderResults => {
  const allGenders = instagramGenders
    ? useSelector(selectInstagramGenders)
    : useSelector(selectAllGenders)

  const genderOptions = allGenders.map(({ code, display_name }: IGender) => ({
    value: code,
    display: display_name,
  }))

  const findGender = (code?: string): FindGenderResult => {
    if (!code) {
      return undefined
    }
    return allGenders.find((gender: IGender) => gender.code === code)
  }

  return {
    allGenders,
    genderOptions,
    findGender,
  }
}

export { useGender }
