import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { trackEvent } from '@store/analytics'
import { selectMeId } from '@store/me'
import { fetchMediaObjects } from '@store/mediaObjects'
import {
  selectBrandFilter,
  selectChannelFilter,
  selectContentLibraryFilter,
  selectMediaTypeFilter,
} from '@store/router'
import { Container } from '@tribegroup'
import { BrandsFilter } from './BrandFilter'
import ChannelFilter from './ChannelFilter'
import MediaTypeFilter from './MediaTypeFilter'
import { FilterContainer } from './Search.styled'

const MediaObjectSearch = () => {
  const dispatch = useDispatch()

  const {
    brandIdFilter,
    channelFilter,
    mediaTypeFilter,
    contentLibraryFilter,
    brandManagerId,
  } = useSelector((state: IApplicationState) => {
    return {
      brandIdFilter: selectBrandFilter(state),
      channelFilter: selectChannelFilter(state),
      mediaTypeFilter: selectMediaTypeFilter(state),
      contentLibraryFilter: selectContentLibraryFilter(state),
      brandManagerId: selectMeId(state),
    }
  })

  useEffect(() => {
    dispatch(fetchMediaObjects(contentLibraryFilter))
    dispatch(
      trackEvent(EventTrackingNames.FilterContentLibrary, {
        current_filter: `content_library_${contentLibraryFilter}`,
        brand_id: brandIdFilter,
        channel: channelFilter,
        content_type: mediaTypeFilter,
        brand_manager_id: brandManagerId,
      }),
    )
  }, [contentLibraryFilter, brandIdFilter, channelFilter, mediaTypeFilter])

  return (
    <Container flexBetween bottomOuterSpacing={1}>
      <FilterContainer>
        <BrandsFilter />
        <ChannelFilter />
        <MediaTypeFilter />
      </FilterContainer>
    </Container>
  )
}

export default MediaObjectSearch
