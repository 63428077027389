import TextareaAutosize from 'react-textarea-autosize'
import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

import { themed } from '../Theme'

export const StyledList = themed(styled.div`
  ${({ theme }) => css`
    color: ${theme.grey900Color};
  `}
`)

export const IconWrapper = themed(styled.div`
  background: ${({ backgroundColor }) => backgroundColor};
  margin-top: 0.5rem;
  border-radius: 5rem;
  position: relative;
  height: 1rem;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  ${({ empty, theme }) =>
    empty &&
    css`
      svg {
        fill: ${theme.primaryColor};
      }
    `};
`)

export const ListItemWrapper = themed(styled(Flex)`
  padding-right: 0.75rem;
  margin-bottom: 0.625rem;

  .close-icon {
    margin-top: 0.5rem;
    opacity: 0;
    transition: opacity 0.2s;
  }

  ${({ removable, theme }) =>
    removable &&
    css`
      &:hover {
        .close-icon {
          opacity: 1;
          cursor: pointer;
        }

        background: ${theme.grey100Color};
        border-radius: 0.25rem;
      }
    `};
`)

export const ListItemTextarea = themed(styled(TextareaAutosize)`
  border: none;
  padding: 0.375rem 1rem;
  outline: none;
  resize: none;
  width: 100%;
  background: transparent;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.3px;

  ${({ theme }) =>
    css`
      color: ${theme.defaultColor};
    `};
  ::placeholder {
    ${({ theme }) =>
      css`
        color: ${theme.grey700Color};
      `};
  }
`)
