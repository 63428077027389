import React, { PropsWithChildren } from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { withBuilderValidation } from '@hocs'
import { FormInput } from '@tribegroup'
import BudgetErrorMessage from '../../Error/Message'

interface IProps {
  fieldName: string
  fieldErrorKey: string
}

interface IInternalProps extends IProps, IBuilderValidationContextProps, InjectedIntlProps {}

const BuilderBudgetTargetAudienceRequiredField: React.FC<PropsWithChildren<IInternalProps>> = ({
  intl,
  children,
  fieldName,
  fieldErrorKey,
  validationResults,
  clearFieldValidation,
}) => {
  React.useEffect(() => {
    return () => {
      setTimeout(() => {
        clearFieldValidation(fieldName)
      })
    }
  }, [])

  return (
    <FormInput
      validationRule={{
        required: {
          value: true,
          message: intl.formatMessage({ id: fieldErrorKey }),
        },
      }}
    >
      {children}
      <BudgetErrorMessage results={validationResults} errorKey={fieldName} />
    </FormInput>
  )
}

export default compose<IInternalProps, PropsWithChildren<IProps>>(
  withBuilderValidation,
  injectIntl,
)(BuilderBudgetTargetAudienceRequiredField)
