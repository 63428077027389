import React from 'react'

import Flex from 'styled-flex-component'

import CampaignModalPaidPartnershipWatcher from '@components/Campaign/Modal/PaidPartnership/Watcher'
import CampaignNotEnoughCreditModal from '@components/Campaign/NotEnoughCreditModal/NotEnoughCreditModal'
import CampaignPreview from '@components/Campaign/Preview'
import InboxPromptWatcher from '@components/ConnectedAccounts/SideBarItem/Education/Watcher'
import InboxFilters from '@components/Inbox/Filters'
import InboxList from '@components/Inbox/List'
import InboxMemberList from '@components/Inbox/Member/List'
import InboxTitleBar from '@components/Inbox/TitleBar'
import InboxTopPanel from '@components/Inbox/TopPanel/TopPanel'
import MediaObjectsFacebookAdAccounts from '@components/MediaObject/Licensing/Licensed/FacebookAdAccounts'
import ConnectedPayment from '@components/Payment/ConnectedPayment'
import InboxReportList from '@components/Reports/List/List'
import BrandedContentAdWatcher from '@components/Submission/Card/BrandedContentAd/Watcher'
import SubmissionComments from '@components/Submission/Comments'
import MainLayout from '@components/UI/MainLayout'
import PaddedContainerStyled from '@components/UI/PaddedContainer/PaddedContainer.styled'
import SideBar from '@components/UI/SideBar'
import AnalyticsTrackingProvider from '@context/AnalyticsTracking'
import { CampaignProvider } from '@context/Campaign'
import { CurrentMediaObjectIdProvider } from '@context/CurrentMediaObjectId'
import { CurrentSubmissionIdProvider } from '@context/CurrentSubmissionId'
import { DrawerProvider } from '@context/Drawer'
import { InboxPromptProvider } from '@context/InboxPrompt'
import { ModalProvider } from '@context/Modal'
import PopupMenuProvider from '@context/PopupMenu'
import { SelectedSubmissionsProvider } from '@context/SelectedSubmissions'
import { SubmissionProvider } from '@context/Submission'
import { SubmissionListFilterProvider } from '@context/SubmissionListFilter'
import { ToastContainer } from '@tribegroup'

const InboxPage: React.FC = () => {
  return (
    <MainLayout>
      <ToastContainer />
      <CampaignProvider>
        <SubmissionListFilterProvider>
          <SelectedSubmissionsProvider>
            <ConnectedPayment>
              <AnalyticsTrackingProvider>
                <CurrentSubmissionIdProvider>
                  <CurrentMediaObjectIdProvider>
                    <ModalProvider>
                      <DrawerProvider>
                        <InboxPromptProvider>
                          <PopupMenuProvider>
                            <BrandedContentAdWatcher />
                            <CampaignModalPaidPartnershipWatcher />
                            <MediaObjectsFacebookAdAccounts />
                            <InboxTitleBar />
                            <CampaignPreview />
                            <CampaignNotEnoughCreditModal />
                            <SubmissionProvider>
                              <SubmissionComments />
                            </SubmissionProvider>
                            <Flex>
                              <SideBar>
                                <InboxFilters />
                                <InboxReportList />
                                <InboxMemberList />
                              </SideBar>
                              <Flex column full>
                                <PaddedContainerStyled>
                                  <InboxTopPanel />
                                  <InboxList />
                                </PaddedContainerStyled>
                              </Flex>
                            </Flex>
                          </PopupMenuProvider>
                          <InboxPromptWatcher />
                        </InboxPromptProvider>
                      </DrawerProvider>
                    </ModalProvider>
                  </CurrentMediaObjectIdProvider>
                </CurrentSubmissionIdProvider>
              </AnalyticsTrackingProvider>
            </ConnectedPayment>
          </SelectedSubmissionsProvider>
        </SubmissionListFilterProvider>
      </CampaignProvider>
    </MainLayout>
  )
}

export default InboxPage
