import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { H2, H5 } from '@tribegroup'
import BuilderBudgetTargetHeadline from './../Headline'

const BuilderBudgetTargetAudienceHeadline: React.FC = () => (
  <BuilderBudgetTargetHeadline textWidth={12.5}>
    <H5 color={Theme.grey900Color}>
      <FormattedMessage id="builder.budget.demographics.targetAudience.header" />
    </H5>
    <H2 color={Theme.grey900Color}>
      <FormattedMessage id="builder.budget.demographics.targetAudience.target" />
    </H2>
  </BuilderBudgetTargetHeadline>
)

export default BuilderBudgetTargetAudienceHeadline
