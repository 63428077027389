import React, { PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'

import { Spin } from '../Effects'
import { Icon } from '../Icon'
import { IScheme } from '../Interfaces/schemes'
import { getColor, themed } from '../Theme'
import { isTruthyOrZero } from '../utils'

export interface IButtonProps extends IScheme {
  /**
   * Displays button in an outlined state
   *
   * @default false
   */
  outlined?: boolean

  /**
   * Displays button in an quiet state
   *
   * @default false
   */
  quiet?: boolean

  /**
   * Displays button in disabled state
   *
   * @default false
   */
  disabled?: boolean

  /**
   * Displays button with a loading icon
   *
   * @default false
   */
  loading?: boolean

  /**
   * Font size for the button in rem
   *
   * @default 0.75
   */
  fontSize?: number

  /**
   * Displays button text in heavy font
   */
  heavy?: boolean

  /**
   * Callback function once button is clicked
   *
   * @default undefined
   */
  onClick?: (event: React.SyntheticEvent) => void

  /**
   * fixed width of the button in rem
   *
   * @default undefined
   */
  width?: number

  /**
   * if true, make the button full width of parent
   *
   * @default false
   */
  fullWidth?: boolean

  /**
   * Show as campact size butotn
   */
  small?: boolean

  /**
   * fixed height of the button in rem
   *
   * @default undefined
   */
  height?: number

  /**
   * disable text transform uppercase for button
   *
   * @default undefined
   */
  noUppercase?: boolean

  /**
   * left margin for button in rem
   *
   * @default undefined
   */
  leftOuterSpacing?: number

  /**
   * right margin for button in rem
   *
   * @default undefined
   */
  rightOuterSpacing?: number

  /**
   * top margin for button in rem
   *
   * @default undefined
   */
  topOuterSpacing?: number

  /**
   * bottom margin for button in rem
   *
   * @default undefined
   */
  bottomOuterSpacing?: number

  /**
   * top padding for button in rem
   *
   * @default undefined
   */
  topInnerSpacing?: number

  /**
   * top padding for button in rem
   *
   * @default undefined
   */
  bottomInnerSpacing?: number

  /**
   * right padding for button in rem
   *
   * @default undefined
   */
  rightInnerSpacing?: number

  /**
   * left padding for button in rem
   *
   * @default undefined
   */
  leftInnerSpacing?: number

  /**
   * disable focus effects
   *
   * @default false
   */
  noFocus?: boolean

  /**
   * indicate that this component is placed on grey container (affects disabled style)
   */
  onGrey?: boolean

  /**
   * Html button type
   *
   * @default undefined
   */
  type?: string

  /**
   * Set the TabIndex for button
   *
   * @default undefined
   */
  tabIndex?: number

  /**
   * Sets margin as auto to center button
   *
   * @default undefined
   */
  centered?: boolean

  /**
   * Sets margin to 0 auto to respect svg icons
   */
  hasIcon?: boolean

  /**
   * Add nowrap for whitespace
   */
  nowrap?: boolean

  backgroundColor?: string
  borderRadius?: number
  borderStyle?: string
}

const StyledButton = themed(styled<IButtonProps, any>('button')`
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.25s all;
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  border-radius: 2rem;
  padding: 0.8125rem 1.4375rem;
  ${({
    quiet,
    outlined,
    scheme,
    noUppercase,
    small,
    theme,
    fontSize,
    heavy,
    width,
    fullWidth,
    height,
    leftOuterSpacing,
    rightOuterSpacing,
    topOuterSpacing,
    bottomOuterSpacing,
    topInnerSpacing,
    bottomInnerSpacing,
    leftInnerSpacing,
    rightInnerSpacing,
    centered,
    noFocus,
    onGrey,
    nowrap,
    backgroundColor,
    borderRadius,
    borderStyle,
  }) => css`
    border: solid 1px ${getColor(theme, scheme)};

    /** heavy */
    ${heavy &&
    css`
      font-weight: 500;
    `};

    /** small */
    ${small &&
    css`
      padding: 0.5rem 0.9375rem;
    `};

    ${noUppercase &&
    css`
      text-transform: none;
    `};

    font-size: ${fontSize}rem;
    color: ${theme.whiteColor};
    background-color: ${theme.grey900Color};

    /** outlined */
    ${outlined &&
    css`
      background-color: ${theme.whiteColor};
      color: ${theme.grey900Color};
      border: solid 1px ${theme.grey900Color};
    `}

    /** with color scheme */

    ${scheme &&
    css`
      background-color: ${getColor(theme, scheme)};

      &:focus {
        ${!noFocus &&
        css`
          box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 10%);
        `}
      }

      &:disabled {
        cursor: default;
        color: ${theme.grey700Color};
        ${onGrey
          ? css`
              background-color: ${theme.grey300Color};
              border-color: ${theme.grey300Color};
            `
          : css`
              background-color: ${theme.grey200Color};
              border-color: ${theme.grey200Color};
            `};
      }

      /** with color scheme && outlined={true} */

      ${outlined &&
      css`
        color: ${getColor(theme, scheme)};
        background-color: transparent;
        border: solid 1px ${getColor(theme, scheme)};
      `}

      /** with color scheme && quiet={true} */

      ${quiet &&
      css`
        background-color: transparent;
        color: ${theme.grey900Color};
        border: solid 1px transparent;

        &:focus {
          box-shadow: none;
          border-radius: none;
          text-decoration: underline;
          ${noFocus &&
          css`
            text-decoration: none;
          `}
        }
      `};
    `}
      
    ${centered &&
    css`
      margin: auto;
    `};

    ${width &&
    css`
      width: ${width}rem;
    `};

    ${fullWidth &&
    css`
      width: 100%;
    `};

    ${height &&
    css`
      height: ${height}rem;
    `};

    ${leftOuterSpacing &&
    css`
      margin-left: ${leftOuterSpacing}rem;
    `};

    ${rightOuterSpacing &&
    css`
      margin-right: ${rightOuterSpacing}rem;
    `};

    ${bottomOuterSpacing &&
    css`
      margin-bottom: ${bottomOuterSpacing}rem;
    `};

    ${topOuterSpacing &&
    css`
      margin-top: ${topOuterSpacing}rem;
    `};

    ${nowrap &&
    css`
      white-space: nowrap;
    `};

    ${isTruthyOrZero(topInnerSpacing) &&
    css`
      padding-top: ${topInnerSpacing}rem;
    `};

    ${isTruthyOrZero(bottomInnerSpacing) &&
    css`
      padding-bottom: ${bottomInnerSpacing}rem;
    `};

    ${isTruthyOrZero(leftInnerSpacing) &&
    css`
      padding-left: ${leftInnerSpacing}rem;
    `};

    ${isTruthyOrZero(rightInnerSpacing) &&
    css`
      padding-right: ${rightInnerSpacing}rem;
    `};

    ${backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
    ${borderRadius &&
    css`
      border-radius: ${borderRadius}rem;
    `}
    ${borderStyle &&
    css`
      border-style: ${borderStyle};
    `}
  `};
`)

interface IButtonText {
  leftSpace: number
}

const ButtonText = styled<IButtonText, any>('div')`
  position: relative;
  transition: 0.25s padding;
  display: flex;
  align-items: center;
  margin: auto;
  ${({ hasIcon }) =>
    hasIcon &&
    css`
      margin: 0 auto;
    `};
  ${({ leftSpace }) => css`
    padding-left: ${leftSpace}rem;
  `};
`

interface ILoaderProps extends IButtonProps {
  /**
   * adjustments needed based on the button font size
   *
   * @default 0.75
   */
  size: number
}

const Loader = themed(styled<ILoaderProps, any>(Icon)`
  ${({ theme, scheme, outlined, quiet, size, disabled }) =>
    size &&
    css`
      position: absolute;
      left: 0;

      svg {
        ${!quiet &&
        css`
          fill: ${disabled ? theme.grey700Color : theme.whiteColor};
        `};
        ${!disabled &&
        outlined &&
        css`
          fill: ${getColor(theme, scheme)};
        `};
        animation: ${Spin} 2.8s linear infinite;
      }
    `};
`)

export default class Button extends React.PureComponent<PropsWithChildren<IButtonProps>> {
  static defaultProps = {
    fontSize: 0.75,
  }
  public render() {
    const { children, loading, fontSize, quiet, outlined, scheme, disabled, hasIcon } = this.props
    const textLeftSpace = loading ? fontSize! + fontSize! / 2 : 0
    return (
      <StyledButton {...this.props} loading={loading ? 1 : 0} disabled={disabled}>
        <ButtonText leftSpace={textLeftSpace} hasIcon={hasIcon}>
          {loading && (
            <Loader
              glyph="spinner"
              size={fontSize}
              quiet={quiet}
              outlined={outlined}
              scheme={scheme}
              disabled={disabled}
            />
          )}
          {children}
        </ButtonText>
      </StyledButton>
    )
  }
}
