import React, { PropsWithChildren } from 'react'

import { ICoordinates } from '@context/InformationTooltip'
import { useBodyOverlayedClassHandler } from '@hooks/useBodyOverlayedClassHandler'
import PopupMenuBaseWrapper from './PopupMenuBaseWrapper'

export interface IPopupMenuContextProps {
  showPopup: (content: React.ReactNode, coordinates: ICoordinates) => void
  hidePopup: VoidFunction
  coordinates?: ICoordinates
  content?: React.ReactNode
  isVisible: boolean
}

const PopupMenuContext = React.createContext({} as IPopupMenuContextProps)

const POPUP_TRANSITION_DURATION = 200

const PopupMenuProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [content, setContent] = React.useState<React.ReactNode | undefined>()
  const [coordinates, setCoordinates] = React.useState<ICoordinates | undefined>()
  const [isVisible, setIsVisible] = React.useState(false)

  const showPopup = (newItems: React.ReactNode, newCoordinates: ICoordinates) => {
    setContent(newItems)
    setCoordinates(newCoordinates)
    setTimeout(() => {
      setIsVisible(true)
    }, 0)
  }

  const hidePopup = () => {
    setIsVisible(false)
    setTimeout(() => {
      setContent(undefined)
      setCoordinates(undefined)
    }, POPUP_TRANSITION_DURATION)
  }

  React.useEffect(() => {
    window.addEventListener('scroll', hidePopup)
    window.addEventListener('resize', hidePopup)
    return () => {
      window.removeEventListener('scroll', hidePopup)
      window.removeEventListener('resize', hidePopup)
    }
  }, [])

  useBodyOverlayedClassHandler(isVisible)

  return (
    <PopupMenuContext.Provider
      value={{
        showPopup,
        hidePopup,
        coordinates,
        content,
        isVisible,
      }}
    >
      {content && <PopupMenuBaseWrapper transitionDuration={POPUP_TRANSITION_DURATION} />}
      {children}
    </PopupMenuContext.Provider>
  )
}

export { PopupMenuContext, POPUP_TRANSITION_DURATION }

export default PopupMenuProvider
