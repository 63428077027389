import { IResponseError } from '@lib/error'

export enum MediaUploadActionTypes {
  UPLOAD_REQUEST = 'media/UPLOAD_REQUEST',
  UPLOAD_SUCCESS = 'media/UPLOAD_SUCCESS',
  UPLOAD_ERROR = 'media/UPLOAD_ERROR',
  UPLOAD_CLEAR = 'media/UPLOAD_CLEAR',
}

export interface IMedia {
  id: string
  media_url: string
  media_type: string
}

export interface IMediaState {
  readonly uploading: ReadonlyArray<string>
  readonly media?: IMedia
  readonly errors?: IResponseError
}
