import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import Loading from '@components/UI/Loading'
import {
  fetchIdentities,
  selectBrandManagerIdentities,
  selectIsFetching,
} from '@store/brandManagerIdentities'
import { Container } from '@tribegroup'
import { isEmpty } from '@utils'
import ConnectedAccountsListIdentityRow from './IdentityRow'

const ConnectedAccountsList = () => {
  const dispatch = useDispatch()
  const onFetchIdentities = () => dispatch(fetchIdentities())
  const brandManagerIdentities = useSelector(selectBrandManagerIdentities)

  useEffect(() => {
    onFetchIdentities()
  }, [])

  const isFetching = useSelector(selectIsFetching)

  if (isEmpty(brandManagerIdentities) && isFetching) {
    return <Loading />
  }

  if (isEmpty(brandManagerIdentities)) {
    return null
  }

  return (
    <Container topOuterSpacing={2} bottomOuterSpacing={-2.5} data-testid="identity-list-container">
      {brandManagerIdentities.map((brandManagerIdentity) => (
        <ConnectedAccountsListIdentityRow
          key={brandManagerIdentity.id}
          brandManagerIdentity={brandManagerIdentity}
        />
      ))}
    </Container>
  )
}

export default ConnectedAccountsList
