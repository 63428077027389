import { SyntheticEvent, useEffect, useRef, useState } from 'react'

import { useDispatch } from 'react-redux'

import Breakpoint from '@components/UI/Breakpoint'
import { IModalComponentProps } from '@context/Modal'
import { EventTrackingNames, MediaTypes } from '@enums'
import { trackEvent } from '@store/analytics'
import { IPorfolioObject } from '@store/creator-bio'
import Theme from '@theme'
import { Icon, Modal } from '@tribegroup'
import CreatorPortfolioGalleryImage from './Image/Image'
import CreatorPortfolioGalleryVideo from './Video/Video'
import {
  CloseIcon,
  FrameCounter,
  NavigationButton,
  PortfolioContainer,
  PortfolioItemContainer,
  PortfolioModalContainer,
} from './PortfolioGallery.styled'

interface IProps extends IModalComponentProps {
  initialFrame: number
  portolioItems: ReadonlyArray<IPorfolioObject>
  submissionId?: number
  campaignId?: number
}

const CreatorPortfolioGallery: React.FC<IProps> = ({
  isOpen = false,
  onRequestClose,
  initialFrame,
  portolioItems,
  campaignId,
  submissionId,
}) => {
  const dispatch = useDispatch()
  const [initialized, setInitialized] = useState(false)
  const [activeFrame, setActiveFrame] = useState(initialFrame)
  const [oldFrame, setOldFrame] = useState(initialFrame)
  const portfolioContainerRef = useRef<HTMLElement>()

  const loadFrame = (frameNumber: number, behavior: ScrollBehavior = 'smooth') => {
    if (!portfolioContainerRef.current) {
      return
    }
    const child = portfolioContainerRef.current.children.item(frameNumber)
    if (!child) {
      return
    }
    child.scrollIntoView({ behavior })
    setActiveFrame(frameNumber)
  }

  const nextFrame = () => {
    loadFrame(activeFrame + 1)
  }

  const previousFrame = () => {
    loadFrame(activeFrame - 1)
  }

  const setPortfolioContainerRef = (portfolioContainer: HTMLDivElement) => {
    portfolioContainerRef.current = portfolioContainer
    if (!initialized && portfolioContainer) {
      loadFrame(activeFrame, 'auto')
      setInitialized(true)
    }
  }

  const isElementInViewPort = (element: HTMLDivElement) => {
    const rect = element.getBoundingClientRect()
    const containerRect = portfolioContainerRef.current?.getBoundingClientRect()
    if (!containerRect) {
      return
    }
    return (
      rect.top >= containerRect.top &&
      rect.left >= containerRect.left &&
      rect.bottom <= containerRect.bottom &&
      rect.right <= containerRect.right
    )
  }

  const checkSelectedElementInView = ({ currentTarget }: SyntheticEvent) => {
    const foundIndex = Array.from(currentTarget.children).findIndex((el) =>
      isElementInViewPort(el as HTMLDivElement),
    )
    if (foundIndex !== -1) {
      setActiveFrame(foundIndex)
    }
  }

  useEffect(() => {
    if (oldFrame === activeFrame) {
      return
    }
    const isPrevious = oldFrame - activeFrame > 0

    dispatch(
      trackEvent(
        isPrevious
          ? EventTrackingNames.ViewBioPreviewPrevious
          : EventTrackingNames.ViewBioPreviewNext,
        {
          brief_id: campaignId,
          submission_id: submissionId,
        },
      ),
    )
    setOldFrame(activeFrame)
  }, [activeFrame, setOldFrame, oldFrame])

  const onClose = () => {
    dispatch(
      trackEvent(EventTrackingNames.ViewBioPreviewDismiss, {
        brief_id: campaignId,
        submission_id: submissionId,
      }),
    )
    onRequestClose()
  }

  return (
    <Modal
      reactModalProps={{
        isOpen,
        onRequestClose: onClose,
        shouldCloseOnOverlayClick: true,
      }}
      width={48}
      padding={0}
      mobilePadding={0}
      enableOverlayClick
    >
      <PortfolioModalContainer>
        <Breakpoint desktop tabletPortrait tabletLandscape>
          {activeFrame !== 0 && (
            <NavigationButton onClick={previousFrame} data-testid="portfolio-gallery-previous">
              <Icon glyph="chevron-left" size={2} color={Theme.defaultColor} />
            </NavigationButton>
          )}
        </Breakpoint>
        <PortfolioContainer
          ref={setPortfolioContainerRef}
          onScroll={checkSelectedElementInView}
          data-testid="portfolio-container"
        >
          {portolioItems.map((item, frameNumber) => (
            <PortfolioItemContainer key={item.id} flexCenter width="100%">
              {item.media_type === MediaTypes.IMAGE && (
                <CreatorPortfolioGalleryImage portfolioObject={item} />
              )}
              {item.media_type === MediaTypes.VIDEO && (
                <CreatorPortfolioGalleryVideo
                  portfolioObject={item}
                  isActive={frameNumber === activeFrame}
                />
              )}
            </PortfolioItemContainer>
          ))}
        </PortfolioContainer>
        <Breakpoint desktop tabletPortrait tabletLandscape>
          {activeFrame < portolioItems.length - 1 && (
            <NavigationButton onClick={nextFrame} data-testid="portfolio-gallery-next">
              <Icon glyph="chevron-right" size={2} color={Theme.defaultColor} />
            </NavigationButton>
          )}
        </Breakpoint>
        <Breakpoint mobile>
          <CloseIcon onClick={onClose} />
        </Breakpoint>
        <FrameCounter data-testid="portfolio-frame-information">
          {activeFrame + 1}/{portolioItems.length}
        </FrameCounter>
      </PortfolioModalContainer>
    </Modal>
  )
}

export default CreatorPortfolioGallery
