import { useDispatch, useSelector } from 'react-redux'

import { IApplicationState } from '@store'
import {
  fetchCampaigns,
  selectCampaignsByStatus,
  selectCampaignsHasMore,
  selectCampaignsIsFetched,
  selectCampaignsIsFetching,
  selectCampaignsListStats,
} from '@store/campaigns'
import { useCurrentCampaignStatusFilter } from './useCurrentCampaignStatusFilter'

const useCampaigns = () => {
  const currentCampaignStatusFilter = useCurrentCampaignStatusFilter()

  const stateProps = useSelector((state: IApplicationState) => ({
    stats: selectCampaignsListStats(state) || {},
    campaigns: selectCampaignsByStatus(state, currentCampaignStatusFilter),
    isFetching: selectCampaignsIsFetching(state),
    isFetched: selectCampaignsIsFetched(state),
    hasMore: selectCampaignsHasMore(state, currentCampaignStatusFilter),
  }))

  const dispatch = useDispatch()

  const dispatchProps = {
    fetch: () => dispatch(fetchCampaigns(currentCampaignStatusFilter)),
    fetchMore: () => dispatch(fetchCampaigns(currentCampaignStatusFilter, true)),
  }

  return { ...stateProps, ...dispatchProps, currentCampaignStatusFilter }
}

export { useCampaigns }
