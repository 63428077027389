import { FormattedMessage } from 'react-intl'

import { FormValidationMessage, Text } from '@tribegroup'

interface IProps {
  intlId?: string
}

const AuthSignupFormErrorContainer: React.FC<IProps> = ({ intlId }) => {
  if (!intlId) {
    return null
  }

  return (
    <FormValidationMessage>
      <Text small>
        <FormattedMessage id={intlId} />
      </Text>
    </FormValidationMessage>
  )
}

export default AuthSignupFormErrorContainer
