import React from 'react'

interface IBuilderSubmitFormWrapperProps {
  children: any
}

interface IBuilderSubmitFormWrapperState {
  schedulingShown: boolean
}

export class BuilderSubmitFormWrapper extends React.PureComponent<
  IBuilderSubmitFormWrapperProps,
  IBuilderSubmitFormWrapperState
> {
  state = {
    schedulingShown: true,
  }

  onShowForm = (event: React.SyntheticEvent) => {
    event.preventDefault()
    this.setState({
      schedulingShown: false,
    })
  }

  onShowSchedule = (event: React.SyntheticEvent) => {
    event.preventDefault()
    this.setState({
      schedulingShown: true,
    })
  }

  render() {
    return React.cloneElement(this.props.children, {
      schedulingShown: this.state.schedulingShown,
      onShowForm: this.onShowForm,
      onShowSchedule: this.onShowSchedule,
    })
  }
}
