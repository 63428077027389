import { FormattedMessage } from 'react-intl'

import { EllipsisWrapper } from '@components/InfluencerIdentity/Row/Details/Details.styled'
import DetailCard from '@components/UI/DetailCard'
import { Information } from '@components/UI/Information'
import Theme from '@theme'
import { Container, H1, Header, Icon } from '@tribegroup'
import { AnalyticsDetailCardFeaturedMetric } from './DetailCard.styled'
import AnalyticsDetailCardTooltip from './Tooltip'

interface IAnalyticsDetailCard {
  title: string
  content: React.ReactNode
  values?: Record<string, string | React.ReactElement>
  productMetricGroup?: string
  productMetricValue?: string
  externalLink?: string
  locked?: boolean
  showTooltip?: boolean
  elementName?: string
  featured?: boolean
  showAsterisk?: boolean
}

const contentOrEmpty = (content, locked) => {
  if (locked) {
    return undefined
  }

  if (!content) {
    return <FormattedMessage id="core.metric.empty" />
  }

  return content
}

const AnalyticsDetailCard: React.FC<IAnalyticsDetailCard> = ({
  title,
  content,
  values,
  locked,
  showTooltip = true,
  elementName,
  productMetricValue,
  productMetricGroup,
  externalLink,
  featured,
  showAsterisk,
}) => {
  const glyph = locked ? 'locked' : 'information'
  const glyphColor = locked ? Theme.grey600Color : Theme.grey900Color

  const tooltipMessageId = `${title}.tooltip`

  if (featured) {
    return (
      <Container bottomOuterSpacing={1.5} data-testid={elementName}>
        <AnalyticsDetailCardFeaturedMetric fullWidth inlineFlex alignItems="flex-end" gap={0.25}>
          <Header cta color={Theme.grey900Color} uppercase>
            <FormattedMessage id={title} values={values} />
            {showAsterisk && <FormattedMessage id="core.text.symbol.attention" />}
          </Header>
          <Information width={17} target={<Icon glyph={glyph} color={glyphColor} />}>
            <AnalyticsDetailCardTooltip
              title={<FormattedMessage id={title} values={values} />}
              content={<FormattedMessage id={tooltipMessageId} values={values} />}
              locked={locked}
              externalLink={externalLink}
              productMetricGroup={productMetricGroup}
              productMetricValue={productMetricValue}
              showAsterisk={showAsterisk}
            />
          </Information>
        </AnalyticsDetailCardFeaturedMetric>
        <Container topOuterSpacing={0.5} minHeight={2.5}>
          <H1 color={Theme.primaryColor}>{contentOrEmpty(content, locked)}</H1>
        </Container>
      </Container>
    )
  }

  return (
    <DetailCard
      title={
        <EllipsisWrapper color={Theme.grey900Color}>
          <FormattedMessage id={title} values={values} />
          {showAsterisk && <FormattedMessage id="core.text.symbol.attention" />}
        </EllipsisWrapper>
      }
      content={contentOrEmpty(content, locked)}
      tooltip={
        showTooltip && (
          <AnalyticsDetailCardTooltip
            title={<FormattedMessage id={title} values={values} />}
            content={<FormattedMessage id={tooltipMessageId} values={values} />}
            locked={locked}
            externalLink={externalLink}
            productMetricGroup={productMetricGroup}
            productMetricValue={productMetricValue}
            showAsterisk={showAsterisk}
          />
        )
      }
      tooltipWidth={18.125}
      glyph={glyph}
      glyphColor={glyphColor}
      elementName={elementName}
    />
  )
}

export default AnalyticsDetailCard
