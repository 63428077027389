import styled from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'

export const PreviouslyPurchasedWrapper = styled(Flex)`
  border-radius: 1rem;
  border: 1px solid ${Theme.secondary100Color};
  min-width: 1rem;
  height: 1rem;
  text-align: center;
  box-sizing: border-box;
`
