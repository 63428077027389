import Flex from 'styled-flex-component'

import CampaignList from '@components/Campaign/List'
import CampaignModalCampaignCreationPrompt from '@components/Campaign/Modal/CampaignCreation/Prompt'
import CampaignNotEnoughCreditModal from '@components/Campaign/NotEnoughCreditModal/NotEnoughCreditModal'
import CampaignsTitleBar from '@components/Campaign/TitleBar'
import HomeSideBar from '@components/Home/SideBar/SideBar'
import ConnectedPayment from '@components/Payment/ConnectedPayment'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import PaddedContainerStyled from '@components/UI/PaddedContainer/PaddedContainer.styled'
import UIFlagWatcherModal from '@components/UI/UIFlagWatcherModal'
import AnalyticsTrackingProvider from '@context/AnalyticsTracking'
import { CampaignListFilterProvider } from '@context/CampaignListFilter'
import { ModalProvider } from '@context/Modal'
import { UIFlag } from '@store/ui'
import { ToastContainer } from '@tribegroup'

export const CampaignsPage = () => {
  return (
    <MainLayout>
      <ConnectedPayment>
        <ModalProvider>
          <ToastContainer sidebarModifier={13.875} />
          <HeadTag id="core.text.campaigns" />
          <CampaignListFilterProvider>
            <AnalyticsTrackingProvider>
              <UIFlagWatcherModal
                uiFlag={UIFlag.SHOW_CAMPAIGN_CREATION_CONFIRMATION_MODAL}
                modalComponent={CampaignModalCampaignCreationPrompt}
              />
              <CampaignsTitleBar />
              <Flex>
                <HomeSideBar />
                <Flex column full>
                  <PaddedContainerStyled>
                    <CampaignList />
                  </PaddedContainerStyled>
                </Flex>
              </Flex>
            </AnalyticsTrackingProvider>
          </CampaignListFilterProvider>
          <CampaignNotEnoughCreditModal />
        </ModalProvider>
      </ConnectedPayment>
    </MainLayout>
  )
}

export default CampaignsPage
