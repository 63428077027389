import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { SubmissionChannels } from '@enums'
import { IOption } from '@store/rootResource'
import { MediaObjectSearchBaseFilter } from './BaseFilter'

const channels = [
  SubmissionChannels.Instagram,
  SubmissionChannels.TikTok,
  SubmissionChannels.Pinterest,
  SubmissionChannels.Facebook,
  SubmissionChannels.Twitter,
]

export const ChannelFilter: React.FC<InjectedIntlProps> = ({ intl }) => {
  const options: IOption[] = channels.map((channel) => ({
    label: intl.formatMessage({ id: `core.channelNames.${channel}` }),
    value: channel,
  }))

  return (
    <MediaObjectSearchBaseFilter
      allLabel={<FormattedMessage id="contentLibrary.search.filter.channel" />}
      options={options}
      searchKey="channel_filter"
    />
  )
}

export default compose(injectIntl)(ChannelFilter)
