import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import Image from '@components/UI/Image'
import { IModalContextProps } from '@context/Modal'
import { AspectRatios, EventTrackingNames, MediaTypes } from '@enums'
import { withModal } from '@hocs'
import { useLocationSearch } from '@hooks/useLocationSearch'
import { IApplicationState } from '@store'
import { trackEvent } from '@store/analytics'
import { selectIsFetching, selectPortfolioByCreatorLink } from '@store/creator-bio'
import Theme from '@theme'
import { BoxSkeleton, Container, H4 } from '@tribegroup'
import CreatorPortfolioGallery from '../PortfolioGallery/PortfolioGallery'
import { Portfolio, PortfolioObjectIcon } from './Portfolio.styled'

const LoadingTiles = () => (
  <>
    <BoxSkeleton height={125} />
    <BoxSkeleton height={125} />
    <BoxSkeleton height={125} />
    <BoxSkeleton height={125} />
    <BoxSkeleton height={125} />
    <BoxSkeleton height={125} />
    <BoxSkeleton height={125} />
    <BoxSkeleton height={125} />
    <BoxSkeleton height={125} />
  </>
)

interface IPortfolioObjectProps {
  srcUrl: string
  mediaType: MediaTypes
  onClick: () => void
  submissionId?: number
  campaignId?: number
}

const PortfolioObject: React.FC<IPortfolioObjectProps> = ({ srcUrl, mediaType, onClick }) => (
  <Container relative onClick={onClick}>
    <Image src={srcUrl} height="100%" aspectRatio={AspectRatios.FIVE_BY_FOUR} />
    {mediaType === MediaTypes.VIDEO && (
      <PortfolioObjectIcon size={1.5} glyph="video" color={Theme.whiteColor} />
    )}
  </Container>
)

const CreatorPortfolio: React.FC<IModalContextProps> = ({ showModal }) => {
  const dispatch = useDispatch()
  const { href, method, campaignId, submissionId } = useLocationSearch()
  const { isFetching, portfolio } = useSelector((state: IApplicationState) => ({
    isFetching: selectIsFetching(state),
    portfolio: selectPortfolioByCreatorLink(state, { href, method }),
  }))

  const openPortfolioGallery = (selectedObjectId: number) => {
    dispatch(
      trackEvent(EventTrackingNames.ViewBioPreview, {
        brief_id: parseInt(campaignId, 10),
        submission_id: parseInt(submissionId, 10),
      }),
    )
    showModal(CreatorPortfolioGallery, {
      initialFrame: portfolio.findIndex((obj) => obj.id === selectedObjectId),
      portolioItems: portfolio,
      campaignId: campaignId && parseInt(campaignId, 10),
      submissionId: submissionId && parseInt(submissionId, 10),
    })
  }

  return (
    <Container
      data-testid="creator-portfolio"
      bottomOuterSpacing={12.5}
      leftOuterSpacing={-0.5}
      rightOuterSpacing={-0.5}
    >
      <Container>
        <H4 color={Theme.defaultColor}>
          <FormattedMessage id="core.text.portfolio" />
        </H4>
      </Container>
      {isFetching ? (
        <Portfolio data-testid="creator-portfolio-loading">
          <LoadingTiles />
        </Portfolio>
      ) : (
        <Portfolio>
          {portfolio.map((portfolioObject) => (
            <PortfolioObject
              key={portfolioObject.id}
              srcUrl={
                portfolioObject.media_type === MediaTypes.VIDEO
                  ? portfolioObject.thumbnail_url
                  : portfolioObject.original_url
              }
              mediaType={portfolioObject.media_type}
              onClick={() => openPortfolioGallery(portfolioObject.id)}
            />
          ))}
        </Portfolio>
      )}
    </Container>
  )
}

export default withModal(CreatorPortfolio)
