import React from 'react'

import { FormattedMessage } from 'react-intl'

import { BrandFanGroupMemberWarningContext } from '@context/BrandFanGroupMemberWarning'
import Theme from '@theme'
import { H5 } from '@tribegroup'
import BrandFansInfluencerIdentityAddToLive from './Warning/AddToLive'
import BrandFansInfluencerIdentityRemoveFromLive from './Warning/RemoveFromLive'
import BrandFansInfluencerIdentityGroupsForm from './Form'
import { GroupMemberships, WarningContainer } from './Groups.styled'

const MembershipCount = ({ name, count }) => (
  <H5 color={Theme.grey900Color}>
    <FormattedMessage id="brandFans.identityGroups.memberships.count" values={{ name, count }} />
  </H5>
)

interface IProps {
  identityId: number
  influencerId?: number
  submissionId?: number
  campaignId?: number
  groupMembershipsCount: number
  firstName: string
}

const BrandFansInfluencerIdentityFormAndWarningWrapper: React.FC<IProps> = ({
  firstName,
  identityId,
  campaignId,
  submissionId,
  groupMembershipsCount,
  influencerId,
}) => {
  const { warningMode } = React.useContext(BrandFanGroupMemberWarningContext)

  if (warningMode) {
    return (
      <WarningContainer column data-testid={`brand-fans-group-warning-${warningMode}`}>
        {warningMode === 'add' && (
          <BrandFansInfluencerIdentityAddToLive
            campaignId={campaignId}
            submissionId={submissionId}
            identityId={identityId}
            fullName={firstName}
            influencerId={influencerId}
          />
        )}
        {warningMode === 'remove' && (
          <BrandFansInfluencerIdentityRemoveFromLive fullName={firstName} />
        )}
      </WarningContainer>
    )
  }

  return (
    <React.Fragment>
      <GroupMemberships column justifyEnd>
        {Boolean(groupMembershipsCount) && (
          <MembershipCount name={firstName} count={groupMembershipsCount} />
        )}
      </GroupMemberships>
      <BrandFansInfluencerIdentityGroupsForm
        identityId={identityId}
        submissionId={submissionId}
        campaignId={campaignId}
        influencerId={influencerId}
      />
    </React.Fragment>
  )
}

export default BrandFansInfluencerIdentityFormAndWarningWrapper
