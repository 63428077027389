import { DisableRequireApprovalPromptActionTypes, ICampaign } from '@store/campaigns'

export const disableRequireApprovalPrompt = (campaignId: number) => ({
  payload: campaignId,
  type: DisableRequireApprovalPromptActionTypes.DISABLE_REQUEST,
})

export const disableRequireApprovalPromptSuccess = (campaign: ICampaign) => ({
  payload: campaign,
  type: DisableRequireApprovalPromptActionTypes.DISABLE_SUCCESS,
})

export const disableRequireApprovalPromptError = (error: any) => ({
  payload: error,
  type: DisableRequireApprovalPromptActionTypes.DISABLE_ERROR,
})
