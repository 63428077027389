import { ValueLockType } from '@store/performance'
import { IFeatureLockedValue } from '@store/postCampaignReport'

const getFeatureLockedValue = <P, T extends P | IFeatureLockedValue<P>>(value?: T) => {
  if (!value) {
    return undefined
  }

  if (value && ((value as unknown) as IFeatureLockedValue<P>).feature) {
    return ((value as unknown) as IFeatureLockedValue<P>).value
  }

  if (typeof value === 'object' && 'locked' in value) {
    return ((value as unknown) as ValueLockType).value
  }

  return value as P
}

const getFeatureLockedValueIdentifier = <P, T extends P | IFeatureLockedValue<P>>(value: T) => {
  return value && ((value as unknown) as IFeatureLockedValue<P>).feature
}

export { getFeatureLockedValue, getFeatureLockedValueIdentifier }
