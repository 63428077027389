import { createReducer } from '@reduxjs/toolkit'

import { metricDisputeFailed, metricDisputeRequest, metricDisputeSuccess } from './actions'
import { MetricDisputeState } from './types'

const initialState = {
  loading: 'idle',
} as MetricDisputeState

export const metricDisputeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(metricDisputeRequest, (state) => {
      state.loading = 'pending'
      state.errors = undefined
    })

    .addCase(metricDisputeSuccess, (state) => {
      state.loading = 'succeeded'
      state.errors = undefined
    })

    .addCase(metricDisputeFailed, (state, action) => {
      state.loading = 'failed'
      state.errors = action.payload
    })
})
