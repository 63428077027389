import { PCRChannels } from '@enums'
import { IPCRPerformanceMap } from '@store/postCampaignReport'

export const performanceDisplayMap: Record<
  any,
  ReadonlyArray<ReadonlyArray<IPCRPerformanceMap>>
> = {
  [PCRChannels.Facebook]: [
    [
      { key: 'fb.post.totalPost', prop: 'count' },
      { key: 'fb.post.likes', prop: 'total_likes' },
      { key: 'fb.post.overallCostPerEngagement', prop: 'total_cost_per_engagement' },
    ],
    [
      { key: 'fb.post.totalSpend', prop: 'total_purchase_amount' },
      { key: 'fb.post.comments', prop: 'total_comments' },
    ],
    [
      {
        key: 'fb.post.totalReach',
        prop: 'total_followers',
        abbreviateValue: true,
      },
      { key: 'fb.post.shares', prop: 'total_shares' },
    ],
    [
      { key: 'fb.post.totalEngagement', prop: 'total_engagement' },
      { key: 'fb.post.totalEngagementRate', prop: 'total_engagement_pct', showPercentSign: true },
    ],
  ],
  [PCRChannels.Twitter]: [
    [
      { key: 'twitter.post.totalPost', prop: 'count' },
      { key: 'twitter.post.likes', prop: 'total_likes' },
      { key: 'twitter.post.overallCostPerEngagement', prop: 'total_cost_per_engagement' },
    ],
    [
      { key: 'twitter.post.totalSpend', prop: 'total_purchase_amount' },
      { key: 'twitter.post.comments', prop: 'total_comments' },
    ],
    [
      {
        key: 'twitter.post.totalReach',
        prop: 'total_followers',
        abbreviateValue: true,
      },
      { key: 'twitter.post.shares', prop: 'total_shares' },
    ],
    [
      { key: 'twitter.post.totalEngagement', prop: 'total_engagement' },
      {
        key: 'twitter.post.totalEngagementRate',
        prop: 'total_engagement_pct',
        showPercentSign: true,
      },
    ],
  ],
}
