import { Fragment } from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectLicenseOptionDescription, selectLicenseOptionLabel } from '@store/licenses'
import { Radio, SelectionSubtext } from '@tribegroup'

interface ILicenseOptionProps {
  mediaObjectId: number
  licenseIndex: number
  onLicenseChange: (licenseIndex: number) => void
}

interface IInternalProps extends ILicenseOptionProps {
  licenseOptionLabel: string
  licenseOptionDescription: string
}

export const LicenseOption = ({
  licenseOptionLabel,
  licenseOptionDescription,
  licenseIndex,
  onLicenseChange,
}: IInternalProps) => {
  const onChange = () => {
    onLicenseChange(licenseIndex)
  }
  return (
    <Fragment>
      <Radio
        onChange={onChange}
        name="license-options"
        scheme="primary"
        defaultChecked={licenseIndex === 0}
        label={licenseOptionLabel}
        value={licenseIndex}
        labelCapitalize
      />
      <SelectionSubtext>{licenseOptionDescription}</SelectionSubtext>
    </Fragment>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { mediaObjectId, licenseIndex }: IInternalProps,
) => ({
  licenseOptionLabel: selectLicenseOptionLabel(state, mediaObjectId, licenseIndex),
  licenseOptionDescription: selectLicenseOptionDescription(state, mediaObjectId, licenseIndex),
})

export default compose<IInternalProps, ILicenseOptionProps>(connect(mapStateToProps))(LicenseOption)
