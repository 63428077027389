import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import { IApplicationState } from '@store'
import { selectShowPendingSection } from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Container, H3 } from '@tribegroup'
import PostCampaignReportPerformancePending from '../Pending/Pending'

const PostCampaignReportPerformanceTitle = () => {
  const match = useRouteMatch()

  const { showPendingSection } = useSelector((state: IApplicationState) => {
    const campaignId = selectCampaignId(match)!

    return {
      showPendingSection: selectShowPendingSection(state, campaignId),
    }
  })

  return (
    <Container topOuterSpacing={1.25}>
      <H3 color={Theme.grey900Color} bottomOuterSpacing={0.5}>
        <FormattedMessage id="postCampaignReport.performance.title" />
      </H3>
      {showPendingSection && <PostCampaignReportPerformancePending />}
    </Container>
  )
}

export { PostCampaignReportPerformanceTitle }
export default PostCampaignReportPerformanceTitle
