import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { AnalyticsPostCampaignReportGlossaryEntry } from '@components/Analytics/PostCampaignReport/Glossary'
import { CPM_EXPLAINED } from '@constants'
import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { selectCampaignOperatingRegionTaxName } from '@store/campaigns'
import { selectPostsOverallCPE, selectPostsTotalSpend } from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Link } from '@tribegroup'

type IInternalProps = ConnectedProps<typeof connector>

const AnalyticsPostCampaignReportGlossaryPost: React.FC<IInternalProps> = ({ taxName }) => {
  return (
    <React.Fragment>
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="post.published" />
      <AnalyticsPostCampaignReportGlossaryEntry
        translationKey="post.spend"
        values={{
          taxDisplay: taxName,
        }}
      />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="post.followers" />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="post.likes" />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="post.comments" />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="post.engagement" />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="post.engagementPercentage" />
      <AnalyticsPostCampaignReportGlossaryEntry
        translationKey="post.cpe"
        values={{
          taxDisplay: taxName,
        }}
      />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="post.reach" />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="post.reachPercentage" />
      <AnalyticsPostCampaignReportGlossaryEntry translationKey="post.impressions" />
      <AnalyticsPostCampaignReportGlossaryEntry
        translationKey="post.cpm"
        values={{
          link: (
            <Link href={CPM_EXPLAINED} target="_blank" color={Theme.primaryColor} lowercase>
              <FormattedMessage id="core.text.clickHere" />
            </Link>
          ),
        }}
      />
    </React.Fragment>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { match, intl }: RouteComponentProps & InjectedIntlProps,
) => {
  const campaignId = selectCampaignId(match)!

  const totalSpendCurrency = selectPostsTotalSpend(state, campaignId, PCRChannels.IGPosts).currency

  return {
    taxName: selectCampaignOperatingRegionTaxName(state, campaignId, intl, totalSpendCurrency),
    cpeCurrency: selectPostsOverallCPE(state, campaignId, PCRChannels.IGPosts).currency,
  }
}
const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(
  withRouter,
  injectIntl,
  connector,
)(AnalyticsPostCampaignReportGlossaryPost)
