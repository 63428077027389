import styled from 'styled-components'

import Theme from '@theme'

export const LoadingWrapper = styled.div`
  height: calc(100vh - 7.5rem);
`

export const LoadingProfileWrapper = styled.div`
  z-index: 1;
  width: 100%;
  position: relative;
  background: ${Theme.whiteColor};
`

export const LoadingListWrapper = styled.div`
  position: absolute;
  bottom: 4.75rem;
  left: 0;
  width: 100%;
`
