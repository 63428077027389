import { ICampaignMetricsState, IPostMetricsState } from '@store/postCampaignReport'
import { IReelMetricsState } from './reelMetrics'
import { IStoryMetricsState } from './storyMetrics'
import { ITikTokMetricsState } from './tiktokMetrics'
import { IVideoMetricsState } from './videoMetrics'

export interface IPCRChannel {
  active: boolean
  count: number
  channel: string
}

export interface IPCRPerformanceMap {
  key: string
  prop: string
  showTooltip?: boolean
  showPercentSign?: boolean
  abbreviateValue?: boolean
}

export interface IPCRPerformance {
  key: string
  value: number
  currency?: string
  showTooltip?: boolean
  showPercentSign?: boolean
  abbreviateValue?: boolean
}

export interface IPostCampaignReportState {
  campaignMetrics: ICampaignMetricsState
  postMetrics: IPostMetricsState
  videoMetrics: IVideoMetricsState
  storyMetrics: IStoryMetricsState
  tiktokMetrics: ITikTokMetricsState
  reelMetrics: IReelMetricsState
}

export enum DownloadPostCampaignReportActionTypes {
  DOWNLOAD_PCR_REQUEST = 'pcr/DOWNLOAD_PCR_REQUEST',
  DOWNLOAD_PCR_SUCCESS = 'pcr/DOWNLOAD_PCR_SUCCESS',
  DOWNLOAD_PCR_ERROR = 'pcr/DOWNLOAD_PCR_ERROR',
}

export interface IAnalyticsSubmission {
  submission_id: number
  influencer_name: string
  handle: string
  followers: number
  image_url: string
  channel: string
  social_page_url: string
  social_submission_type: string
  published_url?: string
  published_at?: string
}

export interface IAmount {
  amount?: number
  currency?: string
}

export interface IFeatureLockedMetricsSummary {
  total_reach?: number
  avg_reach_pct?: number
  total_impressions?: number
  overall_cpm?: number
  overall_cpm_currency?: string
  reach_enabled?: boolean
  impressions_enabled?: boolean
}

export interface IInsights {
  reach?: number
  reach_pct?: number
  impressions?: number
  cpm?: number
  cpm_currency?: string
}
