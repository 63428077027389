import { createContext, PropsWithChildren, useState } from 'react'

export interface IInboxPromptContextProps {
  component: null
  isPromptOpen?: boolean
  showInboxPrompt: (component: React.ReactNode) => void
  hidePrompt: () => void
}

export interface IInboxPromptComponentProps {
  onRequestClose: VoidFunction
  isOpen?: boolean
}

export const InboxPromptContext = createContext({} as IInboxPromptContextProps)

const InboxPromptProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isPromptOpen, setIsPromptOpen] = useState(false)
  const [component, setComponent] = useState<any>(null)
  const showInboxPrompt = (component: React.ReactNode) => {
    setComponent(component)
    setIsPromptOpen(true)
  }

  const hidePrompt = () => {
    setIsPromptOpen(false)
  }

  return (
    <InboxPromptContext.Provider
      value={{
        component,
        isPromptOpen,
        showInboxPrompt,
        hidePrompt,
      }}
    >
      {isPromptOpen && component}
      {children}
    </InboxPromptContext.Provider>
  )
}

export { InboxPromptProvider }
export default InboxPromptProvider
