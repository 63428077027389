import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

import Confirmation from '@components/UI/Confirmation'
import { FooterButtonWrapper } from '@components/UI/Confirmation/Confirmation.styled'
import { IConnectedSocialAccount } from '@store/campaigns'
import Theme from '@theme'
import { Button, Container, Text } from '@tribegroup'

interface IProps {
  isOpen: boolean
  tiktokSocialAccount: IConnectedSocialAccount
  onCancel: VoidFunction
  onProceed: VoidFunction
}

const SubmissionCardModalTikTokPreApprovalConfirmation = ({
  isOpen,
  tiktokSocialAccount,
  onCancel,
  onProceed,
}: IProps) => {
  return (
    <Confirmation
      title={<FormattedMessage id="submission.preapproval.tiktok.confirmation.title" />}
      content={
        <Container>
          <Text block color={Theme.grey800Color}>
            <FormattedHTMLMessage
              id="submission.preapproval.tiktok.confirmation.paragraph.first"
              values={{ tiktokSocialAccount: tiktokSocialAccount.display_name }}
            />
          </Text>
          <Container topOuterSpacing={0.5}>
            <Text block color={Theme.grey800Color}>
              <FormattedMessage id="submission.preapproval.tiktok.confirmation.paragraph.second" />
            </Text>
          </Container>
        </Container>
      }
      footerButtons={
        <>
          <FooterButtonWrapper>
            <Button
              scheme="primary"
              quiet
              onClick={onCancel}
              data-testid="preapproval-tiktok-submission-modal-cancel-button"
            >
              <FormattedMessage id="core.text.cancel" />
            </Button>
          </FooterButtonWrapper>
          <FooterButtonWrapper>
            <Button
              scheme="primary"
              onClick={onProceed}
              data-testid="preapproval-tiktok-submission-modal-proceed-button"
            >
              <FormattedMessage id="core.text.proceed" />
            </Button>
          </FooterButtonWrapper>
        </>
      }
      isOpen={isOpen}
      onCloseIconClick={onCancel}
    />
  )
}

export default SubmissionCardModalTikTokPreApprovalConfirmation
