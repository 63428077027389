import React from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectShowEmptyState } from '@store/postCampaignReport'
import { selectActivePCRChannel, selectCampaignId } from '@store/router'
import PostCampaignReportSubmissionListEmpty from '../Empty/Empty'
import PostCampaignReportSubmissionList from '../List'

export interface IInternalProps {
  showEmptyState?: boolean
}

const PostCampaignReportSubmissionListWrapper: React.FC<IInternalProps> = ({ showEmptyState }) => {
  if (showEmptyState) {
    return <PostCampaignReportSubmissionListEmpty />
  }

  return <PostCampaignReportSubmissionList />
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match)!
  const activeChannel = selectActivePCRChannel(state)
  return {
    showEmptyState: selectShowEmptyState(state, campaignId, activeChannel),
  }
}

export { PostCampaignReportSubmissionListWrapper }
export default compose(
  withRouter,
  connect(mapStateToProps),
)(PostCampaignReportSubmissionListWrapper)
