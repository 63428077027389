import { createReducer } from '@reduxjs/toolkit'

import { AuthActionTypes } from '@store/auth'
import { indexById } from '@utils/groupBy'
import { fetchTikTokMetrics, fetchTikTokMetricsError, fetchTikTokMetricsSuccess } from './actions'
import { ITikTokMetrics, ITikTokMetricsState } from './types'

const initialState: ITikTokMetricsState = {
  isFetching: false,
  errors: undefined,
  tiktokMetricsById: {},
  tiktokMetricIdsByCampaign: {},
  tiktokMetricsSummary: {},
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchTikTokMetrics, (state) => {
      state.isFetching = true
    })
    .addCase(fetchTikTokMetricsSuccess, (state, { payload }) => {
      const { campaignId, tiktokMetrics } = payload
      state.tiktokMetricsById = {
        ...state.tiktokMetricsById,
        ...indexById(tiktokMetrics.items, state.tiktokMetricsById, 'submission_id'),
      }
      state.tiktokMetricsSummary = {
        ...state.tiktokMetricsSummary,
        [campaignId]: tiktokMetrics.metadata,
      }
      state.tiktokMetricIdsByCampaign = {
        ...state.tiktokMetricIdsByCampaign,
        [campaignId]: tiktokMetrics.items.map((item: ITikTokMetrics) => item.submission_id),
      }
      state.errors = undefined
      state.isFetching = false
    })
    .addCase(fetchTikTokMetricsError, (state, { payload }) => {
      state.isFetching = false
      state.errors = payload
    })
    .addMatcher(
      (action) => action.type === AuthActionTypes.SIGNOUT_SUCCESS,
      () => initialState,
    )
})

export { reducer as tiktokMetricsReducer }
