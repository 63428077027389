import React from 'react'

import OutsideClickHandler from 'react-outside-click-handler'

import Overlay from '@components/UI/Overlay/Overlay.styled'
import { ICoordinates } from '@context/InformationTooltip'
import { Container } from '@tribegroup'
import { PopupMenuContent } from './PopupMenuBaseWrapper.styled'
import { PopupMenuContext } from '.'

interface IProps {
  transitionDuration: number
}

const PopupMenuBaseWrapper: React.FC<IProps> = ({ transitionDuration }) => {
  const { content, hidePopup, isVisible, coordinates } = React.useContext(PopupMenuContext)
  const coords = coordinates || ({} as ICoordinates)

  return (
    <Overlay visible={isVisible} transitionDuration={transitionDuration}>
      <OutsideClickHandler onOutsideClick={hidePopup}>
        <Container
          backgroundColor="transparent"
          absolute
          topPosition={coords.y}
          leftPosition={coords.x}
        >
          <PopupMenuContent transitionDuration={transitionDuration} isVisible={isVisible}>
            {content}
          </PopupMenuContent>
        </Container>
      </OutsideClickHandler>
    </Overlay>
  )
}

export default PopupMenuBaseWrapper
