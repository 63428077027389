import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'

export const OptionWrapper = styled.div`
  display: inline-flex;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${Theme.grey200Color};
  margin-top: 0.5rem;
  ${({ active }) =>
    active &&
    css`
      background-color: ${Theme.primary100Color};
    `}
  ${breakpoint('desktop')`
    margin-top: 0;
  `}
`
