import { createAction } from '@reduxjs/toolkit'

import { CampaignBillingActionTypes } from '../types'

export const fetchCampaignBillings = createAction(
  CampaignBillingActionTypes.FETCH_REQUEST,
  (isFetchNext?: boolean) => ({
    payload: isFetchNext ?? false,
  }),
)

export const fetchCampaignBillingsSuccess = createAction<any>(
  CampaignBillingActionTypes.FETCH_SUCCESS,
)

export const fetchCampaignBillingsError = createAction<any>(CampaignBillingActionTypes.FETCH_ERROR)
