import React from 'react'

import { useSelector } from 'react-redux'
import Slider from 'react-slick'

import { IApplicationState } from '@store'
import { selectSubmissionsByIdentity } from '@store/brandFans'
import { selectBrandFansCurrentView } from '@store/router'
import { ISubmission } from '@store/submissions'
import Arrow from './Arrow'
import { SliderWrapper } from './List.styled'
import SLIDER_RESPONSIVE_CONFIG from './responsive'
import InfluencerIdentityRowSubmissionThumbnail from './Thumbnail'

interface IProps {
  identityId: number
}

const InfluencerIdentityRowSubmissionsList: React.FC<IProps> = ({ identityId }) => {
  const { currentView, identitySubmissions } = useSelector((state: IApplicationState) => ({
    currentView: selectBrandFansCurrentView(state),
    identitySubmissions: selectSubmissionsByIdentity(state, identityId),
  }))
  const [isChangingActiveSlide, setIsChangingActiveSlide] = React.useState(false)
  const beforeChange = () => setIsChangingActiveSlide(true)
  const afterChange = () => setIsChangingActiveSlide(false)
  return (
    <SliderWrapper
      leftOuterSpacing={5.5}
      rightOuterSpacing={5}
      bottomInnerSpacing={1}
      data-testid="slider-wrapper"
    >
      <Slider
        arrows
        infinite={false}
        slidesToScroll={8}
        slidesToShow={8}
        initialSlide={0}
        prevArrow={<Arrow direction="left" />}
        nextArrow={<Arrow direction="right" />}
        beforeChange={beforeChange}
        afterChange={afterChange}
        responsive={SLIDER_RESPONSIVE_CONFIG}
      >
        {identitySubmissions.map((submission: ISubmission) => (
          <InfluencerIdentityRowSubmissionThumbnail
            key={submission.id}
            currentView={currentView}
            submissionId={submission.id}
            campaignId={submission.brief_id}
            mediaObject={submission.media_objects[0]}
            socialSubmissionType={submission.social_submission_type}
            disableLinkClick={isChangingActiveSlide}
          />
        ))}
      </Slider>
    </SliderWrapper>
  )
}

export { InfluencerIdentityRowSubmissionsList }
export default InfluencerIdentityRowSubmissionsList
