import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { SideBarBlock, SideBarBlockItem } from '@components/UI/SideBar'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { IMobileMenuControlsContextProps } from '@context/MobileMenuControls'
import { IModalContextProps } from '@context/Modal/Provider'
import { EventTrackingNames, PageRoutes } from '@enums'
import { withMobileMenuControls, withModal } from '@hocs'
import { IApplicationState } from '@store'
import {
  selectAudienceReportingLink,
  selectIsCampaignFetched,
  selectIsSocialCampaign,
  selectPostCampaignReportLink,
} from '@store/campaigns'
import {
  selectCampaignId,
  selectCurrentSubmissionFilter,
  selectPathname,
  selectSearchStatus,
} from '@store/router'
import { fetchSubmissions, selectIsFetching, selectSubmissionsStats } from '@store/submissions'
import { isEmpty, objectToQueryString } from '@utils'

type IInternalProps = ConnectedProps<typeof connector> &
  IMobileMenuControlsContextProps &
  IModalContextProps

const ReportsList: React.FC<IInternalProps> = ({
  hasSubmissionStats,
  campaignId,
  pathname,
  searchStatus,
  hasPostCampaignReportLink,
  hideMobileMenu,
  isCampaignFetched,
  isFetchingSubmissions,
  fetchSubmissions: fetchSubmissionsAction,
  filter,
  isSocial,
  hasAudienceReportingLink,
}) => {
  React.useEffect(() => {
    if (!hasSubmissionStats && isCampaignFetched && !isFetchingSubmissions && isSocial) {
      fetchSubmissionsAction(campaignId, { filter })
    }
  }, [isCampaignFetched])

  if (!isSocial || !hasPostCampaignReportLink) {
    return null
  }

  const queryString = objectToQueryString({
    status: searchStatus,
  })

  return (
    <React.Fragment>
      <SideBarBlock
        header={<FormattedMessage id="inbox.sidebar.reports" />}
        items={[
          hasPostCampaignReportLink && (
            <ConnectedTrackedRoute
              key="reports-sidebar-pcr"
              eventName={EventTrackingNames.ViewCampaignMetrics}
              campaignId={campaignId}
              additionalProperties={{
                current_filter: undefined,
              }}
            >
              <SideBarBlockItem
                text={<FormattedMessage id="inbox.sidebar.reports.metrics" />}
                active={pathname.includes(`${PageRoutes.Reports}/pcr`)}
                to={{
                  pathname: `/${PageRoutes.Campaigns}/${campaignId}/${PageRoutes.Reports}/pcr`,
                  search: queryString,
                }}
                onClick={hideMobileMenu}
                elementName="reports-sidebar-metrics"
              />
            </ConnectedTrackedRoute>
          ),

          hasAudienceReportingLink && (
            <ConnectedTrackedRoute
              eventName={EventTrackingNames.ViewAudienceReporting}
              campaignId={campaignId}
              key="reports-audience-reporting-tracking"
            >
              <SideBarBlockItem
                key="reports-audience-reporting"
                text={<FormattedMessage id="core.text.audience" />}
                active={pathname.includes(`${PageRoutes.Reports}/audience`)}
                to={{
                  pathname: `/${PageRoutes.Campaigns}/${campaignId}/${PageRoutes.Reports}/audience`,
                  search: queryString,
                }}
              />
            </ConnectedTrackedRoute>
          ),
        ]}
      />
    </React.Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { match, location }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match)!
  const isSocial = selectIsSocialCampaign(state, campaignId)
  const audienceReportingLink = selectAudienceReportingLink(state, campaignId)

  return {
    campaignId,
    isSocial,
    filter: selectCurrentSubmissionFilter(state),
    isCampaignFetched: selectIsCampaignFetched(state, campaignId),
    isFetchingSubmissions: selectIsFetching(state),
    pathname: selectPathname(state),
    searchStatus: selectSearchStatus(location),
    hasSubmissionStats: !isEmpty(selectSubmissionsStats(state, campaignId)),
    hasPostCampaignReportLink: Boolean(selectPostCampaignReportLink(state, campaignId)),
    hasAudienceReportingLink: !isEmpty(audienceReportingLink),
  }
}

const mapDispatchToProps = {
  fetchSubmissions,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export { ReportsList }

export default compose<IInternalProps, {}>(
  withMobileMenuControls,
  withRouter,
  withModal,
  connector,
)(ReportsList)
