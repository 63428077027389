import { FormattedMessage } from 'react-intl'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { EventTrackingNames, MemberRoleTypes } from '@enums'
import { IHateoasLink } from '@store'
import { selectSubmissionTrackingProps, trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import { selectBrandManagerIdentities } from '@store/brandManagerIdentities'
import { ICampaign, selectCampaign } from '@store/campaigns'
import { selectCurrentFilter, selectCurrentSortBy } from '@store/router'
import {
  fetchSubmissions,
  IPromoteAsAdParams,
  ISubmission,
  promoteAsAd,
  PromoteAsAdActionTypes,
  promoteAsAdError,
  promoteAsAdSuccess,
  selectIsSubmissionFromTiktok,
  selectPromoteAsAdLink,
} from '@store/submissions'
import { setUIFlag, UIFlag } from '@store/ui'
import { Toaster } from '@tribegroup'
import { isEmpty, networkRequest } from '@utils'

export function* handlePromoteAsAd(action: ReturnType<typeof promoteAsAd>) {
  try {
    const { promoteAsAdParams, submissionId, campaignId } = action.payload
    const authToken: string = yield select(selectAuthToken)
    const promoteAsAdLink: IHateoasLink = yield select(selectPromoteAsAdLink, submissionId)
    const submission: ISubmission = yield call(
      networkRequest,
      promoteAsAdLink.method,
      promoteAsAdLink.href,
      promoteAsAdParams,
      authToken,
    )

    const isTikTok: IHateoasLink = yield select(selectIsSubmissionFromTiktok, submissionId)

    const sortBy = yield select(selectCurrentSortBy)
    const filter = yield select(selectCurrentFilter)

    const trackingProps = yield select(selectSubmissionTrackingProps, campaignId, submissionId)
    const { duration_in_days, amount }: IPromoteAsAdParams = promoteAsAdParams
    yield put(
      trackEvent(EventTrackingNames.BCARightsRequest, {
        ...trackingProps,
        ad_spend: amount,
        duration: duration_in_days,
      }),
    )

    yield call(Toaster.createToast, FormattedMessage, { id: 'core.text.toast.requestSent' })
    yield put(fetchSubmissions(campaignId, { filter, sortBy }))

    if (!isTikTok) {
      const campaign: ICampaign = yield select(selectCampaign, campaignId)
      const brandManagerIdentities = yield select(selectBrandManagerIdentities)

      if (isEmpty(brandManagerIdentities) && campaign.role === MemberRoleTypes.Owner) {
        yield put(setUIFlag(UIFlag.SHOW_NO_BRAND_MANAGER_IDENTITY_ALERT))
      } else if (campaign.instagram_identity_reconnect_required) {
        yield put(setUIFlag(UIFlag.SHOW_RECONNECT_ACCOUNT_ALERT))
      } else if (!campaign.instagram_identity_id) {
        yield put(setUIFlag(UIFlag.SHOW_CONNECT_ACCOUNT_ALERT))
      }
    }

    yield put(promoteAsAdSuccess(submission))
  } catch (error) {
    yield put(promoteAsAdError(error))
  }
}

export function* watchPromoteAsAdRequest() {
  yield takeEvery(PromoteAsAdActionTypes.PROMOTE_AS_AD_REQUEST, handlePromoteAsAd)
}
