import { ModalProvider } from '@context/Modal'
import { Container } from '@tribegroup'
import { ConnectedAccountsDrawerCollaboratorList } from './List'
import { ConnectedAccountsDrawerCollaboratorTitle } from './Title'

const ConnectedAccountsDrawerCollaborator: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ModalProvider>
      <Container>
        <ConnectedAccountsDrawerCollaboratorTitle />
        <ConnectedAccountsDrawerCollaboratorList />
        {children}
      </Container>
    </ModalProvider>
  )
}

export { ConnectedAccountsDrawerCollaborator }
