import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import {
  AudienceReportingActionTypes,
  fetchAudienceReportingError,
  fetchAudienceReportingSuccess,
} from '@store/audienceReporting'
import { selectAuthToken } from '@store/auth'
import { selectAudienceReportingLink } from '@store/campaigns'
import { networkRequest } from '@utils'

export function* handleFetchAudienceReporting(action) {
  const { campaignId } = action.payload

  try {
    const link: IHateoasLink = yield select(selectAudienceReportingLink, campaignId)
    const authToken: string = yield select(selectAuthToken)

    const response = yield call(networkRequest, link.method, link.href, undefined, authToken)
    yield put(fetchAudienceReportingSuccess(campaignId, response))
  } catch (error) {
    yield put(fetchAudienceReportingError(error))
  }
}

function* watchFetchAudienceInsights() {
  yield takeLatest(AudienceReportingActionTypes.FETCH_REQUEST, handleFetchAudienceReporting)
}

export const audienceReportingSagas: ReadonlyArray<any> = [watchFetchAudienceInsights]
