import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectCampaignBrandFanGroupIds } from '@store/campaigns'
import Theme from '@theme'
import { Container, Header, Text } from '@tribegroup'
import CampaignBrandFansPopoverInvitedGroup from './InvitedGroup'
import { BrandFansPill, InnerContent, ListContainer, Root } from './Popover.styled'

interface IProps {
  campaignId: number
}

type IInternalProps = ConnectedProps<typeof connector>

const CampaignBrandFansPopover: React.FC<IInternalProps> = ({ groupIds }) => {
  return (
    <Root data-testid="campaign-brand-fans-popover">
      <InnerContent>
        <Header cta color={Theme.primaryColor} uppercase bottomOuterSpacing={0.25}>
          <FormattedMessage id="campaign.campaignCard.inviteOnly" />
        </Header>
        <BrandFansPill>
          <Text color={Theme.primaryColor} xxsmall uppercase>
            <FormattedMessage id="core.text.brandFans" />
          </Text>
        </BrandFansPill>
        <Container topOuterSpacing={1.75}>
          <Header small color={Theme.grey700Color} uppercase bottomOuterSpacing={1}>
            <FormattedMessage id="core.text.invitedGroups" />
          </Header>
          <ListContainer
            overflow="scroll"
            maxHeight={10.75}
            rightInnerSpacing={1}
            rightOuterSpacing={-1}
          >
            {groupIds.map((groupId) => (
              <CampaignBrandFansPopoverInvitedGroup groupId={groupId} key={groupId} />
            ))}
          </ListContainer>
        </Container>
      </InnerContent>
    </Root>
  )
}

const mapStateToProps = (state: IApplicationState, { campaignId }: IProps) => ({
  groupIds: selectCampaignBrandFanGroupIds(state, campaignId),
})
const connector = connect(mapStateToProps)
export default compose<IInternalProps, IProps>(connector)(CampaignBrandFansPopover)
