import { call, put, select, takeLatest } from 'redux-saga/effects'

import { ToastTemplateTicked } from '@components/UI/ToastTemplate'
import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  disconnectCampaignSocialAccount,
  DisconnectCampaignSocialAccountActionType,
  disconnectCampaignSocialAccountError,
  disconnectCampaignSocialAccountSuccess,
  fetchCampaignSuccess,
  ICampaign,
  selectDisconnectCampaignSocialAccountLink,
} from '@store/campaigns'
import { Toaster } from '@tribegroup'
import networkRequest from '@utils/networkRequest'

export function* handleDisconnectSocialAccount(
  action: ReturnType<typeof disconnectCampaignSocialAccount>,
) {
  const { campaignId, socialAccountId, handle, channel } = action.payload

  try {
    const authToken: string = yield select(selectAuthToken)
    const link: IHateoasLink = yield select(selectDisconnectCampaignSocialAccountLink, campaignId)

    const campaign: ICampaign = yield call(
      networkRequest,
      link.method,
      link.href.replace('{social_account_id}', `${socialAccountId}`),
      undefined,
      authToken,
    )

    yield put(disconnectCampaignSocialAccountSuccess())
    yield put(fetchCampaignSuccess(campaign))

    yield call(Toaster.createToast, ToastTemplateTicked, {
      i18nKey: `connectedAccounts.toast.${channel}.disconnectSuccess`,
      values: {
        handle: handle ?? '',
      },
    })
  } catch (error) {
    yield put(disconnectCampaignSocialAccountError(error))
  }
}

export function* watchDisconnectSocialAccountRequest() {
  yield takeLatest(DisconnectCampaignSocialAccountActionType.REQUEST, handleDisconnectSocialAccount)
}
