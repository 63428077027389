import { IBrandManagerIdentity, RemoveBrandManagerIdentity } from '@store/brandManagerIdentities'

export const removeIdentity = (brandManagerIdentityId: number) => ({
  type: RemoveBrandManagerIdentity.REMOVE_REQUEST,
  payload: brandManagerIdentityId,
})

export const removeIdentitySuccess = (brandManagerIdentity: IBrandManagerIdentity) => ({
  payload: brandManagerIdentity,
  type: RemoveBrandManagerIdentity.REMOVE_SUCCESS,
})

export const removeIdentityError = (error: any) => ({
  payload: error,
  type: RemoveBrandManagerIdentity.REMOVE_ERROR,
})
