import React from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import Loading from '@components/UI/Loading'
import { BrandFanFilters } from '@enums'
import { IApplicationState } from '@store'
import {
  fetchInfluencerIdentities,
  selectBrandFansNextLink,
  selectFilteredIdentityIds,
  selectIsFetchingInitialIdentityList,
  selectIsListEmpty,
} from '@store/brandFans'
import { Container } from '@tribegroup'
import { isEmpty } from '@utils'
import BrandFanInfluencerIdentityRow from '../Row'
import InfluencerIdentityListEmpty from './Empty'
import InfluencerIdentityListEmptyFiltered from './EmptyFiltered'

interface IProps {
  filter: BrandFanFilters
}
interface IInternalProps extends IProps {
  isEmptyList: boolean
  hasMore: boolean
  sortBy: string
  isLoadingInitialList: boolean
  influencerIdentityIds: ReadonlyArray<number>
  fetchInfluencerIdentities: typeof fetchInfluencerIdentities
}

const InfluencerIdentityList: React.FC<IInternalProps> = ({
  influencerIdentityIds,
  filter,
  isEmptyList,
  hasMore,
  isLoadingInitialList,
  fetchInfluencerIdentities: fetchInfluencerIdentitiesAction,
}) => {
  const filteredListIsEmpty = isEmpty(influencerIdentityIds)
  React.useEffect(() => {
    if (filteredListIsEmpty) {
      fetchInfluencerIdentitiesAction()
    }
  }, [filter])

  if (isLoadingInitialList) {
    return <Loading height={15} />
  }

  if (isEmptyList) {
    return <InfluencerIdentityListEmpty type={filter} />
  }

  if (filteredListIsEmpty) {
    return <InfluencerIdentityListEmptyFiltered />
  }

  const fetchNext = () => {
    const isFetchNext = true
    fetchInfluencerIdentitiesAction(isFetchNext)
  }

  return (
    <Container topOuterSpacing={1} bottomInnerSpacing={4.5}>
      <InfiniteScroll
        dataLength={influencerIdentityIds.length}
        next={fetchNext}
        loader={<Loading height={30} />}
        hasMore={hasMore}
        scrollThreshold={0.8}
        style={{ overflow: 'hidden' }}
      >
        <div data-testid="brand-fans-influencer-identity-list">
          {influencerIdentityIds.map((influencerIdentityId: number) => (
            <BrandFanInfluencerIdentityRow
              key={influencerIdentityId}
              identityId={influencerIdentityId}
            />
          ))}
        </div>
      </InfiniteScroll>
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { filter }: IProps) => ({
  isLoadingInitialList: selectIsFetchingInitialIdentityList(state, filter),
  isEmptyList: selectIsListEmpty(state, filter),
  influencerIdentityIds: selectFilteredIdentityIds(state, filter),
  hasMore: Boolean(selectBrandFansNextLink(state, filter)),
})

const mapDispatchToProps = {
  fetchInfluencerIdentities,
}

export { InfluencerIdentityList }
export default compose<IInternalProps, IProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InfluencerIdentityList)
