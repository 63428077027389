import { createAction } from '@reduxjs/toolkit'

import { FetchCreatorBioByLinkActionTypes, ICreatorBio, ICreatorBioLink } from '../types'

export const fetchCreatorBioByLink = createAction(
  FetchCreatorBioByLinkActionTypes.FETCH_REQUEST,
  function prepare(link: ICreatorBioLink) {
    return {
      payload: { ...link, href: decodeURIComponent(link.href) },
    }
  },
)

export const fetchCreatorBioByLinkSuccess = createAction<{
  link: ICreatorBioLink
  creatorBio: ICreatorBio
}>(FetchCreatorBioByLinkActionTypes.FETCH_SUCCESS)

export const fetchCreatorBioByLinkError = createAction<{ error: any }>(
  FetchCreatorBioByLinkActionTypes.FETCH_ERROR,
)
