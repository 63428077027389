import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { IModalComponentProps } from '@context/Modal'
import Theme from '@theme'
import { Button, Container, H3, Modal, Text } from '@tribegroup'

const ConnectedAccountsModalTikTokRemoveAccount = ({
  onRequestClose,
  isOpen = false,
}: IModalComponentProps) => {
  return (
    <Modal reactModalProps={{ isOpen }} width={20}>
      <Container data-testid="upload-in-progress-dialog">
        <H3 color={Theme.defaultColor}>
          <FormattedHTMLMessage id="connectedAccounts.confirmation.tiktok.removeIdentity.title" />
        </H3>
        <Container topOuterSpacing={0.5}>
          <Text color={Theme.grey800Color}>
            <FormattedHTMLMessage id="connectedAccounts.confirmation.tiktok.removeIdentity.paragraph" />
          </Text>
        </Container>
        <Container topOuterSpacing={3.125}>
          <Flex justifyEnd>
            <Button small scheme="primary" onClick={onRequestClose}>
              <FormattedMessage id="core.text.okay" />
            </Button>
          </Flex>
        </Container>
      </Container>
    </Modal>
  )
}

export default ConnectedAccountsModalTikTokRemoveAccount
