import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { H5 } from '@tribegroup'

export const FormTitle = () => (
  <H5 color={Theme.grey900Color}>
    <FormattedMessage id="account.payment.title" />
  </H5>
)

export default FormTitle
