import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import { useInfluencerIdentityId } from '@context/InfluencerIdentityId/Provider'
import { IApplicationState } from '@store'
import {
  selectInfluencerIdentityHasPosts,
  selectInfluencerIdentityPlatformProvider,
} from '@store/influencerIdentities'
import { selectCampaignId } from '@store/router'
import { Container } from '@tribegroup'
import InfluencerIdentityRowPerformancePostCount from './PostCount/PostCount'
import InfluencerIdentityRowPerformanceShortlisted from './Shortlisted/Shortlisted'
import InfluencerIdentityRowPerformanceMetrics from './Metrics'

export const InfluencerIdentityRowPerformance = () => {
  const match = useRouteMatch()

  const { contextInfluencerIdentityId } = useInfluencerIdentityId()
  const { hasPosts, identityProvider } = useSelector((state: IApplicationState) => {
    const campaignId = selectCampaignId(match)!
    return {
      hasPosts: selectInfluencerIdentityHasPosts(state, campaignId, contextInfluencerIdentityId),
      identityProvider: selectInfluencerIdentityPlatformProvider(
        state,
        campaignId,
        contextInfluencerIdentityId,
      ),
    }
  })

  return (
    <>
      {hasPosts && <InfluencerIdentityRowPerformancePostCount />}
      <InfluencerIdentityRowPerformanceShortlisted />
      <Container leftOuterSpacing={1.25}>
        <InfluencerIdentityRowPerformanceMetrics identityProvider={identityProvider} />
      </Container>
    </>
  )
}

export default InfluencerIdentityRowPerformance
