import { PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'

import Theme from '@theme'
import { Container } from '@tribegroup'

interface IPopupMenuContentProps {
  isVisible: boolean
  transitionDuration: number
}

export const PopupMenuContent: React.FC<PropsWithChildren<IPopupMenuContentProps>> = styled.div<
  IPopupMenuContentProps
>`
  position: absolute;
  bottom: 0;
  transform: translate(-100%, 1rem);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 2rem;
  opacity: 0;
  ${({ isVisible, transitionDuration }) => css`
    transition: transform ${transitionDuration}ms, opacity ${transitionDuration}ms;
    ${isVisible &&
    `
      opacity: 1;
      transform: translate(-100%);
    `}
  `}
`

export const PopupMenuItem = styled(Container)`
  cursor: pointer;
  transition: 200ms;
  color: ${Theme.defaultColor};

  &:hover {
    transform: scale(1.1);
    color: ${Theme.primaryColor};
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`
