import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Flex from 'styled-flex-component'

export interface IBlockProps {
  active: boolean
}

export const IconWrapper = styled(Flex)`
  background: ${({ background }) => background};
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: -1.375rem;
`

const Block = styled.span`
  font-size: 0.875rem;
  letter-spacing: 0.3px;
  align-items: center;
  position: relative;
  white-space: nowrap;
  margin-left: 4.5vw;
  flex-shrink: 0;
`

export const StyledSpan = styled.span`
  display: flex;
`

export const StyledLink = styled(Link)`
  display: flex;
  text-decoration: none;
`

export default Block
