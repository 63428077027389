import React from 'react'

import Flex from 'styled-flex-component'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IModalComponentProps } from '@context/Modal'
import { EventTrackingNames } from '@enums'
import { CloseIcon, Container, Modal } from '@tribegroup'
import InboxReferralCopyButton from './CopyButton'
import InboxReferralGraphics from './Graphics'
import InboxReferralLearnMore from './LearnMore'
import InboxReferralSubtitle from './Subtitle'
import InboxReferralTimelineSteps from './Timeline'
import InboxReferralTimelineGraphics from './TimelineGraphics'
import InboxReferraITitle from './Title'

interface IProps extends IModalComponentProps {
  campaignId: number
}

const InboxReferral: React.FC<IProps> = ({ onRequestClose, campaignId, isOpen = false }) => {
  return (
    <Modal reactModalProps={{ isOpen }} width={34.625}>
      <ConnectedTrackedRoute
        eventName={EventTrackingNames.BrandReferralDismiss}
        campaignId={campaignId}
      >
        <CloseIcon onClick={onRequestClose} />
      </ConnectedTrackedRoute>
      <Container
        textAlign="center"
        leftInnerSpacing={0.25}
        rightInnerSpacing={0.25}
        data-testid="referral-program-modal"
      >
        <InboxReferralGraphics />
        <Container topOuterSpacing={1.75}>
          <InboxReferraITitle />
        </Container>
        <Container topOuterSpacing={1.75} leftInnerSpacing={3} rightInnerSpacing={3}>
          <InboxReferralSubtitle />
        </Container>
        <Container topOuterSpacing={1.75} textAlign="center">
          <Flex justifyCenter>
            <InboxReferralCopyButton campaignId={campaignId} onRequestClose={onRequestClose} />
          </Flex>
        </Container>
        <Container topOuterSpacing={1} textAlign="center">
          <InboxReferralLearnMore campaignId={campaignId} />
        </Container>
        <Container topOuterSpacing={2.5}>
          <InboxReferralTimelineGraphics />
        </Container>
        <Container topOuterSpacing={0.75} leftInnerSpacing={0.75} rightInnerSpacing={0.75}>
          <InboxReferralTimelineSteps />
        </Container>
      </Container>
    </Modal>
  )
}

export default InboxReferral
