import { MarkAsReadActionTypes } from '@store/notes'

export const markAsRead = (submissionId: number) => ({
  payload: submissionId,
  type: MarkAsReadActionTypes.MARK_AS_READ_REQUEST,
})

export const markAsReadError = error => ({
  payload: error,
  type: MarkAsReadActionTypes.MARK_AS_READ_ERROR,
})
