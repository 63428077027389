import { useContext } from 'react'

import { useSelector } from 'react-redux'

import AnalyticsPostCampaignReportListViewProfile from '@components/Analytics/PostCampaignReport/ListView/Profile/Profile'
import { getBackgroundColor } from '@components/Analytics/PostCampaignReport/Post/List/List'
import { CampaignContext } from '@context/Campaign'
import { IApplicationState } from '@store'
import { selectReelMetricIds } from '@store/postCampaignReport'
import { Container } from '@tribegroup'
import AnalyticsPostCampaignReportListView from '../../ListView/ListView'
import { AnalyticsPostCampaignReportListViewReelHeaders } from './Headers'
import { AnalyticsPostCampaignReportReelListItem } from './Item'

export const AnalyticsPostCampaignReportReelList: React.FC = () => {
  const {
    campaign: { id: campaignId },
  } = useContext(CampaignContext)

  const { submissionIds } = useSelector((state: IApplicationState) => {
    return {
      submissionIds: selectReelMetricIds(state, campaignId),
    }
  })

  const profiles = submissionIds.map((submissionId, index) => (
    <Container data-testid="reel-profile-wrapper" key={`profile-${submissionId}`}>
      <AnalyticsPostCampaignReportListViewProfile
        backgroundColor={getBackgroundColor(index)}
        submissionId={submissionId}
      />
    </Container>
  ))

  return (
    <AnalyticsPostCampaignReportListView
      profiles={profiles}
      headers={<AnalyticsPostCampaignReportListViewReelHeaders />}
    >
      {submissionIds.map((submissionId, index) => (
        <Container key={`reel-list-${submissionId}`} data-testid="reel-list-item">
          <AnalyticsPostCampaignReportReelListItem
            submissionId={submissionId}
            backgroundColor={getBackgroundColor(index)}
          />
        </Container>
      ))}
    </AnalyticsPostCampaignReportListView>
  )
}
