import { WatcherFunction } from '..'
import { watchAddBrandRequest } from './add'
import { watchFetchBrandsRequest } from './list'
import { watchUpdateBrandRequest } from './update'

export const brandsSagas: ReadonlyArray<WatcherFunction> = [
  watchFetchBrandsRequest,
  watchAddBrandRequest,
  watchUpdateBrandRequest,
]
