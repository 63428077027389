import { useMemo } from 'react'

import { ALL } from '@constants'
import { CampaignStatuses } from '@enums'
import { useLocationSearch } from '@hooks/useLocationSearch'

type CampaignFilters = CampaignStatuses | typeof ALL

const useCurrentCampaignStatusFilter = () => {
  const searchParams = useLocationSearch<{ status: CampaignStatuses }>()
  const currentCampaignStatus = useMemo(() => {
    const parsedStatus =
      searchParams.status && (decodeURIComponent(searchParams.status) as CampaignStatuses)
    return Object.values(CampaignStatuses).includes(parsedStatus) ? parsedStatus : 'all'
  }, [searchParams])

  return currentCampaignStatus as CampaignFilters
}

export { useCurrentCampaignStatusFilter }
