import { createReducer } from '@reduxjs/toolkit'

import { AuthActionTypes } from '@store/auth'
import {
  fetchPublishedSummary,
  fetchPublishedSummaryError,
  fetchPublishedSummarySuccess,
  IPublishedSummaryState,
} from '@store/publishedSummary'

const initialState: IPublishedSummaryState = {
  isFetching: false,
  errors: undefined,
  publishedSummaryByCampaignId: {},
}

export const publishedSummaryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPublishedSummary, (state) => {
      state.isFetching = true
    })
    .addCase(fetchPublishedSummarySuccess, (state, action) => {
      const {
        payload: { campaignId, publishedSummary },
      } = action

      state.isFetching = false
      state.publishedSummaryByCampaignId[campaignId] = publishedSummary
      state.errors = undefined
    })
    .addCase(fetchPublishedSummaryError, (state, { payload }) => {
      state.isFetching = false
      state.errors = payload
    })
    .addCase(AuthActionTypes.SIGNOUT_SUCCESS, (state) => {
      state.isFetching = false
      state.errors = undefined
      state.publishedSummaryByCampaignId = {}
    })
})
