import React from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container, Text } from '@tribegroup'

const InboxReferralTimelineSteps: React.FC = () => (
  <Flex justifyEvenly>
    <Container textAlign="left" width={9.375} rightOuterSpacing={1} rightInnerSpacing={2}>
      <Container width={7}>
        <Text color={Theme.grey800Color} data-testid="referral-first-step-text">
          <FormattedHTMLMessage id="inbox.referral.modal.steps.first" />
        </Text>
      </Container>
    </Container>
    <Container textAlign="left" width={9.375} rightOuterSpacing={2}>
      <Container width={8}>
        <Text color={Theme.grey800Color} textAlign="left" data-testid="referral-second-step-text">
          <FormattedMessage id="inbox.referral.modal.steps.second" />
        </Text>
      </Container>
    </Container>
    <Container textAlign="left" width={9.375}>
      <Container width={8}>
        <Text color={Theme.grey800Color} textAlign="left" data-testid="referral-third-step-text">
          <FormattedHTMLMessage id="inbox.referral.modal.steps.third" />
        </Text>
      </Container>
    </Container>
  </Flex>
)

export default InboxReferralTimelineSteps
