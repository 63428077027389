/* eslint-disable no-prototype-builtins */
/* eslint-disable no-useless-escape */
export interface IErrorRule<Type> {
  message: string
  value: Type
}

export interface IValidationRule {
  required?: boolean | IErrorRule<boolean>
  min?: number | IErrorRule<number>
  max?: number | IErrorRule<number>
  minLength?: number | IErrorRule<number>
  maxLength?: number | IErrorRule<number>
  type?: 'email' | 'website' | IErrorRule<'email' | 'website'>
  custom?: ReadonlyArray<IErrorRule<string>>
  expression?: IErrorRule<boolean>
  email?: boolean | IErrorRule<boolean>
}

export interface IHasValidationRule {
  validationRule?: IValidationRule
}

export interface IValidationResult {
  hasError: boolean
  errorMessage?: string
  value?: string
}

export const validateRule = (
  rawValue: string,
  rule: IValidationRule,
  prefix?: string,
): IValidationResult => {
  const value = rawValue && rawValue.trim()
  if (rule) {
    const { required, minLength, maxLength, type, custom, min, max, expression, email } = rule
    if (required && (value === '' || prefix === value)) {
      return createErrorMessage('This is a required field', required)
    }
    if (
      minLength &&
      (value.length < (minLength as IErrorRule<number>).value ||
        value.length < (minLength as number))
    ) {
      return createErrorMessage(
        `Character count should not be less than ${minLength} characters`,
        minLength,
      )
    }
    if (
      maxLength &&
      (value.length > (maxLength as IErrorRule<number>).value ||
        value.length > (maxLength as number))
    ) {
      return createErrorMessage(
        `Character count should not be more than ${maxLength} characters`,
        maxLength,
      )
    }

    if (type) {
      const key = type.hasOwnProperty('value') ? type['value'] : type
      if (!typeValidationTests[key].regex.test(value)) {
        return createErrorMessage(typeValidationTests[key].message, type)
      }
    }

    if (min) {
      const numericValue = parseFloat(value)
      const minValue = (min.hasOwnProperty('value')
        ? (min as IErrorRule<number>).value
        : min) as number
      if (minValue && numericValue < minValue) {
        return createErrorMessage(`Value cannot be less than ${min}`, min)
      }
    }

    if (max) {
      const numericValue = parseFloat(value)
      const maxValue = (max.hasOwnProperty('value')
        ? (max as IErrorRule<number>).value
        : max) as number
      if (maxValue && numericValue > maxValue) {
        return createErrorMessage(`Value cannot be more than ${max}`, max)
      }
    }

    if (custom) {
      const failedValidations = custom.find((customRule: IErrorRule<string>) => {
        const regex = new RegExp(customRule.value)
        return !regex.test(value)
      })

      if (failedValidations) {
        return createErrorMessage(failedValidations.message, failedValidations)
      }
    }

    if (email) {
      if (!typeValidationTests.email.regex.test(value)) {
        return createErrorMessage('Please enter a valid email address', email)
      }
    }

    if (expression && expression.value) {
      return createErrorMessage(expression.message, expression)
    }
  }
  return {
    hasError: false,
  }
}

const createErrorMessage = (message: string, rule: any) => ({
  // eslint-disable-next-line no-prototype-builtins
  errorMessage: rule.hasOwnProperty('message') ? rule.message : message,
  hasError: true,
})

const typeValidationTests = {
  email: {
    message: 'Please enter a valid email address',
    regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
  website: {
    message: 'Please enter a valid url',
    regex: /^(ftp|http|https):\/\/[^ "]+$/,
  },
}
