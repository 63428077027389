import { WatcherFunction } from '..'
import { watchAddFeedbackRequest } from './add'
import { watchBulkAddFeedbackRequest } from './bulkAdd'
import { watchListFeedbackRequest } from './list'
import { watchMarkAsReadRequest } from './markAsRead'

export const feedbackSagas: ReadonlyArray<WatcherFunction> = [
  watchListFeedbackRequest,
  watchAddFeedbackRequest,
  watchMarkAsReadRequest,
  watchBulkAddFeedbackRequest,
]
