import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import Loading from '@components/UI/Loading'
import Overlay from '@components/UI/Overlay/Overlay.styled'
import { fetchCampaign, selectCampaignsIsFetching } from '@store/campaigns'
import Theme from '@theme'
import { Button, Container, setAlpha } from '@tribegroup'

interface IProps {
  campaignId: number
}

const MoodboardOverlay = ({ campaignId }: IProps) => {
  const dispatch = useDispatch()

  const isFetching = useSelector(selectCampaignsIsFetching)

  const onClick = () => {
    dispatch(fetchCampaign(campaignId))
  }

  return (
    <Overlay
      visible
      transitionDuration={200}
      position="absolute"
      background={setAlpha(Theme.whiteColor, 0.85)}
      borderRadius={0.5}
      zIndex={1}
    >
      <Container fullHeight flexCenter pointer data-testid="moodboard-overlay">
        {isFetching ? (
          <Loading />
        ) : (
          <Button
            scheme="primary"
            outlined
            small
            leftOuterSpacing={0.75}
            leftInnerSpacing={0}
            rightInnerSpacing={0}
            width={10.25}
            type="button"
            onClick={onClick}
          >
            <FormattedMessage id="builder.brief.moodboard.overlay.button" />
          </Button>
        )}
      </Container>
    </Overlay>
  )
}

export default MoodboardOverlay
