import { Fragment } from 'react'

import { Link } from '@tribegroup'

interface ISocialLinkProps {
  socialUrl?: string
  children: React.ReactNode
  onClick?: (event: React.SyntheticEvent<HTMLAnchorElement>) => void
}

export const AvatarSocialLink: React.FC<ISocialLinkProps> = ({ children, socialUrl, onClick }) => {
  if (socialUrl) {
    return (
      <Link href={socialUrl} onClick={onClick} target="_blank">
        {children}
      </Link>
    )
  }
  return <Fragment>{children}</Fragment>
}

export default AvatarSocialLink
