import { PropsWithChildren } from 'react'

import { FormattedHTMLMessage } from 'react-intl'

import Theme from '@theme'
import { Icon, Text } from '@tribegroup'
import { Caption, EmptyFile } from '@tribegroup/FileDrop/FileDrop.styled'

type FileErrorCodes = 'file-too-large' | 'invalid-video-duration' | 'invalid-media-count' | string

interface IProps {
  isDragActive?: boolean
  errorCode?: FileErrorCodes
}

const errorStringMap: Record<FileErrorCodes, string> = {
  'file-too-large': 'builder.brief.moodboard.error.file-too-large',
  'invalid-media-count': 'builder.brief.moodboard.error.invalid-media-count',
  'invalid-video-duration': 'builder.brief.moodboard.error.invalid-video-duration',
  'upload-failed': 'builder.brief.moodboard.error.upload-failed',
}

const MoodboardItemEmpty: React.FC<PropsWithChildren<IProps>> = ({
  isDragActive,
  errorCode,
  children,
  ...props
}) => {
  return (
    <EmptyFile
      data-testid="empty-moodboard-image"
      data-cy-element="empty-moodboard-image"
      borderWidth={1}
      {...props}
      isDragActive={isDragActive}
    >
      <Icon color={Theme.defaultColor} glyph="plus" size={1.5} />
      {errorCode && (
        <Caption>
          <Text block small color={Theme.errorColor}>
            <FormattedHTMLMessage id={errorStringMap[errorCode]} />
          </Text>
        </Caption>
      )}
      {children}
    </EmptyFile>
  )
}

export default MoodboardItemEmpty
