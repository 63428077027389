import { IResponseError } from '@lib/error'
import { ICampaignPreferences, UpdatePreferencesActionTypes } from '@store/campaigns'

export const updatePreferences = (
  campaignId: number,
  preferences: Partial<ICampaignPreferences>,
) => ({
  payload: { campaignId, preferences },
  type: UpdatePreferencesActionTypes.UPDATE_REQUEST,
})

export const updatePreferencesError = (error: IResponseError) => ({
  payload: error,
  type: UpdatePreferencesActionTypes.UPDATE_ERROR,
})
