enum SocialSubmissionTypes {
  Carousel = 'carousel',
  Pin = 'idea_pin',
  LongFormVideo = 'long_form_video',
  Reel = 'reel',
  Shorts = 'shorts',
  Standard = 'standard',
  Story = 'story',
  Text = 'text',
  TikTok = 'tiktok',
}

export { SocialSubmissionTypes }
