import { useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import { IApplicationState } from '@store'
import {
  selectBrandMatchPercentage,
  selectCreatorBioLink,
  selectIsAudienceMatch,
  selectIsSubmissionFromPinterest,
} from '@store/submissions'
import { Container } from '@tribegroup'
import SubmissionCardHeaderAudienceMatch from '../AudienceMatch/AudienceMatch'
import SubmissionCardHeaderBrandMatch from '../BrandMatch'
import { SubmissionCardHeaderTalentAgencyCreatorTag } from '../TalentAgencyCreator/Tag'
import SubmissionCardHeaderViewBio from '../ViewBio'

interface ISubmissionCardHeaderActionsProps {
  campaignId: number
  submissionId: number
}

const SubmissionCardHeaderActions: React.FC<ISubmissionCardHeaderActionsProps> = ({
  submissionId,
  campaignId,
}) => {
  selectIsSubmissionFromPinterest

  const { isPinterest } = useSelector((state: IApplicationState) => ({
    isPinterest: selectIsSubmissionFromPinterest(state, submissionId),
  }))

  const showAudienceMatch = !isPinterest
  const showBrandMatch = !isPinterest
  const { isAudienceMatch, creatorBio, brandMatchPct } = useSelector((state: IApplicationState) => {
    return {
      isAudienceMatch: selectIsAudienceMatch(state, submissionId),
      creatorBio: selectCreatorBioLink(state, submissionId),
      brandMatchPct: selectBrandMatchPercentage(state, submissionId),
    }
  })

  return (
    <Flex alignCenter>
      {showAudienceMatch && isAudienceMatch && (
        <Container rightOuterSpacing={0.5} bottomOuterSpacing={0.125}>
          <SubmissionCardHeaderAudienceMatch submissionId={submissionId} campaignId={campaignId} />
        </Container>
      )}
      {showBrandMatch && typeof brandMatchPct === 'number' && (
        <Container rightOuterSpacing={0.5} topOuterSpacing={0.125}>
          <SubmissionCardHeaderBrandMatch
            brandMatchPct={brandMatchPct}
            submissionId={submissionId}
            campaignId={campaignId}
          />
        </Container>
      )}
      {creatorBio && (
        <Container rightOuterSpacing={0.5} topOuterSpacing={0.125}>
          <SubmissionCardHeaderViewBio
            href={creatorBio.href}
            method={creatorBio.method}
            submissionId={submissionId}
            campaignId={campaignId}
          />
        </Container>
      )}
      <SubmissionCardHeaderTalentAgencyCreatorTag submissionId={submissionId} />
    </Flex>
  )
}

export default SubmissionCardHeaderActions
