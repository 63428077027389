import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { PanelWrapper } from '@components/Builder/Layout/Layout.styled'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { BuilderSteps } from '@enums'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import {
  selectHasTargetAudienceSet,
  selectIsAllAttributesDisabled,
  selectIsCurrentStepCompleted,
} from '@store/drafts'
import { Container, Panel } from '@tribegroup'
import BuilderBudgetTargetAudienceAge from './Age'
import BuilderBudgetTargetAudienceGender from './Gender'
import BuilderBudgetTargetAudienceLocation from './Location'
import BuilderBudgetTargetAudienceSelection from './Selection'

export const PREFERRED_TARGET_AUDIENCE_SET = 'preferred_target_audience_set'
export const PREFERRED_TARGET_AUDIENCE_UNSET = 'preferred_target_audience_unset'
export const TARGET_AUDIENCE_AGE_RANGE = 'audience_age_range'
export const TARGET_AUDIENCE_GENDER = 'audience_gender'
export const TARGET_AUDIENCE_LOCATION_CODES = 'audience_location_codes'
export const ALL_TARGET_AUDIENCE_ATTRIBUTES = [
  TARGET_AUDIENCE_AGE_RANGE,
  TARGET_AUDIENCE_GENDER,
  TARGET_AUDIENCE_LOCATION_CODES,
]

interface IInternalProps extends IBuilderValidationContextProps, ConnectedProps<typeof connector> {}

const BuilderBudgetTargetAudience: React.FC<IInternalProps> = ({
  updateForm,
  hasTargetAudienceSet,
  isTargetAudienceDisabled,
  isBriefCurrentStepCompleted,
}) => {
  const defaultToYesSelected = hasTargetAudienceSet || isBriefCurrentStepCompleted
  const defaultOptionSelected = defaultToYesSelected
    ? PREFERRED_TARGET_AUDIENCE_SET
    : PREFERRED_TARGET_AUDIENCE_UNSET

  const [selectedOption, setSelectedOption] = React.useState<string>(defaultOptionSelected)

  const onSelectionChage = (selectedOption: string) => {
    if (selectedOption === PREFERRED_TARGET_AUDIENCE_UNSET) {
      updateForm({
        [TARGET_AUDIENCE_AGE_RANGE]: [],
        [TARGET_AUDIENCE_GENDER]: [],
        [TARGET_AUDIENCE_LOCATION_CODES]: [],
      })
    }
    setSelectedOption(selectedOption)
  }

  const hasPreferredTargetAudienceSet = selectedOption === PREFERRED_TARGET_AUDIENCE_SET

  return (
    <PanelWrapper data-testid="builder-budget-target-audience-with-selection">
      <Panel>
        <BuilderBudgetTargetAudienceSelection
          selectedOption={selectedOption}
          onOptionChange={onSelectionChage}
          disabledSelection={isTargetAudienceDisabled}
        />
        {hasPreferredTargetAudienceSet && (
          <Container topOuterSpacing={3.5}>
            <BuilderBudgetTargetAudienceAge />
            <BuilderBudgetTargetAudienceGender />
            <BuilderBudgetTargetAudienceLocation />
          </Container>
        )}
      </Panel>
    </PanelWrapper>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  hasTargetAudienceSet: selectHasTargetAudienceSet(state),
  isBriefCurrentStepCompleted: selectIsCurrentStepCompleted(state, BuilderSteps.BRIEF),
  isTargetAudienceDisabled: selectIsAllAttributesDisabled(state, ALL_TARGET_AUDIENCE_ATTRIBUTES),
})

const connector = connect(mapStateToProps)
export default compose<IInternalProps, {}>(
  withBuilderValidation,
  connector,
)(BuilderBudgetTargetAudience)
