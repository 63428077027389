import React from 'react'

import { FormattedMessage } from 'react-intl'

import { NoticeEmpty } from '@components/UI/Notice'
import { BrandFanFilters } from '@enums'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

interface IProps {
  type: BrandFanFilters
}

const InfluencerIdentityListEmpty: React.FC<IProps> = ({ type }) => {
  const isBrandFiltersAll = type === BrandFanFilters.All
  return (
    <NoticeEmpty
      topOuterSpacing={0}
      title={
        <React.Fragment>
          <Container maxWidth={isBrandFiltersAll ? 19.75 : 21.375} topOuterSpacing={0.5}>
            <Text italic color={Theme.grey800Color} light customFontSize={1}>
              <FormattedMessage
                id={isBrandFiltersAll ? 'brandFans.list.empty.all' : 'brandFans.list.empty.default'}
              />
            </Text>
          </Container>
        </React.Fragment>
      }
      alt={`empty-${type}`}
    />
  )
}

export { InfluencerIdentityListEmpty }
export default InfluencerIdentityListEmpty
