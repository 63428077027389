import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectIsSocialCampaign } from '@store/campaigns'
import {
  selectIdentitySocialUrl,
  selectIdentityUsername,
  selectInfluencerFullName,
} from '@store/submissions'
import Theme from '@theme'
import { H5, Link } from '@tribegroup'

interface ISubmissionCardHeaderHandleProps {
  campaignId: number
  submissionId: number
  onViewSocial: VoidFunction
}

interface IInternalProps extends ISubmissionCardHeaderHandleProps {
  showSocialProfile: boolean
  username: string
  socialUrl: string
  influencerFullName: string
}

export const SubmissionCardHeaderHandle: React.FC<IInternalProps> = ({
  showSocialProfile,
  username,
  socialUrl,
  influencerFullName,
  onViewSocial,
}) => {
  if (showSocialProfile) {
    return (
      <Link href={socialUrl} target="_blank" onClick={onViewSocial}>
        <H5 inline color={Theme.grey900Color}>
          {username}
        </H5>
      </Link>
    )
  }
  return (
    <H5 inline color={Theme.grey900Color}>
      {influencerFullName}
    </H5>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { submissionId, campaignId }: ISubmissionCardHeaderHandleProps,
) => ({
  showSocialProfile: selectIsSocialCampaign(state, campaignId),
  username: selectIdentityUsername(state, submissionId),
  socialUrl: selectIdentitySocialUrl(state, submissionId),
  influencerFullName: selectInfluencerFullName(state, submissionId),
})

export default compose<IInternalProps, ISubmissionCardHeaderHandleProps>(connect(mapStateToProps))(
  SubmissionCardHeaderHandle,
)
