import React, { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import Flex, { FlexItem } from 'styled-flex-component'

import { Chart } from '@components/Charts/AudienceInsigths/AudienceInsights.styled'
import { Hr } from '@components/UI/Hr/Hr.styled'
import Switch from '@components/UI/Switch'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { EventTrackingNames } from '@enums'
import { useBooleanToggle } from '@hooks/useBooleanToggle'
import { IAudienceReporting } from '@store/audienceReporting'
import Theme from '@theme'
import { Container, H3, Header, HorizontalBar } from '@tribegroup'
import { ChartsSectionWrapper, Wrapper } from './AudienceReporting.styled'
import {
  AnalyticsAudienceReportingChartsBar,
  AnalyticsAudienceReportingChartsDoughnut,
} from './Charts'

interface IProps {
  audienceReporting: IAudienceReporting
  campaignId: number
}

const AudienceReportingDemographics: React.FC<IProps> = ({
  audienceReporting: { gender, age_range: ageRange, gender_per_age_range: genderPerAgeRange },
  campaignId,
}) => {
  const [shouldUsePercentage, toggleShouldUsePercentage] = useBooleanToggle(true)

  const toggle = () => toggleShouldUsePercentage()

  return (
    <Fragment>
      <Hr backgroundColor={Theme.grey400Color} />
      <Container topInnerSpacing={1.25} bottomInnerSpacing={2.75}>
        <Flex justifyBetween wrap="wrap">
          <FlexItem>
            <H3 color={Theme.grey900Color} bottomOuterSpacing={0.5}>
              <FormattedMessage id="core.text.demographics" />
            </H3>
          </FlexItem>
          <FlexItem>
            <Header inline uppercase small color={Theme.grey700Color}>
              <FormattedMessage
                id={`analytics.audienceReporting.switch.${
                  shouldUsePercentage ? 'estPercentage' : 'estImpressions'
                }`}
              />
            </Header>
            <Container inlineBlock leftInnerSpacing={0.25}>
              <ConnectedTrackedRoute
                eventName={EventTrackingNames.AudienceReportingToggle}
                campaignId={campaignId}
                additionalProperties={{
                  toggle: shouldUsePercentage ? 'impressions' : 'percent',
                  section: 'demographics',
                }}
              >
                <Switch values={{ on: '#', off: '%' }} onChange={toggle} />
              </ConnectedTrackedRoute>
            </Container>
          </FlexItem>
        </Flex>
      </Container>
      <ChartsSectionWrapper>
        <Chart>
          <Container bottomOuterSpacing={1}>
            <Header color={Theme.grey900Color} uppercase>
              <FormattedMessage id="core.text.gender" />
            </Header>
          </Container>
          {gender && (
            <AnalyticsAudienceReportingChartsDoughnut
              data={gender}
              showAsPercentage={shouldUsePercentage}
            />
          )}
        </Chart>
        <Chart>
          <Container bottomOuterSpacing={1}>
            <Header uppercase color={Theme.grey900Color}>
              <FormattedMessage id="audienceInsights.age_range" />
            </Header>
          </Container>
          {ageRange && (
            <HorizontalBar
              theme={Theme}
              data={ageRange}
              showAsPercentage={shouldUsePercentage}
              roundPercentage
            />
          )}
        </Chart>
      </ChartsSectionWrapper>
      <Container
        leftInnerSpacing={2}
        rightInnerSpacing={2}
        bottomInnerSpacing={1.5}
        topInnerSpacing={1.5}
        borderColor={Theme.grey400Color}
        borderWidth={0.0625}
        borderRadius={0.5}
      >
        <Header uppercase color={Theme.grey900Color} bottomOuterSpacing={1}>
          <FormattedMessage id="analytics.audienceReporting.chart.ageAndGender" />
        </Header>
        <Container overflow="scroll" fullWidth>
          <Wrapper>
            {genderPerAgeRange && (
              <AnalyticsAudienceReportingChartsBar
                data={genderPerAgeRange}
                showAsPercentage={shouldUsePercentage}
              />
            )}
          </Wrapper>
        </Container>
      </Container>
    </Fragment>
  )
}

export default AudienceReportingDemographics
