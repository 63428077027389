import AuthSetPasswordForm from '@components/Auth/SetPassword/Form'
import FullImageBackground from '@components/UI/FullImageBackground'
import HeadTag from '@components/UI/HeadTag'

export const SetPasswordPage = () => (
  <FullImageBackground>
    <HeadTag id="resetPassword.setPassword.h1.header" />
    <AuthSetPasswordForm />
  </FullImageBackground>
)

export default SetPasswordPage
