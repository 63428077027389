import { Component, createRef, Fragment } from 'react'

import { SubmissionChannels } from '@enums'
import Container from '@tribegroup/Container'
import { KEYCODE } from '../../utils'
import { TagSet } from '../TagSet/TagSet'
import { Button } from './../../Button'
import { TextField } from './../../TextField'
import { FACEBOOK, INSTAGRAM, PINTEREST, TIKTOK, TIKTOK_MAX_LENGTH, TWITTER } from './constants'
import CustomInputWrapper from './CustomWrapper'
import ErrorMessage from './ErrorMessage'
import { ButtonWrapper, InputWrapper, Tags } from './InputSet.styled'
import TagInput from './TagsInput'
import { IInputSetProps } from './types'

export class InputSet extends Component<IInputSetProps> {
  state = {
    tagsValue: '',
    tagsError: '',
    handlesValue: '',
    handlesError: '',
    urlsValue: '',
    urlsError: '',
  }

  private tiktokHandle = createRef()
  private instagramHandle = createRef()
  private instagramURL = createRef()
  private facebookHashtag = createRef()
  private facebookHandle = createRef()
  private facebookURL = createRef()
  private twitterHandle = createRef()
  private twitterURL = createRef()
  private pinterestTag = createRef()
  private xHandle = createRef()
  private youtubeHandle = createRef()

  onChange = (event) => {
    const target = event.target as HTMLInputElement
    this.setState({
      [`${target.name}Value`]: target.value,
      [`${target.name}Error`]: '',
    })
  }

  onKeyDown = (event) => {
    if (event.keyCode === KEYCODE.ENTER) {
      event.preventDefault()
      const target = event.currentTarget as HTMLInputElement
      const formName = target.getAttribute('data-form')
      if (formName) {
        this.onSubmit(formName)
      }
    }
  }

  onClick = (event) => {
    event.preventDefault()
    const target = event.currentTarget as HTMLInputElement
    const formName = target.getAttribute('data-form')
    if (formName) {
      this.onSubmit(formName)
    }
  }

  onSubmit = (formName: string) => {
    const target = this[formName].current
    const attr = target!.getAttribute('data-attr')
    const error = target!.getAttribute('data-error')
    const pattern: any = target!.getAttribute('data-pattern')

    const regexp = new RegExp(pattern, 'g')
    const value = `${this.state[`${attr}Value`]}`.trim()
    const valid = regexp.test(value)

    if (valid) {
      this.setState(
        {
          [`${attr}Value`]: '',
        },
        () => {
          this.props.onAddTag(this.props.focused, value)
        },
      )
    } else {
      this.setState({
        [`${attr}Error`]: error,
      })
    }
  }

  render() {
    const {
      channel,
      submitText,
      onFocus,
      onBlur,
      onRemoveTag,
      tags = [],
      handles = [],
      urls = [],
      pillBackgroundColor,
    } = this.props

    const { tagsValue, handlesValue, urlsValue, urlsError, tagsError, handlesError } = this.state

    switch (channel) {
      case TIKTOK:
        return (
          <TagInput
            handle={this.tiktokHandle}
            {...this.props}
            onChange={this.onChange}
            tagsValue={tagsValue}
            onKeyDown={this.onKeyDown}
            onClick={this.onClick}
            tagsError={tagsError}
            submitText={submitText}
            placeholderText="#Tags and @handles will be featured within the caption"
            handleName="tiktokHandle"
            maxLength={TIKTOK_MAX_LENGTH}
          />
        )
      case SubmissionChannels.X:
        return (
          <TagInput
            handle={this.xHandle}
            {...this.props}
            onChange={this.onChange}
            tagsValue={tagsValue}
            onKeyDown={this.onKeyDown}
            onClick={this.onClick}
            tagsError={tagsError}
            submitText={submitText}
            placeholderText="#Tags and @handles will be featured within the caption"
            handleName="xHandle"
            maxLength={280}
          />
        )

      case SubmissionChannels.YouTube:
        return (
          <TagInput
            handle={this.youtubeHandle}
            {...this.props}
            onChange={this.onChange}
            tagsValue={tagsValue}
            onKeyDown={this.onKeyDown}
            onClick={this.onClick}
            tagsError={tagsError}
            submitText={submitText}
            placeholderText="#Tags and @handles will be featured within the caption"
            handleName="youtubeHandle"
            maxLength={280}
          />
        )

      case INSTAGRAM:
        return (
          <>
            <TagInput
              handle={this.instagramHandle}
              {...this.props}
              onChange={this.onChange}
              tagsValue={tagsValue}
              onKeyDown={this.onKeyDown}
              onClick={this.onClick}
              tagsError={tagsError}
              submitText={submitText}
              placeholderText="#Tags and @handles will be featured within the caption or Story"
              handleName="instagramHandle"
            />
            <CustomInputWrapper
              urls={urls}
              refProp={this.instagramURL}
              form={
                <>
                  <TextField
                    name="urls"
                    height={1}
                    fullWidth
                    rounded
                    placeholder="http://URL"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={urlsValue}
                    onChange={this.onChange}
                    data-form="instagramURL"
                    onKeyDown={this.onKeyDown}
                    data-testid="instagram-story-url-input"
                  />
                  <ButtonWrapper>
                    <Button
                      small
                      scheme="primary"
                      disabled={!urlsValue}
                      data-form="instagramURL"
                      onClick={this.onClick}
                      data-testid="instagram-story-url-submit"
                    >
                      {submitText}
                    </Button>
                  </ButtonWrapper>
                </>
              }
            >
              <ErrorMessage message={urlsError} />
              <Container inlineFlex>
                <TagSet
                  attr="urls"
                  list={urls}
                  onRemoveTag={onRemoveTag}
                  pillBackgroundColor={pillBackgroundColor}
                />
              </Container>
            </CustomInputWrapper>
          </>
        )

      case FACEBOOK:
        return (
          <Fragment>
            <InputWrapper
              data-pattern="^#[\w\d\.]+$"
              data-attr="tags"
              data-error="Please enter a valid #hashtag"
              ref={this.facebookHashtag}
            >
              <TextField
                placeholder="Add #hashtags"
                name="tags"
                height={1}
                fullWidth
                rounded
                onFocus={onFocus}
                onBlur={onBlur}
                value={tagsValue}
                onChange={this.onChange}
                data-form="facebookHashtag"
                onKeyDown={this.onKeyDown}
              />
              <ButtonWrapper>
                <Button
                  small
                  scheme="primary"
                  disabled={!tagsValue}
                  data-form="facebookHashtag"
                  onClick={this.onClick}
                >
                  {submitText}
                </Button>
              </ButtonWrapper>
            </InputWrapper>
            <ErrorMessage message={tagsError} />
            <InputWrapper
              data-pattern="^(@facebook.com\/)[\w\d]+([\.][\w\d]+)*$"
              data-attr="handles"
              data-error="Please enter a valid @handle"
              ref={this.facebookHandle}
            >
              <TextField
                placeholder="@facebook.com/handle"
                name="handles"
                height={1}
                fullWidth
                rounded
                onFocus={onFocus}
                onBlur={onBlur}
                value={handlesValue}
                onChange={this.onChange}
                data-form="facebookHandle"
                onKeyDown={this.onKeyDown}
              />
              <ButtonWrapper>
                <Button
                  small
                  scheme="primary"
                  disabled={!handlesValue}
                  data-form="facebookHandle"
                  onClick={this.onClick}
                >
                  {submitText}
                </Button>
              </ButtonWrapper>
            </InputWrapper>
            <ErrorMessage message={handlesError} />
            <InputWrapper
              data-attr="urls"
              data-pattern="^(http|https)://"
              data-error="Please enter a valid URL"
              ref={this.facebookURL}
            >
              <TextField
                name="urls"
                height={1}
                fullWidth
                rounded
                placeholder="http://URL"
                onFocus={onFocus}
                onBlur={onBlur}
                value={urlsValue}
                onChange={this.onChange}
                data-form="facebookURL"
                onKeyDown={this.onKeyDown}
              />
              <ButtonWrapper>
                <Button
                  small
                  scheme="primary"
                  disabled={!urlsValue}
                  data-form="facebookURL"
                  onClick={this.onClick}
                >
                  {submitText}
                </Button>
              </ButtonWrapper>
            </InputWrapper>
            <ErrorMessage message={urlsError} />
            <Tags>
              <TagSet
                attr="tags"
                list={tags}
                onRemoveTag={onRemoveTag}
                pillBackgroundColor={pillBackgroundColor}
              />
              <TagSet
                attr="handles"
                list={handles}
                onRemoveTag={onRemoveTag}
                pillBackgroundColor={pillBackgroundColor}
              />
              <TagSet
                attr="urls"
                list={urls}
                onRemoveTag={onRemoveTag}
                pillBackgroundColor={pillBackgroundColor}
              />
            </Tags>
          </Fragment>
        )
      case TWITTER:
        return (
          <Fragment>
            <InputWrapper
              data-attr="tags"
              data-pattern="^(\#|@)[\w\d\.]+$"
              data-error="Please enter a valid #hashtag or @handle"
              ref={this.twitterHandle}
            >
              <TextField
                placeholder="Add #hashtag or @handle"
                name="tags"
                height={1}
                fullWidth
                rounded
                onFocus={onFocus}
                onBlur={onBlur}
                value={tagsValue}
                onChange={this.onChange}
                data-form="twitterHandle"
                onKeyDown={this.onKeyDown}
              />
              <ButtonWrapper>
                <Button
                  small
                  scheme="primary"
                  disabled={!tagsValue}
                  data-form="twitterHandle"
                  onClick={this.onClick}
                >
                  {submitText}
                </Button>
              </ButtonWrapper>
            </InputWrapper>
            <ErrorMessage message={tagsError} />
            <InputWrapper
              data-attr="urls"
              data-pattern="^(http|https)://"
              data-error="Please enter a valid URL"
              ref={this.twitterURL}
            >
              <TextField
                name="urls"
                height={1}
                fullWidth
                rounded
                placeholder="http://URL"
                onFocus={onFocus}
                onBlur={onBlur}
                value={urlsValue}
                onChange={this.onChange}
                data-form="twitterURL"
                onKeyDown={this.onKeyDown}
              />
              <ButtonWrapper>
                <Button
                  small
                  scheme="primary"
                  disabled={!urlsValue}
                  data-form="twitterURL"
                  onClick={this.onClick}
                >
                  {submitText}
                </Button>
              </ButtonWrapper>
            </InputWrapper>
            <ErrorMessage message={urlsError} />
            <Tags>
              <TagSet
                attr="tags"
                list={tags}
                onRemoveTag={onRemoveTag}
                pillBackgroundColor={pillBackgroundColor}
              />
              <TagSet
                attr="urls"
                list={urls}
                onRemoveTag={onRemoveTag}
                pillBackgroundColor={pillBackgroundColor}
              />
            </Tags>
          </Fragment>
        )

      case PINTEREST: {
        return (
          <TagInput
            handle={this.pinterestTag}
            {...this.props}
            onChange={this.onChange}
            tagsValue={tagsValue}
            onKeyDown={this.onKeyDown}
            onClick={this.onClick}
            tagsError={tagsError}
            submitText={submitText}
            placeholderText="Add up to 10 keywords that relate to your Pins "
            handleName="pinterestTag"
            maxTags={10}
            customTagPattern={{
              pattern: `^[a-zA-Z0-9\\-\\'\\s]+$`,
              errorMessage: 'Please enter keywords without symbols like # and @',
            }}
          />
        )
      }
      default:
        return null
    }
  }
}
