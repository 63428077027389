import { IShortlistSummary, ShortlistSummaryActionTypes } from '@store/shortlistSummary'

export const fetchShortlistSummary = (campaignId: number) => ({
  payload: {
    campaignId,
  },
  type: ShortlistSummaryActionTypes.FETCH_REQUEST,
})

export const fetchShortlistSummarySuccess = (
  campaignId: number,
  shortlistSummary: Readonly<IShortlistSummary>,
) => ({
  payload: {
    campaignId,
    shortlistSummary,
  },
  type: ShortlistSummaryActionTypes.FETCH_SUCCESS,
})

export const fetchShortlistSummaryError = (error: any) => ({
  payload: error,
  type: ShortlistSummaryActionTypes.FETCH_ERROR,
})
