import React, { PropsWithChildren } from 'react'

import ModalRoot from './Root'

export const ModalContext = React.createContext({
  component: null,
  isOpen: false,
  props: {},
} as IModalContextProps)

export const MODAL_ANIMATION_DURATION = 400

export interface IModalContextProps<T = IModalComponentProps> {
  component: React.ComponentClass<IModalComponentProps> | React.FC<IModalComponentProps> | null
  props: any
  showModal: (
    component: React.ComponentClass<IModalComponentProps> | React.FC<T | any>,
    props?: any,
  ) => void
  hideModal: VoidFunction
  isOpen?: boolean
}

export interface IModalComponentProps {
  onRequestClose: VoidFunction
  isOpen?: boolean
}

export class ModalProvider extends React.Component<PropsWithChildren, IModalContextProps> {
  showModal = async (component: React.ComponentClass<IModalComponentProps>, props?: any) => {
    if (this.state.isOpen) {
      await this.hideModal()
    }

    this.setState({
      component,
      props,
      isOpen: true,
    })
  }

  hideModal = () => {
    this.setState({ isOpen: false })
    return this.delayedResetModal()
  }

  resetModal = () => {
    if (!this.state.isOpen) {
      this.setState({
        component: null,
        props: {},
      })
    }
  }

  delayedResetModal = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.resetModal()
        resolve(true)
      }, MODAL_ANIMATION_DURATION)
    })
  }

  state = {
    component: null,
    props: {},
    showModal: this.showModal,
    hideModal: this.hideModal,
    isOpen: false,
  }

  render() {
    return (
      <ModalContext.Provider value={this.state}>
        <ModalRoot {...this.state} />
        {this.props.children}
      </ModalContext.Provider>
    )
  }
}
