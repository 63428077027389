import Theme from '@theme'
import { Text } from '@tribegroup/Typography'
import { ErrorWrapper } from './InputSet.styled'

export const ErrorMessage = ({ message }) => {
  return (
    message && (
      <ErrorWrapper>
        <Text small color={Theme.errorColor}>
          {message}
        </Text>
      </ErrorWrapper>
    )
  )
}

export default ErrorMessage
