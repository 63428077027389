import { Fragment } from 'react'

import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { FULL_DATE_WITH_FULL_MONTH } from '@constants'
import { IApplicationState } from '@store'
import { selectLicensePurchasedAt, selectLicenseReceiptNumber } from '@store/mediaObjects'
import Theme from '@theme'
import { Container, Header, Text } from '@tribegroup'

interface IPurchaseDetailsProps {
  mediaObjectId: number
}

interface IInternalProps extends IPurchaseDetailsProps {
  purchasedAt: string
  receiptNumber: string
}

export const PurchaseDetails = ({ purchasedAt, receiptNumber }: IInternalProps) => (
  <Fragment>
    {purchasedAt && (
      <>
        <Container topOuterSpacing={0.75}>
          <Header small uppercase color={Theme.grey700Color}>
            <FormattedMessage id="socialSubmission.card.modal.license.purchased" />
          </Header>
        </Container>
        <Text color={Theme.grey900Color}>
          {format(new Date(purchasedAt), FULL_DATE_WITH_FULL_MONTH)}
        </Text>
      </>
    )}

    <Container topOuterSpacing={0.75}>
      <Header small uppercase color={Theme.grey700Color}>
        <FormattedMessage id="socialSubmission.card.modal.license.receiptNumber" />
      </Header>
    </Container>
    <Text color={Theme.grey900Color}>{receiptNumber}</Text>
  </Fragment>
)

const mapStateToProps = (state: IApplicationState, { mediaObjectId }) => ({
  purchasedAt: selectLicensePurchasedAt(state, mediaObjectId),
  receiptNumber: selectLicenseReceiptNumber(state, mediaObjectId),
})

export default compose<IInternalProps, IPurchaseDetailsProps>(connect(mapStateToProps))(
  PurchaseDetails,
)
