import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import {
  DetailsBlock,
  DetailsBlockBody,
  DetailsBlockHeader,
} from '@components/UI/DetailsBlock/DetailsBlock'
import { FullDetailsWrapper } from '@components/UI/Full/Full'
import { FullDetailsInnerWrapper } from '@components/UI/Full/Full.styled'
import { FULL_DATE, RELICENSE_STATUSES } from '@constants'
import { LicenseStatuses } from '@enums'
import { IApplicationState } from '@store'
import {
  selectDimensions,
  selectFileType,
  selectHasTransactedLicense,
  selectLicenseDescription,
  selectLicenseLabel,
  selectLicensePurchasedAt,
  selectLicenseReceiptNumber,
  selectMediaObject,
  selectPrintableDimensions,
  selectTransactedLicenseStatus,
} from '@store/mediaObjects'
import { selectMediaObjectId } from '@store/router'
import Theme from '@theme'
import { Text } from '@tribegroup'

export const MediaObjectFullDetails = () => {
  const match = useRouteMatch()

  const {
    hasLicense,
    licenseLabel,
    licenseDescription,
    licensePurchasedAt,
    dimensions,
    printableDimensions,
    licenseReceiptNumber,
    fileType,
    mediaObject,
    transactedLicenseStatus,
  } = useSelector((state: IApplicationState) => {
    const mediaObjectId = selectMediaObjectId(match) as number

    return {
      hasLicense: selectHasTransactedLicense(state, mediaObjectId),
      licenseLabel: selectLicenseLabel(state, mediaObjectId),
      licenseDescription: selectLicenseDescription(state, mediaObjectId),
      licensePurchasedAt: selectLicensePurchasedAt(state, mediaObjectId),
      transactedLicenseStatus: selectTransactedLicenseStatus(state, mediaObjectId),
      dimensions: selectDimensions(state, mediaObjectId),
      printableDimensions: selectPrintableDimensions(state, mediaObjectId),
      licenseReceiptNumber: selectLicenseReceiptNumber(state, mediaObjectId),
      fileType: selectFileType(state, mediaObjectId),
      mediaObject: selectMediaObject(state, mediaObjectId),
    }
  })

  const showLicenseDetails = !RELICENSE_STATUSES.includes(
    transactedLicenseStatus as LicenseStatuses,
  )

  return (
    <FullDetailsWrapper expandable={hasLicense}>
      <FullDetailsInnerWrapper>
        {licenseLabel && showLicenseDetails && (
          <DetailsBlock>
            <DetailsBlockHeader>{licenseLabel}</DetailsBlockHeader>
            <DetailsBlockBody>{licenseDescription}</DetailsBlockBody>
          </DetailsBlock>
        )}
        {licensePurchasedAt && showLicenseDetails && (
          <DetailsBlock>
            <DetailsBlockHeader>
              <FormattedMessage id="contentSubmission.card.details.purchased" />
            </DetailsBlockHeader>
            <DetailsBlockBody>{format(new Date(licensePurchasedAt), FULL_DATE)}</DetailsBlockBody>
          </DetailsBlock>
        )}
        {licenseReceiptNumber && (
          <DetailsBlock>
            <DetailsBlockHeader>
              <FormattedMessage id="contentSubmission.card.details.receiptNumber" />
            </DetailsBlockHeader>
            <DetailsBlockBody>{licenseReceiptNumber}</DetailsBlockBody>
          </DetailsBlock>
        )}
        <DetailsBlock>
          <DetailsBlockHeader>
            <FormattedMessage id="contentSubmission.card.details.dimensions" />
          </DetailsBlockHeader>
          <DetailsBlockBody>
            <p>{dimensions}</p>
            <p>{printableDimensions}</p>
          </DetailsBlockBody>
        </DetailsBlock>
        <DetailsBlock>
          <DetailsBlockHeader>
            <FormattedMessage id="contentSubmission.card.details.fileType" />
          </DetailsBlockHeader>
          <DetailsBlockBody>{fileType}</DetailsBlockBody>
        </DetailsBlock>
        {mediaObject.brand_name && (
          <DetailsBlock>
            <DetailsBlockHeader>
              <FormattedMessage id="mediaObjects.fullView.brandName.label" />
            </DetailsBlockHeader>
            <DetailsBlockBody>{mediaObject.brand_name}</DetailsBlockBody>
          </DetailsBlock>
        )}

        {mediaObject.channel && (
          <DetailsBlock>
            <DetailsBlockHeader>
              <FormattedMessage id="mediaObjects.fullView.channel.label" />
            </DetailsBlockHeader>
            <Text color={Theme.grey900Color} capitalize>
              {mediaObject.channel}
            </Text>
          </DetailsBlock>
        )}
      </FullDetailsInnerWrapper>
    </FullDetailsWrapper>
  )
}

export default MediaObjectFullDetails
