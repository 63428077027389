import { FormattedMessage } from 'react-intl'

import CampaignCardModalCreditCardActivate from '@components/Campaign/Card/Modal/CreditCard/Activate'
import Overlay from '@components/UI/Overlay/Overlay.styled'
import { IModalContextProps } from '@context/Modal'
import { withModal } from '@hocs'
import Theme from '@theme'
import { Button, Container } from '@tribegroup'

interface IProps {
  campaignId: number
}

type IInternalProps = IProps & IModalContextProps

const CampaignCardOverlayActivate: React.FC<IInternalProps> = ({ showModal, campaignId }) => {
  const showActivateCampaignModal = () => {
    return showModal(CampaignCardModalCreditCardActivate, { campaignId })
  }

  return (
    <Overlay
      visible
      transitionDuration={200}
      position="absolute"
      background={Theme.primary200Color}
      borderRadius={0.5}
      opacity={0.92}
      zIndex={1}
    >
      <Container fullHeight flexCenter pointer onClick={showActivateCampaignModal}>
        <Button
          scheme="primary"
          outlined
          small
          leftOuterSpacing={0.75}
          leftInnerSpacing={0}
          rightInnerSpacing={0}
          width={10.25}
        >
          <FormattedMessage id="core.text.activateCampaign" />
        </Button>
      </Container>
    </Overlay>
  )
}

export default withModal(CampaignCardOverlayActivate)
