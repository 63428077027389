import React from 'react'

import { FormattedMessage } from 'react-intl'

import { PopupMenuItem } from '@context/PopupMenu'
import { Text } from '@tribegroup'

interface IProps {
  frameNumber: number
  onClick: VoidFunction
}

const ActionBarIconLicenseCarouselPopupMenu: React.FC<IProps> = ({ frameNumber, onClick }) => {
  return (
    <PopupMenuItem onClick={onClick}>
      <Text xsmall color="inherit" uppercase>
        <FormattedMessage
          id="socialSubmission.card.modal.license.button.request.frame"
          values={{ frame: frameNumber }}
        />
      </Text>
    </PopupMenuItem>
  )
}

export default ActionBarIconLicenseCarouselPopupMenu
