import { FormattedMessage } from 'react-intl'

import { FullDetailsWrapper } from '@components/UI/Full/Full'
import Theme from '@theme'
import { Text } from '@tribegroup'
import { SubmissionCaption } from './Caption.styled'

const MAX_CAPTION_LENGTH_BEFORE_TRUNCATING = 250

interface ISubmissionCardCaption {
  caption?: string
  isProxy?: boolean
}

const SubmissionCardDetailsCaption = ({ caption = '', isProxy }: ISubmissionCardCaption) => {
  const useExpandable = Boolean(caption) && caption.length > MAX_CAPTION_LENGTH_BEFORE_TRUNCATING
  const showCaptionUnavailable = isProxy && (caption.length === 0 || caption === 'undefined')

  return (
    <FullDetailsWrapper expandable={useExpandable}>
      <SubmissionCaption>
        {!showCaptionUnavailable ? (
          <Text color={Theme.grey900Color}>{caption}</Text>
        ) : (
          <Text color={Theme.grey800Color}>
            <FormattedMessage id={`submission.fullView.offPlatform.caption.unavailable`} />
          </Text>
        )}
      </SubmissionCaption>
    </FullDetailsWrapper>
  )
}

export default SubmissionCardDetailsCaption
