import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Text } from '@tribegroup'
import { ColWrapper, Header } from '../ListView.styled'

interface IProps {
  translationKeys?: ReadonlyArray<string>
  isPriceHidden?: boolean
}

const AnalyticsPostCampaignReportListViewHeaders: React.FC<IProps> = ({
  translationKeys,
  isPriceHidden = false,
}) => (
  <Flex full>
    {translationKeys &&
      translationKeys.map((key) => (
        <ColWrapper key={`posts-header-${key}`}>
          <Header>
            <Text uppercase color={Theme.grey800Color} xsmall>
              <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.${key}`} />
            </Text>
          </Header>
        </ColWrapper>
      ))}
    <ColWrapper>
      <Header>
        <Text uppercase color={Theme.grey800Color} xsmall>
          <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.reach`} />
        </Text>
      </Header>
    </ColWrapper>
    <ColWrapper>
      <Header>
        <Text uppercase color={Theme.grey800Color} xsmall>
          <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.reachPct`} />
        </Text>
      </Header>
    </ColWrapper>
    <ColWrapper>
      <Header>
        <Text uppercase color={Theme.grey800Color} xsmall>
          <FormattedMessage
            id={`analytics.postCampaignReport.submission.list.header.impressions`}
          />
        </Text>
      </Header>
    </ColWrapper>
    {!isPriceHidden && (
      <ColWrapper>
        <Header>
          <Text uppercase color={Theme.grey800Color} xsmall>
            <FormattedMessage id={`analytics.postCampaignReport.submission.list.header.cpm`} />
          </Text>
        </Header>
      </ColWrapper>
    )}
  </Flex>
)

export default AnalyticsPostCampaignReportListViewHeaders
