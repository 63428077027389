import styled from 'styled-components'

import Theme from '@theme'
import { Text } from '@tribegroup'

const CampaignPreviewBlockStyled = styled.div`
  padding: 0 1.75rem;
  position: relative;
  display: block;

  > span {
    padding-top: 0.25rem;
    display: block;
  }

  &:last-of-type {
    padding-bottom: 4.9375rem;
  }
`

export const CampaignPreviewBlockInnerWrapper = styled.div`
  padding: 0.75rem 0;
  border-top: solid 1px ${Theme.grey400Color};
`

export const BlockText = styled(Text)`
  padding-top: 0.25rem;
  display: block;
`

export const EmptyBlock = styled.div`
  width: 100%;
`

export const EmptyLine = styled.div`
  &:not(:first-of-type) {
    margin-top: 0.375rem;
  }

  margin-top: 0.5rem;
  border-radius: 12px;
  height: 0.625rem;
  width: ${({ width }) => width}rem;
  background: ${Theme.grey200Color};
`

export default CampaignPreviewBlockStyled
