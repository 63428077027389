import { useEffect, useState } from 'react'

const useIsButtonLoading = (isSaving: boolean) => {
  const [loading, setLoading] = useState<boolean>(false)

  const startLoading = () => {
    setLoading(true)
  }

  const endLoading = () => {
    setLoading(false)
  }

  useEffect(() => {
    if (!isSaving) {
      setLoading(false)
    }
  }, [isSaving])

  return { loading, startLoading, endLoading }
}

export { useIsButtonLoading }
