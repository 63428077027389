import { InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { ActionIconWrapper } from '@components/Inbox/TitleBar/TitleBar.styled'
import ActionIcon from '@components/UI/ActionIcon'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import { EventTrackingNames } from '@enums'
import { withSubmissionListFilter } from '@hocs'
import { ICampaign } from '@store/campaigns'
import { ISubmission } from '@store/submissions'
import Theme from '@theme'
import { useRateCardTrigger } from './useRateCardTrigger'

interface IProps {
  campaign: ICampaign
  submission: ISubmission
  rateCardCurrency?: string
}

interface IInternalProps extends IProps, InjectedIntlProps, ISubmissionListFilterContextProps {}

const SubmissionRateCardPanelTriggerDesktop: React.FC<IInternalProps> = ({
  submission,
  campaign,
  intl,
  rateCardCurrency,
}) => {
  const { onClick, rateCardTipId } = useRateCardTrigger()

  return (
    <ActionIconWrapper>
      <ConnectedTrackedRoute
        submissionId={submission.id}
        campaignId={campaign.id}
        eventName={EventTrackingNames.ToolbarViewRateCard}
      >
        <ActionIcon
          onClick={onClick}
          glyph={`rate-card-${rateCardCurrency}`}
          color={Theme.grey900Color}
          size={1.5}
          tooltip={intl.formatMessage({ id: rateCardTipId })}
          elementName="rate-card"
        />
      </ConnectedTrackedRoute>
    </ActionIconWrapper>
  )
}

export default compose<IInternalProps, IProps>(
  injectIntl,
  withSubmissionListFilter,
)(SubmissionRateCardPanelTriggerDesktop)
