import { useState } from 'react'

import {
  MultiframeAutoplay,
  MultiframeNext,
  MultiframePrevious,
  MultiframeSlider,
} from '@components/UI/Multiframe'
import DescriptionPanel from '@components/UI/Multiframe/DescriptionPanel/DescriptionPanel'
import { MultiframePaginationBars } from '@components/UI/Multiframe/Pagination'
import { IMultiframeContextProps, MultiframeControlsProvider } from '@context/MultiframeControls'
import { SocialSubmissionTypes } from '@enums'
import { withMultiframeControls } from '@hocs'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { NavigationArrowsWrapper, SubmissionFullStoryWrapper } from './FullStory.styled'

export const SubmissionFullStory = ({ frames, activeFrameNumber }: IMultiframeContextProps) => {
  const [showArrows, setShowArrows] = useState(true)
  setTimeout(() => {
    setShowArrows(false)
  }, 3000)

  return (
    <MultiframeControlsProvider
      frames={frames}
      id="submission-full-story"
      startAtFrameNumber={activeFrameNumber}
    >
      <SubmissionFullStoryWrapper>
        <NavigationArrowsWrapper showArrows={showArrows}>
          <MultiframePrevious />
          <MultiframeNext />
        </NavigationArrowsWrapper>
        <MultiframeSlider backgroundColor="transparent" portrait showVideoControls />
        <MultiframePaginationBars />
        <MultiframeAutoplay />
      </SubmissionFullStoryWrapper>
      <Container topOuterSpacing={1.5}>
        <Text uppercase xsmall color={Theme.whiteColor}>
          <DescriptionPanel socialSubmissionType={SocialSubmissionTypes.Story} />
        </Text>
      </Container>
    </MultiframeControlsProvider>
  )
}

export default withMultiframeControls(SubmissionFullStory)
