import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import { updateHasSeenPreviewTooltip } from '@store/campaigns'
import { selectShowCampaignPreviewTooltip } from '@store/drafts'
import Theme from '@theme'
import { Header, Text } from '@tribegroup'
import { PreviewInformationPanel } from './Preview.styled'
interface IInternalProps {
  showPreviewTooltip: boolean
  updateHasSeenPreviewTooltip: typeof updateHasSeenPreviewTooltip
}

const BuilderCampaignPreviewIconTooltip: React.FC<IInternalProps> = ({
  showPreviewTooltip,
  updateHasSeenPreviewTooltip: updateHasSeenPreviewTooltipAction,
}) => {
  const [showTooltip, setShowTooltip] = React.useState(true)

  React.useEffect(() => {
    if (showPreviewTooltip) {
      setTimeout(() => {
        setShowTooltip(true)
      }, 1000)
      setTimeout(() => {
        setShowTooltip(false)
        updateHasSeenPreviewTooltipAction()
      }, 7000)
    } else {
      setShowTooltip(showPreviewTooltip)
    }
  }, [showPreviewTooltip])
  return (
    <PreviewInformationPanel showPreviewTooltip={showTooltip}>
      <Header
        small
        uppercase
        color={Theme.secondaryColor}
        bottomOuterSpacing={0.5}
        textAlign="center"
      >
        <FormattedMessage id="builder.titlebar.preview.tooltip.title" />
      </Header>
      <Text small color={Theme.defaultColor} textAlign="center">
        <FormattedMessage id="builder.titlebar.preview.tooltip.content" />
      </Text>
    </PreviewInformationPanel>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { currentStep }: IBuilderValidationContextProps,
) => ({
  showPreviewTooltip: selectShowCampaignPreviewTooltip(state, currentStep),
})

const mapDispatchToProps = {
  updateHasSeenPreviewTooltip,
}

export { BuilderCampaignPreviewIconTooltip }
export default compose<IInternalProps, {}>(
  withBuilderValidation,
  connect(mapStateToProps, mapDispatchToProps),
)(BuilderCampaignPreviewIconTooltip)
