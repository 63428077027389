import React from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import { compose } from 'recompose'

import { CardGridWithInfinteScroll } from '@components/UI/CardGrid'
import Loading from '@components/UI/Loading'
import { NoticeEmpty } from '@components/UI/Notice'
import { withRememberScrollPosition } from '@hocs'
import { IApplicationState } from '@store'
import {
  fetchShareableContentLibrary,
  selectMediaObjectIsFetching,
  selectShareableContentLibraryHasMore,
  selectShareableContentLibraryMediaObjectIds,
} from '@store/mediaObjects'
import { Container } from '@tribegroup'
import { isEmpty } from '@utils'
import ShareableContentLibraryCard from './Card'

interface IInternalProps {
  mediaObjectIds: ReadonlyArray<number>
  fetchShareableContentLibrary: typeof fetchShareableContentLibrary
  hasMore: boolean
  isInitialFetch: boolean
  hasEmptyList: boolean
}

interface IParams {
  token: string
}

const ShareableContentLibraryList: React.FC<IInternalProps> = ({
  mediaObjectIds,
  fetchShareableContentLibrary: fetchShareableContentLibraryAction,
  hasMore,
  isInitialFetch,
  hasEmptyList,
}) => {
  const { token } = useParams<IParams>()

  React.useEffect(() => {
    fetchShareableContentLibraryAction(String(token))
  }, [])

  if (isInitialFetch) {
    return <Loading height={5} />
  }

  if (hasEmptyList) {
    return (
      <NoticeEmpty
        topOuterSpacing={7}
        alt="shareable-content-library"
        title={<FormattedMessage id="contentLibrary.shareable.empty.title" />}
      />
    )
  }

  const fetchNext = () => {
    const isFetchNext = true
    fetchShareableContentLibraryAction(String(token), isFetchNext)
  }

  return (
    <Container topOuterSpacing={3.75} width="85vw">
      <CardGridWithInfinteScroll gap={0.9375} noSideBar>
        <InfiniteScroll
          dataLength={mediaObjectIds.length}
          next={fetchNext}
          loader={<Loading height={30} />}
          hasMore={hasMore}
          scrollThreshold={0.8}
          style={{ overflow: 'hidden' }}
        >
          <div>
            {mediaObjectIds.map((mediaObjectId) => (
              <ShareableContentLibraryCard key={mediaObjectId} mediaObjectCardId={mediaObjectId} />
            ))}
          </div>
        </InfiniteScroll>
      </CardGridWithInfinteScroll>
    </Container>
  )
}

const mapDispatchToProps = {
  fetchShareableContentLibrary,
}

const mapStateToProps = (state: IApplicationState) => {
  const isFetching = selectMediaObjectIsFetching(state)
  const mediaObjectIds = selectShareableContentLibraryMediaObjectIds(state)
  return {
    mediaObjectIds,
    hasMore: selectShareableContentLibraryHasMore(state),
    isInitialFetch: isFetching && isEmpty(mediaObjectIds),
    hasEmptyList: !isFetching && isEmpty(mediaObjectIds),
  }
}

export { ShareableContentLibraryList }
export default compose<IInternalProps, {}>(
  withRememberScrollPosition,
  connect(mapStateToProps, mapDispatchToProps),
)(ShareableContentLibraryList)
