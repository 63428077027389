import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import Price from '@components/UI/Price/Price'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectPriceForDisplay, selectSubmission } from '@store/submissions'
import Theme from '@theme'
import { H5 } from '@tribegroup'
import { PriceWrapper } from '../Header.styled'

interface ISubmissionCardHeaderPriceProps {
  submissionId: number
  campaignId: number
  enableCostBreakdownTracking?: boolean
  toggleCostBreakdown?: (event: React.MouseEvent) => void
}

interface IInternalProps extends ISubmissionCardHeaderPriceProps {
  price: number
  currency: string
  priceChanged: boolean
}

export const SubmissionCardHeaderPrice: React.FC<IInternalProps> = ({
  toggleCostBreakdown,
  currency,
  price,
  campaignId,
  submissionId,
  enableCostBreakdownTracking,
  priceChanged,
}) => {
  return (
    <H5
      className="price"
      color={Theme.grey900Color}
      data-cy-element="price"
      leftOuterSpacing={0.75}
    >
      <ConnectedTrackedRoute
        disableTracking={enableCostBreakdownTracking}
        eventName={EventTrackingNames.ViewPriceSummary}
        campaignId={campaignId}
        submissionId={submissionId}
      >
        <PriceWrapper onClick={toggleCostBreakdown} role="button">
          <Price
            quantity={price || 0}
            currency={currency || ''}
            rounded
            pattern={priceChanged ? '!###,###*' : undefined}
          />
        </PriceWrapper>
      </ConnectedTrackedRoute>
    </H5>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { submissionId, campaignId }: ISubmissionCardHeaderPriceProps,
) => {
  const { price, currency } = selectPriceForDisplay(state, submissionId, campaignId)
  const { first_preapproved_cents } = selectSubmission(state, submissionId)
  return {
    price,
    currency,
    priceChanged: Boolean(first_preapproved_cents),
  }
}

export default compose<IInternalProps, ISubmissionCardHeaderPriceProps>(connect(mapStateToProps))(
  SubmissionCardHeaderPrice,
)
