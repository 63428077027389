import styled from 'styled-components'
import Flex from 'styled-flex-component'

export default styled(Flex)`
  align-items: center;

  > span {
    &:first-of-type {
      margin-left: 0.75rem;
    }
  }
`
