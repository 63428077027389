import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Theme from '@theme'

export const Wrapper = styled(Link)`
  padding-bottom: 0.5rem;
  width: 5.875rem;
  height: 8.5rem;
  text-decoration: none;
`

export const SelectedSubmissionIndicator = styled.hr`
  border: none;
  height: 2px;
  background: ${Theme.primaryColor};
  margin: 0.25rem 0;
  ${({ visible }) =>
    css`
      visibility: ${visible ? 'visible' : 'hidden'};
    `};
`
