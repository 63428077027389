import styled from 'styled-components'

import Theme from '@theme'
import { Text } from '@tribegroup'

const CampaignPreviewTitleStyled = styled.div`
  text-align: center;
  color: ${Theme.grey900Color};
  padding: 0.875rem 1.75rem 1.25rem;
`

export const CampaignTitle = styled(Text)`
  padding-top: 0.5rem;
`

export const CampaignSubtitle = styled(Text)`
  padding-top: 0.125rem;
`

export default CampaignPreviewTitleStyled
