import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import AnalyticsDetailCard from '@components/Analytics/DetailCard/DetailCard'
import { isNumber } from '@components/Analytics/PrePurchase/PrePurchase'
import { Hr } from '@components/UI/Hr/Hr.styled'
import { AnalyticsCustomPrice } from '@components/UI/Price/Price'
import { CPM_EXPLAINED, TRIBE_PRICING_PAGE_POST_PURCHASE_METRICS_UTM } from '@constants'
import { IApplicationState } from '@store'
import { selectSubmissionPerformance } from '@store/performance'
import { selectSubmissionId } from '@store/router'
import { selectSubmission, selectSubTotalCurrency } from '@store/submissions'
import Theme from '@theme'
import { Link } from '@tribegroup'
import { abbreviateNumberOrDash } from '@utils'
import { AnalyticsPostPerformanceGrid } from '../PostPerformance.styled'
import AnalyticsPostPerformanceUnavailable from '../Unavailable/Unavailable'

const externalLink = TRIBE_PRICING_PAGE_POST_PURCHASE_METRICS_UTM

const AnalyticsPostPerformanceStory = () => {
  const match = useRouteMatch()

  const { performance, followers, currency } = useSelector((state: IApplicationState) => {
    const submissionId = selectSubmissionId(match) as number

    const { identity_followers_count } = selectSubmission(state, submissionId)

    return {
      followers: identity_followers_count,
      currency: selectSubTotalCurrency(state, submissionId),
      performance: selectSubmissionPerformance(state, submissionId),
    }
  })

  if (!performance || Object.keys(performance).length === 0) {
    return <AnalyticsPostPerformanceUnavailable />
  }

  const cpm = performance.cost_per_mille
  const reachPct = performance.reach_pct

  return (
    <>
      <AnalyticsPostPerformanceGrid>
        <AnalyticsDetailCard
          title="submission.postPerformance.followers"
          content={abbreviateNumberOrDash(followers)}
          elementName="post-performance-followers"
          productMetricGroup="post_purchase"
          productMetricValue="followers"
          externalLink={externalLink}
          featured
        />
        <AnalyticsDetailCard
          title="submission.postPerformance.story.reach"
          content={abbreviateNumberOrDash(performance?.reach)}
          elementName="post-performance-reach"
          productMetricGroup="post_purchase"
          productMetricValue="reach"
          externalLink={externalLink}
          featured
        />
        {cpm && (
          <AnalyticsDetailCard
            title="submission.postPerformance.story.CPM"
            content={isNumber(cpm) && <AnalyticsCustomPrice currency={currency} quantity={cpm} />}
            elementName="post-performance-cpm"
            productMetricGroup="post_purchase"
            productMetricValue="cpm"
            externalLink={externalLink}
            values={{
              link: (
                <Link href={CPM_EXPLAINED} target="_blank" color={Theme.primaryColor} lowercase>
                  <FormattedMessage id="core.text.clickHere" />
                </Link>
              ),
            }}
            featured
          />
        )}
      </AnalyticsPostPerformanceGrid>
      <Hr backgroundColor={Theme.grey400Color} />
      <AnalyticsPostPerformanceGrid topOuterSpacing={1.75}>
        <AnalyticsDetailCard
          title="submission.postPerformance.story.impressions"
          content={abbreviateNumberOrDash(performance?.impressions)}
          elementName="post-performance-impressions"
          productMetricGroup="post_purchase"
          productMetricValue="impressions"
          externalLink={externalLink}
        />
        <AnalyticsDetailCard
          title="submission.postPerformance.story.reachPercentage"
          content={
            isNumber(reachPct) && (
              <FormattedMessage id="core.text.percent" values={{ value: reachPct }} />
            )
          }
          elementName="post-performance-reach-percentage"
          productMetricGroup="post_purchase"
          productMetricValue="reach_pct"
          externalLink={externalLink}
        />
      </AnalyticsPostPerformanceGrid>
    </>
  )
}

export { AnalyticsPostPerformanceStory }
export default AnalyticsPostPerformanceStory
