import { FormattedHTMLMessage } from 'react-intl'

import Loading from '@components/UI/Loading'
import Theme from '@theme'
import { Container } from '@tribegroup'
import { Text } from '@tribegroup'
import { CenterOverlay } from './ImageSlider.styled'

const MediaSliderGeneratingPreview = () => {
  return (
    <Container height={26} data-testid="generating-preview-campaign-preview">
      <CenterOverlay direction="column">
        <Container>
          <Loading height={1.5} iconOnly />
        </Container>
        <Text block small color={Theme.grey800Color}>
          <FormattedHTMLMessage id="core.text.generatingPreview" />
        </Text>
      </CenterOverlay>
    </Container>
  )
}

export default MediaSliderGeneratingPreview
