import React from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import PricePanelAmount from '@components/UI/PricePanel/Amount'
import { IApplicationState } from '@store'
import {
  selectShareableContentLibraryAmount,
  selectShareableContentLibraryCurrency,
  selectShareableContentLibraryTax,
} from '@store/mediaObjects'
import { selectMediaObjectId } from '@store/router'

interface IInternalProps {
  price: number
  currency: string
  taxDisplayName: string
}

const ShareableContentLibraryFullPricePanelAmount: React.FC<IInternalProps> = ({
  price,
  currency,
  taxDisplayName,
}) => {
  return (
    <PricePanelAmount price={price} currency={currency} taxDisplayName={taxDisplayName} showFrom />
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const mediaObjectId = selectMediaObjectId(match) as number
  return {
    price: selectShareableContentLibraryAmount(state, mediaObjectId),
    currency: selectShareableContentLibraryCurrency(state, mediaObjectId),
    taxDisplayName: selectShareableContentLibraryTax(state, mediaObjectId),
  }
}

export { ShareableContentLibraryFullPricePanelAmount }
export default compose(
  withRouter,
  connect(mapStateToProps),
)(ShareableContentLibraryFullPricePanelAmount)
