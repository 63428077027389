import { Reducer } from 'redux'

import { SubmissionChannels } from '@enums'
import { FetchStatesActionTypes } from './types'
import { IRootResourceState, RootResourceActionTypes } from './'

const initialState: IRootResourceState = {
  consented_items: {
    brand_marketing_consent_default: false,
    toc_checked_by_default: false,
  },
  tenant_features: [],
  genders: [],
  company_sizes: [],
  company_types: [],
  links: [],
  errors: undefined,
  isFetching: false,
  states: undefined,
  isFetched: false,
  target_regions: [],
  countries: [],
  operating_regions: [],
  bca_duration_in_days_options: [],
  decline_reasons: {
    social_submission: [],
    content_submission: [],
  },
  subscription_tiers: [],
  preapproval_amount_percentage: 0,
  campaign_creation_reasons: [],
  estimated_campaign_creation_count: [],
  product_categories: [],
  channels: {
    [SubmissionChannels.TikTok]: {
      submission_types: [],
    },
    [SubmissionChannels.Instagram]: {
      submission_types: [],
    },
    [SubmissionChannels.Facebook]: {
      submission_types: [],
    },
    [SubmissionChannels.Twitter]: {
      submission_types: [],
    },
    [SubmissionChannels.Pinterest]: {
      submission_types: [],
    },
    [SubmissionChannels.X]: {
      submission_types: [],
    },
    [SubmissionChannels.YouTube]: {
      submission_types: [],
    },
  },
}

const reducer: Reducer<IRootResourceState> = (state = initialState, action) => {
  switch (action.type) {
    case FetchStatesActionTypes.FETCH_STATES_REQUEST:
    case RootResourceActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }

    case RootResourceActionTypes.FETCH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        isFetched: true,
      }
    }

    case FetchStatesActionTypes.FETCH_STATES_SUCCESS: {
      return {
        ...state,
        states: action.payload,
        isFetching: false,
      }
    }

    case FetchStatesActionTypes.FETCH_STATES_ERROR:
    case RootResourceActionTypes.FETCH_ERROR: {
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
      }
    }

    default:
      return state
  }
}

export { reducer as rootResourceReducer }
