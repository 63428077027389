import React from 'react'

import { FormattedMessage } from 'react-intl'

import { INFORMATION_TOOLTIP_ID } from '@context/InformationTooltip/InformationTooltipBaseWrapper'
import Theme from '@theme'
import { Tip } from '@tribegroup'
import SubmissionFeedbackNegotiationCoachMark from './CoachMark'
import SubmissionFeedbackNegotiationContentCoachMark from './ContentCoachMark'
import { HintTextWrapper } from './Hint.styled'

interface IProps {
  submissionId: number
  campaignId: number
  isSocialCampaign?: boolean
}

export const SubmissionFeedbackNegotiationHint: React.FC<IProps> = ({
  campaignId,
  submissionId,
  isSocialCampaign,
}) => (
  <Tip
    ignoredContainerId={INFORMATION_TOOLTIP_ID}
    inline
    icon={{
      size: 1,
      glyph: 'notification-dot',
      padded: false,
      color: Theme.primaryColor,
    }}
    text={
      <HintTextWrapper>
        <FormattedMessage id="submission.feedback.form.popup.negotiatingprice" />
      </HintTextWrapper>
    }
    coachmark={{
      content: isSocialCampaign ? (
        <SubmissionFeedbackNegotiationCoachMark
          submissionId={submissionId}
          campaignId={campaignId}
        />
      ) : (
        <SubmissionFeedbackNegotiationContentCoachMark submissionId={submissionId} />
      ),
      position: 'top',
      pointerXPosition: 'left',
      width: 16.25,
      yOffset: 0.75,
      maxHeightOffset: 10.625,
    }}
    openCoachmarkOnRender
  />
)

export default SubmissionFeedbackNegotiationHint
