import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { INSTAGRAM_BRANDED_CONTENT } from '@constants'
import { EventTrackingNames } from '@enums'
import Theme from '@theme'
import { Checkbox, Container, Text } from '@tribegroup'
import { MessageWithLink } from '../../Channels.styled'

const ADJUSTED_WIDTH = 'calc(100% - 5rem)'

export interface IProps {
  draftId: number
  onCheckboxChange: (event: React.SyntheticEvent) => void
  isChecked: boolean
}

const BuilderBriefChannelsInstagramPaidPartershipNoInstagramCheckBox: React.FC<IProps> = ({
  draftId,
  onCheckboxChange,
  isChecked,
}) => {
  return (
    <Container topOuterSpacing={1} data-testid="no-instagram-checkbox-container">
      <Checkbox
        name="has_brand_partnership_handle"
        scheme="primary"
        readOnly
        label={
          <Text small>
            <FormattedMessage id="builder.brief.socialMedia.dontHaveInstagram" />
          </Text>
        }
        onClick={onCheckboxChange}
        checked={isChecked}
      />
      {isChecked && (
        <Container topOuterSpacing={1.5} data-testid="no-instagram-tip" width={ADJUSTED_WIDTH}>
          <MessageWithLink>
            <Text color={Theme.defaultColor} small>
              <ConnectedTrackedRoute
                draftId={draftId}
                eventName={EventTrackingNames.CampaignBuilderEducationLink}
                targetLink
                additionalProperties={{
                  link_url: INSTAGRAM_BRANDED_CONTENT,
                  link_name:
                    'builder.brief.socialMedia.dontHaveInstagram.tip.brandedContentPolicies',
                }}
              >
                <FormattedHTMLMessage
                  id="builder.brief.socialMedia.dontHaveInstagram.tip"
                  values={{
                    link: INSTAGRAM_BRANDED_CONTENT,
                  }}
                />
              </ConnectedTrackedRoute>
            </Text>
          </MessageWithLink>
        </Container>
      )}
    </Container>
  )
}

export default BuilderBriefChannelsInstagramPaidPartershipNoInstagramCheckBox
