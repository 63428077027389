import React, { PropsWithChildren } from 'react'

import Theme from '@theme'
import { Container, Header, Text } from '@tribegroup'

type textAlignType = 'left' | 'center' | 'right'

export interface IInformationContentProps {
  content: React.ReactNode
  title?: React.ReactNode
  titleColor?: string
  titleTextAlign?: textAlignType
  titleExtraProps?: object
  contentColor?: string
  contentTextAlign?: textAlignType
  contentExtraProps?: object
}

const InformationContent: React.FC<PropsWithChildren<IInformationContentProps>> = ({
  title,
  content,
  titleColor = Theme.primaryColor,
  titleTextAlign = 'left',
  titleExtraProps = {},
  contentColor = Theme.grey900Color,
  contentTextAlign = 'left',
  contentExtraProps = {},
  children,
}) => (
  <Container>
    {title && (
      <Header
        small
        textAlign={titleTextAlign}
        uppercase
        bottomOuterSpacing={0.5}
        color={titleColor}
        data-testid="tooltip-title"
        {...titleExtraProps}
      >
        {title}
      </Header>
    )}
    <Text
      textAlign={contentTextAlign}
      color={contentColor}
      customFontSize={0.8125}
      data-testid="tooltip-content"
      {...contentExtraProps}
    >
      {content}
    </Text>
    {children}
  </Container>
)

export default InformationContent
