import { createContext, PureComponent } from 'react'

interface IMobileMenuControlsProviderState {
  open: boolean
}

export interface IMobileMenuControlsContextProps extends IMobileMenuControlsProviderState {
  toggleMobileMenu: VoidFunction
  hideMobileMenu: VoidFunction
}

export const MobileMenuControlsContext = createContext({} as IMobileMenuControlsContextProps)

export class MobileMenuControlsProvider extends PureComponent<
  any,
  IMobileMenuControlsProviderState
> {
  state = {
    open: false,
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }))
  }

  hideMobileMenu = () => {
    this.setState({
      open: false,
    })
  }

  render() {
    const contextProps: IMobileMenuControlsContextProps = {
      open: this.state.open,
      toggleMobileMenu: this.toggleMobileMenu,
      hideMobileMenu: this.hideMobileMenu,
    }

    return (
      <MobileMenuControlsContext.Provider value={contextProps}>
        {this.props.children}
      </MobileMenuControlsContext.Provider>
    )
  }
}

export default MobileMenuControlsProvider
