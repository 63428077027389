import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import TitleBar from '@components/UI/TitleBar'
import { HeaderBackButton, HeaderTitle } from '@components/UI/TitleBar/TitleBar.styled'
import hooks from '@hooks'
import Theme from '@theme'
import { H5 } from '@tribegroup'
import { getBackLink, queryStringtoObject } from '@utils'

export const BillingTitleBar: React.FC<InjectedIntlProps> = ({ intl }) => {
  const { pathname, search } = hooks.useLocation()

  const backLink = { pathname: getBackLink(pathname) }
  const { from: fromRoute } = queryStringtoObject(search)

  const toRoute = fromRoute ? decodeURIComponent(fromRoute) : backLink

  return (
    <TitleBar>
      <Link to={toRoute} data-cy-element="header-back-button">
        <HeaderBackButton
          glyph="nav-back-default"
          size={1.5}
          color={Theme.grey900Color}
          data-tooltip-id="base-tooltip"
          data-tooltip-content={intl.formatMessage({ id: 'inbox.titlebar.action.back' }) as string}
        />
      </Link>
      <HeaderTitle data-cy-element="titlebar-call-to-action">
        <H5 color={Theme.grey900Color}>
          <FormattedMessage id="core.text.billing" />
        </H5>
      </HeaderTitle>
    </TitleBar>
  )
}
export default injectIntl(BillingTitleBar)
