import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'
import { Container } from '@tribegroup'

export const Separator = styled.i`
  height: 2.75rem;
  border-right: 1px solid;
  border-color: ${Theme.grey400Color};
  margin: 0 1rem;
`

export const BioContentContainer = styled(Container)`
  width: 100%;
  flex-direction: column;
  ${breakpoint('desktop')`
    width: 70%;
 `}
`

export const InfluencerMemberSince = styled(Container)`
  border-bottom: 1px solid ${Theme.grey400Color};
  ${breakpoint('desktop')`
    border-bottom: none;
  `}
`
