import { call, put, select, takeEvery } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { IHateoasLink } from '@store'
import { selectCampaignTrackingProps, trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  downloadInstagramHandles,
  InstagramHandlesActionTypes,
  selectDownloadInstagramHandlesLink,
} from '@store/campaigns'
import { selectApprovedSubmissionsCount } from '@store/submissions'
import { downloadCSV } from '@utils'

export function* handleDownloadInstagramHandles(
  action: ReturnType<typeof downloadInstagramHandles>,
) {
  const { campaignId, fileName } = action.payload
  const authToken: string = yield select(selectAuthToken)
  const downloadLink: IHateoasLink = yield select(selectDownloadInstagramHandlesLink, campaignId)

  const headers = {
    Authorization: `Bearer ${authToken}`,
  }

  yield call(downloadCSV, downloadLink, fileName, headers)

  const campaignProps = yield select(selectCampaignTrackingProps, campaignId)
  const count = yield select(selectApprovedSubmissionsCount, campaignId)
  yield put(trackEvent(EventTrackingNames.DownloadInstagramHandlesCSV, { ...campaignProps, count }))
}

export function* watchDownloadInstagramHandles() {
  yield takeEvery(InstagramHandlesActionTypes.DOWNLOAD_CSV, handleDownloadInstagramHandles)
}
