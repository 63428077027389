import { useContext } from 'react'

import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Flex from 'styled-flex-component'

import { EllipsisWrapper } from '@components/InfluencerIdentity/Row/Details/Details.styled'
import Avatar from '@components/UI/Avatar'
import RouterLink from '@components/UI/RouterLink/RouterLink.styled'
import { CampaignContext } from '@context/Campaign'
import { MemberRoleTypes, PageRoutes } from '@enums'
import { useModal } from '@hooks/useModal'
import hashtagPlaceholderImage from '@images/hashtag-placeholder@3x.png'
import { IBrandManagerIdentity } from '@store/brandManagerIdentities'
import { IConnectedSocialAccount, selectDisplayPaidPartnershipHandle } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import ConnectedAccountsDrawerContentFilledIdentitySwitchOffAccountDialog from '../../Content/Filled/Identity/SwitchOffAccountDialog'
import { ConnectedAccountsDrawerIdentityPlaceholder } from '../Placeholder'
import { ConnectedAccountsDrawerIdentityInstagramIcon } from './Icon'

interface IProps {
  brandManagerIdentity?: IBrandManagerIdentity | IConnectedSocialAccount
  isActive?: boolean
  onClick: VoidFunction
}

const ConnectedAccountsDrawerIdentityInstagram = ({
  brandManagerIdentity,
  isActive,
  onClick: onClickProp,
}: IProps) => {
  const {
    campaign: { id: campaignId, role },
  } = useContext(CampaignContext)
  const location = useLocation()
  const { showModal, hideModal } = useModal()

  const fromRoute = `?from=${encodeURIComponent(`${location.pathname}${location.search}`)}`

  const { paidPartnershipHandle } = useSelector((state: IApplicationState) => {
    return {
      paidPartnershipHandle: selectDisplayPaidPartnershipHandle(state, campaignId),
    }
  })

  if (!brandManagerIdentity && !paidPartnershipHandle) {
    return <></>
  }

  if (!brandManagerIdentity && paidPartnershipHandle) {
    return (
      <RouterLink to={`/${PageRoutes.ConnectedAccounts}${fromRoute}`}>
        <ConnectedAccountsDrawerIdentityPlaceholder
          glyph="social-instagram"
          displayName={paidPartnershipHandle}
          hideChevron={[
            MemberRoleTypes.Collaborator,
            MemberRoleTypes.SolutionCollaborator,
          ].includes(role)}
        />
      </RouterLink>
    )
  }

  const onClick = () => {
    if (isActive) {
      return showModal(ConnectedAccountsDrawerContentFilledIdentitySwitchOffAccountDialog, {
        onAccept: () => {
          onClickProp()
          hideModal()
        },
      })
    }

    return onClickProp()
  }

  return (
    <Container
      leftInnerSpacing={0.75}
      rightInnerSpacing={0.75}
      topInnerSpacing={0.625}
      bottomInnerSpacing={0.625}
      borderRadius={0.5}
      height={3.75}
      flexBetween
      backgroundColor={Theme.grey100Color}
      topOuterSpacing={1.25}
    >
      <Flex alignCenter>
        <Container rightOuterSpacing={0.75} width={2.5} height={2.5} borderRadius={100}>
          <Avatar
            size={2.5}
            borderWidth={0.0625}
            avatarSrc={brandManagerIdentity?.display_url}
            borderColor={Theme.grey300Color}
            placeholderImage={hashtagPlaceholderImage}
          />
        </Container>
        <Container rightOuterSpacing={0.75}>
          <Icon glyph="social-instagram-colour" size={1} verticalAlign="middle" />
        </Container>
        <Container rightInnerSpacing={1} maxWidth={9}>
          <EllipsisWrapper color={Theme.defaultColor} maxWidth={9}>
            <Text color={Theme.grey900Color}>{brandManagerIdentity?.display_name}</Text>
          </EllipsisWrapper>
        </Container>
      </Flex>

      <ConnectedAccountsDrawerIdentityInstagramIcon
        isActive={isActive}
        onClick={onClick}
        brandManagerIdentity={brandManagerIdentity as IConnectedSocialAccount}
      />
    </Container>
  )
}

export { ConnectedAccountsDrawerIdentityInstagram }
