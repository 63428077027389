import React, { PropsWithChildren } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { ConditionalTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectBrandFanGroupMemberCount, selectBrandFanGroupName } from '@store/brandFans'
import { selectBrandFansCurrentView } from '@store/router'

interface IProps {
  hasTracking?: boolean
  eventName: EventTrackingNames
  groupId: number
  sortBy?: string
  additionalProperties?: any
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

const BrandFansGroupRowTrackedRoute: React.FC<PropsWithChildren<IInternalProps>> = ({
  groupId,
  groupName,
  eventName,
  currentView,
  sortBy,
  hasTracking = true,
  additionalProperties,
  totalCount,
  children,
}) => (
  <ConditionalTrackedRoute
    hasTracking={hasTracking}
    eventName={eventName}
    properties={{
      group_id: groupId,
      group_name: groupName,
      current_filter: currentView,
      target_sort_filter: sortBy,
      number_of_identities: totalCount,
      ...additionalProperties,
    }}
  >
    {children}
  </ConditionalTrackedRoute>
)

const mapStateToProps = (state: IApplicationState, { groupId }: IProps) => ({
  currentView: selectBrandFansCurrentView(state),
  groupName: selectBrandFanGroupName(state, groupId),
  totalCount: selectBrandFanGroupMemberCount(state, groupId),
})

const connector = connect(mapStateToProps)
export default compose<IInternalProps, PropsWithChildren<IProps>>(connector)(
  BrandFansGroupRowTrackedRoute,
)
