import React from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import BrandFanInfluencerIdentityRow from '@components/BrandFans/InfluencerIdentity/Row'
import Loading from '@components/UI/Loading'
import { IApplicationState } from '@store'
import {
  fetchBrandFanGroupMembers,
  selectBrandFansGroupMembersNextLink,
  selectGroupMembersIdentityIds,
  selectIsFetchingIdentities,
} from '@store/brandFans'
import { Container } from '@tribegroup'
import { isEmpty } from '@utils'

interface IProps {
  groupId: number
}

interface IInternalProps extends IProps {
  hasMore: boolean
  isFetching: boolean
  memberIdentityIds: ReadonlyArray<number>
  fetchBrandFanGroupMembers: typeof fetchBrandFanGroupMembers
}

const BrandFansGroupMemberList: React.FC<IInternalProps> = ({
  memberIdentityIds,
  hasMore,
  isFetching,
  groupId,
  fetchBrandFanGroupMembers: fetchBrandFanGroupMembersAction,
}) => {
  React.useEffect(() => {
    fetchBrandFanGroupMembersAction(groupId)
  }, [])

  if (isFetching && isEmpty(memberIdentityIds)) {
    return <Loading height={30} />
  }

  const fetchNext = () => {
    const isFetchNext = true
    fetchBrandFanGroupMembersAction(groupId, isFetchNext)
  }

  return (
    <Container topOuterSpacing={0.75} bottomInnerSpacing={6.25}>
      <InfiniteScroll
        dataLength={memberIdentityIds.length}
        next={fetchNext}
        loader={<Loading height={30} />}
        hasMore={hasMore}
        scrollThreshold={0.8}
        style={{ overflow: 'hidden' }}
      >
        <div data-testid="brand-fans-group-members-identity-list">
          {memberIdentityIds.map((influencerIdentityId: number) => (
            <BrandFanInfluencerIdentityRow
              key={influencerIdentityId}
              identityId={influencerIdentityId}
            />
          ))}
        </div>
      </InfiniteScroll>
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { groupId }: IProps) => ({
  isFetching: selectIsFetchingIdentities(state),
  memberIdentityIds: selectGroupMembersIdentityIds(state, groupId),
  hasMore: Boolean(selectBrandFansGroupMembersNextLink(state, groupId)),
})

const mapDispatchToProps = {
  fetchBrandFanGroupMembers,
}

export { BrandFansGroupMemberList }
export default compose<IInternalProps, IProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(BrandFansGroupMemberList)
