import { useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'recompose'

import { PanelWrapper } from '@components/Builder/Layout/Layout.styled'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { BuilderDefault, CampaignStatuses } from '@enums'
import { withBuilderValidation, withDraft } from '@hocs'
import { IApplicationState } from '@store'
import { IMoodboardImage } from '@store/campaigns'
import {
  deleteMoodboardMedia,
  IMedia,
  IMoodboardItem,
  selectMoodboardMediaListByCampaignId,
  uploadMoodboardInvalidFile,
  uploadMoodboardMedia,
} from '@store/moodboard'
import Theme from '@theme'
import { FormInput, H4, Text, TextField } from '@tribegroup'
import {
  ErrorMessage,
  MoodboardHeader,
  MoodboardText,
  MoodboardUploadGridWrapper,
} from '../Moodboard/Moodboard.styled'
import MoodboardGrid from './Grid'
import MoodboardOverlay from './Overlay'

interface IInternalProps extends IBuilderValidationContextProps, IDraftContextProps {}

const BuilderBriefMoodboard: React.FC<IInternalProps> = ({
  draft,
  checkValidationResults,
  clearFieldValidation,
  updateForm,
}) => {
  const dispatch = useDispatch()
  const campaignId = draft.id

  const mediaList: IMoodboardItem[] = useSelector((state: IApplicationState) => {
    if (campaignId) {
      return selectMoodboardMediaListByCampaignId(state, campaignId)
    }
    return [...Array(6)]
  })

  useEffect(() => {
    updateForm({
      moodboard_images: mediaList
        .filter((media) => media && !media.errors)
        .map((media, index) => {
          if (media.moodboard) {
            return media.moodboard as IMoodboardImage
          }

          if (media.file instanceof File) {
            return {
              media_id: `${index}`,
              media_url: URL.createObjectURL(media.file),
              media_type: media.file?.type,
            }
          }

          return {}
        }),
    })
  }, [mediaList])

  const validMediaCount = mediaList.filter(
    (media) => media && !media.errors && !media.isDeleting && !media.isUploading,
  ).length

  const disableDelete =
    draft.status !== CampaignStatuses.Draft &&
    validMediaCount <= BuilderDefault.MIN_ARRAY_LENGTH_MOODBOARD

  const updateMediaList = (mediaFiles: ReadonlyArray<IMedia>) => {
    const emptySlotIndexes = mediaList.reduce(
      (acc, media, index) => (media && !media.errors ? acc : [...acc, index]),
      [],
    )
    const validMediaFiles = mediaFiles.filter((file: IMedia) => !file.errors)
    const filesToUpload =
      validMediaFiles.length > emptySlotIndexes.length
        ? validMediaFiles.slice(0, emptySlotIndexes.length)
        : validMediaFiles

    const uploadPositions = emptySlotIndexes.slice(0, filesToUpload.length)

    if (filesToUpload.length > 0 && campaignId) {
      dispatch(
        uploadMoodboardMedia(
          filesToUpload.map((file) => file.file as File),
          campaignId,
          uploadPositions,
        ),
      )
    }

    const remainingSlots = emptySlotIndexes.filter((slot) => !uploadPositions.includes(slot))
    const invalidMediaFiles = mediaFiles.filter((file: IMedia) => file.errors)
    if (campaignId) {
      invalidMediaFiles.forEach((media: IMedia, index) => {
        if (index > remainingSlots.length - 1) {
          return
        }
        dispatch(uploadMoodboardInvalidFile(campaignId, remainingSlots[index], media.errors!))
      })
    }

    clearFieldValidation('moodboard_size')
  }

  const removeMedia = (position: number) => {
    if (campaignId) {
      dispatch(deleteMoodboardMedia(campaignId, position))
    }
  }

  const showOverlay = mediaList.find(
    (media) => !(media?.file instanceof File) && media?.preSignedUrlItem?.status === 'uploaded',
  )

  return (
    <PanelWrapper
      data-testid="moodboard-v2-panel-wrapper"
      data-cy-element="moodboard-panel-wrapper"
    >
      <MoodboardHeader>
        <H4 color={Theme.grey900Color}>
          <FormattedMessage id="campaign.preview.moodBoard.header" />
        </H4>
      </MoodboardHeader>
      <MoodboardText>
        <Text color={Theme.grey900Color}>
          <FormattedMessage id="builder.brief.moodboard.subText" />
        </Text>
      </MoodboardText>
      <FormInput
        validationRule={{
          min: BuilderDefault.MIN_ARRAY_LENGTH_MOODBOARD,
        }}
      >
        <TextField name="moodboard_size" type="hidden" value={validMediaCount} readOnly />
      </FormInput>

      <MoodboardUploadGridWrapper>
        {showOverlay && campaignId && <MoodboardOverlay campaignId={campaignId} />}
        <MoodboardGrid
          mediaList={mediaList}
          updateMediaList={updateMediaList}
          removeMedia={removeMedia}
          disableDelete={disableDelete}
        />
      </MoodboardUploadGridWrapper>

      {checkValidationResults('moodboard_size') && (
        <ErrorMessage>
          <Text small color={Theme.errorColor}>
            <FormattedMessage id="builder.brief.moodboard.error.invalid-media-count" />
          </Text>
        </ErrorMessage>
      )}
    </PanelWrapper>
  )
}

export default compose<IInternalProps, {}>(withDraft, withBuilderValidation)(BuilderBriefMoodboard)
