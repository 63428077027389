import { useMemo } from 'react'

import hooks from '@hooks'
import { queryStringtoObject } from '@utils'

const useLocationSearch = <T = any>() => {
  const { search } = hooks.useLocation()
  return useMemo<T | any>(() => queryStringtoObject(search), [search])
}

export { useLocationSearch }
