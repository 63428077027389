import { Col, Grid, Row } from 'react-styled-flexboxgrid'

import { PaddedContainerNoSides } from '../PaddedContainer/PaddedContainer.styled'

export const SinglePageContainer = ({ children }) => {
  return (
    <PaddedContainerNoSides>
      <Grid>
        <Row center="xs">
          <Col xs={10} sm={8} md={10}>
            {children}
          </Col>
        </Row>
      </Grid>
    </PaddedContainerNoSides>
  )
}

export default SinglePageContainer
