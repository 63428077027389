import React from 'react'

import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

import { IScheme } from '../Interfaces/schemes'
import { getColor, themed } from '../Theme'

export interface IRadioProps
  extends IScheme,
    ILabelWrapperProps,
    React.FormHTMLAttributes<HTMLInputElement> {
  // tslint:disable-next-line:readonly-array
  value?: string | number | string[] | undefined
  label?: React.ReactNode
  subLine?: React.ReactNode
  showSelectedAsCheck?: boolean
  disablePointerOnHover?: boolean
  labelCapitalize?: boolean
  labelColor?: string
  alignRight?: boolean
  elementName?: string
  innerRef?: React.Ref<HTMLFormElement>
  disabled?: boolean
  checked?: boolean
  backgroundColor?: string
  clearBorder?: boolean
}

export interface ILabelWrapperProps {
  labelFontSize?: number
}

export const RadioStyled = themed(styled<{ size: number } & IScheme, any>('span')`
  ${({ theme, scheme, showSelectedAsCheck, backgroundColor, clearBorder }) => css`
    margin-right: 1rem;
    width: 2rem;
    flex-shrink: 0;
    height: 2rem;
    border-radius: 100rem;
    ${!clearBorder &&
    css`
      border: 1px solid ${theme.grey400Color};
    `};
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    box-sizing: border-box;

    ${backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};
    &:focus {
      box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 10%);
    }

    &::after {
      background: ${getColor(theme, scheme)};
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 100rem;
      display: block;
    }
    ${showSelectedAsCheck &&
    css`
      position: relative;

      &::before,
      &::after {
        background: ${getColor(theme, scheme)};
        position: absolute;
        border-radius: 2rem;
        width: 4px;
        display: block;
      }

      &::before {
        height: 0.5625rem;
        transform: rotate(-45deg);
        top: calc(50% - 0.1812rem);
        left: calc(50% - 0.35rem);
      }

      &::after {
        height: 0.9375rem;
        transform: rotate(45deg);
        position: absolute;
        top: calc(50% - 0.5rem);
        left: 50%;
      }
    `}
  `};
`)

export const RadioWrapperStyled = styled.label`
  cursor: pointer;
  ${({ disablePointerOnHover }) =>
    disablePointerOnHover &&
    css`
      cursor: default;
    `}
  position: relative;
  display: block;

  input {
    display: none;

    &:checked {
      & + span {
        &::before,
        &::after {
          content: ' ';
        }
      }
    }
  }
`

export const LabelWrapper = themed(styled<ILabelWrapperProps, any>('span')`
  ${({ labelCapitalize, labelFontSize, labelColor, theme }) => css`
    ${labelFontSize &&
    css`
      font-size: ${labelFontSize}rem;
    `};
    ${labelCapitalize &&
    css`
      text-transform: capitalize;
    `};
    color: ${labelColor || theme.grey900Color};
  `};
`)

export const FlexWrapper = themed(styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 0;

    ${({ alignRight }) =>
      alignRight &&
      css`
        justify-content: flex-end;
      `};
    input {
      &:checked {
        & ~ div > span > span {
          color: ${theme.grey900Color};
        }
      }
    }
  `};
`)

export const SelectionSubtext = themed(styled.div`
  ${({ theme }) => css`
    margin-left: 3rem;
    font-size: 0.75rem;
    line-height: 1.33;
    letter-spacing: 0.3px;
    color: ${theme.grey800Color};
    margin-bottom: 0.25rem;
  `};
`)

const SubLineWrapper = styled.div`
  margin-top: 0.125rem;
`

export default class Radio extends React.PureComponent<IRadioProps> {
  public render() {
    const {
      label,
      subLine,
      labelFontSize = 1,
      scheme,
      showSelectedAsCheck,
      disablePointerOnHover,
      labelCapitalize,
      alignRight,
      elementName,
      labelColor,
      backgroundColor,
      clearBorder,
      innerRef,
      ...otherProps
    } = this.props
    return (
      <RadioWrapperStyled disablePointerOnHover={disablePointerOnHover}>
        <FlexWrapper alignRight={alignRight}>
          <input
            {...otherProps}
            role="radio"
            type="radio"
            aria-checked={false}
            ref={innerRef as any}
          />
          <RadioStyled
            scheme={scheme}
            showSelectedAsCheck={showSelectedAsCheck}
            tabIndex={0}
            backgroundColor={backgroundColor}
            clearBorder={clearBorder}
            data-cy-element={elementName}
          />
          <Flex column>
            <LabelWrapper
              labelColor={labelColor}
              labelFontSize={labelFontSize}
              labelCapitalize={labelCapitalize}
            >
              {label}
            </LabelWrapper>
            {subLine && <SubLineWrapper>{subLine}</SubLineWrapper>}
          </Flex>
        </FlexWrapper>
      </RadioWrapperStyled>
    )
  }
}
