import { InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import ActionIcon from '@components/UI/ActionIcon'
import Theme from '@theme'

interface IActionBarIconSelection {
  selected?: boolean
  onToggle: VoidFunction
}

interface IInternalProps extends IActionBarIconSelection, InjectedIntlProps {}

export const ActionBarIconSelection = ({ selected, onToggle, intl }: IInternalProps) => {
  return selected ? (
    <ActionIcon
      elementName="action-bar-selection-selected"
      glyph="circle-minus-solid"
      color={Theme.primaryColor}
      size={1.5}
      onClick={onToggle}
      tooltip={intl.formatMessage({
        id: 'submission.card.action.removefromselection',
      })}
    />
  ) : (
    <ActionIcon
      elementName="action-bar-selection-unselected"
      glyph="circle-plus"
      color={Theme.primaryColor}
      size={1.5}
      onClick={onToggle}
      tooltip={intl.formatMessage({
        id: 'submission.card.action.addtoselection',
      })}
    />
  )
}

export default compose<IInternalProps, IActionBarIconSelection>(injectIntl)(ActionBarIconSelection)
