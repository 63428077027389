import React from 'react'

import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { FullDetailsWrapper } from '@components/UI/Full/Full'
import { FULL_DATE } from '@constants'
import { IApplicationState } from '@store'
import { ILicense, selectIsFetching } from '@store/licenses'
import {
  selectDimensions,
  selectFileType,
  selectPrintableDimensions,
  selectTransactedLicense,
} from '@store/mediaObjects'
import Theme from '@theme'
import { Header, Text } from '@tribegroup'
import { DetailsBlock } from './Content.styled'

interface IProps {
  mediaObjectId: number
}

interface IInternalProps extends IProps {
  license?: ILicense
  isFetchingLicense?: boolean
  fileType?: string
  dimensions?: string
  printableDimensions?: string
}

const SubmissionCardDetailsContent: React.FC<IInternalProps> = ({
  license,
  fileType,
  dimensions,
  printableDimensions,
  isFetchingLicense,
}: IInternalProps) => {
  if (isFetchingLicense && !license) {
    return null
  }

  return (
    <FullDetailsWrapper expandable={Boolean(license)}>
      {license && (
        <React.Fragment>
          <DetailsBlock>
            <Header small color={Theme.grey700Color} bottomOuterSpacing={0.125} uppercase>
              {license.label}
            </Header>
            <Text color={Theme.grey900Color}>{license.description}</Text>
          </DetailsBlock>
          {license.purchased_at && (
            <DetailsBlock>
              <Header small color={Theme.grey700Color} bottomOuterSpacing={0.125} uppercase>
                <FormattedMessage id="contentSubmission.card.details.purchased" />
              </Header>
              <Text color={Theme.grey900Color}>
                {format(new Date(license.purchased_at), FULL_DATE)}
              </Text>
            </DetailsBlock>
          )}
          {license.expiry_date && (
            <DetailsBlock>
              <Header small color={Theme.grey700Color} bottomOuterSpacing={0.125} uppercase>
                <FormattedMessage id="contentSubmission.card.details.expires" />
              </Header>
              <Text color={Theme.grey900Color}>
                {format(new Date(license.expiry_date), FULL_DATE)}
              </Text>
            </DetailsBlock>
          )}
          {license.receipt_number && (
            <DetailsBlock>
              <Header small color={Theme.grey700Color} bottomOuterSpacing={0.125} uppercase>
                <FormattedMessage id="contentSubmission.card.details.receiptNumber" />
              </Header>
              <Text color={Theme.grey900Color}>
                {Boolean(license.receipt_number) && license.receipt_number}
              </Text>
            </DetailsBlock>
          )}
        </React.Fragment>
      )}
      <DetailsBlock>
        <Header small color={Theme.grey700Color} bottomOuterSpacing={0.125} uppercase>
          <FormattedMessage id="contentSubmission.card.details.dimensions" />
        </Header>
        <Text color={Theme.grey900Color}>
          {dimensions}
          <br />
          {printableDimensions}
        </Text>
      </DetailsBlock>
      <DetailsBlock>
        <Header small color={Theme.grey700Color} bottomOuterSpacing={0.125} uppercase>
          <FormattedMessage id="contentSubmission.card.details.fileType" />
        </Header>
        <Text color={Theme.grey900Color}>{fileType}</Text>
      </DetailsBlock>
    </FullDetailsWrapper>
  )
}

const mapStateToProps = (state: IApplicationState, { mediaObjectId }: IProps) => ({
  isFetchingLicense: selectIsFetching(state),
  license: selectTransactedLicense(state, mediaObjectId),
  fileType: selectFileType(state, mediaObjectId),
  dimensions: selectDimensions(state, mediaObjectId),
  printableDimensions: selectPrintableDimensions(state, mediaObjectId),
})

export { SubmissionCardDetailsContent }
export default connect(mapStateToProps)(SubmissionCardDetailsContent)
