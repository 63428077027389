import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'

const UploadToFacebookAdsManagerUploaded = () => (
  <Flex alignCenter>
    <Container rightOuterSpacing={0.625}>
      <Icon glyph="approve-submission" color={Theme.successColor} size={1.5} />
    </Container>
    <Text small color={Theme.grey800Color}>
      <FormattedMessage id="socialSubmission.card.modal.facebookAds.accounts.uploadedStatus" />
    </Text>
  </Flex>
)

export { UploadToFacebookAdsManagerUploaded }
export default UploadToFacebookAdsManagerUploaded
