import { WatcherFunction } from '..'
import { campaignMetricsSagas } from './campaignMetrics'
import { watchDownloadPostCampaignReportRequest } from './download'
import { postMetricsSagas } from './postMetrics'
import { reelMetricsSagas } from './reelMetrics'
import { storyMetricsSagas } from './storyMetrics'
import { tiktokMetricsSagas } from './tiktokMetrics'
import { videoMetricsSagas } from './videoMetrics'

export const postCampaignReportSagas: ReadonlyArray<WatcherFunction> = [
  ...campaignMetricsSagas,
  ...postMetricsSagas,
  ...videoMetricsSagas,
  ...storyMetricsSagas,
  ...tiktokMetricsSagas,
  ...reelMetricsSagas,
  watchDownloadPostCampaignReportRequest,
]
