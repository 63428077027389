import React, { PropsWithChildren } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'

import SummaryNavigation, { ISummaryNavigationProps } from '@components/UI/SummaryNavigation'
import { ICampaignContextProps } from '@context/Campaign'
import { IInfluencerIdentityIdContextProps } from '@context/InfluencerIdentityId'
import { ISubmissionContextProps } from '@context/Submission'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import { EventTrackingNames } from '@enums'
import {
  withCampaignNoRenderBlock,
  withSubmissionListFilter,
  withSubmissionNoRenderBlock,
} from '@hocs'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { selectSubmissionsFilters } from '@store/campaigns'
import { selectLocationsearch } from '@store/router'
import {
  fetchSubmissions,
  selectFromSubmissionId,
  selectIsFetching,
  selectLoadMoreSubmissions,
  selectNextSubmissionId,
  selectPrevSubmissionId,
} from '@store/submissions'
import { buildSubmissionUrlById } from '@utils/urls'

export interface IInternalProps extends ISummaryNavigationProps {
  fetchSubmissions: typeof fetchSubmissions
  loadMoreSubmissions?: boolean
  submissionFilters?: ReadonlyArray<string>
  campaignId: number
  sortBy: string
  filter: string
  prevSubmissionId: number
  nextSubmissionId: number
}

export const SubmissionSummaryNavigationByFilter: React.FC<PropsWithChildren<IInternalProps>> = ({
  loadMoreSubmissions,
  prevLink,
  nextLink,
  fetchSubmissions: fetchSubmissionsAction,
  campaignId,
  loadingMore,
  currentId,
  sortBy,
  filter,
  children,
  nextSubmissionId,
  prevSubmissionId,
}) => {
  hooks.useActionDispatcherEffect(
    Boolean(loadMoreSubmissions),
    fetchSubmissionsAction,
    [campaignId, { filter, sortBy }, true],
    [loadMoreSubmissions],
  )

  return (
    <SummaryNavigation
      prevLink={prevLink}
      nextLink={nextLink}
      currentId={currentId}
      nextTrackingProps={{
        campaignId,
        eventName: EventTrackingNames.ViewSubmission,
        submissionId: nextSubmissionId,
      }}
      prevTrackingProps={{
        campaignId,
        eventName: EventTrackingNames.ViewSubmission,
        submissionId: prevSubmissionId,
      }}
      loadingMore={loadingMore}
    >
      {children}
    </SummaryNavigation>
  )
}

interface IStateProps
  extends RouteComponentProps,
    IInfluencerIdentityIdContextProps,
    ISubmissionListFilterContextProps,
    ICampaignContextProps,
    ISubmissionContextProps {}

const mapStateToProps = (
  state: IApplicationState,
  { submission, filter, campaign, sortBy }: IStateProps,
) => {
  const campaignId = campaign.id
  const searchParams = selectLocationsearch(state)
  const fromSubmissionId = selectFromSubmissionId(searchParams, submission.id)
  const nextSubmissionId = selectNextSubmissionId(state, campaignId, filter, fromSubmissionId)
  const prevSubmissionId = selectPrevSubmissionId(state, campaignId, filter, fromSubmissionId)
  return {
    campaignId,
    filter,
    sortBy,
    currentId: submission.id,
    loadingMore: selectIsFetching(state) && Boolean(nextSubmissionId),
    loadMoreSubmissions: selectLoadMoreSubmissions(state, campaignId, filter, fromSubmissionId),
    submissionFilters: selectSubmissionsFilters(state, campaignId),
    prevSubmissionId,
    nextSubmissionId,
    prevLink: buildSubmissionUrlById(campaignId, prevSubmissionId),
    nextLink: buildSubmissionUrlById(campaignId, nextSubmissionId),
  }
}

const mapDispatchToProps = {
  fetchSubmissions,
}

export default compose<IInternalProps, {}>(
  withCampaignNoRenderBlock,
  withSubmissionListFilter,
  withSubmissionNoRenderBlock,
  connect(mapStateToProps, mapDispatchToProps),
)(SubmissionSummaryNavigationByFilter)
