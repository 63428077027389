import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { withBuilderValidation, withDraft } from '@hocs'
import { useGender } from '@hooks/useGender'
import Theme from '@theme'
import { Container, FormInput, MultiSelectButtonGroup } from '@tribegroup'
import { BudgetErrorMessage } from '../../Error/Message'
import BuilderBudgetTargetPanelTitle from '../PanelTitle'
import { CREATORS_HEADER_I18N_PREFIX } from '../Target'

const DEMOGRAPHIC_GENDER_KEY = 'demographic_gender'

interface IInternalProps
  extends IBuilderValidationContextProps,
    IDraftContextProps,
    InjectedIntlProps {}

const BuilderBudgetTargetDemographicGender: React.FC<IInternalProps> = ({
  draft,
  intl,
  clearFieldValidation,
  validationResults,
  updateForm,
}) => {
  const { genderOptions } = useGender()

  const gender = draft.demographic_gender || []

  const onGenderChanged = (options) => {
    clearFieldValidation(DEMOGRAPHIC_GENDER_KEY)
    updateForm({
      gender: options,
      [DEMOGRAPHIC_GENDER_KEY]: options,
    })
  }

  return (
    <Container
      topOuterSpacing={2.5}
      bottomOuterSpacing={1.5}
      data-testid="builder-budget-target-demographic-gender"
    >
      <BuilderBudgetTargetPanelTitle>
        <FormattedMessage id={`${CREATORS_HEADER_I18N_PREFIX}.gender`} />
      </BuilderBudgetTargetPanelTitle>
      <FormInput
        validationRule={{
          required: {
            value: true,
            message: intl.formatMessage({
              id: 'builder.budget.error.gender.required',
            }),
          },
        }}
      >
        <Container topOuterSpacing={1}>
          <MultiSelectButtonGroup
            name={DEMOGRAPHIC_GENDER_KEY}
            options={genderOptions}
            allOption={<FormattedMessage id="core.text.all" />}
            selectedValues={gender}
            onSelectedValuesChange={onGenderChanged}
            activeBackgroundColor={Theme.primary200Color}
          />
        </Container>
      </FormInput>
      <BudgetErrorMessage results={validationResults} errorKey={DEMOGRAPHIC_GENDER_KEY} />
    </Container>
  )
}

export default compose<IInternalProps, {}>(
  withBuilderValidation,
  withDraft,
  injectIntl,
)(BuilderBudgetTargetDemographicGender)
