import { connect } from 'react-redux'
import { compose } from 'recompose'

import BrandFansFilters from '@components/BrandFans/Filters/Filters'
import CampaignFilters from '@components/Campaign/Filters/Filters'
import ContentLibraryFilters from '@components/ContentLibrary/Filters/Filters'
import SideBar from '@components/UI/SideBar'
import { withScrollToTop } from '@hocs'
import { IApplicationState } from '@store'
import {
  selectBrandFanGroupsLink,
  selectContentLibraryEnabled,
  selectInfluencerIdentitiesLink,
} from '@store/me'

interface IInternalProps {
  showContentLibrary: boolean
  showBrandFans: boolean
}

export const HomeSideBar = ({ showContentLibrary, showBrandFans }) => (
  <SideBar>
    <CampaignFilters />
    {showContentLibrary && <ContentLibraryFilters />}
    {showBrandFans && <BrandFansFilters />}
  </SideBar>
)

const mapStateToProps = (state: IApplicationState) => {
  const hasInfluencerIdentitiesLink = Boolean(selectInfluencerIdentitiesLink(state))
  const hasBrandFanGroupsLink = Boolean(selectBrandFanGroupsLink(state))
  return {
    showContentLibrary: selectContentLibraryEnabled(state),
    showBrandFans: hasInfluencerIdentitiesLink || hasBrandFanGroupsLink,
  }
}

export default compose<IInternalProps, {}>(withScrollToTop, connect(mapStateToProps))(HomeSideBar)
