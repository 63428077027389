/* eslint-disable react/display-name */
import { Component } from 'react'

export const withScrollToTop = (WrappedComponent) => {
  return class extends Component {
    componentDidMount() {
      window.scrollTo(0, 0)
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}
