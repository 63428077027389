import { WatcherFunction } from '..'
import { watchAddMemberRequest } from './add'
import { watchMembersRequest } from './members'
import { watchRemoveMemberRequest } from './remove'

export const membersSagas: ReadonlyArray<WatcherFunction> = [
  watchMembersRequest,
  watchAddMemberRequest,
  watchRemoveMemberRequest,
]
