import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container } from '@tribegroup'

export const AnalyticsPostPerformanceIconWrapper = styled(Flex)`
  margin-top: 0.875rem;
  margin-bottom: 1.875rem;
  height: 4.5rem;
  width: 4.5rem;
  overflow: hidden;
  margin-right: 1rem;
  border: double 1px ${Theme.primaryColor};
  border-radius: 80px;
`

export const Wrapper = styled.div`
  border-top: solid 1px ${Theme.grey400Color};
  margin-top: 2.5rem;
  padding-top: 1.25rem;
  margin-bottom: 4rem;
`

export const AnalyticsPostPerformanceGrid = styled(Container)`
  display: block;
  ${breakpoint('tabletPortrait')`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem;
  `};
`
