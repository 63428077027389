import { format } from 'date-fns'
import { InjectedIntlProps, injectIntl } from 'react-intl'

import { FULL_DATE } from '@constants'
import { IOption } from '@store/rootResource'
import { Select } from '@tribegroup'

interface IPublishingOptionsProps {
  // tslint:disable-next-line:readonly-array
  options: any[]
  placeholder: string
  selectedDate?: Date
  hasChosenDateOption: boolean
  onChangeOption: (option: IOption) => void
}

interface IInternalProps extends IPublishingOptionsProps, InjectedIntlProps {}

export const PublishingOptions = ({
  hasChosenDateOption,
  selectedDate,
  placeholder,
  options,
  onChangeOption,
  intl,
}: IInternalProps) => {
  const hasDate = hasChosenDateOption && Boolean(selectedDate)
  const label = hasDate
    ? intl.formatMessage(
        { id: 'socialSubmission.card.modal.approve.onDate' },
        { date: format(selectedDate!, FULL_DATE) },
      )
    : undefined

  return (
    <Select
      scheme="primary"
      quiet
      large
      underlined
      label={label}
      rsConfig={{
        isSearchable: false,
        placeholder,
        options,
        onChange: onChangeOption,
        name: 'publishing-options',
        inputId: 'publishing-options',
      }}
    />
  )
}

export default injectIntl(PublishingOptions)
