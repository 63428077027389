import { call, put, select, takeEvery } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { IHateoasLink } from '@store'
import { selectCampaignTrackingProps, trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  fetchCampaignSuccess,
  ICampaign,
  selectUpdatePreferencesLink,
  updatePreferences,
  UpdatePreferencesActionTypes,
  updatePreferencesError,
} from '@store/campaigns'
import { networkRequest } from '@utils'

export function* handleUpdatePreferences(action: ReturnType<typeof updatePreferences>) {
  try {
    const { campaignId, preferences } = action.payload
    const authToken: string = yield select(selectAuthToken)
    const updatePreferencesLink: IHateoasLink = yield select(
      selectUpdatePreferencesLink,
      campaignId,
    )

    const campaign: ICampaign = yield call(
      networkRequest,
      updatePreferencesLink.method,
      updatePreferencesLink.href,
      preferences,
      authToken,
    )

    yield put(fetchCampaignSuccess(campaign))

    if (preferences.require_approval_selected_option !== undefined) {
      const campaignTrackingProps = yield select(selectCampaignTrackingProps, campaignId)
      yield put(
        trackEvent(EventTrackingNames.SubmitRequireApprovalsModal, {
          ...campaignTrackingProps,
          have_turned_off_response: preferences.require_approval_selected_option ? 'yes' : 'no',
        }),
      )
    }
  } catch (error) {
    yield put(updatePreferencesError(error))
  }
}

export function* watchUpdatePreferences() {
  yield takeEvery(UpdatePreferencesActionTypes.UPDATE_REQUEST, handleUpdatePreferences)
}
