import { addLocaleData } from 'react-intl'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const en = require('react-intl/locale-data/en')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const zh = require('react-intl/locale-data/zh')

const localeData: ReadonlyArray<any> = [en, zh]

const loadLocaleData = () => {
  localeData.forEach(locale => addLocaleData(locale))
}

export default loadLocaleData
