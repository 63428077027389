import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IModalComponentProps, IModalContextProps } from '@context/Modal'
import { withModal } from '@hocs'
import { IApplicationState } from '@store'
import { clearUIFlag, selectUIFlag, UIFlag } from '@store/ui'

interface IProps<T = IModalComponentProps> {
  uiFlag: UIFlag
  modalComponent: React.ComponentClass<T> | React.FC<T>
  modalComponentProps?: any
}

interface IInternalProps extends IModalContextProps, IProps {
  isActive: boolean
  clearUIFlag: typeof clearUIFlag
}

const UIFlagWatcherModal: React.FC<IInternalProps> = ({
  isActive,
  showModal,
  clearUIFlag: clearUIFlagAction,
  uiFlag,
  modalComponent,
  modalComponentProps,
}) => {
  React.useEffect(() => {
    if (isActive) {
      showModal(modalComponent, modalComponentProps)
      clearUIFlagAction(uiFlag)
    }
  }, [isActive])
  return null
}

const mapStateToProps = (state: IApplicationState, { uiFlag }: IProps) => ({
  isActive: selectUIFlag(state, uiFlag),
})

const mapDispathToProps = {
  clearUIFlag,
}

export { UIFlagWatcherModal }
export default compose<IInternalProps, IProps>(
  withModal,
  connect(mapStateToProps, mapDispathToProps),
)(UIFlagWatcherModal)
