import { call, put, select, takeLatest } from 'redux-saga/effects'

import { SHAREABLE_CONTENT_LIBRARY } from '@constants'
import { IHateoasLink, IListResponse } from '@store'
import {
  fetchMediaObjectsError,
  fetchMediaObjectsSuccess,
  fetchShareableContentLibrary,
  FetchShareableContentLibraryActionTypes,
  selectFetchMediaObjectsLink,
} from '@store/mediaObjects'
import { selectShareableContentLibraryLink } from '@store/rootResource'
import { networkRequest } from '@utils'

export function* handleFetchShareableContentLibraryRequest(
  action: ReturnType<typeof fetchShareableContentLibrary>,
) {
  try {
    const { token, isFetchNext } = action.payload

    const shareableContentLibraryLink: IHateoasLink = yield select(
      selectFetchMediaObjectsLink,
      SHAREABLE_CONTENT_LIBRARY,
      isFetchNext,
      selectShareableContentLibraryLink,
    )

    const { items, links, metadata }: IListResponse = yield call(
      networkRequest,
      shareableContentLibraryLink.method,
      shareableContentLibraryLink.href.replace('{token}', token),
    )

    yield put(fetchMediaObjectsSuccess(items, links, metadata, SHAREABLE_CONTENT_LIBRARY))
  } catch (error) {
    yield put(fetchMediaObjectsError(error))
  }
}

export function* watchFetchShareableContentLibraryRequest() {
  yield takeLatest(
    FetchShareableContentLibraryActionTypes.FETCH_REQUEST,
    handleFetchShareableContentLibraryRequest,
  )
}
