import { ICampaign } from '@store/campaigns'
import { BillingFullReportActionTypes } from '../types'

export const downloadFullReportRequest = (campaign: ICampaign) => ({
  payload: campaign,
  type: BillingFullReportActionTypes.DOWNLOAD_FULL_REPORT_REQUEST,
})

export const downloadFullReportError = (error: any) => ({
  payload: error,
  type: BillingFullReportActionTypes.DOWNLOAD_FULL_REPORT_ERROR,
})
