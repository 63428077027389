import { Fragment, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import ActionIcon from '@components/UI/ActionIcon'
import { HIDDEN_CREDIT_CARD_PREFIX } from '@constants'
import { IApplicationState } from '@store'
import {
  selectCampaignCountUsingCreditCard,
  selectCreditCardExpiryMonth,
  selectCreditCardExpiryYear,
  selectCreditCardLastFourDigits,
  selectCreditCardLink,
  selectHasCreditCardInfo,
} from '@store/me'
import Theme from '@theme'
import { H5, Panel, Text } from '@tribegroup'
import { padNumberTwoDigits } from '@utils'
import { Label, List, ListItem } from './../Account.styled'
import { AccountPaymentConfirm } from './Confirm'

interface IAccountPaymentProps {
  disabled?: boolean
}

interface IInternalProps extends IAccountPaymentProps, RouteComponentProps {
  hasCreditCardInfo: boolean
  hasCreditCardApiLink: boolean
  lastFourDigits?: string
  expiryYear?: number
  expiryMonth?: number
  campaignCount?: number
}

export const AccountPayment = ({
  history,
  hasCreditCardApiLink,
  hasCreditCardInfo,
  disabled,
  lastFourDigits,
  expiryYear,
  expiryMonth,
  campaignCount,
}: IInternalProps) => {
  const [displayConfirm, setDisplayConfirm] = useState<boolean>(false)

  const hideConfirm = () => setDisplayConfirm(false)
  const showConfirm = () => setDisplayConfirm(true)

  const onRedirect = () => {
    history.push('/account/payment')
  }

  const onClick = campaignCount ? showConfirm : onRedirect

  return (
    <Fragment>
      <AccountPaymentConfirm
        campaignCount={campaignCount}
        onConfirm={onRedirect}
        onCancel={hideConfirm}
        isOpen={displayConfirm}
      />
      <Panel
        disabled={disabled}
        actionIcons={
          hasCreditCardApiLink
            ? [
                <ActionIcon
                  key="pencil"
                  glyph="edit"
                  onClick={onClick}
                  data-cy-element="edit-payment-details"
                />,
              ]
            : undefined
        }
        title={
          <H5 color={Theme.grey900Color}>
            <FormattedMessage id="account.payment.title" />
          </H5>
        }
      >
        <List>
          <ListItem>
            <Label>
              <Text xsmall uppercase color={Theme.grey800Color}>
                <FormattedMessage id="account.payment.creditCardNumber" />
              </Text>
            </Label>
            {hasCreditCardInfo ? (
              <Text
                color={Theme.grey900Color}
              >{`${HIDDEN_CREDIT_CARD_PREFIX} ${lastFourDigits}`}</Text>
            ) : (
              <Text color={Theme.grey700Color}>
                <FormattedMessage id="account.payment.creditCardNumber.placeholder" />
              </Text>
            )}
          </ListItem>
          <ListItem>
            <Label>
              <Text xsmall uppercase color={Theme.grey800Color}>
                <FormattedMessage id="account.payment.expiryDate" />
              </Text>
            </Label>
            {hasCreditCardInfo && (
              <Text color={Theme.grey900Color}>
                {`${padNumberTwoDigits(expiryMonth!)} / ${expiryYear}`}
              </Text>
            )}
          </ListItem>
        </List>
      </Panel>
    </Fragment>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  hasCreditCardInfo: selectHasCreditCardInfo(state),
  lastFourDigits: selectCreditCardLastFourDigits(state),
  expiryYear: selectCreditCardExpiryYear(state),
  expiryMonth: selectCreditCardExpiryMonth(state),
  campaignCount: selectCampaignCountUsingCreditCard(state),
  hasCreditCardApiLink: Boolean(selectCreditCardLink(state)),
})

export default compose<IInternalProps, IAccountPaymentProps>(
  withRouter,
  connect(mapStateToProps),
)(AccountPayment)
