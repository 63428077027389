import { shutdown } from '@intercom/messenger-js-sdk'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { DEFAULT_TENANT } from '@constants'
import { AnalyticsIdentifyActionTypes, identifyError, identifyRequest } from '@store/analytics'
import { AuthActionTypes } from '@store/auth'
import { IMe } from '@store/me'
import { LocationChangeAction } from '@store/router/actions'
import { LOCATION_CHANGE } from '@store/router/types'
import { isAdmin } from '@utils'

export function* handleShutdown(
  action: ReturnType<typeof identifyRequest | typeof LocationChangeAction>,
) {
  try {
    const isLocationChangeType = action.type === LOCATION_CHANGE
    const meParam = isLocationChangeType ? {} : action.payload
    const isAdminUser = isAdmin(meParam as IMe)

    const isNonDefaultTenant = process.env.APP_NAME !== DEFAULT_TENANT
    const shutdownIntercom = isAdminUser || isNonDefaultTenant

    if (shutdownIntercom) {
      yield call((window as any).Intercom, 'shutdown')
    }
  } catch (error) {
    yield put(identifyError(error))
  }
}

export function* handleIntercomShutdown() {
  if (window.Intercom) {
    yield call(shutdown)
    delete window.intercomSettings
  }
}

export function* watchIntercomShutdown() {
  yield takeEvery([AnalyticsIdentifyActionTypes.IDENTIFY_REQUEST, LOCATION_CHANGE], handleShutdown)
  yield takeLatest(AuthActionTypes.SIGNOUT_REQUEST, handleIntercomShutdown)
}
