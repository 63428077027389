import React from 'react'

import SubmissionCardActionBar from '@components/Submission/Card/ActionBar'
import SubmissionCardHeaderCPE from '@components/Submission/Card/Header/HeaderCPE'
import SubmissionLinkToDetails from '@components/Submission/Card/LinkToDetails/LinkToDetails'
import SubmissionCardMoreMenu from '@components/Submission/Card/MoreMenu'
import SubmissionCardPriceSummary from '@components/Submission/Card/PriceSummary'
import SubmissionCardThumbnail from '@components/Submission/Card/Thumbnail/Thumbnail'
import { CardContainer } from '@components/UI/Card/Card'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { IMediaObject } from '@store/mediaObjects'
import { selectActiveSubmissionMediaObject } from '@store/submissions'
import { Container } from '@tribegroup'

export interface IInfluencerIdentityRowSubmissionCardProps {
  campaignId: number
  submissionId: number
  isSocial: boolean
}

const InfluencerIdentityRowSubmissionCard: React.FC<IInfluencerIdentityRowSubmissionCardProps> = ({
  campaignId,
  submissionId,
  isSocial,
}) => {
  const { toggleIsVisible, toggleHide, toggleShow, toggle } = hooks.useToggle()
  const { id: mediaObjectId } = hooks.useSelector<IMediaObject>((state: IApplicationState) =>
    selectActiveSubmissionMediaObject(state, submissionId),
  )

  return (
    <CardContainer
      elementName="submission-card"
      outerContent={
        isSocial ? (
          <SubmissionCardMoreMenu
            submissionId={submissionId}
            showCostBreakdown={toggleShow}
            topPosition={3.1}
            rightPosition={1}
          />
        ) : undefined
      }
    >
      <SubmissionCardHeaderCPE submissionId={submissionId} toggleCostBreakdown={toggle} />
      <SubmissionLinkToDetails submissionId={submissionId}>
        <SubmissionCardThumbnail submissionId={submissionId} />
      </SubmissionLinkToDetails>
      {toggleIsVisible && (
        <Container absolute leftPosition={0} topPosition={2.75} fullWidth>
          <SubmissionCardPriceSummary
            mediaObjectId={mediaObjectId}
            campaignId={campaignId}
            submissionId={submissionId}
            onHide={toggleHide}
          />
        </Container>
      )}
      <SubmissionCardActionBar submissionId={submissionId} />
    </CardContainer>
  )
}

export { InfluencerIdentityRowSubmissionCard }
export default InfluencerIdentityRowSubmissionCard
