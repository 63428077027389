import { EMPTY_LIST } from '@constants'
import { IApplicationState } from '@store'

export const selectNotesLink = (state: IApplicationState, submissionId: number, rel: string) => {
  const notesLinks = state.notes.notesLinksBySubmissionId[submissionId]
  return notesLinks && notesLinks.find(link => link.rel === rel)
}

export const selectNotes = (state: IApplicationState, submissionId: number) =>
  state.notes.notesBySubmissionId[submissionId] || EMPTY_LIST

export const selectAddNoteLink = (state: IApplicationState, submissionId: number) =>
  selectNotesLink(state, submissionId, 'add_note')

export const selectMarkAsReadLink = (state: IApplicationState, submissionId: number) =>
  selectNotesLink(state, submissionId, 'mark_as_read')
