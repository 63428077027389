import { InjectedIntlProps, injectIntl } from 'react-intl'

import { Tip } from '@tribegroup'

interface IInfluencerIdentityOutsideZoneHintProps {
  influencerName: string
  influencerCountry: string
}

interface IInternalProps extends IInfluencerIdentityOutsideZoneHintProps, InjectedIntlProps {}

export const InfluencerIdentityOutsideZoneHint = ({
  influencerName,
  influencerCountry,
  intl,
}: IInternalProps) => (
  <Tip
    text={intl.formatMessage(
      {
        id: 'socialSubmission.card.modal.approve.influencerDifferentTimezones',
      },
      {
        influencerName,
        influencerCountry,
      },
    )}
  />
)

export default injectIntl(InfluencerIdentityOutsideZoneHint)
