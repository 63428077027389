import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { Link as RouterLink, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { TrackedRoute } from '@components/UI/TrackedRoute'
import { HideMobileMenu } from '@context/MobileMenuControls'
import { EventTrackingNames, PageRoutes } from '@enums'
import { useTenantEnabledFeatures } from '@hooks/useTenantEnabledFeatures'
import { IApplicationState } from '@store'
import {
  selectBrandManagerIdentitiesLink,
  selectCampaignsBillingHistoryLink,
  selectFirstName,
} from '@store/me'
import Theme from '@theme'
import { DropdownMenu, Link, MenuItem } from '@tribegroup'
import SideBarProfileMenuTrigger from './Trigger'

interface IInternalProps extends ConnectedProps<typeof connector>, RouteComponentProps {}

const SideBarProfileMenu: React.FC<IInternalProps> = ({
  firstName,
  hasBillingHistoryLink,
  hasBrandManagerIdentitiesLink,
  location,
}) => {
  const fromRoute = `?from=${encodeURIComponent(location.pathname + location.search)}`
  const { isEnabledTenantFeature } = useTenantEnabledFeatures()

  return (
    <DropdownMenu
      left={0}
      bottom={2}
      width={11.75}
      target={<SideBarProfileMenuTrigger firstName={firstName} />}
    >
      <MenuItem key="menu-item-account" elementName="menu-item-account">
        <HideMobileMenu fullWidth>
          <TrackedRoute eventName={EventTrackingNames.ViewAccountsPage} properties={{}}>
            <RouterLink to={`/${PageRoutes.Account}${fromRoute}`}>
              <FormattedMessage id="core.text.myAccount" />
            </RouterLink>
          </TrackedRoute>
        </HideMobileMenu>
      </MenuItem>
      {hasBrandManagerIdentitiesLink && (
        <MenuItem key="menu-item-connected-accounts" elementName="menu-item-connected-accounts">
          <HideMobileMenu fullWidth>
            <RouterLink to={`/${PageRoutes.ConnectedAccounts}${fromRoute}`}>
              <FormattedMessage id="core.text.connectedAccounts" />
            </RouterLink>
          </HideMobileMenu>
        </MenuItem>
      )}
      {hasBillingHistoryLink && (
        <MenuItem key="menu-item-billing" elementName="menu-item-billing">
          <HideMobileMenu fullWidth>
            <TrackedRoute eventName={EventTrackingNames.ViewBillingPage} properties={{}}>
              <RouterLink to={`/${PageRoutes.BillingCampaigns}${fromRoute}`}>
                <FormattedMessage id="core.text.billing" />
              </RouterLink>
            </TrackedRoute>
          </HideMobileMenu>
        </MenuItem>
      )}
      {isEnabledTenantFeature('faq') && (
        <MenuItem key="menu-item-faq" elementName="menu-item-faq">
          <Link href="https://www.tribegroup.co/faq" target="_blank" color={Theme.grey900Color}>
            <FormattedMessage id="core.text.faqs" />
          </Link>
        </MenuItem>
      )}
      {isEnabledTenantFeature('help_center') && (
        <MenuItem key="menu-item-helpCenter" elementName="menu-item-helpCenter">
          <Link href="https://help.tribegroup.co/brands" target="_blank" color={Theme.grey900Color}>
            <FormattedMessage id="core.text.helpCenter" />
          </Link>
        </MenuItem>
      )}
      <MenuItem destructive elementName="menu-item-signout">
        <RouterLink
          to={`/${PageRoutes.SignOut}`}
          key="menu-item-signout"
          data-cy-element="sign-out-link"
        >
          <FormattedMessage id="core.text.signOut" />
        </RouterLink>
      </MenuItem>
    </DropdownMenu>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  hasBrandManagerIdentitiesLink: Boolean(selectBrandManagerIdentitiesLink(state)),
  hasBillingHistoryLink: Boolean(selectCampaignsBillingHistoryLink(state)),
  firstName: selectFirstName(state),
})

const connector = connect(mapStateToProps)

export default compose(connector, withRouter)(SideBarProfileMenu)
