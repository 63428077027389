import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

import { TRIBE_PREAPPROVAL_BRANDS_LEARN_MORE } from '@constants'
import { SubmissionStatuses } from '@enums'
import { ApprovalType } from '@store/campaigns'
import { ISubmission } from '@store/submissions'
import Theme from '@theme'
import { Container, H1, Link, Text } from '@tribegroup'

interface IProps {
  approvalType?: ApprovalType
  preapprovalPercentage?: number
  submission?: ISubmission
}

const translationKeyMap = {
  'pre-approval': {
    header: 'contentSubmission.card.modal.preapprove.license.header',
    paragraph1: 'contentSubmission.card.modal.preapprove.license.paragraph1',
    paragraph2: 'contentSubmission.card.modal.preapprove.license.paragraph2',
  },

  'final-approval': {
    header: 'contentSubmission.card.modal.finalApprove.license.header',
    paragraph1: 'contentSubmission.card.modal.finalApprove.license.paragraph1',
    paragraph2: 'contentSubmission.card.modal.finalApprove.license.paragraph2',
  },
}

export const SubmissionCardModalLicenseHeader = ({
  approvalType,
  preapprovalPercentage,
  submission,
}: IProps) => {
  let translationKeys

  if (approvalType) {
    translationKeys = translationKeyMap[approvalType]
  }

  const showFinalApprovalSecondParagraph =
    approvalType === 'final-approval'
      ? Boolean(submission?.status === SubmissionStatuses.Pending && translationKeys?.paragraph2)
      : Boolean(translationKeys?.paragraph2)

  return (
    <>
      <H1 color={Theme.grey900Color}>
        <FormattedMessage id={translationKeys?.header} />
      </H1>
      <Container topOuterSpacing={0.75}>
        <Text color={Theme.grey900Color} light customFontSize={1.125}>
          <FormattedHTMLMessage
            id={translationKeys?.paragraph1}
            values={{ preapprovalPercentage }}
          />
        </Text>
      </Container>

      {showFinalApprovalSecondParagraph && (
        <Container topOuterSpacing={0.75}>
          <Text color={Theme.grey900Color} light customFontSize={1.125}>
            <FormattedHTMLMessage
              id={translationKeys?.paragraph2}
              values={{ preapprovalPercentage }}
            />
          </Text>
        </Container>
      )}

      {approvalType === 'pre-approval' && (
        <Container topOuterSpacing={0.75}>
          <Link
            href={TRIBE_PREAPPROVAL_BRANDS_LEARN_MORE}
            target="_blank"
            color={Theme.primaryColor}
          >
            <Text
              customFontSize={0.8125}
              lineHeight={1}
              color={Theme.primaryColor}
              uppercase
              customletterSpacing="1.5px"
            >
              <FormattedMessage id="core.text.learnMore.withArrow" />
            </Text>
          </Link>
        </Container>
      )}
    </>
  )
}
