import styled from 'styled-components'

export default styled.div`
  display: flex;
  margin-right: 0.5rem;
  align-items: center;

  > header {
    margin-right: 1rem;
  }
`
