import React from 'react'

import AutosizeInput from 'react-input-autosize'

import {
  AutosizeInputWrapper,
  Backdrop,
  CharacterLimitErrorMessage,
  Highlight,
  Highlights,
} from './AutosizeTextfield.styled'

export interface IIAutosizeTextfieldProps {
  /**
   * Sets the defaultValue for the text field
   *
   * @default ""
   */
  defaultValue?: string

  /**
   * Sets the value for the text field
   *
   * @default ""
   */
  value?: string

  /**
   * Indicates that this field has invalid value
   *
   * @default false
   */
  invalid?: boolean

  /**
   * Shows textbox as disabled element
   */
  disabled?: boolean

  /**
   * HTML ID for the input element
   *
   * @default undefined
   */
  id?: string

  /**
   * HTML name for the input element
   *
   * @default undefined
   */
  name?: string

  /**
   * characters allowed before showing error message
   *
   * @default undefined
   */
  characterLimit?: number

  /**
   * max input length before not accepting any more characters for input
   *
   * @default undefined
   */
  maxLength?: number

  /**
   * Locale id for error message when character limit is reached
   *
   * @default undefined
   */
  characterLimitErrorMessage?: React.ReactNode

  /**
   * Color of the textbox border when empty
   *
   * @default #4d4d4d
   */
  emptyBorderColor?: string

  /**
   * Color of the textbox border when not empty
   *
   * @default #4d4d4d
   */
  borderColor?: string

  /**
   * Sets the fontsize
   *
   * @default undefined
   */
  fontSize?: number

  /**
   * Sets the placeholder for the text field
   *
   * @default ""
   */
  placeholder?: string

  /**
   * Trims whitespace when element loses focus
   *
   * @default undefined
   */
  trimOnBlur?: boolean

  /**
   * callback function called on blur event
   *
   * @default undefined
   */
  onBlur?: VoidFunction

  /**
   * callback invoked when textbox value has been changed
   *
   * @default undefined
   */
  onChange?: (value: string) => void
}

export class AutosizeTextfield extends React.PureComponent<IIAutosizeTextfieldProps> {
  static defaultProps = {
    characterLimit: 40,
    maxLength: 50,
    fontSize: 1.5,
  }

  state = {
    value: this.props.defaultValue || '',
  }

  backdropRef = React.createRef()

  onChange = (event) => {
    const element = event.target as HTMLInputElement
    this.setState({ value: element.value })
  }

  componentDidUpdate() {
    if (this.props.onChange) {
      this.props.onChange(this.state.value)
    }
  }

  onBlur = (event) => {
    const element = event.target as HTMLInputElement
    this.setState({ value: element.value && element.value.trim() })
  }

  render() {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange,
      defaultValue,
      name,
      id,
      characterLimitErrorMessage,
      characterLimit,
      fontSize,
      maxLength,
      placeholder,
      disabled,
      invalid,
      borderColor,
      emptyBorderColor,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onBlur,
      ...otherProps
    } = this.props
    const overLimit = this.state.value.length > characterLimit!
    return (
      <AutosizeInputWrapper
        {...otherProps}
        fontSize={fontSize}
        invalid={overLimit || invalid}
        borderColor={defaultValue ? borderColor : emptyBorderColor}
      >
        <Backdrop ref={this.backdropRef} fontSize={fontSize}>
          <Highlights>
            {this.state.value.slice(0, characterLimit)}
            <Highlight>{this.state.value.slice(characterLimit, this.state.value.length)}</Highlight>
          </Highlights>
        </Backdrop>
        <AutosizeInput
          placeholder={placeholder}
          value={this.state.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          autoComplete="off"
          name={name}
          id={id}
          maxLength={maxLength}
          disabled={disabled}
        />
        {characterLimitErrorMessage && overLimit && (
          <CharacterLimitErrorMessage>{characterLimitErrorMessage}</CharacterLimitErrorMessage>
        )}
      </AutosizeInputWrapper>
    )
  }
}

export default AutosizeTextfield
