import { IHateoasLink } from '@store'
import { FetchBrandFanGroupMembersActionTypes, IInfluencerIdentity } from '@store/brandFans'

export const fetchBrandFanGroupMembers = (
  groupId: number,
  isFetchNext = false,
  resetList = false,
) => ({
  payload: { groupId, isFetchNext, resetList },
  type: FetchBrandFanGroupMembersActionTypes.FETCH_REQUEST,
})
export const fetchBrandFanGroupMembersSuccess = (
  groupId: number,
  brandFanGroupMembers: ReadonlyArray<IInfluencerIdentity>,
  links: ReadonlyArray<IHateoasLink>,
) => ({
  payload: { brandFanGroupMembers, groupId, links },
  type: FetchBrandFanGroupMembersActionTypes.FETCH_SUCCESS,
})
export const fetchBrandFanGroupMembersError = (error: any) => ({
  payload: error,
  type: FetchBrandFanGroupMembersActionTypes.FETCH_ERROR,
})
export const resetBrandFanGroupMembers = (groupId: number) => ({
  payload: groupId,
  type: FetchBrandFanGroupMembersActionTypes.RESET_LIST,
})

export const refreshBrandFanGroupMembers = (groupId: number) => ({
  payload: groupId,
  type: FetchBrandFanGroupMembersActionTypes.REFRESH_REQUEST,
})
