import React from 'react'

import { Button, Header } from '@tribegroup'

interface ILicenseButtonProps {
  text: React.ReactNode
  disabled?: boolean
  loading?: boolean
  onClick?: (event: React.FormEvent<HTMLInputElement>) => void
  elementName?: string
}

const SubmissionCardModalLicenseActionsButton = ({
  disabled,
  loading,
  onClick,
  text,
  elementName,
}: ILicenseButtonProps) => (
  <Button
    fullWidth
    scheme={'primary'}
    disabled={disabled}
    loading={loading}
    onClick={onClick}
    bottomOuterSpacing={1.25}
    height={3}
    data-cy-element={elementName}
  >
    <Header cta>{text}</Header>
  </Button>
)

export default SubmissionCardModalLicenseActionsButton
