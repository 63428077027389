import { PropsWithChildren } from 'react'

import styled from 'styled-components'

import Theme from '@theme'
import { Container, Icon } from '@tribegroup'

export const DEFAULT_SIZE_IN_REM = 1.5

export const CloseButton = styled.button`
  background-color: ${Theme.whiteColor};
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 0;
  width: 1rem;
  height: 1rem;
  border-radius: 1.25rem;
  transition: 0.2s;
  position: absolute;
  right: -0.5rem;
  top: -0.25rem;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);

  &:hover {
    background-color: ${Theme.grey200Color};
  }
`

export type PreviewContainerProps = {
  onClose?: () => void
} & PropsWithChildren

export const PreviewContainer: React.FC<PreviewContainerProps> = ({ children, onClose }) => {
  return (
    <Container
      width={1.5}
      height={2}
      relative
      rightOuterSpacing={1}
      bottomOuterSpacing={0.25}
      topOuterSpacing={0.25}
      data-testid="drawer-form-upload-preview-container"
      flexCenter
    >
      {children}
      {onClose && (
        <CloseButton onClick={onClose}>
          <Icon glyph="cross-heavy" size={0.5} />
        </CloseButton>
      )}
    </Container>
  )
}

export const LoadingOverlay = styled.div`
  position: absolute;
  padding: 0;
  opacity: 0.6;
  background: #000;
  border-radius: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
`
