import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import Confirmation from '@components/UI/Confirmation'
import { FooterButtonWrapper } from '@components/UI/Confirmation/Confirmation.styled'
import { IBrandManagerIdentity } from '@store/brandManagerIdentities'
import { removeIdentity } from '@store/brandManagerIdentities/actions/removeIdentity'
import { Button, Container } from '@tribegroup'

interface IProps {
  brandManagerIdentity: IBrandManagerIdentity
  isOpen: boolean
  onClose: VoidFunction
}

const IdentityRowMenuRemoveConfirmation = ({ brandManagerIdentity, isOpen, onClose }: IProps) => {
  const dispatch = useDispatch()

  const onRemove = () => {
    dispatch(removeIdentity(brandManagerIdentity.id))
    onClose()
  }

  return (
    <Confirmation
      title={
        <Container textOverflowAsEllipsis>
          <FormattedMessage
            id="connectedAccounts.confirmation.removeIdentity.title"
            values={{ accountName: brandManagerIdentity.display_name }}
          />
        </Container>
      }
      content={<FormattedMessage id="connectedAccounts.confirmation.removeIdentity.paragraph" />}
      footerButtons={
        <Fragment>
          <FooterButtonWrapper>
            <Button scheme="primary" quiet onClick={onClose}>
              <FormattedMessage id="core.text.cancel" />
            </Button>
          </FooterButtonWrapper>
          <FooterButtonWrapper>
            <Button scheme="primary" onClick={onRemove}>
              <FormattedMessage id="core.text.remove" />
            </Button>
          </FooterButtonWrapper>
        </Fragment>
      }
      isOpen={isOpen}
    />
  )
}

export default IdentityRowMenuRemoveConfirmation
