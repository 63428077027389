import { ITrackEventPayload } from 'redux-segment'

import { AnalyticsTrackingActionTypes } from '@store/analytics'
import { toEventsTracking } from '@utils'

export const trackEvent = (name: string, properties: any) => ({
  type: AnalyticsTrackingActionTypes.SEND_TRACKING_REQUEST,
  payload: {
    name,
    properties,
  },
})

export const trackEventSuccess = (name: string, properties: any) => ({
  type: AnalyticsTrackingActionTypes.SEND_TRACKING_SUCCESS,
  meta: {
    analytics: toEventsTracking(name, properties),
  },
})

export const legacyTrackEvent = (event: ITrackEventPayload) => ({
  type: AnalyticsTrackingActionTypes.LEGACY_SEND_TRACKING,
  meta: {
    analytics: event,
  },
})
