import { IApplicationState } from '@store/index'

export const selectReelMetricsSummary = (state: IApplicationState, campaignId: number) => {
  return state.reportings.reelMetrics.reelMetricsSummaryByCampaignId[campaignId] ?? {}
}

export const selectReelMetricIds = (state: IApplicationState, campaignId: number) =>
  state.reportings.reelMetrics.reelMetricIdsByCampaignId[campaignId] ?? []

export const selectReelMetrics = (state: IApplicationState, id: number) =>
  state.reportings.reelMetrics.reelMetricsById[id] ?? {}
