import { createSelector } from 'reselect'

import { IApplicationState } from '@store'
import { selectLink } from '@utils/selectLink'

const selectPurchaseOrderState = (state: IApplicationState) => state.purchaseOrders

export const selectIsFetching = createSelector(
  selectPurchaseOrderState,
  (state) => state.isFetching,
)

export const selectRequestPurchaseOrderAccessLink = createSelector(
  selectPurchaseOrderState,
  (state) => selectLink(state.links, 'request_access'),
)

export const selectPurchaseOrders = createSelector(
  selectPurchaseOrderState,
  (state) => state.purchaseOrders,
)

export const selectHasPurchaseOrders = createSelector(
  selectPurchaseOrders,
  (purchaseOrders) => purchaseOrders?.length > 0,
)

export const selectPurchaseOrderMetadata = createSelector(
  selectPurchaseOrderState,
  (state) => state.metadata,
)
