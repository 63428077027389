import React from 'react'

import { SubmissionChannels } from '@enums'
import { BetaLabel, Container } from '@tribegroup'
import { Icon } from '../Icon'
import { defaultTheme } from '../Theme'
import { Header } from '../Typography'
import { addToSet, removeFromSet } from '../utils'
import { InputSet } from './InputSet/InputSet'
import {
  SocialPanelWrapper,
  SocialWrapper,
  TitleWrapper,
  TrashIconWrapper,
} from './SocialPanel.styled'

export interface IPanelProps {
  /**
   * Type of social channel to render
   *
   * @default undefined
   */
  channel: SubmissionChannels

  /**
   * Text displayed on the button
   *
   * @default undefined
   */
  submitText: string

  /**
   * Default set of tags
   *
   * @default []
   */
  tags?: ReadonlyArray<string>

  /**
   * Default set of handles
   *
   * @default []
   */
  handles?: ReadonlyArray<string>

  /**
   * Default set of handles
   *
   * @default []
   */
  urls?: ReadonlyArray<string>

  /**
   * Children component
   */
  children?: React.ReactNode

  /**
   * Function to be called on remove of channel
   *
   * @default undefined
   */
  onRemoveChannel?: (channel: string) => void

  /**
   * Function to be called on update on lists
   *
   * @default {}
   */
  onTagItemsChanged: (channel: string, items: any) => void

  /**
   * highlights trash icon
   */
  highlightIcon?: boolean

  /**
   * The ReactNode to be added before the input
   */
  contentBeforeInput?: React.ReactNode

  pillBackgroundColor?: string

  isBeta?: boolean
}

export default class SocialPanel extends React.PureComponent<IPanelProps> {
  static defaultProps = {
    submitText: 'Enter',
  }

  state = {
    focused: '',
    tags: this.props.tags,
    handles: this.props.handles,
    urls: this.props.urls,
  }

  onFocus = (event) => {
    const target = event.target as HTMLInputElement
    this.setState({
      focused: target.name,
    })
  }

  onRemoveTag = (attr: string, item: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        [attr]: removeFromSet(prevState[attr], item),
      }),
      this.onUpdate,
    )
  }

  onAddTag = (attr: string, item: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        [attr]: addToSet(prevState[attr], [item]),
      }),
      this.onUpdate,
    )
  }

  onDelete = () => {
    if (this.props.onRemoveChannel) {
      this.props.onRemoveChannel(this.props.channel)
    }
  }

  onUpdate = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { focused, ...items } = this.state
    this.props.onTagItemsChanged(this.props.channel, items)
  }

  render() {
    const {
      channel,
      submitText,
      children,
      highlightIcon,
      onRemoveChannel,
      contentBeforeInput,
      pillBackgroundColor,
      isBeta,
    } = this.props
    const { focused, handles, urls, tags } = this.state

    return (
      <SocialPanelWrapper>
        {onRemoveChannel && (
          <TrashIconWrapper>
            <Icon
              onClick={this.onDelete}
              glyph="trash"
              size={1.25}
              color={highlightIcon ? defaultTheme.errorColor : defaultTheme.defaultColor}
              data-cy-element={`trash-icon-${channel}`}
              data-testid={`trash-icon-${channel}`}
            />
          </TrashIconWrapper>
        )}
        <TitleWrapper>
          <SocialWrapper>
            <Icon size={1.5} glyph={`social-${channel.toLowerCase()}-colour`} />
          </SocialWrapper>
          <Header uppercase color={defaultTheme.defaultColor} cta>
            {channel}
            {isBeta && (
              <Container leftOuterSpacing={0.5} inline>
                <BetaLabel />
              </Container>
            )}
          </Header>
        </TitleWrapper>
        {contentBeforeInput}
        <InputSet
          channel={channel}
          submitText={submitText}
          focused={focused}
          onFocus={this.onFocus}
          tags={tags}
          handles={handles}
          urls={urls}
          onAddTag={this.onAddTag}
          onRemoveTag={this.onRemoveTag}
          pillBackgroundColor={pillBackgroundColor}
        />
        {children}
      </SocialPanelWrapper>
    )
  }
}
