import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import BrandFansAudienceInsightsTrigger from '@components/BrandFans/AudienceInsights/Trigger'
import { IApplicationState } from '@store'
import { selectIdentityAudienceInsightsLink, selectIdentityCreatorBioLink } from '@store/brandFans'
import { Container } from '@tribegroup'
import { InfluencerIdentityRowViewBio } from './ViewBio'

interface IProps {
  identityId: number
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

const BrandFanInfluencerIdentityRowLabels: React.FC<IInternalProps> = ({
  identityId,
  hasAudienceInsights,
  creatorBioLink,
}) => {
  return (
    <Container flexStart gap={0.75}>
      {hasAudienceInsights && <BrandFansAudienceInsightsTrigger identityId={identityId} />}
      {Boolean(creatorBioLink) && (
        <InfluencerIdentityRowViewBio href={creatorBioLink!.href} method={creatorBioLink!.method} />
      )}
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { identityId }: IProps) => ({
  hasAudienceInsights: Boolean(selectIdentityAudienceInsightsLink(state, identityId)),
  creatorBioLink: selectIdentityCreatorBioLink(state, identityId),
})

const connector = connect(mapStateToProps)

export { BrandFanInfluencerIdentityRowLabels }
export default compose<IInternalProps, IProps>(connector)(BrandFanInfluencerIdentityRowLabels)
