import { IResponseError } from '@lib/error'

export interface IFacebookAdAccount {
  business_name: string
  name: string
  id: string
}

export interface IFacebookAdsState {
  readonly isFetching: boolean
  readonly hasFetched: boolean
  readonly accounts: ReadonlyArray<IFacebookAdAccount>
  readonly errors?: IResponseError
  readonly isTransferringMedia: boolean
}

export interface IMediaTransferSubmissionParams {
  submissionId: number
  campaignId: number
}

export interface IMediaTransferMediaObjectParams {
  mediaObjectId: number
}

export type IMediaTransferRequestParams =
  | IMediaTransferSubmissionParams
  | IMediaTransferMediaObjectParams

export type IAnyMediaTransferRequestParams = IMediaTransferSubmissionParams &
  IMediaTransferMediaObjectParams

export enum FetchFacebookAdAccountsActionTypes {
  FETCH_REQUEST = 'facebookAdAccounts/FETCH_REQUEST',
  FETCH_SUCCESS = 'facebookAdAccounts/FETCH_SUCCESS',
  FETCH_ERROR = 'facebookAdAccounts/FETCH_ERROR',
}

export enum FacebookMediaTransferActionTypes {
  MEDIA_TRANSFER_REQUEST = 'facebookAdAccounts/MEDIA_TRANSFER_REQUEST',
  MEDIA_TRANSFER_SUCCESS = 'facebookAdAccounts/MEDIA_TRANSFER_SUCCESS',
  MEDIA_TRANSFER_ERROR = 'facebookAdAccounts/MEDIA_TRANSFER_ERROR',
}
