import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 0.88;
  letter-spacing: 0.3px;
  text-align: center;
  color: ${Theme.grey900Color};
  ${breakpoint('phoneUpperBoundary')`
    font-size: 2rem;
  `};
`
