import React from 'react'

import { connect } from 'react-redux'
import { RouterProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { BuilderDefault, BuilderSteps } from '@enums'
import { withBuilderValidation, withDraft, withScrollToTop } from '@hocs'
import { IApplicationState } from '@store'
import { fetchBrands, IBrand, selectBrand } from '@store/brands'
import { CampaignTypes, fetchCampaigns } from '@store/campaigns'
import { selectDraftIsSocial, selectShowCampaignObjectives } from '@store/drafts'
import { FindProductOption } from '@store/rootResource'
import { getRecentBuilderStep, queryStringtoObject } from '@utils'
import BuilderLayout from '../Layout/Layout'
import BuilderCampaignCallToAction from './CallToAction/CallToAction'
import BuilderCampaignCategories from './Categories/Categories'
import BuilderCampaignCoverImage from './CoverImage/CoverImage'
import BuilderCampaignFindProduct from './FindProduct/FindProduct'
import BuilderCampaignSelectedBrand from './SelectedBrand/SelectedBrand'
import BuilderCampaignTextAreaPanel from './TextAreaPanel/TextAreaPanel'

interface IInternalProps extends IDraftContextProps, RouterProps, IBuilderValidationContextProps {
  fetchBrands: typeof fetchBrands
  fetchCampaigns: typeof fetchCampaigns
  brand: IBrand
  showCampaignObjectives?: boolean
  isInDraftStatus?: boolean
  isSocial?: boolean
}

export const BuilderCampaignForm: React.FC<IInternalProps> = ({
  draft,
  clearFieldValidation,
  validationResults,
  history,
  isEnableActions,
  brand,
  isSocial,
  updateForm,
  formChanged,
  setFormChanged,
  fetchBrands: fetchBrandsAction,
  fetchCampaigns: fetchCampaignsAction,
  setContinueCallback,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onContinue = ({ nextStep, _draft, currentStep, _history, saveCampaign }) => {
    saveCampaign(getRecentBuilderStep(currentStep, draft.step_completed), {
      history,
      redirect: `/builder/${nextStep}/{draft_id}${history.location.search}`,
    })
  }

  React.useEffect(() => {
    fetchCampaignsAction()
    fetchBrandsAction()
    setContinueCallback(onContinue)
  }, [])

  React.useEffect(() => {
    const { step_completed, submission_type, brand_fan_group_ids, traditional } = draft
    const brandProps = brand && {
      brand_id: brand.id,
    }
    updateForm({
      ...brandProps,
      step_completed,
      submission_type,
      brand_fan_group_ids,
      traditional,
    })
  }, [location.search, brand && brand.id])

  const onCoverImageChanged = (mediaId?: string, mediaUrl?: string) => {
    updateForm({
      hero_16x9_id: mediaId || '',
      hero_16x9_original_url: mediaUrl || '',
    })
  }

  const updateFormByName = (name: string, value: string) => {
    clearFieldValidation(name)
    updateForm({
      [name]: value,
    })
  }

  const handleFirstFormChange = (name: string, value: string) => {
    if (!formChanged) {
      updateFormByName(name, value)
      setFormChanged(true)
    }
  }

  const onTextChanged = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement
    handleFirstFormChange(target.name, target.value && target.value.trim())
  }

  const onSelectChanged = (selectedValues: {}) => {
    updateForm({
      ...selectedValues,
    })
  }

  const onBlur = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement
    updateFormByName(target.name, target.value && target.value.trim())
  }

  const onCallToActionBlur = (callToAction: string) => {
    updateForm({
      call_to_action: callToAction,
    })
  }

  const onCallToActionChanged = (callToAction: string) => {
    handleFirstFormChange('call_to_action', callToAction)
  }

  const onFindProductOptionClick = (option) => {
    updateForm({
      product_where_to_find_option: option.code,
      product_where_to_find: option.code !== FindProductOption.OTHER ? option.help_text : '',
    })
  }

  return (
    <BuilderLayout>
      <BuilderCampaignSelectedBrand brand={brand} showVideoTip={false} />
      <BuilderCampaignTextAreaPanel
        disabled={!isEnableActions}
        name="product_name"
        height={3.125}
        maxLength={BuilderDefault.DEFAULT_TEXTAREA_MAX_LENGTH}
        required
        moduleName="campaign"
        onFocus={clearFieldValidation}
        onChange={onTextChanged}
        onBlur={onBlur}
        value={draft.product_name}
        validationResults={validationResults}
        elementName="campaign-title-panel-wrapper"
      />
      <BuilderCampaignCoverImage
        name="hero_16x9_id"
        coverImage={draft.hero_16x9_original_url}
        onCoverImageChanged={onCoverImageChanged}
      />
      <BuilderCampaignCallToAction
        disabled={!isEnableActions}
        value={draft.call_to_action}
        onFieldBlur={onCallToActionBlur}
        onFieldChange={onCallToActionChanged}
        isSocial={isSocial}
      />

      <BuilderCampaignFindProduct
        value={draft.product_where_to_find}
        option={draft.product_where_to_find_option || FindProductOption.OTHER}
        onChange={onTextChanged}
        onFocus={clearFieldValidation}
        onBlur={onBlur}
        onFindProductOptionClick={onFindProductOptionClick}
        isSocial={isSocial}
        validationResults={validationResults}
      />

      <BuilderCampaignTextAreaPanel
        disabled={!isEnableActions}
        name="product_text"
        height={9.375}
        maxLength={500}
        required
        moduleName="campaign"
        onChange={onTextChanged}
        onBlur={onBlur}
        onFocus={clearFieldValidation}
        value={draft.product_text}
        validationResults={validationResults}
        additionalTexts={['text']}
        elementName="about-you-panel-wrapper"
      />
      <BuilderCampaignCategories
        disabled={!isEnableActions}
        primary={draft.product_category_primary_id}
        secondary={draft.product_category_secondary_id}
        onCategoryChanged={onSelectChanged}
      />
    </BuilderLayout>
  )
}

const mapDispatchToProps = {
  fetchBrands,
  fetchCampaigns,
}

const mapStateToProps = (state: IApplicationState, { history, draft }: IInternalProps) => {
  const query: any = queryStringtoObject(history.location.search)
  const queryBrandId = query.brandId && parseInt(query.brandId, 10)
  const queryBrandFanGroupId = query.brand_fan_group_id && parseInt(query.brand_fan_group_id, 10)
  const brandId = queryBrandId || draft.brand_id
  const brand = brandId && selectBrand(state, brandId)
  const campaignType = query.campaignType

  return {
    brand,
    showCampaignObjectives: selectShowCampaignObjectives(state),
    isSocial: selectDraftIsSocial(state),
    draft: {
      step_completed: draft.id ? draft.step_completed : BuilderSteps.BRAND,
      submission_type: query && query.type,
      brand_fan_group_ids: queryBrandFanGroupId
        ? [queryBrandFanGroupId]
        : draft.brand_fan_group_ids,
      brand_id: brand && brand.id,
      brand_logo: brand && brand.logo_url,
      brand_name: brand && brand.name,
      traditional: campaignType !== CampaignTypes.SOLUTION,
      ...draft,
    },
  }
}

export default compose(
  withDraft,
  withRouter,
  withBuilderValidation,
  withScrollToTop,
  connect(mapStateToProps, mapDispatchToProps),
)(BuilderCampaignForm)
