import { FormattedMessage } from 'react-intl'

import { NoticeUnsupportedScreen } from '@components/UI/Notice'
import { SupportedScreenWrapper, UnsupportedScreenWrapper } from '@components/UI/ScreenSize'

export const ContentLibraryUnsupportedScreenWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <>
      <UnsupportedScreenWrapper>
        <NoticeUnsupportedScreen
          subtitle={<FormattedMessage id="core.text.tryUsingDesktop" />}
          alt="content-library-unsupported-screen"
          title={<FormattedMessage id="contentLibrary.unsupportedscreen.title" />}
        />
      </UnsupportedScreenWrapper>
      <SupportedScreenWrapper>{children}</SupportedScreenWrapper>
    </>
  )
}
