import {
  differenceInCalendarDays,
  differenceInDays,
  differenceInMonths,
  isLastDayOfMonth,
} from 'date-fns'

import { CampaignStatuses } from '@enums'

interface IRelativeDate {
  id: string
  values: any
}

const MONTHS_IN_YEAR = 12

const getDaysDiff = (endDate: Date, startDate: Date, useCalendarDays = true) => {
  return useCalendarDays
    ? differenceInCalendarDays(endDate, startDate)
    : differenceInDays(endDate, startDate)
}

const getMonthsDiff = (endDate: Date, startDate: Date) => {
  const diffMonths = differenceInMonths(endDate, startDate)
  const endDateDay = endDate.getUTCDate()
  const startDateDay = startDate.getUTCDate()
  // adjust the number of months and years if we encounter months with different days
  return isLastDayOfMonth(endDate) && startDateDay > endDateDay ? diffMonths + 1 : diffMonths
}

const getYearsDiff = (endDate: Date, startDate: Date) => {
  return Math.floor(getMonthsDiff(endDate, startDate) / MONTHS_IN_YEAR)
}

const getDaysLeft = (endDate: Date, startDate: Date) => {
  const days = getDaysDiff(endDate, startDate)
  return {
    id: 'campaign.campaignCard.relativeDate.daysLeft',
    values: {
      days,
    },
  }
}

const getGracePeriod = (endDate: Date, startDate: Date) => {
  const initialDays = getDaysDiff(endDate, startDate, false)

  const days = initialDays > 0 ? initialDays : 0
  return {
    id: 'campaign.campaignCard.relativeDate.gracePeriodInXDays',
    values: {
      days,
    },
  }
}

const getTimePeriodAgo = (endDate: Date, startDate: Date) => {
  const years = getYearsDiff(endDate, startDate)
  if (years > 0) {
    return {
      id: 'campaign.campaignCard.relativeDate.yearsAgo',
      values: {
        years,
      },
    }
  }

  const months = getMonthsDiff(endDate, startDate)
  if (months > 0) {
    return {
      id: 'campaign.campaignCard.relativeDate.monthsAgo',
      values: {
        months,
      },
    }
  }

  const days = getDaysDiff(endDate, startDate)
  if (days >= 0) {
    return {
      id: 'campaign.campaignCard.relativeDate.daysAgo',
      values: {
        days,
      },
    }
  }

  return undefined
}

const relativeDate = (now: Date, endDate: Date, status: string) => {
  const campaignStatusesDisplayDay = [CampaignStatuses.Active, CampaignStatuses.Paused].map(stat =>
    String(stat),
  )

  if (campaignStatusesDisplayDay.includes(status)) {
    return getDaysLeft(endDate, now) as IRelativeDate
  }

  if (status === CampaignStatuses.Expired) {
    return getGracePeriod(now, endDate) as IRelativeDate
  }

  if (status === CampaignStatuses.Completed) {
    return getTimePeriodAgo(now, endDate) as IRelativeDate
  }

  return undefined
}

export default relativeDate
