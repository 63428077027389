import { WatcherFunction } from '..'
import { watchIdentifyTracking } from './identify'
import { watchIntercomShutdown } from './intercom'
import { watchLegacySuccessTracking } from './legacyTrack'
import { watchTrackingRequest } from './track'

export const analyticsSagas: ReadonlyArray<WatcherFunction> = [
  watchTrackingRequest,
  watchIdentifyTracking,
  watchIntercomShutdown,
  watchLegacySuccessTracking,
]
