import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectAudienceLocations } from '@store/rootResource'
import Theme from '@theme'
import { BlockText } from '../Block/Block.styled'

interface IProps {
  locationCodes: ReadonlyArray<string>
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

const CampaignPreviewPreferredAudienceLocations: React.FC<IInternalProps> = ({
  locationCodes,
  audienceLocations,
}) => {
  const displayedLocations = audienceLocations.reduce((accum, curr) => {
    if (!locationCodes!.includes(curr.code)) {
      return accum
    }
    return [...accum, curr.display]
  }, [])

  return (
    <BlockText small color={Theme.grey800Color}>
      {displayedLocations.join(', ')}
    </BlockText>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  audienceLocations: selectAudienceLocations(state),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(connector)(CampaignPreviewPreferredAudienceLocations)
