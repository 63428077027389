import { PureComponent } from 'react'

import { Doughnut as CharJSDoughnut } from 'react-chartjs-2'

import { defaultTheme, IThemeInterface } from '../../Theme'
import { tooltipValueToPercentage } from './../util'

interface ICharData {
  label: string
  value: number
  percentage: number
}

interface IDoughnutProps {
  data: ReadonlyArray<ICharData>
  showAsPercentage: boolean
  theme: IThemeInterface
}

export class Doughnut extends PureComponent<IDoughnutProps> {
  static defaultProps = {
    showAsPercentage: true,
    theme: defaultTheme,
  }

  render() {
    const { data, showAsPercentage, theme } = this.props
    const dataPoints = data.map((dat) => (showAsPercentage ? dat.percentage : dat.value))
    const labels = data.map((dat) => dat.label)

    const highestValue = Math.max(...dataPoints)
    const backgroundColor = dataPoints.map((value) =>
      highestValue === value ? theme.primaryColor : theme.secondary500Color,
    )

    const config = {
      labels,
      datasets: [
        {
          data: dataPoints,
          backgroundColor,
        },
      ],
    }

    return (
      <CharJSDoughnut
        data={config}
        options={{
          plugins: {
            tooltip: {
              callbacks: {
                label: (data) =>
                  `${data.label}: ${
                    showAsPercentage ? tooltipValueToPercentage(data) : data.formattedValue
                  }`,
              },
            },
            legend: {
              display: true,
              position: 'bottom',
              fullSize: true,
              labels: {
                font: {
                  family: 'Rubik',
                  size: 14,
                },
                color: theme.grey700Color,
              },
            },
          },
        }}
        redraw
      />
    )
  }
}

export type { IDoughnutProps }
