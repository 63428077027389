import BuilderCampaignTypeSelector from '@components/Builder/CampaignType/Selector/Selector'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'

const ChooseCampaignType = () => (
  <MainLayout>
    <HeadTag id="builder.text.title" />
    <BuilderCampaignTypeSelector />
  </MainLayout>
)

export default ChooseCampaignType
