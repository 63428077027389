import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'

import { CostBreakdownRow } from '@components/UI/CostBreakdown'
import { ISubmission } from '@store/submissions'
import Theme from '@theme'

interface IProps {
  submission: ISubmission
  showBalanceAsterisk?: boolean
}

const SubmissionCostBreakdownSocialBalanceDue: React.FC<IProps> = ({
  submission,
  showBalanceAsterisk,
}) => {
  const { balance_amount_before_tax_currency, balance_amount_before_tax_cents } = submission
  return (
    <CostBreakdownRow
      medium
      color={Theme.primaryColor}
      label={
        <Fragment>
          <FormattedMessage
            id="core.text.balanceDueAndCurrency"
            values={{
              currency: balance_amount_before_tax_currency,
            }}
          />
          {showBalanceAsterisk && <FormattedMessage id="core.text.symbol.attention" />}
        </Fragment>
      }
      currency={balance_amount_before_tax_currency}
      quantity={balance_amount_before_tax_cents}
    />
  )
}

export default SubmissionCostBreakdownSocialBalanceDue
