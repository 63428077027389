export enum ErrorCodes {
  ERROR_CODE_APPROVE_INSUFFICIENT_FUNDS = 'submission.approve.insufficient_credit',
  ERROR_CODE_APPROVE_3DS_INSUFFICIENT_FUNDS = 'submission.request_approve3ds.insufficient_credit',
  ERROR_CODE_LICENSE_INSUFFICIENT_FUNDS = 'submission.license.insufficient_credit',
  ERROR_CODE_CAMPAIGN_PERMISSION_ERROR = 'active_record.find.record_not_valid',
  RESEND_VERIFICATION_ACCOUNT_ALREADY_VERIFIED = 'brand_manager.resend_verification_email.account_already_verified',
  VERIFY_EMAIL_ACCOUNT_ALREADY_VERIFIED = 'brand_manager.verify_email.account_already_verified',
  SUBMISSION_APPROVE_STRIPE_ERROR = 'submission.approve.stripe_error',
  CONTENT_LICENSING_STRIPE_ERROR = 'license.request.purchase.stripe_error',
  SIGNIN_UNVERIFIED_ACCOUNT = 'brand_manager.signin.unverified_account',
  STRIPE_INTENT_FAILURE = 'payment_intent_authentication_failure',
  LOCAL_STRIPE_INTENT_FAILURE = '3ds.stripe_intent_failure',
  CAMPAIGN_ACTIVATE_WITH_FEE_STRIPE_ERROR = 'campaign.activateWithFee.stripeError',
  ERROR_CODE_LICENSE_NOT_ENOUGH_BUDGET = 'submission.license.purchase_error.not_enough_budget',
  ERROR_CODE_ACTIVATE_BRIEF_INSUFFICIENT_CREDIT = 'brief.activate.insufficient_credit',
}
