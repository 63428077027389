import { useEffect } from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { isEmpty, isNumber } from 'lodash'
import Currency from 'react-currency-formatter'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import AnalyticsDetailCard from '@components/Analytics/DetailCard'
import Loading from '@components/UI/Loading'
import {
  CPM_EXPLAINED,
  SHORTLISTED_FILTER,
  TRIBE_PRICING_PAGE_SHORTLIST_SUMMARY_UTM,
} from '@constants'
import { FeatureToggles } from '@enums'
import { IApplicationState } from '@store'
import { selectIsPriceHidden, selectShortlistSummaryLink } from '@store/campaigns'
import { selectCampaignId } from '@store/router'
import {
  fetchShortlistSummary,
  selectIsFetchingShortlistSummary,
  selectShortlistSummary,
  selectShortlistSummaryFollowers,
  selectShortlistSummaryProjectedSpend,
} from '@store/shortlistSummary'
import {
  selectHasShortlistedProxySubmission,
  selectIsLoadingInitialList,
  selectSubmissionMetrics,
} from '@store/submissions'
import Theme from '@theme'
import { Container, Link, Text } from '@tribegroup'
import { abbreviateNumber, getMessage } from '@utils'
import { ShortlistedAnalyticsCardGrid } from './Shortlisted.styled'

const link = TRIBE_PRICING_PAGE_SHORTLIST_SUMMARY_UTM

const InboxTopPanelMetricsShortlisted = ({ intl }) => {
  const isMetricsConsolidationEnabled = useFlag(FeatureToggles.METRIC_CONSOLIDATION_ENABLED)
  const isXEnabled = useFlag(FeatureToggles.X_SOCIAL_PLATFORM_ENABLED)
  const match = useRouteMatch()
  const dispatch = useDispatch()

  const {
    campaignId,
    shortlistSummary,
    currency,
    isFetchingShortlistSummary,
    hasShortlistSummaryLink,
    projectedSpend,
    totalFollowers,
    hasShortlistedSubmissionMetadata,
    isLoadingInitialSubmissionList,
    hasShortlistSummary,
    isPriceHidden,
    hasProxy,
  } = useSelector((state: IApplicationState) => {
    const campaignId = selectCampaignId(match)!
    const shortlistSummary = selectShortlistSummary(state, campaignId)
    const projectedSpend = selectShortlistSummaryProjectedSpend(
      state,
      campaignId,
      SHORTLISTED_FILTER,
    )

    return {
      campaignId,
      shortlistSummary,
      projectedSpend,
      currency: projectedSpend.currency,
      hasShortlistSummary: !isEmpty(shortlistSummary),
      isFetchingShortlistSummary: selectIsFetchingShortlistSummary(state),
      hasShortlistSummaryLink: Boolean(selectShortlistSummaryLink(state, campaignId)),
      totalFollowers: selectShortlistSummaryFollowers(state, campaignId, SHORTLISTED_FILTER),
      hasShortlistedSubmissionMetadata: !isEmpty(
        selectSubmissionMetrics(state, campaignId, SHORTLISTED_FILTER),
      ),
      isLoadingInitialSubmissionList: selectIsLoadingInitialList(
        state,
        campaignId,
        SHORTLISTED_FILTER,
      ),
      isPriceHidden: selectIsPriceHidden(state, campaignId),
      hasProxy: selectHasShortlistedProxySubmission(state, campaignId),
    }
  })

  useEffect(() => {
    if (hasShortlistSummaryLink && hasShortlistedSubmissionMetadata) {
      dispatch(fetchShortlistSummary(campaignId))
    }
  }, [hasShortlistedSubmissionMetadata, hasShortlistSummaryLink, campaignId])

  if (isLoadingInitialSubmissionList) {
    return null
  }

  if (isFetchingShortlistSummary && !hasShortlistSummary) {
    return <Loading />
  }

  const showCostPerMetric = !isPriceHidden

  return (
    <Container bottomOuterSpacing={1.25}>
      <ShortlistedAnalyticsCardGrid>
        {!isPriceHidden && (
          <AnalyticsDetailCard
            title="analytics.shortlistSummary.projectedSpend"
            content={
              isNumber(projectedSpend.amount) &&
              projectedSpend.amount > 0 && (
                <Currency
                  currency={currency}
                  pattern="!###,### "
                  quantity={projectedSpend.amount}
                />
              )
            }
            values={{
              taxDisplay: getMessage(
                intl,
                `core.currency.taxDisplay.${projectedSpend.currency || 'generic'}`,
              ),
            }}
            elementName="shortlist-summary-projected-spend"
          />
        )}

        <AnalyticsDetailCard
          title="analytics.shortlistSummary.totalFollowers"
          content={
            isNumber(totalFollowers) && totalFollowers > 0 && abbreviateNumber(totalFollowers)
          }
          elementName="shortlist-summary-total-followers"
        />
        <AnalyticsDetailCard
          title="analytics.shortlistSummary.estimatedEngagement"
          content={
            isNumber(shortlistSummary.est_engagement) && (
              <FormattedNumber value={shortlistSummary.est_engagement} />
            )
          }
          elementName="shortlist-summary-estimated-engagement"
        />
        {showCostPerMetric && (
          <AnalyticsDetailCard
            title="analytics.shortlistSummary.estimatedCPE"
            content={
              isNumber(shortlistSummary.est_cpe) && (
                <Currency currency={currency} quantity={shortlistSummary.est_cpe} />
              )
            }
            elementName="shortlist-summary-estimated-cpe"
          />
        )}

        <AnalyticsDetailCard
          title="analytics.shortlistSummary.estimatedReach"
          content={
            isNumber(shortlistSummary.est_reach) && (
              <FormattedNumber value={shortlistSummary.est_reach} />
            )
          }
          externalLink={link}
          elementName="shortlist-summary-reach"
          showAsterisk={!isMetricsConsolidationEnabled || isXEnabled}
        />
        <AnalyticsDetailCard
          title="analytics.shortlistSummary.estimatedAvgReachPercentage"
          content={
            isNumber(shortlistSummary.est_reach_pct) && (
              <FormattedMessage
                id="core.text.percent"
                values={{ value: shortlistSummary.est_reach_pct }}
              />
            )
          }
          externalLink={link}
          elementName="shortlist-summary-avg-reach-pct"
          showAsterisk={!isMetricsConsolidationEnabled || isXEnabled}
        />
        <AnalyticsDetailCard
          title="analytics.shortlistSummary.estimatedImpressions"
          content={
            isNumber(shortlistSummary.est_impressions) && (
              <FormattedNumber value={shortlistSummary.est_impressions} />
            )
          }
          externalLink={link}
          elementName="shortlist-summary-impressions"
        />
        {showCostPerMetric && (
          <AnalyticsDetailCard
            title="analytics.shortlistSummary.estimatedCPM"
            content={
              isNumber(shortlistSummary.est_cpm) && (
                <Currency currency={currency} quantity={shortlistSummary.est_cpm} />
              )
            }
            externalLink={link}
            elementName="shortlist-summary-estimated-cpm"
            values={{
              link: (
                <Link href={CPM_EXPLAINED} target="_blank" color={Theme.primaryColor} lowercase>
                  <FormattedMessage id="core.text.clickHere" />
                </Link>
              ),
            }}
          />
        )}
      </ShortlistedAnalyticsCardGrid>
      {hasProxy && (
        <Container topOuterSpacing={1}>
          <Text color={Theme.grey800Color}>
            <FormattedMessage id="analytics.shortlistSummary.education.proxySubmission" />
          </Text>
        </Container>
      )}
    </Container>
  )
}

export default injectIntl(InboxTopPanelMetricsShortlisted)
