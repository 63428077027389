import React from 'react'

import Theme from '@theme'
import { H5, Text } from '@tribegroup'
import { InfluencerIdentityRowValueAndLabelRoot } from './ValueAndLabel.styled'

interface IInfluencerIdentityRowValueAndLabelProps {
  value: React.ReactNode
  label: React.ReactNode
  width?: number
  name?: string
}

const InfluencerIdentityRowValueAndLabel = ({
  value,
  label,
  width = 4.75,
  name,
}: IInfluencerIdentityRowValueAndLabelProps) => (
  <InfluencerIdentityRowValueAndLabelRoot data-testid={name} width={width}>
    <H5 color={Theme.defaultColor}>{value}</H5>
    <Text xsmall color={Theme.grey700Color} uppercase>
      {label}
    </Text>
  </InfluencerIdentityRowValueAndLabelRoot>
)

export { InfluencerIdentityRowValueAndLabel }
export default InfluencerIdentityRowValueAndLabel
