import styled, { css, Keyframe } from 'styled-components'

import { AspectRatios } from '@enums'

interface IResponsiveImageProps {
  loaded: boolean
  animation: Keyframe
  loadingColor?: string
  contain?: boolean
  borderRadius?: number
  borderWidth?: number
  borderColor?: string
}

export const ResponsiveImage = styled<IResponsiveImageProps, any>('img')`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  ${({
    loaded,
    animation,
    contain,
    aspectRatio,
    round,
    borderRadius,
    fitToContainer,
    borderWidth,
    borderColor,
  }) => css`
    ${aspectRatio &&
    css`
      position: absolute;
      top: 0;
      left: 0;
    `};
    object-fit: ${contain ? 'contain' : 'cover'};
    ${animation &&
    css`
      visibility: hidden;
      ${loaded &&
      css`
        visibility: visible;
        animation: ${animation} 0.2s linear;
      `}
    `};
    ${borderRadius &&
    `
        border-radius: ${borderRadius}rem;
    `};
    ${fitToContainer &&
    `
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
    `};
    ${round && `border-radius: 100%;`};
    ${borderWidth &&
    `
      border-width: ${borderWidth}rem;
      border-style: solid;
    `};
    ${borderColor && `border-color: ${borderColor}`};
  `}
`

interface IImageWrapperProps {
  aspectRatio: AspectRatios
  loaded: boolean
  opacity?: number
}

export default styled<IImageWrapperProps, any>('div')`
  ${({
    aspectRatio,
    loaded,
    loadingColor,
    backgroundColor,
    fitToContainer,
    opacity,
    round,
    containerHeight,
  }) => css`
    position: relative;
    width: 100%;
    overflow: hidden;
    ${fitToContainer &&
    `
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    `};
    ${aspectRatio &&
    `
      padding-top: ${aspectRatio}%;
      &:before {
        background-color: ${loadingColor};
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: 0.2s opacity;
        ${
          loaded &&
          css`
            opacity: 0;
          `
        };
      }
    `};
    ${backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};
    ${opacity &&
    css`
      opacity: ${opacity};
    `};
    ${round &&
    css`
      border-radius: 50%;
    `};
    ${containerHeight &&
    `
      height: ${typeof containerHeight === 'number' ? `${containerHeight}rem` : containerHeight};
    `}
  `}
`
