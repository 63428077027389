import { WatcherFunction } from '..'
import { watchFetchMediaObjectRequest } from './mediaObject'
import { watchFetchMediaObjectsRequest } from './mediaObjects'
import { watchPurchaseLicenseRequest } from './purchaseLicense'
import { watchRequestLicenseRequest } from './requestLicense'
import { watchFetchShareableContentLibraryRequest } from './shareableContentLibrary'
import { watchFetchShareableMediaObjectRequest } from './shareableMediaObject'

export const mediaObjectsSagas: ReadonlyArray<WatcherFunction> = [
  watchFetchShareableContentLibraryRequest,
  watchFetchMediaObjectsRequest,
  watchFetchMediaObjectRequest,
  watchRequestLicenseRequest,
  watchFetchShareableMediaObjectRequest,
  watchPurchaseLicenseRequest,
]
