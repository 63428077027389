import { Component } from 'react'

import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import ConfirmationMessageBox from '@components/UI/ConfirmationMessageBox'
import { Button } from '@tribegroup'
import { queryStringtoObject } from '@utils'

interface IInternalProps extends InjectedIntlProps, RouteComponentProps {}

export class AuthSetPasswordConfirmation extends Component<IInternalProps> {
  static getDerivedStateFromProps(nextProps: IInternalProps, prevState) {
    const parsedQueryString: any = queryStringtoObject(nextProps.location.search)

    const forgotPasswordEmail =
      parsedQueryString.email && decodeURIComponent(parsedQueryString.email)

    if (!forgotPasswordEmail) {
      nextProps.history.push('/signIn')
    }

    if (forgotPasswordEmail !== prevState.email) {
      return { email: forgotPasswordEmail }
    }

    return null
  }

  state = {
    email: undefined,
  }

  ctaOnClick = () => this.props.history.push('/signIn')

  render() {
    const { intl } = this.props

    return (
      <ConfirmationMessageBox
        title={intl.formatMessage({
          id: 'resetPassword.setPassword.confirmation.title',
        })}
        footerCTA={
          <Button heavy scheme="primary" onClick={this.ctaOnClick}>
            <FormattedMessage id="resetPassword.setPassword.confirmation.button" />
          </Button>
        }
      >
        <FormattedHTMLMessage
          id="resetPassword.setPassword.confirmation.body"
          values={{ email: this.state.email }}
        />
      </ConfirmationMessageBox>
    )
  }
}

export default compose<IInternalProps, {}>(withRouter, injectIntl)(AuthSetPasswordConfirmation)
