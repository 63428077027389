import LoadIntercom from '@intercom/messenger-js-sdk'
import { call, put, takeLatest } from 'redux-saga/effects'

import { DEFAULT_TENANT } from '@constants'
import { unleashClient } from '@context/FeatureToggle'
import { FeatureToggles } from '@enums'
import { amplitude } from '@lib/analytics/amplitude'
import {
  AnalyticsIdentifyActionTypes,
  identifyError,
  identifyRequest,
  identifySuccess,
} from '@store/analytics'
import { IVerifiedMe } from '@store/me'
import { isAdmin } from '@utils'

export function* handleIdentify(action: ReturnType<typeof identifyRequest>) {
  try {
    const {
      id,
      email,
      first_name,
      last_name,
      subscription_tier,
      referrer_id,
      referral_amount_cents,
      referral_amount_currency,
      tenant_identifier = DEFAULT_TENANT,
      intercom_user_hash,
    } = action.payload

    const isDefaultTenant = process.env.APP_NAME === DEFAULT_TENANT
    const subscriptionTier = subscription_tier?.identifier
    const asAdmin = isAdmin(action.payload as IVerifiedMe)

    const isByeByeSegmentEnabled = unleashClient.isEnabled(FeatureToggles.BYE_BYE_SEGMENT_ENABLED)

    if (!asAdmin) {
      if (!isByeByeSegmentEnabled) {
        yield call(
          analytics.identify,
          id,
          {
            user_id: id,
            first_name,
            last_name,
            email,
            subscription_tier: subscriptionTier,
            referrer_id,
            referral_amount_cents,
            referral_amount_currency,
            tenant_identifier,
            'User Type': 'Brand Manager',
          },
          {
            integrations: {
              Intercom: isDefaultTenant ? { user_hash: intercom_user_hash } : false,
            },
          },
        )
      } else if (isDefaultTenant) {
        const userProperties = {
          user_id: `${id}`,
          first_name,
          last_name,
          email,
          subscription_tier: subscriptionTier,
          referrer_id,
          referral_amount_cents,
          referral_amount_currency,
          tenant_identifier,
          'User Type': 'Brand Manager',
        }

        yield call(LoadIntercom, {
          app_id: `${process.env.INTERCOM_APP_ID}`,
          user_hash: intercom_user_hash,
          ...userProperties,
        })

        const identifyEvent = new amplitude.Identify()
        Object.entries({ ...userProperties, id }).forEach(([key, value]) =>
          identifyEvent.set(key, `${value}`),
        )

        amplitude.init(process.env.AMPLITUDE_API_KEY!, { userId: userProperties.user_id })
        yield call(amplitude.identify, identifyEvent, { user_id: userProperties.user_id })
      }
    }

    yield put(identifySuccess())
  } catch (error) {
    yield put(identifyError(error))
  }
}

export function* watchIdentifyTracking() {
  yield takeLatest(AnalyticsIdentifyActionTypes.IDENTIFY_REQUEST, handleIdentify)
}
