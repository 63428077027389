import { Reducer } from 'redux'

import { ALL } from '@constants'
import { CampaignStatuses } from '@enums'
import { AuthActionTypes } from '@store/auth'
import {
  ActivateActionTypes,
  ActivateWithFeeActionTypes,
  CampaignActionTypes,
  CampaignsActionTypes,
  ConnectCampaignSocialAccountActionType,
  DisableRequireApprovalPromptActionTypes,
  DisconnectCampaignSocialAccountActionType,
  FinalizeCampaignActionTypes,
  ICampaignsState,
  PauseActionTypes,
  SaveCampaignActionTypes,
  UpdatePreferencesActionTypes,
} from '@store/campaigns'
import { addToSet, removeFromSet, returnSecondIfDeepEqual } from '@utils'

const initialState: ICampaignsState = {
  campaignsById: {},
  errors: undefined,
  isFetching: false,
  isFetched: false,
  isFinalizing: false,
  isActivating: false,
  isActivatingWithFee: false,
  isUpdatingPreferences: false,
  isDisablingRequireApprovalPrompt: false,
  isPausing: false,
  hasSeenPreviewTooltip: false,
  isConnectingIdentity: false,
  campaignIdsByStatus: {
    all: [],
    [CampaignStatuses.Active]: [],
    [CampaignStatuses.InReview]: [],
    [CampaignStatuses.Draft]: [],
    [CampaignStatuses.Scheduled]: [],
    [CampaignStatuses.Paused]: [],
    [CampaignStatuses.Expired]: [],
    [CampaignStatuses.Completed]: [],
  },
  stats: {
    all: 0,
    [CampaignStatuses.Active]: 0,
    [CampaignStatuses.InReview]: 0,
    [CampaignStatuses.Draft]: 0,
    [CampaignStatuses.Scheduled]: 0,
    [CampaignStatuses.Paused]: 0,
    [CampaignStatuses.Expired]: 0,
    [CampaignStatuses.Completed]: 0,
  },
  linksByStatus: {},
}

const reducer: Reducer<ICampaignsState> = (state = initialState, action) => {
  switch (action.type) {
    case CampaignActionTypes.FETCH_REQUEST:
    case CampaignsActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }

    case ActivateActionTypes.ACTIVATE_REQUEST: {
      return {
        ...state,
        isActivating: true,
      }
    }
    case PauseActionTypes.PAUSE_REQUEST: {
      return {
        ...state,
        isPausing: true,
      }
    }

    case DisableRequireApprovalPromptActionTypes.DISABLE_REQUEST: {
      return {
        ...state,
        isDisablingRequireApprovalPrompt: true,
      }
    }

    case ActivateWithFeeActionTypes.ACTIVATE_WITH_FEE_REQUEST: {
      return {
        ...state,
        isActivatingWithFee: true,
      }
    }

    case CampaignsActionTypes.FETCH_SUCCESS: {
      const {
        payload: { items, metadata, status, links },
      } = action

      const linksByStatus = { [ALL]: links?.filter((link) => link.rel === 'create_brief') }
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        errors: undefined,
        campaignsById: items.reduce(
          (accum, curr) => ({
            ...accum,
            [curr.id]: curr,
          }),
          state.campaignsById,
        ),
        campaignIdsByStatus: {
          ...state.campaignIdsByStatus,
          [status]: addToSet([
            ...state.campaignIdsByStatus[status],
            ...items.map((campaign) => campaign.id),
          ]),
        },
        stats: metadata.stats,
        linksByStatus: {
          ...linksByStatus,
          ...state.linksByStatus,
          [status]: links,
        },
      }
    }

    case PauseActionTypes.PAUSE_SUCCESS:
    case ActivateActionTypes.ACTIVATE_SUCCESS: {
      const campaign = action.payload
      const affectedStatuses: ReadonlyArray<CampaignStatuses> = [
        CampaignStatuses.Active,
        CampaignStatuses.Paused,
        CampaignStatuses.Scheduled,
      ]
      const campaignIdsForAffectedStatuses = affectedStatuses.reduce((accum, curr) => {
        const campaignIdsForCurrentStatus = state.campaignIdsByStatus[curr]
        const newSet = removeFromSet(campaignIdsForCurrentStatus || [], campaign.id)
        return {
          ...accum,
          [curr]: newSet,
        }
      }, {})
      return {
        ...state,
        isFetching: false,
        isActivating: false,
        isPausing: false,
        campaignsById: {
          ...state.campaignsById,
          [campaign.id]: {
            ...state.campaignsById[campaign.id],
            ...campaign,
          },
        },
        campaignIdsByStatus: { ...state.campaignIdsByStatus, ...campaignIdsForAffectedStatuses },
      }
    }

    case SaveCampaignActionTypes.SAVE_REQUEST: {
      return { ...state, isSaving: true }
    }

    case DisableRequireApprovalPromptActionTypes.DISABLE_SUCCESS:
    case SaveCampaignActionTypes.SAVE_SUCCESS:
    case CampaignActionTypes.FETCH_SUCCESS: {
      const campaign = action.payload
      return {
        ...state,
        isFetching: false,
        isSaving: false,
        isActivating: false,
        isDisablingRequireApprovalPrompt: false,
        isPausing: false,
        isUpdatingPreferences: false,
        campaignsById: returnSecondIfDeepEqual(
          {
            ...state.campaignsById,
            [campaign.id]: campaign,
          },
          state.campaignsById,
        ),
      }
    }

    case FinalizeCampaignActionTypes.FINALIZE_REQUEST: {
      return {
        ...state,
        isFinalizing: true,
      }
    }

    case FinalizeCampaignActionTypes.FINALIZE_SUCCESS: {
      const campaignId: number = action.payload
      return {
        ...state,
        campaignIdsByStatus: {
          ...state.campaignIdsByStatus,
          [CampaignStatuses.Draft]: state.campaignIdsByStatus[CampaignStatuses.Draft].filter(
            (id) => id !== campaignId,
          ),
        },
        isFinalizing: false,
      }
    }

    case UpdatePreferencesActionTypes.UPDATE_ERROR:
    case DisableRequireApprovalPromptActionTypes.DISABLE_ERROR:
    case FinalizeCampaignActionTypes.FINALIZE_ERROR:
    case PauseActionTypes.PAUSE_ERROR:
    case ActivateActionTypes.ACTIVATE_ERROR:
    case CampaignActionTypes.FETCH_ERROR:
    case SaveCampaignActionTypes.SAVE_ERROR:
    case CampaignsActionTypes.FETCH_ERROR:
    case ActivateWithFeeActionTypes.ACTIVATE_WITH_FEE_ERROR: {
      return {
        ...state,
        isFetching: false,
        isSaving: false,
        isActivating: false,
        isPausing: false,
        isUpdatingPreferences: false,
        isFinalizing: false,
        isActivatingWithFee: false,
        errors: action.payload,
      }
    }

    case UpdatePreferencesActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isUpdatingPreferences: true,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    case CampaignsActionTypes.UPDATE_HAS_SEEN_PREVIEW_TOOLTIP: {
      return {
        ...state,
        hasSeenPreviewTooltip: true,
      }
    }

    case ConnectCampaignSocialAccountActionType.REQUEST: {
      return {
        ...state,
        isConnectingIdentity: true,
      }
    }

    case ConnectCampaignSocialAccountActionType.SUCCESS: {
      return {
        ...state,
        isConnectingIdentity: false,
      }
    }

    case DisconnectCampaignSocialAccountActionType.REQUEST: {
      return {
        ...state,
        isConnectingIdentity: true,
      }
    }

    case DisconnectCampaignSocialAccountActionType.SUCCESS: {
      return {
        ...state,
        isConnectingIdentity: false,
      }
    }

    case DisconnectCampaignSocialAccountActionType.ERROR:
    case ConnectCampaignSocialAccountActionType.ERROR: {
      return {
        ...state,
        isConnectingIdentity: false,
        errors: action.payload,
      }
    }

    case ActivateWithFeeActionTypes.ACTIVATE_WITH_FEE_SUCCESS: {
      return {
        ...state,
        errors: undefined,
        isActivatingWithFee: false,
      }
    }

    default:
      return state
  }
}

export { reducer as campaignsReducer }
