import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'

import TitleBar, { HeaderBackButton } from '@components/UI/TitleBar'
import { HeaderTitle } from '@components/UI/TitleBar/TitleBar.styled'
import { ICampaignContextProps } from '@context/Campaign'
import { BillingTypes } from '@enums'
import { withCampaign } from '@hocs'
import hooks from '@hooks'
import { IApplicationState, IHateoasLink } from '@store'
import { downloadFullReportRequest, selectDownloadFullReportLink } from '@store/billings'
import Theme from '@theme'
import { Button, H5, Text } from '@tribegroup'
import { getBackLink } from '@utils'

interface IInternalProps extends InjectedIntlProps, ICampaignContextProps {
  downloadFullReportLink: IHateoasLink
  downloadFullReportRequest: typeof downloadFullReportRequest
  billingType: BillingTypes
}

export const PurchaseTitleBar: React.FC<IInternalProps> = ({
  intl,
  campaign,
  downloadFullReportLink,
  downloadFullReportRequest: downloadFullReportRequestAction,
}) => {
  const { pathname } = hooks.useLocation()
  const handleDownloadFullReportClick = () => downloadFullReportRequestAction(campaign)
  const hasDownloadLink = (downloadFullReportLink && downloadFullReportLink.href) || undefined

  return (
    <TitleBar
      actions={
        hasDownloadLink && (
          <Button small outlined noFocus onClick={handleDownloadFullReportClick}>
            <Text color={Theme.grey900Color} nowrap>
              <FormattedMessage id="billing.purchaseBreakdown.titlebar.action.downloadCSVReport" />
            </Text>
          </Button>
        )
      }
    >
      <React.Fragment>
        <Link to={{ pathname: getBackLink(pathname) }}>
          <HeaderBackButton
            glyph="nav-back-default"
            size={1.5}
            color={Theme.grey900Color}
            data-tooltip-id="base-tooltip"
            data-tooltip-content={intl.formatMessage({
              id: 'billing.purchaseBreakdown.titlebar.action.back',
            })}
          />
        </Link>
        <HeaderTitle data-cy-element="titlebar-call-to-action">
          <H5 inline color={Theme.grey900Color}>
            <FormattedMessage id="core.text.billing" />
            {campaign.product_name && ` : ${campaign.product_name}`}
          </H5>
        </HeaderTitle>
      </React.Fragment>
    </TitleBar>
  )
}

const mapStateToProps = (state: IApplicationState, { campaign }: ICampaignContextProps) => ({
  downloadFullReportLink: selectDownloadFullReportLink(state, campaign.id),
})

const mapDispatchToProps = {
  downloadFullReportRequest,
}

export default compose<IInternalProps, {}>(
  withCampaign,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(PurchaseTitleBar)
