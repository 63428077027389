import styled, { css } from 'styled-components'

import { themed } from '../Theme'
import { ITextProps } from './Text'

export default themed(styled<ITextProps, any>('span')`
  font-size: 0.875rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  text-transform: none;
  ${({ medium }) =>
    medium &&
    css`
      font-weight: 500;
    `};
  ${({ light }) =>
    light &&
    css`
      font-weight: 300;
    `};
  ${({ small }) =>
    small &&
    css`
      font-size: 0.75rem;
      line-height: 1.33;
      letter-spacing: 0.3px;
    `};
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `};
  ${({ capitalize }) =>
    capitalize &&
    css`
      text-transform: capitalize;
    `};
  ${({ xsmall }) =>
    xsmall &&
    css`
      font-size: 0.6875rem;
      line-height: 0.9375rem;
      letter-spacing: 1px;
    `};
  ${({ xxsmall }) =>
    xxsmall &&
    css`
      font-size: 0.625rem;
      line-height: 1;
      letter-spacing: 0.3px;
    `};
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
  ${({ textAlign }) =>
    textAlign &&
    css`
      display: block;
      text-align: ${textAlign};
    `};
  ${({ nowrap }) =>
    nowrap &&
    css`
      white-space: nowrap;
    `};
  ${({ block }) =>
    block &&
    css`
      display: block;
    `};
  ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `};
  ${({ customFontSize }) =>
    customFontSize &&
    css`
      font-size: ${customFontSize}rem;
    `};
  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight};
    `};
  ${({ customFontWeight }) =>
    customFontWeight &&
    css`
      font-weight: ${customFontWeight};
    `};
  ${({ customletterSpacing }) =>
    customletterSpacing &&
    css`
      letter-spacing: ${customletterSpacing};
    `};
  ${({ textDecoration }) =>
    textDecoration &&
    css`
      text-decoration: ${textDecoration};
    `};
  ${({ verticalAlign }) =>
    verticalAlign &&
    css`
      vertical-align: ${verticalAlign};
    `};
  ${({ truncated }) =>
    truncated &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `};
`)
