import { useState } from 'react'
import React from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { PrimaryLink } from '@components/UI/PrimaryLink/PrimaryLink.styled'
import { TRIBE_INBOX_DEMO_LINK } from '@constants'
import { IModalComponentProps } from '@context/Modal'
import { PaymentMethods } from '@enums'
import { withModal } from '@hocs'
import heartEyedx1 from '@images/heart-eyed-emoji.png'
import heartEyedx2 from '@images/heart-eyed-emoji@2x.png'
import heartEyedx3 from '@images/heart-eyed-emoji@3x.png'
import timeLinex1 from '@images/numbered-timeline-graphic.png'
import timeLinex2 from '@images/numbered-timeline-graphic@2x.png'
import timeLinex3 from '@images/numbered-timeline-graphic@3x.png'
import { IApplicationState } from '@store'
import { selectUserHasSubscription, updatePreferences } from '@store/me'
import { selectUIFlagData, UIFlag } from '@store/ui'
import Theme from '@theme'
import { Checkbox, CloseIcon, Container, H3, Header, Modal, Text } from '@tribegroup'

interface ICampaignModalCampaignCreationPromptUIFlagData {
  paymentMethod: PaymentMethods
}

interface IInternalProps extends IModalComponentProps {
  updatePreferences: typeof updatePreferences
}

const CampaignModalCampaignCreationPrompt: React.FC<IInternalProps> = ({
  onRequestClose,
  isOpen = false,
  updatePreferences: updatePreferencesAction,
}) => {
  const [checked, setChecked] = useState(false)
  const { userHasSubscription, isCreditCardCampaign } = useSelector((state: IApplicationState) => {
    const uiFlagData = selectUIFlagData(
      state,
      UIFlag.SHOW_CAMPAIGN_CREATION_CONFIRMATION_MODAL,
    ) as ICampaignModalCampaignCreationPromptUIFlagData
    return {
      userHasSubscription: selectUserHasSubscription(state),
      isCreditCardCampaign: uiFlagData?.paymentMethod === PaymentMethods.CREDIT_CARD,
    }
  })
  let subtitleId = 'campaign.creation.prompt.subtitle'
  if (isCreditCardCampaign && !userHasSubscription) {
    subtitleId = 'campaign.creation.prompt.activateCreditCardSubtitle'
  }

  const onCheckboxChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement
    const isChecked = target.checked
    updatePreferencesAction({ disable_brief_creation_prompt: isChecked })
    setChecked(isChecked)
  }

  return (
    <Modal reactModalProps={{ isOpen }} width={34.625}>
      <CloseIcon onClick={onRequestClose} />
      <Container
        textAlign="center"
        leftInnerSpacing={0.25}
        rightInnerSpacing={0.25}
        data-testid="campaign-creation-prompt"
      >
        <img
          width={135}
          height={134}
          src={heartEyedx1}
          srcSet={`${heartEyedx1} 300w, ${heartEyedx2} 768w, ${heartEyedx3} 1280w`}
          alt="campaign-creation-prompt-illustration"
        />
        <Container topOuterSpacing={1.75}>
          <H3 color={Theme.grey900Color}>
            <FormattedHTMLMessage id="campaign.creation.prompt.title" />
          </H3>
        </Container>
        <Container topOuterSpacing={1.75} leftInnerSpacing={3} rightInnerSpacing={3}>
          <Text color={Theme.grey800Color}>
            <FormattedHTMLMessage id={subtitleId} />
          </Text>
        </Container>
        <Container topOuterSpacing={2.5}>
          <Header small uppercase color={Theme.grey900Color}>
            <FormattedMessage id="campaign.creation.prompt.steps.title" />
          </Header>
        </Container>
        <Container topOuterSpacing={1.25} flexCenter>
          <img
            width={474}
            src={timeLinex1}
            srcSet={`${timeLinex1} 300w, ${timeLinex2} 768w, ${timeLinex3} 1280w`}
            alt="campaign-creation-prompt-timeline-graphic"
          />
        </Container>
        <Container topOuterSpacing={0.75} leftInnerSpacing={0.75} rightInnerSpacing={0.75}>
          <Flex justifyEvenly>
            <Container textAlign="left" width={9.375} rightOuterSpacing={1}>
              <Text color={Theme.grey800Color} data-testid="campaign-first-step-text">
                <FormattedHTMLMessage id="campaign.creation.prompt.steps.first" />
              </Text>
            </Container>
            <Container textAlign="left" width={9.375} rightOuterSpacing={1}>
              <Text
                color={Theme.grey800Color}
                textAlign="left"
                data-testid="campaign-second-step-text"
              >
                <FormattedMessage id="campaign.creation.prompt.steps.second" />
              </Text>
            </Container>
            <Container textAlign="left" width={9.375}>
              <Text
                color={Theme.grey800Color}
                textAlign="left"
                data-testid="campaign-third-step-text"
              >
                <PrimaryLink>
                  <FormattedHTMLMessage
                    id="campaign.creation.prompt.steps.third"
                    values={{ link: TRIBE_INBOX_DEMO_LINK }}
                  />
                </PrimaryLink>
              </Text>
            </Container>
          </Flex>
        </Container>
      </Container>
      <Container textAlign="left" topOuterSpacing={1.75} leftInnerSpacing={1}>
        <Checkbox
          data-testid="campaign-creation-disable-prompt-checkbox"
          name="dont_show_prompt"
          scheme="primary"
          label={
            <Text small>
              <FormattedMessage id="core.text.dontShowAgain" />
            </Text>
          }
          onChange={onCheckboxChange}
          checked={checked}
        />
      </Container>
    </Modal>
  )
}

const mapDispatchToProps = {
  updatePreferences,
}

export { CampaignModalCampaignCreationPrompt }
export default compose<IInternalProps, IModalComponentProps>(
  connect(undefined, mapDispatchToProps),
  withModal,
)(CampaignModalCampaignCreationPrompt)
