import { useContext } from 'react'

import { FormattedHTMLMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import HoverableInformation from '@components/UI/Information/HoverableInformation/HoverableInformation'
import { CampaignContext } from '@context/Campaign'
import { BrandManagerIdentityProviders, MemberRoleTypes } from '@enums'
import {
  selectCampaignConnectedSocialAccount,
  selectIsConnectingCampaignIdentity,
} from '@store/campaigns'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import ConnectedAccountsDrawerContentFilledIdentitySwitch from '../../Content/Filled/Identity/Switch'

interface IProps {
  isActive?: boolean
  onClick?: VoidFunction
}

const ConnectedAccountsDrawerIdentityTikTokIcon = ({ isActive, onClick }: IProps) => {
  const {
    campaign: { id: campaignId, has_approved_tiktok_submission, has_tiktok_campaign_id, role },
  } = useContext(CampaignContext)

  const isConnectingCampaignIdentity = useSelector(selectIsConnectingCampaignIdentity)

  const { briefConnectedTikTokIdentity } = useSelector((state: IApplicationState) => {
    return {
      briefConnectedTikTokIdentity: selectCampaignConnectedSocialAccount(
        state,
        campaignId,
        BrandManagerIdentityProviders.TikTok,
      ),
    }
  })

  if (
    briefConnectedTikTokIdentity &&
    [MemberRoleTypes.Collaborator, MemberRoleTypes.SolutionCollaborator].includes(role)
  ) {
    return (
      <Container pointer width={2.5}>
        <Flex alignCenter justifyBetween>
          <Container leftInnerSpacing={0.25}>
            <Icon glyph="tick" size={1.5} color={Theme.primaryColor} verticalAlign="middle" />
          </Container>
        </Flex>
      </Container>
    )
  }

  if (briefConnectedTikTokIdentity && (has_approved_tiktok_submission || has_tiktok_campaign_id)) {
    return (
      <Container flexBetween gap={0.25}>
        <HoverableInformation
          width={18.125}
          iconColor={Theme.primaryColor}
          iconColorOnHover={Theme.primaryColor}
        >
          <Container bottomOuterSpacing={0.5}>
            <Text color={Theme.defaultColor} customFontSize={0.8125}>
              <FormattedHTMLMessage id="connectedAccounts.tooltip.turnOffReminder.paragraph.first" />
            </Text>
          </Container>
          <Text color={Theme.defaultColor} customFontSize={0.8125}>
            <FormattedHTMLMessage id="connectedAccounts.tooltip.turnOffReminder.paragraph.second" />
          </Text>
        </HoverableInformation>
        <Icon glyph="tick" size={1.25} color={Theme.primaryColor} />
      </Container>
    )
  }

  return (
    <ConnectedAccountsDrawerContentFilledIdentitySwitch
      defaulValue={isActive}
      disabled={isConnectingCampaignIdentity}
      onClick={onClick}
    />
  )
}

export { ConnectedAccountsDrawerIdentityTikTokIcon }
