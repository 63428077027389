import { call, put, select, takeLatest } from 'redux-saga/effects'

import { selectAuthToken } from '@store/auth'
import {
  fetchStoryMetrics,
  fetchStoryMetricsError,
  fetchStoryMetricsSuccess,
  IStoryMetricsResponse,
  selectStoryMetricsLink,
  StoryMetricsActionTypes,
} from '@store/postCampaignReport'
import { networkRequest } from '@utils'
import { WatcherFunction } from '..'

export function* handleFetchStoryMetrics(action: ReturnType<typeof fetchStoryMetrics>) {
  const { campaignId, channel } = action.payload

  try {
    const storyMetricsLink = yield select(selectStoryMetricsLink, campaignId, channel)

    const authToken: string = yield select(selectAuthToken)
    const storyMetrics: IStoryMetricsResponse = yield call(
      networkRequest,
      storyMetricsLink.method,
      storyMetricsLink.href,
      undefined,
      authToken,
    )

    yield put(fetchStoryMetricsSuccess(storyMetrics, campaignId, channel))
  } catch (error) {
    yield put(fetchStoryMetricsError(error))
  }
}

function* watchFetchStoryMetrics() {
  yield takeLatest(StoryMetricsActionTypes.FETCH_REQUEST, handleFetchStoryMetrics)
}

export const storyMetricsSagas: ReadonlyArray<WatcherFunction> = [watchFetchStoryMetrics]
