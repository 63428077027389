import Slider from 'react-styled-carousel'

import { IMoodboardImage } from '@store/campaigns'
import MediaSliderFilled from './Filled'
import MediaSliderGeneratingPreview from './GeneratingPreview'
import ImageSliderStyled, { Dot } from './ImageSlider.styled'

export interface IImageSliderProps {
  mediaList: ReadonlyArray<IMoodboardImage>
}

const MediaSlider = ({ mediaList }: IImageSliderProps) => {
  return (
    <ImageSliderStyled>
      <Slider showDots Dot={<Dot />} infinite cardsToShow={1} padding="0" autoSlide={false}>
        {(mediaList.filter(Boolean) || []).map((media) =>
          !media.media_url && media.status !== 'ready' ? (
            <MediaSliderGeneratingPreview key={media.media_id} />
          ) : (
            <MediaSliderFilled key={media.media_id} media={media} />
          ),
        )}
      </Slider>
    </ImageSliderStyled>
  )
}

export default MediaSlider
