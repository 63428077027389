import AuthForgetPasswordConfirmation from '@components/Auth/ForgotPassword/Confirmation'
import FullImageBackground from '@components/UI/FullImageBackground'

const ForgotPasswordConfirmationPage = () => (
  <FullImageBackground>
    <AuthForgetPasswordConfirmation />
  </FullImageBackground>
)

export default ForgotPasswordConfirmationPage
