import styled, { css } from 'styled-components'

import Theme from '@theme'

export default styled.div`
  div {
    > button {
      width: 50%;
      height: 100%;
      top: 0;
      margin: 0;
      opacity: 0;
    }
  }
`

export const Dot = styled.li.attrs({
  children: <button type="button">•</button>,
})`
  display: inline-block;
  pointer-events: none;
  /* stylelint-disable-next-line no-descending-specificity */
  button {
    pointer-events: all;
    border: 0;
    background: 0 0;
    padding: 0;
    margin: 0 0.375rem;
    cursor: pointer;
    font-size: 1.75rem;

    ${({ active }) =>
      active ? `color: ${Theme.primaryColor};` : `color: ${Theme.primary200Color};`}
    &:hover {
      color: ${Theme.primaryColor};
    }

    &:focus {
      outline: none;
    }
  }
`

export const MediaVideo = styled.video`
  display: block;
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
`

export const CenterOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${({ direction }) =>
    direction &&
    css`
      flex-direction: ${direction};
    `}
`

export const VideoIconWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 1rem;
  padding-right: 1rem;
`
