import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Price from '@components/UI/Price/Price'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { IBilling, selectCampaignBillingList } from '@store/billings'
import Theme from '@theme'
import { Icon, Text } from '@tribegroup'
import { isEmpty } from '@utils'
import {
  BreakdownArrow,
  BreakdownStyled,
  BreakdownTable,
  BreakdownTableData,
  BreakdownTableHeader,
  BreakdownTableRow,
  BreakdownTableWrapper,
} from './Breakdown.styled'

interface IInternalProps {
  billings: ReadonlyArray<IBilling>
}

interface IBillingBreakdownTableRowProps {
  billing: IBilling
}

export const BillingBreakdownTableRow: React.FC<IBillingBreakdownTableRowProps> = ({ billing }) => {
  const { pathname } = hooks.useLocation()
  const { push } = hooks.useHistory()
  const campaignId = billing.id

  const redirectToSubmissionBreakdown = () => push(`${pathname}/${campaignId}`)
  const hasPurchases = !isEmpty(billing.purchases)
  const rowOnClick = hasPurchases ? redirectToSubmissionBreakdown : undefined
  return (
    <BreakdownTableRow onClick={rowOnClick}>
      <BreakdownTableData>
        <Text color={Theme.grey900Color}>{billing.name}</Text>
      </BreakdownTableData>
      <BreakdownTableData>
        <Text color={Theme.grey900Color}>
          <FormattedMessage id={`billing.paymentMethod.${billing.payment_method}`} />
        </Text>
      </BreakdownTableData>
      <BreakdownTableData>
        {billing.brand_amount_cents ? (
          <Text color={Theme.grey900Color}>
            {`${billing.brand_amount_currency} `}
            <Price quantity={billing.brand_amount_cents} currency={billing.brand_amount_currency} />
          </Text>
        ) : (
          <Text color={Theme.grey900Color}>
            <FormattedMessage id="core.metric.empty" />
          </Text>
        )}
      </BreakdownTableData>
      <BreakdownTableData>
        <Text color={Theme.grey900Color}>
          <FormattedMessage id={`core.text.${billing.status}`} />
        </Text>
      </BreakdownTableData>
      <BreakdownTableData>
        {hasPurchases && (
          <React.Fragment>
            <BreakdownArrow>
              <Icon glyph="nav-back-default" size={1.375} color={Theme.grey700Color} rotate={180} />
            </BreakdownArrow>
            <BreakdownArrow>
              <Icon glyph="nav-back-default" size={1.375} color={Theme.grey900Color} rotate={180} />
            </BreakdownArrow>
          </React.Fragment>
        )}
      </BreakdownTableData>
    </BreakdownTableRow>
  )
}

export const BillingBreakdown: React.FC<IInternalProps> = ({ billings }) => {
  return (
    <BreakdownStyled>
      <BreakdownTableWrapper>
        <BreakdownTable>
          <thead>
            <tr>
              <BreakdownTableHeader width={'50%'}>
                <Text xsmall uppercase color={Theme.grey700Color}>
                  <FormattedMessage id="billing.table.header.campaignName" />
                </Text>
              </BreakdownTableHeader>
              <BreakdownTableHeader>
                <Text xsmall uppercase color={Theme.grey700Color}>
                  <FormattedMessage id="core.text.paymentMethod" />
                </Text>
              </BreakdownTableHeader>
              <BreakdownTableHeader>
                <Text xsmall uppercase color={Theme.grey700Color}>
                  <FormattedMessage id="billing.table.header.amountIncTax" />
                </Text>
              </BreakdownTableHeader>
              <BreakdownTableHeader>
                <Text xsmall uppercase color={Theme.grey700Color}>
                  <FormattedMessage id="billing.table.header.status" />
                </Text>
              </BreakdownTableHeader>
            </tr>
          </thead>
          <tbody>
            {billings.map((billing) => (
              <BillingBreakdownTableRow key={`purchase-${billing.id}`} billing={billing} />
            ))}
          </tbody>
        </BreakdownTable>
      </BreakdownTableWrapper>
    </BreakdownStyled>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  billings: selectCampaignBillingList(state),
})

export default compose<IInternalProps, {}>(connect(mapStateToProps))(BillingBreakdown)
