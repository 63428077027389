import React from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { PAID_PARTNERSHIP_LEARN_HOW } from '@constants'
import { ICurrentSubmissionIdContextProps } from '@context/CurrentSubmissionId'
import { IModalComponentProps } from '@context/Modal'
import { withCurrentSubmissionId, withModal } from '@hocs'
import { useIsMounted } from '@hooks/useIsMounted'
import { IApplicationState } from '@store'
import { selectIsUpdatingPreferences, updatePreferences } from '@store/campaigns'
import { selectCampaignId } from '@store/router'
import { selectIdentityUsername } from '@store/submissions'
import Theme from '@theme'
import { Button, Container, H3, Header, Link, Modal, Text, Tip } from '@tribegroup'
import { MainModalContent } from './AddHandleReminder.styled'

interface IInternalProps extends IModalComponentProps {
  campaignId: number
  username: string
  isUpdatingPreferences: boolean
  updatePreferences: typeof updatePreferences
}

interface IStateProps extends ICurrentSubmissionIdContextProps, RouteComponentProps {}

const CampaignModalPaidPartnershipAddHandleReminder: React.FC<IInternalProps> = ({
  onRequestClose,
  isOpen = false,
  username,
  campaignId,
  isUpdatingPreferences,
  updatePreferences: updatePreferencesAction,
}) => {
  const onDontShowAgainClick = () => {
    if (!isUpdatingPreferences) {
      updatePreferencesAction(campaignId, {
        show_add_handle_reminder: false,
      })
    }
  }

  const isMounted = useIsMounted()

  React.useEffect(() => {
    if (isMounted && !isUpdatingPreferences) {
      onRequestClose()
    }
  }, [isUpdatingPreferences])

  return (
    <Modal reactModalProps={{ isOpen }} width={20}>
      <MainModalContent data-testid="add-handle-reminder-modal">
        <H3
          textOverflowAsEllipsis
          color={Theme.grey900Color}
          bottomOuterSpacing={1}
          data-testid="add-handle-reminder-modal-header"
        >
          <FormattedHTMLMessage
            id="socialSubmission.card.modal.paidpartnership.addHandleReminder.header"
            values={{ username }}
          />
        </H3>
        <Container bottomOuterSpacing={1.75}>
          <Link href={PAID_PARTNERSHIP_LEARN_HOW} target="_blank" color={Theme.secondaryColor}>
            <Text color={Theme.primaryColor}>
              <FormattedMessage id="core.text.learnMore.withArrow" />
            </Text>
          </Link>
        </Container>
        <Container bottomOuterSpacing={0.75}>
          <Tip width={21.5} />
        </Container>
        <Text color={Theme.grey800Color} data-testid="add-handle-reminder-modal-tip">
          <FormattedHTMLMessage id="socialSubmission.card.modal.paidpartnership.addHandleReminder.tip" />
        </Text>
        <Container topOuterSpacing={2.5}>
          <Flex justifyEnd>
            <Button
              quiet
              onClick={onDontShowAgainClick}
              small
              width={9.3125}
              leftInnerSpacing={0}
              rightInnerSpacing={0}
              data-testid="add-handle-reminder-dont-show-again"
            >
              <Header cta small uppercase color={Theme.grey800Color}>
                <FormattedMessage id="core.text.dontShowAgain" />
              </Header>
            </Button>
            <Button
              scheme="primary"
              onClick={onRequestClose}
              small
              width={6.125}
              leftInnerSpacing={0}
              rightInnerSpacing={0}
              leftOuterSpacing={1.25}
              data-testid="add-handle-reminder-ill-do-it-button"
            >
              <Header cta small uppercase color={Theme.whiteColor}>
                <FormattedMessage id="core.text.illDoIt" />
              </Header>
            </Button>
          </Flex>
        </Container>
      </MainModalContent>
    </Modal>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { currentSubmissionId, match }: IStateProps,
) => ({
  campaignId: selectCampaignId(match),
  username: selectIdentityUsername(state, currentSubmissionId as number),
  isUpdatingPreferences: selectIsUpdatingPreferences(state),
})

const mapDispatchToProps = {
  updatePreferences,
}

export { CampaignModalPaidPartnershipAddHandleReminder }
export default compose<IInternalProps, IModalComponentProps>(
  withRouter,
  withCurrentSubmissionId,
  withModal,
  connect(mapStateToProps, mapDispatchToProps),
)(CampaignModalPaidPartnershipAddHandleReminder)
