import React from 'react'

import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { compose } from 'recompose'

import { SideBarBlockItem } from '@components/UI/SideBar'
import { TrackedRoute } from '@components/UI/TrackedRoute'
import { IMobileMenuControlsContextProps } from '@context/MobileMenuControls'
import { BrandFanFilters, EventTrackingNames, PageRoutes } from '@enums'
import { withMobileMenuControls } from '@hocs'
import { IBrandFanStats } from '@store/brandFans'
import { objectToQueryString, queryStringtoObject } from '@utils'
import { isActiveMenu } from '@utils/urls'

interface IProps {
  filter: string
  stats: IBrandFanStats
}

interface IInternalProps extends IMobileMenuControlsContextProps, IProps {}

const BrandFansFiltersIdentity: React.FC<IInternalProps> = ({ filter, stats, hideMobileMenu }) => {
  const count = stats[filter]
  const { pathname, search } = useLocation()
  const searchParams = queryStringtoObject(search)
  return (
    <TrackedRoute
      eventName={
        filter === BrandFanFilters.BrandFans
          ? EventTrackingNames.BrandFansViewBrandFans
          : EventTrackingNames.BrandFansViewAllCreators
      }
      properties={{
        number_of_identities: count,
        source: 'sidebar',
      }}
    >
      <SideBarBlockItem
        key={filter}
        to={{
          pathname: PageRoutes.Creators,
          search: objectToQueryString({ filter }),
        }}
        count={count}
        active={isActiveMenu(pathname, PageRoutes.Creators, searchParams.filter, filter)}
        text={<FormattedMessage id={`brandFans.sidebar.filter.${filter}`} />}
        onClick={hideMobileMenu}
      />
    </TrackedRoute>
  )
}

export { BrandFansFiltersIdentity }
export default compose<IInternalProps, IProps>(withMobileMenuControls)(BrandFansFiltersIdentity)
