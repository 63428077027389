import { PCRChannels } from '@enums'
import { IVideoMetricsResponse, VideoMetricsActionTypes } from '@store/postCampaignReport'

export const fetchVideoMetrics = (campaignId: number, channel: PCRChannels) => ({
  payload: {
    campaignId,
    channel,
  },
  type: VideoMetricsActionTypes.FETCH_REQUEST,
})

export const fetchVideoMetricsSuccess = (
  videoMetrics: IVideoMetricsResponse,
  campaignId: number,
  channel: PCRChannels,
) => ({
  payload: {
    videoMetrics,
    campaignId,
    channel,
  },
  type: VideoMetricsActionTypes.FETCH_SUCCESS,
})

export const fetchVideoMetricsError = error => ({
  payload: error,
  type: VideoMetricsActionTypes.FETCH_ERROR,
})
