import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { Information } from '@components/UI/Information'
import { IApplicationState } from '@store'
import { selectCampaignOperatingRegionTaxName } from '@store/campaigns'
import Theme from '@theme'
import { Container, Header, Text } from '@tribegroup'

interface IProps {
  title: string
  campaignId: number
  currency: string
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

const PostCampaignReportSubmissionListPerformanceLabelWithTooltip: React.FC<IInternalProps> = ({
  title,
  taxDisplay,
}) => (
  <Container width="fit-content" inlineBlock pointer>
    <Information
      delay={1}
      width={15}
      target={
        <Text xsmall color={Theme.grey700Color} uppercase>
          <FormattedMessage id={`postCampaignReport.submission.list.performance.${title}`} />
        </Text>
      }
    >
      <Header
        textAlign="left"
        small
        uppercase
        color={Theme.secondaryColor}
        bottomOuterSpacing={0.5}
      >
        <FormattedMessage
          id={`postCampaignReport.submission.list.performance.${title}.tooltip.header`}
        />
      </Header>
      <Text textAlign="left" color={Theme.defaultColor} customFontSize={0.8125}>
        <FormattedMessage
          id={`postCampaignReport.submission.list.performance.${title}.tooltip`}
          values={{ taxDisplay }}
        />
      </Text>
    </Information>
  </Container>
)

const mapStateToProps = (
  state: IApplicationState,
  { campaignId, currency, intl }: IProps & InjectedIntlProps,
) => {
  return {
    taxDisplay: selectCampaignOperatingRegionTaxName(state, campaignId, intl, currency),
  }
}

const connector = connect(mapStateToProps)

export { PostCampaignReportSubmissionListPerformanceLabelWithTooltip }
export default compose<IInternalProps, IProps>(
  injectIntl,
  connector,
)(PostCampaignReportSubmissionListPerformanceLabelWithTooltip)
