import { createContext, PropsWithChildren, PureComponent } from 'react'

import { MediaTypes } from '@enums'
import { ISubmissionFrame } from '@store/submissions'

interface IMultiframeProviderProps {
  id: string
  frames: ReadonlyArray<ISubmissionFrame>
  startAtFrameNumber?: number
}

interface IMultiframeProviderState {
  activeFrameNumber: number
  animationEnabled?: boolean
  autoplayPaused?: boolean
  activeFrameProgress: number
}

interface IMultiframeControlsFunctions {
  next: VoidFunction
  previous: VoidFunction
  pause: (value: boolean) => void
  goToFrame: (activeFrameNumber: number) => void
  setActiveFrameProgress: (value: number) => void
  playVideo: (frameNumber: number) => void
  stopVideo: (frameNumber: number) => void
  getSlideVideo: (frameNumber: number) => HTMLVideoElement | null
}

export interface IMultiframeContextProps
  extends IMultiframeProviderState,
    IMultiframeControlsFunctions {
  providerId: string
  frames: ReadonlyArray<ISubmissionFrame>
}

export const MultiframeControlContext = createContext({} as IMultiframeProviderState)

export class MultiframeControlsProvider extends PureComponent<
  PropsWithChildren<IMultiframeProviderProps>,
  IMultiframeProviderState
> {
  state = {
    activeFrameNumber: this.props.startAtFrameNumber || 1,
    animationEnabled: true,
    autoplayPaused: false,
    activeFrameProgress: 0,
  }

  componentDidUpdate(prevProps: IMultiframeProviderProps) {
    if (prevProps.frames !== this.props.frames) {
      this.setState({
        activeFrameNumber: this.props.startAtFrameNumber || 1,
        animationEnabled: false,
      })
    }
  }
  goToFrame = (activeFrameNumber: number) => {
    this.setState({
      animationEnabled: true,
      activeFrameNumber,
      activeFrameProgress: 0,
    })
    if (document.fullscreenElement) {
      document.exitFullscreen()
    }
    const video = this.getSlideVideo(activeFrameNumber)
    if (!video) {
      return
    }
    const { paused } = video
    if (!this.state.autoplayPaused && paused) {
      this.playVideo(activeFrameNumber)
    }
  }
  stopVideo = (frameNumber: number) => {
    const video = this.getSlideVideo(frameNumber)
    if (video) {
      video.load()
      video.pause()
    }
  }
  playVideo = (frameNumber: number) => {
    const video = this.getSlideVideo(frameNumber)
    if (video && video.paused) {
      video.play()
    }
  }
  next = () => {
    const { frames } = this.props
    const { activeFrameNumber } = this.state
    this.stopVideo(activeFrameNumber)
    const frameNumber = activeFrameNumber < frames.length ? activeFrameNumber + 1 : frames.length
    this.goToFrame(frameNumber)
  }
  previous = () => {
    const { activeFrameNumber } = this.state
    this.stopVideo(activeFrameNumber)
    const frameNumber = activeFrameNumber <= 1 ? 1 : activeFrameNumber - 1
    this.goToFrame(frameNumber)
  }
  pause = (value: boolean) => {
    this.setState({
      autoplayPaused: value,
    })
  }
  setActiveFrameProgress = (value: number) => {
    this.setState({
      activeFrameProgress: value,
    })
  }
  getSlideVideo = (frameNumber: number) => {
    const { id, frames } = this.props
    const activeFrame = frames[frameNumber - 1]
    const isVideo = activeFrame && activeFrame.media_type === MediaTypes.VIDEO
    if (isVideo) {
      return document.querySelector(`#${id} .media-video-${frameNumber}`) as HTMLVideoElement
    }
    return null
  }
  render() {
    const contextProps: IMultiframeContextProps = {
      ...this.state,
      next: this.next,
      previous: this.previous,
      frames: this.props.frames || [],
      goToFrame: this.goToFrame,
      providerId: this.props.id,
      pause: this.pause,
      playVideo: this.playVideo,
      stopVideo: this.stopVideo,
      getSlideVideo: this.getSlideVideo,
      setActiveFrameProgress: this.setActiveFrameProgress,
    }

    return (
      <MultiframeControlContext.Provider value={contextProps}>
        <div id={this.props.id}>{this.props.children}</div>
      </MultiframeControlContext.Provider>
    )
  }
}

export default MultiframeControlsProvider
