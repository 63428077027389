import React, { PropsWithChildren } from 'react'

import TrackedRoute, { ITrackedRoute } from './TrackedRoute'

interface IConditionalTrackedRouteProps extends ITrackedRoute {
  hasTracking?: boolean
}

const ConditionalTrackedRouteProps: React.FC<PropsWithChildren<IConditionalTrackedRouteProps>> = ({
  children,
  hasTracking,
  ...trackingProps
}) =>
  hasTracking ? (
    <TrackedRoute {...(trackingProps as ITrackedRoute)}>{children}</TrackedRoute>
  ) : (
    <span>{children}</span>
  )

export default ConditionalTrackedRouteProps
