import { useContext } from 'react'

import { useSelector } from 'react-redux'
import { Grid } from 'react-styled-flexboxgrid'

import { TRIBE_PRICING_PAGE_METRIC_SUMMARY_UTM } from '@constants'
import { CampaignContext } from '@context/Campaign'
import { IApplicationState } from '@store'
import { selectReelMetricsSummary } from '@store/postCampaignReport'
import AnalyticsPostCampaignReportMetric from '../Metric/Metric'
import { StyledCol, StyledRow } from '../Summary/Summary.styled'

const externalLink = TRIBE_PRICING_PAGE_METRIC_SUMMARY_UTM

export const AnalyticsPostCampaignReportReel = () => {
  const {
    campaign: { id: campaignId, budget_total_currency, is_price_hidden: isPriceHidden },
  } = useContext(CampaignContext)

  const { metricSummary, currency } = useSelector((state: IApplicationState) => {
    return {
      metricSummary: selectReelMetricsSummary(state, campaignId),
      currency: budget_total_currency,
    }
  })

  return (
    <Grid fluid>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-posts-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.reel.totalPost"
            value={metricSummary.count}
            externalLink={externalLink}
          />
        </StyledCol>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="total-spend-cell">
            <AnalyticsPostCampaignReportMetric
              title="ig.reel.totalSpend"
              value={metricSummary.total_purchase_amount}
              externalLink={externalLink}
              currency={currency}
            />
          </StyledCol>
        )}
        <StyledCol xs={6} lg={3} data-testid="total-followers-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.reel.totalFollowers"
            value={metricSummary.total_followers}
            externalLink={externalLink}
            abbreviateValue
          />
        </StyledCol>

        <StyledCol xs={6} lg={3} data-testid="total-likes-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.reel.likes"
            value={metricSummary.likes}
            externalLink={externalLink}
            abbreviateValue
          />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-comments-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.reel.comments"
            value={metricSummary.comments}
            externalLink={externalLink}
            abbreviateValue
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-shares-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.reel.shares"
            value={metricSummary.shares}
            externalLink={externalLink}
            abbreviateValue
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-engagement-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.reel.engagement"
            value={metricSummary.engagement}
            externalLink={externalLink}
            abbreviateValue
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-engagement-rate-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.reel.engagementRate"
            value={metricSummary.total_engagement_pct}
            externalLink={externalLink}
            showPercentSign
          />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="overall-cpe-cell">
            <AnalyticsPostCampaignReportMetric
              title="ig.reel.overallCostPerEngagement"
              value={metricSummary.cpe}
              externalLink={externalLink}
              currency={currency}
            />
          </StyledCol>
        )}
        <StyledCol xs={6} lg={3} data-testid="total-views-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.reel.totalViews"
            value={metricSummary.video_views}
            externalLink={externalLink}
            abbreviateValue
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-views-pct-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.reel.totalViewsRate"
            value={metricSummary.video_views_pct}
            externalLink={externalLink}
            showPercentSign
          />
        </StyledCol>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="overall-cpm-cell">
            <AnalyticsPostCampaignReportMetric
              title="ig.reel.overallCPM"
              value={metricSummary.cpm}
              externalLink={externalLink}
              currency={currency}
            />
          </StyledCol>
        )}
      </StyledRow>
      {!isPriceHidden && (
        <StyledRow>
          <StyledCol xs={6} lg={3} data-testid="overall-cpv-cell">
            <AnalyticsPostCampaignReportMetric
              title="ig.reel.overallCPV"
              value={metricSummary.cpv}
              externalLink={externalLink}
              currency={currency}
            />
          </StyledCol>
        </StyledRow>
      )}
    </Grid>
  )
}
