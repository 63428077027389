import { IResponseError } from '@lib/error'
import { IHateoasLink } from '..'

export enum NotesActionTypes {
  FETCH_REQUEST = 'notes/FETCH_REQUEST',
  FETCH_SUCCESS = 'notes/FETCH_SUCCESS',
  FETCH_ERROR = 'notes/FETCH_ERROR',

  ADD_REQUEST = 'notes/ADD_REQUEST',
  ADD_SUCCESS = 'notes/ADD_SUCCESS',
  ADD_ERROR = 'notes/ADD_ERROR',
}

export enum MarkAsReadActionTypes {
  MARK_AS_READ_REQUEST = 'notes/MARK_AS_READ_REQUEST',
  MARK_AS_READ_SUCCESS = 'notes/MARK_AS_READ_SUCCESS',
  MARK_AS_READ_ERROR = 'notes/MARK_AS_READ_ERROR',
}

export interface INote {
  created_at: string
  text: string
  author_id: number
  author_name: string
  id: number
}

export interface IAddNoteRequestBody {
  note: string
}

export interface IAddNoteRequestMeta {
  refreshSubmission?: boolean
}

export interface INoteState {
  readonly errors?: IResponseError
  readonly isFetching: boolean
  readonly isAdding: boolean
  readonly notesBySubmissionId: Record<number, ReadonlyArray<INote>>
  readonly notesLinksBySubmissionId: Record<number, ReadonlyArray<IHateoasLink>>
}
