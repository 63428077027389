import { PureComponent } from 'react'

import equal from 'fast-deep-equal'
import Cookies from 'js-cookie'
import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import ActionIcon from '@components/UI/ActionIcon'
import TrackedRoute from '@components/UI/TrackedRoute/TrackedRoute'
import { INBOX_FILTER } from '@constants'
import { ICampaignContextProps } from '@context/Campaign'
import { ISelectedSubmissionsContextProps } from '@context/SelectedSubmissions'
import { EventTrackingNames } from '@enums'
import { withSelectedSubmissions } from '@hocs'
import { IApplicationState } from '@store'
import { ICampaign } from '@store/campaigns'
import { bulkAddFeedback } from '@store/feedback'
import { bulkDecline, selectSubmissionsStats } from '@store/submissions'
import Theme from '@theme'
import { Button, Header, Text } from '@tribegroup'
import { showCookieToast } from '@utils'
import { ActionIconWrapper } from '../TitleBar.styled'
import InboxTitleBarSelectionModeBulkDecline from './Modal/BulkDecline/BulkDecline'
import InboxTitleBarSelectionModeBulkFeedback from './Modal/BulkFeedback/BulkFeedback'
import {
  CancelButton,
  CancelButtonWrapper,
  InboxTitleBarSelectionModeStyled,
  SelectAll,
  SelectedCount,
  SelectionModeActions,
} from './SelectionMode.styled'

export interface IInboxTitleBarSelectionModeProps {
  campaign: ICampaign
  filter: string
  sortBy?: string
  onSelectAllSubmissions: VoidFunction
  onCancel: VoidFunction
  hasBulkFeedbackPermission: boolean
  hasBulkDeclinePermission: boolean
  totalSubmissions: number
}

interface IInternalProps
  extends IInboxTitleBarSelectionModeProps,
    ISelectedSubmissionsContextProps,
    InjectedIntlProps,
    ICampaignContextProps {
  totalSubmissions: number
  selectedCount: number
  hasSelectedSubmissions?: boolean
  bulkAddFeedback: typeof bulkAddFeedback
  bulkDecline: typeof bulkDecline
}

interface IInboxTitleBarSelectionModeState {
  isOpenModalBulkFeedback: boolean
  isOpenModalBulkDecline: boolean
}

export class InboxTitleBarSelectionMode extends PureComponent<
  IInternalProps,
  IInboxTitleBarSelectionModeState,
  ISelectedSubmissionsContextProps
> {
  state = {
    isOpenModalBulkDecline: false,
    isOpenModalBulkFeedback: false,
  }

  componentDidUpdate(_, prevState) {
    if (
      !equal(prevState, this.state) &&
      !this.state.isOpenModalBulkDecline &&
      !this.state.isOpenModalBulkFeedback &&
      !Cookies.get('tribecookieread')
    ) {
      showCookieToast()
    }
  }

  onBulkDeclineClick = () => {
    if (this.props.selectedCount > 0) {
      this.props.hideToast()
      this.setState({
        isOpenModalBulkDecline: true,
      })
    }
  }

  onBulkFeedbackClick = () => {
    if (this.props.selectedCount > 0) {
      this.props.hideToast()
      this.setState({
        isOpenModalBulkFeedback: true,
      })
    }
  }

  onCancel = () => {
    this.setState({
      isOpenModalBulkFeedback: false,
      isOpenModalBulkDecline: false,
    })
  }

  render() {
    const {
      intl,
      selectedCount,
      onCancel,
      hasBulkFeedbackPermission,
      hasBulkDeclinePermission,
      totalSubmissions,
      campaign,
      filter,
      selectedSubmissionIds,
    } = this.props
    const hasSelectedSubmissions = selectedCount > 0
    const isBulkDeclineOperationAllowed =
      hasBulkDeclinePermission && (filter === INBOX_FILTER || filter === 'shortlisted')
    return (
      <InboxTitleBarSelectionModeStyled full alignCenter justifyBetween>
        <InboxTitleBarSelectionModeBulkDecline
          submissionIds={selectedSubmissionIds}
          campaignId={campaign.id}
          isOpen={this.state.isOpenModalBulkDecline}
          onCancel={this.onCancel}
        />
        <InboxTitleBarSelectionModeBulkFeedback
          submissionIds={selectedSubmissionIds}
          campaignId={campaign.id}
          isOpen={this.state.isOpenModalBulkFeedback}
          onCancel={this.onCancel}
        />
        <SelectionModeActions alignCenter justifyStart>
          <TrackedRoute
            eventName={EventTrackingNames.BulkSelectSelectAll}
            properties={{
              brief_id: campaign.id,
              role: campaign.role,
              current_filter: filter,
            }}
          >
            <SelectAll onClick={this.props.onSelectAllSubmissions}>
              <Button outlined small noFocus>
                <Text>
                  <FormattedMessage id="inbox.cardlist.selection.selectAll" />
                </Text>
              </Button>
            </SelectAll>
          </TrackedRoute>
          {hasBulkFeedbackPermission && (
            <ActionIconWrapper>
              <ActionIcon
                glyph="feedback"
                color={hasSelectedSubmissions ? Theme.grey900Color : Theme.grey700Color}
                tooltip={intl.formatMessage({ id: 'submission.card.action.feedback' })}
                size={1.5}
                disabled={!hasSelectedSubmissions}
                onClick={this.onBulkFeedbackClick}
              />
            </ActionIconWrapper>
          )}
          {isBulkDeclineOperationAllowed && (
            <ActionIconWrapper>
              <ActionIcon
                glyph="decline-submission"
                color={hasSelectedSubmissions ? Theme.grey900Color : Theme.grey700Color}
                tooltip={intl.formatMessage({ id: 'submission.card.action.decline' })}
                size={1.5}
                disabled={!hasSelectedSubmissions}
                onClick={this.onBulkDeclineClick}
              />
            </ActionIconWrapper>
          )}
        </SelectionModeActions>
        <SelectedCount hilightCount={hasSelectedSubmissions}>
          <Header color={Theme.grey700Color}>
            <FormattedHTMLMessage
              id="inbox.cardlist.selection.count"
              values={{ count: selectedCount, total: totalSubmissions }}
            />
          </Header>
        </SelectedCount>
        <CancelButtonWrapper>
          <CancelButton onClick={onCancel}>
            <Text>
              <FormattedMessage id="core.text.cancel" />
            </Text>
          </CancelButton>
        </CancelButtonWrapper>
      </InboxTitleBarSelectionModeStyled>
    )
  }
}

const mapStateToProps = (
  state: IApplicationState,
  { filter = INBOX_FILTER, selectedSubmissionIds, campaign }: IInternalProps,
) => {
  const submissionStats = selectSubmissionsStats(state, campaign.id)
  const totalSubmissions = submissionStats[filter] || 0
  const selectedCount = selectedSubmissionIds.length

  return { totalSubmissions, selectedCount }
}

const mapDispatchToProps = {
  bulkAddFeedback,
  bulkDecline,
}

export default compose<IInternalProps, IInboxTitleBarSelectionModeProps>(
  injectIntl,
  withSelectedSubmissions,
  connect(mapStateToProps, mapDispatchToProps),
)(InboxTitleBarSelectionMode)
