import { useState } from 'react'

import { FormattedMessage } from 'react-intl'

import ActionIcon from '@components/UI/ActionIcon'
import Drawer from '@components/UI/Drawer'
import { SocialSubmissionTypes } from '@enums'
import Theme from '@theme'
import { Container, Header, Icon, Text } from '@tribegroup'
import { MetricEntry } from '../types'
import GlossaryDefinition from './Definition'

type Props = {
  entries: MetricEntry[]
  currency: string
  submissionType: SocialSubmissionTypes
}

export const AnalyticsBreakdownGlossary: React.FC<Props> = ({
  entries,
  submissionType,
  currency,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const openDrawer = () => {
    setIsOpen(true)
  }
  const closeDrawer = () => {
    setIsOpen(false)
  }
  return (
    <>
      <Drawer
        title={<FormattedMessage id="core.text.glossary" />}
        wrapperCallback={closeDrawer}
        headerRightIcon={
          <ActionIcon size={1.5} glyph="close-x" color={Theme.grey900Color} onClick={closeDrawer} />
        }
        showHeaderDivider
        open={isOpen}
        width={31.875}
      >
        <Container
          leftInnerSpacing={1.5}
          rightInnerSpacing={1.5}
          bottomInnerSpacing={1.25}
          topInnerSpacing={1.25}
          data-testid="glossary-container"
        >
          {entries.map(({ metric }) => (
            <Container key={`glossary-${metric}`} bottomOuterSpacing={1}>
              <Text small medium color={Theme.defaultColor}>
                <FormattedMessage id={`analytics.breakdown.metric.${metric}`} />
              </Text>
              <Text
                customFontSize={0.8125}
                block
                color={Theme.defaultColor}
                data-testid={`glossary-definition-${metric}`}
              >
                <GlossaryDefinition
                  metric={metric}
                  submissionType={submissionType}
                  currency={currency}
                />
              </Text>
            </Container>
          ))}
        </Container>
      </Drawer>
      <Container
        data-testid="glossary-button"
        inline
        pointer
        verticalAlign="middle"
        onClick={openDrawer}
      >
        <Header inline cta color={Theme.defaultColor}>
          <FormattedMessage id="core.text.glossary" />
        </Header>
        <Container inlineBlock relative topPosition={0.375}>
          <Icon glyph="chevron-left" size={1.5} color={Theme.primaryColor} />
        </Container>
      </Container>
    </>
  )
}
