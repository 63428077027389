import { differenceInMilliseconds } from 'date-fns'
import { delay } from 'redux-saga'
import { call, cancel, fork, put, select, take } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import {
  AuthActionTypes,
  authError,
  authSuccess,
  refreshToken,
  selectAuthExpiration,
  selectAuthToken,
  selectRefreshTokenLink,
} from '@store/auth'
import { networkRequest } from '@utils'

export function* handleRefreshToken(action: ReturnType<typeof refreshToken>) {
  const FIVE_MINUTES = 5 * 60 * 1000
  try {
    // tslint:disable-next-line:no-loop-statement
    while (true) {
      const authToken = yield select(selectAuthToken)
      const expiration = yield select(selectAuthExpiration)
      const refreshLink: IHateoasLink = yield select(selectRefreshTokenLink)
      const msDiff = differenceInMilliseconds(new Date(expiration), new Date(action.payload))
      const msDiffWithBuffer = msDiff - FIVE_MINUTES

      yield call(delay, msDiffWithBuffer)

      const result = yield call(
        networkRequest,
        refreshLink.method,
        refreshLink.href,
        { access_token: authToken },
        authToken,
      )
      yield put(authSuccess(result))
      yield put(refreshToken())
    }
  } catch (error) {
    yield put(authError(error))
  }
}

export function* watchRefreshTokenRequest() {
  // tslint:disable-next-line:no-loop-statement
  while (true) {
    const action = yield take(AuthActionTypes.REFRESH_REQUEST)
    const refreshTask = yield fork(handleRefreshToken, action)
    yield take(AuthActionTypes.REFRESH_CANCEL)
    yield cancel(refreshTask)
  }
}
