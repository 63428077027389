import * as yup from 'yup'

export const completeSignUpSchema = yup.object({
  first_name: yup.string().required('auth.signup.error.firstName.required').trim(),
  last_name: yup.string().required('auth.signup.error.lastName.required').trim(),
  phone_number: yup.string().optional(),

  password: yup
    .string()
    .required('auth.signup.error.password.required')
    .min(8, 'auth.signup.error.password.min'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), null], 'auth.completeSignup.error.confirmPassword.match'),

  terms_and_conditions: yup.boolean().test({
    test: Boolean,
    message: 'auth.signup.error.terms.checked',
  }),
  marketing_newsletters: yup.boolean(),
})

export type CompleteSignupFormDataType = yup.InferType<typeof completeSignUpSchema>

export enum CompleteSignUpActionType {
  PENDING = 'completeSignUp/pending',
  FULFILLED = 'completeSignUp/fulfilled',
  FAILED = 'completeSignUp/failed',
}

export interface ICompleteSignupState {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  errors?: any
}

export enum CompleteSignUpErrorCodes {
  TOKEN_EXPIRED = 'brand_manager.complete_verification.verification_token_expired',
  ACCOUNT_ALREADY_VERIFIED = 'brand_manager.complete_verification.account_already_verified',
  INVALID_TOKEN = 'brand_manager.complete_verification.invalid_verification_token',
}
