import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { EMPTY_LIST } from '@constants'
import { IApplicationState } from '@store'
import { selectAudienceAgeRange } from '@store/rootResource'
import Theme from '@theme'
import { BlockText } from '../Block/Block.styled'

interface IProps {
  selectedAgeRange: ReadonlyArray<string>
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

export const getCompactAgeValues = (
  selectedAgeRange: ReadonlyArray<string>,
  audienceAgeRange: ReadonlyArray<string>,
) => {
  const rawPossibleMaxAge = selectedAgeRange!.find((value) => value.includes('+'))
  return audienceAgeRange.reduce((accum: string[], curr) => {
    if (!selectedAgeRange!.includes(curr)) {
      return accum
    }

    const [savedRangeStart, savedRangeEnd] = accum[accum.length - 1]?.split('-') || EMPTY_LIST

    const startOfCurrentRange = parseInt(curr.split('-')[0], 10)
    const endOfCurrentRange = isNaN(curr.split('-')[1] as any)
      ? rawPossibleMaxAge
      : parseInt(curr.split('-')[1], 10)

    if (parseInt(savedRangeEnd) + 1 === startOfCurrentRange) {
      return [...accum.slice(0, accum.length - 1), `${savedRangeStart}-${endOfCurrentRange}`]
    } else {
      return [...accum, curr]
    }
  }, [])
}

const CampaignPreviewPreferredAudienceAge: React.FC<IInternalProps> = ({
  selectedAgeRange,
  audienceAgeRange,
}) => {
  const valuesToDisplay = getCompactAgeValues(selectedAgeRange, audienceAgeRange)
  return (
    <BlockText small color={Theme.grey800Color}>
      <FormattedMessage
        id="campaign.preview.influencerDetails.age"
        values={{
          ageRange: valuesToDisplay.join(', '),
        }}
      />
    </BlockText>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  audienceAgeRange: selectAudienceAgeRange(state),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(connector)(CampaignPreviewPreferredAudienceAge)
