import { WatcherFunction } from '..'
import { watchAddNoteRequest } from './add'
import { watchListNotesRequest } from './list'
import { watchMarkAsReadRequest } from './markAsRead'

export const notesSagas: ReadonlyArray<WatcherFunction> = [
  watchListNotesRequest,
  watchAddNoteRequest,
  watchMarkAsReadRequest,
]
