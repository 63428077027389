import { call, put, select, takeEvery } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  CampaignActionTypes,
  fetchCampaign,
  fetchCampaignError,
  fetchCampaignSuccess,
} from '@store/campaigns'
import { selectCampaignLink } from '@store/me'
import { networkRequest } from '@utils'
import { handleCampaignPermissionError } from './permissionError'

export function* handleFetchCampaign(action: ReturnType<typeof fetchCampaign>) {
  try {
    const history = action.meta && action.meta.history
    const authToken: string = yield select(selectAuthToken)
    const campaignsLink: IHateoasLink = yield select(selectCampaignLink)
    const result = yield call(
      networkRequest,
      campaignsLink.method,
      campaignsLink.href.replace('{brief_id}', String(action.payload)),
      undefined,
      authToken,
    )
    yield put(fetchCampaignSuccess(result, history ? { location: history.location } : undefined))
  } catch (error) {
    yield put(fetchCampaignError(error))
    yield call(handleCampaignPermissionError, action)
  }
}

export function* watchFetchCampaignRequest() {
  yield takeEvery(CampaignActionTypes.FETCH_REQUEST, handleFetchCampaign)
}
