import { ValueLockType } from '@store/performance'

export interface IShortlistSummary {
  est_engagement: ValueLockType | number
  est_cpe: ValueLockType | number
  est_reach: ValueLockType | number
  est_reach_pct: ValueLockType | number
  est_impressions: ValueLockType | number
  est_cpm: ValueLockType | number
}

export interface IShortlistSummaryState {
  shortlistSummaryByCampaignId: Record<number, IShortlistSummary>
  isFetching: boolean
  errors: undefined
}

export enum ShortlistSummaryActionTypes {
  FETCH_REQUEST = 'shortlistSummary/FETCH_REQUEST',
  FETCH_SUCCESS = 'shortlistSummary/FETCH_SUCCESS',
  FETCH_ERROR = 'shortlistSummary/FETCH_ERROR',
}
