import { FetchInfluencerIdentitySubmissionsActionTypes } from '@store/brandFans'
import { ISubmission } from '@store/submissions'

export const fetchSubmissions = (identityId: number) => ({
  payload: identityId,
  type: FetchInfluencerIdentitySubmissionsActionTypes.FETCH_REQUEST,
})

export const fetchSubmissionsSuccess = (
  identityId: number,
  submissions: ReadonlyArray<ISubmission>,
) => ({
  payload: { identityId, submissions },
  type: FetchInfluencerIdentitySubmissionsActionTypes.FETCH_SUCCESS,
})

export const fetchSubmissionsError = (identityId: number, error: any) => ({
  payload: { identityId, error },
  type: FetchInfluencerIdentitySubmissionsActionTypes.FETCH_ERROR,
})
