import { IMeta } from '@store'
import { ChangePasswordActionTypes, IPassword } from '@store/me'

export const changePassword = (params: IPassword, meta: IMeta = {}) => ({
  payload: params,
  meta,
  type: ChangePasswordActionTypes.CHANGE_PASSWORD_REQUEST,
})

export const changePasswordSuccess = () => ({
  type: ChangePasswordActionTypes.CHANGE_PASSWORD_SUCCESS,
})

export const changePasswordError = (error: any) => ({
  type: ChangePasswordActionTypes.CHANGE_PASSWORD_ERROR,
  payload: error,
})
