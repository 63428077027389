import { PureComponent } from 'react'

import { IMultiframeContextProps } from '@context/MultiframeControls'
import { withMultiframeControls } from '@hocs'
import { ISubmissionFrame } from '@store/submissions'
import { Dot } from './Dot/Dot'
import { StyledDotsWrapper } from './Dots.styled'

const VISIBLE_ACTIVE_FRAME_ALLOWANCE = 5

export interface ICurrentRange {
  start: number
  end: number
}

interface IMultiframePaginationDotsState {
  previous: number
  next: number
  currentRange: ICurrentRange
}

const getAdjustedVisibleDotsState = (state: IMultiframePaginationDotsState, adjustment: number) => {
  return {
    previous: state.previous + adjustment,
    next: state.next + adjustment,
    currentRange: {
      start: state.currentRange.start + adjustment,
      end: state.currentRange.end + adjustment,
    },
  }
}

export const DotsUtil = {
  getAdjustedVisibleDotsState,
}

export class MultiframePaginationDots extends PureComponent<
  IMultiframeContextProps,
  IMultiframePaginationDotsState
> {
  state = {
    previous: 0,
    next: VISIBLE_ACTIVE_FRAME_ALLOWANCE - 1,
    currentRange: {
      start: 1,
      end: VISIBLE_ACTIVE_FRAME_ALLOWANCE,
    },
  }

  componentDidMount() {
    const { activeFrameNumber } = this.props
    if (activeFrameNumber >= this.state.next) {
      const nextActiveFrame = activeFrameNumber + 1
      this.adjustVisibleDots(nextActiveFrame - this.state.next)
    }
  }

  componentDidUpdate(prevProps: IMultiframeContextProps) {
    if (prevProps && prevProps.activeFrameNumber) {
      const { activeFrameNumber } = this.props
      if (activeFrameNumber !== prevProps.activeFrameNumber) {
        this.calculateVisibleDots(activeFrameNumber)
      }
    }
  }

  calculateVisibleDots = (activeFrameNumber: number) => {
    const { previous, next } = this.state
    const showPrevious = activeFrameNumber === previous
    const showNext = activeFrameNumber === next
    if (showPrevious) {
      this.adjustVisibleDots(-1)
    } else if (showNext) {
      this.adjustVisibleDots(1)
    }
  }

  adjustVisibleDots = (adjustment: number) => {
    this.setState((prevState) => {
      return DotsUtil.getAdjustedVisibleDotsState(prevState, adjustment)
    })
  }

  render() {
    const { frames, activeFrameNumber } = this.props
    const { currentRange, next, previous } = this.state
    return (
      <StyledDotsWrapper center data-testid="pagination-dots">
        {frames.map((frame: ISubmissionFrame, index: number) => (
          <Dot
            key={`${frame.frame_number}-${index}`}
            activeFrameNumber={activeFrameNumber}
            frameNumber={frame.frame_number || index + 1}
            currentRange={currentRange}
            next={next}
            previous={previous}
          />
        ))}
      </StyledDotsWrapper>
    )
  }
}

export default withMultiframeControls(MultiframePaginationDots)
