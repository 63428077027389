import { call, put, select, takeLatest } from 'redux-saga/effects'

import { selectAuthToken } from '@store/auth'
import {
  FetchBrandFanGroupsActionTypes,
  fetchBrandFanGroupsError,
  fetchBrandFanGroupsSuccess,
} from '@store/brandFans'
import { IBrandFansLink, selectBrandFanGroupsLink } from '@store/me'
import { networkRequest } from '@utils'

export function* handleFetchBrandFanGroups() {
  try {
    const brandFanGroupsLink: IBrandFansLink = yield select(selectBrandFanGroupsLink)
    const authToken: string = yield select(selectAuthToken)
    const { items, metadata, links } = yield call(
      networkRequest,
      brandFanGroupsLink.method,
      brandFanGroupsLink.href,
      undefined,
      authToken,
    )
    yield put(fetchBrandFanGroupsSuccess(items, metadata, links))
  } catch (error) {
    yield put(fetchBrandFanGroupsError(error))
  }
}

export function* watchBrandFanGroupsRequest() {
  yield takeLatest(FetchBrandFanGroupsActionTypes.FETCH_REQUEST, handleFetchBrandFanGroups)
}
