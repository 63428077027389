import React from 'react'

import PublishSchedule from '@components/Submission/Card/PublishSchedule/PublishSchedule'
import StatusDate from '@components/Submission/Card/StatusDate/StatusDate'
import { SubmissionStatuses } from '@enums'
import { ISubmission } from '@store/submissions'

interface IProps {
  submission: ISubmission
}

const SubmissionCardActionBarSocialPublishDate: React.FC<IProps> = ({ submission }) => {
  const showPublishSchedule =
    submission.status === SubmissionStatuses.Approved && Boolean(submission.scheduled_publish_date)

  const showPublishedDate =
    submission.status === SubmissionStatuses.Published && Boolean(submission.published_at)
  return (
    <React.Fragment>
      {showPublishSchedule && (
        <PublishSchedule
          publishSchedule={submission.scheduled_publish_date!}
          publishWithinHours={submission.publish_within_hours}
          influencerName={submission.identity_username}
        />
      )}
      {showPublishedDate && (
        <StatusDate date={submission.published_at!} status={SubmissionStatuses.Published} />
      )}
    </React.Fragment>
  )
}

export default SubmissionCardActionBarSocialPublishDate
