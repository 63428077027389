import { Fragment } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { compose } from 'recompose'

import AnalyticsAudienceMetricsWrapper from '@components/Analytics/AudienceMetrics'
import AnalyticsPostPerformance from '@components/Analytics/PostPerformance/PostPerformance'
import InfluencerSubmissions from '@components/Inbox/InfluencerIdentity/Submissions/Submissions'
import SubmissionCardFull from '@components/Submission/Card/Full/Full'
import SubmissionInfluencerProfile from '@components/Submission/Influencer/Profile'
import FullLoading from '@components/UI/Full/Loading'
import SinglePageContainer from '@components/UI/SinglePageContainer/SinglePageContainer'
import { ICampaignContextProps } from '@context/Campaign'
import { MultiframeControlsProvider } from '@context/MultiframeControls'
import { ISubmissionContextProps } from '@context/Submission'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import { BrandManagerIdentityProviders, SubmissionStatuses, SupportedSubmissionTypes } from '@enums'
import {
  withCampaignNoRenderBlock,
  withSubmissionListFilter,
  withSubmissionNoRenderBlock,
} from '@hocs'
import { IApplicationState } from '@store'
import {
  selectHasSubmissionPerformanceLink,
  selectIsLoadingSubmissionPage,
  selectIsSubmissionFromInstagram,
  selectIsSubmissionFromTiktok,
} from '@store/submissions'
import { isSocialCampaign } from '@utils/campaign'
import SubmissionChatOpenOnLoadWatcher from '../Chat/OpenOnLoadWatcher'
import SubmissionPerformance from '../Performance/Performance'
import SubmissionSummaryNavigationWrapper from './Navigation/NavigationWrapper'
import { SummaryWrapper } from './Summary.styled'

export interface IInternalProps
  extends ICampaignContextProps,
    ISubmissionContextProps,
    ISubmissionListFilterContextProps,
    RouteComponentProps {
  showLoadingWrapper?: boolean
  isSubmissionFromInstagram?: boolean
  isSubmissionFromTikTok?: boolean
  hasSubmissionPerformanceLink?: boolean
}
export const SubmissionSummary = ({
  submission,
  campaign,
  hasSubmissionPerformanceLink,
  showLoadingWrapper,
  isCampaignFetched,
  isSubmissionFetched,
  isSubmissionFromInstagram,
  isSubmissionFromTikTok,
}: IInternalProps) => {
  const isPinterest = submission.identity_provider === BrandManagerIdentityProviders.Pinterest

  const showAudienceMetrics = !isPinterest && !submission.is_proxy && !submission.proxy_submission
  const isSubmissionAndCampaignFetched = isSubmissionFetched && isCampaignFetched

  if (showLoadingWrapper || !isSubmissionAndCampaignFetched) {
    return (
      <SinglePageContainer>
        <SubmissionSummaryNavigationWrapper>
          <FullLoading />
        </SubmissionSummaryNavigationWrapper>
      </SinglePageContainer>
    )
  }

  const isSocialSubmission = isSocialCampaign(campaign)
  const submissionType = isSocialSubmission
    ? SupportedSubmissionTypes.SocialSubmission
    : SupportedSubmissionTypes.ContentSubmission

  const isSubmissionPublished = submission!.status === SubmissionStatuses.Published

  const showPerformance = hasSubmissionPerformanceLink && isSubmissionPublished
  const supportAnalytics = isSubmissionFromInstagram || isSubmissionFromTikTok

  return (
    <SinglePageContainer>
      <SubmissionChatOpenOnLoadWatcher submissionId={submission.id} campaignId={campaign.id} />
      <SummaryWrapper>
        <MultiframeControlsProvider id="submission-card-summary" frames={submission.media_objects}>
          <SubmissionSummaryNavigationWrapper>
            <SubmissionCardFull
              submission={submission!}
              submissionType={submissionType}
              campaign={campaign}
              showLicenseRequestAsApprove={!isSocialSubmission}
            />
          </SubmissionSummaryNavigationWrapper>
        </MultiframeControlsProvider>
      </SummaryWrapper>
      <InfluencerSubmissions />

      {isSocialSubmission && (
        <Fragment>
          {showPerformance && (
            <Fragment>
              {supportAnalytics ? <AnalyticsPostPerformance /> : <SubmissionPerformance />}
            </Fragment>
          )}
          {!isSubmissionPublished && <SubmissionInfluencerProfile />}
        </Fragment>
      )}
      {showAudienceMetrics && (
        <AnalyticsAudienceMetricsWrapper campaignId={campaign.id} submissionId={submission.id} />
      )}
    </SinglePageContainer>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { submission, campaign, filter, isSubmissionFetched, isCampaignFetched }: IInternalProps,
) => {
  const isSubmissionAndCampaignFetched = isSubmissionFetched && isCampaignFetched
  if (!isSubmissionAndCampaignFetched) {
    return {}
  }

  return {
    showLoadingWrapper: selectIsLoadingSubmissionPage(state, campaign.id, filter, submission.id),
    isSubmissionFromInstagram: selectIsSubmissionFromInstagram(state, submission.id),
    isSubmissionFromTikTok: selectIsSubmissionFromTiktok(state, submission.id),
    hasSubmissionPerformanceLink: selectHasSubmissionPerformanceLink(state, submission.id),
  }
}

export default compose<IInternalProps, {}>(
  withSubmissionListFilter,
  withCampaignNoRenderBlock,
  withSubmissionNoRenderBlock,
  connect(mapStateToProps),
)(SubmissionSummary)
