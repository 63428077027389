import React from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { FlexItem } from 'styled-flex-component'

import { EllipsisWrapper } from '@components/InfluencerIdentity/Row/Details/Details.styled'
import {
  ThumbnailImageIconWrapper,
  ThumbnailImageWrapper,
} from '@components/Submission/Thumbnail/Image/Image.styled'
import Image from '@components/UI/Image'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IPostCampaignReportSubmissionIdContextProps } from '@context/PostCampaignReportSubmissionId'
import { AspectRatios, EventTrackingNames } from '@enums'
import { withContextPostCampaignReportSubmissionId } from '@hocs'
import { FadeIn } from '@lib/effects'
import { IApplicationState } from '@store'
import {
  selectInfluencerHandle,
  selectInfluencerImageURL,
  selectInfluencerName,
  selectIsCarousel,
  selectSocialPageURL,
  selectSubmissionChannel,
} from '@store/postCampaignReport'
import { selectActivePCRChannel, selectCampaignId } from '@store/router'
import { viewSocial } from '@store/submissions'
import Theme from '@theme'
import { Container, H5, Icon, Link as AnchorLink, Text } from '@tribegroup'
import { ProfileIconWrapper, SubmissionImageWrapper } from './Profile.styled'

export interface IInternalProps {
  influencerName: string
  influencerHandle: string
  submissionImageURL: string
  channel: string
  campaignId: number
  submissionId: number
  socialPageURL: string
  activePCRChannel: string
  isCarousel?: boolean
  viewSocial: typeof viewSocial
}

const PostCampaignReportSubmissionListProfile: React.FC<IInternalProps> = ({
  influencerName,
  influencerHandle,
  submissionImageURL,
  channel,
  campaignId,
  submissionId,
  socialPageURL,
  isCarousel,
  activePCRChannel,
  viewSocial: viewSocialAction,
}) => {
  const onViewSocial = () => viewSocialAction(campaignId, submissionId)
  return (
    <React.Fragment>
      <FlexItem noShrink>
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.ViewSubmission}
          campaignId={campaignId}
          submissionId={submissionId}
          additionalProperties={{
            source: 'campaign_metrics',
          }}
        >
          <Link
            to={`/campaigns/${campaignId}/submissions/${submissionId}?from=${activePCRChannel}`}
          >
            <SubmissionImageWrapper>
              <ThumbnailImageWrapper>
                <Image
                  src={submissionImageURL}
                  aspectRatio={AspectRatios.ONE_BY_ONE}
                  animation={FadeIn}
                  loadingColor={Theme.defaultColor}
                />
                {isCarousel && (
                  <ThumbnailImageIconWrapper>
                    <Icon size={1.25} glyph="carousel" color={Theme.whiteColor} />
                  </ThumbnailImageIconWrapper>
                )}
              </ThumbnailImageWrapper>
            </SubmissionImageWrapper>
          </Link>
        </ConnectedTrackedRoute>
      </FlexItem>
      <FlexItem>
        <EllipsisWrapper color={Theme.defaultColor}>
          <AnchorLink href={socialPageURL} target="_blank" onClick={onViewSocial}>
            <H5 inline color={Theme.defaultColor} rightOuterSpacing={0.75}>
              {influencerName}
            </H5>
          </AnchorLink>
        </EllipsisWrapper>
        <EllipsisWrapper color={Theme.grey700Color}>
          <AnchorLink href={socialPageURL} target="_blank" onClick={onViewSocial}>
            <ProfileIconWrapper rightOuterSpacing={0.25} topOuterSpacing={0.125} inlineBlock>
              <Icon size={0.75} glyph={`social-${channel}-colour`} />
            </ProfileIconWrapper>
            <Container rightOuterSpacing={0.5} inline>
              <Text xsmall color={Theme.grey700Color}>
                {influencerHandle}
              </Text>
            </Container>
          </AnchorLink>
        </EllipsisWrapper>
      </FlexItem>
    </React.Fragment>
  )
}

interface IStateProps extends IPostCampaignReportSubmissionIdContextProps, RouteComponentProps {}

const mapStateToProps = (
  state: IApplicationState,
  { contextPostCampaignReportSubmissionId, match }: IStateProps,
) => ({
  influencerName: selectInfluencerName(state, contextPostCampaignReportSubmissionId),
  influencerHandle: selectInfluencerHandle(state, contextPostCampaignReportSubmissionId),
  submissionImageURL: selectInfluencerImageURL(state, contextPostCampaignReportSubmissionId),
  socialPageURL: selectSocialPageURL(state, contextPostCampaignReportSubmissionId),
  channel: selectSubmissionChannel(state, contextPostCampaignReportSubmissionId),
  campaignId: selectCampaignId(match),
  submissionId: contextPostCampaignReportSubmissionId,
  isCarousel: selectIsCarousel(state, contextPostCampaignReportSubmissionId),
  activePCRChannel: selectActivePCRChannel(state),
})

const mapDispatchToProps = {
  viewSocial,
}

export { PostCampaignReportSubmissionListProfile }
export default compose(
  withRouter,
  withContextPostCampaignReportSubmissionId,
  connect(mapStateToProps, mapDispatchToProps),
)(PostCampaignReportSubmissionListProfile)
