import React, { useState } from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { AppName, BuilderDefault, FeatureToggles, SubmissionChannels } from '@enums'
import { withBuilderValidation, withDraft } from '@hocs'
import { useIsMounted } from '@hooks/useIsMounted'
import { IChannelAttributes } from '@store/campaigns'
import Theme from '@theme'
import { FormInput, H4, Text, TextField } from '@tribegroup'
import {
  getChannelInitialState,
  moveHandlesToTags,
  toChannelsArray,
  toChannelsObject,
} from '@utils'
import ChannelsPlaceholder from './Placeholder/Placeholder'
import BuilderBriefChannelsSocialPanel from './SocialPanel/SocialPanel'
import BuilderBriefChannelsTiktokTip from './Tiktok/Tip'
import {
  ChannelWrapper,
  SocialChannelsHeader,
  SocialChannelsSubText,
  SocialInputWrapper,
} from './Channels.styled'
import BuilderBriefChannelsGenericError from './GenericError'

interface IInternalProps extends IBuilderValidationContextProps, IDraftContextProps {}

interface IChannelsContextProps {
  channelItems: Record<string, IChannelAttributes>
  setChannelItems: React.Dispatch<React.SetStateAction<Record<string, IChannelAttributes>>>
}
const ChannelsContext = React.createContext({} as IChannelsContextProps)

const BuilderBriefChannels: React.FC<IInternalProps> = ({
  checkValidationResults,
  clearFieldValidation,
  draft,
  updateForm,
}) => {
  const isXSocialPlatformEnabled = useFlag(FeatureToggles.X_SOCIAL_PLATFORM_ENABLED)
  const isYoutubeSocialPlatformEnabled = useFlag(FeatureToggles.YOUTUBE_SOCIAL_PLATFORM_ENABLED)

  const isPinterestTenant = process.env.APP_NAME === AppName.PINTEREST

  const [channelItems, setChannelItems] = useState<Record<string, IChannelAttributes>>(
    toChannelsObject(draft.channels),
  )

  const [isInitWithPaidPartnership, setIsInitWithPaidPartnership] = useState(false)

  const isMounted = useIsMounted()

  const hasError = checkValidationResults('channel_items_size')

  const channelsCount = isPinterestTenant ? 1 : (draft.channels || []).length

  const savedDraftHasPaidPartnership =
    Boolean(draft.paid_partnership_handle) || draft.has_brand_partnership_handle === false

  let channels: SubmissionChannels[] = [
    SubmissionChannels.Instagram,
    SubmissionChannels.TikTok,
    SubmissionChannels.Pinterest,
    SubmissionChannels.Facebook,
    SubmissionChannels.Twitter,
  ]

  if (isYoutubeSocialPlatformEnabled) {
    channels.splice(3, 0, SubmissionChannels.YouTube)
  }

  if (isXSocialPlatformEnabled) {
    channels.splice(3, 0, SubmissionChannels.X)

    channels = channels.filter((channel) => channel !== SubmissionChannels.Twitter)
  }

  if (isPinterestTenant) {
    channels = [SubmissionChannels.Pinterest]
  }

  React.useEffect(() => {
    if ((isInitWithPaidPartnership || !savedDraftHasPaidPartnership) && !isPinterestTenant) {
      return
    }

    if (!channelItems[SubmissionChannels.Instagram] && !isPinterestTenant) {
      setChannelItems({
        ...channelItems,
        [SubmissionChannels.Instagram]: getChannelInitialState(
          SubmissionChannels.Instagram,
        ) as IChannelAttributes,
      })
      setIsInitWithPaidPartnership(true)
    }

    if (!channelItems[SubmissionChannels.Pinterest] && isPinterestTenant) {
      setChannelItems({
        ...channelItems,
        [SubmissionChannels.Pinterest]: getChannelInitialState(
          SubmissionChannels.Pinterest,
        ) as IChannelAttributes,
      })
      setIsInitWithPaidPartnership(true)
    }
  }, [savedDraftHasPaidPartnership])

  React.useEffect(() => {
    if (isMounted) {
      const channels = toChannelsArray(moveHandlesToTags(channelItems))
      updateForm({ channels })
      clearFieldValidation('channel_items_size')
    }
  }, [channelItems, draft])

  return (
    <ChannelsContext.Provider value={{ channelItems, setChannelItems }}>
      <FormInput
        validationRule={{
          min: BuilderDefault.MIN_ARRAY_SOCIAL_CHANNEL,
        }}
      >
        <TextField name="channel_items_size" type="hidden" value={channelsCount} readOnly />
      </FormInput>
      <SocialChannelsHeader>
        <H4 color={Theme.grey900Color}>
          <FormattedMessage id="builder.brief.socialMedia.header" />
        </H4>
      </SocialChannelsHeader>
      <SocialChannelsSubText>
        <Text color={Theme.grey900Color}>
          <FormattedMessage id="builder.brief.socialMedia.subText" />
        </Text>
      </SocialChannelsSubText>
      <SocialInputWrapper>
        {channels.map((channelName) => (
          <ChannelWrapper key={channelName} data-testid={`channel-container-${channelName}`}>
            {channelItems[channelName] ? (
              <BuilderBriefChannelsSocialPanel channelName={channelName} />
            ) : (
              <ChannelsPlaceholder channelName={channelName} />
            )}
            {channelName === SubmissionChannels.TikTok && (
              <BuilderBriefChannelsTiktokTip
                isTikTokAdded={Object.keys(channelItems).includes(SubmissionChannels.TikTok)}
              />
            )}
          </ChannelWrapper>
        ))}
        {hasError && (
          <BuilderBriefChannelsGenericError errorId="builder.brief.socialMedia.required" />
        )}
      </SocialInputWrapper>
    </ChannelsContext.Provider>
  )
}

export { BuilderBriefChannels, ChannelsContext }
export default compose<IInternalProps, {}>(withDraft, withBuilderValidation)(BuilderBriefChannels)
