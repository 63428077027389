import { call, put, select, takeEvery } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import { selectBulkAddFeedbackLink } from '@store/campaigns'
import {
  bulkAddFeedback,
  bulkAddFeedbackError,
  bulkAddFeedbackSuccess,
  BulkFeedbackActionTypes,
} from '@store/feedback'
import { fetchSubmissions } from '@store/submissions'
import { networkRequest } from '@utils'

export function* handleBulkAddFeedback(action: ReturnType<typeof bulkAddFeedback>) {
  try {
    const { campaignId, params } = action.payload
    const { refreshSubmissions, filter, sortBy, event: analyticsEvent } = action.meta
    const authToken: string = yield select(selectAuthToken)
    const bulkAddFeedbackLink: IHateoasLink = yield select(selectBulkAddFeedbackLink, campaignId)
    yield call(
      networkRequest,
      bulkAddFeedbackLink.method,
      bulkAddFeedbackLink.href,
      params,
      authToken,
    )
    yield put(
      bulkAddFeedbackSuccess(campaignId, params.submission_ids, {
        refreshSubmissions,
        filter,
        sortBy,
      }),
    )
    yield put(trackEvent(analyticsEvent.eventPayload.event, analyticsEvent.eventPayload.properties))
    yield put(fetchSubmissions(campaignId, { refreshSubmissions, filter, sortBy }))
  } catch (error) {
    yield put(bulkAddFeedbackError(error))
  }
}

export function* watchBulkAddFeedbackRequest() {
  yield takeEvery(BulkFeedbackActionTypes.BULK_ADD_REQUEST, handleBulkAddFeedback)
}
