import { createReducer } from '@reduxjs/toolkit'

import { AuthActionTypes } from '@store/auth'
import { indexById } from '@utils/groupBy'
import { fetchReelMetrics, fetchReelMetricsError, fetchReelMetricsSuccess } from './actions'
import { IReelMetrics, IReelMetricsState } from './types'

const initialState: IReelMetricsState = {
  isFetching: false,
  errors: undefined,
  reelMetricsById: {},
  reelMetricIdsByCampaignId: {},
  reelMetricsSummaryByCampaignId: {},
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchReelMetrics, (state) => {
      state.isFetching = true
    })
    .addCase(fetchReelMetricsSuccess, (state, { payload }) => {
      const { campaignId, metrics } = payload

      state.errors = undefined
      state.isFetching = false

      state.reelMetricsById = {
        ...state.reelMetricsById,
        ...indexById(metrics.items, state.reelMetricsById, 'submission_id'),
      }

      state.reelMetricsSummaryByCampaignId = {
        ...state.reelMetricsSummaryByCampaignId,
        [campaignId]: metrics.metadata,
      }

      state.reelMetricIdsByCampaignId = {
        ...state.reelMetricIdsByCampaignId,
        [campaignId]: metrics.items.map((metric: IReelMetrics) => metric.submission_id),
      }
    })
    .addCase(fetchReelMetricsError, (state, { payload }) => {
      state.isFetching = false
      state.errors = payload
    })
    .addMatcher(
      (action) => action.type === AuthActionTypes.SIGNOUT_SUCCESS,
      () => initialState,
    )
})

export { reducer as reelMetricsReducer }
