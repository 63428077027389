import { RefObject, useEffect } from 'react'

import { FileRejection } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'

import ActionIcon from '@components/UI/ActionIcon'
import Theme from '@theme'
import {
  DrawerFormActionButtonWrapper,
  DrawerFormErrorContainer,
  DrawerFormStyled,
  DrawerFormTextareaAutosizeStyled,
} from './Form.styled'
import DrawerFormUpload from './Upload'
import DrawerFormUploadPreview from './UploadPreview'

export type DrawerFormFileRejection = FileRejection

interface IDrawerFormProps {
  placeholder?: string
  minRows?: number
  maxRows?: number
  message: string
  handleInput?: any
  handleSubmit?: any
  autoFocus?: boolean
  textareaRef?: RefObject<HTMLTextAreaElement>
  attachedFile?: File
  setAttachedFile?: (file?: File) => void
  onAttachmentError?: (fileRejection: DrawerFormFileRejection) => void
  formErrorMessage?: string
  isSubmitting?: boolean
  isSubmittingTextPlaceholder?: string
}

const defaultProps = {
  minRows: 1,
  maxRows: 12,
}

export const DrawerForm: React.FC<IDrawerFormProps> = ({
  handleSubmit,
  placeholder,
  minRows,
  maxRows,
  message,
  autoFocus,
  textareaRef,
  attachedFile,
  setAttachedFile,
  onAttachmentError,
  formErrorMessage,
  handleInput,
  isSubmitting,
  isSubmittingTextPlaceholder,
}) => {
  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    const message = target.value

    handleInput(message)
  }

  const isSubmitDisabled = isSubmitting || (!message && !attachedFile)

  useEffect(() => {
    if (message) {
      const cursorDirection = 'forward'
      textareaRef?.current?.setSelectionRange(message.length, message.length, cursorDirection)
    }
  }, [])

  const isValidContent = Boolean(message || attachedFile)

  return (
    <DrawerFormStyled>
      {formErrorMessage && (
        <DrawerFormErrorContainer>
          <FormattedMessage id={formErrorMessage} defaultMessage={formErrorMessage} />
        </DrawerFormErrorContainer>
      )}
      {setAttachedFile && (
        <>
          <DrawerFormUpload
            onFilesAccepted={(accepterFile) => {
              setAttachedFile(accepterFile)
            }}
            onFilesRejection={(fileRejection) => {
              if (onAttachmentError) {
                onAttachmentError(fileRejection)
              }
            }}
            disabled={Boolean(attachedFile) || Boolean(isSubmitting)}
          />
          {attachedFile && (
            <DrawerFormUploadPreview
              file={attachedFile}
              onRemove={() => setAttachedFile(undefined)}
              isUploading={isSubmitting}
            />
          )}
        </>
      )}
      <DrawerFormTextareaAutosizeStyled
        minRows={minRows ?? defaultProps.minRows}
        maxRows={maxRows ?? defaultProps.maxRows}
        placeholder={
          isSubmitting && isSubmittingTextPlaceholder ? isSubmittingTextPlaceholder : placeholder
        }
        onChange={onChange}
        value={message}
        data-cy-element="textarea-field"
        data-testid="textarea-field"
        autoFocus={autoFocus}
        inputRef={textareaRef}
        disabled={isSubmitting}
      />
      <DrawerFormActionButtonWrapper
        onClick={handleSubmit}
        data-cy-element="drawer-form-submit-button"
        data-testid="drawer-form-submit-button"
        disabled={isSubmitDisabled}
      >
        <ActionIcon
          size={1.375}
          glyph="send"
          disabled={isSubmitDisabled}
          color={!isValidContent ? Theme.grey700Color : Theme.grey900Color}
        />
      </DrawerFormActionButtonWrapper>
    </DrawerFormStyled>
  )
}

export default DrawerForm
