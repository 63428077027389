import { FormattedMessage } from 'react-intl'

import { useDrawer } from '@hooks/useDrawer'
import SubmissionRateCardPanel from '../RateCardPanel'

const useRateCardTrigger = () => {
  const intlDrawerId = 'submission.fullView.rateGuide.title'

  const rateCardTipId = 'submission.fullView.rateGuide.title'

  const { showDrawer } = useDrawer()
  const onClick = () => {
    showDrawer(
      <SubmissionRateCardPanel />,
      { showHeaderDivider: true },
      <FormattedMessage id={intlDrawerId} />,
    )
  }

  return {
    onClick,
    rateCardTipId,
  }
}

export { useRateCardTrigger }
