import { BrandFanFilters } from '@enums'
import { IHateoasLink } from '@store'
import { FetchInfluencerIdentitiesActionTypes, IInfluencerIdentity } from '@store/brandFans'

type MergeType = 'add' | 'append'

export const fetchInfluencerIdentities = (isFetchNext = false, resetList = false) => ({
  payload: { isFetchNext, resetList },
  type: FetchInfluencerIdentitiesActionTypes.FETCH_REQUEST,
})

export const fetchInfluencerIdentitiesSuccess = (
  influencerIdentities: ReadonlyArray<IInfluencerIdentity>,
  metadata: object,
  links: ReadonlyArray<IHateoasLink>,
  filter: BrandFanFilters = BrandFanFilters.All,
  mergeType: MergeType = 'add',
) => ({
  payload: { influencerIdentities, metadata, links, filter, mergeType },
  type: FetchInfluencerIdentitiesActionTypes.FETCH_SUCCESS,
})

export const fetchInfluencerIdentitiesError = (error: any) => ({
  payload: error,
  type: FetchInfluencerIdentitiesActionTypes.FETCH_ERROR,
})

export const resetInfluencerIdentitiesList = (filter: BrandFanFilters) => ({
  payload: filter,
  type: FetchInfluencerIdentitiesActionTypes.RESET_LIST,
})
