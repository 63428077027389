import { createReducer, isAnyOf } from '@reduxjs/toolkit'
import { castDraft } from 'immer'

import { AuthActionTypes } from '@store/auth'
import { IBrandsState } from '@store/brands'
import {
  addBrand,
  addBrandError,
  addBrandSuccess,
  fetchBrands,
  fetchBrandsError,
  fetchBrandsSuccess,
  updateBrand,
  updateBrandError,
  updateBrandSuccess,
} from './actions'

const initialState: IBrandsState = {
  isFetching: false,
  isAdding: false,
  isUpdating: false,
  hasFetched: false,
  errors: undefined,
  brandsById: {},
  links: [],
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchBrands, (state) => {
      state.isFetching = true
    })
    .addCase(addBrand, (state) => {
      state.isAdding = true
    })
    .addCase(updateBrand, (state) => {
      state.isUpdating = true
    })
    .addCase(fetchBrandsSuccess, (state, { payload }) => {
      const { items, links } = payload
      state.isFetching = false
      state.hasFetched = true
      state.links = links
      items.forEach((item) => (state.brandsById[item.id] = item))
    })
    .addCase(addBrandError, (state) => {
      state.isAdding = false
    })
    .addMatcher(isAnyOf(addBrandSuccess, updateBrandSuccess), (state, { payload }) => {
      state.isAdding = false
      state.isUpdating = false
      state.brandsById[payload.id] = castDraft(payload)
    })
    .addMatcher(
      isAnyOf(updateBrandError, addBrandError, fetchBrandsError),
      (state, { payload }) => {
        state.isFetching = false
        state.errors = payload
      },
    )
    .addMatcher(isAnyOf(addBrand, fetchBrands, updateBrand), (state) => {
      state.errors = undefined
    })
    .addMatcher(
      (action) => action.type === AuthActionTypes.SIGNOUT_SUCCESS,
      () => initialState,
    )
})

export { reducer as brandsReducer }
