import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { PanelWrapper } from '@components/Builder/Layout/Layout.styled'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { withBuilderValidation } from '@hocs'
import { IPaymentDetails } from '@store/drafts'
import { ICompany, IInvoiceDetails } from '@store/me'
import Theme from '@theme'
import { FormGroup, FormInput, H4, Panel, TextField } from '@tribegroup'
import { hasValidationError, SubmitErrorMessage } from '../../Error/Message'

interface IBuilderSubmitCampaignPaymentInvoiceProps {
  companyInfo: ICompany
  onPaymentChange: (paymentDetails: IPaymentDetails) => void
  isHeaderHidden?: boolean
}

interface IInternalProps
  extends IBuilderSubmitCampaignPaymentInvoiceProps,
    IBuilderValidationContextProps,
    InjectedIntlProps {}

interface IBuilderSubmitCampaignPaymentInvoiceState {
  invoiceDetails: IInvoiceDetails
}

export class BuilderSubmitCampaignPaymentInvoice extends React.PureComponent<
  IInternalProps,
  IBuilderSubmitCampaignPaymentInvoiceState
> {
  constructor(props: IInternalProps) {
    super(props)
    const { companyInfo } = props
    this.state = {
      invoiceDetails: {
        billing_contact_name: companyInfo.company_billing_contact,
        accounts_payable_email: companyInfo.company_accounts_payable_email,
      },
    }
  }

  onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    this.setState((prevState) => ({
      invoiceDetails: {
        ...prevState.invoiceDetails,
        [target.name]: target.value,
      },
    }))
  }

  onInputBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    this.setState(
      (prevState) => ({
        invoiceDetails: {
          ...prevState.invoiceDetails,
          [target.name]: target.value && target.value.trim(),
        },
      }),
      this.onPaymentChange,
    )
  }

  onPaymentChange = () => {
    const paymentDetails = this.state.invoiceDetails as IPaymentDetails
    this.props.onPaymentChange(paymentDetails)
  }

  render() {
    const { intl, validationResults } = this.props
    const { invoiceDetails } = this.state

    const textFieldProps = {
      thick: true,
      fullWidth: true,
      onChange: this.onInputChange,
      onFocus: this.props.clearFieldValidation,
    }

    return (
      <PanelWrapper data-cy-element="invoice-payment-details-panel-wrapper">
        <Panel
          title={
            !this.props.isHeaderHidden && (
              <H4 color={Theme.defaultColor}>
                <FormattedMessage id="builder.submit.payment.header" />
              </H4>
            )
          }
        >
          <FormGroup>
            <FormInput
              validationRule={{
                required: {
                  value: true,
                  message: this.props.intl.formatMessage({
                    id: 'builder.submit.payment.invoice.accountsEmail.required',
                  }),
                },
                email: {
                  value: true,
                  message: this.props.intl.formatMessage({
                    id: 'builder.submit.payment.invoice.accountsEmail.invalid',
                  }),
                },
              }}
            >
              <TextField
                {...textFieldProps}
                scheme="default"
                onBlur={this.onInputBlur}
                value={invoiceDetails.accounts_payable_email}
                name="accounts_payable_email"
                invalid={hasValidationError(validationResults, 'accounts_payable_email')}
                placeholder={intl.formatMessage({
                  id: 'builder.submit.payment.invoice.accountsEmail',
                })}
              />
            </FormInput>
            <SubmitErrorMessage results={validationResults} errorKey="accounts_payable_email" />
          </FormGroup>
          <FormGroup>
            <FormInput
              validationRule={{
                required: {
                  value: true,
                  message: this.props.intl.formatMessage({
                    id: 'builder.submit.payment.invoice.billingContact.required',
                  }),
                },
              }}
            >
              <TextField
                {...textFieldProps}
                scheme="default"
                onBlur={this.onInputBlur}
                value={invoiceDetails.billing_contact_name}
                name="billing_contact_name"
                invalid={hasValidationError(validationResults, 'billing_contact_name')}
                placeholder={intl.formatMessage({
                  id: 'builder.submit.payment.invoice.billingContact',
                })}
              />
            </FormInput>
            <SubmitErrorMessage results={validationResults} errorKey="billing_contact_name" />
          </FormGroup>
          <FormGroup>
            <FormInput
              validationRule={{
                required: {
                  value: true,
                  message: this.props.intl.formatMessage({
                    id: 'builder.submit.payment.invoice.purchaseOrderNumber.required',
                  }),
                },
              }}
            >
              <TextField
                {...textFieldProps}
                scheme="default"
                onBlur={this.onInputBlur}
                value={invoiceDetails.po_number}
                name="po_number"
                invalid={hasValidationError(validationResults, 'po_number')}
                placeholder={intl.formatMessage({
                  id: 'builder.submit.payment.invoice.purchaseOrderNumber',
                })}
              />
            </FormInput>
            <SubmitErrorMessage results={validationResults} errorKey="po_number" />
          </FormGroup>
        </Panel>
      </PanelWrapper>
    )
  }
}

export default compose<IInternalProps, any>(
  injectIntl,
  withBuilderValidation,
)(BuilderSubmitCampaignPaymentInvoice)
