/* eslint-disable prefer-const */
export const isEmpty = (value: any) => {
  if (Object.keys(value).length) {
    return false
  }
  return true
}

export const dataURLtoBlob = (dataurl) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}
// tslint:enable

export const getBase64Image = (imageSrc: string, canvasConfig: any) => {
  const image = document.createElement('img')
  image.setAttribute('src', imageSrc)
  const canvas = document.createElement('canvas')
  canvas.setAttribute('width', canvasConfig.width)
  canvas.setAttribute('height', canvasConfig.height)
  const ctx = canvas.getContext('2d')
  if (ctx) {
    ctx.drawImage(
      image,
      canvasConfig.x,
      canvasConfig.y,
      canvasConfig.width,
      canvasConfig.height,
      0,
      0,
      canvasConfig.width,
      canvasConfig.height,
    )

    return canvas.toDataURL('image/jpeg')
  } else {
    return null
  }
}

// TODO - Need to simplify this
// currently each loop looks into the parentNode of the selected element
// and checks if the selector matches
// and keeps mutating the `elem` as the next parent
export const getClosestParent = (elem: any, selector: string) => {
  // tslint:disable
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) {
      return elem
    }
  }
  return null
}

export const addToSet = (current: ReadonlyArray<any> = [], elements: ReadonlyArray<any> = []) =>
  Array.from(new Set([...current, ...elements]))

export const removeFromSet = (current: ReadonlyArray<any> = [], element: any) =>
  current.filter((curr) => curr !== element)

export const trimStringMap = (items: Record<any, string>) => {
  const list = Object.values(items).filter(Boolean)
  const trimmedMap = list.reduce((accum, item, index) => {
    const value = item && item.trim()
    if (value) {
      return { ...accum, [index]: value }
    }
    return { ...accum }
  }, []) as Record<number, string>
  return trimmedMap
}

export const KEYCODE = {
  ENTER: 13,
}

export const isTruthyOrZero = (value: any) => Boolean(value) || value === 0

export const toCents = (val: number): number => val * 100
export const fromCents = (val: number): number => val / 100
