import React, { PropsWithChildren } from 'react'

import { FlexItem } from 'styled-flex-component'

import { Container } from '@tribegroup'

interface IProps {
  spacing: number
}

const LabelItem: React.FC<PropsWithChildren<IProps>> = ({ spacing, children }) => {
  return (
    <FlexItem noShrink>
      <Container rightOuterSpacing={spacing}>{children}</Container>
    </FlexItem>
  )
}

export default LabelItem
