import { createSelector } from 'reselect'

import { IApplicationState } from '@store'

const root = (state: IApplicationState) => state.completeSignUp

export const selectIsLoading = createSelector(root, (state) => state.loading === 'pending')
export const selectIsFulfilled = createSelector(root, (state) => state.loading === 'succeeded')

export const selectErrors = createSelector(root, (state) => state.errors)
