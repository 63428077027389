import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  metricDisputeFailed,
  metricDisputeRequest,
  metricDisputeSuccess,
} from '@store/metricDisputes/actions'
import { MetricDisputesActionTypes } from '@store/metricDisputes/types'
import { fetchSubmissionSuccess, selectReportMetricIssueLink } from '@store/submissions'
import { networkRequest } from '@utils'
import { WatcherFunction } from '../'

export function* handleMetricDisputeRequest(action: ReturnType<typeof metricDisputeRequest>) {
  try {
    const { submissionId, issueType, description } = action.payload
    const authToken = yield select(selectAuthToken)

    const metricDisputeLink: IHateoasLink = yield select(selectReportMetricIssueLink, submissionId)

    const submission = yield call(
      networkRequest,
      metricDisputeLink.method,
      metricDisputeLink.href,
      { issue_type: issueType, description },
      authToken,
    )
    yield put(metricDisputeSuccess())
    yield put(fetchSubmissionSuccess(submission))
  } catch (error) {
    yield put(metricDisputeFailed(error))
  }
}

export function* watchMetricDisputeRequest() {
  yield takeLatest(MetricDisputesActionTypes.PENDING, handleMetricDisputeRequest)
}

export const metricDisputeSagas: ReadonlyArray<WatcherFunction> = [watchMetricDisputeRequest]
