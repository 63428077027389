import SubmissionThumbnailStatusLabel from './Label'
import SubmissionThumbnailStatusLabelLicensed from './Licensed'
import SubmissionThumbnailStatusLabelLicenseExpired from './LicenseExpired'
import SubmissionThumbnailStatusLabelMissed from './Missed'
import SubmissionThumbnailStatusLabelPost from './Post'
import SubmissionThumbnailStatusLabelPostOverdue from './PostOverdue'
import SubmissionThumbnailStatusLabelPostWithinHours from './PostWithinHours'
import SubmissionThumbnailStatusLabelPublished from './Published'

export {
  SubmissionThumbnailStatusLabelLicensed,
  SubmissionThumbnailStatusLabelLicenseExpired,
  SubmissionThumbnailStatusLabelMissed,
  SubmissionThumbnailStatusLabelPost,
  SubmissionThumbnailStatusLabelPostOverdue,
  SubmissionThumbnailStatusLabelPostWithinHours,
  SubmissionThumbnailStatusLabelPublished,
}
export default SubmissionThumbnailStatusLabel
