import React, { PropsWithChildren } from 'react'

import {
  ArrowNavigationContainer,
  DisabledNextButton,
  NextButton,
  PreviousButton,
} from '@components/UI/ArrowNavigation/ArrowNavigation'
import { EventTrackingNames } from '@enums'
import hooks from '@hooks'
import { objectToQueryString } from '@utils'
import { IConnectedTrackedRouteIDProps } from '../TrackedRoute/ConnectedTrackedRoute'
import NavigationTrackedRoute, { INavigationTrackedRouteProps } from './NavigationTrackedRoute'

export interface ITrackingProps extends IConnectedTrackedRouteIDProps {
  eventName: EventTrackingNames
}

export interface ISummaryNavigationProps {
  currentId: number
  prevLink?: string
  nextLink?: string
  prevTrackingProps: ITrackingProps
  nextTrackingProps: ITrackingProps
  loadingMore: boolean
  trackingWrapper?: React.FC<PropsWithChildren<INavigationTrackedRouteProps>>
}

const SummaryNavigation: React.FC<PropsWithChildren<ISummaryNavigationProps>> = ({
  prevLink,
  prevTrackingProps,
  nextLink,
  nextTrackingProps,
  currentId,
  loadingMore,
  children,
  trackingWrapper: TrackingWrapper = NavigationTrackedRoute,
}) => {
  const [navigationLinks, setNavigationLinks] = React.useState([prevLink, nextLink])
  React.useEffect(() => {
    setNavigationLinks([prevLink, nextLink])
  }, [currentId])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { from, ...fromFilteredSearch } = hooks.useLocationSearch()
  const search = objectToQueryString(fromFilteredSearch)

  const [currentPrevLink, currentNextLink] = navigationLinks

  const showAsLoading = loadingMore && currentPrevLink && !currentNextLink

  return (
    <ArrowNavigationContainer>
      {currentPrevLink && (
        <TrackingWrapper source="previous" {...(prevTrackingProps as ITrackingProps)}>
          <PreviousButton link={{ pathname: currentPrevLink, search }} />
        </TrackingWrapper>
      )}
      {children}
      {currentNextLink && (
        <TrackingWrapper source="next" {...(nextTrackingProps as ITrackingProps)}>
          <NextButton link={{ pathname: currentNextLink, search }} />
        </TrackingWrapper>
      )}
      {showAsLoading && <DisabledNextButton />}
    </ArrowNavigationContainer>
  )
}

export { SummaryNavigation }
export default SummaryNavigation
