import { useState } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import { PanelWrapper, TextareaErrorMessageWrapper } from '@components/Builder/Layout/Layout.styled'
import { IApplicationState } from '@store'
import { FindProductOption, selectRootResource } from '@store/rootResource'
import Theme from '@theme'
import { Container, FormGroup, FormInput, H4, Panel, Text, TextArea } from '@tribegroup'
import BuilderCampaignFindProductTip from './Tip/Tip'
import BuilderCampaignFindProductOption from './Option'

interface IProps extends InjectedIntlProps {
  value?: string
  option: string
  onChange?: (event: React.FormEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: React.FormEvent<HTMLTextAreaElement>) => void
  onFocus?: (eventorName: React.FormEvent<HTMLInputElement> | string) => void
  isSocial?: boolean
  onFindProductOptionClick: (option) => void
  validationResults: Record<string, any>
}

export const BuilderCampaignFindProduct = ({
  value,
  option,
  onChange,
  onBlur,
  onFocus,
  isSocial,
  intl,
  validationResults,
  onFindProductOptionClick,
}: IProps) => {
  const helpTextRegex = /<(.*?)>/g
  const [helpTexts, setHelpTexts] = useState<string[]>([])

  const validationResult = validationResults['product_where_to_find']

  const { findProductOptions } = useSelector((state: IApplicationState) => {
    return {
      findProductOptions: selectRootResource(state).product_where_to_find_options,
    }
  })

  const onOptionClick = (selectedOption) => {
    if (selectedOption.code === option) {
      return
    }

    onFindProductOptionClick(selectedOption)

    setHelpTexts(selectedOption.help_text.match(helpTextRegex))
  }

  const validationRules = {
    maxLength: {
      value: 300,
      message: intl.formatMessage({ id: 'builder.campaign.error.max' }),
    },
    required:
      option !== FindProductOption.OTHER
        ? {
            value: true,
            message: intl.formatMessage({ id: 'builder.campaign.error.findProduct.required' }),
          }
        : false,
    custom:
      helpTexts?.length > 0
        ? [
            {
              message: intl.formatMessage({ id: 'builder.campaign.error.findProduct.custom' }),
              value: `^${helpTexts.map((text) => `(?!.*${text.replace('$', '\\$')})`).join('')}.*$`,
            },
          ]
        : undefined,
  }

  return (
    <PanelWrapper>
      <Panel
        title={
          <H4 color={Theme.defaultColor}>
            <FormattedMessage id="builder.campaign.findProduct.label" />
          </H4>
        }
      >
        <Text color={Theme.defaultColor} block>
          <FormattedMessage id="builder.campaign.findProduct.text" />
        </Text>
        <Container topOuterSpacing={1.5}>
          <Flex wrap="wrap">
            {findProductOptions?.map((productOption) => {
              if (!isSocial && productOption.code === 'reimbursed') {
                return
              }
              return (
                <BuilderCampaignFindProductOption
                  key={productOption.code}
                  onClick={onOptionClick}
                  option={productOption}
                  active={productOption.code === option}
                />
              )
            })}
          </Flex>
        </Container>

        <FormGroup outerTopSpacing={1.5}>
          <FormInput validationRule={validationRules}>
            <TextArea
              scheme="default"
              placeholder={
                option === FindProductOption.OTHER
                  ? intl.formatMessage({ id: 'builder.campaign.findProduct.placeholder' })
                  : undefined
              }
              name="product_where_to_find"
              height={5.5}
              characterLimit={300}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus as any}
              value={value}
              invalid={validationResult && validationResult.hasError}
              disabled={!option}
              data-testid="find-product-text-area"
            />
          </FormInput>
          {validationResult && validationResult.hasError && (
            <TextareaErrorMessageWrapper>
              <Text small color={Theme.errorColor}>
                {validationResult.errorMessage}
              </Text>
            </TextareaErrorMessageWrapper>
          )}
        </FormGroup>
      </Panel>
      <BuilderCampaignFindProductTip />
    </PanelWrapper>
  )
}

export default injectIntl(BuilderCampaignFindProduct)
