import { LicenseStatuses, SubmissionStatuses } from '@enums'
import { ISubmission } from '@store/submissions'

const getDisplayedSubmissionStatus = (
  submission: ISubmission,
  showLicensePrice: boolean,
  licenseStatus?: LicenseStatuses,
) => {
  if (submission.status === SubmissionStatuses.Pending) {
    if (submission.resubmitted) {
      return 'resubmitted'
    }
    return undefined
  }

  if (showLicensePrice) {
    if (licenseStatus === LicenseStatuses.Requested) {
      return 'license_requested'
    }

    if (
      licenseStatus === LicenseStatuses.ExpiredDeprecated ||
      licenseStatus === LicenseStatuses.LicenseExpired
    ) {
      return 'license_expired'
    }

    if (licenseStatus === LicenseStatuses.RequestExpired) {
      return 'request_expired'
    }

    if (licenseStatus === LicenseStatuses.Declined) {
      return 'license_declined'
    }

    if (licenseStatus === LicenseStatuses.Licensed) {
      return 'licensed'
    }
  }
  return submission.status || ''
}

export { getDisplayedSubmissionStatus }
