import MultiframeAutoplay from './Autoplay'
import MultiframeFullMode from './FullMode'
import MultiframeNext from './Next'
import MultiframePrevious from './Previous'
import MultiframeSlider from './Slider'

export {
  MultiframeNext,
  MultiframePrevious,
  MultiframeSlider,
  MultiframeAutoplay,
  MultiframeFullMode,
}
