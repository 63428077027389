import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'

export const Wrapper = styled.div`
  padding: 0.75rem 1rem;
  margin-bottom: 1.25rem;
  border: solid 1px ${Theme.grey400Color};
  border-radius: 0.5rem;
  box-sizing: border-box;
  display: block;

  ${breakpoint('tabletPortrait')`
    display: flex;
    align-items: center;
    min-height: 3.3125rem;
  `};
  &:hover {
    border-color: transparent;
    cursor: pointer;
    background: ${Theme.grey200Color};
  }
`
