import { useState } from 'react'

import { FormattedHTMLMessage } from 'react-intl'
import OutsideClickHandler from 'react-outside-click-handler'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import Flex from 'styled-flex-component'

import ActionIcon from '@components/UI/ActionIcon'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames, SubmissionStatuses } from '@enums'
import { IApplicationState } from '@store'
import { selectCampaignId, selectSubmissionId } from '@store/router'
import {
  selectFirstMediaObject,
  selectIdentityFirstName,
  selectIsPitchingEnabled,
  selectMediaObjects,
  selectSubmissionStatus,
} from '@store/submissions'
import Theme from '@theme'
import { Container, H5 } from '@tribegroup'
import SubmissionCreatorNoteAccordion from '../Accordion/Accordion'
import SubmissionCreatorNoteBlock from '../Block/Block'
import {
  OpenActionIconWrapper,
  SubmissionCreatorNotePopupClose,
  SubmissionCreatorNotePopupOpen,
} from './Popup.styled'

interface ISubmissionCreatorNotePopupState {
  open: boolean
  pulse: boolean
}

const SubmissionCreatorNotePopup: React.FC = () => {
  const match = useRouteMatch()
  const {
    submissionId,
    campaignId,
    mediaObjectsCount,
    singleFrame,
    identityFirstName,
    socialSubmissionStatus,
    isPitchingEnabled,
  } = useSelector((state: IApplicationState) => {
    const submissionId = selectSubmissionId(match) as number
    const campaignId = selectCampaignId(match) as number

    return {
      submissionId,
      campaignId,
      mediaObjectsCount: selectMediaObjects(state, submissionId).length,
      singleFrame: selectFirstMediaObject(state, submissionId),
      identityFirstName: selectIdentityFirstName(state, submissionId),
      isPitchingEnabled: selectIsPitchingEnabled(state, submissionId),
      socialSubmissionStatus: selectSubmissionStatus(state, submissionId),
    }
  })

  const [state, setState] = useState<ISubmissionCreatorNotePopupState>({
    open: true,
    pulse: false,
  })

  const togglePopup = () => {
    setState((prevState) => ({ open: !prevState.open, pulse: false }))
  }

  const onOutsideClick = () => {
    setState({ ...state, open: false })
  }

  const showCreativeApproachText =
    isPitchingEnabled &&
    [SubmissionStatuses.Pending, SubmissionStatuses.PreApproved].includes(
      socialSubmissionStatus as SubmissionStatuses,
    )

  const actionIcon = (
    <ActionIcon
      glyph="notes-creators"
      highlighted
      animatePulse={state.pulse}
      size={1.5}
      color={Theme.grey900Color}
      onClick={togglePopup}
      data-cy-element="creator-note-icon"
      elementName="creator-note"
    />
  )

  if (!state.open) {
    return (
      <SubmissionCreatorNotePopupClose>
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.ViewFrameNotes}
          submissionId={submissionId}
          campaignId={campaignId}
        >
          {actionIcon}
        </ConnectedTrackedRoute>
      </SubmissionCreatorNotePopupClose>
    )
  }

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <SubmissionCreatorNotePopupOpen data-cy-element="creator-note-popup">
        <Flex justifyBetween>
          <Container fullWidth>
            <H5 color={Theme.grey900Color} leftOuterSpacing={1.25} bottomOuterSpacing={0.625}>
              <FormattedHTMLMessage
                id={
                  showCreativeApproachText
                    ? 'submission.card.creator.notes.creativeApproach'
                    : 'submission.card.creator.notes.from'
                }
                values={{ firstName: identityFirstName }}
              />
            </H5>
            {mediaObjectsCount > 1 && <SubmissionCreatorNoteAccordion />}
            {mediaObjectsCount === 1 && (
              <SubmissionCreatorNoteBlock active text={singleFrame.creator_note as string} />
            )}
          </Container>
          <OpenActionIconWrapper justifyEnd alignEnd noShrink>
            {actionIcon}
          </OpenActionIconWrapper>
        </Flex>
      </SubmissionCreatorNotePopupOpen>
    </OutsideClickHandler>
  )
}

export default SubmissionCreatorNotePopup
