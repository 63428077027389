import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { IPostMetricsState } from '@store/postCampaignReport'
import { returnSecondIfDeepEqual } from '@utils'
import { indexById } from '@utils/groupBy'
import { IPostMetrics, PostMetricsActionTypes } from './types'

const initialState: IPostMetricsState = {
  isFetching: false,
  errors: undefined,
  postMetricsById: {},
  postMetricIdsByChannel: {},
  postMetricsSummary: {},
}

const reducer: Reducer<IPostMetricsState> = (state = initialState, action) => {
  switch (action.type) {
    case PostMetricsActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }

    case PostMetricsActionTypes.FETCH_SUCCESS: {
      const { campaignId, postMetrics, channel } = action.payload

      return {
        ...state,
        postMetricsById: returnSecondIfDeepEqual(
          indexById(postMetrics.items, state.postMetricsById, 'submission_id'),
          state.postMetricsById,
        ),
        postMetricsSummary: returnSecondIfDeepEqual(
          {
            ...state.postMetricsSummary,
            [campaignId]: {
              ...state.postMetricsSummary[campaignId],
              [channel]: postMetrics.metadata,
            },
          },
          state.postMetricsSummary,
        ),
        postMetricIdsByChannel: returnSecondIfDeepEqual(
          {
            ...state.postMetricIdsByChannel,
            [campaignId]: {
              ...state.postMetricIdsByChannel[campaignId],
              [channel]: postMetrics.items.map((item: IPostMetrics) => item.submission_id),
            },
          },
          state.postMetricIdsByChannel,
        ),
        errors: undefined,
        isFetching: false,
      }
    }

    case PostMetricsActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as postMetricsReducer }
