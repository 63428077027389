import React, { useContext } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import AnalyticsPostCampaignReportEmpty from '@components/Analytics/PostCampaignReport/ListView/Empty/Empty'
import AnalyticsPostCampaignReportListViewInsights from '@components/Analytics/PostCampaignReport/ListView/Insights/Insights'
import AnalyticsPostCampaignReportListViewAmount from '@components/Analytics/PostCampaignReport/ListView/Item/Amount'
import AnalyticsPostCampaignReportListViewNumber from '@components/Analytics/PostCampaignReport/ListView/Item/Number'
import AnalyticsPostCampaignReportListViewPercentage from '@components/Analytics/PostCampaignReport/ListView/Item/Percentage'
import {
  Entry,
  RowWrapper,
} from '@components/Analytics/PostCampaignReport/ListView/ListView.styled'
import { CampaignContext } from '@context/Campaign'
import { IApplicationState } from '@store'
import {
  selectShowVideoPerformance,
  selectVideoCPE,
  selectVideoCPM,
  selectVideoCPV,
  selectVideoEngagement,
  selectVideoEngagementPct,
  selectVideoFollowers,
  selectVideoImpressions,
  selectVideoPublishedDate,
  selectVideoReach,
  selectVideoReachPct,
  selectVideoSpend,
  selectVideoViewPct,
  selectVideoViews,
} from '@store/postCampaignReport'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { abbreviateNumber } from '@utils'

interface IProps {
  submissionId: number
  backgroundColor: string
}

type IInternalProps = IProps & ConnectedProps<typeof connector>

const AnalyticsPostCampaignReportVideoListItem: React.FC<IInternalProps> = ({
  spend,
  followers,
  engagement,
  engagementPct,
  cpe,
  views,
  viewPct,
  cpv,
  reach,
  reachPct,
  impressions,
  cpm,
  backgroundColor,
  showMetrics,
  publishedDate,
}) => {
  const {
    campaign: { is_price_hidden: isPriceHidden },
  } = useContext(CampaignContext)

  if (!showMetrics) {
    return (
      <Container data-testid="video-purchase-empty">
        <AnalyticsPostCampaignReportEmpty
          minWidth={isPriceHidden ? 58.5 : 84.375}
          backgroundColor={backgroundColor}
        />
      </Container>
    )
  }

  return (
    <Container inline relative height={4.75}>
      <Flex>
        <RowWrapper data-testid="video-published-date" backgroundColor={backgroundColor}>
          <Entry>
            <Text color={Theme.grey900Color}>{publishedDate}</Text>
          </Entry>
        </RowWrapper>
        {!isPriceHidden && (
          <AnalyticsPostCampaignReportListViewAmount
            backgroundColor={backgroundColor}
            testId="video-purchase-amount"
            pattern="!###,### "
            value={spend}
          />
        )}

        <RowWrapper data-testid="video-followers" backgroundColor={backgroundColor}>
          <Entry>
            <Text color={Theme.grey900Color}>{abbreviateNumber(followers)}</Text>
          </Entry>
        </RowWrapper>
        <AnalyticsPostCampaignReportListViewNumber
          backgroundColor={backgroundColor}
          testId="video-engagement"
          value={engagement}
        />
        <AnalyticsPostCampaignReportListViewPercentage
          backgroundColor={backgroundColor}
          testId="video-engagement-pct"
          value={engagementPct}
        />
        {!isPriceHidden && (
          <AnalyticsPostCampaignReportListViewAmount
            backgroundColor={backgroundColor}
            testId="video-cpe"
            value={cpe}
          />
        )}

        <AnalyticsPostCampaignReportListViewNumber
          backgroundColor={backgroundColor}
          testId="video-views"
          value={views}
        />
        <AnalyticsPostCampaignReportListViewPercentage
          backgroundColor={backgroundColor}
          testId="video-view-pct"
          value={viewPct}
        />

        {!isPriceHidden && (
          <AnalyticsPostCampaignReportListViewAmount
            backgroundColor={backgroundColor}
            testId="video-cpv"
            value={cpv}
          />
        )}

        <AnalyticsPostCampaignReportListViewInsights
          testIdPrefix="video"
          backgroundColor={backgroundColor}
          reach={reach}
          reachPct={reachPct}
          impressions={impressions}
          cpm={cpm}
          isPriceHidden={isPriceHidden}
        />
      </Flex>
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { submissionId }: IProps) => {
  return {
    publishedDate: selectVideoPublishedDate(state, submissionId),
    spend: selectVideoSpend(state, submissionId),
    followers: selectVideoFollowers(state, submissionId),
    engagement: selectVideoEngagement(state, submissionId),
    engagementPct: selectVideoEngagementPct(state, submissionId),
    cpe: selectVideoCPE(state, submissionId),
    views: selectVideoViews(state, submissionId),
    viewPct: selectVideoViewPct(state, submissionId),
    cpv: selectVideoCPV(state, submissionId),
    reach: selectVideoReach(state, submissionId),
    reachPct: selectVideoReachPct(state, submissionId),
    impressions: selectVideoImpressions(state, submissionId),
    cpm: selectVideoCPM(state, submissionId),
    showMetrics: selectShowVideoPerformance(state, submissionId),
  }
}

const connector = connect(mapStateToProps)
export default compose<IInternalProps, IProps>(connector)(AnalyticsPostCampaignReportVideoListItem)
