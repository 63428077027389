import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'

import TitleBar, { TitleBarMobile } from '@components/UI/TitleBar'
import { useCampaigns } from '@hooks/campaigns/useCampaigns'
import { useCampaignsLinks } from '@hooks/campaigns/useCampaignsLinks'
import CampaignsTitleBarButtons from './Buttons'

const CampaignsTitleBar = () => {
  const { createCampaignLink } = useCampaignsLinks()
  const { stats, currentCampaignStatusFilter } = useCampaigns()
  return (
    <Fragment>
      <TitleBar desktopOnly actions={createCampaignLink && <CampaignsTitleBarButtons />} />
      <TitleBarMobile
        title={<FormattedMessage id={`core.text.${currentCampaignStatusFilter}`} />}
        count={stats[currentCampaignStatusFilter]}
      />
    </Fragment>
  )
}

export default CampaignsTitleBar
