/* eslint-disable react/display-name */
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { ICompany, IMe, selectCompanyInfo, selectMeInfo } from '@store/me'

export interface IMeInfoProps {
  meInfo: IMe
  companyInfo?: ICompany
}

const MeInfo = (Component) => (props) => {
  return <Component {...props} />
}

const mapStateToProps = (state: IApplicationState) => ({
  meInfo: selectMeInfo(state),
  companyInfo: selectCompanyInfo(state),
})

export const withMeInfo = compose(connect(mapStateToProps), MeInfo)

export default withMeInfo
