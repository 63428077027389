import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import Loading from '@components/UI/Loading'
import { IInfluencerIdentityIdContextProps } from '@context/InfluencerIdentityId'
import { withContextInfluencerIdentityId } from '@hocs'
import { IApplicationState } from '@store'
import { selectIsFetchingSubmissionsByInfluencerIdentity } from '@store/submissions'
import InfluencerIdentityRowSubmissionList from '../List/List'
import { Wrapper } from './Wrapper.styled'

interface IInternalProps {
  isFetching: boolean
}

export const InfluencerIdentityRowSubmissionLoading = () => (
  <Wrapper justifyCenter alignCenter>
    <Loading height={30} />
  </Wrapper>
)

export const InfluencerIdentityRowSubmissionWrapper: React.FC<IInternalProps> = ({
  isFetching,
}) => {
  return isFetching ? (
    <InfluencerIdentityRowSubmissionLoading />
  ) : (
    <InfluencerIdentityRowSubmissionList />
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { contextInfluencerIdentityId }: IInfluencerIdentityIdContextProps,
) => ({
  isFetching: selectIsFetchingSubmissionsByInfluencerIdentity(state, contextInfluencerIdentityId),
})

export default compose<IInternalProps, {}>(
  withContextInfluencerIdentityId,
  connect(mapStateToProps),
)(InfluencerIdentityRowSubmissionWrapper)
