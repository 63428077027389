import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectShareableContentLibraryMediaObjectIds } from '@store/mediaObjects'
import { Container } from '@tribegroup'
import { isEmpty } from '@utils'
import { ShareThisLibrary } from '../Buttons'
import { HeaderLogo, HeaderSubtitle, HeaderTitle } from '.'

interface IInternalProps {
  hasEmptyLibrary: boolean
}

const ShareableContentLibraryHeader: React.FC<IInternalProps> = ({ hasEmptyLibrary }) => (
  <React.Fragment>
    <Container topOuterSpacing={3.875}>
      <HeaderLogo />
    </Container>
    <Container topOuterSpacing={1.5}>
      <HeaderTitle />
    </Container>
    <Container topOuterSpacing={0.75}>
      <HeaderSubtitle />
    </Container>
    {!hasEmptyLibrary && (
      <Container topOuterSpacing={1.25}>
        <ShareThisLibrary />
      </Container>
    )}
  </React.Fragment>
)

const mapStateToProps = (state: IApplicationState) => ({
  hasEmptyLibrary: isEmpty(selectShareableContentLibraryMediaObjectIds(state)),
})

export { ShareableContentLibraryHeader }
export default compose<IInternalProps, {}>(connect(mapStateToProps))(ShareableContentLibraryHeader)
