import { PureComponent } from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IModalComponentProps } from '@context/Modal'
import { IApplicationState } from '@store'
import {
  selectHasRequestedLicense,
  selectIsLicensed,
  selectIsLoadingLicenseOptions,
  selectMediaObjectErrors,
  selectMediaObjectIsRequestingLicense,
  selectShouldShowLicenseOptions,
} from '@store/mediaObjects'
import { Modal } from '@tribegroup'
import Licensed from './Licensed/Licensed'
import LicensingOptions from './LicenseOptions/LicensingOptions'
import LicenseRequested from './LicenseRequested/LicenseRequested'

interface IContentLibraryLicensingProps {
  mediaObjectId: number
}

interface IInternalProps extends IContentLibraryLicensingProps, IModalComponentProps {
  isLicensed: boolean
  hasRequestedLicense: boolean
  isShowingLicenseOptions: boolean
  isRequesting: boolean
  hasErrors: boolean
  isLoadingLicenseOptions: boolean
}

export class MediaObjectLicensing extends PureComponent<IInternalProps> {
  componentDidUpdate(prevProps: IInternalProps) {
    const { isRequesting, hasErrors } = this.props
    if (prevProps.isRequesting && !isRequesting && !hasErrors) {
      this.props.onRequestClose()
    }
  }
  render() {
    const {
      isOpen,
      isLicensed,
      onRequestClose,
      mediaObjectId,
      hasRequestedLicense,
      isShowingLicenseOptions,
      isLoadingLicenseOptions,
    } = this.props
    return (
      <Modal
        isLoadingContent={isShowingLicenseOptions && isLoadingLicenseOptions}
        reactModalProps={{ isOpen: Boolean(isOpen) }}
        width={32.5}
        complex
      >
        {isShowingLicenseOptions && (
          <LicensingOptions mediaObjectId={mediaObjectId} onRequestClose={onRequestClose} />
        )}
        {hasRequestedLicense && (
          <LicenseRequested mediaObjectId={mediaObjectId} onRequestClose={onRequestClose} />
        )}
        {isLicensed && <Licensed mediaObjectId={mediaObjectId} onRequestClose={onRequestClose} />}
      </Modal>
    )
  }
}

const mapStateToProps = (state: IApplicationState, { mediaObjectId }: IInternalProps) => ({
  isShowingLicenseOptions: selectShouldShowLicenseOptions(state, mediaObjectId),
  hasRequestedLicense: selectHasRequestedLicense(state, mediaObjectId),
  isLicensed: selectIsLicensed(state, mediaObjectId),
  isRequesting: selectMediaObjectIsRequestingLicense(state),
  hasErrors: Boolean(selectMediaObjectErrors(state)),
  isLoadingLicenseOptions: selectIsLoadingLicenseOptions(state, mediaObjectId),
})

export default compose<IInternalProps, IContentLibraryLicensingProps & IModalComponentProps>(
  connect(mapStateToProps),
)(MediaObjectLicensing)
