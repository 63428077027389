import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import { mobileGutter, tabletGutter } from '@theme'

export const PaddedContainerNoSides = styled('div')`
  padding: 2.8125rem 0 1.875rem;
`

export const PaddedContainerCentered = styled.div`
  ${({ hasPaddingOnMobile }) =>
    hasPaddingOnMobile &&
    css`
      padding: 1.5625rem ${mobileGutter - 0.25}rem 1.875rem;
    `};
  ${breakpoint('tabletPortrait')`
    padding: 1.5625rem 10rem 1.875rem;
  `};
`

export default styled.div`
  position: relative;
  padding: 1.5625rem ${mobileGutter - 0.25}rem 1.875rem;
  ${breakpoint('desktop')`
    padding-left: ${tabletGutter - 0.25}rem;
    padding-right: ${tabletGutter - 0.25}rem;
  `};
`
