import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import {
  forgotPasswordDone,
  forgotPasswordRequest,
  PasswordActionTypes,
  setPasswordRequestError,
  setPasswordRequestSuccess,
} from '@store/password'
import { selectForgotPasswordLink, selectSetPasswordLink } from '@store/rootResource'
import { networkRequest } from '@utils'

export function* handleForgotPassword(action: ReturnType<typeof forgotPasswordRequest>) {
  const forgotPasswordLink: IHateoasLink = yield select(selectForgotPasswordLink)
  const { history, params } = action.payload
  try {
    yield call(networkRequest, forgotPasswordLink.method, forgotPasswordLink.href, params)
  } catch (error) {
    // leaving this empty to continue execution so even if the server responded
    // 4xx or 5xx status code it wont halt the redirect to the confirm page
  }
  yield put(forgotPasswordDone())
  yield call(history.push, `/forgotPasswordConfirmation?email=${encodeURIComponent(params.email)}`)
}

function* watchForgotPasswordRequest() {
  yield takeLatest(PasswordActionTypes.FORGOT_PASSWORD_REQUEST, handleForgotPassword)
}

export function* handleSetPassword(action) {
  try {
    const setPasswordLink: IHateoasLink = yield select(selectSetPasswordLink)
    const { history, params } = action.payload

    yield call(networkRequest, setPasswordLink.method, setPasswordLink.href, params)

    yield call(history.push, `/resetPasswordConfirmation?email=${encodeURIComponent(params.email)}`)
    yield put(setPasswordRequestSuccess())
  } catch (error) {
    yield put(setPasswordRequestError(error))
  }
}

function* watchSetPasswordRequest() {
  yield takeLatest(PasswordActionTypes.SET_PASSWORD_REQUEST, handleSetPassword)
}

export const passwordSagas: ReadonlyArray<any> = [
  watchSetPasswordRequest,
  watchForgotPasswordRequest,
]
