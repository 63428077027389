import equal from 'fast-deep-equal'

import { isEmpty } from '@utils'

export const difference = (object1, object2) => {
  if (isEmpty(object1)) {
    return object2
  }

  if (isEmpty(object2)) {
    return object1
  }

  return Object.keys(object1)
    .filter(key => !equal(object1[key], object2[key]))
    .reduce(
      (accum, curr) => ({
        ...accum,
        [curr]: object1[curr],
      }),
      {},
    )
}
