import styled from 'styled-components'

export const SocialPanelWrapper = styled.fieldset`
  position: relative;
  border-radius: 0.5rem;
  width: 100%;
  border: none;
  padding: 2rem 4.625rem 2rem 2.5rem;
  box-sizing: border-box;
  background: #fff;
`

export const TrashIconWrapper = styled.div`
  position: absolute;
  right: 1.375rem;
  top: 1.375rem;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const SocialWrapper = styled.span`
  margin-right: 0.5rem;
`
