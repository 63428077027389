import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { IApplicationState } from '@store'
import {
  selectBrandedContentAdDaysOptions,
  selectTikTokBrandedContentAdDaysOptions,
} from '@store/rootResource'
import {
  selectIsSubmissionFromTiktok,
  selectTransactedBrandedContentAd,
  selectTransactedBrandedContentAdDurationInDays,
} from '@store/submissions'
import Theme from '@theme'
import { Checkbox, Container, H4, Radio } from '@tribegroup'
import { isEmpty } from '@utils'
import DurationOptionsReadOnly from './ReadOnly'

interface IProps {
  submissionId: number
}

const DurationOptions = ({ submissionId }: IProps) => {
  const { register } = useFormContext()

  const { isEditable, durationInDays, durationInDaysOptions } = useSelector(
    (state: IApplicationState) => {
      const isTikTok = selectIsSubmissionFromTiktok(state, submissionId)

      return {
        durationInDaysOptions: !isTikTok
          ? selectBrandedContentAdDaysOptions(state)
          : selectTikTokBrandedContentAdDaysOptions(state),
        isEditable: isEmpty(selectTransactedBrandedContentAd(state, submissionId)),
        durationInDays: selectTransactedBrandedContentAdDurationInDays(state, submissionId),
      }
    },
  )

  if (!isEditable) {
    return (
      <DurationOptionsReadOnly
        durationInDays={durationInDays}
        durationInDaysOptions={durationInDaysOptions}
      />
    )
  }

  if (durationInDaysOptions.length === 1) {
    return (
      <Container bottomOuterSpacing={0.5} height={2}>
        <Checkbox
          checked
          backgroundColor={Theme.primary200Color}
          hideBorder
          scheme="primary"
          noHover
          label={
            <H4 color={Theme.defaultColor}>
              <FormattedMessage id="core.text.xDays" values={{ days: durationInDaysOptions[0] }} />
            </H4>
          }
          circular
          value={durationInDaysOptions[0]}
          name="duration_in_days"
          innerRef={register}
        />
      </Container>
    )
  }

  return (
    <div data-testid="duration-in-days-container">
      {durationInDaysOptions.map((durationOption) => (
        <Container bottomOuterSpacing={0.5} key={durationOption}>
          <Radio
            defaultChecked={durationOption === durationInDaysOptions[0]}
            scheme="primary"
            label={
              <H4 color={Theme.defaultColor} leftOuterSpacing={-0.25}>
                <FormattedMessage id="core.text.xDays" values={{ days: durationOption }} />
              </H4>
            }
            name="duration_in_days"
            value={durationOption}
            labelCapitalize={false}
            innerRef={register}
          />
        </Container>
      ))}
    </div>
  )
}

export { DurationOptions }
export default DurationOptions
