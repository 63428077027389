import React from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Flex, { FlexItem } from 'styled-flex-component'

import StopPropagation from '@components/UI/StopPropagation/StopPropagation'
import { EventTrackingNames, PageRoutes } from '@enums'
import { IApplicationState } from '@store'
import {
  selectBrandFanGroupDescription,
  selectBrandFanGroupMembeCountWithoutAvatars,
  selectBrandFanGroupMemberAvatars,
  selectBrandFanGroupMemberCount,
  selectBrandFanGroupName,
} from '@store/brandFans'
import { selectCreateCampaignLink } from '@store/campaigns'
import { Container } from '@tribegroup'
import BrandFansGroupRowCreateCampaignButton from './CreateCampaign/Button'
import BrandFansGroupRowActions from './Actions'
import { BrandFansGroupRowAvatarList, IBrandFansGroupRowAvatarListProps } from './AvatarList'
import BrandFansGroupRowDescription, { IBrandFansGroupRowDescriptionProps } from './Description'
import BrandFansRowHeader, { IBrandFansRowHeaderProps } from './Header'
import { HideContentOnHover, Root, ShowContentOnHover } from './Row.styled'
import BrandFansGroupRowTrackedRoute from './TrackedRoute'

interface IInternalProps
  extends IProps,
    RouteComponentProps,
    IBrandFansGroupRowAvatarListProps,
    IBrandFansRowHeaderProps,
    IBrandFansGroupRowDescriptionProps {
  hasCreateCampaignLink: boolean
}
interface IProps {
  groupId: number
}

const BrandFansGroupRow: React.FC<IInternalProps> = ({
  groupId,
  groupName,
  description,
  totalCount,
  avatars,
  identitiesWithoutAvatarCount,
  history,
  hasCreateCampaignLink,
}) => {
  const onClick = () => {
    history.push(`${PageRoutes.BrandFanGroups}/${groupId}`)
  }
  const canCreateCampaign = totalCount > 0
  return (
    <BrandFansGroupRowTrackedRoute
      hasTracking
      eventName={EventTrackingNames.BrandFansGroupDetailView}
      groupId={groupId}
    >
      <Root onClick={onClick}>
        <Flex justifyBetween alignCenter full>
          <BrandFansRowHeader groupName={groupName} totalCount={totalCount} />
          <FlexItem noShrink>
            <BrandFansGroupRowActions
              groupId={groupId}
              canCreateCampaign={canCreateCampaign}
              hideCreateCampaign={!hasCreateCampaignLink}
            />
          </FlexItem>
        </Flex>
        <Container height={3.75}>
          <Flex justifyBetween alignEnd full>
            <Container maxWidth={31.25}>
              {hasCreateCampaignLink ? (
                <>
                  <HideContentOnHover>
                    <BrandFansGroupRowDescription description={description} />
                  </HideContentOnHover>
                  <ShowContentOnHover>
                    <StopPropagation>
                      <Container noWrap>
                        <BrandFansGroupRowCreateCampaignButton
                          groupId={groupId}
                          canCreateCampaign={canCreateCampaign}
                        />
                      </Container>
                    </StopPropagation>
                  </ShowContentOnHover>
                </>
              ) : (
                <BrandFansGroupRowDescription description={description} />
              )}
            </Container>
            <FlexItem noShrink>
              <BrandFansGroupRowAvatarList
                avatars={avatars}
                identitiesWithoutAvatarCount={identitiesWithoutAvatarCount}
              />
            </FlexItem>
          </Flex>
        </Container>
      </Root>
    </BrandFansGroupRowTrackedRoute>
  )
}

const mapStateToProps = (state: IApplicationState, { groupId }: IProps) => ({
  groupName: selectBrandFanGroupName(state, groupId),
  description: selectBrandFanGroupDescription(state, groupId),
  avatars: selectBrandFanGroupMemberAvatars(state, groupId),
  totalCount: selectBrandFanGroupMemberCount(state, groupId),
  identitiesWithoutAvatarCount: selectBrandFanGroupMembeCountWithoutAvatars(state, groupId),
  hasCreateCampaignLink: Boolean(selectCreateCampaignLink(state)),
})

export { BrandFansGroupRow }
export default compose<IInternalProps, IProps>(
  withRouter,
  connect(mapStateToProps),
)(BrandFansGroupRow)
