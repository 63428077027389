import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { usePreapprovalAmountPercentage } from '@hooks/usePreapprovalAmountPercentage'
import { IApplicationState } from '@store'
import { preApprove, selectIsPreApproving } from '@store/submissions'
import { Button, Header } from '@tribegroup'

interface IProps {
  submissionId: number
  campaignId: number
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

const SubmissionCardModalPreApprovalPreapprovalCTA: React.FC<IInternalProps> = ({
  submissionId,
  campaignId,
  isPreApproving,
  preApprove: preApproveAction,
}) => {
  const percentage = usePreapprovalAmountPercentage()

  const onClick = () => preApproveAction(submissionId, campaignId)

  return (
    <Button
      fullWidth
      scheme="primary"
      height={3}
      onClick={onClick}
      loading={isPreApproving}
      disabled={isPreApproving}
      data-testid="pre-approval-cta"
    >
      <Header cta>
        <FormattedMessage
          id="submission.chat.preapproved.modal.cta"
          values={{
            percentage,
          }}
        />
      </Header>
    </Button>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  isPreApproving: selectIsPreApproving(state),
})

const mapDispatchToProps = {
  preApprove,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export default compose<IInternalProps, IProps>(connector)(
  SubmissionCardModalPreApprovalPreapprovalCTA,
)
