import React from 'react'

import { ActionBarLeft, ActionBarRight, ActionBarWrapper } from '@components/UI/Card/Card.styled'
import { useInboxFilter } from '@hooks/useInboxFilter'
import { ISubmission } from '@store/submissions'
import ActionBarIconApprove from '../Icon/Approve/Approve'
import ActionBarIconChat from '../Icon/Chat'
import ActionBarIconDecline from '../Icon/Decline/Decline'
import ActionBarIconShortlist from '../Icon/Shortlist/Shortlist'
import ActionBarIconUnshortlist from '../Icon/Unshortlist/Unshortlist'

interface IProps {
  campaignId: number
  hasShortlistLink: boolean
  hasUnshortlistLink: boolean
  hasDeclineLink: boolean
  hasFeedbackLink: boolean
  hasApproveLink: boolean
  hasRescheduleLink: boolean
  hasPreApproveLink: boolean
  submission: ISubmission
}

const SubmissionCardActionBarSocialInbox: React.FC<IProps> = ({
  hasShortlistLink,
  hasUnshortlistLink,
  hasDeclineLink,
  hasFeedbackLink,
  hasApproveLink,
  hasPreApproveLink,
  campaignId,
  submission,
}) => {
  const { currentFilter } = useInboxFilter()
  const modalProps = {
    currFilter: currentFilter,
    submission,
    campaignId,
  }

  const submissionId = submission.id

  return (
    <ActionBarWrapper justifyBetween>
      <ActionBarLeft>
        {hasShortlistLink && <ActionBarIconShortlist submissionId={submissionId} />}
        {hasUnshortlistLink && <ActionBarIconUnshortlist submissionId={submissionId} />}
        {hasFeedbackLink && <ActionBarIconChat submission={submission} campaignId={campaignId} />}
      </ActionBarLeft>
      <ActionBarRight>
        {hasDeclineLink && <ActionBarIconDecline modalProps={modalProps} />}
        {hasApproveLink && (
          <ActionBarIconApprove modalProps={modalProps} hasPreApproveLink={hasPreApproveLink} />
        )}
      </ActionBarRight>
    </ActionBarWrapper>
  )
}

export default SubmissionCardActionBarSocialInbox
