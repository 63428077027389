import { useContext } from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { CampaignContext } from '@context/Campaign'
import { PostCampaignReportListViewHeadersItem } from '../../ListView/Headers/Item'

export const AnalyticsPostCampaignReportListViewReelHeaders = () => {
  const {
    campaign: { is_price_hidden: isPriceHidden },
  } = useContext(CampaignContext)

  return (
    <Flex full>
      <PostCampaignReportListViewHeadersItem>
        <FormattedMessage id="analytics.postCampaignReport.submission.list.header.published" />
      </PostCampaignReportListViewHeadersItem>
      {!isPriceHidden && (
        <PostCampaignReportListViewHeadersItem>
          <FormattedMessage id="analytics.postCampaignReport.submission.list.header.spend" />
        </PostCampaignReportListViewHeadersItem>
      )}

      <PostCampaignReportListViewHeadersItem>
        <FormattedMessage id="analytics.postCampaignReport.submission.list.header.followers" />
      </PostCampaignReportListViewHeadersItem>
      <PostCampaignReportListViewHeadersItem>
        <FormattedMessage id="analytics.postCampaignReport.submission.list.header.likes" />
      </PostCampaignReportListViewHeadersItem>
      <PostCampaignReportListViewHeadersItem>
        <FormattedMessage id="analytics.postCampaignReport.submission.list.header.comments" />
      </PostCampaignReportListViewHeadersItem>
      <PostCampaignReportListViewHeadersItem>
        <FormattedMessage id="analytics.postCampaignReport.submission.list.header.shares" />
      </PostCampaignReportListViewHeadersItem>
      <PostCampaignReportListViewHeadersItem>
        <FormattedMessage id="analytics.postCampaignReport.submission.list.header.eng" />
      </PostCampaignReportListViewHeadersItem>
      <PostCampaignReportListViewHeadersItem>
        <FormattedMessage id="analytics.postCampaignReport.submission.list.header.engagementPct" />
      </PostCampaignReportListViewHeadersItem>
      {!isPriceHidden && (
        <PostCampaignReportListViewHeadersItem>
          <FormattedMessage id="analytics.postCampaignReport.submission.list.header.cpe" />
        </PostCampaignReportListViewHeadersItem>
      )}
      <PostCampaignReportListViewHeadersItem>
        <FormattedMessage id="analytics.postCampaignReport.submission.list.header.views" />
      </PostCampaignReportListViewHeadersItem>
      <PostCampaignReportListViewHeadersItem>
        <FormattedMessage id="analytics.postCampaignReport.submission.list.header.viewPct" />
      </PostCampaignReportListViewHeadersItem>
      {!isPriceHidden && (
        <>
          <PostCampaignReportListViewHeadersItem>
            <FormattedMessage id="analytics.postCampaignReport.submission.list.header.cpm" />
          </PostCampaignReportListViewHeadersItem>
          <PostCampaignReportListViewHeadersItem>
            <FormattedMessage id="analytics.postCampaignReport.submission.list.header.cpv" />
          </PostCampaignReportListViewHeadersItem>
        </>
      )}
    </Flex>
  )
}
