import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { Container, Icon } from '@tribegroup'

const CampaignShareModalToast: React.FC = () => {
  return (
    <Flex center>
      <Container rightOuterSpacing={1}>
        <FormattedMessage id="campaign.share.copyLink.toast" />
      </Container>
      <Icon glyph="tick" />
    </Flex>
  )
}

export default CampaignShareModalToast
