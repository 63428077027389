import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { CampaignActionTypes } from '@store/campaigns'
import {
  ClearDraftActionTypes,
  IDraftsState,
  SaveDraftActionTypes,
  SyncDraftActionTypes,
} from '@store/drafts'
import { LOCATION_CHANGE } from '@store/router/types'

const initialState: IDraftsState = {
  isSaving: false,
  campaign: undefined,
  errors: undefined,
}

const reducer: Reducer<IDraftsState> = (state = initialState, action) => {
  switch (action.type) {
    case SyncDraftActionTypes.SYNC_DRAFT_REQUEST:
    case SaveDraftActionTypes.SAVE_DRAFT_REQUEST: {
      return {
        ...state,
        isSaving: true,
        errors: undefined,
      }
    }

    case SyncDraftActionTypes.SYNC_DRAFT_SUCCESS:
    case SaveDraftActionTypes.SAVE_DRAFT_SUCCESS: {
      return {
        ...state,
        isSaving: false,
        campaign: action.payload,
      }
    }

    case SyncDraftActionTypes.SYNC_DRAFT_ERROR:
    case SaveDraftActionTypes.SAVE_DRAFT_ERROR: {
      return {
        ...state,
        isSaving: false,
        errors: action.payload,
      }
    }

    case ClearDraftActionTypes.CLEAR_DRAFT_REQUEST: {
      return {
        ...state,
        campaign: undefined,
      }
    }

    case CampaignActionTypes.FETCH_SUCCESS: {
      const pathname = String(action.meta && action.meta.location && action.meta.location.pathname)
      const hasBrandId = String(
        action.meta && action.meta.location && action.meta.location.search,
      ).includes('brandId')
      return {
        ...state,
        campaign: pathname.startsWith('/builder') && !hasBrandId ? action.payload : state.campaign,
      }
    }

    case LOCATION_CHANGE: {
      return {
        ...state,
        campaign: String(action.payload.pathname).startsWith('/builder')
          ? state.campaign
          : undefined,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as draftsReducer }
