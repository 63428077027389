import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const MainContainer = styled.div`
  margin-top: 3rem;
  padding: 1rem;
  ${breakpoint('tabletPortrait')`
    margin-top: 8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  `};
`

const Image = styled.img`
  max-width: 31.25rem;
  width: 100%;
  object-fit: contain;
  margin-bottom: 1rem;
`

const JumboHeader = styled.header`
  font-size: 3rem;
  font-weight: 300;
  color: ${({ color }) => color};
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${breakpoint('tabletPortrait')`
    justify-content: flex-start;
    text-align: left;
    margin-left: 3rem;
  `};
`

export { MainContainer, Image, JumboHeader, TextWrapper }
