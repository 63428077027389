import { FormattedMessage } from 'react-intl'

import LinkifiedString from '@components/UI/LinkifiedString'
import Theme from '@theme'
import CampaignPreviewBlock from '../Block'
import { BlockText } from '../Block/Block.styled'

export interface ICampaignPreviewObjectiveProps {
  text: string
}

const CampaignPreviewObjective = ({ text }: ICampaignPreviewObjectiveProps) => (
  <CampaignPreviewBlock
    empty={!text}
    emptyLines={[12.5, 15]}
    title={<FormattedMessage id="campaign.preview.contentWedLoveFromYou.header" />}
  >
    <BlockText small color={Theme.grey800Color}>
      <LinkifiedString value={text} color={Theme.primaryColor} />
    </BlockText>
  </CampaignPreviewBlock>
)

export default CampaignPreviewObjective
