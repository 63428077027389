import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import HeadTag from '@components/UI/HeadTag'
import TitleBar, { HeaderBackButton, TitleBarMobile } from '@components/UI/TitleBar'
import { HeaderTitle } from '@components/UI/TitleBar/TitleBar.styled'
import { PageRoutes } from '@enums'
import { IApplicationState } from '@store'
import { selectCampaignTitle } from '@store/campaigns'
import { selectCampaignId, selectSearchStatus } from '@store/router'
import Theme from '@theme'
import { H5 } from '@tribegroup'
import { objectToQueryString } from '@utils'

export interface IInternalProps {
  productName?: string
  searchStatus: string
}

const ReportsTitleBar: React.FC<IInternalProps> = ({ productName, searchStatus }) => {
  const queryString = objectToQueryString({ status: searchStatus })

  return (
    <React.Fragment>
      {productName && <HeadTag text={productName} />}
      <TitleBarMobile title={<FormattedMessage id={`inbox.sidebar.reports`} />} />
      <TitleBar desktopOnly>
        <Link
          to={{
            pathname: `/${PageRoutes.Campaigns}`,
            search: queryString,
          }}
        >
          <HeaderBackButton glyph="nav-back-default" size={1.5} color={Theme.grey900Color} />
        </Link>
        {productName && (
          <HeaderTitle data-cy-element="titlebar-call-to-action">
            <H5 color={Theme.grey900Color}>{productName}</H5>
          </HeaderTitle>
        )}
      </TitleBar>
    </React.Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { match, location }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match)!

  return {
    productName: selectCampaignTitle(state, campaignId),
    searchStatus: selectSearchStatus(location),
  }
}

export { ReportsTitleBar }

export default compose(withRouter, connect(mapStateToProps))(ReportsTitleBar)
