import React from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { TRIBE_CODES_OF_CONDUCT } from '@constants'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { BuilderDefault } from '@enums'
import { withBuilderValidation, withDraft } from '@hocs'
import Theme from '@theme'
import { FormInput, Link, List, Text, TextField } from '@tribegroup'
import { CodeConductWrapper, PanelSectionWrapper, WrappedIcon } from './DosAndDonts.styled'

interface IInternalProps
  extends InjectedIntlProps,
    IBuilderValidationContextProps,
    IDraftContextProps {}

const BuilderBriefDosAndDonts: React.FC<IInternalProps> = ({
  draft,
  clearFieldValidation,
  intl,
  checkValidationResults,
  getValidationMessage,
  updateForm,
}) => {
  const dos = draft.dos || []
  const donts = draft.donts || []

  const onDosListChanged = (updatedDos: ReadonlyArray<string>) => {
    updateForm({ dos: updatedDos })
    clearFieldValidation('dos_size')
  }

  const onDontsListChanged = (updatedDonts: ReadonlyArray<string>) => {
    updateForm({ donts: updatedDonts })
    clearFieldValidation('donts_size')
  }

  const onDosFocus = () => {
    clearFieldValidation('dos_size')
  }

  const onDontsFocus = () => {
    clearFieldValidation('donts_size')
  }

  return (
    <React.Fragment>
      <PanelSectionWrapper data-cy-element="dos-panel-wrapper">
        <FormInput
          validationRule={{
            min: {
              value: BuilderDefault.MIN_ARRAY_LENGTH_DOSANDDONTS,
              message: intl.formatMessage({ id: 'builder.brief.dosanddonts.min' }),
            },
            max: {
              value: BuilderDefault.MAX_ARRAY_LENGTH_DOSANDDONTS,
              message: intl.formatMessage({ id: 'builder.brief.dosanddonts.max' }),
            },
          }}
        >
          <TextField name="dos_size" type="hidden" value={dos.length} readOnly />
        </FormInput>
        <List
          header={intl.formatMessage({ id: 'builder.brief.dosanddonts.dos' })}
          icon={{
            color: Theme.successColor,
            backgroundColor: Theme.success100Color,
            glyph: 'tick-heavy',
          }}
          defaultValue={dos}
          placeholder={intl.formatMessage({
            id: 'builder.brief.dosanddonts.list.addNewNote.generic',
          })}
          emptyPlaceholder={intl.formatMessage({
            id: 'builder.brief.dosanddonts.list.addNewNote.dos',
          })}
          onChange={onDosListChanged}
          onFocus={onDosFocus}
          disableLineBreak
        />
        {checkValidationResults('dos_size') && (
          <Text small color={Theme.errorColor}>
            {getValidationMessage('dos_size')}
          </Text>
        )}
      </PanelSectionWrapper>
      <PanelSectionWrapper data-cy-element="donts-panel-wrapper">
        <FormInput
          validationRule={{
            min: {
              value: BuilderDefault.MIN_ARRAY_LENGTH_DOSANDDONTS,
              message: intl.formatMessage({ id: 'builder.brief.dosanddonts.min' }),
            },
            max: {
              value: BuilderDefault.MAX_ARRAY_LENGTH_DOSANDDONTS,
              message: intl.formatMessage({ id: 'builder.brief.dosanddonts.max' }),
            },
          }}
        >
          <TextField name="donts_size" type="hidden" value={donts.length} readOnly />
        </FormInput>
        <List
          header={intl.formatMessage({ id: 'builder.brief.dosanddonts.donts' })}
          icon={{
            color: Theme.alertColor,
            backgroundColor: Theme.alert100Color,
            glyph: 'cross-heavy',
          }}
          defaultValue={donts}
          placeholder={intl.formatMessage({
            id: 'builder.brief.dosanddonts.list.addNewNote.generic',
          })}
          emptyPlaceholder={intl.formatMessage({
            id: 'builder.brief.dosanddonts.list.addNewNote.donts',
          })}
          onChange={onDontsListChanged}
          onFocus={onDontsFocus}
          disableLineBreak
        />
        {checkValidationResults('donts_size') && (
          <Text small color={Theme.errorColor}>
            {getValidationMessage('donts_size')}
          </Text>
        )}
      </PanelSectionWrapper>
      <CodeConductWrapper>
        <Link href={TRIBE_CODES_OF_CONDUCT} target="_blank">
          <Text color={Theme.primaryColor}>
            {intl.formatMessage({ id: 'builder.brief.dosanddonts.codeOfConduct' })}
          </Text>
          <WrappedIcon glyph="chevron-right" color={Theme.primaryColor} />
        </Link>
      </CodeConductWrapper>
    </React.Fragment>
  )
}

export default compose<IInternalProps, {}>(
  injectIntl,
  withDraft,
  withBuilderValidation,
)(BuilderBriefDosAndDonts)
