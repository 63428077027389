import React, { useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import { AnalyticsPostPerformanceFlagSubmission } from '@components/Analytics/PostPerformance/FlagSubmission/FlagSubmission'
import { OffPlatformSubmissionMetricsUnavailableNotice } from '@components/Submission/Influencer/Profile/OffPlatformMetricUnavailableNotice'
import Loading from '@components/UI/Loading'
import { useSubmission } from '@context/Submission'
import { IApplicationState } from '@store'
import {
  fetchSubmissionPerformance,
  selectHasAnalytics,
  selectIsFetchingSubmissionPerformance,
} from '@store/performance'
import { selectSubmissionId } from '@store/router'
import { selectReportMetricIssueLink } from '@store/submissions'
import Theme from '@theme'
import { Container, H3, Icon } from '@tribegroup'
import AnalyticsPostPerformanceUnavailable from './Unavailable/Unavailable'
import AnalyticsPostPerformanceWrapper from './Wrapper/Wrapper'
import { AnalyticsPostPerformanceIconWrapper, Wrapper } from './PostPerformance.styled'

const AnalyticsPostPerformance: React.FC = () => {
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const { submission } = useSubmission()

  const { isFetching, hasAnalytics, submissionId, hasReportMetricLink } = useSelector(
    (state: IApplicationState) => {
      const submissionId = selectSubmissionId(match)!
      return {
        submissionId,
        isFetching: selectIsFetchingSubmissionPerformance(state),
        hasAnalytics: selectHasAnalytics(state, submissionId),
        hasReportMetricLink: Boolean(selectReportMetricIssueLink(state, submissionId)),
      }
    },
  )

  useEffect(() => {
    dispatch(fetchSubmissionPerformance(submissionId))
  }, [submissionId])

  if (isFetching) {
    return <Loading />
  }

  return (
    <Wrapper>
      <H3 color={Theme.grey900Color}>
        <FormattedMessage id="submission.performance.title" />
      </H3>
      <AnalyticsPostPerformanceIconWrapper alignCenter justifyCenter>
        <Icon glyph="data" size={2} color={Theme.primaryColor} />
      </AnalyticsPostPerformanceIconWrapper>
      {hasAnalytics ? (
        <AnalyticsPostPerformanceWrapper />
      ) : submission?.is_proxy || submission?.proxy_submission ? (
        <OffPlatformSubmissionMetricsUnavailableNotice type="postPurchase" />
      ) : (
        <AnalyticsPostPerformanceUnavailable />
      )}

      {hasReportMetricLink && (
        <Container topOuterSpacing={1.5} bottomInnerSpacing={1}>
          <AnalyticsPostPerformanceFlagSubmission
            hasAnalytics={hasAnalytics}
            submissionId={submissionId}
          />
        </Container>
      )}
    </Wrapper>
  )
}

export { AnalyticsPostPerformance }

export default AnalyticsPostPerformance
