import { IResponseError } from '@lib/error'
import { ILicenseOption } from '@store/campaigns'
import { ITransactedLicense } from '@store/mediaObjects'
import { IHateoasLink } from '..'

export interface ILicense3DSResponse {
  payment_intent_id: string
  payment_intent_status: string
  client_secret?: string
  license?: ILicense
  links: ReadonlyArray<IHateoasLink>
}

export enum MediaObjectLicenseOptionsActionTypes {
  FETCH_OPTIONS = 'mediaObjectLicenseOptions/FETCH_OPTIONS',
  FETCH_OPTIONS_SUCCESS = 'mediaObjectLicenseOptions/FETCH_OPTIONS_SUCCESS',
  FETCH_OPTIONS_ERROR = 'mediaObjectLicenseOptions/FETCH_OPTIONS_ERROR',
}

export interface ILicense extends ITransactedLicense {
  license_type?: string
  expiry_date?: string
  links: ReadonlyArray<IHateoasLink>
}

export interface ILicenseState {
  readonly errors?: IResponseError
  readonly isFetching: boolean
  readonly isPurchasing: boolean
  readonly isRequestingLicense: boolean
  readonly licenseOptionsByMediaObjectId: Record<number, ReadonlyArray<ILicenseOption>>
}
