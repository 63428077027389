import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { compose } from 'recompose'

import { PanelWrapper, TextareaErrorMessageWrapper } from '@components/Builder/Layout/Layout.styled'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { withBuilderValidation, withDraft } from '@hocs'
import { selectDraftIsSocial } from '@store/drafts'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { FormGroup, FormInput, H4, Panel, Text, TextArea } from '@tribegroup'
import { getInputElementTrimmedValue } from '@utils'
import BuilderBriefDosAndDonts from '../DosAndDonts/DosAndDonts'
import BuilderCampaignDosAndDontsTip from '../DosAndDonts/Tip'
import { DosAndDontsWrapper } from './Content.styled'

interface IInternalProps
  extends InjectedIntlProps,
    IBuilderValidationContextProps,
    IDraftContextProps {}

export const BuilderBriefContent = ({
  draft,
  intl,
  validationResults,
  clearFieldValidation,
  checkValidationResults,
  updateForm,
  setFormChanged,
  formChanged,
}: IInternalProps) => {
  const { isContentCampaign } = useSelector((state: IApplicationState) => ({
    isContentCampaign: !selectDraftIsSocial(state),
  }))
  const onObjectiveBlur = (event: React.SyntheticEvent) => {
    updateForm({ objective: getInputElementTrimmedValue(event.target) })
  }

  const onChange = (event: React.SyntheticEvent) => {
    if (!formChanged) {
      updateForm({ objective: getInputElementTrimmedValue(event.target) })
      setFormChanged(true)
    }
  }
  const objectiveHasError = checkValidationResults('objective')
  return (
    <PanelWrapper>
      <Panel
        title={
          <H4 color={Theme.defaultColor}>
            <FormattedMessage id="campaign.preview.contentWedLoveFromYou.header" />
          </H4>
        }
      >
        {!isContentCampaign && (
          <Text color={Theme.defaultColor}>
            <FormattedMessage id="builder.brief.contentWedLove.social.subText" />
          </Text>
        )}
        {isContentCampaign && (
          <Text color={Theme.defaultColor}>
            <FormattedMessage id="builder.brief.contentWedLove.content.subText" />
          </Text>
        )}
        <FormGroup
          outerTopSpacing={1.5}
          data-cy-element="content-we-would-love-from-you-form"
          outerBottomSpacing={1.5}
        >
          <FormInput
            validationRule={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: 'builder.brief.objectives.error.required',
                }),
              },
              maxLength: {
                value: 500,
                message: intl.formatMessage({ id: 'builder.campaign.error.max' }),
              },
            }}
          >
            <TextArea
              scheme="default"
              placeholder={intl.formatMessage({
                id: 'builder.brief.contentWedLove.placeholder',
              })}
              name="objective"
              value={draft.objective}
              height={9.375}
              characterLimit={500}
              onChange={onChange}
              invalid={objectiveHasError}
              onBlur={onObjectiveBlur}
              onFocus={clearFieldValidation}
            />
          </FormInput>

          {objectiveHasError && (
            <TextareaErrorMessageWrapper>
              <Text small color={Theme.errorColor}>
                {validationResults.objective.errorMessage}
              </Text>
            </TextareaErrorMessageWrapper>
          )}
        </FormGroup>
        <DosAndDontsWrapper data-cy-element="dos-and-donts-wrapper">
          <BuilderBriefDosAndDonts />
          <BuilderCampaignDosAndDontsTip />
        </DosAndDontsWrapper>
      </Panel>
    </PanelWrapper>
  )
}

export default compose<IInternalProps, {}>(
  injectIntl,
  withDraft,
  withBuilderValidation,
)(BuilderBriefContent)
