export const isEmpty = (value: any) => {
  if (value === null || value === undefined) {
    return true
  }
  // eslint-disable-next-line no-prototype-builtins
  if (value.hasOwnProperty('length')) {
    return value.length === 0
  }
  if (!isNaN(value)) {
    return !Number.isInteger(value)
  }
  return Object.keys(value).length === 0
}
