import { LicenseStatuses } from '@enums'
import { ISubmissionsPendingData } from '@store/postCampaignReport'

export const DEFAULT_TENANT = 'tribe'

export const APP_LOCALE = 'en-AU'

export const HIDDEN_CREDIT_CARD_PREFIX =
  '\u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022'

export const DEFAULT_SUBMISSIONS_PENDING_DATA: ISubmissionsPendingData = {
  posts: 0,
  videos: 0,
  stories: 0,
  tiktok: 0,
}
export const EMPTY_LIST: ReadonlyArray<any> = []

export const NOT_FOUND_STATUS = 404

export const DECINE_REASON_CHARACTER_LIMIT = 800

export const DEFAULT_PAID_PARTNERSHIP_HANDLE = '@tribe'

export const METRIC_DISPUTE_FEEDBACK_CHARACTER_LIMIT = 300

export const RELICENSE_STATUSES = [
  LicenseStatuses.RequestExpired,
  LicenseStatuses.DeclinedByBrand,
  LicenseStatuses.Cancelled,
]
