import { PropsWithChildren, PureComponent } from 'react'

import styled, { css } from 'styled-components'

import { IHasValidationRule } from '../FormValidation/ValidationRules'

export interface IFormInputProps extends IHasValidationRule {
  /**
   * Sets outer margin top in rem
   *
   * @default 0
   */
  outerTopSpacing?: number

  /**
   * Sets outer margin bottom in rem
   *
   * @default 0.4375
   */
  outerBottomSpacing?: number

  /**
   * Sets outer margin right in rem
   *
   * @default undefined
   */
  outerRightSpacing?: number
}

const FormInputWrapper = styled<IFormInputProps, any>('div')`
  ${({ outerTopSpacing = 0, outerBottomSpacing = 0, outerRightSpacing = 0 }) => css`
    margin-top: ${outerTopSpacing}rem;
    margin-bottom: ${outerBottomSpacing}rem;
    margin-right: ${outerRightSpacing}rem;
  `};
`

class FormInput extends PureComponent<PropsWithChildren<IFormInputProps>> {
  render() {
    const { validationRule, ...forminputProps } = this.props
    return (
      <FormInputWrapper
        className="formgroup-input"
        {...forminputProps}
        data-validation={JSON.stringify(validationRule)}
      />
    )
  }
}

export default FormInput
