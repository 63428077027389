import React, { PropsWithChildren } from 'react'

import { BuilderLayoutWrapper, MainContent } from './Layout.styled'

interface IBuilderLayoutProps {
  center?: boolean
}

const BuilderLayout: React.FC<PropsWithChildren<IBuilderLayoutProps>> = ({ children, center }) => {
  return (
    <BuilderLayoutWrapper>
      <MainContent center={center}>{children}</MainContent>
    </BuilderLayoutWrapper>
  )
}

export { BuilderLayout }
export default BuilderLayout
