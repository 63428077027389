import { ResponsiveObject } from 'react-slick'

import { breakpoints } from '@tribegroup/Theme'

// tslint:disable-next-line:readonly-array
const SLIDER_RESPONSIVE_CONFIG: ResponsiveObject[] = [
  {
    breakpoint: breakpoints.tabletLandscape,
    settings: { slidesToShow: 4, slidesToScroll: 4 },
  },
  {
    breakpoint: breakpoints.desktop,
    settings: { slidesToShow: 5, slidesToScroll: 5 },
  },
  {
    breakpoint: breakpoints.largeDesktop,
    settings: { slidesToShow: 6, slidesToScroll: 6 },
  },
  {
    breakpoint: breakpoints.extraLargeDesktop,
    settings: { slidesToShow: 7, slidesToScroll: 7 },
  },
]

export { SLIDER_RESPONSIVE_CONFIG }
export default SLIDER_RESPONSIVE_CONFIG
