import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'

import Confirmation from '@components/UI/Confirmation'
import { FooterButtonWrapper } from '@components/UI/Confirmation/Confirmation.styled'
import { Button } from '@tribegroup'

interface IAccountPaymentConfirmProps {
  campaignCount?: number
  onConfirm: VoidFunction
  onCancel: VoidFunction
  isOpen: boolean
}

export const AccountPaymentConfirm = ({
  campaignCount,
  onConfirm,
  onCancel,
  isOpen,
}: IAccountPaymentConfirmProps) => {
  return (
    <Confirmation
      title={<FormattedMessage id="account.payment.confirm.title" values={{ campaignCount }} />}
      content={<FormattedMessage id="account.payment.confirm.body" />}
      footerButtons={
        <Fragment>
          <FooterButtonWrapper>
            <Button
              scheme="primary"
              quiet
              onClick={onCancel}
              data-cy-element="update-payment-details-modal-cancel-button"
            >
              <FormattedMessage id="core.text.cancel" />
            </Button>
          </FooterButtonWrapper>
          <FooterButtonWrapper>
            <Button
              scheme="primary"
              onClick={onConfirm}
              data-cy-element="update-payment-details-modal-update-button"
            >
              <FormattedMessage id="core.text.update" />
            </Button>
          </FooterButtonWrapper>
        </Fragment>
      }
      isOpen={isOpen}
      onCloseIconClick={onCancel}
    />
  )
}

export default AccountPaymentConfirm
