import { keyframes } from 'styled-components'

export const Pulse = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }
  29% {
    transform: scale(0);
    opacity: 0;
  }
  30% {
    transform: scale(1);
    opacity: 1;
  }
  99% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`
