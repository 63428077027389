import { FormattedMessage } from 'react-intl'

import { SideBarContentWrapper } from '@components/UI/Coachmark/Coachmark.styled'
import { Text, Tip } from '@tribegroup'
import BuilderCampaignFindProductTipContent from './Content'

export const BuilderCampaignFindProductTip = () => {
  return (
    <SideBarContentWrapper marginTop={0.75} marginLeft={4.75}>
      <Tip
        text={
          <Text xsmall uppercase data-testid="find-product-tooltip">
            <FormattedMessage id="builder.campaign.findProduct.label" />
          </Text>
        }
        width={13}
        coachmark={{
          content: <BuilderCampaignFindProductTipContent />,
          position: 'left',
          width: 25,
          yOffset: 10,
        }}
      />
    </SideBarContentWrapper>
  )
}

export default BuilderCampaignFindProductTip
