import { createAction } from '@reduxjs/toolkit'

import { IPublishedSummary, PublishedSummaryActionTypes } from '@store/publishedSummary'

export const fetchPublishedSummary = createAction<number>(PublishedSummaryActionTypes.FETCH_REQUEST)

export const fetchPublishedSummarySuccess = createAction(
  PublishedSummaryActionTypes.FETCH_SUCCESS,
  (campaignId: number, response: IPublishedSummary) => ({
    payload: {
      campaignId,
      publishedSummary: response,
    },
  }),
)

export const fetchPublishedSummaryError = createAction<unknown>(
  PublishedSummaryActionTypes.FETCH_ERROR,
)
