import { addDays, differenceInDays } from 'date-fns'
import { createSelector } from 'reselect'

import {
  DAYS_TO_HIDE_REMAINING_BUDGET,
  EXPIRATION_DATE_BUFFER_DAYS,
} from '@components/Campaign/Card/BudgetTracker/constants'
import {
  ALL,
  DEFAULT_PAID_PARTNERSHIP_HANDLE,
  DEFAULT_TENANT,
  EMPTY_LIST,
  INBOX_FILTER,
} from '@constants'
import {
  BrandManagerIdentityProviders,
  CampaignStatuses,
  ErrorCodes,
  MemberRoleTypes,
  PaymentMethods,
  SubmissionChannels,
  SubmissionStatuses,
} from '@enums'
import { IResponseErrorMessage } from '@lib/error'
import { IApplicationState, IHateoasLink } from '@store'
import { selectBrandManagerTikTokAccounts } from '@store/brandManagerIdentities'
import {
  ICampaign,
  ICampaignPreferences,
  IConnectedSocialAccount,
  ILicenseOption,
} from '@store/campaigns'
import { selectCampaignsLink as selectCampaignsLinkFromMe } from '@store/me'
import { selectAudienceLocations, selectOperatingRegions } from '@store/rootResource'
import { selectCurrentSubmissionFilter, selectIsInboxView, selectPathname } from '@store/router'
import {
  abbreviateNumber,
  generateEditLink,
  getCampaignProperties,
  getSubmissionLocationProps,
  isEmpty,
} from '@utils'
import { isSocialCampaign } from '@utils/campaign'

export const selectCampaignsByStatus = (
  state: IApplicationState,
  status = ALL,
): ReadonlyArray<ICampaign> => {
  return state.campaigns.campaignIdsByStatus?.[status]?.map((id) => selectCampaign(state, id))
}

export const selectCampaign = (state: IApplicationState, campaignId: number) =>
  state.campaigns.campaignsById[campaignId] || {}

export const selectCampaignsIsFetched = (state: IApplicationState) => state.campaigns.isFetched

export const selectCampaignLinks = (state: IApplicationState, campaignId: number) => {
  return selectCampaign(state, campaignId).links || EMPTY_LIST
}

export const selectCampaignsListStats = (state: IApplicationState) => state.campaigns.stats

export const selectAllCampaignCount = (state: IApplicationState) =>
  selectCampaignsCountByStatus(state, 'all')

export const selectCampaignsCountByStatus = (state: IApplicationState, status: string) =>
  (selectCampaignsListStats(state) && selectCampaignsListStats(state)[status]) || 0

export const selectCampaignsHasMore = (state: IApplicationState, status = ALL) => {
  const total = selectCampaignsCountByStatus(state, status) || {}
  const stored = selectCampaignsByStatus(state, status) || EMPTY_LIST
  return stored.length < total
}

export const selectCampaignsIsFetching = (state: IApplicationState) => state.campaigns.isFetching

export const selectIsUpdatingPreferences = (state: IApplicationState) =>
  state.campaigns.isUpdatingPreferences

export const selectCampaignLink = (state: IApplicationState, campaignId: number, linkRel: string) =>
  selectCampaignLinks(state, campaignId).find((link) => link.rel === linkRel)

export const selectActivateLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'activate')

export const selectPauseLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'pause')

export const selectEditLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'edit')

export const selectSubmissionsLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'submissions')

export const selectFinalizeLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'finalize_brief')

export const selectDownloadInstagramHandlesLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'download_instagram_handles')

export const selectDisableRequireApprovalPromptLink = (
  state: IApplicationState,
  campaignId: number,
) => selectCampaignLink(state, campaignId, 'disable_require_approval_prompt')

export const selectHasDisableRequireApprovalPromptLink = createSelector(
  selectDisableRequireApprovalPromptLink,
  (link) => Boolean(link),
)

export const selectCampaignsLink = (state: IApplicationState, status: string, linkRel: string) => {
  return state.campaigns.linksByStatus[status]?.find((link) => link.rel === linkRel)
}

export const selectCampaignsNextLink = (state: IApplicationState, status: string) =>
  selectCampaignsLink(state, status, 'next')

export const selectCampaignsSelfLink = (state: IApplicationState, status: string) =>
  selectCampaignsLink(state, status, 'self')

export const selectSaveCampaignLink = (state: IApplicationState, campaignId: number) => {
  if (campaignId) {
    return selectUpdateCampaignLink(state, campaignId)
  }
  return selectCreateCampaignLink(state)
}

export const selectCreateCampaignLink = (state: IApplicationState) =>
  selectCampaignsLink(state, ALL, 'create_brief')

export const selectUpdateCampaignLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'edit')

export const selectUpdatePreferencesLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'update_preferences')

export const selectBulkDeclineLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'bulk_decline')

export const selectBulkAddFeedbackLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'bulk_feedback')

export const selectFollowersPerAccount = (state: IApplicationState, campaignId: number) => {
  const campaign = selectCampaign(state, campaignId)
  if (!campaign || !campaign.rate_card) {
    return []
  }

  return campaign.rate_card.image.reduce((accum, curr) => {
    const minFollowers = abbreviateNumber(curr.minimum_followers)
    const maxFollowers = curr.maximum_followers && abbreviateNumber(curr.maximum_followers)
    return [...accum, `${minFollowers}${maxFollowers ? `-${maxFollowers}` : '+'}`]
  }, [])
}

export const selectErrors = (state: IApplicationState) => state.campaigns.errors

export const selectPaymentErrorMessage = (state: IApplicationState) => {
  const errors = selectErrorMessages(state)
  const errorMessage = errors && errors[0] && (errors[0].message || errors[0])
  return errorMessage
}

export const selectErrorMessages = (state: IApplicationState) => {
  const error = selectErrors(state)
  return error && (error.messages as ReadonlyArray<IResponseErrorMessage>)
}

export const selectCampaignHasPermissionError = (state: IApplicationState) => {
  const errorMessages = selectErrorMessages(state)
  if (errorMessages) {
    const message = errorMessages[0] as any
    const code = message.error_code as string
    return code === ErrorCodes.ERROR_CODE_CAMPAIGN_PERMISSION_ERROR
  }
  return false
}

export const selectCampaignInsufficientCreditForActivationError = (state: IApplicationState) => {
  const errorMessages = selectErrorMessages(state)
  if (!errorMessages || errorMessages.length === 0) {
    return false
  }
  const [error] = errorMessages
  return error.error_code === ErrorCodes.ERROR_CODE_ACTIVATE_BRIEF_INSUFFICIENT_CREDIT
}

export const selectCampaignLicenseCard = createSelector(
  selectCampaign,
  (campaign) => campaign.license_card || EMPTY_LIST,
)

export const selectShouldHideCampaignBudget = createSelector(selectCampaign, (campaign) => {
  const { ended_at } = campaign

  if (!ended_at) return false

  const endedAtWithBuffer = addDays(new Date(ended_at), EXPIRATION_DATE_BUFFER_DAYS)
  const days_diff = differenceInDays(new Date(), endedAtWithBuffer)

  return days_diff >= DAYS_TO_HIDE_REMAINING_BUDGET
})

export const selectCampaignLicenseCardByMediaType = createSelector(
  selectCampaignLicenseCard,
  (state, campaignId, mediaType) => mediaType,
  (licenseOptions: ReadonlyArray<ILicenseOption>, mediaType) =>
    (licenseOptions.filter((licenseOption: ILicenseOption) => {
      //  TODO - when BE FT is turned on for videoLicensingPricingEnabled, remove this undefined check
      if (licenseOption.media_type === undefined) {
        return true
      }
      return licenseOption.media_type === mediaType
    }) || EMPTY_LIST) as ReadonlyArray<ILicenseOption>,
)

export const selectCampaignMinimumLicenseOption = createSelector(
  selectCampaignLicenseCardByMediaType,
  (licenseCard) => {
    return licenseCard?.[0] || ({} as ILicenseOption)
  },
)
export const selectCampaignIsSaving = (state: IApplicationState) => {
  return Boolean(state.campaigns.isSaving)
}

export const selectCampaignIsFinalizing = (state: IApplicationState) => {
  return state.campaigns.isFinalizing
}

export const selectHasSeenPreviewTooltip = (state: IApplicationState) =>
  state.campaigns.hasSeenPreviewTooltip

export const selectCampaignTitle = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.product_name,
)

export const selectCampaignHouseRules = createSelector(selectCampaign, (campaign: ICampaign) => {
  const houseRules = campaign.house_rules || []
  if (process.env.APP_NAME === DEFAULT_TENANT) {
    return houseRules
  }
  return houseRules.slice(0, -1)
})

export const selectSubmissionsFilters = createSelector(
  selectSubmissionsLink,
  (link: IHateoasLink) => {
    const filters = (link && link.filter) || EMPTY_LIST
    return filters
  },
)

export const selectRole = createSelector(selectCampaign, (campaign: ICampaign) => campaign.role)

export const selectCampaignStatus = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.status,
)
export const selectCampaignAnalyticsProperties = createSelector(
  selectCampaign,
  (state, submissionId, location) => location,
  (campaign: ICampaign | undefined, location) => {
    return (
      campaign && {
        ...getCampaignProperties(campaign),
        ...getSubmissionLocationProps(location),
      }
    )
  },
)

export const selectIsSocialCampaign = createSelector(selectCampaign, (campaign: ICampaign) =>
  isSocialCampaign(campaign),
)

export const selectIsTikTokCampaign = createSelector(selectCampaign, (campaign: ICampaign) =>
  Boolean(campaign?.channels?.some(({ name }) => name === SubmissionChannels.TikTok)),
)

export const selectIsContentCampaign = createSelector(
  selectCampaign,
  (campaign: ICampaign) => !isSocialCampaign(campaign),
)

export const selectIsPCRGeneratable = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.pcr_generatable,
)

export const selectInfluencerIdentitiesLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'submission_identities')

export const selectPostCampaignReportLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'in_app_pcr')

export const selectAudienceReportingLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'audience_reporting')

export const selectIsCampaignFetched = createSelector(
  selectCampaign,
  (campaign?: ICampaign) => !isEmpty(campaign),
)

export const selectAutoApprovalAmount = createSelector(
  selectCampaign,
  (campaign: ICampaign) => (campaign.auto_approval_amount_cents || 0) / 100,
)

export const selectAutoApprovalCurrency = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.auto_approval_amount_currency,
)

export const selectBudgetTotalCurrency = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.budget_total_currency,
)

export const selectCreditAmountCurrency = createSelector(
  selectAutoApprovalCurrency,
  selectBudgetTotalCurrency,
  (autoApprovalCurrency: string, budgetTotalCurrency: string) =>
    autoApprovalCurrency || budgetTotalCurrency,
)

export const selectDisplayInfluencerView = createSelector(
  selectInfluencerIdentitiesLink,
  selectCurrentSubmissionFilter,
  (influencerIdentitiesLink, currentFilter) =>
    Boolean(influencerIdentitiesLink) && currentFilter === INBOX_FILTER,
)

export const selectCampaignBudgetRemaining = createSelector(
  selectCampaign,
  (campaign) => campaign.budget_remaining_cents,
)

export const selectCampaignBudgetSpent = createSelector(
  selectCampaign,
  (campaign) => campaign.budget_spent_cents,
)

export const selectCampaignTotalCurrency = createSelector(
  selectCampaign,
  (campaign) => campaign.budget_total_currency,
)

export const selectDisplayCampaignBudget = createSelector(
  selectCampaignBudgetRemaining,
  selectCampaignBudgetSpent,
  (remaining, spent) => !isEmpty(remaining) || !isEmpty(spent),
)

export const selectCampaignBrandName = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.brand_name,
)

export const selectIsSamplingEnabled = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.sampling_enabled || false,
)

export const selectInfluencerIdentitiesSortByOptions = createSelector(
  selectInfluencerIdentitiesLink,
  (link) => (link && link.sort_by) || EMPTY_LIST,
)

export const selectHasBrandPartnershipHandle = createSelector(
  selectCampaign,
  (campaign: ICampaign) => {
    if (campaign.has_brand_partnership_handle === undefined) {
      return true
    }
    return campaign.has_brand_partnership_handle
  },
)

export const selectPaidPartnershipHandle = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.paid_partnership_handle,
)

export const selectShowPaidPartnershipHandle = createSelector(
  selectPaidPartnershipHandle,
  selectHasBrandPartnershipHandle,
  (paidPartnershipHandle, hasBrandPartnershipHandle) => {
    return Boolean(paidPartnershipHandle) && hasBrandPartnershipHandle
  },
)

export const selectHasAnyAction = createSelector(
  selectActivateLink,
  selectPauseLink,
  selectEditLink,
  (activateLink, editLink, pauseLink) => [activateLink, editLink, pauseLink].some(Boolean),
)

export const selectCampaignPreferences = createSelector(
  selectCampaign,
  (campaign: ICampaign) => (campaign.preferences || {}) as ICampaignPreferences,
)

export const selectShowDownloadInstagramHandlesButton = createSelector(
  selectDownloadInstagramHandlesLink,
  selectCurrentSubmissionFilter,
  (link, filter) => filter === SubmissionStatuses.Approved && Boolean(link),
)

export const selectInternalEditLink = createSelector(
  selectEditLink,
  selectCampaign,
  selectPathname,
  (editLink: IHateoasLink, campaign: ICampaign, pathname: string) => {
    const searchParams = campaign.status === CampaignStatuses.Draft ? '' : `?from=${pathname}`
    return Boolean(editLink) && `${generateEditLink(campaign)}${searchParams}`
  },
)

export const selectCampaignBrandFanGroupIds = createSelector(
  selectCampaign,
  (campaign) => campaign.brand_fan_group_ids || (EMPTY_LIST as ReadonlyArray<number>),
)

export const selectHasBrandFanGroupAssociation = createSelector(
  selectCampaignBrandFanGroupIds,
  (groupIds) => groupIds.length > 0,
)

export const selectRequestedSocialSubmissionTypes = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.requested_social_submission_types,
)

export const selectCampaignRegionCode = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.accounting_region_code,
)

export const selectCampaignOperatingRegion = createSelector(
  (state: IApplicationState) => selectOperatingRegions(state),
  selectCampaignRegionCode,
  (operatingRegions, regionCode) =>
    operatingRegions.find((operatingRegion) => operatingRegion.region_code === regionCode),
)

export const selectCampaignOperatingRegionTaxName = createSelector(
  (state, campaignId, intl: ReactIntl.InjectedIntl) => intl,
  (state, campaignId, intl: ReactIntl.InjectedIntl, fallbackCurrency: string | undefined) =>
    fallbackCurrency,
  selectBudgetTotalCurrency,
  (intl, currency, budgetTotalCurrency) => {
    return intl.formatMessage({
      id: `core.currency.taxDisplay.${currency ?? budgetTotalCurrency ?? 'generic'}`,
    })
  },
)

export const selectTargetAudienceAgeRange = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.audience_age_range,
)

export const selectTargetAudienceGender = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.audience_gender,
)

export const selectTargetAudienceLocationCodes = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.audience_location_codes,
)

export const selectFirebaseURL = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.firebase_url,
)

export const selectPaymentMethod = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.payment_method,
)

export const selectIsPayingByCreditCard = createSelector(
  selectPaymentMethod,
  (paymentMethod: PaymentMethods) => paymentMethod === PaymentMethods.CREDIT_CARD,
)

export const selectShortlistSummaryLink = (state: IApplicationState, campaignId: number) =>
  selectCampaignLink(state, campaignId, 'shortlist_summary')

export const selectCampaignTarget = createSelector(
  selectCampaign,
  selectAudienceLocations,
  (campaign, audienceLocations) => {
    const locationCodes = campaign.audience_location_codes
    const locations =
      locationCodes?.map((entry) => {
        return audienceLocations[audienceLocations.findIndex((data) => data.code === entry)].display
      }) || []

    const ageRange = campaign?.audience_age_range || []
    const sortedAgeRange = [...ageRange].sort()
    const age =
      sortedAgeRange.reduce((accumulator: string[], currentValue, index) => {
        if (!accumulator) {
          return undefined
        }

        const [currentMinAge, currentMaxAge] = currentValue.includes('-')
          ? currentValue.split('-')
          : Array(2).fill(currentValue)

        if (index === 0) {
          return [`${currentMinAge}-${currentMaxAge}`]
        }

        const [storedMinAge, storedMaxAge] = accumulator[0].split('-')

        if (parseInt(storedMaxAge) + 1 === parseInt(currentMinAge)) {
          return [`${storedMinAge}-${currentMaxAge}`]
        } else {
          return undefined
        }
      }, []) || sortedAgeRange

    return {
      age,
      genders: campaign.audience_gender,
      locations,
    }
  },
)

export const selectFetchCampaignsLink = createSelector(
  selectCampaignsNextLink,
  selectCampaignsSelfLink,
  (state) => selectCampaignsLinkFromMe(state),
  (state, status, isFetchNext) => isFetchNext,
  (nextLink, selfLink, campaignsLinkFromMe, isFetchNext) => {
    if (isFetchNext) {
      return nextLink
    }
    if (selfLink) {
      return selfLink
    }
    return campaignsLinkFromMe
  },
)

export const selectConnectCampaignSocialAccountLink = (
  state: IApplicationState,
  campaignId: number,
) => selectCampaignLink(state, campaignId, 'connect_social_account')

export const selectDisconnectCampaignSocialAccountLink = (
  state: IApplicationState,
  campaignId: number,
) => selectCampaignLink(state, campaignId, 'disconnect_social_account')

export const selectIsConnectingCampaignIdentity = (state: IApplicationState) =>
  state.campaigns.isConnectingIdentity

export const selectHasApprovedTikTokSubmission = createSelector(
  selectCampaign,
  (campaign: ICampaign) => Boolean(campaign.has_approved_tiktok_submission),
)

export const selectActivateWithFeeLink = (
  state: IApplicationState,
  campaignId: number,
): IHateoasLink | null => selectCampaignLink(state, campaignId, 'activate_with_fee')

export const selectIsActivatingWithFee = (state: IApplicationState) =>
  state.campaigns.isActivatingWithFee

export const selectIsActivating = (state: IApplicationState) =>
  Boolean(state.campaigns.isActivating)

export const selectPublishedSummaryLink = (
  state: IApplicationState,
  campaignId: number,
): IHateoasLink => selectCampaignLink(state, campaignId, 'published_summary')

export const selectRequestPreSignedUrlLink = (
  state: IApplicationState,
  campaignId: number,
): IHateoasLink => selectCampaignLink(state, campaignId, 'moodboard_request_presigned_url')

export const selectBriefActivationFeeCents = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.activation_fee_cents,
)

export const selectBriefActivationFeeCurrency = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.activation_fee_currency,
)

export const selectBriefActivationFeeStatus = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign.activation_fee_status,
)

export const selectBriefChannelPreference = createSelector(
  selectCampaign,
  (campaign: ICampaign) => campaign?.channels?.map((channel) => channel.name) || [],
)

export const selectDisplayPaidPartnershipHandle = createSelector(
  selectPaidPartnershipHandle,
  (handle: string) => {
    return handle !== DEFAULT_PAID_PARTNERSHIP_HANDLE ? handle : undefined
  },
)

export const selectShouldShowConnectAccountButton = createSelector(
  selectIsCampaignFetched,
  selectRole,
  selectCampaignStatus,
  selectIsSocialCampaign,
  selectBriefChannelPreference,
  (isCampaignFetched, role: MemberRoleTypes, campaignStatus, isSocial, channels) => {
    if (!isCampaignFetched || !isSocial) {
      return false
    }

    const supportedStatuses = [
      CampaignStatuses.Active,
      CampaignStatuses.Paused,
      CampaignStatuses.Expired,
      CampaignStatuses.Completed,
    ]

    const supportedRoles = [
      MemberRoleTypes.Owner,
      MemberRoleTypes.Collaborator,
      MemberRoleTypes.SolutionCollaborator,
    ]

    const supportedChannels = [SubmissionChannels.Instagram, SubmissionChannels.TikTok]

    const isSupportedChannelIncluded = channels.some((channel) =>
      supportedChannels.includes(channel),
    )

    return (
      supportedStatuses.includes(campaignStatus) &&
      supportedRoles.includes(role) &&
      isSupportedChannelIncluded
    )
  },
)

export const selectCampaignConnectedSocialAccount = createSelector(
  selectCampaign,
  (state, campaignId, provider: BrandManagerIdentityProviders) => provider,
  (campaign, provider): IConnectedSocialAccount | undefined => {
    return campaign?.connected_social_accounts?.find(
      (socialAccount) => socialAccount.channel === provider,
    )
  },
)

export const selectConnectedTikTokSocialAccount = (state: IApplicationState, campaignId) =>
  selectCampaignConnectedSocialAccount(state, campaignId, BrandManagerIdentityProviders.TikTok)

export const selectShouldShowToggleOnTikTokAdsManagerAccountPrompt = createSelector(
  selectBrandManagerTikTokAccounts,
  selectIsCampaignFetched,
  selectCampaignStatus,
  selectIsInboxView,
  selectIsSocialCampaign,
  selectIsTikTokCampaign,
  selectConnectedTikTokSocialAccount,
  selectRole,
  (
    tikTokAccounts,
    isCampaignFetched,
    campaignStatus,
    isInboxView,
    isSocial,
    isTikTokCampaign,
    connectedTikTokAccount,
    role,
  ) => {
    const supportedStatuses = [
      CampaignStatuses.Scheduled,
      CampaignStatuses.Active,
      CampaignStatuses.Paused,
      CampaignStatuses.Expired,
    ]

    if (
      !isCampaignFetched ||
      !isSocial ||
      !isInboxView ||
      !isTikTokCampaign ||
      !supportedStatuses.includes(campaignStatus) ||
      process.env.APP_NAME !== DEFAULT_TENANT ||
      role !== MemberRoleTypes.Owner
    ) {
      return false
    }

    return tikTokAccounts.length !== 0 && !connectedTikTokAccount
  },
)

export const selectShouldShowConnectTikTokAccountPrompt = createSelector(
  selectBrandManagerTikTokAccounts,
  selectIsCampaignFetched,
  selectRole,
  selectCampaignStatus,
  selectIsInboxView,
  selectIsSocialCampaign,
  selectIsTikTokCampaign,
  selectHasApprovedTikTokSubmission,
  selectConnectedTikTokSocialAccount,
  (
    tikTokAccounts,
    isCampaignFetched,
    role: MemberRoleTypes,
    campaignStatus,
    isInboxView,
    isSocial,
    isTikTokCampaign,
    hasApproveTikTokSubmission,
    connectedTikTokSocialAccount,
  ) => {
    const supportedBriefRole = [
      MemberRoleTypes.Collaborator,
      MemberRoleTypes.SolutionCollaborator,
      MemberRoleTypes.Owner,
    ]
    const supportedStatuses = [
      CampaignStatuses.Active,
      CampaignStatuses.Paused,
      CampaignStatuses.Expired,
    ]
    if (
      hasApproveTikTokSubmission ||
      !isCampaignFetched ||
      !isSocial ||
      !isInboxView ||
      !supportedBriefRole.includes(role as MemberRoleTypes) ||
      !supportedStatuses.includes(campaignStatus) ||
      !isTikTokCampaign ||
      process.env.APP_NAME !== DEFAULT_TENANT
    ) {
      return false
    }

    if ([MemberRoleTypes.Collaborator, MemberRoleTypes.SolutionCollaborator].includes(role)) {
      return isEmpty(connectedTikTokSocialAccount)
    }

    return !tikTokAccounts.length
  },
)

export const selectIsPriceHidden = createSelector(selectCampaign, (campaign) => {
  if (campaign.is_price_hidden === undefined) {
    return false
  }
  return Boolean(campaign.is_price_hidden)
})

export const selectIsTraditional = createSelector(selectCampaign, (campaign) => {
  if (campaign.traditional === undefined) {
    return true
  }
  return Boolean(campaign.traditional)
})

export const selectHasProxySubmissions = createSelector(selectCampaign, (campaign) => {
  return Boolean(campaign.has_proxy_submissions)
})
