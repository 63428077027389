import ConnectedAccounts from '@components/ConnectedAccounts/ConnectedAccounts'
import ConnectedAccountsTitleBar from '@components/ConnectedAccounts/TitleBar/TitleBar'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import { PaddedContainerCentered } from '@components/UI/PaddedContainer/PaddedContainer.styled'
import { ModalProvider } from '@context/Modal'
import { ToastContainer } from '@tribegroup'

export const ConnectedAccountsPage = () => (
  <MainLayout>
    <ModalProvider>
      <ToastContainer />
      <HeadTag id="core.text.connectedAccounts" />
      <ConnectedAccountsTitleBar />
      <PaddedContainerCentered>
        <ConnectedAccounts />
      </PaddedContainerCentered>
    </ModalProvider>
  </MainLayout>
)

export default ConnectedAccountsPage
