import { ComponentType, PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'

interface ICardGridAreaProps {
  name: string
  transparent?: boolean
}

export const CardGridArea: ComponentType<PropsWithChildren<ICardGridAreaProps>> = styled.div`
  grid-area: ${({ name }) => name};
  background: ${Theme.whiteColor};
  ${({ transparent }) =>
    transparent &&
    css`
      background: transparent;
    `}
`

export const CardMediaGridArea = styled.div`
  grid-area: media;
  background: ${Theme.whiteColor};
  ${breakpoint('tabletPortrait')`
    height: 26.875rem;
  `};
  ${breakpoint('tabletLandscape')`
    height: 31.25rem;
  `};
`

export const SubmissionCardDetailsWrapper = styled.div`
  overflow: auto;
  grid-area: details;
  background: ${Theme.whiteColor};
  box-sizing: border-box;
`

export const SubmissionCardMediaWrapper = styled.div`
  margin-bottom: 5rem;
  ${breakpoint('tabletPortrait')`
    margin-bottom: 0;
  `};
`
