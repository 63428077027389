import { FormattedMessage } from 'react-intl'

import { SideBarContentWrapper } from '@components/UI/Coachmark/Coachmark.styled'
import { Container, Text, Tip } from '@tribegroup'
import BuilderBriefChannelsInstagramPaidPartershipTipCoachMark from './CoachMark'

const BuilderBriefChannelsInstagramPaidPartershipTip: React.FC = () => {
  return (
    <SideBarContentWrapper marginLeft={7.125}>
      <Tip
        text={
          <Container absolute width={12.5} data-testid="paid-partnership-require-approvals-tip">
            <Text xsmall uppercase>
              <FormattedMessage id="builder.brief.socialMedia.paidpartnership.requireApprovals.tip" />
            </Text>
          </Container>
        }
        width={12.5}
        coachmark={{
          content: <BuilderBriefChannelsInstagramPaidPartershipTipCoachMark />,
          position: 'left',
          width: 16,
          yOffset: 5,
        }}
      />
    </SideBarContentWrapper>
  )
}

export default BuilderBriefChannelsInstagramPaidPartershipTip
