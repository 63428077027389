import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container, Text } from '@tribegroup'

interface IBetaLabelProps {
  disabled?: boolean
}

const BetaLabel: React.FC<IBetaLabelProps> = ({ disabled }) => (
  <Container
    borderRadius={0.25}
    borderWidth={0.0625}
    leftInnerSpacing={0.25}
    rightInnerSpacing={0.25}
    topInnerSpacing={0.1875}
    bottomInnerSpacing={0.0625}
    inlineBlock
    borderColor={disabled ? Theme.grey400Color : Theme.primary200Color}
  >
    <Flex alignCenter justifyCenter>
      <Text color={disabled ? Theme.grey700Color : Theme.primaryColor} xxsmall uppercase>
        <FormattedMessage id="core.text.beta" />
      </Text>
    </Flex>
  </Container>
)

export { BetaLabel }
export default BetaLabel
