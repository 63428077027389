import { FormattedMessage } from 'react-intl'

import { Button } from '@tribegroup'
import { useIdentityRowReconnect } from './useIdentityRowReconnect'

interface IProps {
  brandManagerIdentityId: number
}

const ConnectedAccountsListIdentityRowReconnectButton: React.FC<IProps> = ({
  brandManagerIdentityId,
}) => {
  const { reconnect } = useIdentityRowReconnect(brandManagerIdentityId)
  return (
    <Button
      onClick={reconnect}
      scheme="primary"
      outlined
      rightOuterSpacing={1.75}
      data-testid="identity-row-button-reconnect"
      topInnerSpacing={0.5}
      bottomInnerSpacing={0.5}
      leftInnerSpacing={1}
      rightInnerSpacing={1}
    >
      <FormattedMessage id="core.text.reconnect" />
    </Button>
  )
}

export default ConnectedAccountsListIdentityRowReconnectButton
