import { call, put, select, takeEvery } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { IHateoasLink } from '@store'
import { trackEvent } from '@store/analytics'
import { selectSubmissionTrackingProps } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  reschedule,
  RescheduleActionTypes,
  rescheduleError,
  rescheduleSuccess,
  selectPostMissed,
  selectRescheduleLink,
  selectRescheduleType,
} from '@store/submissions'
import { networkRequest } from '@utils'

export function* handleReschedule(action: ReturnType<typeof reschedule>) {
  try {
    const { submissionId, campaignId, params } = action.payload
    const authToken: string = yield select(selectAuthToken)
    const rescheduleLink: IHateoasLink = yield select(selectRescheduleLink, submissionId)
    const eventProperties = {
      ...(yield select(selectSubmissionTrackingProps, campaignId, submissionId)),
      rescheduled_to: yield select(
        selectRescheduleType,
        submissionId,
        params.scheduled_publish_date,
      ),
      post_missed: yield select(selectPostMissed, submissionId),
    }

    const submission = yield call(
      networkRequest,
      rescheduleLink.method,
      rescheduleLink.href,
      params,
      authToken,
    )

    yield put(rescheduleSuccess(submission))
    yield put(trackEvent(EventTrackingNames.RescheduleSubmission, eventProperties))
  } catch (error) {
    yield put(rescheduleError(error))
  }
}

export function* watchRescheduleRequest() {
  yield takeEvery(RescheduleActionTypes.RESCHEDULE_REQUEST, handleReschedule)
}
