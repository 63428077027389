/* eslint-disable react/display-name */
import { CampaignContext } from '@context/Campaign'

export const withCampaign = (Component) => (props) => {
  return (
    <CampaignContext.Consumer>
      {(contextProps) =>
        contextProps.isCampaignFetched && <Component {...props} {...contextProps} />
      }
    </CampaignContext.Consumer>
  )
}

export const withCampaignNoRenderBlock = (Component) => (props) => {
  return (
    <CampaignContext.Consumer>
      {(contextProps) => <Component {...props} {...contextProps} />}
    </CampaignContext.Consumer>
  )
}
