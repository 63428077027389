import { FinalizeCampaignActionTypes } from '@store/campaigns'

export const finalize = (campaignId: number, campaignType: string, params: any, meta?: any) => ({
  payload: { campaignId, campaignType, params },
  meta,
  type: FinalizeCampaignActionTypes.FINALIZE_REQUEST,
})

export const finalizeSuccess = (campaignId, meta = {}) => ({
  payload: campaignId,
  meta,
  type: FinalizeCampaignActionTypes.FINALIZE_SUCCESS,
})

export const finalizeError = (error: any) => ({
  payload: error,
  type: FinalizeCampaignActionTypes.FINALIZE_ERROR,
})
