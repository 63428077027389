import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { useInboxPrompt } from '@hooks/useInboxPrompt'
import {
  selectShouldShowConnectTikTokAccountPrompt,
  selectShouldShowToggleOnTikTokAdsManagerAccountPrompt,
} from '@store/campaigns'
import { IApplicationState } from '@store/index'
import { selectCampaignId } from '@store/router'
import ConnectedAccountsSideBarItemEducationConnectTikTokAccount from './ConnectTikTokAccount'
import ToggleOnTikTokAdsManagerAccountPrompt from './ToggleOnTikTokAdsManagerAccount'

const InboxPromptWatcher = () => {
  const match = useRouteMatch()

  const campaignId = selectCampaignId(match) as number
  const { showConnectTikTokAccountPrompt, showToogleOnTikTokAdsManagerAccountPrompt } = useSelector(
    (state: IApplicationState) => {
      return {
        showConnectTikTokAccountPrompt: selectShouldShowConnectTikTokAccountPrompt(
          state,
          campaignId,
        ),
        showToogleOnTikTokAdsManagerAccountPrompt: selectShouldShowToggleOnTikTokAdsManagerAccountPrompt(
          state,
          campaignId,
        ),
      }
    },
  )
  const { showInboxPrompt, hidePrompt } = useInboxPrompt()

  useEffect(() => {
    if (showConnectTikTokAccountPrompt) {
      return showInboxPrompt(<ConnectedAccountsSideBarItemEducationConnectTikTokAccount />)
    }

    if (showToogleOnTikTokAdsManagerAccountPrompt) {
      return showInboxPrompt(<ToggleOnTikTokAdsManagerAccountPrompt />)
    }

    return hidePrompt()
  }, [showConnectTikTokAccountPrompt, showToogleOnTikTokAdsManagerAccountPrompt])

  return null
}

export default InboxPromptWatcher
