import { FormattedMessage } from 'react-intl'

import { CampaignStatuses } from '@enums'
import { ICampaign } from '@store/campaigns'
import Theme from '@theme'
import { Text } from '@tribegroup'
import relativeDate from '@utils/relativeDate'
import { RelativeDateClamped, RelativeDateStyled } from './RelativeDate.styled'

interface ICampaignCardRelativeDate {
  campaign: ICampaign
  show?: boolean
}

export const getRelativeDateProps = (campaign: ICampaign) => {
  const { status, completion_at, ended_at, end_date } = campaign
  const defaultEndDate = ended_at || end_date
  const now = new Date()

  if (status === CampaignStatuses.Expired) {
    return relativeDate(new Date(completion_at!), now, status)
  }

  if (status === CampaignStatuses.Completed) {
    return relativeDate(now, new Date(defaultEndDate), status)
  }

  return relativeDate(now, new Date(end_date), status)
}

const CampaignCardRelativeDate = ({ campaign }: ICampaignCardRelativeDate) => {
  const dateProps = getRelativeDateProps(campaign)
  return (
    <RelativeDateStyled data-cy-element="campaign-relative-date">
      {dateProps && (
        <Text color={Theme.grey700Color} uppercase>
          <RelativeDateClamped>
            <FormattedMessage id={dateProps.id} values={{ ...dateProps.values }} />
          </RelativeDateClamped>
        </Text>
      )}
    </RelativeDateStyled>
  )
}

export { CampaignCardRelativeDate }
export default CampaignCardRelativeDate
