import styled from 'styled-components'

import Theme from '@theme'

export const FixedContainer = styled.div`
  position: fixed;
  right: 5rem;
  bottom: 1.75rem;
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
`

export const SnackbarContainer = styled.div`
  box-shadow: rgb(0 0 0 / 10%) 0 0.125rem 0.5rem 0;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 0.5rem;
  background: ${({ backgroundColor }) => backgroundColor ?? Theme.whiteColor};
  color: ${Theme.defaultColor};
  font-size: 0.75rem;
  grid-gap: 0.5rem;
  display: flex;
  margin-left: auto;
`
