import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchInfluencerIdentity,
  FetchInfluencerIdentityActionTypes,
  fetchInfluencerIdentityError,
  fetchInfluencerIdentitySuccess,
  IInfluencerIdentity,
  selectFetchIdentityLink,
} from '@store/brandFans'
import { networkRequest } from '@utils'

export function* handleFetchInfluencerIdentity(action: ReturnType<typeof fetchInfluencerIdentity>) {
  try {
    const identityId = action.payload
    const authToken: string = yield select(selectAuthToken)
    const fetchIdentityLink: IHateoasLink = yield select(selectFetchIdentityLink, identityId)
    const influencerIdentity: IInfluencerIdentity = yield call(
      networkRequest,
      fetchIdentityLink.method,
      fetchIdentityLink.href,
      undefined,
      authToken,
    )
    yield put(fetchInfluencerIdentitySuccess(influencerIdentity))
  } catch (error) {
    yield put(fetchInfluencerIdentityError(error))
  }
}

export function* watchFetchInfluencerIdentityRequest() {
  yield takeLatest(FetchInfluencerIdentityActionTypes.FETCH_REQUEST, handleFetchInfluencerIdentity)
}
