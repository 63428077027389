import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { Information } from '@components/UI/Information'
import { INSTAGRAM_POST_TYPES_LEARN_MORE } from '@constants'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { withBuilderValidation, withDraft } from '@hocs'
import { IApplicationState } from '@store'
import { selectRequestedSocialSubmissionTypes } from '@store/campaigns'
import { selectHasInstagramChannel, selectIsDisabledAttribute } from '@store/drafts'
import { selectSocialSubmissionTypes } from '@store/rootResource'
import Theme from '@theme'
import { Container, FormInput, H4, Link, MultiSelectButtonGroup, Text } from '@tribegroup'
import { isEmpty } from '@utils'

const toSubmissionTypeOptions = (socialSubmissionTypes: ReadonlyArray<string>) => {
  return socialSubmissionTypes.map((ssType: string) => ({
    value: ssType,
    display: <FormattedMessage id={`core.text.plural.${ssType}`} />,
  }))
}

const SUBMISSION_TYPES_FORM_KEY = 'requested_social_submission_types'

interface IInternalProps
  extends IBuilderValidationContextProps,
    IDraftContextProps,
    ConnectedProps<typeof connector> {}

const BuilderBriefChannelsInstagramSubmissionTypes: React.FC<IInternalProps> = ({
  draft,
  updateForm,
  isSelectionDisabled,
  hasInstagramSelected,
  clearFieldValidation,
  socialSubmissionTypes,
  requestedSocialSubmissionTypes,
}) => {
  const onSubmissionTypesChange = (selectedTypes: ReadonlyArray<string>) => {
    updateForm({
      [SUBMISSION_TYPES_FORM_KEY]: selectedTypes || [],
    })
    clearFieldValidation(SUBMISSION_TYPES_FORM_KEY)
  }

  const selectedValues = draft[SUBMISSION_TYPES_FORM_KEY]
  const hasSelectedRequestedValues = !isEmpty(selectedValues)
  const disabledValues =
    hasSelectedRequestedValues && isSelectionDisabled ? requestedSocialSubmissionTypes : []

  return (
    <Container topOuterSpacing={2} bottomOuterSpacing={2}>
      <H4 color={Theme.grey900Color}>
        <FormattedMessage id="builder.brief.socialMedia.instagram.header" />
        <Container leftOuterSpacing={0.25} inlineBlock verticalAlign="middle">
          <Information width={19.0625} xPosition="right">
            <Text color={Theme.defaultColor} block customFontSize={0.8125}>
              <FormattedMessage id="builder.brief.socialMedia.instagram.tooltip" />
            </Text>
            <Container topOuterSpacing={0.5}>
              <Link target="_blank" href={INSTAGRAM_POST_TYPES_LEARN_MORE}>
                <Text color={Theme.primaryColor}>
                  <FormattedMessage id="core.text.learnMore.withArrow" />
                </Text>
              </Link>
            </Container>
          </Information>
        </Container>
      </H4>
      <FormInput
        validationRule={{
          required: !isSelectionDisabled,
        }}
      >
        <Container topOuterSpacing={1}>
          <MultiSelectButtonGroup
            name={SUBMISSION_TYPES_FORM_KEY}
            options={toSubmissionTypeOptions(socialSubmissionTypes)}
            selectedValues={selectedValues}
            disabledValues={hasInstagramSelected ? disabledValues : []}
            onSelectedValuesChange={onSubmissionTypesChange}
            activeBackgroundColor={Theme.primary200Color}
          />
        </Container>
      </FormInput>
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { draft }) => ({
  socialSubmissionTypes: selectSocialSubmissionTypes(state),
  requestedSocialSubmissionTypes: selectRequestedSocialSubmissionTypes(state, draft.id as number),
  hasInstagramSelected: selectHasInstagramChannel(state),
  isSelectionDisabled: selectIsDisabledAttribute(state, SUBMISSION_TYPES_FORM_KEY),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(
  withBuilderValidation,
  withDraft,
  connector,
)(BuilderBriefChannelsInstagramSubmissionTypes)
