import styled from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'

export const ListItem = styled.li`
  display: flex;
  overflow-wrap: break-word;
  word-break: break-word;
`

const IconWrapper = styled(Flex)`
  flex-shrink: 0;
  border-radius: 1rem;
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
`

export const CheckIconWrapper = styled(IconWrapper)`
  background: ${Theme.success100Color};
`

export const XIconWrapper = styled(IconWrapper)`
  background: ${Theme.alert100Color};
`

export const DontsListWrapper = styled.div`
  margin-top: 0.75rem;
`
