import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

import ConfirmationMessageBox from '@components/UI/ConfirmationMessageBox'

export const AuthCompleteSignupMessageBoxInvalidLink = () => {
  return (
    <ConfirmationMessageBox title={<FormattedMessage id="auth.completeSignup.invalidLink.title" />}>
      <FormattedHTMLMessage id="auth.completeSignup.invalidLink.body" />
    </ConfirmationMessageBox>
  )
}
