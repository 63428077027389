import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { CardIcon, CardLabel } from '@components/UI/Card/Card'
import { MediaThumbnail } from '@components/UI/Media'
import { AspectRatios, MediaTypes, SocialSubmissionTypes } from '@enums'
import { withContextInfluencerIdentityId } from '@hocs'
import { IApplicationState } from '@store'
import { selectMediaType } from '@store/mediaObjects'
import { selectCampaignId } from '@store/router'
import {
  selectActiveSubmissionMediaObject,
  selectCarouselLicensedMediaObjectThumbnail,
  selectIsTextSubmission,
  selectMediaObjectStatusLabel,
  selectMediaThumbnail,
  selectSocialSubmissionType,
} from '@store/submissions'
import Theme from '@theme'
import { Container } from '@tribegroup'
import { buildSubmissionUrlById } from '@utils/urls'
import { SubmissionCardTextPreview } from '../TextPreview/TextPreview'

interface ISubmissionCardThumbnailProps {
  submissionId: number
}

interface IInternalProps extends ISubmissionCardThumbnailProps, InjectedIntlProps {
  submissionLink: string
  mediaThumbnail: string
  mediaType: MediaTypes
  statusLabel?: string
  iconTooltip: string
  socialSubmissionType: SocialSubmissionTypes
  campaignId: number
  licensedMediaThumbnail?: string
  isTextSubmission: boolean
}

export const getThumbnailIcon = (
  mediaType: MediaTypes,
  socialSubmissionType?: SocialSubmissionTypes,
) => {
  if (socialSubmissionType === SocialSubmissionTypes.TikTok) {
    return 'tiktok'
  }

  if (socialSubmissionType === SocialSubmissionTypes.Reel) {
    return 'reels'
  }

  if (socialSubmissionType === SocialSubmissionTypes.Text) {
    return 'social-x'
  }

  const isStandard = socialSubmissionType === SocialSubmissionTypes.Standard
  const isPin = socialSubmissionType === SocialSubmissionTypes.Pin

  const isVideo = mediaType === MediaTypes.VIDEO

  const isMultiframe =
    socialSubmissionType === SocialSubmissionTypes.Story ||
    socialSubmissionType === SocialSubmissionTypes.Carousel

  const showVideo = isVideo && (isStandard || isPin)

  if (showVideo) {
    return 'video'
  }
  if (isMultiframe) {
    return socialSubmissionType
  }
  return undefined
}

const SubmissionCardThumbnail: React.FC<IInternalProps> = ({
  mediaThumbnail,
  mediaType,
  statusLabel,
  socialSubmissionType,
  licensedMediaThumbnail,
  intl,
  submissionId,
  isTextSubmission,
}) => {
  const icon = getThumbnailIcon(mediaType, socialSubmissionType)
  const iconTooltip = icon && intl.formatMessage({ id: `submission.card.thumbnail.${icon}` })

  return (
    <Container relative>
      {isTextSubmission ? (
        <SubmissionCardTextPreview submissionId={submissionId} />
      ) : (
        <MediaThumbnail
          src={licensedMediaThumbnail || mediaThumbnail}
          aspectRatio={AspectRatios.ONE_BY_ONE}
        />
      )}
      {statusLabel && (
        <CardLabel>
          <FormattedMessage id={`submission.card.label.${statusLabel}`} />
        </CardLabel>
      )}
      {icon && (
        <CardIcon
          icon={icon}
          iconTooltip={iconTooltip}
          color={isTextSubmission ? Theme.defaultColor : undefined}
        />
      )}
    </Container>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { match, submissionId }: ISubmissionCardThumbnailProps & RouteComponentProps,
) => {
  const campaignId = selectCampaignId(match) as number
  const submissionLink = buildSubmissionUrlById(campaignId, submissionId)

  const { id: mediaObjectId } = selectActiveSubmissionMediaObject(state, submissionId)

  const statusLabel = selectMediaObjectStatusLabel(state, submissionId, mediaObjectId)

  return {
    campaignId,
    submissionLink,
    statusLabel,
    mediaThumbnail: selectMediaThumbnail(state, submissionId),
    mediaType: selectMediaType(state, mediaObjectId),
    socialSubmissionType: selectSocialSubmissionType(state, submissionId),
    licensedMediaThumbnail: selectCarouselLicensedMediaObjectThumbnail(state, submissionId),
    isTextSubmission: selectIsTextSubmission(state, submissionId),
  }
}

export { SubmissionCardThumbnail }
export default compose<IInternalProps, ISubmissionCardThumbnailProps>(
  withContextInfluencerIdentityId,
  withRouter,
  injectIntl,
  connect(mapStateToProps),
)(SubmissionCardThumbnail)
