import { FormattedHTMLMessage } from 'react-intl'

import Theme from '@theme'
import { Tip } from '@tribegroup'
import { SamplingCampaignHintWrapper } from './SamplingCampaignHint.styled'

export const SamplingCampaignHint = () => (
  <SamplingCampaignHintWrapper>
    <Tip
      width={20}
      icon={{
        padded: true,
        color: Theme.primaryColor,
        backgroundColor: Theme.primary200Color,
      }}
      text={
        <FormattedHTMLMessage id="socialSubmission.card.modal.approve.samplingCampaignWarning" />
      }
    />
  </SamplingCampaignHintWrapper>
)

export default SamplingCampaignHint
