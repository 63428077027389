import { call, select, takeEvery } from 'redux-saga/effects'

import { LogLevels } from '@enums'
import { IResponseError } from '@lib/error'
import { Logger } from '@lib/logger'
import { AuthActionTypes } from '@store/auth'
import { BrandFansActionTypes } from '@store/brandFans'
import { selectMeInfo } from '@store/me'
import { MediaObjectsActionTypes } from '@store/mediaObjects'
import { MemberActionTypes } from '@store/members'
import { ApproveActionTypes, SubmissionsActionTypes } from '@store/submissions'

interface IHandleErrorsProps {
  payload: IResponseError
  type: string
}

const ignoreActionTypes: ReadonlyArray<string> = [
  AuthActionTypes.AUTH_ERROR,
  AuthActionTypes.CLEAR_ERRORS,
  ApproveActionTypes.APPROVE_ERROR,
  MemberActionTypes.CLEAR_ERRORS,
  SubmissionsActionTypes.CLEAR_ERRORS,
  MediaObjectsActionTypes.CLEAR_ERRORS,
  BrandFansActionTypes.CLEAR_ERRORS,
]

const errorActionTypes = (action) =>
  action.type.includes('_ERROR') &&
  ignoreActionTypes.every((fragment) => !action.type.includes(fragment))

export function* handleErrors(action: IHandleErrorsProps) {
  const { name, status, messages } = action.payload
  const me = yield select(selectMeInfo)

  yield call(Logger.log, LogLevels.ERROR, action.type, {
    status,
    errors: messages,
    name,
    type: action.type,
    user_id: me.id,
  })
}

export function* watchHandleErrors() {
  yield takeEvery(errorActionTypes, handleErrors)
}
