/* eslint-disable react/display-name */
import { useContext } from 'react'

import { InfluencerIdentityIdContext } from '@context/InfluencerIdentityId'

const withContextInfluencerIdentityId = (Component) => (props) => {
  const context = useContext(InfluencerIdentityIdContext)
  return <Component {...context} {...props} />
}

export { withContextInfluencerIdentityId }
