import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'

import { CPM_EXPLAINED } from '@constants'
import { SocialSubmissionTypes } from '@enums'
import Theme from '@theme'
import { Link } from '@tribegroup'

type GlossaryDefinitionProps = {
  metric: string
  submissionType: SocialSubmissionTypes
  currency: string
} & InjectedIntlProps

const GlossaryDefinition: React.FC<GlossaryDefinitionProps> = ({
  intl,
  metric,
  currency,
  submissionType,
}) => {
  const ids = [
    `analytics.breakdown.metric.${metric}.glossary.${submissionType}`,
    `analytics.breakdown.metric.${metric}.glossary`,
  ]

  const messageId = ids.find((id) => {
    const translationExist =
      intl.formatMessage({
        id,
      }) !== id
    return translationExist
  })

  return (
    <FormattedMessage
      id={messageId ?? `core.text.notapplicable`}
      values={{
        link: (
          <Link href={CPM_EXPLAINED} target="_blank" color={Theme.primaryColor} lowercase>
            <FormattedMessage id="core.text.clickHere" />
          </Link>
        ),
        taxDisplay: <FormattedMessage id={`core.currency.taxDisplay.${currency ?? 'generic'}`} />,
        currencySymbol: <FormattedMessage id={`core.currency.symbol.${currency ?? 'generic'}`} />,
      }}
    />
  )
}

export default injectIntl(GlossaryDefinition)
