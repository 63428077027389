import { AnyAction } from 'redux'
import { put, select, takeEvery } from 'redux-saga/effects'

import { IMeta } from '@store'
import { legacyTrackEvent } from '@store/analytics'
import { shouldUserTrackEvents } from '@store/me'

interface IActionWithMeta extends AnyAction {
  meta: IMeta
}

const successActionTypes = (action: IActionWithMeta) => action.type.includes('_SUCCESS')

export function* handleLegacySuccessTracking(action: IActionWithMeta) {
  const { meta } = action
  const shouldTrackEvents = yield select(shouldUserTrackEvents)
  if (meta && meta.event && shouldTrackEvents) {
    yield put(legacyTrackEvent(meta.event))
  }
}

export function* watchLegacySuccessTracking() {
  yield takeEvery(successActionTypes, handleLegacySuccessTracking)
}
