import { call, put, select, take, takeLatest } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { IHateoasLink } from '@store'
import { AnalyticsIdentifyActionTypes, identifyRequest, trackEvent } from '@store/analytics'
import {
  AuthActionTypes,
  authError,
  emailVerificationRequest,
  emailVerificationSuccess,
} from '@store/auth'
import { ISubscriptionTier, selectVerificationEmailLink } from '@store/rootResource'
import { networkRequest } from '@utils'

export function* handleEmailVerification(action: ReturnType<typeof emailVerificationRequest>) {
  try {
    const emailVerificationLink: IHateoasLink = yield select(selectVerificationEmailLink)
    const { params: emailVerificationParams } = action.payload
    const me = yield call(
      networkRequest,
      emailVerificationLink.method,
      emailVerificationLink.href,
      emailVerificationParams,
    )

    yield put(
      identifyRequest({
        ...me,
        id: me.user_id,
        subscription_tier: {} as ISubscriptionTier,
        track_events: true,
      }),
    )
    yield take(AnalyticsIdentifyActionTypes.IDENTIFY_SUCCESS)
    yield put(trackEvent(EventTrackingNames.SignUpValidateEmail, { email: me.email }))
    yield put(emailVerificationSuccess())
  } catch (error) {
    yield put(authError(error))
  }
}

export function* watchEmailVerificationRequest() {
  yield takeLatest(AuthActionTypes.EMAIL_VERIFICATION_REQUEST, handleEmailVerification)
}
