import { FC, FormEvent, PropsWithChildren } from 'react'

import { FormattedMessage } from 'react-intl'

import { RELICENSE_STATUSES } from '@constants'
import { LicenseStatuses } from '@enums'
import { ILicenseOption } from '@store/campaigns'
import { IMediaObject } from '@store/mediaObjects'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { LicenseBookletAsideLicenseOptions } from './Aside/LicenseOptions'
import { LicenseBookletAsideLicenseSelected } from './Aside/LicenseSelected'
import { LicenseBookletAsideMediaDetails } from './Aside/MediaDetails'
import {
  LicenseBookletFooter,
  LicenseBookletHeader,
  LicenseBookletOptions,
  LicenseBookletWrapper,
} from './LicenseBooklet.styled'

interface IProps {
  header: React.ReactNode
  mediaObject: IMediaObject
  licenseOptions?: readonly ILicenseOption[]
  onSelectLicenseOption?: (event: FormEvent<HTMLInputElement>) => void
  finalMediaType?: string
}

export const LicenseBooklet: FC<PropsWithChildren<IProps>> = ({
  mediaObject,
  licenseOptions,
  header,
  children,
  onSelectLicenseOption,
  finalMediaType,
}) => {
  return (
    <LicenseBookletWrapper>
      <LicenseBookletHeader>{header}</LicenseBookletHeader>
      <LicenseBookletOptions>
        <Container bottomOuterSpacing={1.25}>
          <Text
            uppercase
            customFontSize={0.8125}
            lineHeight={1.23}
            color={Theme.grey900Color}
            customletterSpacing={'1.5px'}
          >
            {mediaObject.transacted_license &&
            !RELICENSE_STATUSES.includes(
              mediaObject.transacted_license?.status as LicenseStatuses,
            ) ? (
              <FormattedMessage id="socialSubmission.card.modal.license.header.selectedLicense" />
            ) : (
              <FormattedMessage id="licenseBooklet.licenseOptions.header" />
            )}
          </Text>
        </Container>

        {mediaObject.transacted_license &&
        !RELICENSE_STATUSES.includes(mediaObject.transacted_license?.status as LicenseStatuses) ? (
          <LicenseBookletAsideLicenseSelected transactedLicense={mediaObject.transacted_license} />
        ) : (
          <LicenseBookletAsideLicenseOptions
            licenseOptions={licenseOptions!}
            onChange={onSelectLicenseOption}
          />
        )}

        <Container topOuterSpacing={2}>
          <LicenseBookletAsideMediaDetails
            mediaObject={mediaObject}
            finalMediaType={finalMediaType}
          />
        </Container>
      </LicenseBookletOptions>

      <LicenseBookletFooter rightOuterSpacing={3} leftOuterSpacing={1.5} bottomOuterSpacing={3}>
        {children}
      </LicenseBookletFooter>
    </LicenseBookletWrapper>
  )
}
