import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import { EventTrackingNames } from '@enums'
import { withSubmissionListFilter } from '@hocs'
import { ICampaign } from '@store/campaigns'
import { ISubmission } from '@store/submissions'
import { MenuItem } from '@tribegroup'
import { useRateCardTrigger } from './useRateCardTrigger'

interface IProps {
  campaign: ICampaign
  submission: ISubmission
}

interface IInternalProps extends IProps, ISubmissionListFilterContextProps {}

const SubmissionRateCardPanelTriggerMobile: React.FC<IInternalProps> = ({
  submission,
  campaign,
}) => {
  const { onClick, rateCardTipId } = useRateCardTrigger()

  return (
    <ConnectedTrackedRoute
      submissionId={submission.id}
      campaignId={campaign.id}
      eventName={EventTrackingNames.ToolbarViewRateCard}
    >
      <MenuItem onClick={onClick}>
        <FormattedMessage id={rateCardTipId} />
      </MenuItem>
    </ConnectedTrackedRoute>
  )
}

export default compose<IInternalProps, IProps>(withSubmissionListFilter)(
  SubmissionRateCardPanelTriggerMobile,
)
