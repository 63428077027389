import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const ShortlistedAnalyticsCardGrid = styled.div`
  display: block;

  > div {
    margin-bottom: 0.5rem;
  }

  ${breakpoint('tabletPortrait')`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
    > div {
      margin: 0;
    }
  `};
  ${breakpoint('desktop')`
    grid-template-columns: repeat(4, 1fr);
  `};
`
