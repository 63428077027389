import { ChangeEvent, createRef, useEffect, useState } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { DECINE_REASON_CHARACTER_LIMIT } from '@constants'
import { IModalComponentProps, IModalContextProps } from '@context/Modal'
import { withModal } from '@hocs'
import { useIsMounted } from '@hooks/useIsMounted'
import { usePreapprovalAmountPercentage } from '@hooks/usePreapprovalAmountPercentage'
import { IApplicationState } from '@store'
import { selectCampaign } from '@store/campaigns'
import { selectPreapprovedSubmissionDeclineReasons } from '@store/rootResource'
import { decline, ISubmission, selectIsDeclining } from '@store/submissions'
import Theme from '@theme'
import {
  Button,
  CloseIcon,
  Container,
  Header,
  Modal,
  ModalTitle,
  Text,
  TextArea,
} from '@tribegroup'
import { insertStringBetweenText, isEmpty } from '@utils'
import SubmissionCardModalDeclineReasonsLineItem from '../Decline/Reasons/LineItem'
import SubmissionCardModalDeclinePreapproved from './DeclinePreapproved'

interface IProps extends IModalComponentProps, InjectedIntlProps, IModalContextProps {
  selectedReasonCode: string
  campaignId: number
  submission: ISubmission
  currFilter: string
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

const SubmissionCardModalDeclinePreapprovedForm: React.FC<IInternalProps> = ({
  onRequestClose,
  isOpen = false,
  submission,
  selectedReasonCode,
  showModal,
  campaignId,
  currFilter,
  intl,
  isDeclining,
  decline: declineAction,
  reasons,
}) => {
  const [declineReason, setDeclineReason] = useState('')

  const preapprovalAmountPercentage = usePreapprovalAmountPercentage()

  const textAreaRef = createRef<HTMLTextAreaElement>()

  const onBack = () => {
    const modalProps = {
      currFilter,
      submission,
      campaignId,
      defaultSelectedDeclineCode: selectedReasonCode,
    }
    showModal(SubmissionCardModalDeclinePreapproved, modalProps)
  }

  const onSubmit = () => {
    const decline_reason = declineReason.trim()
    if (decline_reason.length > 0 && decline_reason.length <= DECINE_REASON_CHARACTER_LIMIT) {
      declineAction(
        submission.id,
        campaignId,
        { decline_reason, decline_code: selectedReasonCode },
        {
          filter: currFilter,
        },
      )
    }
  }

  const isMounted = useIsMounted()

  useEffect(() => {
    if (isMounted && !isDeclining) {
      onRequestClose()
    }
  }, [isDeclining])

  const onReasonClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    const textArea = textAreaRef.current as HTMLTextAreaElement
    const reasonSelected = event.currentTarget as HTMLElement
    const newDeclineReason = insertStringBetweenText(
      textArea.value,
      reasonSelected.getAttribute('data-text') || '',
      textArea.selectionStart,
    )

    setDeclineReason(newDeclineReason)
  }

  const onDeclineReasonChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setDeclineReason(event.target.value)

  const reasonsCategory = reasons.find((reason) => reason.code === selectedReasonCode)

  return (
    <Modal reactModalProps={{ isOpen }} width={50} complex>
      <ModalTitle bottomOuterSpacing={0.5}>
        <FormattedMessage
          id={`submission.declinePreapproved.form2.${selectedReasonCode}.title`}
          values={{ name: submission.influencer_first_name }}
        />
      </ModalTitle>
      <Container bottomOuterSpacing={1.75}>
        <Text color={Theme.primaryColor}>
          <FormattedMessage
            id={`submission.declinePreapproved.form2.${selectedReasonCode}.subtitle`}
            values={{ name: submission.influencer_first_name, preapprovalAmountPercentage }}
          />
        </Text>
      </Container>
      <CloseIcon onClick={onRequestClose} />

      <TextArea
        innerRef={textAreaRef}
        value={declineReason}
        height={8}
        onChange={onDeclineReasonChange}
        placeholder={intl.formatMessage({
          id: `submission.declinePreapproved.form2.${selectedReasonCode}.placeholder`,
        })}
        characterLimit={DECINE_REASON_CHARACTER_LIMIT}
      />

      {reasonsCategory && reasonsCategory.reasons.length > 0 && (
        <Container topOuterSpacing={1.75} bottomOuterSpacing={3.75}>
          <SubmissionCardModalDeclineReasonsLineItem
            intl={intl}
            reasons={reasonsCategory.reasons}
            onReasonClick={onReasonClick}
            firstName={submission.influencer_first_name}
          />
        </Container>
      )}

      <Container topOuterSpacing={2.5}>
        <Flex alignCenter justifyBetween wrap="wrap">
          <Header
            small
            color={Theme.grey700Color}
            uppercase
            bottomOuterSpacing={1}
            topOuterSpacing={1}
          >
            <FormattedMessage id="core.text.stepXofY" values={{ current: 2, total: 2 }} />
          </Header>
          <Flex justifyEnd>
            <Button scheme="primary" onClick={onBack} outlined rightOuterSpacing={1} width={10.125}>
              <Header cta>
                <FormattedMessage id="core.text.back" />
              </Header>
            </Button>
            <Button
              onClick={onSubmit}
              scheme="primary"
              width={10.125}
              loading={isDeclining}
              disabled={isDeclining || isEmpty(declineReason.trim())}
            >
              <Header cta>
                <FormattedMessage id="core.text.submit" />
              </Header>
            </Button>
          </Flex>
        </Flex>
      </Container>
    </Modal>
  )
}

const mapStateToProps = (state: IApplicationState, { campaignId }: IProps) => {
  const { supported_submission_types } = selectCampaign(state, campaignId)
  return {
    isDeclining: selectIsDeclining(state),
    reasons: selectPreapprovedSubmissionDeclineReasons(state, supported_submission_types[0]),
  }
}

const mapDispatchToProps = {
  decline,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<IProps, IProps>(
  connector,
  injectIntl,
  withModal,
)(SubmissionCardModalDeclinePreapprovedForm)
