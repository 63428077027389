import { ISubmission } from '@store/submissions'
import SubmissionThumbnail from '../Thumbnail'
import { SubmissionThumbnailListWrapper } from './List.styled'

interface ISubmissionThumbnailListProps {
  influencerSubmissionIds: ReadonlyArray<number>
  fromSubmission: ISubmission
}

export default function SubmissionThumbnailList({
  influencerSubmissionIds,
  fromSubmission,
}: ISubmissionThumbnailListProps) {
  return (
    <SubmissionThumbnailListWrapper>
      {influencerSubmissionIds &&
        influencerSubmissionIds.map((submissionId) => (
          <SubmissionThumbnail
            key={submissionId}
            submissionId={submissionId}
            fromSubmission={fromSubmission}
          />
        ))}
    </SubmissionThumbnailListWrapper>
  )
}
