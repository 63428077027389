import { Fragment } from 'react'

import Cookies from 'js-cookie'
import { FormattedMessage } from 'react-intl'

import TribeOnly from '@components/UI/TribeOnly'
import { TRIBE_PRIVACY } from '@constants'
import Theme from '@theme'
import { Link, Text, toast } from '@tribegroup'

export const GDPRCookieToast = () => (
  <Fragment>
    <Text small color={Theme.grey900Color}>
      <FormattedMessage id="gdpr.cookie.experience.toast" />
      &nbsp;
    </Text>
    <TribeOnly>
      <Link color={Theme.primaryColor} href={TRIBE_PRIVACY} target="_blank">
        <FormattedMessage id="core.text.learnMore" />
      </Link>
    </TribeOnly>
  </Fragment>
)

export const showCookieToast = () => {
  toast(<GDPRCookieToast />, true, {
    timeout: Infinity,
    dismiss: (e, toastObject, dismiss) => {
      dismiss()
      Cookies.set('tribecookieread', Date.now(), { expires: 365 })
    },
  })
}
