import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'

export const Section = styled.div`
  margin-bottom: 1rem;
`

export const List = styled.div`
  margin-top: 0.5rem;
`

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoint('tabletPortrait')`
    flex-direction: row;
  `};
  margin-bottom: 1.625rem;

  > span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const Label = styled.label`
  min-width: 12.5rem;
  display: inline-block;
`

export const Submit = styled.button`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  transition: 0.2s;

  &:hover {
    background-color: ${Theme.grey200Color};
  }
`
