import { FC } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import RouterLink from '@components/UI/RouterLink/RouterLink.styled'
import { ALL } from '@constants'
import { useLocationSearch } from '@hooks/useLocationSearch'
import { usePreapprovalAmountPercentage } from '@hooks/usePreapprovalAmountPercentage'
import { IApplicationState } from '@store'
import { selectCampaignId, selectCurrentDeclinedFilter } from '@store/router'
import { selectFilteredDeclinedSubmissionsLinkFilters } from '@store/submissions'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { objectToQueryString } from '@utils'
import { DeclinedFilter } from './Filters.styled'

type IInternalProps = ConnectedProps<typeof connector>

const InboxTopPanelDeclinedFilters: FC<IInternalProps> = ({
  declinedFilters,
  currentDeclineFilter,
}) => {
  const percentage = usePreapprovalAmountPercentage()
  const currentSearch = useLocationSearch()

  const allDeclinedFilters = [ALL, ...declinedFilters]

  return (
    <Container bottomOuterSpacing={1.25}>
      {allDeclinedFilters.map((filter: string) => (
        <RouterLink
          key={filter}
          to={{
            search: objectToQueryString({
              ...currentSearch,
              declined_filter: filter === ALL ? undefined : filter,
            }),
          }}
        >
          <DeclinedFilter active={currentDeclineFilter === filter}>
            <Text color={Theme.grey800Color}>
              <FormattedMessage
                id={`inbox.cardlist.declined.filter.${filter}`}
                values={{ percentage }}
              />
            </Text>
          </DeclinedFilter>
        </RouterLink>
      ))}
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match) as number
  return {
    currentDeclineFilter: selectCurrentDeclinedFilter(state),
    declinedFilters: selectFilteredDeclinedSubmissionsLinkFilters(state, campaignId),
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(withRouter, connector)(InboxTopPanelDeclinedFilters)
