import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'recompose'

import { Information } from '@components/UI/Information'
import InformationContent from '@components/UI/Information/Content/Content'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { EventTrackingNames } from '@enums'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import {
  downloadPostCampaignReportRequest,
  selectFirstPublishedDateText,
  selectIsReadyForDownload,
  selectLastPublishedDateText,
  selectShowCampaignReportTitleDates,
} from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Button, Container } from '@tribegroup'
import { getCampaignMetricsTab } from '@utils'

const PostCampaignReportTitleButton: React.FC<ConnectedProps<typeof connector>> = ({
  enableDownloadButton,
  campaignId,
  downloadPostCampaignReportRequest: downloadPostCampaignReportRequestAction,
}) => {
  const { channel } = hooks.useLocationSearch()
  const onClick = () => {
    const timezoneInfo = Intl.DateTimeFormat().resolvedOptions().timeZone
    downloadPostCampaignReportRequestAction(campaignId, timezoneInfo)
  }

  if (enableDownloadButton) {
    return (
      <ConnectedTrackedRoute
        campaignId={campaignId}
        eventName={EventTrackingNames.CampaignMetricsDownloadCSV}
        additionalProperties={{ metrics_tab: getCampaignMetricsTab(channel) }}
      >
        <Button onClick={onClick} small height={2} outlined data-testid="pcr-enabled-button">
          <FormattedMessage id="postCampaignReport.header.button.downloadCSV" />
        </Button>
      </ConnectedTrackedRoute>
    )
  }

  return (
    <Information
      target={
        <Button
          onClick={onClick}
          small
          height={2}
          hasIcon
          disabled
          data-testid="pcr-disabled-button"
        >
          <Container>
            <FormattedMessage id="postCampaignReport.header.button.downloadCSV" />
          </Container>
        </Button>
      }
      width={18.125}
      panelTopOffset={1.25}
      panelRightOffset={1.25}
    >
      <InformationContent
        titleColor={Theme.primaryColor}
        title={<FormattedMessage id="postCampaignReport.header.button.downloadCSV" />}
        content={<FormattedMessage id="postCampaignReport.header.button.downloadCSV.tooltip" />}
      />
    </Information>
  )
}

const mapStateToProps = (state: IApplicationState, { match }) => {
  const campaignId = selectCampaignId(match)!

  const isReadyForDownload = selectIsReadyForDownload(state, campaignId)

  return {
    campaignId,
    enableDownloadButton: isReadyForDownload,
    firstPublishedDateText: selectFirstPublishedDateText(state, campaignId),
    lastPublishedDateText: selectLastPublishedDateText(state, campaignId),
    showCampaignReportTitleDates: selectShowCampaignReportTitleDates(state, campaignId),
  }
}

const mapDispatchToProps = {
  downloadPostCampaignReportRequest,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<ConnectedProps<typeof connector>, {}>(
  withRouter,
  connector,
)(PostCampaignReportTitleButton)
