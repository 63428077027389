import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Container, Text } from '@tribegroup'

export const CreditManagementCoachMark = () => {
  return (
    <Container>
      <Container flexStart bottomOuterSpacing={0.25}>
        <Text uppercase color={Theme.primaryColor} customletterSpacing="1.5px">
          <FormattedMessage id="contentLibrary.licensing.creditManagement.coachmark.header" />
        </Text>
      </Container>
      <Container flexStart bottomOuterSpacing={0.25}>
        <Text color={Theme.grey900Color} small>
          <FormattedMessage id="contentLibrary.licensing.creditManagement.coachmark.paragraph.first" />
        </Text>
      </Container>
      <Container flexStart bottomOuterSpacing={0.25}>
        <Text color={Theme.grey900Color} small>
          <FormattedMessage id="contentLibrary.licensing.creditManagement.coachmark.paragraph.second" />
        </Text>
      </Container>
      <Container flexStart bottomOuterSpacing={0.25}>
        <Text color={Theme.grey900Color} small>
          <FormattedMessage id="contentLibrary.licensing.creditManagement.coachmark.paragraph.third" />
        </Text>
      </Container>
    </Container>
  )
}
