import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { withBuilderValidation, withDraft } from '@hocs'
import { IApplicationState } from '@store'
import { selectTargetAudienceAgeRange } from '@store/campaigns'
import { selectIsDisabledAttribute } from '@store/drafts'
import { selectAudienceAgeRange } from '@store/rootResource'
import Theme from '@theme'
import { Container, MultiSelectButtonGroup } from '@tribegroup'
import BuilderBudgetTargetPanelTitle from '../PanelTitle'
import { AUDIENCES_HEADER_I18N_PREFIX } from '../Target'
import { TARGET_AUDIENCE_AGE_RANGE } from './Audience'
import BuilderBudgetTargetAudienceRequiredField from './RequiredField'

interface IInternalProps
  extends IBuilderValidationContextProps,
    IDraftContextProps,
    ConnectedProps<typeof connector> {}

const BuilderBudgetTargetAudienceAge: React.FC<IInternalProps> = ({
  draft,
  updateForm,
  isDisabledAttr,
  audienceAgeRange,
  clearFieldValidation,
  campaignAudienceAgeRange,
}) => {
  const ageRangeOptions = audienceAgeRange.map((ageRange) => ({
    value: ageRange,
    display: ageRange,
  }))

  const onAgeRangeChange = (range: ReadonlyArray<string>) => {
    clearFieldValidation(TARGET_AUDIENCE_AGE_RANGE)
    updateForm({
      audience_age_range: range || [],
    })
  }

  return (
    <Container bottomOuterSpacing={1.325} data-testid="builder-budget-target-audience-age">
      <BuilderBudgetTargetPanelTitle>
        <FormattedMessage id={`${AUDIENCES_HEADER_I18N_PREFIX}.age`} />
      </BuilderBudgetTargetPanelTitle>
      <BuilderBudgetTargetAudienceRequiredField
        fieldName={TARGET_AUDIENCE_AGE_RANGE}
        fieldErrorKey="builder.budget.error.age.required"
      >
        <Container topOuterSpacing={1}>
          <MultiSelectButtonGroup
            name={TARGET_AUDIENCE_AGE_RANGE}
            options={ageRangeOptions}
            onSelectedValuesChange={onAgeRangeChange}
            selectedValues={draft.audience_age_range || []}
            disabledValues={isDisabledAttr ? campaignAudienceAgeRange : []}
            allOption={!isDisabledAttr && <FormattedMessage id="core.text.all" />}
            activeBackgroundColor={Theme.primary200Color}
          />
        </Container>
      </BuilderBudgetTargetAudienceRequiredField>
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { draft }) => ({
  audienceAgeRange: selectAudienceAgeRange(state),
  isDisabledAttr: selectIsDisabledAttribute(state, TARGET_AUDIENCE_AGE_RANGE),
  campaignAudienceAgeRange: selectTargetAudienceAgeRange(state, draft.id as number),
})

const connector = connect(mapStateToProps)
export default compose<IInternalProps, {}>(
  withBuilderValidation,
  withDraft,
  connector,
)(BuilderBudgetTargetAudienceAge)
