import MediaObjectFull from '@components/MediaObject/Full'
import MediaObjectsFacebookAdAccounts from '@components/MediaObject/Licensing/Licensed/FacebookAdAccounts'
import MediaObjectNavigation from '@components/MediaObject/Navigation'
import MediaObjectTitleBar from '@components/MediaObject/TitleBar/TitleBar'
import ConnectedPayment from '@components/Payment/ConnectedPayment'
import MainLayout from '@components/UI/MainLayout'
import SinglePageContainer from '@components/UI/SinglePageContainer/SinglePageContainer'
import { CurrentMediaObjectIdProvider } from '@context/CurrentMediaObjectId'
import { ModalProvider } from '@context/Modal'
import { ToastContainer } from '@tribegroup'

const MediaObjectPage = () => (
  <MainLayout>
    <CurrentMediaObjectIdProvider>
      <ModalProvider>
        <MediaObjectsFacebookAdAccounts />
        <ToastContainer />
        <MediaObjectTitleBar />
        <SinglePageContainer>
          <ConnectedPayment>
            <MediaObjectNavigation>
              <MediaObjectFull />
            </MediaObjectNavigation>
          </ConnectedPayment>
        </SinglePageContainer>
      </ModalProvider>
    </CurrentMediaObjectIdProvider>
  </MainLayout>
)

export default MediaObjectPage
