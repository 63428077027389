import { call, put, select, takeLatest } from 'redux-saga/effects'

import { ToastTemplateTicked } from '@components/UI/ToastTemplate'
import ToastTemplateWarning from '@components/UI/ToastTemplate/Warning'
import { BrandManagerIdentityProviders } from '@enums'
import { FacebookService } from '@services/facebook'
import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  ConnectBrandManagerIdentities,
  connectIdentitiesError,
  connectIdentitiesSuccess,
  fetchIdentities,
  IBrandManagerIdentityRequest,
  IFacebookPermissions,
  IInstagramAccountsViaFacebookPages,
  selectCheckIfHasNewBrandManagerIdentityUID,
  selectConnectIdentitiesLink,
} from '@store/brandManagerIdentities'
import { Toaster } from '@tribegroup'
import networkRequest from '@utils/networkRequest'

export const toRequestPayload = (
  instagramAccounts: IInstagramAccountsViaFacebookPages[],
): IBrandManagerIdentityRequest[] => {
  return instagramAccounts
    .filter((igAccount) => Boolean(igAccount.instagram_business_account))
    .map((igAccount) => ({
      provider: BrandManagerIdentityProviders.Instagram,
      oauth_token: igAccount.access_token,
      uid: igAccount.instagram_business_account.id,
    }))
}

export const verifyPermission = (permissions: IFacebookPermissions[]) => {
  const required_permissions = [
    'ads_management',
    'instagram_basic',
    'pages_show_list',
    'business_management',
  ]

  required_permissions.map((permissionID) => {
    const actualPermission = permissions.find(
      (permission) => permission.permission === permissionID,
    )
    if (!actualPermission || actualPermission.status === 'declined') {
      throw {
        showToast: true,
        status: `missingPermission.${permissionID}`,
      }
    }
  })
}

export function* handleConnectIdentities() {
  try {
    const instagramAccounts: IInstagramAccountsViaFacebookPages[] = yield call(
      FacebookService.getInstagramAccounts,
    )

    const permissions: IFacebookPermissions[] = yield call(FacebookService.getPermissions)
    yield call(verifyPermission, permissions)

    const newUIDisAdded = yield select(
      selectCheckIfHasNewBrandManagerIdentityUID,
      instagramAccounts?.map((account) => account?.instagram_business_account?.id) || [],
    )

    const authToken: string = yield select(selectAuthToken)

    const connectIdentitiesLink: IHateoasLink = yield select(selectConnectIdentitiesLink)
    yield call(
      networkRequest,
      connectIdentitiesLink.method,
      connectIdentitiesLink.href,
      { identities: toRequestPayload(instagramAccounts) },
      authToken,
    )

    yield put(fetchIdentities())
    yield put(connectIdentitiesSuccess())

    if (newUIDisAdded) {
      yield call(Toaster.createToast, ToastTemplateTicked, {
        i18nKey: 'connectedAccounts.toast.addSuccess',
      })
    }
  } catch (error) {
    const errorCode = error.messages?.[0]?.error_code || error.status
    if (errorCode) {
      Toaster.createToast(
        ToastTemplateWarning,
        { i18nKey: `connectedAccounts.error.${errorCode}` },
        false,
        { timeout: 5000 },
      )
    }
    yield put(connectIdentitiesError(error))
  }
}

export function* watchConnectIdentitiesRequest() {
  yield takeLatest(ConnectBrandManagerIdentities.CONNECT_REQUEST, handleConnectIdentities)
}
