import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import AuthForm from '@components/UI/AuthForm'
import Box from '@components/UI/Box'
import { IResponseError } from '@lib/error'
import { IApplicationState } from '@store'
import {
  selectIsRequesting,
  selectPasswordErrorMessages,
  setPasswordRequest,
} from '@store/password'
import Theme from '@theme'
import {
  Button,
  Form,
  FormGroup,
  FormInput,
  FormValidationMessage,
  IWithFormValidationProps,
  Text,
  TextField,
  withFormValidation,
} from '@tribegroup'
import { queryStringtoObject } from '@utils'
import { escapeRegExp } from '@utils/escapeRegExp'

interface IInternalProps extends InjectedIntlProps, RouteComponentProps, IWithFormValidationProps {
  errors?: IResponseError
  setPasswordRequest: typeof setPasswordRequest
  isRequesting: boolean
}

export class AuthSetPasswordForm extends React.Component<IInternalProps> {
  static getDerivedStateFromProps(nextProps: IInternalProps) {
    const parsedQueryString: any = queryStringtoObject(nextProps.location.search)
    const setPasswordToken = parsedQueryString.token && decodeURIComponent(parsedQueryString.token)
    const email = parsedQueryString.email && decodeURIComponent(parsedQueryString.email)

    if (!setPasswordToken || !email) {
      nextProps.history.push('/signIn')
    }

    return {
      token: setPasswordToken,
      email,
    }
  }

  state = {
    password: '',
    confirmPassword: '',
    token: '',
    email: '',
  }

  onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    this.props.setPasswordRequest(
      {
        new_password: this.state.password,
        token: this.state.token,
        email: this.state.email,
      },
      this.props.history,
    )
  }

  onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement

    this.setState({
      [target.name]: target.value,
    })
  }

  render() {
    const {
      intl,
      validate,
      validationResults,
      setForm,
      errors,
      clearFieldValidation,
      isRequesting,
    } = this.props
    const errorCode = errors && errors[0].error_code

    return (
      <Box fullOnSmallScreens>
        <AuthForm
          title={<FormattedMessage id="resetPassword.setPassword.h1.header" />}
          subtitle={
            <Text color={Theme.grey800Color} textAlign="center">
              <FormattedMessage id="resetPassword.setPassword.text.subheader" />
            </Text>
          }
        >
          <Form
            name="set-password"
            onSubmit={this.onFormSubmit}
            onBeforeSubmit={validate}
            setForm={setForm}
          >
            <FormGroup stretchChildWidth>
              <FormInput
                validationRule={{
                  minLength: 8,
                }}
              >
                <TextField
                  name="password"
                  type="password"
                  value={this.state.password}
                  invalid={
                    (validationResults.password && validationResults.password.hasError) ||
                    (validationResults.confirmPassword &&
                      validationResults.confirmPassword.hasError)
                  }
                  placeholder={intl.formatMessage({
                    id: 'core.text.password',
                  })}
                  onFocus={clearFieldValidation}
                  onChange={this.onChange}
                />
                {validationResults.password && validationResults.password.hasError && (
                  <FormValidationMessage>
                    <Text small>{validationResults.password.errorMessage}</Text>
                  </FormValidationMessage>
                )}
              </FormInput>
            </FormGroup>
            <FormGroup stretchChildWidth>
              <FormInput
                validationRule={{
                  custom: [
                    {
                      message: intl.formatMessage({
                        id: 'resetPassword.setPassword.confirmPassword.error.mismatch',
                      }),
                      value: `^${escapeRegExp(this.state.password || '')}$`,
                    },
                  ],
                }}
              >
                <TextField
                  name="confirmPassword"
                  type="password"
                  value={this.state.confirmPassword}
                  invalid={
                    validationResults.confirmPassword && validationResults.confirmPassword.hasError
                  }
                  placeholder={intl.formatMessage({
                    id: 'core.text.confirmPassword',
                  })}
                  onFocus={clearFieldValidation}
                  onChange={this.onChange}
                />
                {validationResults.confirmPassword && validationResults.confirmPassword.hasError && (
                  <FormValidationMessage>
                    <Text small>{validationResults.confirmPassword.errorMessage}</Text>
                  </FormValidationMessage>
                )}
              </FormInput>
            </FormGroup>
            {errorCode && (
              <Flex center column>
                <FormValidationMessage>
                  <FormattedMessage id={`resetPassword.setPassword.error.${errorCode}`} />
                </FormValidationMessage>
              </Flex>
            )}
            <Flex center>
              <Button heavy loading={isRequesting} scheme="primary">
                <FormattedMessage id="resetPassword.setPassword.button" />
              </Button>
            </Flex>
          </Form>
        </AuthForm>
      </Box>
    )
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  errors: selectPasswordErrorMessages(state),
  isRequesting: selectIsRequesting(state),
})

const mapDispatchToProps = {
  setPasswordRequest,
}

export default compose<IInternalProps, {}>(
  withRouter,
  withFormValidation,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(AuthSetPasswordForm)
