import React from 'react'

import CheckboxButtonComboBase from './CheckboxButtonComboBase'
import { IOption } from './MultiSelectButtonGroup'

interface IProps extends IOption {
  selected: boolean
  disabled?: boolean
  outlined?: boolean
  showOutlinedAsDisabled?: boolean
  activeBackgroundColor?: string
  onDeselect: (value: React.ReactText) => void
  onSelect: (value: React.ReactText) => void
}

const CheckboxButtonCombo: React.FC<IProps> = ({
  selected,
  disabled,
  display,
  value,
  onSelect,
  onDeselect,
  outlined,
  showOutlinedAsDisabled,
  activeBackgroundColor,
}) => {
  const onClick = () => {
    if (selected) {
      onDeselect(value)
    } else {
      onSelect(value)
    }
  }
  const setOutlinedAsDisabled = showOutlinedAsDisabled && outlined
  const isOptionDisabled = disabled || setOutlinedAsDisabled
  return (
    <CheckboxButtonComboBase
      disabled={isOptionDisabled}
      active={selected}
      outlined={outlined}
      onClick={!isOptionDisabled ? onClick : undefined}
      activeBackgroundColor={activeBackgroundColor}
    >
      {display}
    </CheckboxButtonComboBase>
  )
}

export default CheckboxButtonCombo
