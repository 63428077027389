import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { TermsLink } from '@components/Licensing'
import { TrackedRoute } from '@components/UI/TrackedRoute'
import { DEFAULT_TENANT } from '@constants'
import termsAndConditions from '@downloadables/TRIBE Content Licence Agreement.pdf'
import { EventTrackingNames } from '@enums'
import { selectSubmissionTrackingProps } from '@store/analytics'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

type Props = {
  campaignId: number
  submissionId: number
}

const BrandedContentAdFormRightsAgreement: React.FC<Props> = ({ campaignId, submissionId }) => {
  const isDefaultTenant = process.env.APP_NAME === DEFAULT_TENANT
  const { trackingProps } = useSelector((state: IApplicationState) => ({
    trackingProps: selectSubmissionTrackingProps(state, campaignId, submissionId),
  }))

  if (!isDefaultTenant) {
    return null
  }

  return (
    <Container textAlign="center" topOuterSpacing={1.25}>
      <p>
        <Text color={Theme.grey800Color} small>
          <FormattedMessage id="brandedContentAds.modal.rights.context" />
        </Text>
        <br />
        <TrackedRoute
          eventName={EventTrackingNames.BCARightsAgreement}
          properties={{
            ...trackingProps,
            link_name: 'brandedContentAds.boosting.rights.agreement',
            link_url: termsAndConditions,
          }}
        >
          <TermsLink color={Theme.primaryColor}>
            <FormattedMessage id="brandedContentAds.boosting.rights.agreement" />
          </TermsLink>
        </TrackedRoute>
      </p>
    </Container>
  )
}

export default BrandedContentAdFormRightsAgreement
