import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
export const SubmissionCardMediaMultiframelWrapper = styled.div`
  position: relative;
  height: 100%;

  .slider-navigation-button {
    transition: 0.3s opacity;

    &:disabled {
      transition: 0.3s opacity;
    }
  }

  &:not(:hover) {
    .slider-navigation-button {
      opacity: 0.5;

      &:disabled {
        opacity: 0;
      }
    }
  }
`

export const PaginationWrapper = styled.div`
  width: calc(100% - 2.5rem);
  position: relative;
  margin: auto;
  ${breakpoint('tabletPortrait')`
    width: 100%;
  `};
`
