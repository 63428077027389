import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { useCampaignIdFromRoute } from '@hooks/useCampaignIdFromRoute'
import { selectHasProxySubmissions } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { Text } from '@tribegroup'
import Container, { IContainerProps } from '@tribegroup/Container/Container.styled'

export const PostCampaignReportProxySubmissionEducationText: React.FC<IContainerProps> = ({
  ...props
}) => {
  const campaignId = useCampaignIdFromRoute()
  const hasProxySubmissions = useSelector((state: IApplicationState) =>
    selectHasProxySubmissions(state, campaignId),
  )

  if (!hasProxySubmissions) {
    return null
  }

  return (
    <Container {...props}>
      <Text color={Theme.grey800Color}>
        <FormattedMessage id="postCampaignReport.performance.proxySubmissions.education" />
      </Text>
    </Container>
  )
}
