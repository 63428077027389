import React from 'react'

import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { BuilderDefault } from '@enums'
import { withBuilderValidation, withDraft } from '@hocs'
import Theme from '@theme'
import { Container, H2, Slider } from '@tribegroup'
import BuilderBudgetTargetPanelTitle from '../PanelTitle'
import { CREATORS_HEADER_I18N_PREFIX } from '../Target'

interface IInternalProps extends IBuilderValidationContextProps, IDraftContextProps {}

const BuilderBudgetTargetDemographicAge: React.FC<IInternalProps> = ({ draft, updateForm }) => {
  const [min, setMin] = React.useState(draft.demographic_min_age || BuilderDefault.MIN_AGE_DEFAULT)
  const [max, setMax] = React.useState(draft.demographic_max_age || BuilderDefault.MAX_AGE_DEFAULT)

  const maxAgeLabel = max === BuilderDefault.MAX_AGE_ALLOWED ? `${max}+` : max

  const onChange = (updatedAgeRange: any) => {
    setMin(Math.max(BuilderDefault.MIN_AGE_ALLOWED, updatedAgeRange.min))
    setMax(Math.min(BuilderDefault.MAX_AGE_ALLOWED, updatedAgeRange.max))
  }

  const onChangeComplete = () => {
    updateForm({
      demographic_min_age: min,
      demographic_max_age: max,
    })
  }
  return (
    <Container topOuterSpacing={2.5} data-testid="builder-budget-target-demographic-age">
      <BuilderBudgetTargetPanelTitle>
        <FormattedMessage id={`${CREATORS_HEADER_I18N_PREFIX}.age`} />
      </BuilderBudgetTargetPanelTitle>
      <Container textAlign="center" data-testid="age-slider-container" topOuterSpacing={1.25}>
        <H2 color={Theme.defaultColor}>
          <FormattedMessage
            id="builder.budget.range"
            values={{
              min,
              max: maxAgeLabel,
            }}
          />
        </H2>
      </Container>
      <Container topOuterSpacing={1.25}>
        <Slider
          minValue={BuilderDefault.MIN_AGE_ALLOWED}
          maxValue={BuilderDefault.MAX_AGE_ALLOWED}
          maxValuePlus
          labelCount={5}
          step={1}
          value={{ min, max }}
          onChange={onChange}
          onChangeComplete={onChangeComplete}
        />
      </Container>
    </Container>
  )
}

export default compose<IInternalProps, {}>(
  withBuilderValidation,
  withDraft,
)(BuilderBudgetTargetDemographicAge)
