import React from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import image404 from '@images/404-page.png'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { Image, JumboHeader, MainContainer, TextWrapper } from './404.styled'

export const Error404Page: React.FC<{
  headerTagId: string
  titleId: string
  errorMessageId: string
  TitleBarComponent?: React.FC | React.ComponentType
}> = ({ headerTagId, errorMessageId, titleId, TitleBarComponent }) => (
  <MainLayout backgroundColor={Theme.whiteColor} data-testid="error-404-page">
    <HeadTag id={headerTagId} />
    {TitleBarComponent && <TitleBarComponent />}
    <MainContainer>
      <Flex center>
        <Image src={image404} alt="page 404" />
      </Flex>
      <TextWrapper>
        <div>
          <JumboHeader color={Theme.primaryColor}>
            <FormattedMessage id="error.page.404.title" />
          </JumboHeader>
          <JumboHeader color={Theme.defaultColor}>
            <FormattedHTMLMessage id={titleId} />
          </JumboHeader>
          <Container topOuterSpacing={0.3125}>
            <Text color={Theme.defaultColor} small>
              <FormattedMessage id={errorMessageId} />
            </Text>
          </Container>
        </div>
      </TextWrapper>
    </MainContainer>
  </MainLayout>
)

export default Error404Page
