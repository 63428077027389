import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'
import { Container } from '@tribegroup'

export const EducationImage = styled.img`
  max-width: 100%;
  height: auto;
`

export const SelectionWrapper = styled.div`
  [data-subscribed='false'] span::after {
    opacity: 0.3;
  }
`

export const InformationIconContainer = styled(Container)`
  margin-right: 0.425rem;
  ${breakpoint('smallDesktop')`
    margin-right: 0.625rem;
  `};
  ${breakpoint('largeDesktop')`
    margin-right: 0.125rem;
  `};
`

export const StarContainer = styled.div`
  border-radius: 50%;
  margin-top: 0.5rem;
  border: solid thin ${Theme.grey300Color};
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
