import { FC, Fragment, useEffect } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { CardGridWithInfinteScroll } from '@components/UI/CardGrid'
import Loading from '@components/UI/Loading'
import { NoticeEmpty } from '@components/UI/Notice'
import TribeOnly from '@components/UI/TribeOnly'
import { CONTENT_LIBRARY_LEARN_MORE } from '@constants'
import { ContentLibraryFilterTypes } from '@enums'
import { withRememberScrollPosition } from '@hocs'
import { IApplicationState } from '@store'
import {
  fetchMediaObjects,
  selectFilteredMediaObjectIds,
  selectMediaObjectIsFetching,
  selectMediaObjectsNextLink,
} from '@store/mediaObjects'
import { selectContentLibraryFilter, selectContentLibraryFilterKey } from '@store/router'
import Theme from '@theme'
import { Container, Link, Text } from '@tribegroup'
import { isEmpty } from '@tribegroup/utils'
import MediaObjectCard from '../Card'

interface IInternalProps {
  mediaObjectIds: ReadonlyArray<number>
  hasMore: boolean
  fetchMediaObjects: typeof fetchMediaObjects
  contentLibraryCurrentFilter: ContentLibraryFilterTypes
  hasEmptyList: boolean
  isInitialFetch: boolean
}

const EmptyNoticeSubtitle: FC = () => (
  <Fragment>
    <FormattedHTMLMessage id="mediaObjects.empty.subtitle" />
    <TribeOnly>
      <Container topOuterSpacing={0.6875}>
        <Link href={CONTENT_LIBRARY_LEARN_MORE} target="_blank">
          <Text color={Theme.primaryColor}>
            <FormattedMessage id="core.text.learnMore.withArrow" />
          </Text>
        </Link>
      </Container>
    </TribeOnly>
  </Fragment>
)

const MediaObjectList: React.FC<IInternalProps> = ({
  mediaObjectIds,
  hasMore,
  hasEmptyList,
  isInitialFetch,
  contentLibraryCurrentFilter,
  fetchMediaObjects: fetchMediaObjectsAction,
}) => {
  useEffect(() => {
    fetchMediaObjectsAction(contentLibraryCurrentFilter)
  }, [contentLibraryCurrentFilter])

  const fetchNext = () => {
    const isFetchNext = true
    fetchMediaObjectsAction(contentLibraryCurrentFilter, isFetchNext)
  }

  if (isInitialFetch) {
    return <Loading height={30} />
  }
  if (hasEmptyList) {
    return (
      <NoticeEmpty
        alt="media-objects"
        title={<FormattedMessage id="mediaObjects.empty.title" />}
        subtitle={<EmptyNoticeSubtitle />}
      />
    )
  }
  return (
    <CardGridWithInfinteScroll gap={0.9375}>
      <InfiniteScroll
        dataLength={mediaObjectIds.length}
        next={fetchNext}
        loader={<Loading height={30} />}
        hasMore={hasMore}
        scrollThreshold={0.8}
        style={{ overflow: 'hidden' }}
      >
        <div>
          {mediaObjectIds.map((mediaObjectId) => (
            <MediaObjectCard key={mediaObjectId} mediaObjectCardId={mediaObjectId} />
          ))}
        </div>
      </InfiniteScroll>
    </CardGridWithInfinteScroll>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  const contentLibraryCurrentFilter = selectContentLibraryFilter(state)
  const isFetching = selectMediaObjectIsFetching(state)
  const contentLibraryFilterKey = selectContentLibraryFilterKey(state)
  const mediaObjectIds = selectFilteredMediaObjectIds(state, contentLibraryFilterKey)

  return {
    hasMore: Boolean(selectMediaObjectsNextLink(state, contentLibraryFilterKey)),
    hasEmptyList: !isFetching && isEmpty(mediaObjectIds),
    isInitialFetch: isFetching && isEmpty(mediaObjectIds),
    contentLibraryCurrentFilter,
    mediaObjectIds,
  }
}

const mapDispatchToProps = {
  fetchMediaObjects,
}

export { EmptyNoticeSubtitle, MediaObjectList }
export default compose<IInternalProps, {}>(
  withRememberScrollPosition,
  connect(mapStateToProps, mapDispatchToProps),
)(MediaObjectList)
