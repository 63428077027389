import { PropsWithChildren } from 'react'
import React from 'react'

import { useSelector } from 'react-redux'

import { selectIsSocialCampaign } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import { selectIdentityProvider, selectIdentitySocialUrl } from '@store/submissions'
import { Container, Icon, Link } from '@tribegroup'

type Props = {
  campaignId: number
  submissionId: number
  onViewSocial: VoidFunction
}

const SubHeaderIconWrapper: React.FC<PropsWithChildren<Props>> = ({
  campaignId,
  onViewSocial,
  submissionId,
  children,
}) => {
  const { showSocialProfile, socialUrl, provider } = useSelector((state: IApplicationState) => {
    return {
      showSocialProfile: selectIsSocialCampaign(state, campaignId),
      socialUrl: selectIdentitySocialUrl(state, submissionId),
      provider: selectIdentityProvider(state, submissionId),
    }
  })

  if (!showSocialProfile) {
    return children
  }

  return (
    <Link href={socialUrl} target="_blank" onClick={onViewSocial}>
      <Container inline relative rightOuterSpacing={0.375}>
        <Icon size={0.75} glyph={`social-${provider}-colour`} />
      </Container>
      {children}
    </Link>
  )
}

export default SubHeaderIconWrapper
