import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import SubmissionCardModalDeclineReasons from '@components/Submission/Card/Modal/Decline/Reasons/Reasons'
import { DECINE_REASON_CHARACTER_LIMIT } from '@constants'
import { ICampaignContextProps } from '@context/Campaign'
import { ISelectedSubmissionsContextProps } from '@context/SelectedSubmissions'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import { EventTrackingNames } from '@enums'
import { withCampaign, withSelectedSubmissions, withSubmissionListFilter } from '@hocs'
import { IApplicationState } from '@store'
import { ICategorisedDeclineReasons, selectDeclineReasons } from '@store/rootResource'
import { selectIsDeclining } from '@store/submissions'
import { bulkDecline } from '@store/submissions/actions/bulkDecline'
import {
  Button,
  CloseIcon,
  Form,
  IWithFormValidationProps,
  Modal,
  ModalTitle,
  TextArea,
  Tip,
} from '@tribegroup'
import { insertStringBetweenText, isEmpty, toEventsTracking } from '@utils'

export interface IInboxTitleBarSelectionModeBulkDeclineProps {
  submissionIds: ReadonlyArray<number>
  campaignId: number
  isOpen?: boolean
  onCancel: VoidFunction
}

interface IInternalProps
  extends IInboxTitleBarSelectionModeBulkDeclineProps,
    ICampaignContextProps,
    IWithFormValidationProps,
    ISelectedSubmissionsContextProps,
    ISubmissionListFilterContextProps,
    InjectedIntlProps {
  bulkDecline: typeof bulkDecline
  isDeclining: boolean
  reasons: ReadonlyArray<ICategorisedDeclineReasons>
}

export interface IInboxTitleBarSelectionModeBulkDeclineState {
  open: boolean
  decline_reason: string
  tip: string
}
export class InboxTitleBarSelectionModeBulkDecline extends React.PureComponent<
  IInternalProps,
  IInboxTitleBarSelectionModeBulkDeclineState
> {
  state = {
    open: false,
    decline_reason: '',
    tip: '',
  }

  private textArea = React.createRef<HTMLTextAreaElement>()

  componentDidUpdate(prevProps: IInternalProps) {
    if (prevProps.isDeclining && !this.props.isDeclining) {
      this.props.onCancel()
      this.props.emptySelection()
    }
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { filter, sortBy, campaign, submissionIds } = this.props
    const declineReason = this.state.decline_reason
      .trim()
      .split(
        this.props.intl.formatMessage({
          id: 'submission.decline.form.firstname.placeholder',
        }),
      )
      .join('{firstName}')

    const analyics = toEventsTracking(EventTrackingNames.BulkDeclineSubmissions, {
      brief_id: campaign.id,
      role: campaign.role,
      count: submissionIds.length,
      current_filter: filter,
      message: declineReason,
      brief_state: campaign.status,
    })

    if (declineReason.length > 0 && declineReason.length <= DECINE_REASON_CHARACTER_LIMIT) {
      this.props.bulkDecline(
        campaign.id,
        {
          decline_reason: declineReason,
          submission_ids: submissionIds,
        },
        {
          filter,
          sortBy,
          refreshSubmissions: true,
          event: analyics,
        },
      )
    }
  }

  onCancelClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    this.resetForm()
    this.props.onCancel()
  }

  resetForm = () => {
    this.setState({
      decline_reason: '',
      tip: '',
    })
  }

  onReasonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      decline_reason: event.currentTarget.value,
    })
  }

  onReasonClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    const textArea = this.textArea.current as HTMLTextAreaElement
    const reasonSelected = event.currentTarget as HTMLElement
    const newDeclineReason = insertStringBetweenText(
      textArea.value,
      reasonSelected.getAttribute('data-text') || '',
      textArea.selectionStart,
    )

    this.setState({
      decline_reason: newDeclineReason,
      tip: reasonSelected.getAttribute('data-show-tip') || '',
    })
  }

  render() {
    const {
      intl,
      isOpen = false,
      onCancel,
      isDeclining,
      validate,
      submissionIds,
      reasons,
    } = this.props
    const { decline_reason, tip } = this.state
    return (
      <Modal reactModalProps={{ isOpen, onRequestClose: onCancel }} width={50} complex>
        <ModalTitle center>
          <FormattedMessage
            id="submission.decline.form.bulk.title"
            values={{ value: submissionIds.length }}
          />
        </ModalTitle>
        <CloseIcon onClick={this.onCancelClick} />
        <Form
          onSubmit={this.onSubmit}
          onBeforeSubmit={validate}
          setForm={this.props.setForm}
          method="POST"
        >
          <TextArea
            height={8}
            placeholder={intl.formatMessage({
              id: 'submission.decline.form.reason.placeholder',
            })}
            innerRef={this.textArea}
            value={decline_reason}
            onChange={this.onReasonChange}
            characterLimit={DECINE_REASON_CHARACTER_LIMIT}
          />
          <SubmissionCardModalDeclineReasons reasons={reasons} onReasonClick={this.onReasonClick} />
          <Flex justifyBetween>
            <div>{tip && <Tip text={tip} />}</div>
            <Flex justifyEnd>
              <Button
                scheme="primary"
                outlined
                onClick={this.onCancelClick}
                fontSize={0.875}
                height={3}
                width={10.125}
              >
                <FormattedMessage id="core.text.cancel" />
              </Button>
              <Button
                scheme="primary"
                fontSize={0.875}
                height={3}
                width={10.125}
                leftOuterSpacing={1}
                disabled={isDeclining || isEmpty(decline_reason.trim())}
                loading={isDeclining}
              >
                <FormattedMessage id="core.text.submit" />
              </Button>
            </Flex>
          </Flex>
        </Form>
      </Modal>
    )
  }
}

const mapDispatchToProps = {
  bulkDecline,
}

const mapStateToProps = (state: IApplicationState, ownProps) => {
  return {
    isDeclining: selectIsDeclining(state),
    reasons: selectDeclineReasons(state, ownProps.campaign.supported_submission_types[0]),
  }
}

export default compose<IInternalProps, IInboxTitleBarSelectionModeBulkDeclineProps>(
  withSelectedSubmissions,
  withSubmissionListFilter,
  withCampaign,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(InboxTitleBarSelectionModeBulkDecline)
