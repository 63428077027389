import { InjectedIntlProps, injectIntl } from 'react-intl'

import Theme from '@theme'
import { Icon } from '@tribegroup'
import { PreviouslyPurchasedWrapper } from './PreviouslyPurchased.styled'

interface IInternalProps
  extends InjectedIntlProps,
    IInfluencerIdentityLabelsPreviouslyPurchasedProps {}

interface IInfluencerIdentityLabelsPreviouslyPurchasedProps {
  brandName: string
}

export const InfluencerIdentityLabelsPreviouslyPurchased = ({
  brandName,
  intl,
}: IInternalProps) => {
  const previouslyPurchasedText = intl.formatMessage(
    { id: 'submission.card.header.previouslyPurchased' },
    { brandName },
  )
  return (
    <PreviouslyPurchasedWrapper
      alignCenter
      justifyCenter
      data-tooltip-id="base-tooltip"
      data-tooltip-content={previouslyPurchasedText}
      data-testid="influencer-previously-purchased-label"
    >
      <Icon size={0.75} glyph="tick-heavy" color={Theme.secondaryColor} />
    </PreviouslyPurchasedWrapper>
  )
}

export default injectIntl(InfluencerIdentityLabelsPreviouslyPurchased)
