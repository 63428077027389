import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import HoverableInformation from '@components/UI/Information/HoverableInformation/HoverableInformation'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectPaymentMethod } from '@store/campaigns'
import CampaignBudgetTipContent from './TipContent'

interface IProps {
  campaignId: number
}

type IInternalProps = IProps & ConnectedProps<typeof connector>

const CampaignBudgetHoverableIcon: React.FC<IInternalProps> = ({ campaignId, paymentMethod }) => {
  return (
    <ConnectedTrackedRoute
      eventTrigger="mouseEnter"
      eventName={EventTrackingNames.BudgetPanelHover}
      campaignId={campaignId}
      additionalProperties={{
        budget_type: paymentMethod,
      }}
    >
      <HoverableInformation
        width={24}
        xPosition="right"
        glyph="information"
        iconSize={1}
        waypointYOffset={270}
        topOffset={12}
        testId="campaign-budget-hoverable-icon"
      >
        <CampaignBudgetTipContent campaignId={campaignId} paymentMethod={paymentMethod} />
      </HoverableInformation>
    </ConnectedTrackedRoute>
  )
}

const mapStateToProps = (state: IApplicationState, { campaignId }: IProps) => ({
  paymentMethod: selectPaymentMethod(state, campaignId),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(connector)(CampaignBudgetHoverableIcon)
