import { useContext } from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import UploadToFacebookAdsManager from '@components/Licensing/UploadToFacebookAdsManager'
import { CurrentMediaObjectIdContext } from '@context/CurrentMediaObjectId'
import { IApplicationState } from '@store'
import { selectMediaObjectIsUploadedToFacebookAds } from '@store/mediaObjects'

interface IProps {
  mediaObjectId: number
}

interface IInternalProps extends IProps {
  isUploadedToFacebookAds: boolean
}

const MediaObjectLicensingLicensedUploadToFacebookAdsManager = ({
  mediaObjectId,
  isUploadedToFacebookAds,
}: IInternalProps) => {
  const { setCurrentMediaObjectId } = useContext(CurrentMediaObjectIdContext)
  const onBeforeUpload = () => {
    setCurrentMediaObjectId(mediaObjectId)
  }

  return (
    <UploadToFacebookAdsManager
      isUploadedToFacebookAds={isUploadedToFacebookAds}
      onBeforeUpload={onBeforeUpload}
    />
  )
}

const mapStateToProps = (state: IApplicationState, { mediaObjectId }: IProps) => ({
  isUploadedToFacebookAds: selectMediaObjectIsUploadedToFacebookAds(state, mediaObjectId),
})

export { MediaObjectLicensingLicensedUploadToFacebookAdsManager }
export default compose<IInternalProps, IProps>(connect(mapStateToProps))(
  MediaObjectLicensingLicensedUploadToFacebookAdsManager,
)
