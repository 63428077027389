import styled from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Icon } from '@tribegroup'

export const NewTagContainer = styled.div`
  border-radius: 0.25rem;
  border: 1px solid ${Theme.secondary100Color};
`

export const PlaceholderAvatar = styled(Flex)`
  min-width: 2.5rem;
  width: auto;
  min-height: 2.5rem;
  height: 2.5rem;
  margin-right: 0.75rem;
  border-radius: 3rem;
  overflow: hidden;
  text-transform: uppercase;
  border: 1px transparent;
  position: relative;

  &::after {
    content: ' ';
    width: 100%;
    height: 100%;
    border-radius: 3rem;
    border: 1px dashed ${Theme.primaryColor};
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.6;
    box-sizing: border-box;
  }
`

export const SubmissionCardMainHeader = styled(Flex)`
  box-sizing: border-box;
  height: auto;
  padding-bottom: 0.75rem;

  .header-inner-wrapper {
    .creator-details {
      min-width: 0;
      width: 100%;

      .user-handle-wrapper {
        width: 100%;
        min-width: 0;
        padding-bottom: 0.25rem;

        .user-handle {
          opacity: ${({ userHandleOpacity = 1 }) => userHandleOpacity};
          color: ${Theme.defaultColor};
          margin-right: 0.375rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  .price {
    flex-shrink: 0;
    text-align: right;
  }
`

export const SubmissionCardHeaderRoot = styled.div`
  box-sizing: border-box;
  padding: 0.75rem 1rem 0;
  min-height: 2rem;
`

export const PreviouslyPurchasedWrapper = styled(Flex)`
  border-radius: 1rem;
  background: ${Theme.secondary100Color};
  width: 1rem;
  height: 1rem;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  margin-right: 0.375rem;
  flex-shrink: 0;
  cursor: pointer;
`

export const SmallerIcon = styled(Icon)`
  svg {
    width: 0.5rem;
    height: 0.5rem;
    padding: 0.125rem;
  }
`

export const PriceWrapper = styled.span`
  cursor: pointer;
`
