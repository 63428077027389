import React, { useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import InfluencerIdentityRow, {
  InfluencerIdentityRowRoot,
} from '@components/InfluencerIdentity/Row'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { INBOX_FILTER } from '@constants'
import { InfluencerIdentityIdProvider } from '@context/InfluencerIdentityId'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import {
  selectInfluencerIdentityAge,
  selectInfluencerIdentityAvatar,
  selectInfluencerIdentityFormattedLocationAndGender,
  selectInfluencerIdentityFullName,
  selectInfluencerIdentityHasGroup,
  selectInfluencerIdentityPlatformProvider,
  selectInfluencerIdentityPlatformUsername,
  selectInfluencerIdentitySocialPageUrl,
} from '@store/influencerIdentities'
import {
  fetchSubmissionsByInfluencerIdentity,
  selectSubmissionIdsByInfluencerIdentity,
} from '@store/submissions'
import { isEmpty } from '@utils'
import InfluencerIdentityRowSubmissionWrapper from './Submission/Wrapper/Wrapper'
import InboxInfluencerIdentityRowLabels from './Labels'
import InfluencerIdentityRowPerformance from './Performance'

interface IProps {
  influencerIdentityId: number
  campaignId: number
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

export const InboxInfluencerIdentityRow: React.FC<IInternalProps> = ({
  campaignId,
  hasFetched,
  influencerIdentityId,
  fetchSubmissionsByInfluencerIdentity: fetchSubmissionsByInfluencerIdentityAction,
  locationAndGender,
  socialUrl,
  avatarSrc,
  age,
  fullName,
  hasGroup,
  username,
  provider,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const onFetch = () => {
    if (isOpen && !hasFetched) {
      fetchSubmissionsByInfluencerIdentityAction(campaignId, influencerIdentityId)
    }
  }
  useEffect(onFetch, [isOpen])

  const onRowClick = () => setIsOpen(!isOpen)

  return (
    <InfluencerIdentityIdProvider influencerIdentityId={influencerIdentityId}>
      <InfluencerIdentityRowRoot>
        <ConnectedTrackedRoute
          disableTracking={isOpen}
          eventName={EventTrackingNames.ListViewRowClicked}
          campaignId={campaignId}
          additionalProperties={{ identity_id: influencerIdentityId, current_filter: INBOX_FILTER }}
        >
          <InfluencerIdentityRow
            onRowClick={onRowClick}
            isOpen={isOpen}
            stats={<InfluencerIdentityRowPerformance />}
            socialUrl={socialUrl}
            locationAndGender={locationAndGender}
            provider={provider}
            avatarSrc={avatarSrc}
            age={age}
            fullName={fullName}
            labels={<InboxInfluencerIdentityRowLabels />}
            username={username}
            showStar={hasGroup}
          />
        </ConnectedTrackedRoute>
      </InfluencerIdentityRowRoot>
      {isOpen && <InfluencerIdentityRowSubmissionWrapper />}
    </InfluencerIdentityIdProvider>
  )
}

const mapDispatchToProps = {
  fetchSubmissionsByInfluencerIdentity,
}

const mapStateToProps = (state: IApplicationState, { campaignId, influencerIdentityId }) => ({
  hasFetched: !isEmpty(
    selectSubmissionIdsByInfluencerIdentity(state, campaignId, influencerIdentityId),
  ),
  age: selectInfluencerIdentityAge(state, campaignId, influencerIdentityId),
  locationAndGender: selectInfluencerIdentityFormattedLocationAndGender(
    state,
    campaignId,
    influencerIdentityId,
  ),
  username: selectInfluencerIdentityPlatformUsername(state, campaignId, influencerIdentityId),
  avatarSrc: selectInfluencerIdentityAvatar(state, campaignId, influencerIdentityId),
  socialUrl: selectInfluencerIdentitySocialPageUrl(state, campaignId, influencerIdentityId),
  fullName: selectInfluencerIdentityFullName(state, campaignId, influencerIdentityId),
  provider: selectInfluencerIdentityPlatformProvider(state, campaignId, influencerIdentityId),
  hasGroup: selectInfluencerIdentityHasGroup(state, campaignId, influencerIdentityId),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<IInternalProps, IProps>(React.memo, connector)(InboxInfluencerIdentityRow)
