import { useState } from 'react'

import { SnackBarContext } from './context'
import { ISnackbar } from './types'

const SnackBarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [snackBarId, setSnackBarId] = useState(0)
  const [snackBars, setSnackBars] = useState<ISnackbar[]>([])

  const createSnackbar = (messageId: string, severity: ISnackbar['severity']) => {
    const id = snackBarId
    const snackbar = {
      id,
      message: messageId,
      severity,
    }
    setSnackBars([...snackBars, snackbar])
    setSnackBarId(snackBarId + 1)
    return id
  }

  const removeSnackbar = (id: number) => {
    setSnackBars((currentSnackBars) => currentSnackBars.filter((snackbar) => snackbar.id !== id))
  }

  return (
    <SnackBarContext.Provider value={{ snackBars, createSnackbar, removeSnackbar }}>
      {children}
    </SnackBarContext.Provider>
  )
}

export default SnackBarProvider
