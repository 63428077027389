import H from 'history'

import { PasswordActionTypes } from './'

export interface IForgotPasswordParams {
  email: string
}

export interface ISetPasswordParams {
  new_password: string
  email: string
  token: string
}

export const forgotPasswordRequest = (params: IForgotPasswordParams, history: H.History) => ({
  payload: {
    history,
    params,
  },
  type: PasswordActionTypes.FORGOT_PASSWORD_REQUEST,
})

export const forgotPasswordDone = () => ({
  type: PasswordActionTypes.FORGOT_PASSWORD_DONE,
})

export const setPasswordRequest = (params: ISetPasswordParams, history: H.History) => ({
  payload: {
    history,
    params,
  },
  type: PasswordActionTypes.SET_PASSWORD_REQUEST,
})

export const setPasswordRequestSuccess = () => ({
  type: PasswordActionTypes.SET_PASSWORD_REQUEST_SUCCESS,
})

export const setPasswordRequestError = (error: any) => ({
  payload: error,
  type: PasswordActionTypes.SET_PASSWORD_REQUEST_ERROR,
})
