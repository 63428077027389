import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import {
  fetchMediaObjectsError,
  fetchMediaObjectSuccess,
  fetchShareableMediaObject,
  FetchShareableMediaObjectActionTypes,
} from '@store/mediaObjects'
import { selectShareableMediaObjectLink } from '@store/rootResource'
import { networkRequest } from '@utils'

export function* handleFetchShareableMediaObjectRequest(
  action: ReturnType<typeof fetchShareableMediaObject>,
) {
  try {
    const { token, mediaObjectId } = action.payload

    const shareableContentLibraryLink: IHateoasLink = yield select(selectShareableMediaObjectLink)

    const mediaObject = yield call(
      networkRequest,
      shareableContentLibraryLink.method,
      shareableContentLibraryLink.href
        .replace('{token}', token)
        .replace('{id}', String(mediaObjectId)),
    )

    yield put(fetchMediaObjectSuccess(mediaObject))
  } catch (error) {
    yield put(fetchMediaObjectsError(error))
  }
}

export function* watchFetchShareableMediaObjectRequest() {
  yield takeLatest(
    FetchShareableMediaObjectActionTypes.FETCH_REQUEST,
    handleFetchShareableMediaObjectRequest,
  )
}
