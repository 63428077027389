import { useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import { BrandManagerIdentityProviders } from '@enums'
import { fetchIdentities } from '@store/brandManagerIdentities'
import { ICampaign, selectCampaignConnectedSocialAccount } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'
import { selectLink } from '@utils/selectLink'

interface IProps {
  campaign: ICampaign
  onShowConnectedAccountDrawer: VoidFunction
}

const ConnectedAccountsSideBarItemContent = ({ campaign }: IProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchIdentities())
  }, [])

  const { reconnectRequired } = useSelector((state: IApplicationState) => {
    const campaignConnectedInstagramSocialAccount = selectCampaignConnectedSocialAccount(
      state,
      campaign?.id,
      BrandManagerIdentityProviders.Instagram,
    )

    return {
      reconnectRequired: Boolean(
        selectLink(campaignConnectedInstagramSocialAccount?.links ?? [], 'reconnect_identity'),
      ),
    }
  })

  return (
    <div data-testid="sidebar-connected-account-content">
      <Flex justifyBetween alignCenter>
        <Container flexCenter>
          <Container rightOuterSpacing={0.5} leftOuterSpacing={0.15} height={1.125} width={1.375}>
            <Icon glyph="plus" size={1.125} color={Theme.grey900Color} />
          </Container>
          <Container textOverflowAsEllipsis noWrap width={8.125}>
            <FormattedMessage id="connectedAccounts.connectAccount" />
          </Container>
        </Container>
        {reconnectRequired && (
          <Container data-testid="reconnect-required-indicator" rightOuterSpacing={0.25} flexCenter>
            <Icon glyph="alert-solid" color={Theme.errorColor} size={1} />
          </Container>
        )}
      </Flex>
    </div>
  )
}

export default ConnectedAccountsSideBarItemContent
