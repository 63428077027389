import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { CreateCampaignButton } from '@components/Campaign/TitleBar/Buttons'
import { IApplicationState } from '@store'
import { selectCreateCampaignLink } from '@store/campaigns'
import { selectShowShareLibraryButton } from '@store/mediaObjects'
import { ShareThisLibraryButton } from './'

interface IInternalProps {
  showShareLibraryButton: boolean
  hasCreateCampaignLink: boolean
}

const ContentLibraryTitleBarButtons: React.FC<IInternalProps> = ({
  showShareLibraryButton,
  hasCreateCampaignLink,
}) => (
  <Flex justifyEnd alignCenter full>
    {showShareLibraryButton && <ShareThisLibraryButton />}
    {hasCreateCampaignLink && <CreateCampaignButton />}
  </Flex>
)

const mapStateToProps = (state: IApplicationState) => ({
  showShareLibraryButton: selectShowShareLibraryButton(state),
  hasCreateCampaignLink: Boolean(selectCreateCampaignLink(state)),
})

export { ContentLibraryTitleBarButtons }

export default compose<IInternalProps, {}>(connect(mapStateToProps))(ContentLibraryTitleBarButtons)
