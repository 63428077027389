import { call, put, select, takeLatest } from 'redux-saga/effects'

import { selectAuthToken } from '@store/auth'
import {
  fetchTikTokMetrics,
  fetchTikTokMetricsError,
  fetchTikTokMetricsSuccess,
  ITikTokMetricsResponse,
  selectTikTokMetricsLink,
  TikTokMetricsActionTypes,
} from '@store/postCampaignReport'
import { networkRequest } from '@utils'
import { WatcherFunction } from '..'

export function* handleFetchTikTokMetrics(action: ReturnType<typeof fetchTikTokMetrics>) {
  const { campaignId, channel } = action.payload

  try {
    const tiktokMetricsLink = yield select(selectTikTokMetricsLink, campaignId)

    const authToken: string = yield select(selectAuthToken)
    const tiktokMetrics: ITikTokMetricsResponse = yield call(
      networkRequest,
      tiktokMetricsLink.method,
      tiktokMetricsLink.href,
      undefined,
      authToken,
    )

    yield put(fetchTikTokMetricsSuccess(tiktokMetrics, campaignId, channel))
  } catch (error) {
    yield put(fetchTikTokMetricsError(error))
  }
}

function* watchFetchTikTokMetrics() {
  yield takeLatest(TikTokMetricsActionTypes.FETCH_REQUEST, handleFetchTikTokMetrics)
}

export const tiktokMetricsSagas: ReadonlyArray<WatcherFunction> = [watchFetchTikTokMetrics]
