import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import PurchaseDetails from '@components/Licensing/PurchaseDetails/PurchaseDetails'
import { PageRoutes, PaymentMethods } from '@enums'
import { IApplicationState } from '@store'
import {
  selectDimensions,
  selectFileType,
  selectIsLicensed,
  selectPrintableDimensions,
  selectTransactedLicense,
} from '@store/mediaObjects'
import Theme from '@theme'
import { Container, Header, Text } from '@tribegroup'

interface IMediaDetailsProps {
  mediaObjectId: number
}

export const MediaDetails = ({ mediaObjectId }: IMediaDetailsProps) => {
  const { pathname } = useLocation()

  const { transactedLicense, dimensions, printableDimensions, isLicensed, fileType } = useSelector(
    (state: IApplicationState) => {
      return {
        transactedLicense: selectTransactedLicense(state, mediaObjectId),
        dimensions: selectDimensions(state, mediaObjectId),
        printableDimensions: selectPrintableDimensions(state, mediaObjectId),
        fileType: selectFileType(state, mediaObjectId),
        isLicensed: selectIsLicensed(state, mediaObjectId),
      }
    },
  )

  const inContentLibrary = pathname.includes(PageRoutes.ContentLibrary)
  const inMediaObjectsDetail = pathname.includes(PageRoutes.MediaObjects)

  const showPaymentMethod =
    (inContentLibrary || inMediaObjectsDetail) && transactedLicense.purchase_method

  return (
    <Container relative>
      <Fragment>
        <Container>
          <Header small uppercase color={Theme.grey700Color}>
            <FormattedMessage id="socialSubmission.card.modal.license.media.dimensions" />
          </Header>
        </Container>
        <Text block color={Theme.grey900Color}>
          {dimensions}
          {printableDimensions && <Container>{printableDimensions}</Container>}
        </Text>
      </Fragment>
      <Container topOuterSpacing={0.75}>
        <Header small uppercase color={Theme.grey700Color}>
          <FormattedMessage id="socialSubmission.card.modal.license.media.fileType" />
        </Header>
      </Container>
      <Text color={Theme.grey900Color}>{fileType}</Text>

      {isLicensed && <PurchaseDetails mediaObjectId={mediaObjectId} />}

      {showPaymentMethod && (
        <>
          <Container topOuterSpacing={0.75}>
            <Header small uppercase color={Theme.grey700Color}>
              <FormattedMessage
                id={`contentLibrary.licensing.creditManagement.${transactedLicense.purchase_method}.mediaDetails.header`}
              />
            </Header>
          </Container>
          <Text color={Theme.grey900Color}>
            {transactedLicense.purchase_method === PaymentMethods.PURCHASE_ORDER ? (
              transactedLicense.purchase_details
            ) : (
              <FormattedMessage
                id="contentLibrary.licensing.creditManagement.credit_card.mediaDetails.label"
                values={{ lastFourDigits: transactedLicense.purchase_details }}
              />
            )}
          </Text>
        </>
      )}
    </Container>
  )
}

export default MediaDetails
