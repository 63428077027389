import React from 'react'

import UIFlagWatcherModal from '@components/UI/UIFlagWatcherModal'
import { UIFlag } from '@store/ui'
import BrandedContentAdConfirmationConnectAccount from './Confirmation/ConnectAccount'
import BrandedContentAdConfirmationNoAccount from './Confirmation/NoAccount'
import BrandedContentAdConfirmationReconnectAccount from './Confirmation/ReconnectAccount'

const BrandedContentAdWatcher: React.FC = () => {
  return (
    <>
      <UIFlagWatcherModal
        uiFlag={UIFlag.SHOW_NO_BRAND_MANAGER_IDENTITY_ALERT}
        modalComponent={BrandedContentAdConfirmationNoAccount}
      />
      <UIFlagWatcherModal
        uiFlag={UIFlag.SHOW_RECONNECT_ACCOUNT_ALERT}
        modalComponent={BrandedContentAdConfirmationReconnectAccount}
      />
      <UIFlagWatcherModal
        uiFlag={UIFlag.SHOW_CONNECT_ACCOUNT_ALERT}
        modalComponent={BrandedContentAdConfirmationConnectAccount}
      />
    </>
  )
}

export { BrandedContentAdWatcher }
export default BrandedContentAdWatcher
