import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { IMember, MemberActionTypes } from './types'
import { IMembersState, MembersActionTypes } from './'

const initialState: IMembersState = {
  isFetching: false,
  isAdding: false,
  isRemoving: false,
  errors: undefined,
  membersById: {},
  memberIdsByCampaignId: {},
  memberLinksByCampaignId: {},
}

const reducer: Reducer<IMembersState> = (state = initialState, action) => {
  switch (action.type) {
    case MembersActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }

    case MembersActionTypes.FETCH_SUCCESS: {
      const { campaignId, links } = action.payload
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        membersById: action.payload.items.reduce(
          (accum, curr) => ({
            ...accum,
            [curr.id]: curr,
          }),
          {},
        ),
        memberIdsByCampaignId: action.payload.items.reduce((accum, curr) => {
          const memberIds = accum[campaignId] || []
          return { ...accum, [campaignId]: [...memberIds, curr.id] }
        }, {}),
        memberLinksByCampaignId: {
          ...state.memberIdsByCampaignId,
          [campaignId]: links,
        },
      }
    }

    case MembersActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }
    }

    case MemberActionTypes.ADD_REQUEST: {
      return {
        ...state,
        isAdding: true,
      }
    }

    case MemberActionTypes.ADD_SUCCESS: {
      const { member, campaignId } = action.payload
      return {
        ...state,
        membersById: {
          ...state.membersById,
          [member.id]: { ...member },
        },
        memberIdsByCampaignId: {
          ...state.memberIdsByCampaignId,
          [campaignId]: [...state.memberIdsByCampaignId[campaignId], member.id],
        },
        isAdding: false,
        errors: undefined,
      }
    }

    case MemberActionTypes.ADD_ERROR: {
      return {
        ...state,
        isAdding: false,
        errors: action.payload,
      }
    }

    case MemberActionTypes.REMOVE_REQUEST: {
      return {
        ...state,
        isRemoving: true,
      }
    }

    case MemberActionTypes.REMOVE_SUCCESS: {
      const { memberId, campaignId } = action.payload
      return {
        ...state,
        isRemoving: false,
        errors: undefined,
        memberIdsByCampaignId: {
          ...state.memberIdsByCampaignId,
          [campaignId]: state.memberIdsByCampaignId[campaignId].filter(
            currentMemberId => currentMemberId !== memberId,
          ),
        },
        membersById: Object.values(state.membersById).reduce(
          (members, curr: IMember) =>
            curr.id === memberId ? members : { ...members, [curr.id]: curr },
          {},
        ),
      }
    }

    case MemberActionTypes.REMOVE_ERROR: {
      return {
        ...state,
        isRemoving: false,
        errors: action.payload,
      }
    }

    case MemberActionTypes.CLEAR_ERRORS: {
      return {
        ...state,
        errors: undefined,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as membersReducer }
