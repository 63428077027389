import { IAnalyticsSubmission } from '../types'

export enum TikTokMetricsActionTypes {
  FETCH_REQUEST = 'pcr/tiktokMetrics/FETCH_REQUEST',
  FETCH_SUCCESS = 'pcr/tiktokMetrics/FETCH_SUCCESS',
  FETCH_ERROR = 'pcr/tiktokMetrics/FETCH_ERROR',
}

export interface ITikTokMetricsState {
  isFetching: boolean
  errors?: object | string
  tiktokMetricsById: Record<number, ITikTokMetrics>
  tiktokMetricsSummary: Record<number, ITikTokMetricsSummary>
  tiktokMetricIdsByCampaign: Record<number, ReadonlyArray<number>>
}

export interface ITikTokMetrics extends IAnalyticsSubmission {
  purchase_amount: number
  purchase_amount_currency: string
  video_views?: number
  video_views_pct?: number
  cpm?: number
  cpv?: number
  likes?: number
  comments?: number
  shares?: number
  engagement?: number
  cpe?: number
  engagement_pct?: number
}

export interface ITikTokMetricsSummary {
  count: number | null
  total_purchase_amount: number | null
  total_followers: number | null
  engagement?: number
  engagement_pct?: number
  total_engagement_pct?: number
  likes?: number
  comments?: number
  shares?: number
  video_views?: number
  video_views_pct?: number
  cpv?: number
  cpm?: number
  cpe?: number
}

export interface ITikTokMetricsResponse {
  items: ReadonlyArray<ITikTokMetrics>
  metadata: ITikTokMetricsSummary
}
