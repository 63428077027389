import { FormattedMessage } from 'react-intl'

import { DEFAULT_TENANT, TRIBE_CODES_OF_CONDUCT } from '@constants'
import Theme from '@theme'
import { Header, Link, Text } from '@tribegroup'
import CampaignPreviewBlock from '../Block'
import { BlockText } from '../Block/Block.styled'
import HouseRulesStyled from './HouseRules.styled'

export interface ICampaignPreviewHouseRules {
  rules: ReadonlyArray<string>
}

const CampaignPreviewHouseRules = ({ rules = [] }: ICampaignPreviewHouseRules) => {
  return rules.length > 0 ? (
    <CampaignPreviewBlock>
      <Header small color={Theme.grey900Color} uppercase cta bottomOuterSpacing={0.125}>
        <FormattedMessage id="campaign.preview.houseRules.header" />
      </Header>
      <HouseRulesStyled>
        {rules.map((rule) => (
          <li key={`rule-${rule}`}>
            <BlockText small color={Theme.grey800Color}>
              {rule}
            </BlockText>
          </li>
        ))}
        {process.env.APP_NAME === DEFAULT_TENANT && (
          <li>
            <BlockText small color={Theme.grey800Color}>
              <FormattedMessage id="campaign.preview.houseRules.alwaysAdhere" />{' '}
              <Link href={TRIBE_CODES_OF_CONDUCT} target="_blank">
                <Text small color={Theme.primaryColor}>
                  <FormattedMessage id="campaign.preview.houseRules.tribeCodesOfConduct" />
                </Text>
              </Link>
            </BlockText>
          </li>
        )}
      </HouseRulesStyled>
    </CampaignPreviewBlock>
  ) : null
}

export default CampaignPreviewHouseRules
