import React from 'react'

import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { IModalComponentProps } from '@context/Modal'
import { useIsMounted } from '@hooks/useIsMounted'
import { IApplicationState } from '@store'
import {
  clearBrandFansErrors,
  IBrandFanEditableFields,
  selectBrandFanGroupDescription,
  selectBrandFanGroupName,
  selectIsUpdatingBrandFanGroup,
  updateBrandFanGroup,
} from '@store/brandFans'
import Theme from '@theme'
import { Button, Container, H1, Header, Modal, TextArea } from '@tribegroup'
import BrandFansGroupModalErrorMessage from './ErrorMessage'

interface IProps extends IModalComponentProps {
  groupId: number
}

interface IInternalProps extends IProps {
  description: string
  name: string
  isUpdatingBrandFanGroup: boolean
  clearBrandFansErrors: typeof clearBrandFansErrors
  updateBrandFanGroup: typeof updateBrandFanGroup
}

const BrandFansGroupModalEditDescription: React.FC<IInternalProps> = ({
  groupId,
  isOpen = false,
  onRequestClose,
  description,
  name,
  isUpdatingBrandFanGroup,
  clearBrandFansErrors: clearBrandFansErrorsAction,
  updateBrandFanGroup: updateBrandFanGroupAction,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    trigger,
    formState: { isDirty },
  } = useForm()

  const onSubmit = (params: IBrandFanEditableFields) => {
    updateBrandFanGroupAction(groupId, params)
  }
  const onClick = async () => {
    if (await trigger()) {
      updateBrandFanGroupAction(groupId, { name, description: getValues().description.trim() })
    }
  }
  const isMounted = useIsMounted()
  React.useEffect(() => {
    if (isMounted && !isUpdatingBrandFanGroup) {
      onRequestClose()
    }
  }, [isUpdatingBrandFanGroup])
  React.useEffect(() => clearBrandFansErrorsAction as VoidFunction)

  return (
    <Modal reactModalProps={{ isOpen }} width={33.75} complex>
      <form
        data-testid="brand-fans-group-modal-edit-description-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <H1 color={Theme.grey900Color} bottomOuterSpacing={1.75} capitalize>
          <FormattedMessage id="brandFans.groups.actions.editDescription" />
        </H1>
        <Container bottomOuterSpacing={4.375} relative>
          <TextArea
            innerRef={register({
              maxLength: 120,
            })}
            defaultValue={description || ''}
            name="description"
            height={4.25}
            characterLimit={120}
            autoFocus
            data-testid="brand-fans-group-modal-description"
            disableLineBreak
          />
          {errors.description && (
            <BrandFansGroupModalErrorMessage bottomPosition={0}>
              <FormattedMessage id="builder.campaign.error.max" />
            </BrandFansGroupModalErrorMessage>
          )}
        </Container>
      </form>
      <Flex justifyEnd alignCenter>
        <Button
          width={8.75}
          scheme="primary"
          height={3}
          outlined
          onClick={onRequestClose}
          disabled={isUpdatingBrandFanGroup}
        >
          <Header cta>
            <FormattedMessage id="core.text.cancel" />
          </Header>
        </Button>
        <Button
          width={8.75}
          scheme="primary"
          height={3}
          leftOuterSpacing={0.75}
          onClick={onClick}
          data-testid="brand-fans-group-update"
          disabled={isUpdatingBrandFanGroup || !isDirty}
          loading={isUpdatingBrandFanGroup}
        >
          <Header cta>
            <FormattedMessage id="core.text.update" />
          </Header>
        </Button>
      </Flex>
    </Modal>
  )
}

const mapStateToProps = (state: IApplicationState, { groupId }: IProps) => ({
  description: selectBrandFanGroupDescription(state, groupId),
  name: selectBrandFanGroupName(state, groupId),
  isUpdatingBrandFanGroup: selectIsUpdatingBrandFanGroup(state),
})

const mapDispatchToProps = {
  updateBrandFanGroup,
  clearBrandFansErrors,
}

export { BrandFansGroupModalEditDescription }
export default compose<IInternalProps, IProps>(connect(mapStateToProps, mapDispatchToProps))(
  BrandFansGroupModalEditDescription,
)
