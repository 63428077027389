import React from 'react'

import { FormattedHTMLMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectCompanyRegionCurrency, selectReferralRewardPriceForUserRegion } from '@store/me'
import Theme from '@theme'
import { Text } from '@tribegroup'

interface IInternalProps extends InjectedIntlProps, ConnectedProps<typeof connector> {}

const InboxReferralSubtitle: React.FC<IInternalProps> = ({ amount, currency, intl }) => (
  <Text color={Theme.grey800Color}>
    <FormattedHTMLMessage
      id="inbox.referral.modal.subtitle"
      values={{
        amount,
        currency: intl.formatMessage({ id: `core.currency.symbol.${currency}` }),
      }}
    />
  </Text>
)

const mapStateToProps = (state: IApplicationState) => ({
  amount: selectReferralRewardPriceForUserRegion(state),
  currency: selectCompanyRegionCurrency(state),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(connector, injectIntl)(InboxReferralSubtitle)
