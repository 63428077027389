import React from 'react'

import isNumber from 'lodash.isnumber'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import Theme from '@theme'
import { Text } from '@tribegroup'
import { Entry, RowWrapper } from '../ListView.styled'

interface IAnalyticsPostCampaignReportListViewNumberProps {
  backgroundColor: string
  testId?: string
  value: number
}

const AnalyticsPostCampaignReportListViewNumber: React.FC<IAnalyticsPostCampaignReportListViewNumberProps> = ({
  backgroundColor,
  testId,
  value,
}) => (
  <RowWrapper data-testid={testId} backgroundColor={backgroundColor}>
    <Entry>
      {isNumber(value) ? (
        <Text color={Theme.grey900Color}>
          <FormattedNumber value={value} />
        </Text>
      ) : (
        <Text color={Theme.grey800Color}>
          <FormattedMessage id="core.metric.empty" />
        </Text>
      )}
    </Entry>
  </RowWrapper>
)

export default AnalyticsPostCampaignReportListViewNumber
