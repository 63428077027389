import React from 'react'

import Flex from 'styled-flex-component'

import { Hr } from '@components/UI/Hr/Hr.styled'
import { Container } from '@tribegroup'
import PostCampaignReportSubmissionListPerformance from '../Performance/Performance'
import PostCampaignReportSubmissionListProfile from '../Profile/Profile'
import { FlexItemWrapper } from './Row.styled'

interface IProps {
  campaignId: number
}

const PostCampaignReportSubmissionListRow: React.FC<IProps> = ({ campaignId }) => {
  return (
    <React.Fragment>
      <Container topOuterSpacing={1} bottomOuterSpacing={1}>
        <Hr />
      </Container>
      <Flex justifyBetween alignCenter>
        <Flex justifyBetween alignCenter>
          <PostCampaignReportSubmissionListProfile />
        </Flex>
        <FlexItemWrapper noShrink>
          <Container leftOuterSpacing={1.5}>
            <PostCampaignReportSubmissionListPerformance campaignId={campaignId} />
          </Container>
        </FlexItemWrapper>
      </Flex>
    </React.Fragment>
  )
}

export { PostCampaignReportSubmissionListRow }
export default PostCampaignReportSubmissionListRow
