import React from 'react'

import { FormattedMessage, FormattedNumber } from 'react-intl'

import InfluencerIdentityRowLabelTooltip from '@components/InfluencerIdentity/Row/LabelTooltip'
import InfluencerIdentityRowValueAndLabel from '@components/InfluencerIdentity/Row/ValueAndLabel'

interface IPerformanceCollabsProps {
  collabs: number
}

const BrandFanInfluencerIdentityRowPerformanceCollabs: React.FC<IPerformanceCollabsProps> = ({
  collabs,
}) => {
  return (
    <InfluencerIdentityRowValueAndLabel
      value={<FormattedNumber value={collabs} />}
      label={
        <InfluencerIdentityRowLabelTooltip
          title={<FormattedMessage id="core.text.collabs" />}
          body={<FormattedMessage id="core.text.collabs.description" />}
        >
          <FormattedMessage id="core.text.collabs" />
        </InfluencerIdentityRowLabelTooltip>
      }
      width={4.375}
    />
  )
}

export { BrandFanInfluencerIdentityRowPerformanceCollabs, IPerformanceCollabsProps }
export default BrandFanInfluencerIdentityRowPerformanceCollabs
