import React from 'react'

import hooks from '@hooks'
import SubmissionSummaryNavigationByFilter from './NavigationByFilter'
import SubmissionSummaryNavigationByInfluencerId from './NavigationByInfluencerIdentityId'

const SubmissionSummaryNavigationWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isInfluencerView = hooks.useIsInfluencerView()
  return isInfluencerView ? (
    <SubmissionSummaryNavigationByInfluencerId>
      {children}
    </SubmissionSummaryNavigationByInfluencerId>
  ) : (
    <SubmissionSummaryNavigationByFilter>{children}</SubmissionSummaryNavigationByFilter>
  )
}

export default SubmissionSummaryNavigationWrapper
