import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import SubmissionThumbnailStatusLabel from '.'

const SubmissionThumbnailStatusLabelPostWithinHours: React.FC = () => (
  <SubmissionThumbnailStatusLabel
    firstText={<FormattedMessage id="submission.card.publishSchedule.post" />}
    secondText={<FormattedMessage id="submission.thumbnail.tooltip.within48Hrs" />}
    color={Theme.grey900Color}
  />
)

export default SubmissionThumbnailStatusLabelPostWithinHours
