import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import { selectDraftHasBrandFanGroupAssociation } from '@store/drafts'
import { Container } from '@tribegroup'
import BuilderBudgetTarget from '../Target'
import BuilderBudgetCreatorBrandFanGroups from './BrandFanGroups'
import BuilderBudgetCreatorGroupSelection from './Selection'

export const CREATOR_GROUP_COMMUNITY = 'creator_group_community'
export const CREATOR_GROUP_BRAND_FANS = 'creator_group_brand_fans'

export const BRAND_FAN_GROUP_IDS = 'brand_fan_group_ids'

interface IInternalProps extends IBuilderValidationContextProps, ConnectedProps<typeof connector> {}

const BuilderBudgetCreatorGroup: React.FC<IInternalProps> = ({
  updateForm,
  hasBrandFanGroupAssociation,
}) => {
  const defaultSelected = hasBrandFanGroupAssociation
    ? CREATOR_GROUP_BRAND_FANS
    : CREATOR_GROUP_COMMUNITY

  const [selectedOption, setSelectedOption] = React.useState(defaultSelected)

  const brandFanGroupSelected = selectedOption === CREATOR_GROUP_BRAND_FANS
  const creatorCommunitySelected = selectedOption === CREATOR_GROUP_COMMUNITY

  const showBrandFanGroups = brandFanGroupSelected

  const onCreatorGroupChange = (selected: string) => {
    if (selected === CREATOR_GROUP_COMMUNITY) {
      updateForm({
        brand_fan_group_ids: [],
      })
    }
    setSelectedOption(selected)
  }

  return (
    <Container data-testid="builder-budget-form-creator-group">
      <BuilderBudgetCreatorGroupSelection
        selectedOption={selectedOption}
        onOptionChange={onCreatorGroupChange}
      />
      {creatorCommunitySelected && <BuilderBudgetTarget />}
      {showBrandFanGroups && <BuilderBudgetCreatorBrandFanGroups />}
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  hasBrandFanGroupAssociation: selectDraftHasBrandFanGroupAssociation(state),
})

const connector = connect(mapStateToProps)

export { BuilderBudgetCreatorGroup }
export default compose<IInternalProps, {}>(
  withBuilderValidation,
  connector,
)(BuilderBudgetCreatorGroup)
