import { createAction } from '@reduxjs/toolkit'

import {
  IFileError,
  IPreSignedUrlItem,
  MoodboardMediaDeleteActionTypes,
  MoodboardMediaUploadActionTypes,
} from '@store/moodboard'

export const uploadMoodboardMedia = createAction(
  MoodboardMediaUploadActionTypes.UPLOAD_REQUEST,
  (files: ReadonlyArray<File>, campaignId: number, positions: number[]) => ({
    payload: {
      files,
      campaignId,
      positions,
    },
  }),
)

export const uploadMoodboardInvalidFile = createAction(
  MoodboardMediaUploadActionTypes.INVALID_FILE,
  (campaignId: number, position: number, errors: ReadonlyArray<IFileError>) => ({
    payload: {
      position,
      campaignId,
      errors,
    },
  }),
)

export const uploadMoodboardMediaInProgress = createAction(
  MoodboardMediaUploadActionTypes.UPLOAD_IN_PROGRESS,
  (campaignId: number, preSignedUrlItem: IPreSignedUrlItem, file: File) => ({
    payload: {
      preSignedUrlItem,
      campaignId,
      file,
    },
  }),
)

export const uploadMoodboardMediaSuccess = createAction(
  MoodboardMediaUploadActionTypes.UPLOAD_SUCCESS,
  (campaignId: number, mediaId: number) => ({
    payload: {
      mediaId,
      campaignId,
    },
  }),
)

export const uploadMoodboardMediaError = createAction(
  MoodboardMediaUploadActionTypes.UPLOAD_ERROR,
  (campaignId: number, mediaId: number) => ({
    payload: {
      mediaId,
      campaignId,
    },
  }),
)

export const deleteMoodboardMedia = createAction(
  MoodboardMediaDeleteActionTypes.DELETE_REQUEST,
  (campaignId: number, position: number) => ({
    payload: {
      position,
      campaignId,
    },
  }),
)

export const deleteMoodboardMediaSuccess = createAction(
  MoodboardMediaDeleteActionTypes.DELETE_SUCCESS,
  (campaignId: number, mediaId: number) => ({
    payload: {
      mediaId,
      campaignId,
    },
  }),
)

export const deleteMoodboardMediaError = createAction(
  MoodboardMediaDeleteActionTypes.DELETE_ERROR,
  (campaignId: number, position: number) => ({
    payload: {
      position,
      campaignId,
    },
  }),
)

export const cancelMoodboardMediaUpload = createAction(
  MoodboardMediaUploadActionTypes.CANCEL_REQUEST,
  (campaignId: number, mediaId: number) => ({
    payload: {
      mediaId,
      campaignId,
    },
  }),
)
