import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import InfluencerIdentityRowDetailsLocationAgeGender from '@components/InfluencerIdentity/Row/Details/LocationAgeGender'
import { Hr } from '@components/UI/Hr/Hr.styled'
import { IModalComponentProps } from '@context/Modal'
import { AudienceInsightsSource } from '@enums'
import { IApplicationState } from '@store'
import {
  fetchAudienceInsights,
  selectAudienceInsights,
  selectAudienceInsightsIsFetching,
} from '@store/audienceInsights'
import {
  selectIdentityAge,
  selectIdentityAudienceInsightsLink,
  selectIdentityFormattedLocationAndGender,
} from '@store/brandFans'
import Theme from '@theme'
import { CloseIcon, Container, H3, Modal } from '@tribegroup'
import BrandFanInfluencerIdentityRow from '../InfluencerIdentity/Row'
import BrandFansAudienceInsightsMerics from './Metrics'

interface IProps extends IModalComponentProps {
  identityId: number
}

interface IInternalProps extends IProps, InjectedIntlProps, ConnectedProps<typeof connector> {}

const BrandFansAudienceInsights: React.FC<IInternalProps> = ({
  age,
  identityId,
  audienceInsights,
  onRequestClose,
  isOpen = false,
  locationAndGender,
  hasAudienceInsightsLink,
  isFetching,
  fetchAudienceInsights: fetchAudienceInsightAction,
}) => {
  React.useEffect(() => {
    if (hasAudienceInsightsLink) {
      fetchAudienceInsightAction(identityId, AudienceInsightsSource.Identity)
    }
  }, [])
  return (
    <Modal reactModalProps={{ isOpen }} width={53.25} complex>
      <Container data-testid="audience-insights-modal">
        <CloseIcon onClick={onRequestClose} />
        <div>
          <BrandFanInfluencerIdentityRow
            horizontalPadding={0}
            identityId={identityId}
            showCTAs={false}
            showLocationGenderAgeSection={false}
            showSubmissions={false}
          />
        </div>
        <InfluencerIdentityRowDetailsLocationAgeGender
          showMidDot={false}
          locationAndGender={locationAndGender}
          age={age}
        />

        {hasAudienceInsightsLink && (
          <>
            <Container topOuterSpacing={1.875} maxHeight="55vh" overflow="auto">
              <Flex alignCenter justifyStart>
                <H3 color={Theme.grey900Color}>
                  <FormattedMessage id="core.text.audienceMetrics" />
                </H3>
              </Flex>
              <Container topOuterSpacing={0.625}>
                <Hr />
              </Container>
              <BrandFansAudienceInsightsMerics
                isFetching={isFetching}
                audienceInsights={audienceInsights}
              />
            </Container>
          </>
        )}
        <Hr />
      </Container>
    </Modal>
  )
}

const mapDispatchToProps = {
  fetchAudienceInsights,
}

const mapStateToProps = (state: IApplicationState, { identityId }: IProps) => {
  const audienceInsightsLink = selectIdentityAudienceInsightsLink(state, identityId)
  return {
    isFetching: selectAudienceInsightsIsFetching(state),
    audienceInsights: selectAudienceInsights(state, identityId),
    age: selectIdentityAge(state, identityId),
    locationAndGender: selectIdentityFormattedLocationAndGender(state, identityId),
    hasAudienceInsightsLink: Boolean(audienceInsightsLink),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<IInternalProps, IProps>(injectIntl, connector)(BrandFansAudienceInsights)
