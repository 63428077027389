import styled from 'styled-components'

import Theme from '@theme'

export const CampaignPreviewHeroImageStyled = styled.div`
  position: relative;
  text-align: center;
  margin: 0;

  > img {
    height: 13.125rem;
    width: 100%;
    display: block;
  }

  &::after {
    content: ' ';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(rgb(0 0 0 / 10%), rgb(0 0 0 / 0%));
  }
`

export const BrandLogoWrapper = styled.div`
  display: inline-block;
  width: 4.75rem;
  height: 4.75rem;
  box-shadow: 0 0.1875rem 0.1875rem 0 rgb(0 0 0 / 12%);
  margin-top: -2.375rem;
  box-sizing: border-box;
  border-radius: 0.5875rem;
  overflow: hidden;

  > div {
    border-radius: 0.5875rem;

    img {
      border: 0.25rem solid ${Theme.whiteColor};
      box-sizing: border-box;
      border-radius: 0.5875rem;
    }
  }
`

export const EmptyLogo = styled.div`
  width: 100%;
  height: 100%;
  background: ${Theme.grey200Color};
  border: 0.25rem solid ${Theme.whiteColor};
  box-sizing: border-box;
`

export default CampaignPreviewHeroImageStyled
