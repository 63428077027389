import React from 'react'

import isNumber from 'lodash.isnumber'
import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Text } from '@tribegroup'
import { Entry, RowWrapper } from '../ListView.styled'

interface IAnalyticsPostCampaignReportListViewPercentageProps {
  backgroundColor: string
  testId?: string
  value: number
}

const AnalyticsPostCampaignReportListViewPercentage: React.FC<IAnalyticsPostCampaignReportListViewPercentageProps> = ({
  backgroundColor,
  testId,
  value,
}) => (
  <RowWrapper data-testid={testId} backgroundColor={backgroundColor}>
    <Entry>
      {isNumber(value) ? (
        <Text color={Theme.grey900Color}>
          <FormattedMessage id="core.text.percent" values={{ value }} />
        </Text>
      ) : (
        <Text color={Theme.grey800Color}>
          <FormattedMessage id="core.metric.empty" />
        </Text>
      )}
    </Entry>
  </RowWrapper>
)

export default AnalyticsPostCampaignReportListViewPercentage
