import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Flex from 'styled-flex-component'

import Theme, { titleBarHeight } from '@theme'
import { mobileGutter, tabletGutter } from '@theme'
import { Icon } from '@tribegroup'

export const HeaderAboveSidebar = styled(Flex)`
  box-sizing: content-box;
  width: auto;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  ${breakpoint('phoneUpperBoundary')`
    padding-right: 1.25rem;
    box-sizing: border-box;
    min-width: 13.875rem;
  `};
`
export const HeaderContent = styled(Flex)`
  display: none;
  ${breakpoint('phoneUpperBoundary')`
    display: flex;
    padding: 0 ${mobileGutter}rem;
  `};
  ${breakpoint('desktop')`
    padding: 0 ${tabletGutter}rem;
  `};
`

export const HeaderTitle = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const HeaderAction = styled('div')`
  display: none;
  ${breakpoint('phoneUpperBoundary')`
    display: inherit;
    flex-shrink: 0;
  `};
`
export const HeaderBackButton = styled(Icon)`
  margin-right: 1.75rem;
  cursor: pointer;
`

export const LogoLinkStyled = styled(Link)`
  height: 1.75rem;
  width: 1.75rem;
  padding-left: 1.5rem;
  display: block;
`

export const SelectionModeOverlay = styled.div`
  ${breakpoint('phoneUpperBoundary')`
    transform: translate(-13.875rem);
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100vh;
    width: 13.875rem;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.5s ease-in-out 0s, transform 0s ease-in-out 0.5s;
    ${({ isSelectionModeOn }) =>
      isSelectionModeOn &&
      css`
        transition: opacity 0.5s ease-in-out;
        transform: translate(0);
        opacity: 0.5;
      `};
  `}
`

export const StyledMobileMenu = styled(Flex)`
  cursor: pointer;
  width: 12rem;
  position: absolute;
  text-align: center;
  margin: auto;
  left: calc(50% - 6rem);
`

export const StyledHeaderTextWrapper = styled.div`
  margin-left: 0.25rem;
`

export default styled.header`
  width: 100%;
  box-sizing: border-box;
  top: 0;
  position: sticky;
  background: ${Theme.whiteColor};
  z-index: 3;
  height: ${titleBarHeight}rem;
  border-bottom: 1px solid ${Theme.grey400Color};
  ${({ mobile, desktop }) => css`
    ${mobile &&
    css`
      ${breakpoint('phoneUpperBoundary')`
            display: none;
          `};
    `};
    ${desktop &&
    css`
      display: none;
      ${breakpoint('phoneUpperBoundary')`
          display: block;
        `};
    `};
  `}
`
