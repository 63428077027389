import React from 'react'

import { SubmissionChannels } from '@enums'
import BuilderBriefChannelsGenericError from '../GenericError'

const SocialPanelRequiredErrorMessage: React.FC<{ channel: SubmissionChannels }> = ({
  channel,
}) => {
  switch (channel) {
    case SubmissionChannels.Instagram:
      return (
        <BuilderBriefChannelsGenericError errorId="builder.brief.socialMedia.panel.instagramRequired" />
      )

    case SubmissionChannels.Pinterest:
      return (
        <BuilderBriefChannelsGenericError errorId="builder.brief.socialMedia.panel.pinterestRequired" />
      )

    case SubmissionChannels.X:
      return (
        <BuilderBriefChannelsGenericError errorId="builder.brief.socialMedia.panel.xRequired" />
      )

    case SubmissionChannels.YouTube:
      return (
        <BuilderBriefChannelsGenericError errorId="builder.brief.socialMedia.panel.youtubeRequired" />
      )

    default:
      return <BuilderBriefChannelsGenericError errorId="builder.brief.socialMedia.panel.required" />
  }
}

export default SocialPanelRequiredErrorMessage
