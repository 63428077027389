import React from 'react'

import ButterToast from 'butter-toast'

import { Icon } from '../Icon'
import { Link } from '../Link'
import { defaultTheme } from '../Theme'
import { Text } from '../Typography'
import { TextWrapper, ToastWrapper } from './Toast.styled'

const toast = (content: React.ReactNode, showCloseButton?: boolean, toastConfig?: {}) => {
  const displayContent = ({ dismiss }) => {
    const onClose = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      dismiss(event)
    }
    return (
      <ToastWrapper justifyBetween>
        <TextWrapper>
          <Text small>{content}</Text>
        </TextWrapper>
        {showCloseButton && (
          <Link href="/" onClick={onClose}>
            <Icon glyph="close-x" size={0.875} color={defaultTheme.grey900Color} />
          </Link>
        )}
      </ToastWrapper>
    )
  }
  ButterToast.raise({
    content: displayContent,
    ...toastConfig,
  })
}

const createToast = (
  Component: React.ComponentType,
  componentProps?: any,
  showCloseButton?: boolean,
  toastConfig?: {},
) => {
  toast(<Component {...componentProps} />, showCloseButton, toastConfig)
}

const dismissToast = () => {
  ButterToast.dismissAll()
}

export const Toaster = {
  toast,
  createToast,
  dismissToast,
}

export { dismissToast, createToast }
export default toast
