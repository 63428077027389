import { Component, createRef } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { match, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import ActionIcon from '@components/UI/ActionIcon'
import Drawer from '@components/UI/Drawer'
import DrawerForm from '@components/UI/Drawer/Form'
import InternalNote from '@components/UI/Drawer/InternalMessage'
import { ISubmissionContextProps } from '@context/Submission'
import { withSubmission } from '@hocs'
import { IApplicationState } from '@store'
import { IMe, selectMeInfo } from '@store/me'
import { addNote, fetchNotes, IAddNoteRequestBody, IAddNoteRequestMeta, INote } from '@store/notes'
import { selectNotes } from '@store/notes/selectors'
import { ISubmission } from '@store/submissions'
import Theme from '@theme'
import { Text } from '@tribegroup'
import { drawerDateFormat, getBackLink } from '@utils'
import {
  CommentBlockMessage,
  CommentBlockStyled,
  CommentFormDivider,
  CommentFormStyled,
  CommentFormTextAreaWrapper,
  CommentListStyled,
} from './Comments.styled'

interface IMatchParam {
  campaignId: string
  submissionId: string
}

interface IInternalProps extends RouteComponentProps, ISubmissionContextProps, InjectedIntlProps {
  match: match<IMatchParam>
  notes: ReadonlyArray<INote>
  me: IMe
  submission: ISubmission
  campaignId: number
  fetchNotes: typeof fetchNotes
  addNote: typeof addNote
  submissionId: number
}

export const CommentBlock = ({ note, me }) => {
  const dateTextProps = drawerDateFormat(new Date(note.created_at))
  return (
    <CommentBlockStyled>
      <Text color={Theme.grey700Color} small>
        <FormattedMessage id={dateTextProps.id} values={dateTextProps.values} />
      </Text>
      <CommentBlockMessage>
        <Text color={Theme.grey900Color}>{note.text}</Text>
      </CommentBlockMessage>
      <Text small textAlign="right" color={Theme.grey700Color} block>
        {me.id === note.author_id ? <FormattedMessage id="core.text.you" /> : note.author_name}
      </Text>
    </CommentBlockStyled>
  )
}

export class SubmissionComments extends Component<IInternalProps> {
  static getDerivedStateFromProps(nextProps) {
    const noteBasePath = `/notes`
    return {
      open: nextProps.location.pathname.includes(noteBasePath),
    }
  }

  state = {
    open: false,
    message: '',
  }

  private bottomDiv = createRef<HTMLDivElement>()
  private textAreaRef = createRef<HTMLTextAreaElement>()

  scrollToBottom = () => {
    const node = this.bottomDiv.current
    if (node) {
      setTimeout(() => {
        node.scrollIntoView()
      }, 300)
    }
  }

  resetUI = () => {
    if (this.state.open) {
      this.scrollToBottom()
    }
  }

  private focusOnTextArea = () => {
    setTimeout(() => {
      if (this.textAreaRef.current) {
        this.textAreaRef.current.focus()
      }
    }, 100)
  }

  componentDidMount() {
    if (this.state.open) {
      this.props.fetchNotes(this.props.submissionId, this.props.campaignId)
      this.focusOnTextArea()
    }
    this.resetUI()
  }

  componentDidUpdate(prevProps, prevState) {
    const hasOpened = this.state.open && prevState.open !== this.state.open
    if (hasOpened) {
      this.props.fetchNotes(this.props.submissionId, this.props.campaignId)
      this.focusOnTextArea()
    }
    this.resetUI()
  }

  setMessage = (message) => {
    this.setState({ message })
  }

  onFormSubmit = () => {
    const { message } = this.state
    const { campaignId, submissionId } = this.props

    if (message) {
      const requestBody: IAddNoteRequestBody = {
        note: message,
      }

      const requestMeta: IAddNoteRequestMeta = {
        refreshSubmission: this.props.notes.length === 0,
      }

      this.props.addNote(submissionId, campaignId, requestBody, requestMeta)
      this.setState({ message: '' })
    }
  }

  handleClose = () => {
    if (this.state.open) {
      const { history } = this.props
      const { pathname, search } = history.location
      const backLink = getBackLink(pathname)
      history.push(`${backLink}${search}`)
    }
  }

  render() {
    const { intl, notes, me } = this.props
    const defaultNote = {
      created_at: undefined,
      text: intl.formatMessage({
        id: 'submission.comments.defaultMessage',
      }),
    }
    return (
      <Drawer
        title={intl.formatMessage({ id: 'submission.comments.header.notes' })}
        open={this.state.open}
        headerRightIcon={
          <ActionIcon
            size={1.5}
            glyph="close-x"
            color={Theme.grey900Color}
            onClick={this.handleClose}
            elementName="close-notes-view"
          />
        }
        wrapperCallback={this.handleClose}
        showHeaderDivider
      >
        <CommentListStyled alignBaseline columnReverse>
          <div ref={this.bottomDiv} />
          {notes.length === 0 && <InternalNote message={defaultNote} />}
          {notes.map((note) => (
            <CommentBlock key={note.id} note={note} me={me} />
          ))}
        </CommentListStyled>
        <CommentFormStyled>
          <CommentFormDivider />
          <CommentFormTextAreaWrapper>
            <DrawerForm
              handleInput={this.setMessage}
              handleSubmit={this.onFormSubmit}
              message={this.state.message}
              placeholder={intl.formatMessage({ id: 'submission.comments.form.placeholder' })}
              textareaRef={this.textAreaRef}
            />
          </CommentFormTextAreaWrapper>
        </CommentFormStyled>
      </Drawer>
    )
  }
}

const mapStateToProps = (state: IApplicationState, ownProps: IInternalProps) => {
  const { campaignId, submissionId: urlSubmissionId } = ownProps.match.params
  const submissionId = parseInt(urlSubmissionId, 10) || ownProps.submission.id

  return {
    campaignId: parseInt(campaignId, 10),
    notes: selectNotes(state, submissionId),
    me: selectMeInfo(state),
    submissionId,
  }
}

const mapDispatchToProps = {
  fetchNotes,
  addNote,
}

export default compose<IInternalProps, {}>(
  withSubmission,
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(SubmissionComments)
