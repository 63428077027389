import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { IShortlistSummaryState, ShortlistSummaryActionTypes } from '@store/shortlistSummary'
import { returnSecondIfDeepEqual } from '@utils'

const initialState: Readonly<IShortlistSummaryState> = {
  shortlistSummaryByCampaignId: {},
  isFetching: false,
  errors: undefined,
}

const reducer: Reducer<IShortlistSummaryState> = (state = initialState, action) => {
  switch (action.type) {
    case ShortlistSummaryActionTypes.FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case ShortlistSummaryActionTypes.FETCH_SUCCESS: {
      const { campaignId, shortlistSummary } = action.payload
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        shortlistSummaryByCampaignId: returnSecondIfDeepEqual(
          {
            ...state.shortlistSummaryByCampaignId,
            [campaignId]: shortlistSummary,
          },
          state.shortlistSummaryByCampaignId,
        ),
      }
    }

    case ShortlistSummaryActionTypes.FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as shortlistSummaryReducer }
