import styled from 'styled-components'

import Theme from '@theme'

export const ChannelWrapper = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
`

export const ActionIconWrapper = styled.span`
  margin-right: 0.75rem;
`

export const SocialChannelsHeader = styled.div`
  margin-top: 2rem;
`

export const SocialChannelsSubText = styled.div`
  margin-top: 0.75rem;
`

export const SocialInputWrapper = styled.div`
  margin-top: 2rem;
`

export const NoWrapLinkWrapper = styled.span`
  white-space: nowrap;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;

  button {
    transition: opacity 0.75s;
    opacity: 0;
  }

  input:focus + div > button {
    opacity: 1;
  }
`

export const Tag = styled.div`
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
  margin-bottom: 0.1875rem;
  background-color: ${Theme.primary200Color};
  display: flex;
  align-items: center;
`

export const MessageWithLink = styled.div`
  a {
    color: ${Theme.primaryColor};
    text-decoration: none;
  }
`
