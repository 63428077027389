import React from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'

import { SideBarContentWrapper } from '@components/UI/Coachmark/Coachmark.styled'
import { Text, Tip } from '@tribegroup'
import { getMinimumBudgetMessage } from '@utils'

interface IProps extends InjectedIntlProps {
  minimum: number
  currency: string
}

interface IInternalProps extends IProps, InjectedIntlProps {}

export const BuilderSubmitCampaignPaymentTip: React.FC<IInternalProps> = ({
  intl,
  minimum,
  currency,
}) => (
  <SideBarContentWrapper>
    <Tip
      topOuterSpacing={1.25}
      text={
        <Text xsmall uppercase data-cy-element="invoice-option-tip">
          {getMinimumBudgetMessage(intl, minimum, currency)}
        </Text>
      }
      width={14}
    />
  </SideBarContentWrapper>
)

export default injectIntl(BuilderSubmitCampaignPaymentTip)
