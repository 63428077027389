import React from 'react'

import { Text } from '@tribegroup'
import TabsStyled, { TabContent, TabHeader, TabItem } from './Tabs.styled'

export interface ITab {
  title: string
  content: React.ReactNode
}

export interface ITabsProps {
  tabs: ReadonlyArray<ITab>
}

interface ITabsState {
  activeTabIndex: number
}
export default class Tabs extends React.PureComponent<ITabsProps, ITabsState> {
  state = {
    activeTabIndex: 0,
  }

  onTabClick = (event: Event) => {
    event.preventDefault()
    const tabSelected = event.currentTarget as HTMLElement
    const index = parseInt(tabSelected.getAttribute('data-index') || '0', 10)
    this.setState({
      activeTabIndex: index,
    })
  }

  render() {
    const tabs = this.props.tabs
    const activeTabIndex = this.state.activeTabIndex
    return (
      <TabsStyled>
        <TabHeader justifyAround alignCenter>
          {tabs.map((tab, index) => (
            <TabItem
              key={tab.title}
              href="/"
              data-index={index}
              onClick={this.onTabClick}
              active={index === activeTabIndex}
            >
              <Text medium={index === activeTabIndex}>{tab.title}</Text>
            </TabItem>
          ))}
        </TabHeader>
        <TabContent>{tabs[activeTabIndex] && tabs[activeTabIndex].content}</TabContent>
      </TabsStyled>
    )
  }
}
