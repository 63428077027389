import { call, put, select, takeLatest } from 'redux-saga/effects'

import { ToastTemplateTicked } from '@components/UI/ToastTemplate'
import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  connectCampaignSocialAccount,
  ConnectCampaignSocialAccountActionType,
  connectCampaignSocialAccountError,
  connectCampaignSocialAccountSuccess,
  fetchCampaignSuccess,
  ICampaign,
  selectConnectCampaignSocialAccountLink,
} from '@store/campaigns'
import { Toaster } from '@tribegroup'
import networkRequest from '@utils/networkRequest'

export function* handleConnectSocialAccount(
  action: ReturnType<typeof connectCampaignSocialAccount>,
) {
  const { campaignId, socialAccountId, handle, channel } = action.payload

  try {
    const authToken: string = yield select(selectAuthToken)
    const link: IHateoasLink = yield select(selectConnectCampaignSocialAccountLink, campaignId)

    const campaign: ICampaign = yield call(
      networkRequest,
      link.method,
      link.href.replace('{social_account_id}', `${socialAccountId}`),
      undefined,
      authToken,
    )

    yield put(connectCampaignSocialAccountSuccess())
    yield put(fetchCampaignSuccess(campaign))

    yield call(Toaster.createToast, ToastTemplateTicked, {
      i18nKey: `connectedAccounts.toast.${channel}.connectSuccess`,
      values: {
        handle: handle ?? '',
      },
    })
  } catch (error) {
    yield put(connectCampaignSocialAccountError(error))
  }
}

export function* watchConnectSocialAccountRequest() {
  yield takeLatest(ConnectCampaignSocialAccountActionType.REQUEST, handleConnectSocialAccount)
}
