import { createAction } from '@reduxjs/toolkit'

import { FeedbackDraftsActionTypes } from '../types'

export const removeFeedbackDraft = createAction(
  FeedbackDraftsActionTypes.REMOVE_FEEDBACK_DRAFT,
  (submissionId: number) => ({
    payload: { submissionId },
  }),
)
