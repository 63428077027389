import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { BuilderSteps } from '@enums'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import { selectFinalizeLink } from '@store/campaigns'
import { selectDraft } from '@store/drafts'
import { ButtonLinksWrapper } from './Actions.styled'
import {
  BuilderTitleBarActionsButtonClose,
  BuilderTitleBarActionsButtonContinue,
  BuilderTitleBarActionsButtonFinalize,
  BuilderTitleBarActionsButtonSaveDraft,
} from './Buttons'

interface IInternalProps extends ConnectedProps<typeof connector>, IBuilderValidationContextProps {}

const BuilderTitleBarDraftActions: React.FC<IInternalProps> = ({ hasFinalizelink, nextStep }) => {
  const hasNextStep = Boolean(nextStep)
  const { pathname } = useLocation()
  const showActionButtons = !pathname.startsWith(`/builder/${BuilderSteps.BRAND}`)
  return (
    <React.Fragment>
      {showActionButtons && (
        <ButtonLinksWrapper alignCenter>
          <BuilderTitleBarActionsButtonSaveDraft />
          {hasNextStep && <BuilderTitleBarActionsButtonContinue />}
          {hasFinalizelink && !hasNextStep && <BuilderTitleBarActionsButtonFinalize />}
        </ButtonLinksWrapper>
      )}
      <BuilderTitleBarActionsButtonClose />
    </React.Fragment>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  const { id: draftId } = selectDraft(state)
  return {
    hasFinalizelink: Boolean(selectFinalizeLink(state, draftId as number)),
  }
}

const connector = connect(mapStateToProps)
export default compose<IInternalProps, {}>(
  withBuilderValidation,
  connector,
)(BuilderTitleBarDraftActions)
