import React from 'react'

import { FormattedMessage } from 'react-intl'

import TribeOnly from '@components/UI/TribeOnly'
import { DEFAULT_TENANT, TRIBE_PRIVACY, TRIBE_TOC } from '@constants'
import Theme from '@theme'
import { Container, Link as TextLink, Text, Tip } from '@tribegroup'

const LinkWrapper = ({ href, cyElement, children }) => {
  if (process.env.APP_NAME !== DEFAULT_TENANT) {
    return children
  }
  return (
    <TextLink underlined target="_blank" href={href} data-cy-element={cyElement}>
      {children}
    </TextLink>
  )
}

const AuthSignupFormTerms: React.FC = () => (
  <Container flexStart alignItems="center">
    <Text color={Theme.grey800Color} small>
      <FormattedMessage
        id="auth.signup.form.termsConditions.label"
        values={{
          privacyPolicy: (
            <LinkWrapper href={TRIBE_PRIVACY} cyElement="privacy-policy-link">
              <FormattedMessage id="core.text.privacyPolicy" />
            </LinkWrapper>
          ),
          tAndCs: (
            <LinkWrapper href={TRIBE_TOC} cyElement="terms-and-conditions-link">
              <FormattedMessage id="auth.signup.form.termsConditions.label.terms" />
            </LinkWrapper>
          ),
        }}
      />
    </Text>
    <TribeOnly>
      <Container
        inline
        leftOuterSpacing={0.5}
        height={1}
        onClick={(event) => {
          event.preventDefault()
        }}
        role="button"
        data-cy-element="terms-tooltip"
      >
        <Tip
          inline
          center
          icon={{
            size: 1,
            glyph: 'information',
            padded: false,
          }}
          coachmark={{
            content: (
              <Text small color={Theme.grey900Color}>
                <FormattedMessage id="auth.signup.popover.consent" />
              </Text>
            ),
            position: 'top',
            width: 17.5,
          }}
        />
      </Container>
    </TribeOnly>
  </Container>
)

export default AuthSignupFormTerms
