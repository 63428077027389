import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import {
  selectLicenseOptionAmountBeforeTaxCents,
  selectLicenseOptionAmountBeforeTaxCurrency,
  selectLicenseOptionTaxDisplayName,
} from '@store/licenses'
import LicensingPriceDetails, { IPriceDetailsProps } from './PriceDetails'

interface ILicenseOptionPriceDetailsProps {
  mediaObjectId: number
  licenseIndex: number
}

interface IInternalProps extends ILicenseOptionPriceDetailsProps, IPriceDetailsProps {}

const LicenseOptionPriceDetails: React.FC<IInternalProps> = ({ ...props }) => (
  <LicensingPriceDetails {...props} />
)

const mapStateToProps = (state: IApplicationState, { mediaObjectId, licenseIndex }) => ({
  amountBeforeTaxCents: selectLicenseOptionAmountBeforeTaxCents(state, mediaObjectId, licenseIndex),
  amountBeforeTaxCurrency: selectLicenseOptionAmountBeforeTaxCurrency(
    state,
    mediaObjectId,
    licenseIndex,
  ),
  taxDisplayName: selectLicenseOptionTaxDisplayName(state, mediaObjectId, licenseIndex),
})

export default compose<IInternalProps, ILicenseOptionPriceDetailsProps>(connect(mapStateToProps))(
  LicenseOptionPriceDetails,
)
