import React, { useContext } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import AnalyticsPostCampaignReportEmpty from '@components/Analytics/PostCampaignReport/ListView/Empty/Empty'
import AnalyticsPostCampaignReportListViewInsights from '@components/Analytics/PostCampaignReport/ListView/Insights/Insights'
import AnalyticsPostCampaignReportListViewAmount from '@components/Analytics/PostCampaignReport/ListView/Item/Amount'
import {
  Entry,
  RowWrapper,
} from '@components/Analytics/PostCampaignReport/ListView/ListView.styled'
import { CampaignContext } from '@context/Campaign'
import { IApplicationState } from '@store'
import {
  selectShowStoryPerformance,
  selectStoryCPM,
  selectStoryFollowers,
  selectStoryImpressions,
  selectStoryPublishedDate,
  selectStoryReach,
  selectStoryReachPct,
  selectStorySpend,
} from '@store/postCampaignReport'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { abbreviateNumber } from '@utils'

interface IProps {
  submissionId: number
  backgroundColor: string
}

type IInternalProps = IProps & ConnectedProps<typeof connector>

const AnalyticsPostCampaignReportStoryListItem: React.FC<IInternalProps> = ({
  spend,
  followers,
  reach,
  reachPct,
  impressions,
  cpm,
  backgroundColor,
  showMetrics,
  publishedDate,
}) => {
  const {
    campaign: { is_price_hidden: isPriceHidden },
  } = useContext(CampaignContext)

  if (!showMetrics) {
    return (
      <Container data-testid="story-purchase-empty">
        <AnalyticsPostCampaignReportEmpty
          minWidth={isPriceHidden ? 34.5 : 38.125}
          backgroundColor={backgroundColor}
        />
      </Container>
    )
  }

  return (
    <Container inline relative height={4.75}>
      <Flex>
        <RowWrapper data-testid="story-published-date" backgroundColor={backgroundColor}>
          <Entry>
            <Text color={Theme.grey900Color}>{publishedDate}</Text>
          </Entry>
        </RowWrapper>
        {!isPriceHidden && (
          <AnalyticsPostCampaignReportListViewAmount
            backgroundColor={backgroundColor}
            testId="story-purchase-amount"
            pattern="!###,### "
            value={spend}
          />
        )}
        <RowWrapper data-testid="story-followers" backgroundColor={backgroundColor}>
          <Entry>
            <Text color={Theme.grey900Color}>{abbreviateNumber(followers)}</Text>
          </Entry>
        </RowWrapper>
        <AnalyticsPostCampaignReportListViewInsights
          testIdPrefix="story"
          backgroundColor={backgroundColor}
          reach={reach}
          reachPct={reachPct}
          impressions={impressions}
          cpm={cpm}
          isPriceHidden={isPriceHidden}
        />
      </Flex>
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { submissionId }: IProps) => {
  return {
    publishedDate: selectStoryPublishedDate(state, submissionId),
    spend: selectStorySpend(state, submissionId),
    followers: selectStoryFollowers(state, submissionId),
    reach: selectStoryReach(state, submissionId),
    reachPct: selectStoryReachPct(state, submissionId),
    impressions: selectStoryImpressions(state, submissionId),
    cpm: selectStoryCPM(state, submissionId),
    showMetrics: selectShowStoryPerformance(state, submissionId),
  }
}

const connector = connect(mapStateToProps)
export default compose<IInternalProps, IProps>(connector)(AnalyticsPostCampaignReportStoryListItem)
