import { IMeta } from '@store'
import {
  IRequestLicenseActionRequestPayload,
  ITransactedLicense,
  RequestLicenseActionTypes,
} from '@store/mediaObjects'

export const requestLicense = (
  mediaObjectId: number,
  licenseIndex: number,
  campaignId?: number,
  submissionId?: number,
  meta?: IMeta,
  requestPayload?: IRequestLicenseActionRequestPayload,
) => ({
  payload: { mediaObjectId, licenseIndex, campaignId, submissionId, requestPayload },
  type: RequestLicenseActionTypes.REQUEST_LICENSE,
  meta,
})

export const requestLicenseSuccess = (mediaObjectId, transactedLicense: ITransactedLicense) => ({
  payload: { mediaObjectId, transactedLicense },
  type: RequestLicenseActionTypes.REQUEST_LICENSE_SUCCESS,
})

export const requestLicenseError = (error: any) => ({
  payload: error,
  type: RequestLicenseActionTypes.REQUEST_LICENSE_ERROR,
})
