import { useSelector } from 'react-redux'

import { IApplicationState } from '@store'
import { selectCreateCampaignLink } from '@store/campaigns'

const useCampaignsLinks = () => {
  const links = useSelector((state: IApplicationState) => ({
    createCampaignLink: selectCreateCampaignLink(state),
  }))

  return links
}

export { useCampaignsLinks }
