import { FormattedHTMLMessage } from 'react-intl'
import { call, put, select, take, takeEvery } from 'redux-saga/effects'

import { unleashClient } from '@context/FeatureToggle'
import { BrandFanFilters, FeatureToggles } from '@enums'
import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchInfluencerIdentity,
  FetchInfluencerIdentityActionTypes,
  refreshBrandFanGroupMembers,
  refreshInfluencerIdentitiesSelf,
  removeBrandFanGroupMember,
  RemoveBrandFanGroupMemberActionTypes,
  removeBrandFanGroupMemberError,
  removeBrandFanGroupMemberSuccess,
  selectBrandFanGroupName,
  selectBrandFanMemberId,
  selectRemoveBrandFanMemberLink,
} from '@store/brandFans'
import { removeBrandFanMember } from '@store/brandFans/actions/removeBrandFanMember'
import { fetchSubmission, SubmissionActionTypes } from '@store/submissions'
import { Toaster } from '@tribegroup'
import { networkRequest } from '@utils'

export function* handleRemoveBrandFanGroupMember(
  action: ReturnType<typeof removeBrandFanGroupMember>,
) {
  const { identityId, groupId, submissionId, campaignId, influencerId } = action.payload
  try {
    const authToken: string = yield select(selectAuthToken)
    const removeBrandFanGroupMemberLink: IHateoasLink = yield select(
      selectRemoveBrandFanMemberLink,
      groupId,
    )
    const useBrandFanMemberId = unleashClient.isEnabled(
      FeatureToggles.BRAND_FANS_FROM_CONTENT_CAMPAIGNS_ENABLED,
    )

    if (useBrandFanMemberId) {
      const brandFanMemberId = yield select(
        selectBrandFanMemberId,
        groupId,
        identityId,
        influencerId,
      )
      yield call(
        networkRequest,
        removeBrandFanGroupMemberLink.method,
        removeBrandFanGroupMemberLink.href.replace(
          '{brand_fan_member_id}',
          String(brandFanMemberId),
        ),
        undefined,
        authToken,
      )
      yield put(removeBrandFanMember(groupId, brandFanMemberId))
    } else {
      yield call(
        networkRequest,
        removeBrandFanGroupMemberLink.method,
        removeBrandFanGroupMemberLink.href.replace('{identity_id}', String(identityId)),
        undefined,
        authToken,
      )
    }

    if (submissionId && campaignId) {
      yield put(fetchSubmission(submissionId, campaignId))
      yield take(SubmissionActionTypes.FETCH_SUCCESS)
    } else {
      yield put(refreshBrandFanGroupMembers(groupId))
      yield put(refreshInfluencerIdentitiesSelf(BrandFanFilters.BrandFans))
      yield put(fetchInfluencerIdentity(identityId))

      yield take(FetchInfluencerIdentityActionTypes.FETCH_SUCCESS)
    }

    yield put(removeBrandFanGroupMemberSuccess(identityId, groupId))

    const brandFanGroupName = yield select(selectBrandFanGroupName, groupId)
    yield call(Toaster.createToast, FormattedHTMLMessage, {
      id: 'brandFans.identityGroups.removeFromGroup.success',
      values: { brandFanGroupName },
    })
  } catch (error) {
    yield put(removeBrandFanGroupMemberError(groupId, error))
  }
}

export function* watchRemoveBrandFanGroupMemberRequest() {
  yield takeEvery(
    RemoveBrandFanGroupMemberActionTypes.REMOVE_MEMBER_REQUEST,
    handleRemoveBrandFanGroupMember,
  )
}
