import { SubmissionChannels } from '@enums'
import { IResponseError } from '@lib/error'
import { IHateoasLink } from '@store'

export interface IConsentedItems {
  brand_marketing_consent_default: boolean
  toc_checked_by_default: boolean
}

export enum RootResourceActionTypes {
  FETCH_REQUEST = 'rootResource/FETCH_REQUEST',
  FETCH_SUCCESS = 'rootResource/FETCH_SUCCESS',
  FETCH_ERROR = 'rootResource/FETCH_ERROR',
}

export enum FetchStatesActionTypes {
  FETCH_STATES_REQUEST = 'rootResource/FETCH_STATES_REQUEST',
  FETCH_STATES_SUCCESS = 'rootResource/FETCH_STATES_SUCCESS',
  FETCH_STATES_ERROR = 'rootResource/FETCH_STATES_ERROR',
}

export enum FindProductOption {
  OTHER = 'other',
  DISCOUNTED = 'discounted',
  SHIPPED = 'shipped',
  REIMBURSED = 'reimbursed',
}

export interface IOption {
  label: string | number
  value: string | number
}

export interface ITextWithTip {
  readonly text: string
  readonly tip?: string
}

export interface ICategorisedDeclineReasons {
  category: string
  reasons: ReadonlyArray<ITextWithTip>
  code?: string
}

export interface ICountry {
  country_code: string
  display_name: string
}

export interface IGender {
  code: string
  display_name: string
}

export interface IState {
  code: string
  display_name: string
}

export interface IDeclineReasons {
  readonly social_submission: ReadonlyArray<ICategorisedDeclineReasons>
  readonly content_submission: ReadonlyArray<ICategorisedDeclineReasons>
}

export interface IOperatingRegion {
  readonly currency_code: string
  readonly region_code: string
  readonly brief_activation_fee_cents: number
  readonly minimum_invoice_value_cents: number
  readonly referral_reward_price_cents?: number
  readonly tax_display_name: string
}

export interface ISubscriptionTier {
  readonly tier: string
  readonly display_text: string
  readonly features: ReadonlyArray<string>
}

export interface IAudienceLocation {
  code: string
  display: string
}

export interface ITenantFeature {
  key: string
  value: boolean
}

export interface IProductCategory {
  code: number
  display_value: string
}

export interface IRootResourceState {
  readonly consented_items: IConsentedItems
  readonly company_sizes: ReadonlyArray<string>
  readonly company_types: ReadonlyArray<Record<string, string>>
  readonly target_regions: ReadonlyArray<{ display_name: string; id: number }>
  readonly countries: ReadonlyArray<ICountry>
  readonly audience_age_range?: ReadonlyArray<string>
  readonly audience_locations?: ReadonlyArray<IAudienceLocation>
  readonly operating_regions: ReadonlyArray<IOperatingRegion>
  readonly states?: ReadonlyArray<IState>
  readonly decline_reasons: IDeclineReasons
  readonly subscription_tiers: ReadonlyArray<ISubscriptionTier>
  readonly isFetching: boolean
  readonly isFetched: boolean
  readonly links: ReadonlyArray<IHateoasLink>
  readonly errors?: IResponseError
  readonly provided_objectives?: ReadonlyArray<IProvidedCampaignObjective>
  readonly feedback_negotiation_keywords?: ReadonlyArray<string>
  readonly bca_duration_in_days_options?: ReadonlyArray<number>
  readonly tiktok_bca_duration_in_days_options?: number[]
  readonly social_submission_types?: ReadonlyArray<string>
  readonly preapproval_amount_percentage: number
  readonly genders: ReadonlyArray<IGender>
  readonly tenant_features: ReadonlyArray<ITenantFeature>
  readonly campaign_creation_reasons: ReadonlyArray<string>
  readonly estimated_campaign_creation_count: ReadonlyArray<string>
  readonly product_categories: ReadonlyArray<IProductCategory>
  readonly product_where_to_find_options?: {
    code: string
    display_name: string
    help_text: string
  }[]
  readonly channels: Record<
    Partial<SubmissionChannels>,
    {
      submission_types: string[]
    }
  >
}

export interface IProvidedCampaignObjective {
  text: string
  key: string
  value?: boolean
}
