import styled from 'styled-components'
import Flex from 'styled-flex-component'

import { FadeIn, FadeInPartial } from '@lib/effects'

export const MediaMultiframeFullModeClose = styled.div`
  position: absolute;
  background: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: zoom-in;
`

export const MediaMultiframeFullModeOpenOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  top: 0;
  left: 0;
  z-index: -1;
  animation: ${FadeInPartial} 0.3s ease-in forwards;
`

export const MediaMultiframeFullModeOpen = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 9;
`

export const MediaMultiframeFullModeContent = styled(Flex)`
  width: 45vh;
  max-width: 70vw;
  animation: ${FadeIn} 0.5s ease-in forwards;

  > div {
    width: 100%;
  }
`
