import { put, select, takeLatest } from 'redux-saga/effects'

import { selectCampaign } from '@store/campaigns'
import { syncDraft, SyncDraftActionTypes, syncDraftError, syncDraftSuccess } from '@store/drafts'

export function* handleSync(action: ReturnType<typeof syncDraft>) {
  try {
    const campaign = yield select(selectCampaign, action.payload)
    yield put(syncDraftSuccess(campaign))
  } catch (error) {
    yield put(syncDraftError(error))
  }
}

export function* watchSyncDraftRequest() {
  yield takeLatest(SyncDraftActionTypes.SYNC_DRAFT_REQUEST, handleSync)
}
