import React, { PropsWithChildren } from 'react'

type WarningMode = 'add' | 'remove'

export interface IBrandFanGroupMemberWarningContextProps {
  selectedGroupId?: number
  warningMode?: WarningMode
  showWarning: (groupId: number, mode: WarningMode) => void
  hideWarning: () => void
}

export const BrandFanGroupMemberWarningContext = React.createContext(
  {} as IBrandFanGroupMemberWarningContextProps,
)

const BrandFanGroupMemberWarningProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [selectedGroupId, setSelectedGroupId] = React.useState<number | undefined>()
  const [warningMode, setWarningMode] = React.useState<WarningMode | undefined>()

  const showWarning = (groupId: number, mode: WarningMode) => {
    setSelectedGroupId(groupId)
    setWarningMode(mode)
  }

  const hideWarning = () => {
    setSelectedGroupId(undefined)
    setWarningMode(undefined)
  }

  return (
    <BrandFanGroupMemberWarningContext.Provider
      value={{
        selectedGroupId,
        warningMode,
        showWarning,
        hideWarning,
      }}
    >
      {children}
    </BrandFanGroupMemberWarningContext.Provider>
  )
}

export { BrandFanGroupMemberWarningProvider }
export default BrandFanGroupMemberWarningProvider
