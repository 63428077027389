import { combineReducers } from 'redux'

import { campaignMetricsReducer } from './campaignMetrics'
import { postMetricsReducer } from './postsMetrics'
import { reelMetricsReducer } from './reelMetrics'
import { storyMetricsReducer } from './storyMetrics'
import { tiktokMetricsReducer } from './tiktokMetrics'
import { IPostCampaignReportState } from './types'
import { videoMetricsReducer } from './videoMetrics'

export const postCampaignReducer = combineReducers<IPostCampaignReportState>({
  campaignMetrics: campaignMetricsReducer,
  postMetrics: postMetricsReducer,
  videoMetrics: videoMetricsReducer,
  storyMetrics: storyMetricsReducer,
  tiktokMetrics: tiktokMetricsReducer,
  reelMetrics: reelMetricsReducer,
})
