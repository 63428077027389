import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { selectActivePCRChannel } from '@store/router'
import Theme from '@theme'
import { Container, H5 } from '@tribegroup'

export interface IInternalProps extends InjectedIntlProps {
  activeChannel: PCRChannels
}

const PostCampaignReportSubmissionListEmpty: React.FC<IInternalProps> = ({
  activeChannel,
  intl,
}) => {
  return (
    <Container topOuterSpacing={5}>
      <H5 center color={Theme.grey700Color}>
        <FormattedMessage
          id="postCampaignReport.submission.list.empty"
          values={{
            channel: intl.formatMessage({
              id: `postCampaignReport.text.${activeChannel}`,
            }),
          }}
        />
      </H5>
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    activeChannel: selectActivePCRChannel(state),
  }
}

export { PostCampaignReportSubmissionListEmpty }
export default compose(injectIntl, connect(mapStateToProps))(PostCampaignReportSubmissionListEmpty)
