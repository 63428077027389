import { Fragment, PropsWithChildren } from 'react'

import { DEFAULT_TENANT } from '@constants'

const TribeOnly: React.FC<PropsWithChildren> = ({ children }) => {
  if (process.env.APP_NAME !== DEFAULT_TENANT) {
    return null
  }
  return <Fragment>{children}</Fragment>
}

export default TribeOnly
