import React from 'react'

import { FormattedMessage } from 'react-intl'

import { NoticeUnsupportedScreen } from '@components/UI/Notice'
import { SupportedScreenWrapper, UnsupportedScreenWrapper } from '@components/UI/ScreenSize'

const BrandFansContentUnsupportedScreenHandler: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <React.Fragment>
    <UnsupportedScreenWrapper>
      <NoticeUnsupportedScreen
        subtitle={<FormattedMessage id="core.text.tryUsingDesktop" />}
        alt="brand-fans-unsupported-screen"
        title={<FormattedMessage id="brandFans.unsupportedScreen.title" />}
      />
    </UnsupportedScreenWrapper>
    <SupportedScreenWrapper>{children}</SupportedScreenWrapper>
  </React.Fragment>
)

export { BrandFansContentUnsupportedScreenHandler }
export default BrandFansContentUnsupportedScreenHandler
