import { FormattedMessage } from 'react-intl'

import DetailCard from '@components/UI/DetailCard'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

const AnalyticsPostPerformanceUnavailable = () => {
  return (
    <DetailCard
      title={<FormattedMessage id="submission.postPerformance.unavailable.title" />}
      color={Theme.grey800Color}
      content={
        <Container topOuterSpacing={0.375} bottomInnerSpacing={0.25}>
          <Text block>
            <FormattedMessage id={'submission.postPerformance.unavailable.notYetAvailable'} />
          </Text>
          <Text block>
            <FormattedMessage id={'submission.postPerformance.unavailable.checkBackIn24Hrs'} />
          </Text>
        </Container>
      }
      smallContentText
    />
  )
}
export { AnalyticsPostPerformanceUnavailable }
export default AnalyticsPostPerformanceUnavailable
