import { FormattedHTMLMessage } from 'react-intl'

import Loading from '@components/UI/Loading'
import Theme from '@theme'
import { Text } from '@tribegroup'
import { Caption, EmptyFile } from '@tribegroup/FileDrop/FileDrop.styled'

const MoodboardItemLoading: React.FC = () => {
  return (
    <EmptyFile borderWidth={1}>
      <Loading height={1.5} iconOnly />
      <Caption>
        <Text block small color={Theme.grey800Color}>
          <FormattedHTMLMessage id="builder.brief.moodboard.generatingPreview" />
        </Text>
      </Caption>
    </EmptyFile>
  )
}

export default MoodboardItemLoading
