import { IHateoasLink } from '@store'

export const downloadCSV = (link: IHateoasLink, filename, headers) => {
  fetch(link.href, {
    method: link.method,
    headers,
  })
    .then(response => response.text())
    .then(text => {
      const data = new Blob([text], { type: 'text/csv;charset=utf-8;' })
      const url = window.URL.createObjectURL(data)
      const anchor = document.createElement('a')
      anchor.setAttribute('href', url)
      anchor.setAttribute('download', filename)
      anchor.click()
    })
}

export const downloadZIP = (link: IHateoasLink, headers) => {
  return fetch(link.href, {
    method: link.method,
    headers,
  }).then(response => {
    const contentDispositionHeader = response.headers.get('Content-Disposition')
    const filename = contentDispositionHeader
      ? contentDispositionHeader.split('filename=')[1].replace(/"|;/g, '')
      : ''

    return response.arrayBuffer().then(data => {
      const blob = new Blob([data], { type: 'application/zip' })
      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.setAttribute('href', url)
      anchor.setAttribute('download', filename || '')
      anchor.click()
      window.URL.revokeObjectURL(url)
    })
  })
}
