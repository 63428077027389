import { useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { DECLINE_PROCESS_EDUCATION } from '@constants'
import { IModalComponentProps, IModalContextProps } from '@context/Modal'
import { EventTrackingNames } from '@enums'
import { withModal } from '@hocs'
import { IApplicationState } from '@store'
import { selectSubmissionTrackingProps, trackEvent } from '@store/analytics'
import { selectCampaign } from '@store/campaigns'
import { selectPreapprovedSubmissionDeclineReasons } from '@store/rootResource'
import { ISubmission } from '@store/submissions'
import Theme from '@theme'
import { Button, CloseIcon, Container, Header, Link, ModalTitle, Radio, Text } from '@tribegroup'
import Modal from '@tribegroup/Modal/Modal'
import SubmissionCardModalDeclinePreapprovedForm from './Form'

interface IProps extends IModalComponentProps {
  currFilter: string
  submission: ISubmission
  campaignId: number
  defaultSelectedDeclineCode?: string
}

interface IInternalProps extends IProps, IModalContextProps, ConnectedProps<typeof connector> {}

const DECLINE_CODE_MAP = {
  declined_inspite_of_best_efforts: 'flow1',
  not_delivered_as_expected: 'flow2',
}

const SubmissionCardModalDeclinePreapproved: React.FC<IInternalProps> = ({
  isOpen = false,
  onRequestClose,
  defaultSelectedDeclineCode,
  showModal,
  currFilter,
  submission,
  reasons,
  campaignId,
  submissionAnalyticsProperties,
  trackEvent: trackEventAction,
}) => {
  const [declineCode, setDeclineCode] = useState(defaultSelectedDeclineCode)
  const onContinue = () => {
    if (declineCode) {
      const modalProps = {
        currFilter,
        submission,
        campaignId,
        selectedReasonCode: declineCode,
      }
      trackEventAction(EventTrackingNames.PreApproveDeclineStep2, {
        ...submissionAnalyticsProperties,
        decline_type: DECLINE_CODE_MAP[declineCode],
      })
      showModal(SubmissionCardModalDeclinePreapprovedForm, modalProps)
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setDeclineCode(event.target.value)

  return (
    <Modal reactModalProps={{ isOpen }} width={53} complex>
      <ModalTitle bottomOuterSpacing={0.5}>
        <FormattedMessage id="submission.declinePreapproved.form1.title.line1" />
      </ModalTitle>
      <ModalTitle bottomOuterSpacing={1.75}>
        <FormattedMessage id="submission.declinePreapproved.form1.title.line2" />
      </ModalTitle>
      <CloseIcon onClick={onRequestClose} />
      <div>
        {reasons.map(({ code }) => (
          <Container key={code} bottomOuterSpacing={0.5}>
            <Radio
              data-testid="decline-code-radio"
              showSelectedAsCheck
              name="declineCode"
              scheme="primary"
              value={code}
              checked={declineCode === code}
              onChange={onChange}
              label={
                <Text>
                  <FormattedMessage id={`submission.declinePreapproved.reason.${code}`} />
                </Text>
              }
            />
          </Container>
        ))}

        <Container topOuterSpacing={1.75}>
          <ConnectedTrackedRoute
            campaignId={campaignId}
            submissionId={submission.id}
            eventName={EventTrackingNames.PreApproveDeclineLearnMore}
          >
            <Link href={DECLINE_PROCESS_EDUCATION} target="_blank" color={Theme.secondaryColor}>
              <Text small color={Theme.primaryColor}>
                <FormattedMessage id="submission.declinePreapproved.education.link" />
              </Text>
            </Link>
          </ConnectedTrackedRoute>
        </Container>

        <Container topOuterSpacing={4.25}>
          <Flex alignCenter justifyBetween wrap="wrap">
            <Header
              small
              color={Theme.grey700Color}
              uppercase
              bottomOuterSpacing={1}
              topOuterSpacing={1}
            >
              <FormattedMessage id="core.text.stepXofY" values={{ current: 1, total: 2 }} />
            </Header>
            <Flex justifyEnd>
              <Button
                scheme="primary"
                onClick={onRequestClose}
                outlined
                rightOuterSpacing={1}
                width={10.125}
              >
                <Header cta>
                  <FormattedMessage id="core.text.cancel" />
                </Header>
              </Button>
              <Button scheme="primary" disabled={!declineCode} onClick={onContinue} width={10.125}>
                <Header cta>
                  <FormattedMessage id="core.text.continue" />
                </Header>
              </Button>
            </Flex>
          </Flex>
        </Container>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state: IApplicationState, { campaignId, submission }: IProps) => {
  const { supported_submission_types } = selectCampaign(state, campaignId) || {}
  return {
    reasons: selectPreapprovedSubmissionDeclineReasons(state, supported_submission_types[0]),
    submissionAnalyticsProperties: selectSubmissionTrackingProps(state, campaignId, submission.id),
  }
}

const mapDispatchToProps = {
  trackEvent,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<IInternalProps, IProps>(
  connector,
  withModal,
)(SubmissionCardModalDeclinePreapproved)
