import { PropsWithChildren, PureComponent } from 'react'

import styled, { css } from 'styled-components'

import { getColor, themed } from '../Theme'

export interface IFormValidationMessageProps {
  /**
   * Sets the color for the form validation message
   * Default value depends on the theme applied (defaultTheme when no ThemeProvider is used)
   *
   * @default <theme.errorMessage>
   */
  color?: string

  /**
   * Sets outer margin top in rem
   *
   * @default 0
   */
  outerTopSpacing?: number

  /**
   * Sets outer margin bottom in rem
   *
   * @default 0.4375
   */
  outerBottomSpacing?: number
}

const FormValidationMessageWrapper = themed(styled<IFormValidationMessageProps, any>('div')`
  ${({
    theme,
    color = getColor(theme, 'error'),
    outerTopSpacing = 0.25,
    outerBottomSpacing = 0,
  }) => css`
    color: ${color};
    margin-top: ${outerTopSpacing}rem;
    margin-bottom: ${outerBottomSpacing}rem;

    > span {
      color: ${color};
    }
  `};
`)

class FormValidationMessage extends PureComponent<PropsWithChildren<IFormValidationMessageProps>> {
  render() {
    return <FormValidationMessageWrapper className="formgroup-validation-message" {...this.props} />
  }
}

export default FormValidationMessage
