import { InjectedIntlProps } from 'react-intl'

import { TabLineItem, TabText } from '@components/UI/Tabs/Tabs.styled'
import { ITextWithTip } from '@store/rootResource'
import Theme from '@theme'
import { Icon, Text } from '@tribegroup'

interface IProps extends InjectedIntlProps {
  reasons: ReadonlyArray<ITextWithTip>
  firstName?: string
  onReasonClick: (event: React.SyntheticEvent) => void
}

const SubmissionCardModalDeclineReasonsLineItem: React.FC<IProps> = ({
  reasons,
  firstName,
  onReasonClick,
  intl,
}) => (
  <ul>
    {reasons.map((reason) => (
      <TabLineItem
        key={reason.text}
        data-text={reason.text.split('{firstName}').join(
          firstName ||
            intl.formatMessage({
              id: 'submission.decline.form.firstname.placeholder',
            }),
        )}
        onClick={onReasonClick}
        data-show-tip={reason.tip}
      >
        <Icon glyph="circle-plus-heavy" size={1.25} color={Theme.grey900Color} />
        <TabText>
          <Text color={Theme.grey800Color}>
            {reason.text.split('{firstName}').join(
              firstName ||
                intl.formatMessage({
                  id: 'submission.decline.form.firstname.placeholder',
                }),
            )}
          </Text>
        </TabText>
      </TabLineItem>
    ))}
  </ul>
)

export default SubmissionCardModalDeclineReasonsLineItem
