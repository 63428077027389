import { IPromoteAsAdParams, ISubmission, PromoteAsAdActionTypes } from '@store/submissions'

export const promoteAsAd = (
  campaignId: number,
  submissionId: number,
  promoteAsAdParams: IPromoteAsAdParams,
) => ({
  payload: { campaignId, submissionId, promoteAsAdParams },
  type: PromoteAsAdActionTypes.PROMOTE_AS_AD_REQUEST,
})

export const promoteAsAdSuccess = (submission: ISubmission) => {
  return { payload: submission, type: PromoteAsAdActionTypes.PROMOTE_AS_AD_SUCCESS }
}

export const promoteAsAdError = (error: any) => ({
  payload: error,
  type: PromoteAsAdActionTypes.PROMOTE_AS_AD_ERROR,
})
