import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import { Container } from '@tribegroup'

const Root = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  flex-direction: column;
  ${breakpoint('phoneUpperBoundary')`
    flex-direction: row;
  `};
`
export { Root }
