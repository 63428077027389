import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { IModalContextProps } from '@context/Modal'
import { withBuilderValidation, withDraft, withModal, withScrollToTop } from '@hocs'
import { fetchCampaigns } from '@store/campaigns'
import { getRecentBuilderStep } from '@utils'
import { isSocialCampaign } from '@utils/campaign'
import BuilderDialogUploadInProgress from '../Dialog/UploadInProgress'
import BuilderLayout from '../Layout/Layout'
import BuilderBriefChannels from './Channels/Channels'
import BuilderBriefContent from './Content/Content'
import BuilderBriefMoodboard from './Moodboard/Moodboard'

interface IInternalProps
  extends IDraftContextProps,
    IBuilderValidationContextProps,
    IModalContextProps,
    ConnectedProps<typeof connector> {}

const BuilderBriefForm: React.FC<IInternalProps> = ({
  setContinueCallback,
  draft: builderDraft,
  fetchCampaigns: fetchCampaignsAction,
  showModal,
}) => {
  const onContinue = ({
    nextStep,
    draft: draftOnContinue,
    currentStep,
    history,
    saveCampaign,
    isAnyUploading,
  }) => {
    if (isAnyUploading) {
      return showModal(BuilderDialogUploadInProgress)
    }

    saveCampaign(getRecentBuilderStep(currentStep, draftOnContinue.step_completed), {
      history,
      redirect: `/builder/${nextStep}/{draft_id}${history.location.search}`,
    })
  }

  React.useEffect(() => {
    fetchCampaignsAction()
    setContinueCallback(onContinue)
  }, [])

  const isSocial = isSocialCampaign(builderDraft)
  return (
    <BuilderLayout>
      <BuilderBriefMoodboard />
      <BuilderBriefContent />
      {isSocial && <BuilderBriefChannels />}
    </BuilderLayout>
  )
}

const mapDispatchToProps = {
  fetchCampaigns,
}

const connector = connect(undefined, mapDispatchToProps)

export { BuilderBriefForm }
export default compose(
  connector,
  withDraft,
  withBuilderValidation,
  withScrollToTop,
  withModal,
)(BuilderBriefForm)
