import React from 'react'

import { FormattedMessage } from 'react-intl'

import Price from '@components/UI/Price/Price'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

export interface IPriceDetailsProps {
  amountBeforeTaxCents: number
  amountBeforeTaxCurrency: string
  taxDisplayName: string
}

const LicensingPriceDetails: React.FC<IPriceDetailsProps> = ({
  amountBeforeTaxCents,
  amountBeforeTaxCurrency,
  taxDisplayName,
}) => {
  return (
    <Container
      flexBetween
      alignItems="flex-start"
      fullWidth
      borderColor={Theme.grey400Color}
      topOuterSpacing={0.375}
      borderWidthTop={0.0625}
      topInnerSpacing={0.5}
    >
      <Text color={Theme.grey900Color} textAlign="left" block medium>
        <FormattedMessage
          id="core.text.totalCurrency"
          values={{ currency: amountBeforeTaxCurrency }}
        />
      </Text>
      <Container flexEnd noShrink>
        <Container rightOuterSpacing={0.25}>
          <Text color={Theme.grey900Color} medium>
            <Price quantity={amountBeforeTaxCents} currency={amountBeforeTaxCurrency} />
          </Text>
        </Container>
        <Text color={Theme.grey900Color}>
          (
          <FormattedMessage
            id="submission.fullView.pricePanel.tax.text"
            values={{ taxName: taxDisplayName }}
          />
          )
        </Text>
      </Container>
    </Container>
  )
}

export default LicensingPriceDetails
