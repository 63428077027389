import {
  IBrandFanEditableFields,
  IBrandFanGroup,
  UpdateBrandFanGroupActionTypes,
} from '@store/brandFans'

export const updateBrandFanGroup = (groupId: number, params: Partial<IBrandFanEditableFields>) => ({
  payload: { groupId, params },
  type: UpdateBrandFanGroupActionTypes.UPDATE_REQUEST,
})

export const updateBrandFanGroupSuccess = (brandFanGroup: IBrandFanGroup) => ({
  payload: brandFanGroup,
  type: UpdateBrandFanGroupActionTypes.UPDATE_SUCCESS,
})

export const updateBrandFanGroupError = (error: any) => ({
  payload: error,
  type: UpdateBrandFanGroupActionTypes.UPDATE_ERROR,
})
