import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IResponseError } from '@lib/error'
import { IApplicationState } from '@store'

interface IProps {
  Component: React.ComponentType | React.FC
  ErrorPageComponent: React.ComponentType | React.FC
  pageProps: any
}

interface IInternalProps extends IProps {
  hasError: boolean
}

export const ErrorPage: React.FC<IInternalProps> = ({
  hasError,
  Component,
  ErrorPageComponent,
  pageProps,
}) => {
  if (hasError) {
    return <ErrorPageComponent />
  }

  return <Component {...pageProps} />
}

interface IConnected404Props {
  component: React.FC | React.ComponentType
  selector: (state: IApplicationState) => IResponseError | undefined
}

export const withConnected404Page = ({
  component: errorComponent,
  selector,
}: IConnected404Props) => (Component) => (props) => {
  const mapStateToProps = (state: IApplicationState) => ({
    hasError: selector(state),
  })
  const ConnectedErrorPage = compose<IInternalProps, IProps>(connect(mapStateToProps))(ErrorPage)
  return (
    <ConnectedErrorPage
      Component={Component}
      ErrorPageComponent={errorComponent}
      pageProps={props}
    />
  )
}

export default withConnected404Page
