import { IMeta } from '@store'
import { ISubmission, ShortlistActionTypes } from '@store/submissions'

export const unshortlistSubmissionRequest = (
  campaignId: number,
  submissionId: number,
  { filter, sortBy, refreshSubmissions, event }: IMeta = {},
) => ({
  payload: { campaignId, submissionId },
  type: ShortlistActionTypes.UNSHORTLIST_REQUEST,
  meta: { filter, sortBy, refreshSubmissions, event } as IMeta,
})

export const unshortlistSubmissionSuccess = (
  campaignId: number,
  submission: ISubmission,
  { event }: IMeta,
) => ({
  payload: { submission, campaignId },
  type: ShortlistActionTypes.UNSHORTLIST_SUCCESS,
  meta: { event },
})

export const unshortlistSubmissionError = (error: any) => ({
  payload: error,
  type: ShortlistActionTypes.UNSHORTLIST_ERROR,
})
