import { FC, PropsWithChildren, useEffect, useState } from 'react'

import Breakpoint, { BreakpointsPropType } from '@components/UI/Breakpoint/Breakpoint.styled'
import { defaultTheme } from '../Theme'
import { H5, Text } from '../Typography'
import {
  DRAWER_ANIMATION_DURATION_MS,
  DrawerContent,
  DrawerGrayWrapper,
  DrawerHeader,
  DrawerHeaderContent,
  DrawerHeaderDivider,
  DrawerHeaderWrapper,
  DrawerStyled,
  DrawerWrapperStyled,
} from './Drawer.styled'

interface IDrawerProps {
  id?: string
  zIndex?: number
  desktopXOffset?: number
  open?: boolean
  title?: React.ReactNode
  headerLeftIcon?: React.ReactNode
  headerRightIcon?: React.ReactNode
  showHeaderDivider?: boolean
  anchor?: 'left' | 'right'
  wrapperCallback?: (event: Event) => void
  breakpoints?: BreakpointsPropType
}

const Drawer: FC<PropsWithChildren<IDrawerProps>> = ({
  id = 'drawer',
  title,
  headerLeftIcon,
  headerRightIcon,
  open,
  showHeaderDivider,
  wrapperCallback,
  children,
  anchor,
  desktopXOffset,
  zIndex = 10,
  breakpoints,
}) => {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    if (open) {
      setShouldRender(Boolean(open))
    } else {
      setTimeout(() => {
        setShouldRender(Boolean(open))
      }, DRAWER_ANIMATION_DURATION_MS)
    }
  }, [open])

  if (!shouldRender) {
    return null
  }

  return (
    <DrawerWrapperStyled
      open={open}
      desktopXOffset={desktopXOffset}
      anchor={anchor}
      zIndex={zIndex}
    >
      <DrawerGrayWrapper data-cy-element={`${id}-wrapper`} open={open} onClick={wrapperCallback} />
      <Breakpoint {...breakpoints}>
        <DrawerStyled data-cy-element={id} open={open} anchor={anchor}>
          {title && (
            <DrawerHeader>
              <DrawerHeaderWrapper justifyBetween alignCenter>
                <DrawerHeaderContent left basis="2.5rem">
                  <Text>{headerLeftIcon}</Text>
                </DrawerHeaderContent>
                <DrawerHeaderContent>
                  <H5 color={defaultTheme.grey900Color}>{title}</H5>
                </DrawerHeaderContent>
                <DrawerHeaderContent right basis="2.5rem">
                  {headerRightIcon}
                </DrawerHeaderContent>
              </DrawerHeaderWrapper>
              {showHeaderDivider && <DrawerHeaderDivider />}
            </DrawerHeader>
          )}
          <DrawerContent>{children}</DrawerContent>
        </DrawerStyled>
      </Breakpoint>
    </DrawerWrapperStyled>
  )
}

export { IDrawerProps }
export default Drawer
