import { ResponseError } from '@lib/error'

export const mediaUpload = (method: string, url: string, file: Blob, token: string) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  }

  const formData = new FormData()
  formData.append('file', file)

  return fetch(url, {
    method,
    headers,
    body: formData,
  })
    .then(async (response: Response) => {
      if (!response.ok) {
        throw response
      }
      return response.json()
    })
    .catch(async (response: any) => {
      const json = await response.json()
      throw new ResponseError(json, response.status)
    })
}

export const presignedURLMediaUpload = (method: string, url: string, file: Blob) => {
  const headers = {
    'content-type': file.type,
  }

  return fetch(url, {
    method,
    headers,
    body: file,
  }).then((response: Response) => {
    return response.ok
  })
}

export default mediaUpload
