import React from 'react'

import { compose } from 'recompose'

import ActionIcon from '@components/UI/ActionIcon'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IModalContextProps } from '@context/Modal'
import { EventTrackingNames } from '@enums'
import { withModal } from '@hocs'
import { useTenantEnabledFeatures } from '@hooks/useTenantEnabledFeatures'
import Theme from '@theme'
import { ActionIconWrapper } from '../TitleBar/TitleBar.styled'
import InboxReferral from './Referral'

interface IProps {
  campaignId: number
}

interface IInternalProps extends IProps, IModalContextProps {}

const InboxReferralTrigger: React.FC<IInternalProps> = ({ showModal, campaignId }) => {
  const { isEnabledTenantFeature } = useTenantEnabledFeatures()
  if (!isEnabledTenantFeature('referral_program')) {
    return null
  }

  const onOpenModal = () => showModal(InboxReferral, { campaignId })
  return (
    <ActionIconWrapper>
      <ConnectedTrackedRoute
        eventName={EventTrackingNames.ToolbarBrandReferral}
        campaignId={campaignId}
      >
        <ActionIcon
          data-testid="referral-trigger"
          glyph="gift"
          color={Theme.grey900Color}
          size={1.5}
          onClick={onOpenModal}
        />
      </ConnectedTrackedRoute>
    </ActionIconWrapper>
  )
}

export default compose<IInternalProps, IProps>(withModal)(InboxReferralTrigger)
