import { PropsWithChildren } from 'react'

import styled from 'styled-components'

import { ILineClampProps } from './LineClamp'

/* stylelint-disable */
const LineClampRoot: React.FC<PropsWithChildren<ILineClampProps>> = styled.div`
  display: box;
  -webkit-line-clamp: ${({ lines }) => lines};
  -webkit-box-orient: vertical;
  height: ${({ fallbackHeight }) => fallbackHeight}rem;
  overflow: hidden;
`
/* stylelint-enable */

export { LineClampRoot }
