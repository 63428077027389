import { Fragment } from 'react'

import { Radio, SelectionSubtext } from '@tribegroup'

const SubmissionCardModalLicenseSelected = ({ label, description }) => (
  <Fragment>
    <Radio
      disablePointerOnHover
      showSelectedAsCheck
      scheme="primary"
      label={label}
      labelCapitalize
      defaultChecked
    />
    <SelectionSubtext>{description}</SelectionSubtext>
  </Fragment>
)

export default SubmissionCardModalLicenseSelected
