import React from 'react'

import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { TRIBE_CREATORS } from '@constants'
import Theme from '@theme'
import { Link as TextLink, Text } from '@tribegroup'

interface IProps {
  showCreatorLink: boolean
}

const AuthSignupFormSubTitle = ({ showCreatorLink }: IProps) => (
  <React.Fragment>
    <Text color={Theme.grey700Color}>
      <FormattedMessage id="auth.signup.link.accountExists" />
      &nbsp;
      <Link color={Theme.grey800Color} to="/signIn" data-cy-element="sign-in-link">
        <FormattedMessage id="core.text.logIn" />
      </Link>
    </Text>
    {showCreatorLink && (
      <Text color={Theme.grey700Color}>
        <FormattedMessage id="auth.signup.link.isCreator" />
        &nbsp;
        <TextLink underlined target="_blank" href={TRIBE_CREATORS} data-cy-element="creators-link">
          <FormattedMessage id="core.text.clickHere" />
        </TextLink>
      </Text>
    )}
  </React.Fragment>
)

export default AuthSignupFormSubTitle
