import { useContext } from 'react'

import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Flex from 'styled-flex-component'

import RouterLink from '@components/UI/RouterLink/RouterLink.styled'
import { CampaignContext } from '@context/Campaign'
import { MemberRoleTypes, PageRoutes } from '@enums'
import { selectBrandManagerIdentityLink } from '@store/brandManagerIdentities'
import { IConnectedSocialAccount, selectIsConnectingCampaignIdentity } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'
import ConnectedAccountsDrawerContentFilledIdentitySwitch from '../../Content/Filled/Identity/Switch'

interface IProps {
  brandManagerIdentity?: IConnectedSocialAccount
  isActive?: boolean
  onClick?: VoidFunction
}

const ConnectedAccountsDrawerIdentityInstagramIcon = ({
  brandManagerIdentity,
  isActive,
  onClick,
}: IProps) => {
  const {
    campaign: { role },
  } = useContext(CampaignContext)
  const location = useLocation()

  const fromRoute = `?from=${encodeURIComponent(`${location.pathname}${location.search}`)}`
  const isConnectingCampaignIdentity = useSelector(selectIsConnectingCampaignIdentity)

  const { hasReconnectLink } = useSelector((state: IApplicationState) => {
    return {
      hasReconnectLink: Boolean(
        selectBrandManagerIdentityLink(state, brandManagerIdentity?.id, 'reconnect_identity'),
      ),
    }
  })

  if (hasReconnectLink && role === MemberRoleTypes.Owner) {
    return (
      <RouterLink to={`/${PageRoutes.ConnectedAccounts}${fromRoute}`}>
        <Container pointer data-testid="identity-reconnect-required" width={2.5}>
          <Flex alignCenter justifyBetween>
            <Container leftInnerSpacing={0.25}>
              <Icon glyph="alert-solid" size={1} color={Theme.errorColor} verticalAlign="middle" />
            </Container>
            <Icon
              glyph="chevron-right"
              size={1}
              color={Theme.defaultColor}
              verticalAlign="middle"
            />
          </Flex>
        </Container>
      </RouterLink>
    )
  }

  if ([MemberRoleTypes.Collaborator, MemberRoleTypes.SolutionCollaborator].includes(role)) {
    if (brandManagerIdentity?.reconnect_required) {
      return (
        <Container pointer data-testid="identity-reconnect-required" width={2.5}>
          <Flex alignCenter justifyBetween>
            <Container leftInnerSpacing={0.25}>
              <Icon glyph="alert-solid" size={1} color={Theme.errorColor} verticalAlign="middle" />
            </Container>
          </Flex>
        </Container>
      )
    }

    return (
      <Container pointer width={2.5}>
        <Flex alignCenter justifyBetween>
          <Container leftInnerSpacing={0.25}>
            <Icon glyph="tick" size={1.5} color={Theme.primaryColor} verticalAlign="middle" />
          </Container>
        </Flex>
      </Container>
    )
  }

  return (
    <ConnectedAccountsDrawerContentFilledIdentitySwitch
      defaulValue={isActive}
      disabled={isConnectingCampaignIdentity}
      onClick={onClick}
    />
  )
}

export { ConnectedAccountsDrawerIdentityInstagramIcon }
