import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { ActionBarLeft, ActionBarRight, ActionBarWrapper } from '@components/UI/Card/Card.styled'
import { ISelectedSubmissionsContextProps } from '@context/SelectedSubmissions'
import { withSelectedSubmissions } from '@hocs'
import { IApplicationState } from '@store'
import { selectShortlistLink, selectUnshortlistLink } from '@store/submissions'
import ActionBarIconSelection from '../Icon/Selection/Selection'
import ActionBarIconShortlist from '../Icon/Shortlist/Shortlist'
import ActionBarIconUnshortlist from '../Icon/Unshortlist/Unshortlist'

interface IProps {
  submissionId: number
}

interface IInternalProps
  extends IProps,
    ISelectedSubmissionsContextProps,
    ConnectedProps<typeof connector> {}

const SubmissionCardActionBarSelectionMode: React.FC<IInternalProps> = ({
  submissionId,
  deselectSubmission,
  selectSubmission,
  hasShortlistLink,
  hasUnshortlistLink,
  selectedSubmissionIds,
}) => {
  const selected = selectedSubmissionIds.includes(submissionId)

  const toggleSelection = () => {
    if (selected) {
      deselectSubmission(submissionId)
    } else {
      selectSubmission(submissionId)
    }
  }

  return (
    <ActionBarWrapper justifyBetween>
      <ActionBarLeft>
        {hasShortlistLink && <ActionBarIconShortlist submissionId={submissionId} />}
        {hasUnshortlistLink && <ActionBarIconUnshortlist submissionId={submissionId} />}
      </ActionBarLeft>
      <ActionBarRight>
        <ActionBarIconSelection selected={selected} onToggle={toggleSelection} />
      </ActionBarRight>
    </ActionBarWrapper>
  )
}

const mapStateToProps = (state: IApplicationState, { submissionId }: IProps) => ({
  hasShortlistLink: Boolean(selectShortlistLink(state, submissionId)),
  hasUnshortlistLink: Boolean(selectUnshortlistLink(state, submissionId)),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(
  withSelectedSubmissions,
  connector,
)(SubmissionCardActionBarSelectionMode)
