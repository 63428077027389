import React from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'

import Image from '@components/UI/Image'
import { AspectRatios } from '@enums'
import avatarPlaceholderImage from '@images/avatar-placeholder@3x.png'
import storyHaloImage from '@images/story-halo@3x.png'
import Theme from '@theme'
import { Container } from '@tribegroup'
import { AvatarImage, StoryHalo } from './Avatar.styled'
import AvatarSocialLink from './SocialLink'

interface IAvatarProps {
  size: number
  avatarSrc?: string
  socialUrl?: string
  username?: string
  onClick?: (event: React.SyntheticEvent<HTMLSpanElement>) => void
  isStory?: boolean
  borderWidth?: number
  borderColor?: string
  placeholderImage?: any
}

interface IInternalProps extends InjectedIntlProps, IAvatarProps {}

export const Avatar: React.FC<IInternalProps> = ({
  avatarSrc,
  socialUrl,
  size,
  username,
  intl,
  onClick,
  isStory,
  borderWidth,
  borderColor,
  placeholderImage = avatarPlaceholderImage,
}) => {
  const [src, setSrc] = React.useState(avatarSrc)
  const handleError = () => {
    setSrc(placeholderImage)
  }
  React.useEffect(() => {
    if (avatarSrc) {
      setSrc(avatarSrc)
    } else {
      setSrc(placeholderImage)
    }
  }, [avatarSrc])
  return (
    <AvatarSocialLink socialUrl={socialUrl} onClick={onClick}>
      <Container relative>
        {isStory && <StoryHalo src={storyHaloImage} size={size} />}
        <AvatarImage size={size}>
          <Image
            data-testid="avatar"
            height="100%"
            width="100%"
            data-src={avatarSrc}
            src={src}
            onError={handleError}
            alt={
              username && intl.formatMessage({ id: 'submission.card.avatar.text' }, { username })
            }
            aspectRatio={AspectRatios.ONE_BY_ONE}
            backgroundColor={Theme.grey200Color}
            round
            borderWidth={borderWidth}
            borderColor={borderColor}
            role="avatar"
          />
        </AvatarImage>
      </Container>
    </AvatarSocialLink>
  )
}

export default injectIntl(Avatar)
