import { call, put, select, takeLatest } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { IHateoasLink } from '@store'
import { trackEvent } from '@store/analytics'
import {
  AuthActionTypes,
  authError,
  completeSignUpRequest,
  completeSignUpSuccess,
  selectSignUpData,
  SignUpData,
} from '@store/auth'
import {
  IProductCategory,
  selectCompleteSignUpLink,
  selectProductCategories,
} from '@store/rootResource'
import { clearUIFlag, UIFlag } from '@store/ui'
import { networkRequest } from '@utils'

export function* handleCompleteSignUp(action: ReturnType<typeof completeSignUpRequest>) {
  try {
    const {
      email,
      user_id,
      first_name: first_name,
      last_name,
      company_name,
    }: SignUpData = yield select(selectSignUpData)
    const completeSignUpLink: IHateoasLink = yield select(selectCompleteSignUpLink)
    const { onboardingData, history } = action.payload
    const url = completeSignUpLink.href.replace('{id}', `${user_id}`)
    yield call(networkRequest, completeSignUpLink.method, url, onboardingData)
    yield put(completeSignUpSuccess())
    yield put(clearUIFlag(UIFlag.SHOW_ONBOARDING_AUTH_STEP))

    const productCategories: ReadonlyArray<IProductCategory> = yield select(selectProductCategories)

    yield put(
      trackEvent(EventTrackingNames.BrandSignUpStep2Completed, {
        user_id,
        email,
        first_name,
        last_name,
        company_name,
        objective: onboardingData.campaign_creation_reason,
        number_campaigns: onboardingData.estimated_campaign_creation_count,
        company_size: onboardingData.company_size,
        company_industry: productCategories.find(
          (productCategory) => productCategory.code === onboardingData.product_category,
        )?.display_value,
        company_type: onboardingData.company_type,
      }),
    )

    history.push(`/signUpConfirmation?email=${encodeURIComponent(email)}`)
  } catch (error) {
    yield put(authError(error))
  }
}

export function* watchCompleteSignUpRequest() {
  yield takeLatest(AuthActionTypes.COMPLETE_SIGNUP_REQUEST, handleCompleteSignUp)
}
