import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { fetchAdAccountsRequest } from '@store/facebookAds'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import LicensingUploadToFacebookAdsManagerButton from './Button'
import UploadToFacebookAdsManagerUploaded from './Uploaded'

interface IUploadToFacebookAdsManagerProps {
  onBeforeUpload?: VoidFunction
  isUploadedToFacebookAds?: boolean
}

interface IInternalProps extends IUploadToFacebookAdsManagerProps {
  fetchAdAccountsRequest: typeof fetchAdAccountsRequest
}

export const UploadToFacebookAdsManager = ({
  isUploadedToFacebookAds,
  onBeforeUpload,
  fetchAdAccountsRequest: fetchAdAccounts,
}: IInternalProps) => {
  const onFBAdUpload = () => {
    if (onBeforeUpload) {
      onBeforeUpload()
    }
    fetchAdAccounts()
  }

  if (isUploadedToFacebookAds) {
    return (
      <Fragment>
        <UploadToFacebookAdsManagerUploaded />
        <Container onClick={onFBAdUpload} pointer bottomOuterSpacing={0.0625}>
          <Text small color={Theme.primaryColor}>
            &nbsp;
            <FormattedMessage id="socialSubmission.card.modal.facebookAds.accounts.reUpload" />
          </Text>
        </Container>
      </Fragment>
    )
  }

  return (
    <LicensingUploadToFacebookAdsManagerButton onFBAdUpload={onFBAdUpload}>
      <FormattedMessage id="core.text.uploadToFacebookAdsManager" />
    </LicensingUploadToFacebookAdsManagerButton>
  )
}

const mapDispatchToProps = {
  fetchAdAccountsRequest,
}

export default compose<IInternalProps, IUploadToFacebookAdsManagerProps>(
  connect(undefined, mapDispatchToProps),
)(UploadToFacebookAdsManager)
