import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Flex, { FlexItem } from 'styled-flex-component'

import { LogoLink } from '@components/UI/TitleBar'
import TitleBarStyled, {
  HeaderBackButton,
  HeaderTitle,
} from '@components/UI/TitleBar/TitleBar.styled'
import { PageRoutes } from '@enums'
import { IApplicationState } from '@store'
import { selectBrandFanGroupName } from '@store/brandFans'
import { selectBrandFansGroupId } from '@store/router'
import Theme from '@theme'
import { Container, H5 } from '@tribegroup'
import { TitleBarContent } from './TitleBar.styled'

interface IInternalProps {
  groupName: string
}

const BrandFansGroupDetailsTitleBar: React.FC<IInternalProps> = ({ groupName }) => {
  return (
    <TitleBarStyled>
      <Flex alignCenter full>
        <Container absolute>
          <LogoLink />
        </Container>
        <TitleBarContent>
          <Flex alignCenter full>
            <FlexItem noShrink>
              <Link to={`/${PageRoutes.BrandFanGroups}`}>
                <HeaderBackButton glyph="nav-back-default" size={1.5} color={Theme.grey900Color} />
              </Link>
            </FlexItem>
            <HeaderTitle>
              <H5 color={Theme.grey900Color}>
                <FormattedMessage id="brandFans.groups.titlebar" values={{ groupName }} />
              </H5>
            </HeaderTitle>
          </Flex>
        </TitleBarContent>
      </Flex>
    </TitleBarStyled>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const groupId = selectBrandFansGroupId(match) as number
  return { groupName: selectBrandFanGroupName(state, groupId) }
}

export { BrandFansGroupDetailsTitleBar }
export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps),
)(BrandFansGroupDetailsTitleBar)
