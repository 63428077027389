import React from 'react'

import OutsideClickHandler from 'react-outside-click-handler'

import hooks from '@hooks'
import { Image, Root } from './MediaModal.styled'

const MediaModalImageViewer: React.FC = () => {
  const { closeMediaModal, imageSrc } = hooks.useMediaModal()

  if (!imageSrc) {
    return null
  }

  return (
    <Root data-testid="media-modal-image-viewer">
      <OutsideClickHandler onOutsideClick={closeMediaModal}>
        <Image src={imageSrc} />
      </OutsideClickHandler>
    </Root>
  )
}

export default MediaModalImageViewer
