import React, { PropsWithChildren } from 'react'

import { useLocation } from 'react-router-dom'
import { compose } from 'recompose'

import { ConditionalRouterLink } from '@components/UI/ConditionalLink/ConditionalLink'
import { IModalContextProps } from '@context/Modal'
import { EventTrackingNames, PageRoutes } from '@enums'
import { withModal } from '@hocs'
import { objectToQueryString } from '@utils'
import BrandFansGroupRowTrackedRoute from '../TrackedRoute'
import NoCreatorsModal from './NoCreatorsModal'

interface IProps {
  groupId: number
  eventName: EventTrackingNames
  canCreateCampaign: boolean
  additionalProperties?: any
}

interface IInternalProps extends IModalContextProps, IProps {}

const BrandFansGroupRowCreateCampaignLink: React.FC<PropsWithChildren<IInternalProps>> = ({
  groupId,
  canCreateCampaign,
  children,
  eventName,
  showModal,
  additionalProperties,
}) => {
  const { pathname } = useLocation()
  const onClick = () => {
    showModal(NoCreatorsModal)
  }
  return (
    <div onClick={canCreateCampaign ? undefined : onClick} role="button">
      <BrandFansGroupRowTrackedRoute
        hasTracking={canCreateCampaign}
        eventName={eventName}
        groupId={groupId}
        additionalProperties={additionalProperties}
      >
        <ConditionalRouterLink
          showLink={canCreateCampaign}
          to={{
            pathname: `/${PageRoutes.CampaignBuilder}`,
            search: objectToQueryString({
              brand_fan_group_id: groupId,
              from: pathname,
            }),
          }}
        >
          {children}
        </ConditionalRouterLink>
      </BrandFansGroupRowTrackedRoute>
    </div>
  )
}

export { BrandFansGroupRowCreateCampaignLink }
export default compose<IInternalProps, PropsWithChildren<IProps>>(withModal)(
  BrandFansGroupRowCreateCampaignLink,
)
