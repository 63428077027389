import { PureComponent } from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { H2, Icon } from '@tribegroup'
import { FocusableDropdown } from '../../Form.styled'

export class BuilderSubmitCampaignScheduleDate extends PureComponent<any> {
  render() {
    const { value, onClick, disabled } = this.props

    if (disabled) {
      return (
        <FocusableDropdown>
          <Flex>
            <H2 color={Theme.grey700Color}>{value}</H2>
            <Icon glyph="chevron-down" color={Theme.grey700Color} size={1.5} />
          </Flex>
        </FocusableDropdown>
      )
    }

    return (
      <FocusableDropdown onClick={onClick} role="button" tabIndex={0}>
        <Flex>
          {value ? (
            <H2 color={Theme.grey900Color}>{value}</H2>
          ) : (
            <H2 color={Theme.grey700Color}>
              <FormattedMessage id="builder.submit.schedule.startDate" />
            </H2>
          )}
          <Icon glyph="chevron-down" color={Theme.defaultColor} size={1.5} />
        </Flex>
      </FocusableDropdown>
    )
  }
}

export default BuilderSubmitCampaignScheduleDate
