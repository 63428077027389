enum PageRoutes {
  Campaigns = 'campaigns',
  CampaignBuilder = 'builder',
  Submissions = 'submissions',
  ContentLibrary = 'content-library',
  MediaObjects = 'media-objects',
  ConnectedAccounts = 'connected-accounts',
  Reports = 'reports',
  Account = 'account',
  Billing = 'billing',
  BillingCampaigns = 'billing/campaigns',
  BillingContentLibrary = 'billing/content-library',
  SignIn = 'signIn',
  SignOut = 'signOut',
  CompleteSignup = 'completeSignup',
  AccountPayment = 'account/payment',
  ShareableContentLibrary = 'shareable-content-library',
  Creators = 'creators',
  BrandFanGroups = 'brand-fan-groups',
  CreatorBioPage = 'creator-bio',
}

export { PageRoutes }
