import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { IModalComponentProps } from '@context/Modal'
import Theme from '@theme'
import { Button, Container, H3, Header, Modal, Text, Tip } from '@tribegroup'

const NoCreatorsModal: React.FC<IModalComponentProps> = ({ onRequestClose, isOpen = false }) => {
  return (
    <Modal reactModalProps={{ isOpen }} width={20}>
      <Container leftOuterSpacing={0.375} rightOuterSpacing={0.375} data-testid="no-creators-modal">
        <H3 color={Theme.defaultColor}>
          <FormattedMessage id="brandFans.groups.actions.createCampaign.modal.noCreators.title" />
        </H3>
        <Container topOuterSpacing={1.75}>
          <Tip />
        </Container>
        <Container topOuterSpacing={0.75} bottomOuterSpacing={2.5}>
          <Text color={Theme.grey800Color}>
            <FormattedMessage id="brandFans.groups.actions.createCampaign.modal.noCreators.body" />
          </Text>
        </Container>
        <Flex justifyEnd alignCenter>
          <Button type="button" small scheme="primary" height={2} onClick={onRequestClose}>
            <Header cta small>
              <FormattedMessage id="core.text.okay.thanks" />
            </Header>
          </Button>
        </Flex>
      </Container>
    </Modal>
  )
}

export { NoCreatorsModal }
export default NoCreatorsModal
