import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'

export const StyledRow = styled(Row)`
  box-sizing: content-box;
  border-bottom: solid ${Theme.grey400Color} 1px;
  width: 100%;
  ${breakpoint('tabletLandscape')`
    &:last-of-type {
      border: none;
    }
  `}
`

export const StyledCol = styled(Col)`
  padding-bottom: 1.125rem;
`
