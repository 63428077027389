import BuilderSubmitCampaignForm from '@components/Builder/SubmitCampaign/Form'
import { BuilderSubmitFormWrapper } from '@components/Builder/SubmitCampaign/Wrapper/Wrapper'
import BuilderTitleBar from '@components/Builder/TitleBar'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import { BuilderValidationProvider } from '@context/BuilderValidation'
import { CampaignProvider } from '@context/Campaign'
import { DraftProvider } from '@context/Draft'
import { BuilderSteps } from '@enums'

const SubmitCampaignPage = () => (
  <MainLayout>
    <CampaignProvider>
      <DraftProvider>
        <BuilderValidationProvider currentStep={BuilderSteps.SUBMIT}>
          <HeadTag id={`builder.steps.${BuilderSteps.SUBMIT}.title`} />
          <BuilderTitleBar />
          <BuilderSubmitFormWrapper>
            <BuilderSubmitCampaignForm />
          </BuilderSubmitFormWrapper>
        </BuilderValidationProvider>
      </DraftProvider>
    </CampaignProvider>
  </MainLayout>
)

export default SubmitCampaignPage
