import { AnalyticsIdentifyActionTypes } from '@store/analytics'
import { IVerifiedMe } from '@store/me'

export const identifyRequest = (me: Partial<IVerifiedMe>) => ({
  type: AnalyticsIdentifyActionTypes.IDENTIFY_REQUEST,
  payload: me,
})

export const identifySuccess = () => ({
  type: AnalyticsIdentifyActionTypes.IDENTIFY_SUCCESS,
})

export const identifyError = (error: any) => ({
  payload: error,
  type: AnalyticsIdentifyActionTypes.IDENTIFY_ERROR,
})
