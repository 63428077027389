import { FormattedMessage } from 'react-intl'

import Tooltip from '@components/UI/Tooltip'
import { Container } from '@tribegroup'

export const EstimatedCPETooltipID = 'estimatedCPE_tooltip'

const InfluencerIdentityRowPerformanceEstimatedCPEAverageTooltip = () => (
  <Tooltip id={EstimatedCPETooltipID} place="top">
    <Container width={7}>
      <FormattedMessage id="submission.performance.tooltip.cpeAveraged" />
    </Container>
  </Tooltip>
)

export default InfluencerIdentityRowPerformanceEstimatedCPEAverageTooltip
