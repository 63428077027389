import { useState } from 'react'
import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import ConfirmationMessageBox from '@components/UI/ConfirmationMessageBox'
import { useLocationSearch } from '@hooks/useLocationSearch'
import { resendVerificationRequest } from '@store/auth'
import { Button } from '@tribegroup'

interface IProps extends InjectedIntlProps {
  resendVerificationRequest: typeof resendVerificationRequest
}

const ResendVerification: React.FC<IProps> = ({
  resendVerificationRequest: resendVerificationRequestAction,
  intl,
}) => {
  const locationSearch = useLocationSearch()
  const [buttonEnabled, setButtonEnabled] = useState(true)
  const locationSearchEmail = locationSearch.email

  const onResendVerification = () => {
    const decodedEmail = decodeURIComponent(locationSearchEmail)
    resendVerificationRequestAction(
      decodedEmail,
      undefined,
      <FormattedMessage id="resendVerification.confirmation.toast" />,
    )
    setButtonEnabled(false)
  }

  return (
    <ConfirmationMessageBox
      title={intl.formatMessage({ id: 'resendVerification.confirmation.title' })}
      footerCTA={
        <Button
          heavy
          scheme="primary"
          data-testid="resend-verification-button"
          onClick={onResendVerification}
          disabled={!buttonEnabled || !locationSearchEmail}
        >
          {buttonEnabled ? (
            <FormattedMessage id={`resendVerification.confirmation.button.enabled`} />
          ) : (
            <FormattedMessage id={`resendVerification.confirmation.button.disabled`} />
          )}
        </Button>
      }
    >
      <FormattedMessage id="resendVerification.confirmation.body" />
    </ConfirmationMessageBox>
  )
}

const mapDispatchToProps = {
  resendVerificationRequest,
}

export { ResendVerification }
export default compose<IProps, any>(
  injectIntl,
  connect(undefined, mapDispatchToProps),
)(ResendVerification)
