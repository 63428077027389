import React from 'react'

import { CloseIcon } from '@tribegroup'
import { PopoverStyled } from './Popover.styled'

interface IPopover {
  bottom?: boolean
  hide: VoidFunction
  children: React.ReactNode
}

const Popover = ({ bottom, hide, children }: IPopover) => (
  <PopoverStyled data-testid="popover-container" bottom={bottom}>
    <CloseIcon onClick={hide} />
    {children}
  </PopoverStyled>
)

export default Popover
