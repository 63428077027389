import React from 'react'

import { UnsupportedScreenWrapper } from '@components/UI/ScreenSize'
import Notice, { INoticeConsumerProps } from '../Notice'
import NoticeUnsupportedScreenImage from './Image'

const NoticeUnsupportedScreen: React.FC<INoticeConsumerProps> = ({
  title,
  subtitle,
  alt,
  image = <NoticeUnsupportedScreenImage alt={alt} />,
}) => (
  <UnsupportedScreenWrapper>
    <Notice title={title} subtitle={subtitle} image={image} />
  </UnsupportedScreenWrapper>
)

export default NoticeUnsupportedScreen
