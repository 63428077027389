import React from 'react'

import { FormattedMessage } from 'react-intl'

import MediaObjectCostBreakdownLicenseOptions from '@components/MediaObject/CostBreakdown/LicenseOptions'
import Popover from '@components/UI/Popover/Popover'
import { ILicenseOption } from '@store/campaigns'
import Theme from '@theme'
import { Container, Header } from '@tribegroup'

interface IProps {
  onHide: VoidFunction
  licenses: ReadonlyArray<ILicenseOption>
}

const ShareableContentLibraryFullPricePanelPopover: React.FC<IProps> = ({ onHide, licenses }) => {
  return (
    <Popover hide={onHide}>
      <Container relative data-testid="shareable-content-library-price-panel-popover">
        <Container bottomOuterSpacing={1.25} data-testid="cost-breakdown-license">
          <Header small uppercase color={Theme.primaryColor}>
            <FormattedMessage id="core.text.summary" />
          </Header>
        </Container>
        <MediaObjectCostBreakdownLicenseOptions licenses={licenses} />
      </Container>
    </Popover>
  )
}

export { ShareableContentLibraryFullPricePanelPopover }
export default ShareableContentLibraryFullPricePanelPopover
