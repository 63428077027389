import styled, { css } from 'styled-components'

import imagesSolid from '@images/images-solid.png'
import Theme from '@theme'

const SPACING_SIZE = 2.25

export const MessageBlockContainer = styled.div`
  width: calc(100% - ${SPACING_SIZE}rem);
  display: inline-block;
  border-radius: 1rem;
  ${({ isInfluencer }) =>
    isInfluencer
      ? css`
          margin-right: ${SPACING_SIZE}rem;
          background-color: ${Theme.grey200Color};
          border-bottom-left-radius: 0;
        `
      : css`
          margin-left: ${SPACING_SIZE}rem;
          background-color: ${Theme.success100Color};
          border-bottom-right-radius: 0;
        `}
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  text-align: left;
  margin-top: 0.75rem;
`

export const AttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  ${({ emptyMessage }) =>
    !emptyMessage &&
    `padding-bottom: 0.5rem;
     border-bottom: 1px solid ${Theme.grey400Color};
     margin-bottom: 0.5rem;`}
`

export const ImageContainer = styled.div`
  width: fit-content;
  margin-top: 0.75rem;
  margin-left: ${({ isInfluencer }) => (isInfluencer ? 0 : 'auto')};
`

export const ThumbnailButton = styled.button`
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;

  :disabled {
    cursor: progress;
  }

  ${({ showPlaceholder, large }) =>
    showPlaceholder
      ? `
        width: ${large ? '6.25rem' : '2.5rem'};
        background-image: url(${imagesSolid});
        background-color: ${Theme.grey200Color};
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
      `
      : ''}
`
