import { call, put, select } from 'redux-saga/effects'

import { ResponseStatuses } from '@enums'
import { fetchSubmissionsByInfluencerIdentity } from '@sagas/submissions/submissionsByInfluencerIdentity'
import { selectAuthToken } from '@store/auth'
import {
  IInfluencerIdentity,
  removeInfluencerIdentity,
  updateInfluencerIdentity,
} from '@store/influencerIdentities'
import { selectIdentityId, selectSubmissionIdentityLink } from '@store/submissions'
import { networkRequest } from '@utils'

export function* handleFetchInfluencerIdentity(campaignId, submissionId) {
  try {
    const influencerIdentityLink = yield select(selectSubmissionIdentityLink, submissionId)

    const authToken: string = yield select(selectAuthToken)

    const influencerIdentity: IInfluencerIdentity = yield call(
      networkRequest,
      influencerIdentityLink.method,
      influencerIdentityLink.href,
      undefined,
      authToken,
    )

    yield put(updateInfluencerIdentity(influencerIdentity, campaignId))
    yield call(fetchSubmissionsByInfluencerIdentity, campaignId, influencerIdentity.id, true)
  } catch (error) {
    if (error.status === ResponseStatuses.NotFound) {
      const identityId = yield select(selectIdentityId, submissionId)
      yield put(removeInfluencerIdentity(campaignId, identityId))
    }
  }
}
