import React from 'react'

import { FormattedMessage } from 'react-intl'
import OutsideClickHandler from 'react-outside-click-handler'
import Flex from 'styled-flex-component'

import { HoverToggleChild } from '@components/UI/HoverToggle/HoverToggle.styled'
import { ClickableInformation } from '@components/UI/Information'
import { InformationTooltipContext } from '@context/InformationTooltip'
import { Container } from '@tribegroup'
import SubmissionCostBreakdownTribeFeeInfoTooltip from './Tooltip'

const SubmissionCostBreakdownTribeFeeInfo: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const { tooltipVisible } = React.useContext(InformationTooltipContext)

  const onOutsideClick = () => {
    if (!tooltipVisible) {
      setIsOpen(false)
    }
  }

  const onInsideClick = () => {
    setIsOpen(true)
  }

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <Flex>
        <FormattedMessage id="core.text.tribeFee" />
        <HoverToggleChild visible={isOpen}>
          <Container relative leftOuterSpacing={0.375} onClick={onInsideClick}>
            <ClickableInformation width={18.75}>
              <SubmissionCostBreakdownTribeFeeInfoTooltip />
            </ClickableInformation>
          </Container>
        </HoverToggleChild>
      </Flex>
    </OutsideClickHandler>
  )
}

export default SubmissionCostBreakdownTribeFeeInfo
