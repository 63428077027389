import H from 'history'
import { InjectedIntlProps, injectIntl } from 'react-intl'

import { NewTagContainer } from '@components/Submission/Card/Header/Header.styled'
import { ConditionalRouterLink } from '@components/UI/ConditionalLink/ConditionalLink'
import Tag from '@components/UI/Tag'
import Theme from '@theme'

interface IInternalProps extends InjectedIntlProps, IInfluencerIdentityLabelsNew {}

interface IInfluencerIdentityLabelsNew {
  linkTo?: H.LocationDescriptor
}

export const InfluencerIdentityLabelsNew = ({ linkTo, intl }: IInternalProps) => (
  <ConditionalRouterLink showLink={Boolean(linkTo)} to={linkTo as H.LocationDescriptor}>
    <NewTagContainer data-testid="influencer-new-label">
      <Tag
        color={Theme.secondaryColor}
        text={intl.formatMessage({ id: 'core.text.new' })}
        tooltip={intl.formatMessage({ id: 'submission.card.label.newInfluencer.tip' })}
      />
    </NewTagContainer>
  </ConditionalRouterLink>
)

export default injectIntl(InfluencerIdentityLabelsNew)
