import { Stripe } from '@stripe/stripe-js'
import { AnyAction } from 'redux'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { INBOX_FILTER, PREAPPROVED_FILTER, SHORTLISTED_FILTER } from '@constants'
import { ErrorCodes, EventTrackingNames } from '@enums'
import { handleFetchInfluencerIdentity } from '@sagas/influencerIdentities/influencerIdentity'
import { handlePaymentAuthFailure } from '@sagas/payments'
import { IHateoasLink } from '@store'
import { selectSubmissionTrackingProps, trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import { fetchCampaignBillings } from '@store/billings'
import { fetchCampaign } from '@store/campaigns'
import { selectStripe } from '@store/payments'
import {
  approve,
  ApproveActionTypes,
  approveError,
  approveSuccess,
  fetchSubmissions,
  IApproveRequestResponse,
  ISubmission,
  selectApproveLink,
  selectShowPaidPartnershipAddHandleReminder,
  selectShowTurnOffRequireApprovals,
  updateFilterSubmissionIds,
} from '@store/submissions'
import { setUIFlag, UIFlag } from '@store/ui'
import { networkRequest, selectLink, stripeErrorToResponseError } from '@utils'

export function* handleSecureApproveRequest(
  action: AnyAction,
  authToken: string,
  approveLink: IHateoasLink,
) {
  // eslint-disable-next-line no-useless-catch
  try {
    const { params } = action.payload
    const approveRequestResponse: IApproveRequestResponse = yield call(
      networkRequest,
      approveLink.method,
      approveLink.href,
      params,
      authToken,
    )

    if (approveRequestResponse.submission) {
      return approveRequestResponse.submission
    }

    const stripeSDK: Stripe = yield select(selectStripe)

    const paymentIntentResponse = yield call(
      stripeSDK.confirmCardPayment,
      approveRequestResponse.client_secret,
    )

    if (paymentIntentResponse.error) {
      const paymentError = stripeErrorToResponseError(
        paymentIntentResponse.error,
        ErrorCodes.SUBMISSION_APPROVE_STRIPE_ERROR,
      )

      const errorResponse = yield call(
        handlePaymentAuthFailure,
        approveRequestResponse,
        paymentError,
      )
      throw errorResponse
    }

    const { paymentIntent } = paymentIntentResponse

    const approve3dsLink: IHateoasLink = selectLink(approveRequestResponse.links, 'approve3ds')!

    const submission: ISubmission = yield call(
      networkRequest,
      approve3dsLink.method,
      approve3dsLink.href,
      {
        ...params,
        payment_intent_id: paymentIntent.id,
      },
      authToken,
    )

    return submission
  } catch (error) {
    throw error
  }
}

export function* handleApproveUIFlags(campaignId: number, submissionId: number) {
  const showTurnOffRequireApprovals = yield select(
    selectShowTurnOffRequireApprovals,
    campaignId,
    submissionId,
  )

  if (showTurnOffRequireApprovals) {
    yield put(setUIFlag(UIFlag.SHOW_TURNOFF_REQUIRE_APPROVALS_MODAL))
  }

  const showAddHandleReminder = yield select(
    selectShowPaidPartnershipAddHandleReminder,
    campaignId,
    submissionId,
  )

  if (showAddHandleReminder) {
    yield put(setUIFlag(UIFlag.SHOW_PAID_PARTNERSHIP_ADD_HANDLE_REMINDER))
  }
}

export function* handleApprove(action: ReturnType<typeof approve>) {
  try {
    const { campaignId, submissionId, meta } = action.payload
    const { filter, event } = meta
    const authToken: string = yield select(selectAuthToken)

    const approveLink: IHateoasLink = yield select(selectApproveLink, submissionId)

    const submission: ISubmission = yield call(
      handleSecureApproveRequest,
      action,
      authToken,
      approveLink,
    )

    const properties = yield select(selectSubmissionTrackingProps, campaignId, submissionId)
    yield put(trackEvent(EventTrackingNames.ApproveSubmission, properties))

    yield put(
      updateFilterSubmissionIds(campaignId, submissionId, [
        INBOX_FILTER,
        SHORTLISTED_FILTER,
        PREAPPROVED_FILTER,
      ]),
    )
    yield put(approveSuccess(submission, campaignId, { filter, event }))
    yield put(fetchSubmissions(campaignId, { filter }))
    yield put(fetchCampaign(campaignId))

    yield put(fetchCampaignBillings())

    yield call(handleApproveUIFlags, campaignId, submission.id)

    yield call(handleFetchInfluencerIdentity, campaignId, submission.id)
  } catch (error) {
    yield put(approveError(error))
  }
}

export function* watchApproveRequest() {
  yield takeEvery(ApproveActionTypes.APPROVE_REQUEST, handleApprove)
}
