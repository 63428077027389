import { createSelector } from 'reselect'

import { IApplicationState } from '@store'

const publishedSummaryState = (state: IApplicationState) => state.publishedSummary

export const selectPublishedSummaryByCampaignId = createSelector(
  [publishedSummaryState, (_, campaignId: number) => campaignId],
  (state, campaignId) => state.publishedSummaryByCampaignId[campaignId] ?? {},
)

export const selectIsFetching = createSelector(publishedSummaryState, (state) => state.isFetching)

export const selectTotalFollowers = createSelector(
  selectPublishedSummaryByCampaignId,
  (publishedSummary) => publishedSummary.total_followers,
)

export const selectTotalEngagement = createSelector(
  selectPublishedSummaryByCampaignId,
  (publishedSummary) => publishedSummary.total_engagement,
)
