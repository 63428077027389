import React from 'react'

export interface IFormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  /**
   * Callback function invoked before the onSubmit function is called. Function will return a boolean and
   * determines if the onSubmit function will proceed or not.
   *
   * @default undefined
   */
  onBeforeSubmit?: (event?: Event) => boolean

  /**
   * Function called called upon component mount. This is used in conjunction with the setForm props
   * inherited once the withFormValidation HOC is used
   *
   * @default undefined
   */
  setForm?: (form: React.Ref<HTMLFormElement>) => void
}

export default class Form extends React.PureComponent<IFormProps> {
  private formRef: React.RefObject<HTMLFormElement> = React.createRef()

  componentDidMount() {
    if (typeof this.props.setForm === 'function') {
      this.props.setForm(this.formRef)
    }
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (typeof this.props.onBeforeSubmit === 'function') {
      const proceedwithOnSubmit = this.props.onBeforeSubmit()
      if (!proceedwithOnSubmit) {
        event.preventDefault()
        return
      }
    }
    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(event)
    }
  }
  public render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { setForm, onBeforeSubmit, onSubmit, ...props } = this.props
    return <form {...props} onSubmit={this.onSubmit} noValidate ref={this.formRef} />
  }
}
