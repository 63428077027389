import { FormattedMessage } from 'react-intl'

import {
  CheckItem,
  CoachmarkBlock,
  CoachmarkContent,
  CoachmarkSection,
  ImageWrapper,
} from '@components/UI/Coachmark/Coachmark.styled'
import Theme from '@theme'
import { Header, Icon, Text } from '@tribegroup'

const LogoRequirements = () => (
  <CoachmarkContent>
    <CoachmarkSection width={11.375}>
      <CoachmarkBlock>
        <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.5}>
          <FormattedMessage id="builder.brands.brandLogo.coach.logorequirements" />
        </Header>
        <CheckItem>
          <Icon glyph="tick-heavy" color={Theme.successColor} size={1} />
          <Text color={Theme.grey900Color}>
            <FormattedMessage id="builder.brands.brandLogo.coach.logorequirements.format" />
          </Text>
        </CheckItem>
        <CheckItem>
          <Icon glyph="tick-heavy" color={Theme.successColor} size={1} />
          <Text color={Theme.grey900Color}>
            <FormattedMessage id="builder.brands.brandLogo.coach.logorequirements.dimensions" />
          </Text>
        </CheckItem>
        <CheckItem>
          <Icon glyph="tick-heavy" color={Theme.successColor} size={1} />
          <Text color={Theme.grey900Color}>
            <FormattedMessage id="builder.brands.brandLogo.coach.logorequirements.fileformat" />
          </Text>
        </CheckItem>
        <CheckItem>
          <Icon glyph="tick-heavy" color={Theme.successColor} size={1} />
          <Text color={Theme.grey900Color}>
            <FormattedMessage id="builder.brands.brandLogo.coach.logorequirements.dpi" />
          </Text>
        </CheckItem>
      </CoachmarkBlock>
      <CoachmarkBlock>
        <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.5}>
          <FormattedMessage id="builder.brands.brandLogo.coach.hottip" />
        </Header>
        <Text color={Theme.grey900Color}>
          <FormattedMessage id="builder.brands.brandLogo.coach.hottip.body" />
        </Text>
      </CoachmarkBlock>
    </CoachmarkSection>
    <CoachmarkSection width={10.625}>
      <CoachmarkBlock>
        <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={1.25}>
          <FormattedMessage id="builder.brands.brandLogo.coach.avoid" />
        </Header>
        <ImageWrapper withCross crossColor={Theme.primaryColor}>
          <img
            src={require('@images/campaignbuilder/cb-step1-brand-image-01.jpg')}
            alt="cb-step1-brand-image-01"
          />
        </ImageWrapper>
        <Text xsmall color={Theme.defaultColor}>
          <FormattedMessage id="builder.brands.brandLogo.coach.logotootight" />
        </Text>
      </CoachmarkBlock>
      <CoachmarkBlock>
        <ImageWrapper
          withBorder
          borderColor={Theme.grey300Color}
          withCross
          crossColor={Theme.primaryColor}
        >
          <img
            src={require('@images/campaignbuilder/cb-step1-brand-image-02.jpg')}
            alt="cb-step1-brand-image-02"
          />
        </ImageWrapper>
        <Text xsmall color={Theme.defaultColor}>
          <FormattedMessage id="builder.brands.brandLogo.coach.logonotfilled" />
        </Text>
      </CoachmarkBlock>
    </CoachmarkSection>
  </CoachmarkContent>
)

export default LogoRequirements
