import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  FetchCategoriesActionTypes,
  fetchCategoriesError,
  fetchCategoriesSuccess,
} from '@store/categories'
import { selectCategoriesLink } from '@store/rootResource'
import { networkRequest } from '@utils'

export function* handleFetchCategories() {
  try {
    const authToken: string = yield select(selectAuthToken)
    const categoriesLink: IHateoasLink = yield select(selectCategoriesLink)
    const result = yield call(
      networkRequest,
      categoriesLink.method,
      categoriesLink.href,
      undefined,
      authToken,
    )
    yield put(fetchCategoriesSuccess(result))
  } catch (error) {
    yield put(fetchCategoriesError(error))
  }
}

export function* watchFetchCategoriesRequest() {
  yield takeLatest(FetchCategoriesActionTypes.FETCH_REQUEST, handleFetchCategories)
}
