import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchSubmissions,
  fetchSubmissionsError,
  fetchSubmissionsSuccess,
  ISubmissionResults,
  selectFetchSubmissionsLink,
  SubmissionsActionTypes,
} from '@store/submissions'
import { networkRequest, sanitizeQueryParams } from '@utils'

export function* handleFetchSubmissions(action: ReturnType<typeof fetchSubmissions>) {
  try {
    const { campaignId, isFetchNext } = action.payload
    const { filter = '', sortBy = '', declinedFilter, resetList } = action.meta

    const usedFilter = declinedFilter || filter
    const authToken: string = yield select(selectAuthToken)

    const submissionsLink: IHateoasLink = yield select(
      selectFetchSubmissionsLink,
      campaignId,
      usedFilter,
      isFetchNext,
      resetList,
    )

    const result: ISubmissionResults = yield call(
      networkRequest,
      submissionsLink.method,
      sanitizeQueryParams(submissionsLink.href, action.meta),
      undefined,
      authToken,
    )
    yield put(fetchSubmissionsSuccess(result, campaignId, { filter: usedFilter, sortBy }))
  } catch (error) {
    yield put(fetchSubmissionsError(error))
  }
}

export function* watchSubmissionsRequest() {
  yield takeLatest(SubmissionsActionTypes.FETCH_REQUEST, handleFetchSubmissions)
}
