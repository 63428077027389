import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { EventTrackingNames } from '@enums'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import { saveCampaign, selectCampaignIsSaving } from '@store/campaigns'
import { selectCampaignIdFromDraft } from '@store/drafts'
import { selectIsAnyUploading } from '@store/moodboard/selectors'
import { Button } from '@tribegroup'
import { useIsButtonLoading } from './useIsButtonLoading'

interface IInternalProps extends ConnectedProps<typeof connector>, IBuilderValidationContextProps {}

const BuilderTitleBarActionsButtonSave: React.FC<IInternalProps> = ({
  campaignId,
  isSaving,
  saveCampaign: saveCampaignAction,
  isEnableActions,
  validate,
  formChanged,
  setFormChanged,
  isUploading,
}) => {
  const { loading, startLoading } = useIsButtonLoading(isSaving)

  const onClick = () => {
    if (validate()) {
      saveCampaignAction()
      startLoading()
      setFormChanged(false)
    }
  }

  const disabled = !formChanged || isSaving || !isEnableActions || isUploading
  return (
    <ConnectedTrackedRoute
      eventName={EventTrackingNames.CampaignBuilderSaveCampaign}
      campaignId={campaignId}
      additionalProperties={{
        current_filter: undefined,
      }}
    >
      <Button
        width={7}
        scheme="primary"
        outlined
        small
        nowrap
        onClick={onClick}
        rightOuterSpacing={0.75}
        disabled={disabled}
        loading={loading}
        type="button"
        data-cy-element="save-button"
        data-testid="campaign-builder-save-button"
      >
        <FormattedMessage id="core.text.save" />
      </Button>
    </ConnectedTrackedRoute>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  const campaignId = selectCampaignIdFromDraft(state)
  return {
    campaignId,
    isSaving: selectCampaignIsSaving(state),
    isUploading: selectIsAnyUploading(state, campaignId!),
  }
}

const mapDispatchToProps = {
  saveCampaign,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export default compose<IInternalProps, {}>(
  withBuilderValidation,
  connector,
)(BuilderTitleBarActionsButtonSave)
