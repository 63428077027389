import { call, put, select, takeLatest } from 'redux-saga/effects'

import { BuilderSteps, EventTrackingNames } from '@enums'
import { IHateoasLink } from '@store'
import { trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import { selectBrandFanGroupNamesByIds } from '@store/brandFans'
import {
  fetchCampaigns,
  ICampaign,
  saveCampaign,
  SaveCampaignActionTypes,
  saveCampaignError,
  saveCampaignSuccess,
  selectSaveCampaignLink,
} from '@store/campaigns'
import { IDraft, saveDraftSuccess, selectDraft, selectDraftChanges } from '@store/drafts'
import { selectCurrentCampaignBuilderStep } from '@store/router'
import { getCampaignType, isEmpty, networkRequest } from '@utils'

const stepsAndAnalyticsEventMap = {
  [BuilderSteps.CAMPAIGN]: EventTrackingNames.CampaignBuilderSubmitCampaignPage,
  [BuilderSteps.BRIEF]: EventTrackingNames.CampaignBuilderSubmitBriefPage,
  [BuilderSteps.BUDGET]: EventTrackingNames.CampaignBuilderSubmitBudgetPage,
}

export function* getAdditionalTrackingProps(campaign: IDraft, currentStep: BuilderSteps) {
  if (currentStep === BuilderSteps.BUDGET) {
    if (!isEmpty(campaign.brand_fan_group_ids)) {
      const groupNames = yield select(selectBrandFanGroupNamesByIds, campaign.brand_fan_group_ids)
      return {
        brand_fan_group_names: groupNames,
        brand_fan_group_ids: campaign.brand_fan_group_ids,
        target_creators: 'brand_fans',
        brand_id: campaign.brand_id,
      }
    }
    return {
      brand_id: campaign.brand_id,
      target_creators: 'tribe_community',
    }
  }
  return undefined
}

export function* handleSave(action: ReturnType<typeof saveCampaign>) {
  try {
    const stepCompleted = action.payload
    const authToken: string = yield select(selectAuthToken)

    const draft: IDraft = yield select(selectDraft)
    const draftChanges: Partial<IDraft> = yield select(selectDraftChanges, draft.id)

    const saveCampaignLink: IHateoasLink = yield select(selectSaveCampaignLink, draft.id)

    const moodboardWithUpdatedPositions =
      draft.moodboard_images &&
      draft.moodboard_images.map((moodboardImage, index) => {
        return {
          ...moodboardImage,
          position: index,
        }
      })

    const updatedDraft = {
      ...draftChanges,
      step_completed: stepCompleted || draft.step_completed,
      moodboard_images: moodboardWithUpdatedPositions,
    }

    delete updatedDraft.moodboard_images

    const campaign: ICampaign = yield call(
      networkRequest,
      saveCampaignLink.method,
      saveCampaignLink.href,
      updatedDraft,
      authToken,
    )

    const currentStep = yield select(selectCurrentCampaignBuilderStep)
    const analyticsEventName = stepsAndAnalyticsEventMap[currentStep]
    const additionalTrackingProps = yield call(getAdditionalTrackingProps, campaign, currentStep)
    yield put(
      trackEvent(analyticsEventName, {
        brief_id: campaign.id,
        brief_type: getCampaignType(campaign),
        ...additionalTrackingProps,
      }),
    )

    yield put(saveCampaignSuccess(campaign))

    yield put(saveDraftSuccess(campaign as IDraft))

    if (!draft.id) {
      yield put(fetchCampaigns())
    }
    if (action.meta) {
      const { history, redirect } = action.meta
      if (history) {
        yield call(history.push, redirect!.replace('{draft_id}', String(campaign.id)))
      }
    }
  } catch (error) {
    yield put(saveCampaignError(error))
  }
}

export function* watchSaveRequest() {
  yield takeLatest(SaveCampaignActionTypes.SAVE_REQUEST, handleSave)
}
