import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'

export const ImageWrapper = styled.div`
  height: 24.2188vw;
  width: 100%;
  position: relative;
  margin: 0 auto 3rem;

  img {
    transition: all 0.2s ease-in-out;
    height: 90%;
    border-radius: 0.8rem;
  }
`

export const DisabledCopyWrapper = styled.div`
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
`

export const MainContainer = styled.div`
  position: relative;
`

export const WistiaWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`

export const BuilderCampaignTypeCardWrapper = styled(Flex)`
  width: 50%;
  text-align: center;
  background: ${Theme.whiteColor};
  min-height: 100vh;
  position: relative;
  ${MainContainer} {
    transition: opacity 0.2s ease-in-out, filter 0.2s ease-in-out;
  }

  &:hover {
    ${MainContainer} {
      ${({ disabled }) =>
        disabled &&
        css`
          filter: blur(2rem);
          opacity: 0.5;
        `};
    }
    ${DisabledCopyWrapper} {
      opacity: 1;
    }

    ${ImageWrapper} {
      img {
        transform: scale(1.03);
        box-shadow: 0 0.25rem 2rem 0 rgb(0 0 0 / 10%);
      }
    }
  }
`

export const ContentWrapper = styled.div`
  margin: 1rem auto 2.375rem;
  max-width: 21.25rem;
  text-align: center;
`

export const ButtonWrapper = styled.div`
  margin: auto;
`
