import React from 'react'

import { useLocation } from 'react-router-dom'

import { BuilderSteps } from '@enums'
import { ButtonLinksWrapper } from './Actions.styled'
import { BuilderTitleBarActionsButtonExit, BuilderTitleBarActionsButtonSave } from './Buttons'

const BuilderTitleBarCampaignActions: React.FC = () => {
  const { pathname } = useLocation()
  const showSave = !pathname.startsWith(`/builder/${BuilderSteps.BRAND}`)
  return (
    <ButtonLinksWrapper alignCenter>
      {showSave && <BuilderTitleBarActionsButtonSave />}
      <BuilderTitleBarActionsButtonExit />
    </ButtonLinksWrapper>
  )
}

export default BuilderTitleBarCampaignActions
