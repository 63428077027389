import { takeEvery } from 'redux-saga/effects'

import { toast } from '@tribegroup'

export function* handleSuccessToast(action: ReturnType<typeof ToastActionTypes>) {
  yield toast(action.meta.toast)
}

export const ToastActionTypes = (action) => {
  // eslint-disable-next-line no-prototype-builtins
  return action.type.includes('_SUCCESS') && action.meta && action.meta.hasOwnProperty('toast')
}

export function* watchToastSuccess() {
  yield takeEvery(ToastActionTypes, handleSuccessToast)
}
