import { ActivateActionTypes, ICampaign } from '@store/campaigns'

export const activate = (campaignId: number) => ({
  payload: campaignId,
  type: ActivateActionTypes.ACTIVATE_REQUEST,
})

export const activateSuccess = (campaign: ICampaign) => ({
  payload: campaign,
  type: ActivateActionTypes.ACTIVATE_SUCCESS,
})

export const activateError = (error: any) => ({
  payload: error,
  type: ActivateActionTypes.ACTIVATE_ERROR,
})
