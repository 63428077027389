import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Grid } from 'react-styled-flexboxgrid'
import { compose } from 'recompose'

import { TRIBE_PRICING_PAGE_METRIC_SUMMARY_UTM } from '@constants'
import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { selectIsPriceHidden } from '@store/campaigns'
import {
  selectPostsAvgEngagementPct,
  selectPostsAvgReachPct,
  selectPostsOverallCPE,
  selectPostsOverallCPM,
  selectPostsTotalComments,
  selectPostsTotalEngagement,
  selectPostsTotalEngagementPct,
  selectPostsTotalFollowers,
  selectPostsTotalImpressions,
  selectPostsTotalLikes,
  selectPostsTotalPosts,
  selectPostsTotalReach,
  selectPostsTotalSpend,
} from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import AnalyticsPostCampaignReportMetric from '../Metric/Metric'
import { StyledCol, StyledRow } from '../Summary/Summary.styled'

type IInternalProps = ConnectedProps<typeof connector>

const externalLink = TRIBE_PRICING_PAGE_METRIC_SUMMARY_UTM
const productMetricGroup = 'reporting_instagram_posts'

const AnalyticsPostCampaignReportPost: React.FC<IInternalProps> = ({
  totalPosts,
  totalSpend,
  totalFollowers,
  totalEngagement,
  totalEngagementPct,
  totalLikes,
  totalComments,
  overallCPE,
  totalReach,
  avgReachPct,
  totalImpressions,
  overallCPM,
  isPriceHidden,
}) => {
  return (
    <Grid fluid>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-posts-cell">
          <AnalyticsPostCampaignReportMetric title="ig.post.totalPost" value={totalPosts} />
        </StyledCol>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="total-spend-cell">
            <AnalyticsPostCampaignReportMetric
              title="ig.post.totalSpend"
              value={totalSpend.amount}
              currency={totalSpend.currency}
            />
          </StyledCol>
        )}
        <StyledCol xs={6} lg={3} data-testid="total-followers-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.post.totalFollowers"
            value={totalFollowers}
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-engagement-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.post.totalEngagement"
            value={totalEngagement}
          />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-likes-cell">
          <AnalyticsPostCampaignReportMetric title="ig.post.likes" value={totalLikes} />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-comments-cell">
          <AnalyticsPostCampaignReportMetric title="ig.post.comments" value={totalComments} />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-engagement-pct-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.post.totalEngagementPct"
            value={totalEngagementPct}
            showPercentSign
          />
        </StyledCol>

        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="overall-cpe-cell">
            <AnalyticsPostCampaignReportMetric
              title="ig.post.overallCPE"
              value={overallCPE.amount}
              currency={overallCPE.currency}
            />
          </StyledCol>
        )}
      </StyledRow>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-reach-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.post.totalReach"
            value={totalReach}
            externalLink={externalLink}
            productMetricGroup={productMetricGroup}
            productMetricValue="total_reach"
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="avg-reach-pct-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.post.avgReachPct"
            value={avgReachPct}
            showPercentSign
            externalLink={externalLink}
            productMetricGroup={productMetricGroup}
            productMetricValue="avg_reach_pct"
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-impressions-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.post.totalImpressions"
            value={totalImpressions}
            externalLink={externalLink}
            productMetricGroup={productMetricGroup}
            productMetricValue="total_impressions"
          />
        </StyledCol>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="overall-cpm-cell">
            <AnalyticsPostCampaignReportMetric
              title="ig.post.overallCPM"
              value={overallCPM && overallCPM.amount}
              currency={overallCPM && overallCPM.currency}
              externalLink={externalLink}
              productMetricGroup={productMetricGroup}
              productMetricValue="overall_cpm"
            />
          </StyledCol>
        )}
      </StyledRow>
    </Grid>
  )
}
const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match)!
  const channel = PCRChannels.IGPosts

  return {
    totalPosts: selectPostsTotalPosts(state, campaignId, channel),
    totalSpend: selectPostsTotalSpend(state, campaignId, channel),
    totalFollowers: selectPostsTotalFollowers(state, campaignId, channel),
    totalEngagement: selectPostsTotalEngagement(state, campaignId, channel),
    totalEngagementPct: selectPostsTotalEngagementPct(state, campaignId, channel),
    totalLikes: selectPostsTotalLikes(state, campaignId, channel),
    totalComments: selectPostsTotalComments(state, campaignId, channel),
    overallCPE: selectPostsOverallCPE(state, campaignId, channel),
    avgReachPct: selectPostsAvgReachPct(state, campaignId, channel),
    avgEngagementPct: selectPostsAvgEngagementPct(state, campaignId, channel),
    totalReach: selectPostsTotalReach(state, campaignId, channel),
    totalImpressions: selectPostsTotalImpressions(state, campaignId, channel),
    overallCPM: selectPostsOverallCPM(state, campaignId, channel),
    isPriceHidden: selectIsPriceHidden(state, campaignId),
  }
}

const connector = connect(mapStateToProps)

export { AnalyticsPostCampaignReportPost }
export default compose<IInternalProps, {}>(withRouter, connector)(AnalyticsPostCampaignReportPost)
