import { FC } from 'react'

import { FormattedMessage } from 'react-intl'

import { NoticeEmpty } from '@components/UI/Notice'
import { Container } from '@tribegroup'

interface IProps {
  submissionFilter: string
}

const SubmissionCardListEmptyDefault: FC<IProps> = ({ submissionFilter }) => (
  <NoticeEmpty
    alt="submission"
    title={
      <Container maxWidth={22.75}>
        <FormattedMessage id={`inbox.cardlist.empty.title.${submissionFilter}`} />
      </Container>
    }
    subtitle={<FormattedMessage id={`inbox.cardlist.empty.subtitle.${submissionFilter}`} />}
  />
)

export default SubmissionCardListEmptyDefault
