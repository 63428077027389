import { IDraft, SaveDraftActionTypes } from '@store/drafts'

export const saveDraft = (draft: IDraft) => ({
  payload: draft,
  type: SaveDraftActionTypes.SAVE_DRAFT_REQUEST,
})

export const saveDraftSuccess = (draft: IDraft) => ({
  payload: draft,
  type: SaveDraftActionTypes.SAVE_DRAFT_SUCCESS,
})

export const saveDraftError = (error: any) => ({
  payload: error,
  type: SaveDraftActionTypes.SAVE_DRAFT_ERROR,
})
