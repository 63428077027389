import { FC } from 'react'

import { FormattedMessage } from 'react-intl'

import { NoticeEmpty } from '@components/UI/Notice'
import { usePreapprovalAmountPercentage } from '@hooks/usePreapprovalAmountPercentage'
import { Container } from '@tribegroup'

interface IProps {
  declinedFilter: string
}

const SubmissionCardListEmptyDeclined: FC<IProps> = ({ declinedFilter }) => {
  const percentage = usePreapprovalAmountPercentage()
  return (
    <NoticeEmpty
      alt="Declined submissions"
      title={
        <Container maxWidth={30}>
          <FormattedMessage
            id={`inbox.cardlist.empty.title.declined.${declinedFilter}`}
            values={{ percentage }}
          />
        </Container>
      }
      subtitle={
        <FormattedMessage id={`inbox.cardlist.empty.subtitle.declined.${declinedFilter}`} />
      }
    />
  )
}

export default SubmissionCardListEmptyDeclined
