import React, { PropsWithChildren } from 'react'

import { FormattedMessage } from 'react-intl'

import { BrandFanFilters } from '@enums'
import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import { IconWrapper } from './Header.styled'

interface IProps {
  count?: number
  type: BrandFanFilters
  titleFontSize?: number
}

const IdentityHeaderTitle = ({ type, count }) => {
  if (count) {
    return <FormattedMessage id={`brandFans.sidebar.filter.${type}.withCount`} values={{ count }} />
  }
  return <FormattedMessage id={`brandFans.sidebar.filter.${type}`} />
}

const BrandFanGroupHeaderTitle = ({ count }) => {
  if (count) {
    return (
      <FormattedMessage
        id={`brandFans.sidebar.filter.brandFanGroups.withCount`}
        values={{ count }}
      />
    )
  }
  return <FormattedMessage id={`brandFans.sidebar.filter.brandFanGroups`} />
}

const BrandFansHeader: React.FC<PropsWithChildren<IProps>> = ({
  count,
  type,
  titleFontSize = 1.75,
  children,
}) => {
  return (
    <Container textAlign="center">
      <IconWrapper center>
        <Icon glyph="star-rating" size={1.5} color={Theme.primaryColor} />
      </IconWrapper>
      <Container topOuterSpacing={1}>
        <Text color={Theme.defaultColor} customFontSize={titleFontSize} light>
          {type === BrandFanFilters.Groups ? (
            <BrandFanGroupHeaderTitle count={count} />
          ) : (
            <IdentityHeaderTitle count={count} type={type} />
          )}
        </Text>
      </Container>
      <Container topOuterSpacing={1}>{children}</Container>
    </Container>
  )
}

export { BrandFansHeader }
export default BrandFansHeader
