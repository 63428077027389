import { PCRChannels } from '@enums'
import { IPCRPerformanceMap } from '@store/postCampaignReport'

export const submissionPerformanceMap: Record<string, ReadonlyArray<IPCRPerformanceMap>> = {
  [PCRChannels.Facebook]: [
    { key: 'fb.post.followers', prop: 'followers' },
    { key: 'fb.post.likes', prop: 'likes' },
    { key: 'fb.post.comments', prop: 'comments' },
    { key: 'fb.post.shares', prop: 'shares' },
    { key: 'fb.post.engagement', prop: 'engagement', showTooltip: true },
    {
      key: 'fb.post.engagementRate',
      prop: 'engagement_rate',
      showTooltip: true,
      showPercentSign: true,
    },
    { key: 'fb.post.costPerEngagement', prop: 'cost_per_engagement', showTooltip: true },
    { key: 'fb.post.purchaseAmount', prop: 'purchase_amount', showTooltip: true },
  ],
  [PCRChannels.Twitter]: [
    { key: 'twitter.post.followers', prop: 'followers' },
    { key: 'twitter.post.likes', prop: 'likes' },
    { key: 'twitter.post.comments', prop: 'comments' },
    { key: 'twitter.post.retweets', prop: 'shares' },
    { key: 'twitter.post.engagement', prop: 'engagement', showTooltip: true },
    {
      key: 'twitter.post.engagementRate',
      prop: 'engagement_rate',
      showTooltip: true,
      showPercentSign: true,
    },
    { key: 'twitter.post.costPerEngagement', prop: 'cost_per_engagement', showTooltip: true },
    { key: 'twitter.post.purchaseAmount', prop: 'purchase_amount', showTooltip: true },
  ],
}
