import React from 'react'

import { FormattedMessage } from 'react-intl'

import TribeOnly from '@components/UI/TribeOnly'
import { BRANDED_CONTENT_ADS_HOW_TO } from '@constants'
import Theme from '@theme'
import { Link, Text } from '@tribegroup'

const SubmissionCardListEmptyBrandedContentAdEducationLink: React.FC = () => (
  <TribeOnly>
    <Link href={BRANDED_CONTENT_ADS_HOW_TO} target="_blank" color={Theme.secondaryColor}>
      <Text color={Theme.primaryColor}>
        <FormattedMessage id="core.text.learnMore.withArrow" />
      </Text>
    </Link>
  </TribeOnly>
)

export default SubmissionCardListEmptyBrandedContentAdEducationLink
