import { Component } from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { IOption, selectCountriesAsLabelValue } from '@store/rootResource'
import { Select } from '@tribegroup'

interface ICountriesDropdownProps {
  disabled?: boolean
  isInvalid?: boolean
  onFocus?: any
  onChange?: (option: any) => void
  scheme?: any
  selectedCountry?: object
  defaultCountryCode?: string
}

interface IInternalProps extends ICountriesDropdownProps, InjectedIntlProps {
  countryOptions: ReadonlyArray<IOption>
}

export class CountriesDropdown extends Component<IInternalProps> {
  render() {
    const {
      intl,
      isInvalid,
      onFocus,
      onChange,
      countryOptions = [],
      disabled,
      selectedCountry,
      defaultCountryCode,
    } = this.props

    const value =
      selectedCountry || countryOptions.find((country) => country.value === defaultCountryCode)

    return (
      <Select
        iconSize={1.5}
        fontSize={0.875}
        invalid={isInvalid}
        rsConfig={{
          isDisabled: disabled,
          inputId: 'country_code',
          name: 'country_code',
          onFocus,
          onChange,
          options: countryOptions as any,
          value,
          placeholder: intl.formatMessage({
            id: 'core.text.country',
          }),
        }}
      />
    )
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  countryOptions: selectCountriesAsLabelValue(state),
})

export default compose<IInternalProps, ICountriesDropdownProps>(
  injectIntl,
  connect(mapStateToProps),
)(CountriesDropdown)
