import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Text } from '@tribegroup'

interface IProps {
  errorId: string
}

const BuilderBriefChannelsGenericError: React.FC<IProps> = ({ errorId }) => {
  return (
    <Text small color={Theme.errorColor} block>
      <FormattedMessage id={errorId} />
    </Text>
  )
}

export { BuilderBriefChannelsGenericError }
export default BuilderBriefChannelsGenericError
