import styled from 'styled-components'

import Theme from '@theme'
import { Link } from '@tribegroup'

export const AttachmentFileName = styled(Link)`
  color: ${Theme.defaultColor};
  font-size: 0.875rem;
  font-weight: 500;
  line-break: anywhere;
  display: flex;
  align-items: center;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 0.75rem;
  background: rgb(0 0 0 / 4.3%);
  min-width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
`
