import React from 'react'

const useFormattedNumberFieldValidation = (
  defaultValue = 0,
  prefix: string,
  format: (value: number) => string,
) => {
  const [value, setValue] = React.useState<number>(defaultValue)
  const [formattedValue, setFormattedValue] = React.useState<string>(prefix || '')

  const stripPrefix = (rawValue: string) => {
    if (prefix) {
      return rawValue.replace(prefix, '')
    }
    return rawValue
  }
  const stripFormat = (rawValue: string) => {
    return rawValue.split(',').join('')
  }
  const setValueByElementEvent = (event: React.FormEvent<HTMLInputElement>) => {
    const rawValue = event.currentTarget.value
    const noPrefixValue = stripPrefix(rawValue)
    const noFormattedValue = stripFormat(noPrefixValue)
    const newValue = parseInt(noFormattedValue, 10)
    if (rawValue === prefix || rawValue === '') {
      setValue(0)
    } else if (isNaN(newValue)) {
      event.preventDefault()
    } else {
      setValue(newValue)
    }
  }

  const setValueByNumber = (newValue: number) => {
    setValue(newValue)
  }

  React.useEffect(() => {
    setFormattedValue(`${prefix ? prefix : ''}${format ? format(value) : value}`)
  }, [value])

  return {
    setValueByElementEvent,
    setValueByNumber,
    value,
    formattedValue,
    valueInCents: value * 100,
  }
}

export { useFormattedNumberFieldValidation }
