import styled, { css } from 'styled-components'

import Theme from '@theme'

export default styled.div`
  background-color: ${Theme.grey100Color};
  min-height: 100vh;
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
`
