import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import SubmissionThumbnailStatusLabel from '.'

const SubmissionThumbnailStatusLabelLicenseExpired: React.FC = () => (
  <SubmissionThumbnailStatusLabel
    firstText={<FormattedMessage id="core.text.license" />}
    secondText={<FormattedMessage id="core.text.expired" />}
    color={Theme.errorColor}
  />
)

export default SubmissionThumbnailStatusLabelLicenseExpired
