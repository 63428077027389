import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Grid } from 'react-styled-flexboxgrid'

import { TRIBE_PRICING_PAGE_METRIC_SUMMARY_UTM } from '@constants'
import { SubmissionChannels } from '@enums'
import { IApplicationState } from '@store'
import { selectBriefChannelPreference, selectCampaign } from '@store/campaigns'
import {
  selectAvgEngagementPct,
  selectAvgReachPct,
  selectOverallCPE,
  selectOverallCPM,
  selectTotalEngagement,
  selectTotalEngagementPct,
  selectTotalFollowers,
  selectTotalImpressions,
  selectTotalPosts,
  selectTotalReach,
  selectTotalSpend,
  selectUniqueCreators,
} from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import AnalyticsPostCampaignReportMetric from '../Metric/Metric'
import { StyledCol, StyledRow } from './Summary.styled'

const externalLink = TRIBE_PRICING_PAGE_METRIC_SUMMARY_UTM

const AnalyticsPostCampaignReportSummary = () => {
  const match = useRouteMatch()

  const {
    totalPosts,
    uniqueCreators,
    totalFollowers,
    totalSpend,
    totalEngagement,
    totalEngagementPct,
    overallCPE,
    totalReach,
    avgReachPct,
    totalImpressions,
    overallCPM,
    channelPreference,
    isPriceHidden,
  } = useSelector((state: IApplicationState) => {
    const campaignId = selectCampaignId(match)!
    const campaign = selectCampaign(state, campaignId)

    return {
      totalPosts: selectTotalPosts(state, campaignId),
      uniqueCreators: selectUniqueCreators(state, campaignId),
      totalFollowers: selectTotalFollowers(state, campaignId),
      totalSpend: selectTotalSpend(state, campaignId),
      totalEngagement: selectTotalEngagement(state, campaignId),
      totalEngagementPct: selectTotalEngagementPct(state, campaignId),
      avgEngagementPct: selectAvgEngagementPct(state, campaignId),
      overallCPE: selectOverallCPE(state, campaignId),
      totalReach: selectTotalReach(state, campaignId),
      avgReachPct: selectAvgReachPct(state, campaignId),
      totalImpressions: selectTotalImpressions(state, campaignId),
      overallCPM: selectOverallCPM(state, campaignId),
      channelPreference: selectBriefChannelPreference(state, campaignId),
      isPriceHidden: campaign.is_price_hidden,
    }
  })

  const channelIncludesTiktok = channelPreference.includes(SubmissionChannels.TikTok)

  return (
    <Grid fluid>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-posts-cell">
          <AnalyticsPostCampaignReportMetric title="totalPosts" value={totalPosts} />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="unique-creators-cell">
          <AnalyticsPostCampaignReportMetric title="uniqueCreators" value={uniqueCreators} />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-followers-cell">
          <AnalyticsPostCampaignReportMetric title="totalFollowers" value={totalFollowers} />
        </StyledCol>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="total-spend-cell">
            <AnalyticsPostCampaignReportMetric
              title="totalSpend"
              value={totalSpend.amount}
              currency={totalSpend.currency}
            />
          </StyledCol>
        )}
      </StyledRow>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-engagement-cell">
          <AnalyticsPostCampaignReportMetric title="totalEngagement" value={totalEngagement} />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-engagement-pct-cell">
          <AnalyticsPostCampaignReportMetric
            title="totalEngagementPct"
            value={totalEngagementPct}
            showPercentSign
          />
        </StyledCol>

        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="overall-cpe-cell">
            <AnalyticsPostCampaignReportMetric
              title="overallCPE"
              value={overallCPE.amount}
              currency={overallCPE.currency}
            />
          </StyledCol>
        )}
      </StyledRow>

      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-reach-cell">
          <AnalyticsPostCampaignReportMetric
            title="totalReach"
            value={totalReach}
            externalLink={externalLink}
            productMetricValue="total_reach"
            showAsterisk={channelIncludesTiktok}
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="avg-reach-pct-cell">
          <AnalyticsPostCampaignReportMetric
            title="avgReachPct"
            value={avgReachPct}
            showPercentSign
            externalLink={externalLink}
            productMetricValue="avg_reach_pct"
            showAsterisk={channelIncludesTiktok}
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-impressions-cell">
          <AnalyticsPostCampaignReportMetric
            title="totalImpressions"
            value={totalImpressions}
            externalLink={externalLink}
            productMetricValue="total_impressions"
          />
        </StyledCol>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="overall-cpm-cell">
            <AnalyticsPostCampaignReportMetric
              title="overallCPM"
              value={overallCPM && overallCPM.amount}
              currency={overallCPM && overallCPM.currency}
              externalLink={externalLink}
              productMetricValue="overall_cpm"
            />
          </StyledCol>
        )}
      </StyledRow>
    </Grid>
  )
}

export { AnalyticsPostCampaignReportSummary }
export default AnalyticsPostCampaignReportSummary
