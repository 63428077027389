import React, { PropsWithChildren } from 'react'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { useCampaignIdFromRoute } from '@hooks/useCampaignIdFromRoute'

interface IProps {
  submissionId: number
}

const BrandedContentAdBarButtonWrapper: React.FC<PropsWithChildren<IProps>> = ({
  submissionId,
  children,
}) => {
  const campaignId = useCampaignIdFromRoute()
  return (
    <ConnectedTrackedRoute
      campaignId={campaignId}
      submissionId={submissionId}
      eventName={EventTrackingNames.ViewBCAModal}
    >
      {children}
    </ConnectedTrackedRoute>
  )
}

export { BrandedContentAdBarButtonWrapper }
export default BrandedContentAdBarButtonWrapper
