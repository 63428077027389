import React from 'react'

import SVGInline from 'react-svg-inline'
import styled, { css } from 'styled-components'

import { defaultTheme } from '../Theme'
interface ISvgWrapperProps {
  size?: number
  shadowed?: boolean
}

export const SvgWrapper = styled<ISvgWrapperProps & React.HtmlHTMLAttributes<HTMLDivElement>, any>(
  'div',
)`
  display: inline-flex;
  position: relative;

  ${({ verticalAlign }) =>
    verticalAlign &&
    css`
      vertical-align: ${verticalAlign};
    `}
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};
  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(${rotate}deg);
    `};
  svg {
    width: ${({ size }) => (size ? `${size}rem` : '2rem')};
    height: ${({ size }) => (size ? `${size}rem` : '2rem')};
    vertical-align: middle;
    ${({ shadowed }) =>
      shadowed &&
      css`
        filter: drop-shadow(0 0.1875rem 0.1875rem rgb(0 0 0 / 15%));
      `}
    ${({ opacity }) =>
      opacity &&
      css`
        opacity: ${opacity};
      `}
  }
`

export interface IGlyphProps {
  /** The name of the icon to display */
  glyph: string

  /** Disabled state of the icon
   *
   * @default undefined
   */
  disabled?: boolean

  /** The color of the icon.
   *
   * @default grey
   */
  color?: string

  id?: string

  /**
   * Display icon with dropshadow
   *
   * @default false
   */
  shadowed?: boolean

  /**
   * Add opacity to the icon
   *
   * @default undefined
   */
  opacity?: number

  /**
   * rotates the icon around a fixed point on the 2D plane
   */
  rotate?: number

  /**
   * sets vertical alignment of an inline, inline-block or table-cell box.
   */
  verticalAlign?: string
}
export interface IIconProps extends IGlyphProps {
  /**
   * Additonal class name to be added to the icon
   *
   * @default ""
   */
  className?: string

  /**
   * The size of the icon in rem
   *
   * @default 1
   */
  size?: number

  /**
   * Applies fill attribute
   */
  filled?: boolean

  /**
   * Callback triggered upon click
   *
   * @default undefined
   */
  onClick?: (event: React.SyntheticEvent) => void
}

export default class Icon extends React.PureComponent<IIconProps> {
  static defaultProps = {
    size: 1,
  }

  getIcon = (glyph: string, color?: string) => {
    const inlineProps = color ? { fill: color, cleanup: true } : {}
    try {
      return <SVGInline svg={require(`./icons/${glyph}.svg`)} {...inlineProps} />
    } catch (error) {
      // tslint:disable-next-line
      console.error(`icon ${glyph} could not be loaded`)
      return null
    }
  }

  public render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { className, glyph, size, color, disabled, onClick, filled, ...otherProps } = this.props
    const fill = disabled ? defaultTheme.grey600Color : color
    return (
      <SvgWrapper
        size={size}
        className={className}
        onClick={onClick}
        {...otherProps}
        data-icon-glyph={glyph}
      >
        {this.getIcon(glyph, filled || filled === undefined ? fill : undefined)}
      </SvgWrapper>
    )
  }
}
