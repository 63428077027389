import { FormattedMessage } from 'react-intl'

import {
  CheckItem,
  CoachmarkBlock,
  CoachmarkContent,
  CoachmarkSection,
  ImageWrapper,
  SideBarContentWrapper,
  SmallTextWrapper,
  TipImageWrapper,
} from '@components/UI/Coachmark/Coachmark.styled'
import Theme from '@theme'
import { Header, Icon, Text, Tip } from '@tribegroup'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const image1_1x = require('@images/campaignbuilder/cb-step2-campaigns-image-01.jpg')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const image1_2x = require('@images/campaignbuilder/cb-step2-campaigns-image-01@2x.jpg')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const image1_3x = require('@images/campaignbuilder/cb-step2-campaigns-image-01@3x.jpg')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const image2_2x = require('@images/campaignbuilder/cb-step2-campaigns-image-02@2x.jpg')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const image2_3x = require('@images/campaignbuilder/cb-step2-campaigns-image-02@3x.jpg')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const image3_2x = require('@images/campaignbuilder/cb-step2-campaigns-image-03@2x.jpg')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const image3_3x = require('@images/campaignbuilder/cb-step2-campaigns-image-03@3x.jpg')

export const CoachMarkContent = () => {
  return (
    <CoachmarkContent data-cy-element="how-to-nail-your-cover-image-tip-pop-up-wrapper">
      <CoachmarkSection width={15.625}>
        <CoachmarkBlock>
          <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.5}>
            <FormattedMessage id="builder.campaign.coverimage.coach.requirements" />
          </Header>
          <CheckItem>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.campaign.coverimage.coach.ratio" />
            </Text>
          </CheckItem>
          <CheckItem>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.campaign.coverimage.coach.dimensions" />
            </Text>
          </CheckItem>
          <CheckItem>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.campaign.coverimage.coach.filetype" />
            </Text>
          </CheckItem>
        </CoachmarkBlock>
        <CoachmarkBlock>
          <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.5}>
            <FormattedMessage id="builder.campaign.coverimage.coach.remember" />
          </Header>
          <Text color={Theme.grey900Color}>
            <FormattedMessage id="builder.campaign.coverimage.coach.remember.body" />
          </Text>
        </CoachmarkBlock>
        <CoachmarkBlock>
          <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.5}>
            <FormattedMessage id="builder.campaign.coverimage.coach.hottip" />
          </Header>
          <Text color={Theme.grey900Color}>
            <FormattedMessage id="builder.campaign.coverimage.coach.hottip.body" />
          </Text>
        </CoachmarkBlock>
      </CoachmarkSection>
      <CoachmarkSection width={18.875}>
        <CoachmarkBlock>
          <Header small uppercase color={Theme.grey700Color}>
            <CheckItem>
              <Icon glyph="tick-heavy" color={Theme.successColor} size={1} />
              <Header small uppercase color={Theme.grey700Color} inline>
                <FormattedMessage id="builder.campaign.coverimage.coach.do" />
              </Header>
            </CheckItem>
          </Header>
          <ImageWrapper>
            <img srcSet={`${image2_2x} 300w, ${image2_3x} 768w`} alt={image2_2x} />
          </ImageWrapper>
          <SmallTextWrapper>
            <Text xsmall color={Theme.defaultColor}>
              <FormattedMessage id="builder.campaign.coverimage.coach.do.body" />
            </Text>
          </SmallTextWrapper>
        </CoachmarkBlock>
        <CoachmarkBlock>
          <Header small uppercase color={Theme.grey700Color}>
            <CheckItem>
              <Icon glyph="cross-heavy" color={Theme.alertColor} size={1} />
              <Header small uppercase color={Theme.grey700Color} inline>
                <FormattedMessage id="builder.campaign.coverimage.coach.dont" />
              </Header>
            </CheckItem>
          </Header>
          <ImageWrapper withCross crossColor={Theme.primaryColor}>
            <img srcSet={`${image3_2x} 300w, ${image3_3x} 768w`} alt={image3_2x} />
          </ImageWrapper>
          <SmallTextWrapper>
            <Text xsmall color={Theme.defaultColor}>
              <FormattedMessage id="builder.campaign.coverimage.coach.dont.body" />
            </Text>
          </SmallTextWrapper>
        </CoachmarkBlock>
      </CoachmarkSection>
    </CoachmarkContent>
  )
}

export const BuilderCampaignCoverImageTip = () => {
  return (
    <SideBarContentWrapper>
      <TipImageWrapper>
        <img srcSet={`${image1_1x} 300w, ${image1_2x} 768w, ${image1_3x} 1280w`} alt={image1_1x} />
      </TipImageWrapper>
      <Tip
        text={
          <Text xsmall uppercase data-cy-element="how-to-nail-your-cover-image-tip">
            <FormattedMessage id="builder.campaign.coverimage.tip" />
          </Text>
        }
        width={10}
        coachmark={{
          content: <CoachMarkContent />,
          position: 'left',
          width: 40.4375,
          yOffset: 11,
        }}
      />
    </SideBarContentWrapper>
  )
}

export default BuilderCampaignCoverImageTip
