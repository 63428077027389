import { PageRoutes } from '@enums'
import { IMeta } from '@store'
import { ICampaign } from '@store/campaigns'
import { ISubmission } from '@store/submissions'

export const buildSubmissionUrl = (campaign: ICampaign, submission: ISubmission) =>
  `/${PageRoutes.Campaigns}/${campaign.id}/${PageRoutes.Submissions}/${submission.id}`

export const buildSubmissionUrlById = (campaignId: number, submissionId?: number) =>
  submissionId && `/${PageRoutes.Campaigns}/${campaignId}/${PageRoutes.Submissions}/${submissionId}`

export const isActiveMenu = (
  currentPath: string,
  targetPath: string,
  currentFilter?: string | number,
  targetFilter?: string | number,
) => {
  return currentPath.includes(targetPath) && currentFilter === targetFilter
}

const updateQueryParamEntry = (
  link: string,
  queryParam: string,
  replaceableToken: string,
  filterValue?: string,
) => {
  if (filterValue) {
    return link.replace(replaceableToken, filterValue)
  }
  return link.replace(queryParam, '')
}

export const sanitizeQueryParams = (link: string, { filter, sortBy, declinedFilter }: IMeta) => {
  let sanitizedLink = link.replace('{filter}', filter as string)
  sanitizedLink = updateQueryParamEntry(sanitizedLink, '&sort_by={sort_by}', '{sort_by}', sortBy)
  sanitizedLink = updateQueryParamEntry(
    sanitizedLink,
    '&declined_filter={declined_filter}',
    '{declined_filter}',
    declinedFilter,
  )
  return sanitizedLink
}
