import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Flex from 'styled-flex-component'

export const StyledHeader = styled.div`
  margin-top: 5rem;
  text-align: center;
`
export const Wrapper = styled(Flex)`
  padding: 3.5rem 0;
  box-sizing: border-box;
  min-height: 70vh;
`

export const AddBrand = styled.div`
  text-align: center;
`

export const BrandFlexWrapper = styled(Flex)`
  > div {
    margin: 0.75rem;
  }
`

export const ProfileGrid = styled.div`
  display: grid;
  box-sizing: border-box;
  grid-gap: 1.5rem;
  ${breakpoint('tabletPortrait')`
    grid-template-columns: repeat(3, 1fr);
  `};
  ${breakpoint('desktop')`
    grid-template-columns: repeat(4, 1fr);
  `};
`

export const TipWrapper = styled.div`
  padding-bottom: 2.5rem;
`

export const FileDropWrapper = styled.div`
  padding: 2.5rem;
`
