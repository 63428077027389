import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import { selectCampaignIsSaving } from '@store/campaigns'
import { selectCampaignIdFromDraft } from '@store/drafts'
import { selectIsAnyUploading } from '@store/moodboard'
import { Button } from '@tribegroup'
import { useIsButtonLoading } from './useIsButtonLoading'

interface IInternalProps extends ConnectedProps<typeof connector>, IBuilderValidationContextProps {}

const BuilderTitleBarActionsButtonContinue: React.FC<IInternalProps> = ({
  isSaving,
  isEnableSubmit,
  isEnableActions,
  validationResults,
  isAnyUploading,
}) => {
  const disabled = isSaving || !isEnableSubmit || !isEnableActions

  const { loading, startLoading, endLoading } = useIsButtonLoading(isSaving)

  const onClick = () => {
    if (!isAnyUploading) {
      startLoading()
    }
  }

  React.useEffect(() => {
    const hasValidationError = Object.values(validationResults).some((result) => result.hasError)
    if (hasValidationError) {
      endLoading()
    }
  }, [validationResults])

  return (
    <Button
      scheme="primary"
      small
      disabled={disabled}
      data-cy-element="continue-button"
      data-testid="campaign-builder-continue-button"
      width={9.1875}
      onClick={onClick}
      loading={loading}
    >
      <FormattedMessage id="core.text.continue" />
    </Button>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  const campaignId = selectCampaignIdFromDraft(state)
  return {
    campaignId,
    isSaving: selectCampaignIsSaving(state),
    isAnyUploading: campaignId && selectIsAnyUploading(state, campaignId!),
  }
}

const connector = connect(mapStateToProps)

export { BuilderTitleBarActionsButtonContinue }
export default compose(withBuilderValidation, connector)(BuilderTitleBarActionsButtonContinue)
