import React, { PropsWithChildren } from 'react'

import { Waypoint } from 'react-waypoint'

import { InformationTooltipContext, XPositionType } from '@context/InformationTooltip'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'
import { pxToRem } from '@utils'

interface IProps {
  glyph?: string
  iconSize?: number
  iconColor?: string
  iconColorOnHover?: string
  width?: number
  xPosition?: XPositionType
  testId?: string
  waypointYOffset?: number
  topOffset?: number
}

const HoverableInformation: React.FC<PropsWithChildren<IProps>> = ({
  children,
  width,
  iconSize,
  iconColor = Theme.grey900Color,
  iconColorOnHover = Theme.grey900Color,
  xPosition = 'left',
  topOffset = 0,
  glyph = 'information',
  testId,
  waypointYOffset,
}) => {
  const [showBelowTarget, setShowBelowTarget] = React.useState<boolean>(false)

  const onPositionChange = ({ currentPosition }: Waypoint.CallbackArgs) => {
    setShowBelowTarget(currentPosition === 'above')
  }

  const { showTooltip, hideTooltip, tooltipVisible } = React.useContext(InformationTooltipContext)
  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement
    const { left, top } = target.getBoundingClientRect()

    showTooltip(
      children,
      {
        x: pxToRem(left),
        y: pxToRem(top + window.scrollY + topOffset),
      },
      xPosition,
      width,
      {
        hoverable: true,
        showBelowTarget,
      },
    )
  }

  const onMouseLeave = () => {
    hideTooltip()
  }
  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} inherit data-testid={testId}>
      <Waypoint
        onPositionChange={onPositionChange}
        bottomOffset={waypointYOffset}
        topOffset={waypointYOffset}
      >
        <div>
          <Container pointer inlineBlock>
            <Icon
              glyph={glyph}
              size={iconSize}
              color={tooltipVisible ? iconColorOnHover : iconColor}
            />
          </Container>
        </div>
      </Waypoint>
    </Container>
  )
}

export default HoverableInformation
