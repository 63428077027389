import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchMeSuccess,
  selectUpdateContactLink,
  updateContact,
  UpdateContactActionTypes,
  updateContactError,
} from '@store/me'
import { networkRequest } from '@utils'

export function* handleUpdateContact(action: ReturnType<typeof updateContact>) {
  try {
    const { history, redirect } = action.meta
    const authToken: string = yield select(selectAuthToken)
    const updateContactLink: IHateoasLink = yield select(selectUpdateContactLink)
    const me = yield call(
      networkRequest,
      updateContactLink.method,
      updateContactLink.href,
      action.payload,
      authToken,
    )
    yield put(fetchMeSuccess(me))
    if (history && redirect) {
      yield call(history.push, redirect)
    }
  } catch (error) {
    yield put(updateContactError(error))
  }
}

export function* watchUpateContactRequest() {
  yield takeLatest(UpdateContactActionTypes.UPDATE_CONTACT_REQUEST, handleUpdateContact)
}
