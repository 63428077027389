import React from 'react'

import { compose } from 'recompose'

import CampaignShareModal from '@components/Campaign/Share/Modal'
import { ActionIconWrapper } from '@components/Inbox/TitleBar/TitleBar.styled'
import ActionIcon from '@components/UI/ActionIcon'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IModalContextProps } from '@context/Modal'
import { EventTrackingNames } from '@enums'
import { withModal } from '@hocs'
import Theme from '@theme'

interface IProps {
  campaignId: number
}

interface IInternalProps extends IProps, IModalContextProps {}

const CampaignShareTrigger: React.FC<IInternalProps> = ({ showModal, campaignId }) => {
  const onOpenModal = () => showModal(CampaignShareModal, { campaignId })
  return (
    <ActionIconWrapper>
      <ConnectedTrackedRoute
        eventName={EventTrackingNames.ToolbarShareCampaignDeeplink}
        campaignId={campaignId}
      >
        <ActionIcon
          disabled={false}
          data-testid="campaign-share-trigger"
          glyph="share-universal"
          color={Theme.grey900Color}
          size={1.5}
          onClick={onOpenModal}
        />
      </ConnectedTrackedRoute>
    </ActionIconWrapper>
  )
}

export default compose<IInternalProps, IProps>(withModal)(CampaignShareTrigger)
