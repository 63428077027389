import styled from 'styled-components'

import Theme from '@theme'
import { Link as UILink } from '@tribegroup'

const Link = styled(UILink)`
  color: ${Theme.primaryColor};
  border-radius: 0.25rem;
  border: 1px solid ${Theme.primary100Color};
`

export { Link }
