import {
  BrandManagerIdentityProviders,
  CampaignStatuses,
  MemberRoleTypes,
  SocialSubmissionTypes,
  SubmissionChannels,
} from '@enums'
import { IResponseError } from '@lib/error'
import { IHateoasLink } from '@store'

interface ICampaignRate {
  maximum_rate?: number | null
  minimum_rate: number
  maximum_followers?: number | null
  minimum_followers: number
  append_plus_to_max?: boolean
}

export interface ICampaignRateCard {
  rates_currency: string
  video_motion_content_rate: string
  image: ReadonlyArray<ICampaignRate>
  story: string
  carousel: string
  tiktok: ReadonlyArray<ICampaignRate>
}

export interface ICampaignPreferences {
  require_approval_selected_option?: boolean
  show_add_handle_reminder?: boolean
  show_instagram_connect_prompt?: boolean
  show_instagram_reconnect_prompt?: boolean
}

export interface ILicenseOption {
  label: string
  source?: string
  media_type?: string
  submission_type?: string
  description: string
  tax_display_name: string
  license_type?: string
  total_amount_before_tax_cents: number
  total_amount_before_tax_currency: string
  links?: ReadonlyArray<IHateoasLink>
  preapproval?: boolean
  preapproval_amount_before_tax_cents?: number
  preapproval_amount_before_tax_currency?: string
  amount_paid_before_tax_cents?: number
  amount_paid_before_tax_currency?: string
  balance_amount_before_tax_cents?: number
  balance_amount_before_tax_currency?: string
}

export interface IConnectedSocialAccount {
  channel: BrandManagerIdentityProviders
  display_url: string
  display_name: string
  id: string
  oauth_uid: string
  links?: IHateoasLink[]
  reconnect_required?: boolean
}

export interface IBaseCampaignProps {
  id: number
  brand_id?: number
  brand_logo?: string
  brand_name?: string
  donts?: ReadonlyArray<string>
  dos?: ReadonlyArray<string>
  house_rules?: ReadonlyArray<string>
  moodboard_images?: ReadonlyArray<IMoodboardImage>
  channels: ReadonlyArray<IChannel>
  demographic_gender?: ReadonlyArray<string>
  demographic_min_age?: number
  demographic_max_age?: number
  demographic_target_region_ids?: ReadonlyArray<number>
  demographic_interests?: ReadonlyArray<string>
  audience_age_range?: ReadonlyArray<string>
  audience_gender?: ReadonlyArray<string>
  audience_location_codes?: ReadonlyArray<string>
  supported_submission_types: ReadonlyArray<string>
  budget_total_cents?: number
  budget_total_currency: string
  budget_spent_approvals_cents?: number
  budget_spent_approvals_currency?: string
  budget_spent_preapprovals_cents?: number
  budget_spent_preapprovals_currency?: string
  budget_spent_outstandings_cents?: number
  budget_spent_outstandings_currency?: string
  budget_spent_content_licenses_cents?: number
  budget_spent_content_licenses_currency?: string
  budget_spent_branded_content_ad_cents?: number
  budget_spent_branded_content_ad_currency?: string
  budget_spent_activation_fee_cents?: number
  budget_spent_activation_fee_currency?: string
  budget_spent_ad_hoc_purchases_cents?: number
  budget_spent_ad_hoc_purchases_currency?: string
  call_to_action: string
  follower_threshold?: number
  min_follower_threshold: number
  max_follower_threshold: number
  hero_16x9_original_url?: string
  hero_original_url?: string
  objective: string
  role: MemberRoleTypes
  payment_method: string
  product_name: string
  product_text: string
  created_at?: string
  updated_at?: string
  product_where_to_find?: string
  product_where_to_find_option?: string
  product_category_primary_id?: number
  product_category_secondary_id?: number
  status: CampaignStatuses
  end_date: string
  step_completed?: string
  sampling_enabled?: boolean
  license_card?: ReadonlyArray<ILicenseOption>
  links?: ReadonlyArray<IHateoasLink>
  paid_partnership_handle?: string
  has_brand_partnership_handle?: boolean | ''
  has_tiktok_ad_manager_account?: boolean
  has_approved_tiktok_submission?: boolean
  has_tiktok_campaign_id?: boolean
  brand_fan_group_ids?: ReadonlyArray<number>
  requested_social_submission_types?: ReadonlyArray<string>
  accounting_region_code: string
  budget_spent_cents?: number
  budget_spent_currency?: string
  rate_card?: ICampaignRateCard
  start_date?: string
  disabled_attributes?: ReadonlyArray<string>
  allowed_to_associate_brand_fan_group?: boolean
  interested_in_ongoing_collabs?: boolean
  preapproval_allowed?: boolean
  activation_fee_waived: boolean
  indicative_budget_cents?: number
  indicative_budget_currency?: string
  pitching_enabled?: boolean
  activation_fee_status?: 'waived' | 'default' | 'overridden'
  activation_fee_cents?: number
  activation_fee_currency?: string
  is_brand_fans?: boolean
  requested_submission_types?: Partial<Record<SubmissionChannels, SocialSubmissionTypes[]>>
  traditional?: boolean
  is_price_hidden?: boolean
}

export interface IConnectedAccount {
  instagram_identity_id?: number
  instagram_identity_handle?: string
  instagram_identity_avatar_url?: string
  instagram_identity_reconnect_required?: boolean
}

export interface ICampaign extends IBaseCampaignProps, IConnectedAccount {
  auto_approval_amount_cents?: number
  auto_approval_amount_currency?: string
  completion_at?: string
  budget_remaining_cents?: number
  budget_remaining_currency?: string
  hero_thumbnail_url?: string
  product_link?: string
  ended_at?: string
  pcr_generatable?: boolean
  preferences?: Partial<ICampaignPreferences>
  firebase_url?: string
  connected_social_accounts?: IConnectedSocialAccount[]
  invitable_roles?: MemberRoleTypes[]
  has_proxy_submissions?: boolean
}

export interface IMoodboardImage {
  id?: number
  position?: number | null
  media_id?: string | null
  media_url?: string | null
  media_type?: string | null
  status?: 'awaiting_upload' | 'uploaded' | 'ready' | string
}

export interface IChannelAttributes {
  tags?: ReadonlyArray<string>
  handles?: ReadonlyArray<string>
  urls?: ReadonlyArray<string>
  paid_partnership_handle?: string
  has_required_social_channel_account?: boolean
  requested_social_submission_types?: string[]
}

export interface IChannel extends IChannelAttributes {
  name: SubmissionChannels
}

export interface ICampaignsState {
  readonly campaignsById: Record<number, ICampaign>
  readonly errors?: IResponseError
  readonly isFetching?: boolean
  readonly isFetched?: boolean
  readonly isFinalizing?: boolean
  readonly isActivating?: boolean
  readonly isPausing?: boolean
  readonly isSaving?: boolean
  readonly isDisablingRequireApprovalPrompt?: boolean
  readonly isUpdatingPreferences?: boolean
  readonly hasSeenPreviewTooltip?: boolean
  readonly isConnectingIdentity?: boolean
  readonly isActivatingWithFee?: boolean
  readonly campaignIdsByStatus: {
    all: ReadonlyArray<number>
    [CampaignStatuses.Active]: ReadonlyArray<number>
    [CampaignStatuses.InReview]: ReadonlyArray<number>
    [CampaignStatuses.Draft]: ReadonlyArray<number>
    [CampaignStatuses.Scheduled]: ReadonlyArray<number>
    [CampaignStatuses.Paused]: ReadonlyArray<number>
    [CampaignStatuses.Expired]: ReadonlyArray<number>
    [CampaignStatuses.Completed]: ReadonlyArray<number>
  }
  readonly stats: {
    all: number
    [CampaignStatuses.Active]: number
    [CampaignStatuses.InReview]: number
    [CampaignStatuses.Draft]: number
    [CampaignStatuses.Scheduled]: number
    [CampaignStatuses.Paused]: number
    [CampaignStatuses.Expired]: number
    [CampaignStatuses.Completed]: number
  }
  readonly linksByStatus: Record<string, ReadonlyArray<IHateoasLink>>
}

export type ApprovalType = 'final-approval' | 'pre-approval'

export enum CampaignsActionTypes {
  FETCH_REQUEST = 'campaigns/FETCH_REQUEST',
  FETCH_SUCCESS = 'campaigns/FETCH_SUCCESS',
  FETCH_ERROR = 'campaigns/FETCH_ERROR',
  UPDATE_HAS_SEEN_PREVIEW_TOOLTIP = 'campaigns/UPDATE_HAS_SEEN_PREVIEW_TOOLTIP',
}

export enum CampaignActionTypes {
  FETCH_REQUEST = 'campaign/FETCH_REQUEST',
  FETCH_SUCCESS = 'campaign/FETCH_SUCCESS',
  FETCH_ERROR = 'campaign/FETCH_ERROR',
}

export enum ActivateActionTypes {
  ACTIVATE_REQUEST = 'campaign/ACTIVATE_REQUEST',
  ACTIVATE_SUCCESS = 'campaign/ACTIVATE_SUCCESS',
  ACTIVATE_ERROR = 'campaign/ACTIVATE_ERROR',
}

export enum PauseActionTypes {
  PAUSE_REQUEST = 'campaign/PAUSE_REQUEST',
  PAUSE_SUCCESS = 'campaign/PAUSE_SUCCESS',
  PAUSE_ERROR = 'campaign/PAUSE_ERROR',
}

export enum SaveCampaignActionTypes {
  SAVE_REQUEST = 'campaign/SAVE_REQUEST',
  SAVE_SUCCESS = 'campaign/SAVE_SUCCESS',
  SAVE_ERROR = 'campaign/SAVE_ERROR',
}

export enum FinalizeCampaignActionTypes {
  FINALIZE_REQUEST = 'campaign/FINALIZE_REQUEST',
  FINALIZE_SUCCESS = 'campaign/FINALIZE_SUCCESS',
  FINALIZE_ERROR = 'campaign/FINALIZE_ERROR',
}

export enum DisableRequireApprovalPromptActionTypes {
  DISABLE_REQUEST = 'campaign/DISABLE_REQUEST',
  DISABLE_SUCCESS = 'campaign/DISABLE_SUCCESS',
  DISABLE_ERROR = 'campaign/DISABLE_ERROR',
}

export enum UpdatePreferencesActionTypes {
  UPDATE_REQUEST = 'campaign/UPDATE_REQUEST',
  UPDATE_ERROR = 'campaign/UPDATE_ERROR',
}

export enum InstagramHandlesActionTypes {
  DOWNLOAD_CSV = 'instagramHandles/DOWNLOAD_CSV',
}

export interface ILicenseGroup {
  label: string
  description: string
  options: ILicenseOption[]
}

export enum ConnectCampaignSocialAccountActionType {
  REQUEST = 'campaign/CONNECT_CAMPAIGN_SOCIAL_ACCOUNT_REQUEST',
  SUCCESS = 'campaign/CONNECT_CAMPAIGN_SOCIAL_ACCOUNT_SUCCESS',
  ERROR = 'campaign/CONNECT_CAMPAIGN_SOCIAL_ACCOUNT_ERROR',
}

export enum DisconnectCampaignSocialAccountActionType {
  REQUEST = 'campaign/DISCONNECT_CAMPAIGN_SOCIAL_ACCOUNT_REQUEST',
  SUCCESS = 'campaign/DISCONNECT_CAMPAIGN_SOCIAL_ACCOUNT_SUCCESS',
  ERROR = 'campaign/DISCONNECT_CAMPAIGN_SOCIAL_ACCOUNT_ERROR',
}

export enum ActivateWithFeeActionTypes {
  ACTIVATE_WITH_FEE_REQUEST = 'campaign/ACTIVATE_WITH_FEE_REQUEST',
  ACTIVATE_WITH_FEE_SUCCESS = 'campaign/ACTIVATE_WITH_FEE_SUCCESS',
  ACTIVATE_WITH_FEE_ERROR = 'campaign/ACTIVATE_WITH_FEE_ERROR',
}

export enum CampaignTypes {
  TRADITIONAL = 'traditional',
  SOLUTION = 'solution',
}

export interface IActivateWithFeeResponse {
  payment_intent_id: string
  payment_intent_status: string
  client_secret?: string
  brief?: ICampaign
  links: ReadonlyArray<IHateoasLink>
}
