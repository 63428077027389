import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Container, Header } from '@tribegroup'

const SubmissionCardPriceSummaryHeader = () => {
  return (
    <Container data-testid="cost-breakdown-social" bottomOuterSpacing={1.25}>
      <Header small uppercase color={Theme.primaryColor}>
        <FormattedMessage id="core.text.summary" />
      </Header>
    </Container>
  )
}

export default SubmissionCardPriceSummaryHeader
