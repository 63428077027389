import { createContext, PropsWithChildren, PureComponent } from 'react'

export interface ICurrentSubmissionIdContextProps {
  currentSubmissionId?: number
  setCurrentSubmissionId: (currentSubmissionId: number | undefined) => void
}

interface ICurrentSubmissionIdProviderState {
  currentSubmissionId?: number
}

export const CurrentSubmissionIdContext = createContext({} as ICurrentSubmissionIdContextProps)

export class CurrentSubmissionIdProvider extends PureComponent<
  PropsWithChildren,
  ICurrentSubmissionIdProviderState
> {
  state = {
    currentSubmissionId: undefined,
  }

  setCurrentSubmissionId = (currentSubmissionId: number) => {
    this.setState({ currentSubmissionId })
  }

  render() {
    const contextProps: ICurrentSubmissionIdContextProps = {
      currentSubmissionId: this.state.currentSubmissionId,
      setCurrentSubmissionId: this.setCurrentSubmissionId,
    }

    return (
      <CurrentSubmissionIdContext.Provider value={contextProps}>
        {this.props.children}
      </CurrentSubmissionIdContext.Provider>
    )
  }
}

export default CurrentSubmissionIdProvider
