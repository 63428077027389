import React from 'react'

import { ActionBarRight, ActionBarWrapper } from '@components/UI/Card/Card.styled'
import { ISubmission } from '@store/submissions'
import ActionBarIconChat from '../Icon/Chat'
import ActionBarIconLicense from '../Icon/License'

interface IProps {
  submission: ISubmission
  hasFeedbackLink: boolean
  showLicenseIcon: boolean
  campaignId: number
}

const SubmissionCardActionBarSocialDeclined: React.FC<IProps> = ({
  hasFeedbackLink,
  submission,
  campaignId,
  showLicenseIcon,
}) => {
  return (
    <ActionBarWrapper justifyEnd>
      <ActionBarRight>
        {hasFeedbackLink && <ActionBarIconChat submission={submission} campaignId={campaignId} />}
        {showLicenseIcon && <ActionBarIconLicense submissionId={submission.id} />}
      </ActionBarRight>
    </ActionBarWrapper>
  )
}

export default SubmissionCardActionBarSocialDeclined
