import { WatcherFunction } from '..'
import { watchFetchCampaignBillingRequest } from './campaignBillings'
import { watchFetchContentLibraryBillingRequest } from './contentLibraryBillings'
import { watchHandleDownloadFullReportRequest } from './downloadFullReport'

export const billingsSagas: ReadonlyArray<WatcherFunction> = [
  watchFetchCampaignBillingRequest,
  watchHandleDownloadFullReportRequest,
  watchFetchContentLibraryBillingRequest,
]
