import { ILicenseGroup, ILicenseOption } from '@store/campaigns'

export const checkIfShouldGroupLicenseOptions = (licenseOptions: ReadonlyArray<ILicenseOption>) => {
  const label = licenseOptions[0]?.label
  const firstLicenseGroup = licenseOptions.filter((licenseOption) => licenseOption.label === label)

  if (firstLicenseGroup[0]?.media_type === undefined) {
    return false
  }

  const shouldGroupLicenses =
    firstLicenseGroup.length > 1 &&
    firstLicenseGroup?.[0]?.total_amount_before_tax_cents !==
      firstLicenseGroup?.[1]?.total_amount_before_tax_cents

  return shouldGroupLicenses
}

export const groupLicenseOptions = (licenseOptions: ReadonlyArray<ILicenseOption>) => {
  const licenseGroups = Object.values(
    licenseOptions.reduce(
      (accum, curr) => ({
        ...accum,
        [curr.label]: {
          label: curr.label,
          description: curr.description,
          options: [...(accum[curr.label]?.options || []), curr],
        } as ILicenseGroup,
      }),
      {},
    ),
  ) as ReadonlyArray<ILicenseGroup>
  return licenseGroups
}
