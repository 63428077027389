import React, { PropsWithChildren } from 'react'

import { InformationTooltipContext, XPositionType } from '@context/InformationTooltip'
import { IAdditionalOptions } from '@context/InformationTooltip/Provider'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'
import { pxToRem } from '@utils'

interface IClickableInformationProps {
  width?: number
  xPosition?: XPositionType
  target?: JSX.Element
  leftOffset?: number
  onHideTooltip?: VoidFunction
  options?: Partial<IAdditionalOptions>
}

const ClickableInformation: React.FC<PropsWithChildren<IClickableInformationProps>> = ({
  children,
  width,
  xPosition = 'left',
  leftOffset = 0,
  target = <Icon glyph="information" color={Theme.grey900Color} />,
  onHideTooltip,
  options = {},
}) => {
  const { showTooltip } = React.useContext(InformationTooltipContext)
  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const node = event.currentTarget || event.target
    const { left, top } = node.getBoundingClientRect()
    showTooltip(
      children,
      {
        x: pxToRem(left) + leftOffset,
        y: pxToRem(top + window.scrollY),
      },
      xPosition,
      width,
      {
        onHideTooltip: onHideTooltip,
        ...options,
      },
    )
  }
  return (
    <Container pointer onClick={onClick} inherit>
      {target}
    </Container>
  )
}

export default ClickableInformation
