import { MediaTypes } from '@enums'
import { IResponseError } from '@lib/error'

export enum FetchCreatorBioByLinkActionTypes {
  FETCH_REQUEST = 'creatorBioByLink/FETCH_REQUEST',
  FETCH_SUCCESS = 'creatorBioByLink/FETCH_REQUEST_SUCCESS',
  FETCH_ERROR = 'creatorBioByLink/FETCH_REQUEST_ERROR',
}

export interface ICreatorBioLink {
  href: string
  method: string
}

export interface ICreatorBio {
  id: number
  headshot: IHeadshot
  full_name: string
  location: string
  age: number
  gender?: string
  rating?: number
  joining_date: string
  bio_text: string
  portfolio_items: ReadonlyArray<IPorfolioObject>
}

export interface IPorfolioObject {
  id: number
  original_url: string
  thumbnail_url: string
  media_type: MediaTypes
  order: number
}

export interface IHeadshot {
  original_url: string
  thumbnail_url: string
}

export interface ICreatorBioState {
  readonly isFetching: boolean
  readonly errors?: IResponseError
  readonly creatorBioByLink: Record<string, ICreatorBio>
}
