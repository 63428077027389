import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { MediaTypes } from '@enums'
import { IOption } from '@store/rootResource'
import { MediaObjectSearchBaseFilter } from './BaseFilter'

const mediaTypes = [MediaTypes.IMAGE, MediaTypes.VIDEO]

export const MediaTypeFilter: React.FC<InjectedIntlProps> = ({ intl }) => {
  const options: IOption[] = mediaTypes.map((mediaType) => ({
    label: intl.formatMessage({ id: `contentLibrary.search.filter.mediaType.${mediaType}` }),
    value: mediaType,
  }))

  return (
    <MediaObjectSearchBaseFilter
      allLabel={<FormattedMessage id="contentLibrary.search.filter.mediaType" />}
      options={options}
      searchKey="media_type_filter"
    />
  )
}

export default compose(injectIntl)(MediaTypeFilter)
