import { call, put, select, takeLatest } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { IHateoasLink } from '@store'
import { trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  addBrandFanGroupMember,
  createBrandFanGroup,
  CreateBrandFanGroupActionTypes,
  createBrandFanGroupError,
  createBrandFanGroupSuccess,
  fetchBrandFanGroups,
  IBrandFanGroup,
  selectCreateBrandFanGroupLink,
} from '@store/brandFans'
import { selectBrandFansCurrentView } from '@store/router'
import { networkRequest } from '@utils'

export function* handleCreateBrandFanGroup(action: ReturnType<typeof createBrandFanGroup>) {
  try {
    const { identityId, params, campaignId, submissionId, influencerId } = action.payload
    const authToken: string = yield select(selectAuthToken)
    const createBrandFanGroupLink: IHateoasLink = yield select(selectCreateBrandFanGroupLink)
    const brandFanGroup: IBrandFanGroup = yield call(
      networkRequest,
      createBrandFanGroupLink.method,
      createBrandFanGroupLink.href,
      params,
      authToken,
    )
    yield put(fetchBrandFanGroups())
    yield put(createBrandFanGroupSuccess(brandFanGroup))
    yield put(
      addBrandFanGroupMember(identityId, brandFanGroup.id, campaignId, submissionId, influencerId),
    )

    const currentBrandFansFilter = yield select(selectBrandFansCurrentView)
    yield put(
      trackEvent(EventTrackingNames.BrandFansGroupsAdd, {
        group_id: brandFanGroup.id,
        group_name: brandFanGroup.name,
        identity_id: identityId,
        current_filter: currentBrandFansFilter,
        isNewGroup: true,
      }),
    )
  } catch (error) {
    yield put(createBrandFanGroupError(error))
  }
}

export function* watchCreateBrandFanGroupRequest() {
  yield takeLatest(CreateBrandFanGroupActionTypes.CREATE_REQUEST, handleCreateBrandFanGroup)
}
