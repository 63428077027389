import { useContext } from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { useSelector } from 'react-redux'
import { compose } from 'recompose'

import { SubmissionCardModalLicensed } from '@components/Submission/Card/Modal/License.New/Licensed'
import { RequestLicense } from '@components/Submission/Card/Modal/RequestLicense/RequestLicense'
import FacebookAdAccounts from '@components/UI/FacebookAdAccounts'
import { CampaignContext } from '@context/Campaign'
import { CurrentMediaObjectIdContext } from '@context/CurrentMediaObjectId'
import { IModalContextProps } from '@context/Modal'
import { FeatureToggles } from '@enums'
import { withModal } from '@hocs'
import { selectIsSocialCampaign } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import MediaObjectLicensing from '../Licensing'

const MediaObjectsFacebookAdAccounts: React.FC<IModalContextProps> = ({ showModal, hideModal }) => {
  const isNewLicenseTypesEnabled = useFlag(FeatureToggles.NEW_LICENSE_TYPES_ENABLED)

  const { currentMediaObjectId, setCurrentMediaObjectId } = useContext(CurrentMediaObjectIdContext)

  const {
    campaign: { id: campaignId },
  } = useContext(CampaignContext)

  const isSocialCampaign = useSelector((state: IApplicationState) =>
    selectIsSocialCampaign(state, campaignId),
  )

  const onDone = () => {
    if (currentMediaObjectId) {
      setCurrentMediaObjectId(undefined)

      if (campaignId && !isSocialCampaign) {
        if (isNewLicenseTypesEnabled) {
          return showModal(RequestLicense, { currentMediaObjectId })
        }

        return showModal(SubmissionCardModalLicensed, { mediaObjectId: currentMediaObjectId })
      }

      if (isNewLicenseTypesEnabled) {
        return showModal(RequestLicense, { currentMediaObjectId })
      }

      showModal(MediaObjectLicensing, { mediaObjectId: currentMediaObjectId })
    }
  }
  return (
    <FacebookAdAccounts
      onDone={onDone}
      onMount={hideModal}
      mediaTransferProps={{
        mediaObjectId: currentMediaObjectId as number,
      }}
    />
  )
}

export { MediaObjectsFacebookAdAccounts }
export default compose<IModalContextProps, {}>(withModal)(MediaObjectsFacebookAdAccounts)
