import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ActionIcon from '@components/UI/ActionIcon'
import { IApplicationState } from '@store'
import { selectChangePasswordLink } from '@store/me'
import Theme from '@theme'
import { H5, Panel, Text } from '@tribegroup'
import { Label, List, ListItem } from './../Account.styled'

interface IAccountPasswordProps {
  disabled?: boolean
}

interface IInternalProps extends IAccountPasswordProps {
  hasChangePasswordLink: boolean
}

export const AccountPassword = ({ hasChangePasswordLink, disabled }: IInternalProps) => {
  return (
    <Panel
      disabled={disabled}
      actionIcons={
        hasChangePasswordLink
          ? [
              <Link key="edit-account" to="/account/password" data-cy-element="edit-password">
                <ActionIcon key="pencil" glyph="edit" />
              </Link>,
            ]
          : undefined
      }
      title={
        <H5 color={Theme.grey900Color}>
          <FormattedMessage id="core.text.password" />
        </H5>
      }
    >
      <List>
        <ListItem>
          <Label>
            <Text xsmall uppercase color={Theme.grey800Color}>
              <FormattedMessage id="core.text.password" />
            </Text>
          </Label>
          <Text color={Theme.grey900Color}>
            <FormattedMessage id="account.password.placeholder" />
          </Text>
        </ListItem>
      </List>
    </Panel>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  hasChangePasswordLink: Boolean(selectChangePasswordLink(state)),
})

export default connect(mapStateToProps)(AccountPassword)
