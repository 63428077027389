import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { DownloadButton, LicensingAgreementsLink } from '@components/Licensing'
import MediaObjectLicensingLicensedUploadToFacebookAdsManager from '@components/MediaObject/Licensing/Licensed/UploadToFacebookAdsManager'
import { useModal } from '@hooks/useModal'
import { IApplicationState } from '@store/index'
import { ILicense } from '@store/licenses'
import { selectMediaObject, selectUploadToFacebookLink } from '@store/mediaObjects'
import { selectPreapprovalAmountPercentage } from '@store/rootResource'
import Theme from '@theme'
import { CloseIcon, Container, H1, Modal, Text } from '@tribegroup'
import { SubmissionCardModalLicenseMediaDetails } from './Aside/MediaDetails'
import { SubmissionCardModalLicenseSelected } from './Aside/Selected'
import {
  ModalLicenseHeaderWrapper,
  ModalLicenseLicenseOptionsWrapper,
  ModalLicensePriceSummaryWrapper,
  ModalLicenseWrapper,
} from './License.styled'
import { SubmissionCardModalLicensePriceSummary } from './PriceSummary'

interface IProps {
  mediaObjectId: number
}

export const SubmissionCardModalLicensed = ({ mediaObjectId }: IProps) => {
  const { isOpen, hideModal } = useModal()

  const { activeMediaObject, preapprovalPercentage, transactedLicense, hasFacebookAdUploadLink } =
    useSelector((state: IApplicationState) => {
      const activeMediaObject = selectMediaObject(state, mediaObjectId)

      return {
        activeMediaObject,
        preapprovalPercentage: selectPreapprovalAmountPercentage(state),
        transactedLicense: activeMediaObject?.transacted_license,
        hasFacebookAdUploadLink: Boolean(selectUploadToFacebookLink(state, mediaObjectId)),
      }
    })

  return (
    <Modal reactModalProps={{ isOpen: Boolean(isOpen) }} width={55} padding={0} mobilePadding={0}>
      <CloseIcon onClick={hideModal} />
      <ModalLicenseWrapper>
        <ModalLicenseHeaderWrapper topOuterSpacing={3} rightOuterSpacing={3} leftOuterSpacing={1.5}>
          <H1 color={Theme.grey900Color}>
            <FormattedMessage id="core.text.licensed" />
          </H1>
          <Container topOuterSpacing={0.75}>
            <Text color={Theme.grey900Color} light customFontSize={1.125}>
              <FormattedHTMLMessage id="contentSubmission.card.modal.licensed.paragraph1" />
            </Text>
          </Container>
        </ModalLicenseHeaderWrapper>

        <ModalLicenseLicenseOptionsWrapper>
          <Container bottomOuterSpacing={1.25} leftOuterSpacing={3}>
            <Text
              uppercase
              customFontSize={0.8125}
              lineHeight={1.23}
              color={Theme.grey900Color}
              customletterSpacing={'1.5px'}
            >
              <FormattedMessage id="socialSubmission.card.modal.license.header.selectedLicense" />
            </Text>
          </Container>

          <SubmissionCardModalLicenseSelected transactedLicense={transactedLicense!} />

          <Container leftOuterSpacing={3} topOuterSpacing={2}>
            <SubmissionCardModalLicenseMediaDetails mediaObject={activeMediaObject} />
          </Container>
        </ModalLicenseLicenseOptionsWrapper>
        <ModalLicensePriceSummaryWrapper leftOuterSpacing={1} bottomOuterSpacing={3}>
          <Container leftOuterSpacing={0.5}>
            <SubmissionCardModalLicensePriceSummary
              license={transactedLicense as ILicense}
              preapprovalPercentage={preapprovalPercentage}
            />

            <Container topOuterSpacing={2.5}>
              <DownloadButton mediaObjectId={mediaObjectId}>
                <FormattedMessage id="licensing.licensed.cta" />
              </DownloadButton>
              <LicensingAgreementsLink color={Theme.primaryColor} />
            </Container>
          </Container>

          {hasFacebookAdUploadLink && (
            <Container topOuterSpacing={1.25} flexCenter>
              <MediaObjectLicensingLicensedUploadToFacebookAdsManager
                mediaObjectId={mediaObjectId}
              />
            </Container>
          )}
        </ModalLicensePriceSummaryWrapper>
      </ModalLicenseWrapper>
    </Modal>
  )
}
