import { format } from 'date-fns'
import { createSelector } from 'reselect'

import { FULL_DATE } from '@constants'
import { IApplicationState } from '@store'
import { ITikTokMetrics } from './types'

const TIKOK_METRIC_PROPS: ReadonlyArray<string> = [
  'likes',
  'comments',
  'shares',
  'engagement',
  'engagement_pct',
  'cpe',
  'video_views',
  'video_views_pct',
  'cpv',
  'cpm',
]

export const selectTikTokMetricsSummary = (state: IApplicationState, campaignId: number) => {
  const summary = state.reportings.tiktokMetrics.tiktokMetricsSummary[campaignId]
  return summary ?? {}
}

export const selectTikTokMetricIds = (state: IApplicationState, campaignId: number) => {
  const tiktokMetricIds = state.reportings.tiktokMetrics.tiktokMetricIdsByCampaign[campaignId] ?? []
  return tiktokMetricIds
}

export const selectTikTokMetrics = (state: IApplicationState, id: number) =>
  state.reportings.tiktokMetrics.tiktokMetricsById[id] ?? {}

export const selectShowTikTokPerformance = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics: ITikTokMetrics) => {
    return TIKOK_METRIC_PROPS.some(
      (metricProp: string) => tiktokMetrics[metricProp] || tiktokMetrics[metricProp] === 0,
    )
  },
)

export const selectTikTokPublishedDate = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics: ITikTokMetrics) =>
    tiktokMetrics.published_at && format(new Date(tiktokMetrics.published_at), FULL_DATE),
)

export const selectTikTokSpend = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics: ITikTokMetrics) => tiktokMetrics.purchase_amount,
)

export const selectTikTokFollowers = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics: ITikTokMetrics) => tiktokMetrics.followers,
)

export const selectTikTokSubmissionLikes = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics) => tiktokMetrics.likes,
)

export const selectTikTokSubmissionComments = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics: ITikTokMetrics) => tiktokMetrics.comments,
)

export const selectTikTokSubmissionShares = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics: ITikTokMetrics) => tiktokMetrics.shares,
)

export const selectTikTokSubmissionEngagement = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics: ITikTokMetrics) => tiktokMetrics.engagement,
)

export const selectTikTokSubmissionEngagementPct = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics: ITikTokMetrics) => tiktokMetrics.engagement_pct,
)

export const selectTikTokCPE = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics: ITikTokMetrics) => tiktokMetrics.cpe,
)

export const selectTikTokViews = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics: ITikTokMetrics) => tiktokMetrics.video_views,
)

export const selectTikTokViewsPct = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics: ITikTokMetrics) => tiktokMetrics.video_views_pct,
)

export const selectTikTokCPV = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics: ITikTokMetrics) => tiktokMetrics.cpv,
)

export const selectTikTokCPM = createSelector(
  selectTikTokMetrics,
  (tiktokMetrics: ITikTokMetrics) => tiktokMetrics.cpm,
)
