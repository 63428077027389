import styled from 'styled-components'

import Theme from '@theme'

export const SubTitle = styled.h4`
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: ${Theme.grey900Color};
`

export const SubmissionCardModalPreApprovalCostBreakdownSpacer = styled.div`
  height: 7rem;
`
