import React from 'react'

import { Link, LinkProps } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Icon } from '../../Icon'
import { defaultTheme, themed } from '../../Theme'

interface IMenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
  destructive?: boolean
  selected?: boolean
  disabled?: boolean
  boldOnSelected?: boolean
  greyedOut?: boolean
  elementName?: string
  iconColor?: string
}

const MenuItemWrapperBase = css`
  ${({ theme, destructive, disabled, greyedOut }) => css`
    white-space: nowrap;
    color: ${theme.grey900Color};
    text-align: left;
    text-transform: none;
    border-radius: 0.2857rem;
    display: block;
    text-decoration: none;
    letter-spacing: 0.3px;
    line-height: 1.43;
    font-size: 0.875rem;
    position: relative;

    a {
      text-decoration: none;
      display: block;
    }

    > a,
    > span {
      padding: 0.25rem 0.75rem;
    }

    > span {
      margin-right: 1.75rem;
      display: inline-block;
    }

    ${destructive &&
    css`
      &,
      * {
        color: ${theme.errorColor};
      }
    `}
    ${greyedOut &&
    css`
      &,
      * {
        color: ${theme.grey700Color};
      }
    `};

    ${!disabled &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${theme.grey200Color};
      }
    `};
  `};
`

const MenuItemWrapper = themed(styled<IMenuItemProps, any>('div')`
  ${MenuItemWrapperBase}
`)

const MenuItemLinkWrapper = themed(styled<IMenuItemProps, any>(Link)`
  ${MenuItemWrapperBase};
`)

export const IconStyled = styled<IMenuItemProps, any>(Icon)`
  position: absolute;
  right: 0.625rem;
  top: 0.25rem;
`

const MenuItem = ({
  elementName,
  children,
  selected,
  iconColor = defaultTheme.primaryColor,
  ...props
}: IMenuItemProps) => {
  return (
    <MenuItemWrapper {...props} data-cy-element={elementName}>
      {children}
      {selected && <IconStyled size={1.25} glyph="tick" color={iconColor} />}
    </MenuItemWrapper>
  )
}

const MenuItemLink = ({
  elementName,
  children,
  selected,
  iconColor = defaultTheme.primaryColor,
  ...props
}: IMenuItemProps & LinkProps) => {
  return (
    <MenuItemLinkWrapper {...props} data-cy-element={elementName}>
      {children}
      {selected && <IconStyled size={1.25} glyph="tick" color={iconColor} data-testid="tick" />}
    </MenuItemLinkWrapper>
  )
}

export { MenuItemLink }
export default MenuItem
