import { call, put, select, takeEvery } from 'redux-saga/effects'

import { selectAuthToken } from '@store/auth'
import {
  fetchInfluencerIdentities,
  FetchInfluencerIdentitiesActionTypes,
  fetchInfluencerIdentitiesError,
  fetchInfluencerIdentitiesSuccess,
  resetInfluencerIdentitiesList,
  selectBrandFansNextLink,
} from '@store/brandFans'
import { IBrandFansLink, selectInfluencerIdentitiesLink } from '@store/me'
import {
  selectCurrentBrandFansFilter,
  selectCurrentBrandFilter,
  selectCurrentSortBy,
} from '@store/router'
import { networkRequest } from '@utils'

export function* handleFetchInfluencerIdentities(
  action: ReturnType<typeof fetchInfluencerIdentities>,
) {
  try {
    const influencerIdentitiesBaseLink = yield select(selectInfluencerIdentitiesLink)

    const { isFetchNext } = action.payload

    const filter = yield select(selectCurrentBrandFansFilter)

    const influencerIdentitiesLink: IBrandFansLink = isFetchNext
      ? yield select(selectBrandFansNextLink, filter)
      : influencerIdentitiesBaseLink

    const authToken: string = yield select(selectAuthToken)

    const sortBy = yield select(selectCurrentSortBy)
    const brandFilter = yield select(selectCurrentBrandFilter)

    if (action.payload.resetList) {
      yield put(resetInfluencerIdentitiesList(filter))
    }

    const { items, metadata, links } = yield call(
      networkRequest,
      influencerIdentitiesLink.method,
      influencerIdentitiesLink.href
        .replace('{filter}', filter)
        .replace('{sort_by}', sortBy)
        .replace('{brand_filter}', brandFilter),
      undefined,
      authToken,
    )
    yield put(fetchInfluencerIdentitiesSuccess(items, metadata, links, filter))
  } catch (error) {
    yield put(fetchInfluencerIdentitiesError(error))
  }
}

export function* watchInfluencerIdentitiesRequest() {
  yield takeEvery(
    FetchInfluencerIdentitiesActionTypes.FETCH_REQUEST,
    handleFetchInfluencerIdentities,
  )
}
