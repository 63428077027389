import React from 'react'

import { Col } from 'react-styled-flexboxgrid'

import { Container } from '@tribegroup'
import Expandable from '../Expandable/Expandable'

interface IFullDetailsWrapper {
  children: React.ReactNode
  expandable?: boolean
}

export const FullDetailsWrapper = ({ children, expandable }: IFullDetailsWrapper) => (
  <Container
    leftInnerSpacing={1.25}
    rightInnerSpacing={1.25}
    topInnerSpacing={1.25}
    bottomInnerSpacing={1.25}
  >
    <Col xs={12} md={false}>
      {expandable && <Expandable height={6.9}>{children}</Expandable>}
      {!expandable && children}
    </Col>
    <Col xs={false} md={12}>
      {children}
    </Col>
  </Container>
)
