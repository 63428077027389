import { IResponseError } from '@lib/error'
import { InfluencerIdentitiesActionTypes } from '@store/influencerIdentities'

export const fetchInfluencerIdentities = (campaignId: number) => ({
  payload: campaignId,
  type: InfluencerIdentitiesActionTypes.FETCH_REQUEST,
})

export const fetchInfluencerIdentitiesSuccess = (data: any, campaignId: number) => ({
  payload: { ...data, campaignId },
  type: InfluencerIdentitiesActionTypes.FETCH_SUCCESS,
})

export const fetchInfluencerIdentitiesError = (error: IResponseError) => ({
  payload: error,
  type: InfluencerIdentitiesActionTypes.FETCH_ERROR,
})
