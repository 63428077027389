import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'
import { IBoxProps } from './Box'

export default styled<IBoxProps>('div')`
  transition: height 0.15s ease-out;
  box-sizing: border-box;
  background-color: ${Theme.whiteColor};
  box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 10%);
  border-radius: 0.3125rem;
  ${({ fullOnSmallScreens }) =>
    fullOnSmallScreens &&
    css`
      width: 100%;
      padding: 3rem 1.875rem;
      min-height: 100vh;
      border-radius: 0;
      border: none;
      ${breakpoint('phoneUpperBoundary')`
        border-radius: 0.3125rem;
      `};
    `};
  ${breakpoint('phoneUpperBoundary')`
    margin: 1rem 0;
    padding: 3rem 3.125rem;
    width: 30rem;
    min-height: 0;
    height: fit-content;
  `};
`
