/* eslint-disable react/display-name */
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { clearUpload, IMedia, selectMedia, selectMediaIsUploading, upload } from '@store/media'

export interface IMediaUploaderProps {
  clearUpload: typeof clearUpload
  upload: typeof upload
  media?: IMedia
  isUploading: boolean
}

export const MediaUploader = (Component) => (props) => {
  return <Component {...props} mediaId={Date.now()} />
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearUpload: () => dispatch(clearUpload()),
  upload: (media) => {
    dispatch(upload(media, ownProps.name || ownProps.mediaId))
  },
})

const mapStateToProps = (state: IApplicationState, ownProps) => ({
  media: selectMedia(state),
  isUploading: selectMediaIsUploading(state, ownProps.name || ownProps.mediaId),
})

export const withMediaUploader = compose(
  MediaUploader,
  connect(mapStateToProps, mapDispatchToProps),
)

export default withMediaUploader
