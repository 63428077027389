import React from 'react'

import Theme from '@theme'
import { Container, H3, Text } from '@tribegroup'
import { HeaderRoot } from './Row.styled'

interface IBrandFansRowHeaderProps {
  groupName: string
  totalCount: number
}

const BrandFansRowHeader: React.FC<IBrandFansRowHeaderProps> = ({ groupName, totalCount }) => (
  <HeaderRoot>
    <Container rightOuterSpacing={0.5} inline>
      <H3 color={Theme.defaultColor} inline>
        {groupName}
      </H3>
    </Container>
    {Boolean(totalCount) && (
      <Text light color={Theme.grey700Color}>
        {totalCount}
      </Text>
    )}
  </HeaderRoot>
)

export { BrandFansRowHeader, IBrandFansRowHeaderProps }
export default BrandFansRowHeader
