import { FormattedMessage } from 'react-intl'

import { MenuItem, Text } from '@tribegroup'
import { useIdentityRowReconnect } from './useIdentityRowReconnect'

interface IProps {
  brandManagerIdentityId: number
}

const ConnectedAccountsListIdentityRowMenuReconnect: React.FC<IProps> = ({
  brandManagerIdentityId,
}) => {
  const { reconnect } = useIdentityRowReconnect(brandManagerIdentityId)
  return (
    <MenuItem onClick={reconnect}>
      <Text>
        <FormattedMessage id="core.text.reconnect" />
      </Text>
    </MenuItem>
  )
}

export default ConnectedAccountsListIdentityRowMenuReconnect
