import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { EllipsisWrapper } from '@components/InfluencerIdentity/Row/Details/Details.styled'
import { BudgetAmount } from '@components/UI/BudgetAmount/BudgetAmount'
import Theme from '@theme'
import { Container, Header, Icon } from '@tribegroup'

interface IProps {
  amount: number
  currency: string
}

export const AvailableCreditPanel = ({ amount, currency }: IProps) => {
  return (
    <Header small color={Theme.grey900Color}>
      <Flex alignCenter>
        <Container maxWidth="calc(100vw - 9.75rem)">
          <BudgetAmount
            spent={amount}
            currency={currency}
            largetext
            color={Theme.primaryColor}
            customMessage={
              <EllipsisWrapper>
                <FormattedMessage id="contentLibrary.licensing.creditManagement.availableCredit.label" />
              </EllipsisWrapper>
            }
          />
        </Container>
        <Container topOuterSpacing={0.25} leftOuterSpacing={0.5} width={1} inlineBlock>
          <Icon glyph="information" color={Theme.grey900Color} />
        </Container>
      </Flex>
    </Header>
  )
}
