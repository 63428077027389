import { lazy, Suspense, useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import { Router, useLocation } from 'react-router-dom'

import { ConnectedAccountsPopUpCallback } from '@components/ConnectedAccounts/PopUp/Callback'
import PrivateRoute from '@components/UI/PrivateRoute'
import PublicRoute from '@components/UI/PublicRoute'
import InformationTooltipBaseWrapper from '@context/InformationTooltip/InformationTooltipBaseWrapper'
import { PageRoutes } from '@enums'
import { LocationChangeAction } from '@store/router/actions'
import { history } from './../configureStore'
import AutoSignIn from './AutoSignIn/AutoSignIn'
import ContentLibraryPurchasesPage from './ContentLibraryPurchases/Page'
import AudienceReporting from './AudienceReporting'
import BrandFanGroup from './BrandFanGroup'
import BrandFanGroups from './BrandFanGroups'
import BrandFans from './BrandFans'
import Inbox from './Inbox'
import PostCampaignReport from './PostCampaignReport'
import ShareableContentLibrary from './ShareableContentLibrary'
import ShareableMediaObject from './ShareableMediaObject'
import SignIn from './SignIn'
import SignOut from './SignOut'
import Submission from './Submission'

const ForgotPassword = lazy(() => import('./ForgotPassword'))
const SignUp = lazy(() => import('./SignUp'))
const EmailVerification = lazy(() => import('./EmailVerification'))
const ResendVerification = lazy(() => import('./ResendVerification'))
const ForgotPasswordConfirmation = lazy(() => import('./ForgotPasswordConfirmation'))
const SetPassword = lazy(() => import('./SetPassword'))
const SetPasswordConfirmation = lazy(() => import('./SetPasswordConfirmation'))
const SignUpConfirmation = lazy(() => import('./SignUpConfirmation'))
const CompleteSignup = lazy(() => import('./CompleteSignup'))
const CreatorBioPage = lazy(() => import('./CreatorBio'))

const Campaigns = lazy(() => import('./Campaigns'))
const ContentLibrary = lazy(() => import('./ContentLibrary'))
const CampaignBillingHistoryPage = lazy(() => import('./CampaignBillingHistory'))
const CampaignPurchasesPage = lazy(() => import('./CampaignPurchases'))
const Account = lazy(() => import('./Account'))
const ConnectedAccounts = lazy(() => import('./ConnectedAccounts'))
const Builder = lazy(() => import('./Builder'))
const MediaObject = lazy(() => import('./MediaObject'))

const Async = (Component) => {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  )
}

export const LocationChangeDispatcher = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!location) {
      return
    }
    dispatch(LocationChangeAction(location))
  }, [location])

  return null
}

export const PageRoot = () => (
  <Router history={history}>
    <InformationTooltipBaseWrapper />
    <LocationChangeDispatcher />
    <Switch>
      <PrivateRoute exact path={`/${PageRoutes.Campaigns}`} component={Async(Campaigns)} />
      <PrivateRoute
        exact
        path={`/${PageRoutes.ContentLibrary}`}
        component={Async(ContentLibrary)}
      />
      <PrivateRoute exact path="/campaigns/:campaignId" component={Inbox} />
      <PrivateRoute exact path="/campaigns/:campaignId/preview" component={Inbox} />
      <PrivateRoute
        exact
        path="/campaigns/:campaignId/submissions/:submissionId"
        component={Submission}
      />
      <PrivateRoute
        exact
        path="/campaigns/:campaignId/submissions/:submissionId/preview"
        component={Submission}
      />
      <PrivateRoute
        exact
        path="/campaigns/:campaignId/submissions/:submissionId/feedback"
        component={Inbox}
      />
      <PrivateRoute
        exact
        path="/campaigns/:campaignId/submissions/:submissionId/notes"
        component={Inbox}
      />
      <PrivateRoute
        exact
        path="/campaigns/:campaignId/submissions/:submissionId/detail/(feedback|notes|rate)"
        component={Submission}
      />
      <PrivateRoute
        exact
        path={`/${PageRoutes.Campaigns}/:campaignId/${PageRoutes.Reports}/pcr`}
        component={PostCampaignReport}
      />
      <PrivateRoute
        exact
        path={`/${PageRoutes.Campaigns}/:campaignId/${PageRoutes.Reports}/audience`}
        component={AudienceReporting}
      />

      <PrivateRoute path="/builder" component={Async(Builder)} />

      <PrivateRoute exact path={`/${PageRoutes.SignOut}`} component={SignOut} />

      <PrivateRoute
        exact
        path={`/${PageRoutes.BillingCampaigns}/:campaignId`}
        component={Async(CampaignPurchasesPage)}
      />

      <PrivateRoute
        exact
        path={`/${PageRoutes.BillingCampaigns}`}
        component={Async(CampaignBillingHistoryPage)}
      />

      <PrivateRoute
        exact
        path={`/${PageRoutes.BillingContentLibrary}`}
        component={Async(ContentLibraryPurchasesPage)}
      />

      <PrivateRoute exact path="/account/(contact|password|payment)?" component={Async(Account)} />
      <PrivateRoute
        exact
        path={`/${PageRoutes.ConnectedAccounts}`}
        component={Async(ConnectedAccounts)}
      />
      <PrivateRoute
        exact
        path={`/connect-tiktok/callback`}
        component={Async(ConnectedAccountsPopUpCallback)}
      />
      <PrivateRoute
        exact
        path={`/${PageRoutes.MediaObjects}/:mediaObjectId`}
        component={Async(MediaObject)}
      />
      <PrivateRoute exact path={`/${PageRoutes.Creators}`} component={Async(BrandFans)} />
      <PrivateRoute
        exact
        path={`/${PageRoutes.BrandFanGroups}/:brandFansGroupId`}
        component={Async(BrandFanGroup)}
      />
      <PrivateRoute
        exact
        path={`/${PageRoutes.BrandFanGroups}`}
        component={Async(BrandFanGroups)}
      />
      <PrivateRoute
        exact
        path={`/${PageRoutes.CreatorBioPage}`}
        component={Async(CreatorBioPage)}
      />

      <Route
        exact
        path="/"
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render={(props: any) => {
          return <Redirect to="/signIn" />
        }}
      />
      <Route
        path="/referral/:token"
        render={({ match: { params } }) => {
          return <Redirect to={`/signUp?referrer=${params.token}`} />
        }}
      />
      <Route
        path={`/${PageRoutes.ShareableContentLibrary}/:token/:mediaObjectId`}
        component={ShareableMediaObject}
      />
      <Route
        path={`/${PageRoutes.ShareableContentLibrary}/:token`}
        component={ShareableContentLibrary}
      />
      <PublicRoute path="/signIn" component={SignIn} />
      <Route path="/autoSignin" component={AutoSignIn} />
      <PublicRoute path="/signUp" component={Async(SignUp)} />
      <PublicRoute path="/signUpConfirmation" component={Async(SignUpConfirmation)} />

      <PublicRoute path="/completeSignup" component={Async(CompleteSignup)} />

      <PublicRoute path="/verifyAccount" component={Async(EmailVerification)} />
      <PublicRoute path="/resendVerification" component={Async(ResendVerification)} />
      <PublicRoute path="/forgotPassword" component={Async(ForgotPassword)} />
      <PublicRoute
        path="/forgotPasswordConfirmation"
        component={Async(ForgotPasswordConfirmation)}
      />
      <PublicRoute path="/resetPassword" component={Async(SetPassword)} />
      <PublicRoute path="/resetPasswordConfirmation" component={Async(SetPasswordConfirmation)} />
      <Redirect to="/" />
    </Switch>
  </Router>
)

export default PageRoot
