import { useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { IApplicationState } from '@store'
import { ILicenseOption } from '@store/campaigns'
import { IPurchaseOrder, selectPurchaseOrders } from '@store/purchaseOrders'
import Theme from '@theme'
import { Container, Text, Tip } from '@tribegroup'
import { CreditManagementPaymentMethodPurchaseOrderListItem } from './Item'

interface IProps {
  licenseOption: ILicenseOption
  onSelectPurchaseOrders: (purchaseOrders: IPurchaseOrder[]) => void
}

export const CreditManagementPaymentMethodPurchaseOrderList = ({
  licenseOption,
  onSelectPurchaseOrders,
}: IProps) => {
  const [selectedPurchaseOrders, setSelectedPurchaseOrders] = useState<IPurchaseOrder[]>([])
  const [totalSelectedAmount, setTotalSelectedAmount] = useState(0)

  const { purchaseOrders } = useSelector((state: IApplicationState) => {
    return {
      purchaseOrders: selectPurchaseOrders(state),
    }
  })

  useEffect(() => {
    onSelectPurchaseOrders(selectedPurchaseOrders)
  }, [selectedPurchaseOrders])

  const removeFromList = (purchaseOrder: IPurchaseOrder) => {
    setSelectedPurchaseOrders((prevSelectedPurchaseOrders) =>
      prevSelectedPurchaseOrders.filter(
        (selectedPurchaseOrder) => selectedPurchaseOrder.id !== purchaseOrder.id,
      ),
    )

    setTotalSelectedAmount((prevAmount) => prevAmount - purchaseOrder.amount_remaining_cents)
  }

  const addToList = (purchaseOrder: IPurchaseOrder) => {
    setSelectedPurchaseOrders((prevSelectedPurchaseOrders) => [
      ...prevSelectedPurchaseOrders,
      purchaseOrder,
    ])
    setTotalSelectedAmount((prevAmount) => prevAmount + purchaseOrder.amount_remaining_cents)
  }

  const licenseAmountCovered = totalSelectedAmount >= licenseOption.total_amount_before_tax_cents

  const showTip = selectedPurchaseOrders.length > 0 && !licenseAmountCovered

  return (
    <Container>
      <Container topOuterSpacing={2.875} bottomOuterSpacing={1.25} flexBetween>
        <Text color={Theme.primaryColor}>
          <FormattedMessage id="contentLibrary.licensing.creditManagement.purchaseOrder.selectPO" />
        </Text>
        <Text color={Theme.primaryColor}>
          <FormattedMessage id="core.text.credit" />
        </Text>
      </Container>

      <Container maxHeight={!showTip ? 15 : 9.75} overflow="scroll">
        {purchaseOrders.map((purchaseOrder) => (
          <CreditManagementPaymentMethodPurchaseOrderListItem
            key={`purchase-order-${purchaseOrder.id}`}
            purchaseOrder={purchaseOrder}
            onSelect={addToList}
            onDeselect={removeFromList}
            checked={selectedPurchaseOrders.includes(purchaseOrder)}
            disabled={licenseAmountCovered && !selectedPurchaseOrders.includes(purchaseOrder)}
          />
        ))}
      </Container>
      {showTip && (
        <Container
          flexStart
          alignItems="flex-start"
          verticalAlign="middle"
          bottomOuterSpacing={0.5}
          topOuterSpacing={2}
        >
          <Tip
            text={
              <Text color={Theme.grey800Color} small>
                <FormattedMessage id="contentLibrary.licensing.creditManagement.purchaseOrder.tip" />
              </Text>
            }
            width={26}
          />
        </Container>
      )}
    </Container>
  )
}
