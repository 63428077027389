import { FC } from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { AnalyticsPostPerformanceFlagSubmissionModal } from '@components/Analytics/PostPerformance/FlagSubmission/Modal'
import { useModal } from '@hooks/useModal'
import { IApplicationState } from '@store'
import { selectSubmission } from '@store/submissions'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

interface IProps {
  hasAnalytics?: boolean
  submissionId: number
}

const Button = styled.button`
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  margin-left: 0.25rem;
  box-shadow: inset 0 -1.5px 0 0 ${Theme.grey400Color};

  &:hover {
    box-shadow: inset 0 -1.5px 0 0 ${Theme.primaryColor};
  }
`

export const AnalyticsPostPerformanceFlagSubmission: FC<IProps> = ({
  hasAnalytics,
  submissionId,
}) => {
  const { showModal } = useModal()
  const submission = useSelector((state: IApplicationState) =>
    selectSubmission(state, submissionId),
  )

  const suffix = !hasAnalytics ? 'unavailable' : 'incorrect'

  return (
    <>
      {submission.has_unresolved_metric_issue ? (
        <Text color={Theme.grey800Color} small>
          <FormattedMessage id="submission.performance.flag.submitted" />
        </Text>
      ) : (
        <Container>
          <Text color={Theme.grey900Color} small>
            <FormattedMessage id={`submission.performance.flag.${suffix}`} />
          </Text>
          <Button
            onClick={() =>
              showModal(AnalyticsPostPerformanceFlagSubmissionModal, { submissionId, hasAnalytics })
            }
          >
            <Text color={Theme.grey900Color} small customFontWeight={500}>
              <FormattedMessage id="submission.performance.flag.cta" />
            </Text>
          </Button>
        </Container>
      )}
      {hasAnalytics && (
        <Container topOuterSpacing={0.5} fullWidth>
          <Text color={Theme.grey800Color} small>
            {submission.is_proxy ? (
              <FormattedHTMLMessage id="submission.performance.flag.incorrect.proxy" />
            ) : (
              <FormattedHTMLMessage id="submission.performance.flag.modal.incorrect.disclaimer" />
            )}
          </Text>
        </Container>
      )}
    </>
  )
}
