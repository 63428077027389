import { IMeta } from '@store'
import { ISubmission, SubmissionActionTypes } from '@store/submissions'

export const fetchSubmission = (submissionId: number, campaignId: number, meta?: IMeta) => ({
  payload: {
    submissionId,
    campaignId,
  },
  type: SubmissionActionTypes.FETCH_REQUEST,
  meta,
})

export const fetchSubmissionSuccess = (submission: ISubmission) => ({
  payload: submission,
  type: SubmissionActionTypes.FETCH_SUCCESS,
})

export const fetchSubmissionError = (error: any) => ({
  payload: error,
  type: SubmissionActionTypes.FETCH_ERROR,
})
