import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames, PageRoutes } from '@enums'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { Link } from './ViewBio.styled'

interface IProps {
  href: string
  method: string
  campaignId: number
  submissionId: number
}

const SubmissionCardHeaderViewBio = ({ href, method, submissionId, campaignId }: IProps) => {
  const params = new URLSearchParams()
  params.set('href', encodeURI(href))
  params.set('method', method)
  params.set('campaignId', campaignId.toString(10))
  params.set('submissionId', submissionId.toString(10))

  return (
    <ConnectedTrackedRoute
      eventName={EventTrackingNames.ViewBio}
      submissionId={submissionId}
      campaignId={campaignId}
    >
      <Flex>
        <Link
          href={`/${PageRoutes.CreatorBioPage}?${params.toString()}`}
          color={Theme.primaryColor}
          target="_blank"
        >
          <Container innerSpacing={0.1875} flexCenter>
            <Text uppercase light xxsmall>
              <FormattedMessage id="core.text.viewBio" />
            </Text>
          </Container>
        </Link>
      </Flex>
    </ConnectedTrackedRoute>
  )
}

export default SubmissionCardHeaderViewBio
