import { call, put, select, takeLatest } from 'redux-saga/effects'

import { unleashClient } from '@context/FeatureToggle'
import { FeatureToggles } from '@enums'
import amplitude from '@lib/analytics/amplitude'
import { IHateoasLink } from '@store'
import {
  AuthActionTypes,
  authError,
  cancelRefreshToken,
  selectAuthToken,
  signOutRequest,
  signOutSuccess,
} from '@store/auth'
import { selectSignOutLink } from '@store/me'
import { networkRequest } from '@utils'

export function* handleSignOut(action: ReturnType<typeof signOutRequest>) {
  try {
    const authToken: string = yield select(selectAuthToken)
    const signOutLink: IHateoasLink = yield select(selectSignOutLink)
    yield call(networkRequest, signOutLink.method, signOutLink.href, undefined, authToken)
    yield put(signOutSuccess())
    yield put(cancelRefreshToken())
    const isAmplitudeEnabled = unleashClient.isEnabled(FeatureToggles.BYE_BYE_SEGMENT_ENABLED)
    if (isAmplitudeEnabled) {
      yield call(amplitude.reset)
    } else {
      yield call(analytics.reset)
    }
  } catch (error) {
    yield put(authError(error))
  } finally {
    const { history, redirect } = action.meta
    if (history && redirect) {
      yield call(history.push, redirect)
    }
  }
}

export function* watchSignOutRequest() {
  yield takeLatest(AuthActionTypes.SIGNOUT_REQUEST, handleSignOut)
}
