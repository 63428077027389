import React, { FC, useContext, useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { SideBarBlock, SideBarBlockItem } from '@components/UI/SideBar'
import { ALL } from '@constants'
import { MobileMenuControlsContext } from '@context/MobileMenuControls'
import { CampaignStatuses, PageRoutes } from '@enums'
import { useCampaigns } from '@hooks/campaigns/useCampaigns'
import { useCurrentCampaignStatusFilter } from '@hooks/campaigns/useCurrentCampaignStatusFilter'
import { objectToQueryString } from '@utils'
import { isActiveMenu } from '@utils/urls'

const CampaignFilters: FC = () => {
  const { hideMobileMenu } = useContext(MobileMenuControlsContext)
  const currentCampaignStatusFilter = useCurrentCampaignStatusFilter()
  const { stats: campaignStats, fetch, isFetching, isFetched } = useCampaigns()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!isFetching && !isFetched) {
      fetch()
    }
  }, [])

  const campaignStatusesWithAll: ReadonlyArray<string> = [ALL, ...Object.values(CampaignStatuses)]
  return (
    <SideBarBlock
      header={<FormattedMessage id="core.text.campaigns" />}
      items={[
        ...campaignStatusesWithAll.map((status) => (
          <SideBarBlockItem
            key={status}
            to={{
              pathname: PageRoutes.Campaigns,
              search: objectToQueryString({ status: encodeURIComponent(status) }),
            }}
            count={campaignStats[status]}
            text={<FormattedMessage id={`core.text.${status}`} />}
            data-status={status}
            active={isActiveMenu(
              pathname,
              PageRoutes.Campaigns,
              currentCampaignStatusFilter,
              status,
            )}
            showNotification={false}
            onClick={hideMobileMenu}
            elementName={`side-bar-${status}`}
          />
        )),
      ]}
    />
  )
}

export default CampaignFilters
