import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectDraftIsSocial, selectDraftType } from '@store/drafts'
import { selectDemoVideoId } from '@store/me'
import Theme from '@theme'
import { Link } from '@tribegroup'
import HowToVideosTipContentCampaign from './ContentCampaign'
import HowToVideosTipSocialCampaign from './SocialCampaign'

type IInternalProps = ConnectedProps<typeof connector>

export const HowToVideosTip: React.FC<IInternalProps> = ({ isSocial, demoVideoId }) => {
  return (
    <Link
      href={`https://tribe.wistia.com/medias/${demoVideoId}`}
      target="_blank"
      color={Theme.secondaryColor}
    >
      {isSocial ? <HowToVideosTipSocialCampaign /> : <HowToVideosTipContentCampaign />}
    </Link>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  const draftType = selectDraftType(state)
  return {
    isSocial: selectDraftIsSocial(state),
    demoVideoId: selectDemoVideoId(state, draftType),
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(connector)(HowToVideosTip)
