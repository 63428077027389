import { createBrowserHistory } from 'history'
import { call, put, takeEvery } from 'redux-saga/effects'

import { unleashClient } from '@context/FeatureToggle'
import { FeatureToggles } from '@enums'
import amplitude from '@lib/analytics/amplitude'
import { AuthActionTypes, authError, cancelRefreshToken, signOutSuccess } from '@store/auth'
import { SubmissionPerformanceActionTypes } from '@store/performance'
import { CampaignMetricsActionTypes } from '@store/postCampaignReport'
import { PrePurchaseAnalyticsActionTypes } from '@store/prePurchaseAnalytics'
import { ShortlistSummaryActionTypes } from '@store/shortlistSummary'

const UNAUTHORIZED_STATUS = 401
const history: any = createBrowserHistory()

const ignoreActionTypes: ReadonlyArray<any> = [
  AuthActionTypes.AUTH_ERROR,
  CampaignMetricsActionTypes.FETCH_ERROR,
  SubmissionPerformanceActionTypes.FETCH_ERROR,
  PrePurchaseAnalyticsActionTypes.FETCH_ERROR,
  ShortlistSummaryActionTypes.FETCH_ERROR,
]

const errorActionTypes = (action: ReturnType<typeof authError>) =>
  action.type.includes('_ERROR') &&
  ignoreActionTypes.every((fragment) => !action.type.includes(fragment))

export function* handleUnAuthorizedAccess(action?: any) {
  if (action.payload && action.payload.status === UNAUTHORIZED_STATUS) {
    yield put(signOutSuccess())
    yield put(cancelRefreshToken())
    yield call(history.push, '/signIn')

    const isAmplitudeEnabled = unleashClient.isEnabled(FeatureToggles.BYE_BYE_SEGMENT_ENABLED)
    if (isAmplitudeEnabled) {
      yield call(amplitude.reset)
    } else if (analytics && analytics.reset) {
      yield call(analytics.reset)
    }
  }
}

export function* watchUnAuthorizedResponses() {
  yield takeEvery(errorActionTypes, handleUnAuthorizedAccess)
}
