import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  selectCreditCardLink,
  selectPaymentApiKey,
  updateCreditCard,
  UpdateCreditCardActionTypes,
  updateCreditCardError,
  updateCreditCardSuccess,
} from '@store/me'
import { createPaymentToken, networkRequest } from '@utils'

export function* handleUpdateCreditCard(action: ReturnType<typeof updateCreditCard>) {
  try {
    const { history, redirect } = action.meta
    const paymentApiKey = yield select(selectPaymentApiKey)
    const stripeResponse = yield call(createPaymentToken, action.payload, paymentApiKey)
    const authToken: string = yield select(selectAuthToken)
    const creditCardLink: IHateoasLink = yield select(selectCreditCardLink)
    const me = yield call(
      networkRequest,
      creditCardLink.method,
      creditCardLink.href,
      { stripe_token: stripeResponse.id },
      authToken,
    )
    yield put(updateCreditCardSuccess(me))
    if (history && redirect) {
      yield call(history.push, redirect)
    }
  } catch (error) {
    yield put(updateCreditCardError(error))
  }
}

export function* watchUpateCreditCardRequest() {
  yield takeLatest(UpdateCreditCardActionTypes.UPDATE_CREDITCARD_REQUEST, handleUpdateCreditCard)
}
