import { FC } from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Container, Text } from '@tribegroup'

interface IProps {
  translationKey: string
  titleKeyOverride?: string
  contentKeyOverride?: string
  values?: any
  locked?: boolean
}

const AnalyticsPostCampaignReportGlossaryEntry: FC<IProps> = ({
  translationKey,
  titleKeyOverride,
  contentKeyOverride,
  values,
  locked,
}) => {
  const messageId = `analytics.postCampaignReport.glossary.entry.${translationKey}`

  return (
    <Container topInnerSpacing={1.25} data-testid={`${translationKey}-container`}>
      <Container bottomOuterSpacing={0.25}>
        <Text block color={Theme.grey900Color} customFontWeight={500} small>
          <FormattedMessage id={`${messageId}.${titleKeyOverride ?? 'title'}`} />
          {locked && ' *'}
        </Text>
      </Container>
      <Text block color={Theme.grey900Color} customFontSize={0.8125} lineHeight={1.23}>
        <FormattedMessage id={`${messageId}.${contentKeyOverride ?? 'content'}`} values={values} />
      </Text>
    </Container>
  )
}

export default AnalyticsPostCampaignReportGlossaryEntry
