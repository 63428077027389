import React from 'react'

import { isEmpty } from 'lodash'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import ChartsAudienceInsights from '@components/Charts/AudienceInsigths'
import { IApplicationState } from '@store'
import { selectAudienceInsights } from '@store/audienceInsights'

interface IProps {
  identityId: number
  isTikTok?: boolean
}

type IInternalProps = IProps & ConnectedProps<typeof connector>

const AnalyticsAudienceInsights: React.FC<IInternalProps> = ({ audienceInsights, isTikTok }) => {
  if (isEmpty(audienceInsights)) {
    return null
  }

  return <ChartsAudienceInsights insights={audienceInsights} isTikTok={isTikTok} />
}

const mapStateToProps = (state: IApplicationState, { identityId }: IProps) => ({
  audienceInsights: selectAudienceInsights(state, identityId),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(connector)(AnalyticsAudienceInsights)
