import React from 'react'

import ReactGoogleReCaptcha from 'react-google-recaptcha'

import { RECAPTCHA_SITE_KEY } from '@constants'

export interface IGRecaptchaProps {
  reset: VoidFunction
}

interface IReCaptchaProps {
  onChange: (event: Partial<React.FormEvent<HTMLInputElement>>) => void
  name: string
  innerRef?: React.RefObject<IGRecaptchaProps>
}

const ReCaptcha = ({ name, onChange, innerRef }: IReCaptchaProps) => {
  const onRecaptchaChange = (value) => {
    const inputValues = {
      value,
      name,
    } as Partial<HTMLInputElement>
    onChange({ target: inputValues } as Partial<React.FormEvent<HTMLInputElement>>)
  }

  return (
    <ReactGoogleReCaptcha
      sitekey={RECAPTCHA_SITE_KEY}
      onChange={onRecaptchaChange}
      ref={innerRef}
    />
  )
}

export default ReCaptcha
