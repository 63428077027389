import Loading from '@components/UI/Loading'
import { useLocationSearch } from '@hooks/useLocationSearch'
import { IOption } from '@store/rootResource'
import Theme from '@theme'
import { DropdownMenu, MenuItemLink, TargetContainer, Text } from '@tribegroup'
import { objectToQueryString } from '@utils'

type Props = {
  searchKey: 'brand_filter' | 'channel_filter' | 'media_type_filter'
  allLabel: string | React.ReactNode
  options: IOption[]
  isLoading?: boolean
}

export const MediaObjectSearchBaseFilter: React.FC<Props> = ({
  searchKey,
  allLabel,
  options,
  isLoading,
}) => {
  const search = useLocationSearch()

  const selected = search[searchKey]

  const selectedChannelName = options.find((option) => option.value === selected)?.label
  const isAllChannelSelected = selected === undefined

  return (
    <DropdownMenu
      target={
        <TargetContainer anchor="right">
          <Text color={Theme.defaultColor}>
            {isAllChannelSelected ? allLabel : selectedChannelName}
          </Text>
        </TargetContainer>
      }
      showIndicator
      indicatorAnchor="left"
      menuAnchor="left"
      data-testid={searchKey}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <MenuItemLink
            to={{
              search: objectToQueryString({
                ...search,
                [searchKey]: undefined,
              }),
            }}
            selected={isAllChannelSelected}
            iconColor={Theme.primaryColor}
          >
            {allLabel}
          </MenuItemLink>
          {options.map((option) => (
            <MenuItemLink
              to={{
                search: objectToQueryString({
                  ...search,
                  [searchKey]: option.value,
                }),
              }}
              key={option.value}
              selected={selected === option.value}
              iconColor={Theme.primaryColor}
            >
              <span>{option.label}</span>
            </MenuItemLink>
          ))}
        </>
      )}
    </DropdownMenu>
  )
}
