import React from 'react'

import { FormattedMessage } from 'react-intl'

import FlatButton from '@components/UI/FlatButton'
import Theme from '@theme'
import { Header } from '@tribegroup'
import { IButtonProps } from './Button'
import BrandedContentAdBarButtonWrapper from './Wrapper'

const RightsExpired: React.FC<IButtonProps> = ({ onClick, submissionId }) => (
  <BrandedContentAdBarButtonWrapper submissionId={submissionId}>
    <FlatButton backgroundColor={Theme.grey200Color} onClick={onClick}>
      <Header color={Theme.defaultColor} uppercase cta>
        <FormattedMessage id="submission.bca.rights.expired" />
      </Header>
    </FlatButton>
  </BrandedContentAdBarButtonWrapper>
)

export { RightsExpired }
export default RightsExpired
