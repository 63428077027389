import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { IApplicationState } from '@store'
import { selectDataPendingSubmissions } from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Text } from '@tribegroup'

const PostCampaignReportPerformancePending = () => {
  const match = useRouteMatch()

  const { pendingSubmissionCount } = useSelector((state: IApplicationState) => {
    const campaignId = selectCampaignId(match)!
    const pendingSubmissionCount = selectDataPendingSubmissions(state, campaignId)

    return {
      pendingSubmissionCount,
    }
  })

  const pendingCount = [
    {
      translation: 'postCampaignReport.performance.pending.tiktok',
      count: pendingSubmissionCount.tiktok,
    },
    {
      translation: 'postCampaignReport.performance.pending.posts',
      count: pendingSubmissionCount.posts,
    },
    {
      translation: 'postCampaignReport.performance.pending.reels',
      count: pendingSubmissionCount.reels!,
    },
    {
      translation: 'postCampaignReport.performance.pending.videos',
      count: pendingSubmissionCount.videos,
    },
    {
      translation: 'postCampaignReport.performance.pending.stories',
      count: pendingSubmissionCount.stories,
    },
  ]

  const index = pendingCount.reduce((prev, current) => {
    if (current.count) {
      return current
    }

    return prev
  })

  const pendingTypeCount = pendingCount.filter((item) => item.count).length

  return (
    <Text color={Theme.grey700Color} data-testid="pcr-pending-count">
      <FormattedMessage id="postCampaignReport.performance.pending.prompt" />
      {pendingCount.map((item) => {
        return (
          <Fragment key={item.translation}>
            {pendingTypeCount > 1 && index.translation === item.translation && (
              <span>
                &nbsp;
                <FormattedMessage id="core.text.and" />
              </span>
            )}

            {Boolean(item.count) && (
              <span>
                &nbsp;
                <FormattedMessage id={item.translation} values={{ count: item.count }} />
              </span>
            )}
          </Fragment>
        )
      })}
      <span>.</span>
    </Text>
  )
}

export { PostCampaignReportPerformancePending }
export default PostCampaignReportPerformancePending
