import React from 'react'

import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import { StripeCardNumberElement } from '@stripe/stripe-js'
import { FormattedMessage } from 'react-intl'

import { HIDDEN_CREDIT_CARD_PREFIX } from '@constants'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { padNumberTwoDigits } from '@utils'
import { FormError, FormRow } from './components'
import { stipeStyles } from './styleOptions'

interface IPaymentElementsProps {
  errorMessage?: string | React.ReactNode
  autoFocus: boolean
}

interface IPlaceholderPaymentElementsProps {
  lastFourDigits: string
  expiryMonth: number
  expiryYear: number
}

const onReady = (element: StripeCardNumberElement) => element.focus()

export const CardNumberRow = ({ children }) => (
  <FormRow label={<FormattedMessage id="account.payment.creditCardNumber" />}>{children}</FormRow>
)

export const CardExpiryRow = ({ children }) => (
  <FormRow label={<FormattedMessage id="account.payment.expiryDate" />}>{children}</FormRow>
)

export const PlaceholderPaymentElements = ({
  lastFourDigits,
  expiryMonth,
  expiryYear,
}: IPlaceholderPaymentElementsProps) => (
  <Container textAlign="left">
    <CardNumberRow>
      <Text color={Theme.defaultColor}>{`${HIDDEN_CREDIT_CARD_PREFIX} ${lastFourDigits}`}</Text>
    </CardNumberRow>
    <CardExpiryRow>
      <Text color={Theme.defaultColor}>{`${padNumberTwoDigits(expiryMonth)} / ${expiryYear}`}</Text>
    </CardExpiryRow>
  </Container>
)

export const PaymentElements = ({ errorMessage, autoFocus }: IPaymentElementsProps) => (
  <Container textAlign="left">
    <CardNumberRow>
      <CardNumberElement
        options={{ style: stipeStyles }}
        onReady={autoFocus ? onReady : undefined}
      />
    </CardNumberRow>
    <CardExpiryRow>
      <CardExpiryElement options={{ style: stipeStyles }} />
    </CardExpiryRow>
    <FormRow label={<FormattedMessage id="account.payment.creditCardCvc" />}>
      <CardCvcElement options={{ style: stipeStyles }} />
    </FormRow>
    {errorMessage && <FormError>{errorMessage}</FormError>}
  </Container>
)

export default PaymentElements
