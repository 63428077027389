import React, { PropsWithChildren } from 'react'

import Theme from '@theme'
import { PopupMenuItem as PopupMenuItemStyled } from './PopupMenuBaseWrapper.styled'

interface IProps {
  onClick: VoidFunction
}

const PopupMenuItem: React.FC<PropsWithChildren<IProps>> = ({ onClick, children, ...props }) => (
  <PopupMenuItemStyled
    borderRadius={1.125}
    bottomInnerSpacing={0.625}
    topInnerSpacing={0.625}
    rightInnerSpacing={1}
    leftInnerSpacing={1}
    backgroundColor={Theme.whiteColor}
    width="fit-content"
    noWrap
    onClick={onClick}
    {...props}
  >
    {children}
  </PopupMenuItemStyled>
)

export { PopupMenuItem }
