export const TRIBE_TOC = 'https://www.tribegroup.co/toc'

export const TRIBE_PRIVACY = 'https://www.tribegroup.co/privacy'

export const TRIBE_CREATORS = 'https://offers.tribegroup.co/tribe-app'

export const TRIBEFEE_INFO_LINK = 'https://www.tribegroup.co/blog/rundown-on-tribes-fee'

export const CONTENT_LIBRARY_LEARN_MORE =
  'https://www.tribegroup.co/blog/elevate-your-advertising-with-content-library'

export const CONTENT_LIBRARY_AD_READY_VIDEOS_LEARN_MORE =
  'https://www.tribegroup.co/social-video-ads'

export const ACTIVATION_FEE_LEARN_MORE =
  'https://www.tribegroup.co/blog/tribe-activation-fee-explained'

export const PAID_PARTNERSHIP_LEARN_HOW =
  'https://www.tribegroup.co/blog/a-marketers-guide-to-instagram-paid-partnerships'

export const TRIBE_INBOX_DEMO_LINK = 'https://tribe.wistia.com/medias/2o2uj2cjil'

export const INSTAGRAM_BRANDED_CONTENT = 'https://help.instagram.com/116947042301556'

export const BRANDED_CONTENT_ADS_HOW_TO =
  'https://www.tribegroup.co/blog/how-paid-partnerships-become-branded-content-ads'

export const WHAT_ARE_SPARK_ADS = 'https://www.tribegroup.co/blog/tiktok-spark-ads'

export const SHAREABLE_CONTENT_LIBRARY_EDUCATION_LINK =
  'https://www.tribegroup.co/blog/share-your-content-library'

export const TRIBE_BRANDS = 'https://www.tribegroup.co/brands'

export const INFLUENTIAL_CO = 'https://influential.co'

export const TRIBE_SIGNUP_SCL_CTA =
  'https://brand.tribegroup.co/signUp?utm_campaign=Content%20Library%20Share&utm_source=Referral&utm_medium=share&utm_content=content_library'

export const TRIBE_PRICING_PAGE = 'https://www.tribegroup.co/pricing'

export const TRIBE_PRICING_PAGE_AUDIENCE_INSIGHTS = `${TRIBE_PRICING_PAGE}?utm_source=Brand%20Platform&utm_medium=Product&utm_campaign=Product%20Links&utm_content=Audience%20Insights`
export const TRIBE_PRICING_PAGE_PRE_PURCHASE_METRICS_UTM = `${TRIBE_PRICING_PAGE}?utm_source=Brand%20Platform&utm_medium=Product&utm_campaign=Product%20Links&utm_content=Prepurchase%20Metrics`
export const TRIBE_PRICING_PAGE_POST_PURCHASE_METRICS_UTM = `${TRIBE_PRICING_PAGE}?utm_campaign=Product%20Links&utm_source=Brand%20Platform&utm_medium=Product&utm_content=Postpurchase`
export const TRIBE_PRICING_PAGE_METRIC_SUMMARY_UTM = `${TRIBE_PRICING_PAGE}?utm_source=Brand%20Platform&utm_medium=Product&utm_campaign=Product%20Links&utm_content=Metrics%20Summary`
export const TRIBE_PRICING_PAGE_CSV_DOWNLOAD_UTM = `${TRIBE_PRICING_PAGE}?utm_source=Brand%20Platform&utm_medium=Product&utm_campaign=Product%20Links&utm_content=CSV%20Download`
export const TRIBE_PRICING_PAGE_METRICS_GLOSSARY = `${TRIBE_PRICING_PAGE}?utm_source=Brand%20Platform&utm_medium=Product&utm_campaign=Product%20Links&utm_content=Metrics%20Glossary`
export const TRIBE_PRICING_PAGE_CONTENT_LIBRARY_LICENSING_UTM = `${TRIBE_PRICING_PAGE}??utm_source=Brand%20Platform&utm_medium=Product&utm_campaign=Product%20Links&utm_content=Content%20Licensing`
export const TRIBE_PRICING_PAGE_SHORTLIST_SUMMARY_UTM = `${TRIBE_PRICING_PAGE}?utm_source=Brand%20Platform&utm_medium=Product&utm_campaign=Product%20Links&utm_content=Shortlist%20Summary`
export const TRIBE_PRICING_PAGE_AUDIENCE_REPORTING_UTM = `${TRIBE_PRICING_PAGE}?utm_source=Brand%20Platform&utm_medium=Product&utm_campaign=Product%20Links&utm_content=Audience%20Reporting`

export const TRIBE_BRAND_FANS_BLOG =
  'https://www.tribegroup.co/blog/brand-fans-activate-your-top-creators'

export const TRIBE_BRAND_FANS_EDUCATION_LINK =
  'https://www.tribegroup.co/blog/brand-fans-activate-your-top-creators?utm_source=Brand%20Platform&utm_medium=Product&utm_campaign=Product%20Links&utm_content=Brandfans'

export const INSTAGRAM_POST_TYPES_LEARN_MORE =
  'https://help.tribegroup.co/en/articles/3276922-types-of-influencer-campaign-instagram-posts'

export const TRIBE_HELP_REQUEST_INVOICE_CREDIT =
  'https://help.tribegroup.co/en/articles/4520955-requesting-invoice-credit-from-your-content-library'

export const BRAND_REFERRAL_PROGRAM_LEARN_MORE =
  'https://help.tribegroup.co/en/articles/4610987-refer-a-friend-everything-you-need-to-know'

export const TRIBE_HOW_TO_INVITE_CREATORS =
  'https://help.tribegroup.co/en/articles/4679078-how-to-invite-your-own-creators-to-tribe'

export const TRIBE_PREAPPROVAL_BRANDS_LEARN_MORE =
  'https://help.tribegroup.co/en/articles/4932552-pre-approval-what-brands-need-to-know?_gl=1*nkoapi*_ga*MTc1MDA1MzAzMS4xNjQyMzk3NzU2*_ga_5MBMXTLJWL*MTY0Njc4OTczMC45MC4xLjE2NDY3ODk5MDAuMTk'

export const AUDIENCE_INSIGHTS_KNOW_HOW =
  'https://help.tribegroup.co/en/articles/4790309-everything-you-need-to-know-about-audience-insights'

export const DECLINE_PROCESS_EDUCATION =
  'https://help.tribegroup.co/en/articles/4932552-pre-approval-what-brands-need-to-know'

export const AUDIENCE_INSIGHTS_INFO =
  'https://help.tribegroup.co/en/articles/4790309-everything-you-need-to-know-about-audience-insights'

export const BRANDS_INSTAGRAM_CONNECT_EDUCATION =
  'https://help.tribegroup.co/en/articles/5138853-why-brands-should-connect-their-instagram-account-to-their-tribe-account'

export const BRANDS_TIKTOK_CONNECT_EDUCATION =
  'http://help.tribegroup.co/en/articles/6588467-why-brands-need-to-connect-their-tiktok-account-to-tribe'

export const BRAND_MATCH_EDUCATION =
  'https://www.tribegroup.co/blog/brandmatch-ai-find-right-creators'

export const CPM_EXPLAINED = 'https://help.tribegroup.co/en/articles/6126708-cpm-explained'

export const CAMPAIGN_PAYMENT_AND_FEES =
  'https://help.tribegroup.co/en/articles/2739814-campaign-payments-and-fees'

export const HOW_TO_PITCH = 'https://www.tribegroup.co/blog/how-to-pitch'

export const HOW_TO_SUBMIT_TO_CAMPAIGN =
  'https://www.tribegroup.co/blog/how-to-submit-to-a-campaign'

export const WHAT_IS_A_CONTENT_CAMPAIGN =
  'https://www.tribegroup.co/blog/what-the-faq-is-a-content-campaign'

export const TRIBE_CODES_OF_CONDUCT =
  'https://help.tribegroup.co/brands/campaigns/tribe-codes-of-conduct'

export const TIKTOK_ADS_MANAGER_CONNECT_EDUCATION =
  'http://help.tribegroup.co/en/articles/6588467-why-brands-need-to-connect-their-tiktok-account-to-tribe'

export const HOW_TO_USE_AUTH_CODE =
  'https://help.tribegroup.co/en/articles/6843741-how-to-use-an-auth-code-on-tiktok-ads-manager'

export const RATE_GUIDE = 'https://www.tribegroup.co/blog/guide-to-creator-fees'

export const BOOK_A_DEMO = 'https://www.tribegroup.co/book-a-demo'
