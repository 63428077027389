import React from 'react'

import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'

import {
  BreakdownStyled,
  BreakdownTable,
  BreakdownTableHeader,
  BreakdownTableWrapper,
} from '@components/Billing/Breakdown/Breakdown.styled'
import { MONTH_MONTH } from '@constants'
import { ICampaignContextProps } from '@context/Campaign'
import { ReferenceNumberPrefixes } from '@enums'
import { withCampaignNoRenderBlock } from '@hocs'
import hooks from '@hooks'
import { IPurchase } from '@store/billings'
import Theme from '@theme'
import { H4, Text } from '@tribegroup'
import PurchaseBreakdownRow from './Row/Row'

interface IPurchaseBreakdownProps {
  purchases: ReadonlyArray<IPurchase>
  month: string
}

interface IInternalProps extends IPurchaseBreakdownProps, ICampaignContextProps {}

export const PurchaseBreakdown: React.FC<IInternalProps> = ({ purchases, month, campaign }) => {
  const displayMonth = format(new Date(month), MONTH_MONTH)

  const { pathname } = hooks.useLocation()

  return (
    <BreakdownStyled>
      <H4
        color={Theme.grey900Color}
        topOuterSpacing={1}
        bottomOuterSpacing={2}
        leftOuterSpacing={0.625}
      >
        {displayMonth}
      </H4>
      <BreakdownTableWrapper>
        <BreakdownTable>
          <thead>
            <tr>
              <BreakdownTableHeader width="35%">
                <Text xsmall uppercase color={Theme.grey700Color}>
                  <FormattedMessage id="billing.purchaseBreakdown.table.header.referenceNo" />
                </Text>
              </BreakdownTableHeader>
              <BreakdownTableHeader>
                <Text xsmall uppercase color={Theme.grey700Color}>
                  <FormattedMessage id="core.text.poNumber" />
                </Text>
              </BreakdownTableHeader>
              <BreakdownTableHeader>
                <Text xsmall uppercase color={Theme.grey700Color}>
                  <FormattedMessage id="core.text.paymentMethod" />
                </Text>
              </BreakdownTableHeader>
              <BreakdownTableHeader>
                <Text xsmall uppercase color={Theme.grey700Color}>
                  <FormattedMessage id="billing.purchaseBreakdown.table.header.purchaseDate" />
                </Text>
              </BreakdownTableHeader>
              <BreakdownTableHeader>
                <Text xsmall uppercase color={Theme.grey700Color}>
                  <FormattedMessage id="billing.purchaseBreakdown.table.header.amount" />
                </Text>
              </BreakdownTableHeader>
              <BreakdownTableHeader>
                <Text xsmall uppercase color={Theme.grey700Color}>
                  <FormattedMessage id="billing.purchaseBreakdown.table.header.tax" />
                </Text>
              </BreakdownTableHeader>
              <BreakdownTableHeader>
                <Text xsmall uppercase color={Theme.grey700Color}>
                  <FormattedMessage id="billing.purchaseBreakdown.table.header.total" />
                </Text>
              </BreakdownTableHeader>
            </tr>
          </thead>
          <tbody>
            {purchases.map((purchase) => {
              const isContentLicense = purchase.purchase_number.includes(
                ReferenceNumberPrefixes.ContentLicense,
              )
              return (
                <PurchaseBreakdownRow
                  key={`purchase-${purchase.id}`}
                  campaign={campaign}
                  purchase={purchase}
                  searchParams={{
                    from: pathname,
                    filter: isContentLicense ? 'licensed' : undefined,
                    receiptNumber: isContentLicense ? purchase.purchase_number : undefined,
                  }}
                />
              )
            })}
          </tbody>
        </BreakdownTable>
      </BreakdownTableWrapper>
    </BreakdownStyled>
  )
}

export default withCampaignNoRenderBlock(PurchaseBreakdown)
