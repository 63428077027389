import React from 'react'

import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'

const CheckedListIem: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Container bottomOuterSpacing={1}>
    <Flex alignStart justifyStart>
      <Container rightOuterSpacing={0.5}>
        <Icon glyph="tick-heavy" color={Theme.successColor} size={1.25} />
      </Container>
      <Text color={Theme.grey900Color}>{children}</Text>
    </Flex>
  </Container>
)

export default CheckedListIem
