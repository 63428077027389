import React from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Image from '@components/UI/Image'
import { AspectRatios } from '@enums'
import { FadeIn } from '@lib/effects'
import { IApplicationState } from '@store'
import {
  selectGlyphIcon,
  selectMediaThumbnail,
  selectSubmission,
  selectTranslatedTooltipText,
} from '@store/submissions'
import Theme from '@theme'
import { Icon } from '@tribegroup'
import {
  ThumbnailImageIconWrapper,
  ThumbnailImageOverlay,
  ThumbnailImageWrapper,
} from './Image.styled'

interface ISubmissionThumbnailImageProps {
  submissionId: number
  isSocial: boolean
}

interface IInternalProps extends ISubmissionThumbnailImageProps, InjectedIntlProps {
  mediaUrl: string
  iconGlyph?: string
  tooltipText?: string
}

const SubmissionThumbnailImage: React.FC<IInternalProps> = ({
  tooltipText,
  iconGlyph,
  mediaUrl,
}) => {
  return (
    <ThumbnailImageWrapper data-tooltip-content={tooltipText} data-tooltip-id="base-tooltip">
      <Image
        src={mediaUrl}
        aspectRatio={AspectRatios.ONE_BY_ONE}
        animation={FadeIn}
        loadingColor={Theme.whiteColor}
      />
      <ThumbnailImageOverlay />
      {iconGlyph && (
        <ThumbnailImageIconWrapper>
          <Icon size={1.5} glyph={iconGlyph} color={Theme.whiteColor} />
        </ThumbnailImageIconWrapper>
      )}
    </ThumbnailImageWrapper>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { submissionId, intl }: ISubmissionThumbnailImageProps & InjectedIntlProps,
) => ({
  submission: selectSubmission(state, submissionId),
  mediaUrl: selectMediaThumbnail(state, submissionId),
  iconGlyph: selectGlyphIcon(state, submissionId),
  tooltipText: selectTranslatedTooltipText(state, submissionId, intl),
})

export { SubmissionThumbnailImage }
export default compose<IInternalProps, ISubmissionThumbnailImageProps>(
  injectIntl,
  connect(mapStateToProps),
)(SubmissionThumbnailImage)
