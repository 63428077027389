import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { MemberRoleTypes, SubmissionChannels } from '@enums'
import { selectBudgetTotalCurrency, selectRole } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import {
  fetchPrePurchaseAnalytics,
  selectIsFetchingPrePurchaseAnalytics,
  selectPrePurchaseAnalytics,
  selectPrePurchaseAnalyticsType,
} from '@store/prePurchaseAnalytics'
import {
  selectEstimatedCPECurrency,
  selectIdentityId,
  selectSocialSubmissionType,
  selectSubmission,
  selectSubTotalCurrency,
} from '@store/submissions'
import { AnalyticsBreakdown } from './Breakdown'
import { FINANCIAL_ANALYTICS_KEYS, HIDDEN_REACH_METRICS } from './mapping-functions'
import { AnalyticsData } from './types'

type Props = {
  submissionId: number
}

export const AnalyticsPrePurchaseBreakdown: React.FC<Props> = ({ submissionId }) => {
  const dispatch = useDispatch()
  const {
    identityId,
    analyticsType,
    isFetching,
    analytics,
    currency,
    socialSubmissionType,
    channel,
  } = useSelector((state: IApplicationState) => {
    const {
      identity_provider,
      identity_followers_count,
      brief_id,
      is_price_hidden,
    } = selectSubmission(state, submissionId)
    const role = selectRole(state, brief_id)
    const identityId = selectIdentityId(state, submissionId)
    const analyticsType = selectPrePurchaseAnalyticsType(state, submissionId)
    const isFetching = selectIsFetchingPrePurchaseAnalytics(state)
    const prePurchaseAnalytics = selectPrePurchaseAnalytics(state, submissionId, identityId) ?? {}
    const campaignBudgetCurrency = selectBudgetTotalCurrency(state, brief_id)
    const estCpeCurrency = selectEstimatedCPECurrency(state, submissionId)
    const currency =
      selectSubTotalCurrency(state, submissionId) ?? estCpeCurrency ?? campaignBudgetCurrency
    const socialSubmissionType = selectSocialSubmissionType(state, submissionId)
    const analytics: AnalyticsData = {
      followers: identity_followers_count,
      ...prePurchaseAnalytics,
    }

    const isHiddenFinancialAnalytics =
      role === MemberRoleTypes.SolutionCollaborator && is_price_hidden

    if (isHiddenFinancialAnalytics) {
      FINANCIAL_ANALYTICS_KEYS.forEach((key) => {
        analytics[key] = 'hidden'
      })
    }

    const hiddenMetrics = HIDDEN_REACH_METRICS[socialSubmissionType]

    if (hiddenMetrics) {
      hiddenMetrics.forEach((key) => {
        analytics[key] = 'hidden'
      })
    }

    return {
      identityId,
      analyticsType,
      isFetching,
      analytics,
      currency,
      socialSubmissionType,
      channel: identity_provider,
    }
  })

  useEffect(() => {
    dispatch(fetchPrePurchaseAnalytics(identityId, submissionId, analyticsType))
  }, [identityId, submissionId, analyticsType])

  return (
    <AnalyticsBreakdown
      analytics={analytics}
      perspective="pre-purchase"
      isLoaded={!isFetching}
      currency={currency}
      socialSubmissionType={socialSubmissionType}
      channel={channel as SubmissionChannels}
    />
  )
}

export default AnalyticsPrePurchaseBreakdown
