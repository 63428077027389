import { call, put, select, takeLatest } from 'redux-saga/effects'

import SubmissionCardModalPreapproveLicenseToast from '@components/Submission/Card/Modal/License.New/Toast'
import SubmissionCardModalLicenseToast from '@components/Submission/Card/Modal/License/Toast'
import { INBOX_FILTER, PREAPPROVED_FILTER, SHORTLISTED_FILTER } from '@constants'
import { ErrorCodes, EventTrackingNames } from '@enums'
import { handlePaymentAuthFailure } from '@sagas/payments'
import { IHateoasLink } from '@store'
import {
  selectSelectedLicenseOptionTrackingProps,
  selectSubmissionTrackingProps,
  trackEvent,
} from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import { fetchCampaignBillings } from '@store/billings'
import { fetchCampaign } from '@store/campaigns'
import {
  ILicense3DSResponse,
  selectPreApprovePurchaseLicenseOptionLink,
  selectPurchaseLicenseOptionLink,
} from '@store/licenses'
import { fetchMeRequest } from '@store/me'
import {
  fetchMediaObjects,
  ITransactedLicense,
  purchaseLicense,
  PurchaseLicenseActionTypes,
  purchaseLicenseError,
  purchaseLicenseSuccess,
} from '@store/mediaObjects'
import { selectStripe } from '@store/payments'
import { selectCurrentSubmissionFilter } from '@store/router'
import { fetchSubmission, fetchSubmissions, updateFilterSubmissionIds } from '@store/submissions'
import { setUIFlag, UIFlag } from '@store/ui'
import { Toaster } from '@tribegroup'
import { networkRequest, selectLink, stripeErrorToResponseError } from '@utils'

export function* handleSecurePurchaseLicenseRequest(licenseLink: IHateoasLink) {
  const authToken: string = yield select(selectAuthToken)

  const requestPurchase3DSResponse: ILicense3DSResponse = yield call(
    networkRequest,
    licenseLink.method,
    licenseLink.href,
    undefined,
    authToken,
  )

  if (requestPurchase3DSResponse.license) {
    return requestPurchase3DSResponse.license
  }

  const stripeSDK = yield select(selectStripe)

  const paymentIntentResponse = yield call(
    stripeSDK.confirmCardPayment,
    requestPurchase3DSResponse.client_secret,
  )

  if (paymentIntentResponse.error) {
    const paymentError = stripeErrorToResponseError(
      paymentIntentResponse.error,
      ErrorCodes.CONTENT_LICENSING_STRIPE_ERROR,
    )
    const errorResponse = yield call(
      handlePaymentAuthFailure,
      requestPurchase3DSResponse,
      paymentError,
    )
    throw errorResponse
  }

  const { paymentIntent } = paymentIntentResponse

  const purchase3DSLink: IHateoasLink = selectLink(requestPurchase3DSResponse.links, 'purchase3ds')!

  const license: ITransactedLicense = yield call(
    networkRequest,
    purchase3DSLink.method,
    purchase3DSLink.href,
    {
      payment_intent_id: paymentIntent.id,
    },
    authToken,
  )

  return license
}

export function* handlePurchaseLicense(action: ReturnType<typeof purchaseLicense>) {
  try {
    const { mediaObjectId, licenseIndex, campaignId, submissionId, meta } = action.payload

    let purchaseLicenseLink: IHateoasLink = yield select(
      selectPurchaseLicenseOptionLink,
      mediaObjectId,
      licenseIndex,
    )

    if (meta?.purchaseType === 'pre-approval') {
      purchaseLicenseLink = yield select(
        selectPreApprovePurchaseLicenseOptionLink,
        mediaObjectId,
        licenseIndex,
      )
    }

    const transactedLicense: ITransactedLicense = yield call(
      handleSecurePurchaseLicenseRequest,
      purchaseLicenseLink,
    )

    if (meta?.purchaseType === 'pre-approval') {
      yield call(Toaster.createToast, SubmissionCardModalPreapproveLicenseToast)
    } else {
      yield call(Toaster.createToast, SubmissionCardModalLicenseToast, {
        downloadLink: transactedLicense.original_media_url,
      })
    }

    yield put(
      updateFilterSubmissionIds(campaignId!, submissionId!, [
        INBOX_FILTER,
        SHORTLISTED_FILTER,
        PREAPPROVED_FILTER,
      ]),
    )

    yield put(fetchCampaign(campaignId!))

    yield put(purchaseLicenseSuccess(mediaObjectId, transactedLicense))

    if (meta?.purchaseType === 'pre-approval') {
      const properties = yield select(selectSubmissionTrackingProps, campaignId, submissionId)
      const licenseOptionTrackingProps = yield select(
        selectSelectedLicenseOptionTrackingProps,
        campaignId,
        submissionId,
        licenseIndex,
      )
      yield put(
        trackEvent(EventTrackingNames.PreApproveSubmission, {
          ...properties,
          ...licenseOptionTrackingProps,
          pre_approved: true,
        }),
      )
    } else {
      const eventProperties = yield select(
        selectSelectedLicenseOptionTrackingProps,
        campaignId,
        submissionId,
        licenseIndex,
      )
      yield put(trackEvent(EventTrackingNames.LicenseContentSubmission, eventProperties))
    }

    yield put(fetchSubmission(submissionId!, campaignId!))
    yield put(fetchMeRequest())

    yield put(fetchMediaObjects())

    yield put(fetchCampaignBillings())

    if (meta?.refreshSubmissions) {
      const filter = yield select(selectCurrentSubmissionFilter, campaignId)
      yield put(fetchSubmissions(campaignId!, { ...meta, filter }))
    }

    yield put(setUIFlag(UIFlag.HIDE_MODAL))
  } catch (error) {
    yield put(purchaseLicenseError(error))
  }
}

export function* watchPurchaseLicenseRequest() {
  yield takeLatest(PurchaseLicenseActionTypes.PURCHASE_LICENSE, handlePurchaseLicense)
}
