import { Col } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'

export const PostCampaignReportPerformanceWrapper = styled.div`
  box-sizing: content-box;
  width: calc(100% - 1.25rem);
  border-right: solid ${Theme.grey400Color} 1px;
  height: 100%;
`

export const ColWrapper = styled(Col)`
  ${breakpoint('tabletLandscape')`
  &:last-of-type > ${PostCampaignReportPerformanceWrapper} {
    border: none;
  }
`}
`
