import { FC } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import Price from '@components/UI/Price/Price'
import { ApprovalType } from '@store/campaigns'
import { ILicense } from '@store/licenses'
import { IMediaObject } from '@store/mediaObjects'
import { selectPreapprovalAmountPercentage } from '@store/rootResource'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { isEmpty } from '@utils/isEmpty'

interface IProps {
  license: ILicense
  mediaObject: IMediaObject
  approvalType?: ApprovalType
}

export const PurchaseLicensePriceSummary: FC<IProps> = ({ license, approvalType, mediaObject }) => {
  if (!license) {
    return <></>
  }

  const {
    tax_display_name,
    total_amount_before_tax_cents,
    total_amount_before_tax_currency,
    preapproval_amount_before_tax_cents,
    preapproval_amount_before_tax_currency,
    amount_paid_before_tax_cents,
    amount_paid_before_tax_currency,
    balance_amount_before_tax_cents,
    balance_amount_before_tax_currency,
  } = license

  const transactedLicense = mediaObject?.transacted_license

  const preapprovalPercentage = useSelector(selectPreapprovalAmountPercentage)

  if (isEmpty(transactedLicense) && approvalType === 'pre-approval') {
    return (
      <>
        <Text
          customFontSize={0.8125}
          lineHeight={1}
          color={Theme.primaryColor}
          uppercase
          customletterSpacing="1.5px"
        >
          <FormattedMessage id="contentSubmission.card.modal.preApprove.priceSummary.header" />
        </Text>
        <Container
          flexBetween
          alignItems="flex-start"
          fullWidth
          borderColor={Theme.grey400Color}
          topOuterSpacing={1}
          borderWidthTop={0.0625}
          topInnerSpacing={0.5}
        >
          <Text color={Theme.grey900Color} textAlign="left" block medium>
            <FormattedMessage
              id="core.text.totalCurrency"
              values={{ currency: total_amount_before_tax_currency }}
            />
            &nbsp;(
            <FormattedMessage
              id="submission.fullView.pricePanel.tax.text"
              values={{ taxName: tax_display_name }}
            />
            )
          </Text>
          <Container flexEnd noShrink>
            <Container rightOuterSpacing={0.25}>
              <Text color={Theme.grey900Color} medium>
                <Price
                  quantity={total_amount_before_tax_cents!}
                  currency={total_amount_before_tax_currency}
                />
              </Text>
            </Container>
          </Container>
        </Container>

        <Container
          flexBetween
          alignItems="flex-start"
          fullWidth
          borderColor={Theme.grey400Color}
          topOuterSpacing={0.75}
          borderWidthTop={0.0625}
          topInnerSpacing={0.5}
        >
          <Text color={Theme.primaryColor} textAlign="left" block medium>
            {`${preapprovalPercentage}% ${preapproval_amount_before_tax_currency}`}
          </Text>
          <Container flexEnd noShrink>
            <Container rightOuterSpacing={0.25}>
              <Text color={Theme.primaryColor} medium>
                <Price
                  quantity={preapproval_amount_before_tax_cents!}
                  currency={preapproval_amount_before_tax_currency}
                />
              </Text>
            </Container>
          </Container>
        </Container>
      </>
    )
  }

  if (transactedLicense?.status === 'preapproved') {
    return (
      <>
        <Text
          customFontSize={0.8125}
          lineHeight={1}
          color={Theme.primaryColor}
          uppercase
          customletterSpacing="1.5px"
        >
          <FormattedMessage id="core.text.summary" />
        </Text>
        <Container
          flexBetween
          alignItems="flex-start"
          fullWidth
          borderColor={Theme.grey400Color}
          topOuterSpacing={1}
          borderWidthTop={0.0625}
          topInnerSpacing={0.5}
        >
          <Text color={Theme.grey900Color} textAlign="left" block medium>
            <FormattedMessage
              id="core.text.totalCurrency"
              values={{ currency: total_amount_before_tax_currency }}
            />
            &nbsp;(
            <FormattedMessage
              id="submission.fullView.pricePanel.tax.text"
              values={{ taxName: tax_display_name }}
            />
            )
          </Text>
          <Container flexEnd noShrink>
            <Container rightOuterSpacing={0.25}>
              <Text color={Theme.grey900Color} medium>
                <Price
                  quantity={total_amount_before_tax_cents!}
                  currency={total_amount_before_tax_currency}
                />
              </Text>
            </Container>
          </Container>
        </Container>

        <Container
          flexBetween
          alignItems="flex-start"
          fullWidth
          borderColor={Theme.grey400Color}
          topOuterSpacing={0.75}
          borderWidthTop={0.0625}
          topInnerSpacing={0.5}
        >
          <Text color={Theme.grey900Color} textAlign="left" block light>
            <FormattedMessage
              id="contentSubmission.card.modal.preApprove.priceSummary.payment"
              values={{
                preapprovalPercentage,
                currency: amount_paid_before_tax_currency,
              }}
            />
          </Text>
          <Container flexEnd noShrink>
            <Container rightOuterSpacing={0.25}>
              <Text color={Theme.grey900Color} light>
                <Price
                  quantity={amount_paid_before_tax_cents! * -1}
                  currency={amount_paid_before_tax_currency}
                />
              </Text>
            </Container>
          </Container>
        </Container>
        <Container flexBetween alignItems="flex-start" fullWidth topOuterSpacing={0.25}>
          <Text color={Theme.primaryColor} textAlign="left" block medium>
            <FormattedMessage
              id="contentSubmission.card.modal.preApprove.priceSummary.balanceDue"
              values={{ currency: balance_amount_before_tax_currency }}
            />
          </Text>
          <Container flexEnd noShrink>
            <Container rightOuterSpacing={0.25}>
              <Text color={Theme.primaryColor} medium>
                <Price
                  quantity={balance_amount_before_tax_cents!}
                  currency={balance_amount_before_tax_currency}
                />
              </Text>
            </Container>
          </Container>
        </Container>
      </>
    )
  }

  return (
    <Container
      flexBetween
      alignItems="flex-start"
      fullWidth
      borderColor={Theme.grey400Color}
      topOuterSpacing={1}
      borderWidthTop={0.0625}
      topInnerSpacing={0.5}
    >
      <Text color={Theme.grey900Color} textAlign="left" block medium>
        <FormattedMessage
          id="core.text.totalCurrency"
          values={{ currency: total_amount_before_tax_currency }}
        />
        &nbsp;(
        <FormattedMessage
          id="submission.fullView.pricePanel.tax.text"
          values={{ taxName: tax_display_name }}
        />
        )
      </Text>
      <Container flexEnd noShrink>
        <Container rightOuterSpacing={0.25}>
          <Text color={Theme.grey900Color} medium>
            <Price
              quantity={total_amount_before_tax_cents!}
              currency={total_amount_before_tax_currency}
            />
          </Text>
        </Container>
      </Container>
    </Container>
  )
}
