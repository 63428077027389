import { FormattedMessage } from 'react-intl'

import DetailCard from '@components/UI/DetailCard'
import Theme from '@theme'
import { Container } from '@tribegroup'
import { PerformanceDetailCardWrapper } from './Details.styled'

interface IPerformancePlaceholderProps {
  type: 'unavailable' | 'story' | 'pinterest'
}

const PerformancePlaceholder = ({ type }: IPerformancePlaceholderProps) => (
  <PerformanceDetailCardWrapper xs={12}>
    <DetailCard
      title={<FormattedMessage id={`submission.performance.placeholder.${type}.title`} />}
      color={Theme.grey800Color}
      content={
        <Container topOuterSpacing={0.375} bottomInnerSpacing={0.25}>
          <div>
            <FormattedMessage id={`submission.performance.placeholder.${type}.content1`} />
          </div>
          <div>
            <FormattedMessage id={`submission.performance.placeholder.${type}.content2`} />
          </div>
        </Container>
      }
      smallContentText
    />
  </PerformanceDetailCardWrapper>
)

export default PerformancePlaceholder
