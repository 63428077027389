import PaymentForm from '@components/Payment/Form'
import Payment from '@components/Payment/Payment'

export const AccountPaymentForm = () => (
  <Payment>
    <PaymentForm autoFocus />
  </Payment>
)

export default AccountPaymentForm
