import { format } from 'date-fns'
import { Reducer } from 'redux'

import { YEAR_MONTH } from '@constants'
import { BillingTypes } from '@enums'
import { AuthActionTypes } from '@store/auth'
import { addToSet } from '@utils'
import { indexById } from '@utils/groupBy'
import {
  CampaignBillingActionTypes,
  ContentLibraryBillingActionTypes,
  ContentLibraryBillingUsingNextActionTypes,
  IBillingState,
} from './types'

const initialState: IBillingState = {
  isFetching: false,
  linksByBillingType: {},
  billingsByCampaignId: {},
  purchaseIdsByMonthInContentLibrary: {},
  purchasesById: {},
  purchaseIdsByMonthPerCampaignId: {},
  errors: undefined,
}

const reducer: Reducer<IBillingState> = (state = initialState, action) => {
  switch (action.type) {
    case ContentLibraryBillingUsingNextActionTypes.FETCH_REQUEST:
    case ContentLibraryBillingActionTypes.FETCH_REQUEST:
    case CampaignBillingActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        purchaseIdsByMonthPerCampaignId: {},
        isFetching: true,
      }
    }

    case CampaignBillingActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }
    }

    case ContentLibraryBillingActionTypes.FETCH_SUCCESS:
    case ContentLibraryBillingUsingNextActionTypes.FETCH_SUCCESS: {
      const billing = action.payload
      const items = billing.items || []
      const contentLibraryLinks = billing.links || []

      return {
        ...state,
        isFetching: false,
        errors: undefined,
        linksByBillingType: {
          ...state.linksByBillingType,
          [BillingTypes.ContentLibrary]: contentLibraryLinks,
        },
        purchasesById: indexById(items, state.purchasesById),
        purchaseIdsByMonthInContentLibrary: items.reduce((obj, item) => {
          const month = format(new Date(item.purchased_at), YEAR_MONTH)
          const purchaseIdsUnderMonth = obj[month] || []
          return {
            ...obj,
            [month]: addToSet([...purchaseIdsUnderMonth, item.id]),
          }
        }, {}),
      }
    }

    case CampaignBillingActionTypes.FETCH_SUCCESS: {
      const billing = action.payload
      const items = billing.items || []
      const campaignLinks = billing.links || []

      return {
        ...state,
        isFetching: false,
        errors: undefined,
        linksByBillingType: {
          ...state.linksByBillingType,
          [BillingTypes.Campaigns]: campaignLinks,
        },
        billingsByCampaignId: indexById(items, state.billingsByCampaignId),
        purchasesById: items.reduce((accum, curr) => {
          const purchases = curr.purchases.reduce(
            (obj, item) => ({
              ...obj,
              [item.id]: item,
            }),
            {},
          )
          return {
            ...accum,
            ...purchases,
          }
        }, state.purchasesById),

        purchaseIdsByMonthPerCampaignId: items.reduce((accum, curr) => {
          const campaignId = curr.id
          const purchaseIdsByMonthDefaultValue =
            state.purchaseIdsByMonthPerCampaignId[campaignId] || {}
          const purchaseIdsByMonth = curr.purchases.reduce((obj, item) => {
            const month = format(new Date(item.purchased_at), YEAR_MONTH)
            const purchaseIdsUnderMonth = obj[month] || []
            return {
              ...obj,
              [month]: addToSet([...purchaseIdsUnderMonth, item.id]),
            }
          }, purchaseIdsByMonthDefaultValue)
          return {
            ...accum,
            [campaignId]: { ...purchaseIdsByMonth },
          }
        }, state.purchaseIdsByMonthPerCampaignId),
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as billingsReducer }
