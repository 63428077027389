import getConfig from '@utils/getConfig'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Rollbar = require('rollbar')

const ROLLBAR_ACCESSTOKEN = '2ecf298e1d2044feaa370fcbdf345063'

const environment = getConfig('environment')
const enabled = ['test', 'uat', 'production'].includes(environment)

export const Logger = {
  init() {
    Rollbar.init({
      accessToken: ROLLBAR_ACCESSTOKEN,
      captureUncaught: true,
      payload: {
        environment: enabled && environment,
      },
      enabled,
    })
  },
  log(level, name, body) {
    return enabled && Rollbar.log(level, name, body)
  },
}
