import React, { useContext } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { compose } from 'recompose'

import { CampaignContext } from '@context/Campaign'
import { IApplicationState } from '@store'
import { IPCRPerformance, selectPerformanceDisplayList } from '@store/postCampaignReport'
import { selectActivePCRChannel, selectCampaignId } from '@store/router'
import PostCampaignReportPerformance from '../Performance'
import { ColWrapper, PostCampaignReportPerformanceWrapper } from './List.styled'

export interface IInternalProps {
  performanceList: ReadonlyArray<ReadonlyArray<IPCRPerformance>>
  campaignId: number
}

const PostCampaignReporPerformanceList: React.FC<IInternalProps> = ({
  performanceList,
  campaignId,
}) => {
  const {
    campaign: { is_price_hidden: isPriceHidden },
  } = useContext(CampaignContext)

  let data = performanceList

  if (isPriceHidden) {
    data = data.map((performanceList) =>
      performanceList.filter(
        (column) =>
          !column.key.includes('overallCostPerEngagement') && !column.key.includes('totalSpend'),
      ),
    )
  }

  return (
    <Grid fluid>
      <Row>
        {data.map((column, colIndex) => (
          <ColWrapper xs={6} lg={3} key={`pcr-performance-column-${colIndex}`}>
            <PostCampaignReportPerformanceWrapper>
              {column.map((performance, index) => (
                <Col key={`pcr-performance-row-${index}`}>
                  <PostCampaignReportPerformance
                    campaignId={campaignId}
                    title={performance.key}
                    value={performance.value}
                    currency={performance.currency}
                    showPercentSign={performance.showPercentSign}
                    abbreviateValue={performance.abbreviateValue}
                  />
                </Col>
              ))}
            </PostCampaignReportPerformanceWrapper>
          </ColWrapper>
        ))}
      </Row>
    </Grid>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const activeChannel = selectActivePCRChannel(state)
  const campaignId = selectCampaignId(match)!
  return {
    campaignId,
    performanceList: selectPerformanceDisplayList(state, campaignId, activeChannel),
  }
}

export { PostCampaignReporPerformanceList }
export default compose(withRouter, connect(mapStateToProps))(PostCampaignReporPerformanceList)
