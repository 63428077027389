import { FormattedMessage } from 'react-intl'

import LinkifiedString from '@components/UI/LinkifiedString'
import Theme from '@theme'
import { Text } from '@tribegroup'
import { drawerDateFormat } from '@utils'
import {
  InternalMessageBlockStyled,
  InternalMessageDateText,
  InternalMessageStyled,
} from './InternalMessage.styled'

const InternalMessage = ({ message }) => {
  const dateTextProps = message.created_at && drawerDateFormat(new Date(message.created_at))

  return (
    <InternalMessageBlockStyled>
      <InternalMessageDateText>
        <Text small color={Theme.grey700Color}>
          {message.created_at && (
            <FormattedMessage id={dateTextProps.id} values={dateTextProps.values} />
          )}
        </Text>
      </InternalMessageDateText>
      <InternalMessageStyled>
        <Text textAlign="left" color={Theme.grey900Color}>
          <LinkifiedString value={message.text} />
        </Text>
      </InternalMessageStyled>
    </InternalMessageBlockStyled>
  )
}

export default InternalMessage
