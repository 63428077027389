import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Text } from '@tribegroup'
import { OptionWrapper } from './FindProduct.styled'

export const BuilderCampaignFindProductOption = ({ option, onClick, active }) => {
  const onOptionClick = () => {
    return onClick(option)
  }

  return (
    <OptionWrapper
      key={option.code}
      onClick={onOptionClick}
      active={active}
      data-testid={`find-product-option-${option.code}`}
    >
      <Text color={Theme.grey800Color}>
        <FormattedMessage id={`builder.campaign.findProduct.option.${option.code}`} />
      </Text>
    </OptionWrapper>
  )
}

export default BuilderCampaignFindProductOption
