import { FormattedMessage } from 'react-intl'

import { useGender } from '@hooks/useGender'
import Theme from '@theme'
import { BlockText } from '../Block/Block.styled'

interface IInfluencerGenderProps {
  instagramGenders?: boolean
  selectedGenderCodes: ReadonlyArray<string>
}

const CampaignPreviewCommonBlockGender = ({
  instagramGenders,
  selectedGenderCodes,
}: IInfluencerGenderProps) => {
  const { allGenders } = useGender(instagramGenders)

  const hasSelectedAll = allGenders.length === selectedGenderCodes.length

  if (hasSelectedAll) {
    return (
      <BlockText small color={Theme.grey800Color}>
        <FormattedMessage id="core.text.allGenders" />
      </BlockText>
    )
  }

  const displayedGenders = allGenders.reduce((accum, curr) => {
    if (!selectedGenderCodes!.includes(curr.code)) {
      return accum
    }
    return [...accum, curr.display_name]
  }, [])

  return (
    <BlockText small color={Theme.grey800Color}>
      {displayedGenders.join(', ')}
    </BlockText>
  )
}

export default CampaignPreviewCommonBlockGender
