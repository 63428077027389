import { IApplicationState } from '@store'

export const selectPasswordErrors = (state: IApplicationState) => state.password.errors

export const selectPasswordErrorMessages = (state: IApplicationState) => {
  const errors = selectPasswordErrors(state)
  return errors && errors.messages
}

export const selectIsRequesting = (state: IApplicationState) => state.password.isRequesting
