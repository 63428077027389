import { useContext } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import MultiframeDescriptionPanel from '@components/UI/Multiframe/DescriptionPanel/DescriptionPanel'
import { CampaignContext } from '@context/Campaign'
import { SubmissionContext } from '@context/Submission'
import { SocialSubmissionTypes } from '@enums'
import { IApplicationState } from '@store'
import { selectSocialSubmissionType } from '@store/submissions'
import Theme from '@theme'
import { Text } from '@tribegroup'
import { isSocialCampaign } from '@utils/campaign'

interface IHeaderProps {
  socialSubmissionType: SocialSubmissionTypes
  isSocial: boolean
}

const Header: React.FC<IHeaderProps> = ({ isSocial, socialSubmissionType }) => {
  const isMultiframe =
    socialSubmissionType === SocialSubmissionTypes.Story ||
    socialSubmissionType === SocialSubmissionTypes.Carousel
  const isReel = socialSubmissionType === SocialSubmissionTypes.Reel

  if (isMultiframe) {
    return <MultiframeDescriptionPanel socialSubmissionType={socialSubmissionType} />
  }

  if (isReel) {
    return <FormattedMessage id="core.text.reel" />
  }

  if (isSocial) {
    if (socialSubmissionType === SocialSubmissionTypes.TikTok) {
      return <FormattedMessage id="submission.card.creator.notes.label.tiktok" />
    }
    if (socialSubmissionType === SocialSubmissionTypes.Pin) {
      return <FormattedMessage id="submission.card.creator.notes.label.pin" />
    }
    if (socialSubmissionType === SocialSubmissionTypes.Text) {
      return <FormattedMessage id="submission.card.creator.notes.label.text" />
    }
    return <FormattedMessage id="submission.card.creator.notes.label.socialPost" />
  }

  return <FormattedMessage id="submission.card.creator.notes.label.contentSubmission" />
}

export const SubmissionCreatorNotePanelHeader: React.FC = () => {
  const { submission } = useContext(SubmissionContext)
  const { campaign } = useContext(CampaignContext)
  const { socialSubmissionType, isSocial } = useSelector((state: IApplicationState) => ({
    socialSubmissionType: selectSocialSubmissionType(state, submission.id),
    isSocial: isSocialCampaign(campaign),
  }))

  return (
    <Text
      color={Theme.defaultColor}
      uppercase
      xsmall
      data-testid="submissionCreatorNotePanelHeader"
    >
      <Header socialSubmissionType={socialSubmissionType} isSocial={isSocial} />
    </Text>
  )
}

export default SubmissionCreatorNotePanelHeader
