import { MemberRoleTypes } from '@enums'
import { IResponseError } from '@lib/error'
import { IHateoasLink } from '@store'

export interface IMember {
  id: number
  name?: string
  first_name?: string
  last_name?: string
  email: string
  created_at?: string
  phone_number?: string
  role_type: string
  links?: ReadonlyArray<IHateoasLink>
}

export interface IMembersState {
  readonly errors?: IResponseError
  readonly isFetching: boolean
  readonly membersById: Record<number, IMember>
  readonly memberIdsByCampaignId: Record<number, ReadonlyArray<number>>
  readonly memberLinksByCampaignId: Record<number, any>
  readonly isAdding: boolean
  readonly isRemoving: boolean
}

export interface IMemberInviteFormData {
  email: string
  role_type: MemberRoleTypes
}

export enum MembersActionTypes {
  FETCH_REQUEST = 'members/FETCH_REQUEST',
  FETCH_SUCCESS = 'members/FETCH_SUCCESS',
  FETCH_ERROR = 'members/FETCH_ERROR',
}

export enum MemberActionTypes {
  ADD_REQUEST = 'member/ADD_REQUEST',
  ADD_SUCCESS = 'member/ADD_SUCCESS',
  ADD_ERROR = 'member/ADD_ERROR',
  REMOVE_REQUEST = 'member/REMOVE_REQUEST',
  REMOVE_SUCCESS = 'member/REMOVE_SUCCESS',
  REMOVE_ERROR = 'member/REMOVE_ERROR',
  CLEAR_ERRORS = 'member/CLEAR_ERROR',
}
