import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import { selectInfluencerIdentitiesLink } from '@store/campaigns'
import {
  fetchInfluencerIdentities,
  fetchInfluencerIdentitiesError,
  fetchInfluencerIdentitiesSuccess,
  fetchInfluencerIdentitiesUsingNext,
  InfluencerIdentitiesActionTypes,
} from '@store/influencerIdentities'
import { selectCurrentSortBy } from '@store/router'
import { networkRequest } from '@utils'

export function* handleFetchInfluencerIdentities(
  action: ReturnType<typeof fetchInfluencerIdentities | typeof fetchInfluencerIdentitiesUsingNext>,
) {
  try {
    const campaignId = action.payload
    const authToken: string = yield select(selectAuthToken)

    const sortBy = yield select(selectCurrentSortBy)
    const influencersIdentitiesLink: IHateoasLink = yield select(
      selectInfluencerIdentitiesLink,
      campaignId,
    )

    const result = yield call(
      networkRequest,
      influencersIdentitiesLink.method,
      influencersIdentitiesLink.href.replace('{sort_by}', sortBy),
      undefined,
      authToken,
    )

    yield put(fetchInfluencerIdentitiesSuccess(result, campaignId))
  } catch (error) {
    yield put(fetchInfluencerIdentitiesError(error))
  }
}

export function* watchFetchInfluencerIdentitiesRequest() {
  yield takeLatest(InfluencerIdentitiesActionTypes.FETCH_REQUEST, handleFetchInfluencerIdentities)
}
