import { InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { ErrorCodes } from '@enums'
import Error from './Error'

interface IProps {
  errorCode: string
}

interface IInternalProps extends IProps, InjectedIntlProps {}

const StripeError: React.FC<IInternalProps> = ({ errorCode, intl }) => (
  <Error
    showResponseErrorMessage={Boolean(errorCode)}
    errorMessage={intl.formatMessage({
      id:
        errorCode === ErrorCodes.STRIPE_INTENT_FAILURE
          ? ErrorCodes.LOCAL_STRIPE_INTENT_FAILURE
          : ErrorCodes.SUBMISSION_APPROVE_STRIPE_ERROR,
    })}
    errorCode={errorCode}
  />
)

export default compose<IInternalProps, IProps>(injectIntl)(StripeError)
