import { Fragment, RefObject } from 'react'

import { Button } from '@tribegroup/Button'
import { TextField } from '@tribegroup/TextField'
import { TagSet } from '../TagSet/TagSet'
import ErrorMessage from './ErrorMessage'
import { ButtonWrapper, InputWrapper, Tags } from './InputSet.styled'
import { IInputSetProps } from './types'

interface ITagInputs extends IInputSetProps {
  handle: RefObject<unknown>
  onChange: (event) => void
  tagsValue: string
  onKeyDown: (event) => void
  onClick: (event) => void
  tagsError: string
  submitText: string
  placeholderText: string
  handleName: string
  maxLength?: number
  customTagPattern?: {
    pattern: string
    errorMessage: string
  }
  maxTags?: number
}

const TagInput: React.FC<ITagInputs> = ({
  handle,
  onChange,
  onClick,
  onKeyDown,
  onFocus,
  onBlur,
  tagsValue,
  tagsError,
  submitText,
  onRemoveTag,
  pillBackgroundColor,
  tags,
  placeholderText,
  handleName,
  maxLength,
  customTagPattern,
  maxTags,
}) => {
  const isMaxTagsReached = maxTags !== undefined && tags && tags.length >= maxTags
  return (
    <Fragment>
      {!isMaxTagsReached && (
        <InputWrapper
          data-attr="tags"
          data-pattern={customTagPattern?.pattern ?? `^(\\#|@)[\\w\\d\\.]+$`}
          data-error={customTagPattern?.errorMessage ?? 'Please enter a valid #hashtag or @handle'}
          ref={handle}
        >
          <TextField
            placeholder={placeholderText}
            data-testid="tag-input-textbox"
            name="tags"
            height={1}
            fullWidth
            rounded
            onFocus={onFocus}
            onBlur={onBlur}
            value={tagsValue}
            data-form={handleName}
            onChange={onChange}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
          />
          <ButtonWrapper>
            <Button
              small
              scheme="primary"
              disabled={!tagsValue}
              data-form={handleName}
              onClick={onClick}
              data-testid="add-tag-button"
            >
              {submitText}
            </Button>
          </ButtonWrapper>
        </InputWrapper>
      )}
      <ErrorMessage message={tagsError} />
      <Tags data-testid="tag-list">
        <TagSet
          attr="tags"
          list={tags ?? []}
          onRemoveTag={onRemoveTag}
          pillBackgroundColor={pillBackgroundColor}
        />
      </Tags>
    </Fragment>
  )
}

export default TagInput
