import React from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'

import { HeaderBackButton } from '@components/UI/TitleBar'
import { PageRoutes, PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { objectToQueryString, queryStringtoObject } from '@utils'

export interface IInternalProps {
  campaignId: number
  search: string
}

export const getRedirectLink = (search, campaignId) => {
  const searchParams = queryStringtoObject(search)
  if (searchParams && searchParams.from) {
    const { from: searchParamsFrom, ...otherSearchParams } = searchParams
    if (Object.values(PCRChannels).includes(searchParamsFrom)) {
      return `/campaigns/${campaignId}/reports/pcr?channel=${searchParamsFrom}`
    }
    if (searchParams.filter || searchParams.sort_by) {
      return {
        pathname: decodeURIComponent(searchParamsFrom),
        search: objectToQueryString(otherSearchParams),
      }
    }
    return decodeURIComponent(searchParamsFrom)
  }

  const queryObject = { ...searchParams, from: undefined, fromPageRoute: PageRoutes.Submissions }
  return {
    pathname: `/campaigns/${campaignId}`,
    search: objectToQueryString(queryObject),
  }
}

const TitleBarDesktopBackButton: React.FC<IInternalProps> = ({ search, campaignId }) => {
  return (
    <Link to={getRedirectLink(search, campaignId)}>
      <HeaderBackButton
        glyph="nav-back-default"
        size={1.5}
        color={Theme.grey900Color}
        data-cy-element="header-back-button"
      />
    </Link>
  )
}

const mapStateToProps = (state: IApplicationState, { location, match }: RouteComponentProps) => ({
  campaignId: selectCampaignId(match),
  search: location.search,
})

export { TitleBarDesktopBackButton }
export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps),
)(TitleBarDesktopBackButton)
