import React from 'react'

import { FileRejection, useDropzone } from 'react-dropzone'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'

import { Information } from '@components/UI/Information'
import InformationContent from '@components/UI/Information/Content/Content'
import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import { DrawerFormUploadButton } from './Form.styled'

const ACCEPTED_FILE_TYPES: string[] = ['image/png', 'image/jpeg', 'application/pdf']

const MAX_FILE_SIZE_IMAGE_IN_MB = 40
const MAX_FILE_SIZE_PDF_IN_MB = 40
const toBytes = (megabytes: number) => megabytes * 1024 * 1024

type Props = {
  onFilesAccepted: (files: File) => void
  onFilesRejection: (files: FileRejection) => void
  disabled: boolean
} & InjectedIntlProps

const DrawerFormUpload: React.FC<Props> = ({
  onFilesAccepted,
  disabled,
  onFilesRejection,
  intl,
}) => {
  const { open, getRootProps, getInputProps } = useDropzone({
    accept: ACCEPTED_FILE_TYPES,
    onDropAccepted: (files) => onFilesAccepted(files[0]),
    onDropRejected: (fileRejections) => onFilesRejection(fileRejections[0]),
    validator: (file) => {
      if (file.type.includes('image') && file.size > toBytes(MAX_FILE_SIZE_IMAGE_IN_MB)) {
        const message = intl.formatMessage(
          { id: 'submission.feedback.block.errors.fileTooLarge' },
          { size: MAX_FILE_SIZE_IMAGE_IN_MB },
        )
        return {
          code: 'file-too-large',
          message,
        }
      }

      if (file.type.includes('pdf') && file.size > toBytes(MAX_FILE_SIZE_PDF_IN_MB)) {
        const message = intl.formatMessage(
          { id: 'submission.feedback.block.errors.fileTooLarge' },
          { size: MAX_FILE_SIZE_PDF_IN_MB },
        )
        return {
          code: 'file-too-large',
          message,
        }
      }

      return null
    },
    multiple: false,
  })

  return (
    <Information
      disabled={disabled}
      target={
        <DrawerFormUploadButton
          disabled={disabled}
          onClick={() => open()}
          data-testid="drawer-form-upload-button"
          {...getRootProps({ className: 'dropzone' })}
        >
          <input {...getInputProps()} />
          <Icon
            glyph="plus"
            size={1.125}
            color={disabled ? Theme.grey700Color : Theme.defaultColor}
          />
        </DrawerFormUploadButton>
      }
      width={16}
      panelBottomOffset={1.75}
      panelLeftOffset={1}
      xPosition={'right'}
      delay={0.25}
    >
      <InformationContent
        titleColor={Theme.primaryColor}
        title={<FormattedMessage id="core.text.attachments" />}
        content={<FormattedMessage id="submission.feedback.attachment.tooltip.content" />}
        contentExtraProps={{
          customFontSize: undefined,
        }}
        titleExtraProps={{
          small: undefined,
        }}
      >
        <Container topOuterSpacing={0.5}>
          <Text color={Theme.grey900Color} light italic block>
            <FormattedMessage id="submission.feedback.attachment.tooltip.footer" />
          </Text>
        </Container>
      </InformationContent>
    </Information>
  )
}

export default injectIntl(DrawerFormUpload)
