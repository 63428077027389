import { FC } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import AnalyticsDetailCard from '@components/Analytics/DetailCard'
import { isNumber } from '@components/Analytics/PrePurchase/PrePurchase'
import { Hr } from '@components/UI/Hr/Hr.styled'
import { AnalyticsCustomPrice } from '@components/UI/Price/Price'
import { CPM_EXPLAINED, TRIBE_PRICING_PAGE_POST_PURCHASE_METRICS_UTM } from '@constants'
import { IApplicationState } from '@store'
import { selectHasAnalytics, selectSubmissionPerformance } from '@store/performance'
import { selectSubmissionId } from '@store/router'
import { selectSubmission, selectSubTotalCurrency } from '@store/submissions'
import Theme from '@theme'
import { Link } from '@tribegroup'
import { abbreviateNumberOrDash } from '@utils/abbreviateNumber'
import { AnalyticsPostPerformanceGrid } from '../PostPerformance.styled'
import AnalyticsPostPerformanceUnavailable from '../Unavailable/Unavailable'

const externalLink = TRIBE_PRICING_PAGE_POST_PURCHASE_METRICS_UTM

export const AnalyticsPostPerformanceTikTok: FC<InjectedIntlProps> = ({ intl }) => {
  const match = useRouteMatch()

  const { performance, hasAnalytics, followers, currency } = useSelector(
    (state: IApplicationState) => {
      const submissionId = selectSubmissionId(match) as number

      const { identity_followers_count } = selectSubmission(state, submissionId)

      return {
        followers: identity_followers_count,
        hasAnalytics: selectHasAnalytics(state, submissionId),
        currency: selectSubTotalCurrency(state, submissionId),
        performance: selectSubmissionPerformance(state, submissionId),
      }
    },
  )

  if (!hasAnalytics) {
    return <AnalyticsPostPerformanceUnavailable />
  }

  const cpe = performance.cpe
  const cpv = performance.cpv
  const cpm = performance.cpm
  const engagementPct = performance.engagement_pct
  const videoViewsPct = performance.video_views_pct

  return (
    <>
      <AnalyticsPostPerformanceGrid>
        <AnalyticsDetailCard
          title="submission.postPerformance.tiktok.engagement"
          content={abbreviateNumberOrDash(performance?.engagement)}
          elementName="post-performance-engagement"
          productMetricGroup="post_purchase"
          productMetricValue="engagement"
          featured
        />
        {cpe && (
          <AnalyticsDetailCard
            title="submission.postPerformance.tiktok.cpe"
            values={{
              taxDisplay: intl.formatMessage({
                id: `core.currency.taxDisplay.${currency ?? 'generic'}`,
              }),
            }}
            content={isNumber(cpe) && <AnalyticsCustomPrice currency={currency} quantity={cpe} />}
            elementName="post-performance-cpe"
            productMetricGroup="post_purchase"
            productMetricValue="cpe"
            featured
          />
        )}
        <AnalyticsDetailCard
          title="submission.postPerformance.tiktok.views"
          content={abbreviateNumberOrDash(performance?.video_views)}
          elementName="post-performance-views"
          productMetricGroup="post_purchase"
          productMetricValue="views"
          featured
          externalLink={externalLink}
        />
      </AnalyticsPostPerformanceGrid>
      <Hr backgroundColor={Theme.grey400Color} />
      <AnalyticsPostPerformanceGrid topOuterSpacing={1.75}>
        <AnalyticsDetailCard
          title="submission.postPerformance.tiktok.followers"
          content={abbreviateNumberOrDash(followers)}
          elementName="post-performance-followers"
          productMetricGroup="post_purchase"
          productMetricValue="followers"
          externalLink={externalLink}
        />
        <AnalyticsDetailCard
          title="submission.postPerformance.tiktok.likes"
          content={abbreviateNumberOrDash(performance?.likes)}
          elementName="post-performance-likes"
          productMetricGroup="post_purchase"
          productMetricValue="likes"
          externalLink={externalLink}
        />
        <AnalyticsDetailCard
          title="submission.postPerformance.tiktok.comments"
          content={abbreviateNumberOrDash(performance?.comments)}
          elementName="post-performance-comments"
          productMetricGroup="post_purchase"
          productMetricValue="comments"
          externalLink={externalLink}
        />
        <AnalyticsDetailCard
          title="submission.postPerformance.tiktok.shares"
          content={abbreviateNumberOrDash(performance?.shares)}
          elementName="post-performance-shares"
          productMetricGroup="post_purchase"
          productMetricValue="shares"
          externalLink={externalLink}
        />
        <AnalyticsDetailCard
          title="submission.postPerformance.tiktok.engagementPct"
          content={
            isNumber(engagementPct) && (
              <FormattedMessage id="core.text.percent" values={{ value: engagementPct }} />
            )
          }
          elementName="post-performance-engagement-pct"
          productMetricGroup="post_purchase"
          productMetricValue="engagement_pct"
          externalLink={externalLink}
        />
        <AnalyticsDetailCard
          title="submission.postPerformance.tiktok.viewPct"
          content={
            isNumber(videoViewsPct) && (
              <FormattedMessage id="core.text.percent" values={{ value: videoViewsPct }} />
            )
          }
          elementName="post-performance-views-pct"
          productMetricGroup="post_purchase"
          productMetricValue="views_pct"
          externalLink={externalLink}
        />

        {cpv && (
          <AnalyticsDetailCard
            title="submission.postPerformance.tiktok.cpv"
            content={isNumber(cpv) && <AnalyticsCustomPrice currency={currency} quantity={cpv!} />}
            elementName="post-performance-cpv"
            productMetricGroup="post_purchase"
            productMetricValue="cpv"
            externalLink={externalLink}
          />
        )}

        {cpm && (
          <AnalyticsDetailCard
            title="submission.postPerformance.tiktok.cpm"
            content={isNumber(cpm) && <AnalyticsCustomPrice currency={currency} quantity={cpm} />}
            elementName="post-performance-cpm"
            productMetricGroup="post_purchase"
            productMetricValue="cpm"
            externalLink={externalLink}
            values={{
              link: (
                <Link href={CPM_EXPLAINED} target="_blank" color={Theme.primaryColor} lowercase>
                  <FormattedMessage id="core.text.clickHere" />
                </Link>
              ),
            }}
          />
        )}
      </AnalyticsPostPerformanceGrid>
    </>
  )
}

export default injectIntl(AnalyticsPostPerformanceTikTok)
