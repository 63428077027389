import { PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export interface IBreakpointProps {
  mobile?: boolean
  desktop?: boolean
  tabletPortrait?: boolean
  tabletLandscape?: boolean
}

export type BreakpointsPropType = React.ComponentProps<typeof Breakpoint>

const Breakpoint: React.FC<PropsWithChildren<IBreakpointProps>> = styled.div`
  display: none;
  ${({ mobile, desktop, tabletPortrait, tabletLandscape }: IBreakpointProps) =>
    css`
      ${mobile &&
      css`
        display: block;
        ${breakpoint('phoneUpperBoundary')`
            display: none;
          `};
      `};
      ${tabletPortrait &&
      css`
        ${breakpoint('phoneUpperBoundary')`
            display: block;
          `};
        ${breakpoint('tabletPortrait')`
            display: none;
          `}
      `}
      ${tabletLandscape &&
      css`
        ${breakpoint('tabletPortrait')`
            display: block;
          `};
        ${breakpoint('tabletLandscape')`
            display: none;
          `}
      `}
      ${desktop &&
      css`
        ${breakpoint('tabletLandscape')`
            display: block;
          `};
      `};
    `}
`

export default Breakpoint
