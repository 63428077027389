import { useEffect } from 'react'

import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import Loading from '@components/UI/Loading'
import Price from '@components/UI/Price/Price'
import { FULL_DATE } from '@constants'
import { IApplicationState } from '@store'
import {
  fetchPurchaseOrders,
  PurchaseOrderStatus,
  selectHasPurchaseOrders,
  selectIsFetching,
  selectPurchaseOrders,
} from '@store/purchaseOrders'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

export const AvailableCreditPurchaseOrderList = () => {
  const { purchaseOrders, hasPurchaseOrders, isFetching } = useSelector(
    (state: IApplicationState) => {
      return {
        purchaseOrders: selectPurchaseOrders(state),
        hasPurchaseOrders: selectHasPurchaseOrders(state),
        isFetching: selectIsFetching(state),
      }
    },
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPurchaseOrders())
  }, [])

  if (isFetching) {
    return (
      <Container bottomOuterSpacing={5.75}>
        <Loading iconOnly />
      </Container>
    )
  }

  return (
    <Container maxHeight={15} overflow="auto">
      {!hasPurchaseOrders && (
        <Container flexBetween alignItems="flex-start" bottomOuterSpacing={5.75}>
          <Container rightOuterSpacing={1} width={12}></Container>
          <Container fullWidth textAlign="left">
            <Text block color={Theme.grey700Color}>
              <FormattedMessage id="contentLibrary.licensing.creditManagement.availableCredit.empty" />
            </Text>
          </Container>
          <Container minWidth={6} textAlign="right"></Container>
        </Container>
      )}

      {purchaseOrders.map((purchaseOrder) => (
        <Container
          flexBetween
          alignItems="flex-start"
          bottomOuterSpacing={0.5}
          key={`${purchaseOrder.id}-po-available-credit`}
        >
          <Container rightOuterSpacing={1} width={12}>
            <Text color={Theme.grey900Color}>
              {format(new Date(purchaseOrder.expiry_date), FULL_DATE)}
            </Text>
          </Container>
          <Container fullWidth textAlign="left">
            <Text block color={Theme.grey900Color}>
              {purchaseOrder.customer_order_number}{' '}
              {purchaseOrder.status === PurchaseOrderStatus.EXPIRED && (
                <FormattedMessage id="contentLibrary.licensing.creditManagement.purchaseOrder.expired" />
              )}
            </Text>
          </Container>
          <Container minWidth={6} textAlign="right">
            <Text color={Theme.grey900Color}>
              <Price
                quantity={purchaseOrder.amount_remaining_cents}
                currency={purchaseOrder.amount_remaining_currency}
              />
            </Text>
          </Container>
        </Container>
      ))}
    </Container>
  )
}
