import styled, { css } from 'styled-components'

import Theme from '@theme'

export const ShadowOverlay = (show, direction) => css`
  position: absolute;
  content: ' ';
  top: 1.875rem;
  width: 0.75rem;
  height: calc(100% - 0.5rem);
  border-radius: 0.25rem;
  z-index: 2;
  transition: all 0.2s ease;
  background: linear-gradient(${direction}, rgb(0 0 0 / 7%), rgb(255 255 255 / 0%));
  opacity: ${show ? '1' : '0'};
`

export const Wrapper = styled.div`
  position: relative;
  width: 80%;
  overscroll-behavior: contain;
  flex: 4 0 15.5rem;

  ${({ showLeftShadow }) =>
    css`
      &::before {
        ${ShadowOverlay(showLeftShadow, 'to right')}
        left: 0;
      }
    `}
  ${({ showRightShadow }) =>
    css`
      &::after {
        ${ShadowOverlay(showRightShadow, 'to left')}
        right: 0;
      }
    `}
  &:first-of-type {
    flex: 1 0 15.5rem;
    margin-right: 1rem;
  }
`

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 1.5rem;
  padding-top: 3.375rem;
  background-color: ${Theme.whiteColor};
  z-index: 1;
`

export const ScrollWrapper = styled.div`
  display: flex;
  overflow: auto;
  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}
  ${({ overflowX }) =>
    overflowX &&
    css`
      overflow-x: ${overflowX};
    `}
  ${({ overflowY }) =>
    overflowY &&
    css`
      overflow-y: ${overflowY};
    `}
`

export const RowWrapper = styled.div`
  flex: 1 0 6.25rem;
  scroll-snap-align: start;
  height: 4.75rem;
  border: solid transparent;
  border-width: 0 1px;

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
  &:first-of-type {
    border-left-width: 0;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    flex-basis: 8rem;
  }

  &:last-of-type {
    border-right-width: 0;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`

export const ColWrapper = styled.div`
  flex: 1 0 6.25rem;
  scroll-snap-align: start;
  border: solid ${Theme.whiteColor};
  border-width: 0 1px;
  background: ${Theme.grey200Color};
  border-radius: 0.25rem;

  &:first-of-type {
    flex-basis: 8rem;
    border-left-width: 0;
  }

  &:last-of-type {
    border-right-width: 0;
  }
`

export const Header = styled.div`
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
`

export const Entry = styled.div`
  padding: 1.75rem 0.5rem;
  text-align: center;
`
