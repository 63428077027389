import { FormattedMessage } from 'react-intl'

import { Checkbox, Container, Text } from '@tribegroup'

export interface IProps {
  isDisabled: boolean
  onCheckboxChange: (event: React.SyntheticEvent) => void
  isChecked: boolean
}

const BuilderBriefChannelsTikTokNoTikTokCheckBox: React.FC<IProps> = ({
  onCheckboxChange,
  isChecked,
  isDisabled,
}) => {
  return (
    <Container topOuterSpacing={1} data-testid="no-tiktok-checkbox-container">
      <Checkbox
        name="has_tiktok_ad_manager_account"
        scheme="primary"
        disabled={isDisabled}
        label={
          <Text small>
            <FormattedMessage id="builder.brief.socialMedia.dontHaveTikTok" />
          </Text>
        }
        onClick={onCheckboxChange}
        checked={isChecked}
      />
    </Container>
  )
}

export default BuilderBriefChannelsTikTokNoTikTokCheckBox
