import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Flex from 'styled-flex-component'

import { ConnectedAccountsDrawer } from '@components/ConnectedAccounts/Drawer/Drawer'
import Breakpoint from '@components/UI/Breakpoint'
import StopPropagation from '@components/UI/StopPropagation/StopPropagation'
import { BRANDS_TIKTOK_CONNECT_EDUCATION } from '@constants'
import { PageRoutes } from '@enums'
import { useDrawer } from '@hooks/useDrawer'
import { useInboxPrompt } from '@hooks/useInboxPrompt'
import { selectBrandManagerIdentitiesIsFetched } from '@store/brandManagerIdentities'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { Button, CloseIcon, Container, Header, Icon, Link, Text } from '@tribegroup'
import queryStringtoObject from '@utils/url'

const ToggleOnTikTokAdsManagerAccountPrompt = () => {
  const { isBrandManagerIdentitiesFetched } = useSelector((state: IApplicationState) => {
    return {
      isBrandManagerIdentitiesFetched: selectBrandManagerIdentitiesIsFetched(state),
    }
  })

  const { showDrawer, isOpen } = useDrawer()
  const { location } = useHistory()

  const { isPromptOpen, hidePrompt } = useInboxPrompt()

  const searchParams = queryStringtoObject(location.search)

  const isFromSubmissionDetailView = searchParams?.fromPageRoute === PageRoutes.Submissions

  if (!isBrandManagerIdentitiesFetched || !isPromptOpen || isOpen || isFromSubmissionDetailView) {
    return null
  }

  const onCloseClick = () => {
    hidePrompt()
  }

  const onShowConnectedAccountDrawer = () => {
    showDrawer(
      <ConnectedAccountsDrawer />,
      {
        desktopXOffset: 13.875,
        zIndex: 2,
        anchor: 'left',
      },
      undefined,
      ['desktop', 'tabletLandscape', 'tabletPortrait'],
    )
  }

  return (
    <Breakpoint tabletLandscape tabletPortrait desktop>
      <StopPropagation>
        <Container
          cursorDefault
          width={31.25}
          backgroundColor={Theme.whiteColor}
          borderRadius={0.5}
          innerSpacing={1.25}
          fixed
          bottomPosition={1.75}
          leftPosition={15.25}
          zIndex={1}
          boxShadow="0 2px 20px 0 rgba(77, 77, 77, 0.15)"
          data-testid="toggle-on-tiktok-ads-manager-account-prompt"
        >
          <CloseIcon onClick={onCloseClick} size={1} right={1} top={1} />
          <Text medium color={Theme.grey900Color} block>
            <FormattedMessage id="connectedAccounts.toggle.education.tiktok.header" />
          </Text>
          <Text color={Theme.grey900Color} block>
            <FormattedMessage id="connectedAccounts.toggle.education.tiktok.body" />
          </Text>

          <Container topOuterSpacing={1}>
            <Flex justifyBetween alignCenter>
              <Flex center>
                <Icon glyph="nav-back-default" color={Theme.primaryColor} size={1.25} />
                <Button
                  scheme="primary"
                  fullWidth
                  outlined
                  small
                  leftOuterSpacing={0.75}
                  onClick={onShowConnectedAccountDrawer}
                >
                  <Header color={Theme.primaryColor} uppercase cta small>
                    <FormattedMessage id="connectedAccounts.toggle.education.tiktok.openSideDraw" />
                  </Header>
                </Button>
              </Flex>
              <Link href={BRANDS_TIKTOK_CONNECT_EDUCATION} target="_blank">
                <Text color={Theme.primaryColor}>
                  <FormattedMessage id="core.text.learnWhy.withArrow" />
                </Text>
              </Link>
            </Flex>
          </Container>
        </Container>
      </StopPropagation>
    </Breakpoint>
  )
}

export default ToggleOnTikTokAdsManagerAccountPrompt
