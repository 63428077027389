import H from 'history'
import { combineReducers } from 'redux'

import { metricDisputeReducer } from '@store/metricDisputes/reducers'
import { MetricDisputeState } from '@store/metricDisputes/types'
import { influencerIdentitiesReducer } from './influencerIdentities/reducers'
import { IRouterState, routerReducer } from './router/reducer'
import { audienceInsightsReducer, IIAudienceInsightsState } from './audienceInsights'
import { audienceReportingReducer, IAudienceReportingState } from './audienceReporting'
import { authReducer, IAuthState } from './auth'
import { billingsReducer, IBillingState } from './billings'
import { brandFansReducer, IBrandFansState } from './brandFans'
import {
  brandManagerIdentitiesReducer,
  IBrandManagerIdentitiesState,
} from './brandManagerIdentities'
import { brandsReducer, IBrandsState } from './brands'
import { ApprovalType, campaignsReducer, ICampaignsState } from './campaigns'
import { categoriesReducer, ICategoriesState } from './categories'
import { completeSignUpReducer, ICompleteSignupState } from './completeSignup'
import { creatorBioReducer, ICreatorBioState } from './creator-bio'
import { draftsReducer, IDraftsState } from './drafts'
import { facebookAdsReducer, IFacebookAdsState } from './facebookAds'
import { feedbackReducer, IFeedbackState } from './feedback'
import { feedbackDraftReducer, IFeedbackDraftState } from './feedbackDraft'
import { IInfluencerIdentityState } from './influencerIdentities'
import { ILicenseState, licensesReducer } from './licenses'
import { IMeState, meReducer } from './me'
import { IMediaState, mediaReducer } from './media'
import { IMediaObjectsState, mediaObjectsReducer } from './mediaObjects'
import { IMembersState, membersReducer } from './members'
import { IMoodboardMediaState, moodboardReducer } from './moodboard'
import { INoteState, notesReducer } from './notes'
import { IPasswordState, passwordReducer } from './password'
import { IPaymentState, paymentsReducer } from './payments'
import { ISubmissionPerformanceState, submissionPerformanceReducer } from './performance'
import { IPostCampaignReportState, postCampaignReducer } from './postCampaignReport'
import { IPrePurchaseAnalyticsState, prePurchaseAnalyticsReducer } from './prePurchaseAnalytics'
import { IPublishedSummaryState, publishedSummaryReducer } from './publishedSummary'
import { IPurchaseOrderState, purchaseOrderReducer } from './purchaseOrders'
import { IRootResourceState, rootResourceReducer } from './rootResource'
import { IShortlistSummaryState, shortlistSummaryReducer } from './shortlistSummary'
import { ISubmissionsState, submissionsReducer } from './submissions'
import { IUIState, uiReducer } from './ui'

export interface IHateoasLink {
  href: string
  method: string
  rel: string
  template?: any
  filter?: ReadonlyArray<string>
  sort_by?: ReadonlyArray<string>
  feature?: string
}

export interface IFeatureHateoasLink extends IHateoasLink {
  feature: string
}

export interface IFetchMetaData {
  stats: any
}

export interface IListResponse {
  metadata: IFetchMetaData
  items: ReadonlyArray<any>
  links: ReadonlyArray<IHateoasLink>
}

export interface IMeta {
  filter?: string
  sortBy?: string
  refreshSubmissions?: boolean
  refreshPurchaseOrders?: boolean
  event?: any
  history?: H.History
  toast?: any
  redirect?: string
  resetList?: boolean
  declinedFilter?: string
  signIn?: boolean
  purchaseType?: ApprovalType
}

export interface IApplicationState {
  auth: IAuthState
  audienceInsights: IIAudienceInsightsState
  audienceReporting: IAudienceReportingState
  brands: IBrandsState
  brandFans: IBrandFansState
  brandManagerIdentities: IBrandManagerIdentitiesState
  campaigns: ICampaignsState
  categories: ICategoriesState
  drafts: IDraftsState
  facebookAds: IFacebookAdsState
  me: IMeState
  media: IMediaState
  mediaObjects: IMediaObjectsState
  password: IPasswordState
  rootResource: IRootResourceState
  router: IRouterState
  submissions: ISubmissionsState
  members: IMembersState
  feedback: IFeedbackState
  notes: INoteState
  feedbackDraft: IFeedbackDraftState
  licenses: ILicenseState
  billings: IBillingState
  payments: IPaymentState
  influencerIdentities: IInfluencerIdentityState
  reportings: IPostCampaignReportState
  performance: ISubmissionPerformanceState
  ui: IUIState
  prePurchaseAnalytics: IPrePurchaseAnalyticsState
  shortlistSummary: IShortlistSummaryState
  creatorBio: ICreatorBioState
  publishedSummary: IPublishedSummaryState
  moodboard: IMoodboardMediaState
  purchaseOrders: IPurchaseOrderState
  completeSignUp: ICompleteSignupState
  metricDisputes: MetricDisputeState
}

export default () =>
  combineReducers<IApplicationState>({
    auth: authReducer,
    audienceInsights: audienceInsightsReducer,
    audienceReporting: audienceReportingReducer,
    brands: brandsReducer,
    brandFans: brandFansReducer,
    brandManagerIdentities: brandManagerIdentitiesReducer,
    campaigns: campaignsReducer,
    categories: categoriesReducer,
    drafts: draftsReducer,
    me: meReducer,
    media: mediaReducer,
    mediaObjects: mediaObjectsReducer,
    facebookAds: facebookAdsReducer,
    password: passwordReducer,
    rootResource: rootResourceReducer,
    router: routerReducer,
    submissions: submissionsReducer,
    members: membersReducer,
    feedback: feedbackReducer,
    notes: notesReducer,
    feedbackDraft: feedbackDraftReducer,
    licenses: licensesReducer,
    billings: billingsReducer,
    payments: paymentsReducer,
    influencerIdentities: influencerIdentitiesReducer,
    reportings: postCampaignReducer,
    performance: submissionPerformanceReducer,
    ui: uiReducer,
    prePurchaseAnalytics: prePurchaseAnalyticsReducer,
    shortlistSummary: shortlistSummaryReducer,
    creatorBio: creatorBioReducer,
    publishedSummary: publishedSummaryReducer,
    moodboard: moodboardReducer,
    purchaseOrders: purchaseOrderReducer,
    completeSignUp: completeSignUpReducer,
    metricDisputes: metricDisputeReducer,
  })
