import React from 'react'

import { TitleBarLogo } from '@components/UI/TitleBar/TitleBar'
import { INFLUENTIAL_CO, TRIBE_BRANDS } from '@constants'
import { Container, Link } from '@tribegroup'

const ShareableContentLibraryTitleBarLogo: React.FC = () => (
  <Link
    href={process.env.APP_NAME === 'influential' ? INFLUENTIAL_CO : TRIBE_BRANDS}
    target="_blank"
    data-testid="title-bar-logo-container"
  >
    <Container height={1.75} width={1.75} leftInnerSpacing={1.5}>
      <TitleBarLogo />
    </Container>
  </Link>
)

export { ShareableContentLibraryTitleBarLogo }
export default ShareableContentLibraryTitleBarLogo
