import { PureComponent } from 'react'

import ButterToast, { POS_BOTTOM, POS_CENTER } from 'butter-toast'

import { ToastContainerStyled } from './Toast.styled'

export interface IToastContainer {
  /**
   * Width of sidebar when above phoneUpperBoundary
   */
  sidebarModifier?: number
}

export default class ToastContainer extends PureComponent<IToastContainer> {
  render() {
    return (
      <ToastContainerStyled sidebarModifier={this.props.sidebarModifier}>
        <ButterToast
          renderInContext
          timeout={3000}
          position={{ vertical: POS_BOTTOM, horizontal: POS_CENTER }}
        />
      </ToastContainerStyled>
    )
  }
}
