import React, { PropsWithChildren } from 'react'

import styled from 'styled-components'

import { defaultTheme } from '../Theme'
import { Text } from '../Typography'

export const Root = styled.span`
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border: solid 1px ${defaultTheme.grey400Color};
  cursor: pointer;
  outline: 0;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  ${({ active, activeBackgroundColor = defaultTheme.primary200Color }) =>
    active &&
    `
    border-color: transparent;
    background-color: ${activeBackgroundColor};
  `};
  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    border-color: transparent;
    background-color: ${defaultTheme.grey200Color};
  `};
  ${({ outlined }) =>
    outlined &&
    `
    cursor: default;
    border-color: ${defaultTheme.grey400Color};
    background-color: ${defaultTheme.whiteColor};
  `};
`

interface IProps {
  active: boolean
  outlined?: boolean
  disabled?: boolean
  activeBackgroundColor?: string
  onClick?: VoidFunction
}

const CheckboxButtonComboBase: React.FC<PropsWithChildren<IProps>> = ({
  active,
  disabled,
  outlined,
  children,
  onClick,
  activeBackgroundColor,
}) => {
  const inactiveFontColor = outlined ? defaultTheme.grey600Color : defaultTheme.grey800Color
  const textFontColor = active ? defaultTheme.grey900Color : inactiveFontColor
  return (
    <Root
      onClick={onClick}
      active={active}
      disabled={disabled}
      outlined={outlined}
      aria-disabled={disabled}
      role="checkbox"
      aria-checked={active}
      tabIndex="0"
      checked={active}
      activeBackgroundColor={activeBackgroundColor}
    >
      <Text color={textFontColor}>{children}</Text>
    </Root>
  )
}

export default CheckboxButtonComboBase
