import { createSelector } from '@reduxjs/toolkit'

import { IApplicationState } from '@store'
import { ICreatorBio, ICreatorBioLink } from './types'
import { linkToIdentifier } from './util'

const creatorBio = (state: IApplicationState) => state.creatorBio

export const selectIsFetching = createSelector(creatorBio, (state) => state.isFetching)

export const selectCreatorBioLinkMap = createSelector(creatorBio, (bio) => {
  return bio.creatorBioByLink
})

export const selectCreatorBioByLink = createSelector(
  [selectCreatorBioLinkMap, (_, link: ICreatorBioLink) => link],
  (bioLinkMap, link) => {
    return bioLinkMap[linkToIdentifier({ ...link, href: decodeURIComponent(link.href) })]
  },
)

export const selectPortfolioByCreatorLink = createSelector(
  selectCreatorBioByLink,
  (creator: ICreatorBio) =>
    [...(creator?.portfolio_items ?? [])].sort((itemA, itemB) => itemA.order - itemB.order),
)

export const selectCreatorBioError = createSelector(creatorBio, (state) => state.errors)
