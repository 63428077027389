import { PaymentMethods } from '@enums'
import { ILicenseOption } from '@store/campaigns'
import { IPurchaseOrder } from '@store/purchaseOrders'
import { CreditManagementPaymentMethodCreditCard } from './CreditCard'
import { CreditManagementPaymentMethodPurchaseOrder } from './PurchaseOrder'

interface IProps {
  paymentMethod: PaymentMethods
  licenseOption: ILicenseOption
  onSelectPurchaseOrders: (purchaseOrderIds: IPurchaseOrder[]) => void
}

export const CreditManagementPaymentMethodWrapper = ({
  paymentMethod,
  licenseOption,
  onSelectPurchaseOrders,
}: IProps) => {
  if (paymentMethod === PaymentMethods.CREDIT_CARD) {
    return <CreditManagementPaymentMethodCreditCard />
  }

  if (paymentMethod === PaymentMethods.PURCHASE_ORDER) {
    return (
      <CreditManagementPaymentMethodPurchaseOrder
        licenseOption={licenseOption}
        onSelectPurchaseOrders={onSelectPurchaseOrders}
      />
    )
  }

  return <></>
}
