import { IMedia, MediaUploadActionTypes } from '@store/media'

export const upload = (media: Blob, uploadId?: string) => ({
  payload: { media, uploadId },
  type: MediaUploadActionTypes.UPLOAD_REQUEST,
})

export const uploadSuccess = (media: IMedia, uploadId: string) => ({
  payload: { media, uploadId },
  type: MediaUploadActionTypes.UPLOAD_SUCCESS,
})

export const uploadError = (error: any, uploadId: string) => ({
  payload: { error, uploadId },
  type: MediaUploadActionTypes.UPLOAD_ERROR,
})

export const clearUpload = () => ({
  type: MediaUploadActionTypes.UPLOAD_CLEAR,
})
