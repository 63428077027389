import React from 'react'

import H from 'history'

import LabelItem from './Item'
import InfluencerIdentityLabelsTalentAgencyCreatorTag from './TalentAgencyCreator'
import {
  InfluencerIdentityLabelsInsights,
  InfluencerIdentityLabelsNew,
  InfluencerIdentityLabelsPreviouslyPurchased,
  InfluencerIdentityLabelsViewBio,
} from '.'

interface IProps {
  spacing: number
  showPreviouslyPurchased: boolean
  showNew: boolean
  showAnalytics?: boolean
  brandName: string
  smallInsightIcon?: boolean
  newLink?: H.LocationDescriptor
  showTalentAgencyCreatorTag?: boolean
}

const InboxInfluencerIdentityLabels: React.FC<IProps> = ({
  brandName,
  showAnalytics,
  showNew,
  showPreviouslyPurchased,
  smallInsightIcon,
  newLink,
  spacing = 0.9375,
  showTalentAgencyCreatorTag,
}) => {
  return (
    <React.Fragment>
      {showPreviouslyPurchased && (
        <LabelItem spacing={spacing}>
          <InfluencerIdentityLabelsPreviouslyPurchased brandName={brandName} />
        </LabelItem>
      )}
      {showNew && (
        <LabelItem spacing={spacing}>
          <InfluencerIdentityLabelsNew linkTo={newLink} />
        </LabelItem>
      )}
      {showAnalytics && (
        <LabelItem spacing={spacing}>
          <InfluencerIdentityLabelsInsights small={smallInsightIcon} />
        </LabelItem>
      )}
      <InfluencerIdentityLabelsViewBio />
      {showTalentAgencyCreatorTag && <InfluencerIdentityLabelsTalentAgencyCreatorTag />}
    </React.Fragment>
  )
}

export { InboxInfluencerIdentityLabels }
export default InboxInfluencerIdentityLabels
