import { WatcherFunction } from '..'
import { watchApproveRequest } from './approve'
import { watchBulkDeclineRequest } from './bulkDecline'
import { watchDeclineRequest } from './decline'
import { watchFetchBrandFansMembership } from './fetchBrandFansMembership'
import { watchFetchInfluencerSubmissionsRequest } from './influencerSubmissions'
import { watchPreApproveRequest } from './preApprove'
import { watchPromoteAsAdRequest } from './promoteAsAd'
import { watchRescheduleRequest } from './reschedule'
import { watchShortlistRequest } from './shortlist'
import { watchSubmissionRequest } from './submission'
import { watchSubmissionsRequest } from './submissions'
import { watchSubmissionsByInfluencerIdentityRequest } from './submissionsByInfluencerIdentity'
import { watchUnshortlistnRequest } from './unshortlist'
import { watchViewSocialRequests } from './viewSocial'

export const submissionsSagas: ReadonlyArray<WatcherFunction> = [
  watchApproveRequest,
  watchPreApproveRequest,
  watchDeclineRequest,
  watchRescheduleRequest,
  watchSubmissionRequest,
  watchSubmissionsRequest,
  watchSubmissionsByInfluencerIdentityRequest,
  watchShortlistRequest,
  watchUnshortlistnRequest,
  watchBulkDeclineRequest,
  watchFetchInfluencerSubmissionsRequest,
  watchViewSocialRequests,
  watchPromoteAsAdRequest,
  watchFetchBrandFansMembership,
]
