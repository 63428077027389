import {
  IAnalyticsSubmission,
  IFeatureLockedMetricsSummary,
  IInsights,
} from '@store/postCampaignReport'

export enum VideoMetricsActionTypes {
  FETCH_REQUEST = 'pcr/videoMetrics/FETCH_REQUEST',
  FETCH_SUCCESS = 'pcr/videoMetrics/FETCH_SUCCESS',
  FETCH_ERROR = 'pcr/videoMetrics/FETCH_ERROR',
}

export interface IVideoMetricsState {
  isFetching: boolean
  errors?: object | string
  videoMetricsById: Record<number, IVideoMetrics>
  videoMetricsSummary: Record<number, Record<string, IVideoMetricsSummary>>
  videoMetricIdsByChannel: Record<number, Record<string, ReadonlyArray<number>>>
}

export interface IVideoMetrics extends IAnalyticsSubmission, IInsights {
  purchase_amount: number
  purchase_amount_currency: string
  views: number
  view_rate: number
  cost_per_view: number
  cost_per_view_currency: string
  engagement: number
  engagement_rate: number
  cost_per_engagement: number
  cost_per_engagement_currency: string
}

export interface IVideoMetricsSummary extends IFeatureLockedMetricsSummary {
  count: number
  total_purchase_amount: number
  total_purchase_amount_currency: string
  total_followers: number
  total_video_views: number
  total_engagement: number
  total_engagement_pct: number
  avg_engagement_rate: number
  avg_video_view_rate: number
  total_cost_per_view: number
  total_cost_per_view_currency: string
}

export interface IVideoMetricsResponse {
  items: ReadonlyArray<IVideoMetrics>
  metadata: IVideoMetricsSummary
}
