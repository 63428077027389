import { FetchFacebookAdAccountsActionTypes, IFacebookAdAccount } from '@store/facebookAds'

export const fetchAdAccountsRequest = () => ({
  type: FetchFacebookAdAccountsActionTypes.FETCH_REQUEST,
})

export const fetchAdAccountsSuccess = (adAccounts: ReadonlyArray<IFacebookAdAccount>) => ({
  payload: adAccounts,
  type: FetchFacebookAdAccountsActionTypes.FETCH_SUCCESS,
})

export const fetchAdAccountsError = (error: any) => ({
  payload: error,
  type: FetchFacebookAdAccountsActionTypes.FETCH_ERROR,
})
