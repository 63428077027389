import {
  IAnalyticsSubmission,
  IFeatureLockedMetricsSummary,
  IInsights,
} from '@store/postCampaignReport'

export enum PostMetricsActionTypes {
  FETCH_REQUEST = 'pcr/postMetrics/FETCH_REQUEST',
  FETCH_SUCCESS = 'pcr/postMetrics/FETCH_SUCCESS',
  FETCH_ERROR = 'pcr/postMetrics/FETCH_ERROR',
}

export interface IPostMetricsState {
  isFetching: boolean
  errors?: object | string
  postMetricsById: Record<number, IPostMetrics>
  postMetricsSummary: Record<number, Record<string, IPostMetricsSummary>>
  postMetricIdsByChannel: Record<number, Record<string, ReadonlyArray<number>>>
}

export interface IPostMetrics extends IAnalyticsSubmission, IInsights {
  purchase_amount: number
  purchase_amount_currency: string
  likes: number
  comments: number
  shares: number
  engagement: number
  engagement_rate: number
  cost_per_engagement: number
  cost_per_engagement_currency: string
}

export interface IFeatureLockedValue<T> {
  feature: string
  value: T | null
}

export interface IPostMetricsSummary extends IFeatureLockedMetricsSummary {
  count: number
  total_purchase_amount: number
  total_purchase_amount_currency: string
  total_followers: number
  total_engagement: number
  total_engagement_pct: number
  total_likes: number
  total_comments: number
  total_shares: number
  avg_engagement_rate: number
  total_cost_per_engagement: number
  total_cost_per_engagement_currency: string
}

export interface IPostMetricsResponse {
  items: ReadonlyArray<IPostMetrics>
  metadata: IPostMetricsSummary
}
