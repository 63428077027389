import { FlexItem } from 'styled-flex-component'

import Theme from '@theme'
import { H5 } from '@tribegroup'
import { PlaceholderAvatarStyled } from './PlaceholderAvatar.styled'

type Props = {
  size?: number
  children: React.ReactNode
}

const PlaceholderAvatar: React.FC<Props> = ({ children, size }) => (
  <PlaceholderAvatarStyled alignCenter justifyCenter size={size}>
    <FlexItem>
      <H5 color={Theme.grey900Color}>{children}</H5>
    </FlexItem>
  </PlaceholderAvatarStyled>
)

export default PlaceholderAvatar
