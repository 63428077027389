import React, { PropsWithChildren } from 'react'

import { Container } from '@tribegroup'
import { MobileMenuControlsContext } from '../Provider'

interface IProps {
  fullWidth?: boolean
}

const HideMobileMenu: React.FC<PropsWithChildren<IProps>> = ({ fullWidth, children }) => {
  const { hideMobileMenu } = React.useContext(MobileMenuControlsContext)
  return (
    <Container
      topInnerSpacing={0.375}
      bottomInnerSpacing={0.375}
      leftInnerSpacing={0.625}
      rightInnerSpacing={0.625}
      fullWidth={fullWidth}
      onClick={hideMobileMenu}
      role="button"
    >
      {children}
    </Container>
  )
}

export { HideMobileMenu }
export default HideMobileMenu
