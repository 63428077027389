import React from 'react'

import { FormattedMessage } from 'react-intl'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { BRAND_REFERRAL_PROGRAM_LEARN_MORE } from '@constants'
import { EventTrackingNames } from '@enums'
import Theme from '@theme'
import { Link, Text } from '@tribegroup'

interface IProps {
  campaignId: number
}

const InboxReferralLearnMore: React.FC<IProps> = ({ campaignId }) => (
  <ConnectedTrackedRoute
    eventName={EventTrackingNames.BrandReferralLearnMore}
    campaignId={campaignId}
  >
    <Link
      data-testid="referral-learn-more"
      href={BRAND_REFERRAL_PROGRAM_LEARN_MORE}
      target="_blank"
    >
      <Text color={Theme.primaryColor}>
        <FormattedMessage id="core.text.learnMore.withArrow" />
      </Text>
    </Link>
  </ConnectedTrackedRoute>
)

export default InboxReferralLearnMore
