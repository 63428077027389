import React from 'react'

import styled, { css } from 'styled-components'

import { Icon } from '../Icon'
import { IScheme } from '../Interfaces/schemes'
import { LabelWrapper } from '../Radio/Radio'
import { defaultTheme, getColor, themed } from '../Theme'
import { Text } from '../Typography'

const StyledCheckboxWrapper = styled.div`
  position: relative;
`

const Dot = themed(styled.div`
  ${({ scheme, theme }) => css`
    background: ${getColor(theme, scheme)};
    position: absolute;
    width: 1.125rem;
    border-radius: 2rem;
    height: 1.125rem;
    margin-left: 0.4375rem;
  `}
`)

export const StyledCheckbox = themed(styled<ICheckboxProps, any>('input')`
  ${({ scheme, theme, circular, noHover, backgroundColor, hideBorder }) => css`
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      ${!noHover && `cursor: pointer`};
      padding: 0;
      display: inline-flex;
      align-items: center;

      &::before {
        content: '';
        margin-right: 0.625rem;
        display: inline-block;
        vertical-align: text-top;
        background: ${theme.whiteColor};
        border-radius: ${circular ? 2 : 0.25}rem;
        border: solid 1px ${theme.grey400Color};
        width: ${circular ? 2 : 1.5}rem;
        height: ${circular ? 2 : 1.5}rem;
        box-sizing: border-box;
        flex-shrink: 0;
        ${backgroundColor && `background-color: ${backgroundColor}`};
        ${hideBorder && `border: none`};
      }

      & .heavy-tick-icon {
        display: none;
        position: absolute;
        top: ${circular ? 0.5 : 0.25}rem;
        left: ${circular ? 0.5 : 0.25}rem;
        width: 1rem;
        height: 1rem;
        align-items: center;

        svg {
          fill: ${getColor(theme, scheme)};
        }
      }
    }

    &:checked + label .heavy-tick-icon {
      display: flex;
      height: 1.5rem;
      top: calc(50% - 0.75rem);
    }

    &:disabled + label {
      cursor: auto;

      &::before {
        border-color: ${theme.grey400Color};
      }
    }
  `};
`)

interface ICheckboxProps extends IScheme, React.FormHTMLAttributes<HTMLInputElement> {
  /**
   * Sets the checkbox value
   *
   *
   */
  value?: any

  /**
   * Sets the checkbox html name
   *
   *
   */
  name?: any

  /**
   * Sets the checkbox status
   *
   *
   */
  checked?: boolean

  /**
   * The label adjacent to the checkbox element
   *
   *
   */
  label?: JSX.Element | string

  /**
   * Displays checkbox in disabled state
   *
   * @default false
   */
  disabled?: boolean

  /**
   * Displays circular checkbox design
   *
   */
  circular?: boolean

  /**
   * Display dot instead of check
   */
  dot?: boolean

  /**
   * ref to the actual input element
   *
   * @default undefined
   */
  innerRef?: React.Ref<HTMLFormElement>

  /**
   * disables cursor hover when set to true
   *
   * @default undefined
   */
  noHover?: boolean

  /**
   * sets checkbox to readonly
   *
   * @default undefined
   */
  readOnly?: boolean

  /**
   * sets checkbox background color
   *
   * @default undefined
   */
  backgroundColor?: string

  /**
   * hides checkbox border
   *
   * @default undefined
   */
  hideBorder?: boolean
}

class Checkbox extends React.PureComponent<ICheckboxProps> {
  static defaultProps = {
    labelPosition: 'left',
  }

  private randomId = Math.random()

  public render() {
    const { label, checked = false, circular, dot, readOnly, ...props } = this.props

    return (
      <StyledCheckboxWrapper>
        <StyledCheckbox
          id={this.randomId}
          type="checkbox"
          checked={checked}
          circular={circular}
          readOnly={readOnly}
          {...props}
        />
        <label htmlFor={`${this.randomId}`}>
          <LabelWrapper labelFontSize={0.875}>
            <Text color={checked ? defaultTheme.defaultColor : defaultTheme.grey700Color}>
              {label}
            </Text>
          </LabelWrapper>
          {checked && dot && <Dot className="doooot" scheme={props.scheme} />}
          {!dot && circular && <Icon glyph="approved" className="heavy-tick-icon" />}
          {!dot && !circular && <Icon glyph="tick-heavy" className="heavy-tick-icon" />}
        </label>
      </StyledCheckboxWrapper>
    )
  }
}

export type { ICheckboxProps }
export default Checkbox
