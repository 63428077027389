import { IMeta } from '@store'
import { IMe, MeActionTypes } from '@store/me'

export const fetchMeRequest = (meta: IMeta = {}) => ({
  meta,
  type: MeActionTypes.FETCH_REQUEST,
})

export const fetchMeSuccess = (me: IMe) => ({
  type: MeActionTypes.FETCH_SUCCESS,
  payload: me,
})

export const fetchMeError = (error: any) => ({
  type: MeActionTypes.FETCH_ERROR,
  payload: error,
})
