import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { IModalComponentProps } from '@context/Modal'
import Theme from '@theme'
import { Button, Container, H3, Modal, Text } from '@tribegroup'

interface IProps extends IModalComponentProps {
  onAccept: VoidFunction
}

const ConnectedAccountsDrawerContentFilledIdentitySwitchOffAccountDialog: React.FC<IProps> = ({
  isOpen = false,
  onRequestClose,
  onAccept,
}) => {
  return (
    <Modal reactModalProps={{ isOpen }} width={20}>
      <Container
        leftOuterSpacing={0.25}
        rightOuterSpacing={0.25}
        data-testid="switch-account-dialog"
      >
        <H3 color={Theme.grey900Color}>
          <FormattedMessage id="connectedAccounts.dialog.switchOffAccount.title" />
        </H3>
        <Container topOuterSpacing={0.5}>
          <Text color={Theme.grey800Color}>
            <FormattedMessage id="connectedAccounts.dialog.switchOffAccount.paragraph" />
          </Text>
        </Container>
      </Container>
      <Container fullWidth textAlign="right" topOuterSpacing={3.125}>
        <Flex justifyEnd>
          <Button scheme="default" outlined borderStyle="none" onClick={onRequestClose}>
            <FormattedMessage id="core.text.cancel" />
          </Button>
          <Button scheme="primary" onClick={onAccept} small>
            <Container noWrap>
              <FormattedMessage id="connectedAccounts.dialog.switchOffAccount.button.accept" />
            </Container>
          </Button>
        </Flex>
      </Container>
    </Modal>
  )
}

export default ConnectedAccountsDrawerContentFilledIdentitySwitchOffAccountDialog
