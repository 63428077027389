import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import Flex, { FlexItem } from 'styled-flex-component'

import RouterLink from '@components/UI/RouterLink/RouterLink.styled'
import { PageRoutes } from '@enums'
import Theme from '@theme'
import { Button, Container, Text, Tip } from '@tribegroup'

export const CreditManagementPaymentMethodCreditCardEmpty = () => {
  const location = useLocation()
  const fromRoute = `?from=${encodeURIComponent(`${location.pathname}${location.search}`)}`

  return (
    <Flex full column>
      <Container topInnerSpacing={2}>
        <Text color={Theme.grey700Color}>
          <FormattedMessage id="contentLibrary.licensing.creditManagement.creditCard.empty.label" />
        </Text>
      </Container>
      <FlexItem grow={1}>
        <Container fullHeight flexCenter>
          <Button
            noUppercase
            small
            outlined
            scheme="primary"
            topInnerSpacing={0.25}
            bottomInnerSpacing={0.25}
            leftInnerSpacing={0.5}
            rightInnerSpacing={0.5}
          >
            <RouterLink to={`/${PageRoutes.Account}${fromRoute}`}>
              <Text color={Theme.primaryColor} small>
                <FormattedMessage id="contentLibrary.licensing.creditManagement.creditCard.empty.button" />
              </Text>
            </RouterLink>
          </Button>
        </Container>
      </FlexItem>
      <Container flexStart alignItems="flex-start" verticalAlign="middle" bottomOuterSpacing={1.5}>
        <Tip
          text={
            <Text color={Theme.grey800Color} small>
              <FormattedMessage id="contentLibrary.licensing.creditManagement.creditCard.empty.tip" />
            </Text>
          }
          width={26}
        />
      </Container>
    </Flex>
  )
}
