import { Fragment } from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { hasCompanyInfo } from '@store/me'
import {
  selectIsEditingAnyAccountForm,
  selectPathnameEndsWithContact,
  selectPathnameEndsWithPassword,
  selectPathnameEndsWithPayment,
} from '@store/router'
import { FormValidationMessage, Text } from '@tribegroup'
import Company from './Company/Company'
import Contact from './Contact/Contact'
import ContactForm from './Contact/Form'
import PasswordForm from './Password/Form'
import Password from './Password/Password'
import PaymentForm from './Payment/Form'
import Payment from './Payment/Payment'
import { Section } from './Account.styled'

export const ErrorMessage = ({ results, field }) => {
  // eslint-disable-next-line no-prototype-builtins
  if (results.hasOwnProperty(field) && results[field].hasError) {
    return (
      <FormValidationMessage>
        <Text small>{results[field].errorMessage}</Text>
      </FormValidationMessage>
    )
  }
  return null
}

interface IInternalProps {
  hasCompany: boolean
  isEditingContact: boolean
  isEditingPassword: boolean
  isEditingPayment: boolean
  isEditingAny: boolean
}

export const Account = ({
  hasCompany,
  isEditingContact,
  isEditingPassword,
  isEditingPayment,
  isEditingAny,
}: IInternalProps) => {
  return (
    <Fragment>
      <Section>{isEditingContact ? <ContactForm /> : <Contact disabled={isEditingAny} />}</Section>
      <Section>
        {isEditingPassword ? <PasswordForm /> : <Password disabled={isEditingAny} />}
      </Section>
      {hasCompany && (
        <Fragment>
          <Section>
            {isEditingPayment ? <PaymentForm /> : <Payment disabled={isEditingAny} />}
          </Section>
          <Section>
            <Company disabled={isEditingAny} />
          </Section>
        </Fragment>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  hasCompany: hasCompanyInfo(state),
  isEditingContact: selectPathnameEndsWithContact(state),
  isEditingPassword: selectPathnameEndsWithPassword(state),
  isEditingPayment: selectPathnameEndsWithPayment(state),
  isEditingAny: selectIsEditingAnyAccountForm(state),
})

export default compose<IInternalProps, {}>(connect(mapStateToProps))(Account)
