import { PropsWithChildren } from 'react'

import { SubmissionCreatorNoteAccordionItemStyled } from './Item.styled'

interface ISubmissionCreatorNoteAccordionItem {
  onClick: (id?: number) => void
  active: boolean
  id?: number
}

const SubmissionCreatorNoteAccordionItem: React.FC<PropsWithChildren<
  ISubmissionCreatorNoteAccordionItem
>> = ({ active, onClick, children, id }) => {
  return (
    <SubmissionCreatorNoteAccordionItemStyled active={active} onClick={() => onClick(id)}>
      {children}
    </SubmissionCreatorNoteAccordionItemStyled>
  )
}

export default SubmissionCreatorNoteAccordionItem
