import { call, put, select, takeEvery } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import { selectSubmissionsByInfluencerIdentityLink } from '@store/influencerIdentities'
import {
  fetchSubmissionsByInfluencerIdentity as fetchSubmissionsByInfluencerIdentityAction,
  fetchSubmissionsByInfluencerIdentityError,
  fetchSubmissionsByInfluencerIdentitySuccess,
  SubmissionsByInfluencerIdentityActionTypes,
} from '@store/submissions'
import { networkRequest } from '@utils'

export function* fetchSubmissionsByInfluencerIdentity(
  campaignId,
  influencerIdentityId,
  backgroundRefresh,
) {
  try {
    const authToken: string = yield select(selectAuthToken)

    const submissionsByInfluencerIdentityLink: IHateoasLink = yield select(
      selectSubmissionsByInfluencerIdentityLink,
      campaignId,
      influencerIdentityId,
    )

    const { items } = yield call(
      networkRequest,
      submissionsByInfluencerIdentityLink.method,
      submissionsByInfluencerIdentityLink.href,
      undefined,
      authToken,
    )

    yield put(
      fetchSubmissionsByInfluencerIdentitySuccess(
        campaignId,
        influencerIdentityId,
        items,
        backgroundRefresh,
      ),
    )
  } catch (error) {
    yield put(fetchSubmissionsByInfluencerIdentityError(influencerIdentityId, error))
  }
}

export function* handleFetchSubmissionsByInfluencerIdentity(
  action: ReturnType<typeof fetchSubmissionsByInfluencerIdentityAction>,
) {
  const { campaignId, influencerIdentityId } = action.payload
  yield call(fetchSubmissionsByInfluencerIdentity, campaignId, influencerIdentityId, false)
}

export function* watchSubmissionsByInfluencerIdentityRequest() {
  yield takeEvery(
    SubmissionsByInfluencerIdentityActionTypes.FETCH_REQUEST,
    handleFetchSubmissionsByInfluencerIdentity,
  )
}
