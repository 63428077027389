import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import Flex, { FlexItem } from 'styled-flex-component'

import { requestPurchaseOrdersAccess } from '@store/purchaseOrders/actions/requestAccess'
import Theme from '@theme'
import { Button, Container, Text } from '@tribegroup'

export const CreditManagementPaymentMethodPurchaseOrderEmpty = () => {
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(requestPurchaseOrdersAccess())
  }

  return (
    <Flex full column>
      <Container topInnerSpacing={2}>
        <Text color={Theme.grey700Color}>
          <FormattedMessage id="contentLibrary.licensing.creditManagement.purchaseOrder.empty.label" />
        </Text>
      </Container>
      <FlexItem grow={1}>
        <Container fullHeight flexCenter flexColumn>
          <Container bottomOuterSpacing={0.75}>
            <Text color={Theme.grey800Color}>
              <FormattedMessage id="contentLibrary.licensing.creditManagement.purchaseOrder.empty.button.label" />
            </Text>
          </Container>

          <Button
            noUppercase
            small
            outlined
            scheme="primary"
            topInnerSpacing={0.25}
            bottomInnerSpacing={0.25}
            leftInnerSpacing={0.5}
            rightInnerSpacing={0.5}
            onClick={onClick}
            data-testid="request-access-button"
          >
            <Text color={Theme.primaryColor} small>
              <FormattedMessage id="contentLibrary.licensing.creditManagement.purchaseOrder.empty.button" />
            </Text>
          </Button>
        </Container>
      </FlexItem>
    </Flex>
  )
}
