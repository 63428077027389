import { FeedbackActionTypes } from '@store/feedback'

export const fetchFeedback = (submissionId: number, campaignId: number) => ({
  payload: { submissionId, campaignId },
  type: FeedbackActionTypes.FETCH_REQUEST,
})

export const fetchFeedbackSuccess = (submissionId: number, result) => ({
  payload: { submissionId, result },
  type: FeedbackActionTypes.FETCH_SUCCESS,
})

export const fetchFeedbackError = (error: any) => ({
  payload: error,
  type: FeedbackActionTypes.FETCH_ERROR,
})
