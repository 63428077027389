import { createSelector } from 'reselect'

import { APP_LOCALE, CONTENT_LIBRARY } from '@constants'
import { BrandedContentAdStatusTypes } from '@enums'
import { IApplicationState, IFeatureHateoasLink, IHateoasLink } from '@store'
import { ICampaign, ILicenseOption, selectCampaign } from '@store/campaigns'
import { IDraft, selectDraft } from '@store/drafts'
import { selectMediaObjectLicenseOption } from '@store/licenses'
import { selectHasCreditCardInfo, selectSubscriptionTierFeatures } from '@store/me'
import {
  selectLicenseReceiptNumber,
  selectMediaType,
  selectTransactedLicense,
  selectTransactedLicenseStatus,
} from '@store/mediaObjects'
import { selectContentLibraryFilter, selectCurrentView, selectLocation } from '@store/router'
import {
  selectAudienceInsightsLink,
  selectCreatorBioLink,
  selectFirstMediaObjectId,
  selectPromoteAsAdLink,
  selectSubmission,
  selectTransactedBrandedContentAdStatus,
} from '@store/submissions'
import {
  getBCAStatus,
  getCampaignProperties,
  getDraftLocationProps,
  getDraftProperties,
  getLicenseOptionValue,
  getSubmissionLocationProps,
  getSubmissionProperties,
  isEmpty,
} from '@utils'

const selectSubmissionId = (state, submissionId) => submissionId

export const selectBrandedContentAdProperties = createSelector(
  selectPromoteAsAdLink,
  selectTransactedBrandedContentAdStatus,
  (link: IHateoasLink, status: BrandedContentAdStatusTypes) => ({
    bca_state: getBCAStatus(Boolean(link), status),
  }),
)

const selectSubmissionProperties = createSelector(
  selectSubmissionId,
  selectSubmission,
  (state, submissionId) => selectMediaType(state, selectFirstMediaObjectId(state, submissionId)),
  (state, submissionId) =>
    selectLicenseReceiptNumber(state, selectFirstMediaObjectId(state, submissionId)),
  (state, submissionId) =>
    selectTransactedLicenseStatus(state, selectFirstMediaObjectId(state, submissionId)),
  selectHasCreditCardInfo,
  selectAudienceInsightsLink,
  selectBrandedContentAdProperties,
  selectSubscriptionTierFeatures,
  selectCreatorBioLink,
  (state, submissionId) =>
    selectTransactedLicense(state, selectFirstMediaObjectId(state, submissionId)),
  (
    submissionId,
    submission,
    mediaType,
    receiptNumber,
    licenseStatus,
    hasCreditCardInfo,
    audienceInsightsLink: IFeatureHateoasLink,
    bcaProperties,
    features: ReadonlyArray<string>,
    creatorBioLink,
    transactedLicense,
  ) => {
    if (isEmpty(submission)) {
      return {
        submission_id: submissionId,
      }
    }
    const hasInsights = Boolean(
      audienceInsightsLink && features.includes(audienceInsightsLink.feature),
    )

    let response = {
      ...getSubmissionProperties(submission),
      content_type: mediaType,
      content_licensing_id: receiptNumber,
      license_state: licenseStatus,
      credit_card_available: hasCreditCardInfo,
      has_audience_insights: hasInsights,
      creator_portfolio: Boolean(creatorBioLink),
      ...bcaProperties,
    }

    if (transactedLicense && !submission?.social_submission_type) {
      const amount = transactedLicense.amount_paid_before_tax_cents! / 100
      const formatOptions = {
        style: 'decimal',
        useGrouping: false,
        minimumFractionDigits: 2,
      }

      response = {
        ...response,
        currency: transactedLicense?.amount_paid_before_tax_currency,
        value: new Intl.NumberFormat(APP_LOCALE, formatOptions).format(amount),
      }
    }

    return response
  },
)

const selectSubmissionPropertiesAdapter = (
  state: IApplicationState,
  campaignId,
  submissionId,
  mediaObjectId,
) => selectSubmissionProperties(state, submissionId, mediaObjectId)

export const selectCampaignTrackingProps = createSelector(
  selectCampaign,
  selectLocation,
  (campaign: ICampaign, location) => ({
    ...getCampaignProperties(campaign),
    ...getSubmissionLocationProps(location),
  }),
)

export const selectSubmissionTrackingProps = createSelector(
  selectCampaign,
  selectSubmissionPropertiesAdapter,
  selectLocation,
  (campaign: ICampaign, submissionProperties, location) => ({
    ...getCampaignProperties(campaign),
    ...submissionProperties,
    ...getSubmissionLocationProps(location),
  }),
)

const selectLicenseOptionAdapter = (state, campaignId, submissionId, licenseIndex) => {
  const mediaObjectId = selectFirstMediaObjectId(state, submissionId)
  return selectMediaObjectLicenseOption(state, mediaObjectId, licenseIndex)
}

export const selectSelectedLicenseOptionTrackingProps = createSelector(
  (state, campaignId, submissionId) =>
    selectSubmissionTrackingProps(state, campaignId, submissionId),
  selectLicenseOptionAdapter,
  (trackingProps: {}, selectedLicenseOption: ILicenseOption) => ({
    ...trackingProps,
    license_type: selectedLicenseOption.license_type,
    value: getLicenseOptionValue(selectedLicenseOption),
    currency: selectedLicenseOption.total_amount_before_tax_currency,
  }),
)

export const selectMediaObjectTrackingProps = createSelector(
  selectMediaType,
  selectContentLibraryFilter,
  selectCurrentView,
  selectLicenseReceiptNumber,
  selectTransactedLicenseStatus,
  selectHasCreditCardInfo,
  (mediaType, filter, currentView, receiptNumber, licenseStatus, hasCreditCardInfo) => ({
    content_type: mediaType,
    current_filter: `${CONTENT_LIBRARY}_${filter}`,
    current_view: currentView,
    content_licensing_id: receiptNumber,
    license_state: licenseStatus,
    credit_card_available: hasCreditCardInfo,
    content_library: true,
  }),
)

export const selectDraftTrackingProps = createSelector(
  selectDraft,
  selectLocation,
  (draft: IDraft, location) => ({
    ...getDraftProperties(draft),
    ...getDraftLocationProps(location),
  }),
)
