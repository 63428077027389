import { call, put, select, takeLatest } from 'redux-saga/effects'

import { AudienceInsightsSource } from '@enums'
import { IHateoasLink } from '@store'
import {
  AudienceInsightsActionTypes,
  fetchAudienceInsights,
  fetchAudienceInsightsError,
  fetchAudienceInsightsSuccess,
} from '@store/audienceInsights'
import { selectAuthToken } from '@store/auth'
import { selectIdentityAudienceInsightsLink } from '@store/brandFans'
import { selectAudienceInsightsLinkByIdentityId } from '@store/submissions'
import { networkRequest } from '@utils'

export function* handleFetchAudienceInsights(action: ReturnType<typeof fetchAudienceInsights>) {
  try {
    const { identityId, source } = action.payload
    const authToken: string = yield select(selectAuthToken)

    const selector =
      source === AudienceInsightsSource.Submission
        ? selectAudienceInsightsLinkByIdentityId
        : selectIdentityAudienceInsightsLink

    const audienceInsightsLink: IHateoasLink = yield select(selector, identityId)
    const audienceInsights = yield call(
      networkRequest,
      audienceInsightsLink.method,
      audienceInsightsLink.href,
      undefined,
      authToken,
    )
    yield put(fetchAudienceInsightsSuccess(identityId, audienceInsights))
  } catch (error) {
    yield put(fetchAudienceInsightsError(error))
  }
}

function* watchFetchAudienceInsights() {
  yield takeLatest(AudienceInsightsActionTypes.FETCH_REQUEST, handleFetchAudienceInsights)
}

export const audienceInsightsSagas: ReadonlyArray<any> = [watchFetchAudienceInsights]
