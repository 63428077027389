import { createRef, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import Cookies from 'js-cookie'
import { useForm } from 'react-hook-form'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Flex from 'styled-flex-component'

import AuthForm from '@components/UI/AuthForm'
import Box from '@components/UI/Box'
import CountriesDropdown from '@components/UI/CountriesDropdown/CountriesDropdown'
import ReCaptcha from '@components/UI/ReCaptcha'
import { IGRecaptchaProps } from '@components/UI/ReCaptcha/ReCaptcha'
import { DEFAULT_TENANT } from '@constants'
import { EMAIL_RELATED_ERROR_CODES_FOR_SIGNUP } from '@constants'
import { useLocationSearch } from '@hooks/useLocationSearch'
import { IResponseErrorMessage } from '@lib/error'
import { IApplicationState } from '@store'
import {
  clearErrors as clearAuthErrors,
  fetchIPRequest,
  ISignUpParams,
  selectAuthErrorMessages,
  selectIsAuthenticating,
  selectRequesterCountryCode,
  SignUpFormData,
  signUpRequest,
  signUpSchema,
} from '@store/auth'
import {
  selectConsentedItemsEmailDefault,
  selectConsentedItemsTermsDefault,
  selectTenantFeaturesByKey,
} from '@store/rootResource'
import Theme from '@theme'
import {
  Button,
  CheckboxWithState,
  Container,
  FormGroup,
  FormGroupRow,
  FormInput,
  Text,
  TextField,
} from '@tribegroup'
import getConfig from '@utils/getConfig'
import AuthSignupFormErrorContainer from './ErrorContainer'
import AuthSignupFormSubTitle from './SubTitle'
import AuthSignupFormTerms from './Terms'

interface IOptionType {
  label: string
  value: string
}

const DEFAULT_GOOGLE_CLIENT_ID = '111111.222222'

const AuthSignUpForm: React.FC<InjectedIntlProps> = ({ intl }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { referrer, bypassRecaptchaToken } = useLocationSearch()
  const { register, handleSubmit, clearErrors, errors } = useForm<SignUpFormData>({
    resolver: yupResolver(signUpSchema),
  })

  const recaptchaRef: React.RefObject<IGRecaptchaProps> = createRef()

  const { showCreatorLink, countryCode, errorMessages, isAuthenticating } = useSelector(
    (state: IApplicationState) => {
      const tenantFeaturesByKey = selectTenantFeaturesByKey(state)
      return {
        showCreatorLink: tenantFeaturesByKey?.['signup_creator_link'] ?? true,
        errorMessages: selectAuthErrorMessages(state) as IResponseErrorMessage[],
        isAuthenticating: selectIsAuthenticating(state),
        marketing_newsletters: selectConsentedItemsEmailDefault(state),
        terms_and_conditions: selectConsentedItemsTermsDefault(state),
        countryCode: selectRequesterCountryCode(state),
      }
    },
  )

  useEffect(() => {
    dispatch(fetchIPRequest())
  }, [])

  useEffect(() => {
    if (!isAuthenticating && recaptchaRef?.current) {
      setRecaptchaToken('')
      recaptchaRef.current.reset()
    }
  }, [isAuthenticating])

  const [selectedCountryOption, setSelectedCountryOption] = useState<IOptionType | null>(null)
  const [recaptchaToken, setRecaptchaToken] = useState<string | undefined>(undefined)

  const errorCode = errorMessages && errorMessages?.[0]?.error_code
  const emailRelatedErrorCode =
    errorCode && EMAIL_RELATED_ERROR_CODES_FOR_SIGNUP.includes(errorCode) ? errorCode : undefined

  const skipOnboarding = process.env.APP_NAME !== DEFAULT_TENANT

  const onReCaptchaChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement
    clearErrors('recaptcha_token')
    setRecaptchaToken(value)
  }

  const onSubmit = (data: ISignUpParams) => {
    if (data.google_click_id) {
      data.google_click_id = data.google_click_id.split('.').pop()
    }

    if (data.google_client_id) {
      const clientId = data.google_client_id.split('.')
      if (clientId[2] && clientId[3]) {
        data.google_client_id = `${clientId[2]}.${clientId[3]}`
      }
    } else {
      data.google_client_id = DEFAULT_GOOGLE_CLIENT_ID
    }

    dispatch(signUpRequest(data, history))
  }

  const onChangeCountryCode = (option: IOptionType) => {
    setSelectedCountryOption(option)
  }

  const clearServerSideErrors = () => {
    if (errorCode) {
      dispatch(clearAuthErrors())
    }
  }

  return (
    <Box fullOnSmallScreens>
      <AuthForm
        title={<FormattedMessage id="auth.signup.h1.signUpHeader" />}
        subtitle={<AuthSignupFormSubTitle showCreatorLink={showCreatorLink} />}
      >
        <form name="sign-up" onSubmit={handleSubmit(onSubmit)} method="POST" autoComplete="off">
          <FormGroupRow spaceBetween={1}>
            <FormGroup stretchChildWidth>
              <TextField
                fullWidth
                rounded
                type="text"
                name="first_name"
                innerRef={register}
                placeholder={intl.formatMessage({
                  id: 'auth.signup.form.firstName.placeholder',
                })}
                invalid={Boolean(errors.first_name)}
              />
              <AuthSignupFormErrorContainer intlId={errors.first_name?.message} />
            </FormGroup>
            <FormGroup stretchChildWidth>
              <TextField
                innerRef={register}
                fullWidth
                rounded
                type="text"
                name="last_name"
                placeholder={intl.formatMessage({
                  id: 'auth.signup.form.lastName.placeholder',
                })}
                invalid={Boolean(errors.last_name)}
              />
              <AuthSignupFormErrorContainer intlId={errors.last_name?.message} />
            </FormGroup>
          </FormGroupRow>

          {!skipOnboarding && (
            <FormGroup stretchChildWidth>
              <TextField
                innerRef={register}
                rounded
                fullWidth
                type="text"
                name="company_name"
                placeholder={intl.formatMessage({
                  id: 'auth.signup.form.companyName.placeholder',
                })}
                invalid={Boolean(errors.company_name)}
              />
              <AuthSignupFormErrorContainer intlId={errors.company_name?.message} />
            </FormGroup>
          )}

          <FormGroup>
            <input
              type="hidden"
              ref={register}
              name="country_code"
              data-testid="country-code-field"
              value={selectedCountryOption?.value || countryCode}
            />
            <CountriesDropdown
              defaultCountryCode={countryCode}
              onChange={onChangeCountryCode}
              selectedCountry={selectedCountryOption as Object}
            />
          </FormGroup>
          <FormGroup stretchChildWidth>
            <TextField
              innerRef={register}
              fullWidth
              rounded
              type="text"
              name="phone_number"
              placeholder={intl.formatMessage({
                id: 'auth.signup.form.phone_number.placeholder',
              })}
              invalid={Boolean(errors.phone_number)}
            />
          </FormGroup>
          <FormGroup stretchChildWidth>
            <TextField
              innerRef={register}
              fullWidth
              rounded
              type="text"
              name="email"
              placeholder={intl.formatMessage({
                id: 'auth.signup.form.email.placeholder',
              })}
              onChange={clearServerSideErrors}
              invalid={Boolean(errors.email)}
            />

            <AuthSignupFormErrorContainer intlId={errors.email?.message} />
            {emailRelatedErrorCode && (
              <AuthSignupFormErrorContainer intlId={emailRelatedErrorCode} />
            )}
          </FormGroup>
          <FormGroup stretchChildWidth>
            <TextField
              innerRef={register}
              rounded
              fullWidth
              type="password"
              name="password"
              placeholder={intl.formatMessage({
                id: 'core.text.password',
              })}
              invalid={Boolean(errors.password)}
            />
            <AuthSignupFormErrorContainer intlId={errors.password?.message} />
          </FormGroup>
          <FormGroup outerBottomSpacing={0.5}>
            <CheckboxWithState
              innerRef={register}
              name="terms_and_conditions"
              scheme="primary"
              readOnly
              label={<AuthSignupFormTerms />}
            />
            <AuthSignupFormErrorContainer intlId={errors.terms_and_conditions?.message} />
          </FormGroup>
          <FormGroup>
            <FormInput>
              <CheckboxWithState
                innerRef={register}
                name="marketing_newsletters"
                scheme="primary"
                readOnly
                label={
                  <Text color={Theme.grey800Color} small>
                    <FormattedMessage id="auth.signup.form.marketingEmail.simplified.label" />
                  </Text>
                }
              />
            </FormInput>
          </FormGroup>
          {getConfig('recaptchaEnabled') && (
            <FormGroup>
              <ReCaptcha
                onChange={onReCaptchaChange}
                name="recaptcha_token"
                innerRef={recaptchaRef}
              />
              <input
                ref={register}
                data-testid="recaptcha-field"
                name="recaptcha_token"
                id="recaptcha_field"
                type="hidden"
                value={recaptchaToken}
              />
              <AuthSignupFormErrorContainer intlId={errors.recaptcha_token?.message} />
            </FormGroup>
          )}
          {errorCode && !emailRelatedErrorCode && (
            <AuthSignupFormErrorContainer intlId={errorCode} />
          )}
          <Flex center>
            <Button
              fullWidth
              loading={isAuthenticating}
              disabled={isAuthenticating}
              scheme="primary"
              data-cy-element="sign-up-button"
            >
              <FormattedMessage id={!skipOnboarding ? 'core.text.continue' : 'core.text.submit'} />
            </Button>
          </Flex>
          {!skipOnboarding && (
            <Container topOuterSpacing={1.5} textAlign="center" color={Theme.grey700Color}>
              <Text light>
                <FormattedMessage id="auth.onboarding.stepXofY" values={{ x: 1, y: 2 }} />
              </Text>
            </Container>
          )}
          <input ref={register} name="referrer_token" type="hidden" value={referrer} />
          <input ref={register} name="hubspotutk" type="hidden" value={Cookies.get('hubspotutk')} />
          <input ref={register} name="google_client_id" type="hidden" value={Cookies.get('_ga')} />
          <input
            ref={register}
            name="google_click_id"
            type="hidden"
            value={Cookies.get('_gac_UA-68174509-1')}
          />
          <input
            ref={register}
            name="bypass_recaptcha_token"
            type="hidden"
            value={bypassRecaptchaToken}
          />
        </form>
      </AuthForm>
    </Box>
  )
}

export default injectIntl(AuthSignUpForm)
