import * as React from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import BrandFansHeader from '@components/BrandFans/Header'
import InfluencerIdentityList from '@components/BrandFans/InfluencerIdentity/List'
import { BrandFanFilters } from '@enums'
import { withRememberScrollPosition } from '@hocs'
import { IApplicationState } from '@store'
import {
  IBrandFanStats,
  selectBrandFansStats,
  selectIsListEmpty,
  selectStatByFilter,
} from '@store/brandFans'
import { selectCurrentBrandFansFilter } from '@store/router'
import InfluencerIdentityGroupList from '../Group/List'
import { IdentitiesPageSubHeader } from '../Header/SubHeader'
import BrandFansInfluencerIdentityTopPanel from '../InfluencerIdentity/TopPanel'
import BrandFansContentUnsupportedScreenHandler from './UnsupportedScreenHandler'

interface IInternalProps {
  hasEmpyList: boolean
  statsLoaded: boolean
  currentFilter: BrandFanFilters
  brandFanStats: IBrandFanStats
}

const BrandFansContent: React.FC<IInternalProps> = ({
  hasEmpyList,
  brandFanStats,
  currentFilter,
  statsLoaded,
}) => {
  if (currentFilter === BrandFanFilters.Groups) {
    return (
      <BrandFansContentUnsupportedScreenHandler>
        <BrandFansHeader
          type={BrandFanFilters.Groups}
          count={brandFanStats[BrandFanFilters.Groups]}
        >
          <IdentitiesPageSubHeader hasEmpyList={hasEmpyList} type={BrandFanFilters.Groups} />
        </BrandFansHeader>
        <InfluencerIdentityGroupList />
      </BrandFansContentUnsupportedScreenHandler>
    )
  }

  return (
    <BrandFansContentUnsupportedScreenHandler>
      <BrandFansHeader type={currentFilter} count={brandFanStats[currentFilter]}>
        <IdentitiesPageSubHeader hasEmpyList={hasEmpyList} type={currentFilter} />
      </BrandFansHeader>
      {!hasEmpyList && statsLoaded && <BrandFansInfluencerIdentityTopPanel />}
      <InfluencerIdentityList filter={currentFilter} />
    </BrandFansContentUnsupportedScreenHandler>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  const currentFilter = selectCurrentBrandFansFilter(state)
  return {
    currentFilter,
    brandFanStats: selectBrandFansStats(state),
    hasEmpyList: selectIsListEmpty(state, currentFilter),
    statsLoaded: Boolean(selectStatByFilter(state, currentFilter)),
  }
}

export { BrandFansContent }
export default compose<IInternalProps, {}>(
  withRouter,
  withRememberScrollPosition,
  connect(mapStateToProps),
)(BrandFansContent)
