import { ClearDraftActionTypes } from '@store/drafts'

export const clearDraft = () => ({
  type: ClearDraftActionTypes.CLEAR_DRAFT_REQUEST,
})

export const clearDraftSuccess = () => ({
  type: ClearDraftActionTypes.CLEAR_DRAFT_SUCCESS,
})

export const clearDraftError = (error: any) => ({
  payload: error,
  type: ClearDraftActionTypes.CLEAR_DRAFT_ERROR,
})
