import React, { PropsWithChildren } from 'react'

import { FormattedMessage } from 'react-intl'

import hooks from '@hooks'
import Theme from '@theme'
import { Container, DropdownMenu, Icon, MenuItemLink, TargetContainer, Text } from '@tribegroup'
import { IDropdownMenuProps } from '@tribegroup/Menu/DropdownMenu'
import { isEmpty, objectToQueryString } from '@utils'

interface IOption {
  value: string
  display: React.ReactNode
}

interface ITrackingWrapper {
  component: React.ComponentType<PropsWithChildren<{}>>
  createProps: (value: string) => object
}

interface IProps {
  options: ReadonlyArray<IOption>
  onSortByChange: VoidFunction
  dropdownProps?: Partial<IDropdownMenuProps>
  trackingWrapper?: ITrackingWrapper
  anchor?: 'left' | 'right'
}

const SortMenu: React.FC<IProps> = ({
  options,
  dropdownProps,
  onSortByChange,
  anchor,
  trackingWrapper: TrackingWrapper = { component: 'span', createProps: () => ({}) },
}) => {
  const search = hooks.useLocationSearch()

  const { sort_by } = search
  const selected = (sort_by && decodeURIComponent(sort_by)) || (options[0] && options[0].value)

  const isMounted = hooks.useIsMounted()
  React.useEffect(() => {
    if (isMounted) {
      onSortByChange()
    }
  }, [selected])

  if (isEmpty(options)) {
    return null
  }

  const selectedOption = options.find((option) => option.value === selected) as IOption

  return (
    <DropdownMenu
      target={
        <TargetContainer anchor={anchor}>
          {selectedOption && (
            <Text color={Theme.defaultColor}>
              <Container rightOuterSpacing={0.2} inline>
                <FormattedMessage id="core.text.sortBy" />
              </Container>
              {selectedOption.display}
            </Text>
          )}
        </TargetContainer>
      }
      showIndicator
      indicatorSpace={0}
      right={0}
      width={12}
      maxHeight={18}
      topOuterSpacing={0.5}
      indicatorClose={<Icon glyph="sort" color={Theme.grey900Color} size={1} />}
      indicatorOpen={<Icon glyph="sort" color={Theme.grey900Color} size={1} />}
      {...dropdownProps}
    >
      {options.map(({ value, display }: IOption) => (
        <TrackingWrapper.component key={value} {...TrackingWrapper.createProps(value)}>
          <MenuItemLink
            to={{
              search: objectToQueryString({
                ...search,
                sort_by: encodeURIComponent(value),
              }),
            }}
            selected={selected === value}
            iconColor={Theme.primaryColor}
          >
            {display}
          </MenuItemLink>
        </TrackingWrapper.component>
      ))}
    </DropdownMenu>
  )
}

export { SortMenu }
export default SortMenu
