import { PropsWithChildren } from 'react'

import { Container } from '@tribegroup'
import { LogoLink } from './TitleBar'
import TitleBarStyled from './TitleBar.styled'

const SimplifiedTitleBar: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <TitleBarStyled>
      <Container absolute leftPosition={0} topPosition={0} fullHeight flexStart alignItems="center">
        <LogoLink />
      </Container>
      <Container flexCenter alignItems="center" fullHeight>
        {children}
      </Container>
    </TitleBarStyled>
  )
}

export default SimplifiedTitleBar
