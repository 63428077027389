import React from 'react'

import { FormattedMessage } from 'react-intl'

import { CostBreakdownRow } from '@components/UI/CostBreakdown'
import { usePreapprovalAmountPercentage } from '@hooks/usePreapprovalAmountPercentage'
import { ISubmission } from '@store/submissions'
import Theme from '@theme'

interface IProps {
  submission: ISubmission
}

const SubmissionCostBreakdownPreApprovePrice: React.FC<IProps> = ({ submission }) => {
  const percentage = usePreapprovalAmountPercentage()
  const { preapproval_amount_before_tax_cents, preapproval_amount_before_tax_currency } = submission
  return (
    <CostBreakdownRow
      medium
      color={Theme.primaryColor}
      label={
        <FormattedMessage
          id="core.text.percentage.withCurrency"
          values={{ percentage, currency: preapproval_amount_before_tax_currency }}
        />
      }
      currency={preapproval_amount_before_tax_currency}
      quantity={preapproval_amount_before_tax_cents}
    />
  )
}

export default SubmissionCostBreakdownPreApprovePrice
