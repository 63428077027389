import React, { PropsWithChildren } from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import SummaryNavigation from '@components/UI/SummaryNavigation'
import { ContentLibraryFilterTypes, EventTrackingNames } from '@enums'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import {
  fetchMediaObjects,
  selectIsLoadingMoreMediaObjects,
  selectLoadMoreMediaObjects,
  selectNextMediaObjectId,
  selectNextMediaObjectLink,
  selectPrevMediaObjectId,
  selectPrevMediaObjectLink,
} from '@store/mediaObjects'
import {
  selectContentLibraryFilter,
  selectContentLibraryFilterKey,
  selectMediaObjectId,
} from '@store/router'

interface IInternalProps {
  filter: ContentLibraryFilterTypes
  prevLink?: string
  nextLink?: string
  isLoadingMore: boolean
  loadMoreMediaObjects: boolean
  fetchMediaObjects: typeof fetchMediaObjects
  prevMediaObjectId?: number
  nextMediaObjectId?: number
  mediaObjectId: number
}

export const MediaObjectNavigation: React.FC<PropsWithChildren<IInternalProps>> = ({
  children,
  nextLink,
  prevLink,
  mediaObjectId,
  prevMediaObjectId,
  nextMediaObjectId,
  loadMoreMediaObjects,
  filter,
  fetchMediaObjects: fetchMediaObjectsAction,
}) => {
  hooks.useActionDispatcherEffect(
    Boolean(loadMoreMediaObjects),
    fetchMediaObjectsAction,
    [filter, true],
    [loadMoreMediaObjects],
  )
  return (
    <SummaryNavigation
      prevLink={prevLink}
      nextLink={nextLink}
      currentId={mediaObjectId}
      prevTrackingProps={{
        eventName: EventTrackingNames.ViewContentLibraryItem,
        mediaObjectId: prevMediaObjectId,
      }}
      nextTrackingProps={{
        eventName: EventTrackingNames.ViewContentLibraryItem,
        mediaObjectId: nextMediaObjectId,
      }}
      loadingMore={loadMoreMediaObjects}
    >
      {children}
    </SummaryNavigation>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const mediaObjectId = selectMediaObjectId(match)
  const filter = selectContentLibraryFilter(state)
  const filterKey = selectContentLibraryFilterKey(state)
  const isLoadingMore = selectIsLoadingMoreMediaObjects(state, filterKey, mediaObjectId)

  return {
    filter,
    mediaObjectId: !isLoadingMore && mediaObjectId,
    prevLink: selectPrevMediaObjectLink(state, filterKey, mediaObjectId),
    prevMediaObjectId: selectPrevMediaObjectId(state, filterKey, mediaObjectId),
    nextLink: selectNextMediaObjectLink(state, filterKey, mediaObjectId),
    nextMediaObjectId: selectNextMediaObjectId(state, filterKey, mediaObjectId),
    loadMoreMediaObjects: selectLoadMoreMediaObjects(state, filterKey, mediaObjectId),
  }
}

const mapDispatchToProps = {
  fetchMediaObjects,
}

export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MediaObjectNavigation)
