import { BrandedContentAdStatusTypes, SocialSubmissionTypes } from '@enums'
import { IResponseError } from '@lib/error'
import { IFeatureHateoasLink, IHateoasLink } from '@store'
import { IBrandFanMember } from '@store/brandFans'
import { IMediaObject } from '@store/mediaObjects'

export interface ISubmission extends ISubmissionPricing {
  id: number
  brief_id: number
  can_add_to_brand_fan_group?: boolean
  status: string
  new_influencer_bonus_cents?: number
  new_influencer_bonus_currency?: string
  influencer_id: number
  influencer_outside_timezone?: boolean
  influencer_first_name: string
  influencer_last_name: string
  influencer_full_name: string
  influencer_gender?: string
  influencer_headshot?: {
    original_url: string
    thumbnail_url: string
  }
  influencer_state?: string
  influencer_country?: string
  influencer_rating?: number
  influencer_age?: number
  influencer_new?: boolean
  influencer_is_proxy?: boolean
  caption: string
  identity_id: number
  identity_avatar: string
  identity_followers_count?: number
  identity_provider: string
  identity_social_page_url: string
  identity_username: string
  identity_analytics?: IIdentityAnalytics
  identity_brand_fan_group_ids?: ReadonlyArray<number>
  identity_is_proxy?: boolean
  feedback_unread: boolean
  feedback_any: boolean
  notes_any?: boolean
  notes_unread?: boolean
  scheduled_publish_date?: string
  publish_within_hours?: number
  shortlisted?: boolean
  purchase_date?: string
  receipt_number?: string
  license_type?: string
  resubmitted?: boolean
  previously_purchased?: boolean
  content_license_requested?: boolean
  content_license_expired?: boolean
  published_at?: string
  licensed_at?: string
  transacted_bca_request?: IBrandedContentAdRequest
  links?: ReadonlyArray<IHateoasLink | IFeatureHateoasLink>
  analytics?: IPerformanceAnalytics
  social_submission_type?: SocialSubmissionTypes
  media_objects: ReadonlyArray<ISubmissionFrame>
  cpe_cents?: number
  cpe_currency?: string
  estimated_cpe_cents: number
  estimated_cpe_currency: string
  preapproved?: boolean
  top_identity_match?: boolean
  brand_match?: number
  pitching_enabled?: boolean
  is_price_hidden?: boolean
  final_media_type?: 'video' | 'image'
  has_unresolved_metric_issue?: boolean
  is_proxy?: boolean
  brand_fan_members?: ReadonlyArray<IBrandFanMember>
  proxy_submission?: boolean
  text?: string
}

export interface ISubmissionPricing {
  amount_cents: number
  amount_currency: string
  service_fee_cents?: number
  service_fee_currency?: string
  tax_display_name?: string
  minimum_amount_cents?: number
  first_preapproved_cents?: number
  first_preapproved_currency?: string
  total_amount_before_tax_cents?: number
  total_amount_before_tax_currency?: string
  preapproval_amount_before_tax_cents: number
  preapproval_amount_before_tax_currency: string
  amount_paid_before_tax_cents: number
  amount_paid_before_tax_currency: string
  balance_amount_before_tax_cents: number
  balance_amount_before_tax_currency: string
}

export interface ISubmissionsMetadataStats {
  inbox?: number
  shortlisted?: number
  approved?: number
  declined?: number
  published?: number
  license_request?: number
  licensed?: number
}

interface IShortlistedSubmissionIdentities {
  identity_id: number
  shortlisted_submissions_count: {
    video: number
    story: number
    post: number
  }
}

export interface IShortlistedSubmissionStats {
  collective_reach: number
  cost_per_engagement_cents: number
  cost_per_engagement_currency: string
  engagement: number
  projected_spend_cents: number
  projected_spend_currency: string
  tax_display_name: string
  identities?: ReadonlyArray<IShortlistedSubmissionIdentities>
  has_proxy_submissions?: boolean
}

export interface ISubmissionsMetadata {
  stats: ISubmissionsMetadataStats
  shortlisted_submissions_stats?: IShortlistedSubmissionStats
  published_submissions_stats?: object
  approved_submissions_stats?: object
  total_value?: {
    inbox_cents: number
    inbox_currency: string
  }
}

export interface ISubmissionResults {
  items: ReadonlyArray<ISubmission>
  metadata: ISubmissionsMetadata
}

export interface IIdentityAnalytics {
  likes: number
  comments: number
  engagement: number
  avg_engagement: number
  avg_engagement_pct: number
}

export interface IPerformanceAnalytics {
  likes?: number
  comments?: number
  video_views?: number
  retweets?: number
  shares?: number
  engagement: number
  engagement_pct: number
}

export interface ISubmissionFrame extends IMediaObject {
  creator_note?: string
  frame_number?: number
}

export interface ISubmissionsState {
  readonly errors?: IResponseError
  readonly isFetching: boolean
  readonly isPreApproving: boolean
  readonly isApproving?: boolean
  readonly isDeclining?: boolean
  readonly isRescheduling?: boolean
  readonly isRequestingLicense?: boolean
  readonly isShortlisting: Record<number, boolean>
  readonly isTransferringMedia?: boolean
  readonly isRequestingBrandedContentAd?: boolean
  readonly submissionsById: Record<number, ISubmission>
  readonly submissionIdsByFilter: Record<number, Record<string, ReadonlyArray<number>>>
  readonly submissionLinksByFilter: Record<number, Record<string, ReadonlyArray<IHateoasLink>>>
  readonly submissionsMetadataByCampaignId: Record<number, ISubmissionsMetadata>
  readonly selectedSortByFilter: Record<string, string>
  readonly isFetchingInfluencerSubmissions?: boolean
  readonly submissionsByInfluencerId: Record<number, Record<number, ReadonlyArray<number>>>
  readonly submissionsByInfluencerIdentityId: Record<number, Record<number, ReadonlyArray<number>>>
  readonly pendingRequestsByInfluencerIdentityId: ReadonlyArray<number>
  readonly brandFanGroupIdsByIdentityId: Record<number, ReadonlyArray<number>>
}

export interface IApproveRequestResponse {
  payment_intent_id: string
  payment_intent_status: string
  client_secret?: string
  submission?: ISubmission
  links: ReadonlyArray<IHateoasLink>
}

export interface IBrandedContentAdRequest {
  status: BrandedContentAdStatusTypes
  duration_in_days: number
  amount_cents: number
  amount_currency: string
  auth_code?: string
  readonly expires_at?: string
  receipt_number?: string
}

export interface IPromoteAsAdParams {
  duration_in_days: number
  amount: number
  interested_in_media_package?: boolean
}

export enum SubmissionsActionTypes {
  FETCH_REQUEST = 'submissions/FETCH_REQUEST',
  FETCH_SUCCESS = 'submissions/FETCH_SUCCESS',
  FETCH_ERROR = 'submissions/FETCH_ERROR',
  CLEAR_ERRORS = 'submissions/CLEAR_ERROR',
}

export enum SubmissionActionTypes {
  FETCH_REQUEST = 'submission/FETCH_REQUEST',
  FETCH_SUCCESS = 'submission/FETCH_SUCCESS',
  FETCH_ERROR = 'submission/FETCH_ERROR',
}

export enum ShortlistActionTypes {
  SHORTLIST_REQUEST = 'submission/SHORTLIST_REQUEST',
  SHORTLIST_SUCCESS = 'submission/SHORTLIST_SUCCESS',
  SHORTLIST_ERROR = 'submission/SHORTLIST_ERROR',
  UNSHORTLIST_REQUEST = 'submission/UNSHORTLIST_REQUEST',
  UNSHORTLIST_SUCCESS = 'submission/UNSHORTLIST_SUCCESS',
  UNSHORTLIST_ERROR = 'submission/UNSHORTLIST_ERROR',
}

export enum ApproveActionTypes {
  APPROVE_REQUEST = 'submission/APPROVE_REQUEST',
  APPROVE_SUCCESS = 'submission/APPROVE_SUCCESS',
  APPROVE_ERROR = 'submission/APPROVE_ERROR',
}

export enum PreApproveActionTypes {
  PREAPPROVE_REQUEST = 'submission/PREAPPROVE_REQUEST',
  PREAPPROVE_SUCCESS = 'submission/PREAPPROVE_SUCCESS',
  PREAPPROVE_ERROR = 'submission/PREAPPROVE_ERROR',
}

export enum DeclineActionTypes {
  DECLINE_REQUEST = 'submission/DECLINE_REQUEST',
  DECLINE_SUCCESS = 'submission/DECLINE_SUCCESS',
  DECLINE_ERROR = 'submission/DECLINE_ERROR',
}

export enum BulkDeclineActionTypes {
  BULK_DECLINE_REQUEST = 'submission/BULK_DECLINE_REQUEST',
  BULK_DECLINE_SUCCESS = 'submission/BULK_DECLINE_SUCCESS',
  BULK_DECLINE_ERROR = 'submission/BULK_DECLINE_ERROR',
}

export enum RescheduleActionTypes {
  RESCHEDULE_REQUEST = 'submission/RESCHEDULE_REQUEST',
  RESCHEDULE_SUCCESS = 'submission/RESCHEDULE_SUCCESS',
  RESCHEDULE_ERROR = 'submission/RESCHEDULE_ERROR',
}

export enum RequestLicenseActionTypes {
  LICENSE_REQUEST = 'submission/LICENSE_REQUEST',
  LICENSE_SUCCESS = 'submission/LICENSE_SUCCESS',
  LICENSE_ERROR = 'submission/LICENSE_ERROR',
}

export enum InfluencerSubmissionsActionTypes {
  FETCH_REQUEST = 'influencerSubmissions/FETCH_REQUEST',
  FETCH_SUCCESS = 'influencerSubmissions/FETCH_SUCCESS',
  FETCH_ERROR = 'influencerSubmissions/FETCH_ERROR',
}

export enum SubmissionsByInfluencerIdentityActionTypes {
  FETCH_REQUEST = 'submissionsByInfluencer/FETCH_REQUEST',
  FETCH_SUCCESS = 'submissionsByInfluencer/FETCH_SUCCESS',
  FETCH_ERROR = 'submissionsByInfluencer/FETCH_ERROR',
}

export enum ViewInfluencerSocialActionTypes {
  VIEW_SOCIAL_REQUEST = 'submission/VIEW_INFLUENCER_SOCIAL_REQUEST',
  VIEW_SOCIAL_ERROR = 'submission/VIEW_INFLUENCER_SOCIAL_ERROR',
}

export enum PromoteAsAdActionTypes {
  PROMOTE_AS_AD_REQUEST = 'submission/PROMOTE_AS_AD_REQUEST',
  PROMOTE_AS_AD_SUCCESS = 'submission/PROMOTE_AS_AD_SUCCESS',
  PROMOTE_AS_AD_ERROR = 'submission/PROMOTE_AS_AD_ERROR',
}

export enum UpdateFilterSubmissionIdsTypes {
  REMOVE = 'submission/REMOVE',
}

export enum FetchSubmissionBrandFansMembershipActionTypes {
  REQUEST = 'submission/FETCH_BRAND_FANS_MEMBERSHIP_REQUEST',
  SUCCESS = 'submission/FETCH_BRAND_FANS_MEMBERSHIP_SUCCESS',
  ERROR = 'submission/FETCH_BRAND_FANS_MEMBERSHIP_ERROR',
}
