import styled, { css } from 'styled-components'

import { transitionTime } from '../../Form.styled'

export const BackToScheduleButton = styled.div`
  top: -6rem;
  left: 0;
  width: 100%;
  position: absolute;
  text-align: center;
  transition: transform ${transitionTime}, opacity ${transitionTime};
  transform: translate(0, 3.125rem);
  overflow: hidden;
  opacity: 0;
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      transform: none;
    `};
`
