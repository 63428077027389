import { call, put, select, take, takeEvery } from 'redux-saga/effects'

import { ToastTemplateTicked } from '@components/UI/ToastTemplate'
import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  FetchBrandManagerIdentities,
  fetchIdentities,
  IBrandManagerIdentity,
  RemoveBrandManagerIdentity,
  removeIdentity,
  removeIdentityError,
  removeIdentitySuccess,
  selectBrandManagerIdentity,
  selectBrandManagerIdentityLink,
} from '@store/brandManagerIdentities'
import { Toaster } from '@tribegroup'
import { networkRequest } from '@utils'

export function* handleRemoveIdentity(action: ReturnType<typeof removeIdentity>) {
  try {
    const brandManagerIdentityId = action.payload
    const authToken: string = yield select(selectAuthToken)
    const removeIdentityLink: IHateoasLink = yield select(
      selectBrandManagerIdentityLink,
      brandManagerIdentityId,
      'remove',
    )
    yield call(
      networkRequest,
      removeIdentityLink.method,
      removeIdentityLink.href,
      undefined,
      authToken,
    )

    const brandManagerIdentity: IBrandManagerIdentity = yield select(
      selectBrandManagerIdentity,
      brandManagerIdentityId,
    )

    yield put(fetchIdentities())
    yield take(FetchBrandManagerIdentities.FETCH_SUCCESS)
    yield put(removeIdentitySuccess(brandManagerIdentity))

    yield call(Toaster.createToast, ToastTemplateTicked, {
      i18nKey: 'connectedAccounts.toast.removeSuccess',
      values: { name: brandManagerIdentity.display_name ?? brandManagerIdentity.handle },
    })
  } catch (error) {
    yield put(removeIdentityError(error))
  }
}

export function* watchRemoveIdentityRequest() {
  yield takeEvery(RemoveBrandManagerIdentity.REMOVE_REQUEST, handleRemoveIdentity)
}
