import { FormattedMessage } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import Flex from 'styled-flex-component'

import { FooterButtonWrapper } from '@components/UI/Confirmation/Confirmation.styled'
import { PageRoutes } from '@enums'
import { useModal } from '@hooks/useModal'
import Theme from '@theme'
import { Button, Container, H3, Modal, Text } from '@tribegroup'

export const BrandedContentAdConfirmationNoAccount = () => {
  const { isOpen, hideModal } = useModal()
  const history = useHistory()
  const location = useLocation()
  const fromRoute = `?from=${encodeURIComponent(`${location.pathname}${location.search}`)}`

  const onclick = () => {
    hideModal()

    history.push({
      pathname: `/${PageRoutes.ConnectedAccounts}`,
      search: fromRoute,
    })
  }

  return (
    <Modal reactModalProps={{ isOpen: Boolean(isOpen), shouldFocusAfterRender: false }} width={20}>
      <Container bottomOuterSpacing={0.5}>
        <H3 color={Theme.grey900Color}>
          <FormattedMessage id="connectedAccounts.boost.confirmation.noConnectedAccount.title" />
        </H3>
      </Container>

      <Container bottomInnerSpacing={3.625} width={16.25}>
        <Text color={Theme.grey800Color}>
          <FormattedMessage id="connectedAccounts.boost.confirmation.noConnectedAccount.paragraph" />
          <Container topInnerSpacing={0.5} width={15.75}>
            <Text color={Theme.grey800Color}>
              <FormattedMessage id="connectedAccounts.boost.confirmation.collaborator" />
            </Text>
          </Container>
        </Text>
      </Container>

      <Flex justifyEnd>
        <FooterButtonWrapper>
          <Button scheme="primary" quiet onClick={hideModal} small>
            <FormattedMessage id="core.text.cancel" />
          </Button>
        </FooterButtonWrapper>
        <FooterButtonWrapper>
          <Button scheme="primary" onClick={onclick} small>
            <FormattedMessage id="core.text.continue" />
          </Button>
        </FooterButtonWrapper>
      </Flex>
    </Modal>
  )
}

export default BrandedContentAdConfirmationNoAccount
