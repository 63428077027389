import React from 'react'

import { FormattedMessage } from 'react-intl'

import { BrandFanFilters } from '@enums'
import Theme from '@theme'
import { Container, H5, Text } from '@tribegroup'

interface IProps {
  hasEmpyList?: boolean
  type?: string
}

export const IdentitiesPageSubHeader: React.FC<IProps> = ({ hasEmpyList, type }) => (
  <React.Fragment>
    <Text
      color={Theme.grey800Color}
      uppercase
      customFontSize={1}
      lineHeight={1.5}
      customletterSpacing="1.5px"
      light
    >
      <FormattedMessage id={`brandFans.header.description.${type}`} />
    </Text>

    {!hasEmpyList && (
      <Container topOuterSpacing={0.5}>
        <Text italic color={Theme.grey800Color} light customFontSize={1}>
          <FormattedMessage id={`brandFans.header.subDescription.${type}`} />
        </Text>
      </Container>
    )}
  </React.Fragment>
)

export const BrandFanGroupsSubHeader: React.FC<IProps> = ({ hasEmpyList }) => {
  if (hasEmpyList) {
    return (
      <H5 color={Theme.defaultColor}>
        <FormattedMessage id={`brandFans.header.description.${BrandFanFilters.Groups}`} />
      </H5>
    )
  }
  return (
    <Text italic color={Theme.grey800Color} light customFontSize={1}>
      <FormattedMessage id={`brandFans.header.subDescription.${BrandFanFilters.Groups}`} />
    </Text>
  )
}

export const EducationDescriptionSubHeader: React.FC<IProps> = ({ type }) => (
  <Text
    color={Theme.grey800Color}
    uppercase
    customFontSize={1}
    lineHeight={1.5}
    customletterSpacing="1.5px"
    light
  >
    <FormattedMessage id={`brandFans.header.description.${type}`} />
  </Text>
)
