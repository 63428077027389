import { call, put, select, takeLatest } from 'redux-saga/effects'

import { selectAuthToken } from '@store/auth'
import { selectPublishedSummaryLink } from '@store/campaigns'
import {
  fetchPublishedSummary,
  fetchPublishedSummaryError,
  fetchPublishedSummarySuccess,
  IPublishedSummary,
  PublishedSummaryActionTypes,
} from '@store/publishedSummary'
import { networkRequest } from '@utils'

export function* handleFetchPublishedSummary(action: ReturnType<typeof fetchPublishedSummary>) {
  const campaignId = action.payload

  try {
    const authToken = yield select(selectAuthToken)
    const publishedSummaryLink = yield select(selectPublishedSummaryLink, campaignId)

    const publishedSummary: IPublishedSummary = yield call(
      networkRequest,
      publishedSummaryLink.method,
      publishedSummaryLink.href,
      undefined,
      authToken,
    )

    yield put(fetchPublishedSummarySuccess(campaignId, publishedSummary))
  } catch (error) {
    yield put(fetchPublishedSummaryError(error))
  }
}

export function* watchFetchPublishedSummary() {
  yield takeLatest(PublishedSummaryActionTypes.FETCH_REQUEST, handleFetchPublishedSummary)
}
