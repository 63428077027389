import React from 'react'

import { FormattedMessage } from 'react-intl'

import CreatorDetails from '@components/Creator/Details'
import CreatorPortfolio from '@components/Creator/Portfolio'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import SinglePageContainer from '@components/UI/SinglePageContainer/SinglePageContainer'
import { SimplifiedTitleBar } from '@components/UI/TitleBar'
import { ModalProvider } from '@context/Modal'
import { withConnected404Page } from '@hocs'
import Error404Page from '@pages/Error/404'
import { selectCreatorBioError } from '@store/creator-bio'
import Theme from '@theme'
import { Text } from '@tribegroup'

const TitleBar = () => (
  <SimplifiedTitleBar>
    <Text color={Theme.defaultColor} customFontSize={1}>
      <FormattedMessage id="creatorPortfolio.bio.header" />
    </Text>
  </SimplifiedTitleBar>
)

export const CreatorBioPage: React.FC = () => (
  <MainLayout data-testid="creator-bio-page">
    <ModalProvider>
      <HeadTag id="creatorPortfolio.bio.header" />
      <TitleBar />
      <SinglePageContainer>
        <CreatorDetails />
        <CreatorPortfolio />
      </SinglePageContainer>
    </ModalProvider>
  </MainLayout>
)

const ErrorComponent = () => (
  <Error404Page
    errorMessageId="creatorPortfolio.bio.notFoundError"
    headerTagId="creatorPortfolio.bio.header"
    titleId="creatorPortfolio.bio.404.title"
    TitleBarComponent={TitleBar}
  />
)

export default withConnected404Page({
  component: ErrorComponent,
  selector: selectCreatorBioError,
})(CreatorBioPage)
