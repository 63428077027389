import React from 'react'

import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { IModalComponentProps } from '@context/Modal'
import { useIsMounted } from '@hooks/useIsMounted'
import { IApplicationState } from '@store'
import {
  clearBrandFansErrors,
  IBrandFanEditableFields,
  selectBrandFanGroupName,
  selectBrandFansErrorCode,
  selectIsUpdatingBrandFanGroup,
} from '@store/brandFans'
import { updateBrandFanGroup } from '@store/brandFans/actions/updateBrandFanGroup'
import Theme from '@theme'
import { Button, Container, H1, Header, Modal, TextField } from '@tribegroup'
import BrandFansGroupModalErrorMessage from './ErrorMessage'

interface IProps extends IModalComponentProps {
  groupId: number
}

interface IInternalProps extends IProps {
  groupName: string
  isUpdatingBrandFanGroup: boolean
  errorCode?: string
  updateBrandFanGroup: typeof updateBrandFanGroup
  clearBrandFansErrors: typeof clearBrandFansErrors
}

const BrandFansGroupModalRename: React.FC<IInternalProps> = ({
  groupId,
  isOpen = false,
  onRequestClose,
  groupName,
  isUpdatingBrandFanGroup,
  errorCode,
  updateBrandFanGroup: updateBrandFanGroupAction,
  clearBrandFansErrors: clearBrandFansErrorsAction,
}) => {
  const { register, handleSubmit, errors, formState } = useForm()

  const onSubmit = (params: IBrandFanEditableFields) => {
    updateBrandFanGroupAction(groupId, params)
  }

  const hasError = Boolean(errorCode)

  const isMounted = useIsMounted()
  React.useEffect(() => {
    if (isMounted && !isUpdatingBrandFanGroup && !hasError) {
      onRequestClose()
    }
  }, [isUpdatingBrandFanGroup])

  const onClearErrors = () => {
    if (hasError) {
      clearBrandFansErrorsAction()
    }
  }
  React.useEffect(() => onClearErrors)

  return (
    <Modal reactModalProps={{ isOpen }} width={33.75} complex>
      <form data-testid="brand-fans-group-modal-rename-form" onSubmit={handleSubmit(onSubmit)}>
        <H1 color={Theme.grey900Color} bottomOuterSpacing={1.75} capitalize>
          <FormattedMessage id="brandFans.groups.actions.renameGroup" />
        </H1>
        <Container bottomOuterSpacing={4.375} relative>
          <TextField
            innerRef={register({
              required: true,
            })}
            defaultValue={groupName}
            maxLength={60}
            fullWidth
            thick
            name="name"
            onChange={onClearErrors}
            autoFocus
            data-testid="brand-fan-group-modal-name"
          />
          {errors.name && (
            <BrandFansGroupModalErrorMessage>
              <FormattedMessage id="brandFans.error.brand_fan_group.update.name" />
            </BrandFansGroupModalErrorMessage>
          )}
          {hasError && (
            <BrandFansGroupModalErrorMessage>
              <FormattedMessage id={`brandFans.error.${errorCode}`} />
            </BrandFansGroupModalErrorMessage>
          )}
        </Container>
        <Flex justifyEnd alignCenter>
          <Button
            width={8.75}
            scheme="primary"
            height={3}
            outlined
            onClick={onRequestClose}
            disabled={isUpdatingBrandFanGroup}
            type="button"
          >
            <Header cta>
              <FormattedMessage id="core.text.cancel" />
            </Header>
          </Button>
          <Button
            width={8.75}
            scheme="primary"
            height={3}
            leftOuterSpacing={0.75}
            data-testid="brand-fans-group-update"
            disabled={isUpdatingBrandFanGroup || !formState.isDirty}
            loading={isUpdatingBrandFanGroup}
          >
            <Header cta>
              <FormattedMessage id="core.text.update" />
            </Header>
          </Button>
        </Flex>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state: IApplicationState, { groupId }: IProps) => ({
  groupName: selectBrandFanGroupName(state, groupId),
  isUpdatingBrandFanGroup: selectIsUpdatingBrandFanGroup(state),
  errorCode: selectBrandFansErrorCode(state),
})

const mapDispatchToProps = {
  updateBrandFanGroup,
  clearBrandFansErrors,
}

export { BrandFansGroupModalRename }
export default compose<IInternalProps, IProps>(connect(mapStateToProps, mapDispatchToProps))(
  BrandFansGroupModalRename,
)
