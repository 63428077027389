import { createSelector } from 'reselect'

import { IApplicationState } from '@store'
import {
  ISubmission,
  selectAmountCents,
  selectIsVideoSocialSubmission,
  selectSubmission,
} from '@store/submissions'
import { isEmpty } from '@utils'
import { ISubmissionPerformance } from './types'

// TODO remove unused selectors once we migrated fb and twitter analytics

export const selectSubmissionPerformance = (state: IApplicationState, submissionId: number) =>
  state.performance.submissionPerformanceById[submissionId] || {}

export const selectIsFetchingSubmissionPerformance = (state: IApplicationState) =>
  state.performance.isFetching

export const selectAnalytics = createSelector(
  selectSubmissionPerformance,
  selectIsVideoSocialSubmission,
  selectSubmission,
  (submissionPerformance: ISubmissionPerformance, isVideo: boolean, submission: ISubmission) => {
    if (isEmpty(submissionPerformance)) {
      return undefined
    }

    if (isVideo && submission.analytics) {
      const { likes, comments } = submission.analytics
      return { ...submissionPerformance, likes, comments }
    }

    return submissionPerformance
  },
)

export const selectHasAnalytics = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => {
    const hasValues =
      analytics &&
      Object.values(analytics).some((field) => {
        if (field && typeof field === 'object') {
          return Boolean(field.value)
        }
        return Boolean(field)
      })

    return hasValues
  },
)

export const selectAnalyticsFollowers = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics && analytics.identity_followers_count,
)

export const selectAnalyticsComments = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.comments,
)

export const selectAnalyticsLikes = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.likes,
)

export const selectAnalyticsVideoViews = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.video_views,
)

export const selectAnalyticsShares = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.shares,
)

export const selectAnalyticsRetweets = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.retweets,
)

export const selectAnalyticsEngagement = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.engagement,
)

export const selectAnalyticsEngagementPercentage = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.engagement_pct,
)

export const selectAnalyticsCostPerEngagement = createSelector(
  selectAnalyticsEngagement,
  selectAmountCents,
  selectAnalytics,
  (engagement: number | null, subtotalCents: number | null, analytics: ISubmissionPerformance) => {
    if (analytics?.cpe || analytics?.cost_per_engagement) {
      return analytics?.cpe || analytics?.cost_per_engagement
    }

    if (!engagement || !subtotalCents) {
      return undefined
    }

    const subtotal = subtotalCents / 100
    return subtotal / engagement
  },
)

export const selectAnalyticsImpressions = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance): number | null | undefined => analytics?.impressions,
)

export const selectAnalyticsUniqueViews = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.unique_views,
)

export const selectAnalyticsStoryViewRate = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.story_view_rate,
)

export const selectAnalyticsActions = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.actions,
)

export const selectAnalyticsReach = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.reach,
)

export const selectAnalyticsReachPercentage = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.reach_pct,
)

export const selectAnalyticsCPM = createSelector(
  selectAnalyticsImpressions,
  selectAmountCents,
  (impressions: number | null, subtotalCents: number | null) => {
    if (!impressions || !subtotalCents) {
      return undefined
    }

    const subTotal = subtotalCents / 100

    return (subTotal / impressions) * 1000
  },
)

export const selectAnalyticsVideoViewPercentage = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.video_views_pct,
)

export const selectAnalyticsCostPerVideoView = createSelector(
  selectAnalyticsVideoViews,
  selectAmountCents,
  (videoViews: number | null, subtotalCents: number | null) => {
    if (!videoViews || !subtotalCents) {
      return undefined
    }

    const subtotal = subtotalCents / 100
    return subtotal / videoViews
  },
)

// @TODO legacy will remove on a separate pr
export const selectAnalyticsCostPerView = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.cost_per_view,
)

export const selectAnalyticsCostPerViewCurrency = createSelector(
  selectAnalytics,
  (analytics: ISubmissionPerformance) => analytics?.cost_per_view_currency,
)
