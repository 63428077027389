import capitalize from 'lodash.capitalize'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import InfluencerIdentityRowLabelTooltip from '@components/InfluencerIdentity/Row/LabelTooltip'
import InfluencerIdentityRowValueAndLabel from '@components/InfluencerIdentity/Row/ValueAndLabel'
import { IInfluencerIdentityIdContextProps } from '@context/InfluencerIdentityId'
import { withContextInfluencerIdentityId } from '@hocs'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { selectInfluencerIdentityPostCount } from '@store/influencerIdentities'
import { selectCampaignId } from '@store/router'

interface IInternalProps {
  postCount: number
}

const InfluencerIdentityRowPerformancePostCount = ({ postCount }: IInternalProps) => {
  const { currentFilter } = hooks.useInboxFilter()
  return (
    <InfluencerIdentityRowValueAndLabel
      value={<FormattedNumber value={postCount} />}
      label={
        <InfluencerIdentityRowLabelTooltip
          title={<FormattedMessage id="submission.performance.post" values={{ postCount }} />}
          body={
            <FormattedMessage
              id="submission.performance.post.body"
              values={{ filter: capitalize(currentFilter) }}
            />
          }
        >
          <FormattedMessage id="submission.performance.post" values={{ postCount }} />
        </InfluencerIdentityRowLabelTooltip>
      }
      width={4.375}
      name="influencer-identity-row-post-count"
    />
  )
}

interface IStateProps extends RouteComponentProps, IInfluencerIdentityIdContextProps {}

const mapStateToProps = (
  state: IApplicationState,
  { match, contextInfluencerIdentityId }: IStateProps,
) => {
  const campaignId = selectCampaignId(match)!
  return {
    postCount: selectInfluencerIdentityPostCount(state, campaignId, contextInfluencerIdentityId),
  }
}

export { InfluencerIdentityRowPerformancePostCount }

export default compose<IInternalProps, {}>(
  withContextInfluencerIdentityId,
  withRouter,
  connect(mapStateToProps),
)(InfluencerIdentityRowPerformancePostCount)
