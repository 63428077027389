import React from 'react'

import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import HeadTag from '@components/UI/HeadTag'
import { BillingTypes } from '@enums'
import { IApplicationState } from '@store'
import { selectBillingType } from '@store/router'

interface IInternalProps {
  billingType: BillingTypes
}

const BillingHeaderTag: React.FC<IInternalProps> = ({ billingType }) => {
  const headerId =
    billingType === BillingTypes.Campaigns
      ? 'core.text.billing.campaignBillingHistory'
      : 'core.text.purchases.contentLibraryPurchases'
  return <HeadTag id={headerId} />
}

const mapsTateToProps = (state: IApplicationState, { location }: RouteComponentProps) => ({
  billingType: selectBillingType(location),
})

export { BillingHeaderTag }
export default compose<IInternalProps, {}>(withRouter, connect(mapsTateToProps))(BillingHeaderTag)
