import { NotesActionTypes } from '@store/notes'

export const fetchNotes = (submissionId: number, campaignId: number) => ({
  payload: { submissionId, campaignId },
  type: NotesActionTypes.FETCH_REQUEST,
})

export const fetchNotesSuccess = (submissionId: number, result) => ({
  payload: { submissionId, result },
  type: NotesActionTypes.FETCH_SUCCESS,
})

export const fetchNotesError = error => ({
  payload: error,
  type: NotesActionTypes.FETCH_ERROR,
})
