import styled from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container, Icon } from '@tribegroup'

export const FeedbackProfileAvatarWrapperStyled = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  margin-top: 1.5rem;
`

export const FeedbackProfileIndicatorStyled = styled(Flex)`
  border-radius: 1rem;
  margin-left: 0.3125rem;
  border: 1px solid ${Theme.secondary100Color};
  height: 1rem;
  min-width: 1rem;
  position: relative;
  top: 0.0625rem;
`

export const FeedbackProfileIndicatorPreviouslyPurchasedIcon = styled(Icon)`
  margin-left: 0 0.125rem;
`

export const FeedbackProfileInitials = styled(Container)`
  border: 1px dashed ${Theme.primaryColor};
  border-radius: 50%;
  height: 100%;
`
