import { FormGroup, FormGroupRow, FormValidationMessage, Text } from '@tribegroup'

export const FormError = ({ children }) => (
  <FormGroupRow spaceBetween={1}>
    <FormGroup>
      <FormValidationMessage>
        <Text small>{children}</Text>
      </FormValidationMessage>
    </FormGroup>
  </FormGroupRow>
)

export default FormError
