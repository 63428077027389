import { INBOX_FILTER, SHORTLISTED_FILTER } from '@constants'
import { UpdateFilterSubmissionIdsTypes } from '@store/submissions'

export const updateFilterSubmissionIds = (
  campaignId: number,
  submissionId: number,
  filters: ReadonlyArray<string> = [INBOX_FILTER, SHORTLISTED_FILTER],
) => ({
  payload: { campaignId, submissionId, filters },
  type: UpdateFilterSubmissionIdsTypes.REMOVE,
})
