import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import ActionIcon from '@components/UI/ActionIcon'
import { ICampaignContextProps } from '@context/Campaign/Provider'
import { withCampaign } from '@hocs'
import { IApplicationState } from '@store'
import { selectIsShortlisting, unshortlistSubmissionRequest } from '@store/submissions'
import Theme from '@theme'
import { getSubmissionFilter, getSubmissionSortBy } from '@utils'

interface IActionBarIconUnshortlistProps {
  submissionId: number
  refreshSubmissionsOnAction?: boolean
}

interface IInternalProps
  extends IActionBarIconUnshortlistProps,
    InjectedIntlProps,
    RouteComponentProps,
    ICampaignContextProps {
  unshortlistSubmissionRequest: typeof unshortlistSubmissionRequest
  filter?: string
  sortBy?: string
}

export const ActionBarIconUnshortlist = ({
  intl,
  filter,
  sortBy,
  submissionId,
  campaign,
  unshortlistSubmissionRequest: unshortlistSubmission,
  refreshSubmissionsOnAction = true,
}: IInternalProps) => {
  const isShortlisting = useSelector((state: IApplicationState) =>
    selectIsShortlisting(state, submissionId),
  )

  const onUnshortlistSubmission = () => {
    unshortlistSubmission(campaign.id, submissionId, {
      filter,
      sortBy,
      refreshSubmissions: refreshSubmissionsOnAction,
    })
  }

  return (
    <ActionIcon
      glyph="heart-solid"
      color={Theme.primaryColor}
      size={1.5}
      onClick={onUnshortlistSubmission}
      tooltip={intl.formatMessage({
        id: 'submission.card.action.removefromshortlist',
      })}
      elementName="unshortlist"
      disabled={isShortlisting}
    />
  )
}

const mapDispatchToProps = {
  unshortlistSubmissionRequest,
}

const mapStateToProps = (state: IApplicationState, { history }) => ({
  filter: getSubmissionFilter(history.location),
  sortBy: getSubmissionSortBy(history.location),
})

export default compose<IInternalProps, IActionBarIconUnshortlistProps>(
  injectIntl,
  withRouter,
  withCampaign,
  connect(mapStateToProps, mapDispatchToProps),
)(ActionBarIconUnshortlist)
