import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import { selectMediaObjectsLink } from '@store/me'
import {
  fetchMediaObject,
  FetchMediaObjectActionTypes,
  fetchMediaObjectError,
  fetchMediaObjectSuccess,
} from '@store/mediaObjects'
import { networkRequest } from '@utils'

export function* handleFetchMediaObject(action: ReturnType<typeof fetchMediaObject>) {
  try {
    const authToken: string = yield select(selectAuthToken)
    const submissionLink: IHateoasLink = yield select(selectMediaObjectsLink)

    const mediaObjectId = action.payload
    const mediaObject = yield call(
      networkRequest,
      submissionLink.method,
      submissionLink.href.replace('{media_object_id}', String(mediaObjectId)),
      undefined,
      authToken,
    )
    yield put(fetchMediaObjectSuccess(mediaObject))
  } catch (error) {
    yield put(fetchMediaObjectError(error))
  }
}

export function* watchFetchMediaObjectRequest() {
  yield takeLatest(FetchMediaObjectActionTypes.FETCH_REQUEST, handleFetchMediaObject)
}
