import React from 'react'

import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { compose } from 'recompose'

import { SideBarBlockItem } from '@components/UI/SideBar'
import { TrackedRoute } from '@components/UI/TrackedRoute'
import { IMobileMenuControlsContextProps } from '@context/MobileMenuControls'
import { BrandFanFilters, EventTrackingNames, PageRoutes } from '@enums'
import { withMobileMenuControls } from '@hocs'
import { IBrandFanStats } from '@store/brandFans'
import { isActiveMenu } from '@utils/urls'

interface IProps {
  stats: IBrandFanStats
}

interface IInternalProps extends IProps, IMobileMenuControlsContextProps {}

const BrandFansFiltersGroups: React.FC<IInternalProps> = ({ stats, hideMobileMenu }) => {
  const { pathname } = useLocation()
  const count = stats[BrandFanFilters.Groups]
  return (
    <TrackedRoute
      eventName={EventTrackingNames.BrandFansViewGroupsList}
      properties={{ number_of_groups: count }}
    >
      <SideBarBlockItem
        key="brand-fan-groups"
        to={{
          pathname: PageRoutes.BrandFanGroups,
        }}
        count={count}
        text={<FormattedMessage id="brandFans.sidebar.filter.groups" />}
        active={isActiveMenu(pathname, PageRoutes.BrandFanGroups)}
        onClick={hideMobileMenu}
      />
    </TrackedRoute>
  )
}

export { BrandFansFiltersGroups }
export default compose<IInternalProps, IProps>(withMobileMenuControls)(BrandFansFiltersGroups)
