import { createReducer } from '@reduxjs/toolkit'
import { castDraft } from 'immer'

import { AuthActionTypes } from '@store/auth'
import {
  fetchPurchaseOrders,
  fetchPurchaseOrdersSuccess,
  IPurchaseOrderState,
} from '@store/purchaseOrders'
import { fetchPurchaseOrdersError } from './actions/list'

const initialState: IPurchaseOrderState = {
  isFetching: false,
  purchaseOrders: [],
  links: [],
}

export const purchaseOrderReducer = createReducer<IPurchaseOrderState>(initialState, (builder) => {
  builder
    .addCase(fetchPurchaseOrders, (state) => {
      state.isFetching = true
      state.hasError = false
    })
    .addCase(fetchPurchaseOrdersSuccess, (state, { payload }) => {
      const { links, items, metadata } = payload
      state.isFetching = false
      state.purchaseOrders = items
      state.metadata = metadata
      state.links = castDraft(links)
    })
    .addCase(fetchPurchaseOrdersError, (state) => {
      state.isFetching = false
      state.hasError = true
    })
    .addMatcher(
      (action) => action.type === AuthActionTypes.SIGNOUT_SUCCESS,
      () => initialState,
    )
})
