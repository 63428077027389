import hooks from '@hooks'
import { selectSubmissionsFilters } from '@store/campaigns'
import { queryStringtoObject } from '@utils'

interface IUseInboxFilterResults {
  currentFilter: string
}

const useInboxFilter = () => {
  const { filter } = queryStringtoObject(hooks.useLocation().search) as { filter?: string }
  const { campaignId } = hooks.useParams() as { campaignId: string }
  const submissionFilters = hooks.useSelector((state) =>
    selectSubmissionsFilters(state, parseInt(campaignId, 10)),
  ) as string[]

  return {
    currentFilter: filter || submissionFilters?.[0] || 'inbox',
  } as IUseInboxFilterResults
}

export { useInboxFilter }
