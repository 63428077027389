import styled from 'styled-components'

import Theme from '@theme'
import { Container } from '@tribegroup'

const Root = styled.div`
  cursor: default;
  position: absolute;
  top: 0;
  right: 0;
  max-width: calc(100vw - 5.75rem);
  width: 21.75rem;
  z-index: 2;
  margin-right: 1.75rem;
  margin-top: -0.75rem;
  padding-right: 0.75rem;
`

const InnerContent = styled.div`
  background-color: ${Theme.whiteColor};
  width: auto;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 1.875rem 0 rgb(0 0 0 / 12%);
  padding: 1.75rem 1.5rem;

  &::after {
    border-bottom: 0.625rem solid transparent;
    border-top: 0.625rem solid transparent;
    border-left: 0.625rem solid ${Theme.whiteColor};
    right: 0.125rem;
    top: 0.9375rem;
    position: absolute;
    content: ' ';
  }
`

const BrandFansPill = styled.div`
  border-radius: 0.25rem;
  display: inline-flex;
  padding: 0.25rem;
  background-color: ${Theme.primary100Color};
`

const ListItem = styled.div`
  padding: 0.75rem 0;
`

const ListContainer = styled(Container)`
  ${ListItem} {
    border-bottom: ${Theme.grey300Color} 1px solid;

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
`

export { Root, BrandFansPill, ListContainer, ListItem, InnerContent }
