import { call, put, select, take, takeEvery } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { handleFetchInfluencerIdentity } from '@sagas/influencerIdentities/influencerIdentity'
import { IHateoasLink } from '@store'
import { selectSubmissionTrackingProps, trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import { fetchShortlistSummary } from '@store/shortlistSummary'
import {
  fetchSubmissions,
  ISubmission,
  selectUnshortlistLink,
  ShortlistActionTypes,
  SubmissionsActionTypes,
  unshortlistSubmissionError,
  unshortlistSubmissionRequest,
  unshortlistSubmissionSuccess,
} from '@store/submissions'
import { networkRequest } from '@utils'

export function* handleUnshortlist(action: ReturnType<typeof unshortlistSubmissionRequest>) {
  const { campaignId, submissionId } = action.payload

  try {
    const { filter, sortBy, refreshSubmissions, event } = action.meta
    const authToken: string = yield select(selectAuthToken)
    const unshortlistSubmissionLink: IHateoasLink = yield select(
      selectUnshortlistLink,
      submissionId,
    )
    const submission: ISubmission = yield call(
      networkRequest,
      unshortlistSubmissionLink.method,
      unshortlistSubmissionLink.href,
      undefined,
      authToken,
    )

    const properties = yield select(selectSubmissionTrackingProps, campaignId, submissionId)
    yield put(trackEvent(EventTrackingNames.UnshortlistSubmission, properties))

    yield put(unshortlistSubmissionSuccess(campaignId, submission, { event }))

    if (refreshSubmissions) {
      yield put(fetchSubmissions(campaignId, { filter, sortBy }))
      yield take(SubmissionsActionTypes.FETCH_SUCCESS)
    }
    yield put(fetchShortlistSummary(campaignId))
    yield call(handleFetchInfluencerIdentity, campaignId, submission.id)
  } catch (error) {
    yield put(unshortlistSubmissionError(error))
  }
}

export function* watchUnshortlistnRequest() {
  yield takeEvery(ShortlistActionTypes.UNSHORTLIST_REQUEST, handleUnshortlist)
}
