import { FormattedMessage } from 'react-intl'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchBrandFanGroups,
  selectBrandFanGroupUpdateLink,
  updateBrandFanGroup,
  UpdateBrandFanGroupActionTypes,
  updateBrandFanGroupError,
  updateBrandFanGroupSuccess,
} from '@store/brandFans'
import { Toaster } from '@tribegroup'
import { networkRequest } from '@utils'

export function* handleUpdateBrandFanGroup(action: ReturnType<typeof updateBrandFanGroup>) {
  try {
    const { groupId, params } = action.payload
    const authToken: string = yield select(selectAuthToken)
    const brandFanGroupsLink: IHateoasLink = yield select(selectBrandFanGroupUpdateLink, groupId)
    const brandFanGroup = yield call(
      networkRequest,
      brandFanGroupsLink.method,
      brandFanGroupsLink.href,
      params,
      authToken,
    )

    yield put(updateBrandFanGroupSuccess(brandFanGroup))
    yield put(fetchBrandFanGroups())
    yield call(Toaster.createToast, FormattedMessage, {
      id:
        params.description !== undefined
          ? 'brandFans.groups.actions.editDescription.toast'
          : 'brandFans.groups.actions.renameGroup.toast',
    })
  } catch (error) {
    yield put(updateBrandFanGroupError(error))
  }
}

export function* watchUpdateBrandFanGroupRequest() {
  yield takeLatest(UpdateBrandFanGroupActionTypes.UPDATE_REQUEST, handleUpdateBrandFanGroup)
}
