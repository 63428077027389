import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import { Hr } from '@components/UI/Hr/Hr.styled'
import Price from '@components/UI/Price/Price'
import { IApplicationState } from '@store/index'
import {
  selectTaxDisplayName,
  selectTransactedBrandedContentAdAmountCents,
  selectTransactedBrandedContentAdAmountCurrency,
} from '@store/submissions'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

interface IProps {
  submissionId: number
}

const TotalAmount = ({ submissionId }: IProps) => {
  const { currency, amount, taxName } = useSelector((state: IApplicationState) => {
    return {
      currency: selectTransactedBrandedContentAdAmountCurrency(state, submissionId),
      amount: selectTransactedBrandedContentAdAmountCents(state, submissionId),
      taxName: selectTaxDisplayName(state, submissionId),
    }
  })
  return (
    <Container topOuterSpacing={2.5}>
      <Hr />
      <Flex justifyBetween>
        <Text color={Theme.grey900Color} medium customFontSize={0.875}>
          <FormattedMessage id="core.text.totalCurrency" values={{ currency: currency }} />
        </Text>
        <Text color={Theme.grey900Color} medium customFontSize={0.875}>
          <Price currency={currency} quantity={amount} pattern="!###,###, " />{' '}
          <FormattedMessage
            id="submission.fullView.pricePanel.tax.text"
            values={{
              taxName,
            }}
          />
        </Text>
      </Flex>
    </Container>
  )
}

export default TotalAmount
