import React from 'react'

import Flex from 'styled-flex-component'

import Theme from '@theme'
import { H1 } from '@tribegroup'
import AuthFormStyled from './AuthForm.styled'

export interface IAuthForm {
  title: React.ReactNode
  subtitle?: React.ReactNode
}

const AuthForm = ({ title, subtitle, children }: any) => (
  <AuthFormStyled>
    <Flex center column alignCenter className="header">
      <img alt={title} width={40} src={require('@images/brand-icon.png')} />
      <H1 color={Theme.grey900Color}>{title}</H1>
      {subtitle}
    </Flex>
    {children}
  </AuthFormStyled>
)

export default AuthForm
