import React from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'recompose'

import { Information } from '@components/UI/Information'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { PAID_PARTNERSHIP_LEARN_HOW } from '@constants'
import { IDraftContextProps } from '@context/Draft'
import { EventTrackingNames } from '@enums'
import { withDraft } from '@hocs'
import { selectDraftTrackingProps, trackEvent } from '@store/analytics'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { Container, Header, Link, Text } from '@tribegroup'

const BuilderBriefChannelsInstagramPaidPartershipInformation: React.FC<IDraftContextProps> = ({
  draft,
}) => {
  const dispatch = useDispatch()
  const { draftAnalyticsProperties } = useSelector((state: IApplicationState) => ({
    draftAnalyticsProperties: selectDraftTrackingProps(state),
  }))
  const onEnterProp = () => {
    dispatch(
      trackEvent(EventTrackingNames.CampaignBuilderTooltip, {
        ...draftAnalyticsProperties,
        link_url: PAID_PARTNERSHIP_LEARN_HOW,
        tooltip_name: 'builder.brief.socialMedia.paidpartnership.tooltip',
        link_name: 'core.text.learnHow.withArrow',
      }),
    )
  }
  return (
    <Container leftOuterSpacing={0.25} data-testid="paid-partnership-information-tooltip">
      <Information width={19.0625} xPosition="right" onEnterProp={onEnterProp}>
        <Header small uppercase color={Theme.primaryColor} bottomOuterSpacing={0.5}>
          <FormattedMessage id="builder.brief.socialMedia.paidpartnership.title" />
        </Header>
        <Text color={Theme.defaultColor} block customFontSize={0.8125}>
          <FormattedMessage id="builder.brief.socialMedia.paidpartnership.tooltip" />
        </Text>
        <Container topOuterSpacing={0.5}>
          <ConnectedTrackedRoute
            draftId={draft.id}
            eventName={EventTrackingNames.CampaignBuilderEducationLink}
            additionalProperties={{
              link_url: PAID_PARTNERSHIP_LEARN_HOW,
              tooltip_name: 'builder.brief.socialMedia.paidpartnership.tooltip',
              link_name: 'core.text.learnHow.withArrow',
            }}
          >
            <Link href={PAID_PARTNERSHIP_LEARN_HOW} target="_blank" color={Theme.secondaryColor}>
              <Text color={Theme.primaryColor} customFontSize={0.8125}>
                <FormattedMessage id="core.text.learnHow.withArrow" />
              </Text>
            </Link>
          </ConnectedTrackedRoute>
        </Container>
      </Information>
    </Container>
  )
}

export { BuilderBriefChannelsInstagramPaidPartershipInformation }
export default compose<IDraftContextProps, {}>(withDraft)(
  BuilderBriefChannelsInstagramPaidPartershipInformation,
)
