import React, { useContext } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Grid } from 'react-styled-flexboxgrid'
import { compose } from 'recompose'

import { TRIBE_PRICING_PAGE_METRIC_SUMMARY_UTM } from '@constants'
import { CampaignContext } from '@context/Campaign'
import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import {
  selectVideosAvgReachPct,
  selectVideosAvgVideoViewsPct,
  selectVideosOverallCPM,
  selectVideosOverallCPV,
  selectVideosTotalEngagement,
  selectVideosTotalEngagementPct,
  selectVideosTotalFollowers,
  selectVideosTotalImpressions,
  selectVideosTotalPosts,
  selectVideosTotalReach,
  selectVideosTotalSpend,
  selectVideosTotalVideoViews,
} from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import AnalyticsPostCampaignReportMetric from '../Metric/Metric'
import { StyledCol, StyledRow } from '../Summary/Summary.styled'

type IInternalProps = ConnectedProps<typeof connector>

const externalLink = TRIBE_PRICING_PAGE_METRIC_SUMMARY_UTM
const productMetricGroup = 'reporting_instagram_videos'

const AnalyticsPostCampaignReportVideo: React.FC<IInternalProps> = ({
  totalPosts,
  totalSpend,
  totalFollowers,
  totalVideoViews,
  totalEngagement,
  totalEngagementPct,
  avgVideoViewsPct,
  overallCPV,
  totalReach,
  avgReachPct,
  totalImpressions,
  overallCPM,
}) => {
  const {
    campaign: { is_price_hidden: isPriceHidden },
  } = useContext(CampaignContext)

  return (
    <Grid fluid>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-posts-cell">
          <AnalyticsPostCampaignReportMetric title="ig.videos.totalVideoPosts" value={totalPosts} />
        </StyledCol>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="total-spend-cell">
            <AnalyticsPostCampaignReportMetric
              title="ig.videos.totalSpend"
              value={totalSpend.amount}
              currency={totalSpend.currency}
            />
          </StyledCol>
        )}
        <StyledCol xs={6} lg={3} data-testid="total-followers-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.videos.totalFollowers"
            value={totalFollowers}
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-video-views-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.videos.totalVideoViews"
            value={totalVideoViews}
          />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-engagement-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.videos.totalEngagement"
            value={totalEngagement}
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-engagement-pct-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.videos.totalEngagementPct"
            value={totalEngagementPct}
            showPercentSign
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="avg-video-views-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.videos.avgVideoViewPct"
            value={avgVideoViewsPct}
            showPercentSign
          />
        </StyledCol>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="overall-cpv-cell">
            <AnalyticsPostCampaignReportMetric
              title="ig.videos.overallCPV"
              value={overallCPV.amount}
              currency={overallCPV.currency}
            />
          </StyledCol>
        )}
      </StyledRow>
      <StyledRow>
        <StyledCol xs={6} lg={3} data-testid="total-reach-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.videos.totalReach"
            value={totalReach}
            externalLink={externalLink}
            productMetricGroup={productMetricGroup}
            productMetricValue="total_reach"
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="avg-reach-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.videos.avgReachPct"
            value={avgReachPct}
            showPercentSign
            externalLink={externalLink}
            productMetricGroup={productMetricGroup}
            productMetricValue="avg_reach_pct"
          />
        </StyledCol>
        <StyledCol xs={6} lg={3} data-testid="total-impressions-cell">
          <AnalyticsPostCampaignReportMetric
            title="ig.videos.totalImpressions"
            value={totalImpressions}
            externalLink={externalLink}
            productMetricGroup={productMetricGroup}
            productMetricValue="total_impressions"
          />
        </StyledCol>
        {!isPriceHidden && (
          <StyledCol xs={6} lg={3} data-testid="overall-cpm-cell">
            <AnalyticsPostCampaignReportMetric
              title="ig.videos.overallCPM"
              value={overallCPM.amount}
              currency={overallCPM.currency}
              externalLink={externalLink}
              productMetricGroup={productMetricGroup}
              productMetricValue="overall_cpm"
            />
          </StyledCol>
        )}
      </StyledRow>
    </Grid>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const campaignId = selectCampaignId(match)!
  const channel = PCRChannels.IGVideos

  return {
    totalPosts: selectVideosTotalPosts(state, campaignId, channel),
    totalSpend: selectVideosTotalSpend(state, campaignId, channel),
    totalFollowers: selectVideosTotalFollowers(state, campaignId, channel),
    totalVideoViews: selectVideosTotalVideoViews(state, campaignId, channel),
    totalEngagement: selectVideosTotalEngagement(state, campaignId, channel),
    totalEngagementPct: selectVideosTotalEngagementPct(state, campaignId, channel),
    avgVideoViewsPct: selectVideosAvgVideoViewsPct(state, campaignId, channel),
    overallCPV: selectVideosOverallCPV(state, campaignId, channel),
    totalReach: selectVideosTotalReach(state, campaignId, channel),
    avgReachPct: selectVideosAvgReachPct(state, campaignId, channel),
    totalImpressions: selectVideosTotalImpressions(state, campaignId, channel),
    overallCPM: selectVideosOverallCPM(state, campaignId, channel),
  }
}

const connector = connect(mapStateToProps)

export { AnalyticsPostCampaignReportVideo }

export default compose<IInternalProps, {}>(withRouter, connector)(AnalyticsPostCampaignReportVideo)
