import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { SubmissionBrandedContentAdModal } from '@components/Submission/Card/BrandedContentAd'
import { IModalContextProps } from '@context/Modal'
import { BrandedContentAdStatusTypes } from '@enums'
import { withModal } from '@hocs'
import { IApplicationState } from '@store'
import {
  selectTransactedBrandedContentAd,
  selectTransactedBrandedContentAdStatus,
} from '@store/submissions'
import { isEmpty } from '@utils'
import PendingCreatorApproval from './PendingCreatorApproval'
import PromoteAsAd from './PromoteAsAd'
import RightsAccepted from './RightsAccepted'
import RightsDeclined from './RightsDeclined'
import RightsExpired from './RightsExpired'
import RightsRequested from './RightsRequested'

interface IBrandedContentAdBarButtonProps {
  submissionId: number
}

interface IInternalProps extends IBrandedContentAdBarButtonProps, IModalContextProps {
  hasBcaTransaction: boolean
  bcaStatus: BrandedContentAdStatusTypes
  isTikTok?: boolean
}

export interface IButtonProps {
  onClick?: (event: React.SyntheticEvent) => void
  submissionId: number
}

const BrandedContentAdBarButton: React.FC<IInternalProps> = ({
  showModal,
  submissionId,
  bcaStatus,
  hasBcaTransaction,
}) => {
  const onOpenModal = () => showModal(SubmissionBrandedContentAdModal, { submissionId })
  const props: IButtonProps = {
    onClick: onOpenModal,
    submissionId,
  }

  if (!hasBcaTransaction) {
    return <PromoteAsAd {...props} />
  }

  switch (bcaStatus) {
    case BrandedContentAdStatusTypes.PendingCreatorApproval:
      return <PendingCreatorApproval {...props} />
    case BrandedContentAdStatusTypes.Requested: {
      return <RightsRequested {...props} />
    }
    case BrandedContentAdStatusTypes.Declined: {
      return <RightsDeclined {...props} />
    }
    case BrandedContentAdStatusTypes.Completed:
    case BrandedContentAdStatusTypes.Approved: {
      return <RightsAccepted {...props} />
    }
    case BrandedContentAdStatusTypes.Expired: {
      return <RightsExpired {...props} />
    }
  }
}

const mapStateToProps = (
  state: IApplicationState,
  { submissionId }: IBrandedContentAdBarButtonProps,
) => ({
  bcaStatus: selectTransactedBrandedContentAdStatus(state, submissionId),
  hasBcaTransaction: !isEmpty(selectTransactedBrandedContentAd(state, submissionId)),
})

export { BrandedContentAdBarButton }
export default compose<IInternalProps, IBrandedContentAdBarButtonProps>(
  withModal,
  connect(mapStateToProps),
)(BrandedContentAdBarButton)
