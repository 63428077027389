import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'

const gap = 1.5

export const ChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoint('tabletPortrait')`
    display: grid;
    grid-column-gap: ${gap}rem;
    grid-template-columns: repeat(auto-fit, calc(50% - ${gap / 2}rem));
    `};
`

export const Chart = styled.div`
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: solid 1px ${Theme.grey400Color};
  margin-bottom: ${gap}rem;
`

export const PlaceholderImage = styled.img`
  width: 100%;
  margin-bottom: ${gap}rem;
`
