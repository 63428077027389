import { Fragment } from 'react'

import Image from '@components/UI/Image'
import { AspectRatios } from '@enums'
import imagesSolid from '@images/images-solid.png'
import { ICampaign } from '@store/campaigns'
import Theme from '@theme'
import { FadeIn } from '@tribegroup'
import CampaignCardImageWrapperStyled, { GradientCover, PlaceholderImage } from './Image.styled'

interface ICampaignCardImage {
  campaign: ICampaign
}

export const CampaignCardImage = ({ campaign }: ICampaignCardImage) => {
  const { hero_16x9_original_url: thumbnailUrl } = campaign
  return (
    <CampaignCardImageWrapperStyled data-cy-element="campaign-image">
      {thumbnailUrl ? (
        <Fragment>
          <Image
            src={thumbnailUrl}
            animation={FadeIn}
            aspectRatio={AspectRatios.SIXTEEN_BY_NINE}
            loadingColor={Theme.whiteColor}
          />
          <GradientCover />
        </Fragment>
      ) : (
        <PlaceholderImage
          src={imagesSolid}
          animation={FadeIn}
          loadingColor={Theme.whiteColor}
          aspectRatio={AspectRatios.SIXTEEN_BY_NINE}
        />
      )}
    </CampaignCardImageWrapperStyled>
  )
}

export default CampaignCardImage
