enum SubmissionStatuses {
  New = 'new',
  PreApproved = 'preapproved',
  Approved = 'approved',
  Declined = 'declined',
  Pending = 'pending',
  Published = 'published',
  LicenseRequest = 'license_request',
  Licensed = 'licensed',
}

enum SubmissionDeclineStatuses {
  DeclinedInDispute = 'declined_in_dispute',
  DeclinedInInfluencersFavour = 'declined_in_influencers_favour',
  DeclinedInBrandsFavour = 'declined_in_brands_favour',
  DeclinedByBrand = 'declined_by_brand',
  DeclinedByTribe = 'declined_by_tribe',
  DeclinedByInfluencer = 'declined_by_influencer',
}

type AllSubmissionStatuses = SubmissionStatuses | SubmissionDeclineStatuses

export { SubmissionStatuses, SubmissionDeclineStatuses }
export type { AllSubmissionStatuses }
