import { Helmet } from 'react-helmet'
import { InjectedIntlProps, injectIntl } from 'react-intl'

export interface IHeadManagerProps {
  id?: string
  text?: string
}

export interface IInternalProps extends IHeadManagerProps, InjectedIntlProps {}

export const HeadTag = ({ intl, id, text }: IInternalProps) => {
  if (id) {
    return <Helmet title={intl.formatMessage({ id })} />
  } else if (text) {
    return <Helmet title={text} />
  }
  return null
}

export default injectIntl<IHeadManagerProps>(HeadTag)
