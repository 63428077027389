import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { RouterProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import ConditionalLink from '@components/UI/ConditionalLink/ConditionalLink'
import Image from '@components/UI/Image'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { AspectRatios, EventTrackingNames, PageRoutes, SupportedSubmissionTypes } from '@enums'
import hooks from '@hooks'
import logo from '@images/brand-icon.png'
import { fetchCampaigns } from '@store/campaigns'
import { clearDraft } from '@store/drafts'
import { IApplicationState } from '@store/index'
import { BrandManagerCapability, selectMeCapabilities } from '@store/me'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'
import BuilderCampaignTypeCard from '../Card/Card'

interface IInternalProps extends RouterProps {
  hasCreatedAnyCampaign: boolean
  fetchCampaigns: typeof fetchCampaigns
  clearDraft: typeof clearDraft
  capabilities?: BrandManagerCapability[]
}

export const LogoImage = () => (
  <Container height={1.75} width={1.75} leftInnerSpacing={1.5}>
    <Image src={logo} aspectRatio={AspectRatios.ONE_BY_ONE} loadingColor={Theme.whiteColor} />
  </Container>
)

export const BuilderCampaignTypeSelector: React.FC<IInternalProps> = ({
  history,
  capabilities,
  fetchCampaigns: fetchCampaignsAction,
  clearDraft: clearDraftAction,
}) => {
  const onMount = () => {
    fetchCampaignsAction()
    clearDraftAction()
  }

  const { from = `/${PageRoutes.Campaigns}` } = hooks.useLocationSearch()

  useEffect(onMount, [])

  const onClose = () => {
    history.push(from)
  }

  const showSolutionsCampaignLink = capabilities?.includes('solution_campaign_creation')

  return (
    <Container fullWidth>
      <Flex justifyBetween alignCenter>
        <Container absolute topPosition={1}>
          <ConditionalLink to="/campaigns" showLink>
            <LogoImage />
          </ConditionalLink>
        </Container>
        <BuilderCampaignTypeCard
          type={SupportedSubmissionTypes.SocialSubmission}
          showSolutionsCampaignLink={showSolutionsCampaignLink}
        />
        <BuilderCampaignTypeCard
          type={SupportedSubmissionTypes.ContentSubmission}
          showSolutionsCampaignLink={showSolutionsCampaignLink}
        />
        <Container
          data-cy-element="close-button"
          absolute
          topPosition={1}
          rightPosition={1.5}
          pointer
        >
          <ConnectedTrackedRoute
            additionalProperties={{
              action: 'close',
            }}
            eventName={EventTrackingNames.CampaignBuilderCloseBuilder}
          >
            <Icon glyph="close-x" color={Theme.grey700Color} onClick={onClose} size={1.5} />
          </ConnectedTrackedRoute>
        </Container>
      </Flex>
    </Container>
  )
}

const mapDispatchToProps = {
  clearDraft,
  fetchCampaigns,
}

const mapStateToProps = (state: IApplicationState) => ({
  capabilities: selectMeCapabilities(state),
})

export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(BuilderCampaignTypeSelector)
