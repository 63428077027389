import styled from 'styled-components'

import Theme from '@theme'
import { Checkbox } from '@tribegroup'

export const CustomCheckBox = styled(Checkbox)`
  & + label {
    position: relative;
    padding: 0;
    display: inline-flex;
    align-items: center;

    &::before {
      content: '';
      margin-right: 0.625rem;
      display: inline-block;
      vertical-align: text-top;
      background: ${Theme.whiteColor};
      border-radius: 0.5rem;
      border: solid 1px ${Theme.grey400Color};
      width: 2rem;
      height: 2rem;
      box-sizing: border-box;
      flex-shrink: 0;
    }

    & .heavy-tick-icon {
      display: none;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      width: 1rem;
      height: 1rem;
      align-items: center;

      svg {
        fill: ${Theme.primaryColor};
      }
    }
  }

  &:disabled + label {
    cursor: auto;

    &::before {
      border-color: ${Theme.grey400Color};
      opacity: 0.4;
    }
  }
`
