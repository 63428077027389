import { FormattedMessage } from 'react-intl'

import {
  CheckItem,
  CoachmarkBlock,
  CoachmarkContent,
  CoachmarkSection,
  ImageGrid,
  ImageWrapper,
  SideBarContentWrapper,
  SmallTextWrapper,
} from '@components/UI/Coachmark/Coachmark.styled'
import image1 from '@images/campaignbuilder/cb-step3-briefs-image-01-new.jpg'
import image2 from '@images/campaignbuilder/cb-step3-briefs-image-02-new.png'
import image3 from '@images/campaignbuilder/cb-step3-briefs-image-03-new.png'
import image4 from '@images/campaignbuilder/cb-step3-briefs-image-04-new.png'
import Theme from '@theme'
import { Container, Header, Icon, Text, Tip } from '@tribegroup'

export const CoachMarkContent = () => {
  return (
    <CoachmarkContent data-cy-element="how-to-nail-your-moodboard-content-wrapper">
      <CoachmarkSection width={16.75}>
        <CoachmarkBlock>
          <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.5}>
            <FormattedMessage id="builder.brief.moodboard.coach.moodboardrequirements" />
          </Header>
          <CheckItem>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.moodboard.coach.moodboardrequirements.upload" />
            </Text>
          </CheckItem>
          <CheckItem>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.moodboard.coach.moodboardrequirements.videoDuration" />
            </Text>
          </CheckItem>
          <CheckItem>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.moodboard.coach.moodboardrequirements.file.new" />
            </Text>
          </CheckItem>
          <CheckItem>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.moodboard.coach.moodboardrequirements.aspectratio" />
            </Text>
          </CheckItem>
        </CoachmarkBlock>
        <CoachmarkBlock>
          <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.5}>
            <FormattedMessage id="builder.brief.moodboard.coach.remember" />
          </Header>
          <Container bottomOuterSpacing={0.75}>
            <Text block color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.moodboard.coach.remember.body1" />
            </Text>
          </Container>
          <Container bottomOuterSpacing={0.75}>
            <Text block color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.moodboard.coach.remember.body2" />
            </Text>
          </Container>
          <Container bottomOuterSpacing={0.75}>
            <Text block color={Theme.grey900Color}>
              <FormattedMessage id="builder.brief.moodboard.coach.remember.body3" />
            </Text>
          </Container>
          <Text block color={Theme.grey900Color}>
            <FormattedMessage id="builder.brief.moodboard.coach.remember.body4.new" />
          </Text>
        </CoachmarkBlock>
      </CoachmarkSection>
      <CoachmarkSection width={21.5}>
        <CoachmarkBlock>
          <Header small uppercase color={Theme.grey700Color}>
            <FormattedMessage id="builder.brief.moodboard.coach.avoid" />
          </Header>
          <ImageGrid>
            <div>
              <ImageWrapper crossOut crossColor={Theme.errorColor}>
                <img src={image1} />
              </ImageWrapper>
              <SmallTextWrapper>
                <Text small color={Theme.defaultColor}>
                  <FormattedMessage id="builder.brief.moodboard.coach.avoid.watermarks" />
                </Text>
              </SmallTextWrapper>
            </div>
            <div>
              <ImageWrapper crossOut crossColor={Theme.errorColor}>
                <img src={image2} />
              </ImageWrapper>
              <SmallTextWrapper>
                <Text small color={Theme.defaultColor}>
                  <FormattedMessage id="builder.brief.moodboard.coach.avoid.includinglogo" />
                </Text>
              </SmallTextWrapper>
            </div>
            <div>
              <ImageWrapper crossOut crossColor={Theme.errorColor}>
                <img src={image3} />
              </ImageWrapper>
              <SmallTextWrapper>
                <Text small color={Theme.defaultColor}>
                  <FormattedMessage id="builder.brief.moodboard.coach.avoid.uncropped" />
                </Text>
              </SmallTextWrapper>
            </div>
            <div>
              <ImageWrapper crossOut crossColor={Theme.errorColor}>
                <img src={image4} />
              </ImageWrapper>
              <SmallTextWrapper>
                <Text small color={Theme.defaultColor}>
                  <FormattedMessage id="builder.brief.moodboard.coach.avoid.lowresolution" />
                </Text>
              </SmallTextWrapper>
            </div>
          </ImageGrid>
        </CoachmarkBlock>
      </CoachmarkSection>
    </CoachmarkContent>
  )
}

export const BuilderCampaignMoodboardTip = () => {
  return (
    <SideBarContentWrapper>
      <Tip
        text={
          <Text
            xsmall
            uppercase
            data-cy-element="how-to-nail-your-moodboard-image-tip"
            data-testid="how-to-nail-your-moodboard-image-tip"
          >
            <FormattedMessage id="builder.brief.moodboard.tip" />
          </Text>
        }
        width={10}
        coachmark={{
          content: <CoachMarkContent />,
          position: 'left',
          width: 46.125,
          yOffset: 2,
          overflow: 'hidden',
        }}
      />
    </SideBarContentWrapper>
  )
}

export default BuilderCampaignMoodboardTip
