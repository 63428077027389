import Flex from 'styled-flex-component'

import BuilderTitleBarActions from '@components/Builder/TitleBar/Actions'
import { BuilderTitleBarActionsConfirmCloseProvider } from '@components/Builder/TitleBar/Actions/ConfirmClose'
import TitleBarStyled from '@components/UI/TitleBar/TitleBar.styled'
import BuilderTitleBarLogoLink from '../TitleBar/Actions/LogoLink'
import BuilderTitleBarProgressTracker from '../TitleBar/Actions/ProgressTracker'
import { LogoWrapper } from './Titlebar.styled'

const BuilderTitleBar = () => (
  <TitleBarStyled>
    <Flex alignCenter justifyBetween full>
      <BuilderTitleBarActionsConfirmCloseProvider>
        <LogoWrapper>
          <BuilderTitleBarLogoLink />
        </LogoWrapper>
        <BuilderTitleBarProgressTracker />
        <BuilderTitleBarActions />
      </BuilderTitleBarActionsConfirmCloseProvider>
    </Flex>
  </TitleBarStyled>
)

export default BuilderTitleBar
