export enum PrePurchaseAnalyticsActionTypes {
  FETCH_REQUEST = 'prePurchaseAnalytics/FETCH_REQUEST',
  FETCH_SUCCESS = 'prePurchaseAnalytics/FETCH_SUCCESS',
  FETCH_ERROR = 'prePurchaseAnalytics/FETCH_ERROR',
}

export enum PrePurchaseAnalyticsType {
  VIDEO = 'video',
  POST = 'post',
  STORY = 'story',
  TIKTOK = 'tiktok',
  PINTEREST = 'pinterest',
  REEL = 'reel',
}

export interface IPrePurchaseAnalytics {
  avg_engagement?: number
  avg_engagement_pct?: number
  total_engagement?: number
  est_cpe?: number
  avg_reach?: number
  avg_reach_pct?: number
  avg_impressions?: number
  est_cpm?: number
  avg_video_views?: number
  avg_video_views_pct?: number
  total_video_views?: number
  avg_likes?: number
  avg_comments?: number
  avg_shares?: number
  est_cpv?: number
  avg_daily_impressions?: number
  avg_daily_engagement?: number
  avg_daily_saves?: number
  avg_save_pct?: number
  avg_daily_pin_clicks?: number
  avg_pin_click_pct?: number
  avg_daily_outbound_clicks?: number
  avg_outbound_click_pct?: number
}

export interface IPrePurchaseAnalyticsState {
  prePurchaseAnalyticsByIdentityId: Record<
    number,
    Partial<Record<PrePurchaseAnalyticsType, IPrePurchaseAnalytics>>
  >
  isFetching: boolean
  errors: any
}
