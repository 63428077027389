import { FormattedMessage } from 'react-intl'

import { CoachmarkContent, CoachmarkSection } from '@components/UI/Coachmark/Coachmark.styled'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

const BuilderCampaignFindProductTipContent = () => {
  return (
    <CoachmarkContent>
      <CoachmarkSection>
        <Container bottomOuterSpacing={1.5}>
          <Text
            color={Theme.grey900Color}
            customFontSize={1.125}
            lineHeight={1.33}
            customletterSpacing="0.3px"
          >
            <FormattedMessage id="builder.campaign.findProduct.coach.title" />
          </Text>
        </Container>

        <Container bottomOuterSpacing={0.25}>
          <Text
            uppercase
            color={Theme.primaryColor}
            customFontSize={0.8125}
            lineHeight={1.5}
            customletterSpacing="1.5px"
          >
            <FormattedMessage id="builder.campaign.findProduct.coach.discountCode.title" />
          </Text>
        </Container>
        <Container bottomOuterSpacing={1.25}>
          <Text color={Theme.grey900Color} customFontSize={0.875}>
            <FormattedMessage id="builder.campaign.findProduct.coach.discountCode.text" />
          </Text>
        </Container>

        <Container bottomOuterSpacing={0.25}>
          <Text
            uppercase
            color={Theme.primaryColor}
            customFontSize={0.8125}
            lineHeight={1.5}
            customletterSpacing="1.5px"
          >
            <FormattedMessage id="builder.campaign.findProduct.coach.shipProduct.title" />
          </Text>
        </Container>
        <Container bottomOuterSpacing={1.25}>
          <Text color={Theme.grey900Color} customFontSize={0.875}>
            <FormattedMessage id="builder.campaign.findProduct.coach.shipProduct.text" />
          </Text>
        </Container>

        <Container bottomOuterSpacing={0.25}>
          <Text
            uppercase
            color={Theme.primaryColor}
            customFontSize={0.8125}
            lineHeight={1.5}
            customletterSpacing="1.5px"
          >
            <FormattedMessage id="builder.campaign.findProduct.coach.reimburseCreator.title" />
          </Text>
        </Container>
        <Container>
          <Text color={Theme.grey900Color} customFontSize={0.875}>
            <FormattedMessage id="builder.campaign.findProduct.coach.reimburseCreator.text" />
          </Text>
        </Container>
      </CoachmarkSection>
    </CoachmarkContent>
  )
}

export default BuilderCampaignFindProductTipContent
