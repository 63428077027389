export enum SubmissionPerformanceActionTypes {
  FETCH_REQUEST = 'submissionPerformance/FETCH_REQUEST',
  FETCH_SUCCESS = 'submissionPerformance/FETCH_SUCCESS',
  FETCH_ERROR = 'submissionPerformance/FETCH_ERROR',
}

export type ValueLockType = {
  value: number | null
  locked: boolean
}

export interface ISubmissionPerformance {
  identity_followers_count?: number
  engagement_pct?: number
  cost_per_engagement?: number
  cost_per_engagement_currency?: string
  likes?: number
  comments?: number
  video_views?: number
  video_views_pct?: number
  cost_per_video_view?: number
  cost_per_video_view_currency?: string
  shares?: number
  retweets?: number
  engagement?: number
  unique_views?: number
  story_view_rate?: number
  actions?: number
  cost_per_view?: number
  cost_per_view_currency?: string
  reach_enabled?: boolean
  impressions_enabled?: boolean
  engagement_rate?: number
  views?: number
  view_rate?: number
  impressions?: number
  reach?: number
  reach_pct?: number
  cost_per_mille?: number
  cpm?: number
  cpv?: number
  cpe?: number
  cpm_currency?: string
}

export interface ISubmissionPerformanceState {
  isFetching?: boolean
  errors?: any
  submissionPerformanceById: Record<number, ISubmissionPerformance>
}
