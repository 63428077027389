import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import BrandFansGroupRowTrackedRoute from '@components/BrandFans/Group/Row/TrackedRoute'
import { BrandFanGroupMemberWarningContext } from '@context/BrandFanGroupMemberWarning'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import {
  addBrandFanGroupMember,
  removeBrandFanGroupMember,
  selectBrandFanGroupHasLiveCampaigns,
  selectBrandFanGroupName,
  selectHasPendingBrandFanGroupRequest,
} from '@store/brandFans'
import Theme from '@theme'
import { Container, H5 } from '@tribegroup'
import { GroupListItem } from './Groups.styled'
import BrandFansInfluencerIdentityGroupsItemAction from './ItemAction'

interface IProps {
  groupId: number
  identityId: number
  influencerId?: number
  isMemberOfGroup: boolean
  campaignId?: number
  submissionId?: number
}

interface IInternalProps extends ConnectedProps<typeof connector>, IProps {}

const BrandFansInfluencerIdentityGroupsItem: React.FC<IInternalProps> = ({
  groupId,
  groupName,
  identityId,
  hasLiveCampaigns,
  isMemberOfGroup,
  hasPendingRequest,
  addBrandFanGroupMember: addBrandFanGroupMemberAction,
  removeBrandFanGroupMember: removeBrandFanGroupMemberAction,
  campaignId,
  submissionId,
  influencerId,
}) => {
  const { showWarning, selectedGroupId } = React.useContext(BrandFanGroupMemberWarningContext)
  const onItemClick = () => {
    if (hasLiveCampaigns) {
      showWarning(groupId, isMemberOfGroup ? 'remove' : 'add')
    } else if (isMemberOfGroup) {
      removeBrandFanGroupMemberAction(identityId, groupId, campaignId, submissionId, influencerId)
    } else {
      addBrandFanGroupMemberAction(identityId, groupId, campaignId, submissionId, influencerId)
    }
  }

  const groupsAlert = isMemberOfGroup
    ? 'brandFans.identityGroups.warning.removeFromLive'
    : 'brandFans.identityGroups.warning.addToLive'

  return (
    <BrandFansGroupRowTrackedRoute
      eventName={
        isMemberOfGroup
          ? EventTrackingNames.BrandFansGroupsRemove
          : EventTrackingNames.BrandFansGroupsAdd
      }
      groupId={groupId}
      additionalProperties={{
        identity_id: identityId,
        influencer_id: influencerId,
        groups_alert: hasLiveCampaigns ? groupsAlert : undefined,
      }}
    >
      <GroupListItem
        relative
        onClick={!hasPendingRequest ? onItemClick : undefined}
        topInnerSpacing={0.75}
        bottomInnerSpacing={0.75}
        leftInnerSpacing={1}
        rightInnerSpacing={1}
        bottomOuterSpacing={0.125}
        warning={selectedGroupId === groupId}
        data-testid={`brand-fan-group-item-${groupId}`}
      >
        <Flex full justifyBetween>
          <Container maxWidth={18} noWrap>
            <H5 title={groupName} textOverflowAsEllipsis color={Theme.grey900Color}>
              {groupName}
            </H5>
          </Container>
          <BrandFansInfluencerIdentityGroupsItemAction
            groupId={groupId}
            isMemberOfGroup={isMemberOfGroup}
          />
        </Flex>
      </GroupListItem>
    </BrandFansGroupRowTrackedRoute>
  )
}

const mapDispatchToProps = {
  addBrandFanGroupMember,
  removeBrandFanGroupMember,
}

const mapStateToProps = (state: IApplicationState, { groupId }: IProps) => ({
  groupName: selectBrandFanGroupName(state, groupId),
  hasPendingRequest: selectHasPendingBrandFanGroupRequest(state, groupId),
  hasLiveCampaigns: selectBrandFanGroupHasLiveCampaigns(state, groupId),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export { BrandFansInfluencerIdentityGroupsItem }
export default compose<IInternalProps, IProps>(connector)(BrandFansInfluencerIdentityGroupsItem)
