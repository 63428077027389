import { FormattedMessage } from 'react-intl'

import { PaymentMethods } from '@enums'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

interface IProps {
  paymentMethod: PaymentMethods
  isRequestingLicense?: boolean
  onItemClick: (paymentMethod: PaymentMethods) => void
  active?: boolean
}

export const LicensingCreditManagementPaymentMethodItem = ({
  paymentMethod,
  isRequestingLicense,
  onItemClick,
  active,
}: IProps) => {
  const onClick = () => {
    onItemClick(paymentMethod)
  }

  return (
    <Container
      pointer={!isRequestingLicense}
      onClick={!isRequestingLicense ? onClick : undefined}
      backgroundColor={active ? Theme.primary100Color : Theme.grey200Color}
      rightOuterSpacing={0.5}
      topOuterSpacing={1.5}
      topInnerSpacing={0.375}
      bottomInnerSpacing={0.375}
      leftInnerSpacing={0.75}
      rightInnerSpacing={0.75}
      borderRadius={0.25}
      data-testid={`${paymentMethod}-payment-method`}
    >
      <Text color={Theme.grey800Color}>
        <FormattedMessage id={`core.text.${paymentMethod}`} />
      </Text>
    </Container>
  )
}
