import styled from 'styled-components'

import Theme from '@theme'

export const InternalMessageBlockStyled = styled('div')`
  width: 100%;
  position: relative;
  text-align: center;
  padding-top: 1.25rem;
  word-break: break-word;
`

export const InternalMessageStyled = styled('div')`
  padding: 0.75rem 1rem 0.8125rem;
  background: ${Theme.secondary100Color};
  border-radius: 0.5rem;
`

export const InternalMessageSignature = styled('span')`
  display: inline-block;
  width: 100%;
  text-align: left;
  color: ${Theme.grey700Color};
`

export const InternalMessageDateText = styled('span')`
  width: 100%;
  display: inline-block;
  padding-bottom: 0.75rem;
`
