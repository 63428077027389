import { call, put, select, takeLatest } from 'redux-saga/effects'

import { selectAuthToken } from '@store/auth'
import {
  fetchPostMetrics,
  fetchPostMetricsError,
  fetchPostMetricsSuccess,
  IPostMetricsResponse,
  PostMetricsActionTypes,
  selectPostsMetricsLink,
} from '@store/postCampaignReport'
import { networkRequest } from '@utils'
import { WatcherFunction } from '..'

export function* handleFetchPostMetrics(action: ReturnType<typeof fetchPostMetrics>) {
  const { campaignId, channel } = action.payload

  try {
    const postMetricsLink = yield select(selectPostsMetricsLink, campaignId, channel)
    const authToken: string = yield select(selectAuthToken)
    const postMetrics: IPostMetricsResponse = yield call(
      networkRequest,
      postMetricsLink.method,
      postMetricsLink.href,
      undefined,
      authToken,
    )

    yield put(fetchPostMetricsSuccess(postMetrics, campaignId, channel))
  } catch (error) {
    yield put(fetchPostMetricsError(error))
  }
}

function* watchFetchPostMetrics() {
  yield takeLatest(PostMetricsActionTypes.FETCH_REQUEST, handleFetchPostMetrics)
}

export const postMetricsSagas: ReadonlyArray<WatcherFunction> = [watchFetchPostMetrics]
