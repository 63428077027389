import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import Flex from 'styled-flex-component'

import AnalyticsPostCampaignReportEmpty from '@components/Analytics/PostCampaignReport/ListView/Empty/Empty'
import AnalyticsPostCampaignReportListViewAmount from '@components/Analytics/PostCampaignReport/ListView/Item/Amount'
import {
  Entry,
  RowWrapper,
} from '@components/Analytics/PostCampaignReport/ListView/ListView.styled'
import LockableEntry from '@components/Analytics/PostCampaignReport/ListView/LockableEntry/LockableEntry'
import { isNumber } from '@components/Analytics/PrePurchase/PrePurchase'
import { AnalyticsCustomPrice } from '@components/UI/Price/Price'
import { IApplicationState } from '@store'
import { selectCampaignTotalCurrency } from '@store/campaigns'
import {
  selectShowTikTokPerformance,
  selectTikTokCPE,
  selectTikTokCPM,
  selectTikTokCPV,
  selectTikTokFollowers,
  selectTikTokPublishedDate,
  selectTikTokSpend,
  selectTikTokSubmissionComments,
  selectTikTokSubmissionEngagement,
  selectTikTokSubmissionEngagementPct,
  selectTikTokSubmissionLikes,
  selectTikTokSubmissionShares,
  selectTikTokViews,
  selectTikTokViewsPct,
} from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { abbreviateNumber } from '@utils'

interface IProps {
  submissionId: number
  backgroundColor: string
  isPriceHidden?: boolean
}

const AnalyticsPostCampaignReportTikTokListItem: React.FC<IProps> = ({
  submissionId,
  backgroundColor,
  isPriceHidden = false,
}) => {
  const match = useRouteMatch()

  const {
    publishedDate,
    spend,
    followers,
    likes,
    comments,
    shares,
    engagement,
    engagementPct,
    cpe,
    views,
    viewsPct,
    cpv,
    cpm,
    showMetrics,
    currency,
  } = useSelector((state: IApplicationState) => {
    const campaignId = selectCampaignId(match)!

    return {
      publishedDate: selectTikTokPublishedDate(state, submissionId),
      spend: selectTikTokSpend(state, submissionId),
      followers: selectTikTokFollowers(state, submissionId),
      likes: selectTikTokSubmissionLikes(state, submissionId),
      comments: selectTikTokSubmissionComments(state, submissionId),
      shares: selectTikTokSubmissionShares(state, submissionId),
      engagement: selectTikTokSubmissionEngagement(state, submissionId),
      engagementPct: selectTikTokSubmissionEngagementPct(state, submissionId),
      cpe: selectTikTokCPE(state, submissionId),
      views: selectTikTokViews(state, submissionId),
      viewsPct: selectTikTokViewsPct(state, submissionId),
      cpv: selectTikTokCPV(state, submissionId),
      cpm: selectTikTokCPM(state, submissionId),
      showMetrics: selectShowTikTokPerformance(state, submissionId),
      currency: selectCampaignTotalCurrency(state, campaignId),
    }
  })

  if (!showMetrics) {
    return (
      <Container data-testid="tiktok-purchase-empty" inline relative>
        <AnalyticsPostCampaignReportEmpty
          minWidth={isPriceHidden ? 58.5 : 84}
          backgroundColor={backgroundColor}
        />
      </Container>
    )
  }

  return (
    <Container inline relative height={4.75}>
      <Flex>
        <RowWrapper data-testid="tiktok-published-date" backgroundColor={backgroundColor}>
          <Entry>
            <Text color={Theme.grey900Color}>{publishedDate}</Text>
          </Entry>
        </RowWrapper>
        {!isPriceHidden && (
          <AnalyticsPostCampaignReportListViewAmount
            backgroundColor={backgroundColor}
            testId="tiktok-purchase-amount"
            pattern="!###,### "
            value={{ amount: spend, currency }}
          />
        )}

        <RowWrapper data-testid="tiktok-followers" backgroundColor={backgroundColor}>
          <Entry>
            <Text color={Theme.grey900Color}>{abbreviateNumber(followers)}</Text>
          </Entry>
        </RowWrapper>
        <RowWrapper data-testid="tiktok-likes" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!likes}
            content={
              isNumber(likes) && (
                <Text color={Theme.grey900Color}>{abbreviateNumber(likes ?? 0)}</Text>
              )
            }
          />
        </RowWrapper>
        <RowWrapper data-testid="tiktok-comments" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!comments}
            content={
              isNumber(comments) && (
                <Text color={Theme.grey900Color}>{abbreviateNumber(comments ?? 0)}</Text>
              )
            }
          />
        </RowWrapper>
        <RowWrapper data-testid="tiktok-shares" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!shares}
            content={
              isNumber(shares) && (
                <Text color={Theme.grey900Color}>{abbreviateNumber(shares ?? 0)}</Text>
              )
            }
          />
        </RowWrapper>
        <RowWrapper data-testid="tiktok-engagement" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!engagement}
            content={
              isNumber(engagement) && (
                <Text color={Theme.grey900Color}>{abbreviateNumber(engagement ?? 0)}</Text>
              )
            }
          />
        </RowWrapper>
        <RowWrapper data-testid="tiktok-engagement-pct" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!engagementPct}
            content={
              isNumber(engagementPct) && (
                <Text color={Theme.grey900Color}>
                  <FormattedMessage id="core.text.percent" values={{ value: engagementPct }} />
                </Text>
              )
            }
          />
        </RowWrapper>
        {!isPriceHidden && (
          <RowWrapper data-testid="tiktok-cpe" backgroundColor={backgroundColor}>
            <LockableEntry
              empty={!cpe}
              content={
                isNumber(cpe) && (
                  <Text color={Theme.grey900Color}>
                    <AnalyticsCustomPrice currency={currency} quantity={cpe!} />
                  </Text>
                )
              }
            />
          </RowWrapper>
        )}
        <RowWrapper data-testid="tiktok-views" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!views}
            content={
              isNumber(views) && (
                <Text color={Theme.grey900Color}>{abbreviateNumber(views ?? 0)}</Text>
              )
            }
          />
        </RowWrapper>
        <RowWrapper data-testid="tiktok-view-pct" backgroundColor={backgroundColor}>
          <LockableEntry
            empty={!viewsPct}
            content={
              isNumber(viewsPct) && (
                <Text color={Theme.grey900Color}>
                  <FormattedMessage id="core.text.percent" values={{ value: viewsPct }} />
                </Text>
              )
            }
          />
        </RowWrapper>
        {!isPriceHidden && (
          <>
            <RowWrapper data-testid="tiktok-cpv" backgroundColor={backgroundColor}>
              <LockableEntry
                empty={!cpv}
                content={
                  isNumber(cpv) && (
                    <Text color={Theme.grey900Color}>
                      <AnalyticsCustomPrice currency={currency} quantity={cpv!} />
                    </Text>
                  )
                }
              />
            </RowWrapper>
            <RowWrapper data-testid="tiktok-cpm" backgroundColor={backgroundColor}>
              <LockableEntry
                empty={!cpm}
                content={
                  isNumber(cpm) && (
                    <Text color={Theme.grey900Color}>
                      <AnalyticsCustomPrice currency={currency} quantity={cpm!} />
                    </Text>
                  )
                }
              />
            </RowWrapper>
          </>
        )}
      </Flex>
    </Container>
  )
}

export default AnalyticsPostCampaignReportTikTokListItem
