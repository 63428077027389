import React from 'react'

import { ActionBarLeft, ActionBarRight, ActionBarWrapper } from '@components/UI/Card/Card.styled'
import { SubmissionStatuses } from '@enums'
import { useInboxFilter } from '@hooks/useInboxFilter'
import { ISubmission } from '@store/submissions'
import ActionBarIconChat from '../Icon/Chat'
import ActionBarIconDecline from '../Icon/Decline/Decline'
import ActionBarIconPurchaseLicense from '../Icon/PurchaseLicense/PurchaseLicense'
import ActionBarIconShortlist from '../Icon/Shortlist/Shortlist'
import ActionBarIconUnshortlist from '../Icon/Unshortlist/Unshortlist'

interface IProps {
  submission: ISubmission
  campaignId: number
  hasShortlistLink: boolean
  hasUnshortlistLink: boolean
  hasDeclineLink: boolean
  hasFeedbackLink: boolean
  showLicenseIcon: boolean
}

const SubmissionCardActionBarContentInbox: React.FC<IProps> = ({
  hasShortlistLink,
  hasUnshortlistLink,
  hasDeclineLink,
  hasFeedbackLink,
  campaignId,
  submission,
  showLicenseIcon,
}) => {
  const { currentFilter } = useInboxFilter()
  const modalProps = {
    currFilter: currentFilter,
    submission,
    campaignId,
  }

  const submissionId = submission.id

  if (submission.status === SubmissionStatuses.PreApproved) {
    return (
      <ActionBarWrapper justifyBetween>
        <ActionBarLeft>
          {hasDeclineLink && <ActionBarIconDecline modalProps={modalProps} />}
          {hasShortlistLink && <ActionBarIconShortlist submissionId={submissionId} />}
          {hasUnshortlistLink && <ActionBarIconUnshortlist submissionId={submissionId} />}
        </ActionBarLeft>
        <ActionBarRight>
          {hasFeedbackLink && <ActionBarIconChat submission={submission} campaignId={campaignId} />}
          {showLicenseIcon && <ActionBarIconPurchaseLicense submissionId={submissionId} />}
        </ActionBarRight>
      </ActionBarWrapper>
    )
  }

  return (
    <ActionBarWrapper justifyBetween>
      <ActionBarLeft>
        {hasShortlistLink && <ActionBarIconShortlist submissionId={submissionId} />}
        {hasUnshortlistLink && <ActionBarIconUnshortlist submissionId={submissionId} />}
        {hasFeedbackLink && <ActionBarIconChat submission={submission} campaignId={campaignId} />}
      </ActionBarLeft>
      <ActionBarRight>
        {hasDeclineLink && <ActionBarIconDecline modalProps={modalProps} />}
        {showLicenseIcon && <ActionBarIconPurchaseLicense submissionId={submissionId} />}
      </ActionBarRight>
    </ActionBarWrapper>
  )
}

export default SubmissionCardActionBarContentInbox
