import React, { useState } from 'react'

import Checkbox, { ICheckboxProps } from './Checkbox'

const CheckboxWithState: React.FC<ICheckboxProps> = (props) => {
  const [checked, setChecked] = useState(false)
  const onClick = (event: React.MouseEvent<HTMLInputElement>) => {
    if (props.onClick) {
      props.onClick(event)
    }
    setChecked((value) => !value)
  }
  return <Checkbox {...props} onClick={onClick} checked={checked} />
}

export default CheckboxWithState
