import React from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import MediaObjectLicensing from '@components/MediaObject/Licensing/Licensing'
import { SubmissionCardModalLicensed } from '@components/Submission/Card/Modal/License.New/Licensed'
import SubmissionCardModalLicenseInboxCarouselEducation from '@components/Submission/Card/Modal/License/InboxCarouselEducation'
import SubmissionCardModalLicense from '@components/Submission/Card/Modal/License/License'
import { RequestLicense } from '@components/Submission/Card/Modal/RequestLicense/RequestLicense'
import ActionIcon from '@components/UI/ActionIcon'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { INBOX_FILTER } from '@constants'
import { IModalContextProps } from '@context/Modal'
import { IMultiframeContextProps } from '@context/MultiframeControls'
import { PopupMenuContext } from '@context/PopupMenu'
import { POPUP_TRANSITION_DURATION } from '@context/PopupMenu/Provider'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import { EventTrackingNames, FeatureToggles, LicenseStatuses } from '@enums'
import { withModal, withMultiframeControls, withSubmissionListFilter } from '@hocs'
import { IApplicationState } from '@store'
import { selectIsSocialCampaign } from '@store/campaigns'
import {
  selectFirstMediaObjectWithTransactedLicense,
  selectTransactedLicense,
  selectTransactedLicenseStatus,
} from '@store/mediaObjects'
import { selectCampaignId, selectCurrentView } from '@store/router'
import {
  selectActiveSubmissionMediaObject,
  selectIsCarousel,
  selectSubmissionMediaObjectFrameNumber,
  selectSubmissionMediaObjectIds,
} from '@store/submissions'
import Theme from '@theme'
import { isEmpty, pxToRem } from '@utils'
import ActionBarIconLicenseCarouselPopupMenu from './Carousel/PopupMenu'

interface IProps {
  submissionId: number
}

interface IInternalProps
  extends IProps,
    InjectedIntlProps,
    IModalContextProps,
    IMultiframeContextProps,
    ISubmissionListFilterContextProps,
    ConnectedProps<typeof connector> {}

export const ActionBarIconLicense = ({
  campaignId,
  submissionId,
  intl,
  showModal,
  licenseStatus,
  isInbox,
  isCarousel,
  isFilterLicenseRelated,
  mediaObjectId,
  frameNumber,
  hasAtleastOneLicenseRequested,
  hasAtleastOneLicensed,
  transactedLicense,
  isSocialCampaign,
}: IInternalProps) => {
  const isNewLicenseTypesEnabled = useFlag(FeatureToggles.NEW_LICENSE_TYPES_ENABLED)

  const { showPopup, hidePopup } = React.useContext(PopupMenuContext)
  const isCarouselAndInbox = isCarousel && isInbox

  const isLicenseRequestExpired = licenseStatus === LicenseStatuses.RequestExpired
  const tooltipId =
    licenseStatus && !isLicenseRequestExpired
      ? `submission.card.action.licensecontent.${licenseStatus}`
      : 'submission.card.action.licensecontent.request'

  const isHighlighted = isCarouselAndInbox
    ? hasAtleastOneLicensed || hasAtleastOneLicenseRequested
    : licenseStatus && [LicenseStatuses.Requested, LicenseStatuses.Licensed].includes(licenseStatus)

  const isDisabled =
    licenseStatus &&
    [
      LicenseStatuses.LicenseExpired,
      LicenseStatuses.ExpiredDeprecated,
      LicenseStatuses.Declined,
    ].includes(licenseStatus)

  if (isDisabled) {
    return (
      <ActionIcon
        glyph="license-content"
        color={Theme.grey600Color}
        size={1.5}
        tooltip={intl.formatMessage({ id: tooltipId })}
        highlighted={isHighlighted}
        disabled
        elementName="license"
      />
    )
  }

  const shouldShowCarouselPopup =
    isCarousel && (!licenseStatus || licenseStatus === LicenseStatuses.RequestExpired)

  const modalProps = { submissionId, frameNumber }

  const showLicenseModal = () => showModal(SubmissionCardModalLicense, modalProps)

  const shouldShowMediaObjectLicenseModal = [
    LicenseStatuses.Requested,
    LicenseStatuses.Licensed,
  ].includes(transactedLicense?.status as LicenseStatuses)

  const onPopupClick = () => {
    hidePopup()
    setTimeout(() => {
      if (isNewLicenseTypesEnabled) {
        showModal(RequestLicense, modalProps)
      } else {
        showLicenseModal()
      }
    }, POPUP_TRANSITION_DURATION)
  }

  const onClick = (event: React.SyntheticEvent) => {
    if (isCarouselAndInbox && !isFilterLicenseRelated) {
      return showModal(SubmissionCardModalLicenseInboxCarouselEducation, modalProps)
    }

    if (shouldShowCarouselPopup) {
      const target = event.target as HTMLElement
      const { left, top, width } = target.getBoundingClientRect()

      showPopup(
        <ActionBarIconLicenseCarouselPopupMenu frameNumber={frameNumber} onClick={onPopupClick} />,
        {
          x: pxToRem(left + width),
          y: pxToRem(top),
        },
      )
      return
    }

    if (isNewLicenseTypesEnabled) {
      return showModal(RequestLicense, modalProps)
    }

    if (shouldShowMediaObjectLicenseModal) {
      if (!isSocialCampaign) {
        showModal(SubmissionCardModalLicensed, { mediaObjectId })
        return
      }
      showModal(MediaObjectLicensing, { mediaObjectId })
      return
    }

    return showLicenseModal()
  }

  return (
    <ConnectedTrackedRoute
      eventName={EventTrackingNames.ViewLicensingOptions}
      campaignId={campaignId}
      submissionId={submissionId}
      mediaObjectId={mediaObjectId}
    >
      <ActionIcon
        glyph="license-content"
        color={Theme.grey900Color}
        size={1.5}
        onClick={onClick}
        tooltip={intl.formatMessage({ id: tooltipId })}
        highlighted={isHighlighted}
        elementName="license"
      />
    </ConnectedTrackedRoute>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  {
    submissionId,
    match,
    activeFrameNumber,
  }: IProps & RouteComponentProps & IMultiframeContextProps,
) => {
  const { id: mediaObjectId } = selectActiveSubmissionMediaObject(
    state,
    submissionId,
    activeFrameNumber,
  )

  const currentView = selectCurrentView(state)
  const isCarousel = selectIsCarousel(state, submissionId)

  const isInbox = currentView === INBOX_FILTER

  const mediaObjectIds = selectSubmissionMediaObjectIds(state, submissionId)
  const hasAtleastOneLicensed = !isEmpty(
    selectFirstMediaObjectWithTransactedLicense(state, mediaObjectIds, [LicenseStatuses.Licensed]),
  )

  const hasAtleastOneLicenseRequested = !isEmpty(
    selectFirstMediaObjectWithTransactedLicense(state, mediaObjectIds, [LicenseStatuses.Requested]),
  )

  const transactedLicense = selectTransactedLicense(state, mediaObjectId)
  const campaignId = selectCampaignId(match) as number

  return {
    hasAtleastOneLicensed,
    hasAtleastOneLicenseRequested,
    frameNumber: selectSubmissionMediaObjectFrameNumber(state, submissionId, mediaObjectId),
    mediaObjectId,
    isCarousel,
    campaignId,
    licenseStatus: selectTransactedLicenseStatus(state, mediaObjectId),
    isInbox,
    transactedLicense,
    isSocialCampaign: selectIsSocialCampaign(state, campaignId),
  }
}

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(
  withMultiframeControls,
  withSubmissionListFilter,
  withRouter,
  withModal,
  injectIntl,
  connector,
)(ActionBarIconLicense)
