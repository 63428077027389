import React from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { Hr } from '@components/UI/Hr/Hr.styled'
import InformationContent from '@components/UI/Information/Content/Content'
import HoverableInformation from '@components/UI/Information/HoverableInformation/HoverableInformation'
import UpsellInformation from '@components/Upsell/Information/Information'
import { TRIBE_PRICING_PAGE_METRICS_GLOSSARY } from '@constants'
import { EventTrackingNames } from '@enums'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

type IInternalProps = ConnectedProps<typeof connector>

const AnalyticsPostCampaignReportGlossaryLockedEntryHeader: React.FC<IInternalProps> = ({
  campaignId,
}) => (
  <Container topInnerSpacing={1.75}>
    <Container bottomInnerSpacing={1.5} rightInnerSpacing={1}>
      <Hr color={Theme.grey400Color} />
    </Container>
    <Container bottomOuterSpacing={0.75}>
      <Container inlineBlock width={1}>
        <HoverableInformation
          glyph="locked"
          iconSize={1}
          iconColor={Theme.grey600Color}
          iconColorOnHover={Theme.errorColor}
          testId="upsell-icon"
          waypointYOffset={270}
        >
          <InformationContent
            titleColor={Theme.grey900Color}
            title={<FormattedMessage id="analytics.postCampaignReport.glossary.upsell.title" />}
            content={<FormattedMessage id="analytics.postCampaignReport.glossary.upsell.content" />}
          />
          <Container data-testid="upsell-info">
            <UpsellInformation
              textInformationMaxWidth={9.75}
              textInformation={<FormattedHTMLMessage id="upsell.information.unlock" />}
              externalLink={TRIBE_PRICING_PAGE_METRICS_GLOSSARY}
              trackingProps={{
                eventName: EventTrackingNames.SubscriptionLearnMore,
                additionalProperties: {
                  current_filter: 'metrics',
                  product_metric_group: 'reporting',
                  product_metric: 'glossary',
                },
                campaignId,
              }}
            />
          </Container>
        </HoverableInformation>
      </Container>
    </Container>
    <Container rightInnerSpacing={1.25}>
      <Text block color={Theme.primaryColor} customFontSize={1} lineHeight={1.5}>
        <FormattedMessage id="analytics.postCampaignReport.glossary.lockedHeader" />
      </Text>
    </Container>
  </Container>
)

const mapStateToProps = (_, { match }: RouteComponentProps) => ({
  campaignId: selectCampaignId(match)!,
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, {}>(
  withRouter,
  connector,
)(AnalyticsPostCampaignReportGlossaryLockedEntryHeader)
