import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { RowWrapper } from '../ListView.styled'

interface IAnalyticsPostCampaignReportEmptyProps {
  minWidth: number
  backgroundColor: string
}

const AnalyticsPostCampaignReportEmpty: React.FC<IAnalyticsPostCampaignReportEmptyProps> = ({
  minWidth,
  backgroundColor,
}) => (
  <Container minWidth={minWidth} height={4.75}>
    <Flex>
      <RowWrapper backgroundColor={backgroundColor}>
        <Container topInnerSpacing={1.75} rightInnerSpacing={0.5} leftInnerSpacing={1.75}>
          <Text italic small color={Theme.grey800Color}>
            <FormattedMessage id="postCampaignReport.submission.list.performance.empty" />
          </Text>
        </Container>
      </RowWrapper>
    </Flex>
  </Container>
)

export default AnalyticsPostCampaignReportEmpty
