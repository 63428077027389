import {
  IAnalyticsSubmission,
  IFeatureLockedMetricsSummary,
  IInsights,
} from '@store/postCampaignReport'

export enum StoryMetricsActionTypes {
  FETCH_REQUEST = 'pcr/storyMetrics/FETCH_REQUEST',
  FETCH_SUCCESS = 'pcr/storyMetrics/FETCH_SUCCESS',
  FETCH_ERROR = 'pcr/storyMetrics/FETCH_ERROR',
}

export interface IStoryMetricsState {
  isFetching: boolean
  errors?: object | string
  storyMetricsById: Record<number, IStoryMetrics>
  storyMetricsSummary: Record<number, Record<string, object>>
  storyMetricIdsByChannel: Record<number, Record<string, ReadonlyArray<number>>>
}

export interface IStoryMetrics extends IAnalyticsSubmission, IInsights {
  purchase_amount: number
  purchase_amount_currency: string
}

export interface IStoryMetricsSummary extends IFeatureLockedMetricsSummary {
  count: number
  total_purchase_amount: number
  total_purchase_amount_currency: string
  total_unique_views: number
  avg_view_rate: number
  total_cost_per_view: number
  total_cost_per_view_currency: string
  total_story_actions: number
  total_followers: number
}

export interface IStoryMetricsResponse {
  items: ReadonlyArray<IStoryMetrics>
  metadata: IStoryMetricsSummary
}
