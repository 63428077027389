import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import Flex from 'styled-flex-component'

import TitleBar from '@components/UI/TitleBar'
import { HeaderBackButton, HeaderTitle } from '@components/UI/TitleBar/TitleBar.styled'
import Theme from '@theme'
import { H5 } from '@tribegroup'
import queryStringtoObject from '@utils/url'

export const AcountTitleBar = () => {
  const { search } = useLocation()
  const { from = '/campaigns' } = queryStringtoObject(search)
  return (
    <TitleBar>
      <HeaderTitle data-cy-element="titlebar-call-to-action">
        <Flex full justifyStart>
          <Link to={decodeURIComponent(from)}>
            <HeaderBackButton glyph="nav-back-default" size={1.5} color={Theme.grey900Color} />
          </Link>
          <H5 color={Theme.grey900Color}>
            <FormattedMessage id="core.text.myAccount" />
          </H5>
        </Flex>
      </HeaderTitle>
    </TitleBar>
  )
}
export default AcountTitleBar
