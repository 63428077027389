import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchMeSuccess,
  selectUpdatePreferencesLink,
  updatePreferences,
  UpdatePreferencesActionTypes,
  updatePreferencesError,
} from '@store/me'
import { networkRequest } from '@utils'

export function* handleUpdatePreferences(action: ReturnType<typeof updatePreferences>) {
  try {
    const authToken: string = yield select(selectAuthToken)
    const updatePreferencesLink: IHateoasLink = yield select(selectUpdatePreferencesLink)
    const me = yield call(
      networkRequest,
      updatePreferencesLink.method,
      updatePreferencesLink.href,
      action.payload,
      authToken,
    )
    yield put(fetchMeSuccess(me))
  } catch (error) {
    yield put(updatePreferencesError(error))
  }
}

export function* watchUpatePreferencesRequest() {
  yield takeLatest(UpdatePreferencesActionTypes.UPDATE_PREFERENCES_REQUEST, handleUpdatePreferences)
}
