import { Component } from 'react'

import { FormattedMessage, FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import DetailCard from '@components/UI/DetailCard'
import Price from '@components/UI/Price/Price'
import { ICampaignContextProps } from '@context/Campaign'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import { withCampaign, withSubmissionListFilter } from '@hocs'
import { IApplicationState } from '@store'
import { selectSubmissionMetrics } from '@store/submissions'
import SubmissionMetricsStyled from './Metrics.styled'

interface IMetricProps {
  projected_spend_cents?: number
  projected_spend_currency?: string
  spend_cents?: number
  spend_currency?: string
  collective_reach?: number
  engagement?: number
  cost_per_engagement_cents?: number
  cost_per_engagement_currency?: string
  tax_display_name?: string
}

interface IInternalProps extends ICampaignContextProps, ISubmissionListFilterContextProps {
  metrics: IMetricProps
}

export class SubmissionMetrics extends Component<IInternalProps> {
  public render() {
    const { metrics, filter } = this.props

    if (metrics === undefined) {
      return null
    }

    const spendCents = metrics.spend_cents || metrics.projected_spend_cents
    const spendCurrency = metrics.spend_currency || metrics.projected_spend_currency
    const { cost_per_engagement_cents, cost_per_engagement_currency } = metrics

    return (
      <SubmissionMetricsStyled>
        {Boolean(spendCurrency) && (
          <DetailCard
            title={
              <FormattedMessage
                id={`inbox.submission.metrics.spend.${filter}`}
                values={{ taxName: metrics.tax_display_name }}
              />
            }
            content={<MetricValue value={spendCents} currency={spendCurrency} rounded />}
            elementName="metrics-spend"
          />
        )}
        <DetailCard
          title={<FormattedMessage id={`inbox.submission.metrics.collectiveReach`} />}
          content={<MetricValue value={metrics.collective_reach} wholeNumber />}
          elementName="metrics-collective-reach"
        />
        <DetailCard
          title={<FormattedMessage id={`inbox.submission.metrics.engagement.${filter}`} />}
          content={<MetricValue value={metrics.engagement} wholeNumber />}
          elementName="metrics-engagement"
        />
        {Boolean(cost_per_engagement_currency) && (
          <DetailCard
            title={<FormattedMessage id={`inbox.submission.metrics.costPerEngagement.${filter}`} />}
            content={
              <MetricValue
                value={cost_per_engagement_cents}
                currency={cost_per_engagement_currency}
              />
            }
            elementName="metrics-cost-per-engagement"
          />
        )}
      </SubmissionMetricsStyled>
    )
  }
}

interface IMetricValueProps {
  value?: number
  currency?: string
  rounded?: boolean
  wholeNumber?: boolean
}

export const MetricValue = ({ value, currency, rounded, wholeNumber }: IMetricValueProps) => {
  if (isNaN(value!) || value === 0) {
    return <FormattedMessage id="core.metric.empty" />
  } else if (wholeNumber) {
    return <FormattedNumber value={value || 0} />
  }
  return <Price quantity={value || 0} currency={currency!} rounded={rounded} />
}

const mapStateToProps = (state: IApplicationState, ownProps) => ({
  metrics: selectSubmissionMetrics(state, ownProps.campaign.id, ownProps.filter),
})

export default compose<IInternalProps, {}>(
  withCampaign,
  withSubmissionListFilter,
  connect(mapStateToProps, undefined),
)(SubmissionMetrics)
