import React, { PropsWithChildren } from 'react'

interface ICurrentMediaObjectIdContextProps {
  currentMediaObjectId?: number
  setCurrentMediaObjectId: (id?: number) => void
}

const CurrentMediaObjectIdContext = React.createContext({} as ICurrentMediaObjectIdContextProps)

const CurrentMediaObjectIdProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [currentMediaObjectId, setCurrentMediaObjectId] = React.useState<number>()
  return (
    <CurrentMediaObjectIdContext.Provider
      value={{
        currentMediaObjectId,
        setCurrentMediaObjectId,
      }}
    >
      {children}
    </CurrentMediaObjectIdContext.Provider>
  )
}

export { CurrentMediaObjectIdContext, CurrentMediaObjectIdProvider }
