import styled from 'styled-components'
import Flex from 'styled-flex-component'

export const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  background-color: transparent;
`

export const VideoStyled = styled.div`
  padding-bottom: ${({ aspectRatio }) => aspectRatio}%;
  height: 0;
`

export const Video = styled.video`
  width: calc(100% - 0.1px);
  outline: none;
  ${({ aspectRatio }) =>
    aspectRatio &&
    `
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  `}
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`}
`

export const VideoOverlay = styled(Flex)`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 2;
  position: absolute;
`

export const VideoOverlayImage = styled.img`
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`};
  display: block;
  object-fit: contain;
  position: absolute;
  width: 100%;
  height: 100%;
`
