import React, { PropsWithChildren } from 'react'

import { defaultTheme } from '../../Theme'
import { H1 } from '../../Typography'

export interface ICloseIconProps {
  onClick?: VoidFunction
}

interface IModalTitleProps {
  center?: boolean
  bottomOuterSpacing?: number
}

const ModalTitle: React.FC<PropsWithChildren<IModalTitleProps>> = ({
  center,
  children,
  bottomOuterSpacing = 1.75,
}) => (
  <H1
    center={center}
    color={defaultTheme.grey900Color}
    bottomOuterSpacing={bottomOuterSpacing}
    data-testid="modal-title"
  >
    {children}
  </H1>
)

export default ModalTitle
