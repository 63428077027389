import React from 'react'

import Theme from '@theme'
import { Header } from '@tribegroup'
import CampaignPreviewBlockStyled, {
  CampaignPreviewBlockInnerWrapper,
  EmptyLine,
} from './Block.styled'
export interface ICampaignPreviewBlockProps {
  title?: React.ReactNode
  children?: React.ReactNode
  emptyLines?: ReadonlyArray<number>
  empty?: boolean
}

export const EmptyBlock = ({ lines }) => {
  return (
    <div>
      {lines.map((line, index) => (
        <EmptyLine key={`line-${line}-${index}`} width={line} />
      ))}
    </div>
  )
}

export const CampaignPreviewBlock = ({
  title,
  children,
  empty,
  emptyLines,
}: ICampaignPreviewBlockProps) => (
  <CampaignPreviewBlockStyled>
    <CampaignPreviewBlockInnerWrapper>
      {title && (
        <Header small color={Theme.grey900Color} uppercase bottomOuterSpacing={0.125}>
          {title}
        </Header>
      )}
      {empty ? <EmptyBlock lines={emptyLines} /> : children}
    </CampaignPreviewBlockInnerWrapper>
  </CampaignPreviewBlockStyled>
)

export default CampaignPreviewBlock
