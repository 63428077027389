import styled from 'styled-components'

import Theme from '@theme'

export const BetaTagContainer = styled.div`
  display: inline-flex;
  border-radius: 0.25rem;
  border: 1px solid ${Theme.primary200Color};
  background-color: ${Theme.whiteColor};
  padding: 0 0.125rem;
  margin-left: 0.5rem;
`
