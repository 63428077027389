import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Theme from '@theme'

export const BrandCardWrapper = styled.div`
  position: relative;

  .card-menu-toggle {
    transition: opacity 0.2s;
    opacity: 0;
    z-index: 1;

    &[open] {
      opacity: 1;
    }
  }

  &:hover {
    box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 10%);

    .card-menu-toggle {
      opacity: 1;

      &:hover,
      &:active,
      &[open] {
        svg g {
          fill: ${Theme.grey900Color};
        }
      }
    }
  }

  .clamp-lines > div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const BrandName = styled.div`
  margin-top: 1.5rem;
`

export const StyledCardWrapper = styled(Link)`
  position: relative;
  display: block;
  cursor: pointer;
  width: 12rem;
  height: 17.5rem;
  text-align: center;
  background: ${Theme.whiteColor};
  padding: 1.5rem;
  border-radius: 0.25rem;
  border: solid 1px ${Theme.whiteColor};
  transition: box-shadow 0.2s, background 0.2s;
  text-decoration: none;
  ${({ selected }) =>
    selected &&
    css`
      border: solid 1px ${Theme.primaryColor};

      &:hover {
        box-shadow: none;
      }
    `};
`

export const DropdownWrapper = styled.div`
  display: block;
  position: absolute;
  top: 0.625rem;
  right: 0.75rem;
`
