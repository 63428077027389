import { call, put, select, takeEvery } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { IHateoasLink } from '@store'
import { selectCampaignTrackingProps, trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  activate,
  ActivateActionTypes,
  activateError,
  activateSuccess,
  fetchCampaigns,
  ICampaign,
  selectActivateLink,
} from '@store/campaigns'
import { networkRequest } from '@utils'

export function* handleActivate(action: ReturnType<typeof activate>) {
  try {
    const campaignId = action.payload
    const authToken: string = yield select(selectAuthToken)
    const activateLink: IHateoasLink = yield select(selectActivateLink, campaignId)
    const campaign: ICampaign = yield call(
      networkRequest,
      activateLink.method,
      activateLink.href,
      undefined,
      authToken,
    )

    yield put(activateSuccess(campaign))

    const eventProperties = yield select(selectCampaignTrackingProps, campaignId)
    yield put(trackEvent(EventTrackingNames.ActivateCampaign, eventProperties))

    yield put(fetchCampaigns())
  } catch (error) {
    yield put(activateError(error))
  }
}

export function* watchActivateRequest() {
  yield takeEvery(ActivateActionTypes.ACTIVATE_REQUEST, handleActivate)
}
