import React from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import SubmissionCard from '@components/Submission/Card'
import { CardGridWithInfinteScroll } from '@components/UI/CardGrid/CardGrid.styled'
import Loading from '@components/UI/Loading'
import { ICampaignContextProps } from '@context/Campaign'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import { withCampaign, withRememberScrollPosition, withSubmissionListFilter } from '@hocs'
import { useIsMounted } from '@hooks/useIsMounted'
import { IApplicationState } from '@store'
import {
  fetchSubmissions,
  ISubmission,
  selectFilteredSubmissions,
  selectFilteredSubmissionsNextLink,
  selectIsFetching,
  selectIsLoadingInitialList,
} from '@store/submissions'
import { isEmpty } from '@utils'
import SubmissionCardListEmpty from './Empty'

interface IInternalProps extends ISubmissionListFilterContextProps {
  fetchSubmissions: typeof fetchSubmissions
  campaignId: number
  isSocialSubmission?: boolean
  submissions: ReadonlyArray<ISubmission>
  hasMore: boolean
  hasEmptyList?: boolean
  isLoadingInitialList?: boolean
}

const SubmissionCardList: React.FC<IInternalProps> = ({
  campaignId,
  filter,
  sortBy,
  declinedFilter,
  isLoadingInitialList,
  hasEmptyList,
  fetchSubmissions: fetchSubmissionsAction,
  submissions,
  hasMore,
}) => {
  const isMounted = useIsMounted()
  const queryParams = { filter, sortBy, declinedFilter }

  React.useEffect(() => {
    fetchSubmissionsAction(campaignId, queryParams)
  }, [])

  React.useEffect(() => {
    if (isMounted) {
      fetchSubmissionsAction(campaignId, { ...queryParams, resetList: true })
    }
  }, [sortBy])

  React.useEffect(() => {
    if (isMounted) {
      fetchSubmissionsAction(campaignId, { ...queryParams, resetList: false })
    }
  }, [filter, declinedFilter])

  const fetchSubmissionsUsingNext = () => fetchSubmissionsAction(campaignId, queryParams, true)

  if (isLoadingInitialList) {
    return <Loading height={30} />
  }

  if (hasEmptyList) {
    return <SubmissionCardListEmpty submissionFilter={filter} />
  }

  return (
    <CardGridWithInfinteScroll gap={0.9375}>
      <InfiniteScroll
        dataLength={submissions.length}
        next={fetchSubmissionsUsingNext}
        loader={<Loading height={30} />}
        hasMore={hasMore}
        scrollThreshold={0.8}
        style={{ overflow: 'hidden' }}
      >
        <div>
          {submissions.map((submission) => (
            <SubmissionCard key={submission.id} submissionId={submission.id} />
          ))}
        </div>
      </InfiniteScroll>
    </CardGridWithInfinteScroll>
  )
}

interface IStateProps extends ICampaignContextProps, IInternalProps {}

const mapStateToProps = (
  state: IApplicationState,
  { filter, campaign, declinedFilter }: IStateProps,
) => {
  const usedFilter = declinedFilter || filter
  const { id: campaignId } = campaign
  const isFetching = selectIsFetching(state)

  const submissions = selectFilteredSubmissions(state, campaignId, usedFilter)

  const hasMore = Boolean(selectFilteredSubmissionsNextLink(state, campaignId, usedFilter))

  return {
    campaignId,
    submissions,
    isLoadingInitialList: selectIsLoadingInitialList(state, campaignId, usedFilter),
    hasEmptyList: !isFetching && isEmpty(submissions),
    hasMore,
  }
}

const mapDispatchToProps = {
  fetchSubmissions,
}

export default compose<IInternalProps, {}>(
  withCampaign,
  withSubmissionListFilter,
  withRememberScrollPosition,
  connect(mapStateToProps, mapDispatchToProps),
)(SubmissionCardList)
