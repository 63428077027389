import React from 'react'

export const PostCampaignReportSubmissionIdContext = React.createContext({})

interface IPostCampaignReportSubmissionIdProviderProps {
  submissionId: number
  children: React.ReactNode
}

export interface IPostCampaignReportSubmissionIdContextProps {
  contextPostCampaignReportSubmissionId: number
}

export const PostCampaignReportSubmissionIdProvider = ({
  submissionId,
  children,
}: IPostCampaignReportSubmissionIdProviderProps) => (
  <PostCampaignReportSubmissionIdContext.Provider
    value={
      {
        contextPostCampaignReportSubmissionId: submissionId,
      } as IPostCampaignReportSubmissionIdContextProps
    }
  >
    {children}
  </PostCampaignReportSubmissionIdContext.Provider>
)
