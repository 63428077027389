import { SocialSubmissionTypes, SubmissionChannels } from '@enums'
import {
  getEngagementMetricEntries,
  getOverviewMetricEntries,
  getPerformanceMetricEntries,
} from './mapping-functions'
import { AnalyticsData, AnalyticsPerspective } from './types'

export const useAnalyticsBreakdown = (
  analytics: Partial<AnalyticsData>,
  socialSubmissionType: SocialSubmissionTypes,
  type: AnalyticsPerspective,
  isProxy = false,
  channel: SubmissionChannels | undefined = undefined,
) => {
  return {
    overview: getOverviewMetricEntries(analytics, socialSubmissionType, type, isProxy, channel),
    performance: getPerformanceMetricEntries(
      analytics,
      socialSubmissionType,
      type,
      isProxy,
      channel,
    ),
    engagement: getEngagementMetricEntries(analytics, socialSubmissionType, type, isProxy, channel),
  }
}
