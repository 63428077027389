import { call, put, select, takeLatest } from 'redux-saga/effects'

import { ToastTemplateWarning } from '@components/UI/ToastTemplate'
import { DUPLICATE_TIKTOK_ACCOUNT_ERROR } from '@constants'
import { BrandManagerIdentityProviders } from '@enums'
import { selectAuthToken } from '@store/auth'
import {
  connectAdAccount,
  ConnectBrandManagerIdentities,
  connectIdentitiesError,
  connectIdentitiesSuccess,
  fetchIdentities,
  selectConnectIdentitiesLink,
} from '@store/brandManagerIdentities'
import { IHateoasLink } from '@store/index'
import { Toaster } from '@tribegroup'
import networkRequest from '@utils/networkRequest'

export function* handleConnectAdAccount(action: ReturnType<typeof connectAdAccount>) {
  const authCode = action.payload

  try {
    const authToken: string = yield select(selectAuthToken)

    const connectIdentitiesLink: IHateoasLink = yield select(selectConnectIdentitiesLink)

    yield call(
      networkRequest,
      connectIdentitiesLink.method,
      connectIdentitiesLink.href,
      {
        identities: [
          {
            provider: BrandManagerIdentityProviders.TikTok,
            oauth_token: authCode,
          },
        ],
      },
      authToken,
    )

    yield put(fetchIdentities())
    yield put(connectIdentitiesSuccess())
  } catch (error) {
    const errorCode = error.messages?.[0]?.error_code || error.status
    if (errorCode && errorCode !== DUPLICATE_TIKTOK_ACCOUNT_ERROR) {
      yield call(
        Toaster.createToast,
        ToastTemplateWarning,
        {
          i18nKey: `connectedAccounts.error.${errorCode}`,
        },
        false,
        { timeout: 10000 },
      )
    }
    yield put(connectIdentitiesError(error))
  }
}

export function* watchConnectAdAccountRequest() {
  yield takeLatest(ConnectBrandManagerIdentities.CONNECT_AD_ACCOUNT_REQUEST, handleConnectAdAccount)
}
