enum LicenseStatuses {
  Requested = 'requested',
  Declined = 'declined',
  Licensed = 'licensed',
  LicenseExpired = 'license_expired',
  RequestExpired = 'request_expired',
  ExpiredDeprecated = 'expired',
  Preapproved = 'preapproved',
  Cancelled = 'cancelled',
  DeclinedByBrand = 'declined_by_brand',
}

export { LicenseStatuses }
