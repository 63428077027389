import { useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import Loading from '@components/UI/Loading'
import Price from '@components/UI/Price/Price'
import { IApplicationState } from '@store'
import {
  fetchMediaObjectLicenseOptions,
  selectIsFetching,
  selectLicenseOptionAmountBeforeTaxCurrency,
  selectMinimumLicenseAmount,
} from '@store/licenses'
import { selectFirstMediaObject, selectTaxDisplayName } from '@store/submissions'
import Theme from '@theme'
import { Container, Header, Text } from '@tribegroup'

interface IProps {
  submissionId: number
}

export const SubmissionFeedbackNegotiationContentCoachMark: React.FC<IProps> = ({
  submissionId,
}) => {
  const { isFetching, fromPrice, mediaObjectId, fromPriceCurrency, taxDisplayName } = useSelector(
    (state: IApplicationState) => {
      const { id: mediaObjectId } = selectFirstMediaObject(state, submissionId)

      return {
        mediaObjectId,
        isFetching: selectIsFetching(state),
        fromPrice: selectMinimumLicenseAmount(state, mediaObjectId),
        fromPriceCurrency: selectLicenseOptionAmountBeforeTaxCurrency(state, mediaObjectId!, 0),
        taxDisplayName: selectTaxDisplayName(state, submissionId),
      }
    },
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (!fromPrice && fromPrice !== 0) {
      dispatch(fetchMediaObjectLicenseOptions(mediaObjectId))
    }
  }, [mediaObjectId])

  return (
    <div>
      <Header small uppercase bottomOuterSpacing={1.25} color={Theme.primaryColor}>
        <FormattedMessage id="submission.feedback.form.popup.negotiatingprice" />
      </Header>
      <Container bottomOuterSpacing={1.25}>
        <Text color={Theme.primaryColor}>
          <FormattedMessage id="submission.content.feedback.form.popup.text" />
        </Text>
      </Container>

      {isFetching && <Loading />}

      {fromPrice && (
        <Container flexBetween bottomOuterSpacing={0.25}>
          <Text block capitalize color={Theme.grey900Color}>
            <FormattedMessage id="core.text.from" />{' '}
            <Price currency={fromPriceCurrency} quantity={fromPrice} rounded />
          </Text>
          <Text color={Theme.grey900Color}>
            <FormattedMessage
              id="submission.feedback.negotiation.tax.text"
              values={{ taxName: taxDisplayName }}
            />
          </Text>
        </Container>
      )}
    </div>
  )
}

export default SubmissionFeedbackNegotiationContentCoachMark
