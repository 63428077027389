import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Price from '@components/UI/Price/Price'
import { IApplicationState } from '@store'
import { selectCPE, selectCPECurrency } from '@store/submissions'
import Theme from '@theme'
import { H5 } from '@tribegroup'

interface IProps {
  submissionId: number
}

interface IInternalProps extends IProps {
  currency: string
  cpe: number
}

export const SubHeaderLabelCPE: React.FC<IInternalProps> = ({ cpe, currency }) => {
  return (
    <H5 color={Theme.grey700Color} uppercase inline light>
      {Boolean(cpe) && (
        <React.Fragment>
          <FormattedMessage id="submission.performance.cpe" />
          &nbsp;
          <Price quantity={cpe} currency={currency} />
        </React.Fragment>
      )}
    </H5>
  )
}

const mapStateToProps = (state: IApplicationState, { submissionId }: IProps) => ({
  cpe: selectCPE(state, submissionId),
  currency: selectCPECurrency(state, submissionId),
})

export default compose<IInternalProps, IProps>(connect(mapStateToProps))(SubHeaderLabelCPE)
