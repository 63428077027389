import { call, put, select, takeEvery } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  FetchInfluencerIdentitySubmissionsActionTypes,
  fetchSubmissions,
  fetchSubmissionsError,
  fetchSubmissionsSuccess,
  selectIdentitySubmissionsLink,
} from '@store/brandFans'
import { selectCurrentBrandFansFilter, selectCurrentBrandFilter } from '@store/router'
import { networkRequest } from '@utils'

export function* handleFetchSubmissions(action: ReturnType<typeof fetchSubmissions>) {
  const identityId = action.payload
  try {
    const authToken: string = yield select(selectAuthToken)
    const brandFilter = yield select(selectCurrentBrandFilter)
    const brandFansFilter = yield select(selectCurrentBrandFansFilter)
    const submissionsLink: IHateoasLink = yield select(selectIdentitySubmissionsLink, identityId)
    const { items: submissions } = yield call(
      networkRequest,
      submissionsLink.method,
      submissionsLink.href
        .replace('{brand_filter}', brandFilter)
        .replace('{brand_fans_filter}', brandFansFilter),
      undefined,
      authToken,
    )
    yield put(fetchSubmissionsSuccess(identityId, submissions))
  } catch (error) {
    yield put(fetchSubmissionsError(identityId, error))
  }
}

export function* watchFetchSubmissionsRequest() {
  yield takeEvery(
    FetchInfluencerIdentitySubmissionsActionTypes.FETCH_REQUEST,
    handleFetchSubmissions,
  )
}
