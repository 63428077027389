import { IApplicationState } from '@store'

export const selectIsFetchingAudienceReporting = (state: IApplicationState) =>
  state.audienceReporting.isFetching

export const selectIsErrorAudienceReporting = (state: IApplicationState) =>
  Boolean(state.audienceReporting.errors)

export const selectAudienceReporting = (state: IApplicationState, campaignId: number) =>
  state.audienceReporting.audienceReportingByCampaignId[campaignId] || {}
