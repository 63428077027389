import { isAfter, isPast } from 'date-fns'
import { IntlShape } from 'react-intl'
import { createSelector } from 'reselect'

import { EMPTY_LIST, INBOX_FILTER, INBOX_VIEW } from '@constants'
import {
  AllSubmissionStatuses,
  BrandedContentAdStatusTypes,
  ErrorCodes,
  LicenseStatuses,
  MediaTypes,
  RescheduleTypes,
  SocialSubmissionTypes,
  SubmissionChannels,
  SubmissionDeclineStatuses,
  SubmissionStatuses,
} from '@enums'
import { IApplicationState, IFeatureHateoasLink, IHateoasLink } from '@store'
import {
  selectBrandFanMembersByIdentity,
  selectBrandFanMembersByInfluencer,
} from '@store/brandFans'
import {
  ICampaignPreferences,
  selectBulkAddFeedbackLink,
  selectBulkDeclineLink,
  selectCampaignPreferences,
  selectHasBrandPartnershipHandle,
  selectIsSamplingEnabled,
  selectIsSocialCampaign,
  selectSubmissionsLink as selectSubmissionsLinkFromCampaigns,
  selectUpdatePreferencesLink,
} from '@store/campaigns'
import { selectHasInsufficientFundsForLicense } from '@store/licenses'
import {
  IMediaObject,
  selectFirstMediaObjectWithTransactedLicense,
  selectIsLicenseDeclined,
  selectIsLicenseExpired,
  selectMediaObjectIsRequestingLicense,
  selectMediaType,
} from '@store/mediaObjects'
import {
  selectAllowResubmittedLabel,
  selectCurrentFilter,
  selectCurrentSortBy,
  selectCurrentSubmissionFilter,
  selectCurrentView,
  selectIsBCAFilter,
  selectIsInfluencerView,
} from '@store/router'
import {
  IBrandedContentAdRequest,
  IPerformanceAnalytics,
  ISubmission,
  ISubmissionFrame,
  ISubmissionsMetadataStats,
} from '@store/submissions'
import { getMessage, getSubmissionFlags, isEmpty, selectNextId, selectPrevId } from '@utils'

const selectState = (state: IApplicationState) => state

export const selectIsFetching = (state: IApplicationState) => state.submissions.isFetching

export const selectIsApproving = (state: IApplicationState) => state.submissions.isApproving

export const selectIsPreApproving = (state: IApplicationState) => state.submissions.isPreApproving

export const selectIsDeclining = (state: IApplicationState) => state.submissions.isDeclining

export const selectIsRescheduling = (state: IApplicationState) => state.submissions.isRescheduling

export const selectSubmissionsById = (state: IApplicationState) => state.submissions.submissionsById

export const selectIsRequestingBrandedContentAd = (state: IApplicationState) =>
  state.submissions.isRequestingBrandedContentAd

export const selectErrors = (state: IApplicationState) => state.submissions.errors

export const selectErrorMessages = (state: IApplicationState) => {
  const error = selectErrors(state)
  return error && error.messages
}

export const selectSubmission = (state: IApplicationState, submissionId: number) =>
  state.submissions.submissionsById[submissionId] || {}

export const selectFilteredSubmissionIds = (
  state: IApplicationState,
  campaignId: number,
  filter: string = INBOX_FILTER,
) =>
  (state.submissions.submissionIdsByFilter[campaignId] &&
    state.submissions.submissionIdsByFilter[campaignId][filter]) ||
  EMPTY_LIST

export const selectFilteredSubmissions = createSelector(
  selectFilteredSubmissionIds,
  (state) => state.submissions.submissionsById,
  (submissionIds, submissionsById) => submissionIds.map((id) => submissionsById[id]),
)

export const selectSubmissionsLink = (
  state: IApplicationState,
  campaignId: number,
  filter: string,
  linkRel: string,
) => {
  const submissionLinksByFilters = state.submissions.submissionLinksByFilter
  const submissionsLinks = submissionLinksByFilters?.[campaignId]?.[filter] || EMPTY_LIST
  return submissionsLinks.find((link) => link.rel === linkRel)
}

export const selectFilteredSubmissionSelfLink = (
  state: IApplicationState,
  campaignId: number,
  filter: string,
) => {
  return selectSubmissionsLink(state, campaignId, filter, 'self')
}

export const selectFilteredSubmissionsNextLink = (
  state: IApplicationState,
  campaignId: number,
  filter: string,
) => {
  return selectSubmissionsLink(state, campaignId, filter, 'next')
}

export const selectFilteredDeclinedSubmissionsLink = (
  state: IApplicationState,
  campaignId: number,
) =>
  selectSubmissionsLink(
    state,
    campaignId,
    SubmissionStatuses.Declined,
    'filtered_declined_submissions',
  )

export const selectFilteredDeclinedSubmissionsLinkFilters = createSelector(
  selectFilteredDeclinedSubmissionsLink,
  (link: IHateoasLink) => link?.filter || EMPTY_LIST,
)

export const selectFilteredSubmissionsSortOptions = (
  state: IApplicationState,
  campaignId: number,
  filter: string,
) => {
  const sortOptions = (selectSubmissionsLink(state, campaignId, filter, 'sort_by') || {
    sort_by: [],
  }) as IHateoasLink
  return sortOptions
}

export const selectSubmissionsStats = (state: IApplicationState, campaignId: number): object => {
  const metadata = state.submissions.submissionsMetadataByCampaignId[campaignId]
  return (metadata && metadata.stats) || {}
}

export const selectSubmissionsMetadata = (state: IApplicationState, campaignId: number) => {
  const metadata = state.submissions.submissionsMetadataByCampaignId[campaignId]
  return metadata ?? {}
}

export const selectHasShortlistedProxySubmission = createSelector(
  selectSubmissionsMetadata,
  (metadata) => Boolean(metadata?.shortlisted_submissions_stats?.has_proxy_submissions),
)

export const selectSubmissionsTotalValue = createSelector(
  selectSubmissionsMetadata,
  (metadata) => metadata.total_value,
)

export const selectSubmissionMetrics = (
  state: IApplicationState,
  campaignId: number,
  status: string,
) => {
  const key = `${status}_submissions_stats`
  const metadata = state.submissions.submissionsMetadataByCampaignId[campaignId]
  return metadata && metadata[key]
}

export const selectApprovedSubmissionsCount = createSelector(
  selectSubmissionsStats,
  (stats: ISubmissionsMetadataStats) => stats.approved || 0,
)

export const selectSubmissionLinks = (state: IApplicationState, submissionId: number) =>
  selectSubmission(state, submissionId).links || []

export const selectSubmissionLink = (
  state: IApplicationState,
  submissionId: number,
  linkRel: string,
) => selectSubmissionLinks(state, submissionId).find((link) => link.rel === linkRel)

export const selectShortlistLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'shortlist')

export const selectUnshortlistLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'unshortlist')

export const selectApproveLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'request_approve3ds')

export const selectPreApproveLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'preapprove')

export const selectDeclineLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'decline')

export const selectFeedbackLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'feedbacks')

export const selectRescheduleLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'reschedule')

export const selectNotesLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'notes')

export const selectLicensesLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'license_options')

export const selectLicenseOptionsLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'license_options')

export const selectAudienceInsightsLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'identity_audience_insights') as IFeatureHateoasLink

export const selectCreatorBioLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'creator_bio')

export const selectUploadToFacebookLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'upload_to_facebook')

export const selectViewSocialLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'view_social_profile')

export const selectSubmissionPerformanceLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'published_submission_performance')

export const selectSubmissionIdentityLink = createSelector(selectSubmissionLinks, (links) =>
  links.find((link) => link.rel === 'submission_identity'),
)

export const selectPromoteAsAdLink = createSelector(selectSubmissionLinks, (links) =>
  links.find((link) => link.rel === 'promote_as_ad'),
)

export const selectBrandFansMembersLink = createSelector(selectSubmissionLinks, (links) =>
  links.find((link) => link.rel === 'brand_fan_members'),
)

export const selectReportMetricIssueLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'report_metric_issue')

export const selectPrePurchaseLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'pre_purchase_analytics')

const selectIdentityAdapter = (state: IApplicationState, identityId: number) => identityId

export const selectAudienceInsightsLinkByIdentityId = createSelector(
  selectSubmissionsById,
  selectIdentityAdapter,
  (submissionsById: Record<number, ISubmission>, identityId: number) => {
    const targetSubmission = Object.values(submissionsById).find(
      (submission) => submission.identity_id === identityId,
    )
    return (
      (targetSubmission &&
        (targetSubmission.links!.find(
          (link) => link.rel === 'identity_audience_insights',
        ) as IFeatureHateoasLink)) ||
      undefined
    )
  },
)

export const selectErrorMessage = (state: IApplicationState) => {
  const errorMessages = selectErrorMessages(state)

  if (isEmpty(errorMessages)) {
    return undefined
  }

  if (typeof errorMessages === 'string') {
    return errorMessages
  }

  return errorMessages && errorMessages[0].message
}

export const selectErrorCode = (state: IApplicationState) => {
  const errorMessages = selectErrorMessages(state)

  if (isEmpty(errorMessages) || typeof errorMessages === 'string') {
    return undefined
  }

  return errorMessages && errorMessages[0].error_code
}

export const selectHasInsufficientFundsForSubmission = createSelector(
  selectErrorCode,
  (submissionErrorCode: string) =>
    [
      ErrorCodes.ERROR_CODE_APPROVE_INSUFFICIENT_FUNDS,
      ErrorCodes.ERROR_CODE_APPROVE_3DS_INSUFFICIENT_FUNDS,
    ].some((errorCode: string) => errorCode === submissionErrorCode),
)

export const selectDefaultSortByFilter = (state: IApplicationState) =>
  state.submissions.selectedSortByFilter

export const selectAllSubmissionIds = createSelector(
  selectFilteredSubmissions,
  (state, campaignId, filter) => filter || INBOX_FILTER,
  (filteredSubmissions, filter) =>
    filteredSubmissions
      .filter((submission) => (filter === INBOX_FILTER ? !submission.shortlisted : true))
      .map((submission) => submission.id),
)

export const selectPrevSubmissionId = createSelector(
  (state, campaignId, filter, submissionId: number) => submissionId,
  selectFilteredSubmissionIds,
  (submissionId, ids) => selectPrevId(ids, submissionId),
)

export const selectNextSubmissionId = createSelector(
  (state, campaignId, filter, submissionId: number) => submissionId,
  selectFilteredSubmissionIds,
  (submissionId, ids) => selectNextId(ids, submissionId),
)

export const selectInfluencerSubmissionsLink = (state: IApplicationState, submissionId: number) =>
  selectSubmissionLink(state, submissionId, 'influencer_brief_submissions')

export const selectInfluencerSubmissionIds = (
  state: IApplicationState,
  influencerId: number,
  campaignId: number,
): ReadonlyArray<number> => {
  const campaignInfluencers = state.submissions.submissionsByInfluencerId[campaignId]
  if (!campaignInfluencers) {
    return EMPTY_LIST
  }
  return campaignInfluencers[influencerId] || EMPTY_LIST
}

export const selectFirstMediaObject = createSelector(
  selectSubmission,
  (submission): ISubmissionFrame => (submission.media_objects && submission.media_objects[0]) || {},
)

export const selectFirstMediaObjectId = createSelector(
  selectFirstMediaObject,
  (mediaObject: ISubmissionFrame) => mediaObject.id,
)

export const selectMediaObjects = createSelector(
  selectSubmission,
  (submission: ISubmission): ReadonlyArray<ISubmissionFrame> => submission.media_objects,
)

const selectActiveFrameAdapter = (state: any, submissionId: any, activeFrameNumber?: number) =>
  activeFrameNumber

export const selectSubmissionMediaObject = createSelector(
  selectMediaObjects,
  selectActiveFrameAdapter,
  (mediaObjects, activeFrameNumber) =>
    mediaObjects?.find((mediaObject) => mediaObject.frame_number === activeFrameNumber) ||
    mediaObjects[0],
)

export const selectSubmissionMediaObjectIds = createSelector(
  selectMediaObjects,
  (mediaObjects) => mediaObjects.map((mediaObject) => mediaObject.id) || [],
)

export const selectMediaThumbnail = createSelector(
  selectFirstMediaObject,
  (media: ISubmissionFrame) => media.thumbnail_media_url,
)

export const selectSocialSubmissionType = createSelector(
  selectSubmission,
  (submission) => submission.social_submission_type || SocialSubmissionTypes.Standard,
)

export const selectIsStorySocialSubmission = createSelector(
  selectSocialSubmissionType,
  (submissionType: SocialSubmissionTypes) => submissionType === SocialSubmissionTypes.Story,
)

export const selectIsReelSocialSubmission = createSelector(
  selectSocialSubmissionType,
  (submissionType: SocialSubmissionTypes) => submissionType === SocialSubmissionTypes.Reel,
)

const selectBrandFanGroupIdsByIdentity = (state: IApplicationState) =>
  state.submissions.brandFanGroupIdsByIdentityId

export const selectIdentityBrandFanGroupIds = createSelector(
  selectSubmission,
  selectBrandFanGroupIdsByIdentity,
  (submission: ISubmission, brandFanGroupIdsByIdentityId: Record<number, ReadonlyArray<number>>) =>
    (submission &&
      submission.identity_id &&
      brandFanGroupIdsByIdentityId[submission.identity_id]) ||
    EMPTY_LIST,
)

const selectIsSocialSubmission = createSelector(selectSubmission, (submission) =>
  Boolean(submission.identity_id),
)

export const selectIsResubmitted = createSelector(selectSubmission, (submission) =>
  Boolean(submission.resubmitted),
)

export const selectIsInfluencerOutsideTimezone = createSelector(selectSubmission, (submission) =>
  Boolean(submission.influencer_outside_timezone),
)

export const selectShowSamplingHint = createSelector(
  (state: IApplicationState, campaignId: number) => selectIsSamplingEnabled(state, campaignId),
  (state: IApplicationState, _, submissionid: number) => selectIsResubmitted(state, submissionid),
  (isSamplingEnabled, isResubmitted) => isSamplingEnabled && !isResubmitted,
)

export const selectHasCreatorNotes = createSelector(selectMediaObjects, (mediaObjects) =>
  mediaObjects.some((mediaObject) => Boolean(mediaObject.creator_note)),
)

export const selectFirstNoteFrameNumber = createSelector(selectMediaObjects, (mediaObjects) => {
  const firstFrameWithNote = mediaObjects.find((mediaObject) => Boolean(mediaObject.creator_note))
  return firstFrameWithNote && firstFrameWithNote.frame_number
})

export const selectIsNewInfluencer = createSelector(selectSubmission, (submission): boolean =>
  Boolean(submission.influencer_new),
)

export const selectScheduledPublishDate = createSelector(
  selectSubmission,
  (submission: ISubmission) =>
    submission.scheduled_publish_date && new Date(submission.scheduled_publish_date),
)

export const selectPostMissed = createSelector(selectScheduledPublishDate, (scheduledPublishDate) =>
  Boolean(scheduledPublishDate && isPast(scheduledPublishDate)),
)

export const selectRescheduleType = createSelector(
  selectScheduledPublishDate,
  (appState, submissionId, newScheduledPublishDate: string | undefined) =>
    newScheduledPublishDate && new Date(newScheduledPublishDate),
  (currentPublishDate, newPublishDate) => {
    if (newPublishDate) {
      if (currentPublishDate) {
        return isAfter(newPublishDate, currentPublishDate)
          ? RescheduleTypes.LaterDate
          : RescheduleTypes.EarlierDate
      }
      return RescheduleTypes.LaterDate
    }
    return RescheduleTypes.Within48Hours
  },
)

export const selectShortlisted = createSelector(selectSubmission, (submission) =>
  Boolean(submission.shortlisted),
)

export const selectSubmissionStatus = createSelector(
  selectSubmission,
  (submission) => submission.status,
)

const getCardStatusLabel = (params: {
  isBCAFilter: boolean
  isResubmitted: boolean
  isLicenseExpired: boolean
  isLicenseDeclined: boolean
}) => {
  const { isBCAFilter, isLicenseDeclined, isLicenseExpired, isResubmitted } = params
  if (isResubmitted) {
    return 'resubmitted'
  }
  if (isBCAFilter) {
    return undefined
  }
  if (isLicenseExpired) {
    return LicenseStatuses.LicenseExpired
  }
  if (isLicenseDeclined) {
    return LicenseStatuses.Declined
  }
  return undefined
}

export const selectMediaObjectStatusLabel = createSelector(
  selectIsBCAFilter,
  selectIsResubmitted,
  (state, submissionId, mediaObjectId) => selectIsLicenseExpired(state, mediaObjectId),
  (state, submissionId, mediaObjectId) => selectIsLicenseDeclined(state, mediaObjectId),
  selectAllowResubmittedLabel,
  (isBCAFilter, isResubmitted, isLicenseExpired, isLicenseDeclined, allowResubmitted) =>
    getCardStatusLabel({
      isBCAFilter,
      isResubmitted: isResubmitted && allowResubmitted,
      isLicenseExpired,
      isLicenseDeclined,
    }),
)

export const selectSubTotalCents = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.total_amount_before_tax_cents,
)

export const selectSubTotalCurrency = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.total_amount_before_tax_currency,
)

export const selectServiceFeeCents = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.service_fee_cents,
)

export const selectTaxDisplayName = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.tax_display_name,
)

export const selectAmountCurrency = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.amount_currency,
)

export const selectAmountCents = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.amount_cents,
)

export const selectIdentityFirstName = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.influencer_first_name,
)

export const selectIdentityId = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.identity_id,
)

export const selectIdentityUsername = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.identity_username,
)

export const selectIdentityBrandFanGroupsCount = createSelector(
  selectIdentityBrandFanGroupIds,
  (ids: ReadonlyArray<number>) => ids.length,
)

export const selectIdentitySocialUrl = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.identity_social_page_url,
)

export const selectIdentityAvatarSrc = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.identity_avatar,
)

export const selectInfluencerInitials = createSelector(
  selectSubmission,
  (submission: ISubmission) => {
    return [submission.influencer_first_name, submission.influencer_last_name].map(
      (name) => name && name.charAt(0),
    )
  },
)

export const selectInfluencerId = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.influencer_id,
)

export const selectInfluencerFullName = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.influencer_full_name,
)

export const selectInfluencerHeadshot = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.influencer_headshot,
)

export const selectIdentityFollowersCount = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.identity_followers_count as number,
)

export const selectInfluencerIsProxy = createSelector(selectSubmission, (submission) =>
  Boolean(submission.influencer_is_proxy),
)

export const selectIdentityProvider = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.identity_provider,
)

export const selectIsLoadingInitialList = createSelector(
  selectIsFetching,
  selectFilteredSubmissionIds,
  (state, campaignId: number, filter) => filter,
  (isFetching, submissions, filter) => !filter || (isFetching && isEmpty(submissions)),
)

export const selectIsLoadingMoreSubmissions = createSelector(
  selectFilteredSubmissionsNextLink,
  selectIsFetching,
  selectPrevSubmissionId,
  selectNextSubmissionId,
  (nextSubmissionLink: IHateoasLink, isFetching: boolean, prevId: number, nextId: number) => {
    return Boolean(nextSubmissionLink) && prevId && !nextId && isFetching
  },
)

export const selectIsLoadingSubmissionPage = createSelector(
  selectIsLoadingMoreSubmissions,
  (state, filteredIds, submissionId) => isEmpty(submissionId),
  (isLoadingMore, submissionNotLoaded) => submissionNotLoaded || isLoadingMore,
)

export const selectLoadMoreSubmissions = createSelector(
  selectIsFetching,
  selectFilteredSubmissionsNextLink,
  selectNextSubmissionId,
  (isFetching, nextSubmissionLink, nextSubmissionId) =>
    Boolean(nextSubmissionLink) && !nextSubmissionId && !isFetching,
)

export const selectIsPreviouslyPurchased = createSelector(selectSubmission, (submission) =>
  Boolean(submission.previously_purchased),
)

export const selectIsPriceHidden = createSelector(selectSubmission, (submission) => {
  if (submission.is_price_hidden === undefined) {
    return false
  }
  return submission.is_price_hidden
})

export const shouldShowSubmissionPrice = createSelector(
  selectSubmission,
  selectCurrentView,
  selectIsPriceHidden,
  (submission: ISubmission, currentView: string, isPriceHidden) => {
    if (isPriceHidden) {
      return false
    }
    const [firstMediaObject] = submission.media_objects || EMPTY_LIST
    const hasPrice =
      !isEmpty(submission?.total_amount_before_tax_cents) ||
      !isEmpty(firstMediaObject?.transacted_license?.total_amount_before_tax_cents)
    return hasPrice && currentView === INBOX_FILTER
  },
)

export const selectIsFetchingSubmissionsByInfluencerIdentity = (
  state: IApplicationState,
  influencerIdentityId: number,
) => {
  return state.submissions.pendingRequestsByInfluencerIdentityId.includes(influencerIdentityId)
}

export const selectSubmissionIdsByInfluencerIdentity = (
  state: IApplicationState,
  campaignId: number,
  influencerIdentityId: number,
) => {
  return (
    (state.submissions.submissionsByInfluencerIdentityId[campaignId] &&
      state.submissions.submissionsByInfluencerIdentityId[campaignId][influencerIdentityId]) ||
    EMPTY_LIST
  )
}

export const selectHasAnyNotes = createSelector(selectSubmission, (submission: ISubmission) =>
  Boolean(submission.notes_any),
)

export const selectCPE = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.cpe_cents,
)

export const selectCPECurrency = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.cpe_currency,
)

export const selectEstimatedCPE = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.estimated_cpe_cents,
)

export const selectEstimatedCPECurrency = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.estimated_cpe_currency,
)

export const selectInfluencerIdentityPrevSubmissionId = createSelector(
  selectSubmissionIdsByInfluencerIdentity,
  (state, campaignId, influencerIdentityId, submissionId) => submissionId,
  (ids, submissionId) => selectPrevId(ids, submissionId),
)

export const selectInfluencerIdentityNextSubmissionId = createSelector(
  selectSubmissionIdsByInfluencerIdentity,
  (state, campaignId, influencerIdentityId, submissionId) => submissionId,
  (ids, submissionId) => selectNextId(ids, submissionId),
)

export const selectFromSubmissionId = (searchParams, submissionId) =>
  parseInt(searchParams.from, 10) || submissionId

export const selectHasSubmissionPerformanceLink = createSelector(
  selectSubmissionPerformanceLink,
  (submissionPerformanceLink: IHateoasLink) => Boolean(submissionPerformanceLink),
)

export const selectHasAudienceInsightsLink = createSelector(
  selectAudienceInsightsLink,
  (audienceInsightsLink: IHateoasLink) => Boolean(audienceInsightsLink),
)

export const selectIsCarousel = createSelector(
  selectSocialSubmissionType,
  (socialSubmissionType) => socialSubmissionType === SocialSubmissionTypes.Carousel,
)

export const selectActiveNoteFrameNumber = createSelector(
  selectFirstNoteFrameNumber,
  selectIsCarousel,
  selectMediaObjects,
  selectActiveFrameAdapter,
  (firstFrameNumber, isCarousel, mediaObjects, activeFrameNumber) => {
    if (isCarousel) {
      const activeFrameCreatorNote = mediaObjects.find(
        (mediaObject) => mediaObject.frame_number === activeFrameNumber,
      )
      if (activeFrameCreatorNote && activeFrameCreatorNote.creator_note) {
        return activeFrameNumber
      }
    }
    return firstFrameNumber
  },
)

export const selectAllowSubmissionsMultiSelect = createSelector(
  selectIsInfluencerView,
  selectCurrentSubmissionFilter,
  selectBulkAddFeedbackLink,
  selectBulkDeclineLink,
  (isInfluencerView, filter, feedbackLink, declineLink) => {
    const notLicenseRequest = filter !== SubmissionStatuses.LicenseRequest
    const hasFeedbackOrDeclineLink = Boolean(feedbackLink) || Boolean(declineLink)
    return notLicenseRequest && !isInfluencerView && hasFeedbackOrDeclineLink
  },
)

export const selectIsSubmissionFromInstagram = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.identity_provider === SubmissionChannels.Instagram,
)

export const selectIsSubmissionFromFacebook = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.identity_provider === SubmissionChannels.Facebook,
)

export const selectIsSubmissionFromTwitter = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.identity_provider === SubmissionChannels.Twitter,
)

export const selectIsSubmissionFromTiktok = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.identity_provider === SubmissionChannels.TikTok,
)

export const selectIsSubmissionFromPinterest = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.identity_provider === SubmissionChannels.Pinterest,
)

export const selectIsSubmissionFromX = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.identity_provider === SubmissionChannels.X,
)

export const selectSubmissionCardSubheaderAsCPE = createSelector(
  selectCurrentSortBy,
  (sortBy: string) => 'cpe' === sortBy,
)

export const selectSubmissionCardSubheaderAsEstimatedCPE = createSelector(
  selectCurrentSortBy,
  (sortBy: string) => 'estimated_cpe' === sortBy,
)

export const selectSubmissionCardSubheaderAsEngagementPercent = createSelector(
  selectCurrentSortBy,
  (sortBy: string) => 'engagement_percentage' === sortBy,
)

export const selectShowTurnOffRequireApprovals = createSelector(
  (state: IApplicationState, _, submissionId: number) =>
    selectIdentityProvider(state, submissionId),
  selectCampaignPreferences,
  selectHasBrandPartnershipHandle,
  selectUpdatePreferencesLink,
  (
    identityProvider: SubmissionChannels,
    preferences: ICampaignPreferences,
    hasBrandPaidPartnershipHandle?: boolean,
    updatePreferencesLink?: boolean,
  ) => {
    const isInstagramIdentity = identityProvider === SubmissionChannels.Instagram

    return (
      Boolean(updatePreferencesLink) &&
      Boolean(hasBrandPaidPartnershipHandle) &&
      preferences.require_approval_selected_option === undefined &&
      isInstagramIdentity
    )
  },
)

export const selectTransactedBrandedContentAd = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.transacted_bca_request || {},
)

export const selectTransactedBrandedContentAdStatus = createSelector(
  selectTransactedBrandedContentAd,
  (brandedContentAd: IBrandedContentAdRequest) => brandedContentAd.status,
)

export const selectShowBrandedContentAdActions = createSelector(
  selectSubmission,
  selectPromoteAsAdLink,
  (submission: ISubmission, link?: IHateoasLink) => {
    return Boolean(submission.transacted_bca_request) || Boolean(link)
  },
)

export const selectIsBrandedContentAdStatusApprovedOrCompleted = createSelector(
  selectTransactedBrandedContentAdStatus,
  (bcaStatus?: BrandedContentAdStatusTypes) => {
    const approvedOrCompletedStatus: ReadonlyArray<BrandedContentAdStatusTypes> = [
      BrandedContentAdStatusTypes.Completed,
      BrandedContentAdStatusTypes.Approved,
    ]
    return approvedOrCompletedStatus.includes(bcaStatus as BrandedContentAdStatusTypes)
  },
)

export const selectShowBrandedContentAdBar = createSelector(
  selectSubmissionStatus,
  (state, submissionId, isSocial) => isSocial,
  selectCurrentSubmissionFilter,
  (status, isSocial, currentFilter) => {
    if (!isSocial) {
      return false
    }

    if (
      [SubmissionStatuses.Licensed, SubmissionStatuses.LicenseRequest].includes(
        currentFilter as SubmissionStatuses,
      )
    ) {
      return true
    }

    return status === SubmissionStatuses.Published
  },
)

export const selectTransactedBrandedContentAdAmountCents = createSelector(
  selectTransactedBrandedContentAd,
  (brandedContentAd: IBrandedContentAdRequest) => brandedContentAd.amount_cents,
)

export const selectTransactedBrandedContentAdAmountCurrency = createSelector(
  selectTransactedBrandedContentAd,
  (brandedContentAd: IBrandedContentAdRequest) => brandedContentAd.amount_currency,
)

export const selectTransactedBrandedContentAdDurationInDays = createSelector(
  selectTransactedBrandedContentAd,
  (brandedContentAd: IBrandedContentAdRequest) => brandedContentAd.duration_in_days,
)

export const selectTransactedBrandedContentAdAuthCode = createSelector(
  selectTransactedBrandedContentAd,
  (brandedContentAd: IBrandedContentAdRequest) => brandedContentAd.auth_code,
)

export const selectTransactedBrandedContentAdExpiresAt = createSelector(
  selectTransactedBrandedContentAd,
  (brandedContentAd: IBrandedContentAdRequest) => brandedContentAd.expires_at,
)

export const selectTransactedBrandedContentAdReceiptNumber = createSelector(
  selectTransactedBrandedContentAd,
  (brandedContentAd: IBrandedContentAdRequest) => brandedContentAd.receipt_number,
)

export const selectTranslatedTooltipText = createSelector(
  selectSubmission,
  (state, submissionId, intl) => intl,
  (submission, intl: IntlShape) => {
    const {
      isApproved,
      isDeclined,
      isPending,
      isMediaTypeVideo,
      isShortlisted,
      isLicenseExpired,
      isLicensed,
      isPublished,
      isPublishScheduleMissed,
      shouldSubmissionBePostedWithinHours,
      isCarousel,
      isStory,
      isReel,
    } = getSubmissionFlags(submission)

    if (isLicenseExpired) {
      return getMessage(intl, 'submission.thumbnail.tooltip.licenseExpired')
    }

    if (isPublishScheduleMissed) {
      if (shouldSubmissionBePostedWithinHours) {
        return getMessage(intl, 'submission.thumbnail.tooltip.postOverdue', {
          name: submission.influencer_first_name,
        })
      }

      return getMessage(intl, 'submission.thumbnail.tooltip.scheduleMissed', {
        name: submission.influencer_first_name,
      })
    }

    if (isLicensed) {
      return submission.media_objects[0]?.transacted_license?.label
    }

    if (isPublished) {
      return getMessage(intl, 'core.text.published')
    }

    if (isApproved) {
      return getMessage(intl, 'core.text.approved')
    }

    if (isDeclined) {
      return getMessage(intl, 'core.text.declined')
    }

    if (isShortlisted) {
      return getMessage(intl, 'core.text.shortlisted')
    }

    if (isReel) {
      return getMessage(intl, 'core.text.reel')
    }

    if (isCarousel) {
      return getMessage(intl, 'core.text.carousel')
    }

    if (isStory) {
      return getMessage(intl, 'core.text.story')
    }

    if (isPending && isMediaTypeVideo) {
      return getMessage(intl, 'core.text.video')
    }

    return undefined
  },
)

export const selectGlyphIcon = createSelector(selectSubmission, (submission) => {
  const {
    isApproved,
    isPublished,
    isDeclined,
    isMediaTypeVideo,
    isShortlisted,
    isCarousel,
    isStory,
    isReel,
  } = getSubmissionFlags(submission)

  if (isApproved || isPublished) {
    return 'approved'
  }

  if (isDeclined) {
    return 'declined'
  }

  if (isShortlisted) {
    return 'heart-solid'
  }

  if (isReel) {
    return 'reels'
  }

  if (isCarousel) {
    return 'carousel'
  }

  if (isStory) {
    return 'story'
  }

  if (isMediaTypeVideo) {
    return 'video'
  }

  return undefined
})

export const selectAnalytics = createSelector(
  selectSubmission,
  (submission: ISubmission) => submission.analytics || {},
)

export const selectEngagementPercent = createSelector(
  selectAnalytics,
  (analytics: IPerformanceAnalytics) => analytics.engagement_pct,
)

const selectMediaTypeAdapter = (state: IApplicationState, submissionId: number) => {
  const mediaObjectId = selectFirstMediaObjectId(state, submissionId)
  return selectMediaType(state, mediaObjectId)
}
export const selectIsVideoSocialSubmission = createSelector(
  selectMediaTypeAdapter,
  selectSocialSubmissionType,
  (mediaType: MediaTypes, socialSubmissionType: SocialSubmissionTypes) =>
    mediaType === MediaTypes.VIDEO && socialSubmissionType === SocialSubmissionTypes.Standard,
)

export const selectShowPaidPartnershipAddHandleReminder = createSelector(
  (state: IApplicationState, campaignId: number) => selectCampaignPreferences(state, campaignId),
  (state: IApplicationState, _, submissionId: number) =>
    selectIsSubmissionFromInstagram(state, submissionId),
  (preferences: ICampaignPreferences, isInstagramSubmission: boolean) =>
    Boolean(preferences.show_add_handle_reminder && isInstagramSubmission),
)

const selectSubmissionFirstMediaObjectWithTransactedLicense = createSelector(
  selectState,
  selectSubmissionMediaObjectIds,
  (state, mediaObjectIds) => selectFirstMediaObjectWithTransactedLicense(state, mediaObjectIds),
)

const INBOX_FILTER_TO_LICENSE_STATUS_MAP = {
  license_request: [LicenseStatuses.Requested, LicenseStatuses.Declined],
  licensed: [LicenseStatuses.Licensed],
}

const selectCarouselLicensedMediaObjectForCurrentFilter = createSelector(
  selectState,
  selectIsCarousel,
  selectSubmissionMediaObjectIds,
  selectCurrentFilter,
  (state, isCarousel, mediaObjectIds, currentFilter) => {
    if (
      isCarousel &&
      ['licensed', 'license_request'].includes(currentFilter as SubmissionStatuses)
    ) {
      const mediaObject = selectFirstMediaObjectWithTransactedLicense(
        state,
        mediaObjectIds,
        INBOX_FILTER_TO_LICENSE_STATUS_MAP[currentFilter as SubmissionStatuses],
      )

      if (mediaObject) {
        return mediaObject
      }
    }
    return {} as IMediaObject
  },
)

export const selectCarouselLicensedMediaObjectThumbnail = createSelector(
  selectCarouselLicensedMediaObjectForCurrentFilter,
  (mediaObject) => mediaObject.thumbnail_media_url,
)

export const selectActiveSubmissionMediaObject = createSelector(
  selectCurrentView,
  selectFirstMediaObject,
  selectSubmissionMediaObject,
  selectSubmissionFirstMediaObjectWithTransactedLicense,
  (state, submissionId, activeFrameNumber) => activeFrameNumber,
  selectCarouselLicensedMediaObjectForCurrentFilter,
  (
    currentView,
    firstMediaObject,
    activeMediaObject,
    firstMediaObjectWithTransaction,
    activeFrameNumber,
    carouselLicensedMediaObjectForCurrentFilter,
  ) => {
    if (!isEmpty(carouselLicensedMediaObjectForCurrentFilter) && currentView === INBOX_VIEW) {
      return carouselLicensedMediaObjectForCurrentFilter
    }
    if (activeFrameNumber) {
      return activeMediaObject
    }
    if (!firstMediaObjectWithTransaction) {
      return firstMediaObjectWithTransaction
    }
    return firstMediaObject
  },
)

export const selectSubmissionMediaObjectFrameNumber = createSelector(
  selectSubmissionMediaObjectIds,
  (state, submissionId, mediaObjectId) => mediaObjectId,
  (mediaObjectIds, mediaObjectId) => mediaObjectIds.indexOf(mediaObjectId) + 1,
)

export const selectPriceForDisplay = createSelector(
  selectSubmission,
  selectCurrentFilter,
  selectActiveSubmissionMediaObject,
  (state, submissionId, campaignId) => selectIsSocialCampaign(state, campaignId),
  (submission, filter, activeMediaObject: IMediaObject | null, isSocialCampaign) => {
    if (!activeMediaObject) {
      return {
        price: submission?.total_amount_before_tax_cents,
        currency: submission?.total_amount_before_tax_currency,
      }
    }

    const transactedLicense = activeMediaObject.transacted_license!

    const isFilterLicenseRelated = [
      SubmissionStatuses.Licensed,
      SubmissionStatuses.LicenseRequest,
    ].includes(filter as SubmissionStatuses)

    const useLicenseData =
      (isFilterLicenseRelated && !isEmpty(transactedLicense)) || !isSocialCampaign

    const price = useLicenseData
      ? transactedLicense?.total_amount_before_tax_cents
      : submission?.total_amount_before_tax_cents

    const currency = useLicenseData
      ? transactedLicense?.total_amount_before_tax_currency
      : submission?.total_amount_before_tax_currency

    return {
      price,
      currency,
    }
  },
)

export const selectHasInsufficientFundsForPurchase = createSelector(
  selectHasInsufficientFundsForSubmission,
  (state) => selectHasInsufficientFundsForLicense(state),
  (hasInsufficientFundsForSubmissionApproval, hasInsufficientFundsForMediaObjectLicense) =>
    hasInsufficientFundsForMediaObjectLicense || hasInsufficientFundsForSubmissionApproval,
)

export const selectIsMakingAPurchase = createSelector(
  selectIsApproving,
  (state) => selectMediaObjectIsRequestingLicense(state),
  selectIsPreApproving,
  (isApprovingSubmission, isIsRequestingMediaObjectLicense, isPreAproving) =>
    isIsRequestingMediaObjectLicense || isApprovingSubmission || isPreAproving,
)

export const selectIsPreApproved = createSelector(selectSubmission, (submission: ISubmission) =>
  Boolean(submission.preapproved),
)

export const selectIsPreApprovedOrDeclinedPreapproval = createSelector(
  selectSubmission,
  (submission: ISubmission) => {
    return [
      SubmissionDeclineStatuses.DeclinedInBrandsFavour,
      SubmissionDeclineStatuses.DeclinedInInfluencersFavour,
      SubmissionDeclineStatuses.DeclinedInDispute,
      SubmissionStatuses.PreApproved,
    ].includes(submission.status as AllSubmissionStatuses)
  },
)

export const selectIsSubmissionDeclined = createSelector(
  selectSubmission,
  (submission: ISubmission) => {
    return [
      SubmissionDeclineStatuses.DeclinedInBrandsFavour,
      SubmissionDeclineStatuses.DeclinedInInfluencersFavour,
      SubmissionDeclineStatuses.DeclinedInDispute,
      SubmissionStatuses.Declined,
    ].includes(submission.status as AllSubmissionStatuses)
  },
)

export const selectIsPreApproveDeclined = createSelector(
  selectSubmission,
  (submission: ISubmission) => {
    return (
      submission.preapproved &&
      [
        SubmissionDeclineStatuses.DeclinedInBrandsFavour,
        SubmissionDeclineStatuses.DeclinedInInfluencersFavour,
        SubmissionDeclineStatuses.DeclinedInDispute,
      ].includes(submission.status as SubmissionDeclineStatuses)
    )
  },
)

export const selectIsPreApprovalAllowed = createSelector(
  selectPreApproveLink,
  selectSubmissionStatus,
  (preapprovelLink: IHateoasLink | undefined, status: SubmissionStatuses) => {
    return Boolean(preapprovelLink) && status === SubmissionStatuses.Pending
  },
)

export const selectIsAudienceMatch = createSelector(
  selectSubmission,
  (submission) => submission.top_identity_match,
)

export const selectIsShortlisting = (state: IApplicationState, submissionId) =>
  state.submissions.isShortlisting[submissionId]

export const selectPriceBeforePreApproval = createSelector(
  selectSubmission,
  (submission: ISubmission) => {
    return submission.first_preapproved_cents
  },
)

export const selectCostBreakdownMessage = createSelector(
  selectSubmissionStatus,
  selectPriceBeforePreApproval,
  (status: AllSubmissionStatuses, priceBeforePreApproval: number | undefined) => {
    if (
      [
        SubmissionDeclineStatuses.DeclinedInBrandsFavour,
        SubmissionDeclineStatuses.DeclinedInDispute,
        SubmissionDeclineStatuses.DeclinedInInfluencersFavour,
      ].includes(status as SubmissionDeclineStatuses)
    ) {
      return status
    }

    if (priceBeforePreApproval) {
      return 'creatorChangedPrice'
    }

    return undefined
  },
)

export const selectFetchSubmissionsLink = createSelector(
  selectFilteredSubmissionsNextLink,
  selectFilteredSubmissionSelfLink,
  (state: IApplicationState, campaignId) => selectSubmissionsLinkFromCampaigns(state, campaignId),
  (state, campaignId, filter, isFetchNext) => isFetchNext,
  (state, campaignId, filter, isFetchNext, resetList) => resetList,
  (nextLink, selfLink, fallbackLink, isFetchNext, resetList) => {
    if (isFetchNext) {
      return nextLink
    }

    if (!resetList && selfLink) {
      return selfLink
    }
    return fallbackLink
  },
)

export const selectBrandMatchPercentage = createSelector(
  selectSubmission,
  (submission) => submission.brand_match,
)

export const selectIsPitchingEnabled = createSelector(
  selectSubmission,
  (submission) => submission.pitching_enabled,
)

export const selectIsProxySubmission = createSelector(selectSubmission, (submission) =>
  Boolean(submission.is_proxy || submission.proxy_submission),
)

export const selectIsInfluencerEligibleForBrandFans = createSelector(
  selectSubmission,
  (submission) => {
    return Boolean(
      !(submission.identity_is_proxy || submission.influencer_is_proxy) &&
        (submission.can_add_to_brand_fan_group === true ||
          submission.can_add_to_brand_fan_group === undefined),
    )
  },
)

export const selectBrandFanGroupsFromSubmission = createSelector(
  (state) => state,
  selectInfluencerId,
  selectIdentityId,
  selectIsSocialSubmission,
  (state, influencerId, identityId, isSocialSubmission) => {
    if (isSocialSubmission) {
      return selectBrandFanMembersByIdentity(state, identityId).map((m) => m.brand_fan_group_id)
    }
    return selectBrandFanMembersByInfluencer(state, influencerId).map((m) => m.brand_fan_group_id)
  },
)

export const selectHasBrandFanGroupMemberships = createSelector(
  selectBrandFanGroupsFromSubmission,
  (groupIds) => groupIds.length > 0,
)

export const selectIsTextSubmission = createSelector(selectSubmission, (submission) =>
  Boolean(submission.text),
)
