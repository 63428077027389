import styled from 'styled-components'

import Container from '../Container'
import { getColor, themed } from '../Theme'

const CircleSkeleton = themed(styled(Container)`
  background-color: ${({ theme }) => getColor(theme, 'grey', '300')};
  ${({ length = 8 }) => `
    width: ${length}rem;
    height: ${length}rem;
  `};
  border-radius: 50%;
  border-style: unset;
`)

const LineSkeleton = themed(styled(Container)`
  background-color: ${({ theme }) => getColor(theme, 'grey', '300')};
  height: ${({ height = 0.75 }) => `${height}rem`};
  border-radius: 0.5rem;
  ${({ width }) => `width: ${width || '100%'};`}
  border-style: unset;
`)

const BoxSkeleton = themed(styled.div`
  background-color: ${({ theme }) => getColor(theme, 'grey', '300')};
  position: relative;
  width: 100%;
  padding-top: ${({ height = 100 }) => `${height}%`};
`)

export { CircleSkeleton, BoxSkeleton, LineSkeleton }
