import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectShowBrandedContentAdActions } from '@store/submissions'
import { Container } from '@tribegroup'
import BrandedContentAdBarButton from './Button'

interface IBrandedContentAdActions {
  submissionId: number
}

interface IInternalProps extends IBrandedContentAdActions {
  showBrandedContentAdActions: boolean
}

const BrandedContentAdBar: React.FC<IInternalProps> = ({
  submissionId,
  showBrandedContentAdActions,
}) => (
  <Container
    topInnerSpacing={1}
    bottomInnerSpacing={0.5}
    leftInnerSpacing={1}
    rightInnerSpacing={1}
    height={2}
  >
    {showBrandedContentAdActions && <BrandedContentAdBarButton submissionId={submissionId} />}
  </Container>
)

const mapStateToProps = (state: IApplicationState, { submissionId }: IBrandedContentAdActions) => ({
  showBrandedContentAdActions: selectShowBrandedContentAdActions(state, submissionId),
})

export { BrandedContentAdBar }
export default compose<IInternalProps, IBrandedContentAdActions>(connect(mapStateToProps))(
  BrandedContentAdBar,
)
