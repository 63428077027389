import { FormattedMessage } from 'react-intl'

import { PanelWrapper } from '@components/Builder/Layout/Layout.styled'
import Payment from '@components/Payment/Payment'
import Theme from '@theme'
import { H4, Panel } from '@tribegroup'
import CreditCardElements from './Elements'

export const BuilderSubmitCampaignPaymentCreditCard = ({ onPaymentChange }) => (
  <PanelWrapper data-cy-element="credit-card-payment-details-panel-wrapper">
    <Panel
      title={
        <H4 color={Theme.defaultColor}>
          <FormattedMessage id="builder.submit.payment.header" />
        </H4>
      }
    >
      <Payment>
        <CreditCardElements onPaymentChange={onPaymentChange} />
      </Payment>
    </Panel>
  </PanelWrapper>
)

export default BuilderSubmitCampaignPaymentCreditCard
