import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import {
  MediaObjectCostBreakdownLicense,
  MediaObjectCostBreakdownLicenseOptions,
} from '@components/MediaObject/CostBreakdown'
import Popover from '@components/UI/Popover/Popover'
import { IApplicationState } from '@store'
import { ILicenseOption } from '@store/campaigns'
import { selectMediaObjectLicenseOptions } from '@store/licenses'
import {
  ITransactedLicense,
  selectShouldShowLicenseOptions,
  selectTransactedLicense,
} from '@store/mediaObjects'
import Theme from '@theme'
import { Container, Header } from '@tribegroup'

interface IProps {
  mediaObjectId: number
  onHide: () => void
}

interface IInternalProps extends IProps {
  licenses: ReadonlyArray<ILicenseOption>
  license?: ITransactedLicense
  showLicenseOptionsPricing: boolean
}

export const MediaObjectCardPriceSummary: React.FC<IProps> = ({
  mediaObjectId,
  onHide,
}: IInternalProps) => {
  const { licenses, license, showLicenseOptionsPricing } = useSelector(
    (state: IApplicationState) => ({
      licenses: selectMediaObjectLicenseOptions(state, mediaObjectId),
      license: selectTransactedLicense(state, mediaObjectId),
      showLicenseOptionsPricing: selectShouldShowLicenseOptions(state, mediaObjectId),
    }),
  )
  return (
    <Popover hide={onHide}>
      <Container bottomOuterSpacing={1.25} data-testid="cost-breakdown-license">
        <Header small uppercase color={Theme.primaryColor}>
          <FormattedMessage id="core.text.summary" />
        </Header>
      </Container>
      {!showLicenseOptionsPricing ? (
        <MediaObjectCostBreakdownLicense license={license} />
      ) : (
        <MediaObjectCostBreakdownLicenseOptions licenses={licenses} />
      )}
    </Popover>
  )
}

export default MediaObjectCardPriceSummary
