import React from 'react'

import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { IMobileMenuControlsContextProps } from '@context/MobileMenuControls'
import { withMobileMenuControls } from '@hocs'
import Theme from '@theme'
import { Icon, Text } from '@tribegroup'
import { LogoLink } from '../TitleBar'
import TitleBarStyled, {
  HeaderAboveSidebar,
  StyledHeaderTextWrapper,
  StyledMobileMenu,
} from '../TitleBar.styled'

interface ITitleBarMobileProps {
  title: React.ReactNode
  count?: React.ReactNode
}

interface IInternalProps extends ITitleBarMobileProps, IMobileMenuControlsContextProps {}

export const TitleBarMobile = ({ title, count, toggleMobileMenu }: IInternalProps) => (
  <TitleBarStyled mobile>
    <Flex justifyBetween full alignCenter>
      <HeaderAboveSidebar justifyBetween alignCenter>
        <LogoLink />
      </HeaderAboveSidebar>
      <StyledMobileMenu onClick={toggleMobileMenu} justifyCenter alignCenter>
        <Text color={Theme.grey900Color}>{title}</Text>
        <StyledHeaderTextWrapper>
          <Text capitalize color={Theme.grey700Color}>
            {count}
          </Text>
        </StyledHeaderTextWrapper>
        <Icon glyph="chevron-down" size={1.5} color={Theme.grey700Color} />
      </StyledMobileMenu>
    </Flex>
  </TitleBarStyled>
)

export default compose<IInternalProps, ITitleBarMobileProps>(withMobileMenuControls)(TitleBarMobile)
