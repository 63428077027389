import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  BillingFullReportActionTypes,
  downloadFullReportError,
  downloadFullReportRequest,
  selectDownloadFullReportLink,
} from '@store/billings'
import { downloadCSV } from '@utils'

export function* handleDownloadFullReportRequest(
  action: ReturnType<typeof downloadFullReportRequest>,
) {
  try {
    const campaign = action.payload
    const authToken: string = yield select(selectAuthToken)
    const downloadFullReportLink: IHateoasLink = yield select(
      selectDownloadFullReportLink,
      campaign.id,
    )
    const headers = {
      Authorization: `Bearer ${authToken}`,
    }
    yield call(downloadCSV, downloadFullReportLink, `${campaign.product_name}.csv`, headers)
  } catch (error) {
    yield put(downloadFullReportError(error))
  }
}

export function* watchHandleDownloadFullReportRequest() {
  yield takeLatest(
    BillingFullReportActionTypes.DOWNLOAD_FULL_REPORT_REQUEST,
    handleDownloadFullReportRequest,
  )
}
