import React, { useEffect, useState } from 'react'

import { useFlag } from '@unleash/proxy-client-react'
import { differenceInCalendarDays } from 'date-fns'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouterProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { BuilderSteps, CampaignStatuses, FeatureToggles, PaymentMethods } from '@enums'
import { IMeInfoProps, withBuilderValidation, withDraft, withMeInfo, withScrollToTop } from '@hocs'
import { fetchCampaigns, finalize } from '@store/campaigns'
import {
  ICampaignDetails,
  ICompanyParams,
  IDraft,
  IPaymentDetails,
  IProfileParams,
} from '@store/drafts'
import Theme from '@theme'
import { Container, H1 } from '@tribegroup'
import { getCampaignType } from '@utils'
import BuilderTitleBarActionsButtonFinalize from '../TitleBar/Actions/Buttons/Finalize'
import ConfettiImage from './ConfettiImage/ConfettiImage'
import BuilderSubmitConfirmInvoiceTerms from './Confirm/Confirm'
import BuilderSubmitCampaignPaymentInvoice from './Payment/Invoice/Invoice'
import BuilderSubmitCampaignPayment from './Payment/Payment'
import BuilderSubmitCampaignScheduleBackButton from './Schedule/BackButton/BackButton'
import { BuilderSubmitCampaignScheduleLabel } from './Schedule/Label'
import BuilderSubmitCampaignSchedule from './Schedule/Schedule'
import {
  AnimatedBottomBlock,
  AnimatedTopBlock,
  BuilderSubmitCampaignScheduleWrapper,
  FormBodyWrapper,
  RelativeContainer,
} from './Form.styled'

interface IBuilderCampaignFormProps {
  schedulingShown?: boolean
  onShowForm?: (event: React.SyntheticEvent) => void
  onShowSchedule?: (event: React.SyntheticEvent) => void
}

interface IInternalProps
  extends IBuilderCampaignFormProps,
    IBuilderValidationContextProps,
    IDraftContextProps,
    InjectedIntlProps,
    IMeInfoProps,
    RouterProps {
  finalize: typeof finalize
  fetchCampaigns: typeof fetchCampaigns
}

export const BuilderSubmitCampaignForm: React.FC<IInternalProps> = (props) => {
  const { companyInfo, schedulingShown } = props
  const isCreditCardRemoved = useFlag(FeatureToggles.CREDIT_CARD_REMOVAL_ENABLED)

  const [showTerms, setShowTerms] = useState(false)
  const [draft, setDraft] = useState<IDraft>(props.draft)

  const [paymentDetails, setPaymentDetails] = useState<IPaymentDetails>()

  const onContinue = () => {
    const { intl, meInfo } = props

    const { first_name, last_name, phone_number } = meInfo
    const profileDetails: IProfileParams = {
      first_name,
      last_name,
      phone_number,
    }

    const campaignDetails: ICampaignDetails = {
      indicative_budget_cents: draft.indicative_budget_cents,
      step_completed: BuilderSteps.SUBMIT,
    }

    const companyDetails: ICompanyParams = {
      company_name: companyInfo?.company_name ?? '',
      street_address: companyInfo?.company_address_street,
      city: companyInfo?.company_address_city,
      state_code: companyInfo?.company_address_state_code,
      country_code: companyInfo?.company_address_country ?? '',
      postcode: companyInfo?.company_address_postcode,
    }

    const params = {
      profile_details: profileDetails,
      company_details: companyDetails,
      campaign_details: campaignDetails,
      payment_details: paymentDetails,
    }
    const campaignType = getCampaignType(draft)
    props.finalize(draft.id!, campaignType!, params, {
      toast: intl.formatMessage({ id: 'builder.submit.toast.success' }),
      history: props.history,
      redirect: `/campaigns?status=${CampaignStatuses.InReview}`,
    })
  }

  const onBeforeContinue = () => {
    if (paymentDetails!.payment_type === PaymentMethods.INVOICE) {
      setShowTerms(true)
    } else {
      onContinue()
    }
  }

  useEffect(() => {
    props.setContinueCallback(onBeforeContinue)
  }, [paymentDetails, props.setContinueCallback])

  useEffect(() => {
    props.fetchCampaigns()

    return () => {
      if (props.setEnableSubmit) {
        props.setEnableSubmit(true)
      }
    }
  }, [])

  useEffect(() => {
    if (props.setEnableSubmit) {
      props.setEnableSubmit(Boolean(!props.schedulingShown))
    }
  }, [props.setEnableSubmit, props.schedulingShown])

  const startDate = draft.start_date ? new Date(draft.start_date) : undefined
  const endDate = draft.end_date ? new Date(draft.end_date) : undefined
  const duration = startDate && endDate ? differenceInCalendarDays(endDate, startDate) : undefined

  const onHideInvoiceTerms = () => {
    setShowTerms(false)
  }

  const onChangeSchedule = (startDate: Date, endDate?: Date) => {
    setDraft((oldDraft) => {
      const updatedDraft = {
        ...oldDraft,
        start_date: startDate.toISOString(),
        end_date: endDate?.toISOString(),
      }
      props.setFormChanged(true)
      props.saveDraft(updatedDraft)
      return updatedDraft
    })
  }

  const onPaymentUpdate = (payment: IPaymentDetails) => {
    setPaymentDetails(payment)
  }
  return (
    <React.Fragment>
      <BuilderSubmitConfirmInvoiceTerms
        onConfirm={onContinue}
        onCancel={onHideInvoiceTerms}
        isOpen={showTerms}
      />
      <RelativeContainer>
        <ConfettiImage />
        <BuilderSubmitCampaignScheduleBackButton
          onClick={props.onShowSchedule}
          duration={duration}
          startDate={startDate}
          isVisible={!schedulingShown}
        />
      </RelativeContainer>
      <AnimatedTopBlock isVisible={schedulingShown} height={23}>
        <BuilderSubmitCampaignScheduleWrapper width={37}>
          <BuilderSubmitCampaignScheduleLabel />
          <FormBodyWrapper>
            <BuilderSubmitCampaignSchedule
              onShowForm={props.onShowForm}
              onChangeSchedule={onChangeSchedule}
              disabledAttrs={draft.disabled_attributes}
              campaignId={draft.id}
              campaignType={getCampaignType(draft)}
              startDate={startDate}
              duration={duration}
            />
          </FormBodyWrapper>
        </BuilderSubmitCampaignScheduleWrapper>
      </AnimatedTopBlock>
      <AnimatedBottomBlock isVisible={!schedulingShown}>
        {!schedulingShown && (
          <BuilderSubmitCampaignScheduleWrapper width={36}>
            <FormBodyWrapper>
              <H1 color={Theme.defaultColor} topOuterSpacing={2.25} bottomOuterSpacing={1}>
                <FormattedMessage
                  id={
                    isCreditCardRemoved
                      ? 'builder.submit.billing.solution.headerV2'
                      : 'builder.submit.billing.solution.header'
                  }
                />
              </H1>
              {isCreditCardRemoved ? (
                <BuilderSubmitCampaignPaymentInvoice
                  companyInfo={companyInfo!}
                  onPaymentChange={(details) =>
                    onPaymentUpdate({ ...details, payment_type: PaymentMethods.INVOICE })
                  }
                  isHeaderHidden
                />
              ) : (
                <BuilderSubmitCampaignPayment
                  currency={draft.budget_total_currency!}
                  companyInfo={companyInfo}
                  onPaymentUpdate={onPaymentUpdate}
                />
              )}
            </FormBodyWrapper>
            <Container flexCenter>
              <BuilderTitleBarActionsButtonFinalize />
            </Container>
          </BuilderSubmitCampaignScheduleWrapper>
        )}
      </AnimatedBottomBlock>
    </React.Fragment>
  )
}

const mapDispatchToProps = {
  finalize,
  fetchCampaigns,
}

export default compose<IInternalProps, IBuilderCampaignFormProps>(
  injectIntl,
  withRouter,
  withBuilderValidation,
  withDraft,
  withMeInfo,
  withScrollToTop,
  connect(undefined, mapDispatchToProps),
)(BuilderSubmitCampaignForm)
