import React from 'react'

import { FormattedMessage } from 'react-intl'

import { NoticeEmpty } from '@components/UI/Notice'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

const InfluencerIdentityListEmptyFiltered: React.FC = () => {
  return (
    <NoticeEmpty
      topOuterSpacing={3.75}
      title={
        <React.Fragment>
          <Container maxWidth={16.875} topOuterSpacing={0.5}>
            <Text italic color={Theme.grey800Color} light customFontSize={0.875}>
              <FormattedMessage id="brandFans.list.empty.filtered" />
            </Text>
          </Container>
        </React.Fragment>
      }
      alt="empty-filtered-list"
    />
  )
}

export { InfluencerIdentityListEmptyFiltered }
export default InfluencerIdentityListEmptyFiltered
