import React, { useEffect, useState } from 'react'

import { Bar as ChartJSBar } from 'react-chartjs-2'

import Theme from '@theme'

type BarData = {
  label: string
  data: number[]
}

interface IBar {
  data: {
    labels: string[]
    datasets: {
      percentage: BarData[]
      value: BarData[]
    }
  }
  showAsPercentage?: boolean
}

export const AnalyticsAudienceReportingChartsBar: React.FC<IBar> = ({
  data: { labels, datasets: dataProp },
  showAsPercentage,
}) => {
  const [dataSet, setDataSet] = useState<BarData[]>([])

  useEffect(() => {
    // deep-cloning is necessary as the dataProp have deep nested object
    // this ensures the chart updates properly when showAsPercentage change
    if (showAsPercentage) {
      setDataSet(
        JSON.parse(
          JSON.stringify(
            dataProp.percentage.sort((firstEl, secondEl) =>
              firstEl.label > secondEl.label ? 1 : -1,
            ),
          ),
        ),
      )
    } else {
      setDataSet(
        JSON.parse(
          JSON.stringify(
            dataProp.value.sort((firstEl, secondEl) => (firstEl.label > secondEl.label ? 1 : -1)),
          ),
        ),
      )
    }
  }, [showAsPercentage])

  const font = {
    family: 'Rubik',
    size: 14,
  }

  const config = {
    labels,
    datasets: dataSet.map((value, index) => {
      return {
        ...value,
        datalabels: {
          display: false,
        },
        backgroundColor: index === 0 ? Theme.primaryColor : Theme.secondary500Color,
      }
    }),
  }
  return (
    <ChartJSBar
      data={config}
      options={{
        scales: {
          y: {
            grid: {
              display: false,
            },
            beginAtZero: true,
            ticks: {
              callback: (value) => value.toLocaleString(),
              font: font,
              color: Theme.grey700Color,
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: { font, color: Theme.grey700Color },
          },
        },

        plugins: {
          tooltip: {
            callbacks: {
              label: (data) => {
                return `${data.label}: ${parseFloat(data.formattedValue).toLocaleString()}${
                  showAsPercentage ? '%' : ''
                }`
              },
            },
          },
          legend: {
            display: true,
            labels: {
              font: font,
              color: Theme.grey700Color,
            },
          },
        },
      }}
    />
  )
}
