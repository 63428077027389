import React, { FC, FormEvent, useContext, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { LicenseBooklet } from '@components/UI/LicenseBooklet/LicenseBooklet'
import Loading from '@components/UI/Loading'
import { RELICENSE_STATUSES } from '@constants'
import { CampaignContext } from '@context/Campaign'
import { SubmissionContext } from '@context/Submission'
import { useModal } from '@hooks/useModal'
import { selectIsPriceHidden } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import {
  fetchMediaObjectLicenseOptions,
  ILicense,
  selectIsFetching,
  selectMediaObjectLicenseOptions,
} from '@store/licenses'
import {
  requestLicense,
  selectFirstErrorCode,
  selectHasInsufficientFundError,
  selectMediaObject,
  selectMediaObjectIsRequestingLicense,
} from '@store/mediaObjects'
import { selectIsInboxView } from '@store/router'
import { selectActiveSubmissionMediaObject, selectSubmission } from '@store/submissions'
import { clearUIFlag, selectUIFlag, UIFlag } from '@store/ui'
import { CloseIcon, Modal } from '@tribegroup'
import { isSocialCampaign } from '@utils/campaign'
import { isEmpty } from '@utils/isEmpty'
import { RequestLicenseFooter } from './Footer'
import { RequestLicenseHeader } from './Header'
import { RequestLicensePriceSummary } from './PriceSummary'

interface IProps {
  submissionId?: number
  frameNumber?: number
  currentMediaObjectId?: number
}

export const RequestLicense: FC<IProps> = ({ submissionId, frameNumber, currentMediaObjectId }) => {
  const { isOpen, hideModal } = useModal()
  const dispatch = useDispatch()
  const { campaign } = useContext(CampaignContext)

  const [selectedLicenseIndex, setSelectedLicenseIndex] = useState(0)

  const {
    mediaObject,
    licenseOptions,
    isFetching,
    showPriceSummary,
    isInboxView,
    shouldHideModal,
    submission,
  } = useSelector((state: IApplicationState) => {
    let activeMediaObject

    if (currentMediaObjectId) {
      activeMediaObject = selectMediaObject(state, currentMediaObjectId)
    } else {
      activeMediaObject = selectActiveSubmissionMediaObject(state, submissionId, frameNumber)
    }

    const licenseOptions = selectMediaObjectLicenseOptions(state, activeMediaObject.id)

    return {
      mediaObject: activeMediaObject,
      licenseOptions,
      isFetching: selectIsFetching(state),
      showPriceSummary: !selectIsPriceHidden(state, campaign.id),
      hasInsufficientFundError: selectHasInsufficientFundError(state),
      errorCode: selectFirstErrorCode(state),
      isRequesting: selectMediaObjectIsRequestingLicense(state),
      isInboxView: selectIsInboxView(state),
      shouldHideModal: selectUIFlag(state, UIFlag.HIDE_MODAL),
      submission: selectSubmission(state, submissionId!),
    }
  })

  const showLicenseOptions =
    isEmpty(mediaObject.transacted_license) ||
    RELICENSE_STATUSES.includes(mediaObject.transacted_license?.status)

  const showCreditCardPreAuth = Boolean(
    isSocialCampaign(campaign) &&
      campaign.payment_method &&
      !campaign.payment_method.includes('invoice') &&
      showLicenseOptions,
  )

  useEffect(() => {
    dispatch(fetchMediaObjectLicenseOptions(mediaObject.id))
  }, [])

  useEffect(() => {
    if (shouldHideModal) {
      hideModal()
      dispatch(clearUIFlag(UIFlag.HIDE_MODAL))
    }
  }, [shouldHideModal])

  if (isFetching && isEmpty(licenseOptions)) {
    return (
      <Modal reactModalProps={{ isOpen: Boolean(isOpen) }} width={55} padding={0}>
        <Loading />
      </Modal>
    )
  }

  const selectLicenseOption = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    const elementValue = target.value

    const index = licenseOptions.findIndex((value) => `${value.license_type}` === `${elementValue}`)

    setSelectedLicenseIndex(index)
  }

  const onClick = () => {
    const transactedLicense = mediaObject.transacted_license

    if (transactedLicense && transactedLicense.status === 'requested') {
      return hideModal()
    }

    dispatch(
      requestLicense(mediaObject?.id, selectedLicenseIndex, campaign.id, submissionId, {
        refreshSubmissions: isInboxView,
      }),
    )
  }

  return (
    <Modal reactModalProps={{ isOpen: Boolean(isOpen) }} width={55} padding={0} mobilePadding={0}>
      <CloseIcon onClick={hideModal} />
      <SubmissionContext.Provider value={{ submission, isSubmissionFetched: true }}>
        <LicenseBooklet
          mediaObject={mediaObject}
          licenseOptions={licenseOptions}
          header={<RequestLicenseHeader mediaObject={mediaObject} />}
          onSelectLicenseOption={selectLicenseOption}
        >
          {showPriceSummary && (
            <RequestLicensePriceSummary
              license={
                (mediaObject.transacted_license &&
                !RELICENSE_STATUSES.includes(mediaObject.transacted_license?.status)
                  ? mediaObject.transacted_license
                  : licenseOptions[selectedLicenseIndex]) as ILicense
              }
            />
          )}

          <RequestLicenseFooter
            onClick={onClick}
            mediaObject={mediaObject}
            showCreditCardPreAuth={showCreditCardPreAuth}
          />
        </LicenseBooklet>
      </SubmissionContext.Provider>
    </Modal>
  )
}
