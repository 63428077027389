import { PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'

interface IProps {
  visible: boolean
  transitionDuration: number
  position?: 'absolute' | 'fixed'
  background?: string
  borderRadius?: number
  zIndex?: number
  opacity?: number
}

const Overlay: React.FC<PropsWithChildren<IProps>> = styled.div<IProps>`
  inset: 0;
  opacity: 0;
  ${({ zIndex }) =>
    css`
      z-index: ${zIndex ?? 100};
    `}
  ${({ position }) =>
    css`
      position: ${position ?? 'fixed'};
    `}
  ${({ background }) =>
    css`
      background: ${background ?? 'rgba(0, 0, 0, 0.5)'};
    `}
    ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius}rem;
    `}
  ${({ visible, opacity = 1, transitionDuration = 200 }) =>
    css`
      transition: opacity ${transitionDuration}ms;
      ${visible &&
      `
      opacity: ${opacity};
    `};
    `}
`

export default Overlay
