import { useEffect, useState } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { PAID_PARTNERSHIP_LEARN_HOW } from '@constants'
import { IModalComponentProps } from '@context/Modal'
import { withModal } from '@hocs'
import { useIsMounted } from '@hooks/useIsMounted'
import { IApplicationState } from '@store'
import { selectIsUpdatingPreferences, updatePreferences } from '@store/campaigns'
import { selectCampaignId } from '@store/router'
import { setUIFlag, UIFlag } from '@store/ui'
import Theme from '@theme'
import { Button, Container, H1, Header, Link, Modal, Radio, Text, Tip } from '@tribegroup'
import { MainModalContent } from '../AddHandleReminder/AddHandleReminder.styled'

interface IInternalProps extends IModalComponentProps, InjectedIntlProps {
  campaignId: number
  updatePreferences: typeof updatePreferences
  setUIFlag: typeof setUIFlag
  isUpdatingPreferences: boolean
}

const CampaignModalPaidPartnershipTurnOffRequireApprovals: React.FC<IInternalProps> = ({
  onRequestClose,
  isOpen = false,
  campaignId,
  updatePreferences: updatePreferencesAction,
  setUIFlag: setUIFlagAction,
  isUpdatingPreferences,
  intl,
}) => {
  const [selected, setSelected] = useState<boolean | undefined>(undefined)
  const isMounted = useIsMounted()

  useEffect(() => {
    if (isMounted && !isUpdatingPreferences) {
      if (!selected) {
        setUIFlagAction(UIFlag.SHOW_PAID_PARTNERSHIP_ADD_HANDLE_REMINDER)
      }
      onRequestClose()
    }
  }, [isUpdatingPreferences])

  const disabled = selected === undefined || isUpdatingPreferences

  const onChange = (event: React.SyntheticEvent) => {
    const value = parseInt((event.target as HTMLInputElement).value, 10)
    setSelected(Boolean(value))
  }

  const onSubmit = () => {
    const selectedOption = Boolean(selected)
    updatePreferencesAction(campaignId, {
      require_approval_selected_option: selectedOption,
      show_add_handle_reminder: !selectedOption,
    })
  }

  return (
    <Modal reactModalProps={{ isOpen }} width={27.5} complex>
      <MainModalContent data-testid="turn-off-require-approvals-modal">
        <H1 color={Theme.grey900Color} bottomOuterSpacing={1}>
          <FormattedMessage id="socialSubmission.card.modal.paidpartnership.turnOffRequireApprovals.header" />
        </H1>
        <Container bottomOuterSpacing={1.5}>
          <Text color={Theme.grey800Color}>
            <FormattedMessage id="socialSubmission.card.modal.paidpartnership.turnOffRequireApprovals.summary" />
          </Text>
        </Container>
        <Container bottomOuterSpacing={0.75} data-testid="turn-off-require-approvals-option-yes">
          <Radio
            showSelectedAsCheck
            name="turn_off_require_approvals"
            scheme="primary"
            label={
              <FormattedMessage id="socialSubmission.card.modal.paidpartnership.turnOffRequireApprovals.options.yes" />
            }
            onChange={onChange}
            value={1}
            labelColor={Theme.grey900Color}
            labelFontSize={0.875}
          />
        </Container>
        <Container bottomOuterSpacing={2} data-testid="turn-off-require-approvals-option-no">
          <Radio
            showSelectedAsCheck
            name="turn_off_require_approvals"
            scheme="primary"
            label={
              <FormattedMessage id="socialSubmission.card.modal.paidpartnership.turnOffRequireApprovals.options.no" />
            }
            onChange={onChange}
            value={0}
            labelColor={Theme.grey900Color}
            labelFontSize={0.875}
          />
        </Container>
        <Container bottomOuterSpacing={2}>
          <Link href={PAID_PARTNERSHIP_LEARN_HOW} target="_blank" color={Theme.secondaryColor}>
            <Text color={Theme.primaryColor}>
              <FormattedMessage id="core.text.learnMore.withArrow" />
            </Text>
          </Link>
        </Container>
        <Container bottomOuterSpacing={1.625} minHeight={3.625}>
          {selected === false && (
            <Tip
              width={21.5}
              text={intl.formatMessage({
                id: 'socialSubmission.card.modal.paidpartnership.turnOffRequireApprovals.tip',
              })}
            />
          )}
        </Container>
        <Button
          fullWidth
          scheme="primary"
          height={3}
          onClick={onSubmit}
          disabled={disabled}
          loading={isUpdatingPreferences}
          data-testid="turn-off-require-approvals-okay-button"
        >
          <Header cta small uppercase color={disabled ? Theme.grey700Color : Theme.whiteColor}>
            <FormattedMessage id="core.text.okay" />
          </Header>
        </Button>
      </MainModalContent>
    </Modal>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => ({
  campaignId: selectCampaignId(match),
  isUpdatingPreferences: selectIsUpdatingPreferences(state),
})

const mapDispatchToProps = {
  updatePreferences,
  setUIFlag,
}

export { CampaignModalPaidPartnershipTurnOffRequireApprovals }
export default compose<IInternalProps, IModalComponentProps>(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withModal,
)(CampaignModalPaidPartnershipTurnOffRequireApprovals)
