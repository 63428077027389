import { BuilderDefault } from '@enums'

export const loadVideo = (file: File): Promise<HTMLVideoElement> =>
  new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.preload = 'metadata'
    video.onloadedmetadata = () => {
      resolve(video)
    }
    video.onerror = () => reject(video)
    video.src = URL.createObjectURL(file)
  })

export const moodboardFileValidator = async (
  file: File,
  acceptedVideoFileTypes: ReadonlyArray<String>,
) => {
  if (file.size > BuilderDefault.FILE_SIZE_MOODBOARD_NEW) {
    return {
      code: 'file-too-large',
      message: 'file-too-large',
    }
  }

  if (!acceptedVideoFileTypes.includes(file.type)) {
    return null
  }

  const video = await loadVideo(file)

  if (!video || video?.duration > BuilderDefault.MOODBOARD_MAX_VIDEO_DURATION_SEC) {
    return {
      code: 'invalid-video-duration',
      message: 'invalid-video-duration',
    }
  }

  return null
}

export default {
  loadVideo,
  moodboardFileValidator,
}
