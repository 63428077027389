import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { IModalComponentProps } from '@context/Modal'
import Theme from '@theme'
import { Button, Container, H3, Modal, Text } from '@tribegroup'

interface IProps extends IModalComponentProps {
  onConfirm: VoidFunction
}

const BuilderBudgetTargetAudienceConfirmation: React.FC<IProps> = ({
  onConfirm,
  onRequestClose,
  isOpen = false,
}) => {
  const onOkayClick = () => {
    onConfirm()
    onRequestClose()
  }
  return (
    <Modal reactModalProps={{ isOpen }} width={20}>
      <Container data-testid="target-audience-confirm-dialog">
        <H3 color={Theme.defaultColor}>
          <FormattedMessage id="builder.budget.demographics.targetAudience.confirm.title" />
        </H3>
        <Container topOuterSpacing={0.5}>
          <Text color={Theme.grey800Color}>
            <FormattedMessage id="builder.budget.demographics.targetAudience.confirm.text" />
          </Text>
        </Container>
        <Container topOuterSpacing={3.125}>
          <Flex justifyEnd>
            <Button small quiet onClick={onRequestClose}>
              <FormattedMessage id="core.text.cancel" />
            </Button>
            <Button small scheme="primary" onClick={onOkayClick}>
              <FormattedMessage id="core.text.okay" />
            </Button>
          </Flex>
        </Container>
      </Container>
    </Modal>
  )
}

export default BuilderBudgetTargetAudienceConfirmation
