import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectReferralProgramLink } from '@store/me'
import { Button, Header, Toaster } from '@tribegroup'
import { clipboard } from '@utils'
import InboxReferralToast from './Toast'

interface IProps {
  campaignId: number
  onRequestClose: VoidFunction
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

const InboxReferralUICopyButton: React.FC<IInternalProps> = ({
  campaignId,
  referralProgramLink,
  onRequestClose,
}) => {
  const onCopyLink = async () => {
    await clipboard.copy(referralProgramLink as string)
    Toaster.createToast(InboxReferralToast)
    onRequestClose()
  }
  return (
    <ConnectedTrackedRoute
      eventName={EventTrackingNames.BrandReferralCopyURL}
      campaignId={campaignId}
    >
      <Button
        data-testid="copy-unique-link-button"
        scheme="primary"
        height={3}
        onClick={onCopyLink}
        noFocus
      >
        <Header cta>
          <FormattedMessage id="inbox.referral.modal.cta" />
        </Header>
      </Button>
    </ConnectedTrackedRoute>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  referralProgramLink: selectReferralProgramLink(state),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, IProps>(connector)(InboxReferralUICopyButton)
