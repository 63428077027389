import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import InfluencerIdentityRowSubmissionCard from '@components/Inbox/InfluencerIdentity/Row/Submission/Card/Card'
import CardGrid from '@components/UI/CardGrid/CardGrid.styled'
import { IInfluencerIdentityIdContextProps } from '@context/InfluencerIdentityId'
import { withContextInfluencerIdentityId } from '@hocs'
import { IApplicationState } from '@store'
import { selectIsSocialCampaign } from '@store/campaigns'
import { selectCampaignId } from '@store/router'
import { selectSubmissionIdsByInfluencerIdentity } from '@store/submissions'
import { Container } from '@tribegroup'

type IInternalProps = ConnectedProps<typeof connector>

const InfluencerIdentityRowSubmissionList: React.FC<IInternalProps> = ({
  campaignId,
  submissionIds,
  isSocial,
}) => {
  return (
    <Container topOuterSpacing={1.25} bottomOuterSpacing={1.25}>
      <CardGrid gap={0.9375}>
        {submissionIds.map((submissionId: number) => (
          <InfluencerIdentityRowSubmissionCard
            key={submissionId}
            submissionId={submissionId}
            campaignId={campaignId}
            isSocial={isSocial}
          />
        ))}
      </CardGrid>
    </Container>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { contextInfluencerIdentityId, match }: IInfluencerIdentityIdContextProps & RouteComponentProps,
) => {
  const campaignId = selectCampaignId(match) as number
  const isSocial = selectIsSocialCampaign(state, campaignId)
  return {
    campaignId,
    isSocial,
    submissionIds: selectSubmissionIdsByInfluencerIdentity(
      state,
      campaignId,
      contextInfluencerIdentityId,
    ),
  }
}

const connector = connect(mapStateToProps)

export { InfluencerIdentityRowSubmissionList }
export default compose(
  withRouter,
  withContextInfluencerIdentityId,
  connector,
)(InfluencerIdentityRowSubmissionList)
