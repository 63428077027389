import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import { Spin } from '@lib/effects'
import Theme from '@theme'

const IconStyles = css`
  svg {
    height: 100%;
    width: 100%;
  }
`

export const CardProfilePic = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 3.5rem;
  height: 4.125rem;
  ${IconStyles};

  svg {
    display: block;
  }
`

export const CardHeader = styled.div`
  height: 4.125rem;
  ${IconStyles};
`

export const ContentWrapper = styled.div`
  height: 100%;
  position: relative;
  min-height: 25rem;
`

export const UpperContent = styled.div`
  ${IconStyles};
  height: 8rem;
`
export const LowerContent = styled.div`
  ${IconStyles};
  height: 11.25rem;
  bottom: 0;
  position: absolute;
  width: 100%;
`

export const ContentMedia = styled.div`
  position: relative;

  ${IconStyles};
  svg {
    display: block;
  }

  ${breakpoint('tabletPortrait')`
    border-right: 1px solid ${Theme.grey200Color};
  `};
`

export const SpinnerIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  animation: ${Spin} 2.8s linear infinite;
`
