import React from 'react'

import empty from '@images/empty-image.png'
import emptyx2 from '@images/empty-image@2x.png'
import emptyx3 from '@images/empty-image@3x.png'
import { NoticeImage } from '../Notice.styled'

interface IProps {
  alt: string
}

const NoticeEmptyImage: React.FC<IProps> = ({ alt }) => {
  return (
    <NoticeImage
      data-testid="notice-image"
      width={12.5}
      height={12.5}
      src={empty}
      srcSet={`${empty} 300w, ${emptyx2} 768w, ${emptyx3} 1280w`}
      alt={`empty-${alt}`}
    />
  )
}

export { NoticeEmptyImage }
export default NoticeEmptyImage
