import { call, put, select, takeEvery } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { IHateoasLink } from '@store'
import { selectSubmissionTrackingProps, trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  selectViewSocialLink,
  ViewInfluencerSocialActionTypes,
  viewSocial,
  viewSocialError,
} from '@store/submissions'
import { networkRequest } from '@utils'

export function* handleViewSocial(action: ReturnType<typeof viewSocial>) {
  try {
    const { campaignId, submissionId } = action.payload
    const authToken: string = yield select(selectAuthToken)
    const viewSocialLink: IHateoasLink = yield select(selectViewSocialLink, submissionId)
    if (viewSocialLink) {
      yield call(networkRequest, viewSocialLink.method, viewSocialLink.href, undefined, authToken)
    }
    const eventProperties = yield select(selectSubmissionTrackingProps, campaignId, submissionId)
    yield put(trackEvent(EventTrackingNames.ViewCreatorProfile, eventProperties))
  } catch (error) {
    yield put(viewSocialError(error))
  }
}

export function* watchViewSocialRequests() {
  yield takeEvery(ViewInfluencerSocialActionTypes.VIEW_SOCIAL_REQUEST, handleViewSocial)
}
