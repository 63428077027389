import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'

import { CardContainer, CardIcon, CardLabel } from '@components/UI/Card/Card'
import { MediaThumbnail } from '@components/UI/Media'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { AspectRatios, EventTrackingNames, MediaTypes, PageRoutes } from '@enums'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import {
  selectCardStatusLabel,
  selectIsVideoMediaType,
  selectMediaObjectType,
  selectThumbnailMediaUrl,
} from '@store/mediaObjects'
import { Container } from '@tribegroup'
import MediaObjectActionBar from './ActionBar/ActionBar'

interface IMediaObjectCardProps {
  mediaObjectCardId: number
}

interface IInternalProps extends IMediaObjectCardProps {
  mediaThumbnail: string
  isVideoMediaType: boolean
  statusLabel?: string
}

export const MediaObjectCard = ({
  mediaObjectCardId,
  mediaThumbnail,
  isVideoMediaType,
  statusLabel,
}: IInternalProps) => {
  const { search } = hooks.useLocation()
  return (
    <CardContainer elementName="media-object-card">
      <Container relative>
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.ViewContentLibraryItem}
          additionalProperties={{ source: 'content_library_inbox' }}
          mediaObjectId={mediaObjectCardId}
        >
          <Link
            to={{
              pathname: `/${PageRoutes.MediaObjects}/${mediaObjectCardId}`,
              search,
            }}
          >
            <MediaThumbnail src={mediaThumbnail} aspectRatio={AspectRatios.ONE_BY_ONE} />
            {statusLabel && (
              <CardLabel>
                <FormattedMessage id={`submission.card.label.${statusLabel}`} />
              </CardLabel>
            )}
            {isVideoMediaType && <CardIcon icon={MediaTypes.VIDEO} />}
          </Link>
        </ConnectedTrackedRoute>
        <MediaObjectActionBar mediaObjectId={mediaObjectCardId} />
      </Container>
    </CardContainer>
  )
}

const mapStateToProps = (state: IApplicationState, { mediaObjectCardId }: IInternalProps) => ({
  mediaThumbnail: selectThumbnailMediaUrl(state, mediaObjectCardId),
  mediaType: selectMediaObjectType(state, mediaObjectCardId),
  isVideoMediaType: selectIsVideoMediaType(state, mediaObjectCardId),
  statusLabel: selectCardStatusLabel(state, mediaObjectCardId),
})

export default compose<IInternalProps, IMediaObjectCardProps>(connect(mapStateToProps))(
  MediaObjectCard,
)
