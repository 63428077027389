import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { IApplicationState } from '@store'
import { selectFilteredIdentityIds } from '@store/brandFans'
import { selectCurrentBrandFansFilter } from '@store/router'
import { Container } from '@tribegroup'
import InfluencerIdentityTopPanelBrandFilters from './BrandFilters'
import InfluencerIdentityTopPanelSort from './Sort'

interface IInternalProps {
  identityCount: number
}

const BrandFansInfluencerIdentityTopPanel: React.FC<IInternalProps> = ({ identityCount }) => (
  <Container topOuterSpacing={6}>
    <Flex justifyBetween>
      <InfluencerIdentityTopPanelBrandFilters />
      {identityCount > 0 && <InfluencerIdentityTopPanelSort />}
    </Flex>
  </Container>
)

const mapStateToProps = (state: IApplicationState) => {
  const currentFilter = selectCurrentBrandFansFilter(state)
  const identities = selectFilteredIdentityIds(state, currentFilter)
  return {
    identityCount: identities.length,
  }
}

export { BrandFansInfluencerIdentityTopPanel }
export default compose<IInternalProps, {}>(connect(mapStateToProps))(
  BrandFansInfluencerIdentityTopPanel,
)
