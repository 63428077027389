import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import {
  selectMediaObjectIsFetching,
  selectMediaObjectIsRequestingLicense,
} from '@store/mediaObjects'
import { LicensingButton } from './Button'

interface IRequestLicenseButton {
  children: React.ReactNode
  onLicenseRequest: (event: React.SyntheticEvent) => void
}

interface IInternalProps extends IRequestLicenseButton {
  isRequestingLicense: boolean
  isMediaObjectIsFetching: boolean
}

export const RequestLicenseButton = ({
  onLicenseRequest,
  isRequestingLicense,
  children,
  isMediaObjectIsFetching,
}: IInternalProps) => (
  <LicensingButton
    loading={isRequestingLicense}
    disabled={isRequestingLicense || isMediaObjectIsFetching}
    onClick={onLicenseRequest}
  >
    {children}
  </LicensingButton>
)

const mapStateToProps = (state: IApplicationState) => ({
  isRequestingLicense: selectMediaObjectIsRequestingLicense(state),
  isMediaObjectIsFetching: selectMediaObjectIsFetching(state),
})

export default compose<IInternalProps, IRequestLicenseButton>(connect(mapStateToProps))(
  RequestLicenseButton,
)
