import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Text } from '@tribegroup'

const LicensingPreAuthMessage = () => (
  <Flex justifyBetween alignEnd>
    <Text small color={Theme.grey700Color}>
      <FormattedMessage id="socialSubmission.card.modal.license.ccPreAuth" />
    </Text>
  </Flex>
)

export default LicensingPreAuthMessage
