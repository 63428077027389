import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container, Text } from '@tribegroup'

const BuilderBudgetTargetBeta: React.FC = () => (
  <Container
    absolute
    width={2.75}
    rightPosition={0}
    leftInnerSpacing={1}
    backgroundColor={Theme.grey100Color}
  >
    <Container
      borderRadius={0.25}
      borderWidth={0.0625}
      leftInnerSpacing={0.5}
      rightInnerSpacing={0.5}
      topInnerSpacing={0.125}
      bottomInnerSpacing={0.125}
      borderColor={Theme.primaryColor}
    >
      <Flex>
        <Text color={Theme.primaryColor} xxsmall uppercase>
          <FormattedMessage id="core.text.beta" />
        </Text>
      </Flex>
    </Container>
  </Container>
)

export default BuilderBudgetTargetBeta
