import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'

interface IProps {
  displayName: string
  glyph: string
  hideChevron?: boolean
}

const ConnectedAccountsDrawerIdentityPlaceholder = ({
  displayName,
  glyph,
  hideChevron,
}: IProps) => {
  return (
    <Container
      topOuterSpacing={0.75}
      backgroundColor={Theme.grey100Color}
      borderRadius={0.5}
      innerSpacingX={0.75}
      innerSpacingY={0.625}
      flexStart
    >
      <Container
        width={2.5}
        height={2.5}
        borderRadius="100%"
        borderStyle="dashed"
        borderWidth={0.0625}
        borderColor={Theme.grey600Color}
        rightOuterSpacing={0.75}
      />
      <Container relative rightOuterSpacing={0.5}>
        <Icon glyph={glyph} size={1} color={Theme.grey700Color} verticalAlign="middle" />
      </Container>
      <Container grow={1} rightInnerSpacing={0.75} textOverflowAsEllipsis>
        <Text color={Theme.grey700Color}>{displayName}</Text>
      </Container>
      {!hideChevron && (
        <Container>
          <Text customFontSize={1} color={Theme.grey700Color}>{`>`}</Text>
        </Container>
      )}
    </Container>
  )
}

export { ConnectedAccountsDrawerIdentityPlaceholder }
