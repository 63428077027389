import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { SubmissionChannels } from '@enums'
import { selectDraft } from '@store/drafts'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { Button, Container, Text, TextField } from '@tribegroup'
import { KEYCODE } from '@tribegroup/utils'
import { InputWrapper } from '../Channels.styled'
import { IInstagramPaidPartershipResults } from './PaidPartnership'

interface IBuilderBriefChannelsInstagram {
  disabled: boolean
  onUpdate: (results: IInstagramPaidPartershipResults) => void
}

interface IInternalProps extends IBuilderBriefChannelsInstagram, InjectedIntlProps {}

const BuilderBriefChannelsInstagramHandleInput: React.FC<IInternalProps> = ({
  disabled,
  onUpdate,
  intl,
}) => {
  const { draft } = useSelector((state: IApplicationState) => {
    const draft = selectDraft(state)
    return {
      draft,
    }
  })

  const paidPartnershipInput = React.createRef() as any
  const [hasError, setHasError] = React.useState<boolean>(false)
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === KEYCODE.ENTER) {
      savePaidPartnershipHandle(event)
    }
  }

  const savePaidPartnershipHandle = (event: React.SyntheticEvent) => {
    event.preventDefault()
    const value = (paidPartnershipInput.current as HTMLInputElement).value
    // eslint-disable-next-line no-useless-escape
    if (value.match(/^(\@)[\w\d\.]+$/g)) {
      return onUpdate({
        channels: draft?.channels?.map((channel) => {
          if (channel.name !== SubmissionChannels.Instagram) {
            return channel
          }

          return {
            ...channel,
            paid_partnership_handle: value,
            has_required_social_channel_account: true,
          }
        }),
      })
    } else {
      setHasError(true)
    }
  }

  const clearError = () => {
    setHasError(false)
  }

  React.useEffect(() => {
    if (disabled) {
      clearError()
    }
  }, [disabled])

  return (
    <React.Fragment>
      <InputWrapper data-testid="channels-instagram-input-wrapper">
        <TextField
          innerRef={paidPartnershipInput}
          name="paid_partnership_handle"
          disabled={disabled}
          placeholder={intl.formatMessage({
            id: 'builder.brief.socialMedia.paidpartnership.input.placeholder',
          })}
          height={0.875}
          fullWidth
          rounded
          onKeyDown={onKeyDown}
          onChange={clearError}
          data-testid="paid_partnership_handle_input"
        />
        <Container leftOuterSpacing={1}>
          <Button
            data-testid={'paid_partnership_handle_input_button'}
            small
            scheme="primary"
            onClick={savePaidPartnershipHandle}
          >
            <FormattedMessage id="builder.brief.socialMedia.submitText" />
          </Button>
        </Container>
      </InputWrapper>
      {hasError && (
        <Container topOuterSpacing={0.25}>
          <Text small color={Theme.errorColor}>
            <FormattedMessage id="builder.brief.socialMedia.error.handle" />
          </Text>
        </Container>
      )}
    </React.Fragment>
  )
}

export { BuilderBriefChannelsInstagramHandleInput }
export default injectIntl(BuilderBriefChannelsInstagramHandleInput)
