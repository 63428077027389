import { keyframes } from 'styled-components'

import { SLIDE_DISTANCE } from './constants'

export const SlideInUp = keyframes`
  from {
    transform: translateY(${SLIDE_DISTANCE});
    visibility: visible;
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`
