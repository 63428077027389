import { PropsWithChildren } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import Flex, { FlexItem } from 'styled-flex-component'

import { selectIsPayingByCreditCard, selectShouldHideCampaignBudget } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { Container, Header } from '@tribegroup'
import BudgetAmountInformation from './BudgetInformationAmount'
import { BudgetInformationPanelRoot } from './BudgetInformationPanel.styled'

interface IProps {
  currency: string
  remaining?: number
  spent?: number
  separator?: React.ReactNode
  customMessage?: React.ReactNode
  secondRowContent?: React.ReactNode
  showBudget?: boolean
  onClick?: VoidFunction
}

const BudgetInformationPanel: React.FC<PropsWithChildren<IProps>> = ({
  remaining,
  spent,
  currency,
  customMessage,
  separator,
  children,
  showBudget = true,
  secondRowContent,
  onClick: propsOnClick,
}) => {
  const match = useRouteMatch()
  const campaignId = selectCampaignId(match) as number
  const { isPayingByCreditCard, shouldHideRemainingBudget } = useSelector(
    (state: IApplicationState) => {
      return {
        isPayingByCreditCard: selectIsPayingByCreditCard(state, campaignId),
        shouldHideRemainingBudget: selectShouldHideCampaignBudget(state, campaignId),
      }
    },
  )

  const hasOnClick = Boolean(propsOnClick)
  const onClick = () => {
    if (propsOnClick) {
      propsOnClick()
    }
  }

  return (
    <BudgetInformationPanelRoot
      data-cy-element="campaign-budget"
      data-testid="campaign-budget"
      onClick={hasOnClick ? onClick : undefined}
      hoverable={hasOnClick}
    >
      <Header small color={Theme.grey900Color} fullWidth>
        <Flex alignCenter justifyStart>
          {!shouldHideRemainingBudget && showBudget && (
            <BudgetAmountInformation
              remaining={remaining}
              spent={spent}
              currency={currency}
              customMessage={customMessage}
            />
          )}
          {shouldHideRemainingBudget && (
            <>
              {isPayingByCreditCard && showBudget && (
                <BudgetAmountInformation
                  remaining={remaining}
                  spent={spent}
                  currency={currency}
                  customMessage={customMessage}
                />
              )}
              {!isPayingByCreditCard && (
                <FlexItem noShrink>
                  <Container maxWidth="calc(100vw - 9.75rem)">
                    <Header uppercase small>
                      <FormattedMessage id={'campaign.budget.summary'} />
                    </Header>
                  </Container>
                </FlexItem>
              )}
            </>
          )}
          {children && (
            <>
              {separator}
              <Container fullWidth>{children}</Container>
            </>
          )}
        </Flex>
      </Header>
      {secondRowContent}
    </BudgetInformationPanelRoot>
  )
}

export default BudgetInformationPanel
