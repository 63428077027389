import { FC } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import { DownloadButton, LicensingAgreementsLink } from '@components/Licensing'
import MediaObjectLicensingLicensedUploadToFacebookAdsManager from '@components/MediaObject/Licensing/Licensed/UploadToFacebookAdsManager'
import StripeError from '@components/UI/Error/StripeError'
import { IApplicationState } from '@store'
import {
  IMediaObject,
  selectFirstErrorCode,
  selectHasInsufficientFundError,
  selectMediaObjectIsRequestingLicense,
  selectUploadToFacebookLink,
} from '@store/mediaObjects'
import Theme from '@theme'
import { Button, Container, Header, Text } from '@tribegroup'
import { isEmpty } from '@utils'

interface IProps {
  onClick: VoidFunction
  mediaObject: IMediaObject
}

export const MediaObjectRequestLicenseFooter: FC<IProps> = ({ onClick, mediaObject }) => {
  const {
    hasInsufficientFundError,
    errorCode,
    isRequesting,
    hasFacebookAdUploadLink,
  } = useSelector((state: IApplicationState) => {
    return {
      hasInsufficientFundError: selectHasInsufficientFundError(state),
      errorCode: selectFirstErrorCode(state),
      isRequesting: selectMediaObjectIsRequestingLicense(state),
      hasFacebookAdUploadLink: Boolean(selectUploadToFacebookLink(state, mediaObject.id)),
    }
  })

  return (
    <Container topOuterSpacing={2.5}>
      <Container bottomOuterSpacing={1.5}>
        {!hasInsufficientFundError && errorCode && <StripeError errorCode={errorCode} />}
      </Container>
      {mediaObject?.transacted_license?.status === 'licensed' ? (
        <DownloadButton mediaObjectId={mediaObject.id}>
          <FormattedMessage id="licensing.licensed.cta" />
        </DownloadButton>
      ) : (
        <Button
          fullWidth
          scheme="primary"
          disabled={isRequesting}
          loading={isRequesting}
          onClick={onClick}
          bottomOuterSpacing={1.25}
          height={3}
        >
          {mediaObject.transacted_license?.status === 'requested' ? (
            <Header cta>
              <FormattedMessage id="core.text.okay" />
            </Header>
          ) : (
            <Header cta>
              <FormattedMessage id="licensing.licensingOptions.cta" />
            </Header>
          )}
        </Button>
      )}

      {(isEmpty(mediaObject.transacted_license) ||
        mediaObject.transacted_license?.status === 'expired') && (
        <Container bottomOuterSpacing={1.25}>
          <Flex justifyCenter alignCenter>
            <Text textAlign="center" small color={Theme.grey800Color}>
              <FormattedMessage id="contentLibrary.licensing.creditManagement.paymentOption" />
            </Text>
          </Flex>
        </Container>
      )}

      <LicensingAgreementsLink color={Theme.primaryColor} />

      {hasFacebookAdUploadLink && (
        <Container topOuterSpacing={1.25} flexCenter>
          <MediaObjectLicensingLicensedUploadToFacebookAdsManager mediaObjectId={mediaObject.id} />
        </Container>
      )}
    </Container>
  )
}
