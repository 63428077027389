import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { IApplicationState } from '@store'
import { IFacebookAdAccount, selectAdAccounts } from '@store/facebookAds'
import Theme from '@theme'
import { Button, CloseIcon, Container, H5, Modal, ModalTitle, Radio } from '@tribegroup'

interface IFacebookAdAccountsModalProps {
  onCloseModal: VoidFunction
  onSelectAccountId: (adAccountId: string) => void
  selectedAccountId: string
  onSubmit: VoidFunction
  isTransferringMedia: boolean
  isOpen: boolean
}

interface IInternalProps extends IFacebookAdAccountsModalProps {
  adAccounts: ReadonlyArray<IFacebookAdAccount>
}

export const FacebookAdAccountsModal = ({
  onCloseModal,
  adAccounts,
  onSelectAccountId,
  selectedAccountId,
  onSubmit,
  isTransferringMedia,
  isOpen,
}: IInternalProps) => {
  const onAccountChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement
    onSelectAccountId(target.value)
  }

  return (
    <Modal reactModalProps={{ isOpen, onRequestClose: onCloseModal }} width={28.75} complex>
      <ModalTitle center>
        <FormattedMessage id="facebook.adAccount.title" />
      </ModalTitle>
      <CloseIcon onClick={onCloseModal} />
      <Container>
        <H5 color={Theme.grey900Color}>
          <FormattedMessage id="facebook.adAccount.subtitle" />
        </H5>
      </Container>
      <Container topOuterSpacing={1.25} bottomOuterSpacing={3.125}>
        {adAccounts.map((adAccount) => (
          <Container key={adAccount.id} bottomOuterSpacing={1}>
            <Radio
              scheme="primary"
              name="ad_account"
              label={adAccount.name}
              defaultValue={adAccount.id}
              onChange={onAccountChange}
              defaultChecked={selectedAccountId === adAccount.id}
              subLine={<H5 color={Theme.grey700Color}>{adAccount.id}</H5>}
            />
          </Container>
        ))}
      </Container>
      <Container topOuterSpacing={1.5}>
        <Flex center>
          <Button scheme="primary" outlined height={3} width={10.125} onClick={onCloseModal}>
            <FormattedMessage id="core.text.cancel" />
          </Button>
          <Button
            scheme="primary"
            height={3}
            width={10.125}
            leftOuterSpacing={1}
            onClick={onSubmit}
            disabled={!adAccounts.length || isTransferringMedia}
            loading={isTransferringMedia}
          >
            <FormattedMessage id="core.text.upload" />
          </Button>
        </Flex>
      </Container>
    </Modal>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  adAccounts: selectAdAccounts(state),
})

export default compose<IInternalProps, IFacebookAdAccountsModalProps>(connect(mapStateToProps))(
  FacebookAdAccountsModal,
)
