import { IFeedbackAttachment } from '@store/feedback'
import { Icon } from '@tribegroup'
import { AttachmentFileName, IconContainer } from './DownloadFile.styled'

const glyphMap = {
  '.pdf': 'document-pdf',
  '.doc': 'document-doc',
  '.docx': 'document-doc',
}

export const SubmissionChatAttachmentDownloadFile: React.FC<{
  attachment: IFeedbackAttachment
}> = ({ attachment }) => {
  const fileName = attachment.file_name
  const glyphKey = Object.keys(glyphMap).find((key) => fileName.toLowerCase().endsWith(key))

  if (!glyphKey) {
    return null
  }
  return (
    <AttachmentFileName href={attachment.original_url} download aria-label={`Download ${fileName}`}>
      <IconContainer>
        <Icon size={1.5} glyph={glyphMap[glyphKey]} />
      </IconContainer>
      <span>{fileName}</span>
    </AttachmentFileName>
  )
}

export default SubmissionChatAttachmentDownloadFile
