import styled from 'styled-components'

import Theme from '@theme'

export const InfluencerIdentityRowAvatarImage = styled.div`
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 10rem;
  overflow: hidden;
  margin-right: 1rem;
  flex-shrink: 0;
`

export const StarWrapper = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 100%;
  background-color: ${Theme.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.625rem;
  right: -0.625rem;
`
