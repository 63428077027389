import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Avatar from '@components/UI/Avatar'
import hashtagPlaceholderImage from '@images/hashtag-placeholder@3x.png'
import { IBrandManagerIdentity } from '@store/brandManagerIdentities'
import { IConnectedSocialAccount } from '@store/campaigns'
import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import { ConnectedAccountsDrawerIdentityTikTokIcon } from './Icon'
import { TikTokIdentityWrapper } from './TikTok.styled'

interface IProps {
  brandManagerIdentity?: IBrandManagerIdentity | IConnectedSocialAccount
  isActive?: boolean
  onClick: VoidFunction
}

const ConnectedAccountsDrawerIdentityTikTok = ({
  brandManagerIdentity,
  isActive,
  onClick,
}: IProps) => {
  return (
    <TikTokIdentityWrapper
      leftInnerSpacing={0.75}
      rightInnerSpacing={0.75}
      topInnerSpacing={0.625}
      bottomInnerSpacing={0.625}
      borderRadius={0.5}
      backgroundColor={Theme.grey100Color}
      hoverBackgroundColor={Theme.grey200Color}
      topOuterSpacing={0.75}
      height={3.75}
      flexBetween
      pointer
      isActive={isActive}
    >
      <Flex alignCenter>
        <Container rightOuterSpacing={0.75} width={2.5} height={2.5} borderRadius={100}>
          <Avatar
            size={2.5}
            borderWidth={0.0625}
            avatarSrc={brandManagerIdentity?.display_url}
            borderColor={Theme.grey300Color}
            placeholderImage={hashtagPlaceholderImage}
          />
        </Container>
        <Container rightOuterSpacing={0.75}>
          <Icon glyph="social-tiktok-colour" size={1} verticalAlign="middle" />
        </Container>
        <Container rightInnerSpacing={1} maxWidth={9}>
          <Container id="tiktok-handle" data-testid="tiktok-handle">
            <Text color={Theme.defaultColor}>{brandManagerIdentity?.display_name}</Text>
          </Container>
          <Text block color={Theme.grey700Color} small light id="tiktok-handle-subtitle">
            <FormattedMessage id="connectedAccounts.tiktok.drawer.identity.subtitle" />
          </Text>
        </Container>
      </Flex>

      <ConnectedAccountsDrawerIdentityTikTokIcon isActive={isActive} onClick={onClick} />
    </TikTokIdentityWrapper>
  )
}

export { ConnectedAccountsDrawerIdentityTikTok }
