import React, { PropsWithChildren } from 'react'

import equal from 'fast-deep-equal'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouterProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { BuilderSteps } from '@enums'
import { IApplicationState } from '@store'
import { saveCampaign } from '@store/campaigns'
import { IDraft, saveDraft, selectDraft } from '@store/drafts'
import { selectMinimumInvoiceBudgetForUserRegion } from '@store/me'
import { selectIsAnyUploading } from '@store/moodboard'
import { Form, IWithFormValidationBaseProps, withFormValidation } from '@tribegroup'
import { IValidationResult } from '@tribegroup'
import { validateSteps } from './rules'

export interface IBuilderValidationContextProps
  extends IBuilderValidationProviderProps,
    IWithFormValidationBaseProps {
  updateForm: (draftProps: Partial<IDraft>) => void
  validationResults: Record<string, IValidationResult>
  clearFieldValidation: (
    eventorName: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | string,
  ) => void
  stepsValidation: Record<BuilderSteps, boolean>
  hasStepValidationErrors: boolean
  setContinueCallback: (callback) => void
  setEnableActions: (enable: boolean) => void
  checkValidationResults: (fieldName: string) => boolean
  getValidationMessage: (fieldName: string) => string | undefined
  isEnableActions: boolean
  formChanged?: boolean
  setFormChanged: (enable: boolean) => void
  setEnableSubmit: (enable: boolean) => void
  isEnableSubmit: boolean
}

interface IBuilderValidationProviderProps {
  currentStep: BuilderSteps
  nextStep?: BuilderSteps
}

interface IInternalProps
  extends IBuilderValidationProviderProps,
    IBuilderValidationContextProps,
    InjectedIntlProps,
    RouterProps {
  isUpdating?: boolean
  setForm: (form: React.Ref<HTMLFormElement>) => void
  draft: IDraft
  saveCampaign: typeof saveCampaign
  saveDraft: typeof saveDraft
  minimumInvoiceBudget?: number
  isAnyUploading?: boolean
}

const builderSteps = Object.values(BuilderSteps)

export const BuilderValidationContext = React.createContext({ currentStep: {} })

export class BuilderValidationProvider extends React.PureComponent<
  PropsWithChildren<IInternalProps>
> {
  form = React.createRef()
  completedStep = this.props.draft.step_completed
  completedStepIndex = builderSteps.findIndex((step) => step === this.completedStep)
  currentStepIndex = builderSteps.findIndex((step) => step === this.props.currentStep)

  state = {
    enableSubmit: this.props.currentStep !== BuilderSteps.BRAND,
    enableActions: true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    continueCallback: (params: any) => false,
    formLoaded: false,
    formChanged: false,
  }

  onContinue = (event: React.FormEvent) => {
    event.preventDefault()
    if (this.state.continueCallback) {
      const { nextStep, draft, currentStep, history } = this.props
      this.state.continueCallback({
        nextStep,
        draft,
        currentStep,
        history,
        saveDraft: this.props.saveDraft,
        saveCampaign: this.props.saveCampaign,
        isAnyUploading: this.props.isAnyUploading,
      })
    }
  }

  onLoad = () => {
    if (!this.state.formLoaded && this.currentStepIndex <= this.completedStepIndex) {
      this.props.validate()
    }
    this.setState({
      formLoaded: true,
    })
  }

  setContinueCallback = (callback) => {
    this.setState({
      continueCallback: callback,
    })
  }

  setEnableSubmit = (enable: boolean) => {
    this.setState({
      enableSubmit: enable,
    })
  }

  setEnableActions = (enable: boolean) => {
    this.setState({
      enableActions: enable,
    })
  }

  setFormChanged = (formChanged: boolean) => {
    this.setState({
      formChanged,
    })
  }

  checkValidationResults = (fieldName: string) => {
    const { validationResults } = this.props
    return (
      Boolean(validationResults && validationResults[fieldName]) &&
      validationResults[fieldName].hasError
    )
  }

  getValidationMessage = (fieldName: string) => {
    const { validationResults } = this.props
    return (
      validationResults && validationResults[fieldName] && validationResults[fieldName].errorMessage
    )
  }

  updateForm = (draftProps: Partial<IDraft>) => {
    const updatedProperties = getFormChanges(draftProps, this.props.draft)
    if (updatedProperties.length > 0) {
      this.props.saveDraft({ ...this.props.draft, ...draftProps })
      this.setFormChanged(true)
    }
  }

  render() {
    const {
      currentStep,
      nextStep,
      validate,
      clearFieldValidation,
      validationResults,
      draft,
      minimumInvoiceBudget,
    } = this.props

    const stepsValidation = validateSteps(draft, { minimumInvoiceBudget })

    const contextProps: IBuilderValidationContextProps = {
      currentStep,
      nextStep,
      stepsValidation,
      validate,
      checkValidationResults: this.checkValidationResults,
      getValidationMessage: this.getValidationMessage,
      clearFieldValidation,
      hasStepValidationErrors: !Object.values(stepsValidation).every(Boolean),
      setContinueCallback: this.setContinueCallback,
      setEnableSubmit: this.setEnableSubmit,
      isEnableSubmit: this.state.enableSubmit,
      setEnableActions: this.setEnableActions,
      isEnableActions: this.state.enableActions,
      updateForm: this.updateForm,
      formChanged: this.state.formChanged,

      // should eventually make these internal only when no longer used by consuming components
      validationResults,

      // TODO: Deprecate
      setFormChanged: this.setFormChanged,
      validationRules: {} as any,
    }

    return (
      <BuilderValidationContext.Provider value={contextProps}>
        <Form
          onBeforeSubmit={this.props.validate}
          onSubmit={this.onContinue}
          setForm={this.props.setForm}
          onLoad={this.onLoad}
        >
          {this.props.children}
        </Form>
      </BuilderValidationContext.Provider>
    )
  }
}

export const getFormChanges = (draftProps: IDraft, lastDraft: IDraft) => {
  const updatedProperties = Object.keys(draftProps).filter((key) => {
    if (
      key === 'submission_type' &&
      lastDraft.supported_submission_types &&
      lastDraft.supported_submission_types[0]
    ) {
      return false
    }
    const alreadyEmpty = draftProps[key] === '' && lastDraft[key] === undefined
    const replacedWithAnotherEmptyArray =
      draftProps[key] &&
      draftProps[key].length === 0 &&
      lastDraft[key] &&
      lastDraft[key].length === 0
    const uninitializedAndReplacedWithEmpty =
      draftProps[key] && draftProps[key].length === 0 && lastDraft[key] === undefined
    if (alreadyEmpty || replacedWithAnotherEmptyArray || uninitializedAndReplacedWithEmpty) {
      return false
    }
    return !equal(draftProps[key], lastDraft[key])
  })
  return updatedProperties
}

const mapDispatchToProps = {
  saveCampaign,
  saveDraft,
}

const mapStateToProps = (state: IApplicationState, ownProps) => {
  const currentStepIndex = Object.values(BuilderSteps).findIndex(
    (step) => step === ownProps.currentStep,
  )
  const nextStep = Object.values(BuilderSteps)[currentStepIndex + 1]
  const draft = selectDraft(state)
  return {
    nextStep,
    draft,
    minimumInvoiceBudget: selectMinimumInvoiceBudgetForUserRegion(state),
    isAnyUploading: draft.id && selectIsAnyUploading(state, draft.id),
  }
}

export default compose<IInternalProps, PropsWithChildren<IBuilderValidationProviderProps>>(
  withFormValidation,
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(BuilderValidationProvider)
