import { delay } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  FeedbackActionTypes,
  fetchFeedback,
  fetchFeedbackError,
  fetchFeedbackSuccess,
  markAsRead,
} from '@store/feedback'
import { fetchSubmission, selectFeedbackLink } from '@store/submissions'
import { networkRequest } from '@utils'

export function* handleFetchFeedback(action: ReturnType<typeof fetchFeedback>) {
  try {
    const { submissionId, campaignId } = action.payload
    const authToken: string = yield select(selectAuthToken)
    const feedbackLink: IHateoasLink = yield select(selectFeedbackLink, submissionId)

    const result = yield call(
      networkRequest,
      feedbackLink.method,
      feedbackLink.href,
      undefined,
      authToken,
    )

    yield put(fetchFeedbackSuccess(submissionId, result))
    yield put(markAsRead(submissionId))
    yield call(delay, 100)
    yield put(fetchSubmission(submissionId, campaignId))
  } catch (error) {
    yield put(fetchFeedbackError(error))
  }
}

export function* watchListFeedbackRequest() {
  yield takeLatest(FeedbackActionTypes.FETCH_REQUEST, handleFetchFeedback)
}
