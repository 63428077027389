import { FormattedMessage } from 'react-intl'

import { Text } from '@tribegroup'
import Loading from '../Loading'
import { SnackbarContainer } from './Base.styled'
import { ISnackbar } from './types'

export const SnackbarInProgress: React.FC<{ snackbar: ISnackbar }> = ({ snackbar }) => {
  return (
    <SnackbarContainer>
      <Loading iconOnly yPadding={0} />
      <Text small>
        <FormattedMessage id={snackbar.message} />
      </Text>
    </SnackbarContainer>
  )
}
