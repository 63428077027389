enum SubmissionChannels {
  Instagram = 'instagram',
  Facebook = 'facebook',
  Pinterest = 'pinterest',
  TikTok = 'tiktok',
  Twitter = 'twitter',
  X = 'x',
  YouTube = 'youtube',
}

export { SubmissionChannels }
