import { createAction } from '@reduxjs/toolkit'

import { UIActionTypes, UIFlag } from './types'

export const setUIFlag = createAction<UIFlag>(UIActionTypes.SET_FLAG)

export const clearUIFlag = createAction<UIFlag>(UIActionTypes.CLEAR_FLAG)

export const setUIFlagData = createAction<{
  flag: UIFlag
  data: unknown
}>(UIActionTypes.SET_FLAG_DATA)
