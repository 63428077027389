import React from 'react'

import Image from '@components/UI/Image'
import { AspectRatios } from '@enums'
import Theme from '@theme'
import { FadeIn } from '@tribegroup'

interface IProps {
  src: string
  aspectRatio?: AspectRatios
}

const MediaThumbnail: React.FC<IProps> = ({ aspectRatio, src }) => (
  <Image
    src={src}
    aspectRatio={aspectRatio}
    animation={FadeIn}
    backgroundColor={Theme.whiteColor}
    loadingColor={Theme.whiteColor}
  />
)

export { MediaThumbnail }
export default MediaThumbnail
