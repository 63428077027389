import { call, put, select, takeLatest } from 'redux-saga/effects'

import { ContentLibraryFilterTypes } from '@enums'
import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import { selectContentLibraryLink } from '@store/me'
import {
  fetchMediaObjects,
  FetchMediaObjectsActionTypes,
  fetchMediaObjectsError,
  fetchMediaObjectsSuccess,
  selectFetchMediaObjectsLink,
} from '@store/mediaObjects'
import {
  selectBrandFilter,
  selectChannelFilter,
  selectContentLibraryFilterKey,
  selectMediaTypeFilter,
} from '@store/router'
import { networkRequest } from '@utils'

export function* handleFetchMediaObjects(action: ReturnType<typeof fetchMediaObjects>) {
  try {
    const { filter = '', isFetchNext } = action.payload
    const authToken: string = yield select(selectAuthToken)
    const filterKey = yield select(selectContentLibraryFilterKey)

    const contentLibraryLink: IHateoasLink = yield select(
      selectFetchMediaObjectsLink,
      filterKey,
      isFetchNext,
      selectContentLibraryLink,
    )

    const brandFilter = yield select(selectBrandFilter)
    const channelFilter = yield select(selectChannelFilter)
    const mediaTypeFilter = yield select(selectMediaTypeFilter)

    const {
      items,
      links,
      metadata,
      shareable_content_library_token: shareableCLToken,
    } = yield call(
      networkRequest,
      contentLibraryLink.method,
      contentLibraryLink.href
        .replace('{filter}', filter)
        .replace('{brand_filter}', brandFilter ?? '')
        .replace('{channel_filter}', channelFilter ?? '')
        .replace('{media_type_filter}', mediaTypeFilter ?? ''),
      undefined,
      authToken,
    )

    yield put(
      fetchMediaObjectsSuccess(
        items,
        links,
        metadata,
        filterKey as ContentLibraryFilterTypes,
        shareableCLToken,
      ),
    )
  } catch (error) {
    yield put(fetchMediaObjectsError(error))
  }
}

export function* watchFetchMediaObjectsRequest() {
  yield takeLatest(FetchMediaObjectsActionTypes.FETCH_REQUEST, handleFetchMediaObjects)
}
