import React from 'react'

import { FormattedMessage } from 'react-intl'

import { TRIBE_SIGNUP_SCL_CTA } from '@constants'
import { Button, Header, Link } from '@tribegroup'

interface IProps {
  outlined?: boolean
}

const SignUpCallToAction: React.FC<IProps> = ({ outlined }) => {
  return (
    <Link target="_blank" href={TRIBE_SIGNUP_SCL_CTA}>
      <Button
        data-testid="get-started-button"
        height={2}
        noFocus
        scheme="primary"
        small
        outlined={outlined}
        width={11.25}
      >
        <Header cta small>
          <FormattedMessage id="contentLibrary.shareable.getStarted" />
        </Header>
      </Button>
    </Link>
  )
}

export { SignUpCallToAction }
export default SignUpCallToAction
