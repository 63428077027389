import { ConnectBrandManagerIdentities } from '@store/brandManagerIdentities'

export const connectIdentities = () => ({
  type: ConnectBrandManagerIdentities.CONNECT_REQUEST,
})

export const connectIdentitiesSuccess = () => ({
  type: ConnectBrandManagerIdentities.CONNECT_SUCCESS,
})

export const connectIdentitiesError = (error: any) => ({
  payload: error,
  type: ConnectBrandManagerIdentities.CONNECT_ERROR,
})

export const connectAdAccount = (payload) => ({
  type: ConnectBrandManagerIdentities.CONNECT_AD_ACCOUNT_REQUEST,
  payload: payload,
})
