import React, { PropsWithChildren } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { RouteProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { CampaignStatuses } from '@enums'
import { IApplicationState } from '@store'
import {
  ICampaign,
  selectActivateWithFeeLink,
  selectEditLink,
  selectHasAnyAction,
  selectHasBrandFanGroupAssociation,
} from '@store/campaigns'
import { generateEditLink } from '@utils'
import CampaignBrandFansIndicator from '../BrandFans/Indicator'
import { CampaignMenuActionsProvider } from '../Menu'
import CampaignCardBudgetTracker from './BudgetTracker/BudgetTracker'
import CampaignCardChannels from './Channels/Channels'
import CampaignCardImage from './Image/Image'
import CampaignCardOverlayActivate from './Overlay/Activate/Activate'
import CampaignCardPill from './Pill/Pill'
import CampaignCardRelativeDate from './RelativeDate/RelativeDate'
import CampaignCardTitle from './Title/Title'
import CampaignCardStyled, {
  CampaignCardContent,
  CampaignCardContentHeader,
  MidContentWrapperStyled,
  WrapperStyled,
} from './Card.styled'
import CampaignCardMoreMenu from './MoreMenu'

export interface ICampaignCardProps {
  campaign: ICampaign
  showPill?: boolean
}

type IInternalProps = ConnectedProps<typeof connector> & ICampaignCardProps & RouteProps

interface ICampaignCardWrapperProps {
  clickthroughLink?: string
}

export const CampaignCardWrapper: React.FC<PropsWithChildren<ICampaignCardWrapperProps>> = ({
  clickthroughLink,
  children,
}) => {
  if (clickthroughLink) {
    return (
      <Link to={clickthroughLink}>
        <WrapperStyled>{children}</WrapperStyled>
      </Link>
    )
  }
  return <WrapperStyled>{children}</WrapperStyled>
}

const CampaignCard = ({
  campaign,
  showPill = true,
  hasAnyAction,
  location,
  hasEditPermission,
  hasBrandFanGroupAssociation,
  hasActivateWithFeeLink,
}: IInternalProps) => {
  const getClickthroughLink = () => {
    if (hasEditPermission && campaign.status === CampaignStatuses.Draft) {
      return generateEditLink(campaign)
    }
    if (campaign.status !== CampaignStatuses.Draft) {
      return `/campaigns/${campaign.id}${location && location.search}`
    }
    return undefined
  }

  return (
    <CampaignCardStyled
      data-cy-element="campaign-card"
      data-cy-element-id={campaign.product_name && campaign.product_name.toLowerCase()}
    >
      <CampaignCardWrapper clickthroughLink={getClickthroughLink()}>
        <CampaignCardImage campaign={campaign} />
        <CampaignCardPill show={showPill} campaign={campaign} />
        <CampaignCardContent>
          <CampaignCardContentHeader>
            <CampaignCardTitle campaign={campaign} />
            <CampaignCardChannels
              channels={campaign.channels}
              supportedTypes={campaign.supported_submission_types}
            />
          </CampaignCardContentHeader>
          <MidContentWrapperStyled>
            <CampaignCardRelativeDate campaign={campaign} />
          </MidContentWrapperStyled>
          <CampaignCardBudgetTracker campaign={campaign} />
        </CampaignCardContent>
      </CampaignCardWrapper>
      {hasAnyAction && (
        <CampaignMenuActionsProvider campaignId={campaign.id}>
          <CampaignCardMoreMenu />
        </CampaignMenuActionsProvider>
      )}
      {hasBrandFanGroupAssociation && <CampaignBrandFansIndicator campaignId={campaign.id} />}
      {hasActivateWithFeeLink && <CampaignCardOverlayActivate campaignId={campaign.id} />}
    </CampaignCardStyled>
  )
}

const mapStateToProps = (state: IApplicationState, { campaign: { id } }) => ({
  hasEditPermission: Boolean(selectEditLink(state, id)),
  hasAnyAction: selectHasAnyAction(state, id),
  hasBrandFanGroupAssociation: selectHasBrandFanGroupAssociation(state, id),
  hasActivateWithFeeLink: Boolean(selectActivateWithFeeLink(state, id)),
})

const connector = connect(mapStateToProps)

export { CampaignCard }
export default compose<IInternalProps, ICampaignCardProps>(withRouter, connector)(CampaignCard)
