import { createContext, PropsWithChildren, PureComponent } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { INBOX_FILTER } from '@constants'
import { IApplicationState } from '@store'
import { selectCampaignId, selectCurrentView, selectLocationsearchAsObject } from '@store/router'
import { selectAllSubmissionIds } from '@store/submissions'
import { dismissToast, toast } from '@tribegroup'

interface ISelectedSubmissionsState {
  isSelectionModeOn: boolean
  selectedSubmissionIds: ReadonlyArray<number>
}

export interface ISelectedSubmissionsContextProps extends ISelectedSubmissionsState {
  selectSubmission: (submissionId: number) => void
  deselectSubmission: (submissionId: number) => void
  emptySelection: VoidFunction
  selectAll: VoidFunction
  toggleSelectionMode: VoidFunction
  closeSelectionMode: VoidFunction
  hideToast: VoidFunction
}

interface ISelectedSubmissionsProviderProps {
  allSubmissionIds?: ReadonlyArray<number>
  selectedFilter: string
}

interface IInternalProps
  extends ISelectedSubmissionsProviderProps,
    ISelectedSubmissionsContextProps {
  currentView: string
}

export const SelectedSubmissionsContext = createContext({})

export class SelectedSubmissionsProvider extends PureComponent<
  PropsWithChildren<IInternalProps>,
  ISelectedSubmissionsState
> {
  state = {
    isSelectionModeOn: false,
    selectedSubmissionIds: [] as ReadonlyArray<number>,
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isSelectionModeOn && this.state.isSelectionModeOn) {
      const { selectedFilter } = this.props
      const filter = selectedFilter || INBOX_FILTER
      if (filter === INBOX_FILTER) {
        toast(<FormattedMessage id="inbox.cardlist.selectionMode.toast" />, false)
      }
    } else if (!this.state.isSelectionModeOn && this.state.selectedSubmissionIds.length !== 0) {
      dismissToast()
    }
    if (prevProps.currentView !== this.props.currentView) {
      this.closeSelectionMode()
    }
  }

  toggleSelectionMode = () => {
    this.setState((prevState) => {
      return {
        isSelectionModeOn: !prevState.isSelectionModeOn,
        selectedSubmissionIds: [],
      }
    })
  }

  hideToast = () => {
    dismissToast()
  }

  closeSelectionMode = () => {
    this.setState({ isSelectionModeOn: false, selectedSubmissionIds: [] })
  }

  selectSubmission = (submissionId: number) => {
    this.setState((prevState) => ({
      selectedSubmissionIds: [...prevState.selectedSubmissionIds, submissionId],
    }))
  }

  deselectSubmission = (submissionId: number) =>
    this.setState((prevState) => ({
      selectedSubmissionIds: prevState.selectedSubmissionIds.filter((id) => id !== submissionId),
    }))

  emptySelection = () =>
    this.setState({
      selectedSubmissionIds: [] as ReadonlyArray<number>,
    })

  selectAll = () =>
    this.setState({
      selectedSubmissionIds: this.props.allSubmissionIds || [],
    })

  render() {
    const contextProps: ISelectedSubmissionsContextProps = {
      selectSubmission: this.selectSubmission,
      deselectSubmission: this.deselectSubmission,
      toggleSelectionMode: this.toggleSelectionMode,
      closeSelectionMode: this.closeSelectionMode,
      isSelectionModeOn: this.state.isSelectionModeOn,
      selectedSubmissionIds: this.state.selectedSubmissionIds,
      emptySelection: this.emptySelection,
      selectAll: this.selectAll,
      hideToast: this.hideToast,
    }

    return (
      <SelectedSubmissionsContext.Provider value={contextProps}>
        {this.props.children}
      </SelectedSubmissionsContext.Provider>
    )
  }
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const searchParams = selectLocationsearchAsObject(state)
  const campaignId = selectCampaignId(match) as number
  const selectedFilter = searchParams.filter && decodeURIComponent(searchParams.filter)
  return {
    selectedFilter,
    allSubmissionIds: selectAllSubmissionIds(state, campaignId!, selectedFilter),
    currentView: selectCurrentView(state),
  }
}

export default compose<ISelectedSubmissionsProviderProps, any>(
  withRouter,
  connect(mapStateToProps),
)(SelectedSubmissionsProvider)
