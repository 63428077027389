import { useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { IApplicationState } from '@store'
import { ILicenseOption } from '@store/campaigns'
import { selectMediaObjectErrors } from '@store/mediaObjects'
import { fetchPurchaseOrders, IPurchaseOrder, selectHasPurchaseOrders } from '@store/purchaseOrders'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { CreditManagementPaymentMethodPurchaseOrderEmpty } from './Empty'
import { CreditManagementPaymentMethodPurchaseOrderList } from './List'

interface IProps {
  licenseOption: ILicenseOption
  onSelectPurchaseOrders: (purchaseOrderIds: IPurchaseOrder[]) => void
}

export const CreditManagementPaymentMethodPurchaseOrder = ({
  licenseOption,
  onSelectPurchaseOrders,
}: IProps) => {
  const dispatch = useDispatch()

  const { hasPurchaseOrders, hasErrors } = useSelector((state: IApplicationState) => {
    return {
      hasPurchaseOrders: selectHasPurchaseOrders(state),
      hasErrors: selectMediaObjectErrors(state),
    }
  })

  useEffect(() => {
    dispatch(fetchPurchaseOrders())
  }, [hasErrors])

  if (hasErrors) {
    return (
      <Container topOuterSpacing={13.125}>
        <Container
          height={2}
          width={2}
          backgroundColor={Theme.error100Color}
          flexCenter
          borderRadius="50%"
          bottomOuterSpacing={0.75}
        >
          <Text color={Theme.errorColor} customFontSize={1.25}>
            <FormattedMessage id="contentLibrary.licensing.creditManagement.hasError.icon" />
          </Text>
        </Container>
        <Container bottomOuterSpacing={0.5} width={21.25}>
          <Text small color={Theme.errorColor}>
            <FormattedMessage id="contentLibrary.licensing.creditManagement.purchaseOrder.hasError.label.first" />
          </Text>
        </Container>
        <Container bottomOuterSpacing={0.5} width={21.25}>
          <Text small color={Theme.errorColor}>
            <FormattedMessage id="contentLibrary.licensing.creditManagement.purchaseOrder.hasError.label.second" />
          </Text>
        </Container>
      </Container>
    )
  }

  if (!hasPurchaseOrders) {
    return <CreditManagementPaymentMethodPurchaseOrderEmpty />
  }

  return (
    <CreditManagementPaymentMethodPurchaseOrderList
      licenseOption={licenseOption}
      onSelectPurchaseOrders={onSelectPurchaseOrders}
    />
  )
}
