import React from 'react'

import logo from '@images/brand-logo-full.png'
import { Container } from '@tribegroup'

const ShareableContentLibraryLogo: React.FC = () => (
  <Container width={9.5} height={2.25} data-testid="shareable-content-library-logo" flexCenter>
    <img width="auto%" height="100%" src={logo} alt="brand-logo" />
  </Container>
)

export { ShareableContentLibraryLogo }
export default ShareableContentLibraryLogo
