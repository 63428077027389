import { ICurrentRange } from '../Dots'
import { StyledDot } from './Dot.styled'

interface IDotProps {
  currentRange: ICurrentRange
  activeFrameNumber: number
  frameNumber: number
  previous: number
  next: number
}

export const Dot = ({
  activeFrameNumber,
  currentRange,
  frameNumber,
  previous,
  next,
}: IDotProps) => {
  const active = activeFrameNumber === frameNumber
  if (frameNumber === previous || frameNumber === next) {
    return <StyledDot active={active} small />
  }
  if (frameNumber === previous - 1 || frameNumber === next + 1) {
    return <StyledDot active={active} xsmall />
  }
  if (frameNumber >= currentRange.start && frameNumber <= currentRange.end) {
    return <StyledDot active={active} />
  }
  return <StyledDot hidden />
}
