import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  CreateSetupIntentActionTypes,
  createSetupIntentError,
  createSetupIntentSuccess,
  selectCreditCardLink,
} from '@store/me'
import { networkRequest } from '@utils'

export function* handleCreateSetupIntent() {
  try {
    const authToken: string = yield select(selectAuthToken)
    const createSetupIntentLink: IHateoasLink = yield select(selectCreditCardLink)
    const setupIntent = yield call(
      networkRequest,
      createSetupIntentLink.method,
      createSetupIntentLink.href,
      undefined,
      authToken,
    )
    yield put(createSetupIntentSuccess(setupIntent.client_secret))
  } catch (error) {
    yield put(createSetupIntentError(error))
  }
}

export function* watchCreateSetupIntentRequest() {
  yield takeLatest(CreateSetupIntentActionTypes.CREATE_REQUEST, handleCreateSetupIntent)
}
