import { RemoveBrandFanGroupMemberActionTypes } from '@store/brandFans'

export const removeBrandFanGroupMember = (
  identityId: number,
  groupId: number,
  campaignId?: number,
  submissionId?: number,
  influencerId?: number,
) => ({
  payload: { identityId, groupId, campaignId, submissionId, influencerId },
  type: RemoveBrandFanGroupMemberActionTypes.REMOVE_MEMBER_REQUEST,
})

export const removeBrandFanGroupMemberSuccess = (identityId: number, groupId: number) => ({
  payload: { identityId, groupId },
  type: RemoveBrandFanGroupMemberActionTypes.REMOVE_MEMBER_SUCCESS,
})

export const removeBrandFanGroupMemberError = (groupId: number, error: any) => ({
  payload: { groupId, error },
  type: RemoveBrandFanGroupMemberActionTypes.REMOVE_MEMBER_ERROR,
})
