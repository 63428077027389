import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { returnSecondIfDeepEqual } from '@utils'
import { AudienceReportingActionTypes, IAudienceReportingState } from './types'

const initialState: IAudienceReportingState = {
  audienceReportingByCampaignId: {},
  errors: undefined,
  isFetching: false,
}

export const audienceReportingReducer: Reducer<IAudienceReportingState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case AudienceReportingActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case AudienceReportingActionTypes.FETCH_SUCCESS: {
      const { campaignId, audienceReporting } = action.payload
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        audienceReportingByCampaignId: returnSecondIfDeepEqual(
          {
            ...state.audienceReportingByCampaignId,
            [campaignId]: audienceReporting,
          },
          state.audienceReportingByCampaignId,
        ),
      }
    }
    case AudienceReportingActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }
    }
    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState
    default:
      return state
  }
}
