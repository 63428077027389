import { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { Text } from '@tribegroup'

interface IError {
  showResponseErrorMessage?: boolean
  errorMessage?: string
  errorCode?: string
}

export const Error = ({ showResponseErrorMessage, errorMessage, errorCode }: IError) => (
  <Fragment>
    {showResponseErrorMessage && (
      <Text color={Theme.errorColor} small>
        {errorMessage}
      </Text>
    )}
    {errorCode && !showResponseErrorMessage && (
      <Text color={Theme.errorColor} small>
        <FormattedMessage id={errorCode} />
      </Text>
    )}
    {!errorCode && !showResponseErrorMessage && (
      <Text color={Theme.errorColor} small>
        <FormattedMessage id="core.text.genericError" />
      </Text>
    )}
  </Fragment>
)

export default Error
