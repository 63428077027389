import { createSelector } from 'reselect'

import { IApplicationState } from '@store'
import { UIFlag } from '@store/ui'

const selectUIState = (state: IApplicationState) => state.ui

export const selectUIFlag = (state: IApplicationState, flag: UIFlag) => {
  return state.ui.enabled[flag]
}

export const selectUIFlagData = createSelector(
  selectUIState,
  (_, flag: UIFlag) => flag,
  (state, flag) => state.data[flag],
)
