import React from 'react'

import { FormattedMessage } from 'react-intl'

import {
  CheckItem,
  CoachmarkBlock,
  CoachmarkContent,
} from '@components/UI/Coachmark/Coachmark.styled'
import { Hr } from '@components/UI/Hr/Hr.styled'
import Image from '@components/UI/Image'
import budgetSelectionTooltipAsset from '@images/campaignbuilder/cb-budget-selection-tooltip-asset.png'
import Theme from '@theme'
import { Container, Header, Icon, Text } from '@tribegroup'

const BuilderBudgetCreatorGroupSelectionCoachMarkContent: React.FC = () => {
  return (
    <CoachmarkContent data-testid="budget-creator-groups-selection-coach-mark-content">
      <CoachmarkBlock>
        <Container bottomOuterSpacing={1.25}>
          <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.5}>
            <FormattedMessage id="builder.budget.creatorGroup.creator_group_community" />
          </Header>
          <CheckItem marginBottom={0.5}>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1.25} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.budget.creatorGroup.tip.item.publicCampaign" />
            </Text>
          </CheckItem>
          <CheckItem marginBottom={0.5}>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1.25} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.budget.creatorGroup.tip.item.visibleToCommunity" />
            </Text>
          </CheckItem>
          <CheckItem marginBottom={0.5}>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1.25} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.budget.creatorGroup.tip.item.customTarget" />
            </Text>
          </CheckItem>
        </Container>
        <Hr />
        <Container topOuterSpacing={1.25}>
          <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.5}>
            <FormattedMessage id="builder.budget.creatorGroup.creator_group_brand_fans" />
          </Header>
          <CheckItem marginBottom={0.5}>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1.25} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.budget.creatorGroup.tip.item.privateCampaign" />
            </Text>
          </CheckItem>
          <CheckItem marginBottom={0.5}>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1.25} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.budget.creatorGroup.tip.item.visibleByInvite" />
            </Text>
          </CheckItem>
          <CheckItem marginBottom={0.5}>
            <Icon glyph="tick-heavy" color={Theme.successColor} size={1.25} />
            <Text color={Theme.grey900Color}>
              <FormattedMessage id="builder.budget.creatorGroup.tip.item.smallGroups" />
            </Text>
          </CheckItem>
        </Container>
        <Container topInnerSpacing={1} data-testid="tool-tip-image">
          <Image src={budgetSelectionTooltipAsset} alt="tooltip-asset" />
        </Container>
      </CoachmarkBlock>
    </CoachmarkContent>
  )
}

export default BuilderBudgetCreatorGroupSelectionCoachMarkContent
