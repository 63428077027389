import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { IMediaState, MediaUploadActionTypes } from '@store/media'

const initialState: IMediaState = {
  uploading: [],
  errors: undefined,
  media: undefined,
}

const reducer: Reducer<IMediaState> = (state = initialState, action) => {
  switch (action.type) {
    case MediaUploadActionTypes.UPLOAD_REQUEST: {
      return {
        ...initialState,
        uploading: [
          ...state.uploading.filter(item => item !== action.payload.uploadId),
          action.payload.uploadId,
        ],
      }
    }

    case MediaUploadActionTypes.UPLOAD_SUCCESS: {
      return {
        ...initialState,
        media: action.payload.media,
        uploading: state.uploading.filter(item => item !== action.payload.uploadId),
      }
    }

    case MediaUploadActionTypes.UPLOAD_ERROR: {
      return {
        ...state,
        media: action.payload,
        uploading: state.uploading.filter(item => item !== action.payload.uploadId),
        errors: action.payload,
      }
    }

    case MediaUploadActionTypes.UPLOAD_CLEAR: {
      return {
        ...state,
        media: undefined,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as mediaReducer }
