import { ErrorCodes } from '@enums'
import { IResponseErrorMessage } from '@lib/error'

interface IStripeResponseError {
  message: string
  decline_code?: string
  code?: string
}

export const stripeErrorToResponseError = (
  stripeError: IStripeResponseError,
  defaultErrorCode?: ErrorCodes,
): IResponseErrorMessage => {
  const { message, decline_code, code } = stripeError
  return {
    message,
    error_code: decline_code || code || defaultErrorCode || '',
  }
}
