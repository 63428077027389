import { EventTrackingNames } from '@enums'
import { AutoSwitchActionTypes, IMe } from '@store/me'
import { toEventsTracking } from '@utils'

export const autoSwitch = (me: IMe) => ({
  type: AutoSwitchActionTypes.AUTOSWITCH_REQUEST,
  meta: {
    analytics: toEventsTracking(EventTrackingNames.SwitchBrandApp, {
      brand_manager_id: me.id,
      switch_to: 'classic',
    }),
  },
})

export const autoSwitchError = (error: any) => ({
  type: AutoSwitchActionTypes.AUTOSWITCH_ERROR,
  payload: error,
})
