import { call, put, select, takeEvery } from 'redux-saga/effects'

import { EventTrackingNames, MemberRoleTypes } from '@enums'
import { IHateoasLink } from '@store'
import { trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import { selectCampaignStatus, selectRole } from '@store/campaigns'
import {
  IMember,
  MemberActionTypes,
  removeMember,
  removeMemberError,
  removeMemberSuccess,
  selectMember,
  selectRemoveMemberLink,
} from '@store/members'
import { networkRequest } from '@utils'

export function* handleRemove(action: ReturnType<typeof removeMember>) {
  try {
    const authToken: string = yield select(selectAuthToken)

    const { campaignId, memberId } = action.payload
    const role: MemberRoleTypes = yield select(selectRole, campaignId)
    const member: IMember = yield select(selectMember, memberId)
    const briefState = yield select(selectCampaignStatus, campaignId)

    const removeMemberLink: IHateoasLink = yield select(selectRemoveMemberLink, memberId)
    yield call(networkRequest, removeMemberLink.method, removeMemberLink.href, undefined, authToken)

    yield put(removeMemberSuccess(campaignId, memberId))

    yield put(
      trackEvent(EventTrackingNames.RemoveMember, {
        brief_id: campaignId,
        role,
        removed_user_role: member.role_type,
        brief_state: briefState,
      }),
    )
  } catch (error) {
    yield put(removeMemberError(error))
  }
}

export function* watchRemoveMemberRequest() {
  yield takeEvery(MemberActionTypes.REMOVE_REQUEST, handleRemove)
}
