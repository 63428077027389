import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

import { FadeIn } from '../Effects'
import { Icon } from '../Icon'
import { themed } from '../Theme'

export const TipText = themed(styled(Flex)`
  ${({ theme }) => css`
    font-size: 0.75rem;
    line-height: 1.33;
    letter-spacing: 0.3px;
    color: ${theme.grey800Color};
  `};
`)

export const TipIcon = themed(styled(Icon)`
  ${({ theme, color }) => css`
    svg {
      fill: ${theme.grey800Color};
      ${color &&
      css`
        fill: ${color};
      `};
    }
  `};
`)

export const TipIconWrapper = themed(styled(Flex)`
  ${({ theme, padded, backgroundColor }) => css`
    flex-shrink: 0;
    margin-right: 0.75rem;
    border-radius: 10rem;
    background-color: ${theme.alert100Color};
    width: 2rem;
    height: 2rem;
    ${!padded &&
    css`
      background-color: transparent;
      width: initial;
      height: initial;
    `}
    ${backgroundColor && `background-color: ${backgroundColor}`};
  `};
`)

export const CoachMarkWrapper = themed(styled.div`
  ${({ theme, position, pointerXPosition, width, height, yOffset = 0, padded = true }) => css`
    cursor: default;
    z-index: 2;
    border-radius: 0.5rem;
    background: ${theme.whiteColor};
    width: ${width}rem;
    height: ${height}rem;
    box-sizing: border-box;
    position: absolute;
    animation: ${FadeIn} 0.2s ease-in forwards;

    &::after {
      animation: ${FadeIn} 0.2s ease-in forwards;
      position: absolute;
      content: ' ';
    }
    ${position === 'top' &&
    css`
      bottom: ${(padded ? 3 : 2) + yOffset}rem;

      &::after {
        left: calc(50% - ${padded ? '.3225rem' : '1rem'});
        bottom: -0.5625rem;
        ${pointerXPosition === 'center' &&
        css`
          border-left: 0.625rem solid transparent;
          border-right: 0.625rem solid transparent;
          border-top: 0.625rem solid ${theme.whiteColor};
        `};
        ${pointerXPosition === 'left' &&
        css`
          border-top: transparent solid 0;
          border-right: transparent solid 0;
          border-bottom: transparent solid 0.625rem;
          border-left: ${theme.whiteColor} solid 1.625rem;
          height: 0;
          left: 0;
          bottom: -0.45rem;
        `};
      }
    `};
    ${position === 'bottom' &&
    css`
      top: ${(padded ? 3 : 2) + yOffset}rem;

      &::after {
        left: calc(50% - ${padded ? '0.3225rem' : '1rem'});
        top: -0.5625rem;
        ${pointerXPosition === 'center' &&
        css`
          border-left: 0.625rem solid transparent;
          border-right: 0.625rem solid transparent;
          border-bottom: 0.625rem solid ${theme.whiteColor};
        `};
        ${pointerXPosition === 'left' &&
        css`
          border-bottom: transparent solid 0;
          border-right: transparent solid 0;
          border-top: transparent solid 0.625rem;
          border-left: ${theme.whiteColor} solid 1.625rem;
          height: 0;
          left: 0;
          top: -0.45rem;
        `};
      }
    `};
    ${position === 'left' &&
    css`
      left: calc(-${width}rem - 1rem);
      top: calc(0rem - ${yOffset}rem);

      &::after {
        border-bottom: 0.625rem solid transparent;
        border-top: 0.625rem solid transparent;
        border-left: 0.625rem solid ${theme.whiteColor};
        right: -0.5625rem;
        top: calc(0.25rem + ${yOffset}rem);
      }
    `};
    ${position === 'right' &&
    css`
      right: calc(-${width}rem - 1rem);
      top: calc(0rem - ${yOffset}rem);

      &::after {
        border-bottom: 0.625rem solid transparent;
        border-top: 0.625rem solid transparent;
        border-right: 0.625rem solid ${theme.whiteColor};
        left: -0.5625rem;
        top: calc(0.25rem + ${yOffset}rem);
      }
    `};
  `};
`)

export const TipRoot = styled.span`
  display: inline;

  > div {
    display: inline;
  }
`

export default styled(Flex)`
  position: relative;
  animation: ${FadeIn} 0.2s linear;
  ${({ width }) =>
    width &&
    css`
      max-width: ${width}rem;
    `};
  ${({ hasCoachmark }) =>
    hasCoachmark &&
    css`
      cursor: pointer;
    `};
  ${({ justifyCenter }) =>
    justifyCenter &&
    css`
      margin: auto;
    `};
  ${({ topOuterSpacing }) =>
    topOuterSpacing &&
    css`
      margin-top: ${topOuterSpacing}rem;
    `};
`

export const CoachMarkInnerWrapper = styled.div`
  ${({ maxHeightOffset = 0, overflow = 'auto' }) => css`
    max-height: calc(100vh - ${maxHeightOffset}rem);
    overflow: ${overflow};
    box-shadow: 0 0.125rem 0.625rem 0 rgb(0 0 0 / 8%);
    padding: 1.25rem;
    border-radius: 0.25rem;
    box-sizing: border-box;
  `};
`
