import { useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import Loading from '@components/UI/Loading'
import { IApplicationState } from '@store'
import {
  fetchPrePurchaseAnalytics,
  PrePurchaseAnalyticsType,
  selectIsFetchingPrePurchaseAnalytics,
  selectPrePurchaseAnalyticsType,
} from '@store/prePurchaseAnalytics'
import { selectSubmissionId } from '@store/router'
import {
  selectIdentityId,
  selectIsSubmissionFromPinterest,
  selectIsSubmissionFromTiktok,
} from '@store/submissions'
import { AnalyticsPrePurchasePinterest } from './Pinterest/Pinterest'
import { AnalyticsPrePurchaseReel } from './Reel/Reel'
import AnalyticsPrePurchasePost from './Post'
import AnalyticsPrePurchaseStory from './Story'
import { AnalyticsPrePurchaseTikTok } from './TikTok'

export type IInternalProps = ConnectedProps<typeof connector>

export const isNumber = (param) => typeof param === 'number'

export const AnalyticsPrePurchase: React.FC<IInternalProps> = ({
  identityId,
  submissionId,
  prePurchaseAnalyticsType,
  isFetching,
  isTikTok,
  isPinterest,
  fetchPrePurchaseAnalytics: fetchPrePurchaseAnalyticsAction,
}) => {
  useEffect(() => {
    fetchPrePurchaseAnalyticsAction(identityId, submissionId, prePurchaseAnalyticsType)
  }, [identityId, submissionId, prePurchaseAnalyticsType])

  if (isFetching) {
    return <Loading />
  }

  if (isTikTok) {
    return <AnalyticsPrePurchaseTikTok />
  }

  if (isPinterest) {
    return <AnalyticsPrePurchasePinterest />
  }

  if (prePurchaseAnalyticsType === PrePurchaseAnalyticsType.STORY) {
    return <AnalyticsPrePurchaseStory />
  }

  if (prePurchaseAnalyticsType === PrePurchaseAnalyticsType.REEL) {
    return <AnalyticsPrePurchaseReel />
  }

  return <AnalyticsPrePurchasePost />
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const submissionId = selectSubmissionId(match)!
  const identityId = selectIdentityId(state, submissionId)

  return {
    identityId,
    submissionId,
    prePurchaseAnalyticsType: selectPrePurchaseAnalyticsType(state, submissionId),
    isFetching: selectIsFetchingPrePurchaseAnalytics(state),
    isTikTok: selectIsSubmissionFromTiktok(state, submissionId),
    isPinterest: selectIsSubmissionFromPinterest(state, submissionId),
  }
}

const mapDispatchToProps = {
  fetchPrePurchaseAnalytics,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<IInternalProps, {}>(withRouter, connector)(AnalyticsPrePurchase)
