import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import { EventTrackingNames } from '@enums'
import { withSubmissionListFilter } from '@hocs/withSubmissionListFilter'
import hooks from '@hooks'
import Theme from '@theme'
import { DropdownMenu, Header, Icon } from '@tribegroup'
import { MenuItemLink } from '@tribegroup/Menu/Item/Item'
import { objectToQueryString } from '@utils'
import SubmissionSortStyled from './Sort.styled'

export interface ISubmissionSortProps {
  sortOptions: ReadonlyArray<string>
  campaignId: number
}

interface IInternalProps
  extends ISubmissionSortProps,
    InjectedIntlProps,
    ISubmissionListFilterContextProps {}

const SubmissionSort: React.FC<IInternalProps> = ({ sortOptions, intl, campaignId, filter }) => {
  const search = hooks.useLocationSearch()
  const currentView = hooks.useIsInfluencerView() ? 'inbox_list_view' : 'inbox'
  const selected = search.sort_by || sortOptions[0]

  return (
    <SubmissionSortStyled data-cy-element="sort">
      <Header cta color={Theme.grey900Color} uppercase>
        <FormattedMessage
          id="inbox.cardlist.sort.label"
          values={{
            selected: intl.formatMessage({
              id: `inbox.cardlist.sort.option.${selected}`,
            }),
          }}
        />
      </Header>
      <DropdownMenu
        target={<Icon key="sort-list-icon" glyph="sort" size={1.5} color={Theme.grey900Color} />}
        indicatorSpace={0}
        right={-0.75}
        top={0.75}
        width={11.75}
        elementName="sort-drop-down-menu"
      >
        {sortOptions.map((option) => (
          <ConnectedTrackedRoute
            eventName={EventTrackingNames.SortSubmissions}
            campaignId={campaignId}
            additionalProperties={{
              current_filter: filter,
              current_view: currentView,
              sort_type: option,
            }}
            key={`sort-option-${option}`}
          >
            <MenuItemLink
              to={{
                search: objectToQueryString({
                  ...search,
                  sort_by: option,
                }),
              }}
              selected={selected === option}
              iconColor={Theme.primaryColor}
            >
              <FormattedMessage
                id={`inbox.cardlist.sort.option.${option}`}
                defaultMessage={option}
              />
            </MenuItemLink>
          </ConnectedTrackedRoute>
        ))}
      </DropdownMenu>
    </SubmissionSortStyled>
  )
}

export { SubmissionSort }
export default compose<IInternalProps, ISubmissionSortProps>(
  injectIntl,
  withSubmissionListFilter,
)(SubmissionSort)
