import React from 'react'

import { IScheme } from '@tribegroup/Interfaces/schemes'
import { KEYCODE } from '@tribegroup/utils'
import TextAreaStyled, {
  Backdrop,
  CharacterCounter,
  Count,
  Highlight,
  Highlights,
  TextAreaContainer,
} from './TextArea.styled'

export interface ITextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement>, IScheme {
  /**
   * Sets the disabled attribute of the textfield
   *
   * @default auto
   */
  disabled?: boolean

  /**
   * Indicates that this field has invalid value
   *
   * @default false
   */
  invalid?: boolean

  /**
   * Indicate the textarea max-width in rem.
   *
   * @default auto
   */
  width?: number

  /**
   * Indicates textarea height in rem
   *
   * @default 8
   */
  height?: number

  /**
   * Total character allowed
   *
   * @default 800
   */
  characterLimit?: number

  /**
   * Disables the enter key from being pressed
   *
   * @default false
   */
  disableLineBreak?: boolean

  /**
   * Callback function triggered on Textarea change
   */
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void

  /**
   * Reference to the textarea element
   */
  innerRef?: React.Ref<HTMLTextAreaElement>

  /**
   * Trims the value of the textarea
   */
  trim?: boolean
}

export interface ITextAreaState {
  characterCount: number
  value: string
}

export default class TextArea extends React.PureComponent<ITextAreaProps, ITextAreaState> {
  static defaultProps = {
    height: 8,
    characterLimit: 800,
  }

  state = {
    characterCount: 0,
    value: '',
  }

  backdropRef = React.createRef()

  setStateFromProps = (props) => {
    if (props.value !== undefined) {
      const value = String(props.value)
      this.setState({
        characterCount: value.length,
        value,
      })
    }
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.defaultValue === undefined) {
      this.setStateFromProps(nextProps)
    }
  }

  componentDidMount() {
    if (this.props.defaultValue !== undefined) {
      this.setState({
        characterCount: String(this.props.defaultValue).length,
        value: this.props.defaultValue as string,
      })
    } else {
      this.setStateFromProps(this.props)
    }
  }

  onTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value
    this.setState({
      value,
      characterCount: this.props.trim ? value.trim().length : value.length,
    })
    if (this.props.onChange) {
      this.props.onChange(event)
    }
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (this.props.disableLineBreak && event.keyCode === KEYCODE.ENTER) {
      event.preventDefault()
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event)
    }
  }

  syncScroll = (event: React.SyntheticEvent) => {
    this.updateBackdropPosition(event.currentTarget.scrollTop)
  }

  updateBackdropPosition(scrollTop: number) {
    const backdropref = this.backdropRef.current as HTMLElement
    // tslint:disable-next-line:no-object-mutation
    backdropref.scrollTop = scrollTop
  }

  render() {
    const { width, height, characterLimit, innerRef, defaultValue, ...textAreaProps } = this.props
    const overLimit = this.state.characterCount > characterLimit!

    const textAreaValue =
      defaultValue !== undefined
        ? {
            defaultValue,
          }
        : {
            value: this.state.value,
          }
    return (
      <div>
        <TextAreaContainer>
          <Backdrop ref={this.backdropRef} width={width} height={height}>
            <Highlights>
              {this.state.value.slice(0, characterLimit)}
              <Highlight>
                {this.state.value.slice(characterLimit, this.state.characterCount)}
              </Highlight>
            </Highlights>
          </Backdrop>
          <TextAreaStyled
            {...textAreaProps}
            onKeyDown={this.onKeyDown}
            width={width}
            height={height}
            onChange={this.onTextAreaChange}
            onScroll={this.syncScroll}
            overLimit={overLimit}
            innerRef={innerRef}
            {...textAreaValue}
          />
        </TextAreaContainer>
        <CharacterCounter>
          <Count overLimit={overLimit}>{this.state.characterCount}</Count>/{characterLimit}
        </CharacterCounter>
      </div>
    )
  }
}
