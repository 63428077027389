import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const TitleBarContent = styled.div`
  height: 100%;
  margin: 0 2.75rem 0 5.25rem;
  ${breakpoint('tabletPortrait')`
    margin: 0 6.25rem;
  `};
`

export { TitleBarContent }
