import styled from 'styled-components'

const ChannelsWrapper = styled.span`
  display: flex;
  white-space: nowrap;
  flex-shrink: 0;
  gap: 0.5rem;
`

export default ChannelsWrapper
