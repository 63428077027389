import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'
import { FlexItem } from 'styled-flex-component'

import MediaObjectCardPriceSummary from '@components/MediaObject/Card/PriceSummary/PriceSummary'
import { SubmissionCardPriceSumaryWrapper } from '@components/Submission/Card/PricePanel/PricePanel.styled'
import { PricePanelStyled, PricePanelWrapper } from '@components/UI/PricePanel/PricePanel.styled'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import hooks from '@hooks'
import { IApplicationState } from '@store'
import { fetchMediaObjectLicenseOptions } from '@store/licenses'
import { selectLicenseOptionsRequired } from '@store/mediaObjects'
import MediaObjectFullPricePanelAmount from './Amount/Amount'
import MediaObjectFullPricePanelStatus from './Status/Status'

interface IProps {
  mediaObjectId: number
}

interface IInternalProps extends IProps {
  shouldRequestLicenseOptions: boolean
  fetchLicenseOptions: typeof fetchMediaObjectLicenseOptions
}

export const MediaObjectFullPricePanel: React.FC<IInternalProps> = ({
  mediaObjectId,
  shouldRequestLicenseOptions,
  fetchLicenseOptions: fetchLicenseOptionsAction,
}) => {
  const { toggleIsVisible, toggleHide, toggle } = hooks.useToggle()
  React.useEffect(() => {
    if (shouldRequestLicenseOptions) {
      fetchLicenseOptionsAction(mediaObjectId)
    }
  }, [])
  return (
    <React.Fragment>
      {toggleIsVisible && (
        <SubmissionCardPriceSumaryWrapper>
          <MediaObjectCardPriceSummary onHide={toggleHide} mediaObjectId={mediaObjectId} />
        </SubmissionCardPriceSumaryWrapper>
      )}
      <ConnectedTrackedRoute
        eventName={EventTrackingNames.ViewPriceSummary}
        mediaObjectId={mediaObjectId}
      >
        <PricePanelStyled onClick={toggle}>
          <PricePanelWrapper justifyBetween alignCenter>
            <FlexItem>
              <MediaObjectFullPricePanelAmount />
            </FlexItem>
            <MediaObjectFullPricePanelStatus mediaObjectId={mediaObjectId} />
          </PricePanelWrapper>
        </PricePanelStyled>
      </ConnectedTrackedRoute>
    </React.Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { mediaObjectId }: IProps) => ({
  shouldRequestLicenseOptions: selectLicenseOptionsRequired(state, mediaObjectId),
})

const mapDispatchToProps = {
  fetchLicenseOptions: fetchMediaObjectLicenseOptions,
}
export default compose<IInternalProps, IProps>(connect(mapStateToProps, mapDispatchToProps))(
  MediaObjectFullPricePanel,
)
