import { Reducer } from 'redux'

import { IPasswordState, PasswordActionTypes } from './'

const initialState: IPasswordState = {
  errors: undefined,
  isRequesting: false,
}

const reducer: Reducer<IPasswordState> = (state = initialState, action) => {
  switch (action.type) {
    case PasswordActionTypes.FORGOT_PASSWORD_REQUEST:
    case PasswordActionTypes.SET_PASSWORD_REQUEST: {
      return {
        isRequesting: true,
        errors: undefined,
      }
    }

    case PasswordActionTypes.FORGOT_PASSWORD_DONE:
    case PasswordActionTypes.SET_PASSWORD_REQUEST_SUCCESS: {
      return {
        isRequesting: false,
        errors: undefined,
      }
    }

    case PasswordActionTypes.SET_PASSWORD_REQUEST_ERROR: {
      return {
        errors: action.payload,
        isRequesting: false,
      }
    }

    default:
      return state
  }
}

export { reducer as passwordReducer }
