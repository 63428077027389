import { keyframes } from 'styled-components'

import { SLIDE_DISTANCE } from './constants'

export const SlideOutDown = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    visibility: hidden;
    opacity: 0;
    transform: translateY(${SLIDE_DISTANCE});
  }
`
