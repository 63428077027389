import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { SideBarBlock } from '@components/UI/SideBar'
import { ICampaignContextProps } from '@context/Campaign'
import { withCampaign } from '@hocs'
import { IApplicationState } from '@store'
import { ICampaign, selectIsCampaignFetched } from '@store/campaigns'
import { fetchMembers, IMember, selectAddMemberLink, selectMembers } from '@store/members'
import InboxMemberInvite from '../Invite/Invite'
import InboxMember from '../'

interface IInternalProps extends ICampaignContextProps {
  members: ReadonlyArray<IMember>
  fetchMembers: typeof fetchMembers
  hasAddMemberLink: boolean
  isCampaignFetched: boolean
  campaignId: number
  campaign: ICampaign
}

export const InboxMemberList: React.FC<IInternalProps> = ({
  members,
  hasAddMemberLink,
  campaignId,
  isCampaignFetched,
  fetchMembers: fetchMembersAction,
}) => {
  React.useEffect(() => {
    if (isCampaignFetched) {
      fetchMembersAction(campaignId)
    }
  }, [isCampaignFetched])

  return (
    <SideBarBlock
      header={
        <Flex justifyBetween>
          <FormattedMessage id="inbox.sidebar.members" />
          {hasAddMemberLink && <InboxMemberInvite />}
        </Flex>
      }
      items={members.map((member: IMember) => (
        <InboxMember key={member.id} member={member} campaignId={campaignId} />
      ))}
      elementName="side-bar-member"
    />
  )
}

const mapStateToProps = (state: IApplicationState, { campaign }) => {
  const { id: campaignId } = campaign
  return {
    campaignId,
    isCampaignFetched: selectIsCampaignFetched(state, campaignId),
    members: selectMembers(state, campaignId),
    hasAddMemberLink: Boolean(selectAddMemberLink(state, campaignId)),
  }
}

const mapDispatchToProps = {
  fetchMembers,
}

export default compose<IInternalProps, {}>(
  withCampaign,
  connect(mapStateToProps, mapDispatchToProps),
)(InboxMemberList)
