import { FC } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { FlexItem } from 'styled-flex-component'

import { PricePanelLabel } from '@components/Submission/Card/PricePanel/PricePanel.styled'
import { RELICENSE_STATUSES } from '@constants'
import { LicenseStatuses } from '@enums'
import { IApplicationState } from '@store'
import { selectTransactedLicenseStatus } from '@store/mediaObjects'
import Theme from '@theme'
import { Text } from '@tribegroup'

interface IProps {
  mediaObjectId: number
}

export const MediaObjectFullPricePanelStatus: FC<IProps> = ({ mediaObjectId }) => {
  const { transactedLicenseStatus } = useSelector((state: IApplicationState) => {
    return {
      transactedLicenseStatus: selectTransactedLicenseStatus(state, mediaObjectId),
    }
  })

  const isRequestExpired = transactedLicenseStatus === LicenseStatuses.RequestExpired
  const isRelicenseStatus = RELICENSE_STATUSES.includes(transactedLicenseStatus as LicenseStatuses)

  if (!transactedLicenseStatus || (isRelicenseStatus && !isRequestExpired)) {
    return <></>
  }

  return (
    <FlexItem noShrink data-testid="media-object-status-pill">
      <PricePanelLabel>
        <Text xsmall uppercase color={Theme.secondaryColor}>
          <FormattedMessage
            id={`mediaObjects.fullView.pricePanel.label.text.${transactedLicenseStatus}`}
          />
        </Text>
      </PricePanelLabel>
    </FlexItem>
  )
}

export default MediaObjectFullPricePanelStatus
