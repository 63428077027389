import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import { IApplicationState } from '@store'
import { selectLicenseReceiptNumber } from '@store/mediaObjects'
import {
  selectCostBreakdownMessage,
  selectFirstMediaObjectId,
  selectIsPreApprovedOrDeclinedPreapproval,
  selectSubmission,
} from '@store/submissions'

const useSocialCostBreakdown = (campaignId: number, submissionId: number) => {
  const {
    submission: loadedSubmission,
    isPreApprovedOrDeclinedPreapproval: loadedPreApprovedOrDeclinedPreapproval,
    receiptNumber,
    hasCostBreakdownMessage,
  } = useSelector((state: IApplicationState) => {
    const mediaObjectId = selectFirstMediaObjectId(state, submissionId)
    const submission = selectSubmission(state, submissionId)

    return {
      submission,
      isPreApprovedOrDeclinedPreapproval: selectIsPreApprovedOrDeclinedPreapproval(
        state,
        submissionId,
        campaignId,
      ),
      hasCostBreakdownMessage: Boolean(selectCostBreakdownMessage(state, submissionId, campaignId)),
      receiptNumber: selectLicenseReceiptNumber(state, mediaObjectId),
    }
  })

  const [submission, setSubmission] = useState(loadedSubmission)
  const [isPreApprovedOrDeclinedPreapproval, setIsPreApprovedOrDeclinedPreapproval] = useState(
    loadedPreApprovedOrDeclinedPreapproval,
  )

  useEffect(() => {
    setSubmission(loadedSubmission)
    setIsPreApprovedOrDeclinedPreapproval(loadedPreApprovedOrDeclinedPreapproval)
  }, [loadedSubmission.id])

  return {
    submission,
    receiptNumber,
    isPreApprovedOrDeclinedPreapproval,
    hasCostBreakdownMessage,
  }
}

export { useSocialCostBreakdown }
