import React from 'react'

import { FormattedMessage } from 'react-intl'

import TribeOnly from '@components/UI/TribeOnly'
import { TRIBEFEE_INFO_LINK } from '@constants'
import Theme from '@theme'
import { Container, Header, Link, Text } from '@tribegroup'

const SubmissionCostBreakdownTribeFeeInfoTooltip = () => {
  return (
    <React.Fragment>
      <Header small uppercase color={Theme.primaryColor} bottomOuterSpacing={0.5}>
        <FormattedMessage id="costBreakdown.tribeFee.information.header" />
      </Header>
      <Container topOuterSpacing={0.5}>
        <Text color={Theme.defaultColor} customFontSize={0.8125}>
          <FormattedMessage id="costBreakdown.tribeFee.information.body" />
        </Text>
      </Container>
      <TribeOnly>
        <Container topOuterSpacing={0.5}>
          <Link href={TRIBEFEE_INFO_LINK} target="_blank" color={Theme.secondaryColor}>
            <Text color={Theme.primaryColor} customFontSize={0.8125}>
              <FormattedMessage id="core.text.learnMore.withArrow" />
            </Text>
          </Link>
        </Container>
      </TribeOnly>
    </React.Fragment>
  )
}

export default SubmissionCostBreakdownTribeFeeInfoTooltip
