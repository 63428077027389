import React from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Flex, { FlexItem } from 'styled-flex-component'

import { Hr } from '@components/UI/Hr/Hr.styled'
import { IApplicationState } from '@store'
import {
  selectBrandedContentAdDaysOptions,
  selectTikTokBrandedContentAdDaysOptions,
} from '@store/rootResource'
import { selectIsSubmissionFromTiktok } from '@store/submissions'
import Theme from '@theme'
import { Container, Header } from '@tribegroup'
import DurationOptions from './DurationOptions'
import PlannedSpend from './PlannedSpend'
import PlannedSpendTip from './PlannedSpendTip'

interface IProps {
  submissionId: number
  campaignId: number
}

const DurationAndAmount = ({ campaignId, submissionId }: IProps) => {
  const { durationInDaysOptions } = useSelector((state: IApplicationState) => {
    const isTikTok = selectIsSubmissionFromTiktok(state, submissionId)

    return {
      durationInDaysOptions: !isTikTok
        ? selectBrandedContentAdDaysOptions(state)
        : selectTikTokBrandedContentAdDaysOptions(state),
    }
  })

  return (
    <React.Fragment>
      <Header cta color={Theme.grey700Color} uppercase>
        {durationInDaysOptions.length === 1 ? (
          <FormattedMessage id="core.text.duration" />
        ) : (
          <FormattedMessage id="brandedContentAds.modal.selectDuration" />
        )}
      </Header>
      <Container topOuterSpacing={0.75}>
        <DurationOptions submissionId={submissionId} />
      </Container>
      <Container topOuterSpacing={1.75}>
        <Flex>
          <FlexItem noShrink>
            <Flex full alignCenter>
              <Header cta color={Theme.grey700Color} uppercase rightOuterSpacing={0.25}>
                <FormattedMessage id="brandedContentAds.modal.plannedAsSpend" />
              </Header>
            </Flex>
          </FlexItem>
          <FlexItem noShrink>
            <PlannedSpendTip submissionId={submissionId} campaignId={campaignId} />
          </FlexItem>
        </Flex>
      </Container>
      <Container topOuterSpacing={0.375} width={9.75}>
        <PlannedSpend submissionId={submissionId} />
        <Hr />
      </Container>
    </React.Fragment>
  )
}

export { DurationAndAmount }
export default DurationAndAmount
