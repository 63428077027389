import React from 'react'

import Image from '@components/UI/Image'
import Theme from '@theme'
import { FadeIn, Icon, Link } from '@tribegroup'
import { IImageMediaProps } from '../Media'
import { FullscreenIcon, MediaObjectWrapper } from './Image.styled'

export const MediaObjectImage: React.FC<IImageMediaProps> = ({
  src,
  alt,
  containImage,
  withRightSeparatorBorder,
  expandable,
  backgroundColor = Theme.whiteColor,
  aspectRatio,
  fullSizeSrc,
  mediaId,
  fitToContainer,
}) => {
  const image = (
    <Image
      src={src}
      alt={alt}
      aspectRatio={aspectRatio}
      animation={FadeIn}
      backgroundColor={backgroundColor}
      loadingColor={Theme.whiteColor}
      contain={containImage}
      className={`media-image-${mediaId}`}
      fitToContainer={fitToContainer}
    />
  )

  return (
    <MediaObjectWrapper withRightSeparatorBorder={withRightSeparatorBorder}>
      {expandable && fullSizeSrc ? (
        <Link href={fullSizeSrc} target="_blank">
          {image}
          <FullscreenIcon alignCenter justifyCenter>
            <Icon size={1.125} glyph="full-screen-heavy" color={Theme.whiteColor} />
          </FullscreenIcon>
        </Link>
      ) : (
        <React.Fragment>{image}</React.Fragment>
      )}
    </MediaObjectWrapper>
  )
}

export default MediaObjectImage
