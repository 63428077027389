import { IAnalyticsSubmission } from '../types'

export enum ReelMetricsActionTypes {
  FETCH_REQUEST = 'pcr/reelMetrics/FETCH_REQUEST',
  FETCH_SUCCESS = 'pcr/reelMetrics/FETCH_SUCCESS',
  FETCH_ERROR = 'pcr/reelMetrics/FETCH_ERROR',
}

export interface IReelMetrics extends IAnalyticsSubmission {
  purchase_amount: number
  purchase_amount_currency: string
  likes?: number
  comments?: number
  shares?: number
  engagement?: number
  engagement_pct?: number
  cpe?: number
  video_views?: number
  video_views_pct?: number
  cpm?: number
  cpv?: number
}

export interface IReelMetricsSummary {
  count: number | null
  total_purchase_amount: number | null
  total_followers: number | null
  likes?: number
  comments?: number
  shares?: number
  engagement?: number
  engagement_pct?: number
  total_engagement_pct?: number
  cpe?: number
  video_views?: number
  video_views_pct?: number
  cpm?: number
  cpv?: number
}

export interface IReelMetricsResponse {
  items: IReelMetrics[]
  metadata: IReelMetricsSummary
}

export interface IReelMetricsState {
  isFetching: boolean
  errors?: object | string
  reelMetricsById: Record<number, IReelMetrics>
  reelMetricIdsByCampaignId: Record<number, ReadonlyArray<number>>
  reelMetricsSummaryByCampaignId: Record<number, IReelMetricsSummary>
}
