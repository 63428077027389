import { IHateoasLink } from '@store'

export enum FetchPurchaseOrdersActionTypes {
  FETCH_REQUEST = 'purchaseOrders/FETCH_REQUEST',
  FETCH_SUCCESS = 'purchaseOrders/FETCH_SUCCESS',
  FETCH_ERROR = 'purchaseOrders/FETCH_ERROR',
}

export enum RequestPurchaseOrdersActionTypes {
  FETCH_REQUEST = 'purchaseOrders/requestAccess/FETCH_REQUEST',
  FETCH_SUCCESS = 'purchaseOrders/requestAccess/FETCH_SUCCESS',
  FETCH_ERROR = 'purchaseOrders/requestAccess/FETCH_ERROR',
}

export enum PurchaseOrderStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  EXPIRED = 'expired',
}

export interface IPurchaseOrder {
  readonly id: number
  readonly customer_order_number: string
  readonly xero_invoice_reference: string
  readonly expiry_date: string
  readonly status: PurchaseOrderStatus
  readonly amount_cents: number
  readonly amount_currency: string
  readonly amount_remaining_cents: number
  readonly amount_remaining_currency: string
}

export interface IPurchaseOrderMetadata {
  total_amount_remaining_cents: number
  total_amount_remaining_currency: string
}

export interface IPurchaseOrderResponse {
  items: IPurchaseOrder[]
  links: IHateoasLink[]
  metadata: IPurchaseOrderMetadata
}

export interface IPurchaseOrderState {
  readonly isFetching?: boolean
  readonly hasError?: boolean
  readonly purchaseOrders: IPurchaseOrder[]
  readonly metadata?: IPurchaseOrderMetadata
  readonly links: IHateoasLink[]
}
