import React from 'react'

import Flex from 'styled-flex-component'

import Theme from '@theme'
import { CloseIcon, H2, Modal, Text } from '@tribegroup'
import { ConfirmationContent, ConfirmationTitle } from './Confirmation.styled'

export interface IConfirmationProps {
  isOpen: boolean
  showCloseIcon?: boolean
  onCloseIconClick?: VoidFunction
  title: React.ReactNode
  content: React.ReactNode
  footerButtons?: React.ReactNode
  width?: number
}

export const Confirmation = ({
  isOpen,
  showCloseIcon,
  onCloseIconClick,
  title,
  content,
  footerButtons,
  width = 20,
}: IConfirmationProps) => (
  <Modal reactModalProps={{ isOpen, shouldFocusAfterRender: false }} width={width}>
    {showCloseIcon && <CloseIcon onClick={onCloseIconClick} />}
    <ConfirmationTitle>
      <H2 color={Theme.grey900Color}>{title}</H2>
    </ConfirmationTitle>
    <ConfirmationContent>
      <Text color={Theme.grey800Color}>{content}</Text>
    </ConfirmationContent>
    {footerButtons && <Flex justifyEnd>{footerButtons}</Flex>}
  </Modal>
)

export default Confirmation
