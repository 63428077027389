import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectEngagementPercent } from '@store/submissions'
import Theme from '@theme'
import { H5 } from '@tribegroup'

interface IProps {
  submissionId: number
}

interface IInternalProps extends IProps {
  engagementPercent: number
}

const SubHeaderLabelEngagementPercent: React.FC<IInternalProps> = ({ engagementPercent }) => {
  return (
    <H5 color={Theme.grey700Color} uppercase inline light>
      {Boolean(engagementPercent) && (
        <FormattedMessage
          id="submission.performance.averageEngagementPercentWithValue"
          values={{ value: engagementPercent }}
        />
      )}
    </H5>
  )
}

const mapStateToProps = (state: IApplicationState, { submissionId }: IProps) => ({
  engagementPercent: selectEngagementPercent(state, submissionId),
})

export { SubHeaderLabelEngagementPercent }
export default compose<IInternalProps, IProps>(connect(mapStateToProps))(
  SubHeaderLabelEngagementPercent,
)
