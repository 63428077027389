import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Text } from '@tribegroup'
import CampaignPreviewTitleStyled, { CampaignSubtitle, CampaignTitle } from './TitleStyled'

export interface ICampaignPreviewTitleProps {
  brandName: string
  productName: string
  subTitle: string
}

const CampaignPreviewTitle = ({ brandName, productName, subTitle }: ICampaignPreviewTitleProps) => (
  <CampaignPreviewTitleStyled>
    <Flex center column>
      {brandName ? (
        <Text color={Theme.defaultColor}>{brandName}</Text>
      ) : (
        <Text color={Theme.grey700Color}>
          <FormattedMessage id="campaign.preview.empty.brandName" />
        </Text>
      )}
      {productName ? (
        <CampaignTitle color={Theme.defaultColor}>{productName}</CampaignTitle>
      ) : (
        <CampaignTitle color={Theme.grey700Color}>
          <FormattedMessage id="campaign.preview.empty.campaignTitle" />
        </CampaignTitle>
      )}
      {subTitle ? (
        <CampaignSubtitle small color={Theme.grey800Color}>
          {subTitle}
        </CampaignSubtitle>
      ) : (
        <CampaignSubtitle small color={Theme.grey700Color}>
          <FormattedMessage id="campaign.preview.empty.campaignCTA" />
        </CampaignSubtitle>
      )}
    </Flex>
  </CampaignPreviewTitleStyled>
)

export default CampaignPreviewTitle
