import { useSelector } from 'react-redux'

import { IApplicationState } from '@store'
import { selectSubmission } from '@store/submissions'
import { Container } from '@tribegroup'
import { TextContent, TextContentWrapper, TextThumbnailContainer } from './TextPreview.style'

type Props = {
  submissionId: number
}

export const SubmissionCardTextPreview: React.FC<Props> = ({ submissionId }) => {
  const { caption } = useSelector((state: IApplicationState) => ({
    caption: selectSubmission(state, submissionId)?.text,
  }))
  return (
    <TextThumbnailContainer>
      <Container
        absolute
        leftPosition={0}
        topPosition={0}
        innerSpacing={1.5}
        height="100%"
        width="100%"
        flexStart
        borderBox
      >
        <TextContent>
          <TextContentWrapper>{caption}</TextContentWrapper>
        </TextContent>
      </Container>
    </TextThumbnailContainer>
  )
}
