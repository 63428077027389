import React from 'react'

import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectIsRequestingBrandedContentAd } from '@store/submissions'
import Theme from '@theme'
import { Button, Header } from '@tribegroup'

interface IInternalProps {
  isRequesting: boolean
}

const ButtonRequestPricing: React.FC<IInternalProps> = ({ isRequesting }) => {
  const { watch } = useFormContext()

  const watchSpendAmount = watch('planned_spend_amount', 0)

  const disabled = !watchSpendAmount || parseInt(watchSpendAmount) <= 0 || isRequesting

  return (
    <Button
      scheme="primary"
      fullWidth
      disabled={disabled}
      loading={isRequesting}
      data-testid="request-pricing-button"
    >
      <Header color={disabled ? Theme.grey700Color : Theme.whiteColor} uppercase cta>
        <FormattedMessage id="core.text.requestPricing" />
      </Header>
    </Button>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  isRequesting: selectIsRequestingBrandedContentAd(state),
})

export { ButtonRequestPricing }
export default compose<IInternalProps, {}>(connect(mapStateToProps))(ButtonRequestPricing)
