import React, { PropsWithChildren } from 'react'

import { LineClampRoot } from './LineClamp.styled'

export interface ILineClampProps {
  lines: number
  fallbackHeight: number
}

const LineClamp: React.FC<PropsWithChildren<ILineClampProps>> = ({
  children,
  lines,
  fallbackHeight,
}) => (
  <LineClampRoot lines={lines} fallbackHeight={fallbackHeight}>
    {children}
  </LineClampRoot>
)

export default LineClamp
