import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { BulkFeedbackActionTypes, FeedbackActionTypes, IFeedbackState } from './types'

const initialState: IFeedbackState = {
  isFetching: false,
  isAdding: false,
  errors: undefined,
  feedbackBySubmissionId: {},
  feedbackLinksBySubmissionId: {},
  influencerBySubmissionId: {},
}

const reducer: Reducer<IFeedbackState> = (state = initialState, action) => {
  switch (action.type) {
    case FeedbackActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
        errors: undefined,
      }
    }

    case BulkFeedbackActionTypes.BULK_ADD_REQUEST:
    case FeedbackActionTypes.ADD_REQUEST: {
      return {
        ...state,
        isAdding: true,
        errors: undefined,
      }
    }

    case FeedbackActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }
    }

    case BulkFeedbackActionTypes.BULK_ADD_ERROR:
    case FeedbackActionTypes.ADD_ERROR: {
      return {
        ...state,
        isAdding: false,
        errors: action.payload,
      }
    }

    case BulkFeedbackActionTypes.BULK_ADD_SUCCESS: {
      return {
        ...state,
        isAdding: false,
        errors: undefined,
      }
    }

    case FeedbackActionTypes.FETCH_SUCCESS: {
      const { submissionId, result } = action.payload
      const { items, submission_creator, links } = result
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        feedbackBySubmissionId: {
          ...state.feedbackBySubmissionId,
          [submissionId]: items,
        },
        influencerBySubmissionId: {
          ...state.influencerBySubmissionId,
          [submissionId]: submission_creator,
        },
        feedbackLinksBySubmissionId: {
          ...state.feedbackLinksBySubmissionId,
          [submissionId]: links,
        },
      }
    }

    case FeedbackActionTypes.ADD_SUCCESS: {
      const { submissionId, feedback } = action.payload
      const currentFeedback = state.feedbackBySubmissionId[submissionId] || []
      return {
        ...state,
        isAdding: false,
        feedbackBySubmissionId: {
          ...state.feedbackBySubmissionId,
          [submissionId]: [feedback, ...currentFeedback],
        },
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as feedbackReducer }
