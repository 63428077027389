import Flex from 'styled-flex-component'

import ShareableContentLibraryHeader from '@components/ShareableContentLibrary/Header'
import ShareableContentLibraryList from '@components/ShareableContentLibrary/List'
import ShareableContentLibraryListTitleBar from '@components/ShareableContentLibrary/List/TitleBar'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import { withConnected404Page } from '@hocs'
import { selectMediaObjectErrors } from '@store/mediaObjects'
import { Container, ToastContainer } from '@tribegroup'
import ShareableContentLibrary404 from './404'

export const ShareableContentLibraryPage = () => (
  <MainLayout>
    <HeadTag id="contentLibrary.shareable.header" />
    <ShareableContentLibraryListTitleBar />
    <ToastContainer />
    <Container>
      <Flex alignCenter justifyCenter column>
        <ShareableContentLibraryHeader />
        <ShareableContentLibraryList />
      </Flex>
    </Container>
  </MainLayout>
)

const connected404Props = {
  component: ShareableContentLibrary404,
  selector: selectMediaObjectErrors,
}

export default withConnected404Page(connected404Props)(ShareableContentLibraryPage)
