import styled from 'styled-components'

import Theme from '@theme'

export default styled.div`
  a {
    color: ${Theme.grey800Color};
  }

  h1 {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    text-align: center;

    ~ span {
      max-width: 19.375rem;

      &:not(:first-of-type) {
        margin-top: 0.25rem;
      }
    }
  }

  form {
    margin-top: 2.25rem;

    .custom__control {
      padding: 0.3125rem 0;
      height: 3rem;
    }

    .formgroup-input {
      margin-bottom: 0;
    }

    .formgroup-validation-message {
      margin-top: 0.25rem;
    }

    input {
      padding: 0.875rem;
    }

    button {
      margin-top: 2.125rem;
      padding: 0.9375rem 3rem;
      font-size: 0.9375rem;
      letter-spacing: 1.3px;
      white-space: nowrap;
    }
  }

  .footer {
    margin-top: 3.125rem;

    a {
      text-transform: uppercase;
      letter-spacing: 1.2px;
      font-size: 0.875rem;
      text-decoration: none;
    }
  }

  hr {
    margin: 0.5rem 0 1.5rem;
    opacity: 0.2;
    border-top: solid 1px #000;
    border-bottom: 0;
  }
`
