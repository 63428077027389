import { call, put, select, takeEvery } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import { IBrandFanMember } from '@store/brandFans'
import {
  FetchSubmissionBrandFansMembershipActionTypes,
  selectBrandFansMembersLink,
} from '@store/submissions'
import {
  fetchBrandFansMembership,
  fetchBrandFansMembershipError,
  fetchBrandFansMembershipSuccess,
} from '@store/submissions/actions/fetchSubmissionBrandFanMembership'
import { networkRequest } from '@utils'

export function* handleFetchBrandFansMembership(
  action: ReturnType<typeof fetchBrandFansMembership>,
) {
  try {
    const { submissionId } = action.payload
    const authToken: string = yield select(selectAuthToken)
    const brandFanMembersLink: IHateoasLink = yield select(selectBrandFansMembersLink, submissionId)

    const results = yield call(
      networkRequest,
      brandFanMembersLink.method,
      brandFanMembersLink.href,
      undefined,
      authToken,
    )
    const brandFanMembership = results.items as IBrandFanMember[]
    yield put(fetchBrandFansMembershipSuccess(submissionId, brandFanMembership))
  } catch (error) {
    yield put(fetchBrandFansMembershipError(error))
  }
}

export function* watchFetchBrandFansMembership() {
  yield takeEvery(
    FetchSubmissionBrandFansMembershipActionTypes.REQUEST,
    handleFetchBrandFansMembership,
  )
}
