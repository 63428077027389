import { Fragment, PureComponent } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'

import { CampaignMenuActionsProvider } from '@components/Campaign/Menu'
import HeadTag from '@components/UI/HeadTag'
import TitleBar, { HeaderBackButton, TitleBarMobile } from '@components/UI/TitleBar'
import { HeaderTitle } from '@components/UI/TitleBar/TitleBar.styled'
import { IAnalyticsTrackingContextProps } from '@context/AnalyticsTracking'
import { ICampaignContextProps } from '@context/Campaign'
import { ISelectedSubmissionsContextProps } from '@context/SelectedSubmissions'
import { ISubmissionListFilterContextProps } from '@context/SubmissionListFilter'
import {
  withAnalyticsTracking,
  withCampaign,
  withSelectedSubmissions,
  withSubmissionListFilter,
} from '@hocs'
import { IApplicationState } from '@store'
import { selectBulkAddFeedbackLink, selectBulkDeclineLink } from '@store/campaigns'
import { selectSubmissionsStats } from '@store/submissions'
import Theme from '@theme'
import { H5 } from '@tribegroup'
import { isEmpty } from '@utils'
import InboxTitleBarActions from './Actions/Actions'
import InboxTitleBarSelectionMode from './SelectionMode/SelectionMode'

export interface IInternalProps
  extends RouteComponentProps,
    InjectedIntlProps,
    ISelectedSubmissionsContextProps,
    ICampaignContextProps,
    IAnalyticsTrackingContextProps,
    ISubmissionListFilterContextProps {
  allowSelection: boolean
  hasBulkFeedbackPermission: boolean
  hasBulkDeclinePermission: boolean
  hasEditPermission: boolean
  totalSubmissions: number
  submissionStats: {}
}

interface IBackLink {
  tooltipText: string
  searchParams?: string
}

export const BackLink = ({ tooltipText }: IBackLink) => (
  <Link
    to={{
      pathname: '/campaigns',
    }}
    data-cy-element="header-back-button"
  >
    <HeaderBackButton
      glyph="nav-back-default"
      size={1.5}
      color={Theme.grey900Color}
      data-tooltip-id="base-tooltip"
      data-tooltip-content={tooltipText}
    />
  </Link>
)

export class InboxTitleBar extends PureComponent<IInternalProps> {
  redirectToPreview = () => {
    const { history, location, campaign } = this.props
    history.push(`/campaigns/${campaign.id}/preview${location.search}`)
  }

  onCancel = () => {
    this.props.closeSelectionMode()
    this.props.emptySelection()
  }

  onSelectAllSubmissions = () => {
    this.props.selectAll()
  }

  render() {
    const {
      campaign,
      intl,
      toggleSelectionMode,
      closeSelectionMode,
      isSelectionModeOn,
      hasBulkDeclinePermission,
      hasBulkFeedbackPermission,
      totalSubmissions,
      filter,
      submissionStats,
    } = this.props

    if (!campaign) {
      // This means we can't find the specific campaign provided the ID we get from the param
      // this maybe because the id is invalid, non-existent or the user dont have
      // the permission to view the said campaign
      return null
    }

    if (isEmpty(filter)) {
      return null
    }

    return (
      <CampaignMenuActionsProvider campaignId={campaign.id}>
        <HeadTag text={campaign.product_name} />
        <TitleBarMobile
          title={<FormattedMessage id={`inbox.sidebar.filters.${filter}`} />}
          count={submissionStats[filter]}
        />

        <TitleBar
          desktopOnly
          isSelectionModeOn={isSelectionModeOn}
          onSelectionOverlayClick={closeSelectionMode}
          actions={
            !isSelectionModeOn && (
              <InboxTitleBarActions
                redirectToPreview={this.redirectToPreview}
                campaignId={campaign.id}
                toggleSelectionMode={toggleSelectionMode}
              />
            )
          }
        >
          {isSelectionModeOn ? (
            <InboxTitleBarSelectionMode
              campaign={campaign}
              filter={this.props.filter}
              onSelectAllSubmissions={this.onSelectAllSubmissions}
              onCancel={this.onCancel}
              hasBulkFeedbackPermission={hasBulkFeedbackPermission}
              hasBulkDeclinePermission={hasBulkDeclinePermission}
              totalSubmissions={totalSubmissions}
            />
          ) : (
            <TitleBarContent intl={intl} campaignName={campaign.product_name} />
          )}
        </TitleBar>
      </CampaignMenuActionsProvider>
    )
  }
}

export const TitleBarContent = ({ intl, campaignName }) => {
  return (
    <Fragment>
      <BackLink tooltipText={intl.formatMessage({ id: 'inbox.titlebar.action.back' }) as string} />
      <HeaderTitle data-cy-element="titlebar-call-to-action">
        <H5 color={Theme.grey900Color}>{campaignName}</H5>
      </HeaderTitle>
    </Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { campaign }: IInternalProps) => {
  const campaignId = campaign.id
  const hasBulkFeedbackPermission = Boolean(selectBulkAddFeedbackLink(state, campaignId))
  const hasBulkDeclinePermission = Boolean(selectBulkDeclineLink(state, campaignId))
  return {
    hasBulkDeclinePermission,
    hasBulkFeedbackPermission,
    submissionStats: selectSubmissionsStats(state, campaignId),
  }
}

export default compose<IInternalProps, {}>(
  withCampaign,
  withSelectedSubmissions,
  withSubmissionListFilter,
  injectIntl,
  withRouter,
  withAnalyticsTracking,
  connect(mapStateToProps),
)(InboxTitleBar)
