import React from 'react'

import Theme from '@theme'
import { Text } from '@tribegroup'
import { TagWrapper } from './Tag.styled'

interface ITagProps {
  text: React.ReactNode
  tooltip?: string
  color?: string
}

const Tag = ({ text, tooltip, color = Theme.primaryColor }: ITagProps) => (
  <TagWrapper data-tooltip-id="base-tooltip" data-tooltip-content={tooltip}>
    <Text xxsmall color={color} block uppercase>
      {text}
    </Text>
  </TagWrapper>
)

export default Tag
