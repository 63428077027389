import { differenceInHours } from 'date-fns'
import { InjectedIntlProps, injectIntl } from 'react-intl'

import { Approved, Overdue, ScheduleMissed, ScheduleWrapper, Within48Hours } from './Schedules'

interface IPublishScheduleProps {
  publishSchedule: string
  publishWithinHours?: number
  influencerName: string
}

interface IInternalProps extends InjectedIntlProps, IPublishScheduleProps {}

export const PublishSchedule = ({
  publishSchedule,
  publishWithinHours,
  influencerName,
  intl,
}: IInternalProps) => {
  const scheduleDate = new Date(publishSchedule)
  const hourDifference = differenceInHours(scheduleDate, new Date())
  const scheduleMissed = hourDifference <= 0
  const showWithin48Hours = Boolean(publishWithinHours)

  const getIntl = (id: string) => intl.formatMessage({ id }, { influencerName })

  if (scheduleMissed && showWithin48Hours) {
    return (
      <ScheduleWrapper tipMessage={getIntl('submission.card.publishSchedule.overdue')}>
        <Overdue />
      </ScheduleWrapper>
    )
  }

  if (showWithin48Hours) {
    return (
      <ScheduleWrapper tipMessage={getIntl('submission.card.schedule.approved')}>
        <Within48Hours />
      </ScheduleWrapper>
    )
  }

  if (scheduleMissed) {
    return (
      <ScheduleWrapper tipMessage={getIntl('submission.card.schedule.missed')}>
        <ScheduleMissed scheduleDate={scheduleDate} />
      </ScheduleWrapper>
    )
  }

  return (
    <ScheduleWrapper tipMessage={getIntl('submission.card.schedule.approved')}>
      <Approved scheduleDate={scheduleDate} />
    </ScheduleWrapper>
  )
}

export default injectIntl<IPublishScheduleProps>(PublishSchedule)
