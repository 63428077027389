import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import Flex from 'styled-flex-component'

import { ConnectedAccountsDrawer } from '@components/ConnectedAccounts/Drawer/Drawer'
import Breakpoint from '@components/UI/Breakpoint'
import Confirmation from '@components/UI/Confirmation'
import { FooterButtonWrapper } from '@components/UI/Confirmation/Confirmation.styled'
import StopPropagation from '@components/UI/StopPropagation/StopPropagation'
import { BRANDS_TIKTOK_CONNECT_EDUCATION } from '@constants'
import { MemberRoleTypes } from '@enums'
import { useDrawer } from '@hooks/useDrawer'
import { useInboxPrompt } from '@hooks/useInboxPrompt'
import { selectRole } from '@store/campaigns'
import { IApplicationState } from '@store/index'
import { selectCampaignId, selectIsInboxView } from '@store/router'
import Theme from '@theme'
import { Button, CloseIcon, Container, Header, Icon, Link, Text } from '@tribegroup'
import { isMobileDevice } from '@utils/isMobileDevice'

const ConnectedAccountsSideBarItemEducationConnectTikTokAccountOnApprove = () => {
  const { showDrawer, isOpen } = useDrawer()

  const isMobile = isMobileDevice()

  const { isPromptOpen, hidePrompt } = useInboxPrompt()
  const match = useRouteMatch()

  const { role, isInboxView } = useSelector((state: IApplicationState) => {
    const campaignId = selectCampaignId(match) as number
    return {
      role: selectRole(state, campaignId),
      isInboxView: selectIsInboxView(state),
    }
  })

  if (isOpen) {
    return null
  }

  const onCloseClick = () => {
    hidePrompt()
  }

  const onShowConnectedAccountDrawer = () => {
    showDrawer(
      <ConnectedAccountsDrawer />,
      {
        desktopXOffset: 13.875,
        zIndex: 2,
        anchor: 'left',
      },
      undefined,
      ['desktop', 'tabletLandscape', 'tabletPortrait'],
    )
  }

  if (isMobile) {
    return (
      <Confirmation
        title={<FormattedMessage id="connectedAccounts.approve.education.tiktok.mobile.header" />}
        content={<FormattedMessage id="connectedAccounts.approve.education.tiktok.mobile.body" />}
        footerButtons={
          <FooterButtonWrapper>
            <Button scheme="primary" onClick={hidePrompt}>
              <FormattedMessage id="core.text.okay" />
            </Button>
          </FooterButtonWrapper>
        }
        isOpen={Boolean(isPromptOpen)}
      />
    )
  }

  return (
    <Breakpoint tabletLandscape tabletPortrait desktop>
      <StopPropagation>
        <Container
          cursorDefault
          width={26.875}
          backgroundColor={Theme.whiteColor}
          borderRadius={0.5}
          innerSpacing={1.25}
          fixed
          bottomPosition={1.75}
          leftPosition={isInboxView ? 15.25 : 2.5}
          zIndex={1}
          boxShadow="0 2px 20px 0 rgba(77, 77, 77, 0.15)"
          data-testid="preapprove-connect-tiktok-account-prompt"
        >
          <CloseIcon onClick={onCloseClick} size={1} right={1} top={1} />
          <Text medium color={Theme.grey900Color} block>
            <FormattedMessage id={`connectedAccounts.approve.education.${role}.tiktok.header`} />
          </Text>
          <Text color={Theme.grey900Color} block>
            <FormattedMessage id={`connectedAccounts.approve.education.${role}.tiktok.body`} />
          </Text>
          <Container topOuterSpacing={1}>
            <Flex justifyBetween alignCenter>
              {role === MemberRoleTypes.Owner && isInboxView && (
                <Flex center>
                  <Icon glyph="nav-back-default" color={Theme.primaryColor} size={1.25} />
                  <Button
                    scheme="primary"
                    fullWidth
                    outlined
                    small
                    leftOuterSpacing={0.75}
                    onClick={onShowConnectedAccountDrawer}
                  >
                    <Header color={Theme.primaryColor} uppercase cta small>
                      <FormattedMessage id="connectedAccounts.toggle.education.tiktok.openSideDraw" />
                    </Header>
                  </Button>
                </Flex>
              )}
              <Link href={BRANDS_TIKTOK_CONNECT_EDUCATION} target="_blank">
                <Text color={Theme.primaryColor}>
                  <FormattedMessage id="core.text.learnWhy.withArrow" />
                </Text>
              </Link>
            </Flex>
          </Container>
        </Container>
      </StopPropagation>
    </Breakpoint>
  )
}

export { ConnectedAccountsSideBarItemEducationConnectTikTokAccountOnApprove }
