import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'

import Image from '@components/UI/Image'
import { FULL_DATE_WITH_FULL_MONTH } from '@constants'
import { AspectRatios, MediaTypes } from '@enums'
import { IMediaObject } from '@store/mediaObjects'
import Theme from '@theme'
import { Container, Header, Icon, Text } from '@tribegroup'
import { FullscreenIcon } from '../License.styled'

interface IProps {
  mediaObject: IMediaObject
}

export const SubmissionCardModalLicenseMediaDetails = ({ mediaObject }: IProps) => {
  const {
    dimensions,
    printable_dimensions,
    file_type,
    thumbnail_media_url,
    original_media_url,
    media_type,
    transacted_license,
  } = mediaObject

  return (
    <>
      <div>
        <Header uppercase small color={Theme.grey700Color}>
          <FormattedMessage id="socialSubmission.card.modal.license.media.dimensions" />
        </Header>
        <Text block color={Theme.grey800Color}>
          {dimensions}
          {printable_dimensions && <div>{printable_dimensions}</div>}
        </Text>

        <Container topOuterSpacing={1}>
          <Header uppercase small color={Theme.grey700Color}>
            <FormattedMessage id="socialSubmission.card.modal.license.media.fileType" />
          </Header>
        </Container>
        <Text color={Theme.grey800Color}>{file_type}</Text>

        {transacted_license?.purchased_at && (
          <>
            <Container topOuterSpacing={1}>
              <Header uppercase small color={Theme.grey700Color}>
                <FormattedMessage id="contentSubmission.card.details.purchased" />
              </Header>
            </Container>
            <Text color={Theme.grey800Color}>
              {format(new Date(transacted_license?.purchased_at), FULL_DATE_WITH_FULL_MONTH)}
            </Text>
          </>
        )}
        {transacted_license?.receipt_number && (
          <>
            <Container topOuterSpacing={1}>
              <Header small uppercase color={Theme.grey700Color}>
                <FormattedMessage id="socialSubmission.card.modal.license.receiptNumber" />
              </Header>
            </Container>
            <Text color={Theme.grey800Color}>{transacted_license.receipt_number}</Text>
          </>
        )}
      </div>

      <Container topOuterSpacing={1.5}>
        <a
          target="_blank"
          href={original_media_url}
          rel="noreferrer"
          aria-label="license-media-url"
        >
          <Container width={5.25} borderRadius="100%" overflow="hidden" relative>
            <Image width={5.25} src={thumbnail_media_url} aspectRatio={AspectRatios.ONE_BY_ONE} />
            <FullscreenIcon>
              <Icon
                size={1.5}
                glyph={media_type === MediaTypes.VIDEO ? 'video' : 'full-screen-heavy'}
                color={Theme.whiteColor}
                className="fullscreen"
              />
            </FullscreenIcon>
          </Container>
        </a>
      </Container>
    </>
  )
}
