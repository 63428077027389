import { createReducer } from '@reduxjs/toolkit'
import { Reducer } from 'redux'

import { BrandFanFilters } from '@enums'
import { AuthActionTypes } from '@store/auth'
import {
  AddBrandFanGroupMemberActionTypes,
  BrandFansActionTypes,
  CreateBrandFanGroupActionTypes,
  FetchBrandFanGroupMembersActionTypes,
  FetchBrandFanGroupsActionTypes,
  FetchInfluencerIdentitiesActionTypes,
  FetchInfluencerIdentityActionTypes,
  FetchInfluencerIdentitySubmissionsActionTypes,
  IBrandFanGroup,
  IBrandFansState,
  IInfluencerIdentity,
  RemoveBrandFanGroupMemberActionTypes,
  UpdateBrandFanGroupActionTypes,
} from '@store/brandFans'
import { fetchBrandFansMembershipSuccess } from '@store/submissions/actions/fetchSubmissionBrandFanMembership'
import { addToSet, appendToSet, removeFromSet, returnSecondIfDeepEqual } from '@utils'
import { indexById } from '@utils/groupBy'
import { toggleIdentity } from './actions/open'
import { removeBrandFanMember } from './actions/removeBrandFanMember'

const initialState: IBrandFansState = {
  isFetchingIdentities: false,
  isFetchingBrandFanGroups: false,
  isUpdatingBrandFanGroup: false,
  isCreatingBrandFanGroup: false,
  identitiesById: {},
  identityIdsByFilter: {},
  groupsById: {},
  groupIds: [],
  identityIdsByGroupId: {},
  stats: {},
  linksByFilter: {},
  membersLinksByGroupId: {},
  submissionsByIdentityId: {},
  pendingRequestsByInfluencerIdentityId: [],
  pendingRequestsByBrandFanGroupId: [],
  openedIdentities: {},
  membersByGroupId: {},
  membershipById: {},
  membersByInfluencerId: {},
  membersByIdentityId: {},
  errors: undefined,
}

const reducer: Reducer<IBrandFansState> = (state = initialState, action) => {
  switch (action.type) {
    case FetchBrandFanGroupMembersActionTypes.FETCH_REQUEST:
    case FetchInfluencerIdentitiesActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetchingIdentities: true,
      }
    }

    case FetchInfluencerIdentitiesActionTypes.RESET_LIST: {
      const filter = action.payload
      return {
        ...state,
        identityIdsByFilter: {
          ...state.identityIdsByFilter,
          [filter]: [],
        },
      }
    }

    case FetchBrandFanGroupsActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetchingBrandFanGroups: true,
      }
    }

    case UpdateBrandFanGroupActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isUpdatingBrandFanGroup: true,
      }
    }

    case CreateBrandFanGroupActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isCreatingBrandFanGroup: true,
      }
    }

    case RemoveBrandFanGroupMemberActionTypes.REMOVE_MEMBER_REQUEST:
    case AddBrandFanGroupMemberActionTypes.ADD_MEMBER_REQUEST: {
      const { groupId } = action.payload
      return {
        ...state,
        pendingRequestsByBrandFanGroupId: addToSet(state.pendingRequestsByBrandFanGroupId, [
          groupId,
        ]),
      }
    }

    case UpdateBrandFanGroupActionTypes.UPDATE_SUCCESS: {
      const brandFanGroup: IBrandFanGroup = action.payload
      return {
        ...state,
        isUpdatingBrandFanGroup: false,
        groupsById: {
          ...state.groupsById,
          [brandFanGroup.id]: brandFanGroup,
        },
      }
    }

    case FetchInfluencerIdentitySubmissionsActionTypes.FETCH_REQUEST: {
      const identityId = action.payload
      return {
        ...state,
        pendingRequestsByInfluencerIdentityId: addToSet(
          state.pendingRequestsByInfluencerIdentityId,
          [identityId],
        ),
      }
    }

    case FetchInfluencerIdentitiesActionTypes.FETCH_SUCCESS: {
      const { influencerIdentities, metadata, filter, links, mergeType } = action.payload
      const identityIdsForCurrentFilter = state.identityIdsByFilter[filter] || []
      const mergeFunction = mergeType === 'append' ? appendToSet : addToSet
      return {
        ...state,
        isFetchingIdentities: false,
        identitiesById: returnSecondIfDeepEqual(
          indexById(influencerIdentities, state.identitiesById),
          state.identitiesById,
        ),
        identityIdsByFilter: {
          ...state.identityIdsByFilter,
          [filter]: mergeFunction(
            identityIdsForCurrentFilter,
            influencerIdentities.map((identity) => identity.id),
          ),
        },
        linksByFilter: {
          ...state.linksByFilter,
          [filter]: returnSecondIfDeepEqual(links, state.linksByFilter[filter]),
        },
        stats: {
          ...state.stats,
          ...metadata.stats,
        },
      }
    }

    case FetchInfluencerIdentityActionTypes.FETCH_SUCCESS: {
      const influencerIdentity: IInfluencerIdentity = action.payload
      const hasNoGroupMembership = !influencerIdentity.membership_group_ids.length
      const stateBrandFanFilterGroupIds = state.identityIdsByFilter[BrandFanFilters.BrandFans]
      return {
        ...state,
        identitiesById: returnSecondIfDeepEqual(
          {
            ...state.identitiesById,
            [influencerIdentity.id]: influencerIdentity,
          },
          state.identitiesById,
        ),
        identityIdsByFilter: {
          ...state.identityIdsByFilter,
          [BrandFanFilters.BrandFans]: hasNoGroupMembership
            ? removeFromSet(stateBrandFanFilterGroupIds, influencerIdentity.id)
            : stateBrandFanFilterGroupIds,
        },
      }
    }

    case FetchBrandFanGroupsActionTypes.FETCH_SUCCESS: {
      const { brandFanGroups, metadata = {}, links } = action.payload
      return {
        ...state,
        isFetchingBrandFanGroups: false,
        groupsById: returnSecondIfDeepEqual(
          indexById(brandFanGroups, state.groupsById),
          state.groupsById,
        ),
        linksByFilter: {
          ...state.linksByFilter,
          groups: returnSecondIfDeepEqual(links, state.linksByFilter.groups),
        },
        groupIds: returnSecondIfDeepEqual(
          brandFanGroups.map((group) => group.id),
          state.groupIds,
        ),
        stats: {
          ...state.stats,
          ...metadata.stats,
        },
      }
    }

    case CreateBrandFanGroupActionTypes.CREATE_SUCCESS: {
      const brandFanGroup = action.payload
      return {
        ...state,
        isCreatingBrandFanGroup: false,
        groupsById: {
          ...state.groupsById,
          [brandFanGroup.id]: brandFanGroup,
        },
        groupIds: addToSet(state.groupIds, [brandFanGroup.id]),
      }
    }

    case FetchInfluencerIdentitySubmissionsActionTypes.FETCH_SUCCESS: {
      const { identityId, submissions } = action.payload
      return {
        ...state,
        submissionsByIdentityId: returnSecondIfDeepEqual(
          {
            ...state.submissionsByIdentityId,
            [identityId]: submissions,
          },
          state.submissionsByIdentityId,
        ),
        pendingRequestsByInfluencerIdentityId: removeFromSet(
          state.pendingRequestsByInfluencerIdentityId,
          identityId,
        ),
      }
    }

    case FetchBrandFanGroupMembersActionTypes.RESET_LIST: {
      const groupId = action.payload
      return {
        ...state,
        identityIdsByGroupId: {
          ...state.identityIdsByGroupId,
          [groupId]: [],
        },
      }
    }

    case FetchBrandFanGroupMembersActionTypes.FETCH_SUCCESS: {
      const { groupId, brandFanGroupMembers, links } = action.payload
      const identityIdsForCurrentGroupId = state.identityIdsByGroupId[groupId] || []
      const groupMemberIdentityIds = brandFanGroupMembers.map((identity) => identity.id)
      return {
        ...state,
        isFetchingIdentities: false,
        identitiesById: returnSecondIfDeepEqual(
          indexById(brandFanGroupMembers, state.identitiesById),
          state.identitiesById,
        ),
        identityIdsByGroupId: {
          ...state.identityIdsByGroupId,
          [groupId]: returnSecondIfDeepEqual(
            appendToSet(identityIdsForCurrentGroupId, groupMemberIdentityIds),
            identityIdsForCurrentGroupId,
          ),
        },
        membersLinksByGroupId: {
          ...state.membersLinksByGroupId,
          [groupId]: returnSecondIfDeepEqual(links, state.membersLinksByGroupId),
        },
      }
    }

    case AddBrandFanGroupMemberActionTypes.ADD_MEMBER_SUCCESS: {
      const { groupId } = action.payload
      return {
        ...state,
        pendingRequestsByBrandFanGroupId: removeFromSet(
          state.pendingRequestsByBrandFanGroupId,
          groupId,
        ),
        groupsById: {
          ...state.groupsById,
          [groupId]: {
            ...state.groupsById[groupId],
            member_count: state.groupsById[groupId].member_count + 1,
          },
        },
      }
    }

    case RemoveBrandFanGroupMemberActionTypes.REMOVE_MEMBER_SUCCESS: {
      const { identityId, groupId } = action.payload
      return {
        ...state,
        pendingRequestsByBrandFanGroupId: removeFromSet(
          state.pendingRequestsByBrandFanGroupId,
          groupId,
        ),
        identityIdsByGroupId: {
          ...state.identityIdsByGroupId,
          [groupId]: removeFromSet(state.identityIdsByGroupId[groupId], identityId),
        },
        groupsById: {
          ...state.groupsById,
          [groupId]: {
            ...state.groupsById[groupId],
            member_count: state.groupsById[groupId].member_count - 1,
          },
        },
      }
    }

    case FetchBrandFanGroupMembersActionTypes.FETCH_ERROR:
    case RemoveBrandFanGroupMemberActionTypes.REMOVE_MEMBER_ERROR:
    case AddBrandFanGroupMemberActionTypes.ADD_MEMBER_ERROR:
    case UpdateBrandFanGroupActionTypes.UPDATE_ERROR:
    case CreateBrandFanGroupActionTypes.CREATE_ERROR:
    case FetchInfluencerIdentitiesActionTypes.FETCH_ERROR:
    case FetchInfluencerIdentityActionTypes.FETCH_ERROR:
    case FetchBrandFanGroupsActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetchingIdentities: false,
        isFetchingBrandFanGroups: false,
        isUpdatingBrandFanGroup: false,
        isCreatingBrandFanGroup: false,
        errors: action.payload,
      }
    }

    case FetchInfluencerIdentitySubmissionsActionTypes.FETCH_ERROR: {
      const { identityId, errors } = action.payload
      return {
        ...state,
        pendingRequestsByInfluencerIdentityId: removeFromSet(
          state.pendingRequestsByInfluencerIdentityId,
          identityId,
        ),
        errors,
      }
    }

    case BrandFansActionTypes.CLEAR_ERRORS: {
      return {
        ...state,
        errors: undefined,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

const brandFansReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(toggleIdentity, (state, { payload: identityId }) => {
      state.openedIdentities[identityId] = !state.openedIdentities[identityId]
    })
    .addCase(fetchBrandFansMembershipSuccess, (state, action) => {
      const { brandFanMembers } = action.payload
      brandFanMembers.forEach((member) => {
        state.membersByGroupId[member.brand_fan_group_id] = {
          ...state.membersByGroupId[member.brand_fan_group_id],
        }
        state.membersByGroupId[member.brand_fan_group_id][member.id] = member
        state.membershipById[member.id] = member
        const influencerMemberships = state.membersByInfluencerId[member.influencer_id] ?? []
        if (
          !influencerMemberships.some((existingMembership) => existingMembership.id === member.id)
        ) {
          state.membersByInfluencerId[member.influencer_id] = [...influencerMemberships, member]
        }

        const identityMemberships = state.membersByIdentityId[member.identity_id] ?? []
        if (
          !identityMemberships.some((existingMembership) => existingMembership.id === member.id)
        ) {
          state.membersByIdentityId[member.identity_id] = [...identityMemberships, member]
        }
      })
    })
    .addCase(removeBrandFanMember, (state, { payload: { groupId, memberId } }) => {
      const membership = state.membershipById[memberId]
      state.membersByInfluencerId[membership.influencer_id] = state.membersByInfluencerId[
        membership.influencer_id
      ].filter((member) => member.id !== memberId)
      state.membersByIdentityId[membership.identity_id] = state.membersByIdentityId[
        membership.identity_id
      ].filter((member) => member.id !== memberId)
      delete state.membershipById[memberId]
      delete state.membersByGroupId[groupId][memberId]
    })
    .addDefaultCase(reducer)
})

export { brandFansReducer }
