import { IResponseError } from '@lib/error'

export enum AudienceInsightsActionTypes {
  FETCH_REQUEST = 'audienceInsights/FETCH_REQUEST',
  FETCH_SUCCESS = 'audienceInsights/FETCH_SUCCESS',
  FETCH_ERROR = 'audienceInsights/FETCH_ERROR',
}

interface IAudienceInsight {
  label: string
  value: number
  percentage: number
}

export interface IAudienceInsights {
  gender: IAudienceInsight[]
  age_range: ReadonlyArray<IAudienceInsight>
  top_countries: ReadonlyArray<IAudienceInsight>
  top_cities: ReadonlyArray<IAudienceInsight>
}

export interface IIAudienceInsightsState {
  readonly audienceInsightsByIdentityId: Record<number, IAudienceInsights>
  readonly isFetching: boolean
  readonly errors?: IResponseError
}
