import React from 'react'

import { format } from 'date-fns'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import Flex from 'styled-flex-component'

import LicensingPreAuthMessage from '@components/Licensing/PreAuthMessage'
import LicensingPriceDetails from '@components/Licensing/PriceDetails'
import Image from '@components/UI/Image'
import { FULL_DATE_WITH_FULL_MONTH } from '@constants'
import { AspectRatios, MediaTypes } from '@enums'
import { ILicense } from '@store/licenses'
import Theme from '@theme'
import { Container, Header, Icon, Text } from '@tribegroup'
import { isEmpty } from '@utils'
import { FullscreenIcon, ImageWrapper, SubmissionDetails } from './Details.styled'

interface ISubmissionCardModalLicenseDetailsProps {
  showCreditCardPreAuth?: boolean
  license: ILicense
  showPreview?: boolean
}

interface IInternalProps extends ISubmissionCardModalLicenseDetailsProps, InjectedIntlProps {}

const SubmissionCardModalLicenseDetails: React.FC<IInternalProps> = ({
  license,
  showPreview,
  intl,
  showCreditCardPreAuth,
}) => {
  const {
    dimensions,
    printable_dimensions,
    file_type,
    purchased_at,
    receipt_number,
    thumbnail_media_url,
    original_media_url,
    total_amount_before_tax_cents,
    total_amount_before_tax_currency,
    tax_display_name,
    media_type,
  } = license

  return (
    <SubmissionDetails>
      <Flex justifyBetween alignStart>
        <div>
          <Header uppercase small color={Theme.grey700Color}>
            <FormattedMessage id="socialSubmission.card.modal.license.media.dimensions" />
          </Header>
          <Text block color={Theme.grey900Color}>
            {dimensions}
            {printable_dimensions && <div>{printable_dimensions}</div>}
          </Text>
          <Container topOuterSpacing={0.75}>
            <Header uppercase small color={Theme.grey700Color}>
              <FormattedMessage id="socialSubmission.card.modal.license.media.fileType" />
            </Header>
          </Container>
          <Text color={Theme.grey900Color}>{file_type}</Text>

          {purchased_at && (
            <React.Fragment>
              <Container topOuterSpacing={0.75}>
                <Header uppercase small color={Theme.grey700Color}>
                  <FormattedMessage id="socialSubmission.card.modal.license.purchased" />
                </Header>
              </Container>
              <Text color={Theme.grey900Color}>
                {format(new Date(purchased_at), FULL_DATE_WITH_FULL_MONTH)}
              </Text>
            </React.Fragment>
          )}
          {receipt_number && (
            <React.Fragment>
              <Container topOuterSpacing={0.75}>
                <Header uppercase small color={Theme.grey700Color}>
                  <FormattedMessage id="socialSubmission.card.modal.license.receiptNumber" />
                </Header>
              </Container>
              <Text color={Theme.grey900Color}>{receipt_number}</Text>
            </React.Fragment>
          )}
        </div>
        {showPreview && (
          <ImageWrapper
            target="_blank"
            href={original_media_url}
            data-tooltip-id="base-tooltip"
            data-tooltip-content={intl.formatMessage({
              id: 'submission.card.action.licensecontent.viewFullSize',
            })}
          >
            <Image width={5.25} src={thumbnail_media_url} aspectRatio={AspectRatios.ONE_BY_ONE} />
            <FullscreenIcon>
              <Icon
                size={1.5}
                glyph={media_type === MediaTypes.VIDEO ? 'video' : 'full-screen-heavy'}
                color={Theme.whiteColor}
                className="fullscreen"
              />
            </FullscreenIcon>
          </ImageWrapper>
        )}
      </Flex>
      {showCreditCardPreAuth && (
        <Container topOuterSpacing={1.75}>
          <LicensingPreAuthMessage />
        </Container>
      )}
      {!isEmpty(total_amount_before_tax_cents) && (
        <Container topOuterSpacing={2.75}>
          <LicensingPriceDetails
            amountBeforeTaxCents={total_amount_before_tax_cents!}
            amountBeforeTaxCurrency={total_amount_before_tax_currency!}
            taxDisplayName={tax_display_name}
          />
        </Container>
      )}
    </SubmissionDetails>
  )
}

export default injectIntl<ISubmissionCardModalLicenseDetailsProps>(
  SubmissionCardModalLicenseDetails,
)
