import { Fragment } from 'react'

import { differenceInDays } from 'date-fns'

import { BudgetAmount } from '@components/UI/BudgetAmount/BudgetAmount'
import { CampaignStatuses, PaymentMethods } from '@enums'
import { ICampaign } from '@store/campaigns'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { isEmpty } from '@utils'
import BudgetTrackerStyled, { AmountWrapperStyled } from './BudgetTracker.styled'
import { DAYS_TO_HIDE_REMAINING_BUDGET } from './constants'

const getProgressColor = (status: CampaignStatuses) => {
  const campaignStatusSuccessTheme = [
    CampaignStatuses.Active,
    CampaignStatuses.Paused,
    CampaignStatuses.Scheduled,
    CampaignStatuses.Expired,
  ].map((stat) => String(stat))
  return campaignStatusSuccessTheme.includes(status) ? Theme.primaryColor : Theme.grey700Color
}

interface ICampaignCardBudgetTracker {
  campaign: ICampaign
}

export const CampaignCardBudgetTracker = ({ campaign }: ICampaignCardBudgetTracker) => {
  const {
    status,
    budget_total_cents: total,
    budget_spent_cents: spent,
    budget_remaining_cents: remaining,
    budget_total_currency: currency,
    ended_at: endedAt,
  } = campaign

  const showBudget = !isEmpty(remaining) || !isEmpty(spent)

  const endedAtDuration = endedAt ? differenceInDays(new Date(), new Date(endedAt)) : -1
  const showBudgetRemaining = endedAtDuration < DAYS_TO_HIDE_REMAINING_BUDGET

  return (
    <Fragment>
      <>
        {showBudgetRemaining && (
          <BudgetTrackerStyled
            data-testid="budget-tracker-line"
            value={spent || 0}
            max={total}
            progressColor={getProgressColor(status)}
          />
        )}
        {!showBudgetRemaining && (
          <Container topInnerSpacing={1} height={0.1875} bottomInnerSpacing={0.5} />
        )}
      </>
      <AmountWrapperStyled data-cy-element="remaining-budget">
        {showBudget && (
          <Text>
            <BudgetAmount
              spaceBetweenText={0.25}
              spent={spent}
              currency={currency}
              color={
                status === CampaignStatuses.Completed ? Theme.grey900Color : Theme.primaryColor
              }
            />
            <>
              {campaign.payment_method !== PaymentMethods.CREDIT_CARD &&
                Boolean(remaining) &&
                showBudgetRemaining && (
                  <Container leftOuterSpacing={0.5}>
                    <BudgetAmount
                      spaceBetweenText={0.25}
                      remaining={remaining}
                      currency={currency}
                      color={Theme.grey900Color}
                    />
                  </Container>
                )}
            </>
          </Text>
        )}
      </AmountWrapperStyled>
    </Fragment>
  )
}

export default CampaignCardBudgetTracker
