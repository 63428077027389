import styled from 'styled-components'
import Flex from 'styled-flex-component'

import { Spin } from '@lib/effects'
import Theme from '@theme'
import { Container } from '@tribegroup'

export const MainContainer = styled(Container)`
  margin: -0.5rem;
`

export const EmptyContainer = styled(Flex)`
  min-height: 14.125rem;
`

export const GroupImageWrapper = styled(Flex)`
  flex-grow: 1;
`

export const GroupListContainer = styled.div`
  height: 32vh;
  overflow: auto;
`

export const GroupListItem = styled(Container)`
  background-color: ${Theme.grey100Color};
  border-radius: 0.5rem;
  cursor: pointer;

  [data-icon-glyph='spinner'] {
    animation: ${Spin} 2.8s linear infinite;
    transform-origin: 50% 50%;
  }

  [data-icon-glyph='plus'],
  [data-icon-glyph='minus'] {
    display: none;
  }

  ${({ warning }) => warning && `background-color: ${Theme.primary100Color}; `}
  &:hover {
    background-color: ${Theme.grey200Color};

    [data-icon-glyph='warning'],
    [data-icon-glyph='plus'],
    [data-icon-glyph='minus'] {
      display: inline-block;
    }

    [data-icon-glyph='tick'] {
      display: none;
    }

    ${({ warning }) => warning && `background-color: ${Theme.primary100Color}; `}
  }
`

export const GroupMemberships = styled(Flex)`
  margin-bottom: 2rem;
  height: 4.5rem;
`

export const WarningContainer = styled(Flex)`
  height: 8.75rem;
  margin-top: 2.25rem;
  justify-content: space-evenly;
`
