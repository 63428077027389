import styled, { css } from 'styled-components'

import Theme from '@theme'

interface ISideBarBlockItemStyledProps {
  active?: boolean
  noHighlight: boolean
}

export const MainText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const NotificationDot = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 1;
`

export default styled<ISideBarBlockItemStyledProps>('div')`
  display: block;
  position: relative;
  font-size: 0.875rem;
  line-height: 1.14;
  letter-spacing: 0.3px;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${Theme.grey200Color};
    transition: background 0.2s;
  }

  > a,
  > span,
  > div {
    text-decoration: none;
    display: block;
    padding: ${({ paddingOverride = '0.375rem 0.5rem 0.375rem 2.25rem' }) => paddingOverride};
  }

  ${({ active, noHighlight }) => css`
    ${active &&
    !noHighlight &&
    css`
      background-color: ${Theme.primary100Color};

      &:hover {
        background-color: ${Theme.primary100Color};
      }
    `}
  `};
`
