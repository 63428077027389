import { ComponentType, PureComponent } from 'react'

import { withTheme } from 'styled-components'

import { IScheme } from '../Interfaces/schemes'
import { defaultTheme, IThemeInterface } from '.'

interface ISchemedComponentWithRef extends IScheme {
  ref: any
  theme?: IThemeInterface
}

const themed = <P extends ISchemedComponentWithRef & { innerRef: any }>(
  Component: ComponentType<P>,
) => {
  return withTheme(
    // eslint-disable-next-line react/display-name
    class extends PureComponent<P> {
      render() {
        return (
          <Component
            {...this.props}
            ref={this.props.innerRef}
            theme={this.props.theme ? this.props.theme : defaultTheme}
            scheme={this.props.scheme ? this.props.scheme : 'default'}
          />
        )
      }
    },
  )
}

export default themed
