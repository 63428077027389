import { FormattedMessage } from 'react-intl'

import { RATE_GUIDE } from '@constants'
import Theme from '@theme'
import { Container, Link, Text } from '@tribegroup'
import { RateCardStyled } from './RateCard/RateCard.styled'

export const SubmissionRateCardPanelRateGuide = () => {
  return (
    <RateCardStyled>
      <Text color={Theme.grey900Color} customFontSize={1}>
        <FormattedMessage id="submission.fullView.rateGuide.header" />
      </Text>
      <Container topInnerSpacing={0.75}>
        <Text color={Theme.grey800Color}>
          <FormattedMessage id="submission.fullView.rateGuide.paragraph.first" />
        </Text>
      </Container>
      <Container topInnerSpacing={0.75}>
        <Text color={Theme.grey800Color}>
          <FormattedMessage id="submission.fullView.rateGuide.paragraph.second" />
        </Text>
      </Container>
      <Container topOuterSpacing={0.75}>
        <Link href={RATE_GUIDE} target="_blank" color={Theme.secondaryColor}>
          <Text color={Theme.primaryColor}>
            <FormattedMessage id="core.text.learnMore.withArrow" />
          </Text>
        </Link>
      </Container>
    </RateCardStyled>
  )
}
