import React, { createRef, useEffect, useState } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { SubmissionChannels } from '@enums'
import { withBuilderValidation } from '@hocs'
import { IApplicationState } from '@store'
import { selectDraft, selectPinterestChannel } from '@store/drafts'
import Theme from '@theme'
import { Button, Checkbox, Container, FormInput, H4, Icon, Text, TextField } from '@tribegroup'
import { KEYCODE } from '@tribegroup/utils'
import { ChannelsContext } from '../../Channels'
import { InputWrapper, Tag } from '../../Channels.styled'
interface IBuilderBriefChannelsPinterestPaidPartershipProps
  extends InjectedIntlProps,
    IBuilderValidationContextProps {}

const BuilderBriefChannelsPinterestPaidPartership: React.FC<IBuilderBriefChannelsPinterestPaidPartershipProps> = ({
  intl,
  updateForm,
  clearFieldValidation,
}) => {
  const { channelItems, setChannelItems } = React.useContext(ChannelsContext)

  const { paidPartnershipHandle, hasBrandPartnershipHandle, draft } = useSelector(
    (state: IApplicationState) => {
      const draft = selectDraft(state)
      const pinterestChannel = selectPinterestChannel(state)

      const paidPartnershipHandle = pinterestChannel?.paid_partnership_handle

      let hasBrandPartnershipHandle = pinterestChannel?.has_required_social_channel_account

      if (hasBrandPartnershipHandle === undefined) {
        hasBrandPartnershipHandle = true
      }

      return {
        paidPartnershipHandle,
        hasBrandPartnershipHandle,
        draft,
      }
    },
  )

  const isChecked = !hasBrandPartnershipHandle
  const isCheckedOrHasPaidPartnershipHandle = isChecked || Boolean(paidPartnershipHandle)
  const hasPaidPartnershipHandleOrUsingDefaultFallback =
    Boolean(paidPartnershipHandle) && hasBrandPartnershipHandle
  const paidPartnershipRef = createRef<HTMLFormElement>()

  const [hasError, setHasError] = useState(false)

  const savePaidPartnershipHandle = (event: React.SyntheticEvent) => {
    event.preventDefault()
    if (!paidPartnershipRef.current) {
      return
    }
    const value = paidPartnershipRef.current.value
    // eslint-disable-next-line no-useless-escape
    if (value.match(/^(\@)[\w\d\.]+$/g)) {
      setChannelItems({
        ...channelItems,
        [SubmissionChannels.Pinterest]: {
          ...channelItems[SubmissionChannels.Pinterest],
          paid_partnership_handle: value,
          has_required_social_channel_account: true,
        },
      })

      clearFieldValidation('pinterest_paid_partnership_handle_valid')
    } else {
      setHasError(true)
    }
  }

  const onCheckboxChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement

    setChannelItems({
      ...channelItems,
      [SubmissionChannels.Pinterest]: {
        ...channelItems[SubmissionChannels.Pinterest],
        paid_partnership_handle: '',
        has_required_social_channel_account: !target.checked,
      },
    })
  }

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === KEYCODE.ENTER) {
      savePaidPartnershipHandle(event)
    }
  }
  const clearError = () => {
    setHasError(false)
  }

  const clearSavedPaidPartnershipHandle = () => {
    updateForm({
      channels: draft?.channels?.map((channel) => {
        if (channel.name !== SubmissionChannels.Pinterest) {
          return channel
        }

        return {
          ...channel,
          paid_partnership_handle: '',
        }
      }),
    })
  }

  useEffect(() => {
    if (!hasBrandPartnershipHandle) {
      clearError()
    }
  }, [hasBrandPartnershipHandle])

  return (
    <Container topOuterSpacing={1.5} relative rightOuterSpacing={-2.5} rightInnerSpacing={2.5}>
      <FormInput
        validationRule={{
          required: true,
        }}
      >
        <TextField
          name="pinterest_paid_partnership_handle_valid"
          type="hidden"
          value={isCheckedOrHasPaidPartnershipHandle ? 'valid' : ''}
          readOnly
        />
      </FormInput>
      <H4 color={Theme.defaultColor}>
        <FormattedMessage id="builder.brief.socialMedia.pinterest.paidpartnership.header" />
      </H4>
      <Container>
        {hasPaidPartnershipHandleOrUsingDefaultFallback ? (
          <Container topOuterSpacing={1} flexStart>
            <Tag>
              <Text color={Theme.defaultColor}>{paidPartnershipHandle}</Text>
              <Container leftOuterSpacing={0.5}>
                <Flex>
                  <Icon
                    onClick={clearSavedPaidPartnershipHandle}
                    size={1}
                    color={Theme.defaultColor}
                    glyph="cross-heavy"
                  />
                </Flex>
              </Container>
            </Tag>
          </Container>
        ) : (
          <>
            <InputWrapper data-testid="channels-pinterest-input-wrapper">
              <TextField
                innerRef={paidPartnershipRef}
                name="paid_partnership_handle"
                disabled={!hasBrandPartnershipHandle}
                placeholder={intl.formatMessage({
                  id: 'builder.brief.socialMedia.pinterest.paidpartnership.inputPlaceholder',
                })}
                fullWidth
                height={0.875}
                rounded
                onKeyDown={onKeyDown}
                onChange={clearError}
                data-testid="pinterest_paid_partnership_handle_input"
              />
              <Container leftOuterSpacing={1}>
                <Button
                  small
                  scheme="primary"
                  onClick={savePaidPartnershipHandle}
                  data-testid={'pinterest_paid_partnership_handle_input_button'}
                >
                  <FormattedMessage id="builder.brief.socialMedia.submitText" />
                </Button>
              </Container>
            </InputWrapper>
            {hasError && (
              <Container topOuterSpacing={0.25}>
                <Text small color={Theme.errorColor}>
                  <FormattedMessage id="builder.brief.socialMedia.error.handle" />
                </Text>
              </Container>
            )}
          </>
        )}
      </Container>
      <Container topOuterSpacing={1} data-testid="no-pinterest-checkbox-container">
        <Checkbox
          name="pinterest_has_brand_partnership_handle"
          scheme="primary"
          readOnly
          label={
            <Text small>
              <FormattedMessage id="builder.brief.socialMedia.pinterest.paidpartnership.dontHavePinterest" />
            </Text>
          }
          checked={!hasBrandPartnershipHandle}
          onClick={onCheckboxChange}
        />
        {!hasBrandPartnershipHandle && (
          <Container
            topOuterSpacing={1.5}
            data-testid="no-instagram-tip"
            width={'calc(100% - 5rem)'}
          >
            <Text color={Theme.defaultColor} small>
              <FormattedMessage id="builder.brief.socialMedia.pinterest.paidpartnership.dontHavePinterest.tip" />
            </Text>
          </Container>
        )}
      </Container>
    </Container>
  )
}

export { BuilderBriefChannelsPinterestPaidPartership }

export default compose(
  withBuilderValidation,
  injectIntl,
)(BuilderBriefChannelsPinterestPaidPartership)
