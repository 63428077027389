import React from 'react'

import Flex, { FlexItem } from 'styled-flex-component'

import { Container } from '@tribegroup'
import InfluencerIdentityRowDetails, { IInfluencerIdentityRowDetailsProps } from './Details/Details'
import InfluencerIdentityRowAvatar, { IInfluencerIdentityRowAvatarProps } from './Avatar'
import { InfluencerIdentityRowClickableArea } from './Row.styled'

interface IInfluencerIdentityRowProps
  extends IInfluencerIdentityRowAvatarProps,
    IInfluencerIdentityRowDetailsProps {}

interface IProps extends IInfluencerIdentityRowProps {
  onRowClick?: (event: React.SyntheticEvent) => void
  isOpen?: boolean
  stats: React.ReactNode
  actions?: React.ReactNode
  horizontalPadding?: number
}

const InfluencerIdentityRow: React.FC<IProps> = ({
  stats,
  username,
  provider,
  locationAndGender,
  socialUrl,
  age,
  fullName,
  avatarSrc,
  onRowClick,
  showStar,
  labels,
  avatarRightSpacing,
  actions,
  showLocationGenderAge,
  horizontalPadding,
}) => {
  return (
    <InfluencerIdentityRowClickableArea
      horizontalPadding={horizontalPadding}
      onClick={onRowClick}
      data-testid="identity-row-clickable-area"
    >
      <Flex justifyBetween alignCenter>
        <Flex justifyBetween alignCenter>
          <FlexItem noShrink>
            <InfluencerIdentityRowAvatar
              avatarRightSpacing={avatarRightSpacing}
              username={username}
              socialUrl={socialUrl}
              avatarSrc={avatarSrc}
              showStar={showStar}
            />
          </FlexItem>
          <FlexItem>
            <InfluencerIdentityRowDetails
              labels={labels}
              username={username}
              provider={provider}
              locationAndGender={locationAndGender}
              socialUrl={socialUrl}
              age={age}
              fullName={fullName}
              showLocationGenderAge={showLocationGenderAge}
            />
          </FlexItem>
        </Flex>
        <FlexItem noShrink>
          <Container leftOuterSpacing={1.5}>
            <Flex justifyEnd alignCenter>
              <Flex data-testid="identity-row-stats">{stats}</Flex>
              {actions && (
                <Container leftOuterSpacing={3} data-testid="identity-row-actions">
                  {actions}
                </Container>
              )}
            </Flex>
          </Container>
        </FlexItem>
      </Flex>
    </InfluencerIdentityRowClickableArea>
  )
}

export { InfluencerIdentityRow, IInfluencerIdentityRowProps }
export default InfluencerIdentityRow
