enum BuilderDefault {
  MIN_AGE_DEFAULT = 18,
  MAX_AGE_DEFAULT = 45,
  MIN_AGE_ALLOWED = 15,
  MAX_AGE_ALLOWED = 65,
  MIN_FOLLOWERS_DEFAULT = 3000,
  MAX_FOLLOWERS_DEFAULT = 400_000,
  MIN_FOLLOWERS_ALLOWED = 3000,
  MAX_FOLLOWERS_START_RANGE_ALLOWED = 100000,
  MAX_FOLLOWERS_ALLOWED = 500000,
  SOLUTIONS_MAX_FOLLOWERS_ALLOWED = 2_000_000,
  FILE_SIZE_MOODBOARD = 1e7,
  FILE_SIZE_MOODBOARD_NEW = 5e8,
  MIN_ARRAY_LENGTH_DOSANDDONTS = 1,
  MAX_ARRAY_LENGTH_DOSANDDONTS = 10,
  MIN_ARRAY_LENGTH_MOODBOARD = 3,
  MIN_ARRAY_SOCIAL_CHANNEL = 1,
  MIN_ARRAY_SOCIAL_CHANNEL_TAG = 1,
  MAX_ARRAY_LENGTH_MOODBOARD = 6,
  MAX_LENGTH_OBJECTIVE = 500,
  MAX_LENGTH_CALLTOACTION = 55,
  MAX_LENGTH_WHERETOGETPRODUCT = 300,
  MIN_ARRAY_DEMOGRAPHIC_GENDER = 1,
  MIN_ARRAY_DEMOGRAPHIC_REGION = 1,
  MIN_ARRAY_BRAND_FAN_GROUP = 1,
  MIN_INVOICE_BUDGET = 5000,
  MIN_DURATION_DAYS = 5,
  DEFAULT_DURATION_DAYS = 10,
  MAX_LENGTH_BRAND_NAME = 40,
  DEFAULT_TEXTAREA_MAX_LENGTH = 80,
  MOODBOARD_MAX_VIDEO_DURATION_SEC = 60,
  MOODBOARD_MAX_MEDIA_COUNT = 6,
}

export { BuilderDefault }
