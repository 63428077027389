import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { PaymentMethods } from '@enums'
import { IApplicationState } from '@store'
import { IPaymentDetails } from '@store/drafts'
import {
  ICompany,
  selectHasCreditCardInfo,
  selectMinimumInvoiceBudgetForUserRegion,
} from '@store/me'
import { FormGroup, FormGroupRow, Radio } from '@tribegroup'
import { fromCents } from '@tribegroup/utils'
import { RelativeContainer } from '../Form.styled'
import BuilderSubmitCampaignPaymentCreditCard from './CreditCard/CreditCard'
import BuilderSubmitCampaignPaymentCreditCardPlaceholder from './CreditCardPlaceholder/CreditCardPlaceholder'
import BuilderSubmitCampaignPaymentInvoice from './Invoice/Invoice'
import BuilderSubmitCampaignPaymentTip from './Tip/Tip'

interface IBuilderSubmitCampaignPaymentProps {
  currency: string
  companyInfo?: ICompany
  onPaymentUpdate: (payment: IPaymentDetails) => void
  showInvoiceTip?: boolean
}
interface IBuilderSubmitCampaignPaymentState {
  payment_details: IPaymentDetails
  useNewCardForm: boolean
}

interface IInternalProps extends IBuilderSubmitCampaignPaymentProps {
  useExistingCard?: boolean
  minimumInvoiceBudgetCents: number
}

export class BuilderSubmitCampaignPayment extends React.PureComponent<
  IInternalProps,
  IBuilderSubmitCampaignPaymentState
> {
  state = {
    payment_details: {
      payment_type: PaymentMethods.CREDIT_CARD,
      use_existing_card: this.props.useExistingCard,
    } as IPaymentDetails,
    useNewCardForm: false,
  }

  componentDidMount() {
    this.onPaymentUpdate()
  }

  switchToNewCardForm = () => {
    this.setState((prevState) => ({
      ...prevState,
      payment_details: {
        ...prevState.payment_details,
        use_existing_card: false,
      },
    }))
  }

  onChangePaymentType = (event: React.SyntheticEvent) => {
    const element = event.currentTarget as HTMLInputElement
    this.setState(
      (prevState) => ({
        ...prevState,
        payment_details: {
          payment_type: element.value,
        },
      }),
      this.onPaymentUpdate,
    )
  }

  onPaymentChange = (paymentDetails: IPaymentDetails) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        payment_details: {
          ...prevState.payment_details,
          ...paymentDetails,
        },
      }),
      this.onPaymentUpdate,
    )
  }

  onPaymentUpdate = () => {
    this.props.onPaymentUpdate(this.state.payment_details)
  }

  render() {
    const { payment_details } = this.state
    const { currency, minimumInvoiceBudgetCents, showInvoiceTip = true } = this.props

    const isCreditCardSelected = payment_details.payment_type === PaymentMethods.CREDIT_CARD
    const isInvoiceSelected = payment_details.payment_type === PaymentMethods.INVOICE
    const showForm = isCreditCardSelected && !this.state.payment_details.use_existing_card
    const showPlaceholderForm = isCreditCardSelected && this.state.payment_details.use_existing_card

    return (
      <React.Fragment>
        <RelativeContainer>
          {isInvoiceSelected && showInvoiceTip && (
            <BuilderSubmitCampaignPaymentTip
              currency={currency}
              minimum={fromCents(minimumInvoiceBudgetCents)}
            />
          )}
          <FormGroupRow spaceBetween={2.5}>
            <FormGroup outerTopSpacing={1.25} outerBottomSpacing={2.5}>
              <Radio
                alignRight
                defaultChecked={isCreditCardSelected}
                scheme="primary"
                name="billing_method"
                label={<FormattedMessage id="builder.submit.payment.creditcard" />}
                defaultValue={PaymentMethods.CREDIT_CARD}
                onChange={this.onChangePaymentType}
                elementName="credit-card-radio-button"
              />
            </FormGroup>
            <FormGroup outerTopSpacing={1.25} outerBottomSpacing={2.5}>
              <Radio
                defaultChecked={isInvoiceSelected}
                scheme="primary"
                name="billing_method"
                label={<FormattedMessage id="builder.submit.payment.invoice" />}
                defaultValue={PaymentMethods.INVOICE}
                onChange={this.onChangePaymentType}
                elementName="invoice-radio-button"
              />
            </FormGroup>
          </FormGroupRow>
        </RelativeContainer>
        {showPlaceholderForm && (
          <BuilderSubmitCampaignPaymentCreditCardPlaceholder
            switchToNewCardForm={this.switchToNewCardForm}
          />
        )}
        {showForm && (
          <BuilderSubmitCampaignPaymentCreditCard onPaymentChange={this.onPaymentChange} />
        )}
        {isInvoiceSelected && (
          <BuilderSubmitCampaignPaymentInvoice
            companyInfo={this.props.companyInfo!}
            onPaymentChange={this.onPaymentChange}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  useExistingCard: selectHasCreditCardInfo(state),
  minimumInvoiceBudgetCents: selectMinimumInvoiceBudgetForUserRegion(state),
})

export default compose<IInternalProps, IBuilderSubmitCampaignPaymentProps>(
  connect(mapStateToProps),
)(BuilderSubmitCampaignPayment)
