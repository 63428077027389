import { addDays, addYears, subDays } from 'date-fns'

import { DatePicker } from '@tribegroup'

const minSelectableDate = addDays(new Date(), 3)
const maxSelectableDate = subDays(addYears(new Date(), 1), 1)

interface IPublishingDatePickerProps {
  selectedDate?: Date
  onSelectDate: (date: Date) => void
}

export const PublishingDatePicker = ({
  selectedDate,
  onSelectDate,
}: IPublishingDatePickerProps) => (
  <DatePicker
    scheme="primary"
    datePickerConfig={{
      minDate: minSelectableDate,
      maxDate: maxSelectableDate,
      selected: selectedDate,
      inline: true,
      onChange: onSelectDate,
    }}
  />
)

export default PublishingDatePicker
