import styled from 'styled-components'

import Theme from '@theme'

export const ClickTheSubmissionContainer = styled.div`
  padding: 0.5rem 1.25rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  background-color: ${Theme.grey100Color};
`
export const Root = styled.div`
  max-height: calc(100vh - 170px);
  overflow: auto;
`
