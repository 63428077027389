import React from 'react'

import { FormattedMessage } from 'react-intl'

import { Entry } from '@components/Analytics/PostCampaignReport/ListView/ListView.styled'
import Theme from '@theme'
import { Text } from '@tribegroup'

interface ILockableEntryProps {
  locked?: boolean
  empty?: boolean
  content: React.ReactNode
}
const LockableEntry: React.FC<ILockableEntryProps> = ({ locked, empty, content }) => {
  if (locked) {
    return (
      <Entry>
        <Text color={Theme.grey800Color}>
          <FormattedMessage id="core.text.symbol.attention" />
        </Text>
      </Entry>
    )
  }

  if (empty) {
    return (
      <Entry>
        <Text color={Theme.grey800Color}>
          <FormattedMessage id="core.metric.empty" />
        </Text>
      </Entry>
    )
  }

  return <Entry>{content}</Entry>
}

export default LockableEntry
