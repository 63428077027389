import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

export const SubmissionDetails = styled.div`
  margin-top: 1.5rem;
  margin-left: 3.125rem;
`

export const DetailsPrice = styled(Flex)`
  margin-top: 3.125rem;
  ${({ showCreditCardPreAuth }) =>
    showCreditCardPreAuth &&
    css`
      margin-top: 0.75rem;
    `};
`

export const ImageWrapper = styled.a`
  min-width: 5.25rem;
  display: block;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
`

export const FullscreenIcon = styled.div`
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
`
