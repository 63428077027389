import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import hooks from '@hooks'
import { IApplicationState } from '@store'
import {
  IMediaTransferRequestParams,
  mediaTransferRequest,
  selectDefaultAdAccountId,
  selectHasFetchedAdAccounts,
  selectIsTransferringMedia,
} from '@store/facebookAds'
import FacebookAdAccountsModal from './FacebookAdAccountsModal'

interface IInternalProps extends IFacebookAdAccountsProps {
  hasFetchedAdAccounts: boolean
  defaultAccountId: string
  isTransferringMedia: boolean
  mediaTransferRequest: typeof mediaTransferRequest
}

interface IFacebookAdAccountsProps {
  onDone?: VoidFunction
  onMount?: VoidFunction
  mediaTransferProps: IMediaTransferRequestParams
}

const FacebookAdAccounts: React.FC<IInternalProps> = ({
  defaultAccountId,
  hasFetchedAdAccounts,
  isTransferringMedia,
  onDone,
  onMount,
  mediaTransferRequest: mediaTransferRequestAction,
  mediaTransferProps,
}) => {
  const isMounted = hooks.useIsMounted()
  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedAccountId, setSelectedAccountId] = React.useState(defaultAccountId)

  React.useEffect(() => {
    if (isMounted && hasFetchedAdAccounts) {
      if (onMount) {
        onMount()
      }
      setSelectedAccountId(defaultAccountId)
      setIsOpen(true)
    }
  }, [hasFetchedAdAccounts])

  React.useEffect(() => {
    if (isMounted && !isTransferringMedia) {
      onCloseModal()
    }
  }, [isTransferringMedia])

  const onSelectAccountId = (adAccountId: string) => {
    setSelectedAccountId(adAccountId)
  }

  const onCloseModal = () => {
    setIsOpen(false)
    if (onDone) {
      onDone()
    }
  }

  const onSubmit = () => {
    mediaTransferRequestAction(selectedAccountId, mediaTransferProps)
  }

  return (
    <FacebookAdAccountsModal
      isOpen={isOpen}
      isTransferringMedia={isTransferringMedia}
      onCloseModal={onCloseModal}
      onSelectAccountId={onSelectAccountId}
      selectedAccountId={selectedAccountId}
      onSubmit={onSubmit}
    />
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  hasFetchedAdAccounts: selectHasFetchedAdAccounts(state),
  defaultAccountId: selectDefaultAdAccountId(state),
  isTransferringMedia: selectIsTransferringMedia(state),
})

const mapDispatchToProps = {
  mediaTransferRequest,
}

export { FacebookAdAccounts }
export default compose<IInternalProps, IFacebookAdAccountsProps>(
  connect(mapStateToProps, mapDispatchToProps),
)(FacebookAdAccounts)
