import { PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import Theme from '@theme'

interface IFullGridAreaProps {
  name: string
  transparent?: boolean
  cssOverflow?: boolean
}

export const FullGridArea: React.FC<PropsWithChildren<IFullGridAreaProps>> = styled.div`
  grid-area: ${({ name }) => name};
  ${({ cssOverflow }) =>
    cssOverflow &&
    css`
      overflow: auto;
    `};
  background: ${Theme.whiteColor};
  ${({ transparent }) =>
    transparent &&
    css`
      background: transparent;
    `}
`

type LayoutStyle = 'generic' | 'branded_cl'

interface IFullStyled {
  layoutStyle?: LayoutStyle
}

interface ILayoutSetting {
  rows: string
  areas: string
}

const layoutSettings: Record<LayoutStyle, ILayoutSetting> = {
  generic: {
    rows: '5.625rem 1fr',
    areas: `
      'media header'
      'media details'
      'media price'
      'media actions'
      'media feedback'
      'media brandedContentAd'
      'mediapanel extra'
    `,
  },
  branded_cl: {
    rows: '1fr 3.75rem',
    areas: `
      'media details'
      'media price'
      'media actions'
    `,
  },
}

export const FullStyled: React.FC<PropsWithChildren<IFullStyled>> = styled.div`
  background: ${Theme.whiteColor};

  @supports (display: grid) {
    ${breakpoint('tabletPortrait')`
      ${({ layoutStyle = 'generic' }) => css`
        background: none;
        display: grid;
        /* stylelint-disable declaration-block-no-redundant-longhand-properties */
        grid-template-columns: 58.3333% auto;
        grid-template-rows: ${layoutSettings[layoutStyle].rows};
        grid-template-areas: ${layoutSettings[layoutStyle].areas};
        /* stylelint-enable declaration-block-no-redundant-longhand-properties */
      `}; 
    `};
  }
`

export const FullHr = styled.hr`
  margin: 0 1.25rem;
  border: none;
  height: 1px;
  background: ${Theme.grey400Color};
`

export const FullDetailsInnerWrapper = styled.div`
  box-sizing: border-box;
  ${breakpoint('tabletPortrait')`
    height: fill-available;
    max-height: 8rem;
  `};
`
