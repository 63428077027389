import { Component, Fragment } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import ConfirmationMessageBox from '@components/UI/ConfirmationMessageBox'
import HeadTag from '@components/UI/HeadTag'
import { ErrorCodes } from '@enums'
import { IApplicationState } from '@store'
import {
  emailVerificationRequest,
  IEmailVerificationParams,
  resendVerificationRequest,
  selectAuthErrorMessages,
  selectIsRequesting,
} from '@store/auth'
import { Button } from '@tribegroup'
import { queryStringtoObject } from '@utils'

interface IInternalProps extends RouteComponentProps, InjectedIntlProps {
  errorMessages?: ReadonlyArray<Record<string, string>>
  isRequesting: boolean
  emailVerificationRequest: typeof emailVerificationRequest
  resendVerificationRequest: typeof resendVerificationRequest
}

interface IEmailVerificationState {
  token: string
  email: string
  referrer_token?: string
  errorMessages?: ReadonlyArray<Record<string, string>>
}

export class AuthEmailVerificationConfirmation extends Component<
  IInternalProps,
  IEmailVerificationState
> {
  static getDerivedStateFromProps(nextProps: IInternalProps) {
    const parsedQueryString: any = queryStringtoObject(nextProps.location.search)
    const email = parsedQueryString.email && decodeURIComponent(parsedQueryString.email)
    const token = parsedQueryString.token
    const referrerToken = parsedQueryString.referrer
      ? decodeURIComponent(parsedQueryString.referrer)
      : undefined

    if (email && token) {
      return {
        email,
        token,
        referrer_token: referrerToken,
        errorMessages: nextProps.errorMessages,
      }
    }

    return null
  }

  state: IEmailVerificationState = {
    token: '',
    email: '',
    errorMessages: undefined,
  }

  componentDidMount() {
    const { email, token, referrer_token } = this.state
    const params: IEmailVerificationParams = {
      email,
      verification_token: token,
      referrer_token,
    }
    this.props.emailVerificationRequest(params)
  }

  onLoginToTribe = () => {
    this.props.history.push('/signIn')
  }

  onResendLink = () => {
    this.props.resendVerificationRequest(this.state.email, this.props.history)
  }

  public render() {
    const { intl, isRequesting } = this.props
    const { errorMessages } = this.state

    const errorMessage: any = errorMessages && errorMessages[0]
    const errorCode = errorMessage && errorMessage.error_code
    const verifiedErrorStates: ReadonlyArray<any> = [
      ErrorCodes.RESEND_VERIFICATION_ACCOUNT_ALREADY_VERIFIED,
      ErrorCodes.VERIFY_EMAIL_ACCOUNT_ALREADY_VERIFIED,
    ]
    const showErrorState = errorCode && !verifiedErrorStates.includes(errorCode)

    if (isRequesting) {
      return null
    }

    if (showErrorState) {
      return (
        <Fragment>
          <HeadTag id={`${errorCode}.title`} />
          <ConfirmationMessageBox
            title={intl.formatMessage({
              id: `${errorCode}.title`,
            })}
            footerCTA={
              <Button scheme="primary" onClick={this.onResendLink}>
                <FormattedMessage id="auth.emailVerification.error.cta" />
              </Button>
            }
          >
            <FormattedMessage id={`${errorCode}.body`} />
          </ConfirmationMessageBox>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <HeadTag id="auth.emailVerification.success.title" />
        <ConfirmationMessageBox
          title={intl.formatMessage({
            id: 'auth.emailVerification.success.title',
          })}
          footerCTA={
            <Button scheme="primary" onClick={this.onLoginToTribe}>
              <FormattedMessage id="core.text.loginToTribe" />
            </Button>
          }
        >
          <FormattedMessage id="auth.emailVerification.success.body" />
        </ConfirmationMessageBox>
      </Fragment>
    )
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  errorMessages: selectAuthErrorMessages(state),
  isRequesting: selectIsRequesting(state),
})

const mapDispatchToProps = {
  emailVerificationRequest,
  resendVerificationRequest,
}

export default compose<IInternalProps, {}>(
  withRouter,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(AuthEmailVerificationConfirmation)
