import { Panel, PanelFluidWrapper } from '@tribegroup'
import ConnectedAccountsChecklist from './Checklist'
import { ConnectedAccountsHeaderAction } from './HeaderAction'
import ConnectedAccountsHeadline from './Headline'
import ConnectedAccountsList from './List'

const ConnectedAccounts = () => {
  return (
    <Panel>
      <ConnectedAccountsHeaderAction />
      <ConnectedAccountsHeadline />
      <PanelFluidWrapper>
        <ConnectedAccountsList />
      </PanelFluidWrapper>
      <ConnectedAccountsChecklist />
    </Panel>
  )
}

export default ConnectedAccounts
