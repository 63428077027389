import React from 'react'

import Currency from 'react-currency-formatter'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { useMetricTooltipTracking } from '@components/Analytics/PostCampaignReport/Metric/useMetricTooltipTracking'
import Information from '@components/UI/Information/Information'
import { IApplicationState } from '@store'
import { selectCampaignOperatingRegionTaxName } from '@store/campaigns'
import Theme from '@theme'
import { Container, H1, Header, Text } from '@tribegroup'
import { abbreviateNumber } from '@utils'

interface IProps {
  title: string
  value?: number
  currency?: string
  showPercentSign?: boolean
  abbreviateValue?: boolean
  campaignId: number
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

export const getCurrencyPattern = (value) => (value % 1 === 0 ? '!###,### ' : undefined)

export const getPostCampaignReportPerformanceCount = (
  value?: number,
  currency?: string,
  showPercentSign?: boolean,
  abbreviateValue?: boolean,
) => {
  if (value === undefined || value === null) {
    return <FormattedMessage id="core.metric.empty" />
  }

  if (currency) {
    return <Currency quantity={value} currency={currency} pattern={getCurrencyPattern(value)} />
  }

  const localeValue = value.toLocaleString(undefined, { maximumFractionDigits: 2 })

  if (showPercentSign) {
    return <FormattedMessage id="core.text.percent" values={{ value: localeValue }} />
  }

  return abbreviateValue ? abbreviateNumber(value) : localeValue
}

const DELAY_IN_SECONDS = 1

const PostCampaignReportPerformance: React.FC<IInternalProps> = ({
  title,
  value,
  currency,
  showPercentSign,
  abbreviateValue,
  taxName,
}) => {
  const metricMessageId = `postCampaignReport.performance.item.label.text.${title}`
  const { clearTrackingTimeOut, sendTrackEvent } = useMetricTooltipTracking({
    delayInSeconds: DELAY_IN_SECONDS,
    metricMessageId,
  })
  return (
    <Container topInnerSpacing={1.875}>
      <Container width="fit-content">
        <Information
          delay={DELAY_IN_SECONDS}
          width={15}
          target={
            <Header small uppercase color={Theme.grey900Color} bottomOuterSpacing={0.5}>
              <FormattedMessage id={metricMessageId} />
            </Header>
          }
          onEnterProp={sendTrackEvent}
          onLeaveProp={clearTrackingTimeOut}
        >
          <Header small uppercase color={Theme.primaryColor} bottomOuterSpacing={0.5}>
            <FormattedMessage id={metricMessageId} />
          </Header>
          <Text customFontSize={0.8125} customFontWeight={400} color={Theme.defaultColor}>
            <FormattedMessage
              id={`${metricMessageId}.tooltip`}
              values={{
                taxDisplay: taxName,
              }}
            />
          </Text>
        </Information>
      </Container>
      <H1 color={Theme.primaryColor}>
        {getPostCampaignReportPerformanceCount(value, currency, showPercentSign, abbreviateValue)}
      </H1>
    </Container>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { currency, intl, campaignId }: IProps & InjectedIntlProps,
) => ({
  taxName: selectCampaignOperatingRegionTaxName(state, campaignId, intl, currency),
})

const connector = connect(mapStateToProps)
export { PostCampaignReportPerformance }
export default compose<IInternalProps, IProps>(injectIntl, connector)(PostCampaignReportPerformance)
