import React from 'react'

import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'

import { FULL_DATE } from '@constants'
import Theme from '@theme'
import { Button, Icon } from '@tribegroup'
import { BackToScheduleButton } from './BackButton.styled'

interface IProps {
  onClick?: (event: React.SyntheticEvent) => void
  startDate?: Date
  duration?: number
  isVisible: boolean
}

export const BuilderSubmitCampaignScheduleBackButton: React.FC<IProps> = ({
  onClick,
  startDate,
  duration,
  isVisible,
}) => {
  return (
    <BackToScheduleButton isVisible={isVisible}>
      <Icon glyph="chevron-up" color={Theme.defaultColor} size={1.5} />
      <Button
        outlined
        centered
        noUppercase
        small
        onClick={onClick}
        type="button"
        data-cy-element="back-to-schedule-button"
      >
        <FormattedMessage
          id="builder.submit.schedule.back.text"
          values={{
            date: startDate && format(startDate, FULL_DATE),
            duration,
          }}
        />
      </Button>
    </BackToScheduleButton>
  )
}

export default BuilderSubmitCampaignScheduleBackButton
