import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { H1, Text } from '@tribegroup'

export const BuilderSubmitCampaignScheduleLabel = () => {
  return (
    <>
      <H1 color={Theme.defaultColor} topOuterSpacing={2.25} bottomOuterSpacing={1}>
        <FormattedMessage id="builder.submit.schedule.header" />
      </H1>
      <Text color={Theme.defaultColor}>
        <FormattedMessage id="builder.submit.schedule.solution.text" />
      </Text>
    </>
  )
}
