import { PropsWithChildren } from 'react'

import { ITooltip } from 'react-tooltip'

import TooltipStyled from './Tooltip.styled'

export default function Tooltip({ children, ...props }: PropsWithChildren & ITooltip) {
  return (
    <TooltipStyled place="bottom" id="base-tooltip" disableStyleInjection {...props}>
      {children}
    </TooltipStyled>
  )
}
