import React from 'react'

import Theme from '@theme'
import { H1 } from '@tribegroup'

interface ILicensingHeaderProps {
  children: React.ReactNode
}

export const LicensingHeader = ({ children }: ILicensingHeaderProps) => (
  <H1 color={Theme.grey900Color} center bottomOuterSpacing={1.75}>
    {children}
  </H1>
)

export default LicensingHeader
