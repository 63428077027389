import { addSeconds } from 'date-fns'
import { Reducer } from 'redux'
import { REHYDRATE } from 'redux-persist'

import { LOCATION_CHANGE } from '@store/router/types'
import { ISavedSignUpForm } from './types'
import { AuthActionTypes, IAuthState, IPActionTypes } from './'

const initialState: IAuthState = {
  access_token: '',
  duration: 0,
  expiration: 0,
  links: [],
  errors: undefined,
  isAuthenticating: false,
  isRequesting: false,
  requesterCountryCode: '',
  signupData: undefined,
}

const reducer: Reducer<IAuthState> = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return {
        ...state,
        errors: undefined,
      }
    }

    case AuthActionTypes.SIGNIN_REQUEST:
    case AuthActionTypes.SIGNUP_REQUEST:
    case AuthActionTypes.COMPLETE_SIGNUP_REQUEST: {
      return {
        ...state,
        isAuthenticating: true,
        errors: undefined,
      }
    }

    case AuthActionTypes.RESEND_VERIFICATION_REQUEST:
    case AuthActionTypes.EMAIL_VERIFICATION_REQUEST: {
      return {
        ...state,
        isRequesting: true,
      }
    }

    case AuthActionTypes.AUTH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        expiration: addSeconds(Date.now(), action.payload.duration).getTime(),
        isAuthenticating: false,
        errors: undefined,
      }
    }

    case AuthActionTypes.COMPLETE_SIGNUP_SUCCESS: {
      return {
        ...state,
        isAuthenticating: false,
        errors: undefined,
      }
    }

    case AuthActionTypes.SAVE_SIGNUP_FORM: {
      const {
        email,
        first_name,
        last_name,
        user_id,
        company_name,
      }: ISavedSignUpForm = action.payload
      return {
        ...state,
        signupData: {
          email,
          first_name,
          last_name,
          user_id,
          company_name,
        },
        expiration: addSeconds(Date.now(), action.payload.duration).getTime(),
        isAuthenticating: false,
        errors: undefined,
      }
    }

    case AuthActionTypes.RESEND_VERIFICATION_SUCCESS:
    case AuthActionTypes.EMAIL_VERIFICATION_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        errors: undefined,
      }
    }

    case AuthActionTypes.AUTH_ERROR: {
      return {
        ...state,
        errors: action.payload,
        isAuthenticating: false,
        isRequesting: false,
      }
    }

    case AuthActionTypes.CLEAR_ERRORS: {
      return {
        ...state,
        errors: undefined,
      }
    }

    case IPActionTypes.CHECK_IP_SUCCESS: {
      const { country_code } = action.payload
      return {
        ...state,
        requesterCountryCode: country_code,
      }
    }

    case IPActionTypes.CHECK_IP_ERROR: {
      return {
        ...state,
        errors: action.payload,
      }
    }

    case AuthActionTypes.RESEND_VERIFICATION_ERROR: {
      return {
        ...state,
        errors: action.payload,
        isRequesting: false,
      }
    }

    case REHYDRATE: {
      const newState = (action.payload && action.payload.auth) || state
      return {
        ...newState,
        isRequesting: false,
        isAuthenticating: false,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as authReducer }
