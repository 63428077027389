import { Reducer } from 'redux'

import { IPaymentState, PaymentActionTypes } from './'

const initialState: IPaymentState = {
  stripe: undefined,
}

const reducer: Reducer<IPaymentState> = (state = initialState, action) => {
  switch (action.type) {
    case PaymentActionTypes.SET_STRIPE_TO_STORE:
      return {
        ...state,
        stripe: action.payload,
      }

    default:
      return state
  }
}

export { reducer as paymentsReducer }
