import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import { compose } from 'recompose'

import Loading from '@components/UI/Loading'
import { NoticeEmpty } from '@components/UI/Notice'
import { IApplicationState } from '@store'
import {
  fetchCampaignBillings,
  selectCampaignBillingList,
  selectCampaignsBillingHistoryNextLink,
  selectIsFetchingBilling,
} from '@store/billings'
import Theme from '@theme'
import { H3 } from '@tribegroup'
import { isEmpty } from '@utils'
import BillingBreakdown from './Breakdown/Breakdown'

interface IInternalProps {
  hasCampaignBillings: boolean
  isLoading: boolean
  isLoadingMore: boolean
  hasMore: boolean
  fetchCampaignBillings: typeof fetchCampaignBillings
}

const Billing: React.FC<IInternalProps> = ({
  hasCampaignBillings,
  isLoading,
  isLoadingMore,
  hasMore,
  fetchCampaignBillings: fetchCampaignBillingsAction,
}) => {
  React.useEffect(() => {
    fetchCampaignBillingsAction(false)
  }, [])
  const fetchNext = () => {
    if (!isLoading && !isLoadingMore) {
      const isFetchNext = true
      fetchCampaignBillingsAction(isFetchNext)
    }
  }

  if (!hasCampaignBillings && !isLoading) {
    return (
      <NoticeEmpty
        alt="billing"
        title={<FormattedMessage id="billing.empty.title" />}
        subtitle={<FormattedMessage id="billing.empty.campaignBillingHistory.subtitle" />}
      />
    )
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <React.Fragment>
      <H3
        color={Theme.grey900Color}
        topOuterSpacing={0.5}
        bottomOuterSpacing={2.5}
        leftOuterSpacing={0.625}
      >
        <FormattedMessage id="core.text.billing.campaignBillingHistory" />
      </H3>
      <BillingBreakdown />
      {hasMore && <Waypoint onEnter={fetchNext} />}
      {isLoadingMore && <Loading />}
    </React.Fragment>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  const isFetching = selectIsFetchingBilling(state)
  const campaignBillings = selectCampaignBillingList(state)
  const hasCampaignBillings = !isEmpty(campaignBillings)
  const hasNextLink = Boolean(selectCampaignsBillingHistoryNextLink(state))
  return {
    hasCampaignBillings,
    hasMore: hasCampaignBillings && hasNextLink,
    isLoadingMore: hasCampaignBillings && isFetching,
    isLoading: !hasCampaignBillings && isFetching,
  }
}

const mapDispatchToProps = {
  fetchCampaignBillings,
}

export { Billing }
export default compose(connect(mapStateToProps, mapDispatchToProps))(Billing)
