import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Flex from 'styled-flex-component'

import Theme from '@theme'

export const ProfileDetailCardWrapper = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
  ${breakpoint('tabletLandscape')`
    &:not(:first-of-type) {
      margin-left: 0.5rem;
    }
  `};
`

export const IconWrapper = styled(Flex)`
  margin-left: 0.25rem;
`

export const ProfileDetailsWrapper = styled.div`
  display: flex;
  margin-top: 0.875rem;
  margin-bottom: 1.875rem;
`

export const ProfileMetricsWrapper = styled.div`
  ${breakpoint('tabletLandscape')`
    display: flex;
  `};
`

export const ProfileWrapper = styled.div`
  border-top: solid 1px ${Theme.grey400Color};
  margin-top: 2.5rem;
  padding-top: 1.25rem;
  margin-bottom: 4rem;
`
