import { Fragment, PureComponent } from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouterProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import { IDraftContextProps } from '@context/Draft'
import { BuilderSteps } from '@enums'
import { withDraft } from '@hocs'
import { IApplicationState } from '@store'
import {
  fetchBrands,
  IBrand,
  selectAddBrandLink,
  selectBrands,
  selectHasFetchedBrands,
  selectSelectedBrandId,
} from '@store/brands'
import { IDraft, selectDraftTypeFromLocation } from '@store/drafts'
import { selectLocationsearchAsObject } from '@store/router'
import Theme from '@theme'
import { AutosizeTextfield, Container, FileDrop, H4, Text } from '@tribegroup'
import { getCampaignType, isEmpty, objectToQueryString, parseCampaignType } from '@utils'
import BuilderBrandCard from './../Card/Card'
import {
  AddBrand,
  BrandFlexWrapper,
  FileDropWrapper,
  ProfileGrid,
  StyledHeader,
  Wrapper,
} from './List.styled'

export const getAddBrandLink = (draft: IDraft) => {
  if (draft.id) {
    return `/builder/${BuilderSteps.BRAND}/${draft.id}/add`
  }
  return `/builder/${BuilderSteps.BRAND}/add`
}

export const getRedirectLink = (draft: IDraft, builderStep: string) => {
  if (draft.id) {
    return `/builder/${builderStep}/${draft.id}`
  }
  return `/builder/${builderStep}`
}

export const ListContainer = ({ count, children }) => {
  if (count < MIN_CARD_COUNT_FOR_GRID) {
    return <BrandFlexWrapper>{children}</BrandFlexWrapper>
  }
  return <ProfileGrid>{children}</ProfileGrid>
}

export interface IInternalProps extends InjectedIntlProps, RouterProps, IDraftContextProps {
  hasFetchedBrands: boolean
  selectedBrandId?: number
  campaignType?: string
  hasAddBrandLink: boolean
  fetchBrands: typeof fetchBrands
  brands: ReadonlyArray<IBrand>
  searchParams: {}
}

const MIN_CARD_COUNT_FOR_GRID = 3

export class BuilderBrandsList extends PureComponent<IInternalProps> {
  state = {
    brandSelected: false,
  }

  componentDidMount() {
    this.props.fetchBrands()
  }

  componentDidUpdate() {
    const { brands, hasFetchedBrands, history, selectedBrandId, draft } = this.props
    if (hasFetchedBrands && isEmpty(brands)) {
      history.push({
        pathname: `/builder/${BuilderSteps.BRAND}/add`,
        search: history.location.search,
      })
    }

    if (selectedBrandId && this.state.brandSelected) {
      setTimeout(() => {
        history.push({
          pathname: getRedirectLink(draft, BuilderSteps.CAMPAIGN),
          search: history.location.search,
        })
      })
    }
  }

  onCardClick = () => {
    this.setState({
      brandSelected: true,
    })
  }

  render() {
    const {
      brands,
      hasAddBrandLink,
      intl,
      campaignType,
      history,
      selectedBrandId,
      draft,
      searchParams,
    } = this.props
    const { location } = history

    return (
      <Fragment>
        <StyledHeader>
          <H4 color={Theme.grey900Color}>
            <FormattedMessage id="builder.brands.list.selectBrand" />
          </H4>
        </StyledHeader>
        <Wrapper center>
          <ListContainer count={brands.length}>
            {brands.map((brand: IBrand) => (
              <BuilderBrandCard
                key={brand.id}
                brand={brand}
                selected={brand.id === selectedBrandId}
                onCardClick={this.onCardClick}
                campaignType={campaignType}
                to={{
                  pathname: getRedirectLink(draft, BuilderSteps.BRAND),
                  search: objectToQueryString({
                    ...searchParams,
                    brandId: brand.id,
                  }),
                }}
              />
            ))}
            {hasAddBrandLink && (
              <FileDropWrapper>
                <Flex column>
                  <Link
                    to={{
                      pathname: getAddBrandLink(draft),
                      search: location.search,
                    }}
                  >
                    <AddBrand>
                      <FileDrop
                        accepts="image/jpeg,image/png"
                        borderWidth={0.625}
                        caption={
                          <Text xsmall uppercase color={Theme.grey800Color}>
                            <FormattedMessage id="builder.brands.brandLogo" />
                          </Text>
                        }
                        noClick
                        noKeyboard
                      />
                      <Container topOuterSpacing={1.5}>
                        <AutosizeTextfield
                          name="name"
                          fontSize={1.5}
                          placeholder={intl.formatMessage({
                            id: 'builder.brands.add.placeholder',
                          })}
                          disabled
                        />
                      </Container>
                    </AddBrand>
                  </Link>
                </Flex>
              </FileDropWrapper>
            )}
          </ListContainer>
        </Wrapper>
      </Fragment>
    )
  }
}

const mapDispatchToProps = {
  fetchBrands,
}

const mapStateToProps = (state: IApplicationState, { draft }: IInternalProps) => ({
  selectedBrandId: selectSelectedBrandId(state),
  campaignType: getCampaignType(draft) || parseCampaignType(selectDraftTypeFromLocation(state)),
  brands: selectBrands(state),
  hasFetchedBrands: selectHasFetchedBrands(state),
  hasAddBrandLink: Boolean(selectAddBrandLink(state)),
  searchParams: selectLocationsearchAsObject(state),
})

export default compose(
  withRouter,
  withDraft,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(BuilderBrandsList)
