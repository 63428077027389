import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import AuthForm from '@components/UI/AuthForm'
import Box from '@components/UI/Box'
import { useLocationSearch } from '@hooks/useLocationSearch'
import { IResponseError, IResponseErrorMessage } from '@lib/error'
import { completeSignUpRequest } from '@store/completeSignup/actions'
import { selectErrors, selectIsFulfilled, selectIsLoading } from '@store/completeSignup/selectors'
import {
  CompleteSignUpErrorCodes,
  CompleteSignupFormDataType,
  completeSignUpSchema,
} from '@store/completeSignup/types'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import {
  Button,
  CheckboxWithState,
  Container,
  FormGroup,
  FormGroupRow,
  FormInput,
  FormValidationMessage,
  Icon,
  Text,
  TextField,
} from '@tribegroup'
import AuthSignupFormErrorContainer from '../SignUp/Form/ErrorContainer'
import AuthSignupFormTerms from '../SignUp/Form/Terms'
import { AuthCompleteSignupMessageBoxAlreadyVerified } from './MessageBox/AlreadyVerified'
import { AuthCompleteSignupMessageBoxInvalidLink } from './MessageBox/InvalidLink'
import { AuthCompleteSignupMessageBoxLinkExpired } from './MessageBox/LinkExpired'
import { AuthCompleteSignupMessageBoxSetupComplete } from './MessageBox/SetupComplete'

const CUSTOM_ERROR_MESSAGE_MAP = {
  [CompleteSignUpErrorCodes.INVALID_TOKEN]: 'auth.completeSignup.error.token.invalid',
}

const AuthCompleteSignupForm = ({ intl }: InjectedIntlProps) => {
  const dispatch = useDispatch()
  const { first_name, last_name, email, token } = useLocationSearch()
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<CompleteSignupFormDataType>({
    resolver: yupResolver(completeSignUpSchema),
  })

  const { errorCode, errorMessage, isLoading, isFulfilled } = useSelector(
    (state: IApplicationState) => {
      const errorMessages = selectErrors(state) as IResponseError

      const errorMessage = errorMessages?.messages?.[0] as IResponseErrorMessage

      return {
        errorCode: errorMessage?.error_code,
        errorMessage: errorMessage?.message,
        isLoading: selectIsLoading(state),
        isFulfilled: selectIsFulfilled(state),
      }
    },
  )

  if (!token || !email) {
    return <AuthCompleteSignupMessageBoxInvalidLink />
  }

  if (errorCode === CompleteSignUpErrorCodes.TOKEN_EXPIRED) {
    return (
      <AuthCompleteSignupMessageBoxLinkExpired
        email={decodeURIComponent(email)}
        source="solution"
      />
    )
  }

  if (errorCode === CompleteSignUpErrorCodes.ACCOUNT_ALREADY_VERIFIED) {
    return <AuthCompleteSignupMessageBoxAlreadyVerified />
  }

  if (isFulfilled) {
    return <AuthCompleteSignupMessageBoxSetupComplete />
  }

  const onSubmit = (data: CompleteSignupFormDataType) => {
    const formData = {
      ...data,
      verification_token: token,
    }

    return dispatch(completeSignUpRequest(formData))
  }

  return (
    <Box fullOnSmallScreens>
      <AuthForm title={<FormattedHTMLMessage id="auth.completeSignup.header" />}>
        <form
          name="complete-sign-up"
          onSubmit={handleSubmit(onSubmit)}
          method="POST"
          autoComplete="off"
        >
          <FormGroupRow spaceBetween={1}>
            <FormGroup stretchChildWidth>
              <TextField
                fullWidth
                rounded
                type="text"
                name="first_name"
                defaultValue={decodeURIComponent(first_name ?? '')}
                placeholder={intl.formatMessage({
                  id: 'auth.signup.form.firstName.placeholder',
                })}
                disabled={isLoading}
                innerRef={register}
                invalid={Boolean(errors.first_name)}
              />
              <AuthSignupFormErrorContainer intlId={errors.first_name?.message} />
            </FormGroup>
            <FormGroup stretchChildWidth>
              <TextField
                fullWidth
                rounded
                type="text"
                name="last_name"
                defaultValue={decodeURIComponent(last_name ?? '')}
                placeholder={intl.formatMessage({
                  id: 'auth.signup.form.lastName.placeholder',
                })}
                disabled={isLoading}
                innerRef={register}
                invalid={Boolean(errors.last_name)}
              />
              <AuthSignupFormErrorContainer intlId={errors.last_name?.message} />
            </FormGroup>
          </FormGroupRow>
          <FormGroup stretchChildWidth>
            <TextField
              innerRef={register}
              fullWidth
              rounded
              type="text"
              name="phone_number"
              placeholder={intl.formatMessage({
                id: 'auth.signup.form.phone_number.placeholder',
              })}
              disabled={isLoading}
              invalid={Boolean(errors.phone_number)}
            />
          </FormGroup>
          <FormGroup stretchChildWidth>
            <TextField
              innerRef={register}
              fullWidth
              rounded
              type="text"
              color={Theme.grey600Color}
              name="email"
              value={decodeURIComponent(email ?? '')}
              readOnly
              placeholder={intl.formatMessage({
                id: 'auth.signup.form.email.placeholder',
              })}
            />
            <Container absolute rightPosition={1} bottomPosition={0.75}>
              <Icon glyph={'locked'} size={1} color={Theme.grey600Color} />
            </Container>
          </FormGroup>

          <FormGroup stretchChildWidth>
            <TextField
              innerRef={register}
              rounded
              fullWidth
              type="password"
              name="password"
              placeholder={intl.formatMessage({
                id: 'core.text.password',
              })}
              disabled={isLoading}
              invalid={Boolean(errors.password)}
            />
            <AuthSignupFormErrorContainer intlId={errors.password?.message} />
          </FormGroup>

          <FormGroup stretchChildWidth>
            <TextField
              innerRef={register}
              rounded
              fullWidth
              type="password"
              name="confirm_password"
              placeholder={intl.formatMessage({
                id: 'core.text.passwordConfirmation',
              })}
              disabled={isLoading}
              invalid={Boolean(errors.confirm_password)}
            />
            <AuthSignupFormErrorContainer intlId={errors.confirm_password?.message} />
          </FormGroup>

          <FormGroup outerBottomSpacing={0.5}>
            <CheckboxWithState
              innerRef={register}
              name="terms_and_conditions"
              scheme="primary"
              readOnly
              disabled={isLoading}
              label={<AuthSignupFormTerms />}
            />
            <AuthSignupFormErrorContainer intlId={errors.terms_and_conditions?.message} />
          </FormGroup>
          <FormGroup>
            <FormInput>
              <CheckboxWithState
                innerRef={register}
                name="marketing_newsletters"
                scheme="primary"
                disabled={isLoading}
                readOnly
                label={
                  <Text color={Theme.grey800Color} small>
                    <FormattedMessage id="auth.signup.form.marketingEmail.simplified.label" />
                  </Text>
                }
              />
            </FormInput>
          </FormGroup>

          {errorCode && (
            <Container outerSpacing={0.5}>
              <FormValidationMessage>
                <Text small>
                  <FormattedMessage id={CUSTOM_ERROR_MESSAGE_MAP[errorCode] ?? errorMessage} />
                </Text>
              </FormValidationMessage>
            </Container>
          )}

          <Flex center>
            <Button
              fullWidth
              loading={isSubmitting || isLoading}
              disabled={isSubmitting || isLoading}
              scheme="primary"
              data-cy-element="sign-up-button"
            >
              <FormattedMessage id="core.text.submit" />
            </Button>
          </Flex>
        </form>
      </AuthForm>
    </Box>
  )
}

export default injectIntl(AuthCompleteSignupForm)
