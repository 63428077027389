import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import {
  ConnectBrandManagerIdentities,
  FetchBrandManagerIdentities,
  IBrandManagerIdentitiesState,
  IBrandManagerIdentity,
  ReconnectBrandManagerIdentity,
  RemoveBrandManagerIdentity,
} from '@store/brandManagerIdentities'

const initialState: IBrandManagerIdentitiesState = {
  brandManagerIdentities: [],
  pendingBrandManagerIdentityIds: [],
  isFetching: false,
  isConnecting: false,
  errors: undefined,
  isFetched: false,
  links: [],
}

const reducer: Reducer<IBrandManagerIdentitiesState> = (state = initialState, action) => {
  switch (action.type) {
    case FetchBrandManagerIdentities.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isFetched: false,
      }
    }

    case ConnectBrandManagerIdentities.CONNECT_REQUEST:
    case ConnectBrandManagerIdentities.CONNECT_AD_ACCOUNT_REQUEST: {
      return {
        ...state,
        isConnecting: true,
      }
    }

    case FetchBrandManagerIdentities.FETCH_SUCCESS: {
      const { identities, links } = action.payload
      return {
        ...state,
        brandManagerIdentities: identities,
        links,
        isFetching: false,
        isFetched: true,
        isConnecting: false,
      }
    }

    case ReconnectBrandManagerIdentity.RECONNECT_REQUEST:
    case RemoveBrandManagerIdentity.REMOVE_REQUEST: {
      return {
        ...state,
        pendingBrandManagerIdentityIds: [...state.pendingBrandManagerIdentityIds, action.payload],
      }
    }

    case ReconnectBrandManagerIdentity.RECONNECT_SUCCESS:
    case RemoveBrandManagerIdentity.REMOVE_SUCCESS: {
      const brandManagerIdentity: IBrandManagerIdentity = action.payload
      return {
        ...state,
        pendingBrandManagerIdentityIds: state.pendingBrandManagerIdentityIds.filter(
          (id) => id !== brandManagerIdentity.id,
        ),
      }
    }

    case ConnectBrandManagerIdentities.CONNECT_SUCCESS: {
      return {
        ...state,
        isConnecting: false,
      }
    }

    case ReconnectBrandManagerIdentity.RECONNECT_ERROR:
    case RemoveBrandManagerIdentity.REMOVE_ERROR:
    case FetchBrandManagerIdentities.FETCH_ERROR:
    case ConnectBrandManagerIdentities.CONNECT_ERROR: {
      return {
        ...state,
        errors: action.payload,
        isFetching: false,
        isConnecting: false,
        pendingBrandManagerIdentityIds: [],
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as brandManagerIdentitiesReducer }
