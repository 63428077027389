import React from 'react'

import { connect } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { compose } from 'recompose'

import ShareableContentLibraryTrackedRoute from '@components/ShareableContentLibrary/TrackedRoute'
import { CardContainer, CardIcon } from '@components/UI/Card/Card'
import { MediaThumbnail } from '@components/UI/Media'
import { AspectRatios, EventTrackingNames, MediaTypes, PageRoutes } from '@enums'
import { IApplicationState } from '@store'
import {
  selectIsVideoMediaType,
  selectMediaObjectType,
  selectThumbnailMediaUrl,
} from '@store/mediaObjects'
import { Container } from '@tribegroup'

interface IMediaObjectCardProps {
  mediaObjectCardId: number
}

interface IParams {
  token: string
}

interface IInternalProps extends IMediaObjectCardProps {
  mediaThumbnail: string
  isVideoMediaType: boolean
}

const ShareableContentLibraryCard: React.FC<IInternalProps> = ({
  mediaThumbnail,
  mediaObjectCardId,
  isVideoMediaType,
}) => {
  const { token } = useParams<IParams>()
  return (
    <CardContainer elementName="media-object-card">
      <Container relative>
        <ShareableContentLibraryTrackedRoute
          eventName={EventTrackingNames.ShareableCLViewContentLibraryItem}
          source="shareable_cl_discover"
          mediaObjectId={mediaObjectCardId}
        >
          <Link
            to={{
              pathname: `/${PageRoutes.ShareableContentLibrary}/${token}/${mediaObjectCardId}`,
            }}
          >
            <MediaThumbnail src={mediaThumbnail} aspectRatio={AspectRatios.ONE_BY_ONE} />
            {isVideoMediaType && <CardIcon opacity={0.5} icon={MediaTypes.VIDEO} />}
          </Link>
        </ShareableContentLibraryTrackedRoute>
      </Container>
    </CardContainer>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { mediaObjectCardId }: IMediaObjectCardProps,
) => ({
  isVideoMediaType: selectIsVideoMediaType(state, mediaObjectCardId),
  mediaThumbnail: selectThumbnailMediaUrl(state, mediaObjectCardId),
  mediaType: selectMediaObjectType(state, mediaObjectCardId),
})

export { ShareableContentLibraryCard }
export default compose<IInternalProps, IMediaObjectCardProps>(connect(mapStateToProps))(
  ShareableContentLibraryCard,
)
