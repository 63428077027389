import InfluencerIdentityListWrapper from '@components/Inbox/InfluencerIdentity/List'
import SubmissionList from '@components/Submission/List'
import hooks from '@hooks'

export const InboxList = () => {
  const isInfluencerView = hooks.useIsInfluencerView()
  return isInfluencerView ? <InfluencerIdentityListWrapper /> : <SubmissionList />
}

export default InboxList
