import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import hooks from '@hooks'
import { IApplicationState } from '@store'
import { fetchInfluencerIdentities } from '@store/brandFans'
import { fetchBrands, IBrand, selectBrands } from '@store/brands'
import Theme from '@theme'
import { DropdownMenu, MenuItemLink, TargetContainer, Text } from '@tribegroup'
import { isEmpty, objectToQueryString } from '@utils'
import BrandFansInfluencerIdentityTopPanelTrackedRoute from './TrackedRoute'

interface IInternalProps {
  fetchBrands: typeof fetchBrands
  fetchInfluencerIdentities: typeof fetchInfluencerIdentities
  brands: ReadonlyArray<IBrand>
}

const InfluencerIdentityTopPanelBrandFilters: React.FC<IInternalProps> = ({
  brands,
  fetchBrands: fetchBrandsAction,
  fetchInfluencerIdentities: fetchInfluencerIdentitiesAction,
}) => {
  const search = hooks.useLocationSearch()
  const { brand_filter } = search
  const brandName = brand_filter && decodeURIComponent(brand_filter)
  const selectedBrand = (brands.find((brand) => brand.name === brandName) || {}) as IBrand

  React.useEffect(() => {
    if (isEmpty(brands)) {
      fetchBrandsAction()
    }
  }, [])

  const isMounted = hooks.useIsMounted()
  React.useEffect(() => {
    if (isMounted) {
      const resetList = true
      fetchInfluencerIdentitiesAction(false, resetList)
    }
  }, [brandName])

  const isAllBrandsSelected = isEmpty(brandName)

  return (
    <DropdownMenu
      target={
        <TargetContainer anchor={'right'}>
          <Text color={Theme.defaultColor}>
            {isAllBrandsSelected ? (
              <FormattedMessage id="brandFans.list.brand_filter.allBrands" />
            ) : (
              selectedBrand.name
            )}
          </Text>
        </TargetContainer>
      }
      showIndicator
      indicatorSpace={-0.5}
      maxHeight={18}
      left={0}
      topOuterSpacing={0.5}
      elementName="brand-drop-down-menu"
      width={13.75}
    >
      <BrandFansInfluencerIdentityTopPanelTrackedRoute brandName="all_brands" source="brand_filter">
        <MenuItemLink
          to={{
            search: objectToQueryString({
              ...search,
              brand_filter: undefined,
            }),
          }}
          selected={isAllBrandsSelected}
          iconColor={Theme.primaryColor}
        >
          <FormattedMessage id="brandFans.list.brand_filter.allBrands" />
        </MenuItemLink>
      </BrandFansInfluencerIdentityTopPanelTrackedRoute>
      {brands.map((brand) => (
        <BrandFansInfluencerIdentityTopPanelTrackedRoute
          key={brand.id}
          brandName={brand.name}
          source="brand_filter"
        >
          <MenuItemLink
            to={{
              search: objectToQueryString({
                ...search,
                brand_filter: encodeURIComponent(brand.name),
              }),
            }}
            selected={selectedBrand.name === brand.name}
            iconColor={Theme.primaryColor}
          >
            <span>{brand.name}</span>
          </MenuItemLink>
        </BrandFansInfluencerIdentityTopPanelTrackedRoute>
      ))}
    </DropdownMenu>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  brands: selectBrands(state),
})

const mapDispatchToProps = {
  fetchBrands,
  fetchInfluencerIdentities,
}

export { InfluencerIdentityTopPanelBrandFilters }
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  InfluencerIdentityTopPanelBrandFilters,
)
