export enum PublishedSummaryActionTypes {
  FETCH_REQUEST = 'publishedSummary/FETCH_REQUEST',
  FETCH_SUCCESS = 'publishedSummary/FETCH_SUCCESS',
  FETCH_ERROR = 'publishedSummary/FETCH_ERROR',
}

export interface IPublishedSummary {
  total_followers?: number
  total_engagement?: number
}

export interface IPublishedSummaryState {
  readonly isFetching: boolean
  readonly errors?: any
  readonly publishedSummaryByCampaignId: Record<number, IPublishedSummary>
}
