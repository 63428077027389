import React from 'react'

import Flex from 'styled-flex-component'

import { StopPropagation } from '@components/UI/StopPropagation/StopPropagation'
import Theme from '@theme'
import { EllipsisWrapper, InfluencerIdentityRowDetailsRoot } from './Details.styled'
import InfluencerIdentityRowDetailsFullName, {
  IInfluencerIdentityRowDetailsFullNameProps,
} from './FullName'
import InfluencerIdentityRowDetailsLocationAgeGender from './LocationAgeGender'
import { IInfluencerIdentityRowDetailsLocationAgeGenderProps } from './LocationAgeGender'
import InfluencerIdentityRowDetailsSocialProvider, {
  IInfluencerIdentityRowDetailsSocialProviderProps,
} from './SocialProvider'

interface IInfluencerIdentityRowDetailsProps
  extends IInfluencerIdentityRowDetailsSocialProviderProps,
    IInfluencerIdentityRowDetailsLocationAgeGenderProps,
    IInfluencerIdentityRowDetailsFullNameProps {
  labels: React.ReactNode
  showLocationGenderAge?: boolean
}

const InfluencerIdentityRowDetails: React.FC<IInfluencerIdentityRowDetailsProps> = ({
  provider,
  username,
  socialUrl,
  locationAndGender,
  fullName,
  age,
  labels,
  showLocationGenderAge = true,
}) => (
  <InfluencerIdentityRowDetailsRoot data-testid="influencer-identity-details-container">
    <div>
      <Flex justifyStart alignCenter>
        <InfluencerIdentityRowDetailsFullName fullName={fullName} />
        {labels}
      </Flex>
    </div>
    <EllipsisWrapper color={Theme.grey700Color}>
      <StopPropagation>
        <InfluencerIdentityRowDetailsSocialProvider
          provider={provider}
          username={username}
          socialUrl={socialUrl}
        />
      </StopPropagation>
      {showLocationGenderAge && (
        <InfluencerIdentityRowDetailsLocationAgeGender
          locationAndGender={locationAndGender}
          age={age}
        />
      )}
    </EllipsisWrapper>
  </InfluencerIdentityRowDetailsRoot>
)

export { InfluencerIdentityRowDetails, IInfluencerIdentityRowDetailsProps }
export default InfluencerIdentityRowDetails
