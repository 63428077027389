import React from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import BrandFansGroupRowTrackedRoute from '@components/BrandFans/Group/Row/TrackedRoute'
import { BrandFanGroupMemberWarningContext } from '@context/BrandFanGroupMemberWarning'
import { EventTrackingNames } from '@enums'
import { addBrandFanGroupMember } from '@store/brandFans'
import Theme from '@theme'
import { Button, Container, H5 } from '@tribegroup'

interface IProps {
  identityId: number
  influencerId?: number
  submissionId?: number
  campaignId?: number
  fullName: string
}

interface IInternalProps extends IProps {
  addBrandFanGroupMember: typeof addBrandFanGroupMember
}

const BrandFansInfluencerIdentityAddToLive: React.FC<IInternalProps> = ({
  fullName,
  identityId,
  campaignId,
  submissionId,
  addBrandFanGroupMember: addBrandFanGroupMemberAction,
  influencerId,
}) => {
  const { hideWarning, selectedGroupId } = React.useContext(BrandFanGroupMemberWarningContext)
  const onAddToGroup = () => {
    if (selectedGroupId) {
      addBrandFanGroupMemberAction(
        identityId,
        selectedGroupId,
        campaignId,
        submissionId,
        influencerId,
      )
      hideWarning()
    }
  }

  const getButtonEventTrackingProps = (action: 'cancel' | 'add_to_group') => {
    return {
      eventName: EventTrackingNames.BrandFansGroupsAddWarning,
      groupId: selectedGroupId as number,
      additionalProperties: {
        identity_id: identityId,
        action,
      },
    }
  }

  return (
    <React.Fragment>
      <H5 color={Theme.primaryColor}>
        <FormattedHTMLMessage
          id="brandFans.identityGroups.warning.addToLive"
          values={{ influencerName: fullName }}
        />
      </H5>
      <Container topOuterSpacing={2}>
        <Flex>
          <Container rightOuterSpacing={0.75} fullWidth>
            <BrandFansGroupRowTrackedRoute {...getButtonEventTrackingProps('cancel')}>
              <Button
                fullWidth
                scheme="primary"
                outlined
                onClick={hideWarning}
                data-testid="warning-button-cancel"
              >
                <FormattedMessage id="core.text.cancel" />
              </Button>
            </BrandFansGroupRowTrackedRoute>
          </Container>

          <Container fullWidth>
            <BrandFansGroupRowTrackedRoute {...getButtonEventTrackingProps('add_to_group')}>
              <Button
                fullWidth
                scheme="primary"
                onClick={onAddToGroup}
                data-testid="warning-button-add-to-group"
              >
                <FormattedMessage id="core.text.addToGroup" />
              </Button>
            </BrandFansGroupRowTrackedRoute>
          </Container>
        </Flex>
      </Container>
    </React.Fragment>
  )
}

const mapDispatchToProps = {
  addBrandFanGroupMember,
}

export { BrandFansInfluencerIdentityAddToLive }
export default compose<IInternalProps, IProps>(connect(undefined, mapDispatchToProps))(
  BrandFansInfluencerIdentityAddToLive,
)
