import React, { PropsWithChildren } from 'react'

import throttle from 'lodash.throttle'
export interface ICoordinates {
  x: number
  y: number
}

export type XPositionType = 'left' | 'right'

export interface IAdditionalOptions {
  useDefaultStyle: boolean
  hoverable: boolean
  cursor?: string
  onHideTooltip?: VoidFunction
  showBelowTarget?: boolean
  rightOffset: number
}

interface IInformationTooltipContextProps {
  showTooltip: (
    content: React.ReactNode,
    coordinates: ICoordinates,
    xPosition: XPositionType,
    width?: number,
    options?: Partial<IAdditionalOptions>,
  ) => void
  hideTooltip: VoidFunction
  tooltipVisible: boolean
  coordinates: ICoordinates
  width: number
  content: React.ReactNode
  xPosition: XPositionType
  options: Partial<IAdditionalOptions>
}

const DEFAULT_COORDINATES = { x: 0, y: 0 }

const InformationTooltipContext = React.createContext({} as IInformationTooltipContextProps)

const InformationTooltipProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const defaultOptions = {
    useDefaultStyle: true,
    hoverable: false,
    showBelowTarget: true,
    rightOffset: 0,
  }
  const [tooltipVisible, setTooltipVisible] = React.useState(false)
  const [content, setContent] = React.useState<React.ReactNode>(null)
  const [coordinates, setCoordinates] = React.useState<ICoordinates>(DEFAULT_COORDINATES)
  const [width, setWidth] = React.useState<number>(0)
  const [xPosition, setXPosition] = React.useState<XPositionType>('left')
  const [options, setOptions] = React.useState<IAdditionalOptions>(defaultOptions)

  const showTooltip = (
    newContent: React.ReactNode,
    newCoordinates: ICoordinates,
    newXPosition: XPositionType = 'left',
    newWidth = 17.5,
    newOptions,
  ) => {
    setXPosition(newXPosition)
    setContent(newContent)
    setCoordinates(newCoordinates)
    setWidth(newWidth)
    setTooltipVisible(true)
    setOptions({ ...defaultOptions, ...newOptions })
  }

  const hideTooltip = () => {
    if (options.onHideTooltip) {
      options.onHideTooltip()
    }
    setTooltipVisible(false)
    setContent(null)
    setCoordinates(DEFAULT_COORDINATES)
    setOptions(defaultOptions)
  }

  const onHideTooltip = throttle(() => {
    hideTooltip()
  }, 500)

  React.useEffect(() => {
    window.addEventListener('scroll', onHideTooltip)
    window.addEventListener('resize', onHideTooltip)
    return () => {
      window.removeEventListener('scroll', onHideTooltip)
      window.removeEventListener('resize', onHideTooltip)
    }
  }, [options.onHideTooltip])

  return (
    <InformationTooltipContext.Provider
      value={{
        showTooltip,
        hideTooltip,
        tooltipVisible,
        coordinates,
        width,
        content,
        xPosition,
        options,
      }}
    >
      {children}
    </InformationTooltipContext.Provider>
  )
}

export { InformationTooltipContext }

export default InformationTooltipProvider
