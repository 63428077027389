import Currency from 'react-currency-formatter'
import { FormattedMessage } from 'react-intl'

export interface IPriceInterface {
  quantity: number
  currency?: string
  rounded?: boolean
  pattern?: string
}

export interface ICustomPrice {
  quantity: number | null
  currency?: string
}

export const Price = ({ quantity, currency, rounded, pattern = '!###,### ' }: IPriceInterface) => {
  const props = rounded
    ? {
        quantity: Math.round(quantity / 100),
        pattern,
      }
    : {
        quantity: quantity / 100,
      }

  return <Currency currency={currency || undefined} {...props} />
}

export const AnalyticsCustomPrice = ({ quantity, currency }: ICustomPrice) => {
  let amount = quantity

  if (amount === null) {
    return <></>
  }

  if (amount > 0.01) {
    return <Currency currency={currency} quantity={amount} />
  } else {
    amount = Number(amount.toFixed(4))
  }

  return (
    <>
      <FormattedMessage id={`core.currency.symbol.${currency}`} />
      {amount}
    </>
  )
}

export default Price
