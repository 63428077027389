import { BrandFanFilters, PageRoutes } from '@enums'
import hooks from '@hooks'
import { queryStringtoObject } from '@utils'

interface IResults {
  currentFilter: BrandFanFilters
}

const useBrandFansFilter = () => {
  const { pathname, search } = hooks.useLocation()
  const { filter } = queryStringtoObject(search)
  const getFilter = () => {
    if (pathname === `/${PageRoutes.BrandFanGroups}`) {
      return BrandFanFilters.Groups
    }
    if (filter === BrandFanFilters.BrandFans) {
      return BrandFanFilters.BrandFans
    }

    return BrandFanFilters.All
  }
  return {
    currentFilter: getFilter(),
  } as IResults
}

export { useBrandFansFilter }
