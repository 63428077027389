/* eslint-disable react/display-name */
import { CurrentSubmissionIdContext } from '@context/CurrentSubmissionId'

export const withCurrentSubmissionId = (Component) => (props) => {
  return (
    <CurrentSubmissionIdContext.Consumer>
      {(contextProps) => <Component {...props} {...contextProps} />}
    </CurrentSubmissionIdContext.Consumer>
  )
}
