import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { FlexItem } from 'styled-flex-component'

import Price from '@components/UI/Price/Price'
import { ILicense } from '@store/licenses'
import { ITransactedLicense } from '@store/mediaObjects'
import { selectPreapprovalAmountPercentage } from '@store/rootResource'
import { ISubmission } from '@store/submissions'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import MediaObjectCostBreakdownReceipt from '../Receipt'

interface IProps {
  license: ILicense | ITransactedLicense
  submission?: ISubmission
}

const MediaObjectCostBreakdownLicense: React.FC<IProps> = ({ license, submission }) => {
  const preapprovalPercentage = useSelector(selectPreapprovalAmountPercentage)

  const {
    total_amount_before_tax_cents,
    total_amount_before_tax_currency,
    amount_paid_before_tax_cents,
    amount_paid_before_tax_currency,
    balance_amount_before_tax_cents,
    balance_amount_before_tax_currency,
    tax_display_name,
    receipt_number,
    label,
    media_type,
  } = license

  const mediaType = submission?.final_media_type ?? media_type

  return (
    <>
      <Container flexBetween alignItems="flex-end" fullWidth topInnerSpacing={0.5}>
        <FlexItem basis="50%">
          <Container bottomOuterSpacing={0.25}>
            <Text color={Theme.grey900Color} light capitalize>
              {`${label} - ${mediaType}`}
            </Text>
          </Container>

          <Text color={Theme.grey900Color} textAlign="left" block medium>
            <FormattedMessage
              id="core.text.totalCurrency"
              values={{ currency: total_amount_before_tax_currency }}
            />
            &nbsp;(
            <FormattedMessage
              id="submission.fullView.pricePanel.tax.text"
              values={{ taxName: tax_display_name }}
            />
            )
          </Text>
        </FlexItem>
        <Container flexEnd noShrink>
          <Container rightOuterSpacing={0.25}>
            <Text color={Theme.grey900Color} medium>
              <Price
                quantity={total_amount_before_tax_cents!}
                currency={total_amount_before_tax_currency}
              />
            </Text>
          </Container>
        </Container>
      </Container>

      {amount_paid_before_tax_cents && (
        <Container
          flexBetween
          alignItems="flex-start"
          fullWidth
          borderColor={Theme.grey400Color}
          topOuterSpacing={0.75}
          borderWidthTop={0.0625}
          topInnerSpacing={0.75}
        >
          <Text color={Theme.grey900Color} textAlign="left" block light>
            <FormattedMessage
              id="contentSubmission.card.modal.preApprove.priceSummary.payment"
              values={{
                preapprovalPercentage,
                currency: amount_paid_before_tax_currency,
              }}
            />
          </Text>
          <Container flexEnd noShrink>
            <Container rightOuterSpacing={0.25}>
              <Text color={Theme.grey900Color} light>
                <Price
                  quantity={amount_paid_before_tax_cents! * -1}
                  currency={amount_paid_before_tax_currency}
                />
              </Text>
            </Container>
          </Container>
        </Container>
      )}

      {balance_amount_before_tax_cents && (
        <Container
          flexBetween
          alignItems="flex-start"
          fullWidth
          borderColor={Theme.primaryColor}
          topOuterSpacing={0.75}
          borderWidthTop={0.0625}
          topInnerSpacing={0.75}
        >
          <Text color={Theme.primaryColor} textAlign="left" block medium>
            <FormattedMessage
              id="contentSubmission.card.modal.preApprove.priceSummary.balanceDue"
              values={{
                currency: balance_amount_before_tax_currency,
              }}
            />
          </Text>
          <Container flexEnd noShrink>
            <Container rightOuterSpacing={0.25}>
              <Text color={Theme.primaryColor} medium>
                <Price
                  quantity={balance_amount_before_tax_cents!}
                  currency={balance_amount_before_tax_currency}
                />
              </Text>
            </Container>
          </Container>
        </Container>
      )}

      {receipt_number && <MediaObjectCostBreakdownReceipt receiptNumber={receipt_number} />}
    </>
  )
}

export default MediaObjectCostBreakdownLicense
