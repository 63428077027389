import React from 'react'

import Theme from '@theme'
import { H5 } from '@tribegroup'
import { EllipsisWrapper } from '../Details.styled'

interface IInfluencerIdentityRowDetailsFullNameProps {
  fullName: string
}

const InfluencerIdentityRowDetailsFullName: React.FC<IInfluencerIdentityRowDetailsFullNameProps> = ({
  fullName,
}) => {
  return (
    <H5 color={Theme.defaultColor} rightOuterSpacing={0.75}>
      <EllipsisWrapper color={Theme.defaultColor}>{fullName}</EllipsisWrapper>
    </H5>
  )
}

export { InfluencerIdentityRowDetailsFullName, IInfluencerIdentityRowDetailsFullNameProps }
export default InfluencerIdentityRowDetailsFullName
