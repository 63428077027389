import React, { PropsWithChildren } from 'react'

import Theme from '@theme'
import { Button, Text } from '@tribegroup'

interface IProps {
  onFBAdUpload: VoidFunction
}

const LicensingUploadToFacebookAdsManagerButton: React.FC<PropsWithChildren<IProps>> = ({
  onFBAdUpload,
  children,
}) => {
  return (
    <Button
      scheme="primary"
      outlined
      noFocus
      onClick={onFBAdUpload}
      small
      topInnerSpacing={0.25}
      bottomInnerSpacing={0.25}
      leftInnerSpacing={0.75}
      rightInnerSpacing={0.75}
    >
      <Text small color={Theme.primaryColor}>
        {children}
      </Text>
    </Button>
  )
}

export { LicensingUploadToFacebookAdsManagerButton }
export default LicensingUploadToFacebookAdsManagerButton
