import AuthCompleteSignupForm from '@components/Auth/CompleteSignup/Form'
import FullImageBackground from '@components/UI/FullImageBackground'
import HeadTag from '@components/UI/HeadTag'

export const CompleteSignupPage = () => {
  return (
    <FullImageBackground>
      <HeadTag id="auth.signup.h1.signUpHeader" />
      <AuthCompleteSignupForm />
    </FullImageBackground>
  )
}

export default CompleteSignupPage
