import React, { Fragment } from 'react'

import { FormattedMessage } from 'react-intl'
import Flex, { FlexItem } from 'styled-flex-component'

import { Chart } from '@components/Charts/AudienceInsigths/AudienceInsights.styled'
import { Hr } from '@components/UI/Hr/Hr.styled'
import Switch from '@components/UI/Switch'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { useBooleanToggle } from '@hooks/useBooleanToggle'
import { IAudienceReporting } from '@store/audienceReporting'
import Theme from '@theme'
import { Container, H3, Header, HorizontalBar } from '@tribegroup'
import { ChartsSectionWrapper } from './AudienceReporting.styled'

interface IProps {
  audienceReporting: IAudienceReporting
  campaignId: number
}

const AudienceReportingLocation: React.FC<IProps> = ({
  audienceReporting: { cities, countries },
  campaignId,
}) => {
  const [shouldUsePercentage, toggleShouldUsePercentage] = useBooleanToggle(true)

  const toggle = () => toggleShouldUsePercentage()

  return (
    <Fragment>
      <Hr backgroundColor={Theme.grey400Color} />
      <Container topInnerSpacing={1.25} bottomInnerSpacing={2.75}>
        <Flex justifyBetween wrap="wrap">
          <FlexItem>
            <H3 color={Theme.grey900Color} bottomOuterSpacing={0.5}>
              <FormattedMessage id="core.text.location" />
            </H3>
          </FlexItem>
          <FlexItem>
            <Container>
              <Header inline uppercase small color={Theme.grey700Color}>
                <FormattedMessage
                  id={`analytics.audienceReporting.switch.${
                    shouldUsePercentage ? 'estPercentage' : 'estImpressions'
                  }`}
                />
              </Header>
              <Container inlineBlock leftInnerSpacing={0.25}>
                <ConnectedTrackedRoute
                  eventName={EventTrackingNames.AudienceReportingToggle}
                  campaignId={campaignId}
                  additionalProperties={{
                    toggle: shouldUsePercentage ? 'impressions' : 'percent',
                    section: 'location',
                  }}
                >
                  <Switch values={{ on: '#', off: '%' }} onChange={toggle} />
                </ConnectedTrackedRoute>
              </Container>
            </Container>
          </FlexItem>
        </Flex>
      </Container>
      <ChartsSectionWrapper>
        <Chart>
          <Container bottomOuterSpacing={1}>
            <Header color={Theme.grey900Color}>
              <FormattedMessage id="audienceInsights.top_countries" />
            </Header>
          </Container>
          {countries && (
            <HorizontalBar theme={Theme} data={countries} showAsPercentage={shouldUsePercentage} />
          )}
        </Chart>
        <Chart>
          <Container bottomOuterSpacing={1}>
            <Header color={Theme.grey900Color}>
              <FormattedMessage id="audienceInsights.top_cities" />
            </Header>
          </Container>
          {cities && (
            <HorizontalBar theme={Theme} data={cities} showAsPercentage={shouldUsePercentage} />
          )}
        </Chart>
      </ChartsSectionWrapper>
    </Fragment>
  )
}

export default AudienceReportingLocation
