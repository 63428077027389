import React from 'react'

import Theme from '@theme'
import { Icon } from '@tribegroup'
import { IVideoMediaProps } from '../Media'
import { Video, VideoOverlay, VideoOverlayImage, VideoStyled, VideoWrapper } from './Video.styled'

export const MediaVideo: React.FC<IVideoMediaProps> = ({
  src: poster,
  autoPlay,
  controls,
  backgroundColor,
  aspectRatio,
  mediaId,
  fullSizeSrc: videoSrc,
  showPlayIcon = false,
}) => {
  const [showOverlay, setShowOverlay] = React.useState<boolean>(showPlayIcon)
  const videoRef = React.createRef()

  const hideOverlay = () => {
    const videoHtml = videoRef.current as HTMLVideoElement
    if (videoHtml && videoHtml.play) {
      videoHtml.play()
    }
    setShowOverlay(false)
  }

  const video = (
    <Video
      ref={videoRef}
      poster={poster}
      src={videoSrc}
      controlsList="nodownload"
      preload="auto"
      controls={controls}
      aspectRatio={aspectRatio}
      autoPlay={autoPlay}
      backgroundColor={backgroundColor}
      className={`media-video-${mediaId}`}
      disablePictureInPicture
    />
  )

  if (!aspectRatio) {
    return video
  }

  return (
    <VideoWrapper>
      <VideoStyled aspectRatio={aspectRatio}>{video}</VideoStyled>
      {showOverlay && (
        <VideoOverlay onClick={hideOverlay} justifyCenter alignCenter>
          <VideoOverlayImage src={poster} backgroundColor={backgroundColor} />
          <Icon glyph="play" color={Theme.whiteColor} size={4.5} />
        </VideoOverlay>
      )}
    </VideoWrapper>
  )
}

export default MediaVideo
