import { useEffect, useState } from 'react'

import styled from 'styled-components'

import AnalyticsAudienceMatchLabelContent from '@components/Analytics/AudienceMatch/Label/Content'
import { ClickableInformation } from '@components/UI/Information'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { EventTrackingNames } from '@enums'
import { useBox } from '@hooks/useBox'
import { useToggle } from '@hooks/useToggle'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'

interface ISubmissionCardHeaderInsights {
  submissionId: number
  campaignId: number
}

const Wrapper = styled(Container)`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
`

export const SubmissionCardHeaderAudienceMatch: React.FC<ISubmissionCardHeaderInsights> = ({
  campaignId,
  submissionId,
}) => {
  const { toggleIsVisible, toggleHide, toggleShow } = useToggle()
  const [box, ref] = useBox<HTMLDivElement>()
  const [showBelowTarget, setShowBelowTarget] = useState(true)

  // the tooltip content will only have dimensions once the button is clicked
  // we want to know where to set the tooltip before that happens
  const tooltipContentHeight = 300

  useEffect(() => {
    if (box.bottom > 0) {
      const spaceBelow = document.documentElement.clientHeight - box.bottom
      if (spaceBelow > 0) {
        setShowBelowTarget(spaceBelow > tooltipContentHeight)
      }
    }
  }, [box])

  return (
    <ClickableInformation
      width={18.125}
      onHideTooltip={toggleHide}
      options={{
        showBelowTarget,
      }}
      target={
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.AudienceTopMatchHover}
          submissionId={submissionId}
          campaignId={campaignId}
          eventTrigger={toggleIsVisible ? undefined : 'mouseEnter'}
        >
          <ConnectedTrackedRoute
            eventName={EventTrackingNames.AudienceTopMatchClick}
            submissionId={submissionId}
            campaignId={campaignId}
            eventTrigger="click"
          >
            <Wrapper pointer ref={ref} onClick={toggleShow}>
              <Icon
                glyph="audience-match"
                size={0.875}
                verticalAlign="middle"
                data-testid="audience-match-icon"
                color={Theme.primaryColor}
              />
            </Wrapper>
          </ConnectedTrackedRoute>
        </ConnectedTrackedRoute>
      }
    >
      <AnalyticsAudienceMatchLabelContent submissionId={submissionId} campaignId={campaignId} />
    </ClickableInformation>
  )
}

export default SubmissionCardHeaderAudienceMatch
