import { MutableRefObject, useEffect, useRef, useState } from 'react'

import { debounce } from 'lodash'

type RectResult = {
  bottom: number
  height: number
  left: number
  right: number
  top: number
  width: number
}

const defaultRectResult = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
}

export function useBox<T extends HTMLElement>(): [RectResult, MutableRefObject<T | undefined>] {
  const ref = useRef<T>()
  const [box, setBox] = useState<RectResult>(defaultRectResult)

  const set = () => setBox(ref?.current?.getBoundingClientRect() || defaultRectResult)

  useEffect(() => {
    set()
    window.addEventListener('scroll', debounce(set, 400))
    window.addEventListener('resize', debounce(set, 400))
    return () => {
      window.removeEventListener('scroll', debounce(set, 400))
      window.removeEventListener('resize', debounce(set, 400))
    }
  }, [])

  return [box, ref]
}
