import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Image from '@components/UI/Image'
import { AspectRatios, MediaTypes } from '@enums'
import { IApplicationState } from '@store'
import {
  selectIsLicensed,
  selectLicenseOriginalMediaUrl,
  selectMediaType,
  selectOriginalMediaUrl,
  selectThumbnailOrPosterMediaUrl,
} from '@store/mediaObjects'
import Theme from '@theme'
import { Icon } from '@tribegroup'
import { FullscreenIcon, ImageWrapper } from './MediaPreview.styled'

interface IMediaPreviewProps {
  mediaObjectId: number
}
interface IInternalProps extends IMediaPreviewProps, InjectedIntlProps {
  originalMediaUrl: string
  thumbnailMediaUrl: string
  licenseOriginalMediaUrl?: string
  mediaType: MediaTypes
  isLicensed: boolean
}

export const MediaPreview: React.FC<IInternalProps> = ({
  originalMediaUrl,
  thumbnailMediaUrl,
  licenseOriginalMediaUrl,
  isLicensed,
  intl,
  mediaType,
}) => {
  return (
    <ImageWrapper
      target="_blank"
      href={isLicensed ? licenseOriginalMediaUrl : originalMediaUrl}
      data-tooltip-id="base-tooltip"
      data-tooltip-content={intl.formatMessage({
        id: 'submission.card.action.licensecontent.viewFullSize',
      })}
    >
      <Image width={5.25} src={thumbnailMediaUrl} aspectRatio={AspectRatios.ONE_BY_ONE} />
      <FullscreenIcon>
        <Icon
          size={1.5}
          glyph={mediaType === MediaTypes.VIDEO ? 'video' : 'full-screen-heavy'}
          color={Theme.whiteColor}
          className="fullscreen"
        />
      </FullscreenIcon>
    </ImageWrapper>
  )
}

const mapStateToProps = (state: IApplicationState, { mediaObjectId }: IInternalProps) => ({
  originalMediaUrl: selectOriginalMediaUrl(state, mediaObjectId),
  thumbnailMediaUrl: selectThumbnailOrPosterMediaUrl(state, mediaObjectId),
  isLicensed: selectIsLicensed(state, mediaObjectId),
  licenseOriginalMediaUrl: selectLicenseOriginalMediaUrl(state, mediaObjectId),
  mediaType: selectMediaType(state, mediaObjectId),
})

export default compose<IInternalProps, IMediaPreviewProps>(
  injectIntl,
  connect(mapStateToProps),
)(MediaPreview)
