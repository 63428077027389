import { IResponseError } from '@lib/error'
import { IHateoasLink } from '..'

export enum FeedbackActionTypes {
  FETCH_REQUEST = 'feedback/FETCH_REQUEST',
  FETCH_SUCCESS = 'feedback/FETCH_SUCCESS',
  FETCH_ERROR = 'feedback/FETCH_ERROR',

  ADD_REQUEST = 'feedback/ADD_REQUEST',
  ADD_SUCCESS = 'feedback/ADD_SUCCESS',
  ADD_ERROR = 'feedback/ADD_ERROR',
}

export enum BulkFeedbackActionTypes {
  BULK_ADD_REQUEST = 'feedback/BULK_ADD_REQUEST',
  BULK_ADD_SUCCESS = 'feedback/BULK_ADD_SUCCESS',
  BULK_ADD_ERROR = 'feedback/BULK_ADD_ERROR',
}

export enum MarkAsReadActionTypes {
  MARK_AS_READ_REQUEST = 'feedback/MARK_AS_READ_REQUEST',
  MARK_AS_READ_SUCCESS = 'feedback/MARK_AS_READ_SUCCESS',
  MARK_AS_READ_ERROR = 'feedback/MARK_AS_READ_ERROR',
}

export interface IFeedbackAttachment {
  id: number
  file_id: string
  upload_url: string
  status: 'awaiting_upload' | 'uploaded' | 'ready'
  file_name: string
  content_type: string
  original_url?: string
  media_type?: 'pdf' | 'image' | 'video'
  thumbnail_url?: string
}

export interface IFeedback {
  created_at: string
  text: string
  author_id: number
  author_name: string
  role: string
  type: string
  id: number
  message_type?: string
  attachment?: IFeedbackAttachment
}

export interface IInfluencerIdentity {
  id: number
  name: string
  age: number
  gender: string
  country: string
  state: string
  avatar: string
  previously_purchased: boolean
  headshot?: {
    original_url: string
    thumbnail_url: string
  }
}

export interface IAddFeedbackRequestBody {
  message_body: string
  file?: File
}

export interface IFeedBackMeta {
  refreshSubmission?: boolean
  event?: any
}

export interface IFeedbackState {
  readonly errors?: IResponseError
  readonly isFetching: boolean
  readonly isAdding: boolean
  readonly feedbackBySubmissionId: Record<number, ReadonlyArray<IFeedback>>
  readonly feedbackLinksBySubmissionId: Record<number, ReadonlyArray<IHateoasLink>>
  readonly influencerBySubmissionId: Record<number, IInfluencerIdentity>
}
