import { Fragment } from 'react'

import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { DAY_OF_WEEK, FULL_DATE } from '@constants'
import Theme from '@theme'
import { Text } from '@tribegroup'

export const ScheduleWrapper = ({ tipMessage, children }) => (
  <Flex full column justifyCenter data-tooltip-content={tipMessage} data-tooltip-id="base-tooltip">
    {children}
  </Flex>
)

export const Approved = ({ scheduleDate }) => (
  <Fragment>
    <Text xsmall color={Theme.grey900Color} uppercase>
      {format(new Date(scheduleDate), DAY_OF_WEEK)}
    </Text>
    <Text xsmall color={Theme.grey900Color} uppercase>
      {format(new Date(scheduleDate), FULL_DATE)}
    </Text>
  </Fragment>
)

export const ScheduleMissed = ({ scheduleDate }) => (
  <Fragment>
    <Text xsmall color={Theme.errorColor} uppercase>
      <FormattedMessage id="submission.card.publishSchedule.scheduleMissed" />
    </Text>
    <Text xsmall color={Theme.errorColor} uppercase>
      {format(new Date(scheduleDate), FULL_DATE)}
    </Text>
  </Fragment>
)

export const Overdue = () => (
  <Fragment>
    <Text xsmall color={Theme.errorColor} uppercase>
      <FormattedMessage id="submission.card.publishSchedule.post" />
    </Text>
    <Text xsmall color={Theme.errorColor} uppercase>
      <FormattedMessage id="submission.card.publishSchedule.overdue" />
    </Text>
  </Fragment>
)

export const Within48Hours = () => (
  <Fragment>
    <Text xsmall color={Theme.grey900Color} uppercase>
      <FormattedMessage id="submission.card.publishSchedule.post" />
    </Text>
    <Text xsmall color={Theme.grey900Color} uppercase>
      <FormattedMessage id="submission.card.publishSchedule.within48hours" />
    </Text>
  </Fragment>
)
