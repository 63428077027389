import React from 'react'

import throttle from 'lodash.throttle'
import { FormattedMessage } from 'react-intl'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import Flex from 'styled-flex-component'

import { Hr } from '@components/UI/Hr/Hr.styled'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { ColWrapper, Header, HeaderWrapper, ScrollWrapper, Wrapper } from './ListView.styled'

interface IAnalyticsPostCampaignReportListView {
  headers: React.ReactNode
  children: React.ReactNode
  profiles: React.ReactNode
}

const AnalyticsPostCampaignReportListView: React.FC<IAnalyticsPostCampaignReportListView> = ({
  headers,
  children,
  profiles,
}) => {
  const [showLeftShadow, setShowLeftShadow] = React.useState(false)
  const [showRightShadow, setShowRightShadow] = React.useState(false)

  const horizontalScrollListViewRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    window.addEventListener('resize', throttle(adjustScrollingShadowOverlay, 200))

    if (horizontalScrollListViewRef.current) {
      horizontalScrollListViewRef.current.addEventListener(
        'scroll',
        throttle(adjustScrollingShadowOverlay, 200),
      )
    }

    adjustScrollingShadowOverlay()

    return () => {
      window.removeEventListener('resize', adjustScrollingShadowOverlay)
    }
  }, [showLeftShadow, showRightShadow])

  const adjustScrollingShadowOverlay = () => {
    if (!horizontalScrollListViewRef.current) {
      return
    }
    const ref = horizontalScrollListViewRef.current!
    const scrollLeft = ref.scrollLeft
    const remainingWidth = ref.scrollWidth - ref.clientWidth
    const isFullScrolledRight = scrollLeft === remainingWidth

    if (scrollLeft === 0) {
      setShowLeftShadow(false)
    } else {
      setShowLeftShadow(true)
    }

    if (isFullScrolledRight) {
      setShowRightShadow(false)
    } else {
      setShowRightShadow(true)
    }
  }

  return (
    <Container topOuterSpacing={1.25}>
      <ScrollSync>
        <Flex>
          <Wrapper>
            <HeaderWrapper>
              <ScrollSyncPane>
                <ScrollWrapper overflowX="hidden">
                  <ColWrapper>
                    <Header>
                      <Container textAlign="left" fullWidth leftInnerSpacing={0.75}>
                        <Text color={Theme.grey800Color} xsmall>
                          <FormattedMessage id="analytics.postCampaignReport.submission.profile.header.publishedContent" />
                        </Text>
                      </Container>
                    </Header>
                  </ColWrapper>
                </ScrollWrapper>
              </ScrollSyncPane>
              <Container bottomOuterSpacing={0.25}>
                <Hr backgroundColor={Theme.primaryColor} />
              </Container>
            </HeaderWrapper>
            <Flex column>{profiles}</Flex>
          </Wrapper>
          <Wrapper showLeftShadow={showLeftShadow} showRightShadow={showRightShadow}>
            <HeaderWrapper>
              <Container>
                <ScrollSyncPane>
                  <ScrollWrapper overflowX="hidden">{headers}</ScrollWrapper>
                </ScrollSyncPane>
                <Hr backgroundColor={Theme.primaryColor} />
              </Container>
            </HeaderWrapper>
            <ScrollSyncPane>
              <ScrollWrapper column ref={horizontalScrollListViewRef}>
                {children}
              </ScrollWrapper>
            </ScrollSyncPane>
          </Wrapper>
        </Flex>
      </ScrollSync>
    </Container>
  )
}

export default AnalyticsPostCampaignReportListView
