import { PropsWithChildren, PureComponent } from 'react'

import Flex from 'styled-flex-component'

import Theme from '@theme'
import { H1, H5, Text } from '@tribegroup'
import ConfirmationMessageBoxStyled, {
  CTAStyled,
  FooterCTAStyled,
  MessageBoxStyled,
} from './ConfirmationMessageBox.styled'

export interface IConfirmationMessageBox {
  /**
   * Title of the confirmation message box
   *
   * @default undefined
   */
  title: JSX.Element | string

  /**
   * Subtitle of the confirmation message box
   *
   * @default undefined
   */
  subtitle?: JSX.Element | string

  /**
   * Component inserted after the message body. Has small space between message and message CTA
   *
   * @default undefined
   */
  messageCTA?: JSX.Element | string

  /**
   * Component after the message body (and messageCTA). Has relatively big space between message and footer
   *
   * @default undefined
   */
  footerCTA?: JSX.Element | string
}

export default class ConfirmationMessageBox extends PureComponent<
  PropsWithChildren<IConfirmationMessageBox>,
  any
> {
  public render() {
    const { title, subtitle, children, messageCTA, footerCTA } = this.props
    return (
      <ConfirmationMessageBoxStyled fullOnSmallScreens>
        <Flex center column>
          <img alt={'tribegroup-icon'} width={40} src={require('@images/brand-icon.png')} />
          <H1 color={Theme.grey900Color} center>
            {title}
          </H1>
          {subtitle && <H5 color={Theme.grey800Color}>{subtitle}</H5>}
          <MessageBoxStyled>
            <Text color={Theme.grey800Color}>{children}</Text>
          </MessageBoxStyled>
          {messageCTA && <CTAStyled>{messageCTA}</CTAStyled>}
          {footerCTA && <FooterCTAStyled>{footerCTA}</FooterCTAStyled>}
        </Flex>
      </ConfirmationMessageBoxStyled>
    )
  }
}
