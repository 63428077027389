import { BrandManagerIdentityProviders } from '@enums'
import { IResponseError } from '@lib/error'
import { IHateoasLink } from '@store'

export interface IInstagramBusinessAccount {
  // IG Business Account ID
  id: string
}

export interface IInstagramAccountsViaFacebookPages {
  // Facebook page ID
  readonly id: string
  // Facebook page access token
  readonly access_token: string
  // Associated IG Business Account to this Facebook page
  readonly instagram_business_account: IInstagramBusinessAccount
}

export interface IFacebookPermissions {
  permission: string
  status: 'granted' | 'declined'
}

export interface IBrandManagerIdentityRequest {
  provider: string
  oauth_token: string
  uid: string
}

export interface IBrandManagerIdentity {
  id: number
  handle: string
  avatar_url: string
  links: ReadonlyArray<IHateoasLink>
  oauth_uid?: string
  display_name?: string
  display_url?: string
  channel: BrandManagerIdentityProviders
}

export interface IBrandManagerIdentitiesState {
  readonly brandManagerIdentities: IBrandManagerIdentity[]
  readonly links: ReadonlyArray<IHateoasLink>
  readonly isFetching: boolean
  readonly isConnecting: boolean
  readonly errors?: IResponseError
  readonly isFetched?: boolean
  readonly pendingBrandManagerIdentityIds: ReadonlyArray<number>
}

export enum ConnectBrandManagerIdentities {
  CONNECT_REQUEST = 'brandManagerIdentities/CONNECT_REQUEST',
  CONNECT_AD_ACCOUNT_REQUEST = 'brandManagerIdentities/CONNECT_AD_ACCOUNT_REQUEST',
  CONNECT_SUCCESS = 'brandManagerIdentities/CONNECT_SUCCESS',
  CONNECT_ERROR = 'brandManagerIdentities/CONNECT_ERROR',
}

export enum FetchBrandManagerIdentities {
  FETCH_REQUEST = 'brandManagerIdentities/FETCH_REQUEST',
  FETCH_SUCCESS = 'brandManagerIdentities/FETCH_SUCCESS',
  FETCH_ERROR = 'brandManagerIdentities/FETCH_ERROR',
}

export enum RemoveBrandManagerIdentity {
  REMOVE_REQUEST = 'brandManagerIdentities/REMOVE_REQUEST',
  REMOVE_SUCCESS = 'brandManagerIdentities/REMOVE_SUCCESS',
  REMOVE_ERROR = 'brandManagerIdentities/REMOVE_ERROR',
}

export enum ReconnectBrandManagerIdentity {
  RECONNECT_REQUEST = 'brandManagerIdentities/RECONNECT_REQUEST',
  RECONNECT_SUCCESS = 'brandManagerIdentities/RECONNECT_SUCCESS',
  RECONNECT_ERROR = 'brandManagerIdentities/RECONNECT_ERROR',
}
