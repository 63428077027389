import { IMoodboardImage } from '@store/campaigns'
import { IHateoasLink } from '..'

export enum MoodboardMediaUploadActionTypes {
  UPLOAD_REQUEST = 'moodboardMedia/UPLOAD_REQUEST',
  UPLOAD_IN_PROGRESS = 'moodboardMedia/UPLOAD_IN_PROGRESS',
  UPLOAD_SUCCESS = 'moodboardMedia/UPLOAD_SUCCESS',
  UPLOAD_ERROR = 'moodboardMedia/UPLOAD_ERROR',
  CANCEL_REQUEST = 'moodboardMedia/CANCEL_REQUEST',
  INVALID_FILE = 'moodboardMedia/INVALID_FILE',
}
export enum MoodboardMediaDeleteActionTypes {
  DELETE_REQUEST = 'moodboardMedia/DELETE_REQUEST',
  DELETE_SUCCESS = 'moodboardMedia/DELETE_SUCCESS',
  DELETE_ERROR = 'moodboardMedia/DELETE_ERROR',
}

export interface IFileError {
  message: string
  code: 'file-too-large' | 'file-too-small' | 'too-many-files' | 'file-invalid-type' | string
}

export interface IPreSignedUrlItem {
  id: number
  position: number
  status: 'awaiting_upload' | 'uploaded' | 'ready' | string
  upload_url?: string
  links: IHateoasLink[]
}

export interface IMedia {
  file: File
  errors?: IFileError[]
}

export interface IMoodboardItem {
  isUploading?: boolean
  isDeleting?: boolean
  uploadFailed?: boolean
  deleteFailed?: boolean
  preSignedUrlItem?: IPreSignedUrlItem
  moodboard?: IMoodboardImage
  file?: File
  errors?: IFileError[]
}

export interface IMoodboardMediaState {
  mediaList: Record<number, IMoodboardItem[]>
}

export interface IPreSignedUrlResponse {
  items: IPreSignedUrlItem[]
}
