import React from 'react'

import { FormattedMessage } from 'react-intl'

import InformationContent from '@components/UI/Information/Content/Content'
import ConnectedTrackedRoute from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import { AUDIENCE_INSIGHTS_KNOW_HOW } from '@constants'
import { EventTrackingNames } from '@enums'
import Theme from '@theme'
import { Container, Link, Text } from '@tribegroup'

interface IProps {
  submissionId: number
  campaignId: number
}

const AnalyticsAudienceMatchLabelContent: React.FC<IProps> = ({ submissionId, campaignId }) => (
  <InformationContent
    title={<FormattedMessage id="core.text.audienceMatch" />}
    content={
      <React.Fragment>
        <FormattedMessage id="analytics.audienceMatch.label.tooltip.content" />
        <Container topOuterSpacing={0.75}>
          <ConnectedTrackedRoute
            eventName={EventTrackingNames.AudienceTopMatchLearnMore}
            submissionId={submissionId}
            campaignId={campaignId}
          >
            <Link href={AUDIENCE_INSIGHTS_KNOW_HOW} target="_blank" color={Theme.secondaryColor}>
              <Text color={Theme.primaryColor}>
                <FormattedMessage id="core.text.learnMore.withArrow" />
              </Text>
            </Link>
          </ConnectedTrackedRoute>
        </Container>
      </React.Fragment>
    }
  />
)

export default AnalyticsAudienceMatchLabelContent
