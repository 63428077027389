import styled from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'

export const PerformanceIconWrapper = styled(Flex)`
  height: 4.5rem;
  width: 4.5rem;
  overflow: hidden;
  margin-right: 1rem;
  border: double 1px transparent;
  border-radius: 80px;
  background-image: linear-gradient(${Theme.grey100Color}, ${Theme.grey100Color}),
    radial-gradient(circle at top left, ${Theme.primaryColor}, ${Theme.errorColor});
  background-origin: border-box;
  background-clip: content-box, border-box;
`
