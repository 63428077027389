import { Fragment } from 'react'

import ContentLoader from 'react-content-loader'
import { Col, Row } from 'react-styled-flexboxgrid'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Icon } from '@tribegroup'
import {
  CardHeader,
  CardProfilePic,
  ContentMedia,
  ContentWrapper,
  LowerContent,
  SpinnerIconWrapper,
  UpperContent,
} from './Loading.styled'

const animationSpeed = 0
const Header = () => {
  return (
    <Flex justifyStart>
      <CardProfilePic>
        <ContentLoader
          width={56}
          height={66}
          speed={animationSpeed}
          foregroundColor={Theme.grey400Color}
          backgroundColor={Theme.grey200Color}
          preserveAspectRatio="none"
        >
          <circle cx="36" cy="32" r="20" />
        </ContentLoader>
      </CardProfilePic>
      <CardHeader>
        <ContentLoader
          width={274}
          height={66}
          speed={animationSpeed}
          foregroundColor={Theme.grey400Color}
          backgroundColor={Theme.grey200Color}
        >
          <rect x="16" y="17" rx="6" ry="6" width="120" height="10" />
          <rect x="16" y="37" rx="6" ry="6" width="43" height="10" />
        </ContentLoader>
      </CardHeader>
    </Flex>
  )
}

const LowerContentRectGroup = ({ yBasePosition }) => (
  <Fragment>
    <rect x="16" y={yBasePosition} width="295" height="1" />
    <rect x="16" y={yBasePosition + 24} rx="6" ry="6" width="128" height="10" />
    <rect x="16" y={yBasePosition + 59} width="295" height="1" />
    <rect x="16" y={yBasePosition + 84} rx="6" ry="6" width="150" height="10" />
  </Fragment>
)

const ContentPlaceholder = ({ alternatePricePosition }) => {
  return (
    <Fragment>
      <UpperContent>
        <ContentLoader
          width={335}
          height={128}
          speed={animationSpeed}
          foregroundColor={Theme.grey400Color}
          backgroundColor={Theme.grey200Color}
        >
          <rect x="16" y="22" rx="6" ry="6" width="260" height="10" />
          <rect x="16" y="42" rx="6" ry="6" width="289" height="10" />
          <rect x="16" y="62" rx="6" ry="6" width="289" height="10" />
          <rect x="16" y="82" rx="6" ry="6" width="220" height="10" />
        </ContentLoader>
      </UpperContent>
      <LowerContent>
        <ContentLoader
          width={335}
          height={180}
          speed={animationSpeed}
          foregroundColor={Theme.grey400Color}
          backgroundColor={Theme.grey200Color}
        >
          {alternatePricePosition ? (
            <LowerContentRectGroup yBasePosition={61} />
          ) : (
            <LowerContentRectGroup yBasePosition={1} />
          )}
        </ContentLoader>
      </LowerContent>
    </Fragment>
  )
}

interface IFullLoadingProps {
  alternatePricePosition?: boolean
}

const FullLoading = ({ alternatePricePosition }: IFullLoadingProps) => (
  <Row>
    <Col xs={12} md={false}>
      <Header />
    </Col>
    <Col xs={12} md={7}>
      <ContentMedia>
        <SpinnerIconWrapper>
          <Icon color={Theme.primaryColor} glyph="spinner" />
        </SpinnerIconWrapper>
        <ContentLoader
          width={330}
          height={330}
          speed={animationSpeed}
          foregroundColor={Theme.whiteColor}
          backgroundColor={Theme.whiteColor}
        >
          <rect x="0" y="0" width="100%" height="100%" />
        </ContentLoader>
      </ContentMedia>
    </Col>
    <Col xs={false} md={5}>
      <ContentWrapper>
        <Header />
        <ContentPlaceholder alternatePricePosition={alternatePricePosition} />
      </ContentWrapper>
    </Col>
    <Col xs={12} md={false}>
      <ContentWrapper>
        <ContentPlaceholder alternatePricePosition={alternatePricePosition} />
      </ContentWrapper>
    </Col>
  </Row>
)

export default FullLoading
