import { Reducer } from 'redux'

import { EMPTY_LIST } from '@constants'
import { AuthActionTypes } from '@store/auth'
import { addToSet, returnSecondIfDeepEqual } from '@utils'
import {
  IInfluencerIdentityState,
  InfluencerIdentitiesActionTypes,
  InfluencerIdentitiesUsingNextActionTypes,
  InfluencerIdentityActionTypes,
} from './types'

const initialState: IInfluencerIdentityState = {
  isFetching: false,
  influencerIdentitiesByCampaignId: {},
  influencerIdentityLinksByCampaignId: {},
}

const reducer: Reducer<IInfluencerIdentityState> = (state = initialState, action) => {
  switch (action.type) {
    case InfluencerIdentitiesActionTypes.FETCH_REQUEST:
    case InfluencerIdentitiesUsingNextActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
        errors: undefined,
      }
    }

    case InfluencerIdentitiesUsingNextActionTypes.FETCH_SUCCESS: {
      const { campaignId, items, links } = action.payload
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        influencerIdentitiesByCampaignId: {
          [campaignId]: addToSet(state.influencerIdentitiesByCampaignId[campaignId], items),
        },
        influencerIdentityLinksByCampaignId: returnSecondIfDeepEqual(
          { ...state.influencerIdentityLinksByCampaignId, [campaignId]: links },
          state.influencerIdentityLinksByCampaignId,
        ),
      }
    }

    case InfluencerIdentitiesActionTypes.FETCH_SUCCESS: {
      const { campaignId, items, links } = action.payload
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        influencerIdentitiesByCampaignId: returnSecondIfDeepEqual(
          {
            ...state.influencerIdentitiesByCampaignId,
            [campaignId]: items,
          },
          state.influencerIdentitiesByCampaignId,
        ),
        influencerIdentityLinksByCampaignId: returnSecondIfDeepEqual(
          { ...state.influencerIdentityLinksByCampaignId, [campaignId]: links },
          state.influencerIdentityLinksByCampaignId,
        ),
      }
    }

    case InfluencerIdentitiesActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }
    }

    case InfluencerIdentityActionTypes.UPDATE_INFLUENCER_IDENTITY: {
      const { campaignId, influencerIdentity } = action.payload
      const existingItems = state.influencerIdentitiesByCampaignId[campaignId] || EMPTY_LIST
      const updatedItems = existingItems.map((existingInfluencerIdentity) =>
        influencerIdentity.id === existingInfluencerIdentity.id
          ? influencerIdentity
          : existingInfluencerIdentity,
      )
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        influencerIdentitiesByCampaignId: {
          ...state.influencerIdentitiesByCampaignId,
          [campaignId]: returnSecondIfDeepEqual(updatedItems, existingItems),
        },
      }
    }

    case InfluencerIdentityActionTypes.REMOVE_INFLUENCER_IDENTITY: {
      const { campaignId, influencerIdentityId } = action.payload

      const existingItems = state.influencerIdentitiesByCampaignId[campaignId] || EMPTY_LIST
      const updatedItems = existingItems.filter(
        (existingInfluencerIdentity) => influencerIdentityId !== existingInfluencerIdentity.id,
      )

      return {
        ...state,
        isFetching: false,
        influencerIdentitiesByCampaignId: {
          ...state.influencerIdentitiesByCampaignId,
          [campaignId]: returnSecondIfDeepEqual(updatedItems, existingItems),
        },
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS: {
      return initialState
    }

    default:
      return state
  }
}

export { reducer as influencerIdentitiesReducer }
