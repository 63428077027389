import { ComponentType } from 'react'

import styled, { css } from 'styled-components'

import Theme from '@theme'

interface IStyledDotProps {
  active?: boolean
  small?: boolean
  xsmall?: boolean
  hidden?: boolean
}

export const StyledDot: ComponentType<IStyledDotProps> = styled.div`
  display: block;
  position: relative;
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 10rem;
  margin: 0.25rem;
  background: ${Theme.grey400Color};
  overflow: hidden;
  transition: all 500ms ease 100ms;
  ${({ active, small, xsmall, hidden }): IStyledDotProps => css`
    ${hidden &&
    `
        width: 0;
        height: 0;
        opacity: 0;
        margin: 0;
    `};
    ${xsmall &&
    `
        width: 0.125rem;
        height: 0.125rem;
      `};
    ${small &&
    `
        width: 0.25rem;
        height: 0.25rem;
      `};
    &::after {
      content: ' ';
      transition: 300ms ease opacity;
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 10rem;
      ${active &&
      `
          opacity: 1;
          background: ${Theme.primaryColor};
        `};
    }
  `};
`
