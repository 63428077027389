import { PropsWithChildren, PureComponent } from 'react'

import styled, { css } from 'styled-components'

import { themed } from '../Theme'

export interface IFormGroupProps {
  /**
   * Displays contents aligned to left or right
   *
   * @default left
   */
  align?: 'left' | 'right'

  /**
   * Set outer margin top in rem
   *
   * @default 0
   */
  outerTopSpacing?: number

  /**
   * Set outer margin bottom in rem
   *
   * @default 1
   */
  outerBottomSpacing?: number

  /**
   * Set the label colour
   *
   * @default #8c8c8c
   */
  labelColour?: string

  /**
   * Force input fields to fit container width
   *
   * @default false
   */
  stretchChildWidth?: boolean
}

const FormGroupWrapper = themed(styled<IFormGroupProps, any>('div')`
  ${({ theme }) =>
    css`
      ${({
        align = 'left',
        outerBottomSpacing = 1,
        outerTopSpacing = 0,
        labelColour = theme.grey800Color,
        stretchChildWidth = false,
      }) => css`
        display: block;
        position: relative;
        width: 100%;
        margin-top: ${outerTopSpacing}rem;
        margin-bottom: ${outerBottomSpacing}rem;

        label.formgroup-label,
        label.formgroup-label span {
          color: ${labelColour};
        }
        ${align === 'right' &&
        css`
          text-align: right;

          label.formgroup-label {
            display: block;
            text-align: right;
          }
        `};

        label.formgroup-label {
          display: inline-flex;
          align-items: center;
        }

        div.formgroup-input {
          display: block;
        }
        ${stretchChildWidth &&
        css`
          label.formgroup-label {
            width: 100%;
          }

          div.formgroup-input {
            width: 100%;

            > * {
              display: block;
              width: 100%;
              box-sizing: border-box;
            }
          }
        `};
      `};
    `};
`)

class FormGroup extends PureComponent<PropsWithChildren<IFormGroupProps>> {
  render() {
    return <FormGroupWrapper {...this.props} />
  }
}

export default FormGroup
