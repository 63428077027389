import { useContext } from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import { BRANDS_INSTAGRAM_CONNECT_EDUCATION, BRANDS_TIKTOK_CONNECT_EDUCATION } from '@constants'
import { CampaignContext } from '@context/Campaign'
import { BrandManagerIdentityProviders, SubmissionChannels } from '@enums'
import {
  selectBriefChannelPreference,
  selectCampaignConnectedSocialAccount,
} from '@store/campaigns'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { BetaLabel, Container, Link, Text } from '@tribegroup'

const ConnectedAccountsDrawerCollaboratorTitle = () => {
  const { campaign } = useContext(CampaignContext)
  const { channelPreferences } = useSelector((state: IApplicationState) => {
    return {
      briefConnectedInstagramIdentity: selectCampaignConnectedSocialAccount(
        state,
        campaign.id,
        BrandManagerIdentityProviders.Instagram,
      ),
      briefConnectedTikTokIdentity: selectCampaignConnectedSocialAccount(
        state,
        campaign.id,
        BrandManagerIdentityProviders.TikTok,
      ),
      channelPreferences: selectBriefChannelPreference(state, campaign.id),
    }
  })

  return (
    <Container topOuterSpacing={6} leftOuterSpacing={1.5} rightOuterSpacing={1.5}>
      <Flex full justifyBetween alignCenter>
        <Text
          uppercase
          customFontSize={0.8125}
          lineHeight={1.23}
          color={Theme.grey900Color}
          customletterSpacing={'1.5px'}
        >
          <FormattedMessage id="core.text.connectedAccounts" />
        </Text>
        <BetaLabel />
      </Flex>

      <Container topOuterSpacing={1.25}>
        <Text color={Theme.grey900Color}>
          <FormattedHTMLMessage id="connectedAccounts.drawer.collaborator.label.paragraph" />
        </Text>
      </Container>

      <Container topOuterSpacing={0.75}>
        <Text color={Theme.grey900Color}>
          <FormattedHTMLMessage id="connectedAccounts.drawer.label.paragraph.third" />
        </Text>
      </Container>

      <Container topOuterSpacing={0.125} bottomOuterSpacing={1.5} verticalAlign="middle">
        <Flex full alignCenter>
          {channelPreferences?.includes(SubmissionChannels.TikTok) && (
            <Container rightOuterSpacing={0.5}>
              <Link href={BRANDS_TIKTOK_CONNECT_EDUCATION} target="_blank">
                <Text color={Theme.primaryColor}>
                  <FormattedMessage id="core.text.tiktok.withArrow" />
                </Text>
              </Link>
            </Container>
          )}
          {channelPreferences?.includes(SubmissionChannels.Instagram) && (
            <Container>
              <Link href={BRANDS_INSTAGRAM_CONNECT_EDUCATION} target="_blank">
                <Text color={Theme.primaryColor}>
                  <FormattedMessage id="core.text.instagram.withArrow" />
                </Text>
              </Link>
            </Container>
          )}
        </Flex>
      </Container>
    </Container>
  )
}

export { ConnectedAccountsDrawerCollaboratorTitle }
