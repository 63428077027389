import React from 'react'

import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { H4 } from '@tribegroup'

const BuilderBriefChannelsInstagramHeader: React.FC = () => (
  <H4 color={Theme.grey900Color}>
    <FormattedMessage id="builder.brief.socialMedia.instagram.tags.header" />
  </H4>
)

export default BuilderBriefChannelsInstagramHeader
