import { PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'

export const CoachmarkContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.375rem;
`

export const CoachmarkSection = styled.div`
  width: ${({ width }) => width}rem;
`

export const CoachmarkBlock = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 1.25rem;
  }

  p {
    margin-bottom: 0.75rem;
  }
`

interface ICheckItemProps {
  marginBottom?: number
}

export const CheckItem: React.FC<PropsWithChildren<ICheckItemProps>> = styled.div`
  > div {
    margin-right: 0.5rem;
    flex-shrink: 0;
  }

  display: flex;
  align-items: flex-start;
  ${({ marginBottom = 0.25 }) =>
    css`
      margin-bottom: ${marginBottom}rem;
    `};
`

export const ImageWrapper = styled.div`
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  margin-bottom: 0.5rem;
  margin-top: 1.25rem;

  img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
  }

  ${({ withBorder, borderColor }) =>
    withBorder &&
    css`
      border: solid 1px ${borderColor};
    `}
  ${({ crossOut, crossColor }) =>
    crossOut &&
    css`
      &::after {
        content: ' ';
        width: 0.5rem;
        height: 13.5rem;
        background: ${crossColor};
        position: absolute;
        top: calc(50% - 6.75rem);
        left: calc(50% - 0.25rem);
        border-radius: 0.5rem;
        opacity: 0.4;
      }

      &::after {
        transform: rotate(37.5deg);
      }
    `};
  ${({ withCross, crossColor }) =>
    withCross &&
    css`
      &::after,
      &::before {
        content: ' ';
        width: 0.5rem;
        height: 11.5rem;
        background: ${crossColor};
        position: absolute;
        top: calc(50% - 5.75rem);
        left: calc(50% - 0.25rem);
        border-radius: 0.5rem;
        opacity: 0.4;
      }

      &::after {
        transform: rotate(45deg);
      }

      &::before {
        transform: rotate(-45deg);
      }
    `};
`

export const SmallTextWrapper = styled.div`
  width: 15rem;
`

export const ImageGrid = styled.div`
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: repeat(2, 1fr);
`

interface ISideBarContentWrapperProps {
  marginTop?: number
  marginLeft?: number
  width?: number
  center?: boolean
}

export const SideBarContentWrapper: React.FC<PropsWithChildren<
  ISideBarContentWrapperProps
>> = styled.div`
  position: absolute;
  left: 100%;
  top: 0;
  text-align: left;
  ${({ marginTop = 0 }) =>
    css`
      margin-top: ${marginTop}rem;
    `};
  ${({ marginLeft = 5 }) =>
    css`
      margin-left: ${marginLeft}rem;
    `};
  ${({ width = 15 }) =>
    css`
      width: ${width}rem;
    `};
  ${({ center }) =>
    center &&
    css`
      display: flex;
      height: 100%;
      align-items: center;
    `};
`

export const TipImageWrapper = styled.div`
  margin-bottom: 1.875rem;

  img {
    width: 100%;
  }
`
