import React from 'react'

import Flex from 'styled-flex-component'

import { CreateCampaignButton } from '@components/Campaign/TitleBar/Buttons'

const CampaignsTitleBarButtons: React.FC = () => {
  return (
    <Flex justifyEnd alignCenter full>
      <CreateCampaignButton />
    </Flex>
  )
}

export { CampaignsTitleBarButtons }

export default CampaignsTitleBarButtons
