import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { CampaignDraftPreview } from '@components/Campaign/Preview'
import ActionIcon from '@components/UI/ActionIcon'
import TrackedRoute from '@components/UI/TrackedRoute/TrackedRoute'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { EventTrackingNames } from '@enums'
import { withBuilderValidation, withDraft } from '@hocs'
import { useIsMounted } from '@hooks/useIsMounted'
import { useToggle } from '@hooks/useToggle'
import { IApplicationState } from '@store'
import { updateHasSeenPreviewTooltip } from '@store/campaigns'
import { selectShowCampaignPreviewTooltip } from '@store/drafts'
import Theme from '@theme'
import { getCampaignType } from '@utils'
import { PreviewIconWrapper } from './Preview.styled'
import BuilderCampaignPreviewIconTooltip from './Tooltip'

interface IInternalProps extends IStateProps {
  disabled?: boolean
  campaignType: string
  showPreviewTooltip: boolean
  updateHasSeenPreviewTooltip: typeof updateHasSeenPreviewTooltip
}

interface IStateProps extends IDraftContextProps, IBuilderValidationContextProps {}

const BuilderTitleBarActionsButtonPreview: React.FC<IInternalProps> = ({
  draft,
  disabled,
  campaignType,
  showPreviewTooltip,
  updateHasSeenPreviewTooltip: updateHasSeenPreviewTooltipAction,
}) => {
  const { toggleIsVisible, toggleHide, toggleShow } = useToggle()

  const isMounted = useIsMounted()
  const [showTooltipComponent, setShowTooltipComponent] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (isMounted && showPreviewTooltip) {
      setShowTooltipComponent(true)
    }
  }, [showPreviewTooltip])

  const onShowPreview = () => {
    toggleShow()
    if (showPreviewTooltip) {
      updateHasSeenPreviewTooltipAction()
    }
  }

  return (
    <React.Fragment>
      <CampaignDraftPreview open={toggleIsVisible} draft={draft} onClose={toggleHide} />
      <PreviewIconWrapper center>
        {showTooltipComponent && <BuilderCampaignPreviewIconTooltip />}
        <TrackedRoute
          eventName={EventTrackingNames.CampaignBuilderPreviewCampaign}
          properties={{
            brief_id: draft.id,
            brief_type: campaignType,
          }}
        >
          <ActionIcon
            glyph="preview"
            highlighted={false}
            showNotification={false}
            color={Theme.defaultColor}
            size={1.5}
            onClick={onShowPreview}
            disabled={disabled}
            elementName="preview-campaign"
          />
        </TrackedRoute>
      </PreviewIconWrapper>
    </React.Fragment>
  )
}

const mapStateToProps = (state: IApplicationState, { draft, currentStep }: IStateProps) => {
  return {
    campaignType: getCampaignType(draft),
    showPreviewTooltip: selectShowCampaignPreviewTooltip(state, currentStep),
  }
}

const mapDispatchToProps = {
  updateHasSeenPreviewTooltip,
}

export { BuilderTitleBarActionsButtonPreview }
export default compose(
  withDraft,
  withBuilderValidation,
  connect(mapStateToProps, mapDispatchToProps),
)(BuilderTitleBarActionsButtonPreview)
