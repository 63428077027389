import { createReducer, Draft } from '@reduxjs/toolkit'

import { BuilderDefault } from '@enums'
import { AuthActionTypes } from '@store/auth'
import { CampaignActionTypes } from '@store/campaigns'
import {
  deleteMoodboardMedia,
  deleteMoodboardMediaError,
  deleteMoodboardMediaSuccess,
  IFileError,
  IMoodboardItem,
  IMoodboardMediaState,
  IPreSignedUrlItem,
  uploadMoodboardInvalidFile,
  uploadMoodboardMedia,
  uploadMoodboardMediaError,
  uploadMoodboardMediaInProgress,
  uploadMoodboardMediaSuccess,
} from '@store/moodboard'

const initialState: IMoodboardMediaState = {
  mediaList: {},
}

const defaultMoodboardItem: IMoodboardItem = {
  isUploading: false,
  isDeleting: false,
  uploadFailed: false,
  deleteFailed: false,
  preSignedUrlItem: undefined,
  moodboard: undefined,
  file: undefined,
  errors: undefined,
}

export const moodboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CampaignActionTypes.FETCH_SUCCESS, (state, action: any) => {
      const { moodboard_images, id: campaignId } = action.payload

      state.mediaList[campaignId] = []

      if (moodboard_images && moodboard_images.length > 0) {
        moodboard_images.forEach((item) => {
          state.mediaList[campaignId][item.position] = {
            isUploading: false,
            uploadFailed: false,
            deleteFailed: false,
            moodboard: item,
            preSignedUrlItem: item,
          }
        })
      }

      if (BuilderDefault.MAX_ARRAY_LENGTH_MOODBOARD > state.mediaList[campaignId].length) {
        const fillerCount =
          BuilderDefault.MAX_ARRAY_LENGTH_MOODBOARD - state.mediaList[campaignId].length
        state.mediaList[campaignId].push(...Array(fillerCount))
        state.mediaList[campaignId].map((media) => {
          if (media) {
            return media
          }

          return {
            ...defaultMoodboardItem,
          }
        })
      }
    })
    .addCase(uploadMoodboardMedia, (state, { payload: { campaignId, positions } }) => {
      if (!state.mediaList[campaignId]) {
        state.mediaList[campaignId] = []
      }

      positions.forEach((position) => {
        state.mediaList[campaignId][position] = {
          ...state.mediaList[campaignId][position],
          isUploading: true,
          uploadFailed: false,
          errors: undefined,
        }
      })
    })
    .addCase(
      uploadMoodboardMediaInProgress,
      (state, { payload: { campaignId, preSignedUrlItem, file } }) => {
        const { position } = preSignedUrlItem

        if (!state.mediaList[campaignId][position]) {
          state.mediaList[campaignId][position] = {
            isUploading: true,
            uploadFailed: false,
            errors: undefined,
          }
        }

        state.mediaList[campaignId][position].preSignedUrlItem = preSignedUrlItem as Draft<
          IPreSignedUrlItem
        >
        state.mediaList[campaignId][position].file = file
      },
    )
    .addCase(uploadMoodboardMediaSuccess, (state, { payload: { campaignId, mediaId } }) => {
      const position = state.mediaList[campaignId].findIndex((media) => {
        if (media?.moodboard) {
          return media?.moodboard?.id === mediaId
        }

        return media?.preSignedUrlItem?.id === mediaId
      })

      if (typeof position === 'number') {
        state.mediaList[campaignId][position].isUploading = false
        state.mediaList[campaignId][position].uploadFailed = false
      }
    })
    .addCase(uploadMoodboardMediaError, (state, { payload: { campaignId, mediaId } }) => {
      const position = state.mediaList[campaignId].findIndex((media) => {
        if (media?.moodboard) {
          return media?.moodboard?.id === mediaId
        }

        return media?.preSignedUrlItem?.id === mediaId
      })

      if (typeof position !== 'number') {
        return
      }

      if (!state.mediaList[campaignId][position]) {
        state.mediaList[campaignId][position] = {
          ...(state.mediaList[campaignId][position] ?? []),
          isUploading: false,
          uploadFailed: false,
        }
        return
      }

      state.mediaList[campaignId][position].isUploading = false
      state.mediaList[campaignId][position].uploadFailed = true
    })
    .addCase(deleteMoodboardMedia, (state, { payload: { campaignId, position } }) => {
      state.mediaList[campaignId][position].deleteFailed = undefined
      state.mediaList[campaignId][position].isUploading = false
      state.mediaList[campaignId][position].isDeleting = true
    })
    .addCase(deleteMoodboardMediaSuccess, (state, { payload: { campaignId, mediaId } }) => {
      const mediaList = state.mediaList[campaignId].filter((media) => {
        if (media?.moodboard) {
          return media?.moodboard?.id !== mediaId
        }

        return media?.preSignedUrlItem?.id !== mediaId
      })

      const fillerCount = BuilderDefault.MAX_ARRAY_LENGTH_MOODBOARD - mediaList.length
      mediaList.push(...Array(fillerCount))

      state.mediaList[campaignId] = mediaList as Draft<IMoodboardItem[]>
    })
    .addCase(deleteMoodboardMediaError, (state, { payload: { campaignId, position } }) => {
      state.mediaList[campaignId][position].deleteFailed = true
      state.mediaList[campaignId][position].isDeleting = false
    })
    .addCase(uploadMoodboardInvalidFile, (state, { payload: { campaignId, position, errors } }) => {
      state.mediaList[campaignId][position] = {
        ...state.mediaList[campaignId][position],
        errors: errors as IFileError[],
      }
    })
    .addCase(AuthActionTypes.SIGNOUT_SUCCESS, (state) => {
      state.mediaList = {}
    })
})
