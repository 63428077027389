import styled, { css } from 'styled-components'

import Theme from '@theme'

export const transitionTime = '0.3s'
export const secondaryTransitionTime = '0.2s'

export const BuilderSubmitCampaignScheduleWrapper = styled.div`
  margin: auto;
  max-width: ${({ width }) => width}rem;
  width: 100%;
  text-align: center;
  padding-bottom: 3.5rem;
`

export const RelativeContainer = styled.div`
  position: relative;
`

export const SmallAnimatedBlock = styled.div`
  opacity: 0;
  transition: transform ${transitionTime}, opacity ${transitionTime};
  transform: translate(0, -3.125rem);
`

export const AnimatedTopBlock = styled.div`
  opacity: 0;
  height: 0;
  overflow: hidden;
  transform: translate(0, -6.25rem);
  transition: height ${secondaryTransitionTime} linear ${transitionTime}, opacity ${transitionTime},
    transform ${transitionTime};
  ${({ isVisible, height }) => css`
    ${isVisible &&
    css`
      opacity: 1;
      height: ${height}rem;
      overflow: visible;
      transform: none;
      ${SmallAnimatedBlock} {
        opacity: 1;
        transform: none;
      }
    `};
  `};
`

export const AnimatedBottomBlock = styled.div`
  opacity: 0;
  height: 0;
  overflow: hidden;
  transform: translate(0, 32rem);
  transition: all ${transitionTime} linear;
  ${({ isVisible, height }) => css`
    ${isVisible &&
    css`
      opacity: 1;
      height: ${height}rem;
      overflow: visible;
      transform: none;
      transition: height ${secondaryTransitionTime} linear ${transitionTime},
        transform ${secondaryTransitionTime} linear ${transitionTime},
        opacity ${secondaryTransitionTime} linear ${transitionTime};
    `};
  `};
`

export const FormBodyWrapper = styled.div`
  margin-top: 3rem;
`

export const FocusableDropdown = styled.div`
  cursor: pointer;
  outline: none;
  border-bottom: 1px solid ${Theme.grey700Color};

  &:focus {
    border-bottom: 1px solid ${Theme.primaryColor};

    svg {
      transform: rotate(180deg);
    }
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border-bottom: 1px solid ${Theme.errorColor};
    `};
`

export const ScheduleErrorWrapper = styled.div`
  text-align: left;
  margin-top: 0.5rem;
  ${({ hasLeftMargin }) =>
    hasLeftMargin &&
    css`
      margin-left: 3rem;
    `}
`
