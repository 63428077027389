import styled from 'styled-components'

import { Container } from '@tribegroup'

export const SliderWrapper = styled(Container)`
  .slick-list {
    margin: 0 -0.0625rem;

    & .slick-slide > div {
      padding: 0 0.25rem;
    }
  }
`
