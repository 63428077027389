import { EMPTY_LIST } from '@constants'
import { BillingTypes } from '@enums'
import { selectLink } from '@utils/selectLink'
import { IApplicationState } from '..'
import { IPurchase } from './types'

export const selectCampaignBillingList = (state: IApplicationState) => {
  const billingsByCampaignId = state.billings.billingsByCampaignId
  // tslint:disable-next-line
  return billingsByCampaignId ? Object.values(billingsByCampaignId).reverse() : EMPTY_LIST
}

export const selectContentLibraryBillingList = (state: IApplicationState) => {
  return state.billings.purchaseIdsByMonthInContentLibrary || {}
}

export const selectIsFetchingBilling = (state: IApplicationState) => state.billings.isFetching
export const selectBillingRequestErrors = (state: IApplicationState) => state.billings.errors
export const selectDownloadFullReportLink = (state: IApplicationState, campaignId: number) => {
  const billing =
    state.billings.billingsByCampaignId && state.billings.billingsByCampaignId[campaignId]
  const billingLinks = (billing && billing.links) || []
  return selectLink(billingLinks, 'download_purchase_history')
}

export const selectReceiptLink = (state: IApplicationState, purchaseId: number) => {
  const purchase = state.billings.purchasesById[purchaseId]
  const purchaseLinks = purchase.links || []
  return selectLink(purchaseLinks, 'receipt')
}

const getPurchasesByBillingMonths = (
  purchasesById: Record<number, IPurchase>,
  purchaseIdsPerMonth: Record<string, ReadonlyArray<number>>,
) => {
  if (!purchaseIdsPerMonth) {
    return {}
  }
  // tslint:disable-next-line
  const billingMonths = Object.keys(purchaseIdsPerMonth).sort().reverse()
  const purchasesByBillingMonths = billingMonths.reduce((accum, curr) => {
    const purchaseIds = purchaseIdsPerMonth[curr]
    const purchases = purchaseIds.map((id) => purchasesById[id])
    return {
      ...accum,
      [curr]: purchases,
    }
  }, {})

  return purchasesByBillingMonths
}

export const selectCampaignPurchaseListByMonth = (state: IApplicationState, campaignId: number) => {
  const purchaseIdsPerMonth = state.billings.purchaseIdsByMonthPerCampaignId[campaignId as number]
  return getPurchasesByBillingMonths(state.billings.purchasesById, purchaseIdsPerMonth)
}

export const selectContentLibraryPurchaseListByMonth = (state: IApplicationState) => {
  const purchaseIdsPerMonth = state.billings.purchaseIdsByMonthInContentLibrary
  return getPurchasesByBillingMonths(state.billings.purchasesById, purchaseIdsPerMonth)
}

export const selectPurchaseListByMonth = (
  state: IApplicationState,
  billingType: BillingTypes,
  campaignId?: number,
) => {
  return billingType === BillingTypes.Campaigns
    ? selectCampaignPurchaseListByMonth(state, campaignId as number)
    : selectContentLibraryPurchaseListByMonth(state)
}

const selectBillingHistoryNextLink = (state: IApplicationState, billingType: BillingTypes) => {
  const linkByBillingType = state.billings.linksByBillingType[billingType]
  return linkByBillingType && selectLink(linkByBillingType, 'next')
}

export const selectContentLibraryBillingHistoryNextLink = (state: IApplicationState) =>
  selectBillingHistoryNextLink(state, BillingTypes.ContentLibrary)

export const selectCampaignsBillingHistoryNextLink = (state: IApplicationState) =>
  selectBillingHistoryNextLink(state, BillingTypes.Campaigns)
