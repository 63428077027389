import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Grid } from 'react-styled-flexboxgrid'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import Loading from '@components/UI/Loading'
import { IApplicationState } from '@store'
import {
  fetchSubmissionPerformance,
  selectIsFetchingSubmissionPerformance,
} from '@store/performance'
import { selectSubmissionId } from '@store/router'
import Theme from '@theme'
import { H3, Icon } from '@tribegroup'
import { ProfileDetailsWrapper, ProfileWrapper } from '../Influencer/Profile/Profile.styled'
import SubmissionPerformanceDetails from './Details/Details'
import { PerformanceIconWrapper } from './Performance.styled'

export interface IInternalProps {
  isFetching?: boolean
  submissionId: number
  fetchSubmissionPerformance: typeof fetchSubmissionPerformance
}

export const SubmissionPerformance: React.FC<IInternalProps> = ({
  isFetching,
  submissionId,
  fetchSubmissionPerformance: fetchSubmissionPerformanceAction,
}) => {
  React.useEffect(() => {
    fetchSubmissionPerformanceAction(submissionId)
  }, [submissionId])

  if (isFetching) {
    return <Loading />
  }

  return (
    <ProfileWrapper>
      <Flex alignCenter justifyBetween>
        <H3 color={Theme.grey900Color}>
          <FormattedMessage id="submission.performance.title" />
        </H3>
      </Flex>
      <ProfileDetailsWrapper>
        <PerformanceIconWrapper alignCenter justifyCenter>
          <Icon glyph="data" size={2} color={Theme.primaryColor} />
        </PerformanceIconWrapper>
      </ProfileDetailsWrapper>
      <Grid fluid>
        <SubmissionPerformanceDetails />
      </Grid>
    </ProfileWrapper>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => ({
  isFetching: selectIsFetchingSubmissionPerformance(state),
  submissionId: selectSubmissionId(match) as number,
})

const mapDispatchToProps = {
  fetchSubmissionPerformance,
}

export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SubmissionPerformance)
