import { BudgetErrorMessageWrapper } from '@components/Builder/Layout/Layout.styled'
import Theme from '@theme'
import { Text } from '@tribegroup'

export const BudgetErrorMessage = ({ results, errorKey }) => {
  if (results && results[errorKey] && results[errorKey].hasError) {
    return (
      <BudgetErrorMessageWrapper>
        <Text small color={Theme.errorColor}>
          {results[errorKey].errorMessage}
        </Text>
      </BudgetErrorMessageWrapper>
    )
  }
  return null
}

export const hasValidationError = (results, errorKey) =>
  results[errorKey] && results[errorKey].hasError

export default BudgetErrorMessage
