import MoreMenu from './MoreMenu'

export default MoreMenu

export interface IMenuAction {
  /**
   * Actual text displayed for the menu item
   */
  text?: string

  /**
   * Highlight the text in red color (error scheme from theme)
   */
  highlight?: boolean

  /**
   * react-router-dom link to where the menu item anchor will link to
   */
  routeTo?: string

  /**
   * Absolute URL for the anchor href, overrides the routeTo props
   */
  absoluteLink?: string

  /**
   * callback function triggered upon click of the menu item
   */
  onClick?: VoidFunction
}
