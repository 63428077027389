import React from 'react'

import { ISubmission } from '@store/submissions'
import { getSubmissionFlags } from '@utils'
import {
  SubmissionThumbnailStatusLabelLicensed,
  SubmissionThumbnailStatusLabelLicenseExpired,
  SubmissionThumbnailStatusLabelMissed,
  SubmissionThumbnailStatusLabelPost,
  SubmissionThumbnailStatusLabelPostOverdue,
  SubmissionThumbnailStatusLabelPostWithinHours,
  SubmissionThumbnailStatusLabelPublished,
} from './Label'

interface IProps {
  submission: ISubmission
}

const SubmissionThumbnailStatus: React.FC<IProps> = ({ submission }) => {
  const {
    isPublished,
    isLicenseExpired,
    isLicensed,
    isPublishScheduleMissed,
    shouldSubmissionBePostedWithinHours,
    scheduledPublishDate,
  } = getSubmissionFlags(submission)

  if (isPublished) {
    if (isLicenseExpired) {
      return <SubmissionThumbnailStatusLabelLicenseExpired />
    }

    if (isLicensed) {
      return <SubmissionThumbnailStatusLabelLicensed />
    }

    return <SubmissionThumbnailStatusLabelPublished date={new Date(submission.published_at!)} />
  }

  if (shouldSubmissionBePostedWithinHours) {
    if (isPublishScheduleMissed) {
      return <SubmissionThumbnailStatusLabelPostOverdue />
    }
    return <SubmissionThumbnailStatusLabelPostWithinHours />
  }

  if (isPublishScheduleMissed) {
    return <SubmissionThumbnailStatusLabelMissed date={scheduledPublishDate as Date} />
  }

  return <SubmissionThumbnailStatusLabelPost date={scheduledPublishDate as Date} />
}

export default SubmissionThumbnailStatus
