import { PropsWithChildren } from 'react'

import termsAndConditions from '@downloadables/TRIBE Content Licence Agreement.pdf'
import Theme from '@theme'
import { Link, Text } from '@tribegroup'

interface IProps {
  color?: string
}

export const TermsLink: React.FC<PropsWithChildren<IProps>> = ({ children, color }) => (
  <Link href={termsAndConditions} target="_blank" color={color ?? Theme.secondaryColor}>
    <Text small>{children}</Text>
  </Link>
)

export default TermsLink
