import React from 'react'

import { FormattedMessage } from 'react-intl'

import { AnalyticsAudienceReportingChartsDoughnut } from '@components/Analytics/AudienceReporting/Charts'
import genderMetrics from '@images/audience-metrics/mock-gender.png'
import countriesMetrics from '@images/audience-metrics/mock-top-countries.png'
import { IAudienceInsights } from '@store/audienceInsights'
import Theme from '@theme'
import { H5 } from '@tribegroup'
import { Container } from '@tribegroup'
import { HorizontalBar } from '@tribegroup/Charts'
import { Chart, ChartsWrapper, PlaceholderImage } from './AudienceInsights.styled'

interface IProps {
  insights: IAudienceInsights
  isTikTok?: boolean
}

const ChartsAudienceInsights: React.FC<IProps> = ({ insights, isTikTok }) => {
  const gender = !isTikTok
    ? insights.gender
    : insights.gender?.map((insight) => ({ ...insight, value: insight.percentage }))

  const topCountries = !isTikTok
    ? insights.top_countries
    : [...(insights.top_countries || [])].sort((firstEl, secondEl) =>
        firstEl.percentage > secondEl.percentage ? -1 : 1,
      )

  const showGrayGenderChart = isTikTok && (!insights.gender || insights.gender.length === 0)
  const showGrayTopCountriesChart =
    isTikTok && (!insights.top_countries || insights.top_countries.length === 0)

  return (
    <ChartsWrapper>
      {insights.gender && insights.gender.length > 0 && (
        <Chart data-testid="audience-insights-gender">
          <Container bottomOuterSpacing={1}>
            <H5 color={Theme.grey900Color}>
              <FormattedMessage id="audienceInsights.gender" />
            </H5>
          </Container>
          <AnalyticsAudienceReportingChartsDoughnut data={gender} showAsPercentage />
        </Chart>
      )}

      {showGrayGenderChart && (
        <Container relative data-testid="locked-audience-insights-gender">
          <PlaceholderImage src={genderMetrics} />
        </Container>
      )}

      {insights.age_range && (
        <Chart data-testid="audience-insights-age-range">
          <Container bottomOuterSpacing={1}>
            <H5 color={Theme.grey900Color}>
              <FormattedMessage id="audienceInsights.age_range" />
            </H5>
          </Container>

          <HorizontalBar theme={Theme} data={insights.age_range} showAsPercentage roundPercentage />
        </Chart>
      )}

      {insights.top_countries && insights.top_countries.length > 0 && (
        <Chart data-testid="audience-insights-top-countries">
          <Container bottomOuterSpacing={1}>
            <H5 color={Theme.grey900Color}>
              {isTikTok ? (
                <FormattedMessage id="audienceInsights.top_territories" />
              ) : (
                <FormattedMessage id="audienceInsights.top_countries" />
              )}
            </H5>
          </Container>

          <HorizontalBar theme={Theme} data={topCountries} showAsPercentage />
        </Chart>
      )}

      {showGrayTopCountriesChart && (
        <Container relative data-testid="locked-audience-insights-top-countries">
          <PlaceholderImage src={countriesMetrics} />
        </Container>
      )}

      {insights.top_cities && (
        <Chart data-testid="audience-insights-top-cities">
          <Container bottomOuterSpacing={1}>
            <H5 color={Theme.grey900Color}>
              <FormattedMessage id="audienceInsights.top_cities" />
            </H5>
          </Container>

          <HorizontalBar theme={Theme} data={insights.top_cities} showAsPercentage />
        </Chart>
      )}
    </ChartsWrapper>
  )
}

export { ChartsAudienceInsights }
export default ChartsAudienceInsights
