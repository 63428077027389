import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  CampaignBillingActionTypes,
  fetchCampaignBillings,
  fetchCampaignBillingsError,
  fetchCampaignBillingsSuccess,
  selectCampaignsBillingHistoryNextLink,
} from '@store/billings'
import { selectCampaignsBillingHistoryLink } from '@store/me'
import { networkRequest } from '@utils'

export function* handleFetchCampaignBillings(action: ReturnType<typeof fetchCampaignBillings>) {
  try {
    const isFetchNext = action.payload
    const authToken: string = yield select(selectAuthToken)
    const billingHistoryLink: IHateoasLink = yield isFetchNext
      ? select(selectCampaignsBillingHistoryNextLink)
      : select(selectCampaignsBillingHistoryLink)
    const result = yield call(
      networkRequest,
      billingHistoryLink.method,
      billingHistoryLink.href,
      undefined,
      authToken,
    )

    yield put(fetchCampaignBillingsSuccess(result))
  } catch (error) {
    yield put(fetchCampaignBillingsError(error))
  }
}

export function* watchFetchCampaignBillingRequest() {
  yield takeLatest(CampaignBillingActionTypes.FETCH_REQUEST, handleFetchCampaignBillings)
}
