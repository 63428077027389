import { PureComponent } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import SubmissionThumbnailList from '@components/Submission/Thumbnail/List/List'
import { withSubmission } from '@hocs'
import { IApplicationState } from '@store'
import { selectCampaignId } from '@store/router'
import { ISubmission, selectInfluencerSubmissionIds } from '@store/submissions'
import { fetchInfluencerSubmissionsRequest } from '@store/submissions/actions/influencerSubmissions'
import Theme from '@theme'
import { H3 } from '@tribegroup'
import { Wrapper } from './Submissions.styled'

interface IInternalProps {
  fetchInfluencerSubmissionsRequest: typeof fetchInfluencerSubmissionsRequest
  shouldDisplayList?: boolean
  influencerSubmissionIds: ReadonlyArray<number>
  submission: ISubmission
  campaignId: number
}

export class InfluencerSubmissions extends PureComponent<IInternalProps> {
  componentDidMount() {
    const { influencer_id: influencerId, id: submissionId } = this.props.submission!
    this.props.fetchInfluencerSubmissionsRequest(submissionId, influencerId, this.props.campaignId)
  }

  componentDidUpdate(prevProps: IInternalProps) {
    const { influencer_id: influencerId, id: submissionId } = this.props.submission!
    if (prevProps.submission!.id !== submissionId) {
      this.props.fetchInfluencerSubmissionsRequest(
        submissionId,
        influencerId,
        this.props.campaignId,
      )
    }
  }

  render() {
    const { influencerSubmissionIds, shouldDisplayList, submission } = this.props

    if (!shouldDisplayList) {
      return null
    }

    return (
      <Wrapper>
        <H3 color={Theme.defaultColor}>
          <FormattedMessage
            id="submission.fullView.influencerSubmissions.header"
            values={{ firstName: submission!.influencer_first_name }}
          />
        </H3>
        <SubmissionThumbnailList
          influencerSubmissionIds={influencerSubmissionIds}
          fromSubmission={submission!}
        />
      </Wrapper>
    )
  }
}

const mapStateToProps = (
  state: IApplicationState,
  { match, submission }: IInternalProps & RouteComponentProps,
) => {
  const campaignId = selectCampaignId(match)!
  const influencerSubmissionIds = selectInfluencerSubmissionIds(
    state,
    submission.influencer_id,
    campaignId,
  )
  const otherSubmissionsExist = influencerSubmissionIds && influencerSubmissionIds.length > 1
  return {
    shouldDisplayList: otherSubmissionsExist,
    influencerSubmissionIds,
    campaignId,
  }
}

const mapDispatchToProps = {
  fetchInfluencerSubmissionsRequest,
}

export default compose<IInternalProps, {}>(
  withSubmission,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InfluencerSubmissions)
