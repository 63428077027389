import { FormattedMessage } from 'react-intl'

import Theme from '@theme'
import { MenuItem, Text } from '@tribegroup'

interface IProps {
  onClick: VoidFunction
}

const ConnectedAccountsListIdentityRowMenuRemove: React.FC<IProps> = ({ onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <Text color={Theme.errorColor}>
        <FormattedMessage id="core.text.remove" />
      </Text>
    </MenuItem>
  )
}

export default ConnectedAccountsListIdentityRowMenuRemove
