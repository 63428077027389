import { PureComponent } from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'

import { CampaignStatuses } from '@enums'
import { ICampaign } from '@store/campaigns'
import { Pill, Text } from '@tribegroup'
import { PillWrapperStyled } from './Pill.styled'

const getPillScheme = (status) => {
  const pillScheme = {
    [CampaignStatuses.InReview]: 'secondary',
    [CampaignStatuses.Active]: 'success',
    [CampaignStatuses.Paused]: 'alert',
    [CampaignStatuses.Scheduled]: 'alert',
    [CampaignStatuses.Expired]: 'error',
    [CampaignStatuses.Completed]: 'default',
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!pillScheme.hasOwnProperty(status)) {
    throw new Error(`Unknown status: ${status}`)
  }

  return pillScheme[status]
}

interface ICampaignCardPill {
  campaign: ICampaign
  show?: boolean
}

interface IInternalProps extends ICampaignCardPill, InjectedIntlProps {}

export class CampaignCardPill extends PureComponent<IInternalProps> {
  render() {
    const { campaign, show = true, intl } = this.props
    const { status } = campaign
    const isDraft = status === CampaignStatuses.Draft
    const pillStyle = isDraft ? { outlined: true } : { scheme: getPillScheme(status) }
    return (
      show && (
        <PillWrapperStyled data-cy-element="campaign-status">
          <Pill {...pillStyle} data-status={status}>
            <Text xsmall uppercase>
              {intl.formatMessage({ id: `core.text.${status}` })}
            </Text>
          </Pill>
        </PillWrapperStyled>
      )
    )
  }
}

export default injectIntl<ICampaignCardPill>(CampaignCardPill)
