import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import { ProfileContainer, ProfileNameContainer } from '../SideBar.styled'

interface IProps {
  firstName?: string
}

const SideBarProfileMenuTrigger = ({ firstName }: IProps) => (
  <ProfileContainer>
    <Flex data-cy-element="profile-icon" data-testid="profile-icon">
      <Container rightOuterSpacing={0.5} inherit>
        <Icon glyph="profile" size={1.5} color={Theme.grey900Color} key="sidebar-footer-icon" />
      </Container>
      <ProfileNameContainer>
        <Text color={Theme.defaultColor}>
          <Container textOverflowAsEllipsis noWrap width={8.125}>
            {firstName}
          </Container>
        </Text>
      </ProfileNameContainer>
    </Flex>
  </ProfileContainer>
)

export default SideBarProfileMenuTrigger
