import styled from 'styled-components'

import Box from '@components/UI/Box'
import Theme from '@theme'

export default styled(Box)`
  color: ${Theme.grey800Color};
  text-align: center;

  > div {
    margin: auto;

    > *:not(h1) {
      max-width: 19.375rem;
    }
  }

  h1 {
    margin: 1rem 0 0;
  }

  h3 {
    margin: 0.75rem 0 0;
  }

  a {
    font-size: 0.875rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: ${Theme.grey800Color};
    text-transform: uppercase;
  }

  button {
    padding: 0.9375rem 3rem;
    font-size: 0.9375rem;
    white-space: nowrap;
  }
`

export const MessageBoxStyled = styled.div`
  margin-top: 0.75rem;
`

export const CTAStyled = styled.div`
  margin-top: 1.75rem;
`

export const FooterCTAStyled = styled.div`
  margin-top: 3.125rem;

  a {
    text-decoration: none;
  }
`
