import { Tooltip } from 'react-tooltip'
import styled, { css } from 'styled-components'

import { themed } from '@tribegroup/Theme'

export default themed(styled(Tooltip)`
  ${({ theme }) => css`
    max-width: 16.625rem;
    background: ${theme.whiteColor};
    color: ${theme.grey900Color};
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.5rem 0 rgb(0 0 0 / 10%);
    font-size: 0.75rem;
    line-height: 1.17;
    letter-spacing: 0.3px;
    padding: 0.25rem 0.75rem;
    z-index: 999;
  `}
`)
