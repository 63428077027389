import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  InfluencerSubmissionsActionTypes,
  selectInfluencerSubmissionsLink,
} from '@store/submissions'
import {
  fetchInfluencerSubmissionsError,
  fetchInfluencerSubmissionsRequest,
  fetchInfluencerSubmissionsSuccess,
} from '@store/submissions/actions/influencerSubmissions'
import { networkRequest } from '@utils'

export function* handleFetchInfluencerSubmissionsRequest(
  action: ReturnType<typeof fetchInfluencerSubmissionsRequest>,
) {
  try {
    const { submissionId, influencerId, campaignId } = action.payload

    const authToken: string = yield select(selectAuthToken)

    const influencerSubmissionsLink: IHateoasLink = yield select(
      selectInfluencerSubmissionsLink,
      submissionId,
    )

    const result = yield call(
      networkRequest,
      influencerSubmissionsLink.method,
      influencerSubmissionsLink.href,
      undefined,
      authToken,
    )

    yield put(fetchInfluencerSubmissionsSuccess(influencerId, campaignId, result.items))
  } catch (error) {
    yield put(fetchInfluencerSubmissionsError(error))
  }
}

export function* watchFetchInfluencerSubmissionsRequest() {
  yield takeLatest(
    InfluencerSubmissionsActionTypes.FETCH_REQUEST,
    handleFetchInfluencerSubmissionsRequest,
  )
}
