import { compose } from 'recompose'

import Media from '@components/UI/Media/Media'
import { IMultiframeContextProps } from '@context/MultiframeControls'
import { AspectRatios } from '@enums'
import { withMultiframeControls } from '@hocs'
import { ISubmissionFrame } from '@store/submissions'
import Theme from '@theme'
import {
  MultiframeSliderTrack,
  MultiframeSliderWrapper,
  SlidePortraitWrapper,
  SlideWrapper,
} from './Slider.styled'

interface IMultiframeSliderProps {
  backgroundColor?: string
  portrait?: boolean
  aspectRatio?: AspectRatios
  showVideoControls?: boolean
  autoplayVideo?: boolean
  showPlayIcon?: boolean
}

interface IInternalProps extends IMultiframeSliderProps, IMultiframeContextProps {}

export const MultiframeSlider = ({
  frames,
  activeFrameNumber,
  animationEnabled = false,
  backgroundColor = Theme.whiteColor,
  portrait,
  aspectRatio,
  showVideoControls,
  showPlayIcon,
  autoplayVideo,
}: IInternalProps) => {
  const activeIndex = activeFrameNumber - 1
  const Wrapper = portrait ? SlidePortraitWrapper : SlideWrapper
  return (
    <MultiframeSliderWrapper>
      <MultiframeSliderTrack
        active={activeIndex}
        animationEnabled={animationEnabled}
        count={frames.length}
      >
        {frames.map((frame: ISubmissionFrame, index: number) => (
          <Wrapper
            center
            active={index === activeIndex}
            key={`${frame.id}${index}`}
            className={index === activeIndex ? 'active' : null}
          >
            <Media
              mediaId={frame.frame_number}
              src={frame.thumbnail_media_url}
              mediaType={frame.media_type}
              backgroundColor={backgroundColor}
              fullSizeSrc={frame.original_media_url}
              aspectRatio={portrait ? undefined : aspectRatio}
              controls={showVideoControls}
              showPlayIcon={showPlayIcon}
              containImage
              autoPlay={autoplayVideo}
            />
          </Wrapper>
        ))}
      </MultiframeSliderTrack>
    </MultiframeSliderWrapper>
  )
}

export default compose<IInternalProps, IMultiframeSliderProps>(withMultiframeControls)(
  MultiframeSlider,
)
