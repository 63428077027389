import styled from 'styled-components'

export const SubmissionThumbnailListWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 0.75rem;
  margin-top: 1rem;
  grid-template-columns: repeat(auto-fit, 5.875rem);
`

export const Wrapper = styled.div`
  padding-top: 1.75rem;
`
