import React from 'react'

import Theme from '@theme'
import { Container, H4 } from '@tribegroup'

const BuilderBudgetTargetPanelTitle: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Container>
    <H4 color={Theme.defaultColor}>{children}</H4>
  </Container>
)

export default BuilderBudgetTargetPanelTitle
