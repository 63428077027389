import {
  IPrePurchaseAnalytics,
  PrePurchaseAnalyticsActionTypes,
  PrePurchaseAnalyticsType,
} from './types'

export const fetchPrePurchaseAnalytics = (
  identityId: number,
  submissionId: number,
  prePurchaseAnalyticsType: PrePurchaseAnalyticsType,
) => ({
  type: PrePurchaseAnalyticsActionTypes.FETCH_REQUEST,
  payload: {
    submissionId,
    identityId,
    prePurchaseAnalyticsType,
  },
})

export const fetchPrePurchaseAnalyticsSuccess = (
  identityId: number,
  prePurchaseAnalyticsType: PrePurchaseAnalyticsType,
  prePurchaseAnalytics: IPrePurchaseAnalytics,
) => ({
  type: PrePurchaseAnalyticsActionTypes.FETCH_SUCCESS,
  payload: {
    identityId,
    prePurchaseAnalyticsType,
    prePurchaseAnalytics,
  },
})

export const fetchPrePurchaseAnalyticsError = (error: any) => ({
  type: PrePurchaseAnalyticsActionTypes.FETCH_ERROR,
  payload: error,
})
