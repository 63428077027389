import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Flex, { FlexItem } from 'styled-flex-component'

import { defaultTheme } from '@tribegroup/Theme'
import {
  FadeInWithVisibility,
  FadeOutWithVisibility,
  SlideInFromLeft,
  SlideInFromRight,
  SlideOutToLeft,
  SlideOutToRight,
} from '../Effects'

interface IDrawerStyledProps {
  anchor: 'right' | 'left'
  open: boolean
}

interface IDrawerWrapperStyledProps {
  open: boolean
  zIndex: number
}

interface IDrawerHeaderContentProps {
  left: boolean
  right: boolean
}

export const DRAWER_ANIMATION_DURATION_MS = 500

export const DrawerHeader = styled('div')`
  position: sticky;
  top: 0;
  background: ${defaultTheme.whiteColor};
  width: inherit;
  z-index: 2;
`

export const DrawerHeaderWrapper = styled(Flex)`
  height: inherit;
  min-height: 3.75rem;
`

export const DrawerHeaderContent = styled<IDrawerHeaderContentProps>(FlexItem)`
  margin: 0 1.375rem;
  ${({ left }) =>
    left &&
    css`
      margin-right: 0;
    `};
  ${({ right }) =>
    right &&
    css`
      margin-left: 0;
    `};
`

export const DrawerHeaderDivider = styled('div')`
  margin-left: 1.625rem;
  margin-right: 1.625rem;
  border-top: solid 1px ${defaultTheme.grey400Color};
  border-bottom: 0;
`

export const DrawerContent = styled('section')`
  position: inline-block;
  width: inherit;
  background: ${defaultTheme.whiteColor};
`

export const DrawerStyled = styled<IDrawerStyledProps>('aside')`
  position: absolute;
  top: 0;
  ${({ anchor }) => (anchor === 'left' ? `left: 0` : `right: 0`)};
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background: ${defaultTheme.whiteColor};
  ${({ anchor, open }) =>
    anchor === 'left'
      ? css`
          animation: ${open ? SlideInFromLeft : SlideOutToLeft} ${DRAWER_ANIMATION_DURATION_MS}ms
            ease-in-out forwards;
        `
      : css`
          animation: ${open ? SlideInFromRight : SlideOutToRight} ${DRAWER_ANIMATION_DURATION_MS}ms
            ease-in-out forwards;
        `};
  ${breakpoint('phoneUpperBoundary')`
    width: 20.75rem;
  `};
`

export const DrawerGrayWrapper = styled<IDrawerStyledProps>('div')`
  position: absolute;
  left: 0;
  top: 0;
  width: inherit;
  height: inherit;
  background-color: rgb(0 0 0 / 50%);
  ${({ open }) =>
    css`
      animation: ${open ? FadeInWithVisibility : FadeOutWithVisibility}
        ${DRAWER_ANIMATION_DURATION_MS}ms ease-in forwards;
    `};
`

export const DrawerWrapperStyled = styled<IDrawerWrapperStyledProps>('div')`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  ${({ zIndex }) => `z-index: ${zIndex}`};
  ${breakpoint('phoneUpperBoundary')`
    ${({ anchor, desktopXOffset }) =>
      anchor === 'left'
        ? css`
            margin-left: ${desktopXOffset}rem;
          `
        : css`
            margin-right: ${desktopXOffset}rem;
          `};
    ${({ open }) =>
      css`
        animation: ${open ? FadeInWithVisibility : FadeOutWithVisibility}
          ${DRAWER_ANIMATION_DURATION_MS}ms ease-in forwards;
      `};
    `};
`
