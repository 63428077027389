import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import Avatar from '@components/UI/Avatar'
import Image from '@components/UI/Image'
import { AspectRatios } from '@enums'
import tiktokOverlay from '@images/tiktok-overlay.png'
import { IApplicationState } from '@store'
import {
  selectIdentityAvatarSrc,
  selectIdentityUsername,
  selectIsStorySocialSubmission,
  selectIsSubmissionFromTiktok,
  selectMediaThumbnail,
} from '@store/submissions'
import Theme from '@theme'
import { Container } from '@tribegroup'
import { EmptyLine, PreviewContainer, SponsoredText } from './Preview.styled'

interface IProps {
  submissionId: number
}

interface IInternalProps extends IProps {
  thumbnailUrl: string
  avatarSrc: string
  identityUsername: string
  isStory: boolean
  isTikTok?: boolean
}

const BrandedContentAdPreview: React.FC<IInternalProps> = ({
  avatarSrc,
  identityUsername,
  thumbnailUrl,
  isStory,
  isTikTok,
}) => {
  if (isTikTok) {
    return (
      <Container
        width={9.375}
        height={15.625}
        textAlign="center"
        relative
        data-testid="tiktok-bca-preview"
      >
        <Container
          fullWidth
          fullHeight
          relative
          borderRadius={0.5}
          boxShadow="0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.1)"
        >
          <Image src={thumbnailUrl} containerHeight={15.625} borderRadius={0.5} />
        </Container>
        <Container fullWidth fullHeight absolute topPosition={0} leftPosition={0}>
          <Image src={tiktokOverlay} containerHeight={15.625} />
        </Container>
        <Container width={1.75} absolute rightPosition={0.4375} topPosition={8}>
          <Avatar
            size={1.75}
            avatarSrc={avatarSrc}
            borderColor={Theme.whiteColor}
            borderWidth={0.0625}
          />
        </Container>
      </Container>
    )
  }

  return (
    <PreviewContainer width={9.375} textAlign="center" relative>
      <Container
        topInnerSpacing={0.625}
        bottomInnerSpacing={0.625}
        rightInnerSpacing={0.875}
        leftInnerSpacing={0.875}
      >
        <Flex>
          <Container>
            <Avatar
              size={1.75}
              avatarSrc={avatarSrc}
              username={identityUsername}
              isStory={isStory}
            />
          </Container>
          <Container leftOuterSpacing={0.6875}>
            <Flex column>
              <EmptyLine width={5} height={0.375} topOuterSpacing={0.2} />
              <Container textAlign="left">
                <SponsoredText color={Theme.grey800Color}>
                  <FormattedMessage id="core.text.sponsored" />
                </SponsoredText>
              </Container>
            </Flex>
          </Container>
        </Flex>
      </Container>
      <Container width={9.375}>
        <Image src={thumbnailUrl} aspectRatio={AspectRatios.FIVE_BY_FOUR} />
      </Container>
      <Container
        topInnerSpacing={0.875}
        bottomInnerSpacing={0.875}
        rightInnerSpacing={0.875}
        leftInnerSpacing={0.875}
      >
        <EmptyLine width={7.625} height={0.375} topOuterSpacing={0} />
        <EmptyLine width={5.125} height={0.375} topOuterSpacing={0.5} />
      </Container>
    </PreviewContainer>
  )
}

const mapStateToProps = (state: IApplicationState, { submissionId }: IProps) => ({
  thumbnailUrl: selectMediaThumbnail(state, submissionId),
  avatarSrc: selectIdentityAvatarSrc(state, submissionId),
  identityUsername: selectIdentityUsername(state, submissionId),
  isStory: selectIsStorySocialSubmission(state, submissionId),
  isTikTok: selectIsSubmissionFromTiktok(state, submissionId),
})

export { BrandedContentAdPreview }
export default compose<IInternalProps, IProps>(connect(mapStateToProps))(BrandedContentAdPreview)
