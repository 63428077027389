import { call, put, select, takeLatest } from 'redux-saga/effects'

import { selectAuthToken } from '@store/auth'
import {
  fetchVideoMetrics,
  fetchVideoMetricsError,
  fetchVideoMetricsSuccess,
  IVideoMetricsResponse,
  selectVideoMetricsLink,
  VideoMetricsActionTypes,
} from '@store/postCampaignReport'
import { networkRequest } from '@utils'
import { WatcherFunction } from '..'

export function* handleFetchVideoMetrics(action: ReturnType<typeof fetchVideoMetrics>) {
  const { campaignId, channel } = action.payload

  try {
    const videoMetricsLink = yield select(selectVideoMetricsLink, campaignId, channel)

    const authToken: string = yield select(selectAuthToken)
    const videoMetrics: IVideoMetricsResponse = yield call(
      networkRequest,
      videoMetricsLink.method,
      videoMetricsLink.href,
      undefined,
      authToken,
    )

    yield put(fetchVideoMetricsSuccess(videoMetrics, campaignId, channel))
  } catch (error) {
    yield put(fetchVideoMetricsError(error))
  }
}

function* watchFetchVideoMetrics() {
  yield takeLatest(VideoMetricsActionTypes.FETCH_REQUEST, handleFetchVideoMetrics)
}

export const videoMetricsSagas: ReadonlyArray<WatcherFunction> = [watchFetchVideoMetrics]
