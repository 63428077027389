import { CreateSetupIntentActionTypes } from '@store/me'

export const createSetupIntent = () => ({
  type: CreateSetupIntentActionTypes.CREATE_REQUEST,
})

export const createSetupIntentSuccess = (clientSecret: string) => ({
  payload: clientSecret,
  type: CreateSetupIntentActionTypes.CREATE_SUCCESS,
})

export const createSetupIntentError = (error: any) => ({
  type: CreateSetupIntentActionTypes.CREATE_ERROR,
  payload: error,
})
