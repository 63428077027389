import { StripeElementStyle } from '@stripe/stripe-js'

import Theme from '@theme'

// https://docs.stripe.com/stripe-js/react#customization-and-styling
export const stipeStyles: StripeElementStyle = {
  base: {
    fontSize: '14px',
    color: Theme.grey900Color,
    fontFamily: 'Source Code Pro, monospace',
    '::placeholder': {
      color: Theme.grey700Color,
    },
  },
  invalid: {
    color: Theme.errorColor,
  },
}
