import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ActionIcon from '@components/UI/ActionIcon'
import { IApplicationState } from '@store'
import { IMe, selectMeInfo, selectUpdateContactLink } from '@store/me'
import Theme from '@theme'
import { H5, Panel, Text } from '@tribegroup'
import { Label, List, ListItem } from './../Account.styled'

interface IAccountContactProps {
  disabled?: boolean
}

interface IInternalProps extends IAccountContactProps {
  me: IMe
  hasUpdateContactLink: boolean
}

export const AccountContact = ({ me, hasUpdateContactLink, disabled }: IInternalProps) => {
  return (
    <Panel
      disabled={disabled}
      actionIcons={
        hasUpdateContactLink
          ? [
              <Link key="edit-account" to="/account/contact" data-cy-element="edit-contact-details">
                <ActionIcon glyph="edit" />
              </Link>,
            ]
          : undefined
      }
      title={
        <H5 color={Theme.grey900Color}>
          <FormattedMessage id="account.contact.title" />
        </H5>
      }
    >
      <List>
        <ListItem>
          <Label>
            <Text xsmall uppercase color={Theme.grey800Color}>
              <FormattedMessage id="account.contact.firstName" />
            </Text>
          </Label>
          <Text color={Theme.grey900Color}>{me.first_name}</Text>
        </ListItem>
        <ListItem>
          <Label>
            <Text xsmall uppercase color={Theme.grey800Color}>
              <FormattedMessage id="account.contact.lastName" />
            </Text>
          </Label>
          <Text color={Theme.grey900Color}>{me.last_name}</Text>
        </ListItem>
        <ListItem>
          <Label>
            <Text xsmall uppercase color={Theme.grey800Color}>
              <FormattedMessage id="core.text.email" />
            </Text>
          </Label>
          <Text color={Theme.grey900Color}>{me.email}</Text>
        </ListItem>
        <ListItem>
          <Label>
            <Text xsmall uppercase color={Theme.grey800Color}>
              <FormattedMessage id="account.contact.phoneNumber" />
            </Text>
          </Label>
          <Text color={Theme.grey900Color}>{me.phone_number}</Text>
        </ListItem>
      </List>
    </Panel>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  hasUpdateContactLink: Boolean(selectUpdateContactLink(state)),
  me: selectMeInfo(state),
})

export default connect(mapStateToProps)(AccountContact)
