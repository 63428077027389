import { Fragment } from 'react'

import MediaObjectCostBreakdownReceipt from '@components/MediaObject/CostBreakdown/Receipt'
import { HoverToggleParent } from '@components/UI/HoverToggle/HoverToggle.styled'
import Theme from '@theme'
import { Container } from '@tribegroup'
import SubmissionCostBreakdownSocialBalanceDue from './BalanceDue'
import SubmissionCostBreakdownSocialBalancePaid from './BalancePaid'
import SubmissionCostBreakdownSocialBasePrices from './BasePrices'
import SubmissionCostBreakdownSocialTotalPrice from './TotalPrice'
import { useSocialCostBreakdown } from './useSocialCostBreakdown'

interface IProps {
  campaignId: number
  submissionId: number
  showReceipt?: boolean
}

const SubmissionCostBreakdownSocial: React.FC<IProps> = ({
  campaignId,
  submissionId,
  showReceipt,
}) => {
  const { submission, isPreApprovedOrDeclinedPreapproval, receiptNumber } = useSocialCostBreakdown(
    campaignId,
    submissionId,
  )

  return (
    <Fragment>
      <HoverToggleParent data-testid="submission-cost-breakdown-social">
        <Container data-testid="cost-breakdown-social-base">
          <SubmissionCostBreakdownSocialBasePrices submission={submission} />
          <SubmissionCostBreakdownSocialTotalPrice submission={submission} />
        </Container>
        {isPreApprovedOrDeclinedPreapproval && (
          <Fragment>
            <Container
              topOuterSpacing={0.75}
              topInnerSpacing={0.75}
              borderWidthTop={0.0625}
              borderColor={Theme.grey400Color}
            >
              <SubmissionCostBreakdownSocialBalancePaid submission={submission} />
            </Container>
            <Container
              topOuterSpacing={0.75}
              topInnerSpacing={0.75}
              borderWidthTop={0.0625}
              borderColor={Theme.primaryColor}
            >
              <SubmissionCostBreakdownSocialBalanceDue submission={submission} />
            </Container>
          </Fragment>
        )}

        {showReceipt && receiptNumber && (
          <MediaObjectCostBreakdownReceipt receiptNumber={receiptNumber} />
        )}
      </HoverToggleParent>
    </Fragment>
  )
}

export default SubmissionCostBreakdownSocial
