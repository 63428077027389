import { SubscriptionTypes } from '@enums'
import { IResponseError } from '@lib/error'
import { IFeatureHateoasLink, IHateoasLink } from '@store'
import { ILicenseOption } from '@store/campaigns'

export interface IBrandFansLink extends IFeatureHateoasLink {
  subscription: boolean
  brand_filter: ReadonlyArray<string>
}

export enum MeActionTypes {
  FETCH_REQUEST = 'me/FETCH_REQUEST',
  FETCH_SUCCESS = 'me/FETCH_SUCCESS',
  FETCH_ERROR = 'me/FETCH_ERROR',
}

export enum AutoSwitchActionTypes {
  AUTOSWITCH_REQUEST = 'me/AUTOSWITCH_REQUEST',
  AUTOSWITCH_SUCCESS = 'me/AUTOSWITCH_SUCCESS',
  AUTOSWITCH_ERROR = 'me/AUTOSWITCH_ERROR',
}

export enum UpdateContactActionTypes {
  UPDATE_CONTACT_REQUEST = 'me/UPDATE_CONTACT_REQUEST',
  UPDATE_CONTACT_ERROR = 'me/UPDATE_CONTACT_ERROR',
}

export enum UpdatePreferencesActionTypes {
  UPDATE_PREFERENCES_REQUEST = 'me/UPDATE_PREFERENCES_REQUEST',
  UPDATE_PREFERENCES_ERROR = 'me/UPDATE_PREFERENCES_ERROR',
}

export enum ChangePasswordActionTypes {
  CHANGE_PASSWORD_REQUEST = 'me/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = 'me/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR = 'me/CHANGE_PASSWORD_ERROR',
}

export enum UpdateCreditCardActionTypes {
  UPDATE_CREDITCARD_REQUEST = 'me/UPDATE_CREDITCARD_REQUEST',
  UPDATE_CREDITCARD_ERROR = 'me/UPDATE_CREDITCARD_ERROR',
}

export enum CreateSetupIntentActionTypes {
  CREATE_REQUEST = 'setupIntent/CREATE_REQUEST',
  CREATE_SUCCESS = 'setupIntent/CREATE_SUCCESS',
  CREATE_ERROR = 'setupIntent/CREATE_ERROR',
}

export enum SetupCardActionTypes {
  SETUP_REQUEST = 'card/SETUP_REQUEST',
  SETUP_SUCCESS = 'card/SETUP_SUCCESS',
  SETUP_ERROR = 'card/SETUP_ERROR',
}

export interface ICreditCardDetails {
  cvc: number
  exp_month: number
  exp_year: number
  number: string
}

export interface IInvoiceDetails {
  billing_contact_name?: string
  accounts_payable_email?: string
  po_number?: string
}

export interface ICreditCardData {
  last_four_digits: string
  expiry_month: number
  expiry_year: number
  brief_count?: number
}

export interface ICreditCard {
  readonly credit_card_any?: boolean
  readonly credit_card_data?: ICreditCardData
}

export interface IPassword {
  readonly current_password: string
  readonly new_password: string
}

export interface IPreferences {
  disable_brief_creation_prompt?: boolean
}
export interface ISubscriptionTier {
  identifier: SubscriptionTypes
  display_name: string
  free_tier: boolean
  activation_fee_cents: number
  activation_fee_currency: string
  content_licensing_pricings: ReadonlyArray<ILicenseOption>
  features: ReadonlyArray<string>
}

export type BrandManagerCapability = 'traditional_campaign_creation' | 'solution_campaign_creation'

export interface IMe extends ICompany, ICreditCard, IAccountCredit {
  readonly id: number
  readonly links: ReadonlyArray<IHateoasLink>
  readonly subscription_tier: ISubscriptionTier
  readonly track_events?: boolean
  readonly stripe_api_key?: string
  readonly first_name: string
  readonly last_name: string
  readonly email: string
  readonly phone_number?: string
  readonly preferences?: IPreferences
  readonly referral_program_token?: string
  readonly tenant_identifier?: string
  readonly capabilities?: BrandManagerCapability[]
  readonly intercom_user_hash: string
}

export interface IReferrerPayout {
  readonly referrer_id?: number
  readonly referral_amount_cents?: number
  readonly referral_amount_currency?: string
}

export interface IVerifiedMe extends IMe, IReferrerPayout {}

export interface ICompany {
  readonly company_any: boolean
  readonly company_name: string
  readonly company_accounts_payable_email: string
  readonly company_billing_contact?: string
  readonly company_address_street?: string
  readonly company_address_state?: string
  readonly company_address_state_code?: string
  readonly company_address_city?: string
  readonly company_address_postcode?: string
  readonly company_address_country: string
  readonly company_operating_region: string
}

export interface ISetupIntent {
  clientSecret?: string
}

export interface IAccountCredit {
  credit_amount_cents?: number
  credit_amount_currency?: string
  pending_credit_request_any?: boolean
}

export interface IMeState extends ISetupIntent {
  readonly info: IMe
  readonly isFetching: boolean
  readonly isFetched: boolean
  readonly isSwitching: boolean
  readonly isCreatingIntent: boolean
  readonly isSettingUpCard: boolean
  readonly isUpdating: boolean
  readonly errors?: IResponseError
}
