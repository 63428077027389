import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import Price from '@components/UI/Price/Price'
import { IApplicationState } from '@store'
import { selectEstimatedCPE, selectEstimatedCPECurrency } from '@store/submissions'
import Theme from '@theme'
import { H5 } from '@tribegroup'

interface ISubHeaderLabelEstimatedCPEProps {
  submissionId: number
}

interface IInternalProps extends ISubHeaderLabelEstimatedCPEProps {
  currency: string
  estimatedCpe: number
}

export const SubHeaderLabelEstimatedCPE: React.FC<IInternalProps> = ({
  estimatedCpe,
  currency,
}) => {
  return (
    <H5 color={Theme.grey700Color} uppercase inline light>
      {Boolean(estimatedCpe) && (
        <React.Fragment>
          <FormattedMessage id="submission.performance.estCpe" />
          &nbsp;
          <Price quantity={estimatedCpe} currency={currency} />
        </React.Fragment>
      )}
    </H5>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { submissionId }: ISubHeaderLabelEstimatedCPEProps,
) => ({
  estimatedCpe: selectEstimatedCPE(state, submissionId),
  currency: selectEstimatedCPECurrency(state, submissionId),
})

export default compose<IInternalProps, ISubHeaderLabelEstimatedCPEProps>(connect(mapStateToProps))(
  SubHeaderLabelEstimatedCPE,
)
