import { useSelector } from 'react-redux'

import {
  MediaObjectCostBreakdownLicense,
  MediaObjectCostBreakdownLicenseOptions,
} from '@components/MediaObject/CostBreakdown'
import SubmissionCostBreakdownMessage from '@components/Submission/CostBreakdown/Message'
import SubmissionCostBreakdownSocial from '@components/Submission/CostBreakdown/Social'
import { useSocialCostBreakdown } from '@components/Submission/CostBreakdown/Social/useSocialCostBreakdown'
import Popover from '@components/UI/Popover/Popover'
import { IApplicationState } from '@store'
import { selectCampaignLicenseCardByMediaType, selectIsSocialCampaign } from '@store/campaigns'
import {
  selectMediaType,
  selectShouldShowLicenseOptionsPrice,
  selectShouldShowLicensePrice,
  selectTransactedLicense,
} from '@store/mediaObjects'
import { selectSubmission } from '@store/submissions'
import SubmissionCardPriceSummaryHeader from './Header'

interface IProps {
  campaignId: number
  mediaObjectId: number
  submissionId: number
  onHide: VoidFunction
}

const SubmissionCardPriceSummary: React.FC<IProps> = ({
  onHide,
  campaignId,
  submissionId,
  mediaObjectId,
}) => {
  const { hasCostBreakdownMessage } = useSocialCostBreakdown(campaignId, submissionId)

  const {
    showLicenseRelatedPrice,
    showLicenseOptionsPrice,
    license,
    licenseCard,
    submission,
  } = useSelector((state: IApplicationState) => {
    const isSocial = selectIsSocialCampaign(state, campaignId)

    const submission = selectSubmission(state, submissionId)
    const mediaObjectMediaType = selectMediaType(state, mediaObjectId)

    const mediaType = submission?.final_media_type ?? mediaObjectMediaType

    return {
      isSocial,
      submission,
      license: selectTransactedLicense(state, mediaObjectId),
      licenseCard: selectCampaignLicenseCardByMediaType(state, campaignId, mediaType),
      showLicenseRelatedPrice: selectShouldShowLicensePrice(state, mediaObjectId, isSocial),
      showLicenseOptionsPrice: selectShouldShowLicenseOptionsPrice(state, mediaObjectId, isSocial),
    }
  })

  return (
    <Popover hide={onHide}>
      <SubmissionCardPriceSummaryHeader />
      {showLicenseRelatedPrice ? (
        <MediaObjectCostBreakdownLicense license={license} submission={submission} />
      ) : showLicenseOptionsPrice ? (
        <MediaObjectCostBreakdownLicenseOptions licenses={licenseCard} />
      ) : (
        <SubmissionCostBreakdownSocial
          showReceipt
          campaignId={campaignId}
          submissionId={submissionId}
        />
      )}
      {hasCostBreakdownMessage && <SubmissionCostBreakdownMessage submissionId={submissionId} />}
    </Popover>
  )
}

export default SubmissionCardPriceSummary
