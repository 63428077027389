import { call, put, select, takeLatest } from 'redux-saga/effects'

import { selectAuthToken } from '@store/auth'
import { selectPostCampaignReportLink } from '@store/campaigns'
import {
  CampaignMetricsActionTypes,
  fetchCampaignMetrics,
  fetchCampaignMetricsError,
  fetchCampaignMetricsSuccess,
  ICampaignMetrics,
} from '@store/postCampaignReport'
import { networkRequest } from '@utils'
import { WatcherFunction } from '..'

export function* handleFetchCampaignMetrics(action: ReturnType<typeof fetchCampaignMetrics>) {
  const campaignId = action.payload
  try {
    const campaignMetricsLink = yield select(selectPostCampaignReportLink, campaignId)
    const authToken: string = yield select(selectAuthToken)

    const campaignMetrics: ICampaignMetrics = yield call(
      networkRequest,
      campaignMetricsLink.method,
      campaignMetricsLink.href,
      undefined,
      authToken,
    )

    yield put(fetchCampaignMetricsSuccess(campaignMetrics))
  } catch (error) {
    yield put(fetchCampaignMetricsError(error))
  }
}

function* watchFetchCampaignMetrics() {
  yield takeLatest(CampaignMetricsActionTypes.FETCH_REQUEST, handleFetchCampaignMetrics)
}

export const campaignMetricsSagas: ReadonlyArray<WatcherFunction> = [watchFetchCampaignMetrics]
