import { useEffect } from 'react'

import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import PaymentElements from '@components/Payment/Elements'
import { IApplicationState } from '@store'
import { createSetupIntent, selectMeErrorCode, selectMeErrorMessage } from '@store/me'

interface ICreditCardElementsProps {
  onPaymentChange: (props: Record<string, unknown>) => void
  errorMessage?: string
  errorCode?: string
}

interface IInternalProps extends ICreditCardElementsProps {
  createSetupIntent: typeof createSetupIntent
}

export const CreditCardElements: React.FC<IInternalProps> = (props) => {
  const stripe = useStripe()
  const elements = useElements()
  const hasError = props.errorCode || props.errorMessage

  useEffect(() => {
    props.createSetupIntent()
  }, [])

  useEffect(() => {
    if (stripe) {
      const cardNumberElement = elements?.getElement(CardNumberElement)
      props.onPaymentChange({ stripe, cardNumberElement })
    }
  }, [stripe])

  return (
    <PaymentElements
      autoFocus={false}
      errorMessage={
        hasError && (
          <FormattedMessage id={props.errorCode || ''} defaultMessage={props.errorMessage} />
        )
      }
    />
  )
}

const mapDispatchToProps = {
  createSetupIntent,
}

const mapStateToProps = (state: IApplicationState) => ({
  errorMessage: selectMeErrorMessage(state),
  errorCode: selectMeErrorCode(state),
})

export default compose<IInternalProps, ICreditCardElementsProps>(
  connect(mapStateToProps, mapDispatchToProps),
)(CreditCardElements)
