import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Flex from 'styled-flex-component'

import { ConnectedAccountsDrawer } from '@components/ConnectedAccounts/Drawer/Drawer'
import { FooterButtonWrapper } from '@components/UI/Confirmation/Confirmation.styled'
import { useDrawer } from '@hooks/useDrawer'
import { useModal } from '@hooks/useModal'
import { IApplicationState } from '@store'
import { selectCurrentView } from '@store/router'
import Theme from '@theme'
import { Button, Container, H3, Modal, Text } from '@tribegroup'

export const BrandedContentAdConfirmationConnectAccount = () => {
  const { isOpen, hideModal } = useModal()

  const { showDrawer } = useDrawer()
  const { currentView } = useSelector((state: IApplicationState) => {
    return {
      currentView: selectCurrentView(state),
    }
  })

  const onclick = () => {
    hideModal()

    showDrawer(
      <ConnectedAccountsDrawer />,
      {
        desktopXOffset: currentView !== 'submission_view' ? 13.875 : undefined,
        zIndex: 2,
        anchor: 'left',
      },
      undefined,
      ['desktop', 'tabletLandscape', 'tabletPortrait'],
    )
  }

  return (
    <Modal reactModalProps={{ isOpen: Boolean(isOpen), shouldFocusAfterRender: false }} width={20}>
      <Container bottomOuterSpacing={0.5}>
        <H3 color={Theme.grey900Color}>
          <FormattedMessage id="connectedAccounts.boost.confirmation.connectAccount.title" />
        </H3>
      </Container>

      <Container bottomInnerSpacing={3.625} width={15.75}>
        <Text color={Theme.grey800Color}>
          <FormattedMessage id="connectedAccounts.boost.confirmation.connectAccount.paragraph" />
          <Container topInnerSpacing={0.5}>
            <Text color={Theme.grey800Color}>
              <FormattedMessage id="connectedAccounts.boost.confirmation.collaborator" />
            </Text>
          </Container>
        </Text>
      </Container>

      <Flex justifyEnd>
        <FooterButtonWrapper>
          <Button scheme="primary" quiet onClick={hideModal} small>
            <FormattedMessage id="core.text.cancel" />
          </Button>
        </FooterButtonWrapper>
        <FooterButtonWrapper>
          <Button scheme="primary" onClick={onclick} small>
            <FormattedMessage id="core.text.continue" />
          </Button>
        </FooterButtonWrapper>
      </Flex>
    </Modal>
  )
}

export default BrandedContentAdConfirmationConnectAccount
