import React from 'react'

import { FormattedMessage } from 'react-intl'

import { TRIBE_PREAPPROVAL_BRANDS_LEARN_MORE } from '@constants'
import Theme from '@theme'
import { Container, Link, Text } from '@tribegroup'
import { SubTitle } from './PreApproval.styled'

interface IProps {
  isExpanded?: boolean
  isPitchingEnabled?: boolean
}

const SubmissionCardModalPreApprovalDisclaimer: React.FC<IProps> = ({
  isExpanded,
  isPitchingEnabled,
}) => {
  if (isPitchingEnabled && isExpanded) {
    return <></>
  }

  return (
    <Container
      topOuterSpacing={0.75}
      width="80%"
      overflow="hidden"
      maxHeight={isExpanded ? 2.5 : undefined}
    >
      {!isPitchingEnabled && (
        <SubTitle>
          <Text color={Theme.grey900Color} customFontSize={1.125} customFontWeight={300}>
            <FormattedMessage id="submission.chat.preapproved.modal.disclaimer" />
          </Text>
        </SubTitle>
      )}

      <Container topOuterSpacing={isPitchingEnabled ? undefined : 0.75}>
        <Link
          href={TRIBE_PREAPPROVAL_BRANDS_LEARN_MORE}
          target="_blank"
          color={Theme.secondaryColor}
        >
          <Text color={Theme.primaryColor} small>
            <FormattedMessage id="core.text.learnMore.withArrow" />
          </Text>
        </Link>
      </Container>
    </Container>
  )
}

export default SubmissionCardModalPreApprovalDisclaimer
