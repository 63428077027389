import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import {
  ProfileDetailCardWrapper,
  ProfileMetricsWrapper,
} from '@components/Submission/Influencer/Profile/Profile.styled'
import DetailCard from '@components/UI/DetailCard'
import { IApplicationState } from '@store'
import { selectSubmissionId } from '@store/router'
import { ISubmission, selectSubmission } from '@store/submissions'
import { abbreviateNumber, abbreviateNumberOrDash } from '@utils'

export interface IInternalProps {
  submission: ISubmission
  hasAvgCommentsAndLikes?: boolean
  hasAvgEngagement?: boolean
}

const AnalyticsPrePurchaseLegacy: React.FC<IInternalProps> = ({
  submission,
  hasAvgCommentsAndLikes,
  hasAvgEngagement,
}) => (
  <ProfileMetricsWrapper data-testid="profile-metrics-legacy">
    <ProfileDetailCardWrapper>
      <DetailCard
        title={<FormattedMessage id="submission.influencer.followers" />}
        content={abbreviateNumberOrDash(submission?.identity_followers_count)}
        elementName="metrics-followers"
      />
    </ProfileDetailCardWrapper>
    <React.Fragment>
      <ProfileDetailCardWrapper>
        <DetailCard
          title={
            <FormattedMessage
              id={`submission.influencer.averageEngagement.${submission.identity_provider}`}
            />
          }
          content={
            hasAvgCommentsAndLikes ? (
              abbreviateNumber(submission.identity_analytics!.avg_engagement)
            ) : (
              <FormattedMessage id="submission.influencer.empty" />
            )
          }
          elementName="metrics-avg-comments-and-likes"
        />
      </ProfileDetailCardWrapper>
      <ProfileDetailCardWrapper>
        <DetailCard
          title={<FormattedMessage id="submission.influencer.averageEngagement" />}
          content={
            hasAvgEngagement ? (
              <FormattedMessage
                id="core.text.percent"
                values={{ value: submission.identity_analytics!.avg_engagement_pct }}
              />
            ) : (
              <FormattedMessage id="submission.influencer.empty" />
            )
          }
          elementName="metrics-avg-engagement"
        />
      </ProfileDetailCardWrapper>
    </React.Fragment>
  </ProfileMetricsWrapper>
)

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const submissionId = selectSubmissionId(match) as number
  const submission = selectSubmission(state, submissionId)
  return {
    submission: selectSubmission(state, submissionId),
    hasAvgEngagement:
      submission.identity_analytics && !isNaN(submission.identity_analytics.avg_engagement_pct),
    hasAvgCommentsAndLikes:
      submission.identity_analytics && !isNaN(submission.identity_analytics.avg_engagement),
  }
}

export { AnalyticsPrePurchaseLegacy }

export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps),
)(AnalyticsPrePurchaseLegacy)
