import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import ActionIcon from '@components/UI/ActionIcon'
import TrackedRoute from '@components/UI/TrackedRoute/TrackedRoute'
import { IDraftContextProps } from '@context/Draft'
import { BuilderDefault, BuilderSteps, EventTrackingNames } from '@enums'
import { IMediaUploaderProps, withDraft, withMediaUploader } from '@hocs'
import { IResponseError } from '@lib/error'
import { IApplicationState } from '@store'
import { trackEvent } from '@store/analytics'
import {
  addBrand,
  fetchBrands,
  IBrand,
  selectBrand,
  selectBrandError,
  selectBrandsIsAdding,
  selectBrandsIsisUpdating,
  updateBrand,
} from '@store/brands'
import { IDraft, selectDraftType } from '@store/drafts'
import { IMedia } from '@store/media'
import Theme from '@theme'
import { FileDrop, H4, Text } from '@tribegroup'
import { Container } from '@tribegroup'
import { AutosizeTextfield } from '@tribegroup/AutosizeTextfield'
import {
  getCampaignType,
  objectToQueryString,
  parseCampaignType,
  queryStringtoObject,
  toEventsTracking,
} from '@utils'
import { ActionsWrapper, FileDropWrapper, StyledHeader } from './Form.styled'

export interface IInternalProps
  extends RouteComponentProps,
    InjectedIntlProps,
    IMediaUploaderProps,
    IDraftContextProps {
  brandId?: number
  brand?: IBrand
  hasFetchedBrand: boolean
  addBrandMode: boolean
  fetchBrands: typeof fetchBrands
  addBrand: typeof addBrand
  updateBrand: typeof updateBrand
  isAddingBrand: boolean
  isUpdatingBrand: boolean
  brandError?: IResponseError
  campaignType: string
  trackEvent: typeof trackEvent
}

const MAX_FILE_SIZE = 1e7
const REDIRECT_PATH = `/builder/${BuilderSteps.CAMPAIGN}`
const BRAND_LOGO_UPLOADER_ID = 'brandlogo'

interface IBuilderBrandFormState {
  name?: string
  logo_url?: string
  brand?: IBrand
  media?: IMedia
  errorMessage?: string
}

export const getRedirectPath = (draft: IDraft) => {
  if (draft && draft.id) {
    return `${REDIRECT_PATH}/${draft.id}/`
  }
  return REDIRECT_PATH
}

export const BrandHeader = ({ addMode }) =>
  addMode ? (
    <FormattedMessage id="builder.brands.add.createBrand" />
  ) : (
    <FormattedMessage id="builder.brands.edit.editBrand" />
  )

export class BuilderBrandForm extends React.PureComponent<IInternalProps, IBuilderBrandFormState> {
  static getDerivedStateFromProps(nextProps: IInternalProps, prevState) {
    const { brand: nextBrand } = nextProps
    if (nextBrand !== prevState.brand) {
      return {
        logo_url: nextBrand!.logo_url,
        name: nextBrand!.name,
        brand: nextBrand,
      }
    }
    if (nextProps.media !== prevState.media) {
      return {
        media: nextProps.media,
      }
    }
    return null
  }

  state = {
    name: '',
    logo_url: '',
    media: undefined,
    maxFileSizeExceeded: undefined,
    brand: undefined,
    errorMessage: undefined,
  }

  componentDidMount() {
    if (!this.props.hasFetchedBrand) {
      this.props.fetchBrands()
    }
    this.props.trackEvent(EventTrackingNames.CampaignBuilderCreateBrandStart, {
      brief_id: this.props.draft.id,
      brief_type: this.props.campaignType,
    })
    this.props.clearUpload()
  }

  onFileDrop = (file: File) => {
    this.setState({
      errorMessage: '',
    })
    this.props.upload(file)
  }

  onRejected = (files: ReadonlyArray<File>) => {
    const rejectedFile = files[0]
    const errorId =
      rejectedFile && rejectedFile.size > MAX_FILE_SIZE
        ? 'builder.brands.brandLogo.error.maxfilesize'
        : 'builder.brands.brandLogo.error.invalidFileType'
    this.setState({
      errorMessage: this.props.intl.formatMessage({
        id: errorId,
      }),
    })
  }

  onNameChange = (value: string) => {
    this.setState({
      name: value,
    })
  }

  onDropReset = () => {
    this.setState(
      {
        logo_url: undefined,
      },
      this.props.clearUpload,
    )
  }

  onAddBrand = () => {
    const { media, name } = this.state as IBuilderBrandFormState
    const { history, draft, campaignType } = this.props

    const brandStepIncomplete = Boolean(
      draft.status === undefined && draft.step_completed === undefined,
    )

    const brandProps = {
      brief_id: draft.id,
      brief_type: campaignType,
    }

    const createBrandCompleteEvent = {
      name: EventTrackingNames.CampaignBuilderCreateBrandCompleted,
      properties: brandProps,
    }

    const brandStepCompleteEvent = {
      name: EventTrackingNames.CampaignBuilderBrandStepCompleted,
      properties: brandProps,
    }

    const event = brandStepIncomplete
      ? [brandStepCompleteEvent, createBrandCompleteEvent]
      : createBrandCompleteEvent

    this.props.addBrand(
      {
        name,
        media_id: media && media.id,
      },
      {
        history,
        redirect: getRedirectPath(draft),
        event,
      },
    )
  }

  onUpdateBrand = () => {
    const { media, name, brand } = this.state as IBuilderBrandFormState
    const { history, intl, draft, campaignType } = this.props

    if (brand) {
      this.props.updateBrand(
        brand.id,
        {
          name,
          media_id: media && media.id,
        },
        {
          history,
          redirect: getRedirectPath(draft),
          toast: intl.formatMessage({ id: 'builder.brands.edit.editBrand.changeSuccess' }),
          event: toEventsTracking(EventTrackingNames.CampaignBuilderEditBrandCompleted, {
            brief_id: draft.id,
            brand_id: brand.id,
            brief_type: campaignType,
          }),
        },
      )
    }
  }

  onClickSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()
    return this.props.brandId ? this.onUpdateBrand() : this.onAddBrand()
  }

  render() {
    const {
      intl,
      brandId,
      draft,
      history,
      hasFetchedBrand,
      addBrandMode,
      isAddingBrand,
      isUpdatingBrand,
      isUploading,
      campaignType,
      brandError,
    } = this.props

    const { name, logo_url, media, errorMessage } = this.state

    const enabledOnAdd = addBrandMode && Boolean(media)
    const enabledOnUpdate = !addBrandMode && (Boolean(logo_url) || Boolean(media))

    const isRequestInProgress = isAddingBrand || isUpdatingBrand
    const validName = name && name.length <= BuilderDefault.MAX_LENGTH_BRAND_NAME
    const disabled = !validName || !(enabledOnAdd || enabledOnUpdate) || isRequestInProgress

    const isFormReady = addBrandMode || (!addBrandMode && hasFetchedBrand)

    return (
      isFormReady && (
        <React.Fragment>
          <StyledHeader>
            <H4 color={Theme.grey900Color}>
              <BrandHeader addMode={addBrandMode} />
            </H4>
          </StyledHeader>
          <Flex center column>
            <FileDropWrapper center size={10}>
              <FileDrop
                allowReplace
                defaultSrc={logo_url}
                defaultAlt={name}
                accepts="image/jpeg,image/png"
                isLoading={isUploading}
                borderWidth={0.625}
                maxFileSize={MAX_FILE_SIZE}
                onFileDrop={this.onFileDrop}
                onDropReset={this.onDropReset}
                onRejected={this.onRejected}
                caption={
                  <Text xsmall uppercase color={Theme.grey800Color}>
                    <FormattedMessage id="builder.brands.brandLogo" />
                  </Text>
                }
              />
            </FileDropWrapper>
            {errorMessage && (
              <Container topOuterSpacing={0.25}>
                <Text color={Theme.errorColor}>{errorMessage}</Text>
              </Container>
            )}
            <Container topOuterSpacing={1.5}>
              <AutosizeTextfield
                name="name"
                fontSize={1.5}
                defaultValue={name}
                trimOnBlur
                onChange={this.onNameChange}
                borderColor={Theme.defaultColor}
                emptyBorderColor={Theme.grey700Color}
                placeholder={intl.formatMessage({ id: 'builder.brands.add.placeholder' })}
                characterLimit={40}
                characterLimitErrorMessage={
                  <Text color={Theme.errorColor}>
                    <FormattedMessage
                      id="builder.brands.error.maxcharacters"
                      values={{ characterLimit: BuilderDefault.MAX_LENGTH_BRAND_NAME }}
                    />
                  </Text>
                }
              />
              {brandError?.messages &&
                typeof brandError.messages !== 'string' &&
                brandError.messages.map((errorMessage) => (
                  <Container topOuterSpacing={0.5} key={errorMessage.error_code}>
                    <Text color={Theme.errorColor}>
                      <FormattedMessage
                        id={`builder.brands.add.error.${errorMessage.error_code}`}
                        defaultMessage={errorMessage.message}
                      />
                    </Text>
                  </Container>
                ))}
            </Container>
            <ActionsWrapper center>
              <TrackedRoute
                eventName={
                  addBrandMode
                    ? EventTrackingNames.CampaignBuilderCreateBrandCancelled
                    : EventTrackingNames.CampaignBuilderEditBrandCancelled
                }
                properties={{
                  brief_id: draft.id,
                  brand_id: brandId,
                  brief_type: campaignType,
                }}
              >
                <Link
                  to={{
                    pathname: `/builder/${BuilderSteps.BRAND}`,
                    search: objectToQueryString({
                      ...queryStringtoObject(history.location.search),
                      brandId,
                    }),
                  }}
                  data-cy-element="close-brand-logo"
                >
                  <ActionIcon
                    glyph="close-x"
                    size={1.325}
                    color={Theme.grey900Color}
                    backgroundColor={Theme.whiteColor}
                  />
                </Link>
              </TrackedRoute>
              <a
                href="javascript:void(0)"
                onClick={disabled ? undefined : this.onClickSubmit}
                role="button"
                data-cy-element="save-brand-logo"
              >
                <ActionIcon
                  glyph="tick"
                  size={1.5}
                  color={Theme.grey900Color}
                  backgroundColor={Theme.whiteColor}
                  disabled={disabled}
                />
              </a>
            </ActionsWrapper>
          </Flex>
        </React.Fragment>
      )
    )
  }
}

const mapDispatchToProps = {
  addBrand,
  updateBrand,
  fetchBrands,
  trackEvent,
}

const mapStateToProps = (state: IApplicationState, { match, draft }: IInternalProps) => {
  const matchParamsBrandId = (match.params as any).brandId
  const brandId = matchParamsBrandId && parseInt(matchParamsBrandId, 10)
  const brand = Boolean(brandId) && selectBrand(state, brandId)
  const draftType = selectDraftType(state)
  const brandError = selectBrandError(state)
  return {
    brand,
    brandId,
    addBrandMode: !brandId,
    hasFetchedBrand: Boolean(brand),
    campaignType: parseCampaignType(draftType) || getCampaignType(draft),
    isAddingBrand: selectBrandsIsAdding(state),
    isUpdatingBrand: selectBrandsIsisUpdating(state),
    brandError,
    name: BRAND_LOGO_UPLOADER_ID,
  }
}

export default compose<IInternalProps, {}>(
  withRouter,
  withDraft,
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  withMediaUploader,
)(BuilderBrandForm)
