import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { IInfluencerIdentityIdContextProps } from '@context/InfluencerIdentityId'
import { withContextInfluencerIdentityId } from '@hocs'
import { useCampaignIdFromRoute } from '@hooks/useCampaignIdFromRoute'
import { IApplicationState } from '@store/index'
import { selectInfluencerIsProxy } from '@store/influencerIdentities'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

export const InfluencerIdentityLabelsTalentAgencyCreatorTag: React.FC<IInfluencerIdentityIdContextProps> = ({
  contextInfluencerIdentityId: identityId,
}) => {
  const campaignId = useCampaignIdFromRoute()
  const isProxy = useSelector((state: IApplicationState) =>
    selectInfluencerIsProxy(state, campaignId, identityId),
  )

  if (!isProxy) {
    return null
  }

  return (
    <Container
      color={Theme.primaryColor}
      borderRadius={0.25}
      borderColor={Theme.primary100Color}
      borderWidth={0.0625}
      borderStyle="solid"
      data-testid="influencer-identity-talent-agency-creator"
      innerSpacing={0.1875}
      flexCenter
    >
      <Text uppercase light xxsmall>
        <FormattedMessage id="core.text.talentAgencyCreator" />
      </Text>
    </Container>
  )
}

export default withContextInfluencerIdentityId(InfluencerIdentityLabelsTalentAgencyCreatorTag)
