import { Doughnut as CharJSDoughnut } from 'react-chartjs-2'

import Theme from '@theme'
import { tooltipValueToPercentage } from '@tribegroup/Charts/util'

interface IDoughnut {
  data: {
    label: string
    value: number
    percentage: number
  }[]
  showAsPercentage?: boolean
}

export const AnalyticsAudienceReportingChartsDoughnut: React.FC<IDoughnut> = ({
  data,
  showAsPercentage,
}) => {
  const records = data.sort((firstEl, secondEl) => (firstEl.label > secondEl.label ? 1 : -1))
  const dataSet = records.map(({ value }) => value)

  const config = {
    labels: records.map(({ label }) => label),
    datasets: [
      {
        data: dataSet,
        backgroundColor: [Theme.primaryColor, Theme.secondary500Color],
      },
    ],
  }

  return (
    <CharJSDoughnut
      data={{
        ...config,
      }}
      options={{
        aspectRatio: 2,
        cutout: '75%',
        plugins: {
          tooltip: {
            callbacks: {
              label: (data) => {
                return `${data.label}: ${
                  showAsPercentage ? tooltipValueToPercentage(data) : data.formattedValue
                }`
              },
              title: () => '',
            },
          },
          legend: {
            display: true,
            position: 'bottom',
            fullSize: true,
            labels: {
              font: {
                family: 'Rubik',
                size: 14,
              },
              color: Theme.grey700Color,
            },
          },
        },
      }}
    />
  )
}
