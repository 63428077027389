import { IMultiframeContextProps } from '@context/MultiframeControls'
import { withMultiframeControls } from '@hocs'
import Theme from '@theme'
import { Icon } from '@tribegroup'
import { PreviousWrapper } from './Previous.styled'

export const MultiframePrevious = ({ previous, activeFrameNumber }: IMultiframeContextProps) => {
  const enabled = activeFrameNumber > 1
  return (
    <PreviousWrapper
      onClick={previous}
      disabled={!enabled}
      className="slider-navigation-button previous"
      title="Previous Frame"
    >
      <Icon glyph="chevron-left" size={1.25} color={Theme.defaultColor} />
    </PreviousWrapper>
  )
}

export default withMultiframeControls(MultiframePrevious)
