import styled from 'styled-components'

import Theme from '@theme'
import { Container } from '@tribegroup'
import { useDefaultBreakpoint } from '@tribegroup/Theme'

export const LicenseBookletWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  ${useDefaultBreakpoint('tabletLandscape')`
    display: grid;
    grid-template-columns: repeat(2,minmax(0, 1fr));
    grid-template-rows: auto 1fr;
    row-gap: 3rem;
  `};
`

export const LicenseBookletOptions = styled.div`
  background-color: ${Theme.grey100Color};
  padding: 3rem 1.5rem 3rem 3rem;
  ${useDefaultBreakpoint('tabletLandscape')`
    grid-row: span 2 / span 2;
  `};
`

export const LicenseBookletHeader = styled(Container)`
  margin-top: 3rem;
  margin-right: 3rem;
  margin-left: 1.5rem;
  ${useDefaultBreakpoint('tabletLandscape')`
    order: 1;
  `};
`

export const LicenseBookletFooter = styled(Container)`
  align-self: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-right: 2rem;
  ${useDefaultBreakpoint('tabletLandscape')`
    order: 2;
    margin-right: 3rem;
  `};
`

export const FullscreenIcon = styled.div`
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
`
