import { ICampaign, PauseActionTypes } from '@store/campaigns'

export const pause = (campaignId: number) => ({
  payload: campaignId,
  type: PauseActionTypes.PAUSE_REQUEST,
})

export const pauseSuccess = (campaign: ICampaign) => ({
  payload: campaign,
  type: PauseActionTypes.PAUSE_SUCCESS,
})

export const pauseError = (error: any) => ({
  payload: error,
  type: PauseActionTypes.PAUSE_ERROR,
})
