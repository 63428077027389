import React from 'react'

import { IChannel } from '@store/campaigns'
import Theme from '@theme'
import { BlockText } from '../Block/Block.styled'

interface IProps {
  selectedChannels: ReadonlyArray<IChannel>
}

const CampaignPreviewInfluencerRequirementsChannels: React.FC<IProps> = ({ selectedChannels }) => {
  return (
    <BlockText small color={Theme.grey800Color} capitalize>
      {selectedChannels.map((channel) => channel.name).join(', ')}
    </BlockText>
  )
}

export default CampaignPreviewInfluencerRequirementsChannels
