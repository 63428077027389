import { MediaTypes, PaymentMethods } from '@enums'
import { IResponseError } from '@lib/error'
import { IHateoasLink } from '@store'
import { ILicenseOption } from '@store/campaigns'

export interface ITransactedLicense {
  dimensions: string
  printable_dimensions: string
  thumbnail_media_url: string
  original_media_url: string
  media_type?: MediaTypes
  file_type: string
  label: string
  description: string
  status: 'requested' | 'licensed' | 'expired' | 'preapproved' | string
  tax_display_name: string
  receipt_number?: string
  purchased_at?: string
  purchase_method?: PaymentMethods
  purchase_details?: string
  total_amount_before_tax_cents?: number
  total_amount_before_tax_currency?: string
  preapproval_amount_before_tax_cents?: number
  preapproval_amount_before_tax_currency?: string
  amount_paid_before_tax_cents?: number
  amount_paid_before_tax_currency?: string
  balance_amount_before_tax_cents?: number
  balance_amount_before_tax_currency?: string
}

export interface IMediaObject {
  id: number
  original_media_url: string
  thumbnail_media_url: string
  streamable_media_url?: string
  influencer_last_name: string
  influencer_first_name: string
  influencer_full_name: string
  influencer_avatar: string
  media_type: MediaTypes
  dimensions: string
  printable_dimensions: string
  file_type: string
  uploaded_to_facebook_ads?: boolean
  channel?: string
  brand_name?: string
  transacted_license?: ITransactedLicense
  links: ReadonlyArray<IHateoasLink>
  creator_note?: string
}

export interface IRequestLicenseActionRequestPayload {
  purchase_method?: PaymentMethods
  purchase_order_ids?: number[]
}

export interface IShareableMediaObject extends IMediaObject {
  license_options: ReadonlyArray<ILicenseOption>
}

export interface IMediaObjectStats {
  discover?: number
  license_requests?: number
  licensed?: number
}

export enum FetchMediaObjectsActionTypes {
  FETCH_REQUEST = 'mediaObjects/FETCH_REQUEST',
  FETCH_SUCCESS = 'mediaObjects/FETCH_SUCCESS',
  FETCH_ERROR = 'mediaObjects/FETCH_ERROR',
}

export enum FetchShareableContentLibraryActionTypes {
  FETCH_REQUEST = 'shareableContentLibrary/FETCH_REQUEST',
}

export enum FetchShareableMediaObjectActionTypes {
  FETCH_REQUEST = 'shareableMediaObject/FETCH_REQUEST',
}

export enum FetchMediaObjectActionTypes {
  FETCH_REQUEST = 'mediaObject/FETCH_REQUEST',
  FETCH_SUCCESS = 'mediaObject/FETCH_SUCCESS',
  FETCH_ERROR = 'mediaObject/FETCH_ERROR',
}

export enum RequestLicenseActionTypes {
  REQUEST_LICENSE = 'mediaObject/REQUEST_LICENSE',
  REQUEST_LICENSE_SUCCESS = 'mediaObject/REQUEST_LICENSE_SUCCESS',
  REQUEST_LICENSE_ERROR = 'mediaObject/REQUEST_LICENSE_ERROR',
}

export enum PurchaseLicenseActionTypes {
  PURCHASE_LICENSE = 'mediaObject/PURCHASE_LICENSE',
  PURCHASE_LICENSE_SUCCESS = 'mediaObject/PURCHASE_LICENSE_SUCCESS',
  PURCHASE_LICENSE_ERROR = 'mediaObject/PURCHASE_LICENSE_ERROR',
}

export enum MediaObjectsActionTypes {
  CLEAR_ERRORS = 'mediaObjects/CLEAR_ERRORS',
}

export interface IMediaObjectsState {
  readonly isFetching: boolean
  readonly isRequestingLicense: boolean
  readonly mediaObjectsById: Record<number, IMediaObject>
  readonly mediaObjectIdsByFilter: Record<string, ReadonlyArray<number>>
  readonly mediaObjectIdsBySubmissionId: Record<number, ReadonlyArray<number>>
  readonly linksByFilter: Record<string, ReadonlyArray<IHateoasLink>>
  readonly shareableCLToken?: string
  readonly stats: IMediaObjectStats
  readonly errors?: IResponseError
}

export enum SyncMediaObjectsActionTypes {
  SYNC_SUCCESS = 'mediaObjects/SYNC_SUCCESS',
}
