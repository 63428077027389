import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  RequestPurchaseOrdersActionTypes,
  selectRequestPurchaseOrderAccessLink,
} from '@store/purchaseOrders'
import {
  requestPurchaseOrdersAccessError,
  requestPurchaseOrdersAccessSuccess,
} from '@store/purchaseOrders/actions/requestAccess'
import { setUIFlag, UIFlag } from '@store/ui'
import networkRequest from '@utils/networkRequest'

export function* handleRequest() {
  try {
    const authToken = yield select(selectAuthToken)
    const requestAccessLink: IHateoasLink = yield select(selectRequestPurchaseOrderAccessLink)

    yield call(
      networkRequest,
      requestAccessLink.method,
      requestAccessLink.href,
      undefined,
      authToken,
    )
    yield put(requestPurchaseOrdersAccessSuccess())
    yield put(setUIFlag(UIFlag.SHOW_REQUEST_ACCESS_SUCCESS))
  } catch (error) {
    yield put(requestPurchaseOrdersAccessError(error))
  }
}

export function* watchRequestPurchaseOrdersAccess() {
  yield takeLatest(RequestPurchaseOrdersActionTypes.FETCH_REQUEST, handleRequest)
}
