export const addToSet = (current: ReadonlyArray<any> = [], elements: ReadonlyArray<any> = []) =>
  Array.from(new Set([...current, ...elements]))

export const removeFromSet = (current: ReadonlyArray<any> = [], element: any) =>
  current.filter(curr => curr !== element)

export const appendToSet = (current: ReadonlyArray<any> = [], elements: ReadonlyArray<any>) => {
  const currElements = current.filter(curr => !elements.includes(curr))
  return addToSet(currElements, elements)
}
