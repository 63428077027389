import React from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { RouterProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import { BuilderTitleBarActionsConfirmCloseContext } from '@components/Builder/TitleBar/Actions/ConfirmClose'
import ProgressTracker, { ProgressTrackerBlock } from '@components/UI/ProgressTracker'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { BuilderSteps } from '@enums'
import { withBuilderValidation, withDraft } from '@hocs'
import { IApplicationState } from '@store'
import { selectIsAnyUploading } from '@store/moodboard'

interface IInternalProps extends IBuilderValidationContextProps, IDraftContextProps, RouterProps {}

const BuilderTitleBarProgressTracker: React.FC<IInternalProps> = ({
  currentStep,
  stepsValidation,
  draft,
  validationResults,
  history,
  formChanged,
}) => {
  const campaignIsSubmitted = !draft.step_completed
  const completedStep =
    campaignIsSubmitted && Boolean(draft.id) ? BuilderSteps.SUBMIT : draft.step_completed

  const currentStepIndex = Object.values(BuilderSteps).findIndex((step) => step === currentStep)
  const completedStepIndex = Object.values(BuilderSteps).findIndex((step) => step === completedStep)

  const hasValidationErrors = Object.values(validationResults).some((result) => result.hasError)

  const { showConfirmModal } = React.useContext(BuilderTitleBarActionsConfirmCloseContext)

  const draftIdPath = draft.id ? `/${draft.id}` : ''

  const onClick = (event: React.SyntheticEvent, to: string) => {
    if (formChanged) {
      event.preventDefault()
      showConfirmModal(to)
    }
  }

  const { isAnyUploading } = useSelector((state: IApplicationState) => {
    return {
      isAnyUploading: draft.id && selectIsAnyUploading(state, draft.id),
    }
  })

  return (
    <ProgressTracker>
      {Object.values(BuilderSteps).map((step, index) => {
        const active = index === currentStepIndex
        const reached = index <= completedStepIndex + 1
        const reachedWithValidationErrors =
          reached && !active && stepsValidation && !stepsValidation[step]
        return (
          <ProgressTrackerBlock
            onBuilderNavigateClick={onClick}
            key={`campaign-builder-step-${step}`}
            reached={reached}
            error={(active && hasValidationErrors) || reachedWithValidationErrors}
            active={active}
            to={`/builder/${step}${draftIdPath}${history.location.search}`}
            elementName={`campaign-builder-step-${step}`}
            isAnyUploading={isAnyUploading}
          >
            <FormattedMessage id={`builder.steps.${step}.title`} />
          </ProgressTrackerBlock>
        )
      })}
    </ProgressTracker>
  )
}

export default compose(withRouter, withBuilderValidation, withDraft)(BuilderTitleBarProgressTracker)
