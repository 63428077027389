import { Component } from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { BuilderDefault } from '@enums'
import { withBuilderValidation, withDraft, withScrollToTop } from '@hocs'
import { IApplicationState } from '@store'
import { fetchCampaigns } from '@store/campaigns'
import { IDraft, selectDraftIsUsingInvoice } from '@store/drafts'
import { getRecentBuilderStep } from '@utils'
import { isSocialCampaign } from '@utils/campaign'
import { BuilderLayout } from '../Layout/Layout'
import BuilderBudgetCreatorGroup from './CreatorGroup/CreatorGroup'
import BuilderBudgetEstimatedBudget from './EstimatedBudget'

interface IInternalProps extends IDraftContextProps, IBuilderValidationContextProps {
  fetchCampaigns: typeof fetchCampaigns
  isInvoice: boolean
}

interface IBuilderBudgetFormFormState {
  draft: IDraft
}

const setBuilderDefault = (draft: IDraft) => ({
  demographic_min_age: draft.demographic_min_age || BuilderDefault.MIN_AGE_DEFAULT,
  demographic_max_age: draft.demographic_max_age || BuilderDefault.MAX_AGE_DEFAULT,
  min_follower_threshold: draft.min_follower_threshold || BuilderDefault.MIN_FOLLOWERS_DEFAULT,
  max_follower_threshold: draft.max_follower_threshold || BuilderDefault.MAX_FOLLOWERS_DEFAULT,
})

export class BuilderBudgetForm extends Component<IInternalProps, IBuilderBudgetFormFormState> {
  componentDidMount() {
    this.props.fetchCampaigns()
    this.props.setContinueCallback(this.onContinue)
  }

  onContinue = ({ nextStep, draft, currentStep, history, saveCampaign }) => {
    this.props.updateForm(setBuilderDefault(setBuilderDefault(this.props.draft)))
    setTimeout(() => {
      saveCampaign(getRecentBuilderStep(currentStep, draft.step_completed), {
        history,
        redirect: `/builder/${nextStep}/{draft_id}${history.location.search}`,
      })
    })
  }

  render() {
    const { draft } = this.props
    const isSocial = isSocialCampaign(draft)
    return (
      <BuilderLayout center={isSocial}>
        <BuilderBudgetEstimatedBudget />
        <BuilderBudgetCreatorGroup />
      </BuilderLayout>
    )
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  isInvoice: selectDraftIsUsingInvoice(state),
})

const mapDispatchToProps = {
  fetchCampaigns,
}

export default compose<IInternalProps, {}>(
  withDraft,
  withBuilderValidation,
  withScrollToTop,
  connect(mapStateToProps, mapDispatchToProps),
)(BuilderBudgetForm)
