import React from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { BrandManagerIdentityProviders } from '@enums'
import { IApplicationState } from '@store'
import { selectIsSocialCampaign } from '@store/campaigns'
import { selectIdentityFollowersCount, selectIdentityProvider } from '@store/submissions'
import Theme from '@theme'
import { H5, Icon } from '@tribegroup'
import { abbreviateNumberOrDash } from '@utils'

interface ISubHeaderLabelFollowersProps {
  campaignId: number
  submissionId: number
}

interface IInternalProps extends ISubHeaderLabelFollowersProps {
  showSocialProfile: boolean
  followersCount: number
  provider: string
}

export const SubHeaderLabelFollowers: React.FC<IInternalProps> = ({
  followersCount,
  provider,
  showSocialProfile,
}) => {
  const showFollowers = provider != BrandManagerIdentityProviders.Pinterest
  if (showSocialProfile && showFollowers) {
    return (
      <H5 className="follower-count" inline light color={Theme.grey700Color}>
        {abbreviateNumberOrDash(followersCount)}
      </H5>
    )
  }

  if (!showSocialProfile) {
    return <Icon size={0.75} glyph="content-campaign" color={Theme.primaryColor} />
  }

  return <></>
}

const mapStateToProps = (
  state: IApplicationState,
  { submissionId, campaignId }: ISubHeaderLabelFollowersProps,
) => ({
  showSocialProfile: selectIsSocialCampaign(state, campaignId),
  followersCount: selectIdentityFollowersCount(state, submissionId),
  provider: selectIdentityProvider(state, submissionId),
})

export default compose<IInternalProps, ISubHeaderLabelFollowersProps>(connect(mapStateToProps))(
  SubHeaderLabelFollowers,
)
