import { FormattedMessage } from 'react-intl'

import { CostBreakdownRow } from '@components/UI/CostBreakdown'
import { HoverToggleParent } from '@components/UI/HoverToggle'
import { ISubmission } from '@store/submissions'
import Theme from '@theme'
import SubmissionCostBreakdownTribeFeeInfo from '../TribeFeeInfo'

interface IProps {
  submission: ISubmission
  showUpdated?: boolean
}

const SubmissionCostBreakdownSocialBasePrices: React.FC<IProps> = ({ submission, showUpdated }) => {
  const { first_preapproved_cents, amount_cents, service_fee_cents, amount_currency } = submission

  const priceChanged = Boolean(first_preapproved_cents)

  const showTribeFee = service_fee_cents !== undefined && service_fee_cents > 0

  return (
    <HoverToggleParent>
      {priceChanged && (
        <CostBreakdownRow
          labelWidth={9}
          label={<FormattedMessage id="core.text.creatorPrice" />}
          currency={amount_currency}
          quantity={first_preapproved_cents!}
          isCrossedOut
          color={Theme.grey700Color}
        />
      )}
      <CostBreakdownRow
        labelWidth={9}
        label={<FormattedMessage id="core.text.creatorPrice" />}
        currency={amount_currency}
        quantity={amount_cents}
        showUpdated={showUpdated && priceChanged}
      />
      {showTribeFee && (
        <CostBreakdownRow
          labelWidth={9}
          label={<SubmissionCostBreakdownTribeFeeInfo />}
          currency={amount_currency}
          quantity={service_fee_cents!}
        />
      )}
    </HoverToggleParent>
  )
}

export default SubmissionCostBreakdownSocialBasePrices
