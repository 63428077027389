import styled, { css } from 'styled-components'

export const ExpandableChildren = styled.div`
  ${({ height, open }) => css`
    margin-bottom: 1rem;
    height: ${height}rem;
    overflow: hidden;

    ${open &&
      css`
        height: initial;
      `}
  `}
`

export const ExpandableToggle = styled.div`
  cursor: pointer;
`
