import { ICampaign } from '@store/campaigns'
import { isSocialCampaign } from '@utils/campaign'

export const getRateCardCurrency = (campaign: ICampaign): string | undefined => {
  if (campaign && campaign.rate_card) {
    return campaign.rate_card.rates_currency.toLowerCase()
  } else if (campaign && campaign.license_card) {
    return campaign.license_card[0].total_amount_before_tax_currency.toLowerCase()
  } else {
    return undefined
  }
}

export const displaySocialDetails = (
  campaign: ICampaign,
  isFilterLicenseRelated: boolean | undefined,
): boolean => {
  return campaign && isSocialCampaign(campaign) && !isFilterLicenseRelated
}
