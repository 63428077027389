import { FC } from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

import { TRIBE_PREAPPROVAL_BRANDS_LEARN_MORE } from '@constants'
import { usePreapprovalAmountPercentage } from '@hooks/usePreapprovalAmountPercentage'
import Theme from '@theme'
import { Container, Icon, Link, Text } from '@tribegroup'

interface IProps {
  hasBottomSpacing: boolean
  isSamplingCampaign: boolean
  isPitchingEnabled?: boolean
  isSocialCampaign?: boolean
}

const SubmissionChatPreApprovalEducation: FC<IProps> = ({
  hasBottomSpacing,
  isSamplingCampaign,
  isPitchingEnabled,
  isSocialCampaign,
}) => {
  const preapprovalAmountPercentage = usePreapprovalAmountPercentage()

  let suffix = 'standard'

  if (isSamplingCampaign) {
    suffix = 'sampling'
  }

  if (isPitchingEnabled) {
    suffix = 'pitching'
  }

  return (
    <Container
      borderRadius={0.5}
      backgroundColor={Theme.primary100Color}
      leftInnerSpacing={1}
      rightInnerSpacing={1}
      topInnerSpacing={1}
      bottomInnerSpacing={1}
      textAlign="left"
      bottomOuterSpacing={hasBottomSpacing ? 0.9375 : 0}
    >
      <Text small color={Theme.grey900Color}>
        <Container bottomOuterSpacing={0.5}>
          {isSocialCampaign ? (
            <FormattedHTMLMessage
              id={`submission.chat.preapproved.education.line1.${suffix}`}
              values={{ preapprovalAmountPercentage }}
            />
          ) : (
            <FormattedHTMLMessage
              id={`submission.content.chat.preapproved.education.line1.${suffix}`}
              values={{ preapprovalAmountPercentage }}
            />
          )}
        </Container>
        <Container bottomOuterSpacing={0.5}>
          <FormattedMessage
            id={`submission.chat.preapproved.education.line2.${suffix}`}
            values={{
              approveIcon: (
                <Container relative height={0.75} width={1.25} inlineBlock>
                  <Container absolute>
                    <Icon glyph="approve-submission" size={1} />
                  </Container>
                </Container>
              ),
            }}
          />
        </Container>
        {isSocialCampaign && (
          <Container bottomOuterSpacing={0.5}>
            <FormattedHTMLMessage id="submission.chat.preapproved.education.line3" />
          </Container>
        )}
      </Text>
      <Link href={TRIBE_PREAPPROVAL_BRANDS_LEARN_MORE} target="_blank" color={Theme.secondaryColor}>
        <Text color={Theme.primaryColor} small>
          <FormattedMessage id="core.text.learnMore.withArrow" />
        </Text>
      </Link>
    </Container>
  )
}

export default SubmissionChatPreApprovalEducation
