import React from 'react'

import { FormattedMessage } from 'react-intl'

import Breakpoint from '@components/UI/Breakpoint'
import ButtonLink from '@components/UI/ButtonLink'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { EventTrackingNames } from '@enums'
import { Header } from '@tribegroup'

const CreateCampaignButton: React.FC = () => {
  return (
    <Breakpoint desktop>
      <ConnectedTrackedRoute eventName={EventTrackingNames.CampaignBuilderCreateCampaign}>
        <ButtonLink
          to="/builder"
          scheme="primary"
          small
          data-cy-element="create-campaign-button"
          leftOuterSpacing={0.75}
          leftInnerSpacing={0}
          rightInnerSpacing={0}
          width={10.25}
        >
          <Header cta small>
            <FormattedMessage id="campaign.campaignsPage.header.button" />
          </Header>
        </ButtonLink>
      </ConnectedTrackedRoute>
    </Breakpoint>
  )
}

export { CreateCampaignButton }
export default CreateCampaignButton
