import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Hr } from '@components/UI/Hr/Hr.styled'
import { usePreapprovalAmountPercentage } from '@hooks/usePreapprovalAmountPercentage'
import { IApplicationState } from '@store'
import {
  selectCampaign,
  selectIsPayingByCreditCard,
  selectIsSocialCampaign,
  selectShouldHideCampaignBudget,
} from '@store/campaigns'
import Theme from '@theme'
import { Container, H3, Text } from '@tribegroup'
import CampaignBudgetSpentLineItem from './BudgetSpentLineItem'
import CampaignBudgetLineItems from './LineItems'

interface IProps {
  campaignId: number
  paymentMethod: string
}

const CampaignBudgetTipContent: React.FC<IProps> = ({ campaignId, paymentMethod }) => {
  const percentage = usePreapprovalAmountPercentage()
  const isPayingByCreditCard = useSelector((state: IApplicationState) =>
    selectIsPayingByCreditCard(state, campaignId),
  )
  const { shouldHideRemainingBudget, isSocialCampaign, isPreapprovalAllowed } = useSelector(
    (state: IApplicationState) => {
      const campaign = selectCampaign(state, campaignId)

      return {
        shouldHideRemainingBudget: selectShouldHideCampaignBudget(state, campaignId),
        isSocialCampaign: selectIsSocialCampaign(state, campaignId),
        isPreapprovalAllowed: campaign?.preapproval_allowed,
      }
    },
  )

  return (
    <Container
      topOuterSpacing={0.75}
      bottomOuterSpacing={0.75}
      leftOuterSpacing={0.75}
      rightOuterSpacing={0.75}
    >
      <H3 capitalize color={Theme.grey900Color}>
        <FormattedMessage id="campaign.budget.summary" />
      </H3>
      <CampaignBudgetLineItems
        campaignId={campaignId}
        isPayingByCreditCard={isPayingByCreditCard}
      />

      {!shouldHideRemainingBudget && (
        <>
          {isPayingByCreditCard ? (
            <Container topOuterSpacing={1.25}>
              <Hr />
            </Container>
          ) : (
            <CampaignBudgetSpentLineItem campaignId={campaignId} />
          )}
        </>
      )}

      {shouldHideRemainingBudget && <CampaignBudgetSpentLineItem campaignId={campaignId} />}

      {isPreapprovalAllowed && (
        <Container topOuterSpacing={1.25} rightOuterSpacing={0.5}>
          <Text color={Theme.defaultColor} block customFontSize={0.8125} lineHeight={1.23}>
            <FormattedMessage id="campaign.budget.summary.infoLine1" values={{ percentage }} />
          </Text>
        </Container>
      )}

      {isSocialCampaign && (
        <>
          <Container topOuterSpacing={0.75} rightOuterSpacing={0.5}>
            <Text color={Theme.defaultColor} block customFontSize={0.8125} lineHeight={1.23}>
              <FormattedMessage id="campaign.budget.summary.infoLine2" />
            </Text>
          </Container>
          {!shouldHideRemainingBudget && (
            <Container topOuterSpacing={0.75} rightOuterSpacing={0.5}>
              <Text color={Theme.defaultColor} block customFontSize={0.8125} lineHeight={1.23}>
                <FormattedMessage id={`campaign.budget.summary.infoLine3.${paymentMethod}`} />
              </Text>
            </Container>
          )}
        </>
      )}
    </Container>
  )
}

export default CampaignBudgetTipContent
