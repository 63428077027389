import { FormattedMessage } from 'react-intl'

import Price from '@components/UI/Price/Price'
import { ILicense } from '@store/licenses'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'

export const RequestLicensePriceSummary = ({ license }: { license: ILicense }) => {
  if (!license) {
    return <></>
  }

  const {
    tax_display_name,
    total_amount_before_tax_cents,
    total_amount_before_tax_currency,
  } = license

  return (
    <Container
      flexBetween
      alignItems="flex-start"
      fullWidth
      borderColor={Theme.grey400Color}
      topOuterSpacing={1}
      borderWidthTop={0.0625}
      topInnerSpacing={0.5}
    >
      <Text color={Theme.grey900Color} textAlign="left" block medium>
        <FormattedMessage
          id="core.text.totalCurrency"
          values={{ currency: total_amount_before_tax_currency }}
        />
        &nbsp;(
        <FormattedMessage
          id="submission.fullView.pricePanel.tax.text"
          values={{ taxName: tax_display_name }}
        />
        )
      </Text>
      <Container flexEnd noShrink>
        <Container rightOuterSpacing={0.25}>
          <Text color={Theme.grey900Color} medium>
            <Price
              quantity={total_amount_before_tax_cents!}
              currency={total_amount_before_tax_currency}
            />
          </Text>
        </Container>
      </Container>
    </Container>
  )
}
