import { Stripe } from '@stripe/stripe-js'

import { IResponseError } from '@lib/error'
import { IBaseCampaignProps } from '@store/campaigns'
import { IProvidedCampaignObjective } from '@store/rootResource'

export interface IDraft extends Partial<IBaseCampaignProps> {
  hero_16x9_id?: string
  campaign_objectives?: ICampaignObjectives
  criteria_for_success?: string
  media_id?: string
  gender?: ReadonlyArray<string>
  submission_type?: string
}

export interface IProfileParams {
  first_name: string
  last_name: string
  phone_number?: string
}

export interface ICompanyParams {
  company_name: string
  street_address?: string
  city?: string
  state_code?: string
  country_code: string
  postcode?: string
}

export interface ICampaignDetails {
  indicative_budget_cents?: number
  step_completed: string
}

export interface IPaymentDetails {
  payment_type?: string
  number?: string
  exp_month?: number
  exp_year?: number
  cvc?: number
  billing_contact_name?: string
  accounts_payable_email?: string
  po_number?: string
  stripe?: Stripe
  cardNumberElement?: unknown
  use_existing_card?: boolean
}

export interface ICampaignObjectives {
  provided: ReadonlyArray<IProvidedCampaignObjective>
  other: string
}

export enum SaveDraftActionTypes {
  SAVE_DRAFT_REQUEST = 'draft/SAVE_DRAFT_REQUEST',
  SAVE_DRAFT_SUCCESS = 'draft/SAVE_DRAFT_SUCCESS',
  SAVE_DRAFT_ERROR = 'draft/SAVE_DRAFT_ERROR',
}

export enum ClearDraftActionTypes {
  CLEAR_DRAFT_REQUEST = 'draft/CLEAR_DRAFT_REQUEST',
  CLEAR_DRAFT_SUCCESS = 'draft/CLEAR_DRAFT_SUCCESS',
  CLEAR_DRAFT_ERROR = 'draft/CLEAR_DRAFT_ERROR',
}

export enum SyncDraftActionTypes {
  SYNC_DRAFT_REQUEST = 'draft/SYNC_DRAFT_REQUEST',
  SYNC_DRAFT_SUCCESS = 'draft/SYNC_DRAFT_SUCCESS',
  SYNC_DRAFT_ERROR = 'draft/SYNC_DRAFT_ERROR',
}

export interface IDraftsState {
  readonly isSaving: boolean
  readonly campaign?: IDraft
  readonly errors?: IResponseError
}
