import React, { PropsWithChildren } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { selectDraftTrackingProps, trackEvent } from '@store/analytics'
import {
  selectCampaignTrackingProps,
  selectMediaObjectTrackingProps,
  selectSubmissionTrackingProps,
} from '@store/analytics'
import TrackedRoute, { IBaseRouteProps } from './TrackedRoute'

export interface IConnectedTrackedRouteIDProps {
  campaignId?: number
  mediaObjectId?: number
  submissionId?: number
  draftId?: number
}

export interface IConnectedTrackedRoute extends IConnectedTrackedRouteIDProps, IBaseRouteProps {
  additionalProperties?: object
}

interface IInternalProps extends IConnectedTrackedRoute {
  trackEvent: typeof trackEvent
  campaignAnalyticsProperties: {}
  submissionAnalyticsProperties: {}
  mediaObjectAnalyticsProperties: {}
  draftAnalyticsProperties: {}
}

const getBaseProperties = ({
  mediaObjectId,
  campaignId,
  submissionId,
  draftId,
  campaignAnalyticsProperties,
  submissionAnalyticsProperties,
  mediaObjectAnalyticsProperties,
  draftAnalyticsProperties,
}) => {
  if (draftId) {
    return draftAnalyticsProperties
  }
  if (submissionId) {
    return submissionAnalyticsProperties
  }
  if (!submissionId && mediaObjectId) {
    return mediaObjectAnalyticsProperties
  }
  if (campaignId) {
    return campaignAnalyticsProperties
  }
  return undefined
}

export const ConnectedTrackedRoute: React.FC<PropsWithChildren<IInternalProps>> = ({
  eventName,
  mediaObjectId,
  campaignId,
  submissionId,
  draftId,
  additionalProperties,
  campaignAnalyticsProperties,
  submissionAnalyticsProperties,
  mediaObjectAnalyticsProperties,
  draftAnalyticsProperties,
  targetLink,
  children,
  inline,
  disableTracking,
  ...trackedRouteProps
}) => {
  const baseProperties = getBaseProperties({
    mediaObjectId,
    campaignId,
    submissionId,
    draftId,
    campaignAnalyticsProperties,
    submissionAnalyticsProperties,
    mediaObjectAnalyticsProperties,
    draftAnalyticsProperties,
  })

  const properties = {
    ...baseProperties,
    ...additionalProperties,
  }

  return (
    <TrackedRoute
      eventName={eventName}
      targetLink={targetLink}
      inline={inline}
      properties={properties}
      disableTracking={disableTracking}
      {...trackedRouteProps}
    >
      {children}
    </TrackedRoute>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { campaignId, submissionId, mediaObjectId, draftId }: IConnectedTrackedRouteIDProps,
) => {
  return {
    draftAnalyticsProperties: draftId && selectDraftTrackingProps(state),
    campaignAnalyticsProperties: campaignId && selectCampaignTrackingProps(state, campaignId),
    submissionAnalyticsProperties:
      campaignId && submissionId && selectSubmissionTrackingProps(state, campaignId, submissionId),
    mediaObjectAnalyticsProperties:
      mediaObjectId && selectMediaObjectTrackingProps(state, mediaObjectId),
  }
}

export default compose<IInternalProps, PropsWithChildren<IConnectedTrackedRoute>>(
  withRouter,
  connect(mapStateToProps),
)(ConnectedTrackedRoute)
