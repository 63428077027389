import React from 'react'

import H from 'history'

import ConditionalLink from '@components/UI/ConditionalLink/ConditionalLink'
import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'
import { isEmpty } from '@utils'
import SideBarBlockItemStyled, { MainText, NotificationDot } from './Item.styled'

export interface ISideBarBlockItemProps {
  active?: boolean
  noHighlight?: boolean
  showNotification?: boolean
  count?: number
  text: React.ReactNode
  elementName?: string
  paddingOverride?: string
  to?: H.LocationDescriptor
  onClick?: (event?: React.MouseEvent<HTMLDivElement>) => void
}

const SideBarBlockItem: React.FC<ISideBarBlockItemProps> = ({
  text,
  active,
  count,
  showNotification,
  noHighlight,
  elementName,
  to,
  onClick,
  paddingOverride,
}) => (
  <SideBarBlockItemStyled
    active={active}
    noHighlight={noHighlight}
    data-cy-element={elementName}
    data-testid={elementName}
    onClick={onClick}
    paddingOverride={paddingOverride}
  >
    <ConditionalLink
      data-active={active}
      showLink={Boolean(to) && !isEmpty(to)}
      to={to as H.LocationDescriptor}
    >
      <MainText>
        <Text color={Theme.grey900Color} medium={active}>
          {text}
        </Text>{' '}
        {count ? (
          <Container leftOuterSpacing={0.5} inlineBlock>
            <Text color={active ? Theme.grey800Color : Theme.grey700Color}>{count}</Text>
          </Container>
        ) : null}
        {showNotification && (
          <NotificationDot>
            <Icon glyph="notification-dot" color={Theme.errorColor} />
          </NotificationDot>
        )}
      </MainText>
    </ConditionalLink>
  </SideBarBlockItemStyled>
)

export default SideBarBlockItem
