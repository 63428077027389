import { Stripe } from '@stripe/stripe-js'
import { FormattedHTMLMessage } from 'react-intl'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { ErrorCodes } from '@enums'
import { selectAuthToken } from '@store/auth'
import {
  activateWithFee,
  ActivateWithFeeActionTypes,
  activateWithFeeError,
  activateWithFeeSuccess,
  fetchCampaigns,
  IActivateWithFeeResponse,
  ICampaign,
  selectActivateWithFeeLink,
} from '@store/campaigns'
import { selectStripe } from '@store/payments'
import { Toaster } from '@tribegroup'
import { networkRequest, selectLink } from '@utils'
import { stripeErrorToResponseError } from '@utils/stripeErrorToResponseError'

export function* handlePaymentFailure(response: IActivateWithFeeResponse, authToken: string) {
  const abandon3dsLink = selectLink(response.links, 'abandon_activation_with_fee')

  yield call(
    networkRequest,
    abandon3dsLink?.method,
    abandon3dsLink?.href,
    { payment_intent_id: response.payment_intent_id },
    authToken,
  )
}

export function* handlePaymentRequest(action: ReturnType<typeof activateWithFee>) {
  const { campaignId } = action.payload
  const authToken = yield select(selectAuthToken)
  const activateWithFeeLink = yield select(selectActivateWithFeeLink, campaignId)

  const activateWithFeeResponse: IActivateWithFeeResponse = yield call(
    networkRequest,
    activateWithFeeLink.method,
    activateWithFeeLink.href,
    undefined,
    authToken,
  )

  if (activateWithFeeResponse.brief) {
    return activateWithFeeResponse.brief
  }

  const stripeSDK: Stripe = yield select(selectStripe)

  const paymentIntentResponse = yield call(
    stripeSDK.confirmCardPayment,
    activateWithFeeResponse.client_secret,
  )

  if (paymentIntentResponse.error) {
    const paymentError = stripeErrorToResponseError(
      paymentIntentResponse.error,
      ErrorCodes.CAMPAIGN_ACTIVATE_WITH_FEE_STRIPE_ERROR,
    )

    yield call(handlePaymentFailure, activateWithFeeResponse, authToken)

    throw {
      messages: [paymentError],
    }
  }

  const { paymentIntent } = paymentIntentResponse
  const approve3dsLink = selectLink(activateWithFeeResponse.links, 'complete_activation_with_fee')!

  const campaign: ICampaign = yield call(
    networkRequest,
    approve3dsLink.method,
    approve3dsLink.href,
    {
      payment_intent_id: paymentIntent.id,
    },
    authToken,
  )

  return campaign
}

export function* handleActivateWithFee(action: ReturnType<typeof activateWithFee>) {
  try {
    const campaign: ICampaign = yield call(handlePaymentRequest, action)

    yield put(activateWithFeeSuccess(campaign))
    yield put(fetchCampaigns())
    yield call(Toaster.createToast, FormattedHTMLMessage, {
      id: 'campaign.card.activateWithFee.toast.success',
    })
  } catch (error) {
    yield put(activateWithFeeError(error))
    yield call(Toaster.createToast, FormattedHTMLMessage, {
      id: 'campaign.card.activateWithFee.toast.error',
    })
  }
}

export function* watchActivateWithFeeRequest() {
  yield takeLatest(ActivateWithFeeActionTypes.ACTIVATE_WITH_FEE_REQUEST, handleActivateWithFee)
}
