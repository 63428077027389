import React from 'react'

import Theme from '@theme'
import { Icon, Text } from '@tribegroup'
import {
  CardInnerWrapper,
  CardOuterWrapper,
  LabelStyled,
  LabelWrapper,
  MediaIconStyled,
} from './Card.styled'

interface IBaseProps {
  children: React.ReactNode
}

interface ICardContainerProps extends IBaseProps {
  selected?: boolean
  elementName?: string
  outerContent?: React.ReactChild
}

export const CardContainer = ({
  children,
  selected,
  elementName,
  outerContent,
}: ICardContainerProps) => {
  return (
    <CardOuterWrapper data-cy-element={elementName} data-testid="card-container">
      <CardInnerWrapper selected={selected}>{children}</CardInnerWrapper>
      {outerContent}
    </CardOuterWrapper>
  )
}

export const CardLabel = ({ children }: IBaseProps) => {
  return (
    <LabelWrapper>
      <LabelStyled>
        <Text color={Theme.grey900Color} uppercase xsmall>
          {children}
        </Text>
      </LabelStyled>
    </LabelWrapper>
  )
}

interface ICardIconProps {
  icon: string
  iconTooltip?: string
  opacity?: number
  color?: string
}

export const CardIcon = ({
  icon,
  iconTooltip,
  opacity = 0.75,
  color = Theme.whiteColor,
}: ICardIconProps) => {
  return (
    <MediaIconStyled>
      <Icon
        shadowed
        size={1.5}
        glyph={icon}
        opacity={opacity}
        color={color}
        data-tooltip-content={iconTooltip}
        data-tooltip-id="base-tooltip"
      />
    </MediaIconStyled>
  )
}
