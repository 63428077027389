import { useEffect } from 'react'

import { addClassToBody, removeClassFromBody } from '@utils/bodyClass'

const useBodyOverlayedClassHandler = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      addClassToBody('overlayed')
    } else {
      removeClassFromBody('overlayed')
    }
    return () => {
      removeClassFromBody('overlayed')
    }
  }, [isOpen])
}

export { useBodyOverlayedClassHandler }
