import React from 'react'

import { ActionBarLeft, ActionBarRight, ActionBarWrapper } from '@components/UI/Card/Card.styled'
import { useInboxFilter } from '@hooks/useInboxFilter'
import { ISubmission } from '@store/submissions'
import ActionBarIconApprove from '../Icon/Approve/Approve'
import ActionBarIconChat from '../Icon/Chat'
import ActionBarIconDecline from '../Icon/Decline/Decline'

interface IProps {
  campaignId: number
  submission: ISubmission
  hasDeclineLink: boolean
  hasFeedbackLink: boolean
  hasApproveLink: boolean
}

const SubmissionCardActionBarSocialPreApproved: React.FC<IProps> = ({
  hasDeclineLink,
  hasApproveLink,
  hasFeedbackLink,
  submission,
  campaignId,
}) => {
  const { currentFilter } = useInboxFilter()
  const modalProps = {
    currFilter: currentFilter,
    submission,
    campaignId,
  }

  return (
    <ActionBarWrapper justifyBetween>
      <ActionBarLeft>
        {hasDeclineLink && <ActionBarIconDecline modalProps={modalProps} />}
      </ActionBarLeft>
      <ActionBarRight>
        {hasFeedbackLink && <ActionBarIconChat submission={submission} campaignId={campaignId} />}
        {hasApproveLink && <ActionBarIconApprove modalProps={modalProps} highlighted />}
      </ActionBarRight>
    </ActionBarWrapper>
  )
}

export default SubmissionCardActionBarSocialPreApproved
