import { format } from 'date-fns'

import Price from '@components/UI/Price/Price'
import { IPurchaseOrder, PurchaseOrderStatus } from '@store/purchaseOrders'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { CustomCheckBox } from './List.styled'

interface IProps {
  purchaseOrder: IPurchaseOrder
  disabled?: boolean
  checked?: boolean
  onSelect: (purchaseOrder: IPurchaseOrder) => void
  onDeselect: (purchaseOrder: IPurchaseOrder) => void
}

export const CreditManagementPaymentMethodPurchaseOrderListItem = ({
  purchaseOrder,
  disabled,
  checked,
  onSelect,
  onDeselect,
}: IProps) => {
  const onChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement

    if (target.checked) {
      onSelect(purchaseOrder)
    } else {
      onDeselect(purchaseOrder)
    }
  }

  const textColor = disabled ? Theme.grey700Color : Theme.grey900Color

  const subLabel =
    purchaseOrder.status === PurchaseOrderStatus.ACTIVE
      ? `Expires ${format(new Date(purchaseOrder.expiry_date), 'dd/MM/yy')}`
      : purchaseOrder.status

  return (
    <Container flexBetween alignItems="flex-start" bottomOuterSpacing={1}>
      <Container rightOuterSpacing={1.25}>
        <CustomCheckBox
          checked={checked}
          scheme="primary"
          disabled={disabled}
          onChange={onChange}
        />
      </Container>
      <Container fullWidth textAlign="left">
        <Text block color={textColor}>
          {purchaseOrder.customer_order_number}
        </Text>
        <Text block color={Theme.grey700Color} capitalize>
          {subLabel}
        </Text>
      </Container>
      <Container minWidth={6} textAlign="right">
        <Text color={textColor}>
          <Price
            quantity={purchaseOrder.amount_remaining_cents}
            currency={purchaseOrder.amount_remaining_currency}
          />
        </Text>
      </Container>
    </Container>
  )
}
