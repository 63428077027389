import Theme from '@theme'
import { Container, FormGroupRow, Text } from '@tribegroup'

export const FormRow = ({ label, children }) => (
  <FormGroupRow
    spaceBetween={1}
    inlineLabelWidth={12.5}
    inlineLableVerticalSpacing={0.625}
    contentWidth={32}
    inlineLabel={
      <Text xsmall uppercase color={Theme.grey800Color}>
        {label}
      </Text>
    }
  >
    <Container width="inherit" topOuterSpacing={0.5} bottomOuterSpacing={0.5}>
      {children}
    </Container>
  </FormGroupRow>
)

export default FormRow
