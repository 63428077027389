import styled from 'styled-components'

export const ImageWrapper = styled.a`
  min-width: 5.25rem;
  display: block;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
`

export const FullscreenIcon = styled.div`
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
`
