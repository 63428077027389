import { FormattedMessage } from 'react-intl'

import { CheckItem } from '@components/UI/Coachmark/Coachmark.styled'
import {
  BRANDS_INSTAGRAM_CONNECT_EDUCATION,
  TIKTOK_ADS_MANAGER_CONNECT_EDUCATION,
} from '@constants'
import Theme from '@theme'
import { Container, Header, Icon, Link, Text } from '@tribegroup'

const ConnectedAccountsChecklist = () => {
  return (
    <>
      <Container topOuterSpacing={4.25}>
        <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.5}>
          <FormattedMessage id="connectedAccounts.checklist.instagram.header" />
        </Header>
        {Array(4)
          .fill('')
          .map((_, index) => (
            <CheckItem key={index}>
              <Icon glyph="tick-heavy" color={Theme.successColor} size={1.25} />
              <Text color={Theme.grey900Color} light>
                <FormattedMessage id={`connectedAccounts.checklist.item${index + 1}`} />
              </Text>
            </CheckItem>
          ))}
      </Container>
      <Container topOuterSpacing={0.75}>
        <Link
          href={BRANDS_INSTAGRAM_CONNECT_EDUCATION}
          target="_blank"
          color={Theme.secondaryColor}
        >
          <Text light color={Theme.primaryColor}>
            <FormattedMessage id="core.text.learnMore.withArrow" />
          </Text>
        </Link>
      </Container>

      <Container topOuterSpacing={1.75}>
        <Header small uppercase color={Theme.grey700Color} bottomOuterSpacing={0.5}>
          <FormattedMessage id="connectedAccounts.tiktok.checklist.header" />
        </Header>
        <CheckItem>
          <Icon glyph="tick-heavy" color={Theme.successColor} size={1.25} />
          <Text color={Theme.grey900Color} light>
            <FormattedMessage id="connectedAccounts.tiktok.checklist.item1" />
            &nbsp;
            <Link
              href={TIKTOK_ADS_MANAGER_CONNECT_EDUCATION}
              target="_blank"
              color={Theme.secondaryColor}
            >
              <Text light color={Theme.primaryColor}>
                <FormattedMessage id="core.text.learnHow.withArrow" />
              </Text>
            </Link>
          </Text>
        </CheckItem>
      </Container>
    </>
  )
}

export default ConnectedAccountsChecklist
