import { IMeta } from '@store'
import { CampaignActionTypes, ICampaign } from '@store/campaigns'

export const fetchCampaign = (id: number, meta?: IMeta) => ({
  payload: id,
  meta,
  type: CampaignActionTypes.FETCH_REQUEST,
})

export const fetchCampaignSuccess = (campaign: ICampaign, meta?: {}) => ({
  payload: campaign,
  meta,
  type: CampaignActionTypes.FETCH_SUCCESS,
})

export const fetchCampaignError = (error: any) => ({
  payload: error,
  type: CampaignActionTypes.FETCH_ERROR,
})
