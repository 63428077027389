import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import Flex from 'styled-flex-component'

import { Hr } from '@components/UI/Hr/Hr.styled'
import RouterLink from '@components/UI/RouterLink/RouterLink.styled'
import { PageRoutes } from '@enums'
import Theme from '@theme'
import { Container, Icon, Text } from '@tribegroup'

const ConnectedAccountsDrawerFooter = () => {
  const location = useLocation()
  const fromRoute = `?from=${encodeURIComponent(`${location.pathname}${location.search}`)}`

  return (
    <Container leftOuterSpacing={1.5} rightOuterSpacing={1.5}>
      <Container
        absolute
        bottomPosition={0}
        height={5.9375}
        width={17.75}
        backgroundColor={Theme.whiteColor}
        zIndex={1}
      >
        <Hr margin={0} color={Theme.grey400Color} />
        <Container
          topOuterSpacing={0.75}
          bottomOuterSpacing={1}
          topInnerSpacing={0.375}
          bottomInnerSpacing={0.375}
          leftInnerSpacing={0.75}
          rightInnerSpacing={0.75}
          borderRadius={0.5}
          pointer
          hoverBackgroundColor={Theme.grey200Color}
        >
          <RouterLink to={`/${PageRoutes.ConnectedAccounts}${fromRoute}`}>
            <Flex full justifyBetween alignCenter>
              <Text color={Theme.grey900Color}>
                <FormattedMessage id="connectedAccounts.drawer.footer" />
              </Text>
              <Text color={Theme.grey900Color}>
                <Icon glyph="chevron-right" />
              </Text>
            </Flex>
          </RouterLink>
        </Container>
      </Container>
    </Container>
  )
}

export { ConnectedAccountsDrawerFooter }
