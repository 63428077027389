import React from 'react'

import Currency from 'react-currency-formatter'
import { FormattedMessage } from 'react-intl'
import Flex, { FlexItem } from 'styled-flex-component'

import { EllipsisWrapper } from '@components/InfluencerIdentity/Row/Details/Details.styled'
import Theme from '@theme'
import { Container, H3, Header, Text } from '@tribegroup'
import { isEmpty } from '@utils'

export const BudgetCurrency = ({ amount, currency, largetext, color = Theme.primaryColor }) => {
  return largetext ? (
    <H3 color={color} inline>
      <Currency
        quantity={Math.round(amount / 100)}
        currency={currency ? currency : undefined}
        pattern="!###,### "
      />
    </H3>
  ) : (
    <Text color={color}>
      <Currency
        quantity={Math.round(amount / 100)}
        currency={currency ? currency : undefined}
        pattern="!###,### "
      />
    </Text>
  )
}

interface IBudgetAmount {
  spaceBetweenText?: number
  remaining?: number
  spent?: number
  currency?: string
  largetext?: boolean
  customMessage?: React.ReactNode
  reversed?: boolean
  color?: string
}

export const BudgetAmountMessage = ({ hasRemaining, hasSpent, customMessage }) => {
  if (customMessage) {
    return customMessage
  }
  if (hasRemaining) {
    return <FormattedMessage id={'campaign.budget.remaining'} />
  }
  if (hasSpent) {
    return <FormattedMessage id={'campaign.budget.spent'} />
  }
  return null
}

export const BudgetAmount = ({
  remaining,
  spent,
  currency,
  largetext,
  spaceBetweenText = 0.5,
  customMessage,
  reversed = false,
  color,
}: IBudgetAmount) => {
  const hasRemaining = !isEmpty(remaining)
  const hasSpent = !isEmpty(spent)
  const amount = hasRemaining ? remaining : spent

  if (!customMessage && !hasRemaining && !hasSpent) {
    return null
  }

  if (reversed) {
    return (
      <Flex alignCenter justifyStart>
        <Container rightOuterSpacing={spaceBetweenText}>
          <EllipsisWrapper>
            <Header uppercase small>
              <BudgetAmountMessage
                hasRemaining={hasRemaining}
                hasSpent={hasSpent}
                customMessage={customMessage}
              />
            </Header>
          </EllipsisWrapper>
        </Container>
        <FlexItem noShrink>
          <Container>
            <BudgetCurrency
              amount={amount}
              currency={currency}
              largetext={largetext}
              color={color}
            />
          </Container>
        </FlexItem>
      </Flex>
    )
  }

  return (
    <Flex alignCenter justifyStart>
      <FlexItem noShrink>
        <Container rightOuterSpacing={spaceBetweenText}>
          <BudgetCurrency amount={amount} currency={currency} largetext={largetext} color={color} />
        </Container>
      </FlexItem>
      <EllipsisWrapper>
        <Header uppercase small>
          <BudgetAmountMessage
            hasRemaining={hasRemaining}
            hasSpent={hasSpent}
            customMessage={customMessage}
          />
        </Header>
      </EllipsisWrapper>
    </Flex>
  )
}
