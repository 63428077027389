import Image from '@components/UI/Image'
import { AspectRatios, MediaTypes } from '@enums'
import { IMoodboardImage } from '@store/campaigns'
import Theme from '@theme'
import { Container, Icon } from '@tribegroup'
import { MediaVideo, VideoIconWrapper } from './ImageSlider.styled'

export interface IMediaSliderFilled {
  media: IMoodboardImage
}

const MediaSliderFilled = ({ media }: IMediaSliderFilled) => {
  return (
    <Container key={media.media_id} height={26}>
      {media.media_type?.includes(MediaTypes.VIDEO) ? (
        <Container data-testid="video-moodboard-campaign-preview" inline>
          <MediaVideo src={media.media_url} preload="auto" autoPlay={false} />
          <VideoIconWrapper>
            <Icon color={Theme.whiteColor} glyph="video" size={1.75} />
          </VideoIconWrapper>
        </Container>
      ) : (
        <Image
          key={media.media_id!}
          src={media.media_url || undefined}
          alt={media.media_id!}
          aspectRatio={AspectRatios.FIVE_BY_FOUR}
        />
      )}
    </Container>
  )
}

export default MediaSliderFilled
