import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Flex from 'styled-flex-component'

import Theme from '@theme'

export const FullscreenIcon = styled(Flex)`
  position: absolute;
  top: calc(50% - 2.625rem);
  left: calc(50% - 2.625rem);
  width: 5.25rem;
  height: 5.25rem;
  border-radius: 50%;
  background-color: rgb(0 0 0 / 20%);
`

export const MediaObjectWrapper = styled.div`
  position: relative;
  ${breakpoint('tabletPortrait')`
  ${({ withRightSeparatorBorder }) =>
    withRightSeparatorBorder &&
    css`
      border-right: 1px solid ${Theme.grey200Color};
    `};

    ${FullscreenIcon} {
      display: none;
    };
    &:hover {
      ${FullscreenIcon} {
        display: flex;
      };
    };
  `};
`
