import { FormattedMessage } from 'react-intl'

import { Text } from '@tribegroup'
import { MetricTable, Section, SectionHeader } from '../Breakdown.styled'
import { MetricEntry } from '../types'
import { AnalyticsBreakdownMetricTableRow } from './Row'

const createMetricEntryId = (entry: MetricEntry) => `${entry.metric}-${entry.source}`

type MetricSectionProps = {
  name: string
  entries: MetricEntry[]
  currency: string
}

export const AnalyticsBreakdownMetricTableSection: React.FC<MetricSectionProps> = ({
  entries,
  name,
  currency,
}) => {
  return (
    <Section data-testid={`${name}-section`}>
      <SectionHeader>
        <Text customFontSize={1.125}>
          <FormattedMessage id={`analytics.breakdown.section.header.${name}`} />
        </Text>
      </SectionHeader>
      <MetricTable>
        <tbody>
          {entries.map((entry) => (
            <AnalyticsBreakdownMetricTableRow
              key={createMetricEntryId(entry)}
              entry={entry}
              currency={currency}
            />
          ))}
        </tbody>
      </MetricTable>
    </Section>
  )
}
