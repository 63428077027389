/* eslint-disable react/display-name */
import { BuilderValidationContext } from '@context/BuilderValidation'

export const withBuilderValidation = (Component) => (props) => {
  return (
    <BuilderValidationContext.Consumer>
      {(contextProps) => <Component {...props} {...contextProps} />}
    </BuilderValidationContext.Consumer>
  )
}
