import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { ILicenseState, MediaObjectLicenseOptionsActionTypes } from '@store/licenses'
import { PurchaseLicenseActionTypes, RequestLicenseActionTypes } from '@store/mediaObjects'
import { returnSecondIfDeepEqual } from '@utils/equals'

const initialState: ILicenseState = {
  licenseOptionsByMediaObjectId: {},
  isFetching: false,
  isPurchasing: false,
  isRequestingLicense: false,
  errors: undefined,
}

const reducer: Reducer<ILicenseState> = (state = initialState, action) => {
  switch (action.type) {
    case MediaObjectLicenseOptionsActionTypes.FETCH_OPTIONS: {
      return {
        ...state,
        isFetching: true,
      }
    }

    case RequestLicenseActionTypes.REQUEST_LICENSE: {
      return {
        ...state,
        isRequestingLicense: true,
        errors: undefined,
      }
    }

    case PurchaseLicenseActionTypes.PURCHASE_LICENSE: {
      return {
        ...state,
        isPurchasing: true,
        errors: undefined,
      }
    }

    case MediaObjectLicenseOptionsActionTypes.FETCH_OPTIONS_SUCCESS: {
      const { mediaObjectId, licenseOptions } = action.payload
      return {
        ...state,
        errors: undefined,
        isFetching: false,
        licenseOptionsByMediaObjectId: returnSecondIfDeepEqual(
          {
            ...state.licenseOptionsByMediaObjectId,
            [mediaObjectId]: licenseOptions,
          },
          state.licenseOptionsByMediaObjectId,
        ),
      }
    }

    case RequestLicenseActionTypes.REQUEST_LICENSE_SUCCESS:
    case PurchaseLicenseActionTypes.PURCHASE_LICENSE_SUCCESS: {
      return {
        ...state,
        errors: undefined,
        isPurchasing: false,
        isRequestingLicense: false,
      }
    }

    case RequestLicenseActionTypes.REQUEST_LICENSE_ERROR:
    case PurchaseLicenseActionTypes.PURCHASE_LICENSE_ERROR:
    case MediaObjectLicenseOptionsActionTypes.FETCH_OPTIONS_ERROR: {
      return {
        ...state,
        isFetching: false,
        isPurchasing: false,
        isRequestingLicense: false,
        errors: action.payload,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as licensesReducer }
