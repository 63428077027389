import { FormattedMessage } from 'react-intl'

import { Container, Text } from '@tribegroup'
import { MetricHeaderTable } from '../Breakdown.styled'

export const AnalyticsBreakdownMetricTableHeader = () => (
  <Container leftInnerSpacing={12.5}>
    <MetricHeaderTable>
      <tbody>
        <tr>
          <td width="40%">
            <Text>
              <FormattedMessage id="analytics.breakdown.table.header.metric" />
            </Text>
          </td>
          <td width="35%">
            <Text>
              <FormattedMessage id="analytics.breakdown.table.header.source" />
            </Text>
          </td>
          <td width="25%" />
        </tr>
      </tbody>
    </MetricHeaderTable>
  </Container>
)
