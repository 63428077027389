import React from 'react'

import Notice, { INoticeConsumerProps } from '../Notice'
import NoticeEmptyImage from './Image'

const NoticeEmpty: React.FC<INoticeConsumerProps> = ({
  title,
  subtitle,
  education,
  alt,
  topOuterSpacing,
  image = <NoticeEmptyImage alt={alt} />,
}) => (
  <Notice
    title={title}
    subtitle={subtitle}
    topOuterSpacing={topOuterSpacing}
    education={education}
    image={image}
  />
)

export default NoticeEmpty
