import { useState } from 'react'

import Image, { IImageProps } from '@components/UI/Image/Image'
import { MediaTypes } from '@enums'
import { useModal } from '@hooks/useModal'
import { IFeedback } from '@store/feedback'
import {
  ChatMessageAttachmentPreviewModal,
  ChatMessageAttachmentPreviewModalProps,
} from '../Attachment/PreviewModal'
import { ThumbnailButton } from './Message.styled'

type Props = {
  feedback: IFeedback
  large?: boolean
}

export const SubmissionChatMessageThumbnail: React.FC<Props> = ({ feedback, large }) => {
  const { showModal } = useModal()
  const isPdf = feedback.attachment?.media_type === 'pdf'
  const [showPreviewPlaceholder, setShowPreviewPlaceholder] = useState(true)

  if (!feedback.attachment || isPdf) {
    return
  }

  const previewProps: IImageProps = {
    src: feedback.attachment?.thumbnail_url,
    alt: feedback.attachment?.file_name,
    containerHeight: 6.25,
    contain: true,
    onLoad: () => setShowPreviewPlaceholder(false),
  }

  if (!large) {
    previewProps.containerHeight = 2.5
    previewProps.width = 2.5
    previewProps.fitToContainer = true
  }

  const showPreview = () => {
    const initials = feedback.author_name
      .split(' ')
      .map((name) => name[0])
      .join('')
      .toUpperCase()
    const previewModalProps: ChatMessageAttachmentPreviewModalProps = {
      url: feedback.attachment!.original_url!,
      mediaType: feedback.attachment!.media_type as MediaTypes,
      authorName: feedback.author_name,
      initials,
      createdAt: feedback.created_at,
    }
    showModal(ChatMessageAttachmentPreviewModal, previewModalProps)
  }

  return (
    <ThumbnailButton
      onClick={showPreview}
      aria-label={`Open ${feedback.attachment.file_name}`}
      showPlaceholder={showPreviewPlaceholder}
      large={large}
      disabled={showPreviewPlaceholder}
    >
      <Image {...previewProps} />
    </ThumbnailButton>
  )
}
