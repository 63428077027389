import styled, { css } from 'styled-components'

export const BudgetSectionWrapper = styled.div`
  margin-top: 1.5rem;
`

export const BudgetInputWrapper = styled.div`
  margin-top: 2rem;
  ${({ topOuterSpacing }) =>
    topOuterSpacing &&
    css`
      margin-top: ${topOuterSpacing}rem;
    `};
  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `};
`

export const BudgetSliderWrapper = styled.div`
  margin-top: 1.25rem;
`
