import React from 'react'

import Theme from '@theme'
import { Container, Text } from '@tribegroup'

const ErrorMessage: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Container topOuterSpacing={0.125} data-testid="brand-fans-group-create-form-error-message">
    <Text small color={Theme.errorColor}>
      {children}
    </Text>
  </Container>
)

export { ErrorMessage }
export default ErrorMessage
