import styled from 'styled-components'
import Flex from 'styled-flex-component'

const BuilderActionsStyled = styled(Flex)`
  min-width: 21.25rem;
  padding-right: 1rem;
  box-sizing: border-box;
  flex-shrink: 0;
`

export const BuilderEditActionStyled = styled(Flex)`
  min-width: 11.125rem;
  padding-right: 1rem;
  box-sizing: border-box;
  flex-shrink: 0;
`

export const ButtonLinksWrapper = styled(Flex)`
  margin-right: 1.25rem;
`

export default BuilderActionsStyled
