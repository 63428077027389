import React from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'
import { compose } from 'recompose'

import StopPropagation from '@components/UI/StopPropagation/StopPropagation'
import { IModalContextProps } from '@context/Modal'
import { EventTrackingNames } from '@enums'
import { withModal } from '@hocs'
import Theme from '@theme'
import { Icon } from '@tribegroup'
import BrandFanInfluencerIdentityRowTrackedRoute from './../InfluencerIdentity/Row/TrackedRoute'
import BrandFansAudienceInsights from './AudienceInsights'

interface IProps {
  identityId: number
}

interface IInternalProps extends IProps, IModalContextProps, InjectedIntlProps {}

const BrandFansAudienceInsightsTrigger: React.FC<IInternalProps> = ({
  showModal,
  identityId,
  intl,
}) => {
  const onOpenModal = () => showModal(BrandFansAudienceInsights, { identityId })
  return (
    <StopPropagation>
      <BrandFanInfluencerIdentityRowTrackedRoute
        eventName={EventTrackingNames.BrandFansViewInsights}
        identityId={identityId}
      >
        <Icon
          size={0.875}
          glyph="insights"
          data-testid="audience-insights-trigger"
          onClick={onOpenModal}
          color={Theme.primaryColor}
          data-tooltip-id="base-tooltip"
          data-tooltip-content={intl.formatMessage({ id: 'audienceInsights.tooltip' })}
        />
      </BrandFanInfluencerIdentityRowTrackedRoute>
    </StopPropagation>
  )
}

export { BrandFansAudienceInsightsTrigger }
export default compose<IInternalProps, IProps>(
  withModal,
  injectIntl,
)(BrandFansAudienceInsightsTrigger)
