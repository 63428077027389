import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { IApplicationState } from '@store'
import { IMember, removeMember, selectRemoveMemberLink } from '@store/members'
import Theme from '@theme'
import { Menu, MenuItem } from '@tribegroup'

interface IProps {
  member: IMember
  onClick: VoidFunction
  showRemoveConfirmation: VoidFunction
}

interface IInternalProps extends IProps {
  removeMember: typeof removeMember
  hasRemoveMemberLink?: boolean
}

const InboxMemberPopupMenu: React.FC<IInternalProps> = ({
  member,
  hasRemoveMemberLink,
  showRemoveConfirmation,
  onClick,
}) => {
  return (
    <Menu width={11.75} onClick={onClick}>
      <MenuItem
        selected
        iconColor={Theme.primaryColor}
        disabled
        elementName="menu-item-member-role"
      >
        <FormattedMessage id={`inbox.sidebar.members.${member.role_type}`} />
      </MenuItem>
      {hasRemoveMemberLink && (
        <MenuItem
          destructive
          onClick={showRemoveConfirmation}
          elementName="menu-item-remove-member"
        >
          <FormattedMessage
            id="inbox.sidebar.members.removeMember"
            values={{ name: member.first_name || member.email }}
          />
        </MenuItem>
      )}
    </Menu>
  )
}

const mapStateToProps = (state: IApplicationState, { member }) => ({
  hasRemoveMemberLink: Boolean(selectRemoveMemberLink(state, member.id)),
})

const mapDispatchToProps = {
  removeMember,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export default compose<IInternalProps, IProps>(connector)(InboxMemberPopupMenu)
