import { IMeta } from '@store'
import { BulkFeedbackActionTypes } from '../types'

interface IBulkAddFeedbackParams {
  message_body: string
  submission_ids: ReadonlyArray<number>
}

export const bulkAddFeedback = (
  campaignId: number,
  params: IBulkAddFeedbackParams,
  meta: IMeta,
) => ({
  payload: { campaignId, params },
  type: BulkFeedbackActionTypes.BULK_ADD_REQUEST,
  meta,
})

export const bulkAddFeedbackError = (error: any) => ({
  payload: error,
  type: BulkFeedbackActionTypes.BULK_ADD_ERROR,
})

export const bulkAddFeedbackSuccess = (
  campaignId: number,
  submissionIds: ReadonlyArray<number>,
  meta?: object,
) => ({
  payload: { campaignId, submissionIds },
  meta,
  type: BulkFeedbackActionTypes.BULK_ADD_SUCCESS,
})
