import React from 'react'

import { Icon } from '@tribegroup'
import CampaignBrandFansPopover from '../Popover'
import { IndicatorWrapper, Root, VisibleOnHover } from './Indicator.styled'

interface IProps {
  campaignId: number
}

const CampaignBrandFansIndicator: React.FC<IProps> = ({ campaignId }) => {
  return (
    <Root data-testid="campaign-brand-fans-indicator">
      <IndicatorWrapper>
        <Icon glyph="star-rating" size={1.125} />
      </IndicatorWrapper>
      <VisibleOnHover>
        <CampaignBrandFansPopover campaignId={campaignId} />
      </VisibleOnHover>
    </Root>
  )
}

export { CampaignBrandFansIndicator }
export default CampaignBrandFansIndicator
