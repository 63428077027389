import React, { PropsWithChildren } from 'react'

import { connect } from 'react-redux'
import { compose } from 'recompose'

import { trackEvent } from '@store/analytics'

export interface IBaseRouteProps {
  eventTrigger?: 'click' | 'change' | 'mouseEnter'
  eventName: string
  inline?: boolean
  targetLink?: boolean
  disableTracking?: boolean
}

const EVENT_TRIGGER_MAP = {
  click: 'onClick',
  change: 'onChange',
  mouseEnter: 'onMouseEnter',
}

export interface ITrackedRoute extends IBaseRouteProps {
  properties: object
}

interface IInternalProps extends ITrackedRoute {
  trackEvent: typeof trackEvent
}

const TrackedRoute: React.FC<PropsWithChildren<IInternalProps>> = ({
  eventTrigger = 'click',
  eventName,
  properties,
  trackEvent: trackEventAction,
  targetLink,
  inline,
  children,
  disableTracking,
}) => {
  const callback = (event: React.SyntheticEvent) => {
    if (disableTracking) {
      return
    }
    if (targetLink) {
      const element = event.target as HTMLElement
      if (element.nodeName.toUpperCase() === 'A') {
        trackEventAction(eventName, properties)
      }
    } else {
      trackEventAction(eventName, properties)
    }
  }

  const Wrapper = inline ? 'span' : 'div'
  const eventType = EVENT_TRIGGER_MAP[eventTrigger]
  const eventListener = { [eventType]: callback }

  return (
    <Wrapper {...eventListener} role="button">
      {children}
    </Wrapper>
  )
}

const mapDispatchToProps = {
  trackEvent,
}

export { TrackedRoute }
export default compose<IInternalProps, PropsWithChildren<ITrackedRoute>>(
  connect(undefined, mapDispatchToProps),
)(TrackedRoute)
