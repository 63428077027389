import React from 'react'

import { InjectedIntlProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import ActionIcon from '@components/UI/ActionIcon'
import { IInfluencerIdentityIdContextProps } from '@context/InfluencerIdentityId'
import { withContextInfluencerIdentityId } from '@hocs'
import { IApplicationState } from '@store'
import { selectInfluencerIdentityHasShortlisted } from '@store/influencerIdentities'
import { selectCampaignId } from '@store/router'
import { Container } from '@tribegroup'

interface IInternalProps extends InjectedIntlProps {
  hasShortlist: boolean
}

const InfluencerIdentityRowPerformanceShortlisted: React.FC<IInternalProps> = ({
  intl,
  hasShortlist,
}) => {
  return (
    <Container leftInnerSpacing={1.25} data-testid="influencer-identity-row-shortlisted">
      <ActionIcon
        glyph="heart"
        highlighted={hasShortlist}
        size={1.5}
        tooltip={intl.formatMessage({ id: 'core.text.shortlisted' })}
        noHover
      />
    </Container>
  )
}

interface IStateProps extends IInfluencerIdentityIdContextProps, RouteComponentProps {}

const mapStateToProps = (
  state: IApplicationState,
  { match, contextInfluencerIdentityId }: IStateProps,
) => {
  const campaignId = selectCampaignId(match) as number
  return {
    hasShortlist: selectInfluencerIdentityHasShortlisted(
      state,
      campaignId,
      contextInfluencerIdentityId,
    ),
  }
}

export { InfluencerIdentityRowPerformanceShortlisted }
export default compose(
  withContextInfluencerIdentityId,
  injectIntl,
  withRouter,
  connect(mapStateToProps),
)(InfluencerIdentityRowPerformanceShortlisted)
