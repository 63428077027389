import React from 'react'

import { connect, useDispatch } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import AnalyticsPostCampaignReportPost from '@components/Analytics/PostCampaignReport/Post/Post'
import { AnalyticsPostCampaignReportReel } from '@components/Analytics/PostCampaignReport/Reel/Reel'
import AnalyticsPostCampaignReportStory from '@components/Analytics/PostCampaignReport/Story/Story'
import AnalyticsPostCampaignReportSummary from '@components/Analytics/PostCampaignReport/Summary/Summary'
import AnalyticsPostCampaignReportTikTok from '@components/Analytics/PostCampaignReport/TikTok/TikTok'
import AnalyticsPostCampaignReportVideo from '@components/Analytics/PostCampaignReport/Video/Video'
import Loading from '@components/UI/Loading'
import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { selectIsCampaignFetched } from '@store/campaigns'
import {
  fetchCampaignMetrics,
  fetchPostMetrics,
  fetchReelMetrics,
  fetchStoryMetrics,
  fetchTikTokMetrics,
  fetchVideoMetrics,
  selectIsCampaignMetricsInErrorState,
  selectIsSummaryActive,
  selectIsSummaryFetched,
  selectShouldFetchPostMetrics,
  selectShouldFetchReelMetrics,
  selectShouldFetchStoryMetrics,
  selectShouldFetchTikTokMetrics,
  selectShouldFetchVideoMetrics,
  selectShowLoading,
} from '@store/postCampaignReport'
import { selectActivePCRChannel, selectCampaignId } from '@store/router'
import PostCampaignReporPerformanceList from '../List'

export interface IInternalProps {
  activeChannel: PCRChannels
  campaignId: number
  isCampaignFetched?: boolean
  isSummaryFetched?: boolean
  showLoading?: boolean
  isCampaignMetricsInErrorState?: boolean
  shouldFetchPostMetrics?: boolean
  shouldFetchVideoMetrics?: boolean
  shouldFetchStoryMetrics?: boolean
  shouldFetchTikTokMetrics?: boolean
  shouldFetchReelMetrics?: boolean
  isSummaryActive?: boolean
  fetchCampaignMetrics: typeof fetchCampaignMetrics
  fetchPostMetrics: typeof fetchPostMetrics
  fetchVideoMetrics: typeof fetchVideoMetrics
  fetchStoryMetrics: typeof fetchStoryMetrics
}

const PostCampaignReporPerformanceListWrapper: React.FC<IInternalProps> = ({
  activeChannel,
  campaignId,
  isCampaignFetched,
  isSummaryFetched,
  showLoading,
  isCampaignMetricsInErrorState,
  shouldFetchPostMetrics,
  shouldFetchVideoMetrics,
  shouldFetchStoryMetrics,
  shouldFetchTikTokMetrics,
  shouldFetchReelMetrics,
  isSummaryActive,
  fetchCampaignMetrics: fetchCampaignMetricsAction,
  fetchPostMetrics: fetchPostMetricsAction,
  fetchVideoMetrics: fetchVideoMetricsAction,
  fetchStoryMetrics: fetchStoryMetricsAction,
}) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (isCampaignFetched && !isCampaignMetricsInErrorState) {
      fetchCampaignMetricsAction(campaignId)
    }

    if (shouldFetchPostMetrics) {
      fetchPostMetricsAction(campaignId, activeChannel)
    }

    if (shouldFetchVideoMetrics) {
      fetchVideoMetricsAction(campaignId, activeChannel)
    }

    if (shouldFetchStoryMetrics) {
      fetchStoryMetricsAction(campaignId, activeChannel)
    }

    if (shouldFetchTikTokMetrics) {
      dispatch(fetchTikTokMetrics(campaignId, activeChannel))
    }

    if (shouldFetchReelMetrics) {
      dispatch(fetchReelMetrics(campaignId))
    }
  }, [isCampaignFetched, isSummaryFetched, activeChannel])

  if (showLoading) {
    return <Loading height={30} />
  }

  if (isSummaryActive) {
    return <AnalyticsPostCampaignReportSummary />
  }

  if (activeChannel === PCRChannels.IGPosts) {
    return <AnalyticsPostCampaignReportPost />
  }

  if (activeChannel === PCRChannels.IGVideos) {
    return <AnalyticsPostCampaignReportVideo />
  }

  if (activeChannel === PCRChannels.IGStories) {
    return <AnalyticsPostCampaignReportStory />
  }

  if (activeChannel === PCRChannels.TikTok) {
    return <AnalyticsPostCampaignReportTikTok />
  }

  if (activeChannel === PCRChannels.Reel) {
    return <AnalyticsPostCampaignReportReel />
  }

  return <PostCampaignReporPerformanceList />
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const activeChannel = selectActivePCRChannel(state)
  const campaignId = selectCampaignId(match)!
  return {
    activeChannel,
    campaignId,
    isCampaignFetched: selectIsCampaignFetched(state, campaignId),
    isSummaryFetched: selectIsSummaryFetched(state, campaignId),
    showLoading: selectShowLoading(state),
    shouldFetchPostMetrics: selectShouldFetchPostMetrics(state, campaignId, activeChannel),
    shouldFetchVideoMetrics: selectShouldFetchVideoMetrics(state, campaignId, activeChannel),
    shouldFetchStoryMetrics: selectShouldFetchStoryMetrics(state, campaignId, activeChannel),
    shouldFetchTikTokMetrics: selectShouldFetchTikTokMetrics(state, campaignId, activeChannel),
    shouldFetchReelMetrics: selectShouldFetchReelMetrics(state, campaignId, activeChannel),
    isCampaignMetricsInErrorState: selectIsCampaignMetricsInErrorState(state),
    isSummaryActive: selectIsSummaryActive(state),
  }
}

const mapDispatchToProps = {
  fetchCampaignMetrics,
  fetchPostMetrics,
  fetchVideoMetrics,
  fetchStoryMetrics,
}

export { PostCampaignReporPerformanceListWrapper }
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PostCampaignReporPerformanceListWrapper)
