import styled from 'styled-components'

import Theme from '@theme'

export const Initials = styled.div`
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  flex-grow: 0;
  border: solid 1px ${Theme.grey600Color};
  font-size: 0.75rem;
  line-height: 1.43rem;
  justify-content: center;
  display: flex;
  margin-right: 0.5rem;
  box-sizing: border-box;
`
