import { Fragment, PropsWithChildren } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { compose } from 'recompose'

import ConnectedAccountsSideBarItem from '@components/ConnectedAccounts/SideBarItem'
import Breakpoint from '@components/UI/Breakpoint'
import { Hr } from '@components/UI/Hr/Hr.styled'
import { IMobileMenuControlsContextProps } from '@context/MobileMenuControls'
import { PageRoutes } from '@enums'
import { withMobileMenuControls } from '@hocs'
import { useDrawer } from '@hooks/useDrawer'
import { IApplicationState } from '@store'
import { selectBrandManagerIdentitiesLink } from '@store/me'
import Theme from '@theme'
import { Container } from '@tribegroup'
import SideBarProfileMenu from './ProfileMenu'
import SideBarStyled, { SideBarFooterStyled } from './SideBar.styled'

interface IProps {
  id?: string
}

interface IInternalProps
  extends IProps,
    ConnectedProps<typeof connector>,
    IMobileMenuControlsContextProps {}

const EXCLUDE_CONNECT_ACCOUNT_FOR_ROUTES = [
  PageRoutes.BillingCampaigns,
  PageRoutes.BillingContentLibrary,
]

const SideBar: React.FC<PropsWithChildren<IInternalProps>> = ({
  id = 'side-bar',
  open,
  hasBrandManagerIdentitiesLink,
  children,
}) => {
  const { isOpen } = useDrawer()

  const { pathname } = useLocation()

  const showConnectAccount =
    hasBrandManagerIdentitiesLink &&
    EXCLUDE_CONNECT_ACCOUNT_FOR_ROUTES.findIndex((route) => pathname.includes(route)) === -1

  return (
    <Fragment>
      <SideBarStyled open={open} data-cy-element={id} data-testid="main-side-bar" expanded={isOpen}>
        <div className="scrollable-pane">{children}</div>
        <SideBarFooterStyled>
          <Container bottomOuterSpacing={0.75}>
            <Hr margin={0} color={Theme.grey400Color} />
          </Container>
          {showConnectAccount && (
            <Breakpoint tabletPortrait tabletLandscape desktop>
              <ConnectedAccountsSideBarItem />
            </Breakpoint>
          )}
          <SideBarProfileMenu />
        </SideBarFooterStyled>
      </SideBarStyled>
    </Fragment>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  hasBrandManagerIdentitiesLink: Boolean(selectBrandManagerIdentitiesLink(state)),
})

const connector = connect(mapStateToProps)

export default compose<IInternalProps, PropsWithChildren<IProps>>(
  connector,
  withMobileMenuControls,
)(SideBar)
