import { PropsWithChildren, PureComponent } from 'react'

import ImageBackground from '@components/UI/ImageBackground'
import backgroundImage from '@images/hero-image.jpg'

class FullImageBackground extends PureComponent<PropsWithChildren> {
  render() {
    return (
      <ImageBackground
        opacity={0.25}
        image={backgroundImage}
        centerContent
        additionalStyles={{
          'min-height': '100vh',
        }}
      >
        {this.props.children}
      </ImageBackground>
    )
  }
}

export default FullImageBackground
