import { useEffect } from 'react'

import { compose } from 'recompose'

import SubmissionCreatorNotePanel from '@components/Submission/CreatorNote/Panel/Panel'
import SubmissionFullStory from '@components/Submission/FullStory/FullStory'
import { MultiframeAutoplay, MultiframeFullMode, MultiframeSlider } from '@components/UI/Multiframe'
import {
  MultiframePaginationBars,
  MultiframePaginationDots,
} from '@components/UI/Multiframe/Pagination'
import { IMultiframeContextProps } from '@context/MultiframeControls'
import { AspectRatios, SocialSubmissionTypes } from '@enums'
import { withMultiframeControls } from '@hocs'
import hooks from '@hooks'
import SubmissionCardMediaMultiframeNavigation from './Navigation/Navigation'
import { PaginationWrapper, SubmissionCardMediaMultiframelWrapper } from './Multiframe.styled'

export interface ISubmissionCardMediaMultiframeProps {
  socialSubmissionType: SocialSubmissionTypes
}

interface IInternalProps extends ISubmissionCardMediaMultiframeProps, IMultiframeContextProps {}

export const SubmissionCardMediaMultiframe = ({
  socialSubmissionType,
  autoplayPaused,
  frames,
  goToFrame,
  pause,
}: IInternalProps) => {
  const isStory = socialSubmissionType === SocialSubmissionTypes.Story
  const isCarousel = socialSubmissionType === SocialSubmissionTypes.Carousel
  const isAutoplay = isStory && !autoplayPaused
  const hasMultipleFrames = frames.length > 1
  const { receiptNumber } = hooks.useLocationSearch()

  useEffect(() => {
    if (!isCarousel || !receiptNumber) {
      return
    }
    const index = frames.findIndex(
      (frame) => frame.transacted_license?.receipt_number === receiptNumber,
    )
    if (index === -1) {
      return
    }
    goToFrame(index + 1)
  }, [frames, receiptNumber, isCarousel])

  useEffect(() => {
    pause(!isStory)
  }, [])

  return (
    <SubmissionCardMediaMultiframelWrapper>
      <MultiframeSlider
        aspectRatio={AspectRatios.ONE_BY_ONE}
        showVideoControls={isCarousel}
        showPlayIcon={!isStory}
      />
      {isStory && (
        <MultiframeFullMode>
          <SubmissionFullStory />
        </MultiframeFullMode>
      )}
      {hasMultipleFrames && <SubmissionCardMediaMultiframeNavigation />}
      <PaginationWrapper>
        {isStory && <MultiframePaginationBars />}
        {hasMultipleFrames && isCarousel && <MultiframePaginationDots />}
      </PaginationWrapper>
      {isAutoplay && <MultiframeAutoplay />}
      <SubmissionCreatorNotePanel />
    </SubmissionCardMediaMultiframelWrapper>
  )
}

export default compose<IInternalProps, ISubmissionCardMediaMultiframeProps>(withMultiframeControls)(
  SubmissionCardMediaMultiframe,
)
