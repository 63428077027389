import React from 'react'

import { FormattedMessage } from 'react-intl'

import { Title } from './Header.styled'

const ShareableContentLibraryTitle: React.FC = () => (
  <Title>
    <FormattedMessage id="contentLibrary.shareable.header" />
  </Title>
)

export { ShareableContentLibraryTitle }
export default ShareableContentLibraryTitle
