import { call, put, select, takeEvery } from 'redux-saga/effects'

import { EventTrackingNames } from '@enums'
import { IHateoasLink } from '@store'
import { selectSubmissionTrackingProps, trackEvent } from '@store/analytics'
import { selectAuthToken } from '@store/auth'
import {
  addFeedback,
  addFeedbackError,
  addFeedbackSuccess,
  FeedbackActionTypes,
  IFeedbackAttachment,
  selectAddFeedbackLink,
  selectGeneratePresignedUrlLink,
} from '@store/feedback'
import { removeFeedbackDraft } from '@store/feedbackDraft'
import { selectMeId } from '@store/me'
import { fetchSubmission } from '@store/submissions'
import { networkRequest, presignedURLMediaUpload } from '@utils'

interface IAddFeedbackBody {
  message_body: string
  attachment?: Partial<IFeedbackAttachment>
}

export function* handleAddFeedback(action: ReturnType<typeof addFeedback>) {
  const { submissionId, campaignId, requestBody } = action.payload
  const { message_body, file } = requestBody
  const { refreshSubmission } = action.meta
  let feedbackAttachment: IFeedbackAttachment | undefined
  const authToken = yield select(selectAuthToken)
  const meId = yield select(selectMeId)
  const isEmptyMessage = !message_body || message_body.length === 0
  try {
    if (file) {
      const preSignedUrlLink = yield select(selectGeneratePresignedUrlLink, submissionId)
      if (preSignedUrlLink) {
        feedbackAttachment = yield call(
          networkRequest,
          preSignedUrlLink.method,
          preSignedUrlLink.href,
          undefined,
          authToken,
        )

        if (feedbackAttachment) {
          const uploadSuccess = yield call(
            presignedURLMediaUpload,
            'PUT',
            feedbackAttachment.upload_url,
            file,
            authToken,
          )

          if (uploadSuccess) {
            feedbackAttachment = {
              ...feedbackAttachment,
              file_name: file.name,
              content_type: file.type,
            }
            yield put(
              trackEvent(EventTrackingNames.FeedbackUploadSuccess, {
                file_size: file.size,
                file_content_type: file.type,
                submission_id: submissionId,
                brief_id: campaignId,
                sender_id: meId,
                sender_type: 'brand_manager',
                is_empty_message: isEmptyMessage,
              }),
            )
          }
        }
      }
    }

    const addFeedbackLink: IHateoasLink = yield select(selectAddFeedbackLink, submissionId)
    let addFeedbackBody: IAddFeedbackBody = {
      message_body,
    }
    if (feedbackAttachment) {
      addFeedbackBody = {
        ...addFeedbackBody,
        attachment: feedbackAttachment,
      }
    }
    const feedback = yield call(
      networkRequest,
      addFeedbackLink.method,
      addFeedbackLink.href,
      addFeedbackBody,
      authToken,
    )
    yield put(addFeedbackSuccess(submissionId, feedback))
    yield put(removeFeedbackDraft(submissionId))

    const eventProperties = yield select(selectSubmissionTrackingProps, campaignId, submissionId)
    yield put(trackEvent(EventTrackingNames.SubmitSubmissionFeedback, eventProperties))

    if (refreshSubmission) {
      yield put(fetchSubmission(submissionId, campaignId))
    }
  } catch (error) {
    yield put(addFeedbackError(error))
    if (file) {
      yield put(
        trackEvent(EventTrackingNames.FeedbackUploadFailure, {
          file_size: file.size,
          file_content_type: file.type,
          submission_id: submissionId,
          brief_id: campaignId,
          sender_id: meId,
          sender_type: 'brand_manager',
          is_empty_message: isEmptyMessage,
          error,
        }),
      )
    }
  }
}

export function* watchAddFeedbackRequest() {
  yield takeEvery(FeedbackActionTypes.ADD_REQUEST, handleAddFeedback)
}
