import React, { PropsWithChildren } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { TrackedRoute } from '@components/UI/TrackedRoute'
import { BrandFanFilters, EventTrackingNames } from '@enums'
import { IApplicationState } from '@store'
import { selectBrandFanStatsForCurrentFilter } from '@store/brandFans'
import { IBrand, selectBrands } from '@store/brands'
import {
  selectBrandFansCurrentView,
  selectCurrentBrandFansBrandFilter,
  selectCurrentBrandFansSort,
} from '@store/router'

interface IProps {
  brandName?: string
  sortBy?: string
  source: 'brand_filter' | 'sort_filter'
}

interface IInternalProps extends IProps, ConnectedProps<typeof connector> {}

const ALL_BRANDS = 'all_brands'

const BrandFansInfluencerIdentityTopPanelTrackedRoute: React.FC<PropsWithChildren<
  IInternalProps
>> = ({
  source,
  children,
  brands,
  count,
  brandName,
  sortBy,
  currentFilter,
  currentSort,
  currentBrandFilter,
}) => {
  const brandNameToUse = brandName || currentBrandFilter
  const selectedBrand =
    (brandNameToUse !== ALL_BRANDS && brands.find((brand) => brand.name === brandNameToUse)) ||
    ({} as IBrand)
  return (
    <TrackedRoute
      eventName={
        currentFilter === BrandFanFilters.BrandFans
          ? EventTrackingNames.BrandFansViewBrandFans
          : EventTrackingNames.BrandFansViewAllCreators
      }
      properties={{
        source,
        number_of_identities: count,
        target_brand_filter_name: brandNameToUse,
        target_brand_filter_id: selectedBrand.id,
        target_sort_filter: sortBy || currentSort,
      }}
    >
      {children}
    </TrackedRoute>
  )
}

const mapStateToProps = (state: IApplicationState) => ({
  currentFilter: selectBrandFansCurrentView(state),
  count: selectBrandFanStatsForCurrentFilter(state),
  currentSort: selectCurrentBrandFansSort(state),
  currentBrandFilter: selectCurrentBrandFansBrandFilter(state),
  brands: selectBrands(state),
})

const connector = connect(mapStateToProps)
export { BrandFansInfluencerIdentityTopPanelTrackedRoute }
export default compose<IInternalProps, PropsWithChildren<IProps>>(
  withRouter,
  connector,
)(BrandFansInfluencerIdentityTopPanelTrackedRoute)
