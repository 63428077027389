import { CampaignMetricsActionTypes, ICampaignMetrics } from '@store/postCampaignReport'

export const fetchCampaignMetrics = (campaignId: number) => ({
  payload: campaignId,
  type: CampaignMetricsActionTypes.FETCH_REQUEST,
})

export const fetchCampaignMetricsSuccess = (campaignMetrics: ICampaignMetrics) => ({
  payload: campaignMetrics,
  type: CampaignMetricsActionTypes.FETCH_SUCCESS,
})

export const fetchCampaignMetricsError = (error) => ({
  payload: error,
  type: CampaignMetricsActionTypes.FETCH_ERROR,
})
