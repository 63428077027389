import { IPreferences, UpdatePreferencesActionTypes } from '@store/me'

export const updatePreferences = (preference: Partial<IPreferences>) => ({
  payload: preference,
  type: UpdatePreferencesActionTypes.UPDATE_PREFERENCES_REQUEST,
})

export const updatePreferencesError = (error: any) => ({
  payload: error,
  type: UpdatePreferencesActionTypes.UPDATE_PREFERENCES_ERROR,
})
