import BuilderBudgetForm from '@components/Builder/Budget/Form'
import BuilderTitleBar from '@components/Builder/TitleBar'
import HeadTag from '@components/UI/HeadTag'
import MainLayout from '@components/UI/MainLayout'
import { BuilderValidationProvider } from '@context/BuilderValidation'
import { CampaignProvider } from '@context/Campaign'
import { DraftProvider } from '@context/Draft'
import { BuilderSteps } from '@enums'

const SetBudgetPage = () => (
  <MainLayout>
    <CampaignProvider>
      <DraftProvider>
        <BuilderValidationProvider currentStep={BuilderSteps.BUDGET}>
          <HeadTag id={`builder.steps.${BuilderSteps.BUDGET}.title`} />
          <BuilderTitleBar />
          <BuilderBudgetForm />
        </BuilderValidationProvider>
      </DraftProvider>
    </CampaignProvider>
  </MainLayout>
)

export default SetBudgetPage
