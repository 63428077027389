import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import Flex, { FlexItem } from 'styled-flex-component'

import { IApplicationState } from '@store'
import {
  selectFirstPublishedDateText,
  selectLastPublishedDateText,
  selectShowCampaignReportTitleDates,
} from '@store/postCampaignReport'
import { selectCampaignId } from '@store/router'
import Theme from '@theme'
import { H3, Header } from '@tribegroup'
import PostCampaignReportTitleButton from './Button/Button'

export interface IInternalProps {
  firstPublishedDateText: string
  lastPublishedDateText: string
  showCampaignReportTitleDates?: boolean
}

const PostCampaignReportTitle: React.FC<IInternalProps> = ({
  firstPublishedDateText,
  lastPublishedDateText,
  showCampaignReportTitleDates,
}) => {
  return (
    <Flex justifyBetween>
      <FlexItem>
        <H3 color={Theme.grey900Color} bottomOuterSpacing={0.5}>
          <FormattedMessage id="postCampaignReport.header.title" />
        </H3>
        {showCampaignReportTitleDates && (
          <Header
            data-testid="pcr-header"
            small
            uppercase
            color={Theme.grey700Color}
            bottomOuterSpacing={0.5}
          >
            <FormattedMessage
              id="postCampaignReport.header.subtitle"
              values={{ firstPublishedDateText, lastPublishedDateText }}
            />
          </Header>
        )}
      </FlexItem>
      <FlexItem>
        <PostCampaignReportTitleButton />
      </FlexItem>
    </Flex>
  )
}

const mapStateToProps = (state: IApplicationState, { match }) => {
  const campaignId = selectCampaignId(match)!
  return {
    firstPublishedDateText: selectFirstPublishedDateText(state, campaignId),
    lastPublishedDateText: selectLastPublishedDateText(state, campaignId),
    showCampaignReportTitleDates: selectShowCampaignReportTitleDates(state, campaignId),
  }
}

export { PostCampaignReportTitle }
export default compose<IInternalProps, {}>(
  withRouter,
  connect(mapStateToProps),
)(PostCampaignReportTitle)
