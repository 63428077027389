export const INBOX_FILTER = 'inbox'
export const SHORTLISTED_FILTER = 'shortlisted'
export const PREAPPROVED_FILTER = 'preapproved'
export const SHAREABLE_CONTENT_LIBRARY = 'shareable_content_library'
export const CONTENT_LIBRARY = 'content_library'
export const CONTENT_LIBRARY_ITEM_VIEW = 'content_library_item_view'
export const SUBMISSIONS_VIEW = 'submission_view'
export const INBOX_VIEW = 'inbox'
export const BEFORE_24_HOURS_IN_MINS = 1439
export const CAMPAIGN_BUILDER = 'campaign_builder'
export const ALL = 'all'
export const BILLING = 'billing'

export const PENDING_SUBMISSION_DECLINE_REASONS_CATEGORY = [
  'Content',
  'Audience',
  'Price',
  'Our fault',
]
export const PREAPPROVED_SUBMISSION_DECLINE_REASONS_CATEGORY = ['Preapproval']
