import React from 'react'

import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'

import { FULL_DATE } from '@constants'
import Theme from '@theme'
import SubmissionThumbnailStatusLabel from '.'

interface IProps {
  date: Date
}

const SubmissionThumbnailStatusLabelPost: React.FC<IProps> = ({ date }) => (
  <SubmissionThumbnailStatusLabel
    firstText={<FormattedMessage id="submission.card.publishSchedule.post" />}
    secondText={format(date, FULL_DATE)}
    color={Theme.grey900Color}
  />
)

export default SubmissionThumbnailStatusLabelPost
