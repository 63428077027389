import React from 'react'

import { FormattedMessage } from 'react-intl'

import { PricePanelLabel } from '@components/UI/PricePanel/PricePanel.styled'
import Theme from '@theme'
import { Text } from '@tribegroup'

interface IProps {
  displayedStatus: string
}

const SubmissionCardPricePanelLabel: React.FC<IProps> = ({ displayedStatus }) => {
  const statusId = displayedStatus.startsWith('declined') ? 'declined' : displayedStatus
  return (
    <PricePanelLabel>
      <Text xsmall uppercase color={Theme.secondaryColor}>
        <FormattedMessage id={`submission.fullView.pricePanel.label.text.${statusId}`} />
      </Text>
    </PricePanelLabel>
  )
}

export default SubmissionCardPricePanelLabel
