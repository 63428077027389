import React from 'react'

import Currency from 'react-currency-formatter'
import { FormattedMessage } from 'react-intl'

import { OffPlatformSubmissionMetricsUnavailableNotice } from '@components/Submission/Influencer/Profile/OffPlatformMetricUnavailableNotice'
import DetailCard from '@components/UI/DetailCard'
import { SocialSubmissionTypes } from '@enums'
import Theme from '@theme'
import { Header, Text } from '@tribegroup'
import { isTruthyOrZero } from '@utils'
import { abbreviateNumberOrDash } from '@utils/abbreviateNumber'
import SubmissionPerformanceDetailsStory from './Story/Story'
import { PerformanceDetailCardGrid, PerformanceDetailCardWrapper } from './Details.styled'
import PerformancePlaceholder from './Placeholder'
import { useSubmissionPerformanceDetails } from './useSubmissionPerformanceDetails'

interface IPerformanceDetailCard {
  title: React.ReactNode
  content: React.ReactNode
  tooltip?: React.ReactNode
  value?: number
  elementName?: string
}

interface IDetailCardTooltip {
  label: string
}

export const DetailCardTooltip: React.FC<IDetailCardTooltip> = ({ label }) => (
  <React.Fragment>
    <Header textAlign="left" uppercase bottomOuterSpacing={0.5} color={Theme.primaryColor}>
      <FormattedMessage id={`submission.performance.${label}`} />
    </Header>
    <Text textAlign="left" color={Theme.grey900Color} customFontSize={0.8125}>
      <FormattedMessage id={`submission.performance.${label}Info`} />
    </Text>
  </React.Fragment>
)

export const PerformanceDetailCardWithEmptyDefault: React.FC<IPerformanceDetailCard> = ({
  title,
  value,
  content,
  tooltip,
  elementName,
}) => (
  <PerformanceDetailCardWrapper>
    <DetailCard
      title={title}
      content={
        isTruthyOrZero(value) ? content : <FormattedMessage id="submission.influencer.empty" />
      }
      tooltip={tooltip}
      elementName={elementName}
    />
  </PerformanceDetailCardWrapper>
)

const PerformanceDetailCard: React.FC<IPerformanceDetailCard> = ({ title, content, tooltip }) => (
  <PerformanceDetailCardWrapper>
    <DetailCard
      title={<FormattedMessage id={`submission.performance.${title}`} />}
      content={content}
      tooltip={tooltip}
    />
  </PerformanceDetailCardWrapper>
)

export const SubmissionPerformanceDetails: React.FC = () => {
  const {
    isStory,
    isVideo,
    isSubmissionFromFacebook,
    isSubmissionFromTwitter,
    hasAnalytics,
    identityFollowersCount,
    engagementPercentage,
    costPerEngagement,
    likes,
    comments,
    videoViews,
    shares,
    retweets,
    totalEngagement,
    currency,
    socialSubmissionType,
    isProxySubmission,
  } = useSubmissionPerformanceDetails()
  const isPin = socialSubmissionType === SocialSubmissionTypes.Pin

  if (!hasAnalytics) {
    if (isProxySubmission) {
      return <OffPlatformSubmissionMetricsUnavailableNotice type="postPurchase" />
    }

    if (isPin) {
      return <PerformancePlaceholder type="pinterest" />
    }
    return <PerformancePlaceholder type="unavailable" />
  }

  if (isStory) {
    return <SubmissionPerformanceDetailsStory />
  }

  return (
    <PerformanceDetailCardGrid>
      <PerformanceDetailCard
        title="followers"
        content={abbreviateNumberOrDash(identityFollowersCount)}
      />
      <PerformanceDetailCard
        title="engagementPercentageShort"
        content={
          isTruthyOrZero(engagementPercentage) ? (
            <FormattedMessage id="core.text.percent" values={{ value: engagementPercentage }} />
          ) : (
            <FormattedMessage id="submission.influencer.empty" />
          )
        }
        tooltip={<DetailCardTooltip label="engagementPercentage" />}
      />
      {isTruthyOrZero(costPerEngagement) && (
        <PerformanceDetailCard
          title="cpe"
          content={<Currency currency={currency} quantity={costPerEngagement} />}
          tooltip={<DetailCardTooltip label="cpe" />}
        />
      )}

      <PerformanceDetailCard title="likes" content={abbreviateNumberOrDash(likes)} />
      <PerformanceDetailCard title="comments" content={abbreviateNumberOrDash(comments)} />
      {isVideo && (
        <PerformanceDetailCard
          title="views"
          content={abbreviateNumberOrDash(videoViews)}
          tooltip={<DetailCardTooltip label="views" />}
        />
      )}

      {isSubmissionFromFacebook && (
        <PerformanceDetailCard title="shares" content={abbreviateNumberOrDash(shares)} />
      )}
      {isSubmissionFromTwitter && (
        <PerformanceDetailCard title="retweets" content={abbreviateNumberOrDash(retweets)} />
      )}
      <PerformanceDetailCard
        title="totalEngagement"
        content={
          isTruthyOrZero(totalEngagement) ? (
            totalEngagement
          ) : (
            <FormattedMessage id="submission.influencer.empty" />
          )
        }
        tooltip={<DetailCardTooltip label="totalEngagement" />}
      />
    </PerformanceDetailCardGrid>
  )
}

export default SubmissionPerformanceDetails
