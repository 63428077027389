import { useContext } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import Flex from 'styled-flex-component'

import Breakpoint from '@components/UI/Breakpoint'
import StopPropagation from '@components/UI/StopPropagation/StopPropagation'
import { BRANDS_TIKTOK_CONNECT_EDUCATION } from '@constants'
import { CampaignContext } from '@context/Campaign'
import { MemberRoleTypes, PageRoutes } from '@enums'
import { useDrawer } from '@hooks/useDrawer'
import { useInboxPrompt } from '@hooks/useInboxPrompt'
import { selectBrandManagerIdentitiesIsFetched } from '@store/brandManagerIdentities'
import { IApplicationState } from '@store/index'
import Theme from '@theme'
import { Button, CloseIcon, Container, Header, Icon, Link, Text } from '@tribegroup'
import queryStringtoObject from '@utils/url'

const ConnectedAccountsSideBarItemEducationConnectTikTokAccount: React.FC = () => {
  const { push, location } = useHistory()
  const { pathname, search } = useLocation()
  const { isOpen } = useDrawer()
  const {
    campaign: { role },
  } = useContext(CampaignContext)

  const fromRoute = `?from=${encodeURIComponent(`${pathname}${search}`)}`

  const { isBrandManagerIdentitiesFetched } = useSelector((state: IApplicationState) => {
    return {
      isBrandManagerIdentitiesFetched: selectBrandManagerIdentitiesIsFetched(state),
    }
  })

  const { isPromptOpen, hidePrompt } = useInboxPrompt()
  const searchParams = queryStringtoObject(location.search)

  const isFromSubmissionDetailView = searchParams?.fromPageRoute === PageRoutes.Submissions

  if (!isBrandManagerIdentitiesFetched || !isPromptOpen || isOpen || isFromSubmissionDetailView) {
    return null
  }

  const onConnectClick = () => {
    push({
      pathname: `/${PageRoutes.ConnectedAccounts}`,
      search: fromRoute,
    })
  }

  const onCloseClick = () => {
    hidePrompt()
  }

  return (
    <Breakpoint tabletLandscape tabletPortrait desktop>
      <StopPropagation>
        <Container
          cursorDefault
          width={31.25}
          backgroundColor={Theme.whiteColor}
          borderRadius={0.5}
          innerSpacing={1.25}
          fixed
          bottomPosition={1.75}
          leftPosition={15.25}
          zIndex={1}
          boxShadow="0 2px 20px 0 rgba(77, 77, 77, 0.15)"
          data-testid="connect-tiktok-account-prompt"
        >
          <CloseIcon onClick={onCloseClick} size={1} right={1} top={1} />
          {role === MemberRoleTypes.Owner && (
            <>
              <Text medium color={Theme.grey900Color} block>
                <FormattedMessage id="connectedAccounts.connectAccount.education.owner.tiktok.header" />
              </Text>
              <Text color={Theme.grey900Color} block>
                <FormattedMessage id="connectedAccounts.connectAccount.education.owner.tiktok.body" />
              </Text>
            </>
          )}
          {[MemberRoleTypes.Collaborator, MemberRoleTypes.SolutionCollaborator].includes(role) && (
            <>
              <Text medium color={Theme.grey900Color} block>
                <FormattedMessage id="connectedAccounts.connectAccount.education.collaborator.tiktok.header" />
              </Text>
              <Text color={Theme.grey900Color} block>
                <FormattedMessage id="connectedAccounts.connectAccount.education.collaborator.tiktok.body" />
              </Text>
            </>
          )}
          <Container topOuterSpacing={1}>
            <Flex justifyBetween alignCenter>
              {role === MemberRoleTypes.Owner && (
                <Flex center>
                  <Icon glyph="nav-back-default" color={Theme.primaryColor} size={1.25} />
                  <Button
                    scheme="primary"
                    fullWidth
                    outlined
                    small
                    leftOuterSpacing={0.75}
                    onClick={onConnectClick}
                  >
                    <Header color={Theme.primaryColor} uppercase cta small>
                      <FormattedMessage id="connectedAccounts.connectAccount" />
                    </Header>
                  </Button>
                </Flex>
              )}
              <Link href={BRANDS_TIKTOK_CONNECT_EDUCATION} target="_blank">
                <Text color={Theme.primaryColor}>
                  <FormattedMessage id="core.text.learnWhy.withArrow" />
                </Text>
              </Link>
            </Flex>
          </Container>
        </Container>
      </StopPropagation>
    </Breakpoint>
  )
}

export default ConnectedAccountsSideBarItemEducationConnectTikTokAccount
