import React from 'react'

import { useSelector } from 'react-redux'
import { compose } from 'recompose'

import BuilderDialogUploadInProgress from '@components/Builder/Dialog/UploadInProgress'
import { BuilderTitleBarActionsConfirmCloseContext } from '@components/Builder/TitleBar/Actions/ConfirmClose'
import ConditionalLink from '@components/UI/ConditionalLink/ConditionalLink'
import { TitleBarLogo } from '@components/UI/TitleBar/TitleBar'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { IModalContextProps } from '@context/Modal'
import { EventTrackingNames, PageRoutes } from '@enums'
import { withBuilderValidation, withDraft, withModal } from '@hocs'
import { IApplicationState } from '@store'
import { selectAllCampaignCount } from '@store/campaigns'
import { selectCampaignIdFromDraft } from '@store/drafts'
import { selectIsAnyUploading } from '@store/moodboard'
import { Container } from '@tribegroup'

interface IInternalProps
  extends IDraftContextProps,
    IBuilderValidationContextProps,
    IModalContextProps {}

const CAMPAIGNS_LINK = `/${PageRoutes.Campaigns}`

const BuilderTitleBarLogoLink: React.FC<IInternalProps> = ({ formChanged, draft, showModal }) => {
  const { showConfirmModal } = React.useContext(BuilderTitleBarActionsConfirmCloseContext)
  const { hasCreatedAnyCampaign, isAnyUploading } = useSelector((state: IApplicationState) => {
    const campaignId = selectCampaignIdFromDraft(state)

    return {
      hasCreatedAnyCampaign: Boolean(selectAllCampaignCount(state)),
      isAnyUploading: campaignId && selectIsAnyUploading(state, campaignId),
    }
  })

  const onClick = (event: React.SyntheticEvent) => {
    if (isAnyUploading) {
      return showModal(BuilderDialogUploadInProgress)
    }

    if (hasCreatedAnyCampaign && formChanged) {
      event.preventDefault()
      showConfirmModal(CAMPAIGNS_LINK, EventTrackingNames.CampaignBuilderCloseBuilder)
    }
  }

  const redirectTo = isAnyUploading ? '#' : CAMPAIGNS_LINK

  return (
    <ConditionalLink
      showLink={hasCreatedAnyCampaign}
      to={redirectTo}
      data-cy-element="builder-logo"
      data-testid="builder-logo"
    >
      <Container
        height={1.75}
        width={1.75}
        leftInnerSpacing={1.5}
        onClick={onClick}
        data-testid="builder-logo-container"
      >
        <ConnectedTrackedRoute
          eventName={EventTrackingNames.CampaignBuilderCloseBuilder}
          campaignId={draft.id}
          additionalProperties={{
            action: 'close',
            current_filter: undefined,
          }}
        >
          <TitleBarLogo />
        </ConnectedTrackedRoute>
      </Container>
    </ConditionalLink>
  )
}

export default compose<IInternalProps, {}>(
  withBuilderValidation,
  withDraft,
  withModal,
)(BuilderTitleBarLogoLink)
