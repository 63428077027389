import React, { useContext } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import AnalyticsPostCampaignReportEmpty from '@components/Analytics/PostCampaignReport/ListView/Empty/Empty'
import AnalyticsPostCampaignReportListViewInsights from '@components/Analytics/PostCampaignReport/ListView/Insights/Insights'
import AnalyticsPostCampaignReportListViewAmount from '@components/Analytics/PostCampaignReport/ListView/Item/Amount'
import AnalyticsPostCampaignReportListViewNumber from '@components/Analytics/PostCampaignReport/ListView/Item/Number'
import AnalyticsPostCampaignReportListViewPercentage from '@components/Analytics/PostCampaignReport/ListView/Item/Percentage'
import {
  Entry,
  RowWrapper,
} from '@components/Analytics/PostCampaignReport/ListView/ListView.styled'
import { CampaignContext } from '@context/Campaign'
import { IApplicationState } from '@store'
import {
  selectPostComments,
  selectPostCPE,
  selectPostCPM,
  selectPostEngagement,
  selectPostEngagementPct,
  selectPostFollowers,
  selectPostImpressions,
  selectPostLikes,
  selectPostPublishedDate,
  selectPostReach,
  selectPostReachPct,
  selectPostSpend,
  selectShowPostPerformance,
} from '@store/postCampaignReport'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { abbreviateNumber } from '@utils'

interface IProps {
  submissionId: number
  backgroundColor: string
}

type IInternalProps = IProps & ConnectedProps<typeof connector>

const AnalyticsPostCampaignReportPostListItem: React.FC<IInternalProps> = ({
  spend,
  followers,
  likes,
  comments,
  engagement,
  engagementPct,
  cpe,
  reach,
  reachPct,
  impressions,
  cpm,
  backgroundColor,
  showMetrics,
  publishedDate,
}) => {
  const {
    campaign: { is_price_hidden: isPriceHidden },
  } = useContext(CampaignContext)

  if (!showMetrics) {
    return (
      <Container data-testid="post-purchase-empty">
        <AnalyticsPostCampaignReportEmpty
          minWidth={isPriceHidden ? 58.5 : 78.375}
          backgroundColor={backgroundColor}
        />
      </Container>
    )
  }

  return (
    <Container inline relative height={4.75}>
      <Flex>
        <RowWrapper data-testid="post-published-date" backgroundColor={backgroundColor}>
          <Entry>
            <Text color={Theme.grey900Color}>{publishedDate}</Text>
          </Entry>
        </RowWrapper>
        {!isPriceHidden && (
          <AnalyticsPostCampaignReportListViewAmount
            backgroundColor={backgroundColor}
            testId="post-purchase-amount"
            pattern="!###,### "
            value={spend}
          />
        )}

        <RowWrapper data-testid="post-followers" backgroundColor={backgroundColor}>
          <Entry>
            <Text color={Theme.grey900Color}>{abbreviateNumber(followers)}</Text>
          </Entry>
        </RowWrapper>
        <AnalyticsPostCampaignReportListViewNumber
          backgroundColor={backgroundColor}
          testId="post-likes"
          value={likes}
        />
        <AnalyticsPostCampaignReportListViewNumber
          backgroundColor={backgroundColor}
          testId="post-comments"
          value={comments}
        />
        <AnalyticsPostCampaignReportListViewNumber
          backgroundColor={backgroundColor}
          testId="post-engagement"
          value={engagement}
        />
        <AnalyticsPostCampaignReportListViewPercentage
          backgroundColor={backgroundColor}
          testId="post-engagement-pct"
          value={engagementPct}
        />

        {!isPriceHidden && (
          <AnalyticsPostCampaignReportListViewAmount
            backgroundColor={backgroundColor}
            testId="post-cpe"
            value={cpe}
          />
        )}

        <AnalyticsPostCampaignReportListViewInsights
          testIdPrefix="post"
          backgroundColor={backgroundColor}
          reach={reach}
          reachPct={reachPct}
          impressions={impressions}
          cpm={cpm}
          isPriceHidden={isPriceHidden}
        />
      </Flex>
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState, { submissionId }: IProps) => {
  return {
    publishedDate: selectPostPublishedDate(state, submissionId),
    spend: selectPostSpend(state, submissionId),
    followers: selectPostFollowers(state, submissionId),
    likes: selectPostLikes(state, submissionId),
    comments: selectPostComments(state, submissionId),
    engagement: selectPostEngagement(state, submissionId),
    engagementPct: selectPostEngagementPct(state, submissionId),
    cpe: selectPostCPE(state, submissionId),
    reach: selectPostReach(state, submissionId),
    reachPct: selectPostReachPct(state, submissionId),
    impressions: selectPostImpressions(state, submissionId),
    cpm: selectPostCPM(state, submissionId),
    showMetrics: selectShowPostPerformance(state, submissionId),
  }
}

const connector = connect(mapStateToProps)
export default compose<IInternalProps, IProps>(connector)(AnalyticsPostCampaignReportPostListItem)
