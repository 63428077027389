import React from 'react'

import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import { Hr } from '@components/UI/Hr/Hr.styled'
import { ConnectedTrackedRoute } from '@components/UI/TrackedRoute'
import { IConnectedTrackedRoute } from '@components/UI/TrackedRoute/ConnectedTrackedRoute'
import TribeOnly from '@components/UI/TribeOnly'
import rocket from '@images/rocketship-illustration.png'
import Theme from '@theme'
import { Button, Container, Link, Text } from '@tribegroup'

interface IUpsellInformationProps {
  textInformation: React.ReactNode
  textInformationMaxWidth?: number
  externalLink: string
  trackingProps?: IConnectedTrackedRoute
  showSeparator?: boolean
}

const UpsellInformation: React.FC<IUpsellInformationProps> = ({
  textInformation,
  externalLink,
  trackingProps,
  textInformationMaxWidth = 9.25,
  showSeparator = true,
}) => (
  <React.Fragment>
    {showSeparator && (
      <Container topInnerSpacing={1} bottomOuterSpacing={1}>
        <Hr margin={0} />
      </Container>
    )}
    <Flex justifyBetween alignStart>
      <Container maxWidth={textInformationMaxWidth} textAlign="left">
        <Text color={Theme.primaryColor} customFontSize={1} lineHeight={1.25}>
          {textInformation}
        </Text>
      </Container>
      <Container topOuterSpacing={-1}>
        <img width={76} height={76} src={rocket} alt="campaign-creation-prompt-illustration" />
      </Container>
    </Flex>
    <TribeOnly>
      <Container fullWidth topOuterSpacing={0.75}>
        <ConnectedTrackedRoute disableTracking={!trackingProps} {...trackingProps!}>
          <Link href={externalLink} target="_blank" color={Theme.secondaryColor}>
            <Button type="button" fullWidth outlined small scheme="primary">
              <FormattedMessage id="core.text.learnMore" />
            </Button>
          </Link>
        </ConnectedTrackedRoute>
      </Container>
    </TribeOnly>
  </React.Fragment>
)

export default UpsellInformation
