import React from 'react'

import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'recompose'

import { IBuilderValidationContextProps } from '@context/BuilderValidation'
import { IDraftContextProps } from '@context/Draft'
import { withBuilderValidation, withDraft } from '@hocs'
import { IApplicationState } from '@store'
import { selectDraftIsSocial, selectIsDisabledAttribute } from '@store/drafts'
import { selectTargetRegionsAsLabelValue } from '@store/rootResource'
import Theme from '@theme'
import { Container, FormInput, MultiSelectButtonGroup } from '@tribegroup'
import { BudgetErrorMessage } from '../../Error/Message'
import BuilderBudgetTargetPanelTitle from '../PanelTitle'
import { CREATORS_HEADER_I18N_PREFIX } from '../Target'

const DEMOGRAPHIC_TARGET_REGION_IDS = 'demographic_target_region_ids'

interface IInternalProps
  extends IBuilderValidationContextProps,
    IDraftContextProps,
    InjectedIntlProps,
    ConnectedProps<typeof connector> {}

const BuilderBudgetTargetDemographicLocation: React.FC<IInternalProps> = ({
  intl,
  validationResults,
  draft,
  updateForm,
  targetRegions,
  isDisabled,
  clearFieldValidation,
}) => {
  const targetRegionIds = draft[DEMOGRAPHIC_TARGET_REGION_IDS] || []

  const onLocationsChanged = (options) => {
    clearFieldValidation(DEMOGRAPHIC_TARGET_REGION_IDS)
    updateForm({
      [DEMOGRAPHIC_TARGET_REGION_IDS]: options || [],
    })
  }

  const regions = targetRegions.map((targetRegion) => ({
    value: targetRegion.value,
    display: targetRegion.label,
  }))

  const outlinedValues = targetRegions
    .filter((targetRegion) => !targetRegionIds.includes(+targetRegion.value))
    .map((targetRegion) => targetRegion.value)

  return (
    <Container data-testid="builder-budget-target-demographic-location">
      <BuilderBudgetTargetPanelTitle>
        <FormattedMessage id={`${CREATORS_HEADER_I18N_PREFIX}.location`} />
      </BuilderBudgetTargetPanelTitle>
      <FormInput
        validationRule={{
          required: {
            value: true,
            message: intl.formatMessage({
              id: 'builder.budget.error.location.required',
            }),
          },
        }}
      >
        <Container topOuterSpacing={1}>
          <MultiSelectButtonGroup
            options={regions}
            disabled={isDisabled}
            showOutlinedAsDisabled={isDisabled}
            name={DEMOGRAPHIC_TARGET_REGION_IDS}
            selectedValues={targetRegionIds}
            onSelectedValuesChange={onLocationsChanged}
            disabledValues={isDisabled ? targetRegionIds : []}
            outlinedValues={isDisabled ? outlinedValues : []}
            allOption={!isDisabled && <FormattedMessage id="core.text.all" />}
            activeBackgroundColor={Theme.primary200Color}
          />
        </Container>
      </FormInput>
      <BudgetErrorMessage results={validationResults} errorKey={DEMOGRAPHIC_TARGET_REGION_IDS} />
    </Container>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  const isSocial = selectDraftIsSocial(state)
  const isDisabledAttr = selectIsDisabledAttribute(state, DEMOGRAPHIC_TARGET_REGION_IDS)
  return {
    isDisabled: isSocial && isDisabledAttr,
    targetRegions: selectTargetRegionsAsLabelValue(state),
  }
}

const connector = connect(mapStateToProps)
export default compose<IInternalProps, {}>(
  withDraft,
  withBuilderValidation,
  injectIntl,
  connector,
)(BuilderBudgetTargetDemographicLocation)
