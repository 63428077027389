import React from 'react'

import { Waypoint } from 'react-waypoint'

import { XPositionType } from '@context/InformationTooltip'
import Theme from '@theme'
import { Icon } from '@tribegroup'
import {
  InformationOuterPanel,
  InformationPanel,
  InformationPanelContent,
  InformationPanelTriangle,
  InformationTarget,
  InformationWrapper,
} from './Information.styled'

interface IInformation {
  target?: React.ReactNode
  children: React.ReactNode
  width?: number
  waypointOffset?: number
  delay?: number
  xPosition?: XPositionType
  panelTopOffset?: number
  panelRightOffset?: number
  panelBottomOffset?: number
  panelLeftOffset?: number
  hoverStyle?: string
  disabled?: boolean

  onEnterProp?: VoidFunction
  onLeaveProp?: VoidFunction
}

const Information = ({
  target = <Icon glyph="information" color={Theme.grey900Color} />,
  children,
  width,
  delay,
  waypointOffset = 250,
  xPosition = 'left',
  panelTopOffset,
  panelRightOffset,
  panelBottomOffset,
  panelLeftOffset,
  hoverStyle,
  disabled,
  onEnterProp,
  onLeaveProp,
}: IInformation) => {
  const mainContainer: React.RefObject<HTMLDivElement> = React.createRef()
  const [showBelowTarget, setShowBelowTarget] = React.useState<boolean>(false)

  const onPositionChange = ({ currentPosition }: Waypoint.CallbackArgs) => {
    setShowBelowTarget(currentPosition === 'above')
  }

  const showRightOfTarget = xPosition === 'right'

  if (disabled) {
    return target
  }

  return (
    <InformationWrapper
      onMouseLeave={onLeaveProp}
      delay={delay}
      data-testid="information-wrapper"
      hoverStyle={hoverStyle}
    >
      <Waypoint onPositionChange={onPositionChange} topOffset={waypointOffset}>
        <div ref={mainContainer}>
          <InformationOuterPanel
            showBelowTarget={showBelowTarget}
            showRightOfTarget={showRightOfTarget}
            topOffset={panelTopOffset}
            rightOffset={panelRightOffset}
            bottomOffset={panelBottomOffset}
            leftOffset={panelLeftOffset}
          >
            <InformationPanel>
              <InformationPanelTriangle
                showBelowTarget={showBelowTarget}
                showRightOfTarget={showRightOfTarget}
              />
              <InformationPanelContent width={width}>{children}</InformationPanelContent>
            </InformationPanel>
          </InformationOuterPanel>
        </div>
      </Waypoint>
      <InformationTarget pointer onMouseEnter={onEnterProp}>
        {target}
      </InformationTarget>
    </InformationWrapper>
  )
}

export default Information
