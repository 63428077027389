import { IResponseError } from '@lib/error'

export interface ISetPassword {
  email: string
}

export interface IPasswordState {
  readonly isRequesting: boolean
  readonly errors?: IResponseError
}

export enum PasswordActionTypes {
  FORGOT_PASSWORD_REQUEST = 'resetPassword/FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_DONE = 'resetPassword/FORGOT_PASSWORD_DONE',
  SET_PASSWORD_REQUEST = 'resetPassword/SET_PASSWORD_REQUEST',
  SET_PASSWORD_REQUEST_SUCCESS = 'resetPassword/SET_PASSWORD_REQUEST_SUCCESS',
  SET_PASSWORD_REQUEST_ERROR = 'resetPassword/SET_PASSWORD_REQUEST_ERROR',
}
