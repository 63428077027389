import { FC } from 'react'

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { TRIBE_PREAPPROVAL_BRANDS_LEARN_MORE } from '@constants'
import { ApprovalType } from '@store/campaigns'
import { IMediaObject } from '@store/mediaObjects'
import { selectPreapprovalAmountPercentage } from '@store/rootResource'
import Theme from '@theme'
import { Container, H1, Link, Text } from '@tribegroup'
import { isEmpty } from '@utils/isEmpty'

interface IProps {
  mediaObject: IMediaObject
  approvalType?: ApprovalType
  preapprovalPercentage?: number
}

const translationKeyMap = {
  'pre-approval': {
    header: 'contentSubmission.card.modal.preapprove.license.header',
    paragraph1: 'contentSubmission.card.modal.preapprove.license.paragraph1',
    paragraph2: 'contentSubmission.card.modal.preapprove.license.paragraph2',
  },

  'final-approval': {
    header: 'contentSubmission.card.modal.finalApprove.license.header',
    paragraph1: 'contentSubmission.card.modal.finalApprove.license.paragraph1',
    paragraph2: 'contentSubmission.card.modal.finalApprove.license.paragraph2',
  },
}

export const PurchaseLicenseHeader: FC<IProps> = ({ approvalType, mediaObject }) => {
  let translationKeys

  if (approvalType) {
    translationKeys = translationKeyMap[approvalType]
  }

  const preapprovalPercentage = useSelector(selectPreapprovalAmountPercentage)

  let showFinalApprovalSecondParagraph = Boolean(translationKeys?.paragraph2)

  if (approvalType === 'final-approval') {
    const isLicenseEmpty =
      isEmpty(mediaObject?.transacted_license) ||
      mediaObject?.transacted_license?.status === 'preapproved'

    showFinalApprovalSecondParagraph = isLicenseEmpty && Boolean(translationKeys?.paragraph2)
  }

  return (
    <>
      <H1 color={Theme.grey900Color}>
        <FormattedMessage id={translationKeys?.header} />
      </H1>
      <Container topOuterSpacing={0.75}>
        <Text color={Theme.grey900Color} light customFontSize={1.125}>
          <FormattedHTMLMessage
            id={translationKeys?.paragraph1}
            values={{ preapprovalPercentage }}
          />
        </Text>
      </Container>

      {showFinalApprovalSecondParagraph && (
        <Container topOuterSpacing={0.75}>
          <Text color={Theme.grey900Color} light customFontSize={1.125}>
            <FormattedHTMLMessage
              id={translationKeys?.paragraph2}
              values={{ preapprovalPercentage }}
            />
          </Text>
        </Container>
      )}

      {approvalType === 'pre-approval' && (
        <Container topOuterSpacing={0.75}>
          <Link
            href={TRIBE_PREAPPROVAL_BRANDS_LEARN_MORE}
            target="_blank"
            color={Theme.primaryColor}
          >
            <Text
              customFontSize={0.8125}
              lineHeight={1}
              color={Theme.primaryColor}
              uppercase
              customletterSpacing="1.5px"
            >
              <FormattedMessage id="core.text.learnMore.withArrow" />
            </Text>
          </Link>
        </Container>
      )}
    </>
  )
}
