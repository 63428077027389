import { useContext, useState } from 'react'

import { useSelector } from 'react-redux'

import { MultiframeControlContext } from '@context/MultiframeControls'
import { SubmissionContext } from '@context/Submission'
import { IApplicationState } from '@store'
import {
  ISubmissionFrame,
  selectActiveNoteFrameNumber,
  selectMediaObjects,
} from '@store/submissions'
import SubmissionCreatorNoteBlock from '../Block/Block'
import SubmissionCreatorNoteAccordionItem from './Item/Item'
import { SubmissionCreatorNoteAccordionStyled } from './Accordion.styled'

interface ISubmissionCreatorNoteAccordionState {
  selectedFrameNumber: number
}

const SubmissionCreatorNoteAccordion: React.FC = () => {
  const { activeFrameNumber } = useContext(MultiframeControlContext)
  const { submission } = useContext(SubmissionContext)
  const { activeNoteFrameNumber, mediaObjects } = useSelector((state: IApplicationState) => ({
    mediaObjects: selectMediaObjects(state, submission.id),
    activeNoteFrameNumber: selectActiveNoteFrameNumber(state, submission.id, activeFrameNumber),
  }))

  const [{ selectedFrameNumber }, setState] = useState<ISubmissionCreatorNoteAccordionState>({
    selectedFrameNumber: activeNoteFrameNumber || 0,
  })

  const selectFrame = (frameNumber: number) => {
    setState({
      selectedFrameNumber: frameNumber,
    })
  }

  return (
    <SubmissionCreatorNoteAccordionStyled>
      {mediaObjects.map(
        ({ frame_number, creator_note }: ISubmissionFrame) =>
          creator_note && (
            <SubmissionCreatorNoteAccordionItem
              active={frame_number === selectedFrameNumber}
              key={frame_number}
              id={frame_number}
              onClick={selectFrame}
            >
              <SubmissionCreatorNoteBlock
                active={frame_number === selectedFrameNumber}
                frameNumber={frame_number}
                text={creator_note}
              />
            </SubmissionCreatorNoteAccordionItem>
          ),
      )}
    </SubmissionCreatorNoteAccordionStyled>
  )
}

export default SubmissionCreatorNoteAccordion
