import styled from 'styled-components'

const RelativeDateStyled = styled.div`
  margin-right: 1.25rem;
  width: 100%;
  height: 1rem;

  > span {
    letter-spacing: 0.4px;
  }
`

const RelativeDateClamped = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export { RelativeDateStyled, RelativeDateClamped }
