import { createSelector } from 'reselect'

import { performanceDisplayMap } from '@components/Reports/PostCampaignReport/Performance/List/performanceMap'
import { submissionPerformanceMap } from '@components/Reports/PostCampaignReport/Submission/List/Performance/performanceMap'
import { EMPTY_LIST } from '@constants'
import { PCRChannels } from '@enums'
import { IApplicationState } from '@store'
import { selectIsCampaignFetched } from '@store/campaigns'
import {
  ICampaignMetrics,
  IPCRPerformanceMap,
  IPostMetricsSummary,
  IReelMetrics,
  IStoryMetricsSummary,
  IVideoMetricsSummary,
} from '@store/postCampaignReport'
import { selectActivePCRChannel, selectSearchStatus } from '@store/router'
import { isEmpty, objectToQueryString } from '@utils'
import {
  selectPostMetricIds,
  selectPostMetrics,
  selectPostMetricsSummary,
  selectShowPostPerformance,
} from './postsMetrics/selectors'
import { selectReelMetricIds, selectReelMetrics } from './reelMetrics/selectors'
import {
  selectShowStoryPerformance,
  selectStoryMetricIds,
  selectStoryMetrics,
  selectStoryMetricsSummary,
} from './storyMetrics/selectors'
import { selectTikTokMetricIds, selectTikTokMetrics } from './tiktokMetrics/selectors'
import {
  selectShowVideoPerformance,
  selectVideoMetricIds,
  selectVideoMetrics,
  selectVideoMetricsSummary,
} from './videoMetrics/selectors'
import { IPostMetrics } from './postsMetrics'
import { IStoryMetrics } from './storyMetrics'
import { ITikTokMetrics } from './tiktokMetrics'
import { IVideoMetrics } from './videoMetrics'

export const selectCampaignMetrics = (state: IApplicationState, campaignId: number) =>
  state.reportings.campaignMetrics.campaignMetricsById[campaignId] || ({} as ICampaignMetrics)

export const selectIsSummaryFetched = createSelector(
  selectCampaignMetrics,
  (campaignMetrics: ICampaignMetrics) => !isEmpty(campaignMetrics),
)

export const selectShowLoading = (state: IApplicationState) =>
  state.reportings.campaignMetrics.isFetching || state.reportings.postMetrics.isFetching

export const selectShowPerformanceCurrency = (performance: IPCRPerformanceMap) => {
  const currencyKeywords: ReadonlyArray<any> = ['cost', 'amount']
  return currencyKeywords.filter((keyword) => performance.prop.includes(keyword)).length > 0
}

export const selectFormattedDisplayList = (
  performanceCollection,
  metrics: ICampaignMetrics | IPostMetricsSummary | IVideoMetricsSummary | IStoryMetricsSummary,
) => {
  return performanceCollection.map((performanceList) =>
    performanceList.map((performance) => {
      const showCurrency = selectShowPerformanceCurrency(performance)
      return {
        key: performance.key,
        value: metrics[performance.prop],
        currency: showCurrency ? metrics[`${performance.prop}_currency`] : undefined,
        showTooltip: performance.showTooltip,
        showPercentSign: performance.showPercentSign,
        abbreviateValue: performance.abbreviateValue,
      }
    }),
  )
}

export const selectPCRChannelQueryString = createSelector(
  selectSearchStatus,
  (_, channel: string) => channel,
  (status: string, channel: string) =>
    objectToQueryString({
      status,
      channel,
    }),
)

export const selectIsSummaryActive = createSelector(
  selectActivePCRChannel,
  (activeChannel: PCRChannels) => activeChannel === PCRChannels.Summary,
)

export const selectIsIGPostsActive = createSelector(
  selectActivePCRChannel,
  (activeChannel: PCRChannels) => activeChannel === PCRChannels.IGPosts,
)

export const selectIsChannelPostRelated = createSelector(
  selectActivePCRChannel,
  (activeChannel: PCRChannels) =>
    [PCRChannels.Facebook, PCRChannels.Twitter, PCRChannels.IGPosts].includes(activeChannel),
)

export const selectIsChannelVideoRelated = createSelector(
  selectActivePCRChannel,
  (activeChannel: PCRChannels) => [PCRChannels.IGVideos].includes(activeChannel),
)

export const selectIsChannelStoryRelated = createSelector(
  selectActivePCRChannel,
  (activeChannel: PCRChannels) => [PCRChannels.IGStories].includes(activeChannel),
)

export const selectIsChannelTikTokRelated = createSelector(
  selectActivePCRChannel,
  (activeChannel: PCRChannels) => activeChannel === PCRChannels.TikTok,
)

export const selectShouldFetchPostMetrics = createSelector(
  selectIsCampaignFetched,
  selectIsSummaryFetched,
  selectIsChannelPostRelated,
  (isCampaignFetched, isSummaryFetched, isChannelPostRelated: boolean) => {
    return isCampaignFetched && isSummaryFetched && isChannelPostRelated
  },
)

export const selectShouldFetchVideoMetrics = createSelector(
  selectIsCampaignFetched,
  selectIsSummaryFetched,
  selectIsChannelVideoRelated,
  (isCampaignFetched, isSummaryFetched, isChannelVideoRelated: boolean) =>
    isCampaignFetched && isSummaryFetched && isChannelVideoRelated,
)

export const selectShouldFetchStoryMetrics = createSelector(
  selectIsCampaignFetched,
  selectIsSummaryFetched,
  (state: IApplicationState, campaignId: number, activeChannel: PCRChannels) => activeChannel,
  (isCampaignFetched, isSummaryFetched, activeChannel: PCRChannels) => {
    return isCampaignFetched && isSummaryFetched && [PCRChannels.IGStories].includes(activeChannel)
  },
)

export const selectShouldFetchTikTokMetrics = createSelector(
  selectIsCampaignFetched,
  selectIsSummaryFetched,
  (state: IApplicationState, campaignId: number, activeChannel: PCRChannels) => activeChannel,
  (isCampaignFetched, isSummaryFetched, activeChannel: PCRChannels) => {
    return isCampaignFetched && isSummaryFetched && PCRChannels.TikTok === activeChannel
  },
)

export const selectShouldFetchReelMetrics = createSelector(
  selectIsCampaignFetched,
  selectIsSummaryFetched,
  (state: IApplicationState, campaignId: number, activeChannel: PCRChannels) => activeChannel,
  (isCampaignFetched, isSummaryFetched, activeChannel: PCRChannels) => {
    return isCampaignFetched && isSummaryFetched && PCRChannels.Reel === activeChannel
  },
)

export const selectPerformanceDisplayList = createSelector(
  selectCampaignMetrics,
  selectPostMetricsSummary,
  selectVideoMetricsSummary,
  selectStoryMetricsSummary,
  selectIsChannelPostRelated,
  selectIsChannelVideoRelated,
  selectIsChannelStoryRelated,
  (state: IApplicationState, campaignId: number, activeChannel: string) => {
    return performanceDisplayMap[activeChannel]
  },
  (state: IApplicationState, campaignId: number, activeChannel: string) => activeChannel,
  (
    campaignMetrics: ICampaignMetrics,
    postMetrics: IPostMetricsSummary,
    videoMetrics: IVideoMetricsSummary,
    storyMetrics: IStoryMetricsSummary,
    isChannelPostRelated,
    isChannelVideoRelated,
    isChannelStoryRelated,
    performanceCollection,
    activeChannel: string,
  ) => {
    if (activeChannel === PCRChannels.Summary) {
      return selectFormattedDisplayList(performanceCollection, campaignMetrics)
    }
    if (isChannelPostRelated) {
      return selectFormattedDisplayList(performanceCollection, postMetrics)
    }
    if (isChannelVideoRelated) {
      return selectFormattedDisplayList(performanceCollection, videoMetrics)
    }
    if (isChannelStoryRelated) {
      return selectFormattedDisplayList(performanceCollection, storyMetrics)
    }

    return EMPTY_LIST
  },
)

export const selectIsIGVideoActive = createSelector(
  selectActivePCRChannel,
  (activeChannel: PCRChannels) => activeChannel === PCRChannels.IGVideos,
)

export const selectShowSubmissionList = createSelector(
  selectShowLoading,
  selectIsSummaryActive,
  (isLoading: boolean, isSummaryActive: boolean) => !isLoading && !isSummaryActive,
)

export const selectSubmissionMetrics = createSelector(
  selectActivePCRChannel,
  selectPostMetrics,
  selectVideoMetrics,
  selectStoryMetrics,
  selectTikTokMetrics,
  selectReelMetrics,
  (
    activeChannel: PCRChannels,
    postMetrics: IPostMetrics,
    videoMetrics: IVideoMetrics,
    storyMetrics: IStoryMetrics,
    tiktokMetrics: ITikTokMetrics,
    selectReelMetrics: IReelMetrics,
  ) => {
    if ([PCRChannels.Facebook, PCRChannels.Twitter, PCRChannels.IGPosts].includes(activeChannel)) {
      return postMetrics
    }

    if (activeChannel === PCRChannels.IGVideos) {
      return videoMetrics
    }

    if (activeChannel === PCRChannels.IGStories) {
      return storyMetrics
    }

    if (activeChannel === PCRChannels.TikTok) {
      return tiktokMetrics
    }

    if (activeChannel === PCRChannels.Reel) {
      return selectReelMetrics
    }

    return {}
  },
)

export const selectDisplaySubmissionPerformance = createSelector(
  selectActivePCRChannel,
  selectSubmissionMetrics,
  (activeChannel: PCRChannels, metrics: IPostMetrics | IVideoMetrics | IStoryMetrics) => {
    return selectDisplaySubmissionPerformanceMap(activeChannel, metrics)
  },
)

export const selectDisplaySubmissionPerformanceMap = (
  activeChannel: PCRChannels,
  metrics: IPostMetrics | IVideoMetrics | IStoryMetrics | ITikTokMetrics,
) => {
  const performanceMap = submissionPerformanceMap[activeChannel]
  return performanceMap.map((structure) => {
    const showCurrency = selectShowPerformanceCurrency(structure)
    return {
      key: structure.key,
      value: metrics[structure.prop],
      currency: showCurrency ? metrics[`${structure.prop}_currency`] : undefined,
      showTooltip: structure.showTooltip,
      showPercentSign: structure.showPercentSign,
    }
  })
}

export const selectShowSubmissionPerformance = createSelector(
  selectIsChannelPostRelated,
  selectIsChannelVideoRelated,
  selectIsChannelStoryRelated,
  selectShowPostPerformance,
  selectShowVideoPerformance,
  selectShowStoryPerformance,
  (
    isChannelPostRelated: boolean,
    isChannelVideoRelated: boolean,
    isChannelStoryRelated: boolean,
    showPostPerformance: boolean,
    showVideoPerformance: boolean,
    showStoryPerformance: boolean,
  ) => {
    if (isChannelPostRelated) {
      return showPostPerformance
    }

    if (isChannelVideoRelated) {
      return showVideoPerformance
    }

    if (isChannelStoryRelated) {
      return showStoryPerformance
    }

    return false
  },
)

export const selectInfluencerName = createSelector(
  selectSubmissionMetrics,
  (metrics: IPostMetrics) => metrics.influencer_name,
)

export const selectInfluencerHandle = createSelector(
  selectSubmissionMetrics,
  (metrics: IPostMetrics) => metrics.handle,
)

export const selectInfluencerImageURL = createSelector(
  selectSubmissionMetrics,
  (metrics: IPostMetrics) => metrics.image_url,
)

export const selectSubmissionChannel = createSelector(
  selectSubmissionMetrics,
  (metrics: IPostMetrics) => metrics.channel,
)

export const selectSocialPageURL = createSelector(
  selectSubmissionMetrics,
  (metrics: IPostMetrics) => metrics.social_page_url,
)

export const selectPublishedURL = createSelector(
  selectSubmissionMetrics,
  (metrics: IPostMetrics) => metrics.published_url,
)

export const selectSubmissionMetricIds = createSelector(
  selectPostMetricIds,
  selectVideoMetricIds,
  selectStoryMetricIds,
  selectTikTokMetricIds,
  selectReelMetricIds,
  selectActivePCRChannel,
  (
    postMetricIds: ReadonlyArray<number>,
    videoMetricIds: ReadonlyArray<number>,
    storyMetricIds: ReadonlyArray<number>,
    tiktokMetricIds: ReadonlyArray<number>,
    reelMetricIds: ReadonlyArray<number>,
    activeChannel: PCRChannels,
  ) => {
    if ([PCRChannels.Facebook, PCRChannels.Twitter, PCRChannels.IGPosts].includes(activeChannel)) {
      return postMetricIds
    }

    if (activeChannel === PCRChannels.IGVideos) {
      return videoMetricIds
    }

    if (activeChannel === PCRChannels.IGStories) {
      return storyMetricIds
    }

    if (activeChannel === PCRChannels.TikTok) {
      return tiktokMetricIds
    }

    if (activeChannel === PCRChannels.Reel) {
      return reelMetricIds
    }

    return EMPTY_LIST
  },
)

export const selectShowEmptyState = createSelector(
  selectSubmissionMetricIds,
  (metricIds: ReadonlyArray<number>) => metricIds.length === 0,
)

export const selectShowGlossaryButton = createSelector(
  selectActivePCRChannel,
  (activeChannel: PCRChannels) => {
    return [
      PCRChannels.IGPosts,
      PCRChannels.IGStories,
      PCRChannels.IGVideos,
      PCRChannels.TikTok,
      PCRChannels.Reel,
    ].includes(activeChannel)
  },
)
