import React from 'react'

import { FormattedMessage } from 'react-intl'

import InfluencerIdentityRowLabelTooltip from '@components/InfluencerIdentity/Row/LabelTooltip'
import InfluencerIdentityRowValueAndLabel from '@components/InfluencerIdentity/Row/ValueAndLabel'
import { SubmissionChannels } from '@enums'
import { abbreviateNumber } from '@utils'

interface IPerformanceFollowersProps {
  followerCount: number
  provider: SubmissionChannels
}

const BrandFanInfluencerIdentityRowPerformanceFollowers: React.FC<IPerformanceFollowersProps> = ({
  followerCount,
  provider,
}) => (
  <InfluencerIdentityRowValueAndLabel
    value={
      SubmissionChannels.Pinterest === provider ? (
        <FormattedMessage id="core.metric.empty" />
      ) : (
        abbreviateNumber(followerCount)
      )
    }
    label={
      <InfluencerIdentityRowLabelTooltip
        title={<FormattedMessage id="core.text.followers" />}
        body={<FormattedMessage id="submission.performance.followers.body" />}
      >
        <FormattedMessage id="core.text.followers" />
      </InfluencerIdentityRowLabelTooltip>
    }
    width={5.75}
  />
)

export { BrandFanInfluencerIdentityRowPerformanceFollowers, IPerformanceFollowersProps }
export default BrandFanInfluencerIdentityRowPerformanceFollowers
