import { Reducer } from 'redux'

import { EMPTY_LIST } from '@constants'
import { AuthActionTypes } from '@store/auth'
import {
  FacebookMediaTransferActionTypes,
  FetchFacebookAdAccountsActionTypes,
  IFacebookAdsState,
} from '@store/facebookAds'

const initialState: IFacebookAdsState = {
  isFetching: false,
  hasFetched: false,
  accounts: [],
  errors: undefined,
  isTransferringMedia: false,
}

const reducer: Reducer<IFacebookAdsState> = (state = initialState, action) => {
  switch (action.type) {
    case FetchFacebookAdAccountsActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
        hasFetched: false,
        errors: undefined,
      }
    }

    case FetchFacebookAdAccountsActionTypes.FETCH_SUCCESS: {
      return {
        ...state,
        accounts: action.payload || EMPTY_LIST,
        isFetching: false,
        hasFetched: true,
      }
    }

    case FacebookMediaTransferActionTypes.MEDIA_TRANSFER_ERROR:
    case FetchFacebookAdAccountsActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        hasFetched: false,
        isTransferringMedia: false,
        errors: action.payload,
      }
    }

    case FacebookMediaTransferActionTypes.MEDIA_TRANSFER_REQUEST:
      return {
        ...state,
        isTransferringMedia: true,
      }

    case FacebookMediaTransferActionTypes.MEDIA_TRANSFER_SUCCESS:
      return {
        ...state,
        isTransferringMedia: false,
      }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as facebookAdsReducer }
