type Data = {
  label: string
  percentage: number
  value: number
}

export interface IAudienceReporting {
  summary: {
    total_posts: number
    unique_creators: number
    total_impressions: number
    overall_cpm: number
    overall_cpm_currency: string
  }
  age_range: Data[]
  gender: Data[]
  cities: Data[]
  countries: Data[]
  gender_per_age_range: {
    labels: string[]
    datasets: {
      percentage: {
        label: string
        data: number[]
      }[]
      value: {
        label: string
        data: number[]
      }[]
    }
  }
}

export enum AudienceReportingActionTypes {
  FETCH_REQUEST = 'audienceReporting/FETCH_REQUEST',
  FETCH_SUCCESS = 'audienceReporting/FETCH_SUCCESS',
  FETCH_ERROR = 'audienceReporting/FETCH_ERROR',
}

export interface IAudienceReportingState {
  readonly audienceReportingByCampaignId: Record<number, IAudienceReporting>
  readonly isFetching: boolean
  readonly errors?: any
}
