import { Reducer } from 'redux'

import { AuthActionTypes } from '@store/auth'
import { FetchCategoriesActionTypes, ICategoriesState } from '@store/categories'

const initialState: ICategoriesState = {
  isFetching: false,
  categories: [],
  errors: undefined,
}

const reducer: Reducer<ICategoriesState> = (state = initialState, action) => {
  switch (action.type) {
    case FetchCategoriesActionTypes.FETCH_REQUEST: {
      return {
        ...state,
        isFetching: true,
        errors: undefined,
      }
    }

    case FetchCategoriesActionTypes.FETCH_SUCCESS: {
      const { items } = action.payload
      return {
        ...state,
        isFetching: false,
        categories: items,
      }
    }

    case FetchCategoriesActionTypes.FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      }
    }

    case AuthActionTypes.SIGNOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export { reducer as categoriesReducer }
