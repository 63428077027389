import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const AnalyticsPrePurchaseGrid = styled.div`
  display: block;
  ${breakpoint('tabletPortrait')`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem;
  `};
`
