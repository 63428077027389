import styled from 'styled-components'
import Flex from 'styled-flex-component'

export const CreatorHeaderStyled = styled(Flex)`
  box-sizing: border-box;
  padding: 0.75rem 1rem;
  height: auto;
`
export const CreatorHeaderPriceStyled = styled.div`
  flex-shrink: 0;
  text-align: right;
`

export const CreatorHeaderDetails = styled.div`
  min-width: 0;
  width: 100%;
`

export const CreatorHeaderDetailsTopStyled = styled(Flex)`
  width: 100%;
  min-width: 0;
  padding-bottom: 0.25rem;
`
export const CreatorHeaderUserHandleStyled = styled.div`
  margin-right: 0.375rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
