import { PCRChannels } from '@enums'
import { IStoryMetricsResponse, StoryMetricsActionTypes } from '@store/postCampaignReport'

export const fetchStoryMetrics = (campaignId: number, channel: PCRChannels) => ({
  payload: {
    campaignId,
    channel,
  },
  type: StoryMetricsActionTypes.FETCH_REQUEST,
})

export const fetchStoryMetricsSuccess = (
  storyMetrics: IStoryMetricsResponse,
  campaignId: number,
  channel: PCRChannels,
) => ({
  payload: {
    storyMetrics,
    campaignId,
    channel,
  },
  type: StoryMetricsActionTypes.FETCH_SUCCESS,
})

export const fetchStoryMetricsError = error => ({
  payload: error,
  type: StoryMetricsActionTypes.FETCH_ERROR,
})
