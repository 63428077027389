import { createSelector } from 'reselect'

import { IApplicationState } from '@store'
import {
  selectIdentityId,
  selectIsReelSocialSubmission,
  selectIsStorySocialSubmission,
  selectIsSubmissionFromPinterest,
  selectIsSubmissionFromTiktok,
  selectIsVideoSocialSubmission,
  selectSubTotalCents,
} from '@store/submissions'
import { getCostPerMetric } from '@utils/metrics'
import { IPrePurchaseAnalytics, PrePurchaseAnalyticsType } from './types'

export const selectIsFetchingPrePurchaseAnalytics = (state: IApplicationState) =>
  state.prePurchaseAnalytics.isFetching

export const selectPrePurchaseAnalyticsType = createSelector(
  selectIsVideoSocialSubmission,
  selectIsStorySocialSubmission,
  selectIsSubmissionFromTiktok,
  selectIsSubmissionFromPinterest,
  selectIsReelSocialSubmission,
  (isVideo, isStory, isTikTok, isPinterest, isReel) => {
    if (isPinterest) {
      return PrePurchaseAnalyticsType.PINTEREST
    }

    if (isTikTok) {
      return PrePurchaseAnalyticsType.TIKTOK
    }

    if (isStory) {
      return PrePurchaseAnalyticsType.STORY
    }

    if (isReel) {
      return PrePurchaseAnalyticsType.REEL
    }

    return PrePurchaseAnalyticsType.POST
  },
)

export const selectPrePurchaseAnalytics = createSelector(
  selectPrePurchaseAnalyticsType,
  selectIdentityId,
  (state: IApplicationState, submissionId: number, identityId: number) => {
    return state.prePurchaseAnalytics.prePurchaseAnalyticsByIdentityId[identityId]
  },
  (analyticsType: PrePurchaseAnalyticsType, identityId: number, prepurchaseAnalytics) => {
    return prepurchaseAnalytics?.[analyticsType]
  },
)

export const selectPrePurchaseAnalyticsPost = (state: IApplicationState, identityId: number) => {
  return state.prePurchaseAnalytics.prePurchaseAnalyticsByIdentityId[identityId]?.[
    PrePurchaseAnalyticsType.POST
  ]
}

export const selectPrePurchaseAnalyticsVideo = (state: IApplicationState, identityId: number) => {
  return state.prePurchaseAnalytics.prePurchaseAnalyticsByIdentityId[identityId]?.[
    PrePurchaseAnalyticsType.VIDEO
  ]
}

export const selectPrePurchaseAnalyticsStory = (state: IApplicationState, identityId: number) => {
  return state.prePurchaseAnalytics.prePurchaseAnalyticsByIdentityId[identityId]?.[
    PrePurchaseAnalyticsType.STORY
  ]
}

export const selectPrePurchaseAnalyticsTikTok = (state: IApplicationState, identityId: number) => {
  return state.prePurchaseAnalytics.prePurchaseAnalyticsByIdentityId[identityId]?.[
    PrePurchaseAnalyticsType.TIKTOK
  ]
}

export const selectPrePurchaseAnalyticsPinterest = (
  state: IApplicationState,
  identityId: number,
) => {
  return state.prePurchaseAnalytics.prePurchaseAnalyticsByIdentityId[identityId]?.[
    PrePurchaseAnalyticsType.PINTEREST
  ]
}

export const selectPrePurchaseAnalyticsReel = (state: IApplicationState, identityId: number) => {
  return state.prePurchaseAnalytics.prePurchaseAnalyticsByIdentityId[identityId]?.[
    PrePurchaseAnalyticsType.REEL
  ]
}

export const selectAvgEngagement = createSelector(
  (state, identityId, prePurchaseAnalyticsType) => prePurchaseAnalyticsType,
  selectPrePurchaseAnalyticsPost,
  (prePurchaseAnalyticsType, prePurchaseAnalyticsPost: IPrePurchaseAnalytics) => {
    return prePurchaseAnalyticsPost?.avg_engagement
  },
)

export const selectAvgEngagementPercentage = createSelector(
  selectPrePurchaseAnalyticsPost,
  (prePurchaseAnalyticsPost: IPrePurchaseAnalytics) => prePurchaseAnalyticsPost?.avg_engagement_pct,
)

export const selectAvgVideoViews = createSelector(
  (state, identityId, prePurchaseAnalyticsType) => prePurchaseAnalyticsType,
  selectPrePurchaseAnalyticsVideo,
  (prePurchaseAnalyticsType, prePurchaseAnalyticsVideo: IPrePurchaseAnalytics) => {
    return prePurchaseAnalyticsVideo?.avg_video_views
  },
)

export const selectAvgVideoViewsPercentage = createSelector(
  selectPrePurchaseAnalyticsVideo,
  (prePurchaseAnalyticsVideo: IPrePurchaseAnalytics) =>
    prePurchaseAnalyticsVideo?.avg_video_views_pct,
)

export const selectAvgReach = createSelector(
  (state, identityId, prePurchaseAnalyticsType) => prePurchaseAnalyticsType,
  selectPrePurchaseAnalyticsPost,
  selectPrePurchaseAnalyticsVideo,
  selectPrePurchaseAnalyticsStory,
  (
    prePurchaseAnalyticsType,
    prePurchaseAnalyticsPost,
    prePurchaseAnalyticsVideo,
    prePurchaseAnalyticsStory,
  ) => {
    if (prePurchaseAnalyticsType === PrePurchaseAnalyticsType.STORY) {
      return prePurchaseAnalyticsStory && prePurchaseAnalyticsStory.avg_reach
    }

    if (prePurchaseAnalyticsType === PrePurchaseAnalyticsType.VIDEO) {
      return prePurchaseAnalyticsVideo && prePurchaseAnalyticsVideo.avg_reach
    }

    return prePurchaseAnalyticsPost && prePurchaseAnalyticsPost.avg_reach
  },
)

export const selectAvgReachPercentage = createSelector(
  (state, identityId, prePurchaseAnalyticsType) => prePurchaseAnalyticsType,
  selectPrePurchaseAnalyticsPost,
  selectPrePurchaseAnalyticsVideo,
  selectPrePurchaseAnalyticsStory,
  (
    prePurchaseAnalyticsType,
    prePurchaseAnalyticsPost,
    prePurchaseAnalyticsVideo,
    prePurchaseAnalyticsStory,
  ) => {
    if (prePurchaseAnalyticsType === PrePurchaseAnalyticsType.STORY) {
      return prePurchaseAnalyticsStory && prePurchaseAnalyticsStory.avg_reach_pct
    }

    if (prePurchaseAnalyticsType === PrePurchaseAnalyticsType.VIDEO) {
      return prePurchaseAnalyticsVideo && prePurchaseAnalyticsVideo.avg_reach_pct
    }

    return prePurchaseAnalyticsPost && prePurchaseAnalyticsPost.avg_reach_pct
  },
)

export const selectAvgImpressions = createSelector(
  (state, identityId, prePurchaseAnalyticsType) => prePurchaseAnalyticsType,
  selectPrePurchaseAnalyticsPost,
  selectPrePurchaseAnalyticsVideo,
  selectPrePurchaseAnalyticsStory,
  (
    prePurchaseAnalyticsType,
    prePurchaseAnalyticsPost,
    prePurchaseAnalyticsVideo,
    prePurchaseAnalyticsStory,
  ) => {
    if (prePurchaseAnalyticsType === PrePurchaseAnalyticsType.STORY) {
      return prePurchaseAnalyticsStory?.avg_impressions
    }

    if (prePurchaseAnalyticsType === PrePurchaseAnalyticsType.VIDEO) {
      return prePurchaseAnalyticsVideo?.avg_impressions
    }
    return prePurchaseAnalyticsPost?.avg_impressions
  },
)

export const selectEstCPM = createSelector(
  selectAvgImpressions,
  (state, identityId, prePurchaseAnalyticsType, submissionId) =>
    selectSubTotalCents(state, submissionId),
  (avgImpressions: number | undefined, subTotalCents) => {
    return getCostPerMetric(
      avgImpressions,
      subTotalCents,
      (metric, amount) => (amount / metric) * 1000,
    )
  },
)

export const selectEstCPE = createSelector(
  selectAvgEngagement,
  (state, identityId, prePurchaseAnalyticsType, submissionId) =>
    selectSubTotalCents(state, submissionId),
  (avgEngagement, subTotalCents) => {
    return getCostPerMetric(avgEngagement, subTotalCents, (metric, amount) => amount / metric)
  },
)

export const selectEstCPV = createSelector(
  selectAvgVideoViews,
  (state, identityId, prePurchaseAnalyticsType, submissionId) =>
    selectSubTotalCents(state, submissionId),
  (avgVideoViews, subTotalCents) => {
    return getCostPerMetric(avgVideoViews, subTotalCents, (metric, amount) => amount / metric)
  },
)
