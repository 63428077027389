import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  downloadPostCampaignReportError,
  downloadPostCampaignReportRequest,
  downloadPostCampaignReportSuccess,
  selectDownloadPostCampaignReportLink,
} from '@store/postCampaignReport'
import { DownloadPostCampaignReportActionTypes } from '@store/postCampaignReport'
import { downloadZIP } from '@utils'

export function* handleDownloadPostCampaignReportRequest(
  action: ReturnType<typeof downloadPostCampaignReportRequest>,
) {
  const { campaignId, timezoneInfo } = action.payload

  try {
    const authToken: string = yield select(selectAuthToken)
    const downloadPostCampaignReportLink: IHateoasLink = yield select(
      selectDownloadPostCampaignReportLink,
      campaignId,
      timezoneInfo,
    )

    const headers = {
      Authorization: `Bearer ${authToken}`,
    }
    yield call(downloadZIP, downloadPostCampaignReportLink, headers)
    yield put(downloadPostCampaignReportSuccess())
  } catch (error) {
    yield put(downloadPostCampaignReportError(error))
  }
}

export function* watchDownloadPostCampaignReportRequest() {
  yield takeLatest(
    DownloadPostCampaignReportActionTypes.DOWNLOAD_PCR_REQUEST,
    handleDownloadPostCampaignReportRequest,
  )
}
