import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchPrePurchaseAnalytics,
  fetchPrePurchaseAnalyticsError,
  fetchPrePurchaseAnalyticsSuccess,
  PrePurchaseAnalyticsActionTypes,
} from '@store/prePurchaseAnalytics'
import { selectPrePurchaseLink } from '@store/submissions'
import { networkRequest } from '@utils'
import { WatcherFunction } from '..'

export function* handleFetchPrePurchase(action: ReturnType<typeof fetchPrePurchaseAnalytics>) {
  const { identityId, submissionId, prePurchaseAnalyticsType } = action.payload

  try {
    const authToken: string = yield select(selectAuthToken)
    const prePurchaseLink: IHateoasLink = yield select(selectPrePurchaseLink, submissionId)

    const prePurchaseAnalytics = yield call(
      networkRequest,
      prePurchaseLink.method,
      prePurchaseLink.href,
      undefined,
      authToken,
    )

    yield put(
      fetchPrePurchaseAnalyticsSuccess(identityId, prePurchaseAnalyticsType, prePurchaseAnalytics),
    )
  } catch (error) {
    yield put(fetchPrePurchaseAnalyticsError(error))
  }
}

function* watchFetchPrePurchase() {
  yield takeLatest(PrePurchaseAnalyticsActionTypes.FETCH_REQUEST, handleFetchPrePurchase)
}

export const prePurchaseSagas: ReadonlyArray<WatcherFunction> = [watchFetchPrePurchase]
