import ResendVerification from '@components/Auth/ResendVerification'
import FullImageBackground from '@components/UI/FullImageBackground'
import { ToastContainer } from '@tribegroup'

const ResendVerificationPage = () => (
  <FullImageBackground>
    <ToastContainer />
    <ResendVerification />
  </FullImageBackground>
)

export { ResendVerificationPage }
export default ResendVerificationPage
