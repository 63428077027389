export const toPercentage = (tooltipItem, data) => {
  const total = data.datasets[0].data.reduce((sum, a) => sum + a)
  const dataset = data.datasets[tooltipItem.datasetIndex]
  const currentValue = dataset.data[tooltipItem.index]
  const percent = parseFloat(((currentValue / total) * 100).toFixed(1))
  return `${percent}%`
}

export const tooltipValueToPercentage = (data) => {
  const total = data.dataset.data.reduce((sum, a) => sum + a)
  const currentValue = data.raw / total
  return (parseFloat(`${currentValue}`) * 100).toLocaleString() + '%'
}

export const toTitle = (tooltipItem, data) => {
  return data.labels[tooltipItem[0].index]
}
