/* eslint-disable react/display-name */
import { MultiframeControlContext } from '@context/MultiframeControls'

export const withMultiframeControls = (Component) => (props) => {
  return (
    <MultiframeControlContext.Consumer>
      {(contextProps) => <Component {...props} {...contextProps} />}
    </MultiframeControlContext.Consumer>
  )
}
