import React from 'react'

import { Keyframe } from 'styled-components'

import { AspectRatios } from '@enums'
import Theme from '@theme'
import ImageStyled, { ResponsiveImage } from './Image.styled'

export interface IImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  animation?: Keyframe
  aspectRatio?: AspectRatios
  loadingColor?: string
  backgroundColor?: string
  contain?: boolean
  round?: boolean
  borderRadius?: number
  fitToContainer?: boolean
  opacity?: number
  borderWidth?: number
  borderColor?: string
  role?: string
  containerHeight?: number | string
}

export default class Image extends React.PureComponent<IImageProps> {
  static defaultProps = {
    loadingColor: Theme.grey400Color,
  }

  state = {
    loaded: false,
  }

  onLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    this.setState({
      loaded: true,
    })
    if (this.props.onLoad) {
      this.props.onLoad(event)
    }
  }

  public render() {
    const { loaded } = this.state
    const {
      animation,
      aspectRatio,
      loadingColor,
      contain,
      backgroundColor,
      src,
      round,
      borderRadius,
      fitToContainer,
      opacity,
      borderWidth,
      borderColor,
      role,
      containerHeight,
    } = this.props
    const imageElement = (
      <ResponsiveImage
        aspectRatio={aspectRatio}
        animation={animation}
        borderWidth={borderWidth}
        borderColor={borderColor}
        {...this.props}
        loaded={loaded}
        onLoad={this.onLoad}
        src={src}
        contain={contain}
        round={round}
        borderRadius={borderRadius}
        fitToContainer={fitToContainer}
        role={role}
      />
    )
    return (
      <ImageStyled
        aspectRatio={aspectRatio}
        loaded={loaded}
        loadingColor={loadingColor}
        backgroundColor={backgroundColor}
        fitToContainer={fitToContainer}
        opacity={opacity}
        round={round}
        containerHeight={containerHeight}
      >
        {imageElement}
      </ImageStyled>
    )
  }
}
