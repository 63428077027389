import { FormattedMessage, InjectedIntlProps } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import RouterLink from '@components/UI/RouterLink/RouterLink.styled'
import { PageRoutes } from '@enums'
import { IApplicationState } from '@store'
import { selectCreditCardLastFourDigits, selectHasCreditCardInfo } from '@store/me'
import Theme from '@theme'
import { Text } from '@tribegroup'

const PaymentLink = ({ children }) => (
  <RouterLink to={`/${PageRoutes.Account}`}>
    <Text small color={Theme.secondaryColor}>
      {children}
    </Text>
  </RouterLink>
)

export const CreditCardAdd = () => (
  <FormattedMessage
    id="licensing.creditCard.addMessage"
    values={{
      addLink: (
        <PaymentLink>
          <FormattedMessage id="licensing.creditCard.addLink" />
        </PaymentLink>
      ),
    }}
  />
)

export const CreditCardUpdate = ({ lastFourDigits }) => (
  <FormattedMessage
    id="licensing.creditCard.updateMessage"
    values={{
      lastFourDigits,
      updateLink: (
        <PaymentLink>
          <FormattedMessage id="licensing.creditCard.updateLink" />
        </PaymentLink>
      ),
    }}
  />
)

interface IInternalProps extends InjectedIntlProps {
  hasCreditCardInfo: boolean
  lastFourDigits?: string
}

export const CreditCardLink = ({ hasCreditCardInfo, lastFourDigits }: IInternalProps) => (
  <Text textAlign="center" small color={Theme.grey800Color}>
    {hasCreditCardInfo ? <CreditCardUpdate lastFourDigits={lastFourDigits} /> : <CreditCardAdd />}
  </Text>
)

const mapStateToProps = (state: IApplicationState) => ({
  hasCreditCardInfo: selectHasCreditCardInfo(state),
  lastFourDigits: selectCreditCardLastFourDigits(state),
})

export default compose<IInternalProps, {}>(connect(mapStateToProps))(CreditCardLink)
