import { call, put, select, takeLatest } from 'redux-saga/effects'

import { selectAuthToken } from '@store/auth'
import {
  FetchBrandFanGroupMembersActionTypes,
  fetchBrandFanGroupMembersError,
  fetchBrandFanGroupMembersSuccess,
  refreshBrandFanGroupMembers,
  selectBrandFansGroupMembersSelfLink,
} from '@store/brandFans'
import { networkRequest } from '@utils'

export function* handleRefreshBrandFanGroupMembers(
  action: ReturnType<typeof refreshBrandFanGroupMembers>,
) {
  try {
    const groupId = action.payload
    const authToken: string = yield select(selectAuthToken)
    const brandFanGroupMembersLink = yield select(selectBrandFansGroupMembersSelfLink, groupId)
    const { items, links } = yield call(
      networkRequest,
      brandFanGroupMembersLink.method,
      brandFanGroupMembersLink.href,
      undefined,
      authToken,
    )
    yield put(fetchBrandFanGroupMembersSuccess(groupId, items, links))
  } catch (error) {
    yield put(fetchBrandFanGroupMembersError(error))
  }
}

export function* watchRefreshBrandFanGroupMemberRequest() {
  yield takeLatest(
    FetchBrandFanGroupMembersActionTypes.REFRESH_REQUEST,
    handleRefreshBrandFanGroupMembers,
  )
}
