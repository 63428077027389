import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import Flex from 'styled-flex-component'

import BrandFansContentUnsupportedScreenHandler from '@components/BrandFans/Content/UnsupportedScreenHandler'
import { NoticeEmpty } from '@components/UI/Notice'
import { withRememberScrollPosition } from '@hocs'
import { IApplicationState } from '@store'
import {
  fetchBrandFanGroups,
  selectBrandFanGroupDescription,
  selectBrandFanGroupIds,
  selectBrandFanGroupMemberCount,
  selectBrandFanGroupName,
} from '@store/brandFans'
import { selectCreateCampaignLink } from '@store/campaigns'
import { selectBrandFansGroupId } from '@store/router'
import Theme from '@theme'
import { Container, Text } from '@tribegroup'
import { isEmpty } from '@utils'
import BrandFansGroupMemberList from '../Member/List'
import { Root } from './Details.styled'
import BrandFansGroupDetailsHeader from './Header'
import BrandFansGroupDetailsSort from './Sort'

const BrandFanGroupMembersNoticeEmpty: React.FC = () => (
  <NoticeEmpty
    topOuterSpacing={4}
    title={
      <Container topOuterSpacing={2} maxWidth={16.875}>
        <Text italic color={Theme.grey800Color} light customFontSize={1}>
          <FormattedMessage id="brandFans.memberList.empty" />
        </Text>
      </Container>
    }
    alt="empty-members-list"
  />
)

interface IInternalProps {
  groupId: number
  memberCount: number
  groupsFetched: boolean
  groupName: string
  groupDescription: string
  fetchBrandFanGroups: typeof fetchBrandFanGroups
  hasCreateCampaignLink: boolean
}

const BrandFansGroupDetails: React.FC<IInternalProps> = ({
  groupId,
  memberCount,
  groupName,
  groupsFetched,
  groupDescription,
  hasCreateCampaignLink,
  fetchBrandFanGroups: fetchBrandFanGroupsAction,
}) => {
  React.useEffect(() => {
    if (!groupsFetched) {
      fetchBrandFanGroupsAction()
    }
  }, [])

  if (!groupsFetched) {
    return null
  }
  return (
    <Root>
      <BrandFansContentUnsupportedScreenHandler>
        <BrandFansGroupDetailsHeader
          groupId={groupId}
          count={memberCount}
          name={groupName}
          description={groupDescription}
          showCreateCampaignButton={hasCreateCampaignLink}
        />
        {memberCount > 0 ? (
          <React.Fragment>
            <Flex justifyEnd>
              <BrandFansGroupDetailsSort groupId={groupId} />
            </Flex>
            <BrandFansGroupMemberList groupId={groupId} />
          </React.Fragment>
        ) : (
          <BrandFanGroupMembersNoticeEmpty />
        )}
      </BrandFansContentUnsupportedScreenHandler>
    </Root>
  )
}

const mapStateToProps = (state: IApplicationState, { match }: RouteComponentProps) => {
  const groupId = selectBrandFansGroupId(match) as number
  return {
    groupId,
    memberCount: selectBrandFanGroupMemberCount(state, groupId),
    groupName: selectBrandFanGroupName(state, groupId),
    groupDescription: selectBrandFanGroupDescription(state, groupId),
    groupsFetched: !isEmpty(selectBrandFanGroupIds(state)),
    hasCreateCampaignLink: Boolean(selectCreateCampaignLink(state)),
  }
}

const mapDispatchToProps = {
  fetchBrandFanGroups,
}

export { BrandFansGroupDetails }
export default compose(
  withRouter,
  withRememberScrollPosition,
  connect(mapStateToProps, mapDispatchToProps),
)(BrandFansGroupDetails)
