import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'

import Price from '@components/UI/Price/Price'
import { IApplicationState } from '@store'
import { selectCampaignId } from '@store/router'
import {
  selectEstimatedCPE,
  selectEstimatedCPECurrency,
  selectIsPriceHidden,
  selectSubTotalCents,
} from '@store/submissions'
import Theme from '@theme'
import { Container, H5 } from '@tribegroup'
import { isEmpty } from '@utils'
import SubmissionCardHeaderPrice from './Price/Price'
import SubmissionCardHeaderActions from './Actions'
import { SubmissionCardHeaderRoot, SubmissionCardMainHeader } from './Header.styled'

interface ISubmissionCardHeaderProps {
  submissionId: number
  toggleCostBreakdown?: (event: React.MouseEvent) => void
}

interface IInternalProps extends ISubmissionCardHeaderProps {
  currency: string
  estimatedCpe: number
  campaignId: number
  showPrice: boolean
  hasAudienceInsights: boolean
}

const SubmissionCardHeaderCPE: React.FC<IInternalProps> = ({
  submissionId,
  campaignId,
  estimatedCpe,
  currency,
  showPrice,
  toggleCostBreakdown,
}) => {
  return (
    <SubmissionCardHeaderRoot>
      <SubmissionCardMainHeader full justifyBetween alignCenter>
        {estimatedCpe ? (
          <H5 uppercase color={Theme.grey700Color}>
            <FormattedMessage id="submission.performance.estCpe" />
            &nbsp;
            <Price quantity={estimatedCpe} currency={currency} />
          </H5>
        ) : (
          <>&nbsp;</>
        )}
        {showPrice && (
          <SubmissionCardHeaderPrice
            toggleCostBreakdown={toggleCostBreakdown}
            submissionId={submissionId}
            campaignId={campaignId}
          />
        )}
      </SubmissionCardMainHeader>
      <Container height={1.75}>
        <SubmissionCardHeaderActions submissionId={submissionId} campaignId={campaignId} />
      </Container>
    </SubmissionCardHeaderRoot>
  )
}

const mapStateToProps = (
  state: IApplicationState,
  { match, submissionId }: ISubmissionCardHeaderProps & RouteComponentProps,
) => {
  const campaignId = selectCampaignId(match)
  const showPrice =
    !isEmpty(selectSubTotalCents(state, submissionId)) && !selectIsPriceHidden(state, submissionId)
  return {
    campaignId,
    showPrice: showPrice,
    estimatedCpe: selectEstimatedCPE(state, submissionId),
    currency: selectEstimatedCPECurrency(state, submissionId),
  }
}

export { SubmissionCardHeaderCPE }
export default compose<IInternalProps, ISubmissionCardHeaderProps>(
  withRouter,
  connect(mapStateToProps),
)(SubmissionCardHeaderCPE)
