import { PropsWithChildren } from 'react'

import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Flex from 'styled-flex-component'

export const BuilderLayoutWrapper = styled(Flex)`
  margin: auto;
  padding-bottom: 10rem;
`

const LEFT_SPACE = '13.4vw'
const RIGHT_SPACE = '22.75rem'

interface IMainContentProps {
  center?: boolean
}

export const MainContent: React.FC<PropsWithChildren<IMainContentProps>> = styled.div`
  width: 100%;
  max-width: 900px;
  padding-left: ${LEFT_SPACE};
  padding-right: ${RIGHT_SPACE};
  ${({ center }) =>
    center &&
    css`
      margin: auto;
      padding: 0;
      width: calc(100% - ${RIGHT_SPACE} - ${LEFT_SPACE});
    `};
  ${breakpoint('tabletPortrait')`
    max-width: 56.25rem;
  `};
`

export const PanelWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;

  &:first-of-type {
    margin-top: 3.75rem;
  }

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 2.75rem;
  }
`
export const TextareaErrorMessageWrapper = styled.div`
  position: absolute;
  left: auto;
  top: auto;
  margin-top: -0.875rem;
`

export const BudgetErrorMessageWrapper = styled.div`
  margin-top: 0.5rem;
`

export const SubmitErrorMessageWrapper = styled.div`
  display: flex;
  margin-top: 0.5rem;
`
