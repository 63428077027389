import { call, put, select, takeLatest } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { AuthActionTypes, authError, authSuccess, signInRequest } from '@store/auth'
import { fetchMeRequest } from '@store/me'
import { selectSignInLink } from '@store/rootResource'
import { networkRequest } from '@utils'

export function* handleSignIn(action: ReturnType<typeof signInRequest>) {
  try {
    const signInLink: IHateoasLink = yield select(selectSignInLink)
    const { history, path, signInParams } = action.payload
    const result = yield call(networkRequest, signInLink.method, signInLink.href, signInParams)
    yield put(authSuccess(result))
    yield put(fetchMeRequest({ history, redirect: path, signIn: true }))
  } catch (error) {
    yield put(authError(error))
  }
}

export function* watchSignInRequest() {
  yield takeLatest(AuthActionTypes.SIGNIN_REQUEST, handleSignIn)
}
