import styled, { css } from 'styled-components'
import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Button } from '@tribegroup'

export const CreateSubmissionButton = styled(Button)`
  width: 100%;
  border-radius: 0;

  > div {
    margin: auto;
  }
`

export const EmptyImage16x9 = styled(Flex)`
  height: 13.625rem;
  width: 100%;
  background: ${Theme.grey200Color};
  ${({ withBottomSpace }) =>
    withBottomSpace &&
    css`
      margin-bottom: 2rem;
    `};
`
