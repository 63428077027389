import AuthSignIn from '@components/Auth/SignIn/Form'
import FullImageBackground from '@components/UI/FullImageBackground'
import HeadTag from '@components/UI/HeadTag'

export const SignInPage = () => (
  <FullImageBackground>
    <HeadTag id="auth.signin.h1.signInHeader" />
    <AuthSignIn />
  </FullImageBackground>
)

export default SignInPage
