import { FormattedMessage } from 'react-intl'
import Flex from 'styled-flex-component'

import {
  LicenseOptionSelected,
  LicensingAgreementsLink,
  LicensingButton,
  MediaDetails,
  MediaPreview,
  TransactedLicensePriceDetails,
} from '@components/Licensing'
import Theme from '@theme'
import { CloseIcon, Container, H1 } from '@tribegroup'

export const LicenseRequested = ({ onRequestClose, mediaObjectId }) => {
  return (
    <>
      <Container bottomOuterSpacing={1.75}>
        <H1 color={Theme.grey900Color} center>
          <FormattedMessage id="contentLibrary.licensing.creditManagement.licenseRequested.header.first" />
        </H1>
        <H1 color={Theme.grey900Color} center>
          <FormattedMessage id="contentLibrary.licensing.creditManagement.licenseRequested.header.second" />
        </H1>
      </Container>

      <CloseIcon onClick={onRequestClose} />
      <LicenseOptionSelected
        mediaObjectId={mediaObjectId}
        backgroundColor={Theme.primary100Color}
        clearBorder
      />
      <Container topOuterSpacing={1.5} leftOuterSpacing={3.125}>
        <Flex justifyBetween alignStart>
          <MediaDetails mediaObjectId={mediaObjectId} />
          <MediaPreview mediaObjectId={mediaObjectId} />
        </Flex>
      </Container>
      <Container topOuterSpacing={2.75} leftOuterSpacing={3.125}>
        <TransactedLicensePriceDetails mediaObjectId={mediaObjectId} />
      </Container>
      <Container topOuterSpacing={1.25}>
        <LicensingButton onClick={onRequestClose}>
          <FormattedMessage id="core.text.okay" />
        </LicensingButton>
      </Container>
      <Container topOuterSpacing={1.25}>
        <LicensingAgreementsLink color={Theme.primaryColor} />
      </Container>
    </>
  )
}

export default LicenseRequested
