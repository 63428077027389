import React from 'react'

import { FormattedMessage } from 'react-intl'

import { AnalyticsPostPerformanceIconWrapper } from '@components/Analytics/PostPerformance/PostPerformance.styled'
import ChartsAudienceInsights from '@components/Charts/AudienceInsigths'
import Loading from '@components/UI/Loading'
import { IAudienceInsights } from '@store/audienceInsights'
import Theme from '@theme'
import { Container, H5, Icon } from '@tribegroup'
import { isEmpty } from '@utils'

interface IProps {
  isFetching: boolean
  audienceInsights: IAudienceInsights
}

const BrandFansAudienceInsightsMerics: React.FC<IProps> = ({ audienceInsights, isFetching }) => {
  if (isFetching) {
    return (
      <Container topOuterSpacing={2.5}>
        <Loading leftAligned yPadding={0} height={1.125} />
      </Container>
    )
  }

  if (isEmpty(audienceInsights)) {
    return (
      <Container topOuterSpacing={2.5}>
        <AnalyticsPostPerformanceIconWrapper
          alignCenter
          justifyCenter
          background={Theme.whiteColor}
        >
          <Icon glyph="data" size={2} color={Theme.primaryColor} />
        </AnalyticsPostPerformanceIconWrapper>
        <Container width={18.25} topOuterSpacing={1.25} bottomOuterSpacing={1}>
          <H5 color={Theme.grey800Color}>
            <FormattedMessage id="audienceMetrics.placeholder.unavailable" />
          </H5>
        </Container>
      </Container>
    )
  }
  return (
    <Container topOuterSpacing={1.6875}>
      <ChartsAudienceInsights insights={audienceInsights} />
    </Container>
  )
}

export default BrandFansAudienceInsightsMerics
