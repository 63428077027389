import { FC, FormEvent } from 'react'

import { FormattedMessage } from 'react-intl'

import { ILicenseOption } from '@store/campaigns'
import Theme from '@theme'
import { Container, FormGroup, Radio, Text } from '@tribegroup'

interface IProps {
  licenseOptions: readonly ILicenseOption[]
  onChange?: (event: FormEvent<HTMLInputElement>) => void
}

export const LicenseBookletAsideLicenseOptions: FC<IProps> = ({ licenseOptions, onChange }) => {
  const digitalRightsLicenses = licenseOptions.filter(({ license_type }) =>
    license_type?.includes('digital_rights'),
  )
  const allRightsLicenses = licenseOptions.filter(({ license_type }) =>
    license_type?.includes('all_rights'),
  )

  return (
    <>
      <Container bottomOuterSpacing={1.5}>
        <Container bottomOuterSpacing={1}>
          <Text color={Theme.grey900Color} customFontSize={1.125} block>
            <FormattedMessage id="licenseBooklet.licenseOptions.digitalRights.header" />
          </Text>
          <Text color={Theme.grey800Color} block>
            <FormattedMessage id="licenseBooklet.licenseOptions.digitalRights.body" />
          </Text>
        </Container>

        <FormGroup>
          {Object.values(digitalRightsLicenses).map((license: ILicenseOption, index) => {
            return (
              <Container key={`${license?.license_type}-${index}`} bottomOuterSpacing={1}>
                <Radio
                  onChange={onChange}
                  scheme="primary"
                  label={
                    <FormattedMessage
                      id={`licenseBooklet.licenseOptions.${license.license_type}`}
                    />
                  }
                  defaultChecked={index === 0}
                  name="licensingOption"
                  labelCapitalize
                  data-license-index={index}
                  value={license.license_type}
                  elementName={license.license_type}
                  labelFontSize={1.125}
                />
              </Container>
            )
          })}
        </FormGroup>
      </Container>
      <Container>
        <Container bottomOuterSpacing={1}>
          <Text color={Theme.grey900Color} customFontSize={1.125} block>
            <FormattedMessage id="licenseBooklet.licenseOptions.allChannels.header" />
          </Text>
          <Text color={Theme.grey800Color} block>
            <FormattedMessage id="licenseBooklet.licenseOptions.allChannels.body" />
          </Text>
        </Container>
        <FormGroup>
          {Object.values(allRightsLicenses).map((license: ILicenseOption, index) => {
            return (
              <Container key={`${license?.label}-${index}`} bottomOuterSpacing={1}>
                <Radio
                  onChange={onChange}
                  scheme="primary"
                  label={
                    <FormattedMessage
                      id={`licenseBooklet.licenseOptions.${license.license_type}`}
                    />
                  }
                  name="licensingOption"
                  labelCapitalize
                  data-license-index={index}
                  value={license.license_type}
                  elementName={license.license_type}
                  labelFontSize={1.125}
                />
              </Container>
            )
          })}
        </FormGroup>
      </Container>
    </>
  )
}
