import React from 'react'

import { FormattedMessage } from 'react-intl'

import FlatButton from '@components/UI/FlatButton'
import Theme from '@theme'
import { Header } from '@tribegroup'

const ButtonRequestGranted: React.FC = () => (
  <FlatButton
    backgroundColor={Theme.primary100Color}
    topInnerSpacing={0.8125}
    bottomInnerSpacing={0.8125}
    leftInnerSpacing={1.4375}
    rightInnerSpacing={1.4375}
    disabled
  >
    <Header color={Theme.defaultColor} uppercase cta>
      <FormattedMessage id="submission.bca.rights.accepted" />
    </Header>
  </FlatButton>
)

export { ButtonRequestGranted }
export default ButtonRequestGranted
