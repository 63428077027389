import { DownloadPostCampaignReportActionTypes } from '@store/postCampaignReport'

export const downloadPostCampaignReportRequest = (campaignId: number, timezoneInfo: string) => ({
  payload: {
    campaignId,
    timezoneInfo,
  },
  type: DownloadPostCampaignReportActionTypes.DOWNLOAD_PCR_REQUEST,
})

export const downloadPostCampaignReportSuccess = () => ({
  type: DownloadPostCampaignReportActionTypes.DOWNLOAD_PCR_SUCCESS,
})

export const downloadPostCampaignReportError = error => ({
  payload: error,
  type: DownloadPostCampaignReportActionTypes.DOWNLOAD_PCR_ERROR,
})
