import AuthEmailVerificationConfirmation from '@components/Auth/EmailVerification/Confirmation'
import FullImageBackground from '@components/UI/FullImageBackground'

const EmailVerificationPage = () => (
  <FullImageBackground>
    <AuthEmailVerificationConfirmation />
  </FullImageBackground>
)

export default EmailVerificationPage
