import { PropsWithChildren, useEffect } from 'react'

import FlagProvider, {
  UnleashClient,
  useFlag,
  useUnleashContext,
  useVariant,
} from '@unleash/proxy-client-react'
import { useSelector } from 'react-redux'

import { selectMeId } from '@store/me'

const url = process.env.UNLEASH_URL!
const clientKey = process.env.UNLEASH_CLIENT_KEY!
const appName = process.env.UNLEASH_APP_NAME!
const environmentName = process.env.UNLEASH_ENVIRONMENT_NAME!
const environment = process.env.UNLEASH_ENVIRONMENT!

const UnleashContextProvider = ({ children }) => {
  const updateContext = useUnleashContext()
  const userId = useSelector(selectMeId)

  useEffect(() => {
    const context = { environment_name: environmentName, userId: userId ? `${userId}` : undefined }
    updateContext(context)
  }, [userId])
  return children
}

const config = {
  url,
  clientKey,
  appName,
  refreshInterval: 3600,
  environment,
}

const unleashClient = new UnleashClient(config)

export const FeatureToggleProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <FlagProvider unleashClient={unleashClient}>
      <UnleashContextProvider>{children}</UnleashContextProvider>
    </FlagProvider>
  )
}

export { useFlag, useUnleashContext, useVariant, unleashClient }

export default FeatureToggleProvider
