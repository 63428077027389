enum PCRChannels {
  Summary = 'summary',
  TikTok = 'tiktok',
  IGPosts = 'instagramPosts',
  Reel = 'reel',
  IGVideos = 'instagramVideos',
  IGStories = 'instagramStories',
  Facebook = 'facebook',
  Twitter = 'twitter',
}

export { PCRChannels }
