import { call, put, select, takeEvery } from 'redux-saga/effects'

import { IHateoasLink } from '@store'
import { selectAuthToken } from '@store/auth'
import {
  fetchBrandFanGroupMembers,
  FetchBrandFanGroupMembersActionTypes,
  fetchBrandFanGroupMembersError,
  fetchBrandFanGroupMembersSuccess,
  resetBrandFanGroupMembers,
  selectBrandFanGroupMembersLink,
  selectBrandFansGroupMembersNextLink,
} from '@store/brandFans'
import { selectCurrentSortBy } from '@store/router'
import { networkRequest } from '@utils'

export function* handleBrandFanGroupMembersLink(
  action: ReturnType<typeof fetchBrandFanGroupMembers>,
) {
  const { isFetchNext, groupId } = action.payload

  const BrandFanGroupMembersLink: IHateoasLink = isFetchNext
    ? yield select(selectBrandFansGroupMembersNextLink, groupId)
    : yield select(selectBrandFanGroupMembersLink, groupId)
  return BrandFanGroupMembersLink
}

export function* handleFetchBrandFanGroupMembers(action) {
  try {
    const { groupId, resetList } = action.payload
    const authToken: string = yield select(selectAuthToken)

    const sortBy = yield select(selectCurrentSortBy)

    const brandFanGroupMembersLink = yield call(handleBrandFanGroupMembersLink, action)

    if (resetList) {
      yield put(resetBrandFanGroupMembers(groupId))
    }

    const { items, links } = yield call(
      networkRequest,
      brandFanGroupMembersLink.method,
      brandFanGroupMembersLink.href.replace('{sort_by}', sortBy),
      undefined,
      authToken,
    )

    yield put(fetchBrandFanGroupMembersSuccess(groupId, items, links))
  } catch (error) {
    yield put(fetchBrandFanGroupMembersError(error))
  }
}

export function* watchBrandFanGroupMemberRequest() {
  yield takeEvery(
    FetchBrandFanGroupMembersActionTypes.FETCH_REQUEST,
    handleFetchBrandFanGroupMembers,
  )
}
