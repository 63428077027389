import { createSelector } from 'reselect'

import { IApplicationState } from '@store'
import { selectDraft } from '@store/drafts'
import { selectLocationsearchAsObject } from '@store/router'

const brandsRoot = (state: IApplicationState) => state.brands

export const selectBrandsIsFetching = createSelector(brandsRoot, (state) => state.isFetching)

export const selectBrandsIsAdding = createSelector(brandsRoot, (state) => state.isAdding)

export const selectBrandsIsisUpdating = createSelector(brandsRoot, (state) => state.isUpdating)

export const selectHasFetchedBrands = createSelector(brandsRoot, (state) => state.hasFetched)

export const selectBrand = createSelector(
  brandsRoot,
  (_, id: number) => id,
  (state, id) => state.brandsById[id],
)

export const selectBrands = createSelector(brandsRoot, (state) => Object.values(state.brandsById))

export const selectBrandIds = createSelector(selectBrands, (brands) =>
  brands.map((brand) => brand.id),
)

const selectBrandLinks = createSelector(brandsRoot, (state) => state.links)

export const selectAddBrandLink = createSelector(selectBrandLinks, (links) =>
  links.find((link) => link.rel === 'create_brand'),
)

export const selectUpdateBrandLink = createSelector(selectBrand, (brand) =>
  brand.links.find((link) => link.rel === 'update'),
)

export const selectSelectedBrandId = createSelector(
  selectDraft,
  selectLocationsearchAsObject,
  selectBrandIds,
  (draft, locationSearch, brandIds) => {
    const queryBrandId = parseInt(locationSearch.brandId, 10)
    if (brandIds.includes(queryBrandId)) {
      return queryBrandId
    } else if (draft && draft.brand_id) {
      return draft.brand_id
    }
    return undefined
  },
)

export const selectBrandError = createSelector(brandsRoot, (state) => state.errors)
