import React from 'react'

import Flex from 'styled-flex-component'

import Theme from '@theme'
import { Container, H4, Text } from '@tribegroup'

interface INoticeProps {
  title: React.ReactNode
  subtitle?: React.ReactNode
  education?: React.ReactNode
  image?: React.ReactNode
  topOuterSpacing?: number
}

export interface INoticeConsumerProps extends INoticeProps {
  alt: string
}

const Notice: React.FC<INoticeProps> = ({
  title,
  subtitle,
  education,
  image,
  topOuterSpacing = 7.625,
}) => (
  <Container topOuterSpacing={topOuterSpacing}>
    <Flex justifyCenter alignCenter full>
      <Flex column center>
        <H4 color={Theme.grey900Color} center>
          {title}
        </H4>
        {subtitle && (
          <Container topOuterSpacing={0.75} textAlign="center">
            <Text color={Theme.grey800Color}>{subtitle}</Text>
          </Container>
        )}
        {education && (
          <Container topOuterSpacing={0.75} textAlign="center">
            {education}
          </Container>
        )}
        <Container topOuterSpacing={2.25} textAlign="center">
          {image}
        </Container>
      </Flex>
    </Flex>
  </Container>
)

export { Notice }
export default Notice
